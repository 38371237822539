import React, { useState,useContext,useEffect,useRef } from 'react'
import { Button, TextField, Grid, Stack, InputLabel } from '@mui/material';
import { NumericFormat } from 'react-number-format';    //数値入力
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import { onMyFocus } from './hooks/useOnMyFocus';     // select()処理

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);

      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

const TCPD0131 = ({
  setScreenPhase,
  rows,
  setRows,
  rowData,
  rowItem,
  setRowItem,
  headerData,
  headerItem,
  setHeaderItem,
  setPrefectureList,
  setPrefectureList2,
  sdate,
  setHeaderHASDD     // 23/09/25
}) => {
  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);

  //項目のバックカラー制御用
  const [inKBNclr,setKBNclr] = useState('#FFFFFF');
  const [inBMNCDEclr,setBMNCDEclr] = useState('#FFFFFF');
  const [inASTCDEclr,setASTCDEclr] = useState('#FFFFFF');
  const [inHASDDclr,setHASDDclr] = useState('#FFFFFF');
  const [inUIRMISEclr,setUIRMISEclr] = useState('#FFFFFF');
  const [inUIRBMNCDEclr,setUIRBMNCDEclr] = useState('#FFFFFF');
  const [inUIRASTCDEclr,setUIRASTCDEclr] = useState('#FFFFFF');

  const inKBN = useRef(null);
  const inBMNCDE = useRef(null);
  const inASTCDE = useRef(null);
  const inHASDD = useRef(null);
  const inUIRMISE = useRef(null);
  const inUIRBMNCDE = useRef(null);
  const inUIRASTCDE = useRef(null);
  const inKAKEZEIRTU = useRef(null);

  // disabled制御用
  const [disASTCDE, setDisASTCDE] = useState(true);    // ASTCDE
  const [disUIRASTCDE, setDisUIRASTCDE] = useState(true);    // UIRASTCDE

  const commonStyles = {
    bgcolor: 'background.paper',
    m: 1,
    border: 0,
    width: '40px',
    height: '40px',
  };

  const ckDate = (strYYYYMMDD) =>{  // YYYY/MM/DD ⇒ 2023/09/07 等
    if(!strYYYYMMDD.match(/^\d{4}\/\d{2}\/\d{2}$/)){
        return false;
    }
    var y = strYYYYMMDD.split("/")[0];
    var m = strYYYYMMDD.split("/")[1] - 1;
    var d = strYYYYMMDD.split("/")[2];
    var date = new Date(y,m,d);
    if(date.getFullYear() != y || date.getMonth() != m || date.getDate() != d){
        return false;
    }
    return true;
  }

  const onKeyDown =  async (key,inputRef,inptRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報

    //console.log(key);
    switch (key) {
      case 'Enter':
        setKBNclr('#FFFFFF');
        setBMNCDEclr('#FFFFFF');
        setASTCDEclr('#FFFFFF');
        setHASDDclr('#FFFFFF');
        setUIRMISEclr('#FFFFFF');
        setUIRBMNCDEclr('#FFFFFF');
        setUIRASTCDEclr('#FFFFFF');
        const header = JSON.parse(JSON.stringify(headerItem));
        switch(inputRef.current.id){
          case 'KBN':
            await onKeyDown_KBN(inputRef,inputNxRef,header);         // 区分
            break;
          case 'BMNCDE':
            await onKeyDown_BMNCDE(inputRef,inputNxRef,header);      // 部門CD
            break;
          case 'ASTCDE':
            await onKeyDown_ASTCDE(inputRef,inputNxRef,header);      // 発送ＡＵ
            break;
          case 'HASDD':
            await onKeyDown_HASDD(inputRef,inputNxRef,header);       // 発送日
            break;
          case 'UIRMISE':
            await onKeyDown_UIRMISE(inputRef,inputNxRef,header);     // 受取店
            break;
          case 'UIRBMNCDE':
            await onKeyDown_UIRBMNCDE(inputRef,inputNxRef,header);   // 受取部門CD
            break;
          case 'UIRASTCDE':
            await onKeyDown_UIRASTCDE(inputRef,inputNxRef,header); // 受取ＡＵ
            break;
          case 'KAKEZEIRTU':
            await onKeyDown_KAKEZEIRTU(header);  // 掛率
            break;
          default:
            await inputNxRef.current.focus();
            break;
        }

      break;
      case 'Home':
        break;
      case 'ArrowUp':
        inptRtRef.current.focus();    //前の項目に遷移
        break;
      case 'ArrowDown':
        inputNxRef.current.focus();   //次の項目に遷移
        break;
      default:
        break;
    }
  };

  // ヘッダ部１入力　区分(初期カーソル)
  const onKeyDown_KBN = async(inputRef, inputNxRef,header,instart = 0) => {
    if(!(inputRef.current.value === '1' || inputRef.current.value === '2')){
      setKBNclr('#f59d9d');
      window.alert('区分入力エラー'); //TCGE0061：
      inputRef.current.focus();
      return 1;
    }

    // if(inputRef.current.value === '2') {
    //   const header = JSON.parse(JSON.stringify(headerItem));
    //   header.UIR_MISE = state.MISES;
    //   header.UIR_MISEMEI = state.MISEMEI;
    //   setHeaderItem(header);
    // }

    // 区分チェック
    if(inputRef.current.value === '1'){
      //「１」の場合、画面.発送ＡＵと画面.受取ＡＵに「000」を設定し、非活性にする。
      // const header = JSON.parse(JSON.stringify(headerItem));
      // header.ASTCDE = '000';
      // header.UIR_ASTCDE = '000';
      // header.UIR_MISE = '';
      // setHeaderItem(header);
      header.ASTCDE = '000';
      header.UIR_ASTCDE = '000';
      setDisASTCDE(true);
      setDisUIRASTCDE(true);
      if (instart !== 1){
        header.UIR_MISE = '';
        // setHeaderItem(HeaderItem => ({...HeaderItem,ASTCDE:'000'}));
        // setHeaderItem(HeaderItem => ({...HeaderItem,UIR_ASTCDE:'000'}));
        // setHeaderItem(HeaderItem => ({...HeaderItem,UIR_MISE:''}));
        // setHeaderItem(HeaderItem => ({...HeaderItem,UIR_MISEMEI:''}));
        setHeaderItem(header);
      }
    } else if(inputRef.current.value === '2'){
      //「２」の場合、画面.発送ＡＵと画面.受取ＡＵは未設定とし、活性化させる。
      // const header = JSON.parse(JSON.stringify(headerItem));
      // header.ASTCDE = '';
      // header.UIR_ASTCDE = '';
      // header.UIR_MISE = state.MISES;
      // header.UIR_MISEMEI = state.MISEMEI;
      // setHeaderItem(header);
      if (instart !== 1){
        header.ASTCDE = '';
        header.UIR_ASTCDE = '';
        header.UIR_MISE = state.MISES;
        header.UIR_MISEMEI = state.MISEMEI;
        // setHeaderItem(HeaderItem => ({...HeaderItem,ASTCDE:''}));
        // setHeaderItem(HeaderItem => ({...HeaderItem,UIR_ASTCDE:''}));
        // setHeaderItem(HeaderItem => ({...HeaderItem,UIR_MISE:state.MISES}));
        // setHeaderItem(HeaderItem => ({...HeaderItem,UIR_MISEMEI:state.MISEMEI}));
        setHeaderItem(header);
      }
      setDisASTCDE(false);
      setDisUIRASTCDE(false);
    }

    // 次の項目に遷移
    if (instart === 0)
    {
      inputNxRef.current.focus();
    }
    return 0;
  }

  // ヘッダ部１入力 部門CD
  const onKeyDown_BMNCDE = async(inputRef, inputNxRef,header,instart = 0) => {
    // const header = JSON.parse(JSON.stringify(headerItem));
    const row = JSON.parse(JSON.stringify(rowItem));
    row.SANTI_KBN = '';                // 産地区分
    row.TODOFUKEN_KUNI = '';           // 都道府県国
    row.GENSANTI = '';                 // 原産地
    row.SKHLBLKBN = '';                // 食品区分
    setRowItem(row);
    header.BMNMEI = '';
    // setHeaderItem(HeaderItem => ({...HeaderItem,BMNMEI:''}));
    // 入力チェック
    if(inputRef.current.value === ''){
      setBMNCDEclr('#f59d9d');
      window.alert('部門コードを入力して下さい。'); //TCGE0019：
      setHeaderItem(header);
      setPrefectureList([]);
      setPrefectureList2(0);
      inputRef.current.focus();
      return 1;
    }

    // 入力値の前0詰め
    let bmncde = inputRef.current.value.padStart(4, '0');
    header.BMNCDE = bmncde;
    setHeaderItem(HeaderItem => ({...HeaderItem,BMNCDE:bmncde}));

    // 部門コード取得
    const getBMNCode = await fetch('/MR_SHP_MST_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ KSACDE: state.KSACDES, MISE:state.MISES, BMNCDE:bmncde, RCNT:0 })});
    const j_BMNC = await getBMNCode.json();
    if(j_BMNC.RCNT === 0){
      setBMNCDEclr('#f59d9d');
      window.alert('部門コードは存在しません。'); //TCGE0007：
      setHeaderItem(header);
      setPrefectureList([]);
      setPrefectureList2(0);
      inputRef.current.focus();
      return 1;
    }

    // 部門名取得
    const getBMNMEI = await fetch('/MR_PRD_CLS_MST_sel',{method: 'POST', headers:{'Content-Type': 'application/json'},
      body: JSON.stringify({ KSACDE: state.KSACDES, BMNCDE:bmncde, ASTCDE:'XXX', RCNT:0 })});
    const j_BMNN = await getBMNMEI.json();
    if(j_BMNN.RCNT === 0){
      setBMNCDEclr('#f59d9d');
      window.alert('部門名は存在しません。'); //TCGE0007：
      setHeaderItem(header);
      setPrefectureList([]);
      setPrefectureList2(0);
      inputRef.current.focus();
      return 1;
    }

    header.BMNMEI = j_BMNN.BRUMEI;
    setHeaderItem(header);
    // setHeaderItem(HeaderItem => ({...HeaderItem,BMNMEI:j_BMNN.BRUMEI}));

    // 都道府県国取得
    const getPrefectureList = await fetch('/TCGT0110/getPrefectureList',{method: 'POST', headers:{'Content-Type': 'application/json'},
      body: JSON.stringify({ BMNCDE: bmncde})});
      const prefecture = await getPrefectureList.json();
      // const prefectureList = await getPrefectureList.json();
      // setPrefectureList(prefectureList.ret);
    if(prefecture.ret.length > 0){
      let unselected = {value:'　',label:'未選択'};
      setPrefectureList([unselected, ...prefecture.ret]);
      if (bmncde === '1110' || bmncde === '1120')
      {
        setPrefectureList2(0);
      }else{
        setPrefectureList2(1);
      }
    }else{
      setPrefectureList([]);
      setPrefectureList2(0);
    }

    if (instart === 0)
    {
      if (inputNxRef.current.disabled) {
        // 発送AUは無効状態なので、発送日にフォーカス移動させる
        inHASDD.current.focus(); // 次の項目に遷移
      } else {
        inputNxRef.current.focus(); // 次の項目に遷移
      }
    }
    return 0;
  }

  // ヘッダ部１入力 発送ＡＵ
  const onKeyDown_ASTCDE = async(inputRef, inputNxRef,header,instart = 0) => {
    // const header = JSON.parse(JSON.stringify(headerItem));
    header.ASTMEI = '';
    // setHeaderItem(HeaderItem => ({...HeaderItem,ASTMEI:''}));
    let Astcde = '';

    if(header.KBN === '2'){
      // 区分が「２」の場合、以下のチェックを行う
      // 必須チェック
      if(inputRef.current.value === ''){
        setASTCDEclr('#f59d9d');
        window.alert('発送ＡＵを入力して下さい。'); //TCGE0019：
        inputRef.current.focus();
        return 1;
      }

      // 桁数チェック
      if(inputRef.current.value.length < 3){
        Astcde = inputRef.current.value.padStart(3, '0');
        header.ASTCDE = Astcde;
        // setHeaderItem(header);
        // setHeaderItem(HeaderItem => ({...HeaderItem,ASTCDE:Astcde}));
      }else
      {
        Astcde = inputRef.current.value;
      }

      // ＡＵ存在チェック
      const getAUNAME = await fetch('/TCGT0110/getAUNAME',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ KSACDE:header.KSACDE, BMNCDE:header.BMNCDE, ASTCDE:Astcde })});
      const AUNAME = await getAUNAME.json();
      if(AUNAME.RCNT === 0) {
        setASTCDEclr('#f59d9d');
        window.alert('ＡＵ入力エラー'); //TCGE0034：
        setHeaderItem(header);
        inputRef.current.focus();
        return 1;
      }

      header.ASTMEI = AUNAME.AUMEI;          // 商品分類名（漢字）
      setHeaderItem(header);
      // setHeaderItem(HeaderItem => ({...HeaderItem,ASTMEI:AUNAME.AUMEI}));
    }
    if (instart === 0)
    {
      inUIRBMNCDE.current.focus();  // 受取部門に遷移
    }
    return 0;
  }

  // ヘッダ部１入力 発送日
  const onKeyDown_HASDD = async(inputRef, inputNxRef,header,instart = 0) => {
    // 入力チェック
    if(inputRef.current.value === ''){
      setHASDDclr('#f59d9d');
      window.alert('TCGE0019:日付を入力してください。');
      inputRef.current.focus();
      return 1;
    }

    // 日付正規チェック
    const strInputDate = inputRef.current.value;
    const InputDate = new Date(strInputDate);

    if (ckDate(strInputDate)===false){
      setHASDDclr('#f59d9d');
      window.alert('日付入力エラー'); //TCGE0016：
      inputRef.current.focus();
      return 1;
    }

    // 日付範囲チェック
    let maxdate = new Date(sdate);
    maxdate.setDate(maxdate.getDate() + 14);    // 2023/11/22 2週間後まで入力可能に変更
    let mindate = new Date(sdate);
    mindate.setMonth(mindate.getMonth()-2);
    if((maxdate < InputDate) || (mindate > InputDate)){
      setHASDDclr('#f59d9d');
      window.alert('日付入力エラー'); //TCGE0016：
      inputRef.current.focus();
      return 1;
    }

    if (instart === 0)
    {
      if(header.KBN === '2'){
        inUIRBMNCDE.current.focus();  // 受取部門に遷移
        return 0;
      }
      inputNxRef.current.focus(); //次の項目に遷移
    }
    return 0;
  }

  // ヘッダ部１入力 受取店
  const onKeyDown_UIRMISE = async(inputRef, inputNxRef, header, instart = 0) => {
    // 入力チェック
    //const header = JSON.parse(JSON.stringify(headerItem));
    if(inputRef.current.value === ''){
      setUIRMISEclr('#f59d9d');
      window.alert('TCGE0019:受取店コードを入力してください。');
      // setHeaderItem(header);
      await inputRef.current.focus(); //次の項目に遷移
      return 1;
    }

    let luir_mise = inputRef.current.value.padStart(3, '0');
    header.UIR_MISE = luir_mise;
    // setHeaderItem(HeaderItem => ({...HeaderItem,UIR_MISE:luir_mise}));

    // 店名取得
    const getMISEName = await fetch('/TCGT0110/getMISEName',{method: 'POST', headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ KSACDE:header.KSACDE, MISE:luir_mise, HASDD:header.HASDD })});
    const j_MISEName = await getMISEName.json();

    if (j_MISEName.RCNT === 0) {
      setUIRMISEclr('#f59d9d');
      window.alert('店コード入力エラー'); //TCGE0010：
      setHeaderItem(header);
      await inputRef.current.focus(); //次の項目に遷移
      return 1;
    }

    header.UIR_MISEMEI =j_MISEName.MISEMEI;
    setHeaderItem(header);
    // setHeaderItem(HeaderItem => ({...HeaderItem,UIR_MISEMEI:j_MISEName.MISEMEI}));
    if (instart === 0)
    {
      await inputNxRef.current.focus(); //次の項目に遷移
    }
    return 0;
  }

  // ヘッダ部１入力 受取部門
  const onKeyDown_UIRBMNCDE = async(inputRef, inputNxRef,header,instart = 0) => {
    // 入力チェック
    // const header = JSON.parse(JSON.stringify(headerItem));
    if(inputRef.current.value === ''){
      setUIRBMNCDEclr('#f59d9d');
      window.alert('部門コードを入力してください。'); //TCGE0019:
      // setHeaderItem(header);
      await inputRef.current.focus(); //次の項目に遷移
      return 1;
    }

    if(header.KBN === '1'
    && header.MISE === header.UIR_MISE){
      setUIRBMNCDEclr('#f59d9d');
      window.alert('発送店と同じ受取店は入力できません。'); //TCGE0082：
      await inputRef.current.focus(); //次の項目に遷移
      return 1;
    }else if(header.KBN === '2'
    && header.MISE !== header.UIR_MISE){
      setUIRBMNCDEclr('#f59d9d');
      window.alert('発送店と受取店が異なります。'); //TCGE0083：
      await inputRef.current.focus(); //次の項目に遷移
      return 1;
    }

    // 入力値の前0詰め
    let luir_bmncde  = inputRef.current.value.padStart(4, '0');
    header.UIR_BMNCDE = luir_bmncde;
    // setHeaderItem(HeaderItem => ({...HeaderItem,UIR_BMNCDE:luir_bmncde}));

    // 部門間チェック
    if(header.KBN === '2'
    && header.BMNCDE === luir_bmncde){
      setUIRBMNCDEclr('#f59d9d');
      window.alert('同一部門への店内移動は出来ません。'); //TCGE0084：
      setHeaderItem(header);
      await inputRef.current.focus(); //次の項目に遷移
      return 1;
    }

    // 部門コードチェック
    const getBMNMEI = await fetch('/MR_SHP_MST_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ KSACDE:header.KSACDE, MISE:header.UIR_MISE, BMNCDE:luir_bmncde })});
    const j_BMNMEI = await getBMNMEI.json();
    if((j_BMNMEI.RCNT === 0)){
      setUIRBMNCDEclr('#f59d9d');
      window.alert('部門コードは存在しません。'); //TCGE0007：
      setHeaderItem(header);
      await inputRef.current.focus(); //次の項目に遷移
      return 1;
    }

    // 部門名取得
    const getItemName = await fetch('/MR_PRD_CLS_MST_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ KSACDE:header.KSACDE, BMNCDE:header.UIR_BMNCDE, ASTCDE:'XXX' })});
    const j_ItemName = await getItemName.json();
    if(j_ItemName.RCNT === 0){
      window.alert('部門コードは存在しません。'); //TCGE0007：
      setHeaderItem(header);
      await inputRef.current.focus(); //次の項目に遷移
      return 1;
    }
    header.UIR_BMNMEI = j_ItemName.BRUMEI;
    setHeaderItem(header);
    // setHeaderItem(HeaderItem => ({...HeaderItem,UIR_BMNMEI:j_ItemName.BRUMEI}));
    let ret = interdepartmentalCheck(inputRef, inputNxRef);
    return ret;
  }

  // ヘッダ部１入力 部門間チェック
  const interdepartmentalCheck = async(inputRef, inputNxRef) => {
    let uir_bmncde = headerItem.UIR_BMNCDE.padStart(4, '0');
    // if(headerItem.BMNCDE !== uir_bmncde){
    //   // 部門間移動許可マスタをチェック
    //   const getInterdepartmental = await fetch('/TCGT0110/getInterdepartmental',{method: 'POST', headers: {'Content-Type': 'application/json'},
    //   body: JSON.stringify({ MISE:headerItem.MISE, BMNCDE:headerItem.BMNCDE, UIR_MISE:headerItem.UIR_MISE, UIR_BMNCDE:uir_bmncde })});
    //   const InterdepartmentalCount = await getInterdepartmental.json();
    //   if (InterdepartmentalCount.RCNT === 0) {
    //     setUIRBMNCDEclr('#f59d9d');
    //     window.alert('TCGE0035：移動できません。');
    //     inputRef.current.focus(); // 次の項目に遷移
    //     return 1;
    //   }
    // let ApplFlg = 0
    // // 部門間チェック１
    // console.log('部門間チェック１ 発送側検索');
    // // 部門間移動許可マスタをチェック 発送店+発送分類CD
    // const getInterdepartmental = await fetch('/TCGT0110/getInterdepartmental',{method: 'POST', headers: {'Content-Type': 'application/json'},
    // body: JSON.stringify({ has_mise:headerItem.MISE,has_bmncde:headerItem.BMNCDE })});
    // const InterdepartmentalCount = await getInterdepartmental.json();
    // if (InterdepartmentalCount.RCNT !== 0){
    //   for (let i = 0; i<InterdepartmentalCount.RCNT; i++){
    //     // 受取分類CDと取得情報を比較
    //     if (uir_bmncde === InterdepartmentalCount.res[i].UIR_BMNCDE){
    //       ApplFlg = 1;
    //       break;
    //     }
    //   }
    //   if (ApplFlg !== 1){
    //     window.alert('TCGE0035：移動できません。');
    //     setUIRBMNCDEclr('#f59d9d');
    //     return 1;
    //   }
    // }else{
    //   // 部門間チェック２
    //   console.log('部門間チェック２ 受取側検索');
    //   // 部門間移動許可マスタをチェック 受取店+受取分類CD
    //   const getInterdepartmental = await fetch('/TCGT0110/getInterdepartmental',{method: 'POST', headers: {'Content-Type': 'application/json'},
    //   body: JSON.stringify({ has_mise:headerItem.UIR_MISE,has_bmncde:uir_bmncde })});
    //   const InterdepartmentalCount = await getInterdepartmental.json();
    //   if (InterdepartmentalCount.RCNT !== 0){
    //     for (let i = 0; i<InterdepartmentalCount.RCNT; i++){
    //       // 発送分類CDと取得情報を比較
    //       if (headerItem.BMNCDE === InterdepartmentalCount.res[i].UIR_BMNCDE){
    //         ApplFlg = 1;
    //         break;
    //       }
    //     }
    //     if (ApplFlg !== 1){
    //       window.alert('TCGE0035：移動できません。');
    //       setUIRBMNCDEclr('#f59d9d');
    //       return 1;
    //     }
    //   }else{
    //     if(headerItem.KBN === '1'){
    //       // 部門間チェック３
    //       console.log('部門間チェック３ 同一部門');
    //       // 部門間移動許可マスタに登録されていない発送店、受取店のため、発送分類CD ＝ 受取分類CD以外はエラー
    //       if (headerItem.BMNCDE !== uir_bmncde){
    //           window.alert('TCGE0035：移動できません。');
    //           setUIRBMNCDEclr('#f59d9d');
    //           return 1;
    //       }
    //     }else{
    //       // 部門間チェック４
    //       // 店内移動
    //       console.log('部門間チェック４ MSE検索');
    //       // 部門間移動許可マスタをチェック 'MSE'+発送分類CD
    //       const getInterdepartmental = await fetch('/TCGT0110/getInterdepartmental',{method: 'POST', headers: {'Content-Type': 'application/json'},
    //       body: JSON.stringify({ has_mise:'MSE',has_bmncde:headerItem.BMNCDE })});
    //       const InterdepartmentalCount = await getInterdepartmental.json();
    //       console.log('件数:' + InterdepartmentalCount.RCNT);
    //       if (InterdepartmentalCount.RCNT !== 0){
    //       for (let i = 0; i<InterdepartmentalCount.RCNT; i++){
    //           // 発送分類CDと取得情報を比較
    //           if (uir_bmncde === InterdepartmentalCount.res[i].UIR_BMNCDE){
    //             ApplFlg = 1;
    //             break;
    //           }
    //         }
    //         if (ApplFlg !== 1){
    //           window.alert('TCGE0035：移動できません。');
    //           setUIRBMNCDEclr('#f59d9d');
    //           return 1;
    //         }
    //       }else{
    //         window.alert('TCGE0035：移動できません。');
    //         setUIRBMNCDEclr('#f59d9d');
    //         return 1;
    //       }
    //     }
    //   }
    // }
    // 2023/10/08 店間移動チェック修正 End

    // 店内移動時（発送店＝受取店）の処理
    if(headerItem.KBN === '2'){
      // 分類CD（発送店側）≠分類CD（受取店側）でなければエラー
      if(headerItem.BMNCDE == uir_bmncde){
        setUIRBMNCDEclr('#f59d9d');
        window.alert('同一部門への店内移動は出来ません。'); //TCGE0084：
        return 1;
      }

      let idoBmnList = ['1110','1120','1125','1130','1131','1140','1150','1210','1230'];
      console.log(idoBmnList.findIndex(idoBmn => idoBmn === headerItem.BMNCDE));
      console.log(idoBmnList.findIndex(idoBmn => idoBmn === uir_bmncde));

      if(idoBmnList.findIndex(idoBmn => idoBmn === headerItem.BMNCDE) == -1
      || idoBmnList.findIndex(idoBmn => idoBmn === uir_bmncde) == -1)
      {
        setUIRBMNCDEclr('#f59d9d');
        window.alert('移動できません。');//TCGE0084：
        return 1;
      }
    }


    // 店外移動時（発送店≠受取店）の処理
    else{
      // 部門間移動許可マスタ　発送店存在チェック
      console.log('部門間チェック１ 発送店存在チェック');
      const responseHas1 = await fetch('/TCGT0110/existsHasMise',{method: 'POST', headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ has_mise:headerItem.MISE })});
      const dataHas1 = await responseHas1.json();
      let has_mise = '';
      if (dataHas1.RCNT === 0) {
        has_mise = 'MIS';
      }else{
        has_mise = headerItem.MISE;
      }

      // 部門間移動許可マスタ　受入店存在チェック
      console.log('部門間チェック１ 受入店存在チェック');
      const responseUir1 = await fetch('/TCGT0110/existsUirMise',{method: 'POST', headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ uir_mise:headerItem.UIR_MISE })});
      const dataUir1 = await responseUir1.json();
      let uir_mise = '';
      if (dataUir1.RCNT === 0) {
        uir_mise = 'MIS';
      }else{
        uir_mise = headerItem.UIR_MISE;
      }


      // 2023/12/06 店間移動時　発送、受取共に部門間移動許可マスタに存在しない場合は、部門＆AUが一致しなければならない
      if ((has_mise === 'MIS' && uir_mise === 'MIS'))
      {
        if (headerItem.BMNCDE !== uir_bmncde)
        {
          window.alert('移動できません。'); //TCGE0035：
          setUIRBMNCDEclr('#f59d9d');
          return 1;
        }
      }else{

        // 部門間チェック１
        console.log('部門間チェック１ 発送側検索');
        // 部門間移動許可マスタをチェック
        const responseA1 = await fetch('/TCGT0110/getInterdepartmental',{method: 'POST', headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ has_mise:has_mise, has_bmncde:headerItem.BMNCDE, uir_mise:uir_mise, uir_bmncde:uir_bmncde })});
        const dataA1 = await responseA1.json();
        if (dataA1.RCNT === 0){
          // 部門間チェック２
          console.log('部門間チェック２ 受取側検索');
          // 部門間移動許可マスタをチェック
          const responseA2 = await fetch('/TCGT0110/getInterdepartmental',{method: 'POST', headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ has_mise:uir_mise, has_bmncde:uir_bmncde, uir_mise:has_mise, uir_bmncde:headerItem.BMNCDE })});
          const dataA2 = await responseA2.json();
          if (dataA2.RCNT === 0){
            // 部門間チェック３
            console.log('部門間チェック３ 同一部門');
            // 部門間移動許可マスタに登録されていない発送店、受取店のため、発送分類CD ＝ 受取分類CD以外はエラー
            if (headerItem.BMNCDE !== uir_bmncde){
              window.alert('移動できません。'); //TCGE0035：
              setUIRBMNCDEclr('#f59d9d');
              return 1;
            }
          }
        }
      }
    }
    if (inputNxRef.current.disabled) {
      // 受取AUは無効状態なので、掛率にフォーカス移動させる
      inKAKEZEIRTU.current.focus(); // 次の項目に遷移
    } else {
      inputNxRef.current.focus(); // 次の項目に遷移
    }
    return 0;
  }

  // 明細データ抽出
  const getMoveDate =  async(header) => {
    // const header = JSON.parse(JSON.stringify(headerItem));
    header.SRYFOT = 0;   // 合計数量
    header.GNKFOT = 0;   // 原価金額合計
    header.BAKFOT = 0;   // 売価金額合計
    setHeaderItem(header);
    // setHeaderItem(HeaderItem => ({...HeaderItem,SRYFOT:0}));
    // setHeaderItem(HeaderItem => ({...HeaderItem,GNKFOT:0}));
    // setHeaderItem(HeaderItem => ({...HeaderItem,BAKFOT:0}));
    setRowItem(rowData);
    setRows([rowData]);

  };

  // ヘッダ部１入力 受取ＡＵ
  const onKeyDown_UIRASTCDE = async(inputRef, inputNxRef,header,instart = 0) => {
    //const header = JSON.parse(JSON.stringify(headerItem));
    let Astcde = '';

    if(header.KBN === '2'){
      // 区分が「２」の場合、以下のチェックを行う
      // 必須チェック
      if(inputRef.current.value === ''){
        setUIRASTCDEclr('#f59d9d');
        window.alert('受取ＡＵを入力して下さい。'); //TCGE0019：
        return;
      }

      // 桁数チェック
      Astcde = inputRef.current.value;
      if(inputRef.current.value.length < 3){
        Astcde = inputRef.current.value.padStart(3, '0');
        header.UIR_ASTCDE = Astcde;
        //setHeaderItem(header);
      }

      // ＡＵ存在チェック
      const getAUNAME = await fetch('/TCGT0110/getAUNAME',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ KSACDE:header.KSACDE, BMNCDE:header.UIR_BMNCDE, ASTCDE:Astcde })});
      const AUNAME = await getAUNAME.json();
      if(AUNAME.RCNT === 0) {
        setASTCDEclr('#f59d9d');
        window.alert('ＡＵ入力エラー'); //TCGE0034：
        return;
      }

      header.UIR_ASTMEI = AUNAME.AUMEI;          // 商品分類名（漢字）
      setHeaderItem(header);
    }

    inputNxRef.current.focus();  // 受取部門に遷移
    return;
  }

  // 掛率
  const onKeyDown_KAKEZEIRTU = async(header) => {
    //const header = JSON.parse(JSON.stringify(headerItem));
    // 掛け率対応　濱田課長待ち
    if (header.KAKEZEIRTU !== '')
    {
      if (Number(header.KAKEZEIRTU) <= 30)
      {
        if(!(window.confirm('30％以下の値が入力されていますがよろしいですか？\r\n掛率は消費税率ではありません。'))) { //TCGM0007：
          return;
        }  
      }  
    }

    let ret = await Header1_confirmed(header);
    if(ret === 0)
    {
      await getMoveDate(header);
      setScreenPhase(2)
    }
  }

  // ヘッダ１部確定
  const Header1_confirmed  = async (header) =>　{
    console.log('ヘッダー項目チェック開始');
    let ret = await onKeyDown_KBN(inKBN,inBMNCDE,header,1);
    // console.log('onKeyDown_KBN ret:%o',ret);
    if (ret === 1){ return 1; }
    ret = await onKeyDown_BMNCDE(inBMNCDE,inASTCDE,header,1);
    if (ret === 1){ return 1; }
    ret = await onKeyDown_ASTCDE(inASTCDE,inHASDD,header,1);
    if (ret === 1){ return 1; }
    ret = await onKeyDown_HASDD(inHASDD,inUIRMISE,header,1);
    if (ret === 1){ return 1; }
    ret = await onKeyDown_UIRMISE(inUIRMISE,inUIRBMNCDE,header,1);
    if (ret === 1){ return 1; }
    ret = await onKeyDown_UIRBMNCDE(inUIRBMNCDE,inUIRASTCDE,header,1);
    if (ret === 1){ return 1; }
    ret = await onKeyDown_UIRASTCDE(inUIRASTCDE,inKAKEZEIRTU,header,1);
    if (ret === 1){ return 1; }

    return 0;

  }

  // フッタ部　取消
  const onClick_Reset = async () => {
    if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))) { //TCGM0007：
      return;
    }

    setRowItem(rowData);
    setRows([]);
    setHeaderItem(headerData);
    setHeaderHASDD();
    inBMNCDE.current.focus();
  }

  // フッタ部　次へ
  const onClick_Next = async () => {
    const header = JSON.parse(JSON.stringify(headerItem));
    let ret = await Header1_confirmed(header);
    if (ret === 0)
    {
      await getMoveDate(header);
      setScreenPhase(2)
    }
  }

  return (
    <Grid>
      <br />
      <Stack direction='row' >
        <NumericFormat  //区分
          id={'KBN'}
          inputRef={inKBN}                    //項目名定義？
          color='success'
          autoFocus                           //初期カーソル位置設定
          thousandSeparator={false}           //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='区分'                        //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width:'5ch', mr:'10px', background:inKBNclr}}                  //textboxの幅
          value={headerItem.KBN}              //初期値
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={false}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          onChange={(e)=>{
            const header = JSON.parse(JSON.stringify(headerItem));
            header.KBN = e.target.value;
            setHeaderItem(header);
          }}
          onKeyDown={(e) => onKeyDown(e.key,inKBN,inKBN,inBMNCDE)}
          onFocus={(e) => onMyFocus(e)}
        />
        <InputLabel variant={'outlined'}>{'（1：店間　2：店内）'}</InputLabel>
      </Stack>
      <br />
      <Stack direction='row'>
        <NumericFormat  //部門CD
          id={'BMNCDE'}
          inputRef={inBMNCDE}                 //項目名定義？
          color='success'
          thousandSeparator={false}           //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='発送部門'                    //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '8ch', background:inBMNCDEclr}}                 //textboxの幅
          value={headerItem.BMNCDE}
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={false}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          onChange={(e)=>{
            const header = JSON.parse(JSON.stringify(headerItem));
            header.BMNCDE = e.target.value;
            setHeaderItem(header);
          }}
          onKeyDown={(e) => onKeyDown(e.key,inBMNCDE,inKBN,inASTCDE)}
          onFocus={(e) => {
            if (inBMNCDE.current !==null && typeof inBMNCDE.current !== 'undefined')
            {
              onMyFocus(e);
            }
          }}
        />
        <InputLabel variant={'outlined'}>{headerItem.BMNMEI}</InputLabel>
      </Stack>
      <br />
      <Stack direction='row'>
        <NumericFormat  //発送AU
          id={'ASTCDE'}
          inputRef={inASTCDE}                   //項目名定義？
          color='success'
          thousandSeparator={false}           //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='発送AU'                       //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '8ch',background:inASTCDEclr}}                 //textboxの幅
          value={headerItem.ASTCDE}
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={disASTCDE}                //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 3,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          onChange={(e)=>{
            const header = JSON.parse(JSON.stringify(headerItem));
            header.ASTCDE = e.target.value;
            setHeaderItem(header);
          }}
          onKeyDown={(e) => onKeyDown(e.key,inASTCDE,inBMNCDE,inHASDD)}
          onFocus={(e) => onMyFocus(e)}
          />
        <InputLabel variant={'outlined'}sx={{mr:'30px'}}>{ headerItem.ASTMEI }</InputLabel>
      </Stack>
      <br />
      <Stack direction='row' spacing={3}>
        <PatternFormat  //発送日
          id={'HASDD'}
          inputRef={inHASDD}                  //項目名定義？
          format='####/##/##'
          mask={['Y','Y','Y','Y','M','M','D','D']}
          color='success'
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='発送日'                      //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          onValueChange={(values, sourceInfo) => {
            const header = JSON.parse(JSON.stringify(headerItem));
            header.HASDD = values.value;
            header.HASDD2 = values.formattedValue;
            setHeaderItem(header);
          }}
          sx={{width: '13ch',background:inHASDDclr}}                //textboxの幅
          value={headerItem.HASDD2}           //初期値
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={false}                    //使用可／不可
          placeholder='YYYY/MM/DD'
          inputProps={{ autoComplete: 'off',maxLength: 11, style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          onKeyDown={(e) => onKeyDown(e.key,inHASDD,inBMNCDE,inUIRMISE)}
          onFocus={(e) => onMyFocus(e)}
        />
      </Stack>
      <br />
      <Stack direction='row'>
        <NumericFormat  //受取店コード
          id={'UIRMISE'}
          inputRef={inUIRMISE}               //項目名定義？
          color='success'
          thousandSeparator={false}           //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='受取店'                      //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '8ch',background:inUIRMISEclr}}                 //textboxの幅
          value={headerItem.UIR_MISE}         //初期値
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={false}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 3,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          onChange={(e)=>{
            const header = JSON.parse(JSON.stringify(headerItem));
            header.UIR_MISE = e.target.value;
            setHeaderItem(header);
          }}
          onKeyDown={(e) => onKeyDown(e.key,inUIRMISE,inHASDD,inUIRBMNCDE)}
          onFocus={(e) => {
            console.log(inUIRMISE.current);
            if (inUIRMISE.current !==null && typeof inUIRMISE.current !== 'undefined')
            {
              onMyFocus(e);
            }
          }}
        />
        <InputLabel variant={'outlined'}>{ headerItem.UIR_MISEMEI}</InputLabel>
      </Stack>
      <br />
      <Stack direction='row'>
        <NumericFormat  //受取部門コード
          id={'UIRBMNCDE'}
          inputRef={inUIRBMNCDE}             //項目名定義？
          color='success'
          thousandSeparator={false}           //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='受取部門'                    //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '8ch',background:inUIRBMNCDEclr}}                  //textboxの幅
          value={headerItem.UIR_BMNCDE}       //初期値
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={false}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          onChange={(e)=>{
            const header = JSON.parse(JSON.stringify(headerItem));
            header.UIR_BMNCDE = e.target.value;
            setHeaderItem(header);
          }}
          onKeyDown={(e) => onKeyDown(e.key,inUIRBMNCDE,inUIRMISE,inUIRASTCDE)}
          onFocus={(e) => {
            if (inUIRBMNCDE.current !==null && typeof inUIRBMNCDE.current !== 'undefined')
            {
              onMyFocus(e);
            }
          }}
        />
        <InputLabel variant={'outlined'}>{ headerItem.UIR_BMNMEI }</InputLabel>
      </Stack>
      <br />
      <Stack direction="row">
        <NumericFormat  //受取AU
          id={'UIRASTCDE'}
          inputRef={inUIRASTCDE}                   //項目名定義？
          color='success'
          thousandSeparator={false}           //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='受取AU'                       //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '8ch',background:inUIRASTCDEclr}}                 //textboxの幅
          value={headerItem.UIR_ASTCDE}
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={disUIRASTCDE}             //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 3,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          onChange={(e)=>{
            const header = JSON.parse(JSON.stringify(headerItem));
            header.UIR_ASTCDE = e.target.value;
            setHeaderItem(header);
          }}
          onKeyDown={(e) => onKeyDown(e.key,inUIRASTCDE,inUIRBMNCDE,inKAKEZEIRTU)}
          onFocus={(e) => onMyFocus(e)}
          />
        <InputLabel variant={'outlined'}sx={{mr:'30px'}}>{ headerItem.UIR_ASTMEI }</InputLabel>
      </Stack>
      <br />
      <Stack direction='row'>
        <NumericFormat  //掛率
          id={'KAKEZEIRTU'}
          inputRef={inKAKEZEIRTU}             //項目名定義？
          color='success'
          thousandSeparator={false}           //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='掛率'                        //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={true}            //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '8ch'}}                 //textboxの幅
          value={headerItem.KAKEZEIRTU}       //初期値
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={false}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 5,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          isAllowed={(values) => {
            const numValue = Number(values.value);
            if (isNaN(numValue)) return true;
            return numValue >= 0 && numValue <= 100;
          }}
          onChange={(e)=>{
            const header = JSON.parse(JSON.stringify(headerItem));
            header.KAKEZEIRTU = e.target.value;
            setHeaderItem(header);
          }}
          onKeyDown={(e) => onKeyDown(e.key,inKAKEZEIRTU,inUIRASTCDE,inKAKEZEIRTU)}
          // onFocus={(e) => setTimeout(() => {inKAKEZEIRTU.current.select();},100)}
        />
        <InputLabel variant={'outlined'} sx={{mr:'10px'}}>{ '%' }</InputLabel>
        <InputLabel variant={'outlined'} sx={{mr:'5px',mt:'-20px',fontSize:12}}>掛率＝入力された値を売単価に掛けて原単価を<br/>　　　計算します。<br/>
              　　　衣料品の何掛けで移動する等で使用<br/>　　　※消費税率ではありません。</InputLabel>
      </Stack>
      <br /><br /><br /><br /><br /><br /><br />
      <Stack direction='row' spacing={0}>
        <Button
          variant='contained'
          color='primary'
          sx={{width:80}}
          onClick={(e) => navigate('/TCSA0005') }
          >
          {'戻る'}
        </Button>&nbsp;
        <Button
          variant='contained'
          color='primary'
          sx={{width:80}}
          onClick={(e) => onClick_Reset()}
        >
          {'再入力'}
        </Button>&nbsp;
        <Button
          variant='contained'
          color='primary'
          sx={{width:80}}
          onClick={(e) => onClick_Next()}
        >
          {'次へ'}
        </Button>
      </Stack>
    </Grid>
  );
};

export default TCPD0131;