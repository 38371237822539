import React, { useEffect, useRef ,useState ,useContext} from 'react';
import { UserContext } from './App';
import './CustomFormTCSH0111.css'; // スタイル用のCSSをインポート

function CustomFormTCSH0111(props) {
  const {state, dispatch} = useContext(UserContext);

  const backButtonRef = useRef(null);
  const clearButtonRef = useRef(null);
  const okButtonRef = useRef(null);
  const returnFocus = useRef(null);
  // console.log(props.message04);
  const [selected01, setSelected01] = useState(''); // JAN1
  const [selected02, setSelected02] = useState(''); // JAN2
  const [selected03, setSelected03] = useState('');
  const [selected04, setSelected04] = useState('');
  const [selected05, setSelected05] = useState('');
  const [selected06, setSelected06] = useState('');
  const [selected07, setSelected07] = useState('');
  const [selected08, setSelected08] = useState('');
  const [selected11, setSelected11] = useState(''); // 取引品番
  const [selected12, setSelected12] = useState(''); // サイズ
  const [selected13, setSelected13] = useState(''); // カラー
  const [selected14, setSelected14] = useState(''); // シーズン
  const [selected15, setSelected15] = useState(''); // 商品ID
  const [selectedEos, setSelectedEos] = useState(''); // Eos

  const inJAN1 = useRef(null);    // JAN1
  const inJAN2 = useRef(null);    // JAN2
  const inJotai = useRef(null);   // 発注状態
  const inChnry = useRef(null);   // 基準在庫
  // const inHacsu = useRef(null);   // 最低発注数
  const inTnpzik = useRef(null);  // 店舗在庫
  const inTrihbn = useRef(null);  // 取引品番
  const inSizmei = useRef(null);  // サイズ
  const inColmei = useRef(null);  // カラー
  const inSznmei = useRef(null);  // シーズン

  // エラーチェック用 0:エラーなし, 1:未チェック, 2:エラー, 9:確認中
  const [chkflg,setChkflg] = useState(0);               // チェックフラグ
  const [regflg,setRegflg] = useState(0);               // チェックフラグ

  //disabled制御用
  const [dissel01, setDisSel01] = useState(false);
  const [dissel02, setDisSel02] = useState(true);      // JAN2
  const [dissel04, setDisSel04] = useState(true);       // 発注状態
  const [dissel05, setDisSel05] = useState(false);
  const [dissel06, setDisSel06] = useState(false);
  const [dissel07, setDisSel07] = useState(false);
  const [dissel11, setDisSel11] = useState(true);       // 取引品番
  const [dissel12, setDisSel12] = useState(true);       // サイズ
  const [dissel13, setDisSel13] = useState(true);       // カラー
  const [dissel14, setDisSel14] = useState(true);       // シーズン

  //項目のバックカラー制御用
  const [inJAN1clr,setJAN1clr] = useState('#FFFFFF');
  const [inJAN2clr,setJAN2clr] = useState('#FFFFFF');

  // ロストフォーカス用
  const [chkJAN1,setChkJAN1] = useState('0');
  const [chkJAN2,setChkJAN2] = useState('0');

  const rowData ={
    id : 0,                        // 行№
    KSACDE : state.KSACDES,        // 会社コード
    MISE : state.MISES,            // 店コード
    BMNCDE : state.BMNCDE,         // 部門コード
    ASTCDE : '000',                // ＡＵ
    JANCDE : '',                   // ＪＡＮコード(スキャンコード)
    JANCDE2 : '',                  // ＪＡＮコード2(スキャンコード)
    SHNMEIKN : '',                 // 商品名
    KIKAKUKN : '',                 // 規格
    SUU : 0,                       // 数量(数字)
    SUU2 : '',                     // ◇数量2(文字列)
    GNT : 0,                       // 原単価(数字)
    GNT2 : '',                     // ◇原単価2(文字列)
    GNKKIN : 0,                    // 原価金額
    BTN : 0,                       // 売単価(数字)
    BTN2 : '',                     // ◇売単価2(文字列)
    BAKKIN : 0,                    // 売価金額
    SIRCDE : '',                   // 仕入先(取引先)コード
    SIRMEIK : '',                  // 仕入先(取引先)名
    EOSCDE : '',                   // ＥＯＳコード
    EOSCDE1 : '',                  // ＥＯＳコード１便
    EOSCDE2 : '',                  // ＥＯＳコード２便
    EOSCDE3 : '',                  // ＥＯＳコード３便
    EOSCDE4 : '',                  // ＥＯＳコード４便
    TAGASTCDE : '',                // タグＡＵ
    TAGLINCDE : '',                // タグライン
    TAGCLSCDE : '',                // タグクラス
    TAGSZN : '',                   // タグシーズン
    TAGSZNNM : '',                 // タグシーズン名カナ
    TAGITM : '',                   // タグアイテム
    TRIHBN : '',                   // 取引先品番
    TAGSIZ : '',                   // サイズコード
    TAGSIZMEI : '',                // サイズ名カナ
    TAGCOLCDE : '',                // カラーコード
    TAGCOLMEI : '',                // カラー名カナ
    IRYO_FLG : '0',                // 衣料フラグ
    IN_CD_KBN : '',                // 入力コード区分
    HTTSTD : '',                   // 発注開始日
    HTTEND : '',                   // 発注終了日
    HTTTNICD : '',                 // 発注単位CD 
    HTTTNI : '',                   // 発注単位
    IRISUU : '',                   // 入数
    SYMNSU : '',                   // 賞味日数
    TOK_GNT : '',                  // 特売原価
    TOK_BTN : '',                  // 特売売価
    TEINOK1 : '',                  // 定番納期１
    TEINOK2 : '',                  // 定番納期２
    TEINOK3 : '',                  // 定番納期３
    TEINOK4 : '',                  // 定番納期４
    TEINOK5 : '',                  // 定番納期５
    TEINOK6 : '',                  // 定番納期６
    TEINOK7 : '',                  // 定番納期７
    TOKNOK1 : '',                  // 特売納期１
    TOKNOK2 : '',                  // 特売納期２
    TOKNOK3 : '',                  // 特売納期３
    TOKNOK4 : '',                  // 特売納期４
    TOKNOK5 : '',                  // 特売納期５
    TOKNOK6 : '',                  // 特売納期６
    TOKNOK7 : '',                  // 特売納期７
    NEIRI : '',                    // 値入率＝（売価-原価）÷売価×100
  }
  const [rowItem,setRowItem] = useState(rowData);

  // 表示日付フォーマット
  const formatDate = (dateString) => {
    if(dateString === null){ return dateString; }

    const fmtYear = dateString.substr(0, 4);
    const fmtMonth = dateString.substr(4, 2);
    const fmtDay = dateString.substr(6, 2);

    return `${fmtYear}/${fmtMonth}/${fmtDay}`;
  }
  
  const [nextActive, setNextActive] = useState(null);
  useEffect(() => {
    if (nextActive === 'JAN1') {
      inJAN1.current.focus();
      setNextActive('');
    }
  }, [dissel01]);

  useEffect(() => {
    if (nextActive === 'JAN2') {
      inJAN2.current.focus();
      setNextActive('');
    }
  }, [dissel02]);
  
  useEffect(() => {
    // 初期値移送
    console.log('初期値移送');
    console.log(props.message04);
    setSelected01(props.message01);
    setSelected02(props.message02);
    setSelected03(props.message03);
    setSelected04(props.message04);
    setSelected05(props.message05);
    //S SK-0051-013 2024/7/26           TCI)M.K【課題No.77：バグFIX】
    // props.handleValueChange05(props.message05);
    //E SK-0051-013
    setSelected06(props.message06);
    setSelected07(props.message07);
    //S SK-0051-014 2024/7/26           TCI)M.K【課題No.77：バグFIX】
    // props.handleValueChange05(props.message07);
    //E SK-0051-014
    setSelected08(props.message08);
    setSelected11(props.message11);
    setSelected12(props.message12);
    setSelected13(props.message13);
    setSelected14(props.message14);
    setSelected15(props.message15);

    // アラートが表示されたときにOKボタンにフォーカスをセットする
    if (props.isVisible) {
      returnFocus.current = document.activeElement;
      console.log('returnFocus_セット:',returnFocus);
      //okButtonRef.current.focus();

    } else {
      // アラートが非表示になったときに元の場所にフォーカスを戻す
      console.log('returnFocus_非表示:',returnFocus); 
      returnFocus.current && returnFocus.current.focus();
    }

    // 表示・非表示
    if (props.message04 === '02' | props.message04 === '04'){
      setDisSel05(true);
      setDisSel06(true);
      setDisSel07(true);
    }
    if (props.callergamen === 'TCSH0100' || props.callergamen === 'TCSH0130'){
      setDisSel01(true);
      console.log('陳列量フォーカス:',inChnry);
      inChnry.current.focus();
      setTimeout(() => {inChnry.current.select();}, 100);
    } else if(props.callergamen === 'TCSH0101' || props.callergamen === 'TCSH0201')
    {
      setDisSel01(true);
      console.log('陳列量フォーカス:',inChnry);
      inChnry.current.focus();
      setTimeout(() => {inChnry.current.select();}, 100);
    }
    else{
      console.log('JANフォーカス:',inJAN1);
      inJAN1.current.focus();
    }

  }, [props.isVisible]);

  const [StartTime,setStartTime] = useState(0); //開始時間
  var EndTime;          //終了時間
  const [Scanflg,setScanflg] = useState(0);     //スキャン判定 0:入力,1:スキャナー
  const [Over8,setOver8] = useState(0);         //8桁以上入力判定(スキャン判定時) 0:判定不要,1:入力チェック

  useEffect (()=>{
    if(Over8 === 1){
      setTimeout(() => {
        console.log(`８桁タイマー処理:`+Over8+'/'+inJAN1.current.value.length);
        if (Over8 === 1 && inJAN1.current.value.length === 8)
        {
          console.log(`８桁処理開始`);
          get_Jdhdat(inJAN1,inChnry);
        }
      },200);
    }
  }, [Over8]);

  // シノプス情報初期化
  const clear_sinops = () => {
    setSelected02('');    // JAN2
    setSelected03('');    // 商品名
    setSelected04('10');  // 発注状態
    setSelected05('-');   // 基準在庫
    setSelected06('-');   // 最低発注数
    setSelected07('-');   // 計算在庫
    setSelected08('-');   // 商品ランク
    setSelected11('');    // 取引品番
    setSelected12('');    // サイズ
    setSelected13('');    // カラー
    setSelected14('');    // シーズン
    setSelected15('');    // 商品ID
  }

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    // console.log(key);
    // console.log('id:',inputRef.current.id);// inputRef.current.idが拾えない為、コメント
    switch (key) {
      case 'Enter':
        switch (inputRef.current.id) {
          case 'JAN1':      // ＪＡＮ１
            clear_sinops();
            await get_Jdhdat(inputRef,inputNxRef);
            break;
          case 'JAN2':      // ＪＡＮ２
            await onKeyDown_JAN2(inputRef, inputNxRef);
            break;
          case 'select':    // 自動発注状態
            break;
          case 'Chnry':     // 最低陳列量
            break;
          case 'Hacsu':     // 最低発注数
            break;
          case 'Tnpzik':    // 店舗在庫
            break;
          default:
            break;
        }
        console.log('Enter処理後フラグ：',chkflg);
        setRegflg(0);
        if (chkflg === 0){
          // inputNxRef.current.focus();   //次の項目に遷移
          setTimeout(() => {inputNxRef.current.focus();}, 200);
          console.log('タイムラグ処理');
        }
        else{
          inputNxRef.current.focus();   //次の項目に遷移
          inputRef.current.focus();
        }
        
        break;
      case 'Home':
        break;
      case 'ArrowUp':
        break;
      case 'ArrowDown':
        break;
      default:
        break;
    }
  };  
  
  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      okButtonRef.current.focus();
    }
  };

  // 親コンポーネントから受け取った関数を使って、inputの値を渡す
  const handleChange = (event) => {
    setSelected04(event.target.value);

    // 表示・非表示
    if (event.target.value === '02' | event.target.value === '04'){
      setDisSel05(true);
      setDisSel06(true);
      setDisSel07(true);
      // TCSH0100からの起動の場合、trueにしたら、初期化する
      if (props.callergamen === 'TCSH0100'){
        setSelected05(props.message05);
        setSelected06(props.message06);
        setSelected07(props.message07);
      }
    }else{
      setDisSel05(false);
      setDisSel06(false);
      setDisSel07(false);
    }

    const value = event.target.value;
    console.log('親コンポーネントから受け取った',value);
    props.handleValueChange04(value);
  };

  function validateNumberInput (event)  {
    var input = event.target;
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  // JAN1
  const handleInputChange01 = async (event) => {
    setSelected01(event.target.value);
    const value = event.target.value;
    props.handleValueChange01(value);
    if (event.target.value.length === 0 || event.target.value.length === 1)
      {
        setStartTime(performance.now()); //詳細なミリ秒数
        console.log(`計測スタート:${event.target.value.length} / ${StartTime}`);
        setChkJAN1('0');
        await setOver8(0);
        setScanflg(0);
      }else
      {
        EndTime = performance.now(); //詳細なミリ秒数
        let elapsedTime = (EndTime - StartTime) * 1000; // マイクロ秒単位に変換
        if (isNaN(elapsedTime))
        {
          console.log(`NaN理由: ${StartTime} / ${Math.floor(EndTime)} マイクロ秒`);
        }else{
          console.log(`経過時間: ${event.target.value.length} / ${Math.floor(elapsedTime)} マイクロ秒`);
        }
        //6桁時スキャン使用判定する(400ミリ秒で判定)
        if (event.target.value.length === 5)
        {
          if (elapsedTime<400000)
          {
            console.log(`５桁スキャン判定`);
            setScanflg(1);
          }
        }
        
        if (event.target.value.length === 8 && Scanflg ===1)
        {
          setOver8(1);
          console.log(`８桁処理`);
        }
        if (event.target.value.length === 9 && Scanflg ===1)
        {
          console.log(`８桁解除`);
          setOver8(0);
        }
        if (event.target.value.length === 13)
        {
          setOver8(0);
          setScanflg(0);
          console.log(`１３桁処理開始: ${Math.floor(elapsedTime)} マイクロ秒`);
          if (elapsedTime<1000000 && event.target.value !== event.target.defaultValue)
          {
            get_Jdhdat(inJAN1,inChnry);
          }
        }
      }
  };
  // JAN2
  const handleInputChange02 = async (event) => {
    setSelected02(event.target.value);
    const value = event.target.value;
    console.log('親コンポーネントから受け取った',value);
    props.handleValueChange02(value);


    if (event.target.value.length === 0 || event.target.value.length === 1)
      {
        setStartTime(performance.now()); //詳細なミリ秒数
        console.log(`計測スタート:${event.target.value.length} / ${StartTime}`);
        setChkJAN2('0');
        await setOver8(0);
        setScanflg(0);
      }else{
        EndTime = performance.now(); //詳細なミリ秒数
        let elapsedTime = (EndTime - StartTime) * 1000; // マイクロ秒単位に変換
        if (isNaN(elapsedTime))
        {
          console.log(`NaN理由: ${StartTime} / ${Math.floor(EndTime)} マイクロ秒`);
        }else{
          console.log(`経過時間: ${event.target.value.length} / ${Math.floor(elapsedTime)} マイクロ秒`);
        }
        //6桁時スキャン使用判定する(400ミリ秒で判定)
        if (event.target.value.length === 5)
        {
          if (elapsedTime<400000)
          {
            console.log(`５桁スキャン判定`);
            setScanflg(1);
          }
        }
        
        if (event.target.value.length === 8 && Scanflg ===1)
        {
          await setOver8(1);
          console.log(`８桁処理2`);
        }
        if (event.target.value.length === 9 && Scanflg ===1)
        {
          console.log(`８桁解除2`);
          await setOver8(0);
        }
        if (event.target.value.length === 13)
        {
          setOver8(0);
          setScanflg(0);
          console.log(`１３桁処理開始2: ${Math.floor(elapsedTime)} マイクロ秒`);
          if (elapsedTime<1000000 && event.target.value !== event.target.defaultValue)
          {
            await onKeyDown_JAN2(inJAN1,inChnry);
          }
        }
      }
  };
  const handleInputChange05 = (event) => {
    setSelected05(event.target.value);
    const value = event.target.value;
    props.handleValueChange05(value);
  };
  const handleInputChange06 = (event) => {
    setSelected06(event.target.value);
    const value = event.target.value;
    props.handleValueChange06(value);
  };
  const handleInputChange07 = (event) => {
    setSelected07(event.target.value);
    const value = event.target.value;
    props.handleValueChange07(value);
  };
  const handleInputChange11 = (event) => {
    setSelected11(event.target.value);
    const value = event.target.value;
    props.handleValueChange11(value);
  };
  const handleInputChange12 = (event) => {
    setSelected12(event.target.value);
    const value = event.target.value;
    props.handleValueChange12(value);
  };
  const handleInputChange13 = (event) => {
    setSelected13(event.target.value);
    const value = event.target.value;
    props.handleValueChange13(value);
  };
  const handleInputChange14 = (event) => {
    setSelected14(event.target.value);
    const value = event.target.value;
    props.handleValueChange14(value);
  };
  const handleInputChange15 = (event) => {
    setSelected15(event.target.value);
    const value = event.target.value;
    props.handleValueChange15(value);
  };
  // const handleInputChangeEos = (event) => {
  //   setSelectedEos(event.target.value);
  //   const value = event.target.value;
  //   props.handleValueChangeEos(value);
  // };

  // 再入力ボタン　押下
  const onClick_FooterButton_Clear = async () => {
    console.log('モーダル再入力ボタン　押下');
    // メモリクリア
        
    setChkflg(0);

    // 画面クリア
    if (props.callergamen === 'TCSH0101'){
    }else{
      setSelected01(props.message01);
      setSelected02(props.message02);   // JAN2
      setSelected03(props.message03);   // 商品名
    }
    setSelected04(props.message04);
    setSelected05(props.message05);
    setSelected06(props.message06);
    setSelected07(props.message07);
    setSelected08(props.message08);
    setSelected11(props.message11);     // 取引品番
    setSelected12(props.message12);     // サイズ
    setSelected13(props.message13);     // カラー
    setSelected14(props.message14);     // シーズン
    setSelected15(props.message15);     // 商品ID

    // 表示・非表示
    if (props.callergamen === 'TCSH0101' || props.callergamen === 'TCSH0130'){
      setDisSel01(true);
      console.log('陳列量フォーカス:',inChnry);
      inChnry.current.focus();
      setTimeout(() => {inChnry.current.select();}, 100);
    }
    else{
      console.log('JANフォーカス:',inJAN1);
      inJAN1.current.focus();
    }
  }


  // 自動発注データ検索
  const get_Jdhdat = async (inputRef, inputNxRef) => {
    console.log('自動発注データ検索開始');
    const row = await JSON.parse(JSON.stringify(rowItem));
    // チェック
    let Jancde = '';
    // ＪＡＮ１入力値の前0詰め
    Jancde = inputRef.current.value;
    if(Jancde.length === 7){
      Jancde = Jancde.padStart(8, '0');
    }
    if(9 <= Jancde.length && Jancde.length <= 12){
      Jancde = Jancde.padStart(13, '0');
    }
    console.log('スキャンコード'+Jancde);

    // 2段判定
    const jan1cd = Jancde.substring(0, 2);

    if (Jancde.length == 13 && (
        (jan1cd === '22' ||jan1cd === '23')
      ||((jan1cd === '21' ||jan1cd === '22') && state.KSACDES ==='005')
    )){
      await setRow_Two_JAN1 (row,jan1cd);

      //次の項目に遷移
      if (dissel02 === false) {
        // 活性時の場合はuseEffectは発生しない
        inJAN2.current.focus();
      } else {
        // 非活性時は、活性化の描画完了後（useEffect）にフォーカス移動させる必要がある
        // setNextActive(inJAN2.current.id);
        setDisSel02(false);
        setTimeout(() => {inJAN2.current.focus();}, 100);
      }
      return;
    }
    if(!(jan1cd === '21' || jan1cd === '22' || jan1cd === '23')){
      setDisSel02(true);
      row.JANCDE2 = '';
    }

    //コード体系チェック
    if (Jancde.length == 13 && (
      ((jan1cd === '28' ||jan1cd === '29') && state.KSACDES ==='001')
    ||((jan1cd === '26' ||jan1cd === '27') && state.KSACDES ==='005')
    )){
      window.alert('入力されたコードに誤りが有ります'); 
      setChkflg(2);
      return;
    }

    let shohinMaster = undefined;
    // 8桁の場合、商品マスタ（基本）にEOSとしてデータが存在するかチェック。
    if(Jancde.length === 8){
      console.log('８桁検索開始:%o');
      const getShohinMaster = await fetch('/TCSH0101/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({row:rowItem, EOSCDE:Jancde })});
      shohinMaster = await getShohinMaster.json();

      if(shohinMaster.ret.length !== 0) {
        row.IN_CD_KBN = '2';
      }
    };

    // 8、12、13 桁の場合、商品マスタ（基本）にJANとしてデータが存在するかチェック。
    if(row.IN_CD_KBN !== '2' &&
      (Jancde.length === 8 || Jancde.length === 12 || Jancde.length === 13)) {
        console.log('８桁以外検索:%o',row);
        console.log('jancde:'+Jancde);
        const getShohinMaster = await fetch('/TCSH0101/getShohinMaster_JanCode',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({row:rowItem, JANCDEX:Jancde })});
        shohinMaster = await getShohinMaster.json();
        console.log('shohinMaster:%o',shohinMaster);

      if(shohinMaster.ret.length !== 0) {
        row.IN_CD_KBN = '1';
      }
    }


    if(shohinMaster === undefined) {
      window.alert('入力されたコードに誤りが有ります'); 
      setChkflg(2);
      return;
    }else if (shohinMaster.ret.length === 0) {
      window.alert('入力されたコードに誤りが有ります'); 
      setChkflg(2);
      return;
    }else{
      rowItem.SHNID = shohinMaster.ret[0].SHNID;             // 商品ID
      setSelected03(shohinMaster.ret[0].SHNMEIKN);           // 商品名
      setSelected11(shohinMaster.ret[0].TRIHBN);             // 取引品番
      setSelected12(shohinMaster.ret[0].TAGSIZMEI);          // サイズ
      setSelected13(shohinMaster.ret[0].TAGCOLMEI);          // カラー
      setSelected14(shohinMaster.ret[0].TAGSZNNM);           // シーズン
    }
    // 発注日（システム日付）が発注終了日以降に該当の場合、エラー（2024/06/03追加）
    if (shohinMaster.ret[0].HTTEND.trim() !== '')
    {
      let ndate = new Date(state.SDAY);
      let today = ndate.getFullYear() + ('0'+(ndate.getMonth()+1)).slice(-2) + ('0'+ndate.getDate()).slice(-2)
      console.log('システム日付が発注終了日以降チェック開始',shohinMaster.ret[0].HTTEND,today);
      if (shohinMaster.ret[0].HTTEND < today){
        window.alert('この商品の発注終了日は'+formatDate(shohinMaster.ret[0].HTTEND)+'までです。\n入力された日付は発注対象期間外です。'); 
        setChkflg(2);
        return;
      }
    }
    // 2024/06/05 1289部門・3599部門考慮漏れ対応
    // 複数部門商品対応：複数ヒットする可能性が有る為、何レコード目に有るか確認する
    let line = 0;
    for(const key in shohinMaster.ret) {
      if(state.BMNCDE  === '3599'||state.BMNCDE  === '3699'){
        // console.log('(1)3599or3699の為、グループコードチェック:%o',state.GRPCDE);
        // 部門名取得
        const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[0].BMNCDE, ASTCDE:'XXX' })});
        const j_ItemName = await getItemName.json();
        console.log('(1)3599or3699の為、グループコードチェック:%o',j_ItemName.GRPCDE);
        if(state.GRPCDE !== j_ItemName.GRPCDE){
          line = -1
        }else{
          line = key
          break;
        }
      }else if(state.BMNCDE  === '1289'){   // 1999⇒1289に変更（2024/04/15）
        // console.log('(2)1289の為、事業部コード１桁チェック:%o',state.GRPCDE);
        // 部門名取得
        const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[0].BMNCDE, ASTCDE:'XXX' })});
        const j_ItemName = await getItemName.json();
        console.log('(2)1289の為、事業部コード１桁チェック:%o',j_ItemName.JGBCDE);
        if(state.GRPCDE !== j_ItemName.JGBCDE){
          line = -1
        }else{
          line = key
          break;
        }
      }else{
        const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[key].BMNCDE, ASTCDE:'XXX' })});
        const j_ItemName = await getItemName.json();
        console.log('(3)それ以外の為、商品マスタ.部門コード全桁チェック:%o',state.BMNCDE,shohinMaster.ret[key].BMNCDE);
        if(state.BMNCDE  !== shohinMaster.ret[key].BMNCDE) {
          line = -1
        }else{
          line = key
          break;
        }
      }
    }
    console.log('errflg%o',line);
    if (line < 0)
    {
      window.alert('入力されたＪＡＮ１は部門が異なります'); 
      setChkflg(2);
      return;
    }else{
    }      
    // 自動発注データ検索
    const getJdhdat = await fetch('/TCSH0101/getJdhdat',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        KSACDE:state.KSACDES
        ,MISE:state.MISES
        // ,BMNCDE:state.BMNCDE
        ,BMNCDE:state.BMNCDE === '3699' ? '' : state.BMNCDE
        ,SHNID:shohinMaster.ret[0].SHNID
      })});
    const Jdhdat = await getJdhdat.json();
    if(Jdhdat.CNT !== 0) {
      console.log(Jdhdat);
      setSelected08(Jdhdat.ret[0].SHNRANK);                  // 商品ランク
      if(Number(Jdhdat.ret[0].HENYMD) === 0) {
        setSelected04(Jdhdat.ret[0].JDHTTST);                // 自動発注状態
        setSelected05(Number(Jdhdat.ret[0].KJN_ZAI));        // 基準在庫
        setSelected06(Number(Jdhdat.ret[0].LOWHTTSUU));      // 最低発注数
        setSelected07(Number(Jdhdat.ret[0].CAL_ZAI));        // 店舗在庫
        //更新に必要だから検索結果を一旦返却する
        props.handleValueChange04(Jdhdat.ret[0].JDHTTST);
        props.handleValueChange05(Jdhdat.ret[0].KJN_ZAI);
        props.handleValueChange06(Jdhdat.ret[0].LOWHTTSUU);
        props.handleValueChange07(Jdhdat.ret[0].CAL_ZAI);
        props.handleValueChange08(Jdhdat.ret[0].SHNID);
      }else{
        console.log('変更日≠0:'+Jdhdat.ret[0].HENYMD);
        setSelected04(Jdhdat.ret[0].HEN_JDHTTST);            // 自動発注状態
        setSelected05(Number(Jdhdat.ret[0].HEN_KJN_ZAI));    // 基準在庫
        setSelected06(Number(Jdhdat.ret[0].HEN_LOWHTTSUU));  // 最低発注数
        //S SK-0051-012 2024/7/25           TCI)M.K【課題No.77：在庫以外の修正は在庫をリアルタイム更新】
        // setSelected07(Number(Jdhdat.ret[0].HEN_CAL_ZAI));    // 店舗在庫
        if(Jdhdat.ret[0].HEN_CAL_ZAI !== ''){
          if(Jdhdat.ret[0].CAL_ZAI_ENTTIM !== ''){
            setSelected07(Number(Jdhdat.ret[0].HEN_CAL_ZAI));    // 店舗在庫
          }else{
            setSelected07(Number(Jdhdat.ret[0].CAL_ZAI));    // 店舗在庫
          }
        }
        //E SK-0051-012
        //更新に必要だから検索結果を一旦返却する
        props.handleValueChange04(Jdhdat.ret[0].HEN_JDHTTST);
        props.handleValueChange05(Jdhdat.ret[0].HEN_KJN_ZAI);
        props.handleValueChange06(Jdhdat.ret[0].HEN_LOWHTTSUU);
        props.handleValueChange07(Jdhdat.ret[0].HEN_CAL_ZAI);
        props.handleValueChange08(shohinMaster.ret[0].SHNID);
        props.handleValueChange08(Jdhdat.ret[0].SHNID);
      }
    }
    //setChkflg(0);
    inChnry.current.focus();
    setTimeout(() => {inChnry.current.select();}, 100);
  }

  // ＪＡＮ１入力
  // const onKeyDown_JAN1 = async(inputRef, inputNxRef) => {
  //   let errflg = 0;
  //   setChkJAN1('1');
  //   clear_sinops();
  //   const row = await JSON.parse(JSON.stringify(rowItem));
  //   // ＪＡＮ１入力値の前0詰め
  //   // Jancde = row.JANCDE;
  //   let Jancde = inJAN1.current.value
  //   if(row.JANCDE.length === 7){
  //     row.JANCDE = row.JANCDE.padStart(8, '0');
  //     Jancde = row.JANCDE.padStart(8, '0');
  //   }
  //   if(9 <= row.JANCDE.length && row.JANCDE.length <= 12){
  //     row.JANCDE = row.JANCDE.padStart(13, '0');
  //     Jancde = row.JANCDE.padStart(13, '0');
  //   }
  //   console.log('スキャンコード'+row.JANCDE);

  //   // 2段判定
  //   //const jan1cd = inputRef.current.value.substring(0, 2);
  //   const jan1cd = Jancde.substring(0, 2);

  //   if (Jancde.length == 13 && (
  //         (jan1cd === '22' ||jan1cd === '23')
  //       ||((jan1cd === '21' ||jan1cd === '22') && state.KSACDES ==='005')
  //   )){
  //     await setRow_Two_JAN1 (row,jan1cd);

  //     //次の項目に遷移
  //     if (dissel02 === false) {
  //       // 活性時の場合はuseEffectは発生しない
  //       setJAN1clr('#FFFFFF');
  //       inJAN2.current.focus();
  //     } else {
  //       // 非活性時は、活性化の描画完了後（useEffect）にフォーカス移動させる必要がある
  //       setNextActive(inJAN2.current.id);
  //       dissel02(false);
  //     }
  //     return;
  //   }
  //   if(!(jan1cd === '21' || jan1cd === '22' || jan1cd === '23')){
  //     dissel02(true);
  //     row.JANCDE2 = '';
  //   }

  //   //コード体系チェック
  //   if (Jancde.length == 13 && (
  //         ((jan1cd === '28' ||jan1cd === '29') && state.KSACDES ==='001')
  //       ||((jan1cd === '26' ||jan1cd === '27') && state.KSACDES ==='005')
  //   )){
  //     setJAN1clr('#f59d9d');
  //     window.alert('入力されたコードに誤りが有ります'); 
  //     setChkflg(2);
  //     return;
  //   }

  //   // 衣料品事業部配下分類かどうかを確認
  //   // 衣料品フラグ = １ の場合
  //   if(state.IRYOFLG === '1') {
  //     row.IRYO_FLG = '1';
  //   } else {
  //     row.IRYO_FLG = '0';
  //   }

  //   let shohinMaster = undefined;
  //   // 8桁の場合、商品マスタ（基本）にEOSとしてデータが存在するかチェック。
  //   if(Jancde.length === 8){
  //     console.log('８桁検索開始:%o',row);
  //     const getShohinMaster = await fetch('/TCSH0101/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
  //     body: JSON.stringify({row:rowItem, EOSCDE:Jancde })});
  //     shohinMaster = await getShohinMaster.json();

  //     if(shohinMaster.ret.length !== 0) {
  //       row.IN_CD_KBN = '2';
  //     }
  //   };

  //   // 8、12、13 桁の場合、商品マスタ（基本）にJANとしてデータが存在するかチェック。
  //   if(row.IN_CD_KBN !== '2' &&
  //     (Jancde.length === 8 || Jancde.length === 12 || Jancde.length === 13)) {
  //       console.log('８桁以外検索:%o',row);
  //       console.log('jancde:'+Jancde);
  //       const getShohinMaster = await fetch('/TCSH0101/getShohinMaster_JanCode',{method: 'POST',headers: {'Content-Type': 'application/json'},
  //       body: JSON.stringify({row:rowItem, JANCDEX:Jancde })});
  //       shohinMaster = await getShohinMaster.json();
  //       console.log('shohinMaster:%o',shohinMaster);

  //     if(shohinMaster.ret.length !== 0) {
  //       row.IN_CD_KBN = '1';
  //     }
  //   }

  //   if(!shohinMaster) {
  //     setJAN1clr('#f59d9d');
  //     window.alert('入力されたコードに誤りが有ります'); 
  //     setChkflg(2);
  //     return;
  //   }

  //   // 仕入先名称取得
  //   if (shohinMaster.ret.length !== 0) {
  //     row.SIRCDE = shohinMaster.ret[0].SIRCDE;  // 仕入先(取引先)コード
  //     const getSirmeik = await fetch('/TCSH0101/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
  //       body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:row.SIRCDE})});
  //     const sirmeik = await getSirmeik.json();
  //     if(sirmeik !== null) {
  //       row.SIRMEIK = sirmeik.SIRMEIK.trim();          // 仕入先(取引先)名
  //     }
  //   }

  //   // ＪＡＮ１チェック
  //   // (1)
  //   if(shohinMaster.ret.length === 0) {
  //     setJAN1clr('#f59d9d');
  //     window.alert('入力されたコードに誤りが有ります'); 
  //     setChkflg(2);
  //     return;
  //   }

  //   // (7)事業部コードチェック
  //   if(state.BMNCDE  === '3599'||state.BMNCDE  === '3699'){
  //     // console.log('(1)3599or3699の為、グループコードチェック:%o',state.GRPCDE);
  //     // 部門名取得
  //     const getItemName = await fetch('/TCSH0101/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
  //       body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[0].BMNCDE, ASTCDE:'XXX' })});
  //     const j_ItemName = await getItemName.json();
  //     console.log('(1)3599or3699の為、グループコードチェック:%o',j_ItemName.GRPCDE);
  //     if(state.GRPCDE !== j_ItemName.GRPCDE){
  //       errflg = -1
  //     }
  //   }else if(state.BMNCDE  === '1999'){
  //     // console.log('(2)1999の為、事業部コード１桁チェック:%o',state.GRPCDE);
  //     // 部門名取得
  //     const getItemName = await fetch('/TCSH0101/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
  //       body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[0].BMNCDE, ASTCDE:'XXX' })});
  //     const j_ItemName = await getItemName.json();
  //     console.log('(2)1999の為、事業部コード１桁チェック:%o',j_ItemName.JGBCDE);
  //     if(state.GRPCDE !== j_ItemName.JGBCDE){
  //       errflg = -1
  //     }
  //   }else{
  //     console.log('(3)それ以外の為、商品マスタ.部門コード全桁チェック:%o',state.BMNCDE,shohinMaster.ret[0].BMNCDE);
  //     if(state.BMNCDE  !== shohinMaster.ret[0].BMNCDE) {
  //       errflg = -1
  //     }  
  //   }
  //   console.log('errflg%o',errflg);
  //   if (errflg !== 0)
  //   {
  //     setJAN1clr('#f59d9d');
  //     window.alert('入力されたＪＡＮ１は部門が異なります'); 
  //     setChkflg(2);
  //     return;
  //   }else{
  //     setJAN1clr('#FFFFFF');
  //   }
    
  //   // データセット（2）
  //   const getJdhdat = await fetch('/TCSH0101/getJdhdat',{method: 'POST',headers: {'Content-Type': 'application/json'},
  //     body: JSON.stringify({
  //       KSACDE:state.KSACDES
  //       ,MISE:state.MISES
  //       ,BMNCDE:shohinMaster.ret[0].BMNCDE
  //       ,SHNID:shohinMaster.ret[0].SHNID
  //     })});
  //   const Jdhdat = await getJdhdat.json();
  //   if(Jdhdat.CNT !== 0) {
  //     console.log(Jdhdat);
  //     setSelected08(Jdhdat.ret[0].SHNRANK);                  // 商品ランク
  //     if(Number(Jdhdat.ret[0].HENYMD) === 0) {
  //       setSelected04(Jdhdat.ret[0].JDHTTST);                // 自動発注状態
  //       setSelected05(Number(Jdhdat.ret[0].KJN_ZAI));        // 基準在庫
  //       setSelected06(Number(Jdhdat.ret[0].LOWHTTSUU));      // 最低発注数
  //       setSelected07(Number(Jdhdat.ret[0].CAL_ZAI));        // 店舗在庫
  //       //更新に必要だから検索結果を一旦返却する
  //       props.handleValueChange04(Jdhdat.ret[0].JDHTTST);
  //       props.handleValueChange05(Jdhdat.ret[0].KJN_ZAI);
  //       props.handleValueChange06(Jdhdat.ret[0].LOWHTTSUU);
  //       props.handleValueChange07(Jdhdat.ret[0].CAL_ZAI);
  //     }else{
  //       console.log('変更日≠0:'+Jdhdat.ret[0].HENYMD);
  //       setSelected04(Jdhdat.ret[0].HEN_JDHTTST);            // 自動発注状態
  //       setSelected05(Number(Jdhdat.ret[0].HEN_KJN_ZAI));    // 基準在庫
  //       setSelected06(Number(Jdhdat.ret[0].HEN_LOWHTTSUU));  // 最低発注数
  //       setSelected07(Number(Jdhdat.ret[0].HEN_CAL_ZAI));    // 店舗在庫
  //       //更新に必要だから検索結果を一旦返却する
  //       props.handleValueChange04(Jdhdat.ret[0].HEN_JDHTTST);
  //       props.handleValueChange05(Jdhdat.ret[0].HEN_KJN_ZAI);
  //       props.handleValueChange06(Jdhdat.ret[0].HEN_LOWHTTSUU);
  //       props.handleValueChange07(Jdhdat.ret[0].HEN_CAL_ZAI);
  //     }
  //   }
    
  //   setRowItem(row);
  //   // 終了処理
  //   setChkflg(0);
  // };

  // ヘッダ部２入力　ＪＡＮ２（活性時）※TCSH0101からの焼き直し※
  const onKeyDown_JAN2 = async (inputRef, inputNxRef) => {
    console.log('JAN2 ENTERされました');
    setChkJAN2('1');
    let row = JSON.parse(JSON.stringify(rowItem));

    // ＪＡＮ2入力値の前0詰め
    let Jan2cde = inJAN2.current.value;
    if(Jan2cde.length === 7){
      Jan2cde = Jan2cde.padStart(8, '0');
    }
    if(9 <= Jan2cde.length && Jan2cde.length <= 12){
      Jan2cde =Jan2cde.padStart(13, '0');
    }
    row.JANCDE2 = Jan2cde;

    const jan2cdH = inJAN2.current.value.substring(0, 2);
    // state.KSACDES = '001'の場合は、前２桁が「28」、「29」以外はエラー
    if(state.KSACDES === '001' && !(jan2cdH === '28' ||jan2cdH === '29')){
      setJAN2clr('#f59d9d');
      window.alert('ＪＡＮ２入力エラー。');
      return 1;
    }

    // state.KSACDES = '005'の場合は、前２桁が「26」、「27」以外はエラー
    if(state.KSACDES === '005' && !(jan2cdH === '26' ||jan2cdH === '27')){
      setJAN2clr('#f59d9d');
      window.alert('ＪＡＮ２入力エラー。');
      return 1;
    }else
    {
      setJAN2clr('#FFFFFF');
    }

    const jan1cd = inJAN1.current.value.substring(0, 2);
    let tagMaster = undefined;
    let shohinMaster = undefined;
    //１）ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005以外もしくは、「２３」の場合
    if((jan1cd ==='22' && state.KSACDES !== '005')|| jan1cd ==='23') {
      console.log('１）ＪＡＮ２チェック開始[オークワ]');
      //(1)ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005以外の場合
      if(jan1cd ==='22' && state.KSACDES !== '005') {
        const ASTCDE = Jan2cde.substring(2, 5);                      // ＡＵ
        const LINCDE = inJAN1.current.value.substring(2, 3);         // ライン
        const CLSCDE = '000' + inJAN1.current.value.substring(3, 4); // クラス
        const TAGSZN = inJAN1.current.value.substring(4, 5);         // タグシーズン
        const TAGITM = inJAN1.current.value.substring(5, 8);         // タグアイテム
        const TAGSIZCDE = inJAN1.current.value.substring(8, 10);     // サイズコード
        const TAGCOLCDE = inJAN1.current.value.substring(10, 12);    // カラーコード

        //①タグマスタから情報取得
        // const getTagMaster = await fetch('/TCSH0101/getTagMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        // body: JSON.stringify({
        //   KSACDE:state.KSACDES,MISE:state.MISES,ASTCDE:ASTCDE,LINCDE:LINCDE,CLSCDE:CLSCDE,TAGSZN:TAGSZN,TAGITM:TAGITM
        // })});
        // tagMaster = await getTagMaster.json();

        // if (tagMaster.ret.length === 0){
        //   setJAN2clr('#f59d9d');
        //   window.alert('商品が存在しません。');
        //   return 1;
        // }else{
        //   setSelected03(tagMaster.ret[0].SHNMEIKN);          // 商品名カナ
        // }
        
        // const getSizeMaster = await fetch('/TCSH0101/getSizeMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        // body: JSON.stringify({
        //   KSACDE:state.KSACDES,BMNCDE:state.BMNCDE,ASTCDE:ASTCDE,LINCDE:LINCDE,TAGSIZCDE:TAGSIZCDE
        // })});
        // const sizeMaster = await getSizeMaster.json();
        // if(sizeMaster.ret.length !== 0) {
        //   setSelected12(sizeMaster.ret[0].TAGSIZMEI);          // サイズ
        // } else {
        //   setSelected12('');          // サイズ
        // }

        // //③ 衣料カラーマスタから情報取得
        // const getColorMaster = await fetch('/TCSH0101/getColorMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        // body: JSON.stringify({KSACDE:state.KSACDES,TAGCOLCDE:TAGCOLCDE})});
        // const colorMaster = await getColorMaster.json();
        // if(colorMaster.ret.length !== 0) {
        //   setSelected13(colorMaster.ret[0].TAGCOLMEI);          // カラー
        // } else {
        //   setSelected13('');          // カラー
        // }

        // //④ タグシーズンマスタから情報取得
        // const getSZNMaster = await fetch('/TCSH0101/getSznMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        // body: JSON.stringify({TAGSZN:TAGSZN})});
        // const SZNMaster = await getSZNMaster.json();
        // if(SZNMaster.ret.length !== 0) {
        //   setSelected14(SZNMaster.ret[0].TAGSZNNM);           // シーズン
        // } else {
        //   setSelected14('');           // シーズン
        // }
        // // setRow_Two(row, tagMaster.ret[0]);

        const getShohinMaster = await fetch('/TCSH0101/getShohinMaster_TagMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,MISE:state.MISES,BMNCDE:state.BMNCDE,ASTCDE:ASTCDE,LINCDE:LINCDE,CLSCDE:CLSCDE,TAGSZN:TAGSZN,TAGITM:TAGITM,TAGSIZCDE:TAGSIZCDE,TAGCOLCDE:TAGCOLCDE
        })});
        shohinMaster = await getShohinMaster.json();
        
        if (shohinMaster.ret.length === 0){
          setJAN2clr('#f59d9d');
          setChkflg(3);
          window.alert('商品が存在しません。'); //TCGE0047：
          // setTimeout(() => {inJAN1.current.focus();}, 0);
          // await showDialog('商品が存在しません。');
          inJAN1.current.focus();
          return 1;
        }else
        {
          rowItem.SHNID = shohinMaster.ret[0].SHNID;             // 商品ID
          setSelected03(shohinMaster.ret[0].SHNMEIKN);          // 商品名カナ
          setSelected11(shohinMaster.ret[0].TRIHBN);             // 取引品番
          setSelected12(shohinMaster.ret[0].TAGSIZMEI);          // サイズ
          setSelected13(shohinMaster.ret[0].TAGCOLMEI);          // カラー
          setSelected14(shohinMaster.ret[0].TAGSZNNM);           // シーズン
        }
        // 発注日（システム日付）が発注終了日以降に該当の場合、エラー（2024/06/03追加）
        if (shohinMaster.ret[0].HTTEND.trim() !== '')
        {
          let ndate = new Date(state.SDAY);
          let today = ndate.getFullYear() + ('0'+(ndate.getMonth()+1)).slice(-2) + ('0'+ndate.getDate()).slice(-2)
          console.log('システム日付が発注終了日以降チェック開始',shohinMaster.ret[0].HTTEND,today);
          if (shohinMaster.ret[0].HTTEND < today){
            window.alert('この商品の発注終了日は'+formatDate(shohinMaster.ret[0].HTTEND)+'までです。\n入力された日付は発注対象期間外です。'); 
            setChkflg(3);
            return 1;
          }
        }
        // 2024/06/05 1289部門・3599部門考慮漏れ対応
        // 複数部門商品対応：複数ヒットする可能性が有る為、何レコード目に有るか確認する
        let line = 0;
        for(const key in shohinMaster.ret) {
          if(state.BMNCDE  === '3599'||state.BMNCDE  === '3699'){
            // console.log('(1)3599or3699の為、グループコードチェック:%o',state.GRPCDE);
            // 部門名取得
            const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[0].BMNCDE, ASTCDE:'XXX' })});
            const j_ItemName = await getItemName.json();
            console.log('(1)3599or3699の為、グループコードチェック:%o',j_ItemName.GRPCDE);
            if(state.GRPCDE !== j_ItemName.GRPCDE){
              line = -1
            }else{
              line = key
              break;
            }
          }else if(state.BMNCDE  === '1289'){   // 1999⇒1289に変更（2024/04/15）
            // console.log('(2)1289の為、事業部コード１桁チェック:%o',state.GRPCDE);
            // 部門名取得
            const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[0].BMNCDE, ASTCDE:'XXX' })});
            const j_ItemName = await getItemName.json();
            console.log('(2)1289の為、事業部コード１桁チェック:%o',j_ItemName.JGBCDE);
            if(state.GRPCDE !== j_ItemName.JGBCDE){
              line = -1
            }else{
              line = key
              break;
            }
          }else{
            const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[key].BMNCDE, ASTCDE:'XXX' })});
            const j_ItemName = await getItemName.json();
            console.log('(3)それ以外の為、商品マスタ.部門コード全桁チェック:%o',state.BMNCDE,shohinMaster.ret[key].BMNCDE);
            if(state.BMNCDE  !== shohinMaster.ret[key].BMNCDE) {
              line = -1
            }else{
              line = key
              break;
            }
          }
        }
        console.log('errflg%o',line);
        if (line < 0)
        {
          window.alert('入力されたＪＡＮ１は部門が異なります'); 
          setChkflg(3);
          return 1;
        }else{
        }      
      }

      //(2) ＪＡＮ１の前２桁が「２３」の場合
      if(jan1cd ==='23') {
        // 桁数チェック
        if(Jan2cde.length !== 13){
          setJAN2clr('#f59d9d');
          window.alert('ＪＡＮ２入力エラー。');
          return;
        }

        //① 商品マスタ(基本)から情報取得
        console.log('① 商品マスタ(基本)から情報取得開始');
        const EosCode = inJAN1.current.value.substring(2, 10);
        const getShohinMaster = await fetch('/TCSH0101/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({row:rowItem, EOSCDE:EosCode})});
        shohinMaster = await getShohinMaster.json();

        if (shohinMaster.ret.length === 0){
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。');
          return 1;
        }else{
          rowItem.SHNID = shohinMaster.ret[0].SHNID;             // 商品ID
          setSelected03(shohinMaster.ret[0].SHNMEIKN);           // 商品名
          setSelected11(shohinMaster.ret[0].TRIHBN);             // 取引品番
          setSelected12(shohinMaster.ret[0].TAGSIZMEI);          // サイズ
          setSelected13(shohinMaster.ret[0].TAGCOLMEI);          // カラー
          setSelected14(shohinMaster.ret[0].TAGSZNNM);           // シーズン
        }
        // 発注日（システム日付）が発注終了日以降に該当の場合、エラー（2024/06/03追加）
        if (shohinMaster.ret[0].HTTEND.trim() !== '')
        {
          let ndate = new Date(state.SDAY);
          let today = ndate.getFullYear() + ('0'+(ndate.getMonth()+1)).slice(-2) + ('0'+ndate.getDate()).slice(-2)
          console.log('システム日付が発注終了日以降チェック開始',shohinMaster.ret[0].HTTEND,today);
          if (shohinMaster.ret[0].HTTEND < today){
            window.alert('この商品の発注終了日は'+formatDate(shohinMaster.ret[0].HTTEND)+'までです。\n入力された日付は発注対象期間外です。'); 
            return 1;
          }
        }
        // 2024/06/05 1289部門・3599部門考慮漏れ対応
        // 複数部門商品対応：複数ヒットする可能性が有る為、何レコード目に有るか確認する
        let line = 0;
        for(const key in shohinMaster.ret) {
          if(state.BMNCDE  === '3599'||state.BMNCDE  === '3699'){
            // console.log('(1)3599or3699の為、グループコードチェック:%o',state.GRPCDE);
            // 部門名取得
            const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[0].BMNCDE, ASTCDE:'XXX' })});
            const j_ItemName = await getItemName.json();
            console.log('(1)3599or3699の為、グループコードチェック:%o',j_ItemName.GRPCDE);
            if(state.GRPCDE !== j_ItemName.GRPCDE){
              line = -1
            }else{
              line = key
              break;
            }
          }else if(state.BMNCDE  === '1289'){   // 1999⇒1289に変更（2024/04/15）
            // console.log('(2)1289の為、事業部コード１桁チェック:%o',state.GRPCDE);
            // 部門名取得
            const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[0].BMNCDE, ASTCDE:'XXX' })});
            const j_ItemName = await getItemName.json();
            console.log('(2)1289の為、事業部コード１桁チェック:%o',j_ItemName.JGBCDE);
            if(state.GRPCDE !== j_ItemName.JGBCDE){
              line = -1
            }else{
              line = key
              break;
            }
          }else{
            const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[key].BMNCDE, ASTCDE:'XXX' })});
            const j_ItemName = await getItemName.json();
            console.log('(3)それ以外の為、商品マスタ.部門コード全桁チェック:%o',state.BMNCDE,shohinMaster.ret[key].BMNCDE);
            if(state.BMNCDE  !== shohinMaster.ret[key].BMNCDE) {
              line = -1
            }else{
              line = key
              break;
            }
          }
        }
        console.log('errflg%o',line);
        if (line < 0)
        {
          window.alert('入力されたＪＡＮ１は部門が異なります'); 
          return 1;
        }else{
        }      

        row.EOSCDE = EosCode;
        setSelectedEos(EosCode);                                 // EosCode
        props.handleValueChangeEos(selectedEos);
      }
    }

    //２）ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005もしくは、「２１」の場合
    if((jan1cd === '22' && state.KSACDES === '005') || jan1cd ==='21') {
      console.log('２）ＪＡＮ２チェック開始[PH]');
      //(1)ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005の場合
      if(jan1cd === '22' && state.KSACDES === '005') {
        const ASTCDE = Jan2cde.substring(2, 5);                      // ＡＵ
        const LINCDE = inJAN1.current.value.substring(2, 3);         // ライン
        const CLSCDE = '000' + inJAN1.current.value.substring(3, 4); // クラス
        const TAGSZN = inJAN1.current.value.substring(4, 5);         // タグシーズン
        const TAGITM = inJAN1.current.value.substring(5, 8);         // タグアイテム
        const TAGSIZCDE = inJAN1.current.value.substring(8, 10);     // サイズコード
        const TAGCOLCDE = inJAN1.current.value.substring(10, 12);    // カラーコード

        // //①タグマスタから情報取得
        // const getTagMaster = await fetch('/TCSH0101/getTagMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        // body: JSON.stringify({
        //   KSACDE:state.KSACDES,MISE:state.MISES,ASTCDE:ASTCDE,LINCDE:LINCDE,CLSCDE:CLSCDE,TAGSZN:TAGSZN,TAGITM:TAGITM
        // })});
        // tagMaster = await getTagMaster.json();

        // if (tagMaster.ret.length === 0){
        //   setJAN2clr('#f59d9d');
        //   window.alert('商品が存在しません。');
        //   return 1;
        // }else{
        //   setSelected03(tagMaster.ret[0].SHNMEIKN);          // 商品名カナ
        // }

        // //② 衣料サイズマスタから情報取得
        // const getSizeMaster = await fetch('/TCSH0101/getSizeMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        // body: JSON.stringify({
        //   KSACDE:state.KSACDES,BMNCDE:state.BMNCDE,ASTCDE:ASTCDE,LINCDE:LINCDE,TAGSIZCDE:TAGSIZCDE
        // })});
        // const sizeMaster = await getSizeMaster.json();
        // if(sizeMaster.ret.length !== 0) {
        //   setSelected12(sizeMaster.ret[0].TAGSIZMEI);          // サイズ
        // } else {
        //   setSelected12('');          // サイズ
        // }

        // //③ 衣料カラーマスタから情報取得
        // const getColorMaster = await fetch('/TCSH0101/getColorMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        // body: JSON.stringify({KSACDE:state.KSACDES,TAGCOLCDE:TAGCOLCDE})});
        // const colorMaster = await getColorMaster.json();
        // if(colorMaster.ret.length !== 0) {
        //   setSelected13(colorMaster.ret[0].TAGCOLMEI);          // カラー
        // } else {
        //   setSelected13('');          // カラー
        // }

        // //④ タグシーズンマスタから情報取得
        // const getSZNMaster = await fetch('/TCSH0101/getSznMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        // body: JSON.stringify({TAGSZN:TAGSZN})});
        // const SZNMaster = await getSZNMaster.json();
        // if(SZNMaster.ret.length !== 0) {
        //   setSelected14(SZNMaster.ret[0].TAGSZNNM);           // シーズン
        // } else {
        //   setSelected14('');           // シーズン
        // }
        const getShohinMaster = await fetch('/TCSH0101/getShohinMaster_TagMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,MISE:state.MISES,BMNCDE:state.BMNCDE,ASTCDE:ASTCDE,LINCDE:LINCDE,CLSCDE:CLSCDE,TAGSZN:TAGSZN,TAGITM:TAGITM,TAGSIZCDE:TAGSIZCDE,TAGCOLCDE:TAGCOLCDE
        })});
        shohinMaster = await getShohinMaster.json();
        
        if (shohinMaster.ret.length === 0){
          setJAN2clr('#f59d9d');
          setChkflg(3);
          window.alert('商品が存在しません。'); //TCGE0047：
          // setTimeout(() => {inJAN1.current.focus();}, 0);
          // await showDialog('商品が存在しません。');
          inJAN1.current.focus();
          return 1;
        }else
        {
          rowItem.SHNID = shohinMaster.ret[0].SHNID;             // 商品ID
          setSelected03(shohinMaster.ret[0].SHNMEIKN);          // 商品名カナ
          setSelected11(shohinMaster.ret[0].TRIHBN);             // 取引品番
          setSelected12(shohinMaster.ret[0].TAGSIZMEI);          // サイズ
          setSelected13(shohinMaster.ret[0].TAGCOLMEI);          // カラー
          setSelected14(shohinMaster.ret[0].TAGSZNNM);           // シーズン
        }
        // 発注日（システム日付）が発注終了日以降に該当の場合、エラー（2024/06/03追加）
        if (shohinMaster.ret[0].HTTEND.trim() !== '')
        {
          let ndate = new Date(state.SDAY);
          let today = ndate.getFullYear() + ('0'+(ndate.getMonth()+1)).slice(-2) + ('0'+ndate.getDate()).slice(-2)
          console.log('システム日付が発注終了日以降チェック開始',shohinMaster.ret[0].HTTEND,today);
          if (shohinMaster.ret[0].HTTEND < today){
            window.alert('この商品の発注終了日は'+formatDate(shohinMaster.ret[0].HTTEND)+'までです。\n入力された日付は発注対象期間外です。'); 
            setChkflg(3);
            return 1;
          }
        }
        // 2024/06/05 1289部門・3599部門考慮漏れ対応
        // 複数部門商品対応：複数ヒットする可能性が有る為、何レコード目に有るか確認する
        let line = 0;
        for(const key in shohinMaster.ret) {
          if(state.BMNCDE  === '3599'||state.BMNCDE  === '3699'){
            // console.log('(1)3599or3699の為、グループコードチェック:%o',state.GRPCDE);
            // 部門名取得
            const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[0].BMNCDE, ASTCDE:'XXX' })});
            const j_ItemName = await getItemName.json();
            console.log('(1)3599or3699の為、グループコードチェック:%o',j_ItemName.GRPCDE);
            if(state.GRPCDE !== j_ItemName.GRPCDE){
              line = -1
            }else{
              line = key
              break;
            }
          }else if(state.BMNCDE  === '1289'){   // 1999⇒1289に変更（2024/04/15）
            // console.log('(2)1289の為、事業部コード１桁チェック:%o',state.GRPCDE);
            // 部門名取得
            const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[0].BMNCDE, ASTCDE:'XXX' })});
            const j_ItemName = await getItemName.json();
            console.log('(2)1289の為、事業部コード１桁チェック:%o',j_ItemName.JGBCDE);
            if(state.GRPCDE !== j_ItemName.JGBCDE){
              line = -1
            }else{
              line = key
              break;
            }
          }else{
            const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[key].BMNCDE, ASTCDE:'XXX' })});
            const j_ItemName = await getItemName.json();
            console.log('(3)それ以外の為、商品マスタ.部門コード全桁チェック:%o',state.BMNCDE,shohinMaster.ret[key].BMNCDE);
            if(state.BMNCDE  !== shohinMaster.ret[key].BMNCDE) {
              line = -1
            }else{
              line = key
              break;
            }
          }
        }
        console.log('errflg%o',line);
        if (line < 0)
        {
          window.alert('入力されたＪＡＮ１は部門が異なります'); 
          setChkflg(3);
          return 1;
        }else{
        }      
      }

      //(2) ＪＡＮ１の前２桁が「２１」の場合
      if (jan1cd === '21') {
        // 桁数チェック
        if(Jan2cde.length !== 13){
          setJAN2clr('#f59d9d');
          window.alert('ＪＡＮ２入力エラー。');
          return;
        }

        //① 商品マスタ(基本)から情報取得
        const EosCode = inJAN1.current.value.substring(4, 12);
        const getShohinMaster = await fetch('/TCSH0101/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({row:rowItem, EOSCDE:EosCode})});
        shohinMaster = await getShohinMaster.json();

        if (shohinMaster.ret.length === 0) {
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。');
          return 1;
        }else{
          rowItem.SHNID = shohinMaster.ret[0].SHNID;             // 商品ID
          setSelected03(shohinMaster.ret[0].SHNMEIKN);           // 商品名
          setSelected11(shohinMaster.ret[0].TRIHBN);             // 取引品番
          setSelected12(shohinMaster.ret[0].TAGSIZMEI);          // サイズ
          setSelected13(shohinMaster.ret[0].TAGCOLMEI);          // カラー
          setSelected14(shohinMaster.ret[0].TAGSZNNM);           // シーズン
        }
       row.EOSCDE = EosCode;
       setSelectedEos(EosCode);                                 // EosCode
       props.handleValueChangeEos(selectedEos);
      }
    }
    
    let jdh_BMNCDE = '';
    let jdh_SHNID = '';

    if (tagMaster === undefined){
      jdh_BMNCDE = shohinMaster.ret[0].BMNCDE;
      jdh_SHNID = shohinMaster.ret[0].SHNID;
    }else{
    // 別途タグマスター系の処理が必要

    }

    // 自動発注データ検索
    console.log('ＪＡＮ２自動発注検索開始');
    const getJdhdat = await fetch('/TCSH0101/getJdhdat',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES
          ,MISE:state.MISES
          ,BMNCDE:jdh_BMNCDE
          ,SHNID:jdh_SHNID
          })});
    const Jdhdat = await getJdhdat.json();
      if(Jdhdat.CNT !== 0) {
        console.log(Jdhdat);
        setSelected08(Jdhdat.ret[0].SHNRANK);                  // 商品ランク
        if(Number(Jdhdat.ret[0].HENYMD) === 0) {
            setSelected04(Jdhdat.ret[0].JDHTTST);                // 自動発注状態
            setSelected05(Number(Jdhdat.ret[0].KJN_ZAI));        // 基準在庫
            setSelected06(Number(Jdhdat.ret[0].LOWHTTSUU));      // 最低発注数
            setSelected07(Number(Jdhdat.ret[0].CAL_ZAI));        // 店舗在庫
            //更新に必要だから検索結果を一旦返却する
            props.handleValueChange04(Jdhdat.ret[0].JDHTTST);
            props.handleValueChange05(Jdhdat.ret[0].KJN_ZAI);
            props.handleValueChange06(Jdhdat.ret[0].LOWHTTSUU);
            props.handleValueChange07(Jdhdat.ret[0].CAL_ZAI);
            props.handleValueChange08(Jdhdat.ret[0].SHNID);
        }else{
            console.log('変更日≠0:'+Jdhdat.ret[0].HENYMD);
            setSelected04(Jdhdat.ret[0].HEN_JDHTTST);            // 自動発注状態
            setSelected05(Number(Jdhdat.ret[0].HEN_KJN_ZAI));    // 基準在庫
            setSelected06(Number(Jdhdat.ret[0].HEN_LOWHTTSUU));  // 最低発注数
            //S SK-0051-013 2024/7/25           TCI)M.K【課題No.77：在庫以外の修正は在庫をリアルタイム更新】
            // setSelected07(Number(Jdhdat.ret[0].HEN_CAL_ZAI));    // 店舗在庫
            if(Jdhdat.ret[0].HEN_CAL_ZAI !== ''){
              if(Jdhdat.ret[0].CAL_ZAI_ENTTIM !== ''){
                setSelected07(Number(Jdhdat.ret[0].HEN_CAL_ZAI));    // 店舗在庫
              }else{
                setSelected07(Number(Jdhdat.ret[0].CAL_ZAI));    // 店舗在庫
              }
            }
            //E SK-0051-013
            //更新に必要だから検索結果を一旦返却する
            props.handleValueChange04(Jdhdat.ret[0].HEN_JDHTTST);
            props.handleValueChange05(Jdhdat.ret[0].HEN_KJN_ZAI);
            props.handleValueChange06(Jdhdat.ret[0].HEN_LOWHTTSUU);
            props.handleValueChange07(Jdhdat.ret[0].HEN_CAL_ZAI);
            props.handleValueChange08(Jdhdat.ret[0].SHNID);
        }
      }
    setRowItem(row);
    inChnry.current.focus();
    setTimeout(() => {inChnry.current.select();}, 100);
  }

  const onClick_Reg  = async() => {
    console.log('[シノプス]OKが押されました',selected01,'/',chkflg,'/',regflg)
    if (selected01 !== ''){
      // setRegflg(1); // regflgの判定必要無い★未使用★後で確認する
      // if (chkflg === 0 && regflg === 1){
      if (chkflg === 0){
        props.onReg(); 
        //returnFocus.current && returnFocus.current.focus();
        dateClear();
      }
      else{
        setRegflg(1);
      }
    }
  }

  // データセット（2段 JAN1時）
  function setRow_Two_JAN1 (row, jan1cd) {
    row.IRYO_FLG = '1';                        // 衣料フラグ
    row.JANCDE = inJAN1.current.value;         // ＪＡＮコード
    //row.EOSCDE = jancde;         // ＥＯＳコード
    if(jan1cd === '22') {
      row.IN_CD_KBN = '3'                      // 入力コード区分
    } else {
      row.IN_CD_KBN = '4'                      // 入力コード区分
      row.EOSCDE = inJAN1.current.value;         // ＥＯＳコード
    }
    setRowItem(row);
  }

  const dateClear = () =>{
    setSelected01('');
    setSelected03('');
    setSelected04('');
    setSelected05('');
    setSelected06('');
    setSelected07('');
    setSelected08('');
    if (props.callergamen !== 'TCSH0100' && props.callergamen !== 'TCSH0130'){
      props.handleValueChange01('');
    }
    props.handleValueChange04('');
    props.handleValueChange05('');
    props.handleValueChange06('');
    props.handleValueChange07('');
    setChkflg(0);               // チェックフラグ
    setRegflg(0);               // チェックフラグ
  }
  return (
    <div className={`vba-message-box-0111-overlay ${props.isVisible ? 'show' : 'hide'}`}>
      <div className={`vba-message-box-0111 ${props.isVisible ? 'show' : 'hide'}`}>
        <div className='vba-message-box-0111-content'>
          <div className='vba-message-box-0111-text'>
            <p>在庫情報修正画面</p>
              {/* <input type='text' name='name' placeholder={props.message01} class='textbox'></input> */}
            {/* <div class='form__item'>
              <dt class='form__item-head'><label for='number'>スキャンコード</label></dt>
              <dd class='form__item-body'><input type='text' name='name' placeholder={props.message01} class='textbox'></input></dd>
            </div> */}
            <table class='design01-0111'>
              <tr>
                <th>JAN1</th>
                <td><input type='text' 
                  id={'JAN1'}
                  // inputRef={inJAN1}                   //項目名定義？
                  ref={inJAN1} 
                  value={selected01}
                  onChange={handleInputChange01} 
                  onInput={validateNumberInput}
                  name='name01' 
                  class='textbox-0111'
                  autoComplete='off'
                  disabled={dissel01}                    //使用可／不可
                  onKeyDown={(e) => {
                    onKeyDown(e.key,inJAN1,inJAN1,inJAN2);
                  }}
                  onFocus={(e) => e.target.select()}
                ></input></td>
                </tr>
            </table>
            <table class='design012-0111'>
              <tr>
                <th>JAN2</th>
                <td><input type='text' 
                  id={'JAN2'}
                  // inputRef={inJAN1}                   //項目名定義？
                  ref={inJAN2} 
                  value={selected02} 
                  onChange={handleInputChange02} 
                  onInput={validateNumberInput}
                  name='name02' 
                  class='textbox-0111'
                  autoComplete='off'
                  disabled={dissel02}                    //使用可／不可
                  onKeyDown={(e) => {
                    onKeyDown(e.key,inJAN2,inJAN1,inChnry);
                  }}
                  onFocus={(e) => e.target.select()}
                ></input></td>
                </tr>
            </table>　
            <table class='design02-0111'>
              {/* <tr>
                <th>JAN2</th>
                <td>{props.message02}</td>
              </tr> */}
              <tr>
                <th>商品名</th>
                {/* <td>{props.message03}</td> */}
                <td>{selected03}</td>
              </tr>
            </table>　
            <table class='design03-0111'>
              <tr>
                <th>取引先品番</th>
                <td><input type='text' 
                id='Chnry' 
                ref={inTrihbn}
                value={selected11} 
                onChange={handleInputChange11} 
                name='name11' 
                class='textbox-0111'
                autoComplete='off'
                disabled={dissel11}                    //使用可／不可
                onKeyDown={(e) => {
                  onKeyDown(e.key,inChnry,inJotai,inTnpzik);
                }}
                ></input></td>
              </tr>
              <tr>
                <th>サイズ</th>
                <td><input type='text' 
                id='Chnry' 
                ref={inSizmei}
                value={selected12} 
                onChange={handleInputChange12} 
                name='name12' 
                class='textbox-0111'
                autoComplete='off'
                disabled={dissel12}                    //使用可／不可
                onKeyDown={(e) => {
                  onKeyDown(e.key,inChnry,inJotai,inTnpzik);
                }}
                ></input></td>
              </tr>
              <tr>
                <th>カラー</th>
                <td><input type='text' 
                id='Chnry' 
                ref={inColmei}
                value={selected13} 
                onChange={handleInputChange13}
                name='name13' 
                class='textbox-0111'
                autoComplete='off'
                disabled={dissel13}                    //使用可／不可
                onKeyDown={(e) => {
                  onKeyDown(e.key,inChnry,inJotai,inTnpzik);
                }}
                ></input></td>
              </tr>
              <tr>
                <th>シーズン</th>
                <td><input type='text' 
                id='Chnry' 
                ref={inSznmei}
                value={selected14} 
                onChange={handleInputChange14}
                name='name14' 
                class='textbox-0111'
                autoComplete='off'
                disabled={dissel14}                    //使用可／不可
                onKeyDown={(e) => {
                  onKeyDown(e.key,inChnry,inJotai,inTnpzik);
                }}
                ></input></td>
              </tr>
              <tr>
                <th>発注状態</th>
                {/* <td><input type='text' onChange={handleInputChange04} name='name04' placeholder={props.message04} class='textbox'></input></td> */}
                <td>
                <select 
                  ref={inJotai}
                  name='name04' 
                  id='select' 
                  onChange={handleChange} 
                  onInput={validateNumberInput}
                  value={selected04} 
                  class='textbox-0111'
                  disabled={dissel04}                    //使用可／不可
                  onKeyDown={(e) => {
                    onKeyDown(e.key,inJotai,inJAN2,inChnry);
                  }}
                  >
                  <option value='10'>POS</option>
                  <option value='11'>EOS</option>
                </select>            
                </td>
              </tr>
              <tr>
                <th>基準在庫</th>
                <td><input type='tel' 
                pattern="^[+-]?([1-9][0-9]*|0)$"
                maxlength='5'
                id='Chnry' 
                ref={inChnry}
                value={selected05} 
                onChange={handleInputChange05} 
                onInput={validateNumberInput}
                name='name05' 
                class='textbox-0111'
                autoComplete='off'
                disabled={dissel05}                    //使用可／不可
                onKeyDown={(e) => {
                  onKeyDown(e.key,inChnry,inJotai,inTnpzik);
                }}
                onFocus={(e) => e.target.select()}
                ></input></td>
              </tr>
              <tr>
                <th>店舗在庫</th>
                <td><input type='tel' 
                pattern="^[+-]?([1-9][0-9]*|0)$"
                maxlength='5'
                id='Tnpzik' 
                ref={inTnpzik}
                value={selected07} 
                onChange={handleInputChange07} 
                onInput={validateNumberInput}
                name='name07' 
                class='textbox-0111'
                autoComplete='off'
                disabled={dissel07}                    //使用可／不可
                onKeyDown={(e) => {
                  onKeyDown(e.key,inTnpzik,inChnry,okButtonRef);
                }}
                onFocus={(e) => e.target.select()}
                ></input></td>
              </tr>
            </table>
          </div>
        </div>
        <div className='vba-message-box-0111-buttons' onKeyDown={handleKeyDown}>
          <button ref={backButtonRef} 
          onClick={() => { props.onClose(); returnFocus.current && returnFocus.current.focus(); }}>戻る
          </button>　
          <button ref={clearButtonRef} 
          onClick={(e) => onClick_FooterButton_Clear()}
          >再入力
          </button>　
          <button ref={okButtonRef} 
          /*onClick={() => { 
            props.onReg(); 
          returnFocus.current && returnFocus.current.focus(); }}>登録*/
          onClick={(e) => {onClick_Reg()}}>登録
          </button>
        </div>
      </div>
    </div>
  );
}

export default CustomFormTCSH0111;