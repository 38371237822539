import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, Button, TextField, Grid, Paper, Typography, Stack, InputLabel,FormControl, Select, MenuItem } from "@mui/material";
import { NumericFormat,PatternFormat } from 'react-number-format';    //数値入力
import { useNavigate, useLocation } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import {
  DataGrid,
  jaJP                              // 日本語用のファイルをインポート
} from '@mui/x-data-grid';
import { useJotKbnAction } from './hooks/useJotKbnAction' // 状態区分共通処理
import './common.css'

import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Modal from "react-modal";

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);

      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "35%",
    backgroundColor:"white",
    zIndex:10000,
  },
};

function TCHP0160 () {
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [value, setValue] = React.useState('item');
  function updateLunchList() {
    // window.alert('value:'+value+inKOSU.current.value); //TCGE00XX：
    closeModal();
  };
  const closeModal = () => {
    setEditModalIsOpen(false);

  };
  const handleChange = (event) => {
    setValue(event.target.value);
    inKOSU.current.focus();
  };
  const inKOSU = useRef(null);
  const [kosu, setkosu] = useState(0);     //個数


  const {JotKbnList, JotKbnComment, JotKbnjName, JotKbnOptions, changeJotKbn} = useJotKbnAction();  // 状態区分共通処理

  const navigate = useNavigate();
  const location = useLocation();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('');
  const [dismeisai, setMeisai] = useState(false);    //disabled設定（明細）
  const [sdate, setsDate] = useState([]);
  const [bmnCd, setBmnCd] = useState('');    //部門CD
  const [hassobi1, setHassobi1] = useState('');    //発送日1
  const [hassobi2, setHassobi2] = useState('');    //発送日2
  const [dennno1, setDennno1] = useState('');    //伝票番号1
  const [dennno2, setDennno2] = useState('');    //伝票番号2
  const [sbrumei, setBrumei] = useState('');    //部門名
  const [miseCd, setMiseCd] = useState('');    //受取店CD
  const [miseMei, setMisemei] = useState('');    //受取店名
  const [tanCd, setTanCd] = useState('');    //担当者CD
  const [jgnmei, setJgnmei] = useState('');     //担当者名

  const [rows, setRows] = useState([]);     //明細列
  const [totalSuryo, setTotalSuryo] = useState(0);     //数量合計
  const [totalGenka, setTotalGenka] = useState(0);     //原価金額合計
  const [totalBaika, setTotalBaika] = useState(0);     //売価金額合計
  const [upd_flg, setUpdflg] = useState('0');   //更新用フラグ
  const [KBNLIST, setKBNLIST] = useState([]); // 原産地区分
  const [SYOKUKBNLIST, setSYOKUKBNLIST] = useState([]);                 // 伝票区分リスト

  //項目のバックカラー制御用
  const [inBMNCDEclr,setBMNCDEclr] = useState('#FFFFFF');
  const [inHASDDclr,setHASDDclr] = useState('#FFFFFF');
  const [inHASDD2clr,setHASDD2clr] = useState('#FFFFFF');
  const [inDENNOclr,setDENNOclr] = useState('#FFFFFF');
  const [inDENNO2clr,setDENNO2clr] = useState('#FFFFFF');
  const [inUIR_MISEclr,setUIR_MISEclr] = useState('#FFFFFF');
  const [inJGNCDEclr,setJGNCDEclr] = useState('#FFFFFF');

  const inHASDD = useRef(null);
  const inHASDD2 = useRef(null);
  const inBMNCDE = useRef(null);
  const inDENNO = useRef(null);
  const inDENNO2 = useRef(null);
  const inGNKFOT = useRef(null);
  const inBAKFOT = useRef(null);
  const inSRYFOT = useRef(null);
  const inUIR_MISE = useRef(null);
  const inJGNCDE = useRef(null);
  const inJOTKBN = useRef(null);

  const [enableSearch, setEnableSearch] = useState(false);

  const [selectionModel, setSelectionModel] = useState([]);
  const [pagenationModel, setPagenationModel] = useState({page: 0, pageSize: 10});

  const [jotaiKbn, setJotaiKbn] = useState('1');

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);
    if (state.KENGEN === '99')
    {
      setUpdflg('1');
    }

    // 画面の復元
    console.log('TCHP0160 location.state');
    console.log(location.state);
    if (location.state != null) {
      setBmnCd(location.state.searchCondition.bmnCd);
      setBrumei(location.state.searchCondition.sbrumei);
      setHassobi1(location.state.searchCondition.hassobi1);
      setHassobi2(location.state.searchCondition.hassobi2);
      setDennno1(location.state.searchCondition.dennno1);
      setDennno2(location.state.searchCondition.dennno2);
      setMiseCd(location.state.searchCondition.miseCd);
      setMisemei(location.state.searchCondition.miseMei);
      setTanCd(location.state.searchCondition.tanCd);
      setJgnmei(location.state.searchCondition.jgnmei);
      setJotaiKbn(location.state.searchCondition.jotaiKbn);
      setRows(location.state.grid.rows);
      setSelectionModel(location.state.grid.selectionModel);
      setPagenationModel(location.state.grid.pagenationModel);
      // 合計数量
      const totalSuryo = location.state.grid.rows.reduce((sum, element) => sum + parseFloat(element.SUU), 0);
      setTotalSuryo(totalSuryo);
      // 原価金額合計
      const totalGenka = location.state.grid.rows.reduce((sum, element) => sum + parseInt(element.GNKKIN), 0);
      setTotalGenka(totalGenka);
      // 売価金額合計
      const totalBaika = location.state.grid.rows.reduce((sum, element) => sum + parseInt(element.BAKKIN), 0);
      setTotalBaika(totalBaika);
    }

  }, []);

  const getOriginKbnList = async(filteredRows) => {
    // 原産地区分情報（使用可能区分）取得
    const response = await fetch('/TCID0161/getMrPrdReg',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(filteredRows)
    });
    return response.json();
  };

  // 食品区分取得
  const getSyokuKbn = async() => {
    // 食品区分情報(使用可能区分）取得
    const getSyokuKbnCd = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({CDE: '007',SCDE: '0000'})});
    const syokuKbnCdList = await getSyokuKbnCd.json();

    // 食品区分情報(区分に対する名称）取得
    const getSyokuKbnName = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({CDE: '007',SCDE: '0010'})});
    const syokuKbnNameList = await getSyokuKbnName.json();

    // 伝票区分リスト化
    setSYOKUKBNLIST([]);
    setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE01,name:syokuKbnNameList.DATE01}]);
    setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE02,name:syokuKbnNameList.DATE02}]);
    setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE03,name:syokuKbnNameList.DATE03}]);
    setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE04,name:syokuKbnNameList.DATE04}]);
    setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE05,name:syokuKbnNameList.DATE05}]);
    setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE06,name:syokuKbnNameList.DATE06}]);
    setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE07,name:syokuKbnNameList.DATE07}]);
    setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE08,name:syokuKbnNameList.DATE08}]);
    setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE09,name:syokuKbnNameList.DATE09}]);
    setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE10,name:syokuKbnNameList.DATE10}]);
  };

  // 伝票区分取得
  const getDenKbnList = async() => {
    // 伝票区分情報(使用可能区分）取得
    // const getDenKbnCd = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    // body: JSON.stringify({CDE: '004',SCDE: '0000'})});
    const getDenKbnCd = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({CDE: '009',SCDE: '0000'})});
    const denKbnCdList = await getDenKbnCd.json();

    // 伝票区分情報(区分に対する名称）取得
    // const getDenKbnName = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    // body: JSON.stringify({CDE: '004',SCDE: '0010'})});
    const getDenKbnName = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({CDE: '009',SCDE: '0010'})});
    const denKbnNameList = await getDenKbnName.json();

    // 伝票区分リスト化
    setKBNLIST([]);
    setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE01,name:denKbnNameList.DATE01}]);
    setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE02,name:denKbnNameList.DATE02}]);
    setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE03,name:denKbnNameList.DATE03}]);
    setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE04,name:denKbnNameList.DATE04}]);
    setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE05,name:denKbnNameList.DATE05}]);
    setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE06,name:denKbnNameList.DATE06}]);
    setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE07,name:denKbnNameList.DATE07}]);
    setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE08,name:denKbnNameList.DATE08}]);
    setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE09,name:denKbnNameList.DATE09}]);
    setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE10,name:denKbnNameList.DATE10}]);
  };

  /**
   * 日付が未来日付か確認する。
   * @param strYYYYMMDD 年月日(YYYYMMDD)
   * @returns true:未来　false:過去（本日含む）
   */
  function ckDate (strYYYYMMDD){
    let y = strYYYYMMDD.substring(0, 4);
    let m = strYYYYMMDD.substring(4, 6);
    let d = strYYYYMMDD.substring(6, 8);
    let date = new Date(y,m,d);
    date.setMonth(date.getMonth()-1);
    //console.log('date1:%o',date.toLocaleDateString());

    let maxdate = new Date(sdate);
    //console.log('maxdate:%o',maxdate.toLocaleDateString());
    if(date > maxdate){
      // window.alert('未来日を指定することはできません。'); //TCGE00XX：
      return true;
    }else{
      return false;
    }
  }

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、次項目情報
    //console.log(key);
    switch (key) {
      case "Enter":
        //inputNxRef.current.focus();   //次の項目に遷移
        setBMNCDEclr('#FFFFFF');
        setHASDDclr('#FFFFFF');
        setHASDD2clr('#FFFFFF');
        setDENNOclr('#FFFFFF');
        setDENNO2clr('#FFFFFF');
        setUIR_MISEclr('#FFFFFF');
        setJGNCDEclr('#FFFFFF');
        switch (inputRef.current.id) {
          case 'BMNCDE':
            await onKeyDown_BMNCD();
            break;
          case 'HASDD':
            await onKeyDown_HASDD();
            break;
          case 'HASDD2':
            await onKeyDown_HASDD2();
            break;
          case 'DENNO':
            await onKeyDown_DENNO();
            break;
          case 'DENNO2':
            await onKeyDown_DENNO2();
            break;
          case 'UIR_MISE':
            await onKeyDown_UIR_MISE();
            break;
          case 'JGNCDE':
            await onKeyDown_JGNCDE();
            break;
          }
        break;
      case "Return":
        navigate('/TCSA0003');
        break;
      case "TCHP0161":
        navigate('/TCHP0161');
        break;
      case "Home":
        break;
      case "ArrowUp":
        inputRtRef.current.focus();   //次の項目に遷移
        break;
      case "ArrowDown":
        inputNxRef.current.focus();   //次の項目に遷移
        break;
      default:
        break;
    }
  };

  // ヘッダ部１入力　部門CD（初期カーソル）
  const onKeyDown_BMNCD = async () => {
    if (inBMNCDE.current.value === '')
    {
      inHASDD.current.focus();   // 次の項目に遷移
      return;
    }
    // 入力値の前0詰め表示
    var padBmnCd = inBMNCDE.current.value.padStart(4, '0');
    setBmnCd(padBmnCd);
    // 部門CDチェック
    const responseB1 = await fetch('/TCID0160/existsBumonCd',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        KSACDE: state.KSACDES,
        MISE: state.MISES,
        BMNCDE: padBmnCd,
        RCNT:0})});
    const dataB1 = await responseB1.json();
    if (dataB1.RCNT === 0) {
      setBMNCDEclr('#f59d9d');
      window.alert('部門コードは存在しません。'); //TCGE0007：
      return;
    }

    // 部門名取得
    const responseB2 = await fetch('/TCID0160/getBumonName',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        KSACDE: state.KSACDE,
        BMNCDE: padBmnCd,
        RCNT:0})});
    const dataB2 = await responseB2.json();
    if (dataB2 != null) {
      setBrumei(dataB2.BRUMEI);
      inHASDD.current.focus();   // 次の項目に遷移
      return
    }
  }

  // ヘッダ部１入力　　発送日From
  const onKeyDown_HASDD = async () => {
    if (inHASDD.current.value === '')
    {
      inHASDD2.current.focus();
      return;
    }

    // 日付チェック
    let strDate = inHASDD.current.value;
    let indate = new Date(strDate);
    if (isNaN(indate.getDate())) {
      setHASDDclr('#f59d9d');
      window.alert('日付入力エラー'); //TCGE0016：
      return;
    }

    // システム日－２か月≦入力日（西暦）＜システム日の日付範囲チェック
    let maxdate = new Date(sdate);
    let mindate = new Date(sdate);
    mindate.setMonth(mindate.getMonth() - 2);
    console.log('日付範囲チェック'+maxdate+'>='+indate+'&&'+mindate+'<='+indate);
    // if (!(mindate < indate && indate <= maxdate)) {
    //   setHASDDclr('#f59d9d');
    //   window.alert('入力された日付は移動対象期間外です。'); //TCGE0072：
    //   return;
    // }

    // 発送日From＞発送日TOチェック
    let HASDDto = inHASDD2.current.value;
    let to = new Date(HASDDto);
    if (!isNaN(to.getTime())) {
      if (indate.getTime() > to.getTime()) {
        setHASDDclr('#f59d9d');
        window.alert('日付の大小関係に誤りがあります。'); //TCGE0065：
        return;
      }
    }

    // 次の項目に遷移
    inHASDD2.current.focus();
  }

  // ヘッダ部１入力　発送日To
  const onKeyDown_HASDD2 = async () => {
    if (inHASDD2.current.value === '')
    {
      inDENNO.current.focus();
      return;
    }

    // 日付チェック
    let strDate = inHASDD2.current.value;
    let indate = new Date(strDate);
    if (isNaN(indate.getDate())) {
      setHASDD2clr('#f59d9d');
      window.alert('日付入力エラー'); //TCGE0016：
      return;
    }

    // システム日－２か月≦入力日（西暦）＜システム日の日付範囲チェック
    let maxdate = new Date(sdate);
    let mindate = new Date(sdate);
    mindate.setMonth(mindate.getMonth() - 2);
    console.log('日付範囲チェック'+maxdate+'>='+indate+'&&'+mindate+'<='+indate);
    // if (!(mindate < indate && indate <= maxdate)) {
    //   setHASDD2clr('#f59d9d');
    //   window.alert('入力された日付は移動対象期間外です。'); //TCGE0072：
    //   return;
    // }

    // 発送日From＞発送日TOチェック
    let HASDDfrom = inHASDD.current.value;
    let from = new Date(HASDDfrom);
    if (!isNaN(from.getTime())) {
      if (from.getTime() > indate.getTime()) {
        setHASDD2clr('#f59d9d');
        window.alert('日付の大小関係に誤りがあります。'); //TCGE0065：
        return;
      }
    }

    // 次の項目に遷移
    inDENNO.current.focus();
  }

  // ヘッダ部１入力　伝票番号From
  const onKeyDown_DENNO = async () => {
    if (inDENNO.current.value === '')
    {
      inDENNO2.current.focus();
      return;
    }
    // エンター時、前0を付与
    setDennno1(inDENNO.current.value.padStart(7, '0'));

    // 伝票番号From＞伝票番号TOチェック
    let DENNOfrom  = inDENNO.current.value;
    let DENNOto = inDENNO2.current.value;
    if (DENNOfrom != '' && DENNOto != '') {
      let from = Number(DENNOfrom);
      let to = Number(DENNOto);
      if (from > to) {
        setDENNOclr('#f59d9d');
        window.alert('伝票番号の大小関係に誤りがあります。'); //TCGE0066：
        return;
      }
    }

    // 次の項目に遷移
    inDENNO2.current.focus();
  }

  // ヘッダ部１入力　伝票番号To
  const onKeyDown_DENNO2 = async () => {
    if (inDENNO2.current.value === '')
    {
      inUIR_MISE.current.focus();
      return;
    }
    // エンター時、前0を付与
    setDennno2(inDENNO2.current.value.padStart(7, '0'));

    // 伝票番号From＞伝票番号TOチェック
    let DENNOfrom  = inDENNO.current.value;
    let DENNOto = inDENNO2.current.value;
    if (DENNOfrom != '' && DENNOto != '') {
      let from = Number(DENNOfrom);
      let to = Number(DENNOto);
      if (from > to) {
        setDENNO2clr('#f59d9d');
        window.alert('伝票番号の大小関係に誤りがあります。'); //TCGE0066：
        return;
      }
    }

    // 次の項目に遷移
    inUIR_MISE.current.focus();
  }

  // ヘッダ部１入力　受取店
  const onKeyDown_UIR_MISE = async () => {
    if (inUIR_MISE.current.value === '')
    {
      inJGNCDE.current.focus();
      return;
    }
    // エンター時、前0を付与
    var padMiseCd = inUIR_MISE.current.value.padStart(3, '0');
    setMiseCd(padMiseCd);

    // 受取店CDチェック
    const responseB1 = await fetch('/TCID0160/existsMiseCd',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        KSACDE: state.KSACDES,
        MISE: state.MISES,
        UIR_MISE: padMiseCd,
        RCNT:0})});
    const dataB1 = await responseB1.json();
    if (dataB1.RCNT === 0) {
      setUIR_MISEclr('#f59d9d');
      window.alert('受取店が存在しません。'); //TCGE0074：
      return;
    }

    // 受取店名取得
    const responseB2 = await fetch('/TCID0160/getMiseName',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        KSACDE: state.KSACDE,
        UIR_MISE: inUIR_MISE.current.value,
        RCNT:0})});
    const dataB2 = await responseB2.json();
    if (dataB2 != null) {
      setMisemei(dataB2.MISEMEI);
      inJGNCDE.current.focus();   // 次の項目に遷移
      return
    }
  }

  // ヘッダ部１入力　担当者コード
  const onKeyDown_JGNCDE = async () => {
    if (inJGNCDE.current.value === '')
    {
      inJOTKBN.current.focus();
      return;
    }
    // エンター時、前0を付与
    //var padJgnCd = inJGNCDE.current.value.padStart(10, '0');
    var padJgnCd = inJGNCDE.current.value.padStart(7, '0');
    setTanCd(padJgnCd);

    // 従業員コードチェック
    const responseB1 = await fetch('/TCID0160/existsTantosyaCd', {method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        KSACDE: state.KSACDES,
        MISE: state.MISES,
        JGNCDE: padJgnCd,
        RCNT:0})});
    const dataB1 = await responseB1.json();
    if (dataB1.RCNT === 0) {
      setJGNCDEclr('#f59d9d');
      window.alert('従業員コードは存在しません。'); //TCGE0006：
      return;
    }

    // 担当者名取得
    const responseB2 = await fetch('/TCID0160/getTantosyaName', {method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        KSACDE: state.KSACDE,
        JGNCDE: inJGNCDE.current.value,
        RCNT:0})});
    const dataB2 = await responseB2.json();
    if (dataB2 != null) {
      setJgnmei(dataB2.JGNMEIKNJ);
      inJOTKBN.current.focus();   // 次の項目に遷移
      return
    }
  }

  // ヘッダ部１入力　状態区分を選択の処理
  // コンボボックスでEnterキーのイベントを補足できない模様
  // 代わりに状態区分が変更されたら検索を行う
  useEffect(()=>{
    //console.log('useEffect JOTKBN' + jotaiKbn + ' / ' + enableSearch);
    if (!enableSearch) {
      return;
    }
    onClick_HeaderButton_Search(null);
  }, [jotaiKbn]);

  // ヘッダ部１入力　検索ボタン　押下
  const onClick_HeaderButton_Search = async () => {
    //console.log("検索ボタン　押下");
    // 明細データ抽出
    failedSearch();
    console.log('食品区分リストの取得')
    await getSyokuKbn();

    console.log('原産地区分リストの取得')
    await getDenKbnList();

    const responseB1 = await fetch('/TCID0160/search', {method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        KSACDE: state.KSACDES,
        MISE: state.MISES,
        BMNCDE: inBMNCDE.current.value,   // 部門
        HASDD: inHASDD.current.value,     // 日付From
        HASDD2: inHASDD2.current.value,   // 日付To
        DENNO: inDENNO.current.value,     // 伝票番号From
        DENNO2: inDENNO2.current.value,   // 伝票番号To
        UIR_MISE: inUIR_MISE.current.value,   // 受取店コード
        JGNCDE: inJGNCDE.current.value,     // 従業員コード
        JOTKBN: inJOTKBN.current.value,   // 状態
        RCNT:0})});
    const dataB1 = await responseB1.json();
    if (dataB1.CNT === 0) {
      // データが存在しない場合は、以下のメッセージを表示して、明細の0行表示を行う。
      window.alert('条件に一致するデータが存在しません。'); //TCGE0065：
      failedSearch();
      setTotalSuryo(0);
      setTotalGenka(0);
      setTotalBaika(0);
      return;
    }
    if (dataB1.CNT > dataB1.MAXCNT) {
      // 抽出結果が９９９件を超えてしまった場合は、再抽出を促すメッセージを表示する。
      window.alert('条件に一致するデータが多すぎます。条件を見直してください。'); //TCGE0066：
      return;
    }

    let id = 1;
    const newRows = [];
    dataB1.ret.forEach(element => {
      newRows.push({
        id: id,
        HASDD:element.HASDD,  //日付
        //SHNMEIKN: '１２３４５６７８９０１２３４５６７８',
        BMN: element.BMNCDE + "-" + element.ASTCDE,  // 発送部門名
        UIR_MISE: element.UIR_MISEMEI,   // 受取店名
        UIR_BMN: element.UIR_BMNCDE + "-" + element.UIR_ASTCDE,   // 受取部門名
        DENNO: element.DENNO,    // 伝票番号
        SUU: element.SRYFOT,   // 数量合計
        GNKKIN:element.GNKFOT,   // 原価金額合計
        BAKKIN:element.BAKFOT,   // 売価金額合計
        JGNCDE:element.IN_JGNMEIKNJ,   // 担当者名
        JOTKBN:element.JOTKBN,   // 状態区分
        DELFLG:element.DELFLG,  // 削除フラグ
        KEY: {
          KSACDE: element.KSACDE,
          MISE: element.MISE,
          BMNCDE: element.BMNCDE,
          ASTCDE: element.ASTCDE,
          DENYMD: element.DENYMD,
          DENNO: element.DENNO,
          DEN_EDABAN: element.DEN_EDABAN,
          SEQ: element.SEQ,
        },
        // 引き渡しデータ用
        ASTCDE: element.ASTCDE,   // AU
        ASTMEI: element.ASTMEI,   // AU名
        UIR_ASTCDE: element.UIR_ASTCDE,   // 受取AU
        UIR_ASTMEI: element.UIR_ASTMEI,   // 受取AU名
        BMNCDE: element.BMNCDE,   // 部門コード
        BMNMEI: element.BMNMEI,   // 部門名
        UIR_BMNCDE: element.UIR_BMNCDE,   // 受取部門コード
        UIR_BMNMEI: element.UIR_BMNMEI,   // 受取部門名
        TENPONM: element.TENPONM,
      });
      id++;
    });

    setRows(newRows);

    // 合計数量
    const totalSuryo = newRows.reduce((sum, element) => sum + parseFloat(element.SUU), 0);
    setTotalSuryo(totalSuryo);
    // 原価金額合計
    const totalGenka = newRows.reduce((sum, element) => sum + parseInt(element.GNKKIN), 0);
    setTotalGenka(totalGenka);
    // 売価金額合計
    const totalBaika = newRows.reduce((sum, element) => sum + parseInt(element.BAKKIN), 0);
    setTotalBaika(totalBaika);

    // 最初の削除明細でない明細にフォーカスを移動する
    // const firstValidRow = newRows.find(x => x.DELFLG == '0');
    // if (firstValidRow != null) {
    //   setSelectionModel([ firstValidRow.id ]);
    // } else {
    //   // 削除明細でない明細が存在しない
    //   setSelectionModel([]);
    // }
  }

  // 検索失敗時の明細0件表示
  const failedSearch = async () => {
    await setRows([]);
  }

  // ヘッダ部１入力　条件再入力ボタン　押下
  const onClick_HeaderButton_Clear = async () => {
    //console.log("条件再入力ボタン　押下");
    setBmnCd('');
    setBrumei('');
    setBMNCDEclr('#FFFFFF');
    setHassobi1('');
    setHASDDclr('#FFFFFF');
    setHassobi2('');
    setHASDD2clr('#FFFFFF');
    setDennno1('');
    setDENNOclr('#FFFFFF');
    setDennno2('');
    setDENNO2clr('#FFFFFF');
    setMiseCd('');
    setMisemei('');
    setTanCd('');
    setJgnmei('');
    setJGNCDEclr('#FFFFFF');
    setJotaiKbn('1');
    setRows([]);
    setTotalSuryo(0);
    setTotalGenka(0);
    setTotalBaika(0);

    setUIR_MISEclr('#FFFFFF');
    inBMNCDE.current.focus();
  }

  // テイル部処理　伝票明細ボタン　押下
  const onClick_FooterButton_Detail = async () => {
    // 伝票明細参照画面を表示する。
    const selected = rows.filter(x => selectionModel.indexOf(x.id) != -1);
    if (selected.length == 0) {
      return;
    }

    const target = selected[0];
    console.log('test:%o', target);

    navigate('/TCHP0161', {
      state: {
        detailKey: target.KEY,
        searchCondition: {
          bmnCd: bmnCd,
          sbrumei:sbrumei,
          hassobi1: hassobi1,
          hassobi2: hassobi2,
          dennno1: dennno1,
          dennno2: dennno2,
          miseCd: miseCd,
          miseMei: miseMei,
          tanCd: tanCd,
          jgnmei: jgnmei,
          jotaiKbn: jotaiKbn,
        },
        grid: {
          rows: rows,
          selectionModel: selectionModel,
          pagenationModel: pagenationModel,
        },
      },
    });
  }

  // テイル部処理　伝票削除ボタン　押下
  const onClick_FooterButton_Delete = async () => {
    const selected = rows.filter(x => selectionModel.indexOf(x.id) != -1);
    console.log(selected);
    //const diffRows = rows.filter(x =>diff.indexOf(x.id) != -1);
    if (selected.length == 0) {
      // 送信済の伝票は削除できないのでメッセージを表示する。
      window.alert('伝票が選択されていません。再度選択してください。'); //TCGE0071：
      return;
    }

    const delRows = selected.filter(x => x.JOTKBN == '3');
    if (delRows.length > 0) {
      // 送信済の伝票は削除できないのでメッセージを表示する。
      window.alert('送信済の伝票は、削除できません。'); //TCGE0067：
      return;
    }

    // エラーが存在しない場合、削除処理を行うか確認メッセージを表示する
    if (!(window.confirm('選択された伝票を削除します。よろしいですか？'))) {  //TCGE0068：
      return;
    }

    // 選択されている伝票の削除を行う。　
    const targetRows = selected.map(targetRow => {
      return {
        KSACDE: targetRow.KEY.KSACDE,
        MISE: targetRow.KEY.MISE,
        BMNCDE: targetRow.KEY.BMNCDE,
        DENYMD: targetRow.KEY.DENYMD,
        DENNO: targetRow.KEY.DENNO,
        DEN_EDABAN: targetRow.KEY.DEN_EDABAN.replace(/ /g, ''),
        SEQ: targetRow.KEY.SEQ,
        upd_jgncde: state.JGNCDE,
        upd_prg_id: 'TCHP0160',
        RCNT: 0
      };
    });
    console.log('targetRows');
    console.log(targetRows);
    const responseB1 = await fetch('/TCID0160/delete', {method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(targetRows)});
    const dataB1 = await responseB1.json();
    if (dataB1.res === -1) {
      //setSIRCDEclr('#f59d9d');
      window.alert('削除エラー');
      return;
    }

    // 明細部（合計欄含む）の初期化を行い、再検索を行う。
    onClick_HeaderButton_Search(null);
  }

  // テイル部処理　伝票発行ボタン　押下
  const onClick_FooterButton_Print2 = async () => {
    const selected = rows.filter(x => selectionModel.indexOf(x.id) != -1);
    if (selected.length == 0) {
      // 対象データがない場合はメッセージを表示する。
      window.alert('伝票が選択されていません。再度選択してください。'); //TCGE0071：
      return;
    }

    // 印刷処理を行うか確認メッセージを表示する
    if (!(window.confirm('印刷処理を行います。よろしいですか？'))) {  //TCGE0069：
      return;
    }

    // 選択されている伝票の印刷を行う。　
    const targetRows = selected.map(targetRow => {
      return {
        KSACDE: state.KSACDES,
        MISE: state.MISES,
        BMNCDE: targetRow.KEY.BMNCDE,
        DENYMD: targetRow.KEY.DENYMD,
        DENNO: targetRow.KEY.DENNO,
        DEN_EDABAN: targetRow.KEY.DEN_EDABAN.replace(/ /g, ''),
        SEQ: targetRow.KEY.SEQ,
        upd_prg_id: 'TCHP0150',
        RCNT: 0,
        ASTCDE: targetRow.KEY.ASTCDE,
      };
    });
    console.log('targetRows');
    console.log(targetRows);
    const responseB01 = await fetch('/TCID0160/IDDN0260',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        ENT_KSACDE: state.KSACDES,
        ENT_MISE: state.MISES,
        JGNCDE: state.JGNCDE,
        LOGIN_NAME:state.JGNMEIKNJ,    //★★１行追加お願いします（09/15亀田）
        KBNLIST:KBNLIST,
        SYOKUKBNLIST:SYOKUKBNLIST,
        DATAS: targetRows,
        UPD_FLG:upd_flg
      })
    });
    const dataB02 = await responseB01.json();
    console.log(dataB02);

    // データ更新
    const responseB1 = await fetch('/TCID0160/denpyo', {method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
        ENT_KSACDE: state.KSACDES,
        ENT_MISE: state.MISES,
        JGNCDE: state.JGNCDE,
        DATAS: targetRows
      })
    });
    const dataB1 = await responseB1.json();
    if (dataB1.res === -1) {
      //setSIRCDEclr('#f59d9d');
      window.alert('更新エラー');
      return;
    }

    onClick_HeaderButton_Search(null);
  }

  // テイル部処理　送信ボタン　押下
  const onClick_FooterButton_Send = async () => {
    // 発行済の伝票で無い場合は、以下のメッセージを表示する。
    const selected = rows.filter(x => selectionModel.indexOf(x.id) != -1);
    const notHakkkozumi = selected.filter(x => x.JOTKBN == '2' && x.DELFLG == '0');
    if (notHakkkozumi.length === 0) {
      window.alert('発行済の伝票が選択されていません。再度選択してください。'); //TCGE0071：
      return;
    }

    const overRows = selected.filter(x => ckDate(x.HASDD));
    if (overRows.length !== 0) {
      window.alert('未来日を指定することはできません。'); //TCGE00XX：
      return;
    }

    // データ更新　「送信時」を行う
    const targetRows = selected.map(targetRow => {
      return {
        KSACDE: state.KSACDES,
        MISE: state.MISES,
        BMNCDE: targetRow.KEY.BMNCDE,
        DENYMD: targetRow.KEY.DENYMD,
        DENNO: targetRow.KEY.DENNO,
        DEN_EDABAN: targetRow.KEY.DEN_EDABAN.replace(/ /g, ''),
        SEQ: targetRow.KEY.SEQ,
        upd_jgncde: state.JGNCDE,
        upd_prg_id: 'TCHP0160',
        RCNT: 0,
        ASTCDE: targetRow.KEY.ASTCDE,
      };
    });

    // 送信処理を行うか確認メッセージを表示する
    // if (!(window.confirm('TCGE0070：送信処理を行います。よろしいですか？'))) {
    if (!(window.confirm('送信処理を行います。よろしいですか？\n送信件数：'+targetRows.length))) {  //TCGE0070：
      return;
    }

    console.log('targetRows'+state.KSACDES+'|'+state.MISES);
    console.log(targetRows);
    const responseB1 = await fetch('/TCID0160/send', {method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(targetRows)});
    const dataB1 = await responseB1.json();

    // 処理画面表示
    window.alert(`送信処理完了\n`
      + `正常件数：${dataB1.res.okCount}件\n`
      + `異常件数：${dataB1.res.ngCount}件`);

    // 明細部（合計欄含む）の初期化を行い、再検索を行う。
    onClick_HeaderButton_Search(null);
  }

  // 状態区分の名称
  const getJotaiKbnName = (jotaiKbn) => {
    switch (jotaiKbn) {
      case '1': return '未発行';
      case '2': return '発行済';
      case '3': return '送信済';
      case '4': return '依頼済';
      case '5': return '計上済';
      case '8': return '削除済';
      default: return '';
    }
  }

  // 表示日付フォーマット
  const formatDate = (dateString) => {
    if(dateString === null){ return dateString; }

    const fmtYear = dateString.substr(0, 4);
    const fmtMonth = dateString.substr(4, 2);
    const fmtDay = dateString.substr(6, 2);

    return `${fmtYear}/${fmtMonth}/${fmtDay}`;
  }
  // 金額表示フォーマット
  const formatCurrency = (amount) => {
    if(amount === null){ return amount; }

    return amount.toLocaleString('ja-JP', {
      style: 'currency',
      currency: 'JPY',
      minimumFractionDigits: 0,
    });
  }
  // 数量フォーマット
  const formatSUU = (amount) => {
    if(amount === null){ return amount; }

    // 数値が整数かどうかを判定
    if (Number.isInteger(amount)) {
      return amount.toLocaleString('ja-JP', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
      });
    } else {
      return amount.toLocaleString();
    }
  }

  // グリッド列情報
  const columns/*: GridColDef[]*/ = [
    // {
    //   field: 'detailAction',
    //   headerName: '　', // セルの右側に罫線を表示するために全角スペースを表示する
    //   align: 'left',
    //   width: 60,
    //   type: 'actions',
    //   // getActions: getDetailAction,
    //   description: '詳細画面を表示',  // ツールチップで表示される説明
    //   hideable: false,          // 非表示にできないようにする
    //   sortable: false,          // ソートできないようにする
    //   hideSortIcons: false,     // ソートアイコンを表示しない
    //   resizable: false,         // リサイズできないようにする
    //   disableExport: false,     // エクスポートに含めないようにする
    //   disableReorder: false,    // 並べ替えできないようにする
    //   filterable: false,        // フィルタリングしないようにする
    //   disableColumnMenu: true  // カラムメニューを表示しない
    // } ,

    //IDは必ず必要みたい
    {
      field: 'id',
      headerName: 'No.',
      align:'center',
      width: 50 ,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
    },
    {
      field: 'HASDD',
      headerName: '発送日',
      headerAlign: 'center',
      align:'center',
      width: 100 ,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
          <Typography>{formatDate(params.value)}</Typography>
      )
    },
    {
      field: 'BMN',
      headerName: '発送部門-AU',
      headerAlign: 'center',
      width: 125,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true   // カラムメニューを表示しない
    },
    {
      field: 'UIR_MISE',
      headerName: '受取店名',
      headerAlign: 'center',
      width: 300,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true   // カラムメニューを表示しない
    },
    {
      field: 'UIR_BMN',
      headerName: '受取部門-AU',
      headerAlign: 'center',
      width: 125,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true   // カラムメニューを表示しない
    },
    {
      field: 'DENNO',
      headerName: '伝票番号',
      headerAlign: 'center',
      width: 100,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true   // カラムメニューを表示しない
    },
    {
      field: 'SUU',
      headerName: '数量',
      type: 'number',
      headerAlign: 'center',
      width: 80,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (formatSUU(params.value))
    },
    {
      field: 'GNKKIN',
      headerName: '原価金額合計',
      type: 'number',
      headerAlign: 'center',
      width: 110,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{formatCurrency(params.value)}</Typography>
        </div>
      )
    },
    {
      field: 'BAKKIN',
      headerName: '売価金額合計',
      type: 'number',
      headerAlign: 'center',
      width: 110,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{formatCurrency(params.value)}</Typography>
        </div>
      )
    },
    {
      field: 'JGNCDE',
      headerName: '担当者',
      headerAlign: 'center',
      width: 120,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true   // カラムメニューを表示しない
    },
    {
      field: 'JOTKBN',
      headerName: '状態',
      headerAlign: 'center',
      align:'center',
      width: 98,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => getJotaiKbnName(params.value)
    },
  ];

  //罫線表示（Data Grid）
  const gridStyle = {
    transform: 'scale(0.9)',
    // 他のスタイルプロパティをここに追加することもできます
  };

  //罫線表示（Data Grid）
  const styles = {
    grid: {
      '.MuiDataGrid-toolbarContainer': {
        borderBottom: 'solid 1px rgba(224, 224, 224, 1)'  // ついでにツールバーの下に罫線を引く
      },
      '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
        borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      // 2022/06/01 追記 列ヘッダの最終セルの右側に罫線が表示されなくなった・・・
      // 列ヘッダの最終列の右端に罫線を表示する
      '.MuiDataGrid-columnHeadersInner': {
        border: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      '.MuiDataGrid-columnSeparator--sideRight':{
        visibility: 'show',
      },
      '.MuiDataGrid-columnHeaders':{
        height:'40px',
      },
      // 削除済明細は背景色をグレーとする
      '.MuiDataGrid-row.row-deactive': {
        background: '#888888',
      },
      // 選択状態を黄色にする 2023/12/04 add imai
      '.MuiDataGrid-row.Mui-selected':{
        background: '#ffff00',
        '&:hover': {
          background: '#ffff00',       // ホバー時のバックグランドカラー
        },
      }
    }
  }

  return (
    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "750px",
          width: "1260px",
          m: "0px auto",
          padding:"10px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="left"
        >
          <div style={{display:"flex"}}>
            <Typography variant={"h5"} sx={{ m: '5px',height:'30px' }}>
            移動伝票確認・送信
            </Typography>
            <Box width={'65%'} display={'flex'} justifyContent={'flex-end'}>
              <Typography variant={"h6"} sx={{ m: "13px" }}>
              {state.MISEMEI}：{state.JGNMEIKNJ}
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px" }}>
                { sdate }
              </Typography>
            </Box>
          </div>
        </Grid>
        <Stack direction="row" sx={{mb:'10px'}}>
          <NumericFormat  //部門
              id={'BMNCDE'}
              inputRef={inBMNCDE}                   //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='発送部門'                       //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}           //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '8ch',background:inBMNCDEclr}}                 //textboxの幅
              value={bmnCd}                         //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={false}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center',touchAction: 'none'} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onKeyDown={(e) => onKeyDown(e.key,inBMNCDE,inBMNCDE,inHASDD)}
              onChange={e => setBmnCd(e.target.value)}
              onFocus={(e) => e.target.select()}
              />
          <InputLabel variant={'outlined'} >{sbrumei}</InputLabel>
        </Stack>
        <Stack direction="row" sx={{mb:'10px'}}>
          {/* <TextField  //発送日
              id={'HASDD'}
              inputRef={inHASDD}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              size='small'                        //textboxの全体サイズ
              label='発送日'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}            //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '10ch',background:inHASDDclr}}                 //textboxの幅
              value={hassobi1}                  //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={false}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 8,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              onKeyDown={(e) => onKeyDown(e.key,inHASDD,inBMNCDE,inHASDD2)}
              onChange={e => setHassobi1(e.target.value)}
              /> */}
          <PatternFormat  //発送日
              id={'HASDD'}
              inputRef={inHASDD}                   //項目名定義？
              format='####/##/##'
              mask={['Y','Y','Y','Y','M','M','D','D']}
              color='success'
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='発送日'                        //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              onValueChange={(values, sourceInfo) => {
                // console.log(values.value);
                // console.log(values.formattedValue);
                // console.log(values.floatValue);
              }}
              sx={{width: '13ch',background:inHASDDclr}}                 //textboxの幅
              value={hassobi1}
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={false}                    //使用可／不可
              placeholder='YYYY/MM/DD'
              //inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'center',touchAction: 'none'} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onKeyDown={(e) => onKeyDown(e.key,inHASDD,inBMNCDE,inHASDD2)}
              onChange={(e) => setHassobi1(e.target.value)}
              />
          <InputLabel variant={'outlined'} >～</InputLabel>
          {/* <TextField  //発送日2
              id={'HASDD2'}
              inputRef={inHASDD2}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              size='small'                        //textboxの全体サイズ
              label=''                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}            //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '10ch',marginLeft:'20px',background:inHASDD2clr}}                 //textboxの幅
              value={hassobi2}                  //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={false}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 8,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              onKeyDown={(e) => onKeyDown(e.key,inHASDD2,inHASDD,inDENNO)}
              onChange={e => setHassobi2(e.target.value)}
              /> */}
          <PatternFormat  //発送日2
              id={'HASDD2'}
              inputRef={inHASDD2}                   //項目名定義？
              format='####/##/##'
              mask={['Y','Y','Y','Y','M','M','D','D']}
              color='success'
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label=''                        //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              onValueChange={(values, sourceInfo) => {
                // console.log(values.value);
                // console.log(values.formattedValue);
                // console.log(values.floatValue);
              }}
              sx={{width: '13ch',marginLeft:'20px',background:inHASDD2clr}}                 //textboxの幅
              value={hassobi2}
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={false}                    //使用可／不可
              placeholder='YYYY/MM/DD'
              //inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'center',touchAction: 'none'} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onKeyDown={(e) => onKeyDown(e.key,inHASDD2,inHASDD,inDENNO)}
              onChange={(e) => setHassobi2(e.target.value)}
              />
          <NumericFormat  //伝票No.
              id={'DENNO'}
              inputRef={inDENNO}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='伝票No.'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}            //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '10ch',marginLeft:'20px',background:inDENNOclr}}                 //textboxの幅
              value={dennno1}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={false}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 7,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 7,style: {textAlign: 'center',touchAction: 'none'} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onKeyDown={(e) => onKeyDown(e.key,inDENNO,inHASDD2,inDENNO2)}
              onChange={e => setDennno1(e.target.value)}
              onFocus={(e) => e.target.select()}
              />
          <InputLabel variant={'outlined'} >～</InputLabel>
          <NumericFormat  //伝票No.2
              id={'DENNO2'}
              inputRef={inDENNO2}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label=''                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}            //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '10ch',marginLeft:'20px',background:inDENNO2clr}}                 //textboxの幅
              value={dennno2}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={false}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 7,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 7,style: {textAlign: 'center',touchAction: 'none'} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onKeyDown={(e) => onKeyDown(e.key,inDENNO2,inDENNO,inUIR_MISE)}
              onChange={e => setDennno2(e.target.value)}
              onFocus={(e) => e.target.select()}
              />
        </Stack>
        <Stack direction="row" sx={{mb:'0px'}}>
          <NumericFormat  //受取店
              id={'UIR_MISE'}
              inputRef={inUIR_MISE}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='受取店'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}            //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '8ch',background:inUIR_MISEclr}}                 //textboxの幅
              value={miseCd}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={false}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 3,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 3,style: {textAlign: 'center',touchAction: 'none'} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onKeyDown={(e) => onKeyDown(e.key,inUIR_MISE,inDENNO2,inJGNCDE)}
              onChange={e => setMiseCd(e.target.value)}
              onFocus={(e) => e.target.select()}
              />
          <InputLabel variant={'outlined'} >{miseMei}</InputLabel>
          <TextField  //担当者CD
              id={'JGNCDE'}
              inputRef={inJGNCDE}                   //項目名定義？
              color='success'
              // thousandSeparator={false}           //1000単位の区切り
              // customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='担当者'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}            //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '11ch',marginLeft:'20px',backgroundColor:inJGNCDEclr}}                 //textboxの幅
              value={tanCd}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={false}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 7,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 7,style: {textAlign: 'center',touchAction: 'none'} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onKeyDown={(e) => onKeyDown(e.key,inJGNCDE,inUIR_MISE,inJOTKBN)}
              onChange={e => setTanCd(e.target.value)}
              onFocus={(e) => e.target.select()}
              />
          <InputLabel variant={'outlined'} >{jgnmei}</InputLabel>　
            <FormControl sx={{ minWidth: 120,maxWidth: 120,mr:'10px'}} size="small">
                <InputLabel id="select-label" color='success'>状態</InputLabel>
                <Select
                  inputRef={inJOTKBN}
                  color='success'
                  defaultValue={''}
                  labelId="select-label"
                  id="select-label"
                  value={jotaiKbn}
                  label="状態区分"
                  onChange={e => setJotaiKbn(e.target.value)}
                  onFocus={e => setEnableSearch(true)}
                  onBlur={e => setEnableSearch(false)}
                >
                {
                  JotKbnOptions.map((jotKbn) => {
                    if( jotKbn.value == 1 || jotKbn.value == 2 || jotKbn.value == 3 || jotKbn.value == 4 || jotKbn.value == 6 || jotKbn.value == 7 || jotKbn.value == 8 ){
                    return <MenuItem value={jotKbn.value} key={jotKbn.value}>{jotKbn.label}</MenuItem>
                    }else{
                      return null;
                    }
                  })
                }
              </Select>
            </FormControl>
            <InputLabel variant={'outlined'} >※全項目て省略可能　　　</InputLabel>
          <Box my={2} flexDirection="row" justifyContent="flex-end" display="flex">
          <Box ml={2}>
            <Button
              variant="contained"
              color='success'
              sx={{width:120,mt:'-25px'}}
              onClick={e => onClick_HeaderButton_Search(e)}
              onFocus={e => setEnableSearch(true)}
              onBlur={e => setEnableSearch(false)}
              >
              検　索
            </Button>　
            <Button variant="contained" color='success' sx={{width:120,mt:'-25px'}} onClick={e => onClick_HeaderButton_Clear(e)}>
              条件再入力
            </Button>
          </Box>
        </Box>
        </Stack>
          {/* <Box style={gridStyle} sx={{ display: 'flex', justifyContent: 'flex-start',height: 455, width: '1400px',padding:'0px',ml:'-70px',mt:'-30px' }}> */}
          <Box className="dataGridList" style={gridStyle} sx={{ display: 'flex', justifyContent: 'flex-start',height: 455, width: '1400px',padding:'0px',ml:'-70px',mt:'-30px' }}>
            <DataGrid
              sx={styles.grid}     // --> 不具合対応のためcssで罫線を引く
              rows={rows}
              columns={columns}
              density='compact'//compact（狭め）、standard（標準）、comfortable（広め）
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              checkboxSelection
              disableRowSelectionOnClick
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}  // メニュー日本語化
              showColumnRightBorder                                             // 列ヘッダセルの右側に線を引く
              showCellRightBorder                                               // セルの右側に線を引く
              getRowClassName={(params) => {
                if (params.row.DELFLG === '1') {
                  // 削除済明細にクラスを設定する
                  return 'row-deactive'
                }
                return '';
              }}
              rowSelectionModel={selectionModel}
              onRowSelectionModelChange={(RowIds) => {
                const diffRows = rows.filter(x =>RowIds.indexOf(x.id) != -1);
                const delRows = diffRows.filter(x => x.DELFLG == '1');
                if (delRows.length > 0) {
                  // 削除済明細の場合はクリックされた際に選択状態にしない
                  return;
                }
                setSelectionModel(RowIds);
              }}
              paginationModel={pagenationModel}
              onPaginationModelChange={(model) => {
                console.log(model);
                setPagenationModel(model);
              }}
              />
          </Box>
          <Box my={2} flexDirection="row" justifyContent="flex-end" display="flex">
            <Box ml={2} sx={{ mt:'-25px' }}>
              <NumericFormat  //合計数量
                id={'SRYFOT'}
                inputRef={inSRYFOT}                   //項目名定義？
                thousandSeparator={','}
                decimalScale={1}
                fixedDecimalScale={true}            //小数点On/Off
                color='success'
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='合計数量'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '17ch'}}                //textboxの幅
                value={totalSuryo}                  //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={true}                    //使用可／不可
                //inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
                inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right',touchAction: 'none'} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
                // onKeyDown={(e) => onKeyDown(e.key,inDENH,inTSEBAKFOT)}
                onFocus={(e) => e.target.select()}
                />　
              <NumericFormat  //原価金額合計
                id={'GNKFOT'}
                inputRef={inGNKFOT}                   //項目名定義？
                thousandSeparator={','}
                decimalScale={0}
                fixedDecimalScale
                color='success'
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='総原価金額合計'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '17ch'}}                 //textboxの幅
                value={totalGenka}               //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={true}                    //使用可／不可
                //inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
                inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right',touchAction: 'none'} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
                // onKeyDown={(e) => onKeyDown(e.key,inDENH,inTSEBAKFOT)}
                onFocus={(e) => e.target.select()}
                />　
              <NumericFormat  //売価金額合計
                id={'BAKFOT'}
                inputRef={inBAKFOT}                   //項目名定義？
                thousandSeparator={','}
                decimalScale={0}
                fixedDecimalScale
                color='success'
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='総売価金額合計'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '17ch'}}                 //textboxの幅
                value={totalBaika}               //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={true}                    //使用可／不可
                //inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
                inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right',touchAction: 'none'} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
                // onKeyDown={(e) => onKeyDown(e.key,inTSEGNKFOT,inRET)}
                onFocus={(e) => e.target.select()}
                />
          </Box>
        </Box>
        <Box my={2} flexDirection="row" justifyContent="flex-end" display="flex" marginTop={'-7px'}>
          <Box ml={2}>
            <Button
              variant="contained"
              color='success'
              sx={{width:120,mr:'565px'}}
              onClick={(e) => onKeyDown('Return','','') }
              >
            メニューへ
            </Button>　
            <Button
              variant="contained"
              color='success'
              sx={{width:120}}
              onClick={(e) => onClick_FooterButton_Detail()}
              >
              伝票明細
            </Button>　
            <Button
              variant="contained"
              color='success'
              sx={{width:120}}
              onClick={(e) => onClick_FooterButton_Delete() }
              >
              伝票削除
            </Button>　
            <Button
              variant="contained"
              color='success'
              sx={{width:120}}
              onClick={(e) => onClick_FooterButton_Print2() }
              >
              伝票発行
            </Button>　
            <Button
              variant="contained"
              color='success'
              sx={{width:120}}
              onClick={(e) => onClick_FooterButton_Send() }
              style={{
                display: (state.KENGEN ==="02" || state.KENGEN ==="04"  ? "none" : "inline-flex"),
              }}
            >
              送　信
            </Button>

            {/* <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setEditModalIsOpen(true);
              }}
            >
              添付票
            </Button>
            <Modal isOpen={editModalIsOpen} style={customStyles}>
              社内メール便／受取票／移動商品添付票作成<br />
              発送　店コード：{state.MISES}　店名：{state.MISEMEI} 　　部門：ＸＸＸＸＸ　担当者：鈴木　太郎　<br />
              受取　店コード：{state.MISES}　店名：{state.MISEMEI} 　　部門：ＸＸＸＸＸ　<br />

              <RadioGroup
                aria-labelledby="radio-group-label"
                value={value}
                name="radio-buttons-group"
                onChange={handleChange}
                onClick={handleChange}
              >
                <FormControlLabel value="item" control={<Radio />} label="個口" />
                <FormControlLabel value="cart" control={<Radio />} label="６輪カート" />
              </RadioGroup><br />

              <NumericFormat  //個数
                id={'KOSU'}
                inputRef={inKOSU}                   //項目名定義？
                thousandSeparator={','}
                decimalScale={0}
                fixedDecimalScale={false}            //小数点On/Off
                color='success'
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='個数'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '17ch'}}                //textboxの幅
                value={kosu}                        //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={false}                    //使用可／不可
                //inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
                inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right',touchAction: 'none'} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
                // onKeyDown={(e) => onKeyDown(e.key,inDENH,inTSEBAKFOT)}
                onFocus={(e) => e.target.select()}
                /><br />
                ※個数に[0]を入力した場合、社内メール便／受取票／移動商品添付票等は作成されません。<br />
              <FormControl sx={{ mr: 0, mt: 1 }}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={updateLunchList}
                >
                  作成
                </Button>
              </FormControl>
            </Modal> */}

          </Box>
        </Box>
        </Paper>
    </Grid>
  );

};

export default TCHP0160;