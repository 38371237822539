import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box,Button, TextField, Grid, Paper, Typography, Stack } from "@mui/material";
import { NumericFormat } from 'react-number-format';    //数値入力
import { useNavigate, useLocation } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import { 
  DataGrid,
  jaJP                              // 日本語用のファイルをインポート
} from '@mui/x-data-grid';

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCHP0151 () {

  const navigate = useNavigate();
  const location = useLocation()
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('');
  const [dismeisai, setMeisai] = useState(false);    //disabled設定（明細）
  const [sdate, setsDate] = useState([]);

  const [HZK, setHZK] = useState(''); // 日付
  const [BMNCDE, setBMNCDE] = useState(''); // 部門コード
  const [BMNMEI, setBMNMEI] = useState(''); // 部門名
  const [SIRCDE, setSIRCDE] = useState(''); // 仕入先(取引先)コード
  const [SIRMEIK, setSIRMEIK] = useState(''); // 仕入先(取引先)名
  const [KIJYMD, setKIJYMD] = useState(''); // 計上日
  const [DENKBN, setDENKBN] = useState(''); // 伝票区分
  const [DENKBNNM, setDENKBNNM] = useState(''); // 伝票区分名
  const [RYUNO, setRYUNO] = useState(''); // 理由№
  const [RYUMEI, setRYUMEI] = useState(''); // 理由名
  const [DENNO, setDENNO] = useState(''); // 伝票番号
  const [rows, setRows] = useState([]);
  const [tgtRows, settgtRows] = useState([]);

  const [totalSuryo, setTotalSuryo] = useState(0);     //数量合計
  const [totalGenka, setTotalGenka] = useState(0);     //原価金額合計
  const [totalBaika, setTotalBaika] = useState(0);     //売価金額合計

  const inYMD = useRef(null);
  const inKIJYYM = useRef(null);
  const inBMNCDE = useRef(null);
  const inSIRCDE = useRef(null);
  const inDENKBN = useRef(null);
  const inRYUNO =  useRef(null);
  const inDENNO = useRef(null);
  const inGNKFOT = useRef(null);
  const inBAKFOT = useRef(null);
  const inSRYFOT = useRef(null);

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);

    if (location.state != null) {
      const locationState = location.state;
      // rowsからselectionModelで一致した情報を取得
      const filteredRows = locationState.grid.rows.filter((item) => {
        return locationState.grid.selectionModel.includes(item.id);
      });
      // DBからデータ取得
      const data = getHenpinMeisaiShokai(filteredRows);
      data.then(result => {
        if (result != null) {
          console.log(result);
          // ヘッダ作成
          // 日付をYY/MM/DD形式に変換
          if (result[0].HZK) {
            // 念のためnull,空文字チェックして変換
            // ステータスに格納
            setHZK(convertStringDate(result[0].HZK));
          }
          else {
            setKIJYMD(result[0].HZK);
          }

          setBMNCDE(result[0].BMNCDE);
          setBMNMEI(result[0].BMNMEI);
          setSIRCDE(result[0].SIRCDE);
          setSIRMEIK(result[0].SIRMEIK);

          // 計上日をYY/MM/DD形式に変換
          if (result[0].KIJYMD) {
            // 念のためnull,空文字チェックと長さチェックして変換
            // ステータスに格納
            setKIJYMD(convertStringDate(result[0].KIJYMD));
          }
          else {
            setKIJYMD(result[0].KIJYMD);
          }

          setDENKBN(result[0].DENKBN);
          setDENKBNNM(result[0].DENKBNNM);
          setRYUNO(result[0].RYUNO);
          setRYUMEI(result[0].RYUMEI);
          setDENNO(result[0].DENNO);
          // rows作成
          let id = 1;
          let newRows = [];
          result.forEach(element => {
            // 譲渡日をYY/MM/DD形式に変換
            let jyotoDate = '';
            if (element.JYOTOYMD) {
              // 念のためnull,空文字チェックして変換
              // ステータスに格納
              jyotoDate = convertStringDate(element.JYOTOYMD);
            }
            else {
              jyotoDate = element.JYOTOYMD;
            }

            newRows.push({ 
              id: id,
              no: element.REC_NO,
              SHNMEIKN: {
                SHNMEIKN: element.SHNMEIKN,
                KIKAKUKN: element.KIKAKUKN,
                JANCDE:   element.JANCDE,
                TRIHBN:   element.TRIHBN,
                SIZCDE:   element.SIZCDE,
                TAGSIZMEI:element.TAGSIZMEI,
                TAGCOLCDE:element.TAGCOLCDE,
                TAGCOLMEI:element.TAGCOLMEI
              },
              SUU: element.SUU,
              GNT: element.GNT,
              GNKKIN: element.GNKKIN,
              BTN: element.BTN,
              BAKKIN: element.BAKKIN,
              JYOTOYMD: jyotoDate,
              IRYO_FLG: element.IRYO_FLG,
            });
    
            id++;
          });
          setRows(newRows);
          // 合計数量
          const totalSuryo = newRows.reduce((sum, element) => sum + parseFloat(element.SUU), 0);
          setTotalSuryo(totalSuryo);
          // 原価金額合計
          const totalGenka = newRows.reduce((sum, element) => sum + parseInt(element.GNKKIN), 0);
          setTotalGenka(totalGenka);
          // 売価金額合計
          const totalBaika = newRows.reduce((sum, element) => sum + parseInt(element.BAKKIN), 0);
          setTotalBaika(totalBaika);
        }
      });
    }
  }, []);

  const getHenpinMeisaiShokai = async (filteredRows) => {
    // バックエンドから取得
    const response = await fetch('/TCHP0151/getHenpinMeisaiShokai',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(filteredRows)
    });

    return response.json();
  };

  // YYorMMorDD変換用
  const  zeroPadding = (val,length) =>  ( "00" + val).slice(-length); 
  // YY/MM/DD形式に変換
  const convertStringDate = (val) => {
    let yearFormatYY = zeroPadding(parseInt(val.substring(2, 4)), 2);
    let yearFormatYYYY = val.substring(0, 4);
    let month = zeroPadding(parseInt(val.substring(4, 6)), 2);
    let day = zeroPadding(parseInt(val.substring(6, 8)), 2);

    let date = new Date(yearFormatYYYY + '/' + month + '/' + day);
    // 日付の変換チェック
    if (isNaN(date.getDate()))
    {
      // 日付が正しく変換できない場合は空文字を返却する
      return '';
    }

    // 日付として正しく認識する場合はYY/MM/DD形式で返却する
    return yearFormatYY + '/' + month + '/' + day;
  };

  // 金額表示フォーマット
  const formatCurrency = (amount, decimalPoint = 0) => {
    if(amount === null){ return amount; }

    return parseFloat(amount).toLocaleString('ja-JP', {
      style: 'currency',
      currency: 'JPY',
      minimumFractionDigits: decimalPoint,
    });
  }

  // 数量フォーマット
  const formatSUU = (amount) => {
    if(amount === null){ return amount; }

    // 数値が整数かどうかを判定
    if (Number.isInteger(amount)) {
      return amount.toLocaleString('ja-JP', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
      });
    } else {
      return amount.toLocaleString();
    }
  }

  const onKeyDown =  async (key,inputRef,inputNxRef) => {  //  入力キー、現在の項目情報、次項目情報
    //console.log(key);
    switch (key) {
      case "Enter":
        inputNxRef.current.focus();   //次の項目に遷移
        
        break;
      case "TCHP0150":
        navigate('/TCHP0150', { state: location.state });
        break;
      case "Home":
        break;
      case "ArrowUp":
        inputRef.current.focus();   //次の項目に遷移
        break;
      case "ArrowDown":
        inputNxRef.current.focus();   //次の項目に遷移
        break;
      default:
        break;
    }
  };  

  // グリッド列情報
  const columns /*: GridColDef[] */ = [
    // {
    //   field: 'detailAction',
    //   headerName: '　', // セルの右側に罫線を表示するために全角スペースを表示する
    //   align: 'left',
    //   width: 60,
    //   type: 'actions',
    //   // getActions: getDetailAction,
    //   description: '詳細画面を表示',  // ツールチップで表示される説明
    //   hideable: false,          // 非表示にできないようにする
    //   sortable: false,          // ソートできないようにする
    //   hideSortIcons: false,     // ソートアイコンを表示しない
    //   resizable: false,         // リサイズできないようにする
    //   disableExport: false,     // エクスポートに含めないようにする
    //   disableReorder: false,    // 並べ替えできないようにする
    //   fil;terable: false,        // フィルタリングしないようにする
    //   disableColumnMenu: true  // カラムメニューを表示しない
    // } ,

    //IDは必ず必要みたい
    { 
      field: 'id', 
      headerName: 'No.', 
      align:'center',
      width: 50 ,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true   // カラムメニューを表示しない
    }, 
    {
      field: 'SHNMEIKN',
      headerName: '商品名',
      headerAlign: 'center',
      width: 300,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      fontSize: 8,
      // 衣料フラグによってレンダリング内容を切り替え
      renderCell: params => {
        console.log(params.id + ':IRYO_FLG=' + params.row.IRYO_FLG + ' result=' + params.row.IRYO_FLG == '1');
        // 衣料フラグが'1'の場合、商品名欄下段を取引先品番+サイズコード+サイズ名+カラーコード+カラー名で表示する
        if(params.row.IRYO_FLG == '1') {
          return (
            <div>
              <Typography>{params.value.SHNMEIKN}</Typography>
              <Typography color="textSecondary">{
                params.value.TRIHBN + ' ' +
                params.value.SIZCDE.substr(0, 8) +
                params.value.TAGSIZMEI.substr(0, 8) + ' ' +
                params.value.TAGCOLCDE.substr(0, 2) +
                params.value.TAGCOLMEI.substr(0, 8)
              }</Typography>
            </div>
          )
        }
        // 衣料フラグが'1'以外の場合、商品名欄下段を規格内容+JANコードで表示する
        else {
          return (
            <div>
              <Typography>{params.value.SHNMEIKN}</Typography>
              <Typography color="textSecondary">{
                params.value.KIKAKUKN + ' ' +
                params.value.JANCDE
              }</Typography>
            </div>
          )
        }
      }        
    },
    {
      field: 'SUU',
      headerName: '数量',
      type: 'number',
      headerAlign: 'center',
      width: 80,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (formatSUU(params.value))
    },
    {
      field: 'GNT',
      headerName: '原単価',
      type: 'number',
      headerAlign: 'center',
      width: 110,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{formatCurrency(params.value, 2)}</Typography>
        </div>
      )
    },
    {
      field: 'GNKKIN',
      headerName: '原価金額',
      type: 'number',
      headerAlign: 'center',
      width: 110,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{formatCurrency(params.value)}</Typography>
        </div>
      )
    },
    {
      field: 'BTN',
      headerName: '売単価',
      type: 'number',
      headerAlign: 'center',
      width: 110,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{formatCurrency(params.value)}</Typography>
        </div>
      )
    },
    {
      field: 'BAKKIN',
      headerName: '売価金額',
      type: 'number',
      headerAlign: 'center',
      width: 110,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{formatCurrency(params.value)}</Typography>
        </div>
      )
    },
    {
      field: 'JYOTOYMD',
      headerName: '譲渡日',
      headerAlign: 'center',
      align:'center',
      width: 100,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true   // カラムメニューを表示しない
    },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params: GridValueGetterParams) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];
  // const rows = [
  //   { id: 1, no:999, SHNMEIKN: { SHNMEIKN: "１２３４５６７８９０１２３４５６７８", SHNEXP: "XXXXXXXXXXXXXXXXXXXXXXXXX" }, SUU: 99999.9, GNT: 999999.99,GNKKIN:999999999,BTN:999999999,BAKKIN:999999999,JYOTOYMD:'YY/MM/DD' },
  //   { id: 2, no:999, SHNMEIKN: { SHNMEIKN: "１２３４５６７８９０１２３４５６７８", SHNEXP: "XXXXXXXXXXXXXXXXXXXXXXXXX" }, SUU: 99999.9, GNT: 999999.99,GNKKIN:999999999,BTN:999999999,BAKKIN:999999999,JYOTOYMD:'YY/MM/DD' },
  //   { id: 3, no:999, SHNMEIKN: { SHNMEIKN: "１２３４５６７８９０１２３４５６７８", SHNEXP: "XXXXXXXXXXXXXXXXXXXXXXXXX" }, SUU: 99999.9, GNT: 999999.99,GNKKIN:999999999,BTN:999999999,BAKKIN:999999999,JYOTOYMD:'YY/MM/DD' },
  //   { id: 4, no:999, SHNMEIKN: { SHNMEIKN: "１２３４５６７８９０１２３４５６７８", SHNEXP: "XXXXXXXXXXXXXXXXXXXXXXXXX" }, SUU: 99999.9, GNT: 999999.99,GNKKIN:999999999,BTN:999999999,BAKKIN:999999999,JYOTOYMD:'YY/MM/DD' },
  //   { id: 5, no:999, SHNMEIKN: { SHNMEIKN: "１２３４５６７８９０１２３４５６７８", SHNEXP: "XXXXXXXXXXXXXXXXXXXXXXXXX" }, SUU: 99999.9, GNT: 999999.99,GNKKIN:999999999,BTN:999999999,BAKKIN:999999999,JYOTOYMD:'YY/MM/DD' },
  //   { id: 6, no:999, SHNMEIKN: { SHNMEIKN: "１２３４５６７８９０１２３４５６７８", SHNEXP: "XXXXXXXXXXXXXXXXXXXXXXXXX" }, SUU: 99999.9, GNT: 999999.99,GNKKIN:999999999,BTN:999999999,BAKKIN:999999999,JYOTOYMD:'YY/MM/DD' },
  //   { id: 7, no:999, SHNMEIKN: { SHNMEIKN: "１２３４５６７８９０１２３４５６７８", SHNEXP: "XXXXXXXXXXXXXXXXXXXXXXXXX" }, SUU: 99999.9, GNT: 999999.99,GNKKIN:999999999,BTN:999999999,BAKKIN:999999999,JYOTOYMD:'YY/MM/DD' },
  //   { id: 8, no:999, SHNMEIKN: { SHNMEIKN: "１２３４５６７８９０１２３４５６７８", SHNEXP: "XXXXXXXXXXXXXXXXXXXXXXXXX" }, SUU: 99999.9, GNT: 999999.99,GNKKIN:999999999,BTN:999999999,BAKKIN:999999999,JYOTOYMD:'YY/MM/DD' },
  //   { id: 9, no:999, SHNMEIKN: { SHNMEIKN: "１２３４５６７８９０１２３４５６７８", SHNEXP: "XXXXXXXXXXXXXXXXXXXXXXXXX" }, SUU: 99999.9, GNT: 999999.99,GNKKIN:999999999,BTN:999999999,BAKKIN:999999999,JYOTOYMD:'YY/MM/DD' },
  //   { id:10, no:999, SHNMEIKN: { SHNMEIKN: "１２３４５６７８９０１２３４５６７８", SHNEXP: "XXXXXXXXXXXXXXXXXXXXXXXXX" }, SUU: 99999.9, GNT: 999999.99,GNKKIN:999999999,BTN:999999999,BAKKIN:999999999,JYOTOYMD:'YY/MM/DD' },
  //   { id:11, no:999, SHNMEIKN: { SHNMEIKN: "１２３４５６７８９０１２３４５６７８", SHNEXP: "XXXXXXXXXXXXXXXXXXXXXXXXX" }, SUU: 99999.9, GNT: 999999.99,GNKKIN:999999999,BTN:999999999,BAKKIN:999999999,JYOTOYMD:'YY/MM/DD' },
  //   { id:12, no:999, SHNMEIKN: { SHNMEIKN: "１２３４５６７８９０１２３４５６７８", SHNEXP: "XXXXXXXXXXXXXXXXXXXXXXXXX" }, SUU: 99999.9, GNT: 999999.99,GNKKIN:999999999,BTN:999999999,BAKKIN:999999999,JYOTOYMD:'YY/MM/DD' },
  // ];

  //罫線表示（Data Grid）
  const gridStyle = {
    transform: 'scale(0.9)',
    // 他のスタイルプロパティをここに追加することもできます
  };

  //罫線表示（Data Grid）
  const styles = {
    grid: {
      '.MuiDataGrid-toolbarContainer': {
        borderBottom: 'solid 1px rgba(224, 224, 224, 1)'  // ついでにツールバーの下に罫線を引く
      },
      '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
        borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      // 2022/06/01 追記 列ヘッダの最終セルの右側に罫線が表示されなくなった・・・
      // 列ヘッダの最終列の右端に罫線を表示する
      '.MuiDataGrid-columnHeadersInner': {
        border: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      '.MuiDataGrid-columnSeparator--sideRight':{
        visibility: 'show',
      },
      '.MuiDataGrid-columnHeaders':{
        height:'40px',
      }
    }
  }
  return (
    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "750px",
          width: "1001px",
          m: '10px auto',
          padding:"10px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="left"
        >
          <div style={{display:"flex"}}>
            <Typography variant={"h5"} sx={{ m: '5px',height:'30px' }}>
            返品明細照会
            </Typography>
            <Box width={'65%'} display={'flex'} justifyContent={'flex-end'}>
              <Typography variant={"h6"} sx={{ m: "13px" }}>
                {state.MISEMEI}：{state.JGNMEIKNJ}
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px" }}>
                { sdate }
              </Typography>
            </Box>
          </div>
        </Grid>

        <Stack direction="row" sx={{mt:'-20px',mb:'-10px'}}>
          <TextField  //年月日
              id={'YMD'}
              inputRef={inYMD}                   //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り           
              size='small'                        //textboxの全体サイズ
              label='日付'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}            //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '10ch',marginLeft:'20px'}}                 //textboxの幅
              value={HZK}                //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 6,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              // onKeyDown={(e) => onKeyDown(e.key,inSIRCDE,inMISE)}
              />
            <TextField  //部門
              id={'BMNCDE'}
              inputRef={inBMNCDE}                   //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り           
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='部門'                       //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}           //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '18ch',marginLeft:'20px'}}                 //textboxの幅
              value={BMNCDE + ' ' + BMNMEI}       //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'left'} }}  //補完,最大文字数,左寄せ
              // onKeyDown={(e) => onKeyDown(e.key,inMISE,inDENKBN)}
              onFocus={(e) => e.target.select()}
              />
          {/* <InputLabel variant={'outlined'} >{BMN}</InputLabel> */}
          <TextField  //取引先CD
              id={'SIRCDE'}
              inputRef={inSIRCDE}                   //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り           
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='取引先'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}            //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '40ch',marginLeft:'20px'}}                 //textboxの幅
              value={SIRCDE + ' ' + SIRMEIK}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 6,style: {textAlign: 'left'} }}  //補完,最大文字数,左寄せ
              // onKeyDown={(e) => onKeyDown(e.key,inDENNO,inMISE)}
              onFocus={(e) => e.target.select()}
              />
          {/* <InputLabel variant={'outlined'} >{SIRMEIK}</InputLabel> */}
        </Stack>
        <br />
        <Stack direction="row">
        <TextField  //計上日
              id={'KIJYYM'}
              inputRef={inKIJYYM}                   //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り           
              size='small'                        //textboxの全体サイズ
              label='計上日'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}            //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '10ch',marginLeft:'20px'}}                 //textboxの幅
              value={KIJYMD}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 6,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              // onKeyDown={(e) => onKeyDown(e.key,inSIRCDE,inMISE)}
              />
            <TextField  //伝票区分
              id={'DENKBN'}
              inputRef={inDENKBN}                 //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り           
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='伝票区分'                      //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}           //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '18ch',marginLeft:'20px'}}                 //textboxの幅
              value={DENKBN + ' ' + DENKBNNM}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 2,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              // onKeyDown={(e) => onKeyDown(e.key,inBMNCDE,inHTTYMD)}
              onFocus={(e) => e.target.select()}
              />
            {/* <InputLabel variant={'outlined'} >{DENKBNNM}</InputLabel> */}
            <TextField  //理由NO
              id={'RYUNO'}
              inputRef={inRYUNO}                 //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り           
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='理由'                      //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}           //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '20ch',marginLeft:'20px'}}                 //textboxの幅
              value={RYUNO + ' ' + RYUMEI}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 2,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              // onKeyDown={(e) => onKeyDown(e.key,inBMNCDE,inHTTYMD)}
              onFocus={(e) => e.target.select()}
              />
            {/* <InputLabel variant={'outlined'} >{RYUMEI}</InputLabel> */}
            {/* 商談書No. */}
            {/* <InputLabel variant={'outlined'} >9999999999999</InputLabel> */}
          <NumericFormat  //伝票No.
              id={'DENNO'}
              inputRef={inDENNO}                   //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り           
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='伝票No.'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}            //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '12ch',marginLeft:'20px'}}                 //textboxの幅
              value={DENNO}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 7,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              // onKeyDown={(e) => onKeyDown(e.key,inKBN,inSIRCDE)}
              onFocus={(e) => e.target.select()}
              />
        </Stack>
        <br />

          <Box style={gridStyle} sx={{ height: 423, width: '1120px',ml:'-60px',mt:'-25px' }}>
            <DataGrid
              sx={styles.grid}     // --> 不具合対応のためcssで罫線を引く
              rows={rows}
              columns={columns}
              density='standard'//compact（狭め）、standard（標準）、comfortable（広め）
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              disableRowSelectionOnClick
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}  // メニュー日本語化
              showColumnRightBorder                                             // 列ヘッダセルの右側に線を引く
              showCellRightBorder                                               // セルの右側に線を引く
              />
          </Box>
          <Box my={2} flexDirection="row" justifyContent="flex-end" display="flex">
            <Box ml={2} sx={{mt:'-20px',mr:'5px'}}>
              <NumericFormat  //合計数量
                id={'SRYFOT'}
                inputRef={inSRYFOT}                   //項目名定義？
                thousandSeparator={','}
                decimalScale={1}
                fixedDecimalScale={true}            //小数点On/Off
                color='success'
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='合計数量'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '17ch'}}                //textboxの幅
                value={totalSuryo}                  //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={true}                    //使用可／不可
                inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
                // onKeyDown={(e) => onKeyDown(e.key,inDENH,inTSEBAKFOT)}
                onFocus={(e) => e.target.select()}
                />　
              <NumericFormat  //原価金額合計
                id={'GNKFOT'}
                inputRef={inGNKFOT}                   //項目名定義？
                thousandSeparator={','}
                decimalScale={0}
                fixedDecimalScale 
                color='success'
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='総原価金額合計'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '17ch'}}                 //textboxの幅
                value={totalGenka}               //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={true}                    //使用可／不可
                inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
                // onKeyDown={(e) => onKeyDown(e.key,inDENH,inTSEBAKFOT)}
                onFocus={(e) => e.target.select()}
                />　
              <NumericFormat  //売価金額合計
                id={'BAKFOT'}
                inputRef={inBAKFOT}                   //項目名定義？
                thousandSeparator={','}
                decimalScale={0}
                fixedDecimalScale 
                color='success'
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='総売価金額合計'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '17ch'}}                 //textboxの幅
                value={totalBaika}               //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={true}                    //使用可／不可
                inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
                // onKeyDown={(e) => onKeyDown(e.key,inTSEGNKFOT,inRET)}
                onFocus={(e) => e.target.select()}
                />
          </Box>
        </Box>
        <Box my={2} flexDirection="row" justifyContent="flex-end" display="flex">
          <Box ml={2}>
            <Button 
              variant="contained" 
              color='success' 
              sx={{width:100}}
              onClick={(e) => onKeyDown('TCHP0150','','') }
              >
              戻　る
            </Button>　
          </Box>
        </Box>
        </Paper>
    </Grid>
  );

};

export default TCHP0151;