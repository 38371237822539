import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, Button, TextField, Grid, Paper, Typography, Stack, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  jaJP                              // 日本語用のファイルをインポート
} from '@mui/x-data-grid';
import { NumericFormat,PatternFormat } from 'react-number-format';    //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import { onMyFocus,onNextFocus } from './hooks/useOnMyFocus';     // select()処理

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);

      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCGT0110 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('');
  const [dismeisai, setMeisai] = useState(false);    //disabled設定（明細）
  const [sdate, setsDate] = useState([]);

  const inKBN = useRef(1);                //区分
  const inBMNCDE = useRef(null);          //部門コード
  const inASTCDE = useRef(null);          //発送AU
  const inHASDD = useRef(sdate);          //発送日
  const inUIRMISE = useRef(null);         //受取店
  const inUIRBMNCDE = useRef(null);       //受取部門コード
  const inUIRASTCDE = useRef(null);       //受取AU
  const inKAKEZEIRTU = useRef(null);      //掛率

  const inJAN1 = useRef(null);            //JANコード1
  const inSUU = useRef(null);             //数量
  const inGNT = useRef(null);             //原単価
  const inBTN = useRef(null);             //売単価
  const inJAN2 = useRef(null);            //JANコード2
  const inSIRCDE2 = useRef(null);         //取引先コード
  const inSANTIKBN = useRef(null);        //産地区分
  const inTODOFUKENKUNI = useRef(null);   //都道府県/国名
  const inGENSANTI = useRef(null);        //原産地
  const inSKHLBLKBN = useRef(null);       //食品区分

  const inSUUG = useRef(null);            //合計数量
  const inGNKFOT = useRef(null);          //原価金額合計
  const inBBAKFOT = useRef(null);         //売価金額合計

  const [dressTaxrate, setDressTaxrate] = useState('');     //衣料タグ税剥がし税率
  const [regionList, setRegionList] = useState([]);         //産地区分情報
  const [prefectureList, setPrefectureList] = useState([]); //都道府県国
  const [prefectureList2, setPrefectureList2] = useState(0); //(水産用表示)
  const [homeList, setHomeList] = useState([]);             //原産地情報
  const [foodLabelList, setFoodLabelList] = useState([]);   //食品ラベル区分情報

  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  //項目のバックカラー制御用
  const [inKBNclr,setKBNclr] = useState('#FFFFFF');
  const [inBMNCDEclr,setBMNCDEclr] = useState('#FFFFFF');
  const [inASTCDEclr,setASTCDEclr] = useState('#FFFFFF');
  const [inHASDDclr,setHASDDclr] = useState('#FFFFFF');
  const [inUIRMISEclr,setUIRMISEclr] = useState('#FFFFFF');
  const [inUIRBMNCDEclr,setUIRBMNCDEclr] = useState('#FFFFFF');
  const [inUIRASTCDEclr,setUIRASTCDEclr] = useState('#FFFFFF');
  const [inJAN1clr,setJAN1clr] = useState('#FFFFFF');
  const [inJAN2clr,setJAN2clr] = useState('#FFFFFF');
  const [inSUUclr,setSUUclr] = useState('#FFFFFF');
  const [inGNTclr,setGNTclr] = useState('#FFFFFF');
  const [inBTNclr,setBTNclr] = useState('#FFFFFF');

  // ロストフォーカス用
  const [chkJAN1,setChkJAN1] = useState('0');
  const [chkJAN2,setChkJAN2] = useState('0');

  // disabled制御用
  const [disHeder, setDHeder] = useState(false);    // ヘッダー用
  const [disJan1, setJan1] = useState(true);    // JAN1
  const [disJan2, setJan2] = useState(true);    // JAN2
  const [disASTCDE, setDisASTCDE] = useState(true);    // ASTCDE
  const [disUIRASTCDE, setDisUIRASTCDE] = useState(true);    // UIRASTCDE
  // 2023/10/06
  const [disRow, setDisRow] = useState(true);    // 数量 原単価 売単価etc

  // セレクトボックス制御用
  const [enableSearch, setEnableSearch] = useState(false);

  const [pagenationModel, setPagenationModel] = useState({page: 0, pageSize: 5});

  // ヘッダデータ　◇画面上だけのデータ
  const headerData = {
    KBN: '1',                       // ◇区分
    KSACDE : state.KSACDES,         // 会社コード
    MISE : state.MISES,             // 店コード
    BMNCDE : '',                    // 部門コード
    ASTCDE : '000',                 // ＡＵ
    DENYMD : '',                    // 伝票日付
    DENNO : '',                     // 伝票番号
    DEN_EDABAN : '1',               // 伝票枝番
    SEQ : '001',                    // ＳＥＱ
    TENPONM : state.MISEMEI,        // 店舗名
    BMNMEI : '',                    // 部門名
    ASTMEI : '',                    // ＡＵ名
    HASDD : '',                     // 発送日
    HASDD2 : '',                    // 発送日2
    UIR_KSACDE : state.KSACDES,     // 受取会社コード
    UIR_MISE : '',                  // 受取店コード
    UIR_MISEMEI : '',               // 受取店名
    UIR_BMNCDE : '',                // 受取部門コード
    UIR_BMNMEI : '',                // 受取部門名
    UIR_ASTCDE : '000',             // 受取ＡＵ
    UIR_ASTMEI : '',                // 受取ＡＵ名
    KAKEZEIRTU : '',                // 掛率
    SRYFOT : '',                    // 数量合計
    GNKFOT : '',                    // 原価金額合計
    BAKFOT : '',                    // 売価金額合計
    // IN_JGNCDE : '',                 // 入力担当者コード
    // IN_JGNMEIKNJ : state.JGNMEIKNJ, // 入力担当者名
    // IN_DATE : '',                   // 入力日時
    // OUT_DATE : '',                  // 発行日時
    // SND_DATE : '',                  // 送信日時
    JOTKBN : '1',                   // 状態区分
    // KIJYKETKBN : '',                // 計上結果区分
    // KIJYMD : '',                    // 計上日
    DATE : '',                      // 日時
    JGNCDE : state.JGNCDE,          // ユーザーID
    JGNMEIKNJ : state.JGNMEIKNJ,    // ◇ユーザー名
    PRG_ID : 'TCGT0110',            // プログラムID
    TRL_ID:state.MISES,             // 端末ＩＤ★一旦店コード入れる
    DELFLG : '0',                   // 削除フラグ
  }
  const [headerItem,setHeaderItem] = useState(headerData);
  // ヘッダ2データ
  const rowData ={
    id : 0,                        // 行№
    KSACDE : state.KSACDES,        // 会社コード
    MISE : state.MISES,            // 店コード
    BMNCDE : '',                   // 部門コード
    ASTCDE : '000',                // ＡＵ
    DENYMD : '',                   // 伝票日付
    DENNO : '',                    // 伝票番号
    DEN_EDABAN : '1',              // 伝票枝番
    SEQ : '',                      // ＳＥＱ
    // GYONO : '',                    // 行№
    JANCDE : '',                   // ＪＡＮコード
    SHNMEIKN : '',                 // 商品名
    KIKAKUKN : '',                 // 規格
    SUU : 0,                       // 数量(数字)
    SUU2 : '',                     // ◇数量2(文字列)
    GNT : 0,                       // 原単価(数字)
    GNT2 : '',                     // ◇原単価2(文字列)
    GNKKIN : 0,                    // 原価金額
    BTN : 0,                       // 売単価(数字)
    BTN2 : '',                     // ◇売単価2(文字列)
    BAKKIN : 0,                    // 売価金額
    SANTI_KBN : '',                // 産地区分
    TODOFUKEN_KUNI : '',           // 都道府県国
    GENSANTI : '',                 // 原産地
    SKHLBLKBN : '',                // 食品区分
    SIRCDE : '',                   // 仕入先(取引先)コード
    SIRMEIK : '',                  // 仕入先(取引先)名
    EOSCDE : '',                   // ＥＯＳコード
    JANCDE2 : '',                  // ＪＡＮコード２
    TAGASTCDE : '',                // タグＡＵ
    TAGLINCDE : '',                // タグライン
    TAGCLSCDE : '',                // タグクラス
    TAGSZN : '',                   // タグシーズン
    TAGITM : '',                   // タグアイテム
    TRIHBN : '',                   // 取引先品番
    TAGSIZ : '',                   // サイズコード
    TAGSIZMEI : '',                // サイズ名カナ
    TAGCOLCDE : '',                // カラーコード
    TAGCOLMEI : '',                // カラー名カナ
    IRYO_FLG : '0',                 // 衣料フラグ
    IN_CD_KBN : '',                // 入力コード区分
    // DATE : '',                     // 作成日時
    JGNCDE : state.JGNCDE,         // ユーザーID
    PRG_ID : 'TCGT0110',           // プログラムID
    TRL_ID:state.MISES,            // 端末ＩＤ★一旦店コード入れる
    DELFLG : '0',                  // 削除フラグ
  }
  const [rowItem,setRowItem] = useState(rowData);

  const commonStyles = {
    bgcolor: 'background.paper',
    m: 1,
    border: 0,
    width: '40px',
    height: '40px',
  };

  // 衣料タグ税剥がし税率固定取得
  const getDressTaxrate = async() => {
    const get_DressTax = await fetch('/SL_SET_MST_sel',{method: 'POST', headers:{'Content-Type': 'application/json'},
    body: JSON.stringify({ CDE:'006', SCDE:'0000' })});
    const set_DressTax = await get_DressTax.json();
    if(set_DressTax.DATE01 != ''){
      setDressTaxrate(Number(set_DressTax.DATE01));
    }
  }

  // セレクトボックスリスト取得
  const getRegionList = async(code) => {
    const get_region = await fetch('/TCGT0110/SL_SET_MST_sel',{method: 'POST', headers:{'Content-Type': 'application/json'},
    body: JSON.stringify({ CDE:code })});
    const data_region = await get_region.json();
    const valueList = data_region.ret.filter(element => {
      return element.SCDE === '0000'
    });
    const labelList = data_region.ret.filter(element => {
      return element.SCDE === '0010'
    });

    let list = [
      {value:'　',label:'未選択'},
      {value:valueList[0].DATE01,label:labelList[0].DATE01},
      {value:valueList[0].DATE02,label:labelList[0].DATE02},
      {value:valueList[0].DATE03,label:labelList[0].DATE03},
      {value:valueList[0].DATE04,label:labelList[0].DATE04},
      {value:valueList[0].DATE05,label:labelList[0].DATE05},
      {value:valueList[0].DATE06,label:labelList[0].DATE06},
      {value:valueList[0].DATE07,label:labelList[0].DATE07},
      {value:valueList[0].DATE08,label:labelList[0].DATE08},
      {value:valueList[0].DATE09,label:labelList[0].DATE09},
      {value:valueList[0].DATE10,label:labelList[0].DATE10},
    ]

    return list.filter(element => {
      return element.value !== '' || element.label !== ''
    });
  }

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  /**
   * 数量設定 (numをrow.SUUに数字で、row.SUU2に文字列で設定)
   * @param row 設定行
   * @param num 設定値
   */
  const setRowSuu = async (row, num) => {
    let suu = Number(num);
    if (isNaN(suu)){
      suu = 0;
    }
    // 数量
    row.SUU = suu;
    // 数量2
    row.SUU2 = suu.toLocaleString(undefined,{minimumFractionDigits : 1});
  }

  /**
   * 原単価設定 (numをrow.GNTに数字で、row.GNT2に文字列で設定)
   * @param row 設定行
   * @param num 設定値
   */
  const setRowGnt = async (row, num) => {
    let gnt = Number(num);
    if (isNaN(gnt)){
      gnt = 0;
    }
    // 原単価
    row.GNT = gnt;
    // 原単価2
    row.GNT2 = gnt.toLocaleString(undefined,{minimumFractionDigits : 2});
  }

  /**
   * 売単価設定 (numをrow.BTNに数字で、row.BTN2に文字列で設定)
   * @param row 設定行
   * @param num 設定値
   */
  const setRowBtn = async (row, num) => {
    let btn = Number(num);
    if (isNaN(btn)){
      btn = 0;
    }
    // 売単価
    row.BTN = btn;
    // 売単価2
    row.BTN2 = btn.toLocaleString(undefined,{minimumFractionDigits : 0});
  }

  const ckDate = (strYYYYMMDD) =>{  // YYYY/MM/DD ⇒ 2023/09/07 等
    if(!strYYYYMMDD.match(/^\d{4}\/\d{2}\/\d{2}$/)){
        return false;
    }
    var y = strYYYYMMDD.split("/")[0];
    var m = strYYYYMMDD.split("/")[1] - 1;
    var d = strYYYYMMDD.split("/")[2];
    var date = new Date(y,m,d);
    if(date.getFullYear() != y || date.getMonth() != m || date.getDate() != d){
        return false;
    }
    return true;
  }

  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);

    getDressTaxrate();
    getSelectMenuList();
    setHeaderHASDD();
  }, []);

  // 行が追加されたら最後のページを表示する
  useEffect(() => {
    let last_page = Math.ceil(rows.length / pagenationModel.pageSize) - 1;
    if (last_page < 0) last_page = 0;
    setPagenationModel({page: last_page, pageSize: pagenationModel.pageSize});
  }, [rows]);

  // JAN1入力後、数量が全選択状態にならないため、
  // 数量の描画完了後にフォーカス移動させる必要がある
  const [nextActive, setNextActive] = useState(null);
  useEffect(() => {
    if (nextActive === 'SUU') {
      //setTimeout(() => {inSUU.current.select();},100);
      inSUU.current.focus();
      onNextFocus(inSUU);
      setNextActive('');
    }
  }, [rowItem.SUU]);
  // JAN1入力後、数量が全選択状態にならないため、
  // 数量の描画完了後にフォーカス移動させる必要がある
  useEffect(() => {
    if (nextActive === 'JAN1') {
      inJAN1.current.focus();
      setNextActive('');
    }
  }, [disJan1]);

  useEffect(() => {
    if (nextActive === 'JAN2') {
      inJAN2.current.focus();
      setNextActive('');
    }
  }, [disJan2]);

  useEffect (()=>{
    if(disRow === false){
      inSUU.current.focus();
    }
  }, [disRow])

  // ヘッダの表示をリセットする。
  const setHeaderHASDD =  async (inputRef, inputNxRef) =>{
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = (d.getMonth() + 1).toString().padStart(2, '0');
    let day = d.getDate().toString().padStart(2, '0');

    const header = headerData;
    header.HASDD = year + month + day;                // 発送日
    header.HASDD2 = year + '/' + month + '/' + day;   // 発送日2
    await setHeaderItem(header);
  }

  const getSelectMenuList = async()=>{
    let a2 = await getRegionList('008');
    let a4 = await getRegionList('009');
    let a5 = await getRegionList('007');

    setRegionList(a2);
    setHomeList(a4);
    setFoodLabelList(a5);
  }

    //項目のバックグランドカラー初期化
  const gmbgclr = () => {
    setKBNclr('#FFFFFF');
    setBMNCDEclr('#FFFFFF');
    setASTCDEclr('#FFFFFF');
    setHASDDclr('#FFFFFF');
    setUIRMISEclr('#FFFFFF');
    setUIRBMNCDEclr('#FFFFFF');
    setUIRASTCDEclr('#FFFFFF');
    setJAN1clr('#FFFFFF');
    setJAN2clr('#FFFFFF');
    setSUUclr('#FFFFFF');
    setGNTclr('#FFFFFF');
    setBTNclr('#FFFFFF');
}

  const onKeyDown =  async (key,inputRef,inptRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    //console.log(key);
    switch (key) {
      case 'Enter':
        gmbgclr();
        switch(inputRef.current.id){

          case 'KBN':
            await onKeyDown_KBN(inputRef,inputNxRef);  // 区分
            break;
          case 'BMNCDE':
            await onKeyDown_BMNCDE(inputRef,inputNxRef); // 部門CD
            break;
          case 'ASTCDE':
            await onKeyDown_ASTCDE(inputRef,inputNxRef); // 発送ＡＵ
            break;
          case 'HASDD':
            await onKeyDown_HASDD(inputRef,inputNxRef);  // 発送日
            break;
          case 'UIRMISE':
            await onKeyDown_UIRMISE(inputRef,inputNxRef); // 受取店
            break;
          case 'UIRBMNCDE':
            await onKeyDown_UIRBMNCDE(inputRef,inputNxRef); // 受取部門CD
            break;
          case 'UIRASTCDE':
            await onKeyDown_UIRASTCDE(inputRef,inputNxRef); // 受取ＡＵ
            break;
          case 'KAKEZEIRTU':
            await getMoveDate(inputNxRef); // 明細データ抽出
            break;
          case 'JAN1':
            await onKeyDown_JAN1(inputRef,inputNxRef);  // JANCD1
            break;
          case 'JAN2':
            await onKeyDown_JAN2(inputRef,inputNxRef);  // JANCD2
            break;
          case 'SUU':
            await onKeyDown_SUU(inputRef,inputNxRef);  // 数量
            break;
          case 'GNT':
            await onKeyDown_GNT(inputRef,inputNxRef);  // 原単価
            break;
          case 'BTN':
            await onKeyDown_BTN(inputRef,inputNxRef);  // 売単価
            break;
          default:
            await inputNxRef.current.focus();
            break;
        }

        break;
      case 'Return':
        if (rows.length != 0)
        {
          if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))){  //TCGM0007：
            return;
          }
        }
        navigate('/TCSA0003');
        break;
      case 'Home':
        break;
      case 'ArrowUp':
        inptRtRef.current.focus();   //前の項目に遷移
        break;
      case 'ArrowDown':
        inputNxRef.current.focus();   //次の項目に遷移
        break;
      default:
        break;
    }
  };

  const onblur = async (inputRef) => {
    gmbgclr();
    console.log('LostFocus:'+inputRef.current.id+' | '+chkJAN1+'|'+chkJAN2);
    if (inputRef.current.id === 'JAN1' && (chkJAN1 == '0'))
    {
      await onKeyDown_JAN1(inputRef, inSUU)
    }
    if (inputRef.current.id === 'JAN2' && (chkJAN2 == '0'))
    {
      await onKeyDown_JAN2(inputRef, inSUU)
    }
  }


  // ヘッダ部１入力　区分(初期カーソル)
  const onKeyDown_KBN = async(inputRef, inputNxRef,instart = 0) => {
    if(!(inputRef.current.value === '1' || inputRef.current.value === '2')){
      setKBNclr('#f59d9d');
      window.alert('区分入力エラー'); //TCGE0061：
      inputRef.current.focus();
      return 1;
    }

    // if(inputRef.current.value === '2') {
    //   const header = JSON.parse(JSON.stringify(headerItem));
    //   header.UIR_MISE = state.MISES;
    //   header.UIR_MISEMEI = state.MISEMEI;
    //   console.log('受取店名:'+state.MISEMEI);
    //   setHeaderItem(header);
    // }Z
    // 区分チェック
    if(inputRef.current.value === '1'){
      //「１」の場合、画面.発送ＡＵと画面.受取ＡＵに「000」を設定し、非活性にする。
      // const header = JSON.parse(JSON.stringify(headerItem));
      // header.ASTCDE = '000';
      // header.UIR_ASTCDE = '000';
      // header.UIR_MISE = '';
      if (instart !== 1){
        setHeaderItem(HeaderItem => ({...HeaderItem,ASTCDE:'000'}));
        setHeaderItem(HeaderItem => ({...HeaderItem,UIR_ASTCDE:'000'}));
        setHeaderItem(HeaderItem => ({...HeaderItem,UIR_MISE:''}));
        setHeaderItem(HeaderItem => ({...HeaderItem,UIR_MISEMEI:''}));
        // setHeaderItem(header);
        setDisASTCDE(true);
        setDisUIRASTCDE(true);
      }
    } else if(inputRef.current.value === '2'){
      //「２」の場合、画面.発送ＡＵと画面.受取ＡＵは未設定とし、活性化させる。
      // const header = JSON.parse(JSON.stringify(headerItem));
      // header.ASTCDE = '';
      // header.UIR_ASTCDE = '';
      // header.UIR_MISE = state.MISES;
      // header.UIR_MISEMEI = state.MISEMEI;
      if (instart !== 1){
        setHeaderItem(HeaderItem => ({...HeaderItem,ASTCDE:''}));
        setHeaderItem(HeaderItem => ({...HeaderItem,UIR_ASTCDE:''}));
        setHeaderItem(HeaderItem => ({...HeaderItem,UIR_MISE:state.MISES}));
        setHeaderItem(HeaderItem => ({...HeaderItem,UIR_MISEMEI:state.MISEMEI}));
        // setHeaderItem(header);
        setDisASTCDE(false);
        setDisUIRASTCDE(false);
      }
    }

    // 次の項目に遷移
    if (instart === 0)
    {
      inputNxRef.current.focus();
    }
    return 0;
  }

  // ヘッダ部１入力 部門CD
  const onKeyDown_BMNCDE = async(inputRef, inputNxRef,instart = 0) => {
    // const header = JSON.parse(JSON.stringify(headerItem));
    const row = JSON.parse(JSON.stringify(rowItem));
    row.SANTI_KBN = '';                // 産地区分
    row.TODOFUKEN_KUNI = '';           // 都道府県国
    row.GENSANTI = '';                 // 原産地
    row.SKHLBLKBN = '';                // 食品区分
    setRowItem(row);
    // header.BMNMEI = '';
    setHeaderItem(HeaderItem => ({...HeaderItem,BMNMEI:''}));
    // 入力チェック
    if(inputRef.current.value === ''){
      setBMNCDEclr('#f59d9d');
      window.alert('部門コードを入力して下さい。'); //TCGE0019：
      // setHeaderItem(header);
      setPrefectureList([]);
      setPrefectureList2(0);
      inputRef.current.focus(); //次の項目に遷移
      return 1;
    }

    // 入力値の前0詰め
    // header.BMNCDE = inputRef.current.value.padStart(4, '0');
    let bmncde = inputRef.current.value.padStart(4, '0');
    setHeaderItem(HeaderItem => ({...HeaderItem,BMNCDE:bmncde}));
    // 部門コード取得
    const getBMNCode = await fetch('/MR_SHP_MST_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ KSACDE: state.KSACDES, MISE:state.MISES, BMNCDE:bmncde, RCNT:0 })});
    const j_BMNC = await getBMNCode.json();
    if(j_BMNC.RCNT === 0){
      setBMNCDEclr('#f59d9d');
      window.alert('部門コードは存在しません。'); //TCGE0007：
      // setHeaderItem(header);
      setPrefectureList([]);
      setPrefectureList2(0);
      inputRef.current.focus(); //次の項目に遷移
      return 1;
    }

    // 部門名取得
    const getBMNMEI = await fetch('/MR_PRD_CLS_MST_sel',{method: 'POST', headers:{'Content-Type': 'application/json'},
      body: JSON.stringify({ KSACDE: state.KSACDES, BMNCDE:bmncde, ASTCDE:'XXX', RCNT:0 })});
    const j_BMNN = await getBMNMEI.json();
    if(j_BMNN.RCNT === 0){
      setBMNCDEclr('#f59d9d');
      window.alert('部門名は存在しません。'); //TCGE0007：
      // setHeaderItem(header);
      setPrefectureList([]);
      setPrefectureList2(0);
      inputRef.current.focus(); //次の項目に遷移
      return 1;
    }

    // header.BMNMEI = j_BMNN.BRUMEI;
    // setHeaderItem(header);
    setHeaderItem(HeaderItem => ({...HeaderItem,BMNMEI:j_BMNN.BRUMEI}));

    // 都道府県国取得
    const getPrefectureList = await fetch('/TCGT0110/getPrefectureList',{method: 'POST', headers:{'Content-Type': 'application/json'},
      body: JSON.stringify({ BMNCDE: bmncde})});
    const prefecture = await getPrefectureList.json();
    if(prefecture.ret.length > 0){
      let unselected = {value:'　',label:'未選択'};
      setPrefectureList([unselected, ...prefecture.ret]);
      if (bmncde === '1110' || bmncde === '1120')
      {
        setPrefectureList2(0);
      }else{
        setPrefectureList2(1);
      }
    }else{
      setPrefectureList([]);
      setPrefectureList2(0);
    }
    if (instart === 0)
    {
      if (inputNxRef.current.disabled) {
        // 発送AUは無効状態なので、発送日にフォーカス移動させる
        inHASDD.current.focus(); // 次の項目に遷移
      } else {
        inputNxRef.current.focus(); // 次の項目に遷移
      }
    }
    return 0;
  }

  // ヘッダ部１入力 発送ＡＵ
  const onKeyDown_ASTCDE = async(inputRef, inputNxRef,instart = 0) => {
    // const header = JSON.parse(JSON.stringify(headerItem));
    // header.ASTMEI = '';
    setHeaderItem(HeaderItem => ({...HeaderItem,ASTMEI:''}));
    let Astcde = '';

    if(headerItem.KBN === '2'){
      // 区分が「２」の場合、以下のチェックを行う
      // 必須チェック
      if(inputRef.current.value === ''){
        setASTCDEclr('#f59d9d');
        window.alert('発送ＡＵを入力して下さい。'); //TCGE0019：
        inputRef.current.focus();
        return 1;
      }

      // 桁数チェック
      if(inputRef.current.value.length < 3){
        Astcde = inputRef.current.value.padStart(3, '0');
        // header.ASTCDE = Astcde;
        //setHeaderItem(header);
        setHeaderItem(HeaderItem => ({...HeaderItem,ASTCDE:Astcde}));
      }else
      {
        Astcde = inputRef.current.value;
      }

      // ＡＵ存在チェック
      const getAUNAME = await fetch('/TCGT0110/getAUNAME',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ KSACDE:headerItem.KSACDE, BMNCDE:headerItem.BMNCDE, ASTCDE:Astcde })});
      const AUNAME = await getAUNAME.json();
      if(AUNAME.RCNT === 0) {
        setASTCDEclr('#f59d9d');
        window.alert('ＡＵ入力エラー'); //TCGE0034：
        inputRef.current.focus();
        return 1;
      }

      // header.ASTMEI = AUNAME.AUMEI;          // 商品分類名（漢字）
      // setHeaderItem(header);
      setHeaderItem(HeaderItem => ({...HeaderItem,ASTMEI:AUNAME.AUMEI}));
    }
    if (instart === 0)
    {
      inUIRBMNCDE.current.focus();  // 受取部門に遷移
    }
    return 0;
  }

  // ヘッダ部１入力 発送日
  const onKeyDown_HASDD = async(inputRef, inputNxRef,instart = 0) => {
    // 入力チェック
    if(inputRef.current.value === ''){
      setHASDDclr('#f59d9d');
      window.alert('TCGE0019:日付を入力してください。');
      inputRef.current.focus();
      return 1;
    }

    // 日付正規チェック
    const strInputDate = inputRef.current.value;
    const InputDate = new Date(strInputDate);

    if (ckDate(strInputDate)===false){
      setHASDDclr('#f59d9d');
      window.alert('日付入力エラー'); //TCGE0016：
      inputRef.current.focus();
      return 1;
    }

    // 日付範囲チェック
    let maxdate = new Date(sdate);
    maxdate.setDate(maxdate.getDate() + 14);    // 2023/11/22 2週間後まで入力可能に変更
    let mindate = new Date(sdate);
    mindate.setMonth(mindate.getMonth()-2);
    if((maxdate < InputDate) || (mindate > InputDate)){
      setHASDDclr('#f59d9d');
      window.alert('日付入力エラー'); //TCGE0016：
      inputRef.current.focus();
      return 1;
    }
    if (instart === 0)
    {
      if(headerItem.KBN === '2'){
        inUIRBMNCDE.current.focus();  // 受取部門に遷移
        return 0;
      }
      inputNxRef.current.focus(); //次の項目に遷移
    }
    return 0;
  }

  // ヘッダ部１入力 受取店
  const onKeyDown_UIRMISE = async(inputRef, inputNxRef,instart = 0) => {
    // 入力チェック
    const header = JSON.parse(JSON.stringify(headerItem));
    if(inputRef.current.value === ''){
      setUIRMISEclr('#f59d9d');
      window.alert('TCGE0019:受取店コードを入力してください。');
      inputRef.current.focus();
      // setHeaderItem(header);
      return 1;
    }

    // header.UIR_MISE = inputRef.current.value.padStart(3, '0');
    let luir_mise = inputRef.current.value.padStart(3, '0');
    setHeaderItem(HeaderItem => ({...HeaderItem,UIR_MISE:luir_mise}));

    // 店名取得
    const getMISEName = await fetch('/TCGT0110/getMISEName',{method: 'POST', headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ KSACDE:header.KSACDE, MISE:luir_mise, HASDD:header.HASDD })});
    const j_MISEName = await getMISEName.json();

    if (j_MISEName.RCNT === 0) {
      setUIRMISEclr('#f59d9d');
      window.alert('店コード入力エラー'); //TCGE0010：
      inputRef.current.focus();
      // setHeaderItem(header);
      return 1;
    }

    // header.UIR_MISEMEI =j_MISEName.MISEMEI;
    // setHeaderItem(header);
    setHeaderItem(HeaderItem => ({...HeaderItem,UIR_MISEMEI:j_MISEName.MISEMEI}));
    if (instart === 0)
    {
      inputNxRef.current.focus(); //次の項目に遷移
    }
    return 0;
  }

  // ヘッダ部１入力 受取部門
  const onKeyDown_UIRBMNCDE = async(inputRef, inputNxRef,instart = 0) => {
    // 入力チェック
    const header = JSON.parse(JSON.stringify(headerItem));
    if(inputRef.current.value === ''){
      setUIRBMNCDEclr('#f59d9d');
      window.alert('部門コードを入力してください。'); //TCGE0019:
      // setHeaderItem(header);
      inputRef.current.focus();
      return 1;
    }

    if(headerItem.KBN === '1'
    && headerItem.MISE === headerItem.UIR_MISE){
      setUIRBMNCDEclr('#f59d9d');
      window.alert('発送店と同じ受取店は入力できません。'); //TCGE0082：
      inputRef.current.focus();
      return 1;
    }else if(headerItem.KBN === '2'
    && headerItem.MISE !== headerItem.UIR_MISE){
      setUIRBMNCDEclr('#f59d9d');
      window.alert('発送店と受取店が異なります。'); //TCGE0083：
      inputRef.current.focus();
      return 1;
    }

    // 入力値の前0詰め
    // header.UIR_BMNCDE = inputRef.current.value.padStart(4, '0');
    let luir_bmncde  = inputRef.current.value.padStart(4, '0');
    setHeaderItem(HeaderItem => ({...HeaderItem,UIR_BMNCDE:luir_bmncde}));

    // 部門間チェック
    if(headerItem.KBN === '2'
    && headerItem.BMNCDE === luir_bmncde){
      setUIRBMNCDEclr('#f59d9d');
      window.alert('同一部門への店内移動は出来ません。'); //TCGE0084：
      inputRef.current.focus();
      return 1;
    }

    // 部門コードチェック
    const getBMNMEI = await fetch('/MR_SHP_MST_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ KSACDE:header.KSACDE, MISE:header.UIR_MISE, BMNCDE:luir_bmncde })});
    const j_BMNMEI = await getBMNMEI.json();
    if((j_BMNMEI.RCNT === 0)){
      setUIRBMNCDEclr('#f59d9d');
      window.alert('部門コードは存在しません。'); //TCGE0007：
      inputRef.current.focus();
      // setHeaderItem(header);
      return 1;
    }

    // 部門名取得
    const getItemName = await fetch('/MR_PRD_CLS_MST_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ KSACDE:header.KSACDE, BMNCDE:luir_bmncde, ASTCDE:'XXX' })});
    const j_ItemName = await getItemName.json();
    if(j_ItemName.RCNT === 0){
      window.alert('部門コードは存在しません。'); //TCGE0007：
      inputRef.current.focus();
      // setHeaderItem(header);
      return 1;
    }
    // header.UIR_BMNMEI = j_ItemName.BRUMEI;
    // setHeaderItem(header);
    setHeaderItem(HeaderItem => ({...HeaderItem,UIR_BMNMEI:j_ItemName.BRUMEI}));
    let ret = interdepartmentalCheck(inputNxRef);
    return ret;
  }

  // ヘッダ部１入力 部門間チェック
  const interdepartmentalCheck = async(inputNxRef) => {
    //console.log('部門間移動チェック:'+headerItem.BMNCDE+'/'+ headerItem.UIR_BMNCDE);
    let uir_bmncde = headerItem.UIR_BMNCDE.padStart(4, '0');
    // if(headerItem.BMNCDE !== uir_bmncde){
    //   // 部門間移動許可マスタをチェック
    //   const getInterdepartmental = await fetch('/TCGT0110/getInterdepartmental',{method: 'POST', headers: {'Content-Type': 'application/json'},
    //   body: JSON.stringify({ MISE:headerItem.MISE, BMNCDE:headerItem.BMNCDE, UIR_MISE:headerItem.UIR_MISE, UIR_BMNCDE:uir_bmncde })});
    //   const InterdepartmentalCount = await getInterdepartmental.json();
    //   if (InterdepartmentalCount.RCNT === 0) {
    //     setUIRBMNCDEclr('#f59d9d');
    //     window.alert('TCGE0035：移動できません。');
    //     return 1;
    //   }
    // }
    // 2023/10/08 店間移動チェック修正 Start
    // let ApplFlg = 0
    // 部門間チェック１
    // console.log('部門間チェック１ 発送側検索');
    // 部門間移動許可マスタをチェック 発送店+発送分類CD
    // const getInterdepartmental = await fetch('/TCGT0110/getInterdepartmental',{method: 'POST', headers: {'Content-Type': 'application/json'},
    // body: JSON.stringify({ has_mise:headerItem.MISE,has_bmncde:headerItem.BMNCDE })});
    // const InterdepartmentalCount = await getInterdepartmental.json();
    // if (InterdepartmentalCount.RCNT !== 0){
    //   for (let i = 0; i<InterdepartmentalCount.RCNT; i++){
    //     // 受取分類CDと取得情報を比較
    //     if (uir_bmncde === InterdepartmentalCount.res[i].UIR_BMNCDE){
    //       ApplFlg = 1;
    //       break;
    //     }
    //   }
    //   if (ApplFlg !== 1){
    //     window.alert('TCGE0035：移動できません。');
    //     setUIRBMNCDEclr('#f59d9d');
    //     return 1;
    //   }
    // }else{
    //   // 部門間チェック２
    //   console.log('部門間チェック２ 受取側検索');
    //   // 部門間移動許可マスタをチェック 受取店+受取分類CD
    //   const getInterdepartmental = await fetch('/TCGT0110/getInterdepartmental',{method: 'POST', headers: {'Content-Type': 'application/json'},
    //   body: JSON.stringify({ has_mise:headerItem.UIR_MISE,has_bmncde:uir_bmncde })});
    //   const InterdepartmentalCount = await getInterdepartmental.json();
    //   if (InterdepartmentalCount.RCNT !== 0){
    //     for (let i = 0; i<InterdepartmentalCount.RCNT; i++){
    //       // 発送分類CDと取得情報を比較
    //       if (headerItem.BMNCDE === InterdepartmentalCount.res[i].UIR_BMNCDE){
    //         ApplFlg = 1;
    //         break;
    //       }
    //     }
    //     if (ApplFlg !== 1){
    //       window.alert('TCGE0035：移動できません。');
    //       setUIRBMNCDEclr('#f59d9d');
    //       return 1;
    //     }
    //   }else{
    //     if(headerItem.KBN === '1'){
    //       // 部門間チェック３
    //       console.log('部門間チェック３ 同一部門');
    //       // 部門間移動許可マスタに登録されていない発送店、受取店のため、発送分類CD ＝ 受取分類CD以外はエラー
    //       if (headerItem.BMNCDE !== uir_bmncde){
    //           window.alert('TCGE0035：移動できません。');
    //           setUIRBMNCDEclr('#f59d9d');
    //           return 1;
    //       }
    //     }else{
    //       // 部門間チェック４
    //       // 店内移動
    //       console.log('部門間チェック４ MSE検索');
    //       // 部門間移動許可マスタをチェック 'MSE'+発送分類CD
    //       const getInterdepartmental = await fetch('/TCGT0110/getInterdepartmental',{method: 'POST', headers: {'Content-Type': 'application/json'},
    //       body: JSON.stringify({ has_mise:'MSE',has_bmncde:headerItem.BMNCDE })});
    //       const InterdepartmentalCount = await getInterdepartmental.json();
    //       console.log('件数:' + InterdepartmentalCount.RCNT);
    //       if (InterdepartmentalCount.RCNT !== 0){
    //       for (let i = 0; i<InterdepartmentalCount.RCNT; i++){
    //           // 発送分類CDと取得情報を比較
    //           if (uir_bmncde === InterdepartmentalCount.res[i].UIR_BMNCDE){
    //             ApplFlg = 1;
    //             break;
    //           }
    //         }
    //         if (ApplFlg !== 1){
    //           window.alert('TCGE0035：移動できません。');
    //           setUIRBMNCDEclr('#f59d9d');
    //           return 1;
    //         }
    //       }else{
    //         window.alert('TCGE0035：移動できません。');
    //         setUIRBMNCDEclr('#f59d9d');
    //         return 1;
    //       }
    //     }
    //   }
    // }
    // 2023/10/08 店間移動チェック修正 End

    // 店内移動時（発送店＝受取店）の処理
    if(headerItem.KBN === '2'){
      // 分類CD（発送店側）≠分類CD（受取店側）でなければエラー
      if(headerItem.BMNCDE == uir_bmncde){
        setUIRBMNCDEclr('#f59d9d');
        window.alert('同一部門への店内移動は出来ません。'); //TCGE0084：
        return 1;
      }

      let idoBmnList = ['1110','1120','1125','1130','1131','1140','1150','1210','1230'];
      console.log(idoBmnList.findIndex(idoBmn => idoBmn === headerItem.BMNCDE));
      console.log(idoBmnList.findIndex(idoBmn => idoBmn === uir_bmncde));

      if(idoBmnList.findIndex(idoBmn => idoBmn === headerItem.BMNCDE) == -1
      || idoBmnList.findIndex(idoBmn => idoBmn === uir_bmncde) == -1)
      {
        setUIRBMNCDEclr('#f59d9d');
        window.alert('移動できません。');//TCGE0084：
        return 1;
      }
    }

    // 店外移動時（発送店≠受取店）の処理
    else{
      // 部門間移動許可マスタ　発送店存在チェック
      console.log('部門間チェック１ 発送店存在チェック');
      const responseHas1 = await fetch('/TCGT0110/existsHasMise',{method: 'POST', headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ has_mise:headerItem.MISE })});
      const dataHas1 = await responseHas1.json();
      let has_mise = '';
      if (dataHas1.RCNT === 0) {
        has_mise = 'MIS';
      }else{
        has_mise = headerItem.MISE;
      }

      // 部門間移動許可マスタ　受入店存在チェック
      console.log('部門間チェック１ 受入店存在チェック');
      const responseUir1 = await fetch('/TCGT0110/existsUirMise',{method: 'POST', headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ uir_mise:headerItem.UIR_MISE })});
      const dataUir1 = await responseUir1.json();
      let uir_mise = '';
      if (dataUir1.RCNT === 0) {
        uir_mise = 'MIS';
      }else{
        uir_mise = headerItem.UIR_MISE;
      }

      // 2023/12/06 店間移動時　発送、受取共に部門間移動許可マスタに存在しない場合は、部門＆AUが一致しなければならない
      if ((has_mise === 'MIS' && uir_mise === 'MIS'))
      {
        if (headerItem.BMNCDE !== uir_bmncde)
        {
          window.alert('移動できません。'); //TCGE0035：
          setUIRBMNCDEclr('#f59d9d');
          return 1;
        }
      }else{
        // 部門間チェック１
        console.log('部門間チェック１ 発送側検索');
        // 部門間移動許可マスタをチェック
        const responseA1 = await fetch('/TCGT0110/getInterdepartmental',{method: 'POST', headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ has_mise:has_mise, has_bmncde:headerItem.BMNCDE, uir_mise:uir_mise, uir_bmncde:uir_bmncde })});
        const dataA1 = await responseA1.json();
        if (dataA1.RCNT === 0){
          // 部門間チェック２
          console.log('部門間チェック２ 受取側検索');
          // 部門間移動許可マスタをチェック
          const responseA2 = await fetch('/TCGT0110/getInterdepartmental',{method: 'POST', headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ has_mise:uir_mise, has_bmncde:uir_bmncde, uir_mise:has_mise, uir_bmncde:headerItem.BMNCDE })});
          const dataA2 = await responseA2.json();
          if (dataA2.RCNT === 0){
            // 部門間チェック３
            console.log('部門間チェック３ 同一部門');
            // 部門間移動許可マスタに登録されていない発送店、受取店のため、発送分類CD ＝ 受取分類CD以外はエラー
            if (headerItem.BMNCDE !== uir_bmncde){
              window.alert('移動できません。'); //TCGE0035：
              setUIRBMNCDEclr('#f59d9d');
              return 1;
            }
          }
        }
      }
    }

    if (inputNxRef.current.disabled) {
      // 受取AUは無効状態なので、掛率にフォーカス移動させる
      inKAKEZEIRTU.current.focus(); // 次の項目に遷移
    } else {
      inputNxRef.current.focus(); // 次の項目に遷移
    }
    return 0;
  }

  // 明細データ抽出
  const getMoveDate =  async(inputNxRef) => {
    // const header = JSON.parse(JSON.stringify(headerItem));
    // header.SRYFOT = 0;   // 合計数量
    // header.GNKFOT = 0;   // 原価金額合計
    // header.BAKFOT = 0;   // 売価金額合計
    // setHeaderItem(header);
    // 掛け率対応　濱田課長待ち
    if (inKAKEZEIRTU.current.value !== '')
    {
      if (Number(inKAKEZEIRTU.current.value) <= 30)
      {
        if(!(window.confirm('30％以下の値が入力されていますがよろしいですか？\r\n掛率は消費税率ではありません。'))) { //TCGM0007：
          return;
        }
      }
    }

    setRowItem(rowData);
    setRows([]);
    setHeaderItem(HeaderItem => ({...HeaderItem,SRYFOT:0}));
    setHeaderItem(HeaderItem => ({...HeaderItem,GNKFOT:0}));
    setHeaderItem(HeaderItem => ({...HeaderItem,BAKFOT:0}));
    // console.log('明細データ抽出');
    let ret = await Header1_confirmed();

    if (ret === 0)
    {
      // inputNxRef.current.focus(); //次の項目に遷移
      setNextActive(inJAN1.current.id);
      setJan1(false);
      setDHeder(true);
    }
  };

  // ヘッダ部１入力 受取ＡＵ
  const onKeyDown_UIRASTCDE = async(inputRef, inputNxRef) => {
    const header = JSON.parse(JSON.stringify(headerItem));
    let Astcde = '';

    if(headerItem.KBN === '2'){
      // 区分が「２」の場合、以下のチェックを行う
      // 必須チェック
      if(inputRef.current.value === ''){
        setUIRASTCDEclr('#f59d9d');
        window.alert('受取ＡＵを入力して下さい。'); //TCGE0019：
        return 1;
      }

      // 桁数チェック
      if(inputRef.current.value.length < 3){
        Astcde = inputRef.current.value.padStart(3, '0');
        // header.UIR_ASTCDE = Astcde;
        //setHeaderItem(header);
      }else
      {
        Astcde = inputRef.current.value;
      }
      setHeaderItem(HeaderItem => ({...HeaderItem,UIR_ASTCDE:Astcde}));

      // ＡＵ存在チェック
      const getAUNAME = await fetch('/TCGT0110/getAUNAME',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ KSACDE:headerItem.KSACDE, BMNCDE:headerItem.UIR_BMNCDE, ASTCDE:Astcde })});
      const AUNAME = await getAUNAME.json();
      if(AUNAME.RCNT === 0) {
        setUIRASTCDEclr('#f59d9d');
        window.alert('ＡＵ入力エラー'); //TCGE0034：
        return 1;
      }

      // header.UIR_ASTMEI = AUNAME.AUMEI;          // 商品分類名（漢字）
      // setHeaderItem(header);
      setHeaderItem(HeaderItem => ({...HeaderItem,UIR_ASTMEI:AUNAME.AUMEI}));
    }

    inputNxRef.current.focus();  // 受取部門に遷移
    return 0;
  }

  // ヘッダ部２入力　ＪＡＮ１
  const onKeyDown_JAN1 = async(inputRef, inputNxRef) => {
    setChkJAN1('1');
    const row = await JSON.parse(JSON.stringify(rowItem));
    const header = await JSON.parse(JSON.stringify(headerItem));
    let Jancde = '';
    // ＪＡＮ１入力値の前0詰め
    Jancde = row.JANCDE;
    if(row.JANCDE.length === 7){
      row.JANCDE = row.JANCDE.padStart(8, '0');
      Jancde = row.JANCDE.padStart(8, '0');
    }
    if(9 <= row.JANCDE.length && row.JANCDE.length <= 12){
      row.JANCDE = row.JANCDE.padStart(13, '0');
      Jancde = row.JANCDE.padStart(13, '0');
    }
    console.log('商品コード'+row.JANCDE);

    // 2段判定
    //const jan1cd = inputRef.current.value.substring(0, 2);
    const jan1cd = Jancde.substring(0, 2);

    //if (inputRef.current.value.length == 13 && (
    if (Jancde.length == 13 && (
          (jan1cd === '22' ||jan1cd === '23')
        ||((jan1cd === '21' ||jan1cd === '22') && state.KSACDES ==='005')
    )){
      await setRow_Two_JAN1 (row,jan1cd);

      //次の項目に遷移
      if (disJan2 === false) {
        // 活性時の場合はuseEffectは発生しない
        inJAN2.current.focus();
      } else {
        // 非活性時は、活性化の描画完了後（useEffect）にフォーカス移動させる必要がある
        setNextActive(inJAN2.current.id);
        setJan2(false);
      }
      return;
    }
    if(!(jan1cd === '21' || jan1cd === '22' || jan1cd === '23')){
      setJan2(true);
      row.JANCDE2 = '';
    }

    // 衣料品事業部配下分類かどうかを確認
    const getIryoFlg = await fetch('/TCGT0110/getIryoFlg',{method: 'POST', headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ header:headerItem })});
    const iryo_flg = await getIryoFlg.json();

    // 衣料品フラグ = １ の場合
    if(iryo_flg.IRYOFLG === '1') {
      row.IRYO_FLG = '1';
    } else {
      row.IRYO_FLG = '0';
    }

    let shohinMaster = undefined;
    // 8桁の場合、商品マスタ（基本）にEOSとしてデータが存在するかチェック。
    //if(inputRef.current.value.length === 8){

    if(Jancde.length === 8){
      const getShohinMaster = await fetch('/TCGT0110/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({row:rowItem, EOSCDE:Jancde })});
      shohinMaster = await getShohinMaster.json();

      if(shohinMaster.ret.length !== 0) {
        row.IN_CD_KBN = '2'
      }
    };

    // 8、12、13 桁の場合、商品マスタ（基本）にJANとしてデータが存在するかチェック。
    // if(row.IN_CD_KBN !== '2' &&
    // (inputRef.current.value.length === 8 || inputRef.current.value.length === 12 || inputRef.current.value.length === 13)) {
    if(row.IN_CD_KBN !== '2' &&
      (Jancde.length === 8 || Jancde.length === 12 || Jancde.length === 13)) {
        console.log('８桁以外検索:%o',row);
        console.log('jancde:'+Jancde);
        const getShohinMaster = await fetch('/TCGT0110/getShohinMaster_JanCode',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({row:rowItem, JANCDEX:Jancde })});
        shohinMaster = await getShohinMaster.json();

      if(shohinMaster.ret.length !== 0) {
        row.IN_CD_KBN = '1'
      }
    }

    if(!shohinMaster) {
      setJAN1clr('#f59d9d');
      window.alert('商品が存在しません。'); //TCGE0047：
      return;
    }
    // 仕入先名称取得
    if (shohinMaster.ret.length !== 0) {
      row.SIRCDE = shohinMaster.ret[0].SIRCDE;  // 仕入先(取引先)コード
      const getSirmeik = await fetch('/TCGT0110/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:row.SIRCDE})});
      const sirmeik = await getSirmeik.json();
      if(sirmeik !== null) {
        row.SIRMEIK = sirmeik.SIRMEIK;          // 仕入先(取引先)名
      }
    }

    // ＪＡＮ１チェック
    // (1)
    if(shohinMaster.ret.length === 0) {
      setJAN1clr('#f59d9d');
      window.alert('商品が存在しません。'); //TCGE0047：
      return;
    }

    // (2)
    if(header.BMNCDE  !== shohinMaster.ret[0].BMNCDE) {
      setJAN1clr('#f59d9d');
      window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
      return;
    }

    // (3)
    // if(shohinMaster.ret[0].HPN_FKA_KBN === '1') {
    //   if(!(window.confirm('TCGC0003：警告：この商品は返品不可扱いです。強制返品しますか？'))){
    //     setJAN1clr('#f59d9d');
    //     return;
    //   }
    // }

    // データセット（1段）
    await setRow_One(row,shohinMaster.ret[0],Jancde);
    //await setRowItem(row);

    // 2023/10/11
    setDisRow(false);
    //次の項目に遷移
    //inputNxRef.current.focus();
    if (inputNxRef.current.value === '1.0') {
      // 表示が変わらない場合はuseEffectは発生しない
      inputNxRef.current.focus();
    } else {
      if (inputNxRef.current.id === 'SUU') {
        // 表示が変わるときは、数量の描画完了後（useEffect）にフォーカス移動させる必要がある
        setNextActive(inputNxRef.current.id);
      } else {
        // 数量以外へのフォーカス移動は問題なし
        inputNxRef.current.focus();
      }
    }
  }

  // データセット（1段）
  function setRow_One(row, shohinMaster,jancde){
    row.SHNMEIKN = shohinMaster.SHNMEIKN;      // 商品名
    row.KIKAKUKN = shohinMaster.KIKAKUKN;      // 規格
    row.JANCDE = jancde;         // ＪＡＮコード
    row.TRIHBN = shohinMaster.TRIHBN;          // 取引先品番
    setRowSuu(row, '1');                       // 数量
    // setRowGnt(row, shohinMaster.GNT);          // 原単価
    row.GNKKIN = 0;                            // 原価金額
    setRowBtn(row, shohinMaster.BTN);          // 売単価
    row.BAKKIN = 0;                            // 売価金額
    // if(headerItem.KAKEZEIRTU !== ''){
    //   //原単価＝売価×掛け率
    //   let gentanka = Number(shohinMaster.BTN) * Number(headerItem.KAKEZEIRTU) / 100;
    //   setRowGnt(row, gentanka);          // 原単価
    // }else{
    //   setRowGnt(row, shohinMaster.GNT);          // 原単価
    // }
    if(headerItem.KAKEZEIRTU == '' || headerItem.KAKEZEIRTU == '0'){
      setRowGnt(row, shohinMaster.GNT);          // 原単価
    }else{
      //原単価＝売価×掛け率
      let gentanka = Number(shohinMaster.BTN) * Number(headerItem.KAKEZEIRTU) / 100;
      setRowGnt(row, gentanka);          // 原単価
    }


    if( row.IN_CD_KBN === '2') {
      row.EOSCDE = jancde;       // ＥＯＳコード
    }

    if(row.IRYO_FLG === '1') {
      row.TAGASTCDE = shohinMaster.ASTCDE;     // タグＡＵ
      row.TAGLINCDE = shohinMaster.LINCDE;     // タグライン
      row.TAGCLSCDE = shohinMaster.CLSCDE;     // タグクラス
      row.TAGSZN = shohinMaster.TAGSZN;        // タグシーズン
      row.TAGSIZ = shohinMaster.TAGSIZCDE;     // サイズコード
      row.TAGSIZMEI = shohinMaster.TAGSIZMEI;  // サイズ名カナ
      row.TAGCOLCDE = shohinMaster.TAGCOLCDE;  // カラーコード
      row.TAGCOLMEI = shohinMaster.TAGCOLMEI;  // カラー名カナ
    }

    setRowItem(row);
  }

  // データセット（2段 JAN1時）
  function setRow_Two_JAN1 (row, jan1cd) {
    row.IRYO_FLG = '1';                        // 衣料フラグ
    row.JANCDE = inJAN1.current.value;         // ＪＡＮコード

    //row.EOSCDE = inJAN1.current.value;         // ＥＯＳコード
    if(jan1cd === '22') {
      row.IN_CD_KBN = '3'                      // 入力コード区分
    } else {
      row.IN_CD_KBN = '4'                      // 入力コード区分
      row.EOSCDE = inJAN1.current.value;         // ＥＯＳコード
    }
    setRowItem(row);
  }

  // ヘッダ部２入力　ＪＡＮ２（活性時）
  const onKeyDown_JAN2 = async (inputRef, inputNxRef) => {
    setChkJAN2('1');
    let row = JSON.parse(JSON.stringify(rowItem));

    // ＪＡＮ2入力値の前0詰め
    let Jan2cde = inJAN2.current.value;
    if(Jan2cde.length === 7){
      Jan2cde = Jan2cde.padStart(8, '0');
    }
    if(9 <= Jan2cde.length && Jan2cde.length <= 12){
      Jan2cde =Jan2cde.padStart(13, '0');
    }
    row.JANCDE2 = Jan2cde;

    const jan2cdH = inJAN2.current.value.substring(0, 2);
    // state.KSACDES = '001'の場合は、前２桁が「28」、「29」以外はエラー
    if(state.KSACDES === '001' && !(jan2cdH === '28' ||jan2cdH === '29')){
      setJAN2clr('#f59d9d');
      window.alert('ＪＡＮ２入力エラー。'); //TCGE00XX：
      return 1;
    }

    // state.KSACDES = '005'の場合は、前２桁が「26」、「27」以外はエラー
    if(state.KSACDES === '005' && !(jan2cdH === '26' ||jan2cdH === '27')){
      setJAN2clr('#f59d9d');
      window.alert('ＪＡＮ２入力エラー。'); //TCGE00XX：
      return 1;
    }

    const header = await JSON.parse(JSON.stringify(headerItem));
    const jan1cd = inJAN1.current.value.substring(0, 2);
    let tagMaster = undefined;
    let shohinMaster = undefined;
    //１）ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005以外もしくは、「２３」の場合
    if((jan1cd ==='22' && state.KSACDES !== '005')|| jan1cd ==='23') {
      //(1)ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005以外の場合
      if(jan1cd ==='22' && state.KSACDES !== '005') {
        const ASTCDE = Jan2cde.substring(2, 5);                      // ＡＵ
        const LINCDE = inJAN1.current.value.substring(2, 3);         // ライン
        const CLSCDE = '000' + inJAN1.current.value.substring(3, 4); // クラス
        const TAGSZN = inJAN1.current.value.substring(4, 5);         // タグシーズン
        const TAGITM = inJAN1.current.value.substring(5, 8);         // タグアイテム
        const TAGSIZCDE = inJAN1.current.value.substring(8, 10);     // サイズコード
        const TAGCOLCDE = inJAN1.current.value.substring(10, 12);    // カラーコード

        //①タグマスタから情報取得
        const getTagMaster = await fetch('/TCGT0110/getTagMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,MISE:state.MISES,ASTCDE:ASTCDE,LINCDE:LINCDE,CLSCDE:CLSCDE,TAGSZN:TAGSZN,TAGITM:TAGITM
        })});
        tagMaster = await getTagMaster.json();

        if (tagMaster.ret.length === 0){
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          return;
        }

        // 仕入先名称取得
        if (tagMaster.ret.length !== 0) {
          row.SIRCDE = tagMaster.ret[0].SIRCDE;     // 仕入先(取引先)コード
          const getSirmeik = await fetch('/TCGT0110/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:row.SIRCDE})});
          const sirmeik = await getSirmeik.json();
          if(sirmeik !== null) {
            row.SIRMEIK = sirmeik.SIRMEIK;          // 仕入先(取引先)名
          }
        }

        //② 衣料サイズマスタから情報取得
        const getSizeMaster = await fetch('/TCGT0110/getSizeMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,BMNCDE:header.BMNCDE,ASTCDE:ASTCDE,LINCDE:LINCDE,TAGSIZCDE:TAGSIZCDE
        })});
        const sizeMaster = await getSizeMaster.json();
        if(sizeMaster.ret.length !== 0) {
          row.TAGSIZ = sizeMaster.ret[0].TAGSIZCDE;     // サイズコード
          row.TAGSIZMEI = sizeMaster.ret[0].TAGSIZMEI;  // サイズ名カナ
        } else {
          row.TAGSIZ = '';     // サイズコード
          row.TAGSIZMEI = '';  // サイズ名カナ
        }


        //③ 衣料カラーマスタから情報取得
        const getColorMaster = await fetch('/TCGT0110/getColorMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({KSACDE:state.KSACDES,TAGCOLCDE:TAGCOLCDE})});
        const colorMaster = await getColorMaster.json();
        if(colorMaster.ret.length !== 0) {
          row.TAGCOLCDE = colorMaster.ret[0].TAGCOLCDE; // カラーコード
          row.TAGCOLMEI = colorMaster.ret[0].TAGCOLMEI; // カラー名カナ
        } else {
          row.TAGCOLCDE = ''; // カラーコード
          row.TAGCOLMEI = ''; // カラー名カナ
        }

        // 2023/10/06
        if(header.BMNCDE !== tagMaster.ret[0].BMNCDE) {
          console.log('header02:%o',header);
          setJAN1clr('#f59d9d');
          inJAN1.current.focus();
          window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
          return;
        }

        setRow_Two(row, tagMaster.ret[0]);
      }

      //(2) ＪＡＮ１の前２桁が「２３」の場合
      if(jan1cd ==='23') {
        // 桁数チェック
        if(Jan2cde.length !== 13){
          setJAN2clr('#f59d9d');
          window.alert('ＪＡＮ２入力エラー。'); //TCGE0047：
          return;
        }

        //① 商品マスタ(基本)から情報取得
        const EosCode = inJAN1.current.value.substring(2, 10);
        const getShohinMaster = await fetch('/TCGT0110/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({row:rowItem, EOSCDE:EosCode})});
        shohinMaster = await getShohinMaster.json();

        if (shohinMaster.ret.length === 0){
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          return;
        }

        row.EOSCDE = EosCode;
        //setRowGnt(row, shohinMaster.ret[0].GNT);          // 原単価
        setRowBtn(row, shohinMaster.ret[0].BTN);          // 売単価
        if(headerItem.KAKEZEIRTU !== ''){
          //原単価＝売価×掛け率
          let gentanka = Number(shohinMaster.ret[0].BTN) * Number(headerItem.KAKEZEIRTU) / 100;
          setRowGnt(row, gentanka);          // 原単価
        }else{
          setRowGnt(row, shohinMaster.ret[0].GNT);          // 原単価
        }
        //② 返品不可判定
        if (shohinMaster.ret[0].HPN_FKA_KBN === '1') {
          if(!(window.confirm('警告：この商品は返品不可扱いです。強制返品しますか？'))){  //TCGC0003：
            setJAN1clr('#f59d9d');
            inJAN1.current.focus();
            setRowItem(row);
            return;
          }
        }

        // 仕入先名称取得
        if (shohinMaster.ret.length !== 0) {
          row.SIRCDE = shohinMaster.ret[0].SIRCDE;  // 仕入先(取引先)コード
          const getSirmeik = await fetch('/TCGT0110/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:row.SIRCDE})});
          const sirmeik = await getSirmeik.json();
          if(sirmeik !== null) {
            row.SIRMEIK = sirmeik.SIRMEIK;          // 仕入先(取引先)名
          }
        }

        // 2023/10/06
        if(header.BMNCDE !== shohinMaster.ret[0].BMNCDE) {
          console.log('header02:%o',header);
          setJAN1clr('#f59d9d');
          inJAN1.current.focus();
          window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
          return 1;
        }

        setRow_Two2(row, shohinMaster.ret[0]);
      }

      //(3) 計算
      const taxcde = Number(Jan2cde.substring(5, 6));
      const jankng = Number(Jan2cde.substring(6, 12));
      //①２段目バーコードの6桁目(税区分)＝0(総額)の場合
      if(taxcde === 0){
        let tax = (jankng * dressTaxrate) / (100 + dressTaxrate)
        setRowBtn(row, jankng - tax);

        if(headerItem.KAKEZEIRTU !== ''){
          //原単価＝売価×掛け率
          let gentanka = (jankng - tax) * Number(headerItem.KAKEZEIRTU) / 100;
          setRowGnt(row, gentanka);          // 原単価
        }

      }

      //②２段目バーコードの6桁目(税区分)≠0(総額)の場合
      if(taxcde !== 0){
        setRowBtn(row, jankng);             // 売単価

        if(headerItem.KAKEZEIRTU !== ''){
          //原単価＝売価×掛け率
          let gentanka = jankng * Number(headerItem.KAKEZEIRTU) / 100;
          setRowGnt(row, gentanka);          // 原単価
        }
      }

      //③２段目バーコードの金額が０の場合。
      //設定済

    }

    //２）ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005もしくは、「２１」の場合
    if((jan1cd === '22' && state.KSACDES === '005') || jan1cd ==='21') {
      //(1)ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005の場合
      if(jan1cd === '22' && state.KSACDES === '005') {
        const ASTCDE = Jan2cde.substring(2, 5);                      // ＡＵ
        const LINCDE = inJAN1.current.value.substring(2, 3);         // ライン
        const CLSCDE = '000' + inJAN1.current.value.substring(3, 4); // クラス
        const TAGSZN = inJAN1.current.value.substring(4, 5);         // タグシーズン
        const TAGITM = inJAN1.current.value.substring(5, 8);         // タグアイテム
        const TAGSIZCDE = inJAN1.current.value.substring(8, 10);     // サイズコード
        const TAGCOLCDE = inJAN1.current.value.substring(10, 12);    // カラーコード

        //①タグマスタから情報取得
        const getTagMaster = await fetch('/TCGT0110/getTagMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,MISE:state.MISES,ASTCDE:ASTCDE,LINCDE:LINCDE,CLSCDE:CLSCDE,TAGSZN:TAGSZN,TAGITM:TAGITM
        })});
        tagMaster = await getTagMaster.json();

        if (tagMaster.ret.length === 0){
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          return;
        }

        // 仕入先名称取得
        if (tagMaster.ret.length !== 0) {
          row.SIRCDE = tagMaster.ret[0].SIRCDE;     // 仕入先(取引先)コード
          const getSirmeik = await fetch('/TCGT0110/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:row.SIRCDE})});
          const sirmeik = await getSirmeik.json();
          if(sirmeik !== null) {
            row.SIRMEIK = sirmeik.SIRMEIK;          // 仕入先(取引先)名
          }
        }

        //② 衣料サイズマスタから情報取得
        const getSizeMaster = await fetch('/TCGT0110/getSizeMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,BMNCDE:header.BMNCDE,ASTCDE:ASTCDE,LINCDE:LINCDE,TAGSIZCDE:TAGSIZCDE
        })});
        const sizeMaster = await getSizeMaster.json();
        if(sizeMaster.ret.length !== 0) {
          row.TAGSIZ = sizeMaster.ret[0].TAGSIZCDE;     // サイズコード
          row.TAGSIZMEI = sizeMaster.ret[0].TAGSIZMEI;  // サイズ名カナ
        } else {
          row.TAGSIZ = '';     // サイズコード
          row.TAGSIZMEI = '';  // サイズ名カナ
        }

        //③ 衣料カラーマスタから情報取得
        const getColorMaster = await fetch('/TCGT0110/getColorMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({KSACDE:state.KSACDES,TAGCOLCDE:TAGCOLCDE})});
        const colorMaster = await getColorMaster.json();
        if(colorMaster.ret.length !== 0) {
          row.TAGCOLCDE = colorMaster.ret[0].TAGCOLCDE; // カラーコード
          row.TAGCOLMEI = colorMaster.ret[0].TAGCOLMEI; // カラー名カナ
        } else {
          row.TAGCOLCDE = ''; // カラーコード
          row.TAGCOLMEI = ''; // カラー名カナ
        }

        // 2023/10/06
        if(header.BMNCDE !== tagMaster.ret[0].BMNCDE) {
          console.log('header02:%o',header);
          setJAN1clr('#f59d9d');
          inJAN1.current.focus();
          window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
          return;
        }
        setRow_Two(row, tagMaster.ret[0]);
      }
      //(2) ＪＡＮ１の前２桁が「２１」の場合
      if (jan1cd === '21') {
        // 桁数チェック
        if(Jan2cde.length !== 13){
          setJAN2clr('#f59d9d');
          window.alert('ＪＡＮ２入力エラー。'); //TCGE0047：
          return;
        }

        //① 商品マスタ(基本)から情報取得
        const EosCode = inJAN1.current.value.substring(4, 12);
        const getShohinMaster = await fetch('/TCGT0110/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({row:rowItem, EOSCDE:EosCode})});
        shohinMaster = await getShohinMaster.json();

        if (shohinMaster.ret.length === 0){
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          return;
        }

        row.EOSCDE = EosCode;
        //setRowGnt(row, shohinMaster.ret[0].GNT);           // 原単価
        setRowBtn(row, shohinMaster.ret[0].BTN);           // 売単価
        if(headerItem.KAKEZEIRTU !== ''){
          //原単価＝売価×掛け率
          let gentanka = Number(shohinMaster.ret[0].BTN) * Number(headerItem.KAKEZEIRTU) / 100;
          setRowGnt(row, gentanka);          // 原単価
        }else{
          setRowGnt(row, shohinMaster.ret[0].GNT);          // 原単価
        }

        //② 返品不可判定
        if (shohinMaster.ret[0].HPN_FKA_KBN === '1') {
          if(!(window.confirm('警告：この商品は返品不可扱いです。強制返品しますか？'))){  //TCGC0003：
            setJAN1clr('#f59d9d');
            inJAN1.current.focus();
            setRowItem(row);
            return;
          }
        }

        // 仕入先名称取得
        if (shohinMaster.ret.length !== 0) {
          row.SIRCDE = shohinMaster.ret[0].SIRCDE;  // 仕入先(取引先)コード
          const getSirmeik = await fetch('/TCGT0110/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:row.SIRCDE})});
          const sirmeik = await getSirmeik.json();
          if(sirmeik !== null) {
            row.SIRMEIK = sirmeik.SIRMEIK;          // 仕入先(取引先)名
          }
        }

        // 2023/10/06
        if(header.BMNCDE !== shohinMaster.ret[0].BMNCDE) {
          console.log('header02:%o',header);
          setJAN1clr('#f59d9d');
          inJAN1.current.focus();
          window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
          return 1;
        }

        setRow_Two2(row, shohinMaster.ret[0]);
      }

      //(3) 計算
      const jankng = Number(Jan2cde.substring(6, 12));
      setRowBtn(row, jankng);           // 売単価

      if(headerItem.KAKEZEIRTU !== ''){
        //原単価＝売価×掛け率
        let gentanka = jankng * Number(headerItem.KAKEZEIRTU) / 100;
        setRowGnt(row, gentanka);          // 原単価
      }
    }

    // 2023/10/11
    setDisRow(false);
    setRowItem(row);
    inputNxRef.current.focus();
  }

  // データセット（2段）
  function setRow_Two(row, tagMaster) {
    row.SHNMEIKN = tagMaster.SHNMEIKN;  // 商品名
    row.KIKAKUKN = tagMaster.KIKAKUKN;  // 規格
    setRowSuu(row, '1');                // 数量
    //setRowGnt(row, tagMaster.GNK);      // 原単価
    row.GNKKIN = 0;                     // 原価金額
    setRowBtn(row, tagMaster.BAK);      // 売単価
    row.BAKKIN = 0;                     // 売価金額

    if(headerItem.KAKEZEIRTU !== ''){
      //原単価＝売価×掛け率
      let gentanka = Number(tagMaster.BAK) * Number(headerItem.KAKEZEIRTU) / 100;
      setRowGnt(row, gentanka);          // 原単価
    }else{
      setRowGnt(row, tagMaster.GNK);      // 原単価
    }

    //row.JANCDE2 = inJAN2.current.value; // ＪＡＮコード２
    row.TAGASTCDE = tagMaster.ASTCDE;   // タグＡＵ
    row.TAGLINCDE = tagMaster.LINCDE;   // タグライン
    row.TAGCLSCDE = tagMaster.CLSCDE;   // タグクラス
    row.TAGSZN = tagMaster.TAGSZN;      // タグシーズン
    row.TAGITM = tagMaster.TAGITM;      // タグアイテム
    row.TRIHBN = tagMaster.TRIHBN;      // 取引先品番
  }

  // データセット（2段）2
  function setRow_Two2(row, shohinMaster) {
    row.SHNMEIKN = shohinMaster.SHNMEIKN;        // 商品名
    row.KIKAKUKN = shohinMaster.KIKAKUKN;        // 規格
    setRowSuu(row, '1');                         // 数量
    //setRowGnt(row, shohinMaster.GNT);            // 原単価
    row.GNKKIN = 0;                              // 原価金額
    setRowBtn(row, shohinMaster.BTN);            // 売単価
    row.BAKKIN = 0;                              // 売価金額

    if(headerItem.KAKEZEIRTU !== ''){
      //原単価＝売価×掛け率
      let gentanka = Number(shohinMaster.BTN) * Number(headerItem.KAKEZEIRTU) / 100;
      setRowGnt(row, gentanka);          // 原単価
    }else{
      setRowGnt(row, shohinMaster.GNT);      // 原単価
    }

    //row.JANCDE2 = inJAN2.current.value;          // ＪＡＮコード２
    row.TAGASTCDE = shohinMaster.ASTCDE;         // タグＡＵ
    row.TAGLINCDE = shohinMaster.LINCDE;         // タグライン
    row.TAGCLSCDE = shohinMaster.CLSCDE;         // タグクラス
    row.TAGSZN = shohinMaster.TAGSZN;            // タグシーズン
    row.TRIHBN = shohinMaster.TRIHBN;            // 取引先品番
    row.TAGSIZ = shohinMaster.TAGSIZCDE;         // サイズコード
    row.TAGSIZMEI = shohinMaster.TAGSIZMEI;      // サイズ名カナ
    row.TAGCOLCDE = shohinMaster.TAGCOLCDE;      // カラーコード
    row.TAGCOLMEI = shohinMaster.TAGCOLMEI;      // カラー名カナ
  }

  // ヘッダ部２入力 数量
  const onKeyDown_SUU = async(inputRef, inputNxRef) => {
    // 数量チェック
    if(rowItem.SUU === 0 || rowItem.SUU2 === '') {
      setSUUclr('#f59d9d');
      window.alert('数量に０は入力できません。'); //TCGE0063：
      return;
    }

    //次の項目に遷移
    inputNxRef.current.focus();
  }

  // ヘッダ部２入力　原単価
  const onKeyDown_GNT =  async (inputRef, inputNxRef) => {
    // 数量チェック
    if(rowItem.SUU === 0 || rowItem.SUU2 === '') {
      setSUUclr('#f59d9d');
      window.alert('数量に０は入力できません。'); //TCGE0063：
      return;
    }

    // (1) 数値チェック
    if(rowItem.GNT === 0 || rowItem.GNT2 === '') {
      setGNTclr('#f59d9d');
      window.alert('原単価に０円は入力できません。'); //TCGE0049：
      return;
    }

    //  四捨五入
    let gnkkin = rowItem.SUU * rowItem.GNT;
    gnkkin = Math.round(gnkkin);

    // (2) 1千万越えチェック
    if(gnkkin > 10000000){
      setGNTclr('#f59d9d');
      window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
      return;
    }

    // (3) 100万越えチェック
    if(gnkkin > 1000000){
      if(!(window.confirm('１００万円を超える金額が入力されました。'))){  //TTCGC0004：
        setGNTclr('#f59d9d');
        return;
      }
    }

    //次の項目に遷移
    inputNxRef.current.focus();
  }

  // ヘッダ部２入力　売単価
  const onKeyDown_BTN =  async (inputRef, inputNxRef) => {
    // 数量チェック
    if(rowItem.SUU === 0 || rowItem.SUU2 === '') {
      setSUUclr('#f59d9d');
      window.alert('数量に０は入力できません。'); //TCGE0063：
      return;
    }

    let row = JSON.parse(JSON.stringify(rowItem));
    if(headerItem.KAKEZEIRTU !== ''){
      //原単価＝売価×掛け率
      let gentanka = row.BTN * Number(headerItem.KAKEZEIRTU) / 100;
      setRowGnt(row, gentanka);          // 原単価
      setRowItem(row);
    }

    // (1) 数値チェック()
    if (row.BTN === 0 || row.BTN2 === ''){
      if(!window.confirm('売単価に０円が入力されています。よろしいですか？')){  //TCGC0007：
        setBTNclr('#f59d9d');
        return;
      }
    }

    // (2) 売単価 ＞ 原単価×10　チェック
    if (row.BTN > (row.GNT * 10)) {
      if(!window.confirm('売単価が原単価に比べて大きすぎます。よろしいですか？')){  //TCGC0008：
        setBTNclr('#f59d9d');
        return;
      }
    };

    // (3) 売単価 ＜ 原単価÷10 チェック
    if ((row.BTN < (row.GNT / 10) && row.BTN !== 0)) {
      if(!window.confirm('売単価が原単価に比べて小さすぎます。よろしいですか？')){  //TCGC0009：
        setBTNclr('#f59d9d');
        return;
      }
    };

    const bakkin = row.SUU * row.BTN;

    // (4) 1千万越えチェック
    if(bakkin > 10000000){
      setBTNclr('#f59d9d');
      window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
      return;
    }

    // (5) 100万越えチェック
    if(bakkin > 1000000){
      if(!(window.confirm('１００万円を超える金額が入力されました。'))){  //TTCGC0004：
        setBTNclr('#f59d9d');
        return;
      }
    }

    if (prefectureList.length !==0) {
      //次の項目に遷移
      inSANTIKBN.current.focus();
    } else {
      Header2_confirmed(row);
    }
  }

  // ヘッダ１部確定
  const Header1_confirmed  = async () =>　{
    console.log('ヘッダー項目チェック開始');
    let ret = await onKeyDown_KBN(inKBN,inBMNCDE,1);
    // console.log('onKeyDown_KBN ret:%o',ret);
    if (ret === 1){ return 1; }
    ret = await onKeyDown_BMNCDE(inBMNCDE,inASTCDE,1);
    if (ret === 1){ return 1; }
    ret = await onKeyDown_ASTCDE(inASTCDE,inHASDD,1);
    if (ret === 1){ return 1; }
    ret = await onKeyDown_HASDD(inHASDD,inUIRMISE,1);
    if (ret === 1){ return 1; }
    ret = await onKeyDown_UIRMISE(inUIRMISE,inUIRBMNCDE,1);
    if (ret === 1){ return 1; }
    ret = await onKeyDown_UIRBMNCDE(inUIRBMNCDE,inUIRASTCDE,1);
    if (ret === 1){ return 1; }
    ret = await onKeyDown_UIRASTCDE(inUIRASTCDE,inKAKEZEIRTU,1);
    if (ret === 1){ return 1; }

    return 0;

  }


  // ヘッダ部2入力　産地区分を選択の処理
  // コンボボックスでEnterキーのイベントを補足できない模様
  // 代わりに産地区分が変更されたら検索を行う
  useEffect(() => {
    console.log('更新掛かってる1？'+headerItem.BMNCDE+'/'+enableSearch);
    if (!enableSearch) {
      return;
    }
    if(rowItem.SANTI_KBN === '　'){
      const row = JSON.parse(JSON.stringify(rowItem));
      Header2_confirmed(row);
      return;
    }
    inTODOFUKENKUNI.current.focus();
  }, [rowItem.SANTI_KBN]);


  // ヘッダ部2入力　都道府県/国名を選択の処理
  // コンボボックスでEnterキーのイベントを補足できない模様
  // 代わりに都道府県/国名が変更されたら検索を行う
  useEffect(()=>{
    console.log('更新掛かってる2？'+headerItem.BMNCDE+'/'+enableSearch);
    if (!enableSearch) {
      return;
    }
    if(headerItem.BMNCDE === '1110' || headerItem.BMNCDE === '1120'){
      const row = JSON.parse(JSON.stringify(rowItem));
      Header2_confirmed(row);
      return;
    }
    inGENSANTI.current.focus();
  }, [rowItem.TODOFUKEN_KUNI]);

  // ヘッダ部2入力　原産地を選択の処理
  // コンボボックスでEnterキーのイベントを補足できない模様
  // 代わりに原産地が変更されたら検索を行う
  useEffect(()=>{
    if (!enableSearch) {
      return;
    }
    inSKHLBLKBN.current.focus();
  }, [rowItem.GENSANTI]);

  // ヘッダ部2入力　食品区分を選択の処理
  // コンボボックスでEnterキーのイベントを補足できない模様
  // 代わりに食品区分が変更されたら検索を行う
  useEffect(()=>{
    if (!enableSearch) {
      return;
    }
    const row = JSON.parse(JSON.stringify(rowItem));
    Header2_confirmed(row);
  }, [rowItem.SKHLBLKBN]);

  // ヘッダ部２入力　ヘッダ部２確定時
  const Header2_confirmed = async (row) => {
    console.log('Header2_confirmed_01:%o',row);
    const header = JSON.parse(JSON.stringify(headerItem));

    // ＪＡＮ１必須チェック
    if(row.JANCDE === '') {
      setJAN1clr('#f59d9d');
      window.alert('ＪＡＮ１を入力して下さい。');
      inJAN1.current.focus();
      return;
    }

    // 計算
    row.GNKKIN = Math.round(row.SUU * row.GNT);  // 原価金額
    row.BAKKIN = row.SUU * row.BTN;              // 売価金額

    // 数量チェック
    if(rowItem.SUU === 0 || rowItem.SUU2 === '') {
      setSUUclr('#f59d9d');
      window.alert('数量に０は入力できません。'); //TCGE0063：
      inSUU.current.focus();
      return;
    }

    // 入力チェック 原単価
    if(row.GNT === 0 || row.GNT2 === '') {
      setGNTclr('#f59d9d');
      window.alert('原単価に０円は入力できません。'); //TCGE0049：
      inGNT.current.focus();
      return;
    }
    // 1千万越えチェック
    if(row.GNKKIN > 10000000){
      setGNTclr('#f59d9d');
      setSUUclr('#f59d9d');
      window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
      inSUU.current.focus();
      return;
    }

    // 入力チェック 売単価
    // 1千万越えチェック
    if(row.BAKKIN > 10000000){
      setBTNclr('#f59d9d');
      window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
      inSUU.current.focus();
      return;
    }

    const selected = rows.filter(x => selectionModel.indexOf(x.id) != -1);
    // 新規追加時の処理
    if(selected.length !== 1) {
      // 入力内容の重複確認
      const new_rows = JSON.parse(JSON.stringify(rows));
      const old_row = new_rows.filter(element => {
        return element.JANCDE === row.JANCDE
        && element.GNT === row.GNT
        && element.BTN === row.BTN
      });

      // 既に入力済の内容であれば合算値を確認する
      if (old_row.length !== 0){
        let totalSuu = row.SUU;
        for(const ckRow of old_row) {
          totalSuu = totalSuu + ckRow.SUU;
        }

        // 原価金額合計 金額オーバーチェック
        let ckGnkkin = Math.round(totalSuu * row.GNT);
        if(ckGnkkin > 10000000){
          setGNTclr('#f59d9d');
          window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
          inSUU.current.focus();
          return;
        }

        // 売価金額合計 金額オーバーチェック
        let ckBakkin = totalSuu * row.BTN;
        if(ckBakkin > 10000000){
          setBTNclr('#f59d9d');
          window.alert('合計で１千万円を超える金額は入力できません。'); //TCGE0060：
          inSUU.current.focus();
          return;
        }
      }

      // 編集中の明細にidを設定する。
      row.id = rows.length + 1;

      // 明細リストの合計を再計算する。
      header.SRYFOT = Number(header.SRYFOT) + row.SUU;
      header.GNKFOT = Number(header.GNKFOT) + Number(row.GNKKIN);
      header.BAKFOT = Number(header.BAKFOT) + Number(row.BAKKIN);

      // 編集中データを反映する。
      setRowItem(rowData);
      setRows((rows) => ([...rows, row]));
      setHeaderItem(header);
      setDisRow(true);
      inJAN1.current.focus();
    }
    // 編集時の処理
    else {
      // 編集中の明細にidを設定する。
      row.id = selected[0].id;

      // 新しく保存する明細リスト
      let new_rows = JSON.parse(JSON.stringify(rows));
      new_rows = await new_rows.map((target) => (target.id === selected[0].id ? row : target));

      // 入力内容の重複確認
      const old_row = new_rows.filter(element => {
        return element.JANCDE === row.JANCDE
        && element.GNT === row.GNT
        && element.BTN === row.BTN
      });

      // 同じ値のデータ合算値を確認する
      if (old_row.length !== 0){
        // 合計数量計算
        let totalSuu = 0;
        for(const ckRow of old_row) {
          totalSuu = totalSuu + ckRow.SUU;
        }

        // 原価金額合計 金額オーバーチェック
        let ckGnkkin = Math.round(totalSuu * row.GNT);
        if(ckGnkkin > 10000000){
          setGNTclr('#f59d9d');
          window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
          inSUU.current.focus();
          return;
        }

        // 売価金額合計 金額オーバーチェック
        let ckBakkin = totalSuu * row.BTN;
        if(ckBakkin > 10000000){
          setBTNclr('#f59d9d');
          window.alert('合計で１千万円を超える金額は入力できません。'); //TCGE0060：
          inSUU.current.focus();
          return;
        }
      }

      // 明細リストの合計を再計算する。
      let totalSuryo = 0;   // 合計数量
      let totalGenka = 0;   // 原価金額合計
      let totalBaika = 0;   // 売価金額合計

      new_rows.forEach(element => {
        totalSuryo += element.SUU;
        totalGenka += Number(element.GNKKIN);
        totalBaika += Number(element.BAKKIN);
      });

      header.SRYFOT = totalSuryo;
      header.GNKFOT = totalGenka;
      header.BAKFOT = totalBaika;

      // 編集中データを反映する。
      setSelectionModel([]);
      setRows(new_rows);
      setRowItem(rowData);
      setDisRow(true);
      setHeaderItem(header);
      inJAN1.current.focus();
    }
  }

  // フッタ部　取消
  const onClick_FooterButton_Reset = async () => {
    if(window.confirm('入力された内容を破棄します。よろしいですか？')){ //TCGM0007：
      setKBNclr('#FFFFFF');
      setBMNCDEclr('#FFFFFF');
      setASTCDEclr('#FFFFFF');
      setHASDDclr('#FFFFFF');
      setUIRMISEclr('#FFFFFF');
      setUIRBMNCDEclr('#FFFFFF');
      setUIRASTCDEclr('#FFFFFF');
      setJAN1clr('#FFFFFF');
      setJAN2clr('#FFFFFF');
      setSUUclr('#FFFFFF');
      setGNTclr('#FFFFFF');
      setBTNclr('#FFFFFF');
      setRowItem(rowData);
      setHeaderItem(headerData);
      setRows([]);
      //「１」の場合、画面.発送ＡＵと画面.受取ＡＵに「000」を設定し、非活性にする。
      setDisASTCDE(true);
      setDisUIRASTCDE(true);
      setDHeder(false);
      setJan1(true);
      setJan2(true);
      setDisRow(true);
      setHeaderHASDD();
      setPrefectureList([]);
      setPrefectureList2(0);
      //inBMNCDE.current.focus();
      setTimeout(() => {inBMNCDE.current.focus();},100);
  } else {
      // inJAN1.current.focus();
      setTimeout(() => {inJAN1.current.focus();},100);
    }
  }

  // フッタ部　削除
  const onClick_FooterButton_Delete = async () => {
    let target_rows = JSON.parse(JSON.stringify(rows))
    const selected = target_rows.filter(x => selectionModel.indexOf(x.id) != -1);
    if(selected.length !== 1)
    {
      return;
    }
    setSelectionModel([]);

    const targetRow = selected[0];

    // 除処理を行うか確認メッセージを表示する
    if (!(window.confirm(''+ targetRow.SHNMEIKN +'を削除します。よろしいですか？'))) {  //TCGM0008：
      return;
    }

    target_rows = target_rows.filter(x => x.id != targetRow.id);

    // 合計額再計算
    const header = JSON.parse(JSON.stringify(headerItem));
    let id = 1;
    let totalSuryo = 0;   // 合計数量
    let totalGenka = 0;   // 原価金額合計
    let totalBaika = 0;   // 売価金額合計
    target_rows.forEach(element => {
      element.id = id;
      totalSuryo += element.SUU;
      totalGenka += Number(element.GNKKIN);
      totalBaika += Number(element.BAKKIN);
      id += 1;
    });
    header.SRYFOT = totalSuryo;
    header.GNKFOT = totalGenka;
    header.BAKFOT = totalBaika;
    setRowItem(rowData);
    setRows(target_rows);
    setHeaderItem(header);
    // inJAN1.current.focus();
    setTimeout(() => {inJAN1.current.focus();},100);
  }

  // フッタ部　登録
  const onClick_FooterButton_Send = async()=> {
    if (rows.length === 0) {
      window.alert('登録するデータが存在しません。'); //TCGE0088：
      return;
    }

    if ((window.confirm('データの更新を行います。よろしいですか？'))) { //TCGM0009：
      // 重複行を合算する処理
      let combinedList = [];
      for(const add_row of rows) {
        // 入力内容の重複確認
        const matched_row = combinedList.filter(element => {
          return element.JANCDE === add_row.JANCDE
          && element.GNT === add_row.GNT
          && element.BTN === add_row.BTN
        });

        // 既に登録済の内容であれば数を合算する
        if (matched_row.length !== 0) {
          matched_row[0].SUU = matched_row[0].SUU + add_row.SUU;
          matched_row[0].GNKKIN = Math.round(matched_row[0].SUU * add_row.GNT); // 原価金額
          matched_row[0].BAKKIN = matched_row[0].SUU * add_row.BTN;             // 売価金額
          combinedList = combinedList.map((target) => (target === matched_row ? matched_row[0] : target));
        }else{
          // 登録済みで無ければ行追加
          combinedList.push(add_row);
        }
      }

      const header = JSON.parse(JSON.stringify(headerItem));

      let d = new Date(state.SDAY);
      let year = d.getFullYear().toString();
      let month = (d.getMonth() + 1).toString().padStart(2, '0');
      let day = d.getDate().toString().padStart(2, '0');
      header.DENYMD = year + month + day;

      // SEQの取得
      const getSeqNo = await fetch('/TCGT0110/getSeqNo',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({header:header})});
      const resseqno = await getSeqNo.json();

      // 登録用のボディデータ作成
      const updateHeder = [];
      const updateRows = [];
      let pushRowlist = [];
      let denno = await getNowDenno(header);
      let hederSEQ = Number(resseqno.SEQ) + 1;
      let checkGenka = 0;
      let checkBaika = 0;
      let count = 1;
      // 10/13 登録時のバグ修正
      //await Promise.all(rows.map(async(row) =>{
      for(const row of combinedList){
        console.log('row:%o',row);
        if(count > 20
            || checkGenka + Number(row.GNKKIN) > 10000000
            || checkBaika + Number(row.BAKKIN) > 10000000
        ) {
          // 20件、もしくは合計原価、合計売価が1千万を超える場合とSEQ番号を変更する。
          updateRows.push(pushRowlist);
          pushRowlist = [];
          count = 1;
          checkGenka = 0;
          checkBaika = 0;
          denno = await getNowDenno(header);
        }
        row.ASTCDE = header.ASTCDE;
        row.GYONO = count;
        row.SEQ = hederSEQ;
        row.DENNO = denno;
        row.BMNCDE = header.BMNCDE;
        row.DENYMD = header.DENYMD;
        row.DEN_EDABAN = header.DEN_EDABAN;
        row.JGNCDE = header.JGNCDE;
        row.PRG_ID = header.PRG_ID;

        checkGenka += Number(row.GNKKIN);
        checkBaika += Number(row.BAKKIN);
        pushRowlist.push(row);
        count++;
      }
      //}));
      updateRows.push(pushRowlist);

      // 登録用のヘッダデータ作成
      updateRows.forEach(rowsList => {
        const pushHeder = JSON.parse(JSON.stringify(header));
        pushHeder.SEQ = rowsList[0].SEQ;
        pushHeder.DENNO = rowsList[0].DENNO;
        let totalSuryo = 0;   // 合計数量
        let totalGenka = 0;   // 原価金額合計
        let totalBaika = 0;   // 売価金額合計
        rowsList.forEach(row => {
          totalSuryo += row.SUU;
          totalGenka += Number(row.GNKKIN);
          totalBaika += Number(row.BAKKIN);
        });
        pushHeder.SRYFOT = totalSuryo;
        pushHeder.GNKFOT = totalGenka;
        pushHeder.BAKFOT = totalBaika;
        updateHeder.push(pushHeder);
      })

      // データの更新
      const response = await fetch('/TCGT0110/send',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          header:updateHeder,
          rows:updateRows,
      })});

      const data = await response.json();
      if (data.res === -1) {
        window.alert('登録エラー');
        return;
      }else{
        const header2 = headerData;
        header2.KBN = header.KBN;
        header2.BMNCDE = header.BMNCDE;
        header2.BMNMEI = header.BMNMEI;
        header2.ASTCDE = header.ASTCDE;
        header2.HASDD = header.HASDD;
        header2.HASDD2 = header.HASDD2;
        if(header2.KBN === '2'){
          header.UIR_ASTCDE = '';
        }

        setHeaderItem(header2);
        setDHeder(false);
        setJan1(true);
        setJan2(true);
        setDisRow(true);
        setRowItem(rowData);
        setRows([]);

        setTimeout(() => {inUIRMISE.current.focus();},100);
        return;
      }
    }
  }

  const getNowDenno = async(header) => {
    // 採番マスタ取得
    let denpyoNo = {
      KSACDE:header.KSACDE,     // 会社コード
      MISE:header.MISE,         // 店コード
      MR_KBN:'1',               // 移動返品区分
      MIN_VLU:'1',              // 最小値
      MAX_VLU:'999',            // 最大値
      JGNCDE:header.JGNCDE,     // ユーザーID
      PRG_ID:header.PRG_ID,     // プログラムID
      TRL_ID:header.TRL_ID,     // 端末ＩＤ
      DELFLG:'0'                // 削除フラグ
    }

    // 採番マスタ取得
    const getDenNo = await fetch('/TCGT0110/getDenNo',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({denpyoNo:denpyoNo})});
    const resdenno = await getDenNo.json();
    const NOW_DENNO = resdenno.denno.toString().padStart(3, '0');

    // 伝票番号の取得
    return '0' + state.MISES + NOW_DENNO;
  }

  // 商品名Cell
  const SHNMEIKNCell = (params) => {
    if (params.row.IRYO_FLG !== '1')
    {
      // 衣料フラグ≠1なら表示
      return (
        <div>
          <Typography>{params.row.SHNMEIKN}</Typography>
          <Typography color='textSecondary'>{params.row.KIKAKUKN + ' ' + params.row.JANCDE}</Typography>
        </div>
      )
    } else {
      // 衣料フラグ=1なら表示
      return (
        <div>
          <Typography>{params.row.SHNMEIKN}</Typography>
          <Typography color='textSecondary'>
            {params.row.TRIHBN + ' ' + params.row.TAGSIZ + ' ' + params.row.TAGSIZMEI
            + ' ' + params.row.TAGCOLCDE + ' ' + params.row.TAGCOLMEI}</Typography>
        </div>
      )
    }
  };

  // 原産地Cell
  const GENSANTICell = (params) => {
        // 産地区分マスタと紐づけ
        let kbnMei = '';
        let gensantiMei = '';
        let syokuhinMei = '　';
        // 都道府県国で都道府県マスタの一致する区分を探す
        prefectureList.forEach(element => {
          //console.log('prefectureList.forEach:%o',element);
          //console.log('prefectureList2.forEach:%o',params.row);
          if (element.value === params.row.TODOFUKEN_KUNI) {
            kbnMei = element.label;
          }
        });
        console.log('kbnMei:'+kbnMei);
        // 食品区分で食品マスタの一致する区分を探す
        foodLabelList.forEach(element => {
          console.log('foodLabelList.forEach:%o',element);
          console.log('foodLabelList2.forEach:%o',params.row);
          if (element.value === params.row.SKHLBLKBN) {
            syokuhinMei = element.label;
          }
        });
        console.log('syokuhinMei:'+syokuhinMei);

        // 産地区分が1もしくは2の場合
        if ((params.row.SANTI_KBN === '1') || (params.row.SANTI_KBN === '2')) {
          // 「産」を付与して設定
          gensantiMei = kbnMei + '産';
        // 産地区分が3の場合
        } else if ((params.row.SANTI_KBN === '3')) {
          // 原産地に値が存在するか？
          if(params.row.GENSANTI) {
            // 原産地で産地区分マスタの一致する区分を探す
            // console.log('GENSANTI:'+params.row.GENSANTI);
            homeList.forEach(element => {
              // console.log('element.cd:'+element.cd);
              if (element.value == params.row.GENSANTI) {
                gensantiMei = '原料原産地　' + kbnMei + '（' + element.label + '）' +'　など';
              }
            });
          // それ以外
          } else {
            gensantiMei = '原料原産地　' + kbnMei + '　など';
          }
        }
        return (
          <div>
            {/* <Typography>{gensantiMei}</Typography> */}
            {/* <Typography>{syokuhinMei}</Typography> */}
            <Typography sx={{fontSize:14}}>{gensantiMei}</Typography>
            <Typography sx={{fontSize:14}}>{syokuhinMei}</Typography>
          </div>
        )

    // // 原産地
    // let home = homeList.filter(element => {
    //   return element.value === params.row.GENSANTI;
    // });
    // if(params.row.GENSANTI === ''){
    //   home = ' ';
    // }
    // // 食品区分
    // let foodLabel = foodLabelList.filter(element => {
    //   return element.value === params.row.SKHLBLKBN;
    // })
    // if(params.row.SKHLBLKBN === ''){
    //   foodLabel = ' ';
    // }
    // return (
    //   <div>
    //     <Typography>{home[0].label}</Typography>
    //     <Typography color='textSecondary'>{foodLabel[0].label}</Typography>
    //   </div>
    // )
  };
  // グリッド列情報(VSCでエラー出てるけど動くよ)
  const columns/*: GridColDef[]*/ = [
    // {
    //   field: 'detailAction',
    //   headerName: '　', // セルの右側に罫線を表示するために全角スペースを表示する
    //   align: 'left',
    //   width: 60,
    //   type: 'actions',
    //   getActions: getDetailAction,
    //   description: '詳細画面を表示',  // ツールチップで表示される説明
    //   hideable: false,              // 非表示にできないようにする
    //   sortable: false,              // ソートできないようにする
    //   hideSortIcons: false,         // ソートアイコンを表示しない
    //   resizable: false,             // リサイズできないようにする
    //   disableExport: false,         // エクスポートに含めないようにする
    //   disableReorder: false,        // 並べ替えできないようにする
    //   filterable: false,            // フィルタリングしないようにする
    //   disableColumnMenu: true       // カラムメニューを表示しない
    // } as GridColDef ,
    {
      field: 'id',
      headerName: 'No',
      headerAlign: 'center',
      align:'center',
      width: 80,
      sortable:false,
      disableColumnMenu:true
    }, //IDは必ず必要みたい
    {
      field: 'SHNMEIKN',
      headerName: '商品名',
      headerAlign: 'center',
      width: 350,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true,
      renderCell: (params) => SHNMEIKNCell(params)
    },
    {
      field: 'SUU2',
      headerName: '数量',
      type: 'number',
      headerAlign: 'center',
      width: 80,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true
    },
    {
      field: 'GNT2',
      headerName: '原単価',
      type: 'number',
      headerAlign: 'center',
      width: 120,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true
    },
    {
      field: 'GNKKIN',
      headerName: '原価金額',
      type: 'number',
      headerAlign: 'center',
      width: 120,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true
    },
    {
      field: 'BTN2',
      headerName: '売単価',
      type: 'number',
      headerAlign: 'center',
      width: 120,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true
    },
    {
      field: 'BAKKIN',
      headerName: '売価金額',
      type: 'number',
      headerAlign: 'center',
      width: 120,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true
    },
    {
      field: 'GENSANTI',
      headerName: '原産地',
      headerAlign: 'center',
      align:'center',
      width: 120,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => GENSANTICell(params)
    },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params: GridValueGetterParams) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  //罫線表示（Data Grid）
  const gridStyle = {
    transform: 'scale(0.9)',
    // 他のスタイルプロパティをここに追加することもできます
  };

  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  // 確認のWidthを設定する
  const cssInputOutlineRetSetwidth = (obj) => {
    let resObj = obj;
    resObj.width = '50px';
    return resObj;
  }

  //罫線表示（Data Grid）
  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    // csspopmenu:
    // {
    //   '& .MuiPaper-root': {
    //     '& .MuiMenu-paper': {
    //       '& .MuiPaper-elevation': {
    //         top: '10px',
    //       },
    //       '& .MuiPopover-paper': {
    //         top: '10px',
    //       },
    //       top: '10px',
    //     },
    //     top: '10px',
    //   },
    // },

    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
    cssInputOutlineJotKbn: {
      '> div': {
        minHeight: 'auto !important',
        paddingTop: '.4em',
        paddingBottom: '.3em',
      }
    },
    cssInputSelect: {
      ' label': {
        color: '#000000'
      },
      ' fieldset': {
        borderColor: '#000000'
      },
    },
  }

  //罫線表示（Data Grid）
  const styles2 = {
    grid: {
      '.MuiDataGrid-toolbarContainer': {
        borderBottom: 'solid 1px rgba(224, 224, 224, 1)'  // ついでにツールバーの下に罫線を引く
      },
      '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
        borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      // 2022/06/01 追記 列ヘッダの最終セルの右側に罫線が表示されなくなった・・・
      // 列ヘッダの最終列の右端に罫線を表示する
      '.MuiDataGrid-columnHeadersInner': {
        border: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      '.MuiDataGrid-columnSeparator--sideRight':{
        visibility: 'show',
      },
      '.MuiDataGrid-columnHeaders':{
        height:'40px',
      },
      // 削除済明細は背景色をグレーとする
      '.MuiDataGrid-row.row-deactive': {
        background: '#888888',
      }
    }
  }
  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: '750px',
          width: '1050px',
          m: '10px auto',
          padding:"10px",
        }}
      >
        <Grid
          container
          direction='column'
          justifyContent='flex-start' //多分、デフォルトflex-startなので省略できる。
          alignItems='left'
        >
          <div style={{display:'flex'}}>
            <Typography variant={'h5'} sx={{ m: '0px',height:'30px' }}>
            移動伝票作成
            </Typography>
            <Box width={'72%'} display={'flex'} justifyContent={'flex-end'}>
              <Typography variant={'h6'} sx={{ m: '8px' }}>
                { state.MISEMEI }：{ state.JGNMEIKNJ }
              </Typography>
              <Typography variant={'h6'} sx={{ m: '8px' }}>
                { sdate }
              </Typography>
            </Box>
          </div>
        </Grid>

        <Paper
            elevation={2}
            sx={{
              p: 2,
              height: '80px',
              width: '1012px',
            }}
          >
          <Stack direction='row' sx={{mb:'10px'}}>
            <NumericFormat  //区分
              id={'KBN'}
              inputRef={inKBN}                   //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='区分'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              //sx={{width: '5ch',mr:'10px',background:inKBNclr}}                  //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 1), {background:inKBNclr})}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.KBN}              //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disHeder}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onChange={(e)=>{
                const header = JSON.parse(JSON.stringify(headerItem));
                header.KBN = e.target.value;
                setHeaderItem(header);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inKBN,inKBN,inBMNCDE)}
              onFocus={(e) => onMyFocus(e)}
              />
            <Box >
              <InputLabel variant={'standard'} sx={{fontSize:14,mt:'0px'}}>1:店間</InputLabel>
              <InputLabel variant={'standard'} sx={{fontSize:14,mt:'0px'}}>2:店内</InputLabel>
            </Box>
            <NumericFormat  //発送部門CD
              id={'BMNCDE'}
              inputRef={inBMNCDE}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='発送部門'                       //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              //sx={{width: '8ch',ml:'20px',background:inBMNCDEclr}}                 //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 4), {background:inBMNCDEclr},{ml:'10px'})}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.BMNCDE}
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disHeder}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onChange={(e)=>{
                const header = JSON.parse(JSON.stringify(headerItem));
                header.BMNCDE = e.target.value;
                setHeaderItem(header);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inBMNCDE,inKBN,inASTCDE)}
              onFocus={(e) => onMyFocus(e)}
              />
            <InputLabel variant={'outlined'}sx={{mr:'30px'}}>{ headerItem.BMNMEI.trim() }</InputLabel>
            <NumericFormat  //発送AU
              id={'ASTCDE'}
              inputRef={inASTCDE}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='発送AU'                       //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              //sx={{width: '8ch',ml:'20px',mr:'10px',background:inASTCDEclr}}                 //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 3), {background:inASTCDEclr})}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.ASTCDE}
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disASTCDE}                //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 3,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 3,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onChange={(e)=>{
                const header = JSON.parse(JSON.stringify(headerItem));
                header.ASTCDE = e.target.value;
                setHeaderItem(header);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inASTCDE,inBMNCDE,inHASDD)}
              onFocus={(e) => onMyFocus(e)}
              />
            <InputLabel variant={'outlined'}sx={{mr:'30px'}}>{ headerItem.ASTMEI }</InputLabel>
            <PatternFormat  //発送日
              id={'HASDD'}
              inputRef={inHASDD}                   //項目名定義？
              format='####/##/##'
              mask={['Y','Y','Y','Y','M','M','D','D']}
              color='success'
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='発送日'                        //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              onValueChange={(values, sourceInfo) => {
                const header = JSON.parse(JSON.stringify(headerItem));
                header.HASDD = values.value;
                header.HASDD2 = values.formattedValue;
                setHeaderItem(header);
              }}
              //sx={{width: '13ch',background:inHASDDclr}}                //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 8), {background:inHASDDclr})}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.HASDD2}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disHeder}                    //使用可／不可
              placeholder='YYYY/MM/DD'
              //inputProps={{ autoComplete: 'off',maxLength: 11, style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onKeyDown={(e) => onKeyDown(e.key,inHASDD,inASTCDE,inUIRMISE)}
              onFocus={(e) => onMyFocus(e)}
              />
          </Stack>
          <Stack direction='row' sx={{mb:'15px'}}>
            <NumericFormat  //受取店
              id={'UIRMISE'}
              inputRef={inUIRMISE}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='受取店'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              // sx={{width: '7ch',background:inUIRMISEclr}}                 //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 3), {background:inUIRMISEclr})}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.UIR_MISE}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disHeder}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 3,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 3,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onChange={(e)=>{
                const header = JSON.parse(JSON.stringify(headerItem));
                header.UIR_MISE = e.target.value;
                setHeaderItem(header);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inUIRMISE,inHASDD,inUIRBMNCDE)}
              onFocus={(e) => onMyFocus(e)}
              />
            <InputLabel variant={'outlined'} sx={{mr:'30px'}}>{ headerItem.UIR_MISEMEI }</InputLabel>
            <NumericFormat  //受取部門
              id={'UIRBMNCDE'}
              inputRef={inUIRBMNCDE}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='受取部門'                       //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              // sx={{width: '8ch',background:inUIRBMNCDEclr}}                  //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 4), {background:inUIRBMNCDEclr})}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.UIR_BMNCDE}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disHeder}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onChange={(e)=>{
                const header = JSON.parse(JSON.stringify(headerItem));
                header.UIR_BMNCDE = e.target.value;
                setHeaderItem(header);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inUIRBMNCDE,inUIRMISE,inUIRASTCDE)}
              onFocus={(e) => onMyFocus(e)}
              />
            <InputLabel variant={'outlined'}sx={{mr:'50px'}}>{ headerItem.UIR_BMNMEI.trim() }</InputLabel>
            <NumericFormat  //受取AU
              id={'UIRASTCDE'}
              inputRef={inUIRASTCDE}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='受取AU'                       //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              // sx={{width: '8ch',ml:'20px',mr:'10px',background:inUIRASTCDEclr}}                 //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 3), {background:inUIRASTCDEclr})}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.UIR_ASTCDE}
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disUIRASTCDE}             //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 3,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 3,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onChange={(e)=>{
                const header = JSON.parse(JSON.stringify(headerItem));
                header.UIR_ASTCDE = e.target.value;
                setHeaderItem(header);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inUIRASTCDE,inUIRBMNCDE,inKAKEZEIRTU)}
              onFocus={(e) => onMyFocus(e)}
              />
            <InputLabel variant={'outlined'}sx={{mr:'30px'}}>{ headerItem.UIR_ASTMEI }</InputLabel>
            <NumericFormat  //掛率追加
              id={'KAKEZEIRTU'}
              inputRef={inKAKEZEIRTU}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='掛け率'                       //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={true}            //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              // sx={{width: '8ch'}}                 //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 3))}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.KAKEZEIRTU}       //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disHeder}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 3,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 3,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              isAllowed={(values) => {
                const numValue = Number(values.value);
                if (isNaN(numValue)) return true;
                return numValue >= 0 && numValue <= 100;
              }}
              onChange={(e)=>{
                const header = JSON.parse(JSON.stringify(headerItem));
                header.KAKEZEIRTU = e.target.value;
                setHeaderItem(header);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inKAKEZEIRTU,inUIRASTCDE,inJAN1)}
              onFocus={(e) => onMyFocus(e)}
              />
              <InputLabel variant={'outlined'} sx={{mr:'5px'}}>{ '%' }</InputLabel>
              <InputLabel variant={'outlined'} sx={{mr:'5px',mt:'-20px',fontSize:12}}>掛け率＝入力された値を売単価に掛けて原単価を計算します。<br/>
              　　　　衣料品の何掛けで移動する等で使用<br/>　　　　※消費税率ではありません。</InputLabel>
          </Stack>
        </Paper>
        <Paper
            elevation={2}
            sx={{
              p: 2,
              height: '130px',
              width: '1012px',
              mt: '5px'
            }}
          >
          <Stack direction='row' sx={{mb:'10px'}}>
            <NumericFormat  //JANCD1
              id={'JAN1'}
              inputRef={inJAN1}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='ＪＡＮ１'                      //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              // sx={{width: '15ch',mr:'10px',background:inJAN1clr}}                  //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 11), {background:inJAN1clr})}  //2023/11/07 入力項目の表示内容拡大
              value={rowItem.JANCDE}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disJan1}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onChange={(e)=>{
                const row = JSON.parse(JSON.stringify(rowData));
                row.JANCDE = e.target.value;
                setRowItem(row);
                setChkJAN1('0');
                setDisRow(true);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inJAN1,inKAKEZEIRTU,inSUU)}
              onFocus={(e) => onMyFocus(e)}
              onBlur={() => onblur(inJAN1)}
              />
              &nbsp;
            <NumericFormat  //数量
              id={'SUU'}
              inputRef={inSUU}
              color='success'
              thousandSeparator={','}
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={true}            //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={1}                    //[PDA、Tablet対応]少数部の桁[0]
              customInput={TextField}
              size='small'
              label='数量'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              // sx={{width: '80px',mr:'10px',background:inSUUclr}}
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 6), {background:inSUUclr})}  //2023/11/07 入力項目の表示内容拡大
              value={rowItem.SUU2}
              allowLeadingZeros
              disabled={disRow}                    //使用可／不可
              //inputProps={{autoComplete: 'off',maxLength: 9,style: {textAlign: 'right'} }}
              inputProps={{ autoComplete: 'off',maxLength: 9,style: {textAlign: 'right',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              isAllowed={(values) => {
                const numValue = Number(values.value);
                if (isNaN(numValue)) return true;
                return numValue >= 0 && numValue <= 99999.9;
              }}
              // onValueChange={(values, sourceInfo) => {
              //   const row = JSON.parse(JSON.stringify(rowItem));
              //   row.SUU = values.floatValue;
              //   row.SUU2 = values.formattedValue;
              //   setRowItem(row);
              // }}
              onChange={(e)=>{
                const row = JSON.parse(JSON.stringify(rowItem));
                // let suu = Number(e.target.value);
                let suu = Number(String(e.target.value||0).replaceAll(',',''));
                if (isNaN(suu) || e.target.value === ''){
                  row.SUU = 0;
                  row.SUU2 = '0.0';
                }
                else{
                  row.SUU = suu;
                  row.SUU2 = suu.toLocaleString(undefined,{minimumFractionDigits : 1});
                }
                setRowItem(row);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inSUU,inJAN1,inGNT)}
              onFocus={(e) => onMyFocus(e)}
            />
            &nbsp;
            <NumericFormat  //原単価
              id={'GNT'}
              inputRef={inGNT}
              color='success'
              thousandSeparator={','}
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={true}            //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={2}                    //[PDA、Tablet対応]少数部の桁[0]
              customInput={TextField}
              size='small'
              label='原単価'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              // sx={{width: '110px',mr:'10px',background:inGNTclr}}
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 9), {background:inGNTclr})}  //2023/11/07 入力項目の表示内容拡大
              value={rowItem.GNT2}
              allowLeadingZeros
              disabled={disRow}                    //使用可／不可
              //inputProps={{autoComplete: 'off',maxLength: 13,style: {textAlign: 'right'} }}
              inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'right',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              isAllowed={(values) => {
                const numValue = Number(values.value);
                if (isNaN(numValue)) return true;
                return numValue >= -9999999.99 && numValue <= 9999999.99;
              }}
              // onValueChange={(values, sourceInfo) => {
              //   const row = JSON.parse(JSON.stringify(rowItem));
              //   row.GNT = values.floatValue;
              //   row.GNT2 = values.formattedValue;
              //   setRowItem(row);
              // }}
              onChange={(e)=>{
                const row = JSON.parse(JSON.stringify(rowItem));
                // let gnt = Number(e.target.value);
                let gnt = Number(String(e.target.value||0).replaceAll(',',''));
                if (isNaN(gnt) || e.target.value === ''){
                  row.GNT = 0;
                  row.GNT2 = '0.00';
                }
                else{
                  row.GNT = gnt;
                  row.GNT2 = gnt.toLocaleString(undefined,{minimumFractionDigits : 2});
                }
                setRowItem(row);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inGNT,inSUU,inBTN)}
              onFocus={(e) => onMyFocus(e)}
            />
            &nbsp;
            <NumericFormat  //売単価
              id={'BTN'}
              inputRef={inBTN}
              color='success'
              thousandSeparator={','}
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              customInput={TextField}
              size='small'
              label='売単価'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              // sx={{width: '110px',mr:'160px',background:inBTNclr}}
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 7), {background:inBTNclr})}  //2023/11/07 入力項目の表示内容拡大
              value={rowItem.BTN2}
              allowLeadingZeros
              disabled={disRow}                    //使用可／不可
              //inputProps={{autoComplete: 'off',maxLength: 13,style: {textAlign: 'right'} }}
              inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'right',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              isAllowed={(values) => {
                const numValue = Number(values.value);
                if (isNaN(numValue)) return true;
                return numValue >= -9999999.99 && numValue <= 9999999.99;
              }}
              // onValueChange={(values, sourceInfo) => {
              //   const row = JSON.parse(JSON.stringify(rowItem));
              //   row.BTN = values.floatValue;
              //   row.BTN2 = values.formattedValue;
              //   setRowItem(row);
              // }}
              onChange={(e)=>{
                const row = JSON.parse(JSON.stringify(rowItem));
                // let btn = Number(e.target.value);
                let btn = Number(String(e.target.value||0).replaceAll(',',''));
                if (isNaN(btn) || e.target.value === ''){
                  row.BTN = 0;
                  row.BTN2 = '0';
                }
                else{
                  row.BTN = btn;
                  row.BTN2 = btn.toLocaleString(undefined,{minimumFractionDigits : 0});
                }
                setRowItem(row);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inBTN,inGNT,inBTN)}
              onFocus={(e) => onMyFocus(e)}
            />
          </Stack>
          <Stack direction='row' sx={{mb:'10px'}}>
            <NumericFormat  //JANCD2
              id={'JAN2'}
              inputRef={inJAN2}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='ＪＡＮ２'                      //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              // sx={{width: '15ch',mr:'10px',background:inJAN2clr}}               //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 11), {background:inJAN2clr})}  //2023/11/07 入力項目の表示内容拡大
              value={rowItem.JANCDE2}             //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disJan2}                  //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onChange={(e)=>{
                const row = JSON.parse(JSON.stringify(rowData));
                row.JANCDE = rowItem.JANCDE;
                row.JYOTOYMD  = rowItem.JYOTOYMD;
                row.JYOTOYMD2  = rowItem.JYOTOYMD2;
                row.IN_CD_KBN = rowItem.IN_CD_KBN;
                row.IRYO_FLG = rowItem.IRYO_FLG;  //10/25
                row.EOSCDE = rowItem.EOSCDE;      //10/25
                row.JANCDE2 = e.target.value;
                setRowItem(row);
                setChkJAN2('0');
                setDisRow(true);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inJAN2,inJAN1,inSUU)}
              onFocus={(e) => onMyFocus(e)}
              onBlur={() => onblur(inJAN1)}
              />
              &nbsp;
              <TextField //商品名
                id={'SIRMEIK'}
                size='small'                        //textboxの全体サイズ
                label='商品名'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '30ch'}}                 //textboxの幅
                value={rowItem.SHNMEIKN}               //初期値
                disabled={true}                    //使用可／不可
                inputProps={{ autoComplete: 'off',style: {textAlign: 'left'} }}  //補完,最大文字数,左寄せ
              />
              &nbsp;
            <NumericFormat  //取引先CD
              id={'SIRCDE2'}
              inputRef={inSIRCDE2}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='取引先'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '9ch'}}                 //textboxの幅
              value={rowItem.SIRCDE}              //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 0,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              onFocus={(e) => onMyFocus(e)}
              />
            <InputLabel variant={'outlined'} sx={{mr:'26px',fontSize:15}} >{rowItem.SIRMEIK.trim()}</InputLabel>
          </Stack>
          <Stack direction='row' sx={{mb:'1em'}}>
            <FormControl
              sx={{
                minWidth: 135,
                maxWidth: 135,
                mr:'10px',
                visibility: prefectureList.length !==0 ? 'visible' : 'hidden'
              }}
              size='small'
            >
                <InputLabel id='select-label' color='success'>産地区分</InputLabel>
                <Select
                    disableUnderline  //2023/11/07 オンスクリーンキーボード対応
                    inputRef={inSANTIKBN}
                    color='success'
                    defaultValue={''}
                    labelId='select-label'
                    id='select-label'
                    value={rowItem.SANTI_KBN}
                    label='産地区分'
                    disabled={disRow}                    //使用可／不可
                    onFocus={e => setEnableSearch(true)}
                    onBlur={e => setEnableSearch(false)}
                    onChange={(e)=>{
                      const row = JSON.parse(JSON.stringify(rowItem));
                      row.SANTI_KBN = e.target.value;
                      setRowItem(row);
                    }}
                  >
                    {
                      regionList.map((region) => {
                        return <MenuItem value={region.value} key={region.value}>{region.label}</MenuItem>
                      })
                    }
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  minWidth: 320,
                  maxWidth: 320,
                  mr:'10px',
                  visibility: prefectureList.length !==0 ? 'visible' : 'hidden'
                }}

                size='small'
              >
                {/* <menuPaper maxHeight='350'> */}
                <InputLabel id='select-label' color='success'>都道府県/国名</InputLabel>
                <Select
                  disableUnderline  //2023/11/07 オンスクリーンキーボード対応
                  inputRef={inTODOFUKENKUNI}
                  color='success'
                  defaultValue={''}
                  labelId='select-label'
                  id='select-label'
                  value={rowItem.TODOFUKEN_KUNI}
                  label='都道府県/国名'
                  disabled={disRow}                    //使用可／不可
                  MenuProps={{
                    PaperProps:{
                      style:{
                        maxHeight:'400px',
                      }
                    }
                    // getContentAnchorEl: null, // anchorOrigin, transformOrigin が変更可能になるように元々ポップオーバーの基準となっている要素を解除
                    // anchorOrigin: { vertical: "top", horizontal: "left" }, // ポップオーバーの表示起点
                    // transformOrigin: { vertical: "top", horizontal: "left" }, // 表示時の transform の起点
                  }}
                  onFocus={e => setEnableSearch(true)}
                  onBlur={e => setEnableSearch(false)}
                  onChange={(e)=>{
                    const row = JSON.parse(JSON.stringify(rowItem));
                    row.TODOFUKEN_KUNI = e.target.value;
                    setRowItem(row);
                  }}
                >
                {
                    prefectureList.map((prefecture) => {
                      return <MenuItem value={prefecture.value} key={prefecture.value}>{prefecture.label}</MenuItem>
                    })
                }
                </Select>
              {/* </menuPaper> */}
            </FormControl>
            <FormControl
              sx={{
                minWidth: 135,
                maxWidth: 135,
                mr:'10px',
                visibility: prefectureList2 !==0 ? 'visible' : 'hidden'
              }}
              size='small'
            >
                <InputLabel id='select-label' color='success'>原産地ほか</InputLabel>
                <Select
                  disableUnderline  //2023/11/07 オンスクリーンキーボード対応
                  inputRef={inGENSANTI}
                  color='success'
                  defaultValue={''}
                  labelId='select-label'
                  id='select-label'
                  value={rowItem.GENSANTI}
                  label='原産地ほか'
                  disabled={disRow}                    //使用可／不可
                  onFocus={e => setEnableSearch(true)}
                  onBlur={e => setEnableSearch(false)}
                  onChange={(e)=>{
                    const row = JSON.parse(JSON.stringify(rowItem));
                    row.GENSANTI = e.target.value;
                    setRowItem(row);
                  }}
                >
                  {
                    homeList.map((home) => {
                      return <MenuItem value={home.value} key={home.value}>{home.label}</MenuItem>
                    })
                  }
              </Select>
            </FormControl>
            <FormControl
              sx={{
                minWidth: 135,
                maxWidth: 135,
                mr:'10px',
                visibility: prefectureList2 !== 0 ? 'visible' : 'hidden'
              }}
              size='small'
            >
                <InputLabel id='select-label' color='success'>食品区分</InputLabel>
                <Select
                  disableUnderline  //2023/11/07 オンスクリーンキーボード対応
                  inputRef={inSKHLBLKBN}
                  color='success'
                  defaultValue={''}
                  labelId='select-label'
                  id='select-label'
                  value={rowItem.SKHLBLKBN}
                  label='食品区分'
                  disabled={disRow}                    //使用可／不可
                  onFocus={e => setEnableSearch(true)}
                  onBlur={e => setEnableSearch(false)}
                  onChange={(e)=>{
                    const row = JSON.parse(JSON.stringify(rowItem));
                    row.SKHLBLKBN = e.target.value;
                    setRowItem(row);
                  }}
                >
                {
                    foodLabelList.map((foodLabel) => {
                      return <MenuItem value={foodLabel.value} key={foodLabel.value}>{foodLabel.label}</MenuItem>
                    })
                  }
              </Select>
            </FormControl>
          </Stack>
        </Paper>
        {/* テーブル表示処理 */}
        <Box style={gridStyle} sx={{ height: 371, width: '1162px', ml:'-60px',mt:'-15px' }}>
          <DataGrid
            sx={styles2.grid}     // --> 不具合対応のためcssで罫線を引く
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}  // メニュー日本語化
            showColumnRightBorder
            rowSelectionModel={selectionModel}
            getRowClassName={(params) => {
              if (params.row.DELFLG === '1') {
                // 削除済明細にクラスを設定する
                return 'row-deactive'
              }
              return '';
            }}
            onRowSelectionModelChange={(RowIds) => {
              // 今回チェックされたチェックボックスだけをチェック状態にする
              const diff = RowIds.filter(i => selectionModel.indexOf(i) == -1);
              const diffRows = rows.filter(x =>diff.indexOf(x.id) != -1);
              if(diffRows.length > 0){
                setRowItem(diffRows[0]);
              }
              const delRows = diffRows.filter(x => x.DELFLG == '1');
              if (delRows.length > 0) {
                // 削除済明細の場合はリックされた際に選択状態にしない
                return;
              }
              setSelectionModel(diff);
            }}
            paginationModel={pagenationModel}
            onPaginationModelChange={(model) => {
              setPagenationModel(model);
            }}
          />
        </Box>
        <Box my={2} flexDirection='row' justifyContent='flex-end' display='flex'>
          <Box ml={2} sx={{mt:'-20px'}}>
          <Button
              variant='contained'
              color='success'
              sx={{width:100}}
              onClick={(e) => onKeyDown('Return','','') }
              >
              {'戻　る'}
            </Button>&nbsp;
            <Button
              variant='contained'
              color='success'
              sx={{width:100}}
              onClick={(e) => onClick_FooterButton_Reset()}
            >
              {'取　消'}
            </Button>&nbsp;
            <Button
              variant='contained'
              color='success'
              sx={{width:100}}
              onClick={(e) => onClick_FooterButton_Delete()}
            >
              {'削　除'}
            </Button>&nbsp;
            <Button
              variant='contained'
              color='success'
              sx={{width:100,mr:'40px'}}
              onClick={(e) => onClick_FooterButton_Send()}
              >
              {'登　録'}
            </Button>
            <NumericFormat  //合計数量
              id={'SUUG'}
              inputRef={inSUUG}
              color='success'
              thousandSeparator={','}
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={true}            //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={1}                    //[PDA、Tablet対応]少数部の桁[0]
              customInput={TextField}
              size='small'
              label='合計数量'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              // sx={{width: '110px'}}
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 7))}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.SRYFOT}
              allowLeadingZeros
              disabled={dismeisai}
              inputProps={{autoComplete: 'off',maxLength: 0,style: {textAlign: 'right', padding: styles.cssIputOutlinPadding} }}
              //onChange={(event) => setGamen((prevState) => ({ ...prevState, jancde1:event.target.value}))}
            />&nbsp;
            <NumericFormat  //原価金額合計
              id={'GNKFOT'}
              inputRef={inGNKFOT}
              color='success'
              thousandSeparator={','}
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]customInput={TextField}
              customInput={TextField}
              size='small'
              label='原価金額合計'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              // sx={{width: '150px'}}
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 10))}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.GNKFOT}
              allowLeadingZeros
              disabled={dismeisai}
              inputProps={{autoComplete: 'off',maxLength: 0,style: {textAlign: 'right', padding: styles.cssIputOutlinPadding} }}
            />&nbsp;
            <NumericFormat  //売価金額合計
              id={'BBAKFOT'}
              inputRef={inBBAKFOT}
              color='success'
              thousandSeparator={','}
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              customInput={TextField}
              size='small'
              label='売価金額合計'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              // sx={{width: '150px'}}
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 10))}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.BAKFOT}
              allowLeadingZeros
              disabled={dismeisai}
              inputProps={{autoComplete: 'off',maxLength: 0,style: {textAlign: 'right', padding: styles.cssIputOutlinPadding} }}
            />&nbsp;
          </Box>
        </Box>
        {/* <Box my={2} flexDirection='row' justifyContent='flex-end' display='flex'>
          <Box ml={2}>
            <Button
              variant='contained'
              color='success'
              sx={{width:100,mr:'695px'}}
              onClick={(e) => onKeyDown('Return','','') }
              >
              {'戻　る'}
            </Button>&nbsp;
            <Button
              variant='contained'
              color='success'
              sx={{width:100}}
              onClick={(e) => onClick_FooterButton_Reset()}
            >
              {'取　消'}
            </Button>&nbsp;
            <Button
              variant='contained'
              color='success'
              sx={{width:100}}
              onClick={(e) => onClick_FooterButton_Delete()}
            >
              {'削　除'}
            </Button>&nbsp;
            <Button
              variant='contained'
              color='success'
              sx={{width:100,mr:'240px'}}
              onClick={(e) => onClick_FooterButton_Send()}
              >
              {'登　録'}
            </Button>
          </Box>
        </Box> */}
      </Paper>
    </Grid>
  );
};

export default TCGT0110;