import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, Stack, Grid, Paper, Typography, InputLabel, TextField } from "@mui/material";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { NumericFormat } from 'react-number-format';  //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F3") { // F3キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F5キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F9キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSA0006 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  //2023/10/04 kameda (使い方が分からないからコメント表示する)
  // const [alert, setAlert] = useState('');
  const [alert, setAlert] = useState(state.MISEMEI+'店へ登録する従業員CDを入力し、Ｆキーを押してください');
  const [rows, setRows] = useState([]);
  const [itJGNCDE, setItjgncde] = useState('');
  const [itJGNMEIKNJ, setItjgnmeiknj] = useState('');

  const inJGNCDE = useRef(null);
  
  const inRET = useRef(null);

  //項目のバックカラー制御用
  const [itJGNCDEclr,setitJGNCDEclr] = useState('#FFFFFF');

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
      if (itJGNCDE != '')
      {
        updwrkm();
      }
      // getRow();
      console.log('フォーカス:'+inJGNCDE.current);
      if (inJGNCDE.current !==null && typeof inJGNCDE.current !== 'undefined')
      {
        inJGNCDE.current.focus();
      }
    }
    if (event.keyCode === 114) { // F3キーが押された場合の処理
      if (itJGNCDE != '')
      {
        delwrkm();
      }
      // getRow();
      console.log('フォーカス:'+inJGNCDE.current);
      if (inJGNCDE.current !==null && typeof inJGNCDE.current !== 'undefined')
      {
        inJGNCDE.current.focus();
      }
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      navigate('/TCSA0003');
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応
  
  //罫線表示（Data Grid）
  //罫線表示（Data Grid）
  const styles = {
    grid: {
      '.MuiDataGrid-toolbarContainer': {
        borderBottom: 'solid 1px rgba(224, 224, 224, 1)'  // ついでにツールバーの下に罫線を引く
      },
      '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
        borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      // 2022/06/01 追記 列ヘッダの最終セルの右側に罫線が表示されなくなった・・・
      // 列ヘッダの最終列の右端に罫線を表示する
      '.MuiDataGrid-columnHeadersInner': {
        border: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      '.MuiDataGrid-columnSeparator--sideRight':{
        visibility: 'show',
      },
      '.MuiDataGrid-columnHeaders':{
        height:'40px',
      },
      // 削除済明細は背景色をグレーとする
      '.MuiDataGrid-row.row-deactive': {
        background: '#888888',
      }
    },
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
          fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
  }

  // グリッド列情報(エラー出力されているが処理可能)
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'No',
      headerAlign: 'center',
      align:'center',
      width: 80,
      sortable:false,
      disableColumnMenu:true
    }, //IDは必ず必要みたい
    {
      field: 'JGNCDE',
      headerName: '従業員コード',
      headerAlign: 'center',
      width: 150,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true,
    },
    {
      field: 'JGNMEIKNJ',
      headerName: '氏名',
      headerAlign: 'center',
      width: 290,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true
    },
  ];
  
  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    getRow();
  }, []);

  // 初期画面
  const getRow = async () => {
    // console.log('全件リスト:%o',state.KSACDES)
    const getWRK = await fetch('/TCSA0006/MR_WRKM_MST_ALL_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ksacde: state.KSACDES,mise: state.MISES})});
    const wrkList = await getWRK.json();
    //console.log('全件リスト:%o',wrkList)
    setRows(wrkList.res);
  }

  //　更新処理
  const updwrkm = async () => {
    //チェック処理追加（2023/09/29亀田）
    const ret = await CHECKsyori();
    console.log('チェック結果:'+ret);
    if (ret===false){
      return;
    }

    console.log('更新処理開始');
    const updWRK = await fetch('/TCSA0006/MR_WRKM_MST_upd',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ksacde: state.KSACDES,mise: state.MISES,jgncde: itJGNCDE,trl_id: state.MISES,ent_jgncde: state.JGNCDE})});
    const updRet = await updWRK.json();
    console.log('更新結果:%o',updRet);
    setItjgncde('');
    setItjgnmeiknj('');

    setAlert('正常に処理が完了しました');
    getRow();
  }

  //　削除処理
  const delwrkm = async () => {
    //チェック処理追加（2023/09/29亀田）
    const ret = await CHECKsyori('D');
    console.log('チェック結果:'+ret);
    if (ret===false){
      return;
    }

    console.log('削除処理開始');
    const delWRK = await fetch('/TCSA0006/MR_WRKM_MST_del',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ksacde: state.KSACDES,mise: state.MISES,jgncde: itJGNCDE})});
    const delRet = await delWRK.json();
    console.log('削除結果:%o',delRet);
    setItjgncde('');
    setItjgnmeiknj('');

    setAlert('正常に処理が完了しました');
    getRow();
  }

  const CHECKsyori = async (KBN) =>{
    //チェック処理
    console.log('チェック処理開始');
    //初期化
    setItjgnmeiknj('');        
    setAlert(state.MISEMEI+'店へ登録する従業員CDを入力し、Ｆキーを押してください')
    setitJGNCDEclr('#ffffff')

    //2023/10/16 kameda 前ゼロ対応
    let lJGNCDE = Fhwith(itJGNCDE,0,7);
    setItjgncde(lJGNCDE);

    // 従業員CD存在チェック
    const getWRK = await fetch('/TCSA0006/MR_WRK_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    //2023/10/16 kameda 前ゼロ対応
    // body: JSON.stringify({ksacde: state.KSACDES,jgncde: itJGNCDE})});
    body: JSON.stringify({ksacde: state.KSACDES,jgncde: lJGNCDE})});
    const wrkList = await getWRK.json();
    console.log('MR_WRK_MST_1:%o',wrkList);
    // console.log('wrkList.res.length:',wrkList.res.length);
    if (wrkList.res.length === 1) //一件
    {
      setItjgnmeiknj(wrkList.res[0].JGNMEIKNJ);        

      //登録済みか確認する
      let kbnErr = 0;
      console.log('既に登録済みチェック');
      rows.forEach(row => {
      //2023/10/16 kameda 前ゼロ対応
      // if (row.JGNCDE.trim() === itJGNCDE){
      if (row.JGNCDE.trim() === lJGNCDE){
          kbnErr = 1
        }
      });
      if (KBN!=='INF'){
        if (KBN!=='D'){
          if (kbnErr===1){
            console.log('既に登録済みです');
            setAlert('従業員情報は既に登録済みです。');
            setitJGNCDEclr('#f59d9d');
            return false;//チェックNG
          }else{
            return true;//チェックOK
          };
        }else{
          if (kbnErr===0){
            console.log('登録されていません');
            setAlert('その店コードは従業員情報に登録されていません。');
            setitJGNCDEclr('#f59d9d');
            return false;//チェックNG
          }else{
            return true;//チェックOK
          };
        }
      }
  }else{
      setAlert('従業員コードは存在しません。');
      setitJGNCDEclr('#f59d9d');
      return false;
    }
  };

  // 頭0埋め
  const Fhwith = (SetDate,SFLG=1,NumDig = 0) =>{    // 対象情報,0:商品コード以外、1:商品コード,桁数(頭0埋め含む)
    let Afcorr;
    console.log('SetDate:%o',typeof(SetDate)) ;
    if (SetDate !== '')
    {
      if (SFLG == 1){
        switch(SetDate.length){
          case 7:
            Afcorr = SetDate.padStart(8,'0');
            break;
          case 9:
          case 10:
          case 11:
          case 12:
            Afcorr = SetDate.padStart(13,'0');
            break;
          default:
            Afcorr = SetDate;
            break;
        }
      }else{
        Afcorr = SetDate.padStart(NumDig,'0');
      }
    }else{
      Afcorr = SetDate;
    }
    return Afcorr;
  }

  const onKeyDown =  async (key,inputRef,inputNxRef) => {  //  入力キー、現在の項目情報、次項目情報
    //console.log(key);
    let mvFlg = 0;
    switch (key) {
      case "Enter":
        if (inputRef.current.id == 'JGNCDE')
        {
          if (itJGNCDE != '')
          {
            //チェック処理追加（2023/09/29亀田）
            // const getWRK = await fetch('/TCSA0006/MR_WRK_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
            // body: JSON.stringify({ksacde: state.KSACDES,jgncde: itJGNCDE})});
            // const wrkList = await getWRK.json();
            // console.log('MR_WRK_MST:%o',wrkList);
            // setItjgnmeiknj(wrkList.res[0].JGNMEIKNJ);        
            const ret = await CHECKsyori('INF');
            if (ret===false){
              mvFlg = 1
            }else{
              mvFlg = 0
            }

          }
        }
        // inputNxRef.current.focus();   //次の項目に遷移
        switch (mvFlg) {
          case 0:
            inputNxRef.current.focus();   //次の項目に遷移
            break;
          case 1:
            inRET.current.focus();
            inputRef.current.focus();   //現在の項目に遷移
            break;
          case 2:
            break;
          case 9:
            break;
          default:
            break;
        }

        break;
      case "F3":
        break;
      case "Home":
        break;
      case "ArrowUp":
        break;
      case "ArrowDown":
        break;
      default:
        break;
    }
  };  

  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  // 確認のWidthを設定する
  const cssInputOutlineRetSetwidth = (obj) => {
    let resObj = obj;
    resObj.width = '50px';
    return resObj;
  }

  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "700px",
          width: "550px",
          m: "20px auto"
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="center"
        >
          <div style={{display:"flex"}}>
          <Typography variant={"h4"} sx={{ m: "5px" }}>
            従業員マスタ
          </Typography>
          </div>
        </Grid>
        <br/>
        <br/>
        <br/>
        <Stack direction="row" >
          <NumericFormat  //従業員CD
              id={'JGNCDE'}
              inputRef={inJGNCDE}                   //項目名定義？
              autoFocus
              color='success'
              thousandSeparator={false}           //1000単位の区切り           
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='従業員CD'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'
              inputMode='tel'
              fixedDecimalScale={false}            //小数点On/Off
              decimalScale={0}                    //少数部の桁
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 10), {background:itJGNCDEclr,marginLeft:'20px'})}
              value={itJGNCDE}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={false}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 10,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              onKeyDown={(e) => onKeyDown(e.key,inJGNCDE,inRET)}
              onFocus={(e) => e.target.select()}
              onChange={(e) => setItjgncde(e.target.value)}
              />
            <InputLabel variant={'outlined'} >{itJGNMEIKNJ} </InputLabel>
        </Stack>
          <br /><br /><br />
          <font color="red">{ alert }</font>
          <Box my={2} flexDirection="row" justifyContent="flex-end" display="flex">
            <font color="black">{ 'F1:新規　F3:削除　F5:戻る　　　　　　　　　　　　　　　　　' }</font>
            <Box ml={2}>
              <NumericFormat  //確認
                id={'ret'}
                inputRef={inRET}                    //項目名定義？
                thousandSeparator={false}           //1000単位の区切り           
                color='success'
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='確認'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                fixedDecimalScale={false}            //小数点On/Off
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={Object.assign({},cssInputOutlineRetSetwidth(styles.cssInputOutline))}
                //value={dbdata.ZEIRTU}               //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={false}                    //使用可／不可
                inputProps={{ autoComplete: 'off',maxLength: 0,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
                />
            </Box>
          </Box>
          <font color="black">{ '＜登録済み情報＞' }</font>
        {/* テーブル表示処理 */}
        <Box sx={{ height: 300, width: '550px', mt:'10px' }}>
          <DataGrid
            sx={styles.grid}     // --> 不具合対応のためcssで罫線を引く
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            showColumnRightBorder
            getRowClassName={(params) => {
              if (params.row.DELFLG === '1') {
                // 削除済明細にクラスを設定する
                return 'row-deactive'
              }
              return '';
            }}
          />
        </Box>

        {/* <p>{ state.usrnm }</p> */}
        {/* <p><font color="red">{ alert }</font></p> */}
      </Paper>
    </Grid>
  );
};

export default TCSA0006;