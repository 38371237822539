import React, { useState, forwardRef, useImperativeHandle} from 'react'
import { TextField, InputLabel } from "@mui/material";
import TableCell from '@mui/material/TableCell';      //テーブル用
import { NumericFormat } from 'react-number-format';    //数値入力
import { PatternFormat } from 'react-number-format';
import { onMyFocus } from './hooks/useOnMyFocus';     // select()処理

//#region スタイル
// スタイルオブジェクトのWidthを自動設定する
const cssInputOutlineSetwidth = (obj, len) => {
  let resObj = obj;
  let calcLen = len;
  const minLen = 4;   // 最小桁数
  const oneLen = 20;  // 1桁につき20pxとして定義
  // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
  if (len < minLen) {
    calcLen = minLen;
  }
  resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
  return resObj;
}

const styles = {
  // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
  cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合
  cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合
  cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合
  cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合

  // OutLineのスタイル指定
  // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
  cssInputOutline: {
    // 活性時デザイン
    '& .MuiInputBase-input': {
      color: '#000000',               // 入力文字の色
      fontSize: '1.6rem'              // フォントサイズ
    },
    '& label': {
      color: '#000000',               // 通常時のラベル色
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#000000',   // 通常時のボーダー色
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#000000',   // ホバー時のボーダー色
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
      },
      '&:hover fieldset': {
        borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
      },
    },
    // 非活性時デザイン
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000000", // 非活性時の文字色
    },
    '& label.Mui-disabled': {
      color: '#000000',               // 非活性時のラベル色
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      '& fieldset': {
        borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
      },
      '&:hover.Mui-disabled fieldset': {
        borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
      },
    },
    width: '0px',         // 設定変更するので0pxで固定定義する
  },
  //明細用
  cssInputOutline2: {
    // 活性時デザイン
    '& .MuiInputBase-input': {
      color: '#FFFFFF',               // 入力文字の色
      fontSize: '1.6rem'              // フォントサイズ
    },
    '& label': {
      color: '#FFFFFF',               // 通常時のラベル色
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#FFFFFF',   // 通常時のボーダー色
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#FFFFFF',   // ホバー時のボーダー色
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFFFFF',   // 通常時のボーダー色
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):after': {
      borderBottomColor: '#FFFFFF',   // ホバー時のボーダー色
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#FFFFFF',       // 通常時のボーダー色(アウトライン)
      },
      '&:hover fieldset': {
        borderColor: '#FFFFFF',       // ホバー時のボーダー色(アウトライン)
      },
    },
    // 非活性時デザイン
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000000", // 非活性時の文字色
    },
    '& label.Mui-disabled': {
      color: '#000000',               // 非活性時のラベル色
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      '& fieldset': {
        borderColor: '#FFFFFF',       // 非活性時のボーダー色(アウトライン)
      },
      '&:hover.Mui-disabled fieldset': {
        borderColor: '#FFFFFF',       // ホバー時のボーダー色(アウトライン)
      },
    },
    width: '0px',         // 設定変更するので0pxで固定定義する
  },

  cssRecordFontSize: {
    // フォントサイズ
    '& .MuiInputBase-input': {
      fontSize: '1.2rem'
    },
    fontSize: '1.2rem',
  },

  cssRecordFontSize2: {
    // フォントサイズ
    '& .MuiInputBase-input': {
      fontSize: '1.6rem'
    },
    fontSize: '1.6rem',
  },

  cssRecordBorder: {
    border:1,
    padding:0,
  },

  cssRecordBorderAddPaddingRight: {
    border:1,
    padding:0,
    paddingRight:'10px',
  },

}
//#endregion

export const TCSA0130_parts = () => {
  //#region ヘッダ部
  // 区分
  const CKubun = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');
    const [checkflg,setCheckflg] = useState(0);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
        getCheckflg() {
          return checkflg;
        },
        setCheckflg(value) {
          setCheckflg(value);
        },
      }
    });

    return (
      <NumericFormat  //区分
        id={'KBN'}
        inputRef={inputRef}                   //項目名定義？
        color='success'
        autoFocus                           //初期カーソル位置設定
        thousandSeparator={false}           //1000単位の区切り
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label='区分'                         //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
        decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 1), {background:itBGColor})}
        value={itValue}               //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={false}                    //使用可／不可
        inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
        onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
        onFocus={(e) => onMyFocus(e)}
        onChange={(e) => {
          setCheckflg(1);
          setItValue(e.target.value);
        }}
      />
    )
  });

  // 税率
  const CZeiritu = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
    setMeisaiflg
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');
    const [checkflg,setCheckflg] = useState(0);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
        getCheckflg() {
          return checkflg;
        },
        setCheckflg(value) {
          setCheckflg(value);
        },
      }
    });

    return (
      <>
        <NumericFormat  //税率
          id={'ZEIRTU'}
          inputRef={inputRef}                   //項目名定義？
          color='success'
          thousandSeparator={false}           //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='税率'                         //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={true}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalSeparator="."
          decimalScale={1}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 3), {marginLeft:'20px', background:itBGColor})}
          value={itValue}               //初期値
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={false}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
          isAllowed={(values) => {
            const numValue = Number(values.value);
            if (isNaN(numValue)) return true;
            return numValue >= 0 && numValue <= 99.9;
          }}
          onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => {
            setMeisaiflg(1);
            setCheckflg(1);
            setItValue(e.target.value);
            // }
          }}
        />
        <InputLabel variant={'outlined'} >％</InputLabel>
      </>
    )
  });

  // 税区分
  const CZeiKubun = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');
    const [checkflg,setCheckflg] = useState(0);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
        getCheckflg() {
          return checkflg;
        },
        setCheckflg(value) {
          setCheckflg(value);
        },
      }
    });

    return (
      <NumericFormat  //税区分
        id={'ZEIKBN'}
        inputRef={inputRef}                   //項目名定義？
        color='success'
        thousandSeparator={false}           //1000単位の区切り
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label='税区分'                         //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
        decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 1), {background:itBGColor})}
        value={itValue}               //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={false}                    //使用可／不可
        inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
        onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
        onFocus={(e) => onMyFocus(e)}
        onChange={(e) => {
          setCheckflg(1);
          setItValue(e.target.value);
        }}
      />
    )
  });

  // 社店CD
  const CSyaMise = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
    dissmise
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');
    const [checkflg,setCheckflg] = useState(0);
    const [focusflg,setFocusflg] = useState(false);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
        getCheckflg() {
          return checkflg;
        },
        setCheckflg(value) {
          setCheckflg(value);
        },
        getFocusflg() {
          return focusflg;
        },
        setFocusflg(value) {
          setFocusflg(value);
        },
      }
    });

    return (
      <NumericFormat  //社店CD
        id={'SMISE'}
        inputRef={inputRef}                   //項目名定義？
        color='success'
        thousandSeparator={false}           //1000単位の区切り
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label='社店CD'                         //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
        decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 3), {marginLeft:'20px',background:itBGColor})}
        value={itValue}               //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={dissmise}                    //使用可／不可
        inputProps={{ autoComplete: 'off',maxLength: 3,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
        onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
        onBlur={(e) => setFocusflg(true)}
        onFocus={(e) => {
          if (e.target.value != '')
          {
            onMyFocus(e)
          }
        }}
        onChange={(e) => {
          setCheckflg(1);
          setItValue(e.target.value);
        }}
      />
    )
  });

  // 分類CD
  const CBunruiCode = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');
    const [checkflg,setCheckflg] = useState(0);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
        getCheckflg() {
          return checkflg;
        },
        setCheckflg(value) {
          setCheckflg(value);
        },
      }
    });

    return (
      <NumericFormat  //分類CD
        id={'BMNCDE'}
        inputRef={inputRef}                   //項目名定義？
        color='success'
        thousandSeparator={false}           //1000単位の区切り
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label='分類CD'                       //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
        decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 5), {marginLeft:'20px',background:itBGColor})}
        value={itValue}               //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={false}                    //使用可／不可
        inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
        onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
        onFocus={(e) => onMyFocus(e)}
        onChange={(e) => {
          setCheckflg(1);
          setItValue(e.target.value);
        }}
      />
    )
  });

  // 伝票区分
  const CDenpyoKubun = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');
    const [checkflg,setCheckflg] = useState(0);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
        getCheckflg() {
          return checkflg;
        },
        setCheckflg(value) {
          setCheckflg(value);
        },
      }
    });

    return (
      <NumericFormat  //伝票区分
        id={'DENKBN'}
        inputRef={inputRef}                 //項目名定義？
        color='success'
        thousandSeparator={false}           //1000単位の区切り
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label='伝票区分'                      //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
        decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 2), {marginLeft:'20px',background:itBGColor})}
        value={itValue}               //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={false}                    //使用可／不可
        inputProps={{ autoComplete: 'off',maxLength: 2,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
        onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
        onFocus={(e) => onMyFocus(e)}
        onChange={(e) => {
          setCheckflg(1);
          setItValue(e.target.value);
        }}
      />
    )
  });

  // 伝票No.
  const CDenpyoNo = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');
    const [checkflg,setCheckflg] = useState(0);
    const [focusflg,setFocusflg] = useState(false);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
        getCheckflg() {
          return checkflg;
        },
        setCheckflg(value) {
          setCheckflg(value);
        },
        getFocusflg() {
          return focusflg;
        },
        setFocusflg(value) {
          setFocusflg(value);
        },
      }
    });

    return (
      <NumericFormat  //伝票No.
        id={'DENNO'}
        inputRef={inputRef}                   //項目名定義？
        color='success'
        thousandSeparator={false}           //1000単位の区切り
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label='伝票No.'                         //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
        decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 7), {marginLeft:'0px',background:itBGColor})}
        value={itValue}               //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={false}                    //使用可／不可
        inputProps={{ autoComplete: 'off',maxLength: 7,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
        onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
        onBlur={(e) => setFocusflg(true)}
        onFocus={(e) => onMyFocus(e)}
        onChange={(e) => {
          setCheckflg(1);
          setItValue(e.target.value);
        }}
      />
    )
  });

  // 取引先CD
  const CSireCode = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');
    const [checkflg,setCheckflg] = useState(0);
    const [meisaiflg,setMeisaiflg] = useState('0');       // 確認時、明細をセットするかどうかのフラグ

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
        getCheckflg() {
          return checkflg;
        },
        setCheckflg(value) {
          setCheckflg(value);
        },
        getMeisaiFlg() {
          return meisaiflg;
        },
        setMeisaiFlg(value) {
          setMeisaiflg(value);
        },
      }
    })

    return (
      <NumericFormat  //取引先CD
        id={'SIRCDE'}
        inputRef={inputRef}                   //項目名定義？
        color='success'
        thousandSeparator={false}           //1000単位の区切り
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label='取引先CD'                         //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
        decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 6), {marginLeft:'20px',background:itBGColor})}
        value={itValue}               //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={false}                    //使用可／不可
        inputProps={{ autoComplete: 'off',maxLength: 6,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
        onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
        onFocus={(e) => onMyFocus(e)}
        onChange={(e) => {
          setMeisaiflg(1);
          //chkSIRCDE = 1;
          setCheckflg(1);
          setItValue(e.target.value);
        }}
      />
    )
  });

  // 年月日(伝票日付)
  const CDenpyoYMD = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');
    const [checkflg,setCheckflg] = useState(0);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
        getCheckflg() {
          return checkflg;
        },
        setCheckflg(value) {
          setCheckflg(value);
        },
      }
    });

    return (
      <PatternFormat  //年月日(伝票日付)
        id={'DENYMD'}
        inputRef={inputRef}                   //項目名定義？
        format="##/##/##"
        mask={['Y','Y','M','M','D','D']}
        color='success'
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label='年月日'                        //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 9), {background:itBGColor})}
        value={itValue}                    //初期値
        disabled={false}                    //使用可／不可
        placeholder="YY/MM/DD"
        inputProps={{ autoComplete: 'off',maxLength: 9,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
        onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
        onFocus={(e) => onMyFocus(e)}
        onChange={(e) => {
          setCheckflg(1);
          setItValue(e.target.value);
        }}
      />
    )
  });

  // 計上年月
  const CKeijyoYM = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <PatternFormat  //計上年月
        id={'KIJYYM'}
        inputRef={inputRef}                   //項目名定義？
        format="##年##月"
        mask={['Y','Y','M','M']}
        color='success'
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label='計上年月'                        //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 8), {background:itBGColor})}
        value={itValue}               //初期値
        disabled={true}                    //使用可／不可
        placeholder="YY年MM月"
        inputProps={{ autoComplete: 'off',maxLength: 9,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
        onKeyDown={(e) =>onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
        onFocus={(e) => onMyFocus(e)}
        onChange={(e) => setItValue(e.target.value)}
      />
    )
  });

  // 便
  const CBin = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
    stateR
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');
    const [checkflg,setCheckflg] = useState(0);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
        getCheckflg() {
          return checkflg;
        },
        setCheckflg(value) {
          setCheckflg(value);
        },
      }
    });

    return (
      <NumericFormat  //便
        id={'BIN'}
        inputRef={inputRef}                   //項目名定義？
        color='success'
        thousandSeparator={false}           //1000単位の区切り
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label='便'                         //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
        decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 1), {background:itBGColor}, {
          width: '50px',
          visibility: stateR.ETC === 'K' ? 'visible' : 'hidden'
        })}                                 //textboxの幅
        value={itValue}                     //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={false}                    //使用可／不可
        inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
        onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
        onFocus={(e) => onMyFocus(e)}
        onChange={(e) => {
          setCheckflg(1);
          setItValue(e.target.value);
        }}
      />
    )
  });

  //#endregion

  //#region 明細部
  // 商品コード
  const CShohinCode = forwardRef(({
    id,
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
    inUpRef,
    inRgRef,
    inDnRef,
    inLfRef,
    dismeisais,
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itColor,setItColor] = useState('#000000');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');
    const [checkflg,setCheckflg] = useState(0);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getColor() {
          return itColor;
        },
        setColor(value){
          setItColor(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
        getCheckflg() {
          return checkflg;
        },
        setCheckflg(value) {
          setCheckflg(value);
        },
      }
    });

    return (
      <TableCell align="center" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}>
        <NumericFormat
          id={id}
          inputRef={inputRef}
          color='success'
          thousandSeparator={false}
          customInput={TextField}
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'145px',background:itBGColor,input: { color: itColor }})}
          value={itValue}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center', padding: styles.cssRecordFontSizePadding} }} //最大文字数,右寄せ
          disabled={dismeisais}
          allowLeadingZeros
          onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef,
            inUpRef,inRgRef,inDnRef,inLfRef)}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => {
            setCheckflg(1);
            setItValue(e.target.value);
          }}
        />
      </TableCell>
    )
  });

  // 数量
  const CShohinSuu = forwardRef(({
    id,
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
    inUpRef,
    inRgRef,
    inDnRef,
    inLfRef,
    dismeisai,
    allow,
  }, ref) => {

    const [itValue,setItValue] = useState('');
    const [itColor,setItColor] = useState('#000000');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getColor() {
          return itColor;
        },
        setColor(value){
          setItColor(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
        <NumericFormat  //数量
          id={id}
          inputRef={inputRef}
          color='success'
          thousandSeparator={','}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={true}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={1}                    //[PDA、Tablet対応]少数部の桁[0]
          customInput={TextField}
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:"80px",background:itBGColor,input: { color: itColor }})}
          value={itValue}
          allowLeadingZeros
          allowNegative={allow}
          disabled={dismeisai}
          inputProps={{autoComplete: 'off',maxLength: 9,style: {textAlign: 'right', padding: styles.cssRecordFontSizePaddingRightModle} }}
          isAllowed={(values) => {
            const numValue = Number(values.value);
            if (isNaN(numValue)) return true;
            return numValue >= -99999.9 && numValue <= 99999.9;
          }}
          onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef,
            inUpRef,inRgRef,inDnRef,inLfRef)}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => setItValue(e.target.value)}
        />
      </TableCell>
    )
  });

  // 原価単価
  const CShohinGentanka = forwardRef(({
    id,
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
    inUpRef,
    inRgRef,
    inDnRef,
    inLfRef,
    dismeisai,
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itColor,setItColor] = useState('#000000');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getColor() {
          return itColor;
        },
        setColor(value){
          setItColor(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
        <NumericFormat  //原価単価
          id={id}
          inputRef={inputRef}
          color='success'
          thousandSeparator={','}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={true}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={2}                    //[PDA、Tablet対応]少数部の桁[0]
          customInput={TextField}
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:"110px",background:itBGColor,input: { color: itColor }})}
          value={itValue}
          allowLeadingZeros
          allowNegative={false}
          disabled={dismeisai}
          inputProps={{autoComplete: 'off',maxLength: 13,style: {textAlign: 'right', padding: styles.cssRecordFontSizePaddingRightModle} }}
          isAllowed={(values) => {
            const numValue = Number(values.value);
            if (isNaN(numValue)) return true;
            return numValue >= -9999999.99 && numValue <= 9999999.99;
          }}
          onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef,
            inUpRef,inRgRef,inDnRef,inLfRef)}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => setItValue(e.target.value)}
        />
      </TableCell>
    )
  });

  // 原価金額
  const CShohinGenkakingaku = forwardRef(({
    id,
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
    inUpRef,
    inRgRef,
    inDnRef,
    inLfRef,
    dismeisai,
    allow,
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itColor,setItColor] = useState('#000000');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getColor() {
          return itColor;
        },
        setColor(value){
          setItColor(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
        <NumericFormat  //原価金額
          id={id}
          inputRef={inputRef}
          color='success'
          thousandSeparator={','}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          customInput={TextField}
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:"100px",background:itBGColor,input: { color: itColor }})}
          value={itValue}
          allowLeadingZeros
          allowNegative={allow}
          disabled={dismeisai}
          inputProps={{autoComplete: 'off',maxLength: 13,style: {textAlign: 'right', padding: styles.cssRecordFontSizePaddingRightModle} }}
          isAllowed={(values) => {
            const numValue = Number(values.value);
            if (isNaN(numValue)) return true;
            return numValue >= -9999999.99 && numValue <= 9999999.99;
          }}
          onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef,
            inUpRef,inRgRef,inDnRef,inLfRef)}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => setItValue(e.target.value)}
        />
      </TableCell>
    )
  });

  // 売単価
  const CShohinBaitanka = forwardRef(({
    id,
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
    inUpRef,
    inRgRef,
    inDnRef,
    inLfRef,
    dismeisai,
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itColor,setItColor] = useState('#000000');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getColor() {
          return itColor;
        },
        setColor(value){
          setItColor(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
        <NumericFormat  //売単価
          id={id}
          inputRef={inputRef}
          color='success'
          thousandSeparator={','}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          customInput={TextField}
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:"110px",background:itBGColor,input: { color: itColor }})}
          value={itValue}
          allowLeadingZeros
          allowNegative={false}
          disabled={dismeisai}
          inputProps={{autoComplete: 'off',maxLength: 13,style: {textAlign: 'right', padding: styles.cssRecordFontSizePaddingRightModle} }}
          isAllowed={(values) => {
            const numValue = Number(values.value);
            if (isNaN(numValue)) return true;
            return numValue >= -9999999.99 && numValue <= 9999999.99;
          }}
          onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef,
            inUpRef,inRgRef,inDnRef,inLfRef)}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => setItValue(e.target.value)}
        />
      </TableCell>
    )
  });

  // 売価金額
  const CShohinBaikakingaku = forwardRef(({
    id,
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
    inUpRef,
    inRgRef,
    inDnRef,
    inLfRef,
    dismeisai,
    allow,
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itColor,setItColor] = useState('#000000');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getColor() {
          return itColor;
        },
        setColor(value){
          setItColor(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
        <NumericFormat  //売価金額
          id={id}
          inputRef={inputRef}
          color='success'
          thousandSeparator={','}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          customInput={TextField}
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:"110px",background:itBGColor,input: { color: itColor }})}
          value={itValue}
          allowLeadingZeros
          allowNegative={allow}
          disabled={dismeisai}
          inputProps={{autoComplete: 'off',maxLength: 13,style: {textAlign: 'right', padding: styles.cssRecordFontSizePaddingRightModle} }}
          isAllowed={(values) => {
            const numValue = Number(values.value);
            if (isNaN(numValue)) return true;
            return numValue >= -9999999.99 && numValue <= 9999999.99;
          }}
          onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef,
            inUpRef,inRgRef,inDnRef,inLfRef)}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => setItValue(e.target.value)}
        />
      </TableCell>
    )
  });
  //#endregion

  //#region フッダ部
  // 伝票F欄,H欄
  const CDenpyoFH = forwardRef(({
    id,
    label,
    inputRef,
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <NumericFormat
        id={id}
        inputRef={inputRef}                   //項目名定義？
        thousandSeparator={','}
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
        decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
        color='success'
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label={label}                       //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 8), {background:itBGColor})}
        value={itValue}               //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={true}                    //使用可／不可
        inputProps={{ autoComplete: 'off',maxLength: 20,style: {textAlign: 'right', padding: styles.cssRecordFontSizePaddingRightModle} }}  //補完,最大文字数,中央寄せ
        onFocus={(e) => onMyFocus(e)}
        onChange={(e) => setItValue(e.target.value)}
      />
    )
  });

  // 伝票I欄,J欄,K欄
  const CDenpyoIJK = forwardRef(({
    id,
    label,
    inputRef
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <NumericFormat
        id={id}
        inputRef={inputRef}                   //項目名定義？
        thousandSeparator={','}
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
        decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
        color='success'
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label={label}                         //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 3), {background:itBGColor})}
        value={itValue}               //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={true}                    //使用可／不可
        inputProps={{ autoComplete: 'off',maxLength: 3,style: {textAlign: 'right', padding: styles.cssIputOutlinPaddingRightModle} }}  //補完,最大文字数,中央寄せ
        onFocus={(e) => onMyFocus(e)}
        onChange={(e) => setItValue(e.target.value)}
      />
    )
  });

  // 伝票G欄
  const CDenpyoG = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
    disdeng,
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <PatternFormat  //伝票G欄
        id={'DENG'}
        inputRef={inputRef}                   //項目名義？
        format="##/##/##"
        mask={['Y','Y','M','M','D','D']}
        color='success'
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label='G'                        //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 8), {background:itBGColor})}
        value={itValue}               //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={disdeng}                    //使用可／不可
        placeholder="YY/MM/DD"
        inputProps={{ autoComplete: 'off',maxLength: 9,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
        onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
        onFocus={(e) => onMyFocus(e)}
        onChange={(e) => setItValue(e.target.value)}
      />
    )
  });

  // 金額合計
  const CGoukei = forwardRef(({
    id,
    label,
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itColor,setItColor] = useState('#000000');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getColor() {
          return itColor;
        },
        setColor(value){
          setItColor(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <NumericFormat  //訂正後売価金額合計
        id={id}
        inputRef={inputRef}                   //項目名定義？
        thousandSeparator={','}
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
        decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
        color='success'
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label={label}                         //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 8),styles.cssRecordFontSize2, {background:itBGColor,input: { color: itColor }})}
        value={itValue}               //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={false}                    //使用可／不可
        inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right', padding: styles.cssIputOutlinPaddingRightModle} }}  //補完,最大文字数,中央寄せ
        onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
        onFocus={(e) => onMyFocus(e)}
        onChange={(e) => setItValue(e.target.value)}
      />
    )
  });
  //#endregion

  return {CKubun,CZeiritu,CZeiKubun,CSyaMise,CBunruiCode,CDenpyoKubun,CDenpyoNo,CSireCode,CDenpyoYMD,CKeijyoYM,
    CShohinCode,CShohinSuu,CShohinGentanka,CShohinGenkakingaku,CShohinBaitanka,CShohinBaikakingaku,
    CDenpyoFH,CDenpyoIJK,CDenpyoG,CGoukei,CBin}

};
export default TCSA0130_parts;