import React, { useState,useContext,useEffect,useRef } from 'react'
import { Button, Grid, Paper, Typography, Stack } from "@mui/material";
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSA0002 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [btncd00,setBtncd00] = useState({cd:'',nm:''});
  const [btncd01,setBtncd01] = useState({cd:'',nm:''});
  const [btncd02,setBtncd02] = useState({cd:'',nm:''});
  const [btncd03,setBtncd03] = useState({cd:'',nm:''});
  const [btncd04,setBtncd04] = useState({cd:'',nm:''});
  const [btncd05,setBtncd05] = useState({cd:'',nm:''});
  const [btncd06,setBtncd06] = useState({cd:'',nm:''});
  const [btncd07,setBtncd07] = useState({cd:'',nm:''});
  const [btncd08,setBtncd08] = useState({cd:'',nm:''});
  const [btncd09,setBtncd09] = useState({cd:'',nm:''});
  const [btncd10,setBtncd10] = useState({cd:'',nm:''});

  const [btntl00,setBtntl00] = useState('　　　　　　　　　　　　　　　　');
  const [btntl01,setBtntl01] = useState('　　　　　　　　　　　　　　　　');
  const [btntl02,setBtntl02] = useState('　　　　　　　　　　　　　　　　');
  const [btntl03,setBtntl03] = useState('　　　　　　　　　　　　　　　　');
  const [btntl04,setBtntl04] = useState('　　　　　　　　　　　　　　　　');
  const [btntl05,setBtntl05] = useState('　　　　　　　　　　　　　　　　');
  const [btntl06,setBtntl06] = useState('　　　　　　　　　　　　　　　　');
  const [btntl07,setBtntl07] = useState('　　　　　　　　　　　　　　　　');
  const [btntl08,setBtntl08] = useState('　　　　　　　　　　　　　　　　');
  const [btntl09,setBtntl09] = useState('　　　　　　　　　　　　　　　　');
  const [btntl10,setBtntl10] = useState('　　　　　　　　　　　　　　　　');
  const [btn00, setBtn00] = useState(false); 
  const [btn01, setBtn01] = useState(true); 
  const [btn02, setBtn02] = useState(true); 
  const [btn03, setBtn03] = useState(true); 
  const [btn04, setBtn04] = useState(true); 
  const [btn05, setBtn05] = useState(true); 
  const [btn06, setBtn06] = useState(true); 
  const [btn07, setBtn07] = useState(true); 
  const [btn08, setBtn08] = useState(true); 
  const [btn09, setBtn09] = useState(true); 
  const [btn10, setBtn10] = useState(true); 

  const inbtn00 = useRef(null);

  
  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    // console.log(state);
    setBtntl00((state.MISES+'：'+state.MISEMEI+'　　　　　　　　　　　　　　　　').slice(0,17));
    let l_smisejh = state.SMISEJH.split(',');
    for (var i = 0; i < l_smisejh.length; i = i + 2) {
      switch(i){
        case 0:
          setBtncd00(btncd00 => ({ ...btncd00, cd: l_smisejh[0] }));
          setBtncd00(btncd00 => ({ ...btncd00, nm: l_smisejh[1] }));
          setBtntl00((l_smisejh[0]+'：'+l_smisejh[1]+'　　　　　　　　　　　　　　　　').slice(0,17));
          setBtn00(false);
          break;
        case 2:
          setBtncd01(btncd01 => ({ ...btncd01, cd: l_smisejh[2] }));
          setBtncd01(btncd01 => ({ ...btncd01, nm: l_smisejh[3] }));
          setBtntl01((l_smisejh[2]+'：'+l_smisejh[3]+'　　　　　　　　　　　　　　　　').slice(0,17));
          setBtn01(false);
          break;
        case 4:
          setBtncd02(btncd02 => ({ ...btncd02, cd: l_smisejh[4] }));
          setBtncd02(btncd02 => ({ ...btncd02, nm: l_smisejh[5] }));
          setBtntl02((l_smisejh[4]+'：'+l_smisejh[5]+'　　　　　　　　　　　　　　　　').slice(0,17));
          setBtn02(false);
          break;
        case 6:
          setBtncd03(btncd03 => ({ ...btncd03, cd: l_smisejh[6] }));
          setBtncd03(btncd03 => ({ ...btncd03, nm: l_smisejh[7] }));
          setBtntl03((l_smisejh[6]+'：'+l_smisejh[7]+'　　　　　　　　　　　　　　　　').slice(0,17));
          setBtn03(false);
          break;
        case 8:
          setBtncd04(btncd04 => ({ ...btncd04, cd: l_smisejh[8] }));
          setBtncd04(btncd04 => ({ ...btncd04, nm: l_smisejh[9] }));
          setBtntl04((l_smisejh[8]+'：'+l_smisejh[9]+'　　　　　　　　　　　　　　　　').slice(0,17));
          setBtn05(false);
          break;
        case 10:
          setBtncd05(btncd05 => ({ ...btncd05, cd: l_smisejh[10] }));
          setBtncd05(btncd05 => ({ ...btncd05, nm: l_smisejh[11] }));
          setBtntl05((l_smisejh[10]+'：'+l_smisejh[11]+'　　　　　　　　　　　　　　　　').slice(0,17));
          setBtn05(false);
          break;
        case 12:
          setBtncd06(btncd06 => ({ ...btncd06, cd: l_smisejh[12] }));
          setBtncd06(btncd06 => ({ ...btncd06, nm: l_smisejh[13] }));
          setBtntl06((l_smisejh[12]+'：'+l_smisejh[13]+'　　　　　　　　　　　　　　　　').slice(0,17));
          setBtn06(false);
          break;
        case 14:
          setBtncd07(btncd07 => ({ ...btncd07, cd: l_smisejh[14] }));
          setBtncd07(btncd07 => ({ ...btncd07, nm: l_smisejh[15] }));
          setBtntl07((l_smisejh[14]+'：'+l_smisejh[15]+'　　　　　　　　　　　　　　　　').slice(0,17));
          setBtn07(false);
          break;
        case 16:
          setBtncd08(btncd08 => ({ ...btncd08, cd: l_smisejh[16] }));
          setBtncd08(btncd08 => ({ ...btncd08, nm: l_smisejh[17] }));
          setBtntl08((l_smisejh[16]+'：'+l_smisejh[17]+'　　　　　　　　　　　　　　　　').slice(0,17));
          setBtn08(false);
          break;
        case 18:
          setBtncd09(btncd09 => ({ ...btncd09, cd: l_smisejh[18] }));
          setBtncd09(btncd09 => ({ ...btncd09, nm: l_smisejh[19] }));
          setBtntl09((l_smisejh[18]+'：'+l_smisejh[19]+'　　　　　　　　　　　　　　　　').slice(0,17));
          setBtn09(false);
          break;
        case 20:
          setBtncd10(btncd10 => ({ ...btncd10, cd: l_smisejh[20] }));
          setBtncd10(btncd10 => ({ ...btncd10, nm: l_smisejh[21] }));
          setBtntl10((l_smisejh[20]+'：'+l_smisejh[21]+'　　　　　　　　　　　　　　　　').slice(0,17));
          setBtn10(false);
          break;
        default:
          break;
      }
    }    


  }, []);

  useEffect(() => {
    // イベントリスナーを登録
    // window.addEventListener('keydown', (e) => {
    //   if (e.key==='F5')
    //   {
    //     e.preventDefault();
    //     navigate('/');
    //   }
    // });
  }, []);

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      //navigate('/');

      if (state.MVEMGAMEN==='TCSA0003')
      {
        navigate('/TCSA0003');
      }else if(state.MVEMGAMEN==='TCSA0005') {
        navigate('/TCSA0005');
      }else{
        navigate('/');
      }
    }
    if (event.keyCode === 120) { // F9キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  const onKeyDown =  async (key) => {
    //console.log(key);
    let st_mise=state.MISES;
    let st_misenm=state.MISEMEI;
    switch (key) {
      case "Enter00":
        st_mise=btncd00.cd;
        st_misenm=btncd00.nm;
        break;
      case "Enter01":
        st_mise=btncd01.cd;
        st_misenm=btncd01.nm;
        break;
      case "Enter02":
        st_mise=btncd02.cd;
        st_misenm=btncd02.nm;
        break;
      case "Enter03":
        st_mise=btncd03.cd;
        st_misenm=btncd03.nm;
        break;
      case "Enter04":
        st_mise=btncd04.cd;
        st_misenm=btncd04.nm;
        break;
      case "Enter05":
        st_mise=btncd05.cd;
        st_misenm=btncd05.nm;
        break;
      case "Enter06":
        st_mise=btncd06.cd;
        st_misenm=btncd06.nm;
        break;
      case "Enter07":
        st_mise=btncd07.cd;
        st_misenm=btncd07.nm;
        break;
      case "Enter08":
        st_mise=btncd08.cd;
        st_misenm=btncd08.nm;
        break;
      case "Enter09":
        st_mise=btncd09.cd;
        st_misenm=btncd09.nm;
        break;
      case "Enter10":
        st_mise=btncd10.cd;
        st_misenm=btncd10.nm;
        break;
      default:
        break;
    }
    dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
    KSACDES : state.KSACDES,MISES: st_mise,K_KSACDES : state.K_KSACDES,K_MISES: state.K_MISES,
    MISEMEI: st_misenm,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
    SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,SDAY:state.SDAY,BUTTON:state.BUTTON });
    if (state.GAMEN == 1 )
    {
      navigate('/TCSA0005');
    }else{
      navigate('/TCSA0003');
    }
  };  

  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "65vh",
          width: "560px",
          m: "20px auto"
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="center"
        >
          <div style={{display:"flex"}}>
          <Typography variant={"h4"} sx={{ m: "10px" }}>
            店舗選択
          </Typography>
          {/* <Typography variant={"h6"} sx={{ m: "15px" }}>
            {state.JGNMEIKNJ}
          </Typography> */}
          </div>
        </Grid>
        <br/>
        <Button variant="contained" color="warning"
            onClick={(e) => onKeyDown('Enter00') }
            disabled={btn00}
            >
          {btntl00}
        </Button>
        <br /><br />
        <Stack direction="row" spacing={2}>
          <Button variant="contained" color='success'
            onClick={(e) => onKeyDown('Enter01') }
            disabled={btn01}
            >
            {btntl01}
          </Button>
          <Button variant="contained" color='success'
            onClick={(e) => onKeyDown('Enter02') }
            disabled={btn02}
            >
            {btntl02}
          </Button>
        </Stack>
        <br />
        <Stack direction="row" spacing={2}>
          <Button variant="contained" color='success'
            onClick={(e) => onKeyDown('Enter03') }
            disabled={btn03}
            >
            {btntl03}
          </Button>
          <Button variant="contained" color='success'
            onClick={(e) => onKeyDown('Enter04') }
            disabled={btn04}
            >
            {btntl04}
          </Button>
        </Stack>
        <br />
        <Stack direction="row" spacing={2}>
          <Button variant="contained" color='success'
            onClick={(e) => onKeyDown('Enter05') }
            disabled={btn05}
            >
            {btntl05}
          </Button>
          <Button variant="contained" color='success'
            onClick={(e) => onKeyDown('Enter06') }
            disabled={btn06}
            >
            {btntl06}
          </Button>
        </Stack>
        <br />
        <Stack direction="row" spacing={2}>
          <Button variant="contained" color='success'
            onClick={(e) => onKeyDown('Enter07') }
            disabled={btn07}
            >
            {btntl07}
          </Button>
          <Button variant="contained" color='success'
            onClick={(e) => onKeyDown('Enter08') }
            disabled={btn08}
            >
            {btntl08}
          </Button>
        </Stack>
        <br />
        <Stack direction="row" spacing={2}>
          <Button variant="contained" color='success'
            onClick={(e) => onKeyDown('Enter09') }
            disabled={btn09}
            >
            {btntl09}
          </Button>
          <Button variant="contained" color='success'
            onClick={(e) => onKeyDown('Enter10') }
            disabled={btn10}
            >
            {btntl10}
          </Button>
        </Stack>

        <p><font color="red">{ alert }</font></p>
      </Paper>
    </Grid>
  );
};

export default TCSA0002;