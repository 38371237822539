import { useState, useEffect } from 'react'

 // 理由No.情報取得
export const useRiyuAction = () => {
  const [riyuList, setRiyuList] = useState([]);                 // 理由Noリスト
  const [riyuName, setRiyuName] = useState('');                 // 理由No（表示名称）
  const [riyuComment, setRiyuComment] = useState('※理由No.');  // 理由Noコメント

  useEffect (() => {
    getRiyu();
  }, []);
  const getRiyu = async() => {
    // 理由No.情報（使用可能No.）取得
    const getRiyuCd = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({CDE: '005',SCDE: '0000'})});
    const riyuCdList = await getRiyuCd.json();

    // 理由No.情報理由No.に対する理由No.名）取得（
    const getRiyuName = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({CDE: '005',SCDE: '0010'})});
    const riyuNameList = await getRiyuName.json();

    // 理由No.リスト化
    setRiyuList([]);
    setRiyuList((riyuList) => [...riyuList ,{cd:riyuCdList.DATE01,name:riyuNameList.DATE01}]);
    setRiyuList((riyuList) => [...riyuList ,{cd:riyuCdList.DATE02,name:riyuNameList.DATE02}]);
    setRiyuList((riyuList) => [...riyuList ,{cd:riyuCdList.DATE03,name:riyuNameList.DATE03}]);
    setRiyuList((riyuList) => [...riyuList ,{cd:riyuCdList.DATE04,name:riyuNameList.DATE04}]);
    setRiyuList((riyuList) => [...riyuList ,{cd:riyuCdList.DATE05,name:riyuNameList.DATE05}]);
    setRiyuList((riyuList) => [...riyuList ,{cd:riyuCdList.DATE06,name:riyuNameList.DATE06}]);
    setRiyuList((riyuList) => [...riyuList ,{cd:riyuCdList.DATE07,name:riyuNameList.DATE07}]);
    setRiyuList((riyuList) => [...riyuList ,{cd:riyuCdList.DATE08,name:riyuNameList.DATE08}]);
    setRiyuList((riyuList) => [...riyuList ,{cd:riyuCdList.DATE09,name:riyuNameList.DATE09}]);
    setRiyuList((riyuList) => [...riyuList ,{cd:riyuCdList.DATE10,name:riyuNameList.DATE10}]);
  };

  // 理由No.コメントの表示
  useEffect (() => {
    let comment = '※理由No.';
    riyuList.forEach(element => {
        if(element.cd !== '') {
          comment = comment + ' ' + element.cd + ':' + element.name;
        }
        setRiyuComment(comment)
      });
  }, [riyuList]);

  const changeRiyuNo = (riyuValue) => {
    let riyuNoName = '';
    if (riyuValue !== '')
    {
      const inputRiyu = riyuList.filter(element => {
        return element.cd === riyuValue
      });

      if (inputRiyu.length !== 0){
        riyuNoName = inputRiyu[0].name;
      }
    }

    setRiyuName(riyuNoName);
  };

  return {riyuList, riyuComment, riyuName, changeRiyuNo}
};


export default useRiyuAction;