import React, { useState,useContext,useEffect,useRef } from 'react'
import { Button, TextField, Grid, Typography, Stack, InputLabel } from '@mui/material';
import { NumericFormat,PatternFormat } from 'react-number-format';    //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import { onMyFocus,onNextFocus } from './hooks/useOnMyFocus';     // select()処理

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);

      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCPD0142 ({
  setScreenPhase,
  headerData,
  headerItem,
  setHeaderItem,
  rowData,
  rowItem,
  setRowItem,
  denKbnList,
  rows,
  setRows,
  syodanData,
  iryoZei,
  sdate,
  ckDate,
  setHeaderReset2,     // 23/09/25
  retDenKbn
}) {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [valueSHNMEI ,setValueSHNMEI] = useState('');   // 商品名

  //項目のバックカラー制御用
  const [inJAN1clr,setJAN1clr] = useState('#FFFFFF');
  const [inJAN2clr,setJAN2clr] = useState('#FFFFFF');
  const [inSUUclr,setSUUclr] = useState('#FFFFFF');
  const [inGNTclr,setGNTclr] = useState('#FFFFFF');
  const [inBTNclr,setBTNclr] = useState('#FFFFFF');
  const [inJYOTOYMDclr,setJYOTOYMDclr] = useState('#FFFFFF');

  //disabled制御用
  const [disJan1, setJan1] = useState(false);   // JAN
  const [disJan2, setJan2] = useState(true);    // JAN2
  // 2023/10/06
  const [disRow, setDisRow] = useState(true);    // 数量 原単価 売単価 譲渡日etc

  const [chkJAN1,setChkJAN1] = useState('0');
  const [chkJAN2,setChkJAN2] = useState('0');

  const inJAN1 = useRef(null);
  const inJAN2 = useRef(null);
  const inSHNMEI = useRef(null);
  const inSUU = useRef(null);
  const inGNT = useRef(null);
  const inBTN = useRef(null);
  const inJYOTOYMD = useRef(null);
  const inSIRCDE2 = useRef(null);

  useEffect (()=>{
    let  rowName = rowItem.SHNMEIKN + '\n';
    if (rowItem.IRYO_FLG !== '1')
    {
      rowName = rowName + rowItem.KIKAKUKN + '\n';
    }else{
      rowName = rowName + rowItem.TRIHBN + '\n' + rowItem.TAGSIZMEI + ' ' + rowItem.TAGCOLMEI;
    }
    setValueSHNMEI(rowName);

    if(rowItem.JANCDE2 !== '') {
      if (rowItem.id === 0)
      {
        setJan2(false);
      }
    }else if(rowItem.JANCDE === ''){
      setJan2(true);
    }
  }, [rowItem]);

  useEffect (()=>{
    if(disRow === false){
      inSUU.current.focus();
    }
  }, [disRow]);

  const [nextActive, setNextActive] = useState(null);
    // JAN1入力後、数量が全選択状態にならないため、
  // 数量の描画完了後にフォーカス移動させる必要がある
  useEffect(() => {
    if (nextActive === 'JAN2') {
      if (inJAN2.current !==null && typeof inJAN2.current !== 'undefined')
      {
        inJAN2.current.focus();
      }
      setNextActive('');
    }
  }, [disJan2]);

  /**
   * 数量設定 (numをrow.SUUに数字で、row.SUU2に文字列で設定)
   * @param row 設定行
   * @param num 設定値
   */
  const setRowSuu = async (row, num) => {
    let suu = Number(num);
    if (isNaN(suu)){
      suu = 0;
    }
    // 数量
    row.SUU = suu;
    // 数量2
    row.SUU2 = suu.toLocaleString(undefined,{minimumFractionDigits : 1});
  }

  /**
   * 原単価設定 (numをrow.GNTに数字で、row.GNT2に文字列で設定)
   * @param row 設定行
   * @param num 設定値
   */
  const setRowGnt = async (row, num) => {
    let gnt = Number(num);
    if (isNaN(gnt)){
      gnt = 0;
    }
    // 原単価
    row.GNT = gnt;
    // 原単価2
    row.GNT2 = gnt.toLocaleString(undefined,{minimumFractionDigits : 2});
  }

  /**
   * 売単価設定 (numをrow.BTNに数字で、row.BTN2に文字列で設定)
   * @param row 設定行
   * @param num 設定値
   */
  const setRowBtn = async (row, num) => {
    let btn = Number(num);
    if (isNaN(btn)){
      btn = 0;
    }
    // 売単価
    row.BTN = btn;
    // 売単価2
    row.BTN2 = btn.toLocaleString(undefined,{minimumFractionDigits : 0});
  }

  const onKeyDown =  async (key,inputRef,inptRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    switch (key) {
      case 'Enter':
        setJAN1clr('#FFFFFF');
        setJAN2clr('#FFFFFF');
        setSUUclr('#FFFFFF');
        setGNTclr('#FFFFFF');
        setBTNclr('#FFFFFF');
        setJYOTOYMDclr('#FFFFFF');
        switch (inputRef.current.id) {
          case 'JAN1':    // ＪＡＮ１
            await onKeyDown_JAN1(inputRef, inputNxRef);
            break;
          case 'JAN2':    // ＪＡＮ２（活性時）
            await onKeyDown_JAN2(inputRef, inputNxRef);
            break;
          case 'SUU':     // 数量
            await onKeyDown_SUU(inputRef, inputNxRef);
            break;
          case 'GNT':     // 原単価
            await onKeyDown_GNT(inputRef, inputNxRef);
            break;
          case 'BTN':     // 原単価
            await onKeyDown_BTN(inputRef, inputNxRef);
            break;
          case 'JYOTOYMD': // 譲渡日
            await onKeyDown_JYOTOYMD(inputRef, inputNxRef);
            break;

          default:
            inputNxRef.current.focus(); //次の項目に遷移;
            break;
        }
        break;
      case 'Home':
        break;
      case 'ArrowUp':
        inptRtRef.current.focus();    //前の項目に遷移
        break;
      case 'ArrowDown':
        inputNxRef.current.focus();   //次の項目に遷移
        break;
      default:
        break;
    }
  };

  const onblur = async (inputRef) => {
    setJAN1clr('#FFFFFF');
    setJAN2clr('#FFFFFF');
    setSUUclr('#FFFFFF');
    setGNTclr('#FFFFFF');
    setBTNclr('#FFFFFF');
    setJYOTOYMDclr('#FFFFFF');
    console.log('LostFocus:'+inputRef.current.id+' | '+chkJAN1+'|'+chkJAN2);
    if (inputRef.current.id === 'JAN1' && (chkJAN1 == '0'))
    {
      await onKeyDown_JAN1(inputRef, inSUU)
      return;
    }
    if (inputRef.current.id === 'JAN2' && (chkJAN2 == '0'))
    {
      await onKeyDown_JAN2(inputRef, inSUU)
    }
  }

  // ヘッダ部２入力　ＪＡＮ１
  const onKeyDown_JAN1 = async(inputRef, inputNxRef) => {
    setChkJAN1('1');
    const row = await JSON.parse(JSON.stringify(rowItem));
    const header = await JSON.parse(JSON.stringify(headerItem));
    let Jancde = row.JANCDE;
    console.log('JAN1:'+inputRef.current.value);
    if  (inputRef.current.value === '')
    {
      return;
    }

    // ＪＡＮ１入力値の前0詰め
    if(row.JANCDE.length === 7){
      row.JANCDE = row.JANCDE.padStart(8, '0');
      Jancde = row.JANCDE.padStart(8, '0');
    }
    if(9 <= row.JANCDE.length && row.JANCDE.length <= 12){
      row.JANCDE = row.JANCDE.padStart(13, '0');
      Jancde = row.JANCDE.padStart(13, '0');
    }

    // 2段判定
    const jan1cd = Jancde.substring(0, 2);

    if (Jancde.length == 13 && (
          (jan1cd === '22' ||jan1cd === '23')
        ||((jan1cd === '21' ||jan1cd === '22') && state.KSACDES ==='005')
    )){

      //TODO 本番コメント化解除(コメント化不要)
      // 譲渡日の取得
      // 2段JANの場合
      const EosCode = row.JANCDE.substring(2, 10);
      if (process.env.NODE_ENV !== 'development') {
        const responseX = await fetch('/trans_GetShnmstFinl',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({URL: `http://10.0.63.10/olive/oasis_plus/menu/denpyo/trans/trans_GetShnmstFinlnhnYmd.jsp?EOSCDE=${EosCode}&MISE=${row.MISE}`})});
        const dataX = await responseX.json();
        console.log('譲渡日02:%o',dataX);

        row.JYOTOYMD = dataX.finlnhnymd;
        row.JYOTOYMD2 = dataX.finlnhnymd.substring(0, 4) + '/' + dataX.finlnhnymd.substring(4, 6) + '/' + dataX.finlnhnymd.substring(6,8);
      }else{
        //開発環境のみ
        row.JYOTOYMD = '20230921';
        row.JYOTOYMD2 = '2023/09/21';
        console.log('テスト譲渡日設定02');
      }


      await setRow_Two_JAN1 (row,jan1cd,Jancde);
      // await setJan2(false);
      // inJAN2.current.focus();
      // return;
      //次の項目に遷移
      if (disJan2 === false) {
        // 活性時の場合はuseEffectは発生しない
        inJAN2.current.focus();
      } else {
        // 非活性時は、活性化の描画完了後（useEffect）にフォーカス移動させる必要がある
        setNextActive(inJAN2.current.id);
        setJan2(false);
      }
      return;
    }

    if(!(jan1cd === '21' || jan1cd === '22' || jan1cd === '23')){
      setJan2(true);
      row.JANCDE2 = '';
    }

    // 衣料品事業部配下分類かどうかを確認
    const getIryoFlg = await fetch('/TCGT0100/getIryoFlg',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ header:headerItem })});
    const iryo_flg = await getIryoFlg.json();

    // 衣料品フラグ = １ の場合
    if(iryo_flg.IRYOFLG === '1') {
      row.IRYO_FLG = '1';
    } else {
      row.IRYO_FLG = '0';
    }


    let shohinMaster = undefined;
    // 8桁の場合、商品マスタ（基本）にEOSとしてデータが存在するかチェック。
    if(Jancde.length === 8){
      const getShohinMaster = await fetch('/TCGT0100/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({row:row, EOSCDE:Jancde })});;
      shohinMaster = await getShohinMaster.json();

      if(shohinMaster.ret.length !== 0) {
        row.IN_CD_KBN = '2'
      }
    };

    // 8、12、13 桁の場合、商品マスタ（基本）にJANとしてデータが存在するかチェック。
    if(row.IN_CD_KBN !== '2' &&
    (Jancde.length === 8 || Jancde.length === 12 || Jancde.length === 13)) {
      const getShohinMaster = await fetch('/TCGT0100/getShohinMaster_JanCode',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({row:row, JANCDEX:Jancde })});
      shohinMaster = await getShohinMaster.json();

      if(shohinMaster.ret.length !== 0) {
        row.IN_CD_KBN = '1'
      }
    }

    if(!shohinMaster) {
      setJAN1clr('#f59d9d');
      window.alert('商品が存在しません。'); //TCGE0047：
      return;
    }

    // 仕入先名称取得
    if (shohinMaster.ret.length !== 0) {
      let ret = '0';
      if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined){
        ret = await getSirmeik(shohinMaster.ret[0].SIRCDE,header,2);
        if (ret === '-1')
        {
          setJAN1clr('#f59d9d');
          window.alert('取引先コード入力エラー'); //TCGE0015：
          return 1;
        }
      }
      console.log('322:'+header.SIRCDE+'!=='+shohinMaster.ret[0].SIRCDE+'/'+ret);
      if(header.SIRCDE !== shohinMaster.ret[0].SIRCDE && ret === '0') {
        setJAN1clr('#f59d9d');
        setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
        setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
        setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
        window.alert('取引先コード入力エラー'); //TCGE0015：
        return 1;
      }

      // if(header.SIRCDE === '') {
      //   setJAN1clr('#f59d9d');
      //   window.alert('TCGE0015：取引先コード入力エラー');
      //   return;
      // }
    }

    // 譲渡日の取得
    let responseX_url = '';
    if(row.IN_CD_KBN === '1') {
      //JANの場合
      responseX_url = `http://10.0.63.10/olive/oasis_plus/menu/denpyo/trans/trans_GetShnmstFinlnhnYmd.jsp?JANCDE=${row.JANCDE}&MISE=${row.MISE}`

    } else {
      //EOSの場合
      responseX_url = `http://10.0.63.10/olive/oasis_plus/menu/denpyo/trans/trans_GetShnmstFinlnhnYmd.jsp?EOSCDE=${row.JANCDE}&MISE=${row.MISE}`
    }
    if (jan1cd === '23')
    {
      //EOSの場合
      const EosCode = row.JANCDE.substring(2, 10);
      responseX_url = `http://10.0.63.10/olive/oasis_plus/menu/denpyo/trans/trans_GetShnmstFinlnhnYmd.jsp?EOSCDE=${EosCode}&MISE=${row.MISE}`
    }

    //TODO 本番コメント化解除(コメント化不要)
    if (process.env.NODE_ENV !== 'development') {
      const responseX = await fetch('/trans_GetShnmstFinl',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({URL: responseX_url})});
      const dataX = await responseX.json();
      console.log('譲渡日設定01:%o',dataX);

      row.JYOTOYMD = dataX.finlnhnymd;
      row.JYOTOYMD2 = dataX.finlnhnymd.substring(0, 4) + '/' + dataX.finlnhnymd.substring(4, 6) + '/' + dataX.finlnhnymd.substring(6,8);
    }else{
      //開発環境のみ
      row.JYOTOYMD = '20230921';
      row.JYOTOYMD2 = '2023/09/21';
      console.log('テスト譲渡日設定01');
    }


    // ＪＡＮ１チェック
    // (1)
    if(shohinMaster.ret.length === 0) {
      setJAN1clr('#f59d9d');
      window.alert('商品が存在しません。'); //TCGE0047：
      return;
    }

    // (2)
    if(header.BMNCDE  !== shohinMaster.ret[0].BMNCDE) {
      setHeaderItem(HeaderItem　=> ({...HeaderItem,SIRCDE:''}));
      setHeaderItem(HeaderItem　=> ({...HeaderItem,SIRMEIK:''}));
      setHeaderItem(HeaderItem　=> ({...HeaderItem,ZEIRTU:''}));
      setHeaderItem(HeaderItem　=> ({...HeaderItem,SIRCDE2:''}));
      setHeaderItem(HeaderItem　=> ({...HeaderItem,SIRMEIK2:''}));
      setHeaderItem(HeaderItem　=> ({...HeaderItem,ZEIRTU2:''}));
      setJAN1clr('#f59d9d');
      window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
      return;
    }

    // (3)
    if(shohinMaster.ret[0].HPN_FKA_KBN === '1') {
      if(!(window.confirm('警告：この商品は返品不可扱いです。強制返品しますか？'))){  //TCGC0003：
        setJAN1clr('#f59d9d');
        return;
      }
    }

    // データセット（1段）
    await setRow_One(row,shohinMaster.ret[0],Jancde,header);
    // ＪＡＮ入力後チェック
    onKeyDown_JANCheck(row, inputNxRef);
  }

  // データセット（1段）
  function setRow_One(row,shohinMaster,jancde,headerx){
    row.SHNMEIKN = shohinMaster.SHNMEIKN;      // 商品名
    row.KIKAKUKN = shohinMaster.KIKAKUKN;      // 規格
    row.JANCDE = jancde;         // ＪＡＮコード
    row.TRIHBN = shohinMaster.TRIHBN;          // 取引先品番
    setRowSuu(row, '1');                       // 数量
    setRowGnt(row, shohinMaster.GNT);          // 原単価
    row.GNKKIN = 0;                            // 原価金額
    setRowBtn(row, shohinMaster.BTN);          // 売単価
    row.BAKKIN = 0;                            // 売価金額
    row.SIRCDE = headerx.SIRCDE2;
    row.SIRMEIK = headerx.SIRMEIK2;
    row.ZEIRTU = headerx.ZEIRTU2;
    if( row.IN_CD_KBN === '2') {
      row.EOSCDE = jancde;       // ＥＯＳコード
    }

    const denKbn = headerItem.DENKBN.padStart(2, '0'); // 伝票区分コード入力値の前0詰め
    if(denKbn === '05' || denKbn === '06') {
      row.BEF_CST_AMT = shohinMaster.GNT;  // 訂正前原価
      row.BEF_SEL_AMT = shohinMaster.BTN;  // 訂正前売価
      row.AFT_CST_AMT = shohinMaster.GNT;  // 訂正後原価
      row.AFT_SEL_AMT = shohinMaster.BTN;  // 訂正後売価
    } else {
      row.BEF_CST_AMT = '0';  // 訂正前原価
      row.BEF_SEL_AMT = '0';  // 訂正前売価
      row.AFT_CST_AMT = '0';  // 訂正後原価
      row.AFT_SEL_AMT = '0';  // 訂正後売価
    }

    if(row.IRYO_FLG === '1'){
      row.TAGASTCDE = shohinMaster.ASTCDE;     // タグＡＵ
      row.TAGLINCDE = shohinMaster.LINCDE;     // タグライン
      row.TAGCLSCDE = shohinMaster.CLSCDE;     // タグクラス
      row.TAGSZN = shohinMaster.TAGSZN;        // タグシーズン
      row.TRIHBN = shohinMaster.TRIHBN;        // 取引先品番
      row.SIZCDE = shohinMaster.TAGSIZCDE;     // サイズコード
      row.TAGSIZMEI = shohinMaster.TAGSIZMEI;  // サイズ名カナ
      row.TAGCOLCDE = shohinMaster.TAGCOLCDE;  // カラーコード
      row.TAGCOLMEI = shohinMaster.TAGCOLMEI;  // カラー名カナ
    }
    setRowItem(row);
  }

  // データセット（2段 JAN1時）
  function setRow_Two_JAN1 (row, jan1cd,jancde) {
    row.IRYO_FLG = '1';                        // 衣料フラグ
    row.JANCDE = jancde;         // ＪＡＮコード
    //row.EOSCDE = jancde;         // ＥＯＳコード
    if(jan1cd === '22') {
      row.IN_CD_KBN = '3'                      // 入力コード区分
    } else {
      row.IN_CD_KBN = '4'                      // 入力コード区分
      row.EOSCDE = jancde;         // ＥＯＳコード
    }
    setRowItem(row);
  }

  // ヘッダ部２入力　ＪＡＮ２（活性時）
  const onKeyDown_JAN2 = async (inputRef, inputNxRef) => {
    setChkJAN2('1');

    // ＪＡＮ2入力値の前0詰め
    let Jan2cde = inJAN2.current.value;
    if(Jan2cde.length === 7){
      Jan2cde = Jan2cde.padStart(8, '0');
    }
    if(9 <= Jan2cde.length && Jan2cde.length <= 12){
      Jan2cde =Jan2cde.padStart(13, '0');
    }

    const jan2cdH = inJAN2.current.value.substring(0, 2);
    // state.KSACDES = '001'の場合は、前２桁が「28」、「29」以外はエラー
    if(state.KSACDES === '001' && !(jan2cdH === '28' ||jan2cdH === '29')){
      setJAN2clr('#f59d9d');
      window.alert('ＪＡＮ２入力エラー。'); //TCGE00XX：
      return 1;
    }

    // state.KSACDES = '005'の場合は、前２桁が「26」、「27」以外はエラー
    if(state.KSACDES === '005' && !(jan2cdH === '26' ||jan2cdH === '27')){
      setJAN2clr('#f59d9d');
      window.alert('ＪＡＮ２入力エラー。'); //TCGE00XX：
      return 1;
    }

    let row = JSON.parse(JSON.stringify(rowItem))
    row.JANCDE2 = Jan2cde;
    const header = await JSON.parse(JSON.stringify(headerItem));
    const jan1cd = inJAN1.current.value.substring(0, 2);
    let tagMaster = undefined;
    let shohinMaster = undefined;
    //１）ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005以外もしくは、「２３」の場合
    if((jan1cd ==='22' && state.KSACDES !== '005')|| jan1cd ==='23') {
      //(1)ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005以外の場合
      if(jan1cd ==='22' && state.KSACDES !== '005') {
        const ASTCDE = Jan2cde.substring(2, 5);                      // ＡＵ
        const LINCDE = inJAN1.current.value.substring(2, 3);         // ライン
        const CLSCDE = '000' + inJAN1.current.value.substring(3, 4); // クラス
        const TAGSZN = inJAN1.current.value.substring(4, 5);         // タグシーズン
        const TAGITM = inJAN1.current.value.substring(5, 8);         // タグアイテム
        const TAGSIZCDE = inJAN1.current.value.substring(8, 10);     // サイズコード
        const TAGCOLCDE = inJAN1.current.value.substring(10, 12);    // カラーコード

        //①タグマスタから情報取得
        const getTagMaster = await fetch('/TCGT0100/getTagMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,MISE:state.MISES,ASTCDE:ASTCDE,LINCDE:LINCDE,CLSCDE:CLSCDE,TAGSZN:TAGSZN,TAGITM:TAGITM
        })});
        tagMaster = await getTagMaster.json();

        if (tagMaster.ret.length === 0){
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          return;
        }

        // 仕入先名称取得
        if (tagMaster.ret.length !== 0) {
          let ret = '0';
          if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined){
            ret = await getSirmeik(tagMaster.ret[0].SIRCDE,header,2);
            if (ret === '-1')
            {
              setJAN1clr('#f59d9d');
              window.alert('取引先コード入力エラー'); //TCGE0015：
              return 1;
            }

          }
          console.log('511:'+header.SIRCDE+'!=='+tagMaster.ret[0].SIRCDE+'/'+ret);
          if(header.SIRCDE !== tagMaster.ret[0].SIRCDE && ret === '0') {
            setJAN1clr('#f59d9d');
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
            window.alert('取引先コード入力エラー'); //TCGE0015：
            return 1;
          }

          // 2023/10/06
          if(header.BMNCDE !== tagMaster.ret[0].BMNCDE) {
            console.log('header02:%o',header);
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
            setJAN1clr('#f59d9d');
            inJAN1.current.focus();
            window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
            return 1;
          }

          // if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined) {
          //   setJAN1clr('#f59d9d');
          //   window.alert('TCGE0015：取引先コード入力エラー');
          //   return;
          // }
        }

        //② 衣料サイズマスタから情報取得
        const getSizeMaster = await fetch('/TCGT0100/getSizeMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,BMNCDE:header.BMNCDE,ASTCDE:ASTCDE,LINCDE:LINCDE,TAGSIZCDE:TAGSIZCDE
        })});
        const sizeMaster = await getSizeMaster.json();
        if(sizeMaster.ret.length !== 0) {
          row.SIZCDE = sizeMaster.ret[0].TAGSIZCDE;     // サイズコード
          row.TAGSIZMEI = sizeMaster.ret[0].TAGSIZMEI;  // サイズ名カナ
        } else {
          row.SIZCDE = '';     // サイズコード
          row.TAGSIZMEI = '';  // サイズ名カナ
        }


        //③ 衣料カラーマスタから情報取得
        const getColorMaster = await fetch('/TCGT0100/getColorMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({KSACDE:state.KSACDES,TAGCOLCDE:TAGCOLCDE})});
        const colorMaster = await getColorMaster.json();
        if(colorMaster.ret.length !== 0) {
          row.TAGCOLCDE = colorMaster.ret[0].TAGCOLCDE; // カラーコード
          row.TAGCOLMEI = colorMaster.ret[0].TAGCOLMEI; // カラー名カナ
        } else {
          row.TAGCOLCDE = ''; // カラーコード
          row.TAGCOLMEI = ''; // カラー名カナ
        }

        setRow_Two(row, tagMaster.ret[0]);
      }

      //(2) ＪＡＮ１の前２桁が「２３」の場合
      if(jan1cd ==='23') {
        // 桁数チェック
        if(Jan2cde.length !== 13){
          setJAN2clr('#f59d9d');
          window.alert('ＪＡＮ２入力エラー。'); //TCGE0047：
          return;
        }

        //① 商品マスタ(基本)から情報取得
        const EosCode = inJAN1.current.value.substring(2, 10);
        const getShohinMaster = await fetch('/TCGT0100/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({row:rowItem, EOSCDE:EosCode})});
        shohinMaster = await getShohinMaster.json();

        if (shohinMaster.ret.length === 0){
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          return;
        }

        row.EOSCDE = EosCode;
        setRowGnt(row, shohinMaster.ret[0].GNT);          // 原単価
        setRowBtn(row, shohinMaster.ret[0].BTN);          // 売単価
        //② 返品不可判定
        if (shohinMaster.ret[0].HPN_FKA_KBN === '1') {
          if(!(window.confirm('警告：この商品は返品不可扱いです。強制返品しますか？'))){  //TCGC0003：
            setJAN1clr('#f59d9d');
            inJAN1.current.focus();
            setRowItem(row);
            return;
          }
        }

        // 仕入先名称取得
        if (shohinMaster.ret.length !== 0) {
          let ret = '0';
          if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined){
            ret = await getSirmeik(shohinMaster.ret[0].SIRCDE,header,2);
            if (ret === '-1')
            {
              setJAN1clr('#f59d9d');
              window.alert('取引先コード入力エラー'); //TCGE0015：
              return 1;
            }
          }
          console.log('594:'+header.SIRCDE+'!=='+shohinMaster.ret[0].SIRCDE+'/'+ret);
          if(header.SIRCDE !== shohinMaster.ret[0].SIRCDE && ret === '0') {
            setJAN1clr('#f59d9d');
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
            window.alert('取引先コード入力エラー'); //TCGE0015：
            return 1;
          }

          // 2023/10/06
          if(header.BMNCDE !== shohinMaster.ret[0].BMNCDE) {
            console.log('header02:%o',header);
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
            setJAN1clr('#f59d9d');
            inJAN1.current.focus();
            window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
            return 1;
          }

          // if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined) {
          //   setJAN1clr('#f59d9d');
          //   window.alert('TCGE0015：取引先コード入力エラー');
          //   return;
          // }
        }

        setRow_Two2(row, shohinMaster.ret[0]);
      }

      //(3) 計算
      const taxcde = Number(Jan2cde.substring(5, 6));
      const jankng = Number(Jan2cde.substring(6, 12));

      if(jankng !== 0){
        //①２段目バーコードの6桁目(税区分)＝0(総額)の場合
        if(taxcde === 0){
          let tax = (jankng * iryoZei) / (100 + iryoZei)
          setRowBtn(row, (jankng - tax));     // 売単価
        }

        //②２段目バーコードの6桁目(税区分)≠0(総額)の場合
        if(taxcde !== 0){
          setRowBtn(row, jankng);             // 売単価
        }
      }
      //③２段目バーコードの金額が０の場合。
      //設定済

    }

    //２）ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005もしくは、「２１」の場合
    if((jan1cd === '22' && state.KSACDES === '005') || jan1cd ==='21') {
      //(1)ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005の場合
      if(jan1cd === '22' && state.KSACDES === '005') {
        const ASTCDE = Jan2cde.substring(2, 5);                      // ＡＵ
        const LINCDE = inJAN1.current.value.substring(2, 3);         // ライン
        const CLSCDE = '000' + inJAN1.current.value.substring(3, 4); // クラス
        const TAGSZN = inJAN1.current.value.substring(4, 5);         // タグシーズン
        const TAGITM = inJAN1.current.value.substring(5, 8);         // タグアイテム
        const TAGSIZCDE = inJAN1.current.value.substring(8, 10);     // サイズコード
        const TAGCOLCDE = inJAN1.current.value.substring(10, 12);    // カラーコード

        //①タグマスタから情報取得
        const getTagMaster = await fetch('/TCGT0100/getTagMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,MISE:state.MISES,ASTCDE:ASTCDE,LINCDE:LINCDE,CLSCDE:CLSCDE,TAGSZN:TAGSZN,TAGITM:TAGITM
        })});
        tagMaster = await getTagMaster.json();

        if (tagMaster.ret.length === 0){
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          return;
        }

        // 仕入先名称取得
        if (tagMaster.ret.length !== 0) {
          let ret = '0';
          if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined){
            ret = await getSirmeik(tagMaster.ret[0].SIRCDE,header,2);
            if (ret === '-1')
            {
              setJAN1clr('#f59d9d');
              window.alert('取引先コード入力エラー'); //TCGE0015：
              return 1;
            }
          }
          console.log('668:'+header.SIRCDE+'!=='+tagMaster.ret[0].SIRCDE+'/'+ret);
          if(header.SIRCDE !== tagMaster.ret[0].SIRCDE && ret === '0') {
            setJAN1clr('#f59d9d');
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
            window.alert('取引先コード入力エラー'); //TCGE0015：
            return 1;
          }

          // 2023/10/06
          if(header.BMNCDE !== tagMaster.ret[0].BMNCDE) {
            console.log('header02:%o',header);
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
            setJAN1clr('#f59d9d');
            inJAN1.current.focus();
            window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
            return 1;
          }

          // if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined) {
          //   setJAN1clr('#f59d9d');
          //   window.alert('TCGE0015：取引先コード入力エラー');
          //   return;
          // }
        }

        //② 衣料サイズマスタから情報取得
        const getSizeMaster = await fetch('/TCGT0100/getSizeMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,BMNCDE:header.BMNCDE,ASTCDE:ASTCDE,LINCDE:LINCDE,TAGSIZCDE:TAGSIZCDE
        })});
        const sizeMaster = await getSizeMaster.json();
        if(sizeMaster.ret.length !== 0) {
          row.SIZCDE = sizeMaster.ret[0].TAGSIZCDE;     // サイズコード
          row.TAGSIZMEI = sizeMaster.ret[0].TAGSIZMEI;  // サイズ名カナ
        } else {
          row.SIZCDE = '';     // サイズコード
          row.TAGSIZMEI = '';  // サイズ名カナ
        }

        //③ 衣料カラーマスタから情報取得
        const getColorMaster = await fetch('/TCGT0100/getColorMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({KSACDE:state.KSACDES,TAGCOLCDE:TAGCOLCDE})});
        const colorMaster = await getColorMaster.json();
        if(colorMaster.ret.length !== 0) {
          row.TAGCOLCDE = colorMaster.ret[0].TAGCOLCDE; // カラーコード
          row.TAGCOLMEI = colorMaster.ret[0].TAGCOLMEI; // カラー名カナ
        } else {
          row.TAGCOLCDE = ''; // カラーコード
          row.TAGCOLMEI = ''; // カラー名カナ
        }

        setRow_Two(row, tagMaster.ret[0]);
      }
      //(2) ＪＡＮ１の前２桁が「２１」の場合
      if (jan1cd === '21') {
        // 桁数チェック
        if(Jan2cde.length !== 13){
          setJAN2clr('#f59d9d');
          window.alert('ＪＡＮ２入力エラー。'); //TCGE0047：
          return;
        }

        //① 商品マスタ(基本)から情報取得
        const EosCode = inJAN1.current.value.substring(4, 12);
        const getShohinMaster = await fetch('/TCGT0100/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({row:rowItem, EOSCDE:EosCode})});
        shohinMaster = await getShohinMaster.json();

        if (shohinMaster.ret.length === 0) {
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          return;
        }

        row.EOSCDE = EosCode;
        setRowGnt(row, shohinMaster.ret[0].GNT);           // 原単価
        setRowBtn(row, shohinMaster.ret[0].BTN);           // 売単価

        //② 返品不可判定
        if (shohinMaster.ret[0].HPN_FKA_KBN === '1') {
          if(!(window.confirm('警告：この商品は返品不可扱いです。強制返品しますか？'))){  //TCGC0003：
            setJAN1clr('#f59d9d');
            inJAN1.current.focus();
            setRowItem(row);
            return;
          }
        }

        // 仕入先名称取得
        if (shohinMaster.ret.length !== 0) {
          let ret = '0';
          if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined){
            ret = await getSirmeik(shohinMaster.ret[0].SIRCDE,header,2);
            if (ret === '-1')
            {
              setJAN1clr('#f59d9d');
              window.alert('取引先コード入力エラー'); //TCGE0015：
              return 1;
            }
          }
          console.log('750:'+header.SIRCDE+'!=='+shohinMaster.ret[0].SIRCDE+'/'+ret);
          if(header.SIRCDE !== shohinMaster.ret[0].SIRCDE && ret === '0') {
            setJAN1clr('#f59d9d');
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
            window.alert('取引先コード入力エラー'); //TCGE0015：
            return;
          }

          // 2023/10/06
          if(header.BMNCDE !== shohinMaster.ret[0].BMNCDE) {
            console.log('header02:%o',header);
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
            setJAN1clr('#f59d9d');
            inJAN1.current.focus();
            window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
            return 1;
          }

          // if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined) {
          //   setJAN1clr('#f59d9d');
          //   window.alert('TCGE0015：取引先コード入力エラー');
          //   return;
          // }
        }

        setRow_Two2(row, shohinMaster.ret[0]);
      }

      //(3) 計算
      const jankng = Number(Jan2cde.substring(6, 12));
      setRowBtn(row, jankng);           // 売単価
    }

    setRowItem(row);
    //ＪＡＮ入力後チェック
    onKeyDown_JANCheck(row, inputNxRef);
  }

  // データセット（2段）
  function setRow_Two(row, tagMaster) {
    row.SHNMEIKN = tagMaster.SHNMEIKN;  // 商品名
    row.KIKAKUKN = tagMaster.KIKAKUKN;  // 規格
    setRowSuu(row, '1');                // 数量
    setRowGnt(row, tagMaster.GNK);      // 原単価
    row.GNKKIN = 0;                     // 原価金額
    setRowBtn(row, tagMaster.BAK);      // 売単価
    row.BAKKIN = 0;                     // 売価金額
    //row.JANCDE2 = inJAN2.current.value; // ＪＡＮコード２

    const denKbn = headerItem.DENKBN.padStart(2, '0'); // 伝票区分コード入力値の前0詰め
    if(denKbn === '05' || denKbn === '06') {
      row.BEF_CST_AMT = tagMaster.GNK;  // 訂正前原価
      row.BEF_SEL_AMT = tagMaster.BAK;  // 訂正前売価
      row.AFT_CST_AMT = tagMaster.GNK;  // 訂正後原価
      row.AFT_SEL_AMT = tagMaster.BAK;  // 訂正後売価
    } else {
      row.BEF_CST_AMT = '0';  // 訂正前原価
      row.BEF_SEL_AMT = '0';  // 訂正前売価
      row.AFT_CST_AMT = '0';  // 訂正後原価
      row.AFT_SEL_AMT = '0';  // 訂正後売価
    }

    row.TAGASTCDE = tagMaster.ASTCDE;   // タグＡＵ
    row.TAGLINCDE = tagMaster.LINCDE;   // タグライン
    row.TAGCLSCDE = tagMaster.CLSCDE;   // タグクラス
    row.TAGSZN = tagMaster.TAGSZN;      // タグシーズン
    row.TAGITM = tagMaster.TAGITM;      // タグアイテム
    row.TRIHBN = tagMaster.TRIHBN;      // 取引先品番
  }

  // データセット（2段）2
  function setRow_Two2(row, shohinMaster) {
    row.SHNMEIKN = shohinMaster.SHNMEIKN;    // 商品名
    row.KIKAKUKN = shohinMaster.KIKAKUKN;    // 規格
    setRowSuu(row, '1');                     // 数量
    row.GNKKIN = 0;                          // 原価金額
    row.BAKKIN = 0;                          // 売価金額
    //row.JANCDE2 = inJAN2.current.value;      // ＪＡＮコード２

    const denKbn = headerItem.DENKBN.padStart(2, '0'); // 伝票区分コード入力値の前0詰め
    if(denKbn === '05' || denKbn === '06') {
      row.BEF_CST_AMT = shohinMaster.GNT;  // 訂正前原価
      row.BEF_SEL_AMT = shohinMaster.BTN;  // 訂正前売価
      row.AFT_CST_AMT = shohinMaster.GNT;  // 訂正後原価
      row.AFT_SEL_AMT = shohinMaster.BTN;  // 訂正後売価
    } else {
      row.BEF_CST_AMT = '0';  // 訂正前原価
      row.BEF_SEL_AMT = '0';  // 訂正前売価
      row.AFT_CST_AMT = '0';  // 訂正後原価
      row.AFT_SEL_AMT = '0';  // 訂正後売価
    }

    row.TAGASTCDE = shohinMaster.ASTCDE;     // タグＡＵ
    row.TAGLINCDE = shohinMaster.LINCDE;     // タグライン
    row.TAGCLSCDE = shohinMaster.CLSCDE;     // タグクラス
    row.TAGSZN = shohinMaster.TAGSZN;        // タグシーズン
    row.TRIHBN = shohinMaster.TRIHBN;        // 取引先品番
    row.SIZCDE = shohinMaster.TAGSIZCDE;     // サイズコード
    row.TAGSIZMEI = shohinMaster.TAGSIZMEI;  // サイズ名カナ
    row.TAGCOLCDE = shohinMaster.TAGCOLCDE;  // カラーコード
    row.TAGCOLMEI = shohinMaster.TAGCOLMEI;  // カラー名カナ
  }

  // ヘッダ部２入力　ＪＡＮ入力後チェック
  const onKeyDown_JANCheck = async (row, inputNxRef) => {
    setDisRow(false);
    // ＪＡＮ２処理　内　ＪＡＮ入力後チェック
    const denKbn = headerItem.DENKBN.padStart(2, '0'); // 伝票区分コード入力値の前0詰め

    // (1) 関係チェック１
    // 売単価 ＞ 原単価 × 10 本体と税込みがある
    if(row.BTN > row.GNT * 10) {
      if(window.confirm('売単価が原単価に比べて大きすぎます。よろしいですか？')){ //TCGC0005：
        inputNxRef.current.focus();
        return;
      }else{
        setGNTclr('#f59d9d');
        inGNT.current.focus();
        return;
      }
    }

    // (2) 関係チェック２
    // 売単価 ＜ 原単価 ÷ 10 本体と税込みがある
    if(row.BTN < row.GNT / 10) {
      if(window.confirm('売単価が原単価に比べて小さすぎます。よろしいですか？')){ //TCGC0006：
        inputNxRef.current.focus();
        return;
      }else{
        setGNTclr('#f59d9d');
        inGNT.current.focus();
        return;
      }
    }

    // (3)訂正後価格チェック
    console.log('訂正後価格チェック');
    // 旧売価(訂正前売価)
    if(row.JANCDE2 !== ''){
      row.BEF_SEL_AMT = Number(row.JANCDE2.substring(6, 12));
    }

    console.log('商談書：原価率:'+syodanData.GNKRTU);
    console.log('商談書：売価率:'+syodanData.BAKRTU);
    console.log('商談書：差引原価:'+syodanData.SPKGNK);
    console.log('商談書：差引売価:'+syodanData.SPKBAK);
    if(denKbn === '05'){

      // 金額、％指定 無し
      if(Number(syodanData.SPKBAK) === 0  // 差引売価
      && Number(syodanData.SPKGNK) === 0  // 差引原価
      && Number(syodanData.BAKRTU) === 0  // 売価率
      && Number(syodanData.GNKRTU) === 0  // 原価率
      ){
        row.GNT = 0;        // 原単価
        row.GNT2 = '';      // 原単価2
        setRowBtn(row, 0);  // 売単価
        row.AFT_CST_AMT = row.BEF_CST_AMT;    // 訂正後原価
        row.AFT_SEL_AMT = row.BEF_SEL_AMT;    // 訂正後売価

        console.log('旧原価:'+row.BEF_CST_AMT);
        console.log('旧売価:'+row.BEF_SEL_AMT);
        console.log('新原価:'+row.AFT_CST_AMT);
        console.log('新売価:'+row.AFT_SEL_AMT);
      }

      // 金額指定
      else if(Number(syodanData.SPKGNK) > 0  // 差引原価
      ){
        row.AFT_CST_AMT = Number(row.BEF_CST_AMT) - Number(syodanData.SPKGNK);           // 訂正後原価
        setRowGnt(row, syodanData.SPKGNK);           // 原単価
        setRowBtn(row, 0);  // 売単価
        row.AFT_SEL_AMT = row.BEF_SEL_AMT;   // 訂正後売価

        console.log('旧原価:'+row.BEF_CST_AMT);
        console.log('旧売価:'+row.BEF_SEL_AMT);
        console.log('新原価:'+row.AFT_CST_AMT);
        console.log('新売価:'+row.AFT_SEL_AMT);

        //  旧原価＜=(C) 差引原価 の場合
        if(Number(row.BEF_CST_AMT) <= Number(syodanData.SPKGNK)){
          window.alert('商談書データから算出した新原価が０以下になりました。'); //TCGE0051：
          //row.BTN = 0;     // 売単価
          row.GNT = 0;     // 原単価
          //row.BTN2 = '';   // 売単価2
          row.GNT2 = '';   // 原単価2
          setRowItem(row);
          inSUU.current.focus();
          //setTimeout(() => {inSUU.current.select();},100);
          onNextFocus(inSUU);
          return;
        }
      }

      // ％指定（掛率）
      else if(Number(syodanData.GNKRTU) > 0  // 原価率
      ){
        // 新原価
        let new_genka = Number(row.BEF_SEL_AMT) * Number(syodanData.GNKRTU) / 100;
        if(row.IRYO_FLG === '1'){
          new_genka = Math.floor(new_genka);
        } else {
          new_genka = Math.floor(new_genka * 100) / 100;
        }
        setRowGnt(row, (Number(row.BEF_CST_AMT) - new_genka));  // 原単価
        setRowBtn(row, 0);    // 売単価
        row.AFT_CST_AMT = new_genka;         // 訂正後原価
        row.AFT_SEL_AMT = row.BEF_SEL_AMT;   // 訂正後売価

        console.log('旧原価:'+row.BEF_CST_AMT);
        console.log('旧売価:'+row.BEF_SEL_AMT);
        console.log('新原価:'+row.AFT_CST_AMT);
        console.log('新売価:'+row.AFT_SEL_AMT);
        // エラーチェック
        if(Number(row.BEF_CST_AMT) <= new_genka){
          window.alert('商談書データから算出した原単価が０以下になりました。'); //TCGE0054：
          //row.BTN = 0;     // 売単価
          row.GNT = 0;     // 原単価
          //row.BTN2 = '';   // 売単価2
          row.GNT2 = '';   // 原単価2
          setRowItem(row);
          inSUU.current.focus();
          //setTimeout(() => {inSUU.current.select();},100);
          onNextFocus(inSUU);
          return;
        }
      }
      setRowItem(row);
    }

    if(denKbn === '06'){
      // 金額、％指定 無し
      if(Number(syodanData.SPKBAK) === 0  // 差引売価
      && Number(syodanData.SPKGNK) === 0  // 差引原価
      && Number(syodanData.BAKRTU) === 0  // 売価率
      && Number(syodanData.GNKRTU) === 0  // 原価率
      ){
        row.BTN = 0;     // 売単価
        row.GNT = 0;     // 原単価
        row.BTN2 = '';   // 売単価2
        row.GNT2 = '';   // 原単価2
        row.AFT_CST_AMT = row.BEF_CST_AMT;   // 訂正後原価
        row.AFT_SEL_AMT = row.BEF_SEL_AMT;   // 訂正後売価

        console.log('旧原価:'+row.BEF_CST_AMT);
        console.log('旧売価:'+row.BEF_SEL_AMT);
        console.log('新原価:'+row.AFT_CST_AMT);
        console.log('新売価:'+row.AFT_SEL_AMT);
      }

      // 金額指定
      else if(Number(syodanData.SPKBAK) > 0   // 差引売価
      && Number(syodanData.SPKGNK) > 0        // 差引原価
      ){

        row.AFT_CST_AMT = Number(row.BEF_CST_AMT) - Number(syodanData.SPKGNK);          // 訂正後原価
        row.AFT_SEL_AMT = Number(row.BEF_SEL_AMT) - Number(syodanData.SPKBAK);          // 訂正後売価

        setRowGnt(row, syodanData.SPKGNK);           // 原単価
        setRowBtn(row, syodanData.SPKBAK);           // 売単価

        console.log('旧原価:'+row.BEF_CST_AMT);
        console.log('旧売価:'+row.BEF_SEL_AMT);
        console.log('新原価:'+row.AFT_CST_AMT);
        console.log('新売価:'+row.AFT_SEL_AMT);

        // 旧原価＜=(C) 差引原価　かつ　旧売価＜=(C) 差引売価の場合
        if(Number(row.BEF_CST_AMT) <= Number(syodanData.SPKGNK)
          && Number(row.BEF_SEL_AMT) <= Number(syodanData.SPKBAK)){
          window.alert('商談書データから算出した新原価と新売価が０以下になりました。'); //TCGE0053：
          row.BTN = 0;     // 売単価
          row.GNT = 0;     // 原単価
          row.BTN2 = '';   // 売単価2
          row.GNT2 = '';   // 原単価2
          setRowItem(row);
          inSUU.current.focus();
          //setTimeout(() => {inSUU.current.select();},100);
          onNextFocus(inSUU);
          return;
        }

        // 旧原価＜=(C) 差引原価 の場合
        if(Number(row.BEF_CST_AMT) <= Number(syodanData.SPKGNK)){
          window.alert('商談書データから算出した新原価が０以下になりました。'); //TCGE0051：
          //row.BTN = 0;     // 売単価
          row.GNT = 0;     // 原単価
          //row.BTN2 = '';   // 売単価2
          row.GNT2 = '';   // 原単価2
          setRowItem(row);
          inSUU.current.focus();
          //setTimeout(() => {inSUU.current.select();},100);
          onNextFocus(inSUU);
          return;
        }

        // 旧売価＜=(C) 差引売価 の場合
        if(Number(row.BEF_SEL_AMT) <= Number(syodanData.SPKBAK)){
          window.alert('商談書データから算出した新売価が０以下になりました。'); //TCGE0052：
          row.BTN = 0;     // 売単価
          //row.GNT = 0;     // 原単価
          row.BTN2 = '';   // 売単価2
          //row.GNT2 = '';   // 原単価2
          setRowItem(row);
          inSUU.current.focus();
          //setTimeout(() => {inSUU.current.select();},100);
          onNextFocus(inSUU);
          return;
        }
      }

      // ％指定（掛率）
      else if(Number(syodanData.BAKRTU) > 0   // 売価率
      && Number(syodanData.GNKRTU) > 0        // 原価率
      ){
        // 新売価
        let new_baika = Number(row.BEF_SEL_AMT) * Number(syodanData.BAKRTU) / 100;

        // 新原価
        let new_genka = new_baika * Number(syodanData.GNKRTU) / 100;
        if(row.IRYO_FLG === '1'){
          new_genka = Math.floor(new_genka);
        } else {
          new_genka = Math.floor(new_genka * 100) / 100;
        }

        row.AFT_CST_AMT = new_genka;          // 訂正後原価
        row.AFT_SEL_AMT = new_baika;          // 訂正後売価
        setRowGnt(row, (Number(row.BEF_CST_AMT) - new_genka));           // 原単価
        setRowBtn(row, (Number(row.BEF_SEL_AMT) - new_baika));           // 売単価

        console.log('旧原価:'+row.BEF_CST_AMT);
        console.log('旧売価:'+row.BEF_SEL_AMT);
        console.log('新原価:'+row.AFT_CST_AMT);
        console.log('新売価:'+row.AFT_SEL_AMT);

        // エラーチェック
        if(Number(row.BEF_CST_AMT) <= new_genka){
          window.alert('商談書データから算出した原単価が０以下になりました。'); //TCGE0054：
          //row.BTN = 0;     // 売単価
          row.GNT = 0;     // 原単価
          //row.BTN2 = '';   // 売単価2
          row.GNT2 = '';   // 原単価2
          setRowItem(row);
          inSUU.current.focus();
          //setTimeout(() => {inSUU.current.select();},100);
          onNextFocus(inSUU);
          return;
        }
      }
      setRowItem(row);
    }

    //次の項目に遷移
    console.log('次の項目'+inputNxRef.current.id);
    inputNxRef.current.focus();
    //inSUU.target.select();
  }

  // ヘッダ部２入力 数量
  const onKeyDown_SUU = async(inputRef, inputNxRef) => {
    if(rowItem.SUU === 0 || rowItem.SUU2 === '' || isNaN(rowItem.SUU)) {
      setSUUclr('#f59d9d');
      window.alert('数量に０は入力できません。'); //TCGE0063：
      return;
    }
    //次の項目に遷移
    inputNxRef.current.focus();
    //inputNxRef.target.select();
  }

  // ヘッダ部２入力　原単価
  const onKeyDown_GNT =  async (inputRef, inputNxRef) => {
    if(rowItem.SUU === 0 || rowItem.SUU2 === '' || isNaN(rowItem.SUU)) {
      setSUUclr('#f59d9d');
      window.alert('数量に０は入力できません。'); //TCGE0063：
      return;
    }
    // (1) 数値チェック
    if(rowItem.GNT === 0 || isNaN(rowItem.GNT)) {
      setGNTclr('#f59d9d');
      window.alert('原単価に０円は入力できません。'); //TCGE0049：
      return;
    }

    //  四捨五入
    let gnkkin = rowItem.SUU * rowItem.GNT;
    gnkkin = Math.round(gnkkin);

    // (2) 1千万越えチェック
    if(gnkkin > 10000000){
      setGNTclr('#f59d9d');
      setSUUclr('#f59d9d');
      inSUU.current.focus();
      window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
      return;
    }

    // (3) 100万越えチェック
    if(gnkkin > 1000000){
      if(!(window.confirm('１００万円を超える金額が入力されました。'))){  //TTCGC0004：
        setGNTclr('#f59d9d');
        return;
      }
    }

    // (4) 伝票区分='05' OR 伝票区分='06'
    const denKbn = headerItem.DENKBN.padStart(2, '0'); // 伝票区分コード入力値の前0詰め
    if(denKbn ==='05' || denKbn ==='06'){
      //旧原価＜=画面.原単価
      if(Number(rowItem.BEF_CST_AMT) <= rowItem.GNT){
        setGNTclr('#f59d9d');
        window.alert('新原価が０以下になる原単価は入力できません。（旧原価－原単価＜＝０）'); //TCGE0055：
        return;
      }
    }

    //次の項目に遷移
    inputNxRef.current.focus();
  }

  // ヘッダ部２入力　売単価
  const onKeyDown_BTN =  async (inputRef, inputNxRef) => {
    const denKbn = headerItem.DENKBN.padStart(2, '0'); // 伝票区分コード入力値の前0詰め

    const header = await JSON.parse(JSON.stringify(headerItem));

    // (4) 伝票区分='05'
    if(denKbn ==='05') {
      if(rowItem.BTN !== 0) {
        setBTNclr('#f59d9d');
        window.alert('売単価は０以外入力できません。'); //TCGE0056：
        return;
      } else {
        //次の項目に遷移
        inputNxRef.current.focus();
        return;
      }
    }

    // (1) 数値チェック
    if(header.GNKKNRFLG === '0' && rowItem.BTN === 0){
      if(!window.confirm('売単価に０円が入力されています。よろしいですか？')){  //TCGC0007：
        setBTNclr('#f59d9d');
        return;
      } else {
        inputNxRef.current.focus();
        return;
      }
    }

    // 追加(2) 売単価 ＞ 原単価×10　チェック
    if (rowItem.BTN > (rowItem.GNT * 10)) {
      if(!window.confirm('売単価が原単価に比べて大きすぎます。よろしいですか？')){  //TCGC0008：
        setBTNclr('#f59d9d');
        return;
      }
    };

    // 追加(3) 売単価 ＜ 原単価÷10 チェック
    if (rowItem.BTN < (rowItem.GNT / 10)) {
      if(!window.confirm('売単価が原単価に比べて小さすぎます。よろしいですか？')){  //TCGC0009：
        setBTNclr('#f59d9d');
        return;
      }
    };

    const bakkin = rowItem.SUU * rowItem.BTN;

    // (2) 1千万越えチェック
    if(bakkin > 10000000){
      setBTNclr('#f59d9d');
      setSUUclr('#f59d9d');
      inSUU.current.focus();
      window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
      return;
    }

    // (3) 100万越えチェック
    if(bakkin > 1000000){
      if(!(window.confirm('１００万円を超える金額が入力されました。'))){  //TTCGC0004：
        setBTNclr('#f59d9d');
        return;
      }
    }

    // (4) 伝票区分='06'
    if(denKbn ==='06' && Number(rowItem.BEF_SEL_AMT) <= rowItem.BTN){
      setBTNclr('#f59d9d');
      window.alert('新売価が０以下になる売単価は入力できません。（旧売価－売単価＜＝０）'); //TCGE0057：
      return;
    }

    //次の項目に遷移
    inputNxRef.current.focus();
  }

  // ヘッダ部２入力　譲渡日
  const onKeyDown_JYOTOYMD =  async () => {
    let dt1 = new Date(headerItem.HZK2);  // ヘッダ年月日
    let dt2 = new Date('2023/10/01');   // インボイス開始日（2023/10/01）
    if (dt1 >= dt2){
      // 必須チェック
      if(rowItem.JYOTOYMD2 ==='') {
        setJYOTOYMDclr('#f59d9d');
        window.alert('譲渡日を入力して下さい。');
        return;
      }
    }

    if(rowItem.JYOTOYMD2 !==''){
      // 日付チェック
      if(ckDate(rowItem.JYOTOYMD2)===false){
        setJYOTOYMDclr('#f59d9d');
        window.alert('TCGE0016:日付入力エラー');
        return;
      }

      // 2023/10/16 システム日付からヘッダ１の日付に変更
      // システム日－7年 ≦入力日（西暦）＜ヘッダ１.日付の日付範囲チェック
      let indate = new Date(rowItem.JYOTOYMD2);
      let maxdate = new Date(headerItem.HZK2);
      let mindate = new Date(sdate);
      mindate.setFullYear(mindate.getFullYear()-7);
      //console.log('日付範囲チェック'+maxdate+'>='+indate+'&&'+mindate+'<='+indate);
      if (indate > maxdate ||  mindate > indate) {
        setJYOTOYMDclr('#f59d9d');
        window.alert('TCGE0016:日付入力エラー');
        return;
      }
    }
    Header2_confirmed();
  }

  // ヘッダ部２入力　ヘッダ部２確定時
  const Header2_confirmed = async () => {
    // rowItemをコピーして使用
    const row = JSON.parse(JSON.stringify(rowItem));
    const header = JSON.parse(JSON.stringify(headerItem));

    // ＪＡＮ１必須チェック
    if(row.JANCDE === '') {
      setJAN1clr('#f59d9d');
      window.alert('ＪＡＮ１を入力して下さい。');
      inJAN1.current.focus();
      return;
    }

    // 計算
    row.GNKKIN = Math.round(row.SUU * row.GNT); // 原価金額
    row.BAKKIN = row.SUU * row.BTN;             // 売価金額
    row.AFT_CST_AMT = row.BEF_CST_AMT - row.GNT;
    row.AFT_SEL_AMT = row.BEF_SEL_AMT - row.BTN;
    if (!(header.DENKBN==='05' || header.DENKBN==='06')) {
      row.AFT_CST_AMT = 0;
      row.BEF_CST_AMT = 0;
      row.AFT_SEL_AMT = 0;
      row.BEF_SEL_AMT = 0;
    }

    // 入力チェック 数量
    if(row.SUU === 0 || row.SUU2 === '') {
      setSUUclr('#f59d9d');
      window.alert('数量に０は入力できません。'); //TCGE0063：
      inSUU.current.focus();
      return;
    }

    // 入力チェック 原単価
    if(row.GNT === 0 || row.GNT2 === '') {
      setGNTclr('#f59d9d');
      window.alert('原単価に０円は入力できません。'); //TCGE0049：
      inGNT.current.focus();
      return;
    }
    // 1千万越えチェック
    if(row.GNKKIN > 10000000){
      setGNTclr('#f59d9d');
      setSUUclr('#f59d9d');
      inSUU.current.focus();
      window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
      return;
    }

    if(header.DENKBN==='05' || header.DENKBN==='06'){
      //旧原価＜=画面.原単価
      if(Number(row.BEF_CST_AMT) <= row.GNT){
        setGNTclr('#f59d9d');
        window.alert('新原価が０以下になる原単価は入力できません。（旧原価－原単価＜＝０）'); //TCGE0055：
        inGNT.current.focus();
        return;
      }
    }

    // 入力チェック 売単価
    if(header.DENKBN ==='05') {
      if(rowItem.BTN !== 0) {
        setBTNclr('#f59d9d');
        window.alert('売単価は０以外入力できません。'); //TCGE0056：
        inBTN.current.focus();
        return;
      }
    }else{
      if(!(header.GNKKNRFLG === '0' && (row.BTN === 0 || row.BTN2 === ''))){
        // 1千万越えチェック
        if(row.BAKKIN > 10000000){
          setBTNclr('#f59d9d');
          setSUUclr('#f59d9d');
          window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
          inBTN.current.focus();
          return;
        }
        if(header.DENKBN  ==='06' && Number(row.BEF_SEL_AMT) <= row.BTN){
          setBTNclr('#f59d9d');
          window.alert('新売価が０以下になる売単価は入力できません。（旧売価－売単価＜＝０）'); //TCGE0057：
          inBTN.current.focus();
          return;
        }
      }
    }

    // 新規追加時の処理
    if(row.id === rows.length){
      // 入力内容の重複確認
      let new_rows = JSON.parse(JSON.stringify(rows));
      const old_row = new_rows.filter(element => {
        return element.JANCDE === row.JANCDE
        && element.GNT === row.GNT
        && element.BTN === row.BTN
      });

      // 既に入力済の内容であれば合算値を確認する
      if (old_row.length !== 0){
        // 合計数量計算
        let totalSuu = row.SUU;
        for(const ckRow of old_row) {
          totalSuu = totalSuu + ckRow.SUU;
        }

        // 原価金額合計 金額オーバーチェック
        let ckGnkkin = Math.round(totalSuu * row.GNT);
        if(ckGnkkin > 10000000){
          setGNTclr('#f59d9d');
          window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
          inSUU.current.focus();
          return;
        }

        // 売価金額合計 金額オーバーチェック
        let ckBakkin = totalSuu * row.BTN;
        if(ckBakkin > 10000000){
          setBTNclr('#f59d9d');
          window.alert('合計で１千万円を超える金額は入力できません。'); //TCGE0060：
          inSUU.current.focus();
          return;
        }
      }

      // 明細リストの合計を再計算する。
      header.SRYFOT = Number(header.SRYFOT) + row.SUU;
      header.GNKFOT = Number(header.GNKFOT) + Number(row.GNKKIN);
      header.BAKFOT = Number(header.BAKFOT) + Number(row.BAKKIN);
      header.SIRCDE2 = '';
      header.SIRMEIK2 = '';
      header.ZEIRTU2 = '';

      // 編集中データを反映する。
      // 新規ページを用意する
      let new_row = await JSON.parse(JSON.stringify(rowData));
      new_row.id = rows.length + 1;
      new_rows = await new_rows.map((target, index) => (target.id === row.id ? row :target));
      setRowItem(new_row);
      setRows(() => ([...new_rows, new_row]));

      setHeaderItem(header);
      inJAN1.current.focus();
    }
    // 編集時の処理
    else{
      // 新しく保存する明細リスト
      let new_rows = JSON.parse(JSON.stringify(rows));
      new_rows = await new_rows.map((target) => (target.id === row.id ? row : target));

      // 入力内容の重複確認
      const old_row = new_rows.filter(element => {
        return element.JANCDE === row.JANCDE
        && element.GNT === row.GNT
        && element.BTN === row.BTN
      });

      // 同じ値のデータ合算値を確認する
      if (old_row.length !== 0){
        // 合計数量計算
        let totalSuu = 0;
        for(const ckRow of old_row) {
          totalSuu = totalSuu + ckRow.SUU;
        }

        // 原価金額合計 金額オーバーチェック
        let ckGnkkin = Math.round(totalSuu * row.GNT);
        if(ckGnkkin > 10000000){
          setGNTclr('#f59d9d');
          window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
          inSUU.current.focus();
          return;
        }

        // 売価金額合計 金額オーバーチェック
        let ckBakkin = totalSuu * row.BTN;
        if(ckBakkin > 10000000){
          setBTNclr('#f59d9d');
          window.alert('合計で１千万円を超える金額は入力できません。'); //TCGE0060：
          inSUU.current.focus();
          return;
        }
      }

      // 明細リストの合計を再計算する。
      let totalSuryo = 0;   // 合計数量
      let totalGenka = 0;   // 原価金額合計
      let totalBaika = 0;   // 売価金額合計

      new_rows.forEach(element => {
        totalSuryo += element.SUU;
        totalGenka += Number(element.GNKKIN);
        totalBaika += Number(element.BAKKIN);
      });

      header.SRYFOT = totalSuryo;
      header.GNKFOT = totalGenka;
      header.BAKFOT = totalBaika;
      header.SIRCDE2 = '';
      header.SIRMEIK2 = '';
      header.ZEIRTU2 = '';

      // 編集中データを反映する。
      setRows(new_rows);
      setRowItem(row);
      setHeaderItem(header);
      inJAN1.current.focus();
    }
  }

  // 仕入先名称取得
  const getSirmeik =  async (sircode,header,lflg) => {
    //header.SIRCDE2 = sircode ?? '';
    const getSirmeik = await fetch('/TCGT0100/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:sircode})});
    const sirmeik = await getSirmeik.json();
    let ret_sircde = '0';
    if(sirmeik !== null) {
      if (lflg === 1)
      {
        header.SIRCDE = sircode;
        header.SIRMEIK = sirmeik.SIRMEIK;
        header.ZEIRTU = sirmeik.ZEIRTU;
      }else
      {
        header.SIRCDE2 = sircode;
        header.SIRMEIK2 = sirmeik.SIRMEIK;
        header.ZEIRTU2 = sirmeik.ZEIRTU;
        if (header.SIRCDE === '' || header.SIRCDE === undefined){
          header.SIRCDE = sircode;
          header.SIRMEIK = sirmeik.SIRMEIK;
          header.ZEIRTU = sirmeik.ZEIRTU;
          ret_sircde = sircode;
        }
      }
    }else{
      ret_sircde = '-1';
    }
    await setHeaderItem(header);
    return ret_sircde;
  }

  // フッタ部　前商品
  const onClick_PreviousPage = async () => {
    // データが編集中か確認する。
    const now_rows = rows.filter(element => {
      return element.id === rowItem.id;
    });
    // if(now_rows[0] !== rowItem){
    //   if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))){  //TCGM0007：
    //     return;
    //   }
    // }
    let flg = 0;
    Object.keys(now_rows[0]).map((key)=>{
      if(now_rows[0][key] !== rowItem[key]){
        flg++;
      }
    });
    if(flg > 0){
      if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))){  //TCGM0007：
        return;
      }
    }

    // 移動先データを探す
    const target_id = rowItem.id - 1;
    const target_rows = rows.filter(element => {
      return element.id === target_id;
    });
    if(target_rows.length > 0){
      // 対象があれば移動する
      setDisRow(false);
      setJan1(true);
      setJan2(true);
      await setRowItem(target_rows[0]);
    }
    inSUU.current.focus();
  }

  // フッタ部　次商品
  const onClick_NextPage = async () => {
    // データが編集中か確認する。
    const now_rows = rows.filter(element => {
      return element.id === rowItem.id;
    });
    // if(now_rows[0] !== rowItem){
    //   if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))){  //TCGM0007：
    //     return;
    //   }
    // }
    let flg = 0;
    Object.keys(now_rows[0]).map((key)=>{
      if(now_rows[0][key] !== rowItem[key]){
        flg++;
      }
    });
    if(flg > 0){
      if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))){  //TCGM0007：
        return;
      }
    }

    // 移動先データを探す
    const target_id = rowItem.id + 1;
    const target_rows = rows.filter(element => {
      return element.id === target_id;
    });
    if(target_rows.length > 0){
      // 対象があれば移動する
      await setRowItem(target_rows[0]);
      if (target_rows[0].JANCDE === '')
      {
        setDisRow(true);
        setJan1(false);
        setJan2(true);
        setTimeout(() => {inJAN1.current.focus();},100);
      }else{
        setDisRow(false);
        setJan1(true);
        setJan2(true);
        setTimeout(() => {inSUU.current.focus();},100);
      }
    }else{
      setDisRow(true);
      setJan1(false);
      setJan2(true);
      setTimeout(() => {inJAN1.current.focus();},100);
    }

    // inJAN1.current.focus();
  }

  // フッタ部　最後尾
  const onClick_LastPage = async () => {
    // データが編集中か確認する。
    const now_rows = rows.filter(element => {
      return element.id === rowItem.id;
    });
    // if(now_rows[0] !== rowItem){
    //   if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))){  //TCGM0007：
    //     return;
    //   }
    // }
    let flg = 0;
    Object.keys(now_rows[0]).map((key)=>{
      if(now_rows[0][key] !== rowItem[key]){
        flg++;
      }
    });
    if(flg > 0){
      if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))){  //TCGM0007：
        return;
      }
    }

    // 移動先データを探す
    const target_id = rows.length;
    const target_row = rows.filter(element => {
      return element.id === target_id;
    });
    if(target_row.length > 0){
      // 対象があれば移動する
      setDisRow(true);
      setJan1(false);
      setJan2(true);
      await setRowItem(target_row[0]);
    }
    inJAN1.current.focus();
  }

  // 23/09/25　onClick処理追加
  // フッタ部　戻る
  const onClick_BackPage = async () => {
    if(rows.length !== 1 || inJAN1.current.value !== '')
    {
      if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))) { //TCGM0007：
        return;
      }
    }
    setHeaderReset2();
    setRowItem(rowData);
    setRows([]);
    setScreenPhase(1)
  }

  // フッタ部　再入力
  const onClick_ReEnterPage = async () => {
    if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))) { //TCGM0007：
      return;
    }

    setChkJAN1('0');
    setChkJAN2('0');
    const target_id = rowItem.id;

    // 最終ページに移動する。
    const target_row = rows.filter(element => {
      return element.id === target_id;
    });
    console.log('削除情報:%o',target_row[0]);
    await setRowItem(target_row[0]);
    inJAN1.current.focus();
  }

  // フッタ部　削除
  const onClick_DeletePage = async () => {
    const target_id = rowItem.id;

    if(rows.length === rowItem.id) {
      if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))) { //TCGM0007：
        return;
      }else{
        // 最終ページに移動する。
        const target_row = rows.filter(element => {
          return element.id === target_id;
        });
        await setRowItem(target_row[0]);
        setJan1(false);
        setJan2(true);
        setTimeout(() => {inJAN1.current.focus();},100);
        return;
      }
    } else{
      // 削除除処理を行うか確認メッセージを表示する
      const del_row = rows.filter(element => {
        return element.id === target_id;
      });
      if (!(window.confirm(''+ del_row[0].SHNMEIKN +'を削除します。よろしいですか？'))) { //TCGM0008：
        return;
      }
    }

    // 指定行を取り除く
    const target_rows = rows.filter(element => {
      return element.id !== target_id;
    });

    console.log('Hed情報:%o',headerItem);

    // 合計額再計算
    const header = JSON.parse(JSON.stringify(headerItem));
    let totalSuryo = 0;   // 合計数量
    let totalGenka = 0;   // 原価金額合計
    let totalBaika = 0;   // 売価金額合計
    let id = 1;
    target_rows.forEach(element => {
      element.id = id;
      totalSuryo += element.SUU;
      totalGenka += Number(element.GNKKIN);
      totalBaika += Number(element.BAKKIN);
      id += 1;
    });
    header.SRYFOT = totalSuryo;
    header.GNKFOT = totalGenka;
    header.BAKFOT = totalBaika;

    await setRows(target_rows);

    // 最終ページに移動する。
    const last_row = target_rows.filter(element => {
      return element.id === target_rows.length;
    });
    await setRowItem(last_row[0]);
    setJan1(false);
    setJan2(true);
    setTimeout(() => {inJAN1.current.focus();},100);

  }

  // フッタ部　登録
  const onClick_Send = async()=> {
    if ((window.confirm('データの更新を行います。よろしいですか？'))) { //TCGM0009：
      const header = JSON.parse(JSON.stringify(headerItem));
      let target_rows = JSON.parse(JSON.stringify(rows));

      // 最終ページ（新規ページ）を取り除く
      target_rows = target_rows.filter(x => x.id !== rows.length);

      // 重複行を合算する処理
      let combinedList = [];
      for(const add_row of target_rows) {
        // 入力内容の重複確認
        const matched_row = combinedList.filter(element => {
          return element.JANCDE === add_row.JANCDE
          && element.GNT === add_row.GNT
          && element.BTN === add_row.BTN
        });

        // 既に登録済の内容であれば数を合算する
        if (matched_row.length !== 0) {
          matched_row[0].SUU = matched_row[0].SUU + add_row.SUU;
          matched_row[0].GNKKIN = Math.round(matched_row[0].SUU * add_row.GNT); // 原価金額
          matched_row[0].BAKKIN = matched_row[0].SUU * add_row.BTN;             // 売価金額
          combinedList = combinedList.map((target) => (target === matched_row ? matched_row[0] : target));
        }else{
          // 登録済みで無ければ行追加
          combinedList.push(add_row);
        }
      }

      let d = new Date(state.SDAY);
      let year = d.getFullYear().toString();
      let month = (d.getMonth() + 1).toString().padStart(2, '0');
      let day = d.getDate().toString().padStart(2, '0');
      let hours = d.getHours().toString().padStart(2, '0')
      let minutes = d.getMinutes().toString().padStart(2, '0')
      let seconds = d.getSeconds().toString().padStart(2, '0')
      header.DENYMD = year + month + day;
      header.IN_DATE = year + month + day + hours + minutes + seconds;

      // SEQの取得
      const getSeqNo = await fetch('/TCGT0100/getSeqNo',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({header:header})});
      const resseqno = await getSeqNo.json();

      // 登録用のボディデータ作成
      const updateHeder = [];
      const updateRows = [];
      let pushRowlist = [];
      let denno = await getNowDenno(header);
      let hederSEQ = Number(resseqno.SEQ) + 1;
      let checkGenka = 0;
      let checkBaika = 0;
      let count = 1;
      // 10/13 登録時のバグ修正
      //await Promise.all(target_rows.map(async(row) =>{
      for(const row of combinedList){
        console.log('row:%o',row);
        if(count > 6
            || checkGenka + Number(row.GNKKIN) > 10000000
            || checkBaika + Number(row.BAKKIN) > 10000000
        ) {
          // 6件、もしくは合計原価、合計売価が1千万を超える場合とSEQ番号を変更する。
          updateRows.push(pushRowlist);
          pushRowlist = [];
          count = 1;
          checkGenka = 0;
          checkBaika = 0;
          denno = await getNowDenno(header);
        }
        row.REC_NO = count;
        row.SEQ = hederSEQ;
        row.DENNO = denno;
        row.BMNCDE = header.BMNCDE;
        row.DENYMD = header.DENYMD;
        row.DEN_EDABAN = header.DEN_EDABAN;
        row.JGNCDE = header.JGNCDE;
        row.PRG_ID = header.PRG_ID;

        checkGenka += Number(row.GNKKIN);
        checkBaika += Number(row.BAKKIN);
        pushRowlist.push(row);
        count++;
      }
      //}));
      updateRows.push(pushRowlist);

      // 登録用のヘッダデータ作成
      updateRows.forEach(rowsList => {
        const pushHeder = JSON.parse(JSON.stringify(header));
        pushHeder.SEQ = rowsList[0].SEQ;
        pushHeder.DENNO = rowsList[0].DENNO;

        // 伝票区分名の再取得
        pushHeder.DENKBNNM = retDenKbn(pushHeder.DENKBN);

        let totalSuryo = 0;   // 合計数量
        let totalGenka = 0;   // 原価金額合計
        let totalBaika = 0;   // 売価金額合計
        rowsList.forEach(row => {
          totalSuryo += row.SUU;
          totalGenka += Number(row.GNKKIN);
          totalBaika += Number(row.BAKKIN);
        });
        pushHeder.SRYFOT = totalSuryo;
        pushHeder.GNKFOT = totalGenka;
        pushHeder.BAKFOT = totalBaika;
        updateHeder.push(pushHeder);
      })

      // データの更新
      const response = await fetch('/TCGT0100/send',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
        header:updateHeder,
        rows:updateRows,
      })});

      const data = await response.json();
      if (data.res === -1) {
        window.alert('伝票データ修正エラー'); //TCGE0024：
        return;
      }else{
        const header2 = headerData;
        header2.BMNCDE = header.BMNCDE;
        header2.BMNMEI = header.BMNMEI;
        header2.HZK = header.HZK;
        header2.HZK2 = header.HZK2;
        header2.DENKBN = header.DENKBN;
        header2.DENKBNNM = header.DENKBNNM;
        header2.RYUNO = header.RYUNO;
        header2.RYUMEI = header.RYUMEI;
        header2.SYODAN = header.SYODAN;
        await setHeaderItem(header2);
        const row2 = rowData;
        row2.id = 1;
        setRowItem(row2);
        setRows([row2]);

        inJAN1.current.focus();
        return;
      }
    } else {
      inJAN1.current.focus();
    }
  }

  const getNowDenno = async(header) => {
    // 採番マスタ取得
    let denpyoNo = {
      KSACDE:header.KSACDE,     // 会社コード
      MISE:header.MISE,         // 店コード
      MR_KBN:'2',               // 移動返品区分
      MIN_VLU:'1',              // 最小値
      MAX_VLU:'999',            // 最大値
      JGNCDE:header.JGNCDE,     // ユーザーID
      PRG_ID:header.PRG_ID,     // プログラムID
      TRL_ID:header.TRL_ID,     // 端末ＩＤ
      DELFLG:'0'                // 削除フラグ
    };

    // 採番マスタ取得
    const getDenNo = await fetch('/TCGT0100/getDenNo',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({denpyoNo:denpyoNo})});
    const resdenno = await getDenNo.json();
    const NOW_DENNO = resdenno.denno.toString().padStart(3, '0');

    // 伝票番号の取得
    let denno_A = state.MISES + NOW_DENNO;
    let denno_B = Number(denno_A) / 7;
    let denno_C = Math.floor(denno_B) * 7;
    let checkDigit = Number(denno_A) - denno_C;
    let updateDenpyoNo = denno_A + checkDigit.toString();
    return updateDenpyoNo;
  }

  return(
    <>
      <Stack direction="row" >
        <TextField  //部門名
          id={'BMNMEI'}
          size='small'
          label='部門'
          variant='outlined'
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '25ch'}}                 //textboxの幅
          value={headerItem.BMNMEI}                      //初期値
          disabled={true}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 12,style: {textAlign: 'left'} }}  //補完,最大文字数,左寄せ
        />&nbsp;
        <TextField  //伝票区分名
          id={'DENKBNNM'}
          //style={{ fontSize:'9px'}}
          // autoFocus                           //初期カーソル位置設定
          size='small'                        //textboxの全体サイズ
          label='伝票区分'                         //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '17ch'}}                 //textboxの幅
          value={headerItem.DENKBN + '：' + headerItem.DENKBNNM}               //初期値
          disabled={true}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 10,style: {textAlign: 'left'} }}  //補完,最大文字数,左寄せ
        />
      </Stack>
      <br />
      <Stack direction="row" spacing={3}>
        <PatternFormat  //日付
          id={'HZK'}
          format="####/##/##"
          mask={['Y','Y','Y','Y','M','M','D','D']}
          color='success'
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='日付'                        //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          fixedDecimalScale={false}            //小数点On/Off
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '13ch',mt:'-10px'}}                 //textboxの幅
          value={headerItem.HZK2}
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={true}                    //使用可／不可
          placeholder="YYYY/MM/DD"
          inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
        />
      </Stack>
      <br />
      <Stack direction="row" >
        <TextField  //仕入先（取引先）分名
          id={'SIRMEIK'}
          size='small'                        //textboxの全体サイズ
          label='取引先'                         //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '50ch',mt:'-10px'}}                 //textboxの幅
          value={headerItem.SIRCDE + ' ' + headerItem.SIRMEIK}               //初期値
          disabled={true}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 2,style: {textAlign: 'left'} }}  //補完,最大文字数,左寄せ
        />
      </Stack>
      <InputLabel variant={'standard'} style={{ textAlign: 'right' }}>{headerItem.ZEIRTU + '%'}</InputLabel>
      <br/>
      <Stack direction="row">
        <NumericFormat  //JANCD1
          id={'JAN1'}
          inputRef={inJAN1}                   //項目名定義？
          color='success'
          autoFocus                           //初期カーソル位置設定
          thousandSeparator={false}           //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='ＪＡＮ１'                      //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '17ch',background:inJAN1clr,mt:'-10px'}}                 //textboxの幅
          value={rowItem.JANCDE}               //初期値
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={disJan1}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          onChange={(e)=>{
            const row = JSON.parse(JSON.stringify(rowData));
            row.id = rowItem.id;
            row.JANCDE = e.target.value;
            setRowItem(row);
            setChkJAN1('0');
            setDisRow(true);

            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
          }}
          onKeyDown={(e) => onKeyDown(e.key,inJAN1,inJAN1,inSUU)}
          onFocus={(e) => {
            if (inJAN1.current !==null && typeof inJAN1.current !== 'undefined')
            {
              onMyFocus(e);
            }
          }}
          onBlur={() => onblur(inJAN1)}
          />
      </Stack>
      <Stack direction="row">
        <NumericFormat  //JANCD2
          id={'JAN2'}
          inputRef={inJAN2}                   //項目名定義？
          color='success'
          thousandSeparator={false}           //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          sx={{width: '17ch',background:inJAN2clr}}                 //textboxの幅
          value={rowItem.JANCDE2}               //初期値
          //allowLeadingZeros                   //前ゼロ表示 on
          disabled={disJan2}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          onChange={(e)=>{
            const row = JSON.parse(JSON.stringify(rowData));
            row.id = rowItem.id;
            row.JANCDE = rowItem.JANCDE;
            row.JYOTOYMD  = rowItem.JYOTOYMD;
            row.JYOTOYMD2  = rowItem.JYOTOYMD2;
            row.IN_CD_KBN = rowItem.IN_CD_KBN;
            row.IRYO_FLG = rowItem.IRYO_FLG;  //10/25
            row.EOSCDE = rowItem.EOSCDE;      //10/25
            row.JANCDE2 = e.target.value;
            setRowItem(row);
            setChkJAN2('0');
            setDisRow(true);

            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
          }}
          onKeyDown={(e) => onKeyDown(e.key,inJAN2,inJAN1,inSUU)}
          onFocus={(e) => onMyFocus(e)}
          onBlur={() => onblur(inJAN2)}
        />
      </Stack>
      <br />
      <Stack direction="row">
        <TextField  //商品名
          id={'SHNMEI'}
          inputRef={inSHNMEI}                   //項目名定義？
          color='success'
          label='商品名'                         //textboxの左上に表示するラベル
          multiline
          maxRows={3}
          variant='outlined'                  //枠の表示方法
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '50ch',mt:'-10px'}}                 //textboxの幅
          value={valueSHNMEI}               //初期値
          disabled={true}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 200,style: {textAlign: 'left'} }}  //補完,最大文字数,左寄せ
          onKeyDown={(e) => onKeyDown(e.key,inSHNMEI,inJAN1,inSUU)}
          onFocus={(e) => onMyFocus(e)}
        />
      </Stack>
      <br />
      <div style={{display:"flex"}}>
        <Grid container>
          <Grid item xs={3}>
            {/* <Paper> */}
            <Typography variant={"h6"} sx={{ m: "0px" }}>
              <NumericFormat  //取引先CD
                id={'SIRCDE2'}
                inputRef={inSIRCDE2}                   //項目名定義？
                color='success'
                thousandSeparator={false}           //1000単位の区切り
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='取引先'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                type='tel'                          //[PDA、Tablet対応]入力モード：tel
                inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
                fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
                decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '9ch',mt:'-10px'}}                 //textboxの幅
                value={headerItem.SIRCDE2}               //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={true}                    //使用可／不可
                inputProps={{ autoComplete: 'off',maxLength: 6,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
                onKeyDown={(e) => onKeyDown(e.key,inSIRCDE2,inBTN,inJYOTOYMD)}
                onFocus={(e) => onMyFocus(e)}
              />
            </Typography>
          {/* </Paper> */}
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"h6"} sx={{ m: "10px",mt:'-3px' }}>
              <InputLabel variant={'standard'}>{headerItem.SIRMEIK2}</InputLabel>
              {/* <InputLabel variant={'standard'}>{rowItem.SIRMEIK2}</InputLabel> */}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {/* <Paper>  */}
            <Typography variant={"h6"} sx={{ m: "10px",mt:'-3px' }}>
              <InputLabel variant={'standard'} style={{ textAlign: 'right' }}>{headerItem.ZEIRTU2 + '%'}</InputLabel>
              {/* <InputLabel variant={'standard'} style={{ textAlign: 'right' }}>{rowItem.ZEIRTU2 + '%'}</InputLabel> */}
            </Typography>
            {/* </Paper> */}
          </Grid>
        </Grid>
      </div>
      <br/>
      <Stack direction="row">
        <NumericFormat  //数量
          id={'SUU'}
          inputRef={inSUU}
          color='success'
          thousandSeparator={','}             //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='数量'                         //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={true}            //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={1}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '12ch',background:inSUUclr}}                 //textboxの幅
          value={rowItem.SUU2}
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={disRow}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 8,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
          isAllowed={(values) => {
            const numValue = Number(values.value);
            if (isNaN(numValue)) return true;
            return numValue >= 0 && numValue <= 99999.9;
          }}
          onValueChange={(values, sourceInfo) => {
            const row = JSON.parse(JSON.stringify(rowItem));
            row.SUU = values.floatValue||0;
            row.SUU2 = values.formattedValue||'0.0';
            setRowItem(row);
          }}
          // onChange={(e)=>{
          //   const row = JSON.parse(JSON.stringify(rowItem));
          //   // let suu = Number(e.target.value);
          //   let suu = Number(String(e.target.value||0).replaceAll(',',''));
          //   if (isNaN(suu) || e.target.value === ''){
          //     row.SUU = 0;
          //     row.SUU2 = '0.0';
          //   }
          //   else{
          //     row.SUU = suu;
          //     row.SUU2 = suu.toLocaleString(undefined,{minimumFractionDigits : 1});
          //   }
          //   setRowItem(row);
          // }}
          onKeyDown={(e) => onKeyDown(e.key,inSUU,inJAN1,inGNT)}
          onFocus={(e) => onMyFocus(e)}
        />
        &nbsp;
        <NumericFormat  //原単価
          id={'GNT'}
          inputRef={inGNT}
          color='success'
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='原単価'                         //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          thousandSeparator={','}             //1000単位の区切り
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={true}            //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={2}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '12ch',background:inGNTclr}}
          value={rowItem.GNT2}
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={disRow}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 10,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
          isAllowed={(values) => {
            const numValue = Number(values.value);
            if (isNaN(numValue)) return true;
            return numValue >= -9999999.99 && numValue <= 9999999.99;
          }}
          onValueChange={(values, sourceInfo) => {
            const row = JSON.parse(JSON.stringify(rowItem));
            row.GNT = values.floatValue||0;
            row.GNT2 = values.formattedValue||'0.00';
            setRowItem(row);
          }}
          // onChange={(e)=>{
          //   const row = JSON.parse(JSON.stringify(rowItem));
          //   // let gnt = Number(e.target.value);
          //   let gnt = Number(String(e.target.value||0).replaceAll(',',''));
          //   if (isNaN(gnt) || e.target.value === ''){
          //     row.GNT = 0;
          //     row.GNT2 = '0.00';
          //   }
          //   else{
          //     row.GNT = gnt;
          //     row.GNT2 = gnt.toLocaleString(undefined,{minimumFractionDigits : 2});
          //   }
          //   setRowItem(row);
          // }}
          onKeyDown={(e) => onKeyDown(e.key,inGNT,inSUU,inBTN)}
          onFocus={(e) => onMyFocus(e)}
        />
        &nbsp;
        <NumericFormat  //売単価
          id={'BTN'}
          inputRef={inBTN}
          color='success'
          thousandSeparator={','}             //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='売単価'                         //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '12ch',background:inBTNclr}}                 //textboxの幅
          value={rowItem.BTN2}
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={disRow}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 8,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
          isAllowed={(values) => {
            const numValue = Number(values.value);
            if (isNaN(numValue)) return true;
            return numValue >= -9999999.99 && numValue <= 9999999.99;
          }}
          onValueChange={(values, sourceInfo) => {
            const row = JSON.parse(JSON.stringify(rowItem));
            row.BTN = values.floatValue||0;
            row.BTN2 = values.formattedValue||'0';
            setRowItem(row);
          }}
          // onChange={(e)=>{
          //   const row = JSON.parse(JSON.stringify(rowItem));
          //   // let btn = Number(e.target.value);
          //   let btn = Number(String(e.target.value||0).replaceAll(',',''));
          //   if (isNaN(btn) || e.target.value === ''){
          //     row.BTN = 0;
          //     row.BTN2 = '0';
          //   }
          //   else{
          //     row.BTN = btn;
          //     row.BTN2 = btn.toLocaleString(undefined,{minimumFractionDigits : 0});
          //   }
          //   setRowItem(row);
          // }}
          onKeyDown={(e) => onKeyDown(e.key,inBTN,inGNT,inJYOTOYMD)}
          onFocus={(e) => onMyFocus(e)}
        />
      </Stack>
      <br />
      <Stack direction="row">
      <PatternFormat  //譲渡日
          id={'JYOTOYMD'}
          inputRef={inJYOTOYMD}               //項目名定義？
          format='####/##/##'
          mask={['Y','Y','Y','Y','M','M','D','D']}
          color='success'
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='譲渡日'                      //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          onValueChange={(values, sourceInfo) => {
            const row = JSON.parse(JSON.stringify(rowItem));
            row.JYOTOYMD = values.value;
            row.JYOTOYMD2 = values.formattedValue;
            setRowItem(row);
          }}
          sx={{width: '13ch',background:inJYOTOYMDclr}}              //textboxの幅
          value={rowItem.JYOTOYMD2}           //初期値
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={disRow}                    //使用可／不可
          placeholder='YYYY/MM/DD'
          inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          onKeyDown={(e) => onKeyDown(e.key,inJYOTOYMD,inBTN,inJYOTOYMD)}
          onFocus={(e) => onMyFocus(e)}
        />
      </Stack>
      <br />
      <Stack direction='row' spacing={0} mx={{mb:'5px'}}>
        <Button
          variant='contained'
          color='primary'
          sx={{
            width:80,
          }}
          disabled={rowItem.id === 1 ? true : false }                  //使用可／不可
          onClick={(e) => onClick_PreviousPage()}
        >
          {'前商品'}
        </Button>&nbsp;
        <Button
          variant='contained'
          color='primary'
          sx={{width:80}}
          disabled={rowItem.id === rows.length ? true : false }                  //使用可／不可
          onClick={(e) => onClick_NextPage()}
        >
          {'後商品'}
        </Button>&nbsp;
        <InputLabel variant={'outlined'}>{ rowItem.id + '/' + rows.length}</InputLabel>
      </Stack>
      <Stack direction='row' spacing={0}>
        <Button
          variant='contained'
          color='primary'
          sx={{width:80}}
          // onClick={(e) => setScreenPhase(1)}
          onClick={(e) => onClick_BackPage()}   // 23/09/25　onClick処理追加
        >
          {'戻る'}
        </Button>&nbsp;
        {(()=> {
          if(rowItem.id === rows.length) {
            return (
              <Button
                variant='contained'
                color='primary'
                sx={{width:80}}
                onClick={(e) => onClick_ReEnterPage()}
              >
                {'再入力'}
              </Button>
            )
          } else {
            return (
              <Button
                variant='contained'
                color='primary'
                sx={{width:80}}
                onClick={(e) => onClick_LastPage()}
              >
                {'最後尾'}
              </Button>
            )
          }
        })()}
        &nbsp;
        <Button
          variant='contained'
          color='primary'
          sx={{width:80}}
          onClick={(e) => onClick_DeletePage()}
        >
          {'削除'}
        </Button>&nbsp;
        <Button
          variant='contained'
          color='primary'
          sx={{width:80}}
          onClick={(e) => onClick_Send()}
          style={{
            display: (rows.length === 1 ? "none" : "inline-flex"),
          }}
        >
          {'登録'}
        </Button>&nbsp;
      </Stack>
    </>
  );

};

export default TCPD0142;