import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, TextField, Grid, Paper, Typography, Stack, InputLabel } from "@mui/material";
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCGT0170 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('ファンクションキーを押してください。');
  const [dismeisai, setMeisai] = useState(false);    //disabled設定（明細）
  const [sdate, setsDate] = useState([]);
  const [inp_DENYMD, setDENYMD] = useState('');       //検索入力用伝票番号
  const [inp_kkn, setkkn] = useState('');       //リスト出力と画面戻る指示分岐用
  const [upd_flg, setUpdflg] = useState('0');   //更新用フラグ

  const inDENYMD = useRef(null);
  const inRET = useRef(null);

  //項目のバックカラー制御用
  const [itDENYMDclr,setItDENYMDclr] = useState('#FFFFFF');

  const commonStyles = {
    bgcolor: 'background.paper',
    m: 1,
    border: 0,
    width: '40px',
    height: '40px',
  };

    //ファンクションキー対応
    const handleKeyDown = (event) => {
      if (event.keyCode === 112) { // F1キーが押された場合の処理
        PRINTsyori();
      }
      if (event.keyCode === 116) { // F5キーが押された場合の処理
        if (state.GAMEN == 0)
        {
          navigate('/TCSA0003');
        }else{
          navigate('/TCSA0005');
        }
      }
      if (event.keyCode === 120) { // F2キーが押された場合の処理
      }
    };
    useEventListener("keydown", handleKeyDown);
    //ファンクションキー対応
  
  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    let d = new Date(state.SDAY);
    d.setDate(d.getDate() - 1);// 2023/12/04 kameda デフォルト-1日とする
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);

    let YYMMDD = String(year).substring(2,4)+ '/' + ('0'+month).slice(-2) + '/' + ('0'+day).slice(-2);
    setDENYMD(YYMMDD);
    console.log('権限:'+state.KENGEN);
    if (state.KENGEN === '99')
    {
      setUpdflg('1');
    }

  }, []);

  const SetCheck = (CheckName,Position) =>{  // CheckName:チェック対象、Pstn：1（チェック対象項目）,2（確認）
    let ErrFlg;
    console.log('連携データ：'+CheckName+'-'+Position);
    setAlert('　'); 
    if (CheckName == 'DENYMD'){
      // 出力日チェック
      var strDate = '20'+inp_DENYMD;
      var dtdate = new Date(strDate);
      setItDENYMDclr('#FFFFFF');
      if (isNaN(dtdate.getDate())==false){
        console.log('発送日チェック1|'+strDate);
        console.log('日付範囲');
        let dt = new Date(state.SDAY);
        let dts = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate()-90); // 実行日から90日前の日付
        let dte = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate());      // 実行日の日付
        //dts.setFullYear(dts.getFullYear() - 1);
        console.log('日付'+dts+'<='+dtdate+'<='+dte);
        if (dts <= dtdate && dtdate <= dte) //実行日90日前より過去または、実行日より未来
        {
          console.log('対象件数チェックおよびPDF出力処理開始');
          ErrFlg = 0;
          console.log('エラーなし：'+ErrFlg);
        }else{                
          setItDENYMDclr('#f59d9d');
          setAlert('日付入力エラー');//TCGE0016：

          switch(Position) {
            case 1:
              ErrFlg = 1;
              break;
            case 2:
              ErrFlg = 2;
              break;
            default:
              break;
          }
          console.log('エラーあり：'+ErrFlg);
        }
      }else{
        setItDENYMDclr('#f59d9d');
        setAlert('日付入力エラー');//TCGE0016：
        switch(Position) {
          case 1:
            ErrFlg = 1;
            break;
          case 2:
            ErrFlg = 2;
            break;
          default:
            break;
        }
      }
    }

    console.log('戻り値：'+ErrFlg);
    return ErrFlg;
  };

  const PRINTsyori = async() =>{
    let retcnt = 0;
    //出力処理
    //S 184-005 2023/09/20 連続印刷指示抑制の為、メッセージボックスを表示する
    window.alert('印刷要求を受け付けました。しばらくお待ちください');
    //E 184-005 2023/09/20 連続印刷指示抑制の為、メッセージボックスを表示する
    setAlert('　'); 
    console.log('PRINTsyori:件数確認開始');
    let inpYYYYMMDD = '20'+inp_DENYMD.substring(0,2)+inp_DENYMD.substring(3,5)+inp_DENYMD.substring(6,8);
    console.log('inpYYYYMMDD：'+inpYYYYMMDD);
    const responseA = await fetch('/TCGT0170/MR_RET_APP_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,THOOUTYMD:inpYYYYMMDD,TB_FLG:0})});
    const dataA = await responseA.json();
    console.log('PRINTsyori:件数確認完了');
    if (dataA.RCNT >= 1) //一件以上
    {
      console.log('PRINTsyori:データあり');
      // setAlert('Ｆキーを押してください。'); 
      const responseB = await fetch('/TCGT0170/SAPRT0310',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,THOOUTYMD:inpYYYYMMDD,JGNCDE:state.JGNCDE,TB_FLG:0,UPD_FLG:upd_flg})});
      const dataB = await responseB.json();
      retcnt = 1;
    }

    const responseC = await fetch('/TCGT0170/MR_RET_APP_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,THOOUTYMD:inpYYYYMMDD,TB_FLG:1})});
    const dataC = await responseC.json();
    console.log('PRINTsyori:件数確認完了');
    if (dataC.RCNT >= 1) //一件以上
    {
      console.log('PRINTsyori:データあり');
      // setAlert('Ｆキーを押してください。'); 
      const responseD = await fetch('/TCGT0170/SAPRT0310',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,THOOUTYMD:inpYYYYMMDD,JGNCDE:state.JGNCDE,TB_FLG:1,UPD_FLG:upd_flg})});
      const dataD = await responseD.json();
      retcnt = 1;
    }

    const responseE = await fetch('/TCGT0170/MR_RET_APP_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,THOOUTYMD:inpYYYYMMDD,TB_FLG:2})});
    const dataE = await responseE.json();
    console.log('PRINTsyori:件数確認完了');
    if (dataE.RCNT >= 1) //一件以上
    {
      console.log('PRINTsyori:データあり');
      // setAlert('Ｆキーを押してください。'); 
      const responseF = await fetch('/TCGT0170/SAPRT0310',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,THOOUTYMD:inpYYYYMMDD,JGNCDE:state.JGNCDE,TB_FLG:2,UPD_FLG:upd_flg})});
      const dataF = await responseF.json();
      retcnt = 1;
    }

    const responseG = await fetch('/TCGT0170/MR_RET_APP_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,THOOUTYMD:inpYYYYMMDD,TB_FLG:3})});
    const dataG = await responseG.json();
    console.log('PRINTsyori:件数確認完了');
    if (dataG.RCNT >= 1) //一件以上
    {
      console.log('PRINTsyori:データあり');
      // setAlert('Ｆキーを押してください。'); 
      const responseH = await fetch('/TCGT0170/SAPRT0310',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,THOOUTYMD:inpYYYYMMDD,JGNCDE:state.JGNCDE,TB_FLG:3,UPD_FLG:upd_flg})});
      const dataH = await responseH.json();
      retcnt = 1;
    }


    if (retcnt === 0){
      console.log('PRINTsyori:データなし');
      setAlert('出力データはありませんでした。');//TCGE0076：
    }else{
      setAlert('正常に処理が完了しました。');//TCGM0003：
    }
  };

  useEffect(() => {
    // イベントリスナーを登録
    window.addEventListener('keydown', (e) => {
      switch(e.key)
      {
        case 'F5':
          e.preventDefault();
          navigate('/TCSA0003');
          break;

        case 'F11':
          e.preventDefault();
          if (inDENYMD.current !== null) {
            inDENYMD.current.focus();
            console.log('state.KSACDE:'+state.KSACDES);
            console.log('state.MISES:'+state.MISES);
          }
          setAlert('Ｆ５：終了'); 
          break;
        default:
          break;
      }
    });
  }, []);

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef,inUpRef,inRgRef,inDnRef,inLfRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報、上キー、右キー、下キー、左キー
    //console.log(key);
    let mvFlg = 0;
    switch (key) {
      case "Enter":
        if (inputRef.current.id==='DENYMD') 
        {
          // 区分チェック
          mvFlg = SetCheck('DENYMD',1);
          console.log('チェック結果(DENYMD)：'+ mvFlg);
        }

        if (inputRef.current.id==='ret') 
        {
          if (inp_kkn === '1')
          {
            // 出力日チェック
            mvFlg = SetCheck('DENYMD',2);
            console.log('チェック結果(ret)：'+ mvFlg);
            if (mvFlg == 0){
              // 帳票出力
              PRINTsyori();
            }
          }else if (inp_kkn === '5')
          {
            navigate('/TCSA0003');
          }
        }

        inRET.current.focus();
        // switch (mvFlg) {
        //   case 0:
        //     inputNxRef.current.focus();   //次の項目に遷移
        //     break;
        //   case 1:
        //     inRET.current.focus();
        //     inputRef.current.focus();   //現在の項目に遷移
        //     break;
        //   case 2:
        //     inputRtRef.current.focus();   //前の項目に遷移
        //     break;
        //   case 9:
        //     // inDENNO.current.focus();   //伝票No.の項目に遷移
        //     break;
        //   default:
        //     break;
        //   }
        break;
      case "Home":
        break;
      case "PageUp":
        inputRtRef.current.focus();   //前の項目に遷移
        break;
      case "PageDown":
        inputNxRef.current.focus();   //次の項目に遷移
        break;
      default:
        break;
    }
  };  

  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "750px",
          width: "1050px",
          m: "10px auto",
          padding:"10px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="left"
        >
          <div style={{display:"flex"}}>
            <Typography variant={"h5"} sx={{ m: "10px" }}>
              計上リスト（GOT返品）再出力処理
            </Typography>
            <Box width={'55%'} display={'flex'} justifyContent={'flex-end'}>
              <Typography variant={"h6"} sx={{ m: "13px" }}>
                {state.MISEMEI}：{state.JGNMEIKNJ}
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px" }}>
                { state.SDAY }
              </Typography>　　　　　
            </Box>
          </div>
        </Grid>
        <br />
        <Stack direction="row">
          {/* <font color="black">{ '計上リスト（返品）を出力します。' }</font> */}
          <InputLabel variant={'outlined'}>計上リスト（返品）を再出力します。</InputLabel>    
        </Stack>
        <br />
        <br />        
        <Stack direction="row">
          <Grid>
            <div>　　</div>
          </Grid>
          <PatternFormat  //伝票日付
            id={'DENYMD'}
            inputRef={inDENYMD}                   //項目名定義？
            format="##/##/##"
            mask={['Y','Y','M','M','D','D']}
            autoFocus                           //初期カーソル位置設定
            color='success'
            customInput={TextField}             //textfieldにreact-number-formatの機能を追加
            size='small'                        //textboxの全体サイズ
            label='処理日'                        //textboxの左上に表示するラベル
            variant='outlined'                  //枠の表示方法
            fixedDecimalScale={false}            //小数点On/Off
            InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
            onValueChange={(values, sourceInfo) => {
              console.log(values.value);
              console.log(values.formattedValue);
              console.log(values.floatValue);
            }}              
            sx={{width: '11ch',background:itDENYMDclr}}                 //textboxの幅
            value={inp_DENYMD}                  //初期値
            allowLeadingZeros                   //前ゼロ表示 on
            disabled={false}                    //使用可／不可
            placeholder="YY/MM/DD"
            inputProps={{ autoComplete: 'off',maxLength: 9,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
            onKeyDown={(e) => onKeyDown(e.key,inDENYMD,inDENYMD,inRET,'','','','')}
            onChange={(event) => setDENYMD(event.target.value)}
            //onFocus={(e) => e.target.select()}
            onFocus={(e) => setTimeout(() => {inDENYMD.current.select();},100)}
            />
        </Stack>
        <br />
        <br />
        {/* <Stack direction="row" >
          <Grid>
            <div>　　</div>
          </Grid>
          <NumericFormat  //確認
                id={'ret'}
                inputRef={inRET}                    //項目名定義？
                thousandSeparator={false}           //1000単位の区切り           
                color='success'
                autoFocus                           //初期カーソル位置設定
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='確認'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                fixedDecimalScale={false}            //小数点On/Off
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '5ch'}}                 //textboxの幅
                value={inp_kkn}                     //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={false}                    //使用可／不可
                inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
                onKeyDown={(e) => onKeyDown(e.key,inRET,inDENYMD,inRET,'','','','')}
                onChange={(event) => setkkn(event.target.value)}
                />    
                <InputLabel variant={'outlined'}>（１：リスト出力　５：前画面へ）</InputLabel>          
        </Stack> */}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <font color="red">{ alert }</font>
        <Stack direction="row" spacing={2}>
          {/* <font color="black">{ 'F9:中止　F5:終了　Up:前項目　Down:次項目　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　' }</font> */}
          <font color="black">{ 'Ｆ１：リスト出力　Ｆ５：終了　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　' }</font>          
        </Stack>
      </Paper>
    </Grid>
  );
};

export default TCGT0170;