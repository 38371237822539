import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, Button,Stack, Grid, Paper, Typography, InputLabel, MenuItem, FormControl, TextField, Select } from "@mui/material";
import { NumericFormat } from 'react-number-format';  //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
//S SK-0050-012 2024/7/25           TCI)M.K【要望：ログイン制御】
import { LOCAL_CONSTANT } from './constants.ts';
//E SK-0050-012

const commonStyles = {
  bgcolor: 'background.paper',
  m: 1,
  border: 0,
  width: '40px',
  height: '40px',
};

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSH0020 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [ksacde, setKsacde] = useState('');
  const [kmise, setKmise] = useState('');
  const [ksanm, setKsanm] = useState('');
  const [misenm, setMisenm] = useState('');
  const [alert, setAlert] = useState('');
  const [K_KSACDE,setK_KSACD] = useState('');
  const [K_MISE,setK_MISE] = useState('');
  const [lK_FLG,setlK_FLG] = useState('');
  const [sdate, setsDate] = useState([]);     // 本日日付（YYYY/MM/DD)

  const inKSACDE = useRef(null);
  const inMISE = useRef(null);
  //S SK-0065-001 2024/8/19           TCI)M.K【課題No.91：デフォルトフォーカス】
  const btn01Ref = useRef(null);
  const btn02Ref = useRef(null);
  const btn03Ref = useRef(null);
  //E SK-0065-001

  //S SK-0050-014 2024/7/25           TCI)M.K【要望：ログイン制御】
  const [btn02name, setBtn02name] = useState('戻る');
  const [btn03, setBtn03] = useState(false); // 排他制御解除（visibility）、true='visible'、false='hidden'
  //E SK-0050-014

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    // 多重防止
    // if (!keyDisabled) {
    //   setKeyDisabled(true);
    // } else {
    //   return;
    // }

    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      // if (state.MVEMGAMEN==='')
      // {
      //   navigate('/');
      // }else{
      //   navigate('/TCSA0003');
      // }      
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  const handleChange = (event, SelectChangeEvent) => {
    setKsacde(event.target.value);
    switch(event.target.value)
    {
      case '001':
        setKsanm('オークワ');
        break;
      case '003':
        setKsanm('オーク');
        break;
      case '005':
        setKsanm('パーティハウス');
        break;
      case '008':
        setKsanm('オー・エンターテイメント');
        break;
      default:
        break;
    };
    
    inMISE.current.focus();
    inMISE.current.select();
  };

  // 初期処理
  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    //S SK-0050-016 2024/7/25           TCI)M.K【要望：ログイン制御】
    if (LOCAL_CONSTANT.EXCLUSION_CONTROL === 1){
      //S SK-0058-003 2024/8/5           TCI)M.K【要望：ログイン制御 本社所属は対象外】
      // setBtn02name('サインアウト');
      if (state.MISE !== '200'){
        setBtn02name('サインアウト');
      }
      //E SK-0058-003
      if (state.KENGEN === '99'){
        setBtn03(true);
      }
    }
    //E SK-0050-016
    
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);

    //S SK-0065-003 2024/8/19           TCI)M.K【課題No.91：デフォルトフォーカス】
    btn01Ref.current.focus();
    //E SK-0065-003
  },[]);

  useEffect(() => {
  }, []);

  // ボタン１押下時の処理
  const onClick_FooterButton_Next1 = async() => {
    let errflg = 0;

    if (errflg == 0 )
    {
      dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
        KSACDES : state.KSACDES,MISES: state.MISES,K_KSACDES : state.K_KSACDE,K_MISES: state.K_MISE,
        MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
        SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: lK_FLG,SDAY: state.SDAY,BUTTON:state.BUTTON, 
        BMNCDE:state.BMNCDE,BRUMEI:state.BRUMEI,GRPCDE:state.GRPCDE,IRYOFLG:state.IRYOFLG
      });
      // if (state.GAMEN == 0)
      // { 
        navigate('/TCSH0040');
      // }else{
      //   navigate('/TCSA0005');
      // }
    }
  }

  // ボタン２押下時の処理
  const onClick_FooterButton_Next2 = async() => {
    let errflg = 0;

    if (errflg == 0 )
    {
      dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
        KSACDES : state.KSACDES,MISES: state.MISES,K_KSACDES : state.K_KSACDE,K_MISES: state.K_MISE,
        MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
        SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: lK_FLG,SDAY: state.SDAY,BUTTON:state.BUTTON, 
        BMNCDE:state.BMNCDE,BRUMEI:state.BRUMEI,GRPCDE:state.GRPCDE,IRYOFLG:state.IRYOFLG
      });
        navigate('/TCSH0030');
    }
  }

  //S SK-0050-017 2024/7/25           TCI)M.K【要望：ログイン制御】
  // ボタン３押下時の処理
  const onClick_FooterButton_Next3 = async() => {
    let errflg = 0;

    if (errflg == 0 )
    {
      navigate('/TCSH0050');
    }
  }
  //E SK-0050-017

  // 戻るボタン押下時の処理
  const onClick_FooterButton_Back = async() => {
    //S SK-0050-007 2024/7/24           TCI)M.K【要望：ログイン制御】
    //S SK-0058-004 2024/8/5           TCI)M.K【要望：ログイン制御 本社所属は対象外】
    // if (LOCAL_CONSTANT.EXCLUSION_CONTROL === 1){
    if (LOCAL_CONSTANT.EXCLUSION_CONTROL === 1 && state.MISE !== '200'){
    //E SK-0058-004
      const repHitDat = fetch('/TCSH0000/Replace_HIT_DAT',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES, 
          MISE:state.MISES, 
          BMNCDE:state.BMNCDE, 
          JGNCDE:state.JGNCDE, 
          JGNMEIKNJ:state.JGNMEIKNJ, 
          PRG_ID:'TCSH0020',
          DELFLG:'1',
        })});
      console.log('repHitDat:%o',repHitDat);
      }
    //E SK-0050-007

    if (state.MISE == '200' || state.MISE == '700' || state.K_FLG == '1')
    {
        navigate('/TCSH0010');                
      
    }else
    {
      if (state.SMISEFLG == 1)
      {
        navigate('/TCSH0010');
      }else{
        navigate('/');
      }
    }
  }

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    //console.log(key);
    switch (key) {
      case "Enter":
        let errflg = 0;
        setAlert('　');

        if (errflg == 0)
        {
          inputNxRef.current.focus();   //次の項目に遷移
        }
        break;
      case "Home":
        break;
      case "ArrowUp":
        break;
      case "ArrowDown":
        break;
      default:
        break;
    }
  };  

  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  // 確認のWidthを設定する
  const cssInputOutlineRetSetwidth = (obj) => {
    let resObj = obj;
    resObj.width = '50px';
    return resObj;
  }

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
         fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
    cssInputOutlineJotKbn: {
      '> div': {
        minHeight: 'auto !important',
        paddingTop: '.4em',
        paddingBottom: '.3em',
      }
    },
    cssInputSelect: {
      ' label': {
        color: '#000000'
      },
      ' fieldset': {
        borderColor: '#000000'
      },
    },
  }

  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "470px",
          width: "280px",
          m: "20px auto"
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="center"
        >
          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
            <Typography variant={'h3'} sx={{ m: '5px',mt:'1px',fontSize:11,width:400,mr:'10px' }}>
            {state.MISES}:{state.BMNCDE} {state.MISEMEI}:{state.BRUMEI}<br/>{state.JGNMEIKNJ}
            </Typography>
            <Typography variant={'h3'} sx={{ m: '5px',mt:'17px',fontSize:11 ,width:110,mr:'1px'}}>
              { sdate }
            </Typography>
          </Box>
          {/* 画像の角丸対応 */}
          <Box width={'55%'} sx={{  ...commonStyles, borderRadius: '5px',overflow: 'hidden' }}><img src={`${process.env.PUBLIC_URL}/OKUWA_Logo.svg`} alt="Logo" width="40" height="40" /></Box>
        </Grid>

        <br/>
        <Box >
            <Button
              //S SK-0065-002 2024/8/19           TCI)M.K【課題No.91：デフォルトフォーカス】
              ref={btn01Ref}
              //E SK-0065-002
              type="submit" 
              color="success" 
              variant="contained" 
              fullWidth
              onClick={(e) => onClick_FooterButton_Next1()}
              >
              {'スキャン発注'}
            </Button>
          </Box>
        <br/>
        <Box >
            <Button
              type="submit" 
              color="success" 
              variant="contained" 
              fullWidth
              onClick={(e) => onClick_FooterButton_Next2()}
              >
              {'シノプス'}
            </Button>
          </Box>
        <br /><br />
        <Box ml={2} flexDirection="row" justifyContent="flex-end" display="flex" marginTop={'5px'}>
          <Box >
            <Button
              variant='contained'
              color='success'
              //S SK-0050-006 2024/7/24           TCI)M.K【要望：ログイン制御】
              // sx={{width:50,mr:'200px'}}
              sx={{width:120,mr:'25px'}}
              //E SK-0050-006
              onClick={(e) => onClick_FooterButton_Back()}
              >
              {/* S SK-0050-008 2024/7/24           TCI)M.K【要望：ログイン制御】 */}
              {/* {'戻る'} */}
              {btn02name}
              {/* E SK-0050-008 */}
            </Button>&nbsp;
          </Box >
          {/* S SK-0050-015 2024/7/25           TCI)M.K【要望：ログイン制御】 */}
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:120,mr:'5px',visibility:btn03 ? 'visible' : 'hidden' }}
              onClick={(e) => onClick_FooterButton_Next3()}
              >
              {'排他制御解除'}
            </Button>
          </Box >
          {/* E SK-0050-015 */}
        </Box>
        {/* <p>{ state.usrnm }</p> */}
        <p><font color="red">{ alert }</font></p>
      </Paper>
    </Grid>
  );
};

export default TCSH0020;