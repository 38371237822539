import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, TextField, Grid, Paper, Typography, Stack, InputLabel } from "@mui/material";
import Table from '@mui/material/Table';              //テーブル用
import TableBody from '@mui/material/TableBody';      //テーブル用
import TableCell from '@mui/material/TableCell';      //テーブル用
import TableContainer from '@mui/material/TableContainer';  //テーブル用
import TableHead from '@mui/material/TableHead';      //テーブル用
import TableRow from '@mui/material/TableRow';        //テーブル用
import { NumericFormat } from 'react-number-format';    //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import { onNextFocus } from './hooks/useOnMyFocus';     // select()処理
import TCSA0140_parts from './TCSA0140_parts';
import { Decimal } from 'decimal.js';

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);

      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F5キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F9キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};

// コンポーネント呼び出し
const {
  CKubun,CDenpyoNo,CKeijyoYM,CMiseCode,CBunruiCode,CAstCode,CDenpyoYMD,
  CUketoriMiseCode,CUketoriBunruiCode,CUketoriAstCode,
  CShohinCode,CSuuryo,CGenkaTanka,CBaikaTanka,CBaikakingaku,
  CSuuryoGoukei,CGenkaTankaGoukei,CBaikaTankaGoukei,CBaikaKingakuGoukei
} = TCSA0140_parts();

function TCSA0140 () {
  // #region 変数
  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('');
  const [dissmise, setSMISE] = useState(true);    //disabled設定（発送店）
  const [dissumise, setSUMISE] = useState(false);    //disabled設定（受取店）
  const [dismeisai, setMeisai] = useState(false);    //disabled設定（明細）
  const [sdate, setsDate] = useState([]);
  const [itRcnt,setItrcnt] = useState(0);
  const keyDisabled = useRef(false);
  const [regionList, setRegionList] = useState([]);  //店情報[高速化用]
  const [regionList2, setRegionList2] = useState([]);  //部門情報[高速化用]

  const [retMvFlg, setretMvFlg] = useState(0);

  const [itKBNNM,setItkbnnm] = useState('');
  const [itMISE01,setItmise01] = useState('');//発送店用1回目メモリ
  const [itDENNO01,setItdenno01] = useState('');//伝票番号１回目メモリ
  const [itASTCDE01,setItastcde01] = useState('');//ＡＵ用１回目メモリ
  const [itASTCDEIDO,setItastcdeido] = useState('');//ＡＵ（移動フラグ）メモリ
  const [itKIJYYM2,setItkijyym2] = useState('');//YYMM形式　計上年月
  const [itUIRMISE01,setItuirmise01] = useState('');//店コード（受取店）１回目メモリ
  const [itUIRASTCDEIDO,setItuirastcdeido] = useState('');//ＡＵ（移動フラグ）（発送店）メモリ
  const [itBMNCDE01,setItbmncde01] = useState(''); //分類CD用１回目メモリ
  const [itUIRBMNCDE01,setITuirbmncde01] = useState('');//分類CD（受取店）用１回目メモリ
  const [itUIRASTCDE01,setITuirastcde01] = useState('');//AU用（受取店）１回目メモリ

  const [itESJ_KBN1,setItesj_kbn1] = useState('');    //伝票明細：ＥＯＳ／ＪＡＮ区分1
  const [itESJ_KBN2,setItesj_kbn2] = useState('');    //伝票明細：ＥＯＳ／ＪＡＮ区分2
  const [itESJ_KBN3,setItesj_kbn3] = useState('');    //伝票明細：ＥＯＳ／ＪＡＮ区分3
  const [itESJ_KBN4,setItesj_kbn4] = useState('');    //伝票明細：ＥＯＳ／ＪＡＮ区分4
  const [itESJ_KBN5,setItesj_kbn5] = useState('');    //伝票明細：ＥＯＳ／ＪＡＮ区分5
  const [itESJ_KBN6,setItesj_kbn6] = useState('');    //伝票明細：ＥＯＳ／ＪＡＮ区分6
  const [itGNKKNRFLG1,setItgnkknrflg1] = useState('');  //原価管理フラグ1
  const [itGNKKNRFLG2,setItgnkknrflg2] = useState('');  //原価管理フラグ2
  const [itGNKKNRFLG3,setItgnkknrflg3] = useState('');  //原価管理フラグ3
  const [itGNKKNRFLG4,setItgnkknrflg4] = useState('');  //原価管理フラグ4
  const [itGNKKNRFLG5,setItgnkknrflg5] = useState('');  //原価管理フラグ5
  const [itGNKKNRFLG6,setItgnkknrflg6] = useState('');  //原価管理フラグ6

  const [cent_ksacde,setCent_ksacde] = useState('');  //各店対応用
  const [cent_mise,setCent_mise] = useState('');      //各店対応用

  // コンポーネント
  const kubunRef = useRef(null);              // 区分
  const denpyoNoRef = useRef(null);           // 伝票No.
  const keijyoYMRef = useRef(null);           // 計上年月
  const miseCodeRef = useRef(null);           // 店CD
  const bunruiCodeRef = useRef(null);         // 分類CD
  const astCodeRef = useRef(null);            // ＡＵ
  const denpyoYMDRef = useRef(null);          // 発送日
  const uketoriMiseCodeRef = useRef(null);    // 店CD（受取店）
  const uketoriBunruiCodeRef = useRef(null);  // 分類CD（受取店）
  const uketoriAstCodeRef = useRef(null);     // ＡＵ（受取店）
  const shohinCode1Ref = useRef(null);        // 商品コード1
  const shohinCode2Ref = useRef(null);        // 商品コード2
  const shohinCode3Ref = useRef(null);        // 商品コード3
  const shohinCode4Ref = useRef(null);        // 商品コード4
  const shohinCode5Ref = useRef(null);        // 商品コード5
  const shohinCode6Ref = useRef(null);        // 商品コード6
  const suuryo1Ref = useRef(null);            // 数量1
  const suuryo2Ref = useRef(null);            // 数量2
  const suuryo3Ref = useRef(null);            // 数量3
  const suuryo4Ref = useRef(null);            // 数量4
  const suuryo5Ref = useRef(null);            // 数量5
  const suuryo6Ref = useRef(null);            // 数量6
  const genkaTanka1Ref = useRef(null);        // 原価単価1
  const genkaTanka2Ref = useRef(null);        // 原価単価2
  const genkaTanka3Ref = useRef(null);        // 原価単価3
  const genkaTanka4Ref = useRef(null);        // 原価単価4
  const genkaTanka5Ref = useRef(null);        // 原価単価5
  const genkaTanka6Ref = useRef(null);        // 原価単価6
  const baikaTanka1Ref = useRef(null);        // 売価単価1
  const baikaTanka2Ref = useRef(null);        // 売価単価2
  const baikaTanka3Ref = useRef(null);        // 売価単価3
  const baikaTanka4Ref = useRef(null);        // 売価単価4
  const baikaTanka5Ref = useRef(null);        // 売価単価5
  const baikaTanka6Ref = useRef(null);        // 売価単価6
  const baikaKingaku1Ref = useRef(null);      // 売価金額1
  const baikaKingaku2Ref = useRef(null);      // 売価金額2
  const baikaKingaku3Ref = useRef(null);      // 売価金額3
  const baikaKingaku4Ref = useRef(null);      // 売価金額4
  const baikaKingaku5Ref = useRef(null);      // 売価金額5
  const baikaKingaku6Ref = useRef(null);      // 売価金額6
  const suuryoGoukeiRef = useRef(null);       // 数量合計
  const genkaTankaGoukeiRef = useRef(null);   // 原価単価合計
  const baikaTankaGoukeiRef = useRef(null);   // 売価単価合計
  const baikaKingakuGoukeiRef = useRef(null); // 売価金額合計

  const [itRETclr,setItretclr] = useState('#FFFFFF');

  const inKBN = useRef(null);
  const inDENNO = useRef(null);
  const inKIJYYM = useRef(null);
  const inMISE = useRef(null);
  const inBMNCDE = useRef(null);
  const inASTCDE = useRef(null);
  const inDENYMD = useRef(null);
  const inUIRMISE = useRef(null);
  const inUIRBMNCDE = useRef(null);
  const inUIRASTCDE = useRef(null);

  const inRET = useRef(null);
  const inSHNCDE1 = useRef(null);
  const inSUU1 = useRef(null);
  const inGNT1 = useRef(null);
  const inBTN1 = useRef(null);
  const inBAKKIN1 = useRef(null);
  const inSHNCDE2 = useRef(null);
  const inSUU2 = useRef(null);
  const inGNT2 = useRef(null);
  const inBTN2 = useRef(null);
  const inBAKKIN2 = useRef(null);
  const inSHNCDE3 = useRef(null);
  const inSUU3 = useRef(null);
  const inGNT3 = useRef(null);
  const inBTN3 = useRef(null);
  const inBAKKIN3 = useRef(null);
  const inSHNCDE4 = useRef(null);
  const inSUU4 = useRef(null);
  const inGNT4 = useRef(null);
  const inBTN4 = useRef(null);
  const inBAKKIN4 = useRef(null);
  const inSHNCDE5 = useRef(null);
  const inSUU5 = useRef(null);
  const inGNT5 = useRef(null);
  const inBTN5 = useRef(null);
  const inBAKKIN5 = useRef(null);
  const inSHNCDE6 = useRef(null);
  const inSUU6 = useRef(null);
  const inGNT6 = useRef(null);
  const inBTN6 = useRef(null);
  const inBAKKIN6 = useRef(null);

  const inSUUG = useRef(null);
  const inGNTG = useRef(null);
  const inBTNG = useRef(null);
  const inBAKKING = useRef(null);

  // 2023/10/05 確認フォーカス処理の重複を制御
  // 確認制御用
  const [enable, setEnable] = useState(false);

  // エラーチェック用 0:エラーなし, 1:未チェック, 2:エラー, 3：ベリファイ1回目入力, 9:確認中
  const [chkflg,setChkflg] = useState(0);               // チェックフラグ
  const [chkflg2,setChkflg2] = useState(0);             // チェックフラグ2
  const [inputConfirmflg,setInputConfirmflg] = useState(0);   // チェック完了フラグ

  const [blurTableflg,setBlurTableflg] = useState(false);       // blurTableflg

  const [ttl ,setTtl] = useState('');

  let isInInputConfirm = false;
  let idoFlagOfAstCde = '';
  // #endregion

  //#region 初期処理
  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      navigate('/TCSA0003');
    }
    if (event.keyCode === 120) { // F9キーが押された場合の処理
      setAlert(' ');
      Gmclr();              //画面クリア
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);

    if (state.ETC == 'K')
    {
      setSMISE(false); // 各店時のみ社店CDを活性にする
      setSUMISE(true); // 各店時のみ受取店CDを非活性にする
      setCent_ksacde(state.K_KSACDES);
      setCent_mise(state.K_MISES);
      // if (state.K_FLG !== 1 ){ 2023/11/24 無意味なのでコメント化
      //   miseCodeRef.current?.setValue(state.K_MISES);
      // }else{
        uketoriMiseCodeRef.current?.setValue(state.MISES);
        setITuirbmncde01(state.MISES);
      // }
      if (state.KSACDES ==='005')
      {
        setTtl('(PH 各店)');
      }else
      {
        setTtl('(各店)');
      }
    }else{
      setCent_ksacde(state.KSACDES);
      setCent_mise(state.MISES);
      // if (state.K_FLG !== 1 ){
        miseCodeRef.current?.setValue(state.MISES);
        setItmise01(state.MISES);
      // }
      if (state.KSACDES ==='005')
      {
        setTtl('(PH)');
      }else
      {
        setTtl('');
      }
    }

    // if (state.K_FLG !== 1 ){
    //   miseCodeRef.current?.setValue(state.MISES);
    // }
    setTimeout(() => {document.documentElement.webkitRequestFullScreen();},1)
    getRegionList();  //2023/11/21 MAP化

  },[]);

  const getRegionList = async() => {

    //店情報
    const get_region = await fetch('/TCSA0140/MR_SHP_MSTM_sel',{method: 'POST', headers:{'Content-Type': 'application/json'},
    body: JSON.stringify({ })});
    const data_region = await get_region.json();
    setRegionList(data_region.ret);

    //部門CD
    const get_region2 = await fetch('/TCSA0140/MR_SHP_MSTM_sel2',{method: 'POST', headers:{'Content-Type': 'application/json'},
    body: JSON.stringify({ })});
    const data_region2 = await get_region2.json();
    setRegionList2(data_region2.ret);

  }


  // useEffect(() => {
  //   // イベントリスナーを登録
  //   window.addEventListener('keydown', (e) => {
  //     switch(e.key)
  //     {
  //       case 'F1':
  //         e.preventDefault();
  //         navigate('/TCSA0004');
  //         break;
  //       case 'F5':
  //         e.preventDefault();
  //         navigate('/TCSA0003');
  //         break;
  //       case 'F9':
  //         setAlert(' ');
  //         e.preventDefault();
  //         Gmclr();              //画面クリア
  //         break;
  //       default:
  //         break;
  //     }
  //   });
  // }, []);
  //#endregion

  //#region 呼出処理
  /**
   * 日付存在チェック
   * @param strYYYYMMDD 年月日（YYYY/MM/DD ⇒ 2023/09/07 等）
   * @returns true:存在, false:エラー
   */
  const ckDate = (strYYYYMMDD) =>{
    if(!strYYYYMMDD.match(/^\d{4}\/\d{2}\/\d{2}$/)){
        return false;
    }
    var y = strYYYYMMDD.split("/")[0];
    var m = strYYYYMMDD.split("/")[1] - 1;
    var d = strYYYYMMDD.split("/")[2];
    var date = new Date(y,m,d);
    if(date.getFullYear() != y || date.getMonth() != m || date.getDate() != d){
        return false;
    }
    return true;
  }

  /**
   * 計上年月算出
   * @param p_YMD 年月日（YYYY/MM/DD ⇒ 2023/09/07 等）
   * @returns 計上年月
   */
  const KeijoYM = (p_YMD) =>{
    const c_YMD = String(p_YMD).replaceAll('/','');
    const n_now = new Date(state.SDAY);
    const n_day = String(n_now.getDate()).padStart(2,'0');  //システム日付の日を取得
    const n_dt = String(n_now.getFullYear())+String(n_now.getMonth()+1).padStart(2,'0')+'21';    //当月の20日以前チェック用
    const np_now = new Date(n_now.getFullYear(),n_now.getMonth(),'01'); //月＋１計算用（一旦日を01にしないとおかしいくなる）

    console.log('n_now:%o',n_now);
    console.log('n_day:%o',n_day);
    console.log('n_dt:%o',n_dt);
    console.log('np_now:%o',np_now);

    np_now.setMonth(np_now.getMonth()+1); //マイナス１月対策

    console.log('対策後n_now:%o',n_now);
    console.log('対策後np_now:%o',np_now);
    console.log('n_now.getMonth:%o',n_now.getMonth())
    console.log('np_now:%o',np_now.getMonth())

    if (n_day <= '20')
    {
      //1パターン
      console.log('パターン１');
      return String(n_now.getFullYear()).slice(2,4)+'年'+String(n_now.getMonth()+1).padStart(2,'0')+'月';
    }else if(n_day === '21' || n_day === '22' )
    {
      // 2023/11/21 upd 誤り（手書きに仕様を合わせる
      // const c_YM = c_YMD.slice(0, 6);
      // const n_YM = String(n_now.getFullYear())+String(n_now.getMonth()+1);
      // if (c_YM < n_YM) {
      if (c_YMD < n_dt){
          //2パターン
        // ただし、入力日（システム日付）が、21日、22日の場合、かつ「発送日」が前月以前のデータを入力した場合は、計上年月に当月を設定する。
        console.log('パターン２'+c_YMD+'<'+n_dt);
        return String(n_now.getFullYear()).slice(2,4)+'年'+String(n_now.getMonth()+1).padStart(2,'0')+'月';
      }else{
        //5パターン
        let ret = String(np_now.getFullYear()).slice(2,4)+'年'+String(np_now.getMonth()+1).padStart(2,'0')+'月';
        console.log('パターン５'+ret);
        return ret;
      }
    }else{
      //3,6パターン
      let ret = String(np_now.getFullYear()).slice(2,4)+'年'+String(np_now.getMonth()+1).padStart(2,'0')+'月';
      console.log('パターン３，６'+ret);
      return ret;
    }
  }

  /**
   * 頭0埋め処理
   * @param SetDate 対象情報
   * @param SFLG 0:商品コード以外、1:商品コード
   * @param NumDig 桁数(頭0埋め含む)
   * @returns　頭0埋めされた値
   */
  const Fhwith = (SetDate,SFLG=1,NumDig = 0) =>{
    let Afcorr;
    console.log('SetDate:%o',typeof(SetDate)) ;
    if (SetDate !== '')
    {
      if (SFLG == 1){
        switch(SetDate.length){
          case 1:
          case 2:
            Afcorr = SetDate.padStart(3,'0');
            break;
          case 7:
            Afcorr = SetDate.padStart(8,'0');
            break;
          case 9:
          case 10:
          case 11:
          case 12:
            Afcorr = SetDate.padStart(13,'0');
            break;
          default:
            Afcorr = SetDate;
            break;
        }
      }else{
        Afcorr = SetDate.padStart(NumDig,'0');
      }
    }else{
      Afcorr = SetDate;
    }
    return Afcorr;
  }

  /**
   * 値呼び出し
   * @param rowNo 呼び出し行
   * @param checkName 呼び出し項目
   * @returns 取得値
   */
  function getItitemval(rowNo,checkName) {
    let value = '';
    switch (rowNo) {
      case 1:
        switch(checkName){
          case 'shncde':
            value = shohinCode1Ref.current?.getValue();
            break;
          case 'suu':
            value = suuryo1Ref.current?.getValue();
            break;
          case 'gnt':
            value = genkaTanka1Ref.current?.getValue();
            break;
          case 'btn':
            value = baikaTanka1Ref.current?.getValue();
            break;
          case 'bakkin':
            value = baikaKingaku1Ref.current?.getValue();
            break;
          default:
            break;
        }
        break;
      case 2:
        switch(checkName){
          case 'shncde':
            value = shohinCode2Ref.current?.getValue();
            break;
          case 'suu':
            value = suuryo2Ref.current?.getValue();
            break;
          case 'gnt':
            value = genkaTanka2Ref.current?.getValue();
            break;
          case 'btn':
            value = baikaTanka2Ref.current?.getValue();
            break;
          case 'bakkin':
            value = baikaKingaku2Ref.current?.getValue();
            break;
          default:
            break;
        }
        break;
      case 3:
        switch(checkName){
          case 'shncde':
            value = shohinCode3Ref.current?.getValue();
            break;
          case 'suu':
            value = suuryo3Ref.current?.getValue();
            break;
          case 'gnt':
            value = genkaTanka3Ref.current?.getValue();
            break;
          case 'btn':
            value = baikaTanka3Ref.current?.getValue();
            break;
          case 'bakkin':
            value = baikaKingaku3Ref.current?.getValue();
            break;
          default:
            break;
        }
        break;
      case 4:
        switch(checkName){
          case 'shncde':
            value = shohinCode4Ref.current?.getValue();
            break;
          case 'suu':
            value = suuryo4Ref.current?.getValue();
            break;
          case 'gnt':
            value = genkaTanka4Ref.current?.getValue();
            break;
          case 'btn':
            value = baikaTanka4Ref.current?.getValue();
            break;
          case 'bakkin':
            value = baikaKingaku4Ref.current?.getValue();
            break;
          default:
            break;
        }
        break;
      case 5:
        switch(checkName){
          case 'shncde':
            value = shohinCode5Ref.current?.getValue();
            break;
          case 'suu':
            value = suuryo5Ref.current?.getValue();
            break;
          case 'gnt':
            value = genkaTanka5Ref.current?.getValue();
            break;
          case 'btn':
            value = baikaTanka5Ref.current?.getValue();
            break;
          case 'bakkin':
            value = baikaKingaku5Ref.current?.getValue();
            break;
          default:
            break;
        }
        break;
      case 6:
        switch(checkName){
          case 'shncde':
            value = shohinCode6Ref.current?.getValue();
            break;
          case 'suu':
            value = suuryo6Ref.current?.getValue();
            break;
          case 'gnt':
            value = genkaTanka6Ref.current?.getValue();
            break;
          case 'btn':
            value = baikaTanka6Ref.current?.getValue();
            break;
          case 'bakkin':
            value = baikaKingaku6Ref.current?.getValue();
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    return value;
  }

  /**
   * 値設定
   * @param value 設定値
   * @param rowNo 設定行
   * @param checkName 設定項目
   */
  function setItitemval(value,rowNo,checkName) {
    switch (rowNo) {
      case 1:
        switch(checkName){
          case 'shncde':
            shohinCode1Ref.current?.setValue(value)
            break;
          case 'suu':
            suuryo1Ref.current?.setValue(value);
            break;
          case 'gnt':
            genkaTanka1Ref.current?.setValue(value);
            break;
          case 'btn':
            baikaTanka1Ref.current?.setValue(value);
            break;
          case 'bakkin':
            baikaKingaku1Ref.current?.setValue(value);
            break;
          case 'gnkknrflg':
            setItgnkknrflg1(value);
            break;
          case 'esj_kbn':
            setItesj_kbn1(value);
            break;
          default:
            break;
        }
        break;
      case 2:
        switch(checkName){
          case 'shncde':
            shohinCode2Ref.current?.setValue(value)
            break;
          case 'suu':
            suuryo2Ref.current?.setValue(value);
            break;
          case 'gnt':
            genkaTanka2Ref.current?.setValue(value);
            break;
          case 'btn':
            baikaTanka2Ref.current?.setValue(value);
            break;
          case 'bakkin':
            baikaKingaku2Ref.current?.setValue(value);
            break;
          case 'gnkknrflg':
            setItgnkknrflg2(value);
            break;
          case 'esj_kbn':
            setItesj_kbn2(value);
            break;
          default:
            break;
        }
        break;
      case 3:
        switch(checkName){
          case 'shncde':
            shohinCode3Ref.current?.setValue(value)
            break;
          case 'suu':
            suuryo3Ref.current?.setValue(value);
            break;
          case 'gnt':
            genkaTanka3Ref.current?.setValue(value);
            break;
          case 'btn':
            baikaTanka3Ref.current?.setValue(value);
            break;
          case 'bakkin':
            baikaKingaku3Ref.current?.setValue(value);
            break;
          case 'gnkknrflg':
            setItgnkknrflg3(value);
            break;
          case 'esj_kbn':
            setItesj_kbn3(value);
            break;
          default:
            break;
        }
        break;
      case 4:
        switch(checkName){
          case 'shncde':
            shohinCode4Ref.current?.setValue(value)
            break;
          case 'suu':
            suuryo4Ref.current?.setValue(value);
            break;
          case 'gnt':
            genkaTanka4Ref.current?.setValue(value);
            break;
          case 'btn':
            baikaTanka4Ref.current?.setValue(value);
            break;
          case 'bakkin':
            baikaKingaku4Ref.current?.setValue(value);
            break;
          case 'gnkknrflg':
            setItgnkknrflg4(value);
            break;
          case 'esj_kbn':
            setItesj_kbn4(value);
            break;
          default:
            break;
        }
        break;
      case 5:
        switch(checkName){
          case 'shncde':
            shohinCode5Ref.current?.setValue(value)
            break;
          case 'suu':
            suuryo5Ref.current?.setValue(value);
            break;
          case 'gnt':
            genkaTanka5Ref.current?.setValue(value);
            break;
          case 'btn':
            baikaTanka5Ref.current?.setValue(value);
            break;
          case 'bakkin':
            baikaKingaku5Ref.current?.setValue(value);
            break;
          case 'gnkknrflg':
            setItgnkknrflg5(value);
            break;
          case 'esj_kbn':
            setItesj_kbn5(value);
            break;
          default:
            break;
        }
        break;
      case 6:
        switch(checkName){
          case 'shncde':
            shohinCode6Ref.current?.setValue(value)
            break;
          case 'suu':
            suuryo6Ref.current?.setValue(value);
            break;
          case 'gnt':
            genkaTanka6Ref.current?.setValue(value);
            break;
          case 'btn':
            baikaTanka6Ref.current?.setValue(value);
            break;
          case 'bakkin':
            baikaKingaku6Ref.current?.setValue(value);
            break;
          case 'gnkknrflg':
            setItgnkknrflg6(value);
            break;
          case 'esj_kbn':
            setItesj_kbn6(value);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  /**
   * 色設定
   * @param color 設定色
   * @param rowNo 設定行
   * @param checkName 設定項目
   */
  function setItitemclr(color,rowNo,checkName) {
    switch (rowNo) {
      case 1:
        switch(checkName){
          case 'shncde':
            shohinCode1Ref.current?.setBGColor(color);
            break;
          case 'suu':
            suuryo1Ref.current?.setBGColor(color);
            break;
          case 'gnt':
            genkaTanka1Ref.current?.setBGColor(color);
            break;
          case 'btn':
            baikaTanka1Ref.current?.setBGColor(color);
              break;
          default:
            break;
        }
        break;
      case 2:
        switch(checkName){
          case 'shncde':
            shohinCode2Ref.current?.setBGColor(color);
            break;
          case 'suu':
            suuryo2Ref.current?.setBGColor(color);
            break;
          case 'gnt':
            genkaTanka2Ref.current?.setBGColor(color);
            break;
          case 'btn':
            baikaTanka2Ref.current?.setBGColor(color);
              break;
          default:
            break;
        }
        break;
      case 3:
        switch(checkName){
          case 'shncde':
            shohinCode3Ref.current?.setBGColor(color);
            break;
          case 'suu':
            suuryo3Ref.current?.setBGColor(color);
            break;
          case 'gnt':
            genkaTanka3Ref.current?.setBGColor(color);
            break;
          case 'btn':
            baikaTanka3Ref.current?.setBGColor(color);
              break;
          default:
            break;
        }
        break;
      case 4:
        switch(checkName){
          case 'shncde':
            shohinCode4Ref.current?.setBGColor(color);
            break;
          case 'suu':
            suuryo4Ref.current?.setBGColor(color);
            break;
          case 'gnt':
            genkaTanka4Ref.current?.setBGColor(color);
            break;
          case 'btn':
            baikaTanka4Ref.current?.setBGColor(color);
              break;
          default:
            break;
        }
        break;
      case 5:
        switch(checkName){
          case 'shncde':
            shohinCode5Ref.current?.setBGColor(color);
            break;
          case 'suu':
            suuryo5Ref.current?.setBGColor(color);
            break;
          case 'gnt':
            genkaTanka5Ref.current?.setBGColor(color);
            break;
          case 'btn':
            baikaTanka5Ref.current?.setBGColor(color);
              break;
          default:
            break;
        }
        break;
      case 6:
        switch(checkName){
          case 'shncde':
            shohinCode6Ref.current?.setBGColor(color);
            break;
          case 'suu':
            suuryo6Ref.current?.setBGColor(color);
            break;
          case 'gnt':
            genkaTanka6Ref.current?.setBGColor(color);
            break;
          case 'btn':
            baikaTanka6Ref.current?.setBGColor(color);
              break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  /**
   * 商品コードチェックフラグ変更
   * @param rowNo 設定行
   * @param cde 0:エラーなし, 1:未チェック, 2:エラー, 3：ベリファイ1回目入力, 9:確認中
   */
  function setChkSHNCDE (rowNo,cde){
    switch (rowNo) {
      case 1:
        shohinCode1Ref.current?.setCheckflg(cde);
        break;
      case 2:
        shohinCode2Ref.current?.setCheckflg(cde);
        break;
      case 3:
        shohinCode3Ref.current?.setCheckflg(cde);
        break;
      case 4:
        shohinCode4Ref.current?.setCheckflg(cde);
        break;
      case 5:
        shohinCode5Ref.current?.setCheckflg(cde);
        break;
      case 6:
        shohinCode6Ref.current?.setCheckflg(cde);
        break;
      default:
        break;
    }
  }

  /**
   * 画面クリア
   */
  const Gmclr = () =>{
    // ----------ヘッダ----------
    denpyoNoRef.current?.setValue('');
    setItdenno01('');			//伝票番号１回目メモリ
    miseCodeRef.current?.setValue('');
    // 2023/11/24 初期処理と同様にする  
    if (state.ETC != 'K')
    {
      miseCodeRef.current?.setValue(state.MISES);
      setItmise01(state.MISES);
      uketoriMiseCodeRef.current?.setValue('');
      setItuirmise01('');
    }else{
      miseCodeRef.current?.setValue('');
      setItmise01('');
      uketoriMiseCodeRef.current?.setValue(state.MISES);
      setItuirmise01(state.MISES);
    }
    bunruiCodeRef.current?.setValue('');
    setItbmncde01('');		//分類CD用１回目メモリ
    astCodeRef.current?.setValue('');
    setItastcde01('');		//ＡＵ用１回目メモリ
    setItastcdeido('');		//ＡＵ（移動フラグ）メモリ
    denpyoYMDRef.current?.setValue('');
    keijyoYMRef.current?.setValue('');
    setItkijyym2('');			//YYMM形式　計上年月
    // uketoriMiseCodeRef.current?.setValue('');
    // setItuirmise01('');		//店コード（受取店）１回目メモリ
    uketoriBunruiCodeRef.current?.setValue('');
    setITuirbmncde01('');		//分類CD（受取店）用１回目メモリ
    uketoriAstCodeRef.current?.setValue('');
    setITuirastcde01('');		//AU用（受取店）１回目メモリ
    setItuirastcdeido('');	//ＡＵ（移動フラグ）（発送店）メモリ
    // ----------ヘッダ----------
    // -----------明細-----------
    shohinCode1Ref.current?.setValue('');			// 商品コード
    shohinCode2Ref.current?.setValue('');
    shohinCode3Ref.current?.setValue('');
    shohinCode4Ref.current?.setValue('');
    shohinCode5Ref.current?.setValue('');
    shohinCode6Ref.current?.setValue('');
    suuryo1Ref.current?.setValue('');			// 数量
    suuryo2Ref.current?.setValue('');
    suuryo3Ref.current?.setValue('');
    suuryo4Ref.current?.setValue('');
    suuryo5Ref.current?.setValue('');
    suuryo6Ref.current?.setValue('');
    genkaTanka1Ref.current?.setValue('');			// 原価単価
    genkaTanka2Ref.current?.setValue('');
    genkaTanka3Ref.current?.setValue('');
    genkaTanka4Ref.current?.setValue('');
    genkaTanka5Ref.current?.setValue('');
    genkaTanka6Ref.current?.setValue('');
    baikaTanka1Ref.current?.setValue('');			// 売価単価
    baikaTanka2Ref.current?.setValue('');
    baikaTanka3Ref.current?.setValue('');
    baikaTanka4Ref.current?.setValue('');
    baikaTanka5Ref.current?.setValue('');
    baikaTanka6Ref.current?.setValue('');
    baikaKingaku1Ref.current?.setValue('');			// 売価金額
    baikaKingaku2Ref.current?.setValue('');
    baikaKingaku3Ref.current?.setValue('');
    baikaKingaku4Ref.current?.setValue('');
    baikaKingaku5Ref.current?.setValue('');
    baikaKingaku6Ref.current?.setValue('');
    // -----------明細-----------

    setItesj_kbn1('');		// 伝票明細：ＥＯＳ／ＪＡＮ区分1
    setItesj_kbn2('');		// 伝票明細：ＥＯＳ／ＪＡＮ区分2
    setItesj_kbn3('');		// 伝票明細：ＥＯＳ／ＪＡＮ区分3
    setItesj_kbn4('');		// 伝票明細：ＥＯＳ／ＪＡＮ区分4
    setItesj_kbn5('');		// 伝票明細：ＥＯＳ／ＪＡＮ区分5
    setItesj_kbn6('');		// 伝票明細：ＥＯＳ／ＪＡＮ区分6
    setItgnkknrflg1('');		// 原価管理フラグ1
    setItgnkknrflg2('');		// 原価管理フラグ2
    setItgnkknrflg3('');		// 原価管理フラグ3
    setItgnkknrflg4('');		// 原価管理フラグ4
    setItgnkknrflg5('');		// 原価管理フラグ5
    setItgnkknrflg6('');		// 原価管理フラグ6

    // ----------フッタ----------
    suuryoGoukeiRef.current?.setValue('');			// 数量合計
    genkaTankaGoukeiRef.current?.setValue('');			// 原価単価合計
    baikaTankaGoukeiRef.current?.setValue('');			// 売価単価合計
    baikaKingakuGoukeiRef.current?.setValue('');			// 売価金額合計
    // ----------フッタ----------


    //項目のバックカラー制御用
    // ----------ヘッダ----------
    denpyoNoRef.current?.setBGColor('#FFFFFF');
    miseCodeRef.current?.setBGColor('#FFFFFF');
    bunruiCodeRef.current?.setBGColor('#FFFFFF');
    astCodeRef.current?.setBGColor('#FFFFFF');
    denpyoYMDRef.current?.setBGColor('#FFFFFF');
    uketoriMiseCodeRef.current?.setBGColor('#FFFFFF');
    uketoriBunruiCodeRef.current?.setBGColor('#FFFFFF');
    uketoriAstCodeRef.current?.setBGColor('#FFFFFF');
    // ----------ヘッダ----------
    // -----------明細-----------
    shohinCode1Ref.current?.setBGColor('#FFFFFF');
    shohinCode2Ref.current?.setBGColor('#FFFFFF');
    shohinCode3Ref.current?.setBGColor('#FFFFFF');
    shohinCode4Ref.current?.setBGColor('#FFFFFF');
    shohinCode5Ref.current?.setBGColor('#FFFFFF');
    shohinCode6Ref.current?.setBGColor('#FFFFFF');
    suuryo1Ref.current?.setBGColor('#FFFFFF');
    suuryo2Ref.current?.setBGColor('#FFFFFF');
    suuryo3Ref.current?.setBGColor('#FFFFFF');
    suuryo4Ref.current?.setBGColor('#FFFFFF');
    suuryo5Ref.current?.setBGColor('#FFFFFF');
    suuryo6Ref.current?.setBGColor('#FFFFFF');
    genkaTanka1Ref.current?.setBGColor('#FFFFFF');
    genkaTanka2Ref.current?.setBGColor('#FFFFFF');
    genkaTanka3Ref.current?.setBGColor('#FFFFFF');
    genkaTanka4Ref.current?.setBGColor('#FFFFFF');
    genkaTanka5Ref.current?.setBGColor('#FFFFFF');
    genkaTanka6Ref.current?.setBGColor('#FFFFFF');
    baikaTanka1Ref.current?.setBGColor('#FFFFFF');
    baikaTanka2Ref.current?.setBGColor('#FFFFFF');
    baikaTanka3Ref.current?.setBGColor('#FFFFFF');
    baikaTanka4Ref.current?.setBGColor('#FFFFFF');
    baikaTanka5Ref.current?.setBGColor('#FFFFFF');
    baikaTanka6Ref.current?.setBGColor('#FFFFFF');
    baikaKingaku1Ref.current?.setBGColor('#FFFFFF');
    baikaKingaku2Ref.current?.setBGColor('#FFFFFF');
    baikaKingaku3Ref.current?.setBGColor('#FFFFFF');
    baikaKingaku4Ref.current?.setBGColor('#FFFFFF');
    baikaKingaku5Ref.current?.setBGColor('#FFFFFF');
    baikaKingaku6Ref.current?.setBGColor('#FFFFFF');

    denpyoNoRef.current?.setCheckflg(0);         // 伝票No.
    bunruiCodeRef.current?.setCheckflg(0);        // 分類CD
    astCodeRef.current?.setCheckflg(0);        // AU
    uketoriMiseCodeRef.current?.setCheckflg(0);       // 受取店
    uketoriBunruiCodeRef.current?.setCheckflg(0);     // 分類CD（受取店）
    uketoriAstCodeRef.current?.setCheckflg(0);     // AU（受取店）
    shohinCode1Ref.current?.setCheckflg(0);       // 商品コード1
    shohinCode2Ref.current?.setCheckflg(0);       // 商品コード2
    shohinCode3Ref.current?.setCheckflg(0);       // 商品コード3
    shohinCode4Ref.current?.setCheckflg(0);       // 商品コード4
    shohinCode5Ref.current?.setCheckflg(0);       // 商品コード5
    shohinCode6Ref.current?.setCheckflg(0);       // 商品コード6

    // -----------明細-----------
    // ----------フッタ----------
    suuryoGoukeiRef.current?.setBGColor('#FFFFFF');
    genkaTankaGoukeiRef.current?.setBGColor('#FFFFFF');
    baikaTankaGoukeiRef.current?.setBGColor('#FFFFFF');
    baikaKingakuGoukeiRef.current?.setBGColor('#FFFFFF');
    setItretclr('#FFFFFF');

    // ----------フッタ----------
    // ----------エラーチェック用フラグ----------
    denpyoNoRef.current?.setCheckflg(0);           // 伝票No.
    bunruiCodeRef.current?.setCheckflg(0);          // 分類CD
    astCodeRef.current?.setCheckflg(0);          // AU
    uketoriMiseCodeRef.current?.setCheckflg(0);         // 受取店
    uketoriBunruiCodeRef.current?.setCheckflg(0);       // 分類CD（受取店）
    uketoriAstCodeRef.current?.setCheckflg(0);       // AU（受取店）
    shohinCode1Ref.current?.setCheckflg(0);         // 商品コード1
    shohinCode2Ref.current?.setCheckflg(0);         // 商品コード2
    shohinCode3Ref.current?.setCheckflg(0);         // 商品コード3
    shohinCode4Ref.current?.setCheckflg(0);         // 商品コード4
    shohinCode5Ref.current?.setCheckflg(0);         // 商品コード5
    shohinCode6Ref.current?.setCheckflg(0);         // 商品コード6
    // ----------エラーチェック用フラグ----------

    // 移動フラグの初期値は true とする
    denpyoNoRef.current?.setFocusflg(true);
    bunruiCodeRef.current?.setFocusflg(true);
    miseCodeRef.current?.setFocusflg(true);
    astCodeRef.current?.setFocusflg(true);
    uketoriMiseCodeRef.current?.setFocusflg(true);
    uketoriBunruiCodeRef.current?.setFocusflg(true);
    uketoriAstCodeRef.current?.setFocusflg(true);

    if (inDENNO.current !== null) {
      inDENNO.current.focus();//クリアしたら伝票番号に戻す
    }
    clearRow(1);
    clearRow(2);
    clearRow(3);
    clearRow(4);
    clearRow(5);
    clearRow(6);
  }

  /**
   * 要素所属タイプ
   * @param id 項目id
   * @returns 1:ヘッダ要素,2:明細要素,3:フッタ要素,9:確認
   */
  const getInputType = (id) => {
    let ret = 0;
    switch (id) {
      case 'KBN':         // 区分設定
      case 'DENNO':       // 伝票No.
      case 'KIJYYM':      // 計上年月
      case 'MISE':        // 店CD
      case 'BMNCDE':      // 分類CD
      case 'ASTCDE':      // ＡＵ
      case 'DENYMD':      // 発送日
      case 'UIRMISE':     // 店CD（受取店）
      case 'UIRBMNCDE':   // 分類CD（受取店）
      case 'UIRASTCDE':   // ＡＵ（受取店）
        ret = 1;
        break;
      case 'SHNCDE1':     // 商品コード1
      case 'SHNCDE2':     // 商品コード2
      case 'SHNCDE3':     // 商品コード3
      case 'SHNCDE4':     // 商品コード4
      case 'SHNCDE5':     // 商品コード5
      case 'SHNCDE6':     // 商品コード6
      case 'SUU1':        // 数量1
      case 'GNT1':        // 原単価1
      case 'BTN1':        // 売単価1
      case 'BAKKIN1':     // 売価金額1
      case 'SUU2':        // 数量2
      case 'GNT2':        // 原単価2
      case 'BTN2':        // 売単価2
      case 'BAKKIN2':     // 売価金額2
      case 'SUU3':        // 数量3
      case 'GNT3':        // 原単価3
      case 'BTN3':        // 売単価3
      case 'BAKKIN3':     // 売価金額3
      case 'SUU4':        // 数量4
      case 'GNT4':        // 原単価4
      case 'BTN4':        // 売単価4
      case 'BAKKIN4':     // 売価金額4
      case 'SUU5':        // 数量5
      case 'GNT5':        // 原単価5
      case 'BTN5':        // 売単価5
      case 'BAKKIN5':     // 売価金額5
      case 'SUU6':        // 数量6
      case 'GNT6':        // 原単価6
      case 'BTN6':        // 売単価6
      case 'BAKKIN6':     // 売価金額6
        ret = 2;
        break;
      case 'SUUG':        // 数量合計
      case 'GNTG':        // 原価単価合価計
      case 'BTNG':        // 売価単価合計
      case 'BAKKING':     // 売価金額合計
        ret = 3;
        break;
      case 'ret':         // 登録処理
        ret = 9;
        break;
      default:
        break;
    }
    return ret;
  }
  //#endregion

  //#region onKeyDown
  /**
   * ボタン押下時の処理
   * @param key 入力キー
   * @param inputRef 現在の項目情報
   * @param inputRtRef 前項目情報
   * @param inputNxRef 次項目情報
   */
  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {
    //console.log(key);
    let mvFlg = 0;
    switch (key) {
      case "Enter":
        setAlert('　');
        switch (inputRef.current.id) {
          case 'KBN':         // 区分設定
            mvFlg = await onKeyDown_KBN();
            break;
          case 'DENNO':       // 伝票No.
            mvFlg = await onKeyDown_DENNO(inputRef);
            break;
          case 'MISE':        // 発送店
            mvFlg = await onKeyDown_MISE();
            break;
          case 'BMNCDE':      // 分類CD（発送店側）
            mvFlg = await onKeyDown_BMNCDE();
            break;
          case 'ASTCDE':      // ＡＵ（発送店側）
            mvFlg = await onKeyDown_ASTCDE();
            break;
          case 'DENYMD':      // 発送日
            mvFlg = await onKeyDown_DENYMD(inputRef);
            break;
          case 'UIRMISE':     // 受取店
            mvFlg = await onKeyDown_UIRMISE();
            break;
          case 'UIRBMNCDE':   // 分類CD（受取店側）
            mvFlg = await onKeyDown_UIRBMNCDE();
              break;
          case 'UIRASTCDE':   // ＡＵ（受取店側）
            mvFlg = await onKeyDown_UIRASTCDE();
            break;
          case 'SUUG':    // 数量合計
            mvFlg = await onKeyDown_SUUG();
            break;
          case 'GNTG':    // 原単価合計
            mvFlg = await onKeyDown_GNTG();
            break;
          case 'BTNG':    // 売単価合計
            mvFlg = await onKeyDown_BTNG();
            break;
          case 'ret':         // 登録処理
            if (keyDisabled.current)
            {
              return;
            }
            keyDisabled.current = true;
            const ret_kbn = await onKeyDown_KBN(); // 入力エラーがなければ0が返る
            if (ret_kbn == 0) {
              isInInputConfirm = true;
              mvFlg = 2;
              await InputConfirmed()
              isInInputConfirm = false;
            }

            setTimeout(() => {
              // 処理中フラグを下げる
              keyDisabled.current = false;
            }, 1500);

            break;
          default:
            break;
        }

        switch (mvFlg) {
          case 0:
            inputNxRef.current.focus();   //次の項目に遷移
            onNextFocus(inputNxRef);
            break;
          case 1:
            inputRef.current.focus();   //現在の項目に遷移
            onNextFocus(inputRef);
            break;
          case 2:
            console.log('遷移なし');
            break;
          case 9:
            break;
          default:
            break;
        }
        break;
      case "PageUp":
        // 確認で押下時の処理
        if (inputRef.current.id == 'ret')
        {
          if (kubunRef.current?.getValue() === '1'){
            inBTNG.current.focus();
          }else if (kubunRef.current?.getValue() === '2'){
            inDENNO.current.focus();
          }else{
            inDENNO.current.focus();
          }
        }else{
          // 確認以外での遷移処理
          inputRtRef.current.focus();
        }
        break;
      case "PageDown":
        inputNxRef.current.focus();
        break;
      default:
        break;
    }
  };

  /**
   * ボタン押下時の処理(明細部)
   * @param key 入力キー
   * @param inputRef 現在の項目情報
   * @param inputRtRef 前項目情報
   * @param inputNxRef 次項目情報
   * @param inUpRef 上キー
   * @param inRgRef 右キー
   * @param inDnRef 下キー
   * @param inLfRef 左キー
   */
  const onKeyDown_Meisai =  async (key,inputRef,inputRtRef,inputNxRef,inUpRef,inRgRef,inDnRef,inLfRef) => {
    let mvFlg = 0;
    switch (key) {
      case "Enter":
        setAlert('　');
        switch (inputRef.current.id) {
          case 'SHNCDE1':     // 商品コード
            mvFlg = 2;
            shohinCode1Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(1) == 0){
              onKeyDown_SHNCDE(1);
            }
            break;
          case 'SHNCDE2':     // 商品コード
            mvFlg = 2;
            shohinCode2Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(2) == 0){
              onKeyDown_SHNCDE(2);
            }
            break;
          case 'SHNCDE3':     // 商品コード
            mvFlg = 2;
            shohinCode3Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(3) == 0){
              onKeyDown_SHNCDE(3);
            }
            break;
          case 'SHNCDE4':     // 商品コード
            mvFlg = 2;
            shohinCode4Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(4) == 0){
              onKeyDown_SHNCDE(4);
            }
            break;
          case 'SHNCDE5':     // 商品コード
            mvFlg = 2;
            shohinCode5Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(5) == 0){
              onKeyDown_SHNCDE(5);
            }
            break;
          case 'SHNCDE6':     // 商品コード
            mvFlg = 2;
            shohinCode6Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(6) == 0){
              onKeyDown_SHNCDE(6);
            }
            break;
          case 'SUU1':      // 数量1
            onKeyDown_SUU(1);
            break;
          case 'SUU2':      // 数量2
            onKeyDown_SUU(2);
            break;
          case 'SUU3':      // 数量3
            onKeyDown_SUU(3);
            break;
          case 'SUU4':      // 数量4
            onKeyDown_SUU(4);
            break;
          case 'SUU5':      // 数量5
            onKeyDown_SUU(5);
            break;
          case 'SUU6':      // 数量6
            onKeyDown_SUU(6);
            break;
          case 'GNT1':      // 原価単価1
            onKeyDown_GNT(1);
            break;
          case 'GNT2':      // 原価単価2
            onKeyDown_GNT(2);
            break;
          case 'GNT3':      // 原価単価3
            onKeyDown_GNT(3);
            break;
          case 'GNT4':      // 原価単価4
            onKeyDown_GNT(4);
            break;
          case 'GNT5':      // 原価単価5
            onKeyDown_GNT(5);
            break;
          case 'GNT6':      // 原価単価6
            onKeyDown_GNT(6);
            break;
          case 'BTN1':      // 売単価1
            onKeyDown_BTN(1);
            break;
          case 'BTN2':      // 売単価2
            onKeyDown_BTN(2);
            break;
          case 'BTN3':      // 売単価3
            onKeyDown_BTN(3);
            break;
          case 'BTN4':      // 売単価4
            onKeyDown_BTN(4);
            break;
          case 'BTN5':      // 売単価5
            onKeyDown_BTN(5);
            break;
          case 'BTN6':      // 売単価6
            onKeyDown_BTN(6);
            break;
          default:
            break;
        }

        switch (mvFlg) {
          case 0:
            inputNxRef.current.focus();   //次の項目に遷移
            onNextFocus(inputNxRef);
            break;
          case 1:
            inputRef.current.focus();   //現在の項目に遷移
            onNextFocus(inputRef);
            break;
          case 2:
            console.log('遷移なし');
            break;
          case 9:
            break;
          default:
            break;
        }
        break;
      case "PageUp":
        inUIRASTCDE.current.focus();
        break;
      case "PageDown":
        inSUUG.current.focus();
        break;
      case "ArrowUp":
        inUpRef.current.focus();      //上の項目遷移（明細用）
        break;
      case "ArrowRight":
        inRgRef.current.focus();      //右の項目遷移（明細用）
        break;
      case "ArrowDown":
        inDnRef.current.focus();      //右の項目遷移（明細用）
        break;
      case "ArrowLeft":
        inLfRef.current.focus();      //左の項目遷移（明細用）
        break;
      default:
        break;
    }
  };

  /**
   * 確認項目にフォーカス時の処理
   */
  function onFocus_ret(){
    let itKBN = kubunRef.current?.getValue();
    switch(itKBN){
      case '1':
        setAlert('確認してください！！');//TCGM0005：
        break;
      case '2':
        setAlert('確認してください！！');//TCGM0005：
        break;
      case '3':
        setAlert('削除を行いますか？');//TCGM0006：
        break;
      default:
        setAlert('　');
        break;
    }
  }
  //#endregion

  //#region ヘッダ部入力
  // 区分チェック
  const onKeyDown_KBN = async()=>{
    let ErrFlg = 0 ;
    //setAlert('　');
    kubunRef.current?.setBGColor('#FFFFFF');
    switch(kubunRef.current?.getValue()){
      case '1':
        setItkbnnm('新規');
        break;
      case '2':
        setItkbnnm('修正');
        break;
      case '3':
        setItkbnnm('削除');
        break;
      default:
        setAlert('区分に1:新規,2:修正,3:削除以外は入力できません。');//TCGE0008：
        kubunRef.current?.setBGColor('#f59d9d');
        inKBN.current.select();
        ErrFlg = 1;
        break;
    }
    console.log('戻り値：'+ErrFlg);
    setretMvFlg(ErrFlg);
    return ErrFlg;
  }

  // ヘッダ部入力 伝票No.
  const onKeyDown_DENNO = async(inputRef)=>{
    let mvFlg = 0;
    let lDENNO = Fhwith(denpyoNoRef.current?.getValue(),0,7);
    let lDENNO01 = itDENNO01;
    denpyoNoRef.current?.setValue(lDENNO);
    console.log('ldenno:%o',typeof(lDENNO)) ;
    denpyoNoRef.current?.setBGColor('#FFFFFF');
    //伝票番号
    console.log('SL_HED_DAT_5_sel:'+lDENNO+'|'+itDENNO01);

    // 一度フォーカス移動しているか確認。
    if(denpyoNoRef.current?.getFocusflg()){
      denpyoNoRef.current?.setFocusflg(false);    // フォーカス移動のフラグをＯＦＦにする。

      // 値が変更されているか確認
      if(denpyoNoRef.current?.getCheckflg() == 1){
        lDENNO01 = '';            // 変更されていたらベリファイをリセット。
      }
    }
    denpyoNoRef.current?.setCheckflg(0);   // 変更フラグをリセット。

    if (kubunRef.current?.getValue() == '1'){
      /*** 入力1回目 ***/
      if (lDENNO01 === ''){
        mvFlg = 1;
        if (isInInputConfirm) {
          setAlert('伝票№入力エラー');
          denpyoNoRef.current?.setBGColor('#f59d9d');
        } else {
          denpyoNoRef.current?.setBGColor('#FFFFFF');
        }
        setItdenno01(lDENNO);
        denpyoNoRef.current?.setValue('');
        setTimeout(() => inDENNO.current.select(),10)
        return mvFlg;
      }

      /*** 入力2回目 ***/
      // ベリファイエラー
      if (itDENNO01 != lDENNO){
        setAlert('伝票№入力エラー');//TCGE0014：
        denpyoNoRef.current?.setBGColor('#f59d9d');
        // setItdenno01('');
        setItdenno01(lDENNO);
        denpyoNoRef.current?.setValue('');
          mvFlg = 1;
        setTimeout(() => inDENNO.current.select(),10)
        return mvFlg;
      }
    } else {
      // 区分２(修正)、３(削除)の時、伝票No.は１回入力（ベリファイをしない）
    }
    denpyoNoRef.current?.setBGColor('#FFFFFF');
    if (kubunRef.current?.getValue() == '1'){
      const responseA = await fetch('/TCSA0140/SL_HED_DAT_5_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ent_ksacde: cent_ksacde,ent_mise: cent_mise,denno:lDENNO,RCNT:0})});
      const dataA = await responseA.json();
      console.log('伝票ヘッダ:%o',dataA);
      console.log('件数:%o',dataA.RCNT);
      if (dataA.RCNT >= 1){ //一件以上
        setAlert('既に登録済みの伝票です。');//TCGE0032：
        denpyoNoRef.current?.setBGColor('#f59d9d');
        inRET.current.focus();
        inputRef.current.focus();
        setItdenno01('');
        mvFlg = 1;
      }else{
        if (!isInInputConfirm) {
          mvFlg = 2;
          //setItdenno01('');
          //setAlert('　');
          if (miseCodeRef.current?.getValue() === ''){
            inMISE.current.focus();
          }else{
            inBMNCDE.current.focus();
          }
        }
      }
    }else{
      if(!isInInputConfirm){
        denpyoNoRef.current?.setCheckflg(0);
        const responseA = await fetch('/TCSA0140/SL_HED_DAT_5_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ent_ksacde: cent_ksacde,ent_mise: cent_mise,denno:lDENNO,RCNT:0,snd_knr_kbn:''})});
        const dataA = await responseA.json();
        console.log('伝票ヘッダ:%o',dataA);
        console.log('件数:%o',dataA.RCNT);
        if (dataA.RCNT >= 1){ //一件以上
          const responseB = await fetch('/TCSA0140/SL_DTL_DAT_5_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ent_ksacde: cent_ksacde,ent_mise: cent_mise,denno:lDENNO,RCNT:0})});
          const dataB = await responseB.json();
          console.log('伝票明細:%o',dataB);
          console.log('件数:%o',dataB.RCNT);
          if (dataB.RCNT >= 1){ //一件以上
            if(dataA.snd_knr_kbn == '0'){
              console.log('dataB',dataB);

              //setAlert('　');
              //伝票ヘッダ
              keijyoYMRef.current?.setValue(dataA.kijyym.substr(2,2)+'年'+dataA.kijyym.slice(-2)+'月');
              miseCodeRef.current?.setValue(dataA.mise);
              bunruiCodeRef.current?.setValue(dataA.bmncde);
              astCodeRef.current?.setValue(dataA.astcde);
              denpyoYMDRef.current?.setValue(dataA.denymd.substr(2,2)+'/'+dataA.denymd.substr(4,2)+'/'+dataA.denymd.slice(-2));
              uketoriMiseCodeRef.current?.setValue(dataA.uir_mise);
              uketoriBunruiCodeRef.current?.setValue(dataA.uir_bmncde);
              uketoriAstCodeRef.current?.setValue(dataA.uir_astcde);
              let sryfot = Number(dataA.sryfot).toLocaleString(undefined, {minimumFractionDigits: 1}); // 数量
              let gnktanfot = Number(dataA.gnktanfot).toLocaleString(undefined, {minimumFractionDigits: 2}); // 原価単価
              let biktanfot = Number(dataA.biktanfot).toLocaleString(); // 売価単価
              let bakfot = Number(dataA.bakfot).toLocaleString(); // 売価金額
              suuryoGoukeiRef.current?.setValue(sryfot);
              genkaTankaGoukeiRef.current?.setValue(gnktanfot);
              baikaTankaGoukeiRef.current?.setValue(biktanfot);
              baikaKingakuGoukeiRef.current?.setValue(bakfot);

              // ＡＵ（受取店側）移動コード取得
              const responseC = await fetch('/TCSA0140/MR_PRD_CLS_MST_AU_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({ksacde: cent_ksacde,bmncde:dataA.bmncde,astcde:dataA.astcde})});
              const dataC = await responseC.json();
              console.log(dataC);
              if (dataC.RCNT == 0) {
                setAlert('ＡＵ入力エラー');//TCGE0034：
                astCodeRef.current?.setBGColor('#f59d9d');
                idoFlagOfAstCde = '';
                setTimeout(() => inASTCDE.current.select(),1);
              }else{;
                console.log('移動フラグ:%o',dataC.idoflg);
                setItastcdeido(dataC.idoflg);
                idoFlagOfAstCde = dataC.idoflg;
              }

              // ＡＵ（受取店側）移動コード取得
              const responseD = await fetch('/TCSA0140/MR_PRD_CLS_MST_AU_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({ksacde: cent_ksacde,bmncde:dataA.uir_bmncde,astcde:dataA.uir_astcde})});
              const dataD = await responseD.json();
              console.log(dataD);
              if (dataD.RCNT == 0) {
                setAlert('ＡＵ入力エラー');//TCGE0034：
                uketoriAstCodeRef.current?.setBGColor('#f59d9d');
                setTimeout(() => inUIRASTCDE.current.select(),1);
              }else{
                console.log('移動フラグ（受取店）:%o',dataD.idoflg);
                setItuirastcdeido(dataD.idoflg);
              }

              setItmise01(dataA.mise);                // ベリファイ用
              setItbmncde01(dataA.bmncde);            // ベリファイ用
              setItastcde01(dataA.astcde);            // ベリファイ用
              setItuirmise01(dataA.uir_mise);         // ベリファイ用
              setITuirbmncde01(dataA.uir_bmncde);     // ベリファイ用
              setITuirastcde01(dataA.uir_astcde);     // ベリファイ用

              //伝票明細
              for (let i = 0; i<dataB.RCNT; i++){
                console.log('SHNCDE',dataB.res[i].SHNCDE);
                let row = i + 1;  // 明細行
                let suu = Number(dataB.res[i].SUU).toLocaleString(undefined, {minimumFractionDigits: 1}); // 数量
                let gnt = Number(dataB.res[i].GNT).toLocaleString(undefined, {minimumFractionDigits: 2}); // 原価単価
                let btn = Number(dataB.res[i].BTN).toLocaleString(); // 売価単価
                let bakkin = Number(dataB.res[i].BAKKIN).toLocaleString(); // 売価金額
                setItitemval(dataB.res[i].SHNCDE, row, 'shncde');
                setItitemval(suu, row, 'suu');
                setItitemval(gnt, row, 'gnt');
                setItitemval(btn, row, 'btn');
                setItitemval(bakkin, row, 'bakkin');
                setItitemval(dataB.res[i].ESJ_KBN, row, 'esj_kbn');


                /*
                if (i == 0){
                  shohinCode1Ref.current?.setValue(dataB.res[i].SHNCDE);
                  suuryo1Ref.current?.setValue(dataB.res[i].SUU);
                  genkaTanka1Ref.current?.setValue(dataB.res[i].GNT);
                  baikaTanka1Ref.current?.setValue(dataB.res[i].BTN);
                  baikaKingaku1Ref.current?.setValue(dataB.res[i].BAKKIN);
                  setItesj_kbn1(dataB.res[i].ESJ_KBN);
                }else if (i == 1){
                  shohinCode2Ref.current?.setValue(dataB.res[i].SHNCDE);
                  suuryo2Ref.current?.setValue(dataB.res[i].SUU);
                  genkaTanka2Ref.current?.setValue(dataB.res[i].GNT);
                  baikaTanka2Ref.current?.setValue(dataB.res[i].BTN);
                  baikaKingaku2Ref.current?.setValue(dataB.res[i].BAKKIN);
                  setItesj_kbn2(dataB.res[i].ESJ_KBN);
                }else if (i == 2){
                  shohinCode3Ref.current?.setValue(dataB.res[i].SHNCDE);
                  suuryo3Ref.current?.setValue(dataB.res[i].SUU);
                  genkaTanka3Ref.current?.setValue(dataB.res[i].GNT);
                  baikaTanka3Ref.current?.setValue(dataB.res[i].BTN);
                  baikaKingaku3Ref.current?.setValue(dataB.res[i].BAKKIN);
                  setItesj_kbn3(dataB.res[i].ESJ_KBN);
                }else if (i == 3){
                  shohinCode4Ref.current?.setValue(dataB.res[i].SHNCDE);
                  suuryo4Ref.current?.setValue(dataB.res[i].SUU);
                  genkaTanka4Ref.current?.setValue(dataB.res[i].GNT);
                  baikaTanka4Ref.current?.setValue(dataB.res[i].BTN);
                  baikaKingaku4Ref.current?.setValue(dataB.res[i].BAKKIN);
                  setItesj_kbn4(dataB.res[i].ESJ_KBN);
                }else if (i == 4){
                  shohinCode5Ref.current?.setValue(dataB.res[i].SHNCDE);
                  suuryo5Ref.current?.setValue(dataB.res[i].SUU);
                  genkaTanka5Ref.current?.setValue(dataB.res[i].GNT);
                  baikaTanka5Ref.current?.setValue(dataB.res[i].BTN);
                  baikaKingaku5Ref.current?.setValue(dataB.res[i].BAKKIN);
                  setItesj_kbn5(dataB.res[i].ESJ_KBN);
                }else if (i == 5){
                  shohinCode6Ref.current?.setValue(dataB.res[i].SHNCDE);
                  suuryo6Ref.current?.setValue(dataB.res[i].SUU);
                  genkaTanka6Ref.current?.setValue(dataB.res[i].GNT);
                  baikaTanka6Ref.current?.setValue(dataB.res[i].BTN);
                  baikaKingaku6Ref.current?.setValue(dataB.res[i].BAKKIN);
                  setItesj_kbn6(dataB.res[i].ESJ_KBN);
                }
                */
              };

              //明細行に移動する為にmvFlg立てる
              if (!isInInputConfirm) {
                mvFlg = 2;
                //setItdenno01('');
                if (kubunRef.current?.getValue() == '2'){
                  inSHNCDE1.current.focus();
                }else{
                  setTimeout(() => {inRET.current.select();},10)
                }
              }
            }else{
              setAlert('送信処理済みの伝票です。（修正・削除不可）');//TCGE0029：
              denpyoNoRef.current?.setBGColor('#f59d9d');
              inRET.current.focus();
              inputRef.current.focus();
              setItdenno01('');
              mvFlg = 1;
            }
          }else{
            setAlert('伝票の明細が登録されていません。');//TCGE0033：
            denpyoNoRef.current?.setBGColor('#f59d9d');
            inRET.current.focus();
            inputRef.current.focus();
            setItdenno01('');
            mvFlg = 1;
          }
        }else{
          setAlert('伝票が登録されていません。');//TCGE0028：
          denpyoNoRef.current?.setBGColor('#f59d9d');
          inRET.current.focus();
          inputRef.current.focus();
          setItdenno01('');
          mvFlg = 1;
        }
      }
    }

    return mvFlg;
  }

  // ヘッダ部入力 発送店
  const onKeyDown_MISE = async()=>{
    let mvFlg = 0;
    let lMISE = Fhwith(miseCodeRef.current?.getValue(),0,3);
    let lMISE01 = itMISE01;//20231030
    miseCodeRef.current?.setValue(lMISE);
    miseCodeRef.current?.setBGColor('#FFFFFF');
    //店コード（発送店）
    console.log('各店FLG'+state.K_FLG);

    // 2023/10/16 ＜各店＞時のベリファイ追加
    if (state.ETC ==='K'){
      // 一度フォーカス移動しているか確認。//20231030
      if(miseCodeRef.current?.getFocusflg()){
        miseCodeRef.current?.setFocusflg(false);    // フォーカス移動のフラグをＯＦＦにする。

        // 値が変更されているか確認
        if(miseCodeRef.current?.getCheckflg() == 1){
          lMISE01 = '';            // 変更されていたらベリファイをリセット。
          setItmise01('');
        }
      }
      miseCodeRef.current?.setCheckflg(0);   // 変更フラグをリセット。//20231030
      if(lMISE01 == '' ){
        if(state.K_FLG == 0 && lMISE != state.MISES){
          mvFlg = 1;
          setAlert('店コード入力  エラー');//TCGE0010：
          miseCodeRef.current?.setBGColor('#f59d9d');
          miseCodeRef.current?.setValue('');
          setTimeout(() => inMISE.current.select(),1);
        }else if(lMISE === ''){
          mvFlg = 1;
          setAlert('店コード入力  エラー');//TCGE0010：
          miseCodeRef.current?.setBGColor('#f59d9d');
          miseCodeRef.current?.setValue('');
          setTimeout(() => inMISE.current.select(),1);
        }else{
          mvFlg = 1;
          miseCodeRef.current?.setBGColor('#FFFFFF');
          miseCodeRef.current?.setValue('');

          //店管理マスタ読込
          console.log('mr_shp_mstm:'+cent_ksacde+'|'+lMISE);

          const mapResult = regionList.filter(item=>{return item.id === cent_ksacde+lMISE;});
          // const response01 = await fetch('/MR_SHP_MSTM_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
          // body: JSON.stringify({ksacde: cent_ksacde,mise: lMISE,bmncde:''})});
          // const data01 = await response01.json();
          // if (data01.RCNT == 0){
          if (mapResult.length == 0){
            mvFlg = 1;
            setAlert('店コード入力  エラー');//TCGE0010：
            miseCodeRef.current?.setBGColor('#f59d9d');
            setTimeout(() => inMISE.current.select(),1);
            miseCodeRef.current?.setValue('');
            //setItmise01('');
          }else{
            //店舗マスタ読込
            // const response0 = await fetch('/MR_STR_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
            // body: JSON.stringify({KSACDE: cent_ksacde,MISE: lMISE})});
            // const data0 = await response0.json();
            // if (data0.RCNT == 0){
            //   mvFlg = 1;
            //   setAlert('店コード入力エラー');//TCGE0010：
            //   miseCodeRef.current?.setBGColor('#f59d9d');
            //   setTimeout(() => inMISE.current.select(),1);
            // }else{
              console.log('店名:'+mapResult[0].MISEMEI);
              setItmise01(lMISE);
              setTimeout(() => inMISE.current.select(),1);
            // }
          }
        }
      }else{
        if (lMISE!=lMISE01){
          setAlert('店コード入力  エラー');//TCGE0010：
          miseCodeRef.current?.setBGColor('#f59d9d');
          //店管理マスタ読込
          const mapResult = regionList.filter(item=>{return item.id === cent_ksacde+lMISE;});
          // console.log('mr_shp_mstm:'+cent_ksacde+'|'+lMISE);
          // const response01 = await fetch('/MR_SHP_MSTM_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
          // body: JSON.stringify({ksacde: cent_ksacde,mise: lMISE,bmncde:''})});
          // const data01 = await response01.json();
          // if (data01.RCNT == 0){
          if (mapResult.length == 0){
            mvFlg = 1;
            setAlert('店コード入力  エラー');//TCGE0010：
            miseCodeRef.current?.setBGColor('#f59d9d');
            setTimeout(() => inMISE.current.select(),1);
            setItmise01('');
          }else{
            //店舗マスタ読込
            // const response0 = await fetch('/MR_STR_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
            // body: JSON.stringify({KSACDE: cent_ksacde,MISE: lMISE})});
            // const data0 = await response0.json();
            // if (data0.RCNT == 0){
            //   mvFlg = 1;
            //   setAlert('店コード入力エラー');//TCGE0010：
            //   miseCodeRef.current?.setBGColor('#f59d9d');
            //   setTimeout(() => inMISE.current.select(),1);
            //   setItmise01('');
            // }else{
              mvFlg = 0;
              setItmise01(lMISE);
              miseCodeRef.current?.setValue('');
              console.log('店名:'+mapResult[0].MISEMEI);
            // }
          }

          mvFlg = 1;
        }else{
          miseCodeRef.current?.setBGColor('#FFFFFF');
          mvFlg = 0;
          // //店管理マスタ読込
          // console.log('mr_shp_mstm:'+cent_ksacde+'|'+lMISE);
          // const response01 = await fetch('/MR_SHP_MSTM_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
          // body: JSON.stringify({ksacde: cent_ksacde,mise: lMISE,bmncde:''})});
          // const data01 = await response01.json();
          // if (data01.RCNT == 0){
          //   mvFlg = 1;
          //   setAlert('店コード入力  エラー');//TCGE0010：
          //   miseCodeRef.current?.setBGColor('#f59d9d');
          //   setTimeout(() => inMISE.current.select(),1);
          //   miseCodeRef.current?.setValue('');
          //   //setItmise01('');
          // }else{
          //   //店舗マスタ読込
          //   const response0 = await fetch('/MR_STR_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
          //   body: JSON.stringify({KSACDE: cent_ksacde,MISE: lMISE})});
          //   const data0 = await response0.json();
          //   if (data0.RCNT == 0){
          //     mvFlg = 1;
          //     setAlert('店コード入力エラー');//TCGE0010：
          //     miseCodeRef.current?.setBGColor('#f59d9d');
          //     setTimeout(() => inMISE.current.select(),1);
          //   }else{
          //     mvFlg = 0;
          //     console.log('店名:'+data0.MISEMEI);
          //   }
          // }
        }
      }
    }else{
      // // 各店FLGが0の場合//20231030
      // if (state.K_FLG == 0 ){
      //   if (lMISE != state.MISES){
      //     mvFlg = 1;
      //     setAlert('店コード入力  エラー');//TCGE0010：
      //     miseCodeRef.current?.setBGColor('#f59d9d');
      //     miseCodeRef.current?.setValue('');
      //     setTimeout(() => inMISE.current.select(),1);
      //   }
      // }else{
      //   //店管理マスタ読込
      //   console.log('mr_shp_mstm:'+state.KSACDE+'|'+lMISE);
      //   const response01 = await fetch('/MR_SHP_MSTM_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      //   body: JSON.stringify({ksacde: state.KSACDE,mise: lMISE,bmncde:''})});
      //   const data01 = await response01.json();
      //   if (data01.RCNT == 0){
      //     mvFlg = 1;
      //     setAlert('店コード入力  エラー');//TCGE0010：
      //     miseCodeRef.current?.setBGColor('#f59d9d');
      //     setTimeout(() => inMISE.current.select(),1);
      //     // miseCodeRef.current?.setValue('');
      //   }else{
      //     //店舗マスタ読込
      //     const response0 = await fetch('/MR_STR_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      //     body: JSON.stringify({KSACDE: state.KSACDE,MISE: lMISE})});
      //     const data0 = await response0.json();
      //     if (data0.RCNT == 0){
      //       mvFlg = 1;
      //       setAlert('店コード入力エラー');//TCGE0010：
      //       miseCodeRef.current?.setBGColor('#f59d9d');
      //       setTimeout(() => inMISE.current.select(),1);
      //     }else{
      //       mvFlg = 0;
      //       console.log('店名:'+data0.MISEMEI);
      //     }
      //   }
      // }
    }
    return mvFlg;
  }

  // ヘッダ部入力 分類CD（発送店側）
  const onKeyDown_BMNCDE = async()=>{
    let mvFlg = 0;
    let lBMNCDE = Fhwith(bunruiCodeRef.current?.getValue(),0,4);
    let lBMNCDE01 = itBMNCDE01;
    bunruiCodeRef.current?.setValue(lBMNCDE);
    bunruiCodeRef.current?.setBGColor('#FFFFFF');
    //分類CD（発送店）
    console.log('mr_shp_mstm:'+lBMNCDE+'|'+itBMNCDE01+'|'+bunruiCodeRef.current?.getFocusflg());
    // 2023/11/22 分類CD抜けた後に、再入力する場合は最初から
    if (bunruiCodeRef.current?.getFocusflg() == true)
    {
      bunruiCodeRef.current?.setFocusflg(false);
      // 値が変更されているか確認
      if(bunruiCodeRef.current?.getCheckflg() == 1){
        lBMNCDE01 = '';            // 変更されていたらベリファイをリセット。
      }
    }
    bunruiCodeRef.current?.setCheckflg(0);   // 変更フラグをリセット。
    console.log('チェック:'+lBMNCDE+'/'+lBMNCDE01);
    if (lBMNCDE01 == ''){
      mvFlg = 1;
      bunruiCodeRef.current?.setValue('');
      if (isInInputConfirm) {
        setAlert('分類コード入力エラー');//TCGE0011：
        bunruiCodeRef.current?.setBGColor('#f59d9d');
      }else{
        if (lBMNCDE === '1212')
        {
          setAlert('分類コード入力エラー');//TCGE0011：
          bunruiCodeRef.current?.setBGColor('#f59d9d');
          setTimeout(() => inBMNCDE.current.select(),1);
        }else
        {
          const mapResult = regionList2.filter(item=>{return item.id === cent_ksacde+miseCodeRef.current?.getValue()+lBMNCDE;});
          // const responseA = await fetch('/MR_SHP_MSTM_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
          // body: JSON.stringify({ksacde: cent_ksacde,mise: miseCodeRef.current?.getValue(),bmncde:lBMNCDE})});
          // const dataA = await responseA.json();
          // if (dataA.RCNT == 0)
          if (mapResult.length == 0)
          {
            setAlert('分類コード入力エラー');//TCGE0011：
            bunruiCodeRef.current?.setBGColor('#f59d9d');
            setTimeout(() => inBMNCDE.current.select(),1);
          }else{
            bunruiCodeRef.current?.setBGColor('#ffffff');
            setItbmncde01(lBMNCDE);
          }
        }
      }
      // setTimeout(() => inBMNCDE.current.select(),1);
      return mvFlg;
    }else{
      if (lBMNCDE01 != lBMNCDE){
        mvFlg = 1;
        setAlert('分類コード入力エラー');//TCGE0011：
        bunruiCodeRef.current?.setBGColor('#f59d9d');
        bunruiCodeRef.current?.setValue('');
        if (lBMNCDE === '1212')
        {
          setTimeout(() => inBMNCDE.current.select(),1);
          setItbmncde01('');
        }else{
          const mapResult = regionList2.filter(item=>{return item.id === cent_ksacde+miseCodeRef.current?.getValue()+lBMNCDE;});
          // const responseA = await fetch('/MR_SHP_MSTM_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
          // body: JSON.stringify({ksacde: cent_ksacde,mise: miseCodeRef.current?.getValue(),bmncde:lBMNCDE})});
          // const dataA = await responseA.json();
          // if (dataA.RCNT == 0)
          if (mapResult.length == 0)
          {
            setItbmncde01('');
            setTimeout(() => inBMNCDE.current.select(),1);
          }else{
            setItbmncde01(lBMNCDE);
          }
        }
        // setTimeout(() => inBMNCDE.current.select(),1);
      }else{
        // const responseA = await fetch('/MR_SHP_MSTM_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        // body: JSON.stringify({ksacde: cent_ksacde,mise: miseCodeRef.current?.getValue(),bmncde:lBMNCDE})});
        // const dataA = await responseA.json();
        // console.log(dataA);
        // if (dataA.RCNT == 0)
        // {
        //   mvFlg = 1;
        //   setAlert('分類コード入力エラー');//TCGE0011：
        //   bunruiCodeRef.current?.setBGColor('#f59d9d');
        //   setItbmncde01('');
        //   setTimeout(() => inBMNCDE.current.select(),1);
        // }else{
          mvFlg = 0;
          bunruiCodeRef.current?.setBGColor('#ffffff');
          //setAlert('　');
          //setItbmncde01('');
        // }
      }
    }
    return mvFlg;
  }

  // ヘッダ部入力 ＡＵ（発送店側）
  const onKeyDown_ASTCDE = async()=>{
    let mvFlg = 0;
    let lASTCDE = Fhwith(astCodeRef.current?.getValue(),0,3);
    let lASTCDE01 = itASTCDE01;
    astCodeRef.current?.setValue(lASTCDE);
    astCodeRef.current?.setBGColor('#FFFFFF');
    //ＡＵ（発送店）
    console.log('mr_shp_mst_au:'+lASTCDE+'|'+itASTCDE01);

    // 一度フォーカス移動しているか確認。
    if(astCodeRef.current?.getFocusflg()){
      astCodeRef.current?.setFocusflg(false);    // フォーカス移動のフラグをＯＦＦにする。

      // 値が変更されているか確認
      if(astCodeRef.current?.getCheckflg() == 1){
        lASTCDE01 = '';            // 変更されていたらベリファイをリセット。
      }
    }
    astCodeRef.current?.setCheckflg(0);   // 変更フラグをリセット。

    if (lASTCDE01 == '') {
      mvFlg = 2;
      astCodeRef.current?.setValue('');
      if (isInInputConfirm) {
        setAlert('ＡＵ入力エラー');//TCGE0034：
        astCodeRef.current?.setBGColor('#f59d9d');
      }else{
        const responseA = await fetch('/TCSA0140/MR_PRD_CLS_MST_AU_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ksacde: cent_ksacde,bmncde:bunruiCodeRef.current?.getValue(),astcde:lASTCDE})});
        const dataA = await responseA.json();
        console.log(dataA);
        if (dataA.RCNT == 0)
        {
          setAlert('ＡＵ入力エラー');//TCGE0034：
          astCodeRef.current?.setBGColor('#f59d9d');
          setItastcde01('');
          setItastcdeido('');
          idoFlagOfAstCde = '';
          setTimeout(() => inASTCDE.current.select(),1);
        }else{
          console.log('移動フラグ:%o',dataA.idoflg);
          astCodeRef.current?.setBGColor('#ffffff');
          setItastcdeido(dataA.idoflg);
          setItastcde01(lASTCDE);
          inASTCDE.current.focus();
        }
      }
      //setTimeout(() => inASTCDE.current.select(),1);
      return mvFlg;
    }else{
      if (itASTCDE01 != lASTCDE)
      {
        mvFlg = 1;
        setAlert('ＡＵ入力エラー');//TCGE0034：
        astCodeRef.current?.setBGColor('#f59d9d');
        astCodeRef.current?.setValue('');
        const responseA = await fetch('/TCSA0140/MR_PRD_CLS_MST_AU_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ksacde: cent_ksacde,bmncde:bunruiCodeRef.current?.getValue(),astcde:lASTCDE})});
        const dataA = await responseA.json();
        console.log(dataA);
        if (dataA.RCNT == 0)
        {
          setAlert('ＡＵ入力エラー');//TCGE0034：
          astCodeRef.current?.setBGColor('#f59d9d');
          setItastcde01('');
          setItastcdeido('');
          idoFlagOfAstCde = '';
          setTimeout(() => inASTCDE.current.select(),1);
        }else{
          console.log('移動フラグ:%o',dataA.idoflg);
          setItastcdeido(dataA.idoflg);
          setItastcde01(lASTCDE);
        }
        // setItastcde01(lASTCDE);
        // setItastcdeido('');
        // idoFlagOfAstCde = '';
        //setTimeout(() => inASTCDE.current.select(),1);
      }else{
        const responseA = await fetch('/TCSA0140/MR_PRD_CLS_MST_AU_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ksacde: cent_ksacde,bmncde:bunruiCodeRef.current?.getValue(),astcde:lASTCDE})});
        const dataA = await responseA.json();
        console.log(dataA);
        if (dataA.RCNT == 0)
        {
          mvFlg = 1;
          setAlert('ＡＵ入力エラー');//TCGE0034：
          astCodeRef.current?.setBGColor('#f59d9d');
          setItastcde01('');
          setItastcdeido('');
          idoFlagOfAstCde = '';
          setTimeout(() => inASTCDE.current.select(),1);
        }else{
          mvFlg = 0;
          console.log('移動フラグ:%o',dataA.idoflg);
          astCodeRef.current?.setBGColor('#ffffff');
          setItastcdeido(dataA.idoflg);
          idoFlagOfAstCde = dataA.idoflg;
        }
      }
    }
    return mvFlg;
  }

  // ヘッダ部入力 発送日
  const onKeyDown_DENYMD = async(inputRef)=>{
    let mvFlg = 0;
    var strDate = '20'+denpyoYMDRef.current?.getValue();
    var dtdate = new Date(strDate);
    denpyoYMDRef.current?.setBGColor('#FFFFFF');
    //日付チェック
    console.log('ckDate(strDate):%o',ckDate(strDate))
    if (ckDate(strDate)===true){
      console.log('発送日チェック1|'+strDate);
      console.log('日付範囲');
      let dt = new Date(state.SDAY);
      let dts = new Date(dt.getFullYear()-1,dt.getMonth(),dt.getDate());
      let dte = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate());
      console.log('日付'+dts+'<='+dtdate+'<'+dte);
      if (dts <= dtdate && dtdate <= dte)
      {
        //const kret = KeijoYM(strDate);
        const kret = KeijoYM('20'+denpyoYMDRef.current?.getValue());
        const kret2 = (String(kret).replace('年','')).replace('月','');
        keijyoYMRef.current?.setValue(kret);
        setItkijyym2(kret2);
      }else{
        denpyoYMDRef.current?.setBGColor('#f59d9d');
        setAlert('日付入力エラー');//TCGE0016:
        // denpyoYMDRef.current?.setValue('');
        // inRET.current.focus();
        inputRef.current.focus();
        setTimeout(() => inputRef.current.select(),1)
        mvFlg = 1;
      }
    }else{
      denpyoYMDRef.current?.setBGColor('#f59d9d');
      setAlert('日付入力エラー');//TCGE0016:
      // denpyoYMDRef.current?.setValue('');
      // inRET.current.focus();
      inputRef.current.focus();
      setTimeout(() => inputRef.current.select(),1)
      mvFlg = 1;
    }

    if (mvFlg ===0){
      //廃止店舗
      const response0 = await fetch('/MR_STR_MST2_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({KSACDE: cent_ksacde,MISE: miseCodeRef.current?.getValue(),YMD:'20'+denpyoYMDRef.current?.getValue().replace(/(\\|\/)/g,'')})});
      const data0 = await response0.json();
      if (data0.RCNT == 0){
        mvFlg = 1;
        setAlert('店コード入力エラー');//TCGE0010：
        miseCodeRef.current?.setBGColor('#f59d9d');
      }else{
        console.log('店名:'+data0.MISEMEI);
      }
    }
    return mvFlg;
  }

  // ヘッダ部入力 受取店
  const onKeyDown_UIRMISE = async()=>{
    let mvFlg = 0;
    let lUIRMISE = Fhwith(uketoriMiseCodeRef.current?.getValue(),0,3);
    let lUIRMISE01 = itUIRMISE01;
    uketoriMiseCodeRef.current?.setValue(lUIRMISE);
    uketoriMiseCodeRef.current?.setBGColor('#FFFFFF');
    //店コード（発送店）
    console.log('mr_shp_mstm:'+lUIRMISE+'|'+itUIRMISE01);

    // 一度フォーカス移動しているか確認。
    if (state.ETC !=='K'){
      if(uketoriMiseCodeRef.current?.getFocusflg()){
        uketoriMiseCodeRef.current?.setFocusflg(false);    // フォーカス移動のフラグをＯＦＦにする。

        // 値が変更されているか確認
        if(uketoriMiseCodeRef.current?.getCheckflg() == 1){
          lUIRMISE01 = '';            // 変更されていたらベリファイをリセット。
        }
      }
      uketoriMiseCodeRef.current?.setCheckflg(0);   // 変更フラグをリセット。

      if (lUIRMISE01 == '' ){
        mvFlg = 1;
        // setItuirmise01(lUIRMISE);
        uketoriMiseCodeRef.current?.setValue('');
        if (isInInputConfirm) {
          setAlert('店コード入力エラー');//TCGE0010：
          uketoriMiseCodeRef.current?.setBGColor('#f59d9d');
        }else{
          mvFlg = 1;
          miseCodeRef.current?.setBGColor('#FFFFFF');
          uketoriMiseCodeRef.current?.setValue('');
          //店管理マスタ読込
          const mapResult = regionList.filter(item=>{return item.id === cent_ksacde+lUIRMISE;});
          // console.log('mr_shp_mstm:'+cent_ksacde+'|'+lUIRMISE);
          // const response01 = await fetch('/MR_SHP_MSTM_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
          // body: JSON.stringify({ksacde: cent_ksacde,mise: lUIRMISE,bmncde:''})});
          // const data01 = await response01.json();
          // if (data01.RCNT == 0){
          if (mapResult.length == 0){
              setAlert('店コード入力エラー');//TCGE0010：
            uketoriMiseCodeRef.current?.setBGColor('#f59d9d');
            setTimeout(() => inUIRMISE.current.select(),1);
          }else{
            //店舗マスタ読込
            // const response0 = await fetch('/MR_STR_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
            // body: JSON.stringify({KSACDE: cent_ksacde,MISE: lUIRMISE})});
            // const data0 = await response0.json();
            // if (data0.RCNT == 0){
            //   setAlert('店コード入力エラー');//TCGE0010：
            //   uketoriMiseCodeRef.current?.setBGColor('#f59d9d');
            //   setTimeout(() => inUIRMISE.current.select(),1);
            // }else{
              console.log('店名:'+mapResult[0].MISEMEI);
              setItuirmise01(lUIRMISE);
              setTimeout(() => inUIRMISE.current.select(),1);
            // }
          }
        }
        // setTimeout(() => inUIRMISE.current.select(),1);
        return mvFlg;
      }else{
        if (lUIRMISE != lUIRMISE01){
          mvFlg = 1;
          setAlert('店コード入力エラー');//TCGE0010：
          uketoriMiseCodeRef.current?.setBGColor('#f59d9d');
          uketoriMiseCodeRef.current?.setValue('');
          //店管理マスタ読込
          const mapResult = regionList.filter(item=>{return item.id === cent_ksacde+lUIRMISE;});
          // console.log('mr_shp_mstm:'+cent_ksacde+'|'+miseCodeRef.current?.getValue());
          // const response01 = await fetch('/MR_SHP_MSTM_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
          // body: JSON.stringify({ksacde: cent_ksacde,mise: lUIRMISE,bmncde:''})});
          // const data01 = await response01.json();
          // if (data01.RCNT == 0){
          if (mapResult.length == 0){
            setItuirmise01('');
            setTimeout(() => inUIRMISE.current.select(),1);
          }else{
            //店舗マスタ読込
            // const response0 = await fetch('/MR_STR_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
            // body: JSON.stringify({KSACDE: cent_ksacde,MISE: lUIRMISE})});
            // const data0 = await response0.json();
            // if (data0.RCNT == 0){
            //   setItuirmise01('');
            //   setTimeout(() => inUIRMISE.current.select(),1);
            // }else{
              console.log('店名:'+mapResult[0].MISEMEI);
              setItuirmise01(lUIRMISE);
            // }
          }
        }else{
          mvFlg = 0;
          uketoriMiseCodeRef.current?.setBGColor('#ffffff');
        }
      }
    }
    return mvFlg;
  }

  // ヘッダ部入力 分類CD（受取店側）
  const onKeyDown_UIRBMNCDE = async()=>{
    let mvFlg = 0;
    let lUIRBMNCDE = Fhwith(uketoriBunruiCodeRef.current?.getValue(),0,4);
    let lUIRBMNCDE01 = itUIRBMNCDE01;
    uketoriBunruiCodeRef.current?.setValue(lUIRBMNCDE);
    uketoriBunruiCodeRef.current?.setBGColor('#FFFFFF');
    //分類CD（受取店）
    console.log('mr_shp_mstm:'+lUIRBMNCDE+'|'+itUIRBMNCDE01);

    // 一度フォーカス移動しているか確認。
    if(uketoriBunruiCodeRef.current?.getFocusflg()){
      uketoriBunruiCodeRef.current?.setFocusflg(false);    // フォーカス移動のフラグをＯＦＦにする。

      // 値が変更されているか確認
      if(uketoriBunruiCodeRef.current?.getCheckflg() == 1){
        lUIRBMNCDE01 = '';            // 変更されていたらベリファイをリセット。
      }
    }
    uketoriBunruiCodeRef.current?.setCheckflg(0);   // 変更フラグをリセット。

    /*** 入力1回目 ***/
    if (lUIRBMNCDE01 == '')
    {
      mvFlg = 1;
      setITuirbmncde01(lUIRBMNCDE);
      uketoriBunruiCodeRef.current?.setValue('');
      setITuirbmncde01('');
      if (isInInputConfirm) {
        setAlert('分類コード入力エラー');//TCGE0011：
        uketoriBunruiCodeRef.current?.setBGColor('#f59d9d');
      }else{
        const mapResult = regionList2.filter(item=>{return item.id === cent_ksacde+uketoriMiseCodeRef.current?.getValue()+lUIRBMNCDE;});
        // const responseA = await fetch('/MR_SHP_MSTM_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        // body: JSON.stringify({ksacde: cent_ksacde,mise: miseCodeRef.current?.getValue(),bmncde:lUIRBMNCDE})});
        // const dataA = await responseA.json();
        // if (dataA.RCNT == 0)
        if (mapResult.length == 0)
        {
          setAlert('分類コード入力エラー');//TCGE0011：
          uketoriBunruiCodeRef.current?.setBGColor('#f59d9d');
          setTimeout(() => inUIRBMNCDE.current.select(),1);
        }else{
          uketoriBunruiCodeRef.current?.setBGColor('#ffffff');
          setITuirbmncde01(lUIRBMNCDE);
        }
      }
      // setTimeout(() => inUIRBMNCDE.current.select(),1);
      return mvFlg;
    }else{
      /*** 入力2回目 ***/
      // ベリファイエラー
      if (lUIRBMNCDE01 != lUIRBMNCDE){
        mvFlg = 1;
        setAlert('分類コード入力エラー');//TCGE0011：
        uketoriBunruiCodeRef.current?.setBGColor('#f59d9d');
        uketoriBunruiCodeRef.current?.setValue('');

        const mapResult = regionList2.filter(item=>{return item.id === cent_ksacde+uketoriMiseCodeRef.current?.getValue()+lUIRBMNCDE;});
        // const responseA = await fetch('/MR_SHP_MSTM_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        // body: JSON.stringify({ksacde: cent_ksacde,mise: miseCodeRef.current?.getValue(),bmncde:lUIRBMNCDE})});
        // const dataA = await responseA.json();
        // if (dataA.RCNT == 0)
        if (mapResult.length == 0)
        {
          setITuirbmncde01('');
          setTimeout(() => inUIRBMNCDE.current.select(),1);
        }else{
          setITuirbmncde01(lUIRBMNCDE);
        }
        // setTimeout(() => inUIRBMNCDE.current.select(),1);
        return mvFlg;
      }

      // const responseA = await fetch('/MR_SHP_MSTM_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      // body: JSON.stringify({ksacde: cent_ksacde,mise: uketoriMiseCodeRef.current?.getValue(),bmncde:lUIRBMNCDE})});
      // const dataA = await responseA.json();
      // console.log(dataA);

      // // 入力値エラー
      // if (dataA.RCNT == 0){
      //   mvFlg = 1;
      //   setAlert('分類コード入力エラー');//TCGE0011：
      //   uketoriBunruiCodeRef.current?.setBGColor('#f59d9d');
      //   setITuirbmncde01('');
      //   setTimeout(() => inUIRBMNCDE.current.select(),1);

      //   return mvFlg;
      // }

      // 店内移動時（発送店＝受取店）の処理
      if(miseCodeRef.current?.getValue() == uketoriMiseCodeRef.current?.getValue()){
        // 分類CD（発送店側）≠分類CD（受取店側）でなければエラー
        if(bunruiCodeRef.current?.getValue() == uketoriBunruiCodeRef.current?.getValue()){
          mvFlg = 1;
          setAlert('同一部門への店内移動は出来ません。');//TCGE0084：
          uketoriBunruiCodeRef.current?.setBGColor('#f59d9d');
          setITuirbmncde01('');
          setTimeout(() => inUIRBMNCDE.current.select(),1);
          return mvFlg;
        }

        let idoBmnList = ['1110','1120','1125','1130','1131','1140','1150','1210','1230'];
        console.log(idoBmnList.findIndex(idoBmn => idoBmn === bunruiCodeRef.current?.getValue()));
        console.log(idoBmnList.findIndex(idoBmn => idoBmn === uketoriBunruiCodeRef.current?.getValue()));

        if(idoBmnList.findIndex(idoBmn => idoBmn === bunruiCodeRef.current?.getValue()) == -1
        || idoBmnList.findIndex(idoBmn => idoBmn === uketoriBunruiCodeRef.current?.getValue()) == -1)
        {
          mvFlg = 1;
          setAlert('発送店分類と受取店分類が一致しません。');//TCGE0084：
          uketoriBunruiCodeRef.current?.setBGColor('#f59d9d');
          setITuirbmncde01('');
          setTimeout(() => inUIRBMNCDE.current.select(),1);
          return mvFlg;
        }
      }

      // 店外移動時（発送店≠受取店）の処理
      else{
        // 部門間移動許可マスタ　発送店存在チェック
        uketoriBunruiCodeRef.current?.setBGColor('#ffffff');
        console.log('部門間チェック１ 発送店存在チェック');
        const responseHas1 = await fetch('/TCSA0140/existsHasMise',{method: 'POST', headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ has_mise:miseCodeRef.current?.getValue()})});
        const dataHas1 = await responseHas1.json();
        let has_mise = '';
        if (dataHas1.RCNT === 0) {
          has_mise = 'MIS';
        }else{
          has_mise =miseCodeRef.current?.getValue();
        }

        // 部門間移動許可マスタ　受入店存在チェック
        console.log('部門間チェック１ 受入店存在チェック');
        const responseUir1 = await fetch('/TCSA0140/existsUirMise',{method: 'POST', headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ uir_mise:uketoriMiseCodeRef.current?.getValue() })});
        const dataUir1 = await responseUir1.json();
        let uir_mise = '';
        if (dataUir1.RCNT === 0) {
          uir_mise = 'MIS';
        }else{
          uir_mise = uketoriMiseCodeRef.current?.getValue();
        }

        // 2023/12/06 店間移動時　発送、受取共に部門間移動許可マスタに存在しない場合は、部門＆AUが一致しなければならない
        if ((has_mise === 'MIS' && uir_mise === 'MIS'))
        {
          if (bunruiCodeRef.current?.getValue() !== lUIRBMNCDE)
          {
            mvFlg = 1;
            setAlert('発送店分類と受取店分類が一致しません。');//TCGE0035：
            uketoriBunruiCodeRef.current?.setBGColor('#f59d9d');
            setITuirbmncde01('');
            setTimeout(() => inUIRBMNCDE.current.select(),1);
          }else{
            mvFlg = 0;
          }
        }else{
          // 部門間チェック１
          console.log('部門間チェック１ 発送側検索');
          // 部門間移動許可マスタをチェック
          const responseB = await fetch('/TCSA0140/SMR_BMN_MVE_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ has_mise:has_mise,has_bmncde:bunruiCodeRef.current?.getValue(),uir_mise:uir_mise, uir_bmncde: lUIRBMNCDE})});
          const dataB = await responseB.json();
          console.log(dataB);
          console.log('部門間チェック１ 発送側検索 件数:%o',dataB.RCNT);
          if (dataB.RCNT !== 0){
            mvFlg = 0;
            //setAlert('　');
            //setITuirbmncde01('');
          }else{
            // 部門間チェック２
            console.log('部門間チェック２ 受取側検索');
            // 部門間移動許可マスタをチェック
            const responseC = await fetch('/TCSA0140/SMR_BMN_MVE_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ has_mise:uir_mise, has_bmncde:lUIRBMNCDE, uir_mise:has_mise, uir_bmncde:bunruiCodeRef.current?.getValue()})});

            const dataC = await responseC.json();
            console.log(dataC);
            console.log('部門間チェック２ 受取側検索 件数:%o',dataC.RCNT);
            if (dataC.RCNT !== 0){
              mvFlg = 0;
              //setAlert('　');
              //setITuirbmncde01('');
            }else{
              // 部門間チェック３
              console.log('部門間チェック３');
              if (bunruiCodeRef.current?.getValue() !== lUIRBMNCDE){
                // 発送店と受取店の分類コードが不一致
                mvFlg = 1;
                setAlert('発送店分類と受取店分類が一致しません。');//TCGE0035：
                uketoriBunruiCodeRef.current?.setBGColor('#f59d9d');
                setITuirbmncde01('');
                setTimeout(() => inUIRBMNCDE.current.select(),1);
              }else{
                mvFlg = 0;
                //setAlert('　');
                //setITuirbmncde01('');
              }
            }
          }
        }
      }
    }
    return mvFlg;
  }

  // ヘッダ部入力 ＡＵ（受取店側）
  const onKeyDown_UIRASTCDE = async()=>{
    let mvFlg = 0;
    let lUIRASTCDE = Fhwith(uketoriAstCodeRef.current?.getValue(),0,3);
    let lUIRASTCDE01 = itUIRASTCDE01;
    uketoriAstCodeRef.current?.setValue(lUIRASTCDE);
    uketoriAstCodeRef.current?.setBGColor('#FFFFFF');
    //ＡＵ（発送店）
    console.log('mr_shp_mst_au:'+lUIRASTCDE+'|'+itUIRASTCDE01);

    // 一度フォーカス移動しているか確認。
    if(uketoriAstCodeRef.current?.getFocusflg()){
      uketoriAstCodeRef.current?.setFocusflg(false);    // フォーカス移動のフラグをＯＦＦにする。

      // 値が変更されているか確認
      if(uketoriAstCodeRef.current?.getCheckflg() == 1){
        lUIRASTCDE01 = '';            // 変更されていたらベリファイをリセット。
      }
    }
    uketoriAstCodeRef.current?.setCheckflg(0);   // 変更フラグをリセット。

    if (lUIRASTCDE01 == ''){
      /*** 入力1回目 ***/
      mvFlg = 1;
      setITuirastcde01(lUIRASTCDE);
      uketoriAstCodeRef.current?.setValue('');
      if (isInInputConfirm) {
        setAlert('ＡＵ入力エラー');//TCGE0034：
        uketoriAstCodeRef.current?.setBGColor('#f59d9d');
      }else{
        const responseA = await fetch('/TCSA0140/MR_PRD_CLS_MST_AU_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ksacde: cent_ksacde,bmncde:uketoriBunruiCodeRef.current?.getValue(),astcde:lUIRASTCDE})});
        const dataA = await responseA.json();
        console.log(dataA);
        if (dataA.RCNT == 0)
        {
          setAlert('ＡＵ入力エラー');//TCGE0034：
          uketoriAstCodeRef.current?.setBGColor('#f59d9d');
          setITuirastcde01('');
          setItuirastcdeido('');
          idoFlagOfAstCde = '';
        }else{
          console.log('移動フラグ:%o',dataA.idoflg);
          uketoriAstCodeRef.current?.setBGColor('#ffffff');
          setItuirastcdeido(dataA.idoflg);
          setITuirastcde01(lUIRASTCDE);
        }
      }
      // setTimeout(() => inUIRASTCDE.current.select(),1);
      return mvFlg;
    }else{
      /*** 入力2回目 ***/
      if (lUIRASTCDE01 != lUIRASTCDE)
      {
        mvFlg = 1;
        setAlert('ＡＵ入力エラー');//TCGE0034：
        uketoriAstCodeRef.current?.setBGColor('#f59d9d');
        setITuirastcde01(lUIRASTCDE);
        uketoriAstCodeRef.current?.setValue('');
        setItuirastcdeido('');
        // ＡＵ存在チェック
        const responseA = await fetch('/TCSA0140/MR_PRD_CLS_MST_AU_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ksacde: cent_ksacde,bmncde:uketoriBunruiCodeRef.current?.getValue(),astcde:lUIRASTCDE})});
        const dataA = await responseA.json();
        console.log(dataA);

        if (dataA.RCNT == 0)
        {
          mvFlg = 1;
          setITuirastcde01('');
          setItuirastcdeido(dataA.idoflg);
        }
        // setTimeout(() => inUIRASTCDE.current.select(),1);

      }else if ((bunruiCodeRef.current?.getValue() == uketoriBunruiCodeRef.current?.getValue())&&(astCodeRef.current?.getValue() != itUIRASTCDE01)){
        // 2023/10/04 分類CD（発送店側）＝分類CD（受取店側）の場合、ＡＵ（発送店側）≠ＡＵ（受取店側）はエラー
        mvFlg = 1;
        setAlert('発送店と受取店のＡＵが一致しません。');//TCGE0086：
        uketoriAstCodeRef.current?.setBGColor('#f59d9d');
        // uketoriAstCodeRef.current?.setValue('');
        setITuirastcde01('');
        setItuirastcdeido('');
        setTimeout(() => inUIRASTCDE.current.select(),1);
      }else{
        // ＡＵ存在チェック
        const responseA = await fetch('/TCSA0140/MR_PRD_CLS_MST_AU_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ksacde: cent_ksacde,bmncde:uketoriBunruiCodeRef.current?.getValue(),astcde:lUIRASTCDE})});
        const dataA = await responseA.json();
        console.log(dataA);

        if (dataA.RCNT == 0)
        {
          mvFlg = 1;
          setAlert('ＡＵ入力エラー');//TCGE0034：
          uketoriAstCodeRef.current?.setBGColor('#f59d9d');
          // uketoriAstCodeRef.current?.setValue('');
          setITuirastcde01('');
          setItuirastcdeido('');
          setTimeout(() => inUIRASTCDE.current.select(),1);
        }else{
          let getItUIRASTCDEIDO = dataA.idoflg;
          console.log('移動フラグ（受取店）:%o',getItUIRASTCDEIDO);
          setItuirastcdeido(getItUIRASTCDEIDO);
          //console.log('移動フラグ（発送店）|移動フラグ（受取店）:' + itASTCDEIDO +'|' + itUIRASTCDEIDO)
          if (itASTCDEIDO != '') {
            idoFlagOfAstCde = itASTCDEIDO;
          }
          console.log('移動フラグ（発送店）|移動フラグ（受取店）:' + idoFlagOfAstCde +'|' + getItUIRASTCDEIDO)
          //移動フラグチェック
          if (idoFlagOfAstCde != getItUIRASTCDEIDO){
            mvFlg = 1;
            setAlert('移動できません。');//TCGE0035：
            uketoriAstCodeRef.current?.setBGColor('#f59d9d');
            // uketoriAstCodeRef.current?.setValue('');
            setITuirastcde01('');
            setItuirastcdeido('');
            setTimeout(() => inUIRASTCDE.current.select(),1);
          }else{
            mvFlg = 0;
            uketoriAstCodeRef.current?.setBGColor('#ffffff');
            //setAlert('　');
            //setITuirastcde01('');
          }
        }
      }
    }
    return mvFlg;
  }
  //#endregion

  //#region 明細部入力
  /**
   * 明細部入力 商品コード 遷移の処理
   * @param rowNo 行No
   * @param instart Default = 0; 0:遷移あり、1:遷移なし
   * @returns 処理継続判定（0:後続処理続行, 1:後続処理中断）
   */
  function inInputConfirm_SHNCDE(rowNo,instart = 0){
    if (rowNo == 1 && shohinCode1Ref.current?.getValue() == ''){
      shohinCode1Ref.current?.setCheckflg(2);
      setAlert('商品コードを入力して下さい。');//TCGE0026：
      setItitemclr('#f59d9d',1,'shncde');
      if(instart === 0){
        inSUU1.current.focus();
      }
      return 1;
    }

    let itSHNCDE = getItitemval(rowNo,'shncde');
    if (itSHNCDE == '') {
      // 明細行で商品コードが空白で入力さエンター押下した場合、
      // 数量、原単価、原価金額、売単価、売価金額を空白にする（バックカラーもクリアにする）
      clearRow(rowNo);
    }

    // 空白行チェック
    let errflg = confirmed_rows(rowNo, true);

    if (itSHNCDE == '') {

      if (errflg != 0) {return 1};

      if(instart === 0){
        inSUUG.current.focus();
        return 1;
      }
    }

    if(instart === 0){
      let inputNxRef = ['',inSUU1,inSUU2,inSUU3,inSUU4,inSUU5,inSUU6];
      inputNxRef[rowNo].current.focus();
    }
    return 0;
  }

  /**
   * 明細部入力 商品コード
   * @param rowNo 明細行
   * @returns 0:正常, 1:エラー
   */
  const onKeyDown_SHNCDE = async(rowNo)=>{
    let l_SHNCDE = getItitemval(rowNo,'shncde');
    let l_BTN = [0,baikaTanka1Ref.current?.getValue(),baikaTanka2Ref.current?.getValue(),baikaTanka3Ref.current?.getValue(),baikaTanka4Ref.current?.getValue(),baikaTanka5Ref.current?.getValue(),baikaTanka6Ref.current?.getValue()];
    let mvFlg = 0;
    let itGNKKNRFLG = '0';
    let itESJ_KBN = '';

    const itSHNCDE = Fhwith(l_SHNCDE);

    setItitemval(itSHNCDE,rowNo,'shncde');
    setItitemclr('#FFFFFF',rowNo,'shncde');

    //inputNxRef.current.focus();   // 2023/10/10
    // 2023/10/04 日付に2重で20を足しているのを削除
    let strDENYMD = '20'+denpyoYMDRef.current?.getValue().slice(0,2)+denpyoYMDRef.current?.getValue().slice(3,5)+denpyoYMDRef.current?.getValue().slice(6,8)
    const responseA = await fetch('/TCSA0140/MR_PRD_CLS_MST_JGB_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ksacde: cent_ksacde,bmncde:bunruiCodeRef.current?.getValue(),denymd:strDENYMD})});
    const dataA = await responseA.json();
    console.log('jgbcde:'+dataA.jgbcde);
    console.log('gnkknrflg:'+dataA.gnkknrflg);
    console.log('itSHNCDE:'+itSHNCDE);
    console.log('length:'+itSHNCDE.length);
    console.log('件数:'+dataA.RCNT);
     //一件以上
    if (dataA.RCNT >= 1){
      itGNKKNRFLG = dataA.gnkknrflg;
      if (itSHNCDE.length == 3){    //商品コード 3桁の場合
        console.log('3桁です');
        console.log('商品コード1:%o',itSHNCDE)
        // 2023/10/04 発送ＡＵが設定されている場合
        if(astCodeRef.current?.getValue() != '' && astCodeRef.current?.getValue() != itSHNCDE){
          setAlert('商品コードとＡＵが一致しません。');//TCGE0085：
          setItitemclr('#f59d9d',rowNo,'shncde');
          setChkSHNCDE(rowNo,2);
          setChkflg(1);
          //setFocusSHNCDE(rowNo);
          return 1;
        }else{
          const responseB = await fetch('/TCSA0140/MR_PRD_CLS_MST_3keta_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ksacde: cent_ksacde,bmncde:bunruiCodeRef.current?.getValue(),shncde:itSHNCDE})});
          const dataB = await responseB.json();
          console.log('dataB.RCNT:'+dataB.RCNT);
          //一件以上
          if (dataB.RCNT >= 1){
            console.log("-- 商品コード 3桁が商品分類マスタに有る --");
            itESJ_KBN = ' ';
            console.log('ＥＯＳ／ＪＡＮ区分:%o',itESJ_KBN);
          }else{
            console.log("-- 商品コード 3桁が商品分類マスタに無い --");
            setAlert('商品コード入力エラー');//TCGE0026：
            setItitemclr('#f59d9d',rowNo,'shncde');
            setChkSHNCDE(rowNo,2);
            setChkflg(1);
            //setFocusSHNCDE(rowNo);
            return 1;
          }
        }

      }else if (itSHNCDE.length == 8){    // 商品コード 8桁の場合
        console.log('8桁です');
        const responseB = await fetch('/TCSA0140/MR_PRD_RMST_EOS_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ksacde: cent_ksacde,bmncde:bunruiCodeRef.current?.getValue(),astcde:astCodeRef.current?.getValue(),shncde:itSHNCDE})});
        const dataB = await responseB.json();
        console.log('dataB.RCNT:'+dataB.RCNT);
        // 2023/10/04 データが存在すれば
        if(dataB.record_exists == 1){
          console.log('商品コード[EOS] 8桁　存在チェックOK');
          itESJ_KBN = 'E';
          console.log('ＥＯＳ／ＪＡＮ区分:%o',itESJ_KBN);
        }else{
          const responseC = await fetch('/TCSA0140/MR_PRD_RMST_JAN_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ksacde: cent_ksacde,bmncde:bunruiCodeRef.current?.getValue(),astcde:astCodeRef.current?.getValue(),shncde:itSHNCDE})});
          const dataC = await responseC.json();
          console.log('dataC.RCNT:'+dataC.RCNT);
          // 2023/10/04 データが存在すれば
          if(dataC.record_exists == 1){
            console.log('商品コード[JAN] 8桁　存在チェックOK');
            itESJ_KBN = 'J';
            console.log('ＥＯＳ／ＪＡＮ区分:%o',itESJ_KBN);
          }else{
            setAlert('商品コード入力エラー');//TCGE0026：
            setItitemclr('#f59d9d',rowNo,'shncde');
            setChkSHNCDE(rowNo,2);
            setChkflg(1);
            //setFocusSHNCDE(rowNo);
            return 1;
          }
        }
      }else if (itSHNCDE.length == 13){   //商品コード 13桁の場合
        console.log('13桁です');
        if (itSHNCDE.substring(0, 2) == '02' && itSHNCDE.slice(-6)=='000000'){
          itESJ_KBN = 'J';
          itGNKKNRFLG = '1';
          console.log('ＥＯＳ／ＪＡＮ区分:%o',itESJ_KBN);
          console.log('原価管理フラグ:%o',itGNKKNRFLG);
        }else{
          const responseB = await fetch('/TCSA0140/MR_PRD_RMST_JAN_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ksacde: cent_ksacde,bmncde:bunruiCodeRef.current?.getValue(),astcde:astCodeRef.current?.getValue(),shncde:itSHNCDE})});
          const dataB = await responseB.json();
          console.log('dataB.RCNT:'+dataB.RCNT);
          // 2023/10/04 データが存在すれば
          if(dataB.record_exists == 1){
            console.log('商品コード 13桁　存在チェックOK');
            itESJ_KBN = 'J';
            console.log('ＥＯＳ／ＪＡＮ区分:%o',itESJ_KBN);
          }else{
            setAlert('商品コード入力エラー');//TCGE0026：
            setItitemclr('#f59d9d',rowNo,'shncde');
            setChkSHNCDE(rowNo,2);
            setChkflg(1);
            //setFocusSHNCDE(rowNo);
            return 1;
          }
        }
      }else{
        console.log("-- 商品コード 3, 8,12,13 桁以外はエラー --");
        setAlert('商品コード入力エラー');//TCGE0026：
        setItitemclr('#f59d9d',rowNo,'shncde');
        setChkSHNCDE(rowNo,2);
        setChkflg(1);
        //setFocusSHNCDE(rowNo);
        return 1;
      }
    }

    //エラー無しの場合
    console.log('itESJ_KBN:'+itESJ_KBN);
    setItitemval(itESJ_KBN,rowNo,'esj_kbn');
    setItitemval(itGNKKNRFLG,rowNo,'gnkknrflg');
    // 2023/10/17 売単価入力済みなら再チェック
    if(l_BTN[rowNo] != ''){
      onKeyDown_BTN(1);
    }
    setChkSHNCDE(rowNo,0);
    setChkflg(1);

    // if(instart === 0){
    //   inputNxRef.current.focus();
    // }
    return mvFlg;
  }

  /**
   * 明細部入力 数量
   * @param MeisaiNo 明細行
   * @returns 0:正常, 1:数量エラー
   */
  function onKeyDown_SUU (MeisaiNo){
    let ErrFlg = 0;
    let itSHNCDE = getItitemval(MeisaiNo,'shncde');
    let itSUU = getItitemval(MeisaiNo,'suu');

    // 空白行チェック 2023/10/25
    confirmed_rows(MeisaiNo);

    if (itSHNCDE === ''){
      if(MeisaiNo === 1){
        setAlert('商品コードを入力して下さい。'); // TCGE0019：
        shohinCode1Ref.current?.setBGColor('#f59d9d');
        shohinCode1Ref.current?.setCheckflg(2);
      }else{
        if (itSUU!==''){
          setAlert('商品コードを入力して下さい。'); // TCGE0019：
          setItitemclr('#f59d9d',MeisaiNo,'shncde');
          setChkSHNCDE(MeisaiNo,2);
        }
      }
    }

    if ((itSUU == ''))
    {
      setAlert('数量を入力して下さい。');//TCGE0019：
      ErrFlg = 1;
    }

    if (ErrFlg != 0){
      setItitemclr('#f59d9d',MeisaiNo,'suu');
    }

    return ErrFlg;
  }

  /**
   * 明細部入力 原価単価
   * @param MeisaiNo 明細行
   * @returns 0:正常, 1:原価単価エラー, 2:数量エラー
   */
  function onKeyDown_GNT (MeisaiNo) {
    let ErrFlg = 0;
    let itSHNCDE = getItitemval(MeisaiNo,'shncde');
    let itSUU = getItitemval(MeisaiNo,'suu');
    let itGNT = getItitemval(MeisaiNo,'gnt');

    //setAlert('　');

    // 空白行チェック 2023/10/25
    confirmed_rows(MeisaiNo);

    console.log("-- 原価単価チェック --");
    console.log('itSUU:'+itSUU);
    console.log('itGNT:'+itGNT);

    if (itSHNCDE === ''){
      if(MeisaiNo === 1){
        setAlert('商品コードを入力して下さい。'); // TCGE0019：
        shohinCode1Ref.current?.setBGColor('#f59d9d');
        shohinCode1Ref.current?.setCheckflg(2);
      }else{
        if (itGNT!==''){
          setAlert('商品コードを入力して下さい。'); // TCGE0019：
          setItitemclr('#f59d9d',MeisaiNo,'shncde');
          setChkSHNCDE(MeisaiNo,2);
        }
      }
    }

    if (itGNT == '')
    {
      setAlert('原価単価を入力して下さい。');//TCGE0019：
      ErrFlg = 1;
    }else{
      if ((itSUU == 0 && itGNT == 0))
      {
        setAlert('数量もしくは原価単価を入力して下さい。');//TCGE0019：
        ErrFlg = 2;
      }else{
        ErrFlg = 0;
      }
    }

    if (ErrFlg != 0){
      if (ErrFlg == 2){
        setItitemclr('#f59d9d',MeisaiNo,'suu');
      }
      setItitemclr('#f59d9d',MeisaiNo,'gnt');
    }else{
      setItitemclr('#FFFFFF',MeisaiNo,'suu');
      setItitemclr('#FFFFFF',MeisaiNo,'gnt');
    }

    console.log('戻り値：'+ErrFlg);
    setretMvFlg(ErrFlg);
    return ErrFlg;
  }

  /**
   * 明細部入力 売価単価
   * @param MeisaiNo 明細行
   * @returns 0:正常
   */
  function onKeyDown_BTN (MeisaiNo){
    let ErrFlg = 0;
    let itSHNCDE = getItitemval(MeisaiNo,'shncde');
    let itSUU = getItitemval(MeisaiNo,'suu');
    let itGNT = getItitemval(MeisaiNo,'gnt');
    let itBTN = getItitemval(MeisaiNo,'btn');
    let itGNKKNRFLG = [0,itGNKKNRFLG1,itGNKKNRFLG2,itGNKKNRFLG3,itGNKKNRFLG4,itGNKKNRFLG5,itGNKKNRFLG6];

    //let itGNKKNRFLG = [0,itGNKKNRFLG1,itGNKKNRFLG2,itGNKKNRFLG3,itGNKKNRFLG4,itGNKKNRFLG5,itGNKKNRFLG6];

    //setAlert('　');
    if (itSHNCDE === ''){
      if(MeisaiNo === 1){
        setAlert('商品コードを入力して下さい。'); // TCGE0019：
        shohinCode1Ref.current?.setBGColor('#f59d9d');
        shohinCode1Ref.current?.setCheckflg(2);
      }else{
        if (itBTN!==''){
          setAlert('商品コードを入力して下さい。'); // TCGE0019：
          setItitemclr('#f59d9d',MeisaiNo,'shncde');
          setChkSHNCDE(MeisaiNo,2);
        }
      }
    }

    if (itSUU!='' && itBTN==''){
      setItitemval('0',MeisaiNo,'btn');
      setItitemval('0',MeisaiNo,'bakkin');
    }

    setItitemclr('#FFFFFF',MeisaiNo,'btn');

    // 売価必要部門（売原管理フラグ＝０（必要））の場合
    console.log('itGNKKNRFLG[MeisaiNo]:%o',itGNKKNRFLG[MeisaiNo])
    if(itGNKKNRFLG[MeisaiNo] == '0'){
      console.log("-- 売単価チェック --");
      let l_BTN = parseFloat(itBTN.replace(/,/g, ''))||0;
      let l_GNT = parseFloat(itGNT.replace(/,/g, ''))||0;
      console.log('itBTN:'+l_BTN);
      console.log('itGNT:'+l_GNT);
      console.log('CHK:'+(l_BTN/l_GNT));
      if(l_BTN== 0||l_BTN/l_GNT<0.1||l_BTN/l_GNT>10){
        // 2023/10/16 エラーメッセージ変更
        setAlert('売価単価入力確認');//TCGC0002：
        setItitemclr('#ffff00',MeisaiNo,'btn');//黄色
      }
    }

    // 売価金額自動計算
    let C_SUU = parseFloat(itSUU.replace(/,/g, ''))||0;
    let C_BTN = parseFloat(itBTN.replace(/,/g, ''))||0;
    let CalcBAKKIN = C_SUU * C_BTN;
    setItitemval(CalcBAKKIN,MeisaiNo,'bakkin')

    console.log('戻り値：'+ErrFlg);
    setretMvFlg(ErrFlg);
    return ErrFlg;
  }
  //#endregion

  //#region 明細部関係の処理
  /**
   * 空白行チェック
   * @param rowNo 対象行
   * @param shncdeflg true:商品コード
   * @returns errorflg 0:正常、2:エラー
   */
  function confirmed_rows(rowNo,shncdeflg = false)
  {
    let tagrow = 0;
    let errorflg = 0;
    switch (rowNo) {
      case 1:   /** 1列目 **/
        // 1行目商品コード削除時、又は1行目全て空白時の処理
        if ((shncdeflg && shohinCode1Ref.current?.getValue() == '')
        || (shohinCode1Ref.current?.getValue() == '' && suuryo1Ref.current?.getValue() == '' && genkaTanka1Ref.current?.getValue() == '' && baikaTanka1Ref.current?.getValue() == ''))
        {
          // 2行目が入力済みだと1行目エラー
          if (shohinCode2Ref.current?.getValue() != '' || suuryo2Ref.current?.getValue() != '' || genkaTanka2Ref.current?.getValue() != '' || baikaTanka2Ref.current?.getValue() != ''){
            errorflg = 2;
            tagrow = rowNo;
            shohinCode1Ref.current?.setCheckflg(2);
            if(isInInputConfirm){
              inSHNCDE1.current.focus();
            }
          }
        }
        break;
      case 2:   /** 2列目 **/
        // 2行目商品コード削除時、又は2行目全て空白時の処理
        if ((shncdeflg && shohinCode2Ref.current?.getValue() == '')
        || (shohinCode2Ref.current?.getValue() == '' && suuryo2Ref.current?.getValue() == '' && genkaTanka2Ref.current?.getValue() == '' && baikaTanka2Ref.current?.getValue() == ''))
        {
          // 3行目が入力済みだと2行目エラー
          if (shohinCode3Ref.current?.getValue() != '' || suuryo3Ref.current?.getValue() != '' || genkaTanka3Ref.current?.getValue() != '' || baikaTanka3Ref.current?.getValue() != ''){
            errorflg = 2;
            tagrow = rowNo;
            shohinCode2Ref.current?.setCheckflg(2);
            if(isInInputConfirm){
              inSHNCDE2.current.focus();
            }
          // 1行目が未入力だと、1行目のエラーをクリア
          } else if(shohinCode1Ref.current?.getValue() == '' && suuryo1Ref.current?.getValue() == '' && genkaTanka1Ref.current?.getValue() == '' && baikaTanka1Ref.current?.getValue() == ''){
            clearRow(1);
          }
        }
        // 2行目入力あり、1行目全て空白時エラー
        else if(shohinCode1Ref.current?.getValue() == '' && suuryo1Ref.current?.getValue() == '' && genkaTanka1Ref.current?.getValue() == '' && baikaTanka1Ref.current?.getValue() == ''){
          errorflg = 1;
          tagrow = rowNo - 1;
          shohinCode1Ref.current?.setCheckflg(2);
          if(isInInputConfirm){
            inSHNCDE1.current.focus();
          }
        }
        break;
      case 3:   /** 3列目 **/
        // 3行目商品コード削除時、又は3行目全て空白時の処理
        if ((shncdeflg && shohinCode3Ref.current?.getValue() == '')
                ||(shohinCode3Ref.current?.getValue() == '' && suuryo3Ref.current?.getValue() == '' && genkaTanka3Ref.current?.getValue() == '' && baikaTanka3Ref.current?.getValue() == ''))
        {
          // 4行目が入力済みだと3行目エラー
          if (shohinCode4Ref.current?.getValue() != '' || suuryo4Ref.current?.getValue() != '' || genkaTanka4Ref.current?.getValue() != '' || baikaTanka4Ref.current?.getValue() != ''){
            errorflg = 2;
            tagrow = rowNo;
            shohinCode3Ref.current?.setCheckflg(2);
            if(isInInputConfirm){
              inSHNCDE3.current.focus();
            }
          // 2行目が未入力だと、2行目のエラーをクリア
          } else if(shohinCode2Ref.current?.getValue() == '' && suuryo2Ref.current?.getValue() == '' && genkaTanka2Ref.current?.getValue() == '' && baikaTanka2Ref.current?.getValue() == ''){
            clearRow(2);
          }
        }
        // 3行目入力あり、2行目全て空白時エラー
        else if(shohinCode2Ref.current?.getValue() == '' && suuryo2Ref.current?.getValue() == '' && genkaTanka2Ref.current?.getValue() == '' && baikaTanka2Ref.current?.getValue() == ''){
          errorflg = 1;
          tagrow = rowNo - 1;
          shohinCode2Ref.current?.setCheckflg(2);
          if(isInInputConfirm){
            inSHNCDE2.current.focus();
          }
        }
        break;
      case 4:   /** 4列目 **/
        // 4行目商品コード削除時、又は4行目全て空白時の処理
        if ((shncdeflg && shohinCode4Ref.current?.getValue() == '')
                ||(shohinCode4Ref.current?.getValue() == '' && suuryo4Ref.current?.getValue() == '' && genkaTanka4Ref.current?.getValue() == '' && baikaTanka4Ref.current?.getValue() == ''))
        {
          // 5行目が入力済みだと4行目エラー
          if (shohinCode5Ref.current?.getValue() != '' || suuryo5Ref.current?.getValue() != '' || genkaTanka5Ref.current?.getValue() != '' || baikaTanka5Ref.current?.getValue() != ''){
            errorflg = 2;
            tagrow = rowNo;
            shohinCode4Ref.current?.setCheckflg(2);
            if(isInInputConfirm){
              inSHNCDE4.current.focus();
            }
          // 3行目が未入力だと、3行目のエラーをクリア
          } else if(shohinCode3Ref.current?.getValue() == '' && suuryo3Ref.current?.getValue() == '' && genkaTanka3Ref.current?.getValue() == '' && baikaTanka3Ref.current?.getValue() == ''){
            clearRow(3);
          }
        }
        // 4行目入力あり、3行目全て空白時エラー
        else if(shohinCode3Ref.current?.getValue() == '' && suuryo3Ref.current?.getValue() == '' && genkaTanka3Ref.current?.getValue() == '' && baikaTanka3Ref.current?.getValue() == ''){
          errorflg = 1;
          tagrow = rowNo - 1;
          shohinCode3Ref.current?.setCheckflg(2);
          if(isInInputConfirm){
            inSHNCDE3.current.focus();
          }
        }
        break;
      case 5:   /** 5列目 **/
        // 5行目商品コード削除時、又は5行目全て空白時の処理
        if ((shncdeflg && shohinCode5Ref.current?.getValue() == '')
                ||(shohinCode5Ref.current?.getValue() == '' && suuryo5Ref.current?.getValue() == '' && genkaTanka5Ref.current?.getValue() == '' && baikaTanka5Ref.current?.getValue() == ''))
        {
          // 6行目が入力済みだと5行目エラー
          if (shohinCode6Ref.current?.getValue() != '' || suuryo6Ref.current?.getValue() != '' || genkaTanka6Ref.current?.getValue() != '' || baikaTanka6Ref.current?.getValue() != ''){
            errorflg = 2;
            tagrow = rowNo;
            shohinCode5Ref.current?.setCheckflg(2);
            if(isInInputConfirm){
              inSHNCDE5.current.focus();
            }
          // 4行目が未入力だと、4行目のエラーをクリア
          } else if(shohinCode4Ref.current?.getValue() == '' && suuryo4Ref.current?.getValue() == '' && genkaTanka4Ref.current?.getValue() == '' && baikaTanka4Ref.current?.getValue() == ''){
            clearRow(4);
          }
        }
        // 5行目入力あり、4行目全て空白時エラー
        else if(shohinCode4Ref.current?.getValue() == '' && suuryo4Ref.current?.getValue() == '' && genkaTanka4Ref.current?.getValue() == '' && baikaTanka4Ref.current?.getValue() == ''){
          errorflg = 1;
          tagrow = rowNo - 1;
          shohinCode4Ref.current?.setCheckflg(2);
          if(isInInputConfirm){
            inSHNCDE4.current.focus();
          }
        }
        break;
      case 6:   /** 6列目 **/
        // 6行目商品コード削除時、又は6行目全て空白時の処理
        if ((shncdeflg && shohinCode6Ref.current?.getValue() == '')
          ||(shohinCode6Ref.current?.getValue() == '' && suuryo6Ref.current?.getValue() == '' && genkaTanka6Ref.current?.getValue() == '' && baikaTanka6Ref.current?.getValue() == ''))
        {
          // 5行目が未入力だと、5行目のエラーをクリア
          if(shohinCode5Ref.current?.getValue() == '' && suuryo5Ref.current?.getValue() == '' && genkaTanka5Ref.current?.getValue() == '' && baikaTanka5Ref.current?.getValue() == ''){
            clearRow(5);
          }
        }
        // 6行目入力あり、5行目全て空白時エラー
        else if(shohinCode5Ref.current?.getValue() == '' && suuryo5Ref.current?.getValue() == '' && genkaTanka5Ref.current?.getValue() == '' && baikaTanka5Ref.current?.getValue() == ''){
          errorflg = 1;
          tagrow = rowNo - 1;
          shohinCode5Ref.current?.setCheckflg(2);
          if(isInInputConfirm){
            inSHNCDE5.current.focus();
          }
        }
        break;
      default:
        break;
    }

    if (errorflg != 0){

      setAlert('明細行を詰めて入力してください。');
      setItitemclr('#f59d9d',tagrow,'shncde');
      setItitemclr('#f59d9d',tagrow,'suu');
      setItitemclr('#f59d9d',tagrow,'gnt');
      setItitemclr('#f59d9d',tagrow,'btn');
    }
    return errorflg;
  }

  /**
   * 行クリア処理
   * @param rowNo 対象行
   */
  const clearRow = (rowNo) => {
    setItitemval('', rowNo, 'shncde');
    setItitemval('', rowNo, 'suu');
    setItitemval('', rowNo, 'gnt');
    setItitemval('', rowNo, 'btn');
    setItitemval('', rowNo, 'bakkin');
    setItitemclr('#FFFFFF', rowNo, 'shncde');
    setItitemclr('#FFFFFF', rowNo, 'suu');
    setItitemclr('#FFFFFF', rowNo, 'gnt');
    setItitemclr('#FFFFFF', rowNo, 'btn');
    setChkSHNCDE(rowNo,0);
  }

  /**
   * 明細からフォーカスが外れた際に確認処理を呼び出す。
   */
  useEffect(() => {
    // 下記の処理でblurTableflgを変更した際に
    // 重複して処理が走らないようにする為にtrueチェック
    // if(blurTableflg){

      let type = getInputType(document.activeElement.id);
      // if(type != 2){
      //   pageup時はヘッダ情報に遷移し、チェックは行わない
      //   if (blurkey!='PageUp')
      //   {
      //     onBlurTable();
      //   }
      // }
      // ※押下ボタンの判定だと、クリア時にエラーとなる
      if(type != 1 && type != 2){
        onBlurTable();
      }
    // }
  }, [blurTableflg]);

  /**
   * 明細からフォーカスが外れた際の処理
   * @returns
   */
  const onBlurTable = async()=>{
    console.log('明細項目チェック開始');

    // 1行目の商品コードは必須
    if (shohinCode1Ref.current?.getValue() == ''){
      shohinCode1Ref.current?.setCheckflg(2);
      setAlert('商品コードを入力して下さい。');//TCGE0026：
      setItitemclr('#f59d9d',1,'shncde');
      inSHNCDE1.current.focus();

      return;
    }


    let chkSHNCDE = [0,shohinCode1Ref.current?.getCheckflg(),shohinCode2Ref.current?.getCheckflg(),shohinCode3Ref.current?.getCheckflg(),shohinCode4Ref.current?.getCheckflg(),shohinCode5Ref.current?.getCheckflg(),shohinCode6Ref.current?.getCheckflg()];
    let inSHNCDE = [0,inSHNCDE1,inSHNCDE2,inSHNCDE3,inSHNCDE4,inSHNCDE5,inSHNCDE6];
    let inSUU = [0,inSUU1,inSUU2,inSUU3,inSUU4,inSUU5,inSUU6];
    let inGNT = [0,inGNT1,inGNT2,inGNT3,inGNT4,inGNT5,inGNT6];

    let clear_row = 7;
    for(let i = 6; i > 0; i--){
      //console.log('i:%o',i);
      let itSHNCDE = getItitemval(i,'shncde');
      if(itSHNCDE == ''){
        clearRow(i);
        clear_row = i;
      }else{
        break;
      }
    }

    let ret = 0;

    // クリアされていなければ処理
    // （廃止）※本来は1行目がクリアされていればエラーだが、明細から抜ける際はエラーにしない
    for(let i = 1; i <= 6; i++){
      if(clear_row > i){
        // 商品コード
        if(chkSHNCDE[i] === 2) {
          inSHNCDE[i].current.focus();
          setAlert('商品コード入力エラー');//TCGE0026：
          return;
        }
        let itSHNCDE = getItitemval(i,'shncde');
        if(itSHNCDE === ''){
          setChkSHNCDE(i,2);
          setAlert('商品コードを入力して下さい。');//TCGE0026：
          setItitemclr('#f59d9d', i, 'shncde');
          inSHNCDE[i].current.focus();
          return;
        }


        // 数量
        ret = onKeyDown_SUU(i);
        if (ret == 1){
          inSUU[i].current.focus();
          return;
        }

        // 原価単価
        ret = onKeyDown_GNT(i);
        if (ret == 1){
          inGNT[i].current.focus();
          return;
        }else if (ret == 2){
          inSUU[i].current.focus();
          return;
        }

        // 売単価
        onKeyDown_BTN(i);
      }
    }
    setAlert('　');
  };
  //#endregion

  //#region フッタ部入力
  // フッタ部入力 数量合計
  const onKeyDown_SUUG = async()=>{
    let mvFlg = 0;
    suuryoGoukeiRef.current?.setBGColor('#FFFFFF');
    console.log("-- 数量合計チェック --");
    let l_suu1 = parseFloat(suuryo1Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu2 = parseFloat(suuryo2Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu3 = parseFloat(suuryo3Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu4 = parseFloat(suuryo4Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu5 = parseFloat(suuryo5Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu6 = parseFloat(suuryo6Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suug = new Decimal(parseFloat(suuryoGoukeiRef.current?.getValue().replace(/,/g, ''))||0);
    console.log('suuryoGoukeiRef.current?.getValue():'+l_suug);
    let l_sud = new Decimal(l_suu1).plus(l_suu2).plus(l_suu3).plus(l_suu4).plus(l_suu5).plus(l_suu6);
    // if (!(((l_suu1*10)+(l_suu2*10)+(l_suu3*10)+(l_suu4*10)+(l_suu5*10)+(l_suu6*10))/10 == l_suug))
    if (!(l_sud.toNumber() == l_suug.toNumber()))
    {
      setAlert('数量合計入力エラー');//TCGE0037：
      suuryoGoukeiRef.current?.setBGColor('#f59d9d');
      setTimeout(() => {inSUUG.current.select();},10)
      mvFlg = 9;
    }
    return mvFlg;
  }

  // フッタ部入力  原価単価
  const onKeyDown_GNTG = async()=>{
    let mvFlg = 0;
    genkaTankaGoukeiRef.current?.setBGColor('#FFFFFF');
    console.log("-- 原単価合計チェック --");
    let l_GNT1 = parseFloat(genkaTanka1Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_GNT2 = parseFloat(genkaTanka2Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_GNT3 = parseFloat(genkaTanka3Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_GNT4 = parseFloat(genkaTanka4Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_GNT5 = parseFloat(genkaTanka5Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_GNT6 = parseFloat(genkaTanka6Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_GNTG = new Decimal(parseFloat(genkaTankaGoukeiRef.current?.getValue().replace(/,/g, ''))||0);
    console.log('genkaTankaGoukeiRef.current?.getValue():'+l_GNTG);
    let l_GNTd = new Decimal(l_GNT1).plus(l_GNT2).plus(l_GNT3).plus(l_GNT4).plus(l_GNT5).plus(l_GNT6);
    // if (!(((l_GNT1*100)+(l_GNT2*100)+(l_GNT3*100)+(l_GNT4*100)+(l_GNT5*100)+(l_GNT6*100))/100 == l_GNTG)){
    if (!(l_GNTd.toNumber() == l_GNTG.toNumber())){
      setAlert('原価単価合計入力エラー');//TCGE0038：
      genkaTankaGoukeiRef.current?.setBGColor('#f59d9d');
      setTimeout(() => {inGNTG.current.select();},10)
      mvFlg = 9;
    }
    return mvFlg;
  }

  // フッタ部入力 売価単価
  const onKeyDown_BTNG = async()=>{
    let mvFlg = 0;
    baikaTankaGoukeiRef.current?.setBGColor('#FFFFFF');
    console.log("-- 売単価合計チェック --");
    let l_BTN1 = parseFloat(baikaTanka1Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_BTN2 = parseFloat(baikaTanka2Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_BTN3 = parseFloat(baikaTanka3Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_BTN4 = parseFloat(baikaTanka4Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_BTN5 = parseFloat(baikaTanka5Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_BTN6 = parseFloat(baikaTanka6Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_BTNG = parseFloat(baikaTankaGoukeiRef.current?.getValue().replace(/,/g, ''))||0;
    console.log('baikaTankaGoukeiRef.current?.getValue():'+l_BTNG);

    if (baikaTankaGoukeiRef.current?.getValue()=='')
    {
      baikaTankaGoukeiRef.current?.setValue('0');
    }
    if (!(l_BTN1+l_BTN2+l_BTN3+l_BTN4+l_BTN5+l_BTN6 == l_BTNG))
    {
      setAlert('売価単価合計入力エラー');//TCGE0039：
      baikaTankaGoukeiRef.current?.setBGColor('#f59d9d');
      // inRET.current.focus();
      // inputRef.current.focus();
      setTimeout(() => {inBTNG.current.select();},10)
      mvFlg = 9;
    }else{
      let l_BAKKIN1 = parseFloat(String(baikaKingaku1Ref.current?.getValue()).replace(/,/g, ''))||0;
      let l_BAKKIN2 = parseFloat(String(baikaKingaku2Ref.current?.getValue()).replace(/,/g, ''))||0;
      let l_BAKKIN3 = parseFloat(String(baikaKingaku3Ref.current?.getValue()).replace(/,/g, ''))||0;
      let l_BAKKIN4 = parseFloat(String(baikaKingaku4Ref.current?.getValue()).replace(/,/g, ''))||0;
      let l_BAKKIN5 = parseFloat(String(baikaKingaku5Ref.current?.getValue()).replace(/,/g, ''))||0;
      let l_BAKKIN6 = parseFloat(String(baikaKingaku6Ref.current?.getValue()).replace(/,/g, ''))||0;
      let l_BAKKING = String(l_BAKKIN1+l_BAKKIN2+l_BAKKIN3+l_BAKKIN4+l_BAKKIN5+l_BAKKIN6)
      baikaKingakuGoukeiRef.current?.setValue(l_BAKKING);
    }
    return mvFlg;
  }

  // フッタ部入力 売価金額
  const onKeyDown_BAKKING = async()=>{
    let mvFlg = 0;
    baikaKingakuGoukeiRef.current?.setBGColor('#FFFFFF');
    console.log("-- 売価金額合計チェック --");
    let l_BAKKIN1 = parseFloat(String(baikaKingaku1Ref.current?.getValue()).replace(/,/g, ''))||0;
    let l_BAKKIN2 = parseFloat(String(baikaKingaku2Ref.current?.getValue()).replace(/,/g, ''))||0;
    let l_BAKKIN3 = parseFloat(String(baikaKingaku3Ref.current?.getValue()).replace(/,/g, ''))||0;
    let l_BAKKIN4 = parseFloat(String(baikaKingaku4Ref.current?.getValue()).replace(/,/g, ''))||0;
    let l_BAKKIN5 = parseFloat(String(baikaKingaku5Ref.current?.getValue()).replace(/,/g, ''))||0;
    let l_BAKKIN6 = parseFloat(String(baikaKingaku6Ref.current?.getValue()).replace(/,/g, ''))||0;
    let l_BAKKING = parseFloat(String(baikaKingakuGoukeiRef.current?.getValue()).replace(/,/g, ''))||0;
    console.log('baikaKingakuGoukeiRef.current?.getValue():'+l_BAKKING);
    if (!(l_BAKKIN1+l_BAKKIN2+l_BAKKIN3+l_BAKKIN4+l_BAKKIN5+l_BAKKIN6 == l_BAKKING))
    {
      setAlert('売価金額合計入力エラー');//TCGE0022：
      baikaKingakuGoukeiRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
    }
    return mvFlg;
  }
  // #endregion フッタ部入力

  //#region 登録処理
  // 商品コードの処理終了後に再処理
  useEffect (()=>{
    if(chkflg2 === 1){
      onKeyDown('Enter',inRET,'',inRET);
    }
    setChkflg(0);
  },[chkflg]);


  // 2023/10/05 処理追加
  // 最終チェック
  const InputConfirmed = async()=>{
    let ret = 0;
    // 商品コードの処理が途中なら一旦処理を中段し、確認が終った後に再処理
    if(shohinCode1Ref.current?.getCheckflg() === 9 || shohinCode2Ref.current?.getCheckflg() === 9 || shohinCode3Ref.current?.getCheckflg() === 9
      || shohinCode4Ref.current?.getCheckflg() === 9 || shohinCode5Ref.current?.getCheckflg() === 9 || shohinCode6Ref.current?.getCheckflg() === 9){
      setChkflg2(1);
      return 1;
    }
    setChkflg2(0);

    setAlert('　');
    console.log('ヘッダー項目チェック開始');
    // 必ず入力する項目
    // 区分設定
    ret = await onKeyDown_KBN();
    if (ret === 1){
      inKBN.current.focus();
      return 1;
    }

    // 伝票No.
    ret = await onKeyDown_DENNO(inDENNO);
    if (ret === 1){
      inDENNO.current.focus();
      return 1;
    }

    // 発送店
    ret = await onKeyDown_MISE();
    if (ret === 1){
      inMISE.current.focus();
      return 1;
    }

    // 分類CD（発送店側）
    ret = await onKeyDown_BMNCDE();
    if (ret === 1){
      inMISE.current.focus();
      return 1;
    }
    // ＡＵ（発送店側）
    ret = await onKeyDown_ASTCDE();
    if (ret === 1){
      inASTCDE.current.focus();
      return 1;
    }

    // 発送日
    ret = await onKeyDown_DENYMD(inDENYMD);
    if (ret === 1){
      inDENYMD.current.focus();
      return 1;
    }

    // 受取店
    ret = await onKeyDown_UIRMISE();
    if (ret === 1){
      inUIRMISE.current.focus();
      return 1;
    }

    // 分類CD（受取店側）
    ret = await onKeyDown_UIRBMNCDE();
    if (ret === 1){
      inUIRBMNCDE.current.focus();
      return 1;
    }

    // ＡＵ（受取店側）
    ret = await onKeyDown_UIRASTCDE();
    if (ret === 1){
      inUIRASTCDE.current.focus();
      return 1;
    }

    console.log('明細項目チェック開始');
    //setAlert('　');
    let ErrFlg = 0;
    let itemcount = 0;
    if(shohinCode1Ref.current?.getValue()!='' || suuryo1Ref.current?.getValue()!='' || genkaTanka1Ref.current?.getValue()!='' || baikaTanka1Ref.current?.getValue()!='' || baikaKingaku1Ref.current?.getValue()!=''){
      // 商品コード1
      if(shohinCode1Ref.current?.getCheckflg() === 2 || shohinCode1Ref.current?.getValue()=='') {
        shohinCode1Ref.current?.setBGColor('#f59d9d');
        setAlert('商品コードを入力して下さい。'); // TCGE0019：
        inSHNCDE1.current.focus();
        ErrFlg = 2;
        return ErrFlg;
      }
      if(shohinCode1Ref.current?.getCheckflg() === 1){
        if(inInputConfirm_SHNCDE(1,1) == 0){
          ret = await onKeyDown_SHNCDE(1);

          if (ret === 1){
            inSHNCDE1.current.focus();
            return 1;
          }
        }else{
          inSHNCDE1.current.focus();
          return 1;
        }
      }

      // 数量1
      ErrFlg = onKeyDown_SUU(1);
      console.log('チェック結果(SUU1)：'+ ErrFlg);
      if (ErrFlg != 0){
        inSUU1.current.focus();
        return ErrFlg;
      }

      // 原価単価1
      ErrFlg = onKeyDown_GNT(1);
      console.log('チェック結果(GNT1)：'+ ErrFlg);
      if (ErrFlg != 0){
        inGNT1.current.focus();
        return ErrFlg;
      }

      // 売単価1
      ErrFlg = onKeyDown_BTN(1);
      console.log('チェック結果(GNT1)：'+ ErrFlg);
      if (ErrFlg != 0){
        inBTN1.current.focus();
        return ErrFlg;
      }

      itemcount++;
    }

    if(shohinCode2Ref.current?.getValue()!='' || suuryo2Ref.current?.getValue()!='' || genkaTanka2Ref.current?.getValue()!='' || baikaTanka2Ref.current?.getValue()!='' || baikaKingaku2Ref.current?.getValue()!=''){
      // 商品コード2
      if(shohinCode2Ref.current?.getCheckflg() === 2 || shohinCode2Ref.current?.getValue()=='') {
        shohinCode2Ref.current?.setBGColor('#f59d9d');
        setAlert('商品コードを入力して下さい。'); // TCGE0019：
        inSHNCDE2.current.focus();
        ErrFlg = 2;
        return ErrFlg;
      }
      if(shohinCode2Ref.current?.getCheckflg() === 1){
        if(inInputConfirm_SHNCDE(2,1) == 0){
          ret = await onKeyDown_SHNCDE(2);

          if (ret === 1){
            inSHNCDE2.current.focus();
            return 1;
          }
        }else{
          inSHNCDE2.current.focus();
          return 1;
        }
      }

      // 数量2
      ErrFlg = onKeyDown_SUU(2);
      console.log('チェック結果(SUU2)：'+ ErrFlg);
      if (ErrFlg != 0){
        inSUU2.current.focus();
        return ErrFlg;
      }

      // 原価単価2
      ErrFlg = onKeyDown_GNT(2);
      console.log('チェック結果(GNT2)：'+ ErrFlg);
      if (ErrFlg != 0){
        inGNT2.current.focus();
        return ErrFlg;
      }

      // 売単価2
      ErrFlg = onKeyDown_BTN(2);
      console.log('チェック結果(GNT2)：'+ ErrFlg);
      if (ErrFlg != 0){
        inBTN2.current.focus();
        return ErrFlg;
      }
      itemcount++;
    }

    if(shohinCode3Ref.current?.getValue()!='' || suuryo3Ref.current?.getValue()!='' || genkaTanka3Ref.current?.getValue()!='' || baikaTanka3Ref.current?.getValue()!='' || baikaKingaku3Ref.current?.getValue()!=''){
      // 商品コード3
      if(shohinCode3Ref.current?.getCheckflg() === 2 || shohinCode3Ref.current?.getValue()=='') {
        shohinCode3Ref.current?.setBGColor('#f59d9d');
        setAlert('商品コードを入力して下さい。'); // TCGE0019：
        inSHNCDE3.current.focus();
        ErrFlg = 2;
        return ErrFlg;
      }
      if(shohinCode3Ref.current?.getCheckflg() === 1){
        if(inInputConfirm_SHNCDE(3,1) == 0){
          ret = await onKeyDown_SHNCDE(3);

          if (ret === 1){
            inSHNCDE3.current.focus();
            return 1;
          }
        }else{
          inSHNCDE3.current.focus();
          return 1;
        }
      }

      // 数量3
      ErrFlg = onKeyDown_SUU(3);
      console.log('チェック結果(SUU3)：'+ ErrFlg);
      if (ErrFlg != 0){
        inSUU3.current.focus();
        return ErrFlg;
      }

      // 原価単価3
      ErrFlg = onKeyDown_GNT(3);
      console.log('チェック結果(GNT3)：'+ ErrFlg);
      if (ErrFlg != 0){
        inGNT3.current.focus();
        return ErrFlg;
      }

      // 売単価3
      ErrFlg = onKeyDown_BTN(3);
      console.log('チェック結果(GNT3)：'+ ErrFlg);
      if (ErrFlg != 0){
        inBTN3.current.focus();
        return ErrFlg;
      }
      itemcount++;
    }

    if(shohinCode4Ref.current?.getValue()!='' || suuryo4Ref.current?.getValue()!='' || genkaTanka4Ref.current?.getValue()!='' || baikaTanka4Ref.current?.getValue()!='' || baikaKingaku4Ref.current?.getValue()!=''){
      // 商品コード4
      if(shohinCode4Ref.current?.getCheckflg() === 2 || shohinCode4Ref.current?.getValue()=='') {
        shohinCode4Ref.current?.setBGColor('#f59d9d');
        setAlert('商品コードを入力して下さい。'); // TCGE0019：
        inSHNCDE4.current.focus();
        ErrFlg = 2;
        return ErrFlg;
      }
      if(shohinCode4Ref.current?.getCheckflg() === 1){
        if(inInputConfirm_SHNCDE(4,1) == 0){
          ret = await onKeyDown_SHNCDE(4);

          if (ret === 1){
            inSHNCDE4.current.focus();
            return 1;
          }
        }else{
          inSHNCDE4.current.focus();
          return 1;
        }
      }

      // 数量4
      ErrFlg = onKeyDown_SUU(4);
      console.log('チェック結果(SUU4)：'+ ErrFlg);
      if (ErrFlg != 0){
        inSUU4.current.focus();
        return ErrFlg;
      }

      // 原価単価4
      ErrFlg = onKeyDown_GNT(4);
      console.log('チェック結果(GNT4)：'+ ErrFlg);
      if (ErrFlg != 0){
        inGNT4.current.focus();
        return ErrFlg;
      }

      // 売単価4
      ErrFlg = onKeyDown_BTN(4);
      console.log('チェック結果(GNT4)：'+ ErrFlg);
      if (ErrFlg != 0){
        inBTN4.current.focus();
        return ErrFlg;
      }
      itemcount++;
    }

    if(shohinCode5Ref.current?.getValue()!='' || suuryo5Ref.current?.getValue()!='' || genkaTanka5Ref.current?.getValue()!='' || baikaTanka5Ref.current?.getValue()!='' || baikaKingaku5Ref.current?.getValue()!=''){
      // 商品コード5
      if(shohinCode5Ref.current?.getCheckflg() === 2 || shohinCode5Ref.current?.getValue()=='') {
        shohinCode5Ref.current?.setBGColor('#f59d9d');
        setAlert('商品コードを入力して下さい。'); // TCGE0019：
        inSHNCDE5.current.focus();
        ErrFlg = 2;
        return ErrFlg;
      }
      if(shohinCode5Ref.current?.getCheckflg() === 1){
        if(inInputConfirm_SHNCDE(5,1) == 0){
          ret = await onKeyDown_SHNCDE(5);

          if (ret === 1){
            inSHNCDE5.current.focus();
            return 1;
          }
        }else{
          inSHNCDE5.current.focus();
          return 1;
        }
      }

      // 数量5
      ErrFlg = onKeyDown_SUU(5);
      console.log('チェック結果(SUU5)：'+ ErrFlg);
      if (ErrFlg != 0){
        inSUU5.current.focus();
        return ErrFlg;
      }

      // 原価単価5
      ErrFlg = onKeyDown_GNT(5);
      console.log('チェック結果(GNT5)：'+ ErrFlg);
      if (ErrFlg != 0){
        inGNT5.current.focus();
        return ErrFlg;
      }

      // 売単価5
      ErrFlg = onKeyDown_BTN(5);
      console.log('チェック結果(GNT5)：'+ ErrFlg);
      if (ErrFlg != 0){
        inBTN5.current.focus();
        return ErrFlg;
      }
      itemcount++;
    }

    if(shohinCode6Ref.current?.getValue()!='' || suuryo6Ref.current?.getValue()!='' || genkaTanka6Ref.current?.getValue()!='' || baikaTanka6Ref.current?.getValue()!='' || baikaKingaku6Ref.current?.getValue()!=''){
      // 商品コード6
      if(shohinCode6Ref.current?.getCheckflg() === 2 || shohinCode6Ref.current?.getValue()=='') {
        shohinCode6Ref.current?.setBGColor('#f59d9d');
        setAlert('商品コードを入力して下さい。'); // TCGE0019：
        inSHNCDE6.current.focus();
        ErrFlg = 2;
        return ErrFlg;
      }
      if(shohinCode6Ref.current?.getCheckflg() === 1){
        if(inInputConfirm_SHNCDE(6,1) == 0){
          ret = await onKeyDown_SHNCDE(6);

          if (ret === 1){
            inSHNCDE6.current.focus();
            return 1;
          }
        }else{
          inSHNCDE6.current.focus();
          return 1;
        }
      }

      // 数量6
      ErrFlg = onKeyDown_SUU(6);
      console.log('チェック結果(SUU6)：'+ ErrFlg);
      if (ErrFlg != 0){
        inSUU6.current.focus();
        return ErrFlg;
      }

      // 原価単価6
      ErrFlg = onKeyDown_GNT(6);
      console.log('チェック結果(GNT6)：'+ ErrFlg);
      if (ErrFlg != 0){
        inGNT6.current.focus();
        return ErrFlg;
      }

      // 売単価6
      ErrFlg = onKeyDown_BTN(6);
      console.log('チェック結果(GNT6)：'+ ErrFlg);
      if (ErrFlg != 0){
        inBTN6.current.focus();
        return ErrFlg;
      }
      itemcount++;
    }

    if(itemcount===0){
      shohinCode1Ref.current?.setBGColor('#f59d9d');
      setAlert('明細を入力してください。'); // TCGE0020：
      inSHNCDE1.current.focus();
      ErrFlg = 2;
      return ErrFlg;
    }

    // 一度処理を終了しないと、明細の合計値が反映されない為、フッダ確認時にエラーとなる。
    // 処理を中段し、最終チェック確認後処理を呼び出す。
    setInputConfirmflg(1);
  }

  // 最終チェック2
  const InputConfirmed2 =  async()=>{
    let ret = 0;
    console.log('フッダ項目チェック開始');
     if(kubunRef.current?.getValue() === '1' || kubunRef.current?.getValue() === '2'){
      // 数量合計
      ret = await onKeyDown_SUUG();
      if (ret === 1){
        inSUUG.current.focus();
        setTimeout(() => inSUUG.current.select(),100);
        return 1;
      }

      // 原単価合計
      ret = await onKeyDown_GNTG();
      if (ret === 1){
        inGNTG.current.focus();
        setTimeout(() => inGNTG.current.select(),100);
        return 1;
      }

      // 売単価合計
      ret = await onKeyDown_BTNG();
      if (ret === 1){
        inBTNG.current.focus();
        setTimeout(() => inGNTG.current.select(),100);
        return 1;
      }

      // 売価金額合計
      // ret = await onKeyDown_BAKKING();
      // if (ret === 1){
      //   inBAKKING.current.focus();
      //   return 1;
      // }
    }
    setInputConfirmflg(9);
    return;
  }

  // 最終チェック確認後処理
  useEffect (()=>{
    if(inputConfirmflg == 1){
      setInputConfirmflg(0);
      // 最終チェック1正常終了時、最終チェック2(InputConfirmed2)を呼び出す。
      InputConfirmed2();
    }else if(inputConfirmflg !== 0){
      setInputConfirmflg(0);
      // 最終チェック2正常終了時、DB更新処理を呼び出す。
      register();
    }
  },[inputConfirmflg]);

  // 確認後DB更新処理
  const register =  async()=>{
    let mvFlg = 0;
    if(kubunRef.current?.getValue() === '1') {
      mvFlg = await onKeyDown_Insert();   // 新規
    } else if(kubunRef.current?.getValue() === '2') {
      mvFlg = await onKeyDown_Update();   // 更新
    } else {
      mvFlg = await onKeyDown_Delete();   // 削除
    }

    switch (mvFlg) {
      case 1:
        inRET.current.focus();   //現在の項目に遷移
        break;
      case 0:
      case 2:
        break;
      case 9:
        // 確認処理後は伝票No.に遷移状態にするため、確認処理前に伝票No.に遷移する
        // 確認時にエラーが発生した場合はエラー箇所に遷移するためこの位置で行っている
        inDENNO.current.focus();
        break;
      default:
        break;
    }
  }

  // 登録処理 登録処理
  const onKeyDown_Insert = async()=>{
    // isInInputConfirm = true;
    // if (await InputConfirmed() !== 0) {
    //   isInInputConfirm = false;
    //   return 2;
    // }
    // isInInputConfirm = false;

    setAlert('　');

    // window.alert('チェックok onKeyDown_Insert');
    // return 0;

    //setAlert('　');
    setItretclr('#FFFFFF');
    let mvFlg = 0;
    console.log('伝票ヘッダ新規');
    const responseUA1 = await fetch('/TCSA0140/SL_HED_DAT_INS',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      ent_ksacde : cent_ksacde  //入力元会社コード
      ,ent_mise : cent_mise     //入力元店コード
      ,densbt : '05'              //伝票種別
      ,denno : denpyoNoRef.current?.getValue()            //伝票番号
      ,kijymd :'20'+itKIJYYM2+'20'//計上日
      ,kijyym :'20'+itKIJYYM2     //計上年月
      ,ksacde : cent_ksacde     //会社コード
      ,mise :  miseCodeRef.current?.getValue()        //店コード
      ,edaban : '01'              //枝番号
      ,bmncde : bunruiCodeRef.current?.getValue()          //部門CD
      ,astcde : astCodeRef.current?.getValue()          //ＡＵ
      ,denymd : '20'+denpyoYMDRef.current?.getValue().replace(/(\\|\/)/g,'')    //伝票日付
      ,uir_ksacde : cent_ksacde //会社コード（移動先）
      ,uir_mise : uketoriMiseCodeRef.current?.getValue()       //店コード（移動先）
      ,uir_bmncde : uketoriBunruiCodeRef.current?.getValue()   //部門コード（移動先）
      ,uir_astcde : uketoriAstCodeRef.current?.getValue()   //AU（移動先）
      ,sryfot : suuryoGoukeiRef.current?.getValue().replaceAll(/,/g, '')            //数量合計
      ,bakfot : baikaKingakuGoukeiRef.current?.getValue().replaceAll(/,/g, '')         //売価金額合計
      ,gnktanfot : genkaTankaGoukeiRef.current?.getValue().replaceAll(/,/g, '')         //原価単価合計
      ,biktanfot : baikaTankaGoukeiRef.current?.getValue().replaceAll(/,/g, '')         //売価単価合計
      ,set_fix_kbn :'0'           //決算修正区分
      ,dat_mkbn :'1'              //データメンテ区分
      ,add_jgncde : state.JGNCDE  //ユーザーID
      ,add_prg_id : 'TCSA0140'    //プログラムID
      ,trl_id :state.MISES        //端末ＩＤ★一旦店コード入れる
      ,delflg :'0'                //削除フラグ
      })});
    const dataUA1 = await responseUA1.json();
    console.log('伝票ヘッダ:%o',dataUA1.res);
    if(dataUA1.res == -1){
      setAlert('伝票データ登録エラー');//TCGE0023：
      setItretclr('#f59d9d');
      mvFlg = 1;
    }else{
      console.log('伝票明細新規');
      console.log('itESJ_KBN1:'+itESJ_KBN1);
      const responseUA2 = await fetch('/TCSA0140/SL_DTL_DAT_INS',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        ent_ksacde : cent_ksacde  //入力元会社コード
        ,ent_mise : cent_mise     //入力元店コード
        ,densbt : '05'              //伝票種別
        ,denno : denpyoNoRef.current?.getValue()            //伝票番号
        ,ksacde : cent_ksacde     //会社コード
        ,mise :  miseCodeRef.current?.getValue()        //店コード
        ,edaban : '01'              //枝番号
        ,meisai:[
           {lineno:'1',shncde:shohinCode1Ref.current?.getValue(),suu:suuryo1Ref.current?.getValue().replaceAll(/,/g, ''),gnt:genkaTanka1Ref.current?.getValue().replaceAll(/,/g, ''),gnkkin:(suuryo1Ref.current?.getValue().replaceAll(/,/g, '')*genkaTanka1Ref.current?.getValue().replaceAll(/,/g, '')),btn:baikaTanka1Ref.current?.getValue().replaceAll(/,/g, ''),bakkin:String(baikaKingaku1Ref.current?.getValue()).replaceAll(/,/g, ''),esj_kbn:itESJ_KBN1}
          ,{lineno:'2',shncde:shohinCode2Ref.current?.getValue(),suu:suuryo2Ref.current?.getValue().replaceAll(/,/g, ''),gnt:genkaTanka2Ref.current?.getValue().replaceAll(/,/g, ''),gnkkin:(suuryo2Ref.current?.getValue().replaceAll(/,/g, '')*genkaTanka2Ref.current?.getValue().replaceAll(/,/g, '')),btn:baikaTanka2Ref.current?.getValue().replaceAll(/,/g, ''),bakkin:String(baikaKingaku2Ref.current?.getValue()).replaceAll(/,/g, ''),esj_kbn:itESJ_KBN2}
          ,{lineno:'3',shncde:shohinCode3Ref.current?.getValue(),suu:suuryo3Ref.current?.getValue().replaceAll(/,/g, ''),gnt:genkaTanka3Ref.current?.getValue().replaceAll(/,/g, ''),gnkkin:(suuryo3Ref.current?.getValue().replaceAll(/,/g, '')*genkaTanka3Ref.current?.getValue().replaceAll(/,/g, '')),btn:baikaTanka3Ref.current?.getValue().replaceAll(/,/g, ''),bakkin:String(baikaKingaku3Ref.current?.getValue()).replaceAll(/,/g, ''),esj_kbn:itESJ_KBN3}
          ,{lineno:'4',shncde:shohinCode4Ref.current?.getValue(),suu:suuryo4Ref.current?.getValue().replaceAll(/,/g, ''),gnt:genkaTanka4Ref.current?.getValue().replaceAll(/,/g, ''),gnkkin:(suuryo4Ref.current?.getValue().replaceAll(/,/g, '')*genkaTanka4Ref.current?.getValue().replaceAll(/,/g, '')),btn:baikaTanka4Ref.current?.getValue().replaceAll(/,/g, ''),bakkin:String(baikaKingaku4Ref.current?.getValue()).replaceAll(/,/g, ''),esj_kbn:itESJ_KBN4}
          ,{lineno:'5',shncde:shohinCode5Ref.current?.getValue(),suu:suuryo5Ref.current?.getValue().replaceAll(/,/g, ''),gnt:genkaTanka5Ref.current?.getValue().replaceAll(/,/g, ''),gnkkin:(suuryo5Ref.current?.getValue().replaceAll(/,/g, '')*genkaTanka5Ref.current?.getValue().replaceAll(/,/g, '')),btn:baikaTanka5Ref.current?.getValue().replaceAll(/,/g, ''),bakkin:String(baikaKingaku5Ref.current?.getValue()).replaceAll(/,/g, ''),esj_kbn:itESJ_KBN5}
          ,{lineno:'6',shncde:shohinCode6Ref.current?.getValue(),suu:suuryo6Ref.current?.getValue().replaceAll(/,/g, ''),gnt:genkaTanka6Ref.current?.getValue().replaceAll(/,/g, ''),gnkkin:(suuryo6Ref.current?.getValue().replaceAll(/,/g, '')*genkaTanka6Ref.current?.getValue().replaceAll(/,/g, '')),btn:baikaTanka6Ref.current?.getValue().replaceAll(/,/g, ''),bakkin:String(baikaKingaku6Ref.current?.getValue()).replaceAll(/,/g, ''),esj_kbn:itESJ_KBN6}
        ]
        // ,esj_kbn:itESJ_KBN
        ,add_jgncde : state.JGNCDE  //ユーザーID
        ,add_prg_id : 'TCSA0140'    //プログラムID
        ,trl_id :state.MISES        //端末ＩＤ★一旦店コード入れる
        ,delflg :'0'                //削除フラグ
        })});
      const dataUA2 = await responseUA2.json();
      console.log('伝票明細:%o',dataUA2.res);
      if(dataUA2.res == -1){
        setAlert('伝票データ登録エラー');//TCGE0023：
        setItretclr('#f59d9d');
        mvFlg = 1;
      }else{
        setItrcnt(itRcnt+1);
        Gmclr();
        //こちらでmv制御する為、mvFlg立てる
        mvFlg = 9;

      }
    }
    return mvFlg;
  }

  // 登録処理 更新処理
  const onKeyDown_Update = async()=>{
    // isInInputConfirm = true;
    // if (await InputConfirmed() !== 0) {
    //   isInInputConfirm = false;
    //   return 2;
    // }
    // isInInputConfirm = false;

    setAlert('　');

    // window.alert('チェックok onKeyDown_Update');
    // return 0;

    //setAlert('　');
    setItretclr('#FFFFFF');
    let mvFlg = 0;
    console.log('伝票ヘッダ更新');
    const responseUA1 = await fetch('/TCSA0140/SL_HED_DAT_upd',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      kijymd:'20'+itKIJYYM2+'20'//計上日
      ,kijyym:'20'+itKIJYYM2         //計上年月
      ,bmncde:bunruiCodeRef.current?.getValue()          //部門CD
      ,denymd: '20'+denpyoYMDRef.current?.getValue().replace(/(\\|\/)/g,'')    //伝票日付
      ,uir_mise:uketoriMiseCodeRef.current?.getValue()       //店コード（移動先）
      ,uir_bmncde:uketoriBunruiCodeRef.current?.getValue()   //部門コード（移動先）
      ,sryfot:suuryoGoukeiRef.current?.getValue().replaceAll(/,/g, '')            //数量合計
      ,bakfot:baikaKingakuGoukeiRef.current?.getValue().replaceAll(/,/g, '')         //売価金額合計
      ,gnktanfot : genkaTankaGoukeiRef.current?.getValue().replaceAll(/,/g, '')       //原価単価合計
      ,biktanfot : baikaTankaGoukeiRef.current?.getValue().replaceAll(/,/g, '')       //売価単価合計
      // ,set_fix_kbn :'0'         //決算修正区分
      // ,dat_mkbn :'1'            //データメンテ区分
      ,upd_jgncde: state.JGNCDE //ユーザーID
      ,upd_prg_id: 'TCSA0140'   //プログラムID
      ,trl_id :state.MISES        //端末ＩＤ★一旦店コード入れる
      ,ent_ksacde: cent_ksacde
      ,ent_mise: cent_mise
      ,densbt: '05'
      ,denno: denpyoNoRef.current?.getValue()
      // ,sircde: ''
      ,ksacde: cent_ksacde
      ,mise: miseCodeRef.current?.getValue()
      // ,httymd:''
      })});
    const dataUA1 = await responseUA1.json();
    console.log('伝票ヘッダ:%o',dataUA1);
    if(dataUA1.res == -1){
      setAlert('伝票データ修正エラー');//TCGE0024：
      setItretclr('#f59d9d');
      mvFlg = 1;
    }else{
      console.log('伝票明細更新');
      const responseUA2 = await fetch('/TCSA0140/SL_DTL_DAT_upd',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        ent_ksacde : cent_ksacde  //入力元会社コード
        ,ent_mise : cent_mise     //入力元店コード
        ,densbt : '05'              //伝票種別
        ,denno : denpyoNoRef.current?.getValue()            //伝票番号
        ,ksacde : cent_ksacde     //会社コード
        ,mise :  miseCodeRef.current?.getValue()        //店コード
        ,edaban : '01'              //枝番号
        ,meisai:[
           {lineno:'1',shncde:shohinCode1Ref.current?.getValue(),suu:suuryo1Ref.current?.getValue().replaceAll(/,/g, ''),gnt:genkaTanka1Ref.current?.getValue().replaceAll(/,/g, ''),gnkkin:(suuryo1Ref.current?.getValue().replaceAll(/,/g, '')*genkaTanka1Ref.current?.getValue().replaceAll(/,/g, '')),btn:baikaTanka1Ref.current?.getValue().replaceAll(/,/g, ''),bakkin:String(baikaKingaku1Ref.current?.getValue()).replaceAll(/,/g, ''),esj_kbn:itESJ_KBN1}
          ,{lineno:'2',shncde:shohinCode2Ref.current?.getValue(),suu:suuryo2Ref.current?.getValue().replaceAll(/,/g, ''),gnt:genkaTanka2Ref.current?.getValue().replaceAll(/,/g, ''),gnkkin:(suuryo2Ref.current?.getValue().replaceAll(/,/g, '')*genkaTanka2Ref.current?.getValue().replaceAll(/,/g, '')),btn:baikaTanka2Ref.current?.getValue().replaceAll(/,/g, ''),bakkin:String(baikaKingaku2Ref.current?.getValue()).replaceAll(/,/g, ''),esj_kbn:itESJ_KBN2}
          ,{lineno:'3',shncde:shohinCode3Ref.current?.getValue(),suu:suuryo3Ref.current?.getValue().replaceAll(/,/g, ''),gnt:genkaTanka3Ref.current?.getValue().replaceAll(/,/g, ''),gnkkin:(suuryo3Ref.current?.getValue().replaceAll(/,/g, '')*genkaTanka3Ref.current?.getValue().replaceAll(/,/g, '')),btn:baikaTanka3Ref.current?.getValue().replaceAll(/,/g, ''),bakkin:String(baikaKingaku3Ref.current?.getValue()).replaceAll(/,/g, ''),esj_kbn:itESJ_KBN3}
          ,{lineno:'4',shncde:shohinCode4Ref.current?.getValue(),suu:suuryo4Ref.current?.getValue().replaceAll(/,/g, ''),gnt:genkaTanka4Ref.current?.getValue().replaceAll(/,/g, ''),gnkkin:(suuryo4Ref.current?.getValue().replaceAll(/,/g, '')*genkaTanka4Ref.current?.getValue().replaceAll(/,/g, '')),btn:baikaTanka4Ref.current?.getValue().replaceAll(/,/g, ''),bakkin:String(baikaKingaku4Ref.current?.getValue()).replaceAll(/,/g, ''),esj_kbn:itESJ_KBN4}
          ,{lineno:'5',shncde:shohinCode5Ref.current?.getValue(),suu:suuryo5Ref.current?.getValue().replaceAll(/,/g, ''),gnt:genkaTanka5Ref.current?.getValue().replaceAll(/,/g, ''),gnkkin:(suuryo5Ref.current?.getValue().replaceAll(/,/g, '')*genkaTanka5Ref.current?.getValue().replaceAll(/,/g, '')),btn:baikaTanka5Ref.current?.getValue().replaceAll(/,/g, ''),bakkin:String(baikaKingaku5Ref.current?.getValue()).replaceAll(/,/g, ''),esj_kbn:itESJ_KBN5}
          ,{lineno:'6',shncde:shohinCode6Ref.current?.getValue(),suu:suuryo6Ref.current?.getValue().replaceAll(/,/g, ''),gnt:genkaTanka6Ref.current?.getValue().replaceAll(/,/g, ''),gnkkin:(suuryo6Ref.current?.getValue().replaceAll(/,/g, '')*genkaTanka6Ref.current?.getValue().replaceAll(/,/g, '')),btn:baikaTanka6Ref.current?.getValue().replaceAll(/,/g, ''),bakkin:String(baikaKingaku6Ref.current?.getValue()).replaceAll(/,/g, ''),esj_kbn:itESJ_KBN6}
        ]
        // ,esj_kbn:itESJ_KBN
        ,add_jgncde : state.JGNCDE  //ユーザーID
        ,add_prg_id : 'TCSA0140'    //プログラムID
        ,trl_id :state.MISES        //端末ＩＤ★一旦店コード入れる
        ,delflg :'0'                //削除フラグ
        })});
      const dataUA2 = await responseUA2.json();
      console.log('伝票明細:%o',dataUA2.res);
      if(dataUA2.res == -1){
        setAlert('伝票データ修正エラー');//TCGE0024：
        setItretclr('#f59d9d');
        mvFlg = 1;
      }else{
        Gmclr();
        //こちらでmv制御する為、mvFlg立てる
        mvFlg = 9;
      }
    }
    return mvFlg;
  }

  // 登録処理 削除処理
  const onKeyDown_Delete = async()=>{
    // isInInputConfirm = true;
    // if (await InputConfirmed() !== 0) {
    //   isInInputConfirm = false;
    //   return 2;
    // }
    // isInInputConfirm = false;

    setAlert('　');

    // window.alert('チェックok onKeyDown_Delete');
    // return 0;

    //setAlert('　');
    setItretclr('#FFFFFF');
    let mvFlg = 0;
    console.log('伝票ヘッダ削除');
    const responseUA1 = await fetch('/TCSA0140/SL_HED_DAT_del',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      ent_ksacde: cent_ksacde
      ,ent_mise: cent_mise
      ,densbt: '05'
      ,denno: denpyoNoRef.current?.getValue()
      ,ksacde: cent_ksacde
      ,mise: miseCodeRef.current?.getValue()
      })});
    const dataUA1 = await responseUA1.json();
    console.log('伝票ヘッダ:%o',dataUA1);
    if(dataUA1.res == -1){
      setAlert('伝票データ削除エラー');//TCGE0025：
      setItretclr('#f59d9d');
      mvFlg = 1;
    }else{
      console.log('伝票明細削除');
      const responseUA2 = await fetch('/TCSA0140/SL_DTL_DAT_del',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        ent_ksacde : cent_ksacde  //入力元会社コード
        ,ent_mise : cent_mise     //入力元店コード
        ,densbt : '05'              //伝票種別
        ,denno : denpyoNoRef.current?.getValue()            //伝票番号
        ,ksacde : cent_ksacde     //会社コード
        ,mise :  miseCodeRef.current?.getValue()        //店コード
        ,edaban : '01'              //枝番号
        })});
      const dataUA2 = await responseUA2.json();
      console.log('伝票明細:%o',dataUA2.res);
      if (dataUA2.res == -1) {
        setAlert('伝票データ削除エラー');//TCGE0025：
        setItretclr('#f59d9d');
        mvFlg = 1;
      }else{
        Gmclr();
        //こちらでmv制御する為、mvFlg立てる
        mvFlg = 9;
      }
    }
    return mvFlg;
  }
  //#endregion

  //#region スタイル
  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  // 確認のWidthを設定する
  const cssInputOutlineRetSetwidth = (obj) => {
    let resObj = obj;
    resObj.width = '50px';
    return resObj;
  }

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
         fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },
    //明細用
    cssInputOutline2: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#FFFFFF',               // 入力文字の色
      },
      '& label': {
        color: '#FFFFFF',               // 通常時のラベル色
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#FFFFFF',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#FFFFFF',   // ホバー時のボーダー色
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#FFFFFF',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):after': {
        borderBottomColor: '#FFFFFF',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#FFFFFF',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#FFFFFF',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#FFFFFF',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#FFFFFF',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
          fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
  }
  //#endregion

  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "650px",
          width: "820px",
          m: "10px auto",
          padding:"10px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="left"
        >
          <div style={{display:"flex"}}>
            <Typography variant={"h6"} sx={{ m: "10px" }}>
              移動伝票{ttl}
            </Typography>
            <Box width={'65%'} display={'flex'} justifyContent={'flex-end'}>
              <Typography variant={"h5"} sx={{ m: "15px",mt:'20px',fontSize:15  }}>
              ［{itKBNNM}］
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px",fontSize:20  }}>
                ({itRcnt.toLocaleString()+'件'}){/* {state.JGNMEIKNJ} */}
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px",mt:'20px',fontSize:15  }}>
                {state.MISEMEI}：{state.JGNMEIKNJ}
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px",mt:'20px',fontSize:15  }}>
                { sdate }
              </Typography>
            </Box>
          </div>
        </Grid>

        <Stack direction="row" >
          {/* 区分 */}
          <CKubun
            ref = {kubunRef}
            onKeyDown = {onKeyDown}
            inputRef = {inKBN}
            inputRtRef = {inKBN}
            inputNxRef = {inDENNO}
          />
          {/* 伝票No. */}
          <CDenpyoNo
            ref = {denpyoNoRef}
            onKeyDown = {onKeyDown}
            inputRef = {inDENNO}
            inputRtRef = {inKBN}
            inputNxRef = {dissmise ? inBMNCDE : inMISE}
          />
          <InputLabel variant={'outlined'} >　　　　　　　　　　　　　　　　　　　　　　　　　</InputLabel>
          {/* 計上年月. */}
          <CKeijyoYM
            ref = {keijyoYMRef}
            inputRef = {inKIJYYM}
          />
        </Stack>
        <br />
        <Stack direction="row">
          {/* 店CD */}
          <CMiseCode
            ref = {miseCodeRef}
            onKeyDown = {onKeyDown}
            inputRef = {inMISE}
            inputRtRef = {inDENNO}
            inputNxRef = {inBMNCDE}
            dissmise = {dissmise}
          />
          {/* 分類CD */}
          <CBunruiCode
            ref = {bunruiCodeRef}
            onKeyDown = {onKeyDown}
            inputRef = {inBMNCDE}
            inputRtRef = {dissmise ? inDENNO : inMISE}
            inputNxRef = {inASTCDE}
          />
          {/* ＡＵ */}
          <CAstCode
            ref = {astCodeRef}
            onKeyDown = {onKeyDown}
            inputRef = {inASTCDE}
            inputRtRef = {inBMNCDE}
            inputNxRef = {inDENYMD}
          />
          &emsp;
          {/* 発送日 */}
          <CDenpyoYMD
            ref = {denpyoYMDRef}
            onKeyDown = {onKeyDown}
            inputRef = {inDENYMD}
            inputRtRef = {inASTCDE}
            // inputNxRef = {inUIRMISE}
            inputNxRef = {dissumise ? inUIRBMNCDE : inUIRMISE}
          />
          <InputLabel variant={'outlined'} >→</InputLabel>
          &emsp;
          {/* 店CD（受取店） */}
          <CUketoriMiseCode
            ref = {uketoriMiseCodeRef}
            onKeyDown = {onKeyDown}
            inputRef = {inUIRMISE}
            inputRtRef = {inDENYMD}
            inputNxRef = {inUIRBMNCDE}
            dissumise = {dissumise}
          />
          {/* 分類CD（受取店） */}
          <CUketoriBunruiCode
            ref = {uketoriBunruiCodeRef}
            onKeyDown = {onKeyDown}
            inputRef = {inUIRBMNCDE}
            // inputRtRef = {inUIRMISE}
            inputRtRef = {dissumise ? inDENYMD : inUIRMISE}
            inputNxRef = {inUIRASTCDE}
          />
          {/* ＡＵ（受取店） */}
          <CUketoriAstCode
            ref = {uketoriAstCodeRef}
            onKeyDown = {onKeyDown}
            inputRef = {inUIRASTCDE}
            inputRtRef = {inUIRBMNCDE}
            inputNxRef = {inSHNCDE1}
          />
          &emsp;
        </Stack>
        <br />
        {/* テーブル表示処理 */}
        <TableContainer
          component={Paper}
          sx={{maxWidth:800}}
          onBlur={(e) => {
            // setBlurTableflg(false);
            // setTimeout(() => setBlurTableflg(true),100);
            if (e.relatedTarget?.id != null && e.relatedTarget?.id != undefined)  //2023/11/13　明細内のカーソル移動だけで再レンダリングしないよう対応
            {
              let type = getInputType(e.relatedTarget.id);
              if(type !== 2){
                if (blurTableflg === true)
                {
                  setBlurTableflg(false);
                }else{
                  setBlurTableflg(true);
                }
              }
            }
          }}
        >
          <Table sx={{border:0}} aria-label="spanning table" size="small">
            <TableHead> {/* テーブル表示処理ヘッダ部 */}
              <TableRow>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="100px" align="center" bgcolor="lightgray">商品コード</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="40px" align="center"  bgcolor="lightgray">数量</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="50px" align="center"  bgcolor="lightgray">原価単価</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="50px" align="center"  bgcolor="lightgray">売価単価</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="50px" align="center"  bgcolor="lightgray">売価金額</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>   {/* テーブル表示処理明細部 */}
              <TableRow>
                {/* 商品コード1 */}
                <CShohinCode
                  ref = {shohinCode1Ref}
                  id = {'SHNCDE1'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSHNCDE1}
                  inputRtRef = {inUIRASTCDE}
                  inputNxRef = {inSUU1}
                  inUpRef = {inSHNCDE1}
                  inRgRef = {inSUU1}
                  inDnRef = {inSHNCDE2}
                  inLfRef = {inSHNCDE1}
                  dismeisai = {dismeisai}
                />

                {/* 数量1 */}
                <CSuuryo
                  ref = {suuryo1Ref}
                  id = {'SUU1'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSUU1}
                  inputRtRef = {inSHNCDE1}
                  inputNxRef = {inGNT1}
                  inUpRef = {inSUU1}
                  inRgRef = {inGNT1}
                  inDnRef = {inSUU2}
                  inLfRef = {inSHNCDE1}
                  dismeisai = {dismeisai}
                />

                {/* 原価単価1 */}
                <CGenkaTanka
                  ref = {genkaTanka1Ref}
                  id = {'GNT1'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNT1}
                  inputRtRef = {inSUU1}
                  inputNxRef = {inBTN1}
                  inUpRef = {inGNT1}
                  inRgRef = {inBTN1}
                  inDnRef = {inGNT2}
                  inLfRef = {inSUU1}
                  dismeisai = {dismeisai}
                />

                {/* 売価単価1 */}
                <CBaikaTanka
                  ref = {baikaTanka1Ref}
                  id = {'BTN1'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBTN1}
                  inputRtRef = {inGNT1}
                  inputNxRef = {inSHNCDE2}
                  inUpRef = {inBTN1}
                  inRgRef = {inBTN1}
                  inDnRef = {inBTN2}
                  inLfRef = {inGNT1}
                  dismeisai = {dismeisai}
                />

                {/* 売価金額1 */}
                <CBaikakingaku
                  ref = {baikaKingaku1Ref}
                  id = {'BAKKIN1'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBAKKIN1}
                  inputRtRef = {inBTN1}
                  inputNxRef = {inSHNCDE2}
                  inUpRef = {inBAKKIN1}
                  inRgRef = {inBAKKIN1}
                  inDnRef = {inBAKKIN2}
                  inLfRef = {inBTN1}
                />
              </TableRow>
              <TableRow>
                {/* 商品コード2 */}
                <CShohinCode
                  ref = {shohinCode2Ref}
                  id = {'SHNCDE2'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSHNCDE2}
                  inputRtRef = {inBAKKIN1}
                  inputNxRef = {inSUU2}
                  inUpRef = {inSHNCDE1}
                  inRgRef = {inSUU2}
                  inDnRef = {inSHNCDE3}
                  inLfRef = {inSHNCDE2}
                  dismeisai = {dismeisai}
                />

                {/* 数量2 */}
                <CSuuryo
                  ref = {suuryo2Ref}
                  id = {'SUU2'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSUU2}
                  inputRtRef = {inSHNCDE2}
                  inputNxRef = {inGNT2}
                  inUpRef = {inSUU1}
                  inRgRef = {inGNT2}
                  inDnRef = {inSUU3}
                  inLfRef = {inSHNCDE2}
                  dismeisai = {dismeisai}
                />

                {/* 原価単価2 */}
                <CGenkaTanka
                  ref = {genkaTanka2Ref}
                  id = {'GNT2'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNT2}
                  inputRtRef = {inSUU2}
                  inputNxRef = {inBTN2}
                  inUpRef = {inGNT1}
                  inRgRef = {inBTN2}
                  inDnRef = {inGNT3}
                  inLfRef = {inSUU2}
                  dismeisai = {dismeisai}
                />

                {/* 売価単価2 */}
                <CBaikaTanka
                  ref = {baikaTanka2Ref}
                  id = {'BTN2'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBTN2}
                  inputRtRef = {inGNT2}
                  inputNxRef = {inSHNCDE3}
                  inUpRef = {inBTN1}
                  inRgRef = {inBTN2}
                  inDnRef = {inBTN3}
                  inLfRef = {inGNT2}
                  dismeisai = {dismeisai}
                />

                {/* 売価金額2 */}
                <CBaikakingaku
                  ref = {baikaKingaku2Ref}
                  id = {'BAKKIN2'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBAKKIN2}
                  inputRtRef = {inBTN2}
                  inputNxRef = {inSHNCDE3}
                  inUpRef = {inBAKKIN1}
                  inRgRef = {inBAKKIN2}
                  inDnRef = {inBAKKIN3}
                  inLfRef = {inBTN2}
                />
              </TableRow>
              <TableRow>
                {/* 商品コード3 */}
                <CShohinCode
                  ref = {shohinCode3Ref}
                  id = {'SHNCDE3'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSHNCDE3}
                  inputRtRef = {inBAKKIN2}
                  inputNxRef = {inSUU3}
                  inUpRef = {inSHNCDE2}
                  inRgRef = {inSUU3}
                  inDnRef = {inSHNCDE4}
                  inLfRef = {inSHNCDE3}
                  dismeisai = {dismeisai}
                />

                {/* 数量3 */}
                <CSuuryo
                  ref = {suuryo3Ref}
                  id = {'SUU3'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSUU3}
                  inputRtRef = {inSHNCDE3}
                  inputNxRef = {inGNT3}
                  inUpRef = {inSUU2}
                  inRgRef = {inGNT3}
                  inDnRef = {inSUU4}
                  inLfRef = {inSHNCDE3}
                  dismeisai = {dismeisai}
                />

                {/* 原価単価3 */}
                <CGenkaTanka
                  ref = {genkaTanka3Ref}
                  id = {'GNT3'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNT3}
                  inputRtRef = {inSUU3}
                  inputNxRef = {inBTN3}
                  inUpRef = {inGNT2}
                  inRgRef = {inBTN3}
                  inDnRef = {inGNT4}
                  inLfRef = {inSUU3}
                  dismeisai = {dismeisai}
                />

                {/* 売価単価3 */}
                <CBaikaTanka
                  ref = {baikaTanka3Ref}
                  id = {'BTN3'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBTN3}
                  inputRtRef = {inGNT3}
                  inputNxRef = {inSHNCDE4}
                  inUpRef = {inBTN2}
                  inRgRef = {inBTN3}
                  inDnRef = {inBTN4}
                  inLfRef = {inGNT3}
                  dismeisai = {dismeisai}
                />

                {/* 売価金額3 */}
                <CBaikakingaku
                  ref = {baikaKingaku3Ref}
                  id = {'BAKKIN3'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBAKKIN3}
                  inputRtRef = {inBTN3}
                  inputNxRef = {inSHNCDE4}
                  inUpRef = {inBAKKIN2}
                  inRgRef = {inBAKKIN3}
                  inDnRef = {inBAKKIN4}
                  inLfRef = {inBTN3}
                />
              </TableRow>
              <TableRow>
                {/* 商品コード4 */}
                <CShohinCode
                  ref = {shohinCode4Ref}
                  id = {'SHNCDE4'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSHNCDE4}
                  inputRtRef = {inBAKKIN3}
                  inputNxRef = {inSUU4}
                  inUpRef = {inSHNCDE3}
                  inRgRef = {inSUU4}
                  inDnRef = {inSHNCDE5}
                  inLfRef = {inSHNCDE4}
                  dismeisai = {dismeisai}
                />

                {/* 数量4 */}
                <CSuuryo
                  ref = {suuryo4Ref}
                  id = {'SUU4'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSUU4}
                  inputRtRef = {inSHNCDE4}
                  inputNxRef = {inGNT4}
                  inUpRef = {inSUU3}
                  inRgRef = {inGNT4}
                  inDnRef = {inSUU5}
                  inLfRef = {inSHNCDE4}
                  dismeisai = {dismeisai}
                />

                {/* 原価単価4 */}
                <CGenkaTanka
                  ref = {genkaTanka4Ref}
                  id = {'GNT4'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNT4}
                  inputRtRef = {inSUU4}
                  inputNxRef = {inBTN4}
                  inUpRef = {inGNT3}
                  inRgRef = {inBTN4}
                  inDnRef = {inGNT5}
                  inLfRef = {inSUU4}
                  dismeisai = {dismeisai}
                />

                {/* 売価単価4 */}
                <CBaikaTanka
                  ref = {baikaTanka4Ref}
                  id = {'BTN4'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBTN4}
                  inputRtRef = {inGNT4}
                  inputNxRef = {inSHNCDE5}
                  inUpRef = {inBTN3}
                  inRgRef = {inBTN4}
                  inDnRef = {inBTN5}
                  inLfRef = {inGNT4}
                  dismeisai = {dismeisai}
                />

                {/* 売価金額4 */}
                <CBaikakingaku
                  ref = {baikaKingaku4Ref}
                  id = {'BAKKIN4'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBAKKIN4}
                  inputRtRef = {inBTN4}
                  inputNxRef = {inSHNCDE5}
                  inUpRef = {inBAKKIN3}
                  inRgRef = {inBAKKIN4}
                  inDnRef = {inBAKKIN5}
                  inLfRef = {inBTN4}
                />
              </TableRow>
              <TableRow>
                {/* 商品コード5 */}
                <CShohinCode
                  ref = {shohinCode5Ref}
                  id = {'SHNCDE5'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSHNCDE5}
                  inputRtRef = {inBAKKIN4}
                  inputNxRef = {inSUU5}
                  inUpRef = {inSHNCDE4}
                  inRgRef = {inSUU5}
                  inDnRef = {inSHNCDE6}
                  inLfRef = {inSHNCDE5}
                  dismeisai = {dismeisai}
                />

                {/* 数量5 */}
                <CSuuryo
                  ref = {suuryo5Ref}
                  id = {'SUU5'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSUU5}
                  inputRtRef = {inSHNCDE5}
                  inputNxRef = {inGNT5}
                  inUpRef = {inSUU4}
                  inRgRef = {inGNT5}
                  inDnRef = {inSUU6}
                  inLfRef = {inSHNCDE5}
                  dismeisai = {dismeisai}
                />

                {/* 原価単価5 */}
                <CGenkaTanka
                  ref = {genkaTanka5Ref}
                  id = {'GNT5'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNT5}
                  inputRtRef = {inSUU5}
                  inputNxRef = {inBTN5}
                  inUpRef = {inGNT4}
                  inRgRef = {inBTN5}
                  inDnRef = {inGNT6}
                  inLfRef = {inSUU5}
                  dismeisai = {dismeisai}
                />

                {/* 売価単価5 */}
                <CBaikaTanka
                  ref = {baikaTanka5Ref}
                  id = {'BTN5'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBTN5}
                  inputRtRef = {inGNT5}
                  inputNxRef = {inSHNCDE6}
                  inUpRef = {inBTN4}
                  inRgRef = {inBTN5}
                  inDnRef = {inBTN6}
                  inLfRef = {inGNT5}
                  dismeisai = {dismeisai}
                />

                {/* 売価金額5 */}
                <CBaikakingaku
                  ref = {baikaKingaku5Ref}
                  id = {'BAKKIN5'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBAKKIN5}
                  inputRtRef = {inBTN5}
                  inputNxRef = {inSHNCDE6}
                  inUpRef = {inBAKKIN4}
                  inRgRef = {inBAKKIN5}
                  inDnRef = {inBAKKIN6}
                  inLfRef = {inBTN5}
                />
              </TableRow>
              <TableRow>
                {/* 商品コード6 */}
                <CShohinCode
                  ref = {shohinCode6Ref}
                  id = {'SHNCDE6'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSHNCDE6}
                  inputRtRef = {inBAKKIN5}
                  inputNxRef = {inSUU6}
                  inUpRef = {inSHNCDE5}
                  inRgRef = {inSUU6}
                  inDnRef = {inSHNCDE6}
                  inLfRef = {inSHNCDE6}
                  dismeisai = {dismeisai}
                />

                {/* 数量6 */}
                <CSuuryo
                  ref = {suuryo6Ref}
                  id = {'SUU6'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSUU6}
                  inputRtRef = {inSHNCDE6}
                  inputNxRef = {inGNT6}
                  inUpRef = {inSUU5}
                  inRgRef = {inGNT6}
                  inDnRef = {inSUU6}
                  inLfRef = {inSHNCDE6}
                  dismeisai = {dismeisai}
                />

                {/* 原価単価6 */}
                <CGenkaTanka
                  ref = {genkaTanka6Ref}
                  id = {'GNT6'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNT6}
                  inputRtRef = {inSUU6}
                  inputNxRef = {inBTN6}
                  inUpRef = {inGNT5}
                  inRgRef = {inBTN6}
                  inDnRef = {inGNT6}
                  inLfRef = {inSUU6}
                  dismeisai = {dismeisai}
                />

                {/* 売価単価6 */}
                <CBaikaTanka
                  ref = {baikaTanka6Ref}
                  id = {'BTN6'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBTN6}
                  inputRtRef = {inGNT6}
                  inputNxRef = {inSUUG}
                  inUpRef = {inBTN5}
                  inRgRef = {inBTN6}
                  inDnRef = {inBTN6}
                  inLfRef = {inGNT6}
                  dismeisai = {dismeisai}
                />

                {/* 売価金額6 */}
                <CBaikakingaku
                  ref = {baikaKingaku6Ref}
                  id = {'BAKKIN6'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBAKKIN6}
                  inputRtRef = {inBTN6}
                  inputNxRef = {inSUUG}
                  inUpRef = {inBAKKIN5}
                  inRgRef = {inBAKKIN6}
                  inDnRef = {inBAKKIN6}
                  inLfRef = {inBTN6}
                />
              </TableRow>
            </TableBody>
          </Table>
          </TableContainer><br/>
          <Table sx={{border:0,maxWidth:800}} aria-label="spanning table" size="small">
            <TableBody>
              <TableRow>
                <TableCell align="center" bgcolor="lightgray" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="147px" >合計</TableCell>
                {/* 数量合計 */}
                <CSuuryoGoukei
                  ref = {suuryoGoukeiRef}
                  onKeyDown = {onKeyDown}
                  inputRef = {inSUUG}
                  inputRtRef = {inSHNCDE1}
                  inputNxRef = {inGNTG}
                  dismeisai = {dismeisai}
                />

                {/* 原価単価合計 */}
                <CGenkaTankaGoukei
                  ref = {genkaTankaGoukeiRef}
                  onKeyDown = {onKeyDown}
                  inputRef = {inGNTG}
                  inputRtRef = {inSUUG}
                  inputNxRef = {inBTNG}
                  dismeisai = {dismeisai}
                />

                {/* 売価単価合計 */}
                <CBaikaTankaGoukei
                  ref = {baikaTankaGoukeiRef}
                  onKeyDown = {onKeyDown}
                  inputRef = {inBTNG}
                  inputRtRef = {inGNTG}
                  inputNxRef = {inRET}
                  dismeisai = {dismeisai}
                />

                {/* 売価金額合計 */}
                <CBaikaKingakuGoukei
                  ref = {baikaKingakuGoukeiRef}
                  onKeyDown = {onKeyDown}
                  inputRef = {inBAKKING}
                  inputRtRef = {inBTNG}
                  inputNxRef = {inRET}
                />
              </TableRow>
            </TableBody>
          </Table>
        <br />
        <font color="red">{ alert }</font>
        <Stack direction="row" spacing={2}>
          <font color="black">{ 'F9:中止　F5:終了　Up:前項目　Down:次項目　　　　　　　　　　　　　　　　　　　　　　　　　' }</font>
          <NumericFormat  //確認
            id={'ret'}
            inputRef={inRET}                    //項目名定義？
            thousandSeparator={false}           //1000単位の区切り
            color='success'
            customInput={TextField}             //textfieldにreact-number-formatの機能を追加
            size='small'                        //textboxの全体サイズ
            label='確認'                         //textboxの左上に表示するラベル
            variant='outlined'                  //枠の表示方法
            fixedDecimalScale={false}            //小数点On/Off
            InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
            sx={Object.assign({},cssInputOutlineRetSetwidth(styles.cssInputOutline), {background:itRETclr})}
            //value={dbdata.ZEIRTU}               //初期値
            allowLeadingZeros                   //前ゼロ表示 on
            disabled={false}                    //使用可／不可
            inputProps={{ autoComplete: 'off',maxLength: 0,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
            onKeyDown={(e) => onKeyDown(e.key,inRET,inBTNG,inRET)}
            // 2023/10/05 処理の重複を制御
            onFocus={(e) =>{
              if(!enable){
                setEnable(true);
                onFocus_ret();
              }
            }}
            onBlur={(e) => setEnable(false)}
          />
        </Stack>
      </Paper>
    </Grid>
  );
};

export default TCSA0140;