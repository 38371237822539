
import React, { useState,useContext,useEffect } from 'react'
import { Grid, Paper, Typography} from '@mui/material';
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import TCPD0141 from './TCPD0141'; // ヘッダ1画面
import TCPD0142 from './TCPD0142'; // ヘッダ2画面
import { useDenKbnAction }from './hooks/useDenKbnAction'  // 伝票区分共通処理
import { useSyodanAction } from './hooks/useSyodanAction' // 商談No共通処理
import { useRiyuAction } from './hooks/useRiyuAction'     // 理由No共通処理

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);

      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCPD0140 () {
  const [screenPhase, setScreenPhase] = useState(1);        // 画面フェーズ

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('');
  const [sdate, setsDate] = useState([]);     // 本日日付（YYYY/MM/DD)
  const [iryoZei, setIryoZei] = useState(0);  // 衣料タグ税剥がし税率固定

  //disabled制御用
  const [disRyuno, setDisRyuno] = useState(false);    // 理由No

  // 画面が再読み込みされた際に伝票区分が初期値に戻らない為のフラグ
  const [relodeFlg, setRelodeFlg] = useState(0);
  const [visibleSYODAN, setVisibleSYODAN] = useState(false);    // 商談No表示フラグ

  const {syodanData, getSyodan, setSyodan} = useSyodanAction(); // 商談No共通処理
  const {denKbnList, denKbnComment, denKbnName, changeDenKbn2} = useDenKbnAction(); // 伝票区分共通処理
  const {riyuList, riyuComment, riyuName, changeRiyuNo2} = useRiyuAction();  // 理由No共通処理
  const [rows, setRows] = useState([]);

  // ヘッダデータ　◇画面上だけのデータ
  const headerData = {
    KSACDE:state.KSACDES,         // 会社コード
    MISE:state.MISES,             // 店コード
    BMNCDE:'',                    // 部門コード
    DENYMD:'',                    // 伝票日付
    DENNO:'',                     // 伝票番号
    DEN_EDABAN :'1',              // 伝票枝番
    SEQ:'001',                    // SEQ
    TENPONM:state.MISEMEI,        // 店舗名
    BMNMEI:'',                    // 部門名
    HZK:'',                       // 日付
    HZK2:'',                      // ◇日付2
    DENKBN:'',                    // 伝票区分
    DENKBNNM:'',                  // 伝票区分名
    RYUNO:'',                     // 理由№
    RYUMEI:'',                    // 理由名
    SYODAN:'',                    // 商談書№
    SIRCDE:'',                    // 仕入先(取引先)コード
    SIRCDE2:'',                   // 仕入先(取引先)コード2
    SIRMEIK:'',                   // 仕入先(取引先)名
    SIRMEIK2:'',                  // 仕入先(取引先)名2
    SRYFOT:0,                     // 数量合計
    GNKFOT:0,                     // 原価金額合計
    BAKFOT:0,                     // 売価金額合計
    JGNCDE:state.JGNCDE,          // 従業員コード
    JGNMEIKNJ:state.JGNMEIKNJ,    // 担当者氏名
    IN_DATE:'',                   // 入力日時
    ZEIRTU:'',                    // 税率
    ZEIRTU2:'',                   // 税率2
    JOTKBN:'1',                   // 状態区分
    KIJYKETKBN:'',                // 計上結果区分
    PRG_ID: 'TCPD0140',           // プログラムID
    DELFLG: '0',                  // 削除フラグ
    TRL_ID:state.MISES,           // 端末ＩＤ★一旦店コード入れる
    GNKKNRFLG: '',                // 原価管理フラグ
  }
  const [headerItem,setHeaderItem] = useState(headerData);
  // ヘッダ2データ
  const rowData = {
    id : 1,                       // ◇行№
    KSACDE :state.KSACDES,        // 会社コード
    MISE : state.MISES,           // 店コード
    BMNCDE : '',                  // 部門コード
    DENYMD : '',                  // 伝票日付
    DENNO : '',                   // 伝票番号
    DEN_EDABAN : '1',             // 伝票枝番
    SEQ : '',                     // ＳＥＱ
    //REC_NO : '',                // 行№
    JANCDE : '',                  // ＪＡＮコード
    SHNMEIKN : '',                // 商品名
    KIKAKUKN : '',                // 規格
    SUU : 0,                      // 数量(数字)
    SUU2 : '',                    // ◇数量2(文字列)
    GNT : 0,                      // 原単価(数字)
    GNT2 : '',                    // ◇原単価2(文字列)
    GNKKIN : 0,                   // 原価金額
    BTN : 0,                      // 売単価(数字)
    BTN2 : '',                    // ◇売単価2(文字列)
    BAKKIN : 0,                   // 売価金額
    JYOTOYMD : '',                // 譲渡日
    JYOTOYMD2 : '',               // ◇譲渡日2
    BEF_CST_AMT : '0.00',         // 訂正前原価
    AFT_CST_AMT : '0.00',         // 訂正後原価
    BEF_SEL_AMT : '0',            // 訂正前売価
    AFT_SEL_AMT : '0',            // 訂正後売価
    EOSCDE : '',                  // ＥＯＳコード
    JANCDE2 : '',                 // ＪＡＮコード２
    TAGASTCDE : '',               // タグＡＵ
    TAGLINCDE : '',               // タグライン
    TAGCLSCDE : '',               // タグクラス
    TAGSZN : '',                  // タグシーズン
    TAGITM : '',                  // タグアイテム
    TRIHBN : '',                  // 取引先品番
    SIZCDE : '',                  // サイズコード
    TAGSIZMEI : '',               // サイズ名カナ
    TAGCOLCDE : '',               // カラーコード
    TAGCOLMEI : '',               // カラー名カナ
    IRYO_FLG : '0',               // 衣料フラグ
    IN_CD_KBN : '',               // 入力コード区分
    UPD_JGNCDE: state.JGNCDE,     // 担当者氏名
    PRG_ID: 'TCPD0140',           // プログラムID
    DELFLG: '0',                  // 削除フラグ
    TRL_ID:state.MISES,           // 端末ＩＤ★一旦店コード入れる
    SIRCDE:'',                   // 仕入先(取引先)コード2
    SIRMEIK:'',                   // 仕入先(取引先)名
    ZEIRTU:'',                    // 税率
  }
  const [rowItem,setRowItem] = useState(rowData);

  const commonStyles = {
    bgcolor: 'background.paper',
    m: 1,
    border: 0,
    width: '40px',
    height: '40px',
  };

  const ckDate = (strYYYYMMDD) =>{  // YYYY/MM/DD ⇒ 2023/09/07 等
    if(!strYYYYMMDD.match(/^\d{4}\/\d{2}\/\d{2}$/)){
        return false;
    }
    var y = strYYYYMMDD.split("/")[0];
    var m = strYYYYMMDD.split("/")[1] - 1;
    var d = strYYYYMMDD.split("/")[2];
    var date = new Date(y,m,d);
    if(date.getFullYear() != y || date.getMonth() != m || date.getDate() != d){
        return false;
    }
    return true;
  }

  // 初期処理
  useEffect (() => {
    if (state.KENGEN === '') {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    } else {
      console.log('メニュー:%o',state);
    }
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);

    if(relodeFlg === 0){
      setHeaderReset();
    }

    getIryo();
  }, []);

  // ヘッダの表示をリセットする。
  const setHeaderReset =  async () =>{
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = (d.getMonth() + 1).toString().padStart(2, '0');
    let day = d.getDate().toString().padStart(2, '0');

    const header = headerData;
    header.HZK = year + month + day;                // 日付
    header.HZK2 = year + '/' + month + '/' + day;   // 日付2
    header.DENKBN = '02'
    // setHeaderItem(HeaderItem　=> ({...HeaderItem,HZK:year + month + day}));
    // setHeaderItem(HeaderItem　=> ({...HeaderItem,HZK2:year + '/' + month + '/' + day}));
    // setHeaderItem(HeaderItem　=> ({...HeaderItem,DENKBN:'02'}));
    await setHeaderItem(header);
    if (denKbnList.length > 0){
      changeDenKbn('02', header);     // 23/09/25
    }
  }

  const setHeaderReset2 =  async () =>{
    let d = new Date(state.SDAY);

    const header = JSON.parse(JSON.stringify(headerItem));
    console.log('setHeaderReset2:%o',header)
    if (!(header.DENKBN === '05' || header.DENKBN === '06'))
    {
      header.RYUNO = '';
      header.RYUMEI = '';
    }
    header.SIRCDE = '';   // 仕入先(取引先)コード
    header.SIRMEIK = '';  // 仕入先(取引先)名
    header.ZEIRTU = '';   // 税率
    header.SIRCDE2 = '';   // 仕入先(取引先)コード
    header.SIRMEIK2 = '';  // 仕入先(取引先)名
    header.ZEIRTU2 = '';   // 税率
    await setHeaderItem(header);

  }

  // 伝票区分の初期表示（伝票区分名表示のために伝票区分リスト読込後に処理）
  useEffect (() => {
    if (denKbnList.length > 0){
      if(relodeFlg === 0){
        const header = JSON.parse(JSON.stringify(headerItem));
        changeDenKbn('02', header);     // 23/09/25
        setRelodeFlg(1);
      }
    }
  }, [denKbnList]);

  // 伝票区分入力時の処理
  const changeDenKbn = (value, header) => {     // 23/09/25
    //const header = JSON.parse(JSON.stringify(headerItem));
    let denKbnName = '';
    if (value !== '') {
      const inputDenKbn = denKbnList.filter(element => {
        return Number(element.cd) === Number(value);
      });
      if (inputDenKbn.length !== 0) {
        denKbnName = inputDenKbn[0].name;
      }
    }
    // header.DENKBN = value;
    setHeaderItem(HeaderItem　=> ({...HeaderItem,DENKBN:value}));
    console.log('伝票区分:%o',denKbnName)
    // header.DENKBNNM = denKbnName;
    setHeaderItem(HeaderItem　=> ({...HeaderItem,DENKBNNM:denKbnName}));

    // 理由Noをリセットする。
    setDisRyuno(false);
    // header.RYUNO = '';
    // header.RYUMEI = '';
    setHeaderItem(HeaderItem　=> ({...HeaderItem,RYUNO:''}));
    setHeaderItem(HeaderItem　=> ({...HeaderItem,RYUMEI:''}));

    // 商談データをリセットする。
    setVisibleSYODAN(false);
    //header.SYODAN='';
    setHeaderItem(HeaderItem　=> ({...HeaderItem,SYODAN:''}));

    //setHeaderItem(header);
  };

  // 伝票区分入力時の処理
  const retDenKbn = (value) => {     // 23/09/25
    if (value !== '') {
      const inputDenKbn = denKbnList.filter(element => {
        return Number(element.cd) === Number(value);
      });
      if (inputDenKbn.length !== 0) {
        return inputDenKbn[0].name;
      }
    }
  };

  // 衣料タグ税剥がし税率固定取得
  const getIryo = async () => {
    const getRiyuCd = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({CDE: '006',SCDE: '0000'})});
    const riyuCdList = await getRiyuCd.json();
    if (riyuCdList.DATE01 !== '') {
      setIryoZei(Number(riyuCdList.DATE01));
    }
  }

  return (
    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "780px",
          width: "400px",
          m: "20px auto"
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="left"
        >
          <div style={{display:"flex"}}>
            <Grid container>
              <Grid item xs={6}>
                {/* <Paper> */}
                  <Typography variant={"h6"} sx={{ m: "5px" }}>
                    返品伝票作成
                  </Typography>
                {/* </Paper> */}
              </Grid>
              <Grid item xs={3}>
              </Grid>
              <Grid item xs={3}>
                {/* <Paper>  */}
                    <Typography variant={"h6"} sx={{ m: "5px" }}>
                      { sdate }
                    </Typography>
                {/* </Paper> */}
              </Grid>
              <Grid item xs={8}>
                {/* <Paper> */}
                  <Typography variant={"h7"} sx={{ m: "5px" }}>
                  &nbsp;
                  </Typography>
                {/* </Paper> */}
              </Grid>
              <Grid item xs={4}>
                  <Typography variant={"h7"} sx={{ m: "10px" } } component="span">
                    {headerItem.JGNMEIKNJ}
                  </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <br />
      {(()=> {
        if(screenPhase == 1) {
          // 1画面目
          return (
            <Grid>
              <TCPD0141
                sdate={sdate}
                ckDate={ckDate}
                setRows={setRows}
                rowData={rowData}
                setRowItem= {setRowItem}
                rowItem={rowItem}
                headerData={headerData}
                headerItem={headerItem}
                setHeaderItem={setHeaderItem}
                getSyodan={getSyodan}
                setSyodan={setSyodan}
                denKbnList = {denKbnList}
                denKbnComment = {denKbnComment}
                changeDenKbn ={changeDenKbn}
                visibleSYODAN = {visibleSYODAN}
                setVisibleSYODAN = {setVisibleSYODAN}
                riyuList ={riyuList}
                riyuComment = {riyuComment}
                disRyuno={disRyuno}
                setDisRyuno={setDisRyuno}
                setScreenPhase={setScreenPhase}
                setHeaderReset2={setHeaderReset2}     // 23/09/25
              />
            </Grid>
          )
        } else if (screenPhase == 2) {
          // 2画面目
          return (
            <Grid>
              <TCPD0142
                setScreenPhase={setScreenPhase}
                headerData={headerData}
                headerItem={headerItem}
                setHeaderItem={setHeaderItem}
                rowData={rowData}
                rowItem={rowItem}
                setRowItem={setRowItem}
                rows={rows}
                setRows={setRows}
                syodanData={syodanData}
                iryoZei={iryoZei}
                sdate={sdate}
                ckDate={ckDate}
                setHeaderReset2={setHeaderReset2}     // 23/09/25
                denKbnList={denKbnList}
                retDenKbn={retDenKbn}
              />
            </Grid>
          )
        }
      })()}
    </Paper>
  </Grid>
  );
};

export default TCPD0140;