//S SK-0050-018 2024/7/25           TCI)M.K【要望：ログイン制御】
import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import {
  DataGrid,
  jaJP                              // 日本語用のファイルをインポート
} from '@mui/x-data-grid';

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSH0050 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('');
  const [bmncde,setBmncde] = useState('');
  const [sdate, setsDate] = useState([]);     // 本日日付（YYYY/MM/DD)
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [pagenationModel, setPagenationModel] = useState({page: 0, pageSize: 10});

  const inBtnSearch = useRef(null);

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  // 初期処理
  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);

    onClick_FooterButton_Next1();
  },[]);

  useEffect(() => {
  }, []);


  // ボタン１押下時の処理
  const onClick_FooterButton_Next1 = async() => {
    console.log('検索ボタン押下されました:%o',bmncde)
    let errflg = 0;

    // 排他情報抽出
    failedSearch();
    const getHitDat = await fetch('/TCSH0000/get_HIT_DAT_all',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        KSACDE:state.KSACDES, 
        MISE:state.MISES, 
      })});
    const HitDat = await getHitDat.json();
    console.log('HitDat:%o',HitDat);

    if (HitDat.ret.length === 0) {
      // データが存在しない場合は、以下のメッセージを表示して、明細の0行表示を行う。
      window.alert('条件に一致するデータが存在しません。');
      failedSearch();
      return -1;
    }
    if (errflg === 0 )
    {

      let id = 1;
      const newRows = [];
      HitDat.ret.forEach(element => {
        const date = new Date(element.ADD_DATE);
        // なんか分からんけど、AWSではUTCと判断される為、強制的にUTCからJPに時刻変換する
        // const dateString = date.toLocaleString('ja-JP');
        const dateString = date.toLocaleString('ja-JP', { timeZone: 'UTC' });
        console.log(dateString); // 例："2023/5/3 12:34:56"
          newRows.push({
          id: id,
          ksacde:element.KSACDE,
          mise:element.MISE,
          bmncde:element.BMNCDE,
          jgncde: element.JGNCDE,
          jgnmeiknj: element.JGNMEIKNJ,
          add_date: dateString,
          delflg:element.DELFLG,
        }
        );
        id++;
      });
      console.log('newRows:%o',newRows)

      setRows(newRows);
      return 0;
    }
  }

  // ボタン２押下時の処理
  const onClick_FooterButton_Next2 = async() => {
    const selected = rows.filter(x => selectionModel.indexOf(x.id) != -1);
    if (selected.length == 0) {
      window.alert('レコードが選択されていません。再度選択してください。'); 
      return;
    }else{
      console.log('選択行情報:%o',selected);
      const target = selected[0];
      const repHitDat = fetch('/TCSH0000/Replace_HIT_DAT',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:target.ksacde, 
          MISE:target.mise, 
          BMNCDE:target.bmncde, 
          JGNCDE:target.jgncde, 
          JGNMEIKNJ:target.jgnmeiknj, 
          PRG_ID:'TCSH0050',
          DELFLG:'1',
        })});
      console.log('repHitDat:%o',repHitDat);
      // 再検索
      setTimeout(() => {
        onClick_FooterButton_Next1();
      }, 500);
    }
  }

  // 戻るボタン押下時の処理
  const onClick_FooterButton_Back = async() => {
    if (state.IRYOFLG==='0')          //食品・住居
    {
      navigate('/TCSH0020');
    }else if(state.IRYOFLG==='1')     //衣料
    {
      navigate('/TCSH0021');
    }else{
      navigate('/');
    }
  }

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    //console.log(key);
    switch (key) {
      case "Enter":
        break;
      case "Home":
        break;
      case "ArrowUp":
        break;
      case "ArrowDown":
        break;
      default:
        break;
    }
  };  

  // 検索失敗時の明細0件表示
  const failedSearch = async () => {
    setPagenationModel({page: 0, pageSize: 10});      // 2024/05/29 Pageを初期化する
    await setRows([]);
  }

  // グリッド列情報
  const columns /*: GridColDef[]*/ = [
    // {
    //   field: 'detailAction',
    //   headerName: '　', // セルの右側に罫線を表示するために全角スペースを表示する
    //   align: 'left',
    //   width: 60,
    //   type: 'actions',
    //   // getActions: getDetailAction,
    //   description: '詳細画面を表示',  // ツールチップで表示される説明
    //   hideable: false,          // 非表示にできないようにする
    //   sortable: false,          // ソートできないようにする
    //   hideSortIcons: false,     // ソートアイコンを表示しない
    //   resizable: false,         // リサイズできないようにする
    //   disableExport: false,     // エクスポートに含めないようにする
    //   disableReorder: false,    // 並べ替えできないようにする
    //   filterable: false,        // フィルタリングしないようにする
    //   disableColumnMenu: true  // カラムメニューを表示しない
    // } ,

    //IDは必ず必要みたい
    // {
    //   field: 'id',
    //   headerName: 'No.',
    //   // align:'center',
    //   width: 10 ,
    //   editable: false ,         //編集可能:false
    //   sortable: false,          // ソートできないようにする
    //   disableColumnMenu: true,   // カラムメニューを表示しない
    // },
    {
      field: 'bmncde',
      headerName: '部門',
      headerAlign: 'center',
      width: 60 ,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,  // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{params.value}</Typography>
        </div>
      )
    },
    {
      field: 'jgncde',
      headerName: '従業員CD',
      headerAlign: 'center',
      width: 90 ,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{params.value}</Typography>
        </div>
      )
    },
    {
      field: 'add_date',
      headerName: 'ﾛｸﾞｲﾝ日時',
      headerAlign: 'center',
      width: 160,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,  // カラムメニューを表示しない
      disableColumnSelector: true,
      renderCell: (params) => (
        <div>
          <Typography>{params.value}</Typography>
        </div>
      )
    },
    {
      field: 'jgnmeiknj',
      headerName: '従業員名',
      headerAlign: 'center',
      width: 420,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,  // カラムメニューを表示しない
      disableColumnSelector: true,
      renderCell: (params) => (
        <div>
          <Typography>{params.value}</Typography>
        </div>
      )
    },
  ];

  //罫線表示（Data Grid）
  const gridStyle = {
    transform: 'scale(0.9)',
    grid: {
      // x-data-gridの全体的な縮小
      '& .MuiDataGrid-root': {
        transform: 'scale(0.9)',
      },
      '.MuiDataGrid-toolbarContainer': {
        borderBottom: 'solid 1px rgba(224, 224, 224, 1)'  // ついでにツールバーの下に罫線を引く
      },
      '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
        borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      // 2022/06/01 追記 列ヘッダの最終セルの右側に罫線が表示されなくなった・・・
      // 列ヘッダの最終列の右端に罫線を表示する
      '.MuiDataGrid-columnHeadersInner': {
        border: 'solid 1px rgba(224, 224, 224, 1) !important',
        transform: 'scale(0.9)',
      },
      '.MuiDataGrid-columnSeparator--sideRight':{
        visibility: 'show',
      },
      '.MuiDataGrid-columnHeaders':{
        height:'40px',
      },
      // 削除済明細は背景色をグレーとする
      '.MuiDataGrid-row.row-deactive': {
        background: '#888888',
      },
      // 選択状態を黄色にする 2023/12/04 add imai
      '.MuiDataGrid-row.Mui-selected':{
        background: '#ffff00',
        '&:hover': {
          background: '#ffff00',       // ホバー時のバックグランドカラー
        },
      },
    }
  };

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
         fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
    cssInputOutlineJotKbn: {
      '> div': {
        minHeight: 'auto !important',
        paddingTop: '.4em',
        paddingBottom: '.3em',
      }
    },
    cssInputSelect: {
      ' label': {
        color: '#000000'
      },
      ' fieldset': {
        borderColor: '#000000'
      },
    },
  }

  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "470px",
          width: "280px",
          m: "20px auto",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="center"
        >
          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
            <Typography variant={'h3'} sx={{ m: '5px',mt:'1px',fontSize:11,width:400,mr:'10px' }}>
            {state.MISES}:{state.BMNCDE} {state.MISEMEI}:{state.BRUMEI}<br/>{state.JGNMEIKNJ}
            </Typography>
            <Typography variant={'h3'} sx={{ m: '5px',mt:'10px',fontSize:11 ,width:110,mr:'1px'}}>
              { sdate }
            </Typography>
          </Box>
          {/* 画像の角丸対応 */}
          <Typography variant={"h6"} sx={{ m: "10px",mt:'-10px' }}>
          排他制御解除
          </Typography>
        </Grid>
        <Box >
            <Button
              inputRef={inBtnSearch}                   //項目名定義？
              type="submit" 
              color="success" 
              variant="contained" 
              sx={{width:50,mr:'5px',height:38,padding:0,mt:'-15px'}}
              onClick={(e) => onClick_FooterButton_Next1()}
              >
              {'照会'}
            </Button>
          </Box>
         <Box className="dataGridList" style={gridStyle} 
            sx={{ display: 'flex', justifyContent: 'flex-start',height: 350, width: '370px',padding:'0px',ml:'-45px',mt:'-10px' }}>
            <DataGrid
              sx={gridStyle.grid}     // --> 不具合対応のためcssで罫線を引く
              rows={rows}
              columns={columns}
              density='compact'//compact（狭め）、standard（標準）、comfortable（広め）
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              checkboxSelection={true}
              disableRowSelectionOnClick
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}  // メニュー日本語化
              showColumnRightBorder                                             // 列ヘッダセルの右側に線を引く
              showCellRightBorder                                               // セルの右側に線を引く
              getRowClassName={(params) => {
                if (params.row.DELFLG === '2') {
                  // 削除済明細にクラスを設定する
                  return 'row-deactive'
                }
                return '';
              }}
              rowSelectionModel={selectionModel}
              onRowSelectionModelChange={(RowIds) => {
                // const diffRows = rows.filter(x =>RowIds.indexOf(x.id) != -1);
                // const delRows = diffRows.filter(x => x.DELFLG == '1');
                // if (delRows.length > 0) {
                //   // 削除済明細の場合はクリックされた際に選択状態にしない
                //   return;
                // }
                // setSelectionModel(RowIds);
                console.log('RowIds,selectionModel:',RowIds,selectionModel);
                if (RowIds.length > 1) 
                {
                  const newSelectedId = RowIds[RowIds.length - 1];
                  setSelectionModel([newSelectedId]);
                }else if (RowIds.length === 0){
                  setSelectionModel([]);
                } else {
                  setSelectionModel(RowIds);
                }
              }}
              paginationModel={pagenationModel}
              onPaginationModelChange={(model) => {
                console.log(model);
                setPagenationModel(model);
              }}
              disableMultipleRowSelection={true}//複数行選択不可
              />
          </Box>
        <Box ml={2} flexDirection="row" justifyContent="flex-end" display="flex" marginTop={'5px'}>
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:50,mr:'150px',height:38,padding:0,mt:'-15px'}}
              onClick={(e) => onClick_FooterButton_Back()}
              >
              {'戻る'}
            </Button>
          </Box >
          <Box >
            <Button
              type="submit" 
              color="success" 
              variant="contained" 
              sx={{width:50,mr:'5px',height:38,padding:0,mt:'-15px'}}
              onClick={(e) => onClick_FooterButton_Next2()}
              >
              {'削除'}
            </Button>
          </Box >
        </Box>
        <p><font color="red">{ alert }</font></p>
      </Paper>
    </Grid>
  );
};

export default TCSH0050;
//E SK-0050-018
