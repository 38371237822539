import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, TextField, Grid, Paper, Typography, Stack } from '@mui/material';
import Table from '@mui/material/Table';              //テーブル用
import TableBody from '@mui/material/TableBody';      //テーブル用
import TableCell from '@mui/material/TableCell';      //テーブル用
import TableContainer from '@mui/material/TableContainer';  //テーブル用
import TableHead from '@mui/material/TableHead';      //テーブル用
import TableRow from '@mui/material/TableRow';        //テーブル用
import { NumericFormat } from 'react-number-format';    //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import { onNextFocus } from './hooks/useOnMyFocus'     // select()処理
import TCSA0100_parts from './TCSA0100_parts';

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);

      if (event.key === 'F1') { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === 'F5') { // F5キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === 'F9') { // F9キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

// コンポーネント呼び出し
const {
  CKubun,CDenpyoNo,CSireCode,CKeijyoYM,CMiseCode,CBunruiCode,CDenpyoKubun,CHattyuYMD,CNouhinYMD,CZituNouhinYMD,CBin,
  CTableCell,CTeiseiSuu,CHikiai,CTeiseiGenkaKingakuGoukei,CTeiseiBaikaKingakuGoukei
} = TCSA0100_parts();

function TCSA0100 () {
  //#region 変数宣言
  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('　');
  const [dismeisai, setMeisai] = useState(false);    //disabled設定（明細）
  const [dishttymd, setHttymd] = useState(true);    //disabled設定（明細）
  const [sdate, setsDate] = useState([]);
  const keyDisabled = useRef(false);

  const [cent_ksacde,setCent_ksacde] = useState('');  //各店対応用
  const [cent_mise,setCent_mise] = useState('');      //各店対応用

  let Err_flg = 0;                                 //カーソル移動時チェックon/off制御：0=on,1=off

  const [itRcnt,setItrcnt] = useState(0);         //登録件数
  const [itDENSBT,setItdensbt] = useState('');
  const [itKBNNM,setItkbnnm] = useState('');
  const [itGNKFOT,setItgnkfot] = useState('');
  const [itBAKFOT,setItbakfot] = useState('');
  //const [itDATMKBN,setItdatkbn] = useState('');

  // コンポーネント
  const kubunRef = useRef(null);                // 区分
  const denpyoNoRef = useRef(null);             // 伝票No.
  const sireCodeRef = useRef(null);             // 取引先CD
  const keijyoYMRef = useRef(null);             // 計上年月
  const miseCodeRef = useRef(null);             // 店CD
  const bunruiCodeRef = useRef(null);           // 分類CD
  const denpyoKubunRef = useRef(null);          // 伝票区分
  const hattyuYMDRef = useRef(null);            // 発注日
  const nouhinYMDRef = useRef(null);            // 納品日
  const zituNouhinYMDRef = useRef(null);        // 実納品日
  const binRef = useRef(null);                  // 便
  const shohinCode1Ref = useRef(null);          // 商品コード1
  const shohinCode2Ref = useRef(null);          // 商品コード2
  const shohinCode3Ref = useRef(null);          // 商品コード3
  const shohinCode4Ref = useRef(null);          // 商品コード4
  const shohinCode5Ref = useRef(null);          // 商品コード5
  const shohinCode6Ref = useRef(null);          // 商品コード6
  const shohinCode7Ref = useRef(null);          // 商品コード7
  const shohinCode8Ref = useRef(null);          // 商品コード8
  const shohinCode9Ref = useRef(null);          // 商品コード9
  const sizeCase1Ref = useRef(null);            // サイズケース1
  const sizeCase2Ref = useRef(null);            // サイズケース2
  const sizeCase3Ref = useRef(null);            // サイズケース3
  const sizeCase4Ref = useRef(null);            // サイズケース4
  const sizeCase5Ref = useRef(null);            // サイズケース5
  const sizeCase6Ref = useRef(null);            // サイズケース6
  const sizeCase7Ref = useRef(null);            // サイズケース7
  const sizeCase8Ref = useRef(null);            // サイズケース8
  const sizeCase9Ref = useRef(null);            // サイズケース9
  const suuryo1Ref = useRef(null);              // 数量1
  const suuryo2Ref = useRef(null);              // 数量2
  const suuryo3Ref = useRef(null);              // 数量3
  const suuryo4Ref = useRef(null);              // 数量4
  const suuryo5Ref = useRef(null);              // 数量5
  const suuryo6Ref = useRef(null);              // 数量6
  const suuryo7Ref = useRef(null);              // 数量7
  const suuryo8Ref = useRef(null);              // 数量8
  const suuryo9Ref = useRef(null);              // 数量9
  const teiseiSuu1Ref = useRef(null);           // 訂正後数量1
  const teiseiSuu2Ref = useRef(null);           // 訂正後数量2
  const teiseiSuu3Ref = useRef(null);           // 訂正後数量3
  const teiseiSuu4Ref = useRef(null);           // 訂正後数量4
  const teiseiSuu5Ref = useRef(null);           // 訂正後数量5
  const teiseiSuu6Ref = useRef(null);           // 訂正後数量6
  const teiseiSuu7Ref = useRef(null);           // 訂正後数量7
  const teiseiSuu8Ref = useRef(null);           // 訂正後数量8
  const teiseiSuu9Ref = useRef(null);           // 訂正後数量9
  const hikiai1Ref = useRef(null);              // 引合1
  const hikiai2Ref = useRef(null);              // 引合2
  const hikiai3Ref = useRef(null);              // 引合3
  const hikiai4Ref = useRef(null);              // 引合4
  const hikiai5Ref = useRef(null);              // 引合5
  const hikiai6Ref = useRef(null);              // 引合6
  const hikiai7Ref = useRef(null);              // 引合7
  const hikiai8Ref = useRef(null);              // 引合8
  const hikiai9Ref = useRef(null);              // 引合9
  const genTanka1Ref = useRef(null);            // 原価単価1
  const genTanka2Ref = useRef(null);            // 原価単価2
  const genTanka3Ref = useRef(null);            // 原価単価3
  const genTanka4Ref = useRef(null);            // 原価単価4
  const genTanka5Ref = useRef(null);            // 原価単価5
  const genTanka6Ref = useRef(null);            // 原価単価6
  const genTanka7Ref = useRef(null);            // 原価単価7
  const genTanka8Ref = useRef(null);            // 原価単価8
  const genTanka9Ref = useRef(null);            // 原価単価9
  const genkaKingaku1Ref = useRef(null);        // 原価金額1
  const genkaKingaku2Ref = useRef(null);        // 原価金額2
  const genkaKingaku3Ref = useRef(null);        // 原価金額3
  const genkaKingaku4Ref = useRef(null);        // 原価金額4
  const genkaKingaku5Ref = useRef(null);        // 原価金額5
  const genkaKingaku6Ref = useRef(null);        // 原価金額6
  const genkaKingaku7Ref = useRef(null);        // 原価金額7
  const genkaKingaku8Ref = useRef(null);        // 原価金額8
  const genkaKingaku9Ref = useRef(null);        // 原価金額9
  const baiTanka1Ref = useRef(null);            // 売単価1
  const baiTanka2Ref = useRef(null);            // 売単価2
  const baiTanka3Ref = useRef(null);            // 売単価3
  const baiTanka4Ref = useRef(null);            // 売単価4
  const baiTanka5Ref = useRef(null);            // 売単価5
  const baiTanka6Ref = useRef(null);            // 売単価6
  const baiTanka7Ref = useRef(null);            // 売単価7
  const baiTanka8Ref = useRef(null);            // 売単価8
  const baiTanka9Ref = useRef(null);            // 売単価9
  const baikaKingaku1Ref = useRef(null);        // 売価金額1
  const baikaKingaku2Ref = useRef(null);        // 売価金額2
  const baikaKingaku3Ref = useRef(null);        // 売価金額3
  const baikaKingaku4Ref = useRef(null);        // 売価金額4
  const baikaKingaku5Ref = useRef(null);        // 売価金額5
  const baikaKingaku6Ref = useRef(null);        // 売価金額6
  const baikaKingaku7Ref = useRef(null);        // 売価金額7
  const baikaKingaku8Ref = useRef(null);        // 売価金額8
  const baikaKingaku9Ref = useRef(null);        // 売価金額9
  const suuryoGoukeiRef = useRef(null);         // 数量合計
  const teiseiSuuGoukeiRef = useRef(null);      // 訂正後数量合計
  const genkaKingakuGoukeiRef = useRef(null);   // 原価金額合計
  const baikaKingakuGoukeiRef = useRef(null);   // 売価金額合計
  const teiseiGenkaKingakuGoukeiRef = useRef(null);   // 訂正後原価金額合計
  const teiseiBaikaKingakuGoukeiRef = useRef(null);   // 訂正後売価金額合計

  const inKBN = useRef(null);
  const inDENNO = useRef(null);
  const inSIRCDE = useRef(null);
  const inKIJYYM = useRef(null);
  const inMISE = useRef(null);
  const inBMNCDE = useRef(null);
  const inDENKBN = useRef(null);
  const inHTTYMD = useRef(null);
  const inNYMD = useRef(null);
  const inNHNYMD = useRef(null);
  const inBIN = useRef(null);
  const inRET = useRef(null);
  const inTSESUU1 = useRef(null);
  const inHKIKBN1 = useRef(null);
  const inTSESUU2 = useRef(null);
  const inHKIKBN2 = useRef(null);
  const inTSESUU3 = useRef(null);
  const inHKIKBN3 = useRef(null);
  const inTSESUU4 = useRef(null);
  const inHKIKBN4 = useRef(null);
  const inTSESUU5 = useRef(null);
  const inHKIKBN5 = useRef(null);
  const inTSESUU6 = useRef(null);
  const inHKIKBN6 = useRef(null);
  const inTSESUU7 = useRef(null);
  const inHKIKBN7 = useRef(null);
  const inTSESUU8 = useRef(null);
  const inHKIKBN8 = useRef(null);
  const inTSESUU9 = useRef(null);
  const inHKIKBN9 = useRef(null);
  const inTSEGNKFOT = useRef(null);
  const inTSEBAKFOT = useRef(null);
  const [ttl ,setTtl] = useState('');
  // 空白行確認用フラグ
  const [blurTableflg,setBlurTableflg] = useState(false);       // blurTableflg 2023/11/06 add
  // const [blurkey,setBlurkey] = useState(false);                 // blurTableflg時にPageUp処理追加 2023/11/06 add

  const [reCalflg,setReCalflg]= useState(false);
  let g_sircdef = 0;
  //#endregion

  //#region 初期処理
  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
      navigate('/TCSA0004');
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      navigate('/TCSA0003');
    }
    if (event.keyCode === 113) { // F2キーが押された場合の処理
      dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
      KSACDES : state.KSACDE,MISES: state.MISES,K_KSACDES : state.K_KSACDES,K_MISES: state.K_MISES,
      MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
      SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,MVEMGAMEN:'TCSA0100',K_FLG:state.K_FLG,NHNYMD:state.NHNYMD,BIN:state.BIN,ETC:state.ETC,SDAY:state.SDAY,BUTTON:state.BUTTON });
      navigate('/TCSA0210');
    }
    if (event.keyCode === 120) { // F9キーが押された場合の処理
      g_sircdef = 1;
      Err_flg = 1;
      Gmclr();
      if(kubunRef.current?.getValue()=='')
      {
        if (inKBN.current !==null && typeof inKBN.current !== 'undefined')
        {
          inKBN.current.focus();
        }
      }
      else
      {
        if (inDENNO.current !==null && typeof inDENNO.current !== 'undefined')
        {
          inDENNO.current.focus();
        }
      }
    }
  };
  useEventListener('keydown', handleKeyDown);
  //ファンクションキー対応

  useEffect (()=>{
    console.log('state:%o',state);
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();

    zituNouhinYMDRef.current?.setValue((state.NHNYMD).slice(-8));   //実納品日
    const ret_keijo = KeijoYM(state.NHNYMD);
    const ret_keijo2 = (String(ret_keijo).replace('年','')).replace('月','');
    console.log('計上日算出:'+ret_keijo+'|'+ret_keijo2);
    keijyoYMRef.current?.setValue(ret_keijo);

    if (state.ETC === 'K')
    {
      setCent_ksacde(state.K_KSACDES);
      setCent_mise(state.K_MISES);
      setItdensbt('02');
      if (state.KSACDES ==='005')
      {
        setTtl('(PH 各店)');
      }else
      {
        setTtl('(各店)');
      }
    }else{
      setCent_ksacde(state.KSACDES);
      setCent_mise(state.MISES);
      setItdensbt('01')
      if (state.KSACDES ==='005')
      {
        setTtl('(PH)');
      }else
      {
        setTtl('');
      }
    }

    binRef.current?.setValue(state.BIN);
    setTimeout(() => {document.documentElement.webkitRequestFullScreen();},1)
  },[]);
  //#endregion

  //#region 呼出処理
  /**
   * 計上年月算出
   * @param p_YMD 実納品日
   * @returns 計上年月
   */
  const KeijoYM = (p_YMD) =>{
    const c_YMD = String(p_YMD).replaceAll('/','');
    const n_now = new Date(state.SDAY);
    const n_day = String(n_now.getDate()).padStart(2,'0');  //システム日付の日を取得
    const n_dt = String(n_now.getFullYear())+String(n_now.getMonth()+1).padStart(2,'0')+'21';    //当月の20日以前チェック用
    const np_now = new Date(n_now.getFullYear(),n_now.getMonth(),'01'); //月＋１計算用（一旦日を01にしないとおかしいくなる）

    console.log('n_now:%o',n_now);
    console.log('n_day:%o',n_day);
    console.log('n_dt:%o',n_dt);
    console.log('np_now:%o',np_now);

    np_now.setMonth(np_now.getMonth()+1); //マイナス１月対策

    console.log('対策後n_now:%o',n_now);
    console.log('対策後np_now:%o',np_now);
    console.log('n_now.getMonth:%o',n_now.getMonth())
    console.log('np_now:%o',np_now.getMonth())

    if (n_day <= '20')
    {
      //1パターン
      console.log('パターン１');
      return String(n_now.getFullYear()).slice(2,4)+'年'+String(n_now.getMonth()+1).padStart(2,'0')+'月';
    }else if(n_day === '21' || n_day === '22' )
    {
      if (c_YMD < n_dt){
        //2パターン
        console.log('パターン２'+c_YMD+'<'+n_dt);
        return String(n_now.getFullYear()).slice(2,4)+'年'+String(n_now.getMonth()+1).padStart(2,'0')+'月';
      }else{
        //5パターン
        let ret = String(np_now.getFullYear()).slice(2,4)+'年'+String(np_now.getMonth()+1).padStart(2,'0')+'月';
        console.log('パターン５'+ret);
        return ret;
      }
    }else{
      //3,6パターン
      let ret = String(np_now.getFullYear()).slice(2,4)+'年'+String(np_now.getMonth()+1).padStart(2,'0')+'月';
      console.log('パターン３，６'+ret);
      return ret;
    }
  }

  /**
   * 日付存在チェック
   * @param strYYYYMMDD 年月日（YYYY/MM/DD ⇒ 2023/09/07 等）
   * @returns true:存在,false:エラー
   */
  const ckDate = (strYYYYMMDD) =>{  //
    if(!strYYYYMMDD.match(/^\d{4}\/\d{2}\/\d{2}$/)){
        return false;
    }
    var y = strYYYYMMDD.split('/')[0];
    var m = strYYYYMMDD.split('/')[1] - 1;
    var d = strYYYYMMDD.split('/')[2];
    var date = new Date(y,m,d);
    if(date.getFullYear() != y || date.getMonth() != m || date.getDate() != d){
        return false;
    }
    return true;
  }

  /**
   * 仕入先コードチェックデジット
   * @param p_SIRCDE 仕入先コード
   * @returns 0:正常,1:異常
   */
  const checkDJSIRCD = (p_SIRCDE) =>{
    const chk_sircde = ('00000'+p_SIRCDE).slice(-6);
    let w1 = chk_sircde.charAt(0) * 8;
    let w2 = chk_sircde.charAt(1) * 7;
    let w3 = chk_sircde.charAt(2) * 4;
    let w4 = chk_sircde.charAt(3) * 3;
    let w5 = chk_sircde.charAt(4) * 2;
    let wD = chk_sircde.charAt(5).toString();
    let wGk = (w1+w2+w3+w4+w5)%10;
    let iRet = 0;
    wGk = 10-wGk;
    let wAmri = wGk.toString().slice(-1);
    //console.log('余り:'+wAmri+' != '+wD);
    if (wAmri != wD)
    {
      iRet = -1;
    }
    return iRet;
  }

  /**
   * 値設定
   * @param value 設定値
   * @param rowNo 設定行
   * @param checkName 設定項目
   */
  function setItitemval(value,rowNo,checkName) {
    switch (rowNo) {
      case 1:
        switch(checkName){
          case 'shncde':
            shohinCode1Ref.current?.setValue(value);    // 商品コード1
            break;
          case 'sizcas':
            sizeCase1Ref.current?.setValue(value);    // ｻｲｽﾞｹｰｽ1
              break;
          case 'suu':
            suuryo1Ref.current?.setValue(value);       // 数量1
            break;
          case 'tsesuu':
            teiseiSuu1Ref.current?.setValue(value);    // 訂正後数量1
            break;
          case 'hkikbn':
            hikiai1Ref.current?.setValue(value);    // 引合1
            break;
          case 'gnt':
            genTanka1Ref.current?.setValue(value);       // 原単価1
            break;
          case 'gnkkin':
            genkaKingaku1Ref.current?.setValue(value);    // 原価金額1
            break;
          case 'btn':
            baiTanka1Ref.current?.setValue(value);       // 売単価1
            break;
          case 'bakkin':
            baikaKingaku1Ref.current?.setValue(value);    // 売価金額1
            break;
        }
        break;
      case 2:
        switch(checkName){
          case 'shncde':
            shohinCode2Ref.current?.setValue(value);    // 商品コード2
            break;
          case 'sizcas':
            sizeCase2Ref.current?.setValue(value);    // ｻｲｽﾞｹｰｽ2
              break;
          case 'suu':
            suuryo2Ref.current?.setValue(value);       // 数量2
            break;
          case 'tsesuu':
            teiseiSuu2Ref.current?.setValue(value);    // 訂正後数量2
            break;
          case 'hkikbn':
            hikiai2Ref.current?.setValue(value);    // 引合2
            break;
          case 'gnt':
            genTanka2Ref.current?.setValue(value);       // 原単価2
            break;
          case 'gnkkin':
            genkaKingaku2Ref.current?.setValue(value);    // 原価金額2
            break;
          case 'btn':
            baiTanka2Ref.current?.setValue(value);       // 売単価2
            break;
          case 'bakkin':
            baikaKingaku2Ref.current?.setValue(value);    // 売価金額2
            break;
        }
        break;
      case 3:
        switch(checkName){
          case 'shncde':
            shohinCode3Ref.current?.setValue(value);    // 商品コード3
            break;
          case 'sizcas':
            sizeCase3Ref.current?.setValue(value);    // ｻｲｽﾞｹｰｽ3
              break;
          case 'suu':
            suuryo3Ref.current?.setValue(value);       // 数量3
            break;
          case 'tsesuu':
            teiseiSuu3Ref.current?.setValue(value);    // 訂正後数量3
            break;
          case 'hkikbn':
            hikiai3Ref.current?.setValue(value);    // 引合3
            break;
          case 'gnt':
            genTanka3Ref.current?.setValue(value);       // 原単価3
            break;
          case 'gnkkin':
            genkaKingaku3Ref.current?.setValue(value);    // 原価金額3
            break;
          case 'btn':
            baiTanka3Ref.current?.setValue(value);       // 売単価3
            break;
          case 'bakkin':
            baikaKingaku3Ref.current?.setValue(value);    // 売価金額3
            break;
        }
        break;
      case 4:
        switch(checkName){
          case 'shncde':
            shohinCode4Ref.current?.setValue(value);    // 商品コード4
            break;
          case 'sizcas':
            sizeCase4Ref.current?.setValue(value);    // ｻｲｽﾞｹｰｽ4
              break;
          case 'suu':
            suuryo4Ref.current?.setValue(value);       // 数量4
            break;
          case 'tsesuu':
            teiseiSuu4Ref.current?.setValue(value);    // 訂正後数量4
            break;
          case 'hkikbn':
            hikiai4Ref.current?.setValue(value);    // 引合4
            break;
          case 'gnt':
            genTanka4Ref.current?.setValue(value);       // 原単価4
            break;
          case 'gnkkin':
            genkaKingaku4Ref.current?.setValue(value);    // 原価金額4
            break;
          case 'btn':
            baiTanka4Ref.current?.setValue(value);       // 売単価4
            break;
          case 'bakkin':
            baikaKingaku4Ref.current?.setValue(value);    // 売価金額4
            break;
        }
        break;
      case 5:
        switch(checkName){
          case 'shncde':
            shohinCode5Ref.current?.setValue(value);    // 商品コード5
            break;
          case 'sizcas':
            sizeCase5Ref.current?.setValue(value);    // ｻｲｽﾞｹｰｽ5
              break;
          case 'suu':
            suuryo5Ref.current?.setValue(value);       // 数量5
            break;
          case 'tsesuu':
            teiseiSuu5Ref.current?.setValue(value);    // 訂正後数量5
            break;
          case 'hkikbn':
            hikiai5Ref.current?.setValue(value);    // 引合5
            break;
          case 'gnt':
            genTanka5Ref.current?.setValue(value);       // 原単価5
            break;
          case 'gnkkin':
            genkaKingaku5Ref.current?.setValue(value);    // 原価金額5
            break;
          case 'btn':
            baiTanka5Ref.current?.setValue(value);       // 売単価5
            break;
          case 'bakkin':
            baikaKingaku5Ref.current?.setValue(value);    // 売価金額5
            break;
        }
        break;
      case 6:
        switch(checkName){
          case 'shncde':
            shohinCode6Ref.current?.setValue(value);    // 商品コード6
            break;
          case 'sizcas':
            sizeCase6Ref.current?.setValue(value);    // ｻｲｽﾞｹｰｽ6
              break;
          case 'suu':
            suuryo6Ref.current?.setValue(value);       // 数量6
            break;
          case 'tsesuu':
            teiseiSuu6Ref.current?.setValue(value);    // 訂正後数量6
            break;
          case 'hkikbn':
            hikiai6Ref.current?.setValue(value);    // 引合6
            break;
          case 'gnt':
            genTanka6Ref.current?.setValue(value);       // 原単価6
            break;
          case 'gnkkin':
            genkaKingaku6Ref.current?.setValue(value);    // 原価金額6
            break;
          case 'btn':
            baiTanka6Ref.current?.setValue(value);       // 売単価6
            break;
          case 'bakkin':
            baikaKingaku6Ref.current?.setValue(value);    // 売価金額6
            break;
        }
        break;
      case 7:
        switch(checkName){
          case 'shncde':
            shohinCode7Ref.current?.setValue(value);    // 商品コード7
            break;
          case 'sizcas':
            sizeCase7Ref.current?.setValue(value);    // ｻｲｽﾞｹｰｽ7
              break;
          case 'suu':
            suuryo7Ref.current?.setValue(value);       // 数量7
            break;
          case 'tsesuu':
            teiseiSuu7Ref.current?.setValue(value);    // 訂正後数量7
            break;
          case 'hkikbn':
            hikiai7Ref.current?.setValue(value);    // 引合7
            break;
          case 'gnt':
            genTanka7Ref.current?.setValue(value);       // 原単価7
            break;
          case 'gnkkin':
            genkaKingaku7Ref.current?.setValue(value);    // 原価金額7
            break;
          case 'btn':
            baiTanka7Ref.current?.setValue(value);       // 売単価7
            break;
          case 'bakkin':
            baikaKingaku7Ref.current?.setValue(value);    // 売価金額7
            break;
        }
        break;
      case 8:
        switch(checkName){
          case 'shncde':
            shohinCode8Ref.current?.setValue(value);    // 商品コード8
            break;
          case 'sizcas':
            sizeCase8Ref.current?.setValue(value);    // ｻｲｽﾞｹｰｽ8
              break;
          case 'suu':
            suuryo8Ref.current?.setValue(value);       // 数量8
            break;
          case 'tsesuu':
            teiseiSuu8Ref.current?.setValue(value);    // 訂正後数量8
            break;
          case 'hkikbn':
            hikiai8Ref.current?.setValue(value);    // 引合8
            break;
          case 'gnt':
            genTanka8Ref.current?.setValue(value);       // 原単価8
            break;
          case 'gnkkin':
            genkaKingaku8Ref.current?.setValue(value);    // 原価金額8
            break;
          case 'btn':
            baiTanka8Ref.current?.setValue(value);       // 売単価8
            break;
          case 'bakkin':
            baikaKingaku8Ref.current?.setValue(value);    // 売価金額8
            break;
        }
        break;
      case 9:
        switch(checkName){
          case 'shncde':
            shohinCode9Ref.current?.setValue(value);    // 商品コード9
            break;
          case 'sizcas':
            sizeCase9Ref.current?.setValue(value);    // ｻｲｽﾞｹｰｽ9
              break;
          case 'suu':
            suuryo9Ref.current?.setValue(value);       // 数量9
            break;
          case 'tsesuu':
            teiseiSuu9Ref.current?.setValue(value);    // 訂正後数量9
            break;
          case 'hkikbn':
            hikiai9Ref.current?.setValue(value);    // 引合9
            break;
          case 'gnt':
            genTanka9Ref.current?.setValue(value);       // 原単価9
            break;
          case 'gnkkin':
            genkaKingaku9Ref.current?.setValue(value);    // 原価金額9
            break;
          case 'btn':
            baiTanka9Ref.current?.setValue(value);       // 売単価9
            break;
          case 'bakkin':
            baikaKingaku9Ref.current?.setValue(value);    // 売価金額9
            break;
        }
        break;
    }
  }

  /**
   * 色設定
   * @param color 設定色
   * @param rowNo 設定行
   * @param checkName 設定項目
   */
  function setItitemclr(color,rowNo,checkName) {
    switch (rowNo) {
      case 1:
        switch(checkName){
          case 'tsesuu':
            teiseiSuu1Ref.current?.setBGColor(color);
            break;
          case 'hkikbn':
            hikiai1Ref.current?.setBGColor(color);
            break;

        }
        break;
      case 2:
        switch(checkName){
          case 'tsesuu':
            teiseiSuu2Ref.current?.setBGColor(color);
            break;
          case 'hkikbn':
            hikiai2Ref.current?.setBGColor(color);
            break;

        }
        break;
      case 3:
        switch(checkName){
          case 'tsesuu':
            teiseiSuu3Ref.current?.setBGColor(color);
            break;
          case 'hkikbn':
            hikiai3Ref.current?.setBGColor(color);
            break;

        }
        break;
      case 4:
        switch(checkName){
          case 'tsesuu':
            teiseiSuu4Ref.current?.setBGColor(color);
            break;
          case 'hkikbn':
            hikiai4Ref.current?.setBGColor(color);
            break;

        }
        break;
      case 5:
        switch(checkName){
          case 'tsesuu':
            teiseiSuu5Ref.current?.setBGColor(color);
            break;
          case 'hkikbn':
            hikiai5Ref.current?.setBGColor(color);
            break;

        }
        break;
      case 6:
        switch(checkName){
          case 'tsesuu':
            teiseiSuu6Ref.current?.setBGColor(color);
            break;
          case 'hkikbn':
            hikiai6Ref.current?.setBGColor(color);
            break;

        }
        break;
      case 7:
        switch(checkName){
          case 'tsesuu':
            teiseiSuu7Ref.current?.setBGColor(color);
            break;
          case 'hkikbn':
            hikiai7Ref.current?.setBGColor(color);
            break;

        }
        break;
      case 8:
        switch(checkName){
          case 'tsesuu':
            teiseiSuu8Ref.current?.setBGColor(color);
            break;
          case 'hkikbn':
            hikiai8Ref.current?.setBGColor(color);
            break;

        }
        break;
      case 9:
        switch(checkName){
          case 'tsesuu':
            teiseiSuu9Ref.current?.setBGColor(color);
            break;
          case 'hkikbn':
            hikiai9Ref.current?.setBGColor(color);
            break;
        }
        break;
    }
  }

  /**
   * 画面クリア
   */
  const Gmclr = async() =>{
    if (kubunRef.current?.getValue() === '') //2023/11/08 upd
    {
      kubunRef.current?.getValue('2');              //初期値2対応
    }
    setAlert(' ');
    denpyoNoRef.current?.setValue('');
    sireCodeRef.current?.setValue('');
    sireCodeRef.current?.setName('');
    miseCodeRef.current?.setValue('');
    miseCodeRef.current?.setName('');
    bunruiCodeRef.current?.setValue('');
    denpyoKubunRef.current?.setValue('');
    hattyuYMDRef.current?.setValue('');
    setHttymd(true);    //2023/11/02 upd
    nouhinYMDRef.current?.setValue('');
    teiseiSuu1Ref.current?.setValue('');
    hikiai1Ref.current?.setValue('');
    teiseiSuu2Ref.current?.setValue('');
    hikiai2Ref.current?.setValue('');
    teiseiSuu3Ref.current?.setValue('');
    hikiai3Ref.current?.setValue('');
    teiseiSuu4Ref.current?.setValue('');
    hikiai4Ref.current?.setValue('');
    teiseiSuu5Ref.current?.setValue('');
    hikiai5Ref.current?.setValue('');
    teiseiSuu6Ref.current?.setValue('');
    hikiai6Ref.current?.setValue('');
    teiseiSuu7Ref.current?.setValue('');
    hikiai7Ref.current?.setValue('');
    teiseiSuu8Ref.current?.setValue('');
    hikiai8Ref.current?.setValue('');
    teiseiSuu9Ref.current?.setValue('');
    hikiai9Ref.current?.setValue('');
    teiseiGenkaKingakuGoukeiRef.current?.setValue('');
    teiseiBaikaKingakuGoukeiRef.current?.setValue('');
    shohinCode1Ref.current?.setValue('');
    sizeCase1Ref.current?.setValue('');
    suuryo1Ref.current?.setValue('');
    genTanka1Ref.current?.setValue('');
    genkaKingaku1Ref.current?.setValue('');
    baiTanka1Ref.current?.setValue('');
    baikaKingaku1Ref.current?.setValue('');
    shohinCode2Ref.current?.setValue('');
    sizeCase2Ref.current?.setValue('');
    suuryo2Ref.current?.setValue('');
    genTanka2Ref.current?.setValue('');
    genkaKingaku2Ref.current?.setValue('');
    baiTanka2Ref.current?.setValue('');
    baikaKingaku2Ref.current?.setValue('');
    shohinCode3Ref.current?.setValue('');
    sizeCase3Ref.current?.setValue('');
    suuryo3Ref.current?.setValue('');
    genTanka3Ref.current?.setValue('');
    genkaKingaku3Ref.current?.setValue('');
    baiTanka3Ref.current?.setValue('');
    baikaKingaku3Ref.current?.setValue('');
    shohinCode4Ref.current?.setValue('');
    sizeCase4Ref.current?.setValue('');
    suuryo4Ref.current?.setValue('');
    genTanka4Ref.current?.setValue('');
    genkaKingaku4Ref.current?.setValue('');
    baiTanka4Ref.current?.setValue('');
    baikaKingaku4Ref.current?.setValue('');
    shohinCode5Ref.current?.setValue('');
    sizeCase5Ref.current?.setValue('');
    suuryo5Ref.current?.setValue('');
    genTanka5Ref.current?.setValue('');
    genkaKingaku5Ref.current?.setValue('');
    baiTanka5Ref.current?.setValue('');
    baikaKingaku5Ref.current?.setValue('');
    shohinCode6Ref.current?.setValue('');
    sizeCase6Ref.current?.setValue('');
    suuryo6Ref.current?.setValue('');
    genTanka6Ref.current?.setValue('');
    genkaKingaku6Ref.current?.setValue('');
    baiTanka6Ref.current?.setValue('');
    baikaKingaku6Ref.current?.setValue('');
    shohinCode7Ref.current?.setValue('');
    sizeCase7Ref.current?.setValue('');
    suuryo7Ref.current?.setValue('');
    genTanka7Ref.current?.setValue('');
    genkaKingaku7Ref.current?.setValue('');
    baiTanka7Ref.current?.setValue('');
    baikaKingaku7Ref.current?.setValue('');
    shohinCode8Ref.current?.setValue('');
    sizeCase8Ref.current?.setValue('');
    suuryo8Ref.current?.setValue('');
    genTanka8Ref.current?.setValue('');
    genkaKingaku8Ref.current?.setValue('');
    baiTanka8Ref.current?.setValue('');
    baikaKingaku8Ref.current?.setValue('');
    shohinCode9Ref.current?.setValue('');
    sizeCase9Ref.current?.setValue('');
    suuryo9Ref.current?.setValue('');
    genTanka9Ref.current?.setValue('');
    genkaKingaku9Ref.current?.setValue('');
    baiTanka9Ref.current?.setValue('');
    baikaKingaku9Ref.current?.setValue('');
    suuryoGoukeiRef.current?.setValue('');
    teiseiSuuGoukeiRef.current?.setValue('');
    genkaKingakuGoukeiRef.current?.setValue('');
    baikaKingakuGoukeiRef.current?.setValue('');
    kubunRef.current?.setBGColor('#FFFFFF');
    denpyoNoRef.current?.setBGColor('#FFFFFF');
    sireCodeRef.current?.setBGColor('#FFFFFF');
    hattyuYMDRef.current?.setBGColor('#FFFFFF');
    teiseiSuu1Ref.current?.setBGColor('#FFFFFF');
    hikiai1Ref.current?.setBGColor('#FFFFFF');
    teiseiSuu2Ref.current?.setBGColor('#FFFFFF');
    hikiai2Ref.current?.setBGColor('#FFFFFF');
    teiseiSuu3Ref.current?.setBGColor('#FFFFFF');
    hikiai3Ref.current?.setBGColor('#FFFFFF');
    teiseiSuu4Ref.current?.setBGColor('#FFFFFF');
    hikiai4Ref.current?.setBGColor('#FFFFFF');
    teiseiSuu5Ref.current?.setBGColor('#FFFFFF');
    hikiai5Ref.current?.setBGColor('#FFFFFF');
    teiseiSuu6Ref.current?.setBGColor('#FFFFFF');
    hikiai6Ref.current?.setBGColor('#FFFFFF');
    teiseiSuu7Ref.current?.setBGColor('#FFFFFF');
    hikiai7Ref.current?.setBGColor('#FFFFFF');
    teiseiSuu8Ref.current?.setBGColor('#FFFFFF');
    hikiai8Ref.current?.setBGColor('#FFFFFF');
    teiseiSuu9Ref.current?.setBGColor('#FFFFFF');
    hikiai9Ref.current?.setBGColor('#FFFFFF');
    teiseiGenkaKingakuGoukeiRef.current?.setBGColor('#FFFFFF');
    teiseiBaikaKingakuGoukeiRef.current?.setBGColor('#FFFFFF');
  }

  // 明細合計計算呼び出し
  useEffect(()=>{
    if(reCalflg){
      reCal();
    }
    setReCalflg(false);
  },[reCalflg]);

  /**
   * 明細合計計算
   */
  const reCal = async()=>{
    let tsesuugk = parseFloat(teiseiSuu1Ref.current?.getValue().replaceAll(/,/g, ''))||0;
    tsesuugk += parseFloat(teiseiSuu2Ref.current?.getValue().replaceAll(/,/g, ''))||0;
    tsesuugk += parseFloat(teiseiSuu3Ref.current?.getValue().replaceAll(/,/g, ''))||0;
    tsesuugk += parseFloat(teiseiSuu4Ref.current?.getValue().replaceAll(/,/g, ''))||0;
    tsesuugk += parseFloat(teiseiSuu5Ref.current?.getValue().replaceAll(/,/g, ''))||0;
    tsesuugk += parseFloat(teiseiSuu6Ref.current?.getValue().replaceAll(/,/g, ''))||0;
    tsesuugk += parseFloat(teiseiSuu7Ref.current?.getValue().replaceAll(/,/g, ''))||0;
    tsesuugk += parseFloat(teiseiSuu8Ref.current?.getValue().replaceAll(/,/g, ''))||0;
    tsesuugk += parseFloat(teiseiSuu9Ref.current?.getValue().replaceAll(/,/g, ''))||0;
    await teiseiSuuGoukeiRef.current?.setValue(tsesuugk.toLocaleString(undefined, {minimumFractionDigits: 1}));
    let gnkkingk = Math.round(parseFloat(genkaKingaku1Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    gnkkingk += Math.round(parseFloat(genkaKingaku2Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    gnkkingk += Math.round(parseFloat(genkaKingaku3Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    gnkkingk += Math.round(parseFloat(genkaKingaku4Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    gnkkingk += Math.round(parseFloat(genkaKingaku5Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    gnkkingk += Math.round(parseFloat(genkaKingaku6Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    gnkkingk += Math.round(parseFloat(genkaKingaku7Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    gnkkingk += Math.round(parseFloat(genkaKingaku8Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    gnkkingk += Math.round(parseFloat(genkaKingaku9Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    await genkaKingakuGoukeiRef.current?.setValue(gnkkingk.toLocaleString());
    let bakkingk = Math.round(parseFloat(baikaKingaku1Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    bakkingk += Math.round(parseFloat(baikaKingaku2Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    bakkingk += Math.round(parseFloat(baikaKingaku3Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    bakkingk += Math.round(parseFloat(baikaKingaku4Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    bakkingk += Math.round(parseFloat(baikaKingaku5Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    bakkingk += Math.round(parseFloat(baikaKingaku6Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    bakkingk += Math.round(parseFloat(baikaKingaku7Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    bakkingk += Math.round(parseFloat(baikaKingaku8Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    bakkingk += Math.round(parseFloat(baikaKingaku9Ref.current?.getValue().replaceAll(/,/g, ''))||0);
    await baikaKingakuGoukeiRef.current?.setValue(bakkingk.toLocaleString());
  }

  /**
   * 要素所属タイプ
   * @param id 項目id
   * @returns 1:ヘッダ要素,2:明細要素,3:フッタ要素,9:確認
   */
  const getInputType = (id) => {  //2023/11/02  add
    let ret = 0;
    switch (id) {
      case 'KBN':         // 区分設定
      case 'DENNO':       // 伝票No.
      case 'SIRCDE':      // 取引先CD
      case 'KIJYYM':      // 計上年月
      case 'MISE':        // 店CD
      case 'BMNCDE':      // 分類CD
      case 'DENKBN':      // 伝票区分
      case 'HTTYMD':      // 発注日
      case 'NHNYMD':      // 実納品日
      case 'BIN':         // 便
        ret = 1;
        break;
      case 'TSESUU1':
      case 'HKIKBN1':
      case 'TSESUU2':
      case 'HKIKBN2':
      case 'TSESUU3':
      case 'HKIKBN3':
      case 'TSESUU4':
      case 'HKIKBN4':
      case 'TSESUU5':
      case 'HKIKBN5':
      case 'TSESUU6':
      case 'HKIKBN6':
      case 'TSESUU7':
      case 'HKIKBN7':
      case 'TSESUU8':
      case 'HKIKBN8':
      case 'TSESUU9':
      case 'HKIKBN9':
        ret = 2;
        break;
      case 'TSEGNKFOT':      // 訂正後原価金額合計
      case 'TSEBAKFOT':      // 訂正後売価金額合計
        ret = 3;
        break;
      case 'ret':       // 登録処理
        ret = 9;
        break;
      default:
        break;
    }
    return ret;
  }
  //#endregion

  //#region onKeyDown
  /**
   * ボタン押下時の処理(明細以外)
   * @param key 入力キー
   * @param inputRef 現在の項目情報
   * @param inputRtRef 前項目情報
   * @param inputNxRef 次項目情報
   */
  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {
    //console.log(key);
    let mvFlg = 0;    //エラー時の移動制御用
    //console.log('onKeyDown:'+key);
    // setBlurkey(key);
    switch (key) {
      case 'Enter':
        switch (inputRef.current.id) {
          case 'KBN':         // 区分設定
            mvFlg = onKeyDown_KBN();
            g_sircdef = 0;
            break;
          case 'DENNO':       // 伝票No.
            denpyoNoRef.current?.setValue(denpyoNoRef.current?.getValue().padStart(7,'0'));
            g_sircdef = 0;
            break;
          case 'SIRCDE':       // 取引先CD
            mvFlg = 1;
            onKeyDown_SIRCDE();
            g_sircdef = 0;
            break;
          case 'HTTYMD':       // 発注日
            onKeyDown_HTTYMD();
            g_sircdef = 0;
            break;
          case 'TSEGNKFOT':     // 訂正後原価金額合計
            mvFlg = onKeyDown_TSEGNKFOT();  //2023/11/09 upd
            break;
          case 'TSEBAKFOT':     // 訂正後売価金額合計
            mvFlg = onKeyDown_TSEBAKFOT();  //2023/11/09 upd
            break;
          case 'ret':           // 確認
            setAlert('　');
            if (keyDisabled.current)
            {
              return;
            }
            keyDisabled.current = true;
            //console.log('確認:',kubunRef.current?.getValue());

            if(kubunRef.current?.getValue() == '2') {
              mvFlg = await onKeyDown_Update();     // 更新処理 2023/11/09 upd
            } else if(kubunRef.current?.getValue() === '3') {
              mvFlg = await onKeyDown_Delete();     // 削除処理（論理削除） 2023/11/09 upd
            }
            if (mvFlg == 0) //2023/11/09 add
            {
              Err_flg = 1;
              Gmclr();
              //初期値2対応
              inDENNO.current.focus();
              // inKBN.current.focus(); 2023/11/02 upd
            }
            mvFlg = 1;
            setTimeout(() => {
              // 処理中フラグを下げる
              keyDisabled.current = false;
            }, 1500);
            break;
        }

        // 次の項目に遷移処理
        if (mvFlg == 0) {
          inputNxRef.current.focus();
          onNextFocus(inputNxRef);
        }

        break;
      case 'Home':
        break;
      case 'PageUp':
        Err_flg = 1;
        console.log('ErrFlg SET:'+Err_flg);
        inputRtRef.current.focus();   // 前の項目に遷移
        break;
      case 'PageDown':
        inputNxRef.current.focus();   // 次の項目に遷移
        break;
      default:
        break;
    }
  };

  /**
   * ボタン押下時の処理(明細部)
   * @param key 入力キー
   * @param rowNo 行No
   * @param inputRef 現在の項目
   * @param inputNxRef 次項目情報
   * @param inUpRef 上キー
   * @param inRgRef 右キー
   * @param inDnRef 下キー
   * @param inLfRef 左キー
   * @param inNxShin 左キー
   */
  const onKeyDown_Meisai =  async (key,rowNo,inputRef,inputNxRef,inUpRef,inRgRef,inDnRef,inLfRef,inNxShin) => { //2023/11/09 upd
    // setBlurkey(key);
    switch (key) {
      case 'Enter':
        switch (inputRef) {
          case 'TSESUU':         // 訂正後数量
            onKeyDown_TSESUU(rowNo);
            inputNxRef.current.focus();   // 次の項目に遷移
            onNextFocus(inputNxRef);
            break;
          case 'HKIKBN':         // 引合
            onKeyDown_HKIKBN(rowNo);
            // 次の行の訂正後数量が空白なら訂正後原価金額合計へ移動
            if(inNxShin == 'END'){
              inTSEGNKFOT.current.focus();  // 訂正後原価金額合計
            }else if (inNxShin.current?.getValue() == ''){
              inTSEGNKFOT.current.focus();  // 訂正後原価金額合計
            }else{
              inputNxRef.current.focus();   // 次の項目に遷移
            }
            break;
        }
        break;
      case 'Home':
        break;
      case 'PageUp':
        inSIRCDE.current.focus();   //前の項目に遷移
        break;
      case 'PageDown':
        if (kubunRef.current?.getValue() === '2') {
          inTSEGNKFOT.current.focus();
        }else{
          inRET.current.focus();
        }
      break;
      case 'ArrowUp':
        inUpRef.current.focus();        // 上の項目遷移（明細用）
        break;
      case 'ArrowRight':
        inRgRef.current.focus();        // 右の項目遷移（明細用）
        break;
      case 'ArrowDown':
        // 下の項目が空白なら訂正後原価金額合計へ移動 2023/11/09　upd
        // if(inDnRef.current.value == ''){
        //   inTSEGNKFOT.current.focus();  // 訂正後原価金額合計
        // }else{
          inDnRef.current.focus();      // 下の項目遷移（明細用）
        // }
        break;
      case 'ArrowLeft':
        inLfRef.current.focus();        // 左の項目遷移（明細用）
        break;
      default:
        break;
    }
  };

  /**
   * 確認項目にフォーカス時の処理
   */
  function onFocus_ret(){
    switch(kubunRef.current?.getValue()){
      case '2':
        setAlert('確認してください！！');//TCGM0005：
        break;
      case '3':
        setAlert('削除を行いますか？');//TCGM0006：
        break;
      default:
        setAlert('　');
        break;
    }
  }
  //#endregion

  //#region ヘッダ部入力
  /**
   * 区分設定
   * @returns  0:正常, 1:エラー
   */
  function onKeyDown_KBN() {
    let mvFlg = 0;
    kubunRef.current?.setBGColor('#FFFFFF');
    setAlert('　');

    switch(kubunRef.current?.getValue()){
      case '2':
        setItkbnnm('修正');
        break;
      case '3':
        setItkbnnm('削除');
        break;
      default:
        setAlert('区分に2:修正,3:削除以外は入力できません。');//TCGE0027:
        kubunRef.current?.setBGColor('#f59d9d');
        onNextFocus(inKBN);
        mvFlg = 1;
        break;
    }
    return mvFlg;
  }

  /**
   * ヘッダ部入力 取引先CD
   * @returns  0:正常, 1:エラー
   */
  const onKeyDown_SIRCDE = async()=>{
    let mvFlg = 0;
    console.log('仕入先Lost:'+sireCodeRef.current?.getValue()+'|'+g_sircdef+'|'+denpyoNoRef.current?.getValue());

    sireCodeRef.current?.setBGColor('#FFFFFF');
    setAlert('　');

    if (sireCodeRef.current?.getValue() == '' || denpyoNoRef.current?.getValue() =='') {
      console.log('取引先CD:'+sireCodeRef.current?.getValue());
      if (sireCodeRef.current?.getValue() === '') {
        mvFlg = 1;
        console.log('取引先CD2:'+sireCodeRef.current?.getValue());
        setAlert('取引先コード入力エラー');//TCGE0015：
        sireCodeRef.current?.setBGColor('#f59d9d');
        onNextFocus(inSIRCDE);
      }

      return mvFlg;
    }

    let lsircde = ('000000'+sireCodeRef.current?.getValue()).slice(-6)
    sireCodeRef.current?.setValue(lsircde);
    sireCodeRef.current?.setBGColor('#FFFFFF');
    setAlert('　');

    //仕入先コードのチェックデジットチェック
    let iRet = checkDJSIRCD(lsircde);
    if (iRet == -1)
    {
      setAlert('取引先コード入力エラー');//TCGE0015：
      sireCodeRef.current?.setBGColor('#f59d9d');
      onNextFocus(inSIRCDE);
      mvFlg = 1;
      return mvFlg;
    }

    // 伝票データ抽出
    let lhttymd = '';
    mvFlg = await getDenDat(lsircde,lhttymd);

    return mvFlg;
  }

  /**
   * ヘッダ部入力 発注日
   * @returns  0:正常, 1:エラー
   */
  const onKeyDown_HTTYMD = async()=>{
    let mvFlg = 0;
    var strDate = '20'+hattyuYMDRef.current?.getValue().slice(-8);
    let lsircde = ('000000'+sireCodeRef.current?.getValue()).slice(-6)
    var dtdate = new Date(strDate);
    hattyuYMDRef.current?.setBGColor('#FFFFFF');

    //日付チェック
    console.log ('発注日02:'+strDate);
    if (ckDate(strDate)===true){
      console.log('日付範囲2');
      let dt = new Date(state.SDAY);
      let dts = new Date(dt.getFullYear()-1,dt.getMonth(),dt.getDate());
      let dte = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate());
      console.log('日付'+dts+'<'+dtdate+'<='+dte);
      if (!(dts < dtdate && dtdate <= dte))
      {
        hattyuYMDRef.current?.setBGColor('#f59d9d');
        setAlert('日付入力エラー');//TCGE0016:
        //inRET.current.focus();
        //inputRef.current.focus();
        onNextFocus(inHTTYMD);
        mvFlg = 1;
        return mvFlg;
      }
    }else{
      hattyuYMDRef.current?.setBGColor('#f59d9d');
      setAlert('日付入力エラー');//TCGE0016:
      //inRET.current.focus();
      //inputRef.current.focus();
      onNextFocus(inHTTYMD);
      mvFlg = 1;
      return mvFlg;
    }

    // 伝票データ抽出
    let lhttymd = '';
    if (hattyuYMDRef.current?.getValue() !== '')
    {
      lhttymd = '20'+''+hattyuYMDRef.current?.getValue().replaceAll(/(\\|\/)/g,'');
    }
    mvFlg = await getDenDat(lsircde,lhttymd);

    return mvFlg;
  }

  // 発注日への遷移処理
  useEffect(() => {
    if(!dishttymd){
      inHTTYMD.current.focus();
      onNextFocus(inHTTYMD);
    }
  }, [dishttymd]);

  /**
   * 伝票データ抽出
   * @param lsircde 仕入先(取引先)コード
   * @param lhttymd 発注日
   * @returns Errflg 0:正常終了, 1:エラー
   */
  const getDenDat = async(lsircde,lhttymd)=>{
    let Errflg = 0;
    // 伝票ヘッダ抽出
    const responseA = await fetch('/SL_HED_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ent_ksacde: cent_ksacde,ent_mise: cent_mise,sircde:lsircde,densbt:itDENSBT,
      denno:denpyoNoRef.current?.getValue(),httymd:lhttymd,RCNT:0})});
    const dataA = await responseA.json();
    //console.log('伝票ヘッダ:%o',dataA);
    //console.log('件数:%o',dataA.RCNT);
    if (dataA.RCNT > 1) // 一件以上
    {
      setAlert('伝票データが複数件存在します。発注日も入力してください。');//TCGM0004：
      setHttymd(false);
      inHTTYMD.current.focus();
      onNextFocus(inHTTYMD);
      Errflg = 1;
      return Errflg;
    }
    else if(dataA.RCNT == 1)  // 通常
    {

      // 送信処理済みの伝票
      if (dataA.res[0].SND_KNR_KBN != 0) {
        setAlert('送信処理済みの伝票です。（修正・削除不可）');//TCGE0029：
        inDENNO.current.focus();
        onNextFocus(inDENNO);
        Errflg = 1;
        return Errflg;
      }

      setAlert(' ');
      setHttymd(true);

      sireCodeRef.current?.setName(dataA.res[0].SIRMEIK);   //仕入先名
      miseCodeRef.current?.setValue(dataA.res[0].MISE);       //店コード
      miseCodeRef.current?.setName(dataA.res[0].MISEMEI);  //店名
      bunruiCodeRef.current?.setValue(dataA.res[0].BMNCDE);   //部門CD（分類）
      denpyoKubunRef.current?.setValue(dataA.res[0].DENKBN);   //伝票区分
      //setItdatkbn(dataA.res[0].DAT_MKBN); //データメンテナンス区分
      let datmkbn = dataA.res[0].DAT_MKBN;//データメンテナンス区分  2023/11/03
      setItgnkfot(dataA.res[0].GNKFOT);   //原価金額合計
      setItbakfot(dataA.res[0].BAKFOT);   //売価金額合計
      if (dataA.res[0].DENYMD!='') {
        console.log('納品日:'+dataA.res[0].DENYMD.slice(2,4)+'/'+dataA.res[0].DENYMD.slice(4,6)+'/'+dataA.res[0].DENYMD.slice(-2));
        nouhinYMDRef.current?.setValue(dataA.res[0].DENYMD.slice(2,4)+'/'+dataA.res[0].DENYMD.slice(4,6)+'/'+dataA.res[0].DENYMD.slice(-2));      //納品日
      }
      if (dataA.res[0].HTTYMD!='') {
        hattyuYMDRef.current?.setValue(dataA.res[0].HTTYMD.slice(2,4)+'/'+dataA.res[0].HTTYMD.slice(4,6)+'/'+dataA.res[0].HTTYMD.slice(-2));   //発注日
      }

      // 伝票明細抽出
      const responseB = await fetch('/SL_DTL_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ent_ksacde: cent_ksacde,ent_mise: cent_mise,densbt:itDENSBT,denno:denpyoNoRef.current?.getValue()
        ,sircde:lsircde,ksacde:cent_ksacde,mise:dataA.res[0].MISE,httymd:lhttymd,RCNT:0})});

      const dataB = await responseB.json();
      //console.log('伝票明細:%o',dataB);
      //console.log('件数:%o',dataB.RCNT);
      let lSUUGK = 0;
      let lTSESUUGK = 0;
      let lGNKKINGK = 0;
      let lBAKKINGK = 0;
      for(const key in dataB.res) {
        let rowNo = (Number(dataB.res[key].LINENO)||0);
        setItitemval(dataB.res[key].SHNCDE,rowNo,'shncde');
        setItitemval(dataB.res[key].SIZCAS,rowNo,'sizcas');
        let lsuu = Number(dataB.res[key].SUU).toLocaleString(undefined, {minimumFractionDigits: 1});
        setItitemval(lsuu,rowNo,'suu');
        let ltsesuu = Number(dataB.res[key].TSESUU).toLocaleString(undefined, {minimumFractionDigits: 1});
        setItitemval(ltsesuu,rowNo,'tsesuu');
        if (dataB.res[key].HKIKBN == 0){
          setItitemval('',rowNo,'hkikbn');
        }else{
          setItitemval(dataB.res[key].HKIKBN,rowNo,'hkikbn');
        }
        let lgnt = Number(dataB.res[key].GNT).toLocaleString(undefined, {minimumFractionDigits: 2});
        setItitemval(lgnt,rowNo,'gnt');
        let lgnkkin = Number(dataB.res[key].GNKKIN).toLocaleString();
        setItitemval(lgnkkin,rowNo,'gnkkin');
        let lbtn = Number(dataB.res[key].BTN).toLocaleString();
        setItitemval(lbtn,rowNo,'btn');
        let lbakkin = Number(dataB.res[key].BAKKIN).toLocaleString();
        setItitemval(lbakkin,rowNo,'bakkin');
        lSUUGK += parseFloat(dataB.res[key].SUU);
        lTSESUUGK += parseFloat(dataB.res[key].TSESUU);
        lGNKKINGK += parseFloat(dataB.res[key].GNKKIN);
        lBAKKINGK += parseFloat(dataB.res[key].BAKKIN);
      }

      suuryoGoukeiRef.current?.setValue(lSUUGK.toLocaleString(undefined, {minimumFractionDigits: 1}));
      teiseiSuuGoukeiRef.current?.setValue(lTSESUUGK.toLocaleString(undefined, {minimumFractionDigits: 1}));
      genkaKingakuGoukeiRef.current?.setValue(lGNKKINGK.toLocaleString());
      baikaKingakuGoukeiRef.current?.setValue(lBAKKINGK.toLocaleString());

      if (kubunRef.current?.getValue()=='2'){
        inTSEGNKFOT.current.focus();
      }else{
        inRET.current.focus();
      }

      // setting 訂正後原価金額合計 and 訂正後売価金額合計
      console.log('メンテ区分：'+datmkbn+'/'+kubunRef.current?.getValue());
      if (datmkbn == '1') {  //2023/11/03
        teiseiGenkaKingakuGoukeiRef.current?.setValue(parseFloat(dataA.res[0].TSEGNKFOT).toLocaleString());
        teiseiBaikaKingakuGoukeiRef.current?.setValue(parseFloat(dataA.res[0].TSEBAKFOT).toLocaleString());
        if (kubunRef.current?.getValue()=='2') {
          setAlert('既に入力済みの伝票です。');
        }
      }

    }else //データ無し
    {
      setAlert('伝票が登録されていません。');//TCGE0028：
      if(document.activeElement.id == 'SIRCDE'){
        inDENNO.current.focus();
        onNextFocus(inDENNO);
      }else{
        onNextFocus(inHTTYMD);
      }
      Errflg = 1;
    }
    return Errflg;
  }
  //#endregion

  //#region 明細部入力
  /**
   * 明細部入力 訂正後数量
   * @param rowNo 明細行
   * @returns  0:正常, 1:エラー
   */
  function onKeyDown_TSESUU(rowNo) {
    let mvFlg = 0;
    let l_TSESUU = [0,teiseiSuu1Ref.current?.getValue(),teiseiSuu2Ref.current?.getValue(),teiseiSuu3Ref.current?.getValue(),teiseiSuu4Ref.current?.getValue(),teiseiSuu5Ref.current?.getValue(),teiseiSuu6Ref.current?.getValue(),teiseiSuu7Ref.current?.getValue(),teiseiSuu8Ref.current?.getValue(),teiseiSuu9Ref.current?.getValue()];
    let l_mTSESUU = [0,suuryo1Ref.current?.getValue(),suuryo2Ref.current?.getValue(),suuryo3Ref.current?.getValue(),suuryo4Ref.current?.getValue(),suuryo5Ref.current?.getValue(),suuryo6Ref.current?.getValue(),suuryo7Ref.current?.getValue(),suuryo8Ref.current?.getValue(),suuryo9Ref.current?.getValue()]; //2023/11/09 upd
    let l_GNT = [0,genTanka1Ref.current?.getValue(),genTanka2Ref.current?.getValue(),genTanka3Ref.current?.getValue(),genTanka4Ref.current?.getValue(),genTanka5Ref.current?.getValue(),genTanka6Ref.current?.getValue(),genTanka7Ref.current?.getValue(),genTanka8Ref.current?.getValue(),genTanka9Ref.current?.getValue()];
    let l_BTN = [0,baiTanka1Ref.current?.getValue(),baiTanka2Ref.current?.getValue(),baiTanka3Ref.current?.getValue(),baiTanka4Ref.current?.getValue(),baiTanka5Ref.current?.getValue(),baiTanka6Ref.current?.getValue(),baiTanka7Ref.current?.getValue(),baiTanka8Ref.current?.getValue(),baiTanka9Ref.current?.getValue()];
    let itTSESUU = l_TSESUU[rowNo];
    let itmTSESUU = l_mTSESUU[rowNo];
    let itGNT = l_GNT[rowNo];
    let itBTN = l_BTN[rowNo];

    setItitemclr('#FFFFFF',rowNo,'tsesuu')
    if (isNaN(parseFloat(itTSESUU)) == true || parseFloat(itTSESUU) == 0) {
      if (itmTSESUU!=='') { //2023/11/09 upd
        setItitemval('0',rowNo,'tsesuu');
        //2023/11/08 upd
        setItitemval('0',rowNo,'gnkkin');
        setItitemval('0',rowNo,'bakkin');
      }else{  //2023/11/09 add
        setItitemval('',rowNo,'hkikbn');
        setItitemval('',rowNo,'tsesuu');
        setItitemval('',rowNo,'gnkkin');
        setItitemval('',rowNo,'bakkin');
        setItitemclr('#FFFFFF',rowNo,'tsesuu');
        setItitemclr('#FFFFFF',rowNo,'hkikbn');
      }
    } else {
      console.log('数量:'+itTSESUU+'>'+itmTSESUU);
      if (itmTSESUU!=='') { //2023/11/09 upd
        if (parseFloat(itTSESUU.replaceAll(/,/g, '')) > itmTSESUU) {
          setItitemclr('#f59d9d',rowNo,'tsesuu');
          setAlert('訂正前の数量を超えることはできません。');//TCGE0087：
          // inputRef.current.focus();
          // inputRef.current.select();
          mvFlg = 1;
          return mvFlg;
        }
        //2023/11/08 upd
        // console.log('計算:'+itTSESUU+' | '+itGNT+' | '+(itTSESUU*itGNT));
        // genkaKingaku1Ref.current?.setValue(Math.round(parseFloat(itTSESUU.replaceAll(/,/g, ''))*parseFloat(itGNT.replaceAll(/,/g, ''))).toLocaleString());
        // baikaKingaku1Ref.current?.setValue(Math.round(parseFloat(itTSESUU.replaceAll(/,/g, ''))*parseFloat(itBTN.replaceAll(/,/g, ''))).toLocaleString());
        let l_Itgnkkin = Math.round(parseFloat(itTSESUU.replaceAll(/,/g, ''))*parseFloat(itGNT.replaceAll(/,/g, ''))).toLocaleString();
        let l_Itbakkin = Math.round(parseFloat(itTSESUU.replaceAll(/,/g, ''))*parseFloat(itBTN.replaceAll(/,/g, ''))).toLocaleString();
        setItitemval(l_Itgnkkin,rowNo,'gnkkin');
        setItitemval(l_Itbakkin,rowNo,'bakkin');
      }else{  //2023/11/09 add
        setItitemval('',rowNo,'tsesuu');
        setItitemval('',rowNo,'hkikbn');
        setItitemval('',rowNo,'gnkkin');
        setItitemval('',rowNo,'bakkin');
        setItitemclr('#FFFFFF',rowNo,'tsesuu')
        setItitemclr('#FFFFFF',rowNo,'hkikbn')
      }

    }

    //await reCal();
    reCal();  //2023/11/04
    return mvFlg;
  }

  /**
   * 明細部入力 引合
   * @param rowNo 明細行
   * @returns  0:正常, 1:エラー
   */
  function onKeyDown_HKIKBN(rowNo) {
    let mvFlg = 0;
    let l_HKIKBN = [0,hikiai1Ref.current?.getValue(),hikiai2Ref.current?.getValue(),hikiai3Ref.current?.getValue(),hikiai4Ref.current?.getValue(),hikiai5Ref.current?.getValue(),hikiai6Ref.current?.getValue(),hikiai7Ref.current?.getValue(),hikiai8Ref.current?.getValue(),hikiai9Ref.current?.getValue()];
    let l_mTSESUU = [0,suuryo1Ref.current?.getValue(),suuryo2Ref.current?.getValue(),suuryo3Ref.current?.getValue(),suuryo4Ref.current?.getValue(),suuryo5Ref.current?.getValue(),suuryo6Ref.current?.getValue(),suuryo7Ref.current?.getValue(),suuryo8Ref.current?.getValue(),suuryo9Ref.current?.getValue()]; //2023/11/09 add
    let itmTSESUU = l_mTSESUU[rowNo]; //2023/11/09 add
    let itHKIKBN = l_HKIKBN[rowNo];

    setItitemclr('#FFFFFF',rowNo,'hkikbn')
    setAlert('　');
    if (itmTSESUU!=='') //2023/11/09 upd
    {
      if (!(itHKIKBN == '0' || itHKIKBN == '' || itHKIKBN=='6' || itHKIKBN=='9')) //2023/11/09 upd
      {
        setItitemclr('#f59d9d',rowNo,'hkikbn')
        setAlert('引合入力エラー');//TCGE0030：
        // inRET.current.focus();
        // inputRef.current.focus();
        mvFlg = 1;
      }
      if (itHKIKBN == '0')  //2023/11/09 add
      {
        setItitemval('',rowNo,'hkikbn');
      }
    } else {
      setItitemval('',rowNo,'hkikbn');
    }

    reCal();
    return mvFlg;
  }
  //#endregion

  //#region 明細からフォーカスが外れた際の処理
  // 明細からフォーカスが外れた際に確認処理を呼び出す。 2023/11/06 add
  useEffect(() => {
    // 下記の処理でblurTableflgを変更した際に
    // 重複して処理が走らないようにする為にtrueチェック
    // if(blurTableflg){
    //   let type = getInputType(document.activeElement.id);
    //   if(type != 2){

    //     //pageup時はヘッダ情報に遷移し、チェックは行わない
    //     if (blurkey!='PageUp')
    //     {
    //       onBlurTable();
    //     }
    //   }
    // }
    let type = getInputType(document.activeElement.id);
    if(type != 2){
      onBlurTable();
    }

  }, [blurTableflg]);

  // 明細からフォーカスが外れた際の処理 2023/11/06 add
  const onBlurTable = async()=>{
    console.log('明細項目チェック開始');
    let itmTSESUU = [0,suuryo1Ref.current?.getValue(),suuryo2Ref.current?.getValue(),suuryo3Ref.current?.getValue(),suuryo4Ref.current?.getValue(),suuryo5Ref.current?.getValue(),suuryo6Ref.current?.getValue(),suuryo7Ref.current?.getValue(),suuryo8Ref.current?.getValue(),suuryo9Ref.current?.getValue()]; //2023/11/09 add
    let itTSESUU = [0,teiseiSuu1Ref.current?.getValue(),teiseiSuu2Ref.current?.getValue(),teiseiSuu3Ref.current?.getValue(),teiseiSuu4Ref.current?.getValue(),teiseiSuu5Ref.current?.getValue(),teiseiSuu6Ref.current?.getValue(),teiseiSuu7Ref.current?.getValue(),teiseiSuu8Ref.current?.getValue(),teiseiSuu9Ref.current?.getValue()];
    let itHKIKBN = [0,hikiai1Ref.current?.getValue(),hikiai2Ref.current?.getValue(),hikiai3Ref.current?.getValue(),hikiai4Ref.current?.getValue(),hikiai5Ref.current?.getValue(),hikiai6Ref.current?.getValue(),hikiai7Ref.current?.getValue(),hikiai8Ref.current?.getValue(),hikiai9Ref.current?.getValue()];
    let inTSESUU = [0,inTSESUU1,inTSESUU2,inTSESUU3,inTSESUU4,inTSESUU5,inTSESUU6,inTSESUU7,inTSESUU8,inTSESUU9];
    let inHKIKBN = [0,inHKIKBN1,inHKIKBN2,inHKIKBN3,inHKIKBN4,inHKIKBN5,inHKIKBN6,inHKIKBN7,inHKIKBN8,inHKIKBN9];

    for(let i = 1; i <= 9; i++){
      // let itTSESUU = l_TSESUU[i];
      // let itHKIKBN = l_HKIKBN[i];
      if(itmTSESUU[i] != '' || itTSESUU[i] != '' || itHKIKBN[i] != ''){  //2023/11/09 upd
        let errflg = onKeyDown_TSESUU(i);
        if(errflg == 1){
          inTSESUU[i].current.focus();
          break;
        }
        errflg = onKeyDown_HKIKBN(i);
        if(errflg == 1){
          inHKIKBN[i].current.focus();
          break;
        }
      }else{
        setItitemclr('#FFFFFF',i,'tsesuu');
        setItitemclr('#FFFFFF',i,'hkikbn');
        break;
      }
    }

    setReCalflg(true);
    // setAlert('　');
  };
  //#endregion

  //#region フッタ部入力
  /**
   * フッタ部入力 訂正後原価金額合計
   * @returns  0:正常, 1:エラー
   */
  function onKeyDown_TSEGNKFOT() {
    let mvFlg = 0;
    setAlert('　');
    teiseiGenkaKingakuGoukeiRef.current?.setBGColor('#FFFFFF');
    if (kubunRef.current?.getValue() == '2') {
      let l_left = parseFloat(genkaKingakuGoukeiRef.current?.getValue().replaceAll(/,/g, ''))||0;
      let l_right = parseFloat(teiseiGenkaKingakuGoukeiRef.current?.getValue().replaceAll(/,/g, ''))||0;
      if (l_left != l_right) {
        teiseiGenkaKingakuGoukeiRef.current?.setBGColor('#f59d9d');
        setAlert('原価金額合計入力エラー');//TCGE0021：
        //inRET.current.focus();
        //inputRef.current.focus();
        onNextFocus(inTSEGNKFOT);
        mvFlg = 1;
      }
    }
    return mvFlg;
  }

  /**
   * フッタ部入力 訂正後売価金額合計
   * @returns  0:正常, 1:エラー
   */
  function onKeyDown_TSEBAKFOT() {
    let mvFlg = 0;
    setAlert('　');
    teiseiBaikaKingakuGoukeiRef.current?.setBGColor('#FFFFFF');
    if (kubunRef.current?.getValue() == '2') {
      let l_left = parseFloat(baikaKingakuGoukeiRef.current?.getValue().replaceAll(/,/g, ''))||0;
      let l_right = parseFloat(teiseiBaikaKingakuGoukeiRef.current?.getValue().replaceAll(/,/g, ''))||0;
      if (l_left != l_right) {
        teiseiBaikaKingakuGoukeiRef.current?.setBGColor('#f59d9d');
        setAlert('売価金額合計入力エラー');//TCGE0022：
        //inRET.current.focus();
        //inputRef.current.focus();
        onNextFocus(inTSEBAKFOT);
        mvFlg = 1;
      }
    }
    return mvFlg;
  }
  //#endregion

  //#region 登録処理
  /**
   * 登録処理 更新処理
   */
  const onKeyDown_Update = async() => {
    let mvFLG = 0;
    // 訂正後原価金額合計
    mvFLG = onKeyDown_TSEGNKFOT();  //2023/11/09 add
    if (mvFLG != 0){
      inTSEGNKFOT.current.focus();
      return mvFLG;
    }
    // 訂正後売価金額合計
    mvFLG = onKeyDown_TSEBAKFOT();  //2023/11/09 add
    if (mvFLG != 0){
      inTSEBAKFOT.current.focus();
      return mvFLG;
    }

    // 伝票ヘッダ更新
    console.log('伝票ヘッダ更新');
    console.log('JSON:%o',state.JGNCDE);
    const responseUA1 = await fetch('/TCSA0100/SL_HED_DAT_upd',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        ent_ksacde: cent_ksacde,ent_mise: cent_mise,densbt: itDENSBT,denno: denpyoNoRef.current?.getValue(),sircde: sireCodeRef.current?.getValue(),ksacde: cent_ksacde,mise: miseCodeRef.current?.getValue(),httymd:'20'+hattyuYMDRef.current?.getValue().replaceAll(/(\\|\/)/g,''),
        denymd: '20'+nouhinYMDRef.current?.getValue().replaceAll(/(\\|\/)/g,''),nhnymd: '20'+zituNouhinYMDRef.current?.getValue().replaceAll(/(\\|\/)/g,''),bin: binRef.current?.getValue(),tsesryfot: teiseiSuuGoukeiRef.current?.getValue().replaceAll(/,/g, ''),tsegnkfot: teiseiGenkaKingakuGoukeiRef.current?.getValue().replaceAll(/,/g, ''),tsebakfot: teiseiBaikaKingakuGoukeiRef.current?.getValue().replaceAll(/,/g, ''),
        upd_jgncde: state.JGNCDE,upd_prg_id: 'TCSA0100',trl_id: state.MISE,kijymd:'20'+(keijyoYMRef.current?.getValue().replaceAll('年','')).replaceAll('月','')+'20',kijyym:'20'+(keijyoYMRef.current?.getValue().replaceAll('年','')).replaceAll('月',''),dat_mkbn:'1'
      })
    });
    const dataUA1 = await responseUA1.json();
    console.log('伝票ヘッダ:%o',dataUA1);

    // 伝票明細更新
    console.log('伝票明細更新');
    const responseUA2 = await  fetch('/TCSA0100/SL_DTL_DAT_upd',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        ent_ksacde: cent_ksacde,ent_mise: cent_mise,densbt: itDENSBT,denno: denpyoNoRef.current?.getValue(),
        sircde: sireCodeRef.current?.getValue(),ksacde: cent_ksacde,mise: miseCodeRef.current?.getValue(),httymd:'20'+hattyuYMDRef.current?.getValue().replaceAll(/(\\|\/)/g,''),
        meisai:[
          {SHNCD: shohinCode1Ref.current?.getValue(),LINENO: '1',TSESUU: teiseiSuu1Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: genkaKingaku1Ref.current?.getValue().replaceAll(/,/g, ''),BAKKIN: baikaKingaku1Ref.current?.getValue().replaceAll(/,/g, ''),HKIKBN: hikiai1Ref.current?.getValue()},
          {SHNCD: shohinCode2Ref.current?.getValue(),LINENO: '2',TSESUU: teiseiSuu2Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: genkaKingaku2Ref.current?.getValue().replaceAll(/,/g, ''),BAKKIN: baikaKingaku2Ref.current?.getValue().replaceAll(/,/g, ''),HKIKBN: hikiai2Ref.current?.getValue()},
          {SHNCD: shohinCode3Ref.current?.getValue(),LINENO: '3',TSESUU: teiseiSuu3Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: genkaKingaku3Ref.current?.getValue().replaceAll(/,/g, ''),BAKKIN: baikaKingaku3Ref.current?.getValue().replaceAll(/,/g, ''),HKIKBN: hikiai3Ref.current?.getValue()},
          {SHNCD: shohinCode4Ref.current?.getValue(),LINENO: '4',TSESUU: teiseiSuu4Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: genkaKingaku4Ref.current?.getValue().replaceAll(/,/g, ''),BAKKIN: baikaKingaku4Ref.current?.getValue().replaceAll(/,/g, ''),HKIKBN: hikiai4Ref.current?.getValue()},
          {SHNCD: shohinCode5Ref.current?.getValue(),LINENO: '5',TSESUU: teiseiSuu5Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: genkaKingaku5Ref.current?.getValue().replaceAll(/,/g, ''),BAKKIN: baikaKingaku5Ref.current?.getValue().replaceAll(/,/g, ''),HKIKBN: hikiai5Ref.current?.getValue()},
          {SHNCD: shohinCode6Ref.current?.getValue(),LINENO: '6',TSESUU: teiseiSuu6Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: genkaKingaku6Ref.current?.getValue().replaceAll(/,/g, ''),BAKKIN: baikaKingaku6Ref.current?.getValue().replaceAll(/,/g, ''),HKIKBN: hikiai6Ref.current?.getValue()},
          {SHNCD: shohinCode7Ref.current?.getValue(),LINENO: '7',TSESUU: teiseiSuu7Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: genkaKingaku7Ref.current?.getValue().replaceAll(/,/g, ''),BAKKIN: baikaKingaku7Ref.current?.getValue().replaceAll(/,/g, ''),HKIKBN: hikiai7Ref.current?.getValue()},
          {SHNCD: shohinCode8Ref.current?.getValue(),LINENO: '8',TSESUU: teiseiSuu8Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: genkaKingaku8Ref.current?.getValue().replaceAll(/,/g, ''),BAKKIN: baikaKingaku8Ref.current?.getValue().replaceAll(/,/g, ''),HKIKBN: hikiai8Ref.current?.getValue()},
          {SHNCD: shohinCode9Ref.current?.getValue(),LINENO: '9',TSESUU: teiseiSuu9Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: genkaKingaku9Ref.current?.getValue().replaceAll(/,/g, ''),BAKKIN: baikaKingaku9Ref.current?.getValue().replaceAll(/,/g, ''),HKIKBN: hikiai9Ref.current?.getValue()}
        ],
        upd_jgncde: state.JGNCDE,upd_prg_id: 'TCSA0100',trl_id: state.MISE
      })
    });
    const dataUA2 = await responseUA2.json();
    console.log('伝票明細:%o',dataUA2);

    if (dataUA2.res == '0'){
      setItrcnt(itRcnt+1);
    }
    return mvFLG;
  }

  /**
   * 登録処理 削除処理（論理削除）
   */
  const onKeyDown_Delete = async() => {
    let mvFLG = 0;
    // 伝票ヘッダ削除
    console.log('伝票ヘッダ削除（論理削除）');
    console.log('JSON:%o',state.JGNCDE);
    const responseUA1 = await fetch('/TCSA0100/SL_HED_DAT_upd',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        ent_ksacde: cent_ksacde,ent_mise: cent_mise,densbt: itDENSBT,denno: denpyoNoRef.current?.getValue(),sircde: sireCodeRef.current?.getValue(),ksacde: cent_ksacde,mise: miseCodeRef.current?.getValue(),httymd:'20'+hattyuYMDRef.current?.getValue().replaceAll(/(\\|\/)/g,''),
        denymd: '20'+nouhinYMDRef.current?.getValue().replaceAll(/(\\|\/)/g,''),nhnymd: '0',bin: '0',tsesryfot: suuryoGoukeiRef.current?.getValue().replaceAll(/,/g, ''),tsegnkfot: itGNKFOT.replaceAll(/,/g, ''),tsebakfot: itBAKFOT.replaceAll(/,/g, ''),
        upd_jgncde: state.JGNCDE,upd_prg_id: 'TCSA0100',trl_id: state.MISE,kijymd:'0',kijyym:'0',dat_mkbn:'0'
      })
    });
    const dataUA1 = await responseUA1.json();
    console.log('伝票ヘッダ:%o',dataUA1);

    // 伝票明細削除
    console.log('伝票明細削除（論理削除）');

    // 原価金額計算
    let lGnkkin1 = Math.round(parseFloat(suuryo1Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(genTanka1Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();
    let lGnkkin2 = Math.round(parseFloat(suuryo2Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(genTanka2Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();
    let lGnkkin3 = Math.round(parseFloat(suuryo3Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(genTanka3Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();
    let lGnkkin4 = Math.round(parseFloat(suuryo4Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(genTanka4Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();
    let lGnkkin5 = Math.round(parseFloat(suuryo5Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(genTanka5Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();
    let lGnkkin6 = Math.round(parseFloat(suuryo6Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(genTanka6Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();
    let lGnkkin7 = Math.round(parseFloat(suuryo7Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(genTanka7Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();
    let lGnkkin8 = Math.round(parseFloat(suuryo8Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(genTanka8Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();
    let lGnkkin9 = Math.round(parseFloat(suuryo9Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(genTanka9Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();
    // 売価金額
    let lBakkin1 = Math.round(parseFloat(suuryo1Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(baiTanka1Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();
    let lBakkin2 = Math.round(parseFloat(suuryo2Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(baiTanka2Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();
    let lBakkin3 = Math.round(parseFloat(suuryo3Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(baiTanka3Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();
    let lBakkin4 = Math.round(parseFloat(suuryo4Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(baiTanka4Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();
    let lBakkin5 = Math.round(parseFloat(suuryo5Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(baiTanka5Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();
    let lBakkin6 = Math.round(parseFloat(suuryo6Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(baiTanka6Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();
    let lBakkin7 = Math.round(parseFloat(suuryo7Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(baiTanka7Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();
    let lBakkin8 = Math.round(parseFloat(suuryo8Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(baiTanka8Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();
    let lBakkin9 = Math.round(parseFloat(suuryo9Ref.current?.getValue().replaceAll(/,/g, ''))*parseFloat(baiTanka9Ref.current?.getValue().replaceAll(/,/g, ''))).toLocaleString();

    const responseUA2 = await fetch('/TCSA0100/SL_DTL_DAT_upd',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        ent_ksacde: cent_ksacde,ent_mise: cent_mise,densbt: itDENSBT,denno: denpyoNoRef.current?.getValue(),
        sircde: sireCodeRef.current?.getValue(),ksacde: cent_ksacde,mise: miseCodeRef.current?.getValue(),httymd:'20'+hattyuYMDRef.current?.getValue().replaceAll(/(\\|\/)/g,''),
        meisai:[
          {SHNCD: shohinCode1Ref.current?.getValue(),LINENO: '1',TSESUU: suuryo1Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: lGnkkin1.replaceAll(/,/g, ''),BAKKIN: lBakkin1.replaceAll(/,/g, ''),HKIKBN: ''},
          {SHNCD: shohinCode2Ref.current?.getValue(),LINENO: '2',TSESUU: suuryo2Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: lGnkkin2.replaceAll(/,/g, ''),BAKKIN: lBakkin2.replaceAll(/,/g, ''),HKIKBN: ''},
          {SHNCD: shohinCode3Ref.current?.getValue(),LINENO: '3',TSESUU: suuryo3Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: lGnkkin3.replaceAll(/,/g, ''),BAKKIN: lBakkin3.replaceAll(/,/g, ''),HKIKBN: ''},
          {SHNCD: shohinCode4Ref.current?.getValue(),LINENO: '4',TSESUU: suuryo4Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: lGnkkin4.replaceAll(/,/g, ''),BAKKIN: lBakkin4.replaceAll(/,/g, ''),HKIKBN: ''},
          {SHNCD: shohinCode5Ref.current?.getValue(),LINENO: '5',TSESUU: suuryo5Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: lGnkkin5.replaceAll(/,/g, ''),BAKKIN: lBakkin5.replaceAll(/,/g, ''),HKIKBN: ''},
          {SHNCD: shohinCode6Ref.current?.getValue(),LINENO: '6',TSESUU: suuryo6Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: lGnkkin6.replaceAll(/,/g, ''),BAKKIN: lBakkin6.replaceAll(/,/g, ''),HKIKBN: ''},
          {SHNCD: shohinCode7Ref.current?.getValue(),LINENO: '7',TSESUU: suuryo7Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: lGnkkin7.replaceAll(/,/g, ''),BAKKIN: lBakkin7.replaceAll(/,/g, ''),HKIKBN: ''},
          {SHNCD: shohinCode8Ref.current?.getValue(),LINENO: '8',TSESUU: suuryo8Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: lGnkkin8.replaceAll(/,/g, ''),BAKKIN: lBakkin8.replaceAll(/,/g, ''),HKIKBN: ''},
          {SHNCD: shohinCode9Ref.current?.getValue(),LINENO: '9',TSESUU: suuryo9Ref.current?.getValue().replaceAll(/,/g, ''),GNKKIN: lGnkkin9.replaceAll(/,/g, ''),BAKKIN: lBakkin9.replaceAll(/,/g, ''),HKIKBN: ''}
        ],
        upd_jgncde: state.JGNCDE,upd_prg_id: 'TCSA0100',trl_id: state.MISE
      })
    });
    const dataUA2 = await responseUA2.json();
    console.log('伝票明細:%o',dataUA2);
    return mvFLG;

  }
  //#endregion

  //#region スタイル
  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  // 確認のWidthを設定する
  const cssInputOutlineRetSetwidth = (obj) => {
    let resObj = obj;
    resObj.width = '50px';
    return resObj;
  }

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
        fontSize: '1.6rem',             // フォントサイズ
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: '#000000', // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

  }
  //#endregion

  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: '740px',
          width: '900px',
          m: '10px auto',
          padding:'10px',
        }}
      >
        <Grid
          container
          direction='column'
          justifyContent='flex-start' //多分、デフォルトflex-startなので省略できる。
          alignItems='left'
        >
          <div style={{display:'flex'}}>
            <Typography variant={'h6'} sx={{ m: '10px' }}>
              伝票(ターンアラウンド)入力{ttl}
            </Typography>
            <Box width={'55%'} display={'flex'} justifyContent={'flex-end'}>
              <Typography variant={'h6'} sx={{ m: '5px',mt:'17px',fontSize:15 }}>
              [{itKBNNM}]
              </Typography>
              <Typography variant={'h6'} sx={{ m: '5px',mt:'17px',fontSize:15 }}>
                ({itRcnt.toLocaleString()+'件'}){/* {state.JGNMEIKNJ} */}
              </Typography>
              <Typography variant={'h6'} sx={{ m: '5px',mt:'17px',fontSize:15 }}>
                {state.MISEMEI}：{state.JGNMEIKNJ}
              </Typography>
              <Typography variant={'h6'} sx={{ m: '5px',mt:'17px',fontSize:15 }}>
                { sdate }
              </Typography>
            </Box>
          </div>
        </Grid>

        <Stack direction='row' sx={{mt:'-15px'}}>
          {/* 区分 */}
          <CKubun
            ref = {kubunRef}
            onKeyDown = {onKeyDown}
            inputRef = {inKBN}
            inputRtRef = {inKBN}
            inputNxRef = {inDENNO}
          />

          {/* 伝票No. */}
          <CDenpyoNo
            ref = {denpyoNoRef}
            onKeyDown = {onKeyDown}
            inputRef = {inDENNO}
            inputRtRef = {inKBN}
            inputNxRef = {inSIRCDE}
          />

          {/* 取引先CD */}
          <CSireCode
            ref = {sireCodeRef}
            onKeyDown = {onKeyDown}
            inputRef = {inSIRCDE}
            inputRtRef = {inDENNO}
            inputNxRef = {inMISE}
          />

          {/* 取引先CD */}
          <CKeijyoYM
            ref = {keijyoYMRef}
            onKeyDown = {onKeyDown}
            inputRef = {inKIJYYM}
            inputRtRef = {inSIRCDE}
            inputNxRef = {inMISE}
          />
        </Stack>
        <br />
        <Stack direction='row' sx={{mt:'-10px'}}>
          {/* 店CD */}
          <CMiseCode
            ref = {miseCodeRef}
            onKeyDown = {onKeyDown}
            inputRef = {inMISE}
            inputRtRef = {inSIRCDE}
            inputNxRef = {inBMNCDE}
          />

          {/* 分類CD */}
          <CBunruiCode
            ref = {bunruiCodeRef}
            onKeyDown = {onKeyDown}
            inputRef = {inBMNCDE}
            inputRtRef = {inMISE}
            inputNxRef = {inDENKBN}
          />

          {/* 伝票区分 */}
          <CDenpyoKubun
            ref = {denpyoKubunRef}
            onKeyDown = {onKeyDown}
            inputRef = {inDENKBN}
            inputRtRef = {inBMNCDE}
            inputNxRef = {inHTTYMD}
          />
        </Stack>
        <br />
        <Stack direction='row' spacing={3} sx={{mt:'-10px'}}>
          {/* 発注日 */}
          <CHattyuYMD
            ref = {hattyuYMDRef}
            onKeyDown = {onKeyDown}
            inputRef = {inHTTYMD}
            inputRtRef = {inDENKBN}
            inputNxRef = {inNYMD}
            dishttymd = {dishttymd}
          />

          {/* 納品日 */}
          <CNouhinYMD
            ref = {nouhinYMDRef}
            onKeyDown = {onKeyDown}
            inputRef = {inNYMD}
            inputRtRef = {inHTTYMD}
            inputNxRef = {inNHNYMD}
          />

          {/* 実納品日 */}
          <CZituNouhinYMD
            ref = {zituNouhinYMDRef}
            onKeyDown = {onKeyDown}
            inputRef = {inNHNYMD}
            inputRtRef = {inNYMD}
            inputNxRef = {inBIN}
          />

          {/* 便 */}
          <CBin
            ref = {binRef}
            onKeyDown = {onKeyDown}
            inputRef = {inBIN}
            inputRtRef = {inNHNYMD}
            inputNxRef = {inTSEGNKFOT}
          />
        </Stack>
        <br />
        {/* テーブル表示処理 */}
        <TableContainer
          component={Paper}
          sx={{maxWidth:900,mt:'-10px'}}
          onBlur={(e) => {    //2023/11/06 add
            // setBlurTableflg(false);
            // setTimeout(() => setBlurTableflg(true),100);
            if (e.relatedTarget?.id != null && e.relatedTarget?.id != undefined)  //2023/11/13　明細内のカーソル移動だけで再レンダリングしないよう対応
            {
              let type = getInputType(e.relatedTarget.id);
              if(type !== 2){
                if (blurTableflg === true)
                {
                  setBlurTableflg(false);
                }else{
                  setBlurTableflg(true);
                }
              }
            }
          }}

        >
          <Table sx={{border:1}} aria-label='spanning table' size='small'>
            <TableHead> {/* テーブル表示処理ヘッダ部 */}
              <TableRow>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width='100px' align='center' bgcolor='lightgray'>商品コード</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width='60px' align='center'  bgcolor='lightgray'>ｻｲｽﾞｹｰｽ</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width='40px' align='center'  bgcolor='lightgray'>数量</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width='20px' align='center'  bgcolor='lightgray'>行</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width='50px' align='center'  bgcolor='lightgray'>訂正後数量</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width='35px' align='center'  bgcolor='lightgray'>引合</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width='50px' align='center'  bgcolor='lightgray'>原単価</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width='70px' align='center'  bgcolor='lightgray'>原価金額</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width='50px' align='center'  bgcolor='lightgray'>売単価</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width='70px' align='center'  bgcolor='lightgray'>売価金額</TableCell>
              </TableRow>
            </TableHead>
          <TableBody>   {/* テーブル表示処理明細部 */}
            <TableRow>
              <CTableCell ref = {shohinCode1Ref} type = {'A'}/>   {/* 商品コード1 */}
              <CTableCell ref = {sizeCase1Ref} type = {'B'}/>     {/* サイズケース1 */}
              <CTableCell ref = {suuryo1Ref} type = {'B'}/>       {/* 数量1 */}
              <TableCell align='center' sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}>1</TableCell>

              {/* 訂正後数量1 */}
              <CTeiseiSuu
                ref = {teiseiSuu1Ref}
                id = {'TSESUU1'}
                itMeisaiNo = {1}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inTSESUU1}
                inputNxRef = {inHKIKBN1}
                inUpRef = {inTSESUU1}
                inRgRef = {inHKIKBN1}
                inDnRef = {inTSESUU2}
                inLfRef = {inTSESUU1}
                inNxShin = {shohinCode1Ref}
                dismeisai = {dismeisai}
              />

              {/* 引合1 */}
              <CHikiai
                ref = {hikiai1Ref}
                id = {'HKIKBN1'}
                itMeisaiNo = {1}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inHKIKBN1}
                inputNxRef = {inTSESUU2}
                inUpRef = {inHKIKBN1}
                inRgRef = {inHKIKBN1}
                inDnRef = {inHKIKBN2}
                inLfRef = {inTSESUU1}
                inNxShin = {shohinCode2Ref}
                dismeisai = {dismeisai}
              />

              <CTableCell ref = {genTanka1Ref} type = {'B'}/>     {/* 原単価1 */}
              <CTableCell ref = {genkaKingaku1Ref} type = {'B'}/> {/* 原価金額1 */}
              <CTableCell ref = {baiTanka1Ref} type = {'B'}/>     {/* 売単価1 */}
              <CTableCell ref = {baikaKingaku1Ref} type = {'B'}/> {/* 売価金額1 */}
            </TableRow>
            <TableRow>
              <CTableCell ref = {shohinCode2Ref} type = {'A'}/>   {/* 商品コード2 */}
              <CTableCell ref = {sizeCase2Ref} type = {'B'}/>     {/* サイズケース2 */}
              <CTableCell ref = {suuryo2Ref} type = {'B'}/>       {/* 数量2 */}
              <TableCell align='center' sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}>2</TableCell>

              {/* 訂正後数量2 */}
              <CTeiseiSuu
                ref = {teiseiSuu2Ref}
                id = {'TSESUU2'}
                itMeisaiNo = {2}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inTSESUU2}
                inputNxRef = {inHKIKBN2}
                inUpRef = {inTSESUU1}
                inRgRef = {inHKIKBN2}
                inDnRef = {inTSESUU3}
                inLfRef = {inTSESUU2}
                inNxShin = {shohinCode2Ref}
                dismeisai = {dismeisai}
              />

              {/* 引合2 */}
              <CHikiai
                ref = {hikiai2Ref}
                id = {'HKIKBN2'}
                itMeisaiNo = {2}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inHKIKBN2}
                inputNxRef = {inTSESUU3}
                inUpRef = {inHKIKBN1}
                inRgRef = {inHKIKBN2}
                inDnRef = {inHKIKBN3}
                inLfRef = {inTSESUU2}
                inNxShin = {shohinCode3Ref}
                dismeisai = {dismeisai}
              />

              <CTableCell ref = {genTanka2Ref} type = {'B'}/>     {/* 原単価2 */}
              <CTableCell ref = {genkaKingaku2Ref} type = {'B'}/> {/* 原価金額2 */}
              <CTableCell ref = {baiTanka2Ref} type = {'B'}/>     {/* 売単価2 */}
              <CTableCell ref = {baikaKingaku2Ref} type = {'B'}/> {/* 売価金額2 */}
            </TableRow>
            <TableRow>
              <CTableCell ref = {shohinCode3Ref} type = {'A'}/>   {/* 商品コード3 */}
              <CTableCell ref = {sizeCase3Ref} type = {'B'}/>     {/* サイズケース3 */}
              <CTableCell ref = {suuryo3Ref} type = {'B'}/>       {/* 数量3 */}
              <TableCell align='center' sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}>3</TableCell>

              {/* 訂正後数量3 */}
              <CTeiseiSuu
                ref = {teiseiSuu3Ref}
                id = {'TSESUU3'}
                itMeisaiNo = {3}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inTSESUU3}
                inputNxRef = {inHKIKBN3}
                inUpRef = {inTSESUU2}
                inRgRef = {inHKIKBN3}
                inDnRef = {inTSESUU4}
                inLfRef = {inTSESUU3}
                inNxShin = {shohinCode3Ref}
                dismeisai = {dismeisai}
              />

              {/* 引合3 */}
              <CHikiai
                ref = {hikiai3Ref}
                id = {'HKIKBN3'}
                itMeisaiNo = {3}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inHKIKBN3}
                inputNxRef = {inTSESUU4}
                inUpRef = {inHKIKBN2}
                inRgRef = {inHKIKBN3}
                inDnRef = {inHKIKBN4}
                inLfRef = {inTSESUU3}
                inNxShin = {shohinCode4Ref}
                dismeisai = {dismeisai}
              />

              <CTableCell ref = {genTanka3Ref} type = {'B'}/>     {/* 原単価3 */}
              <CTableCell ref = {genkaKingaku3Ref} type = {'B'}/> {/* 原価金額3 */}
              <CTableCell ref = {baiTanka3Ref} type = {'B'}/>     {/* 売単価3 */}
              <CTableCell ref = {baikaKingaku3Ref} type = {'B'}/> {/* 売価金額3 */}
            </TableRow>
            <TableRow>
              <CTableCell ref = {shohinCode4Ref} type = {'A'}/>   {/* 商品コード4 */}
              <CTableCell ref = {sizeCase4Ref} type = {'B'}/>     {/* サイズケース4 */}
              <CTableCell ref = {suuryo4Ref} type = {'B'}/>       {/* 数量4 */}
              <TableCell align='center' sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}>4</TableCell>

              {/* 訂正後数量4 */}
              <CTeiseiSuu
                ref = {teiseiSuu4Ref}
                id = {'TSESUU4'}
                itMeisaiNo = {4}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inTSESUU4}
                inputNxRef = {inHKIKBN4}
                inUpRef = {inTSESUU3}
                inRgRef = {inHKIKBN4}
                inDnRef = {inTSESUU5}
                inLfRef = {inTSESUU4}
                inNxShin = {shohinCode4Ref}
                dismeisai = {dismeisai}
              />

              {/* 引合4 */}
              <CHikiai
                ref = {hikiai4Ref}
                id = {'HKIKBN4'}
                itMeisaiNo = {4}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inHKIKBN4}
                inputNxRef = {inTSESUU5}
                inUpRef = {inHKIKBN3}
                inRgRef = {inHKIKBN4}
                inDnRef = {inHKIKBN5}
                inLfRef = {inTSESUU4}
                inNxShin = {shohinCode5Ref}
                dismeisai = {dismeisai}
              />

              <CTableCell ref = {genTanka4Ref} type = {'B'}/>     {/* 原単価4 */}
              <CTableCell ref = {genkaKingaku4Ref} type = {'B'}/> {/* 原価金額4 */}
              <CTableCell ref = {baiTanka4Ref} type = {'B'}/>     {/* 売単価4 */}
              <CTableCell ref = {baikaKingaku4Ref} type = {'B'}/> {/* 売価金額4 */}
            </TableRow>
            <TableRow>
              <CTableCell ref = {shohinCode5Ref} type = {'A'}/>   {/* 商品コード5 */}
              <CTableCell ref = {sizeCase5Ref} type = {'B'}/>     {/* サイズケース5 */}
              <CTableCell ref = {suuryo5Ref} type = {'B'}/>       {/* 数量5 */}
              <TableCell align='center' sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}>5</TableCell>

              {/* 訂正後数量5 */}
              <CTeiseiSuu
                ref = {teiseiSuu5Ref}
                id = {'TSESUU5'}
                itMeisaiNo = {5}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inTSESUU5}
                inputNxRef = {inHKIKBN5}
                inUpRef = {inTSESUU4}
                inRgRef = {inHKIKBN5}
                inDnRef = {inTSESUU6}
                inLfRef = {inTSESUU5}
                inNxShin = {shohinCode5Ref}
                dismeisai = {dismeisai}
              />

              {/* 引合5 */}
              <CHikiai
                ref = {hikiai5Ref}
                id = {'HKIKBN5'}
                itMeisaiNo = {5}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inHKIKBN5}
                inputNxRef = {inTSESUU6}
                inUpRef = {inHKIKBN4}
                inRgRef = {inHKIKBN5}
                inDnRef = {inHKIKBN6}
                inLfRef = {inTSESUU5}
                inNxShin = {shohinCode6Ref}
                dismeisai = {dismeisai}
              />

              <CTableCell ref = {genTanka5Ref} type = {'B'}/>     {/* 原単価5 */}
              <CTableCell ref = {genkaKingaku5Ref} type = {'B'}/> {/* 原価金額5 */}
              <CTableCell ref = {baiTanka5Ref} type = {'B'}/>     {/* 売単価5 */}
              <CTableCell ref = {baikaKingaku5Ref} type = {'B'}/> {/* 売価金額5 */}
            </TableRow>
            <TableRow>
              <CTableCell ref = {shohinCode6Ref} type = {'A'}/>   {/* 商品コード6 */}
              <CTableCell ref = {sizeCase6Ref} type = {'B'}/>     {/* サイズケース6 */}
              <CTableCell ref = {suuryo6Ref} type = {'B'}/>       {/* 数量6 */}
              <TableCell align='center' sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}>6</TableCell>

              {/* 訂正後数量6 */}
              <CTeiseiSuu
                ref = {teiseiSuu6Ref}
                id = {'TSESUU6'}
                itMeisaiNo = {6}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inTSESUU6}
                inputNxRef = {inHKIKBN6}
                inUpRef = {inTSESUU5}
                inRgRef = {inHKIKBN6}
                inDnRef = {inTSESUU7}
                inLfRef = {inTSESUU6}
                inNxShin = {shohinCode6Ref}
                dismeisai = {dismeisai}
              />

              {/* 引合6 */}
              <CHikiai
                ref = {hikiai6Ref}
                id = {'HKIKBN6'}
                itMeisaiNo = {6}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inHKIKBN6}
                inputNxRef = {inTSESUU7}
                inUpRef = {inHKIKBN5}
                inRgRef = {inHKIKBN6}
                inDnRef = {inHKIKBN7}
                inLfRef = {inTSESUU6}
                inNxShin = {shohinCode7Ref}
                dismeisai = {dismeisai}
              />

              <CTableCell ref = {genTanka6Ref} type = {'B'}/>     {/* 原単価6 */}
              <CTableCell ref = {genkaKingaku6Ref} type = {'B'}/> {/* 原価金額6 */}
              <CTableCell ref = {baiTanka6Ref} type = {'B'}/>     {/* 売単価6 */}
              <CTableCell ref = {baikaKingaku6Ref} type = {'B'}/> {/* 売価金額6 */}
            </TableRow>
            <TableRow>
              <CTableCell ref = {shohinCode7Ref} type = {'A'}/>   {/* 商品コード7 */}
              <CTableCell ref = {sizeCase7Ref} type = {'B'}/>     {/* サイズケース7 */}
              <CTableCell ref = {suuryo7Ref} type = {'B'}/>       {/* 数量7 */}
              <TableCell align='center' sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}>7</TableCell>

              {/* 訂正後数量7 */}
              <CTeiseiSuu
                ref = {teiseiSuu7Ref}
                id = {'TSESUU7'}
                itMeisaiNo = {7}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inTSESUU7}
                inputNxRef = {inHKIKBN7}
                inUpRef = {inTSESUU6}
                inRgRef = {inHKIKBN7}
                inDnRef = {inTSESUU8}
                inLfRef = {inTSESUU7}
                inNxShin = {shohinCode7Ref}
                dismeisai = {dismeisai}
              />

              {/* 引合7 */}
              <CHikiai
                ref = {hikiai7Ref}
                id = {'HKIKBN7'}
                itMeisaiNo = {7}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inHKIKBN7}
                inputNxRef = {inTSESUU8}
                inUpRef = {inHKIKBN6}
                inRgRef = {inHKIKBN7}
                inDnRef = {inHKIKBN8}
                inLfRef = {inTSESUU7}
                inNxShin = {shohinCode8Ref}
                dismeisai = {dismeisai}
              />

              <CTableCell ref = {genTanka7Ref} type = {'B'}/>     {/* 原単価7 */}
              <CTableCell ref = {genkaKingaku7Ref} type = {'B'}/> {/* 原価金額7 */}
              <CTableCell ref = {baiTanka7Ref} type = {'B'}/>     {/* 売単価7 */}
              <CTableCell ref = {baikaKingaku7Ref} type = {'B'}/> {/* 売価金額7 */}
            </TableRow>
            <TableRow>
              <CTableCell ref = {shohinCode8Ref} type = {'A'}/>   {/* 商品コード8 */}
              <CTableCell ref = {sizeCase8Ref} type = {'B'}/>     {/* サイズケース8 */}
              <CTableCell ref = {suuryo8Ref} type = {'B'}/>       {/* 数量8 */}
              <TableCell align='center' sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}>8</TableCell>

              {/* 訂正後数量8 */}
              <CTeiseiSuu
                ref = {teiseiSuu8Ref}
                id = {'TSESUU8'}
                itMeisaiNo = {8}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inTSESUU8}
                inputNxRef = {inHKIKBN8}
                inUpRef = {inTSESUU7}
                inRgRef = {inHKIKBN8}
                inDnRef = {inTSESUU9}
                inLfRef = {inTSESUU8}
                inNxShin = {shohinCode8Ref}
                dismeisai = {dismeisai}
              />

              {/* 引合8 */}
              <CHikiai
                ref = {hikiai8Ref}
                id = {'HKIKBN8'}
                itMeisaiNo = {8}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inHKIKBN8}
                inputNxRef = {inTSESUU9}
                inUpRef = {inHKIKBN7}
                inRgRef = {inHKIKBN8}
                inDnRef = {inHKIKBN9}
                inLfRef = {inTSESUU8}
                inNxShin = {shohinCode9Ref}
                dismeisai = {dismeisai}
              />

              <CTableCell ref = {genTanka8Ref} type = {'B'}/>     {/* 原単価8 */}
              <CTableCell ref = {genkaKingaku8Ref} type = {'B'}/> {/* 原価金額8 */}
              <CTableCell ref = {baiTanka8Ref} type = {'B'}/>     {/* 売単価8 */}
              <CTableCell ref = {baikaKingaku8Ref} type = {'B'}/> {/* 売価金額8 */}
            </TableRow>
            <TableRow>
              <CTableCell ref = {shohinCode9Ref} type = {'A'}/>   {/* 商品コード9 */}
              <CTableCell ref = {sizeCase9Ref} type = {'B'}/>     {/* サイズケース9 */}
              <CTableCell ref = {suuryo9Ref} type = {'B'}/>       {/* 数量9 */}
              <TableCell align='center' sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}>9</TableCell>

              {/* 訂正後数量9 */}
              <CTeiseiSuu
                ref = {teiseiSuu9Ref}
                id = {'TSESUU9'}
                itMeisaiNo = {9}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inTSESUU9}
                inputNxRef = {inHKIKBN9}
                inUpRef = {inTSESUU8}
                inRgRef = {inHKIKBN9}
                inDnRef = {inTSEGNKFOT}
                inLfRef = {inTSESUU9}
                inNxShin = {shohinCode9Ref}
                dismeisai = {dismeisai}
              />

              {/* 引合9 */}
              <CHikiai
                ref = {hikiai9Ref}
                id = {'HKIKBN9'}
                itMeisaiNo = {9}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inHKIKBN9}
                inputNxRef = {inTSEGNKFOT}
                inUpRef = {inHKIKBN8}
                inRgRef = {inHKIKBN9}
                inDnRef = {inTSEGNKFOT}
                inLfRef = {inTSESUU9}
                inNxShin = {'END'}
                dismeisai = {dismeisai}
              />

              <CTableCell ref = {genTanka9Ref} type = {'B'}/>     {/* 原単価9 */}
              <CTableCell ref = {genkaKingaku9Ref} type = {'B'}/> {/* 原価金額9 */}
              <CTableCell ref = {baiTanka9Ref} type = {'B'}/>     {/* 売単価9 */}
              <CTableCell ref = {baikaKingaku9Ref} type = {'B'}/> {/* 売価金額9 */}
            </TableRow>
            <TableRow>
              <TableCell align='center' bgcolor='lightgray' sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}></TableCell>
              <TableCell align='center' sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}>合計</TableCell>
              <CTableCell ref = {suuryoGoukeiRef} type = {'B'}/>        {/* 数量合計 */}
              <TableCell align='center' bgcolor='lightgray' sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}></TableCell>
              <CTableCell ref = {teiseiSuuGoukeiRef} type = {'B'}/>     {/* 訂正後数量合計 */}
              <TableCell align='center' bgcolor='lightgray' sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}></TableCell>
              <TableCell align='center' bgcolor='lightgray' sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}></TableCell>
              <CTableCell ref = {genkaKingakuGoukeiRef} type = {'B'}/>  {/* 原価金額合計 */}
              <TableCell align='center' bgcolor='lightgray' sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}></TableCell>
              <CTableCell ref = {baikaKingakuGoukeiRef} type = {'B'}/>  {/* 売価金額合計 */}
            </TableRow>

          </TableBody>
          </Table>
        </TableContainer><br/>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Stack direction='row' spacing={2} >
            {/* 訂正後原価金額合計 */}
            <CTeiseiGenkaKingakuGoukei
              ref = {teiseiGenkaKingakuGoukeiRef}
              onKeyDown = {onKeyDown}
              inputRef = {inTSEGNKFOT}
              inputRtRef = {inTSESUU1}
              inputNxRef = {inTSEBAKFOT}
            />
            &emsp;
            {/* 訂正後売価金額合計 */}
            <CTeiseiBaikaKingakuGoukei
              ref = {teiseiBaikaKingakuGoukeiRef}
              onKeyDown = {onKeyDown}
              inputRef = {inTSEBAKFOT}
              inputRtRef = {inTSEGNKFOT}
              inputNxRef = {inRET}
            />
          </Stack>
        </Box>
        <br />
        <font color='red'>{ alert }</font>
        <Stack direction='row' spacing={2}>
          <font color='black'>{ 'F9:中止　F5:終了　F1:実納品日変更　Up:前項目　Down:次項目　　　　　　　　　　　　　　　　　　　　　　' }</font>
          <NumericFormat  //確認
            id={'ret'}
            inputRef={inRET}                    //項目名定義？
            thousandSeparator={false}           //1000単位の区切り
            color='success'
            customInput={TextField}             //textfieldにreact-number-formatの機能を追加
            size='small'                        //textboxの全体サイズ
            label='確認'                         //textboxの左上に表示するラベル
            variant='outlined'                  //枠の表示方法
            fixedDecimalScale={false}            //小数点On/Off
            InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
            sx={Object.assign({},cssInputOutlineRetSetwidth(styles.cssInputOutline))}                 //textboxの幅
            allowLeadingZeros                   //前ゼロ表示 on
            disabled={false}                    //使用可／不可
            onKeyDown={(e) => onKeyDown(e.key,inRET,inTSEBAKFOT,inRET)}
            onFocus={(e) => onFocus_ret()}
            inputProps={{ autoComplete: 'off',maxLength: 0,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
            />
        </Stack>
      </Paper>
    </Grid>
  );
};

export default TCSA0100;