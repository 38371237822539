import React, { useEffect, useRef ,useState ,useContext} from 'react';
import { UserContext } from './App';
import './CustomFormTCSH0110.css'; // スタイル用のCSSをインポート

function CustomFormTCSH0110(props) {
  const {state, dispatch} = useContext(UserContext);

  const backButtonRef = useRef(null);
  const clearButtonRef = useRef(null);
  const okButtonRef = useRef(null);
  const okButtonRef2 = useRef(null);
  const returnFocus = useRef(null);
  // console.log(props.message04);
  const [selected01, setSelected01] = useState('');
  const [selected03, setSelected03] = useState('');
  const [selected04, setSelected04] = useState('');
  const [selected05, setSelected05] = useState('');
  const [selected06, setSelected06] = useState('');
  const [selected07, setSelected07] = useState('');
  const [selected08, setSelected08] = useState('');

  const inJAN1 = useRef(null);    // スキャンコード
  const inJotai = useRef(null);   // 自動発注状態
  const inChnry = useRef(null);   // 最低陳列量
  const inHacsu = useRef(null);   // 最低発注数
  const inTnpzik = useRef(null);  // 店舗在庫
  // エラーチェック用 0:エラーなし, 1:未チェック, 2:エラー, 9:確認中
  const [chkflg,setChkflg] = useState(0);               // チェックフラグ
  const [regflg,setRegflg] = useState(0);               // チェックフラグ

  //disabled制御用
  const [dissel01, setDisSel01] = useState(false);
  const [dissel04, setDisSel04] = useState(false);
  const [dissel05, setDisSel05] = useState(false);
  const [dissel06, setDisSel06] = useState(false);
  const [dissel07, setDisSel07] = useState(false);
  const [disselokButton1, setDisSelokButton1] = useState(false);
  const [disselokButton2, setDisSelokButton2] = useState(false);
  //S SK-0014-003 2024/6/18           TCI)M.K【非活性ボタンの色変更】
  const [disselclearButton, setDisSelclearButton] = useState(false);
  //E SK-0014-003
  const [visselokButton2, setVisselokButton2] = useState('hidden'); // 表示・非表示
  

  const [StartTime,setStartTime] = useState(0); //開始時間
  var EndTime;                                  //終了時間
  const [Scanflg,setScanflg] = useState(0);     //スキャン判定 0:入力,1:スキャナー
  const [Over8,setOver8] = useState(0);         //8桁以上入力判定(スキャン判定時) 0:判定不要,1:入力チェック

  const rowData ={
    id : 0,                        // 行№
    KSACDE : state.KSACDES,        // 会社コード
    MISE : state.MISES,            // 店コード
    BMNCDE : state.BMNCDE,         // 部門コード
    ASTCDE : '000',                // ＡＵ
    JANCDE : '',                   // ＪＡＮコード(スキャンコード)
    SHNMEIKN : '',                 // 商品名
    KIKAKUKN : '',                 // 規格
    SUU : 0,                       // 数量(数字)
    SUU2 : '',                     // ◇数量2(文字列)
    GNT : 0,                       // 原単価(数字)
    GNT2 : '',                     // ◇原単価2(文字列)
    GNKKIN : 0,                    // 原価金額
    BTN : 0,                       // 売単価(数字)
    BTN2 : '',                     // ◇売単価2(文字列)
    BAKKIN : 0,                    // 売価金額
    SIRCDE : '',                   // 仕入先(取引先)コード
    SIRMEIK : '',                  // 仕入先(取引先)名
    EOSCDE : '',                   // ＥＯＳコード
    EOSCDE1 : '',                  // ＥＯＳコード１便
    EOSCDE2 : '',                  // ＥＯＳコード２便
    EOSCDE3 : '',                  // ＥＯＳコード３便
    EOSCDE4 : '',                  // ＥＯＳコード４便
    TAGASTCDE : '',                // タグＡＵ
    TAGLINCDE : '',                // タグライン
    TAGCLSCDE : '',                // タグクラス
    TAGSZN : '',                   // タグシーズン
    TAGSZNNM : '',                 // タグシーズン名カナ
    TAGITM : '',                   // タグアイテム
    TRIHBN : '',                   // 取引先品番
    TAGSIZ : '',                   // サイズコード
    TAGSIZMEI : '',                // サイズ名カナ
    TAGCOLCDE : '',                // カラーコード
    TAGCOLMEI : '',                // カラー名カナ
    IRYO_FLG : '0',                // 衣料フラグ
    IN_CD_KBN : '',                // 入力コード区分
    HTTSTD : '',                   // 発注開始日
    HTTEND : '',                   // 発注終了日
    HTTTNICD : '',                 // 発注単位CD 
    HTTTNI : '',                   // 発注単位
    IRISUU : '',                   // 入数
    SYMNSU : '',                   // 賞味日数
    TOK_GNT : '',                  // 特売原価
    TOK_BTN : '',                  // 特売売価
    TEINOK1 : '',                  // 定番納期１
    TEINOK2 : '',                  // 定番納期２
    TEINOK3 : '',                  // 定番納期３
    TEINOK4 : '',                  // 定番納期４
    TEINOK5 : '',                  // 定番納期５
    TEINOK6 : '',                  // 定番納期６
    TEINOK7 : '',                  // 定番納期７
    TOKNOK1 : '',                  // 特売納期１
    TOKNOK2 : '',                  // 特売納期２
    TOKNOK3 : '',                  // 特売納期３
    TOKNOK4 : '',                  // 特売納期４
    TOKNOK5 : '',                  // 特売納期５
    TOKNOK6 : '',                  // 特売納期６
    TOKNOK7 : '',                  // 特売納期７
    NEIRI : '',                    // 値入率＝（売価-原価）÷売価×100
  }
  const [rowItem,setRowItem] = useState(rowData);
    
  useEffect(() => {
    // 初期値移送
    console.log('初期値移送');
    console.log(props.message04);
    setSelected01(props.message01);
    setSelected03(props.message03);
    setSelected04(props.message04);
    setSelected05(props.message05);
    setSelected06(props.message06);
    setSelected07(props.message07);
    setSelected08(props.message08);

    // アラートが表示されたときにOKボタンにフォーカスをセットする
    if (props.isVisible) {
      returnFocus.current = document.activeElement;
      console.log('returnFocus_セット:',returnFocus);
      //okButtonRef.current.focus();

    } else {
      // アラートが非表示になったときに元の場所にフォーカスを戻す
      console.log('returnFocus_非表示:',returnFocus);
      returnFocus.current && returnFocus.current.focus();
    }

    // 表示・非表示
    if (props.message04 === '02' | props.message04 === '04'){
      setDisSel05(true);
      setDisSel06(true);
      setDisSel07(true);
      setDisSelokButton1(true);   // 2024/06/04 登録ボタン非表示
      setDisSelokButton2(true);   // 2024/06/04 登録ボタン非表示
    }
    if (props.callergamen === 'TCSH0100' || props.callergamen === 'TCSH0130'){
      setDisSel01(true);
      //S SK-0074-001 2024/9/9           TCI)M.K【課題No.100：シノプス初期カーソルを在庫にする】
      // console.log('陳列量フォーカス:',inChnry);
      // inChnry.current.focus();
      setTimeout(() => {
        inTnpzik.current.select();
      },0);
      //E SK-0074-001
      //S SK-0014-004 2024/6/18           TCI)M.K【非活性ボタンの色変更】
      setDisSelclearButton(true);
      //E SK-0014-004
    }
    else{
      console.log('JANフォーカス:',inJAN1);
      inJAN1.current.focus();
    }
    if (props.callergamen === 'TCSH0130'){
      // setDisSelokButton2(false);
      setVisselokButton2('visible');
    }

  }, [props.isVisible]);

  useEffect (()=>{
    if(Over8 === 1){
      setTimeout(() => {
        console.log(`８桁タイマー処理:`+Over8+'/'+inJAN1.current.value.length);
        if (Over8 === 1 && inJAN1.current.value.length === 8)
        {
          console.log(`８桁処理開始`);
          onKeyDown_JAN1(inJAN1,inJotai);
          if (chkflg === 0){
            inJotai.current.focus();   //次の項目に遷移
          }
        }
      },200);
    }
  }, [Over8]);

  // 自動発注状態変更時の表示・非表示
  useEffect (()=>{
    console.log(`自動発注状態変更を検知:`,selected04);
    if (selected04 === '02' | selected04 === '04'){
      setDisSel04(true);
      setDisSel05(true);
      setDisSel06(true);
      setDisSel07(true);
      setDisSelokButton1(true);   // 2024/06/04 登録ボタン非表示
      setDisSelokButton2(true);   // 2024/06/04 登録ボタン非表示
      // TCSH0100からの起動の場合、trueにしたら、初期化する
      if (props.callergamen === 'TCSH0100'){
        setSelected05(props.message05);
        setSelected06(props.message06);
        setSelected07(props.message07);
      }      
    }else{
      setDisSel04(false);
      setDisSel05(false);
      setDisSel06(false);
      setDisSel07(false);
      setDisSelokButton1(false);   // 2024/06/04 登録ボタン非表示
      setDisSelokButton2(false);   // 2024/06/04 登録ボタン非表示
    }
  }, [selected04]);


  // シノプス情報初期化
  const clear_sinops = () => {
    setSelected03('');    // 商品名
    setSelected04('01');  // 自動発注状態
    setSelected05('-');   // 基準在庫
    setSelected06('-');   // 最低発注数
    setSelected07('-');   // 計算在庫
    setSelected08('-');   // 商品ランク
    setDisSel04(false);
    setDisSel05(false);
    setDisSel06(false);
    setDisSel07(false);
  }

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    // console.log(key);
    // console.log('id:',inputRef.current.id);// inputRef.current.idが拾えない為、コメント
    switch (key) {
      case 'Enter':
        switch (inputRef.current.id) {
          case 'JAN1':      // ＪＡＮ１
            clear_sinops();
            await onKeyDown_JAN1(inputRef);
            break;
          case 'select':    // 自動発注状態
            break;
          case 'Chnry':     // 最低陳列量
            break;
          case 'Hacsu':     // 最低発注数
            break;
          case 'Tnpzik':    // 店舗在庫
            break;
          default:
            break;
        }
        console.log('onKeyDown chkflg:',chkflg);
        setRegflg(0);
        if (chkflg === 0){
          inputNxRef.current.focus();   //次の項目に遷移
        }
        else{
          inputNxRef.current.focus();   //次の項目に遷移
          inputRef.current.focus();
        }
        
        break;
      case 'Home':
        break;
      case 'ArrowUp':
        break;
      case 'ArrowDown':
        break;
      default:
        break;
    }
  };  
  
  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      okButtonRef.current.focus();
    }
  };

  // 親コンポーネントから受け取った関数を使って、inputの値を渡す
  const handleChange = (event) => {
    // 表示・非表示
    // if (event.target.value === '02' | event.target.value === '04'){
    //   setDisSel04(true);
    //   setDisSel05(true);
    //   setDisSel06(true);
    //   setDisSel07(true);
    //   // TCSH0100からの起動の場合、trueにしたら、初期化する
    //   if (props.callergamen === 'TCSH0100'){
    //     setSelected05(props.message05);
    //     setSelected06(props.message06);
    //     setSelected07(props.message07);
    //   }
    // }else{
    //   setDisSel04(false);
    //   setDisSel05(false);
    //   setDisSel06(false);
    //   setDisSel07(false);
    // }
    if (event.target.value === '02' | event.target.value === '04'){
      window.alert('対象外・本部停止は選択出来ません'); 
      return;
    }else{setSelected04(event.target.value);}
    const value = event.target.value;
    console.log('親コンポーネントから受け取った',value);
    props.handleValueChange04(value);
  };
  const handleInputChange01 = (event) => {
    setSelected01(event.target.value);
    const value = event.target.value;
    props.handleValueChange01(value);
  };
  const handleInputChange05 = (event) => {
    setSelected05(event.target.value);
    const value = event.target.value;
    props.handleValueChange05(value);
  };
  const handleInputChange06 = (event) => {
    setSelected06(event.target.value);
    const value = event.target.value;
    props.handleValueChange06(value);
  };
  const handleInputChange07 = (event) => {
    setSelected07(event.target.value);
    const value = event.target.value;
    props.handleValueChange07(value);
  };

  function validateNumberInput (event)  {
    var input = event.target;
    input.value = input.value.replace(/[^0-9]/g, '');
  }


  // 再入力ボタン　押下
  const onClick_FooterButton_Clear = async () => {
    console.log('モーダル再入力ボタン　押下');
    // メモリクリア
        
    setChkflg(0);

    // 画面クリア
    if (props.callergamen === 'TCSH0100'){
    }else{
      setSelected01(props.message01);
      setSelected03(props.message03);    // 商品名
    }
    setSelected04(props.message04);
    setSelected05(props.message05);
    setSelected06(props.message06);
    setSelected07(props.message07);
    setSelected08(props.message08);

    // 表示・非表示
    if (props.message04 === '02' | props.message04 === '04'){
      setDisSel04(true);
      setDisSel05(true);
      setDisSel06(true);
      setDisSel07(true);
      setDisSelokButton1(true);   // 2024/06/04 登録ボタン非表示
      setDisSelokButton2(true);   // 2024/06/04 登録ボタン非表示
    }else{
      setDisSel04(false);
      setDisSel05(false);
      setDisSel06(false);
      setDisSel07(false);
      setDisSelokButton1(false);   // 2024/06/04 登録ボタン非表示
      setDisSelokButton2(false);   // 2024/06/04 登録ボタン非表示
    }
    if (props.callergamen === 'TCSH0100' || props.callergamen === 'TCSH0130'){
      setDisSel01(true);
      console.log('陳列量フォーカス:',inChnry);
      inChnry.current.focus();
    }
    else{
      console.log('JANフォーカス:',inJAN1);
      inJAN1.current.focus();
    }
  }
    
  const onChangeJAN1 = async (e) => {

    if (e.target.value.length === 0 || e.target.value.length === 1)
    {
      setStartTime(performance.now()); //詳細なミリ秒数
      console.log(`計測スタート:${e.target.value.length} / ${StartTime}`);
      // setChkJAN1('0');
      await setOver8(0);
      setScanflg(0);
    }else
    {
      EndTime = performance.now(); //詳細なミリ秒数
      let elapsedTime = (EndTime - StartTime) * 1000; // マイクロ秒単位に変換
      if (isNaN(elapsedTime))
      {
        console.log(`NaN理由: ${StartTime} / ${Math.floor(EndTime)} マイクロ秒`);
      }else{
        console.log(`経過時間: ${e.target.value.length} / ${Math.floor(elapsedTime)} マイクロ秒`);
      }
      //6桁時スキャン使用判定する(400ミリ秒で判定)
      if (e.target.value.length === 5)
      {
        if (elapsedTime<400000)
        {
          console.log(`５桁スキャン判定`);
          setScanflg(1);
        }
      }
      
      if (e.target.value.length === 8 && Scanflg ===1)
      {
        setOver8(1);
        console.log(`８桁処理`);
      }
      if (e.target.value.length === 9 && Scanflg ===1)
      {
        console.log(`８桁解除`);
        setOver8(0);
      }
      if (e.target.value.length === 13)
      {
        setOver8(0);
        setScanflg(0);
        console.log(`１３桁処理開始: ${Math.floor(elapsedTime)} マイクロ秒`);
        console.log('JAN:'+e.target.value+'|'+e.target.defaultValue);
        if (elapsedTime<1000000 && e.target.value !== e.target.defaultValue)
        {
          await onKeyDown_JAN1(inJAN1,inJotai);
          if (chkflg === 0){
            inJotai.current.focus();   //次の項目に遷移
          }
        }
        // await onKeyDown_JAN1(inJAN1,inJotai);
        
      }
    }
  }

  // 自動発注データ検索
  const onKeyDown_JAN1 = async (inputRef, inputNxRef) => {
    console.log('自動発注データ検索開始');
    const row = await JSON.parse(JSON.stringify(rowItem));
    // チェック
    let Jancde = '';
    // ＪＡＮ１入力値の前0詰め
    // Jancde = selected01;
    // if(selected01.length === 7){
    //   Jancde = selected01.padStart(8, '0');
    // }
    // if(9 <= selected01.length && selected01.length <= 12){
    //   Jancde = selected01.padStart(13, '0');
    // }
    Jancde = inputRef.current.value;
    if(Jancde.length === 7){
      Jancde = Jancde.padStart(8, '0');
    }
    if(9 <= Jancde.length && Jancde.length <= 12){
      Jancde = Jancde.padStart(13, '0');
    }
    console.log('スキャンコード'+Jancde);
    const jan1cd = Jancde.substring(0, 2);

    //コード体系チェック
    if (Jancde.length == 13 && (
      ((jan1cd === '28' ||jan1cd === '29') && state.KSACDES ==='001')
    ||((jan1cd === '26' ||jan1cd === '27') && state.KSACDES ==='005')
    )){
      window.alert('入力されたコードに誤りが有ります'); 
      setChkflg(2);
      return;
    }

    let shohinMaster = undefined;
    // 8桁の場合、商品マスタ（基本）にEOSとしてデータが存在するかチェック。
    if(Jancde.length === 8){
      console.log('８桁検索開始:%o');
      const getShohinMaster = await fetch('/TCSH0100/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({row:rowItem, EOSCDE:Jancde })});
      shohinMaster = await getShohinMaster.json();

      if(shohinMaster.ret.length !== 0) {
        row.IN_CD_KBN = '2';
      }
    };

    // 8、12、13 桁の場合、商品マスタ（基本）にJANとしてデータが存在するかチェック。
    if(row.IN_CD_KBN !== '2' &&
      (Jancde.length === 8 || Jancde.length === 12 || Jancde.length === 13)) {
        console.log('８桁以外検索:%o',row);
        console.log('jancde:'+Jancde);
        const getShohinMaster = await fetch('/TCSH0100/getShohinMaster_JanCode',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({row:rowItem, JANCDEX:Jancde })});
        shohinMaster = await getShohinMaster.json();
        console.log('shohinMaster:%o',shohinMaster);

      if(shohinMaster.ret.length !== 0) {
        row.IN_CD_KBN = '1';
      }
    }

    if(!shohinMaster) {
      window.alert('入力されたコードに誤りが有ります'); 
      setChkflg(2);
      return;
    }else{
      // setSelected03(shohinMaster.ret[0].SHNMEIKN);           // 商品名
      setSelected03(shohinMaster.ret[0].SHNMEIKN 
        + ' 入数' + shohinMaster.ret[0].IRISUU);                // 商品名 + 入数
    }

    // 2024/06/05 1289部門・3599部門考慮漏れ対応
    // 複数部門商品対応：複数ヒットする可能性が有る為、何レコード目に有るか確認する
    let line = 0;
    for(const key in shohinMaster.ret) {
      //S SK-0033-011 2024/7/3           TCI)M.K【課題No.69：集計用部門コード認識誤り】
      // if(state.BMNCDE  === '3599'||state.BMNCDE  === '3699'){
      if(state.BMNCDE  === '3599'||state.BMNCDE  === '3699'||state.BMNCDE  === '1289'){
      //E SK-0033-011
        // console.log('(1)3599or3699の為、グループコードチェック:%o',state.GRPCDE);
        // 部門名取得
        const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[0].BMNCDE, ASTCDE:'XXX' })});
        const j_ItemName = await getItemName.json();
        console.log('(1)3599or3699or1289の為、グループコードチェック:%o',j_ItemName.GRPCDE);
        if(state.GRPCDE !== j_ItemName.GRPCDE){
          line = -1
        }else{
          line = key
          break;
        }
      //S SK-0033-012 2024/7/3           TCI)M.K【課題No.69：集計用部門コード認識誤り】
      // }else if(state.BMNCDE  === '1289'){   // 1999⇒1289に変更（2024/04/15）
      }else if(state.BMNCDE  === '1999'){
      //E SK-0033-012
        // console.log('(2)1289の為、事業部コード１桁チェック:%o',state.GRPCDE);
        // 部門名取得
        const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[0].BMNCDE, ASTCDE:'XXX' })});
        const j_ItemName = await getItemName.json();
        console.log('(2)1999の為、事業部コード１桁チェック:%o',j_ItemName.JGBCDE);
        if(state.GRPCDE !== j_ItemName.JGBCDE){
          line = -1
        }else{
          line = key
          break;
        }
      }else{
        const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:shohinMaster.ret[key].BMNCDE, ASTCDE:'XXX' })});
        const j_ItemName = await getItemName.json();
        console.log('(3)それ以外の為、商品マスタ.部門コード全桁チェック:%o',state.BMNCDE,shohinMaster.ret[key].BMNCDE);
        if(state.BMNCDE  !== shohinMaster.ret[key].BMNCDE) {
          line = -1
        }else{
          line = key
          break;
        }
      }
    }
    console.log('errflg%o',line);
    if (line < 0)
    {
      window.alert('入力されたＪＡＮ１は部門が異なります'); 
      setChkflg(2);
      return;
    }else{
    }      

    // 自動発注データ検索
    const getJdhdat = await fetch('/TCSH0100/getJdhdat',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        KSACDE:state.KSACDES
        ,MISE:state.MISES
        // ,BMNCDE:state.BMNCDE
        ,BMNCDE:state.BMNCDE === '1289' ? '' :
                state.BMNCDE === '3599' ? '' : state.BMNCDE
        // ,Jancde:selected01
        ,Jancde:Jancde
        //S SK-0041-004 2024/7/9           TCI)M.K【レスポンス対応（商品ID）】
        ,SHNID:shohinMaster.ret[0].SHNID
        //E SK-0041-004
      })});
    const Jdhdat = await getJdhdat.json();
    let kjn_zai = '-';
    let lowhttsuu = '-';
    let cal_zai = '-';
    if(Jdhdat.CNT !== 0) {
      console.log(Jdhdat);
      setSelected08(Jdhdat.ret[0].SHNRANK);                  // 商品ランク
      if(Number(Jdhdat.ret[0].HENYMD) === 0) {
        setSelected04(Jdhdat.ret[0].JDHTTST);                // 自動発注状態
        // setSelected05(Number(Jdhdat.ret[0].KJN_ZAI));        // 基準在庫
        // setSelected06(Number(Jdhdat.ret[0].LOWHTTSUU));      // 最低発注数
        // setSelected07(Number(Jdhdat.ret[0].CAL_ZAI));        // 店舗在庫
        if(Jdhdat.ret[0].KJN_ZAI !== ''){kjn_zai = Number(Jdhdat.ret[0].KJN_ZAI)}
        if(Jdhdat.ret[0].LOWHTTSUU !== ''){lowhttsuu = Number(Jdhdat.ret[0].LOWHTTSUU)}
        if(Jdhdat.ret[0].CAL_ZAI !== ''){cal_zai = Number(Jdhdat.ret[0].CAL_ZAI)}
        setSelected05(kjn_zai);                              // 基準在庫
        setSelected06(lowhttsuu);                            // 最低発注数
        setSelected07(cal_zai);                              // 店舗在庫
        //更新に必要だから検索結果を一旦返却する
        props.handleValueChange04(Jdhdat.ret[0].JDHTTST);
        props.handleValueChange05(Jdhdat.ret[0].KJN_ZAI);
        props.handleValueChange06(Jdhdat.ret[0].LOWHTTSUU);
        props.handleValueChange07(Jdhdat.ret[0].CAL_ZAI);
      }else{
        console.log('変更日≠0:'+Jdhdat.ret[0].HENYMD);
        setSelected04(Jdhdat.ret[0].HEN_JDHTTST);            // 自動発注状態
        // setSelected05(Number(Jdhdat.ret[0].HEN_KJN_ZAI));    // 基準在庫
        // setSelected06(Number(Jdhdat.ret[0].HEN_LOWHTTSUU));  // 最低発注数
        // setSelected07(Number(Jdhdat.ret[0].HEN_CAL_ZAI));    // 店舗在庫
        if(Jdhdat.ret[0].HEN_KJN_ZAI !== ''){kjn_zai = Number(Jdhdat.ret[0].HEN_KJN_ZAI)}
        if(Jdhdat.ret[0].HEN_LOWHTTSUU !== ''){lowhttsuu = Number(Jdhdat.ret[0].HEN_LOWHTTSUU)}
        //S SK-0051-004 2024/7/24           TCI)M.K【課題No.77：在庫以外の修正は在庫をリアルタイム更新】
        // if(Jdhdat.ret[0].HEN_CAL_ZAI !== ''){cal_zai = Number(Jdhdat.ret[0].HEN_CAL_ZAI)}
        if(Jdhdat.ret[0].HEN_CAL_ZAI !== ''){
          if(Jdhdat.ret[0].CAL_ZAI_ENTTIM !== ''){
            cal_zai = Number(Jdhdat.ret[0].HEN_CAL_ZAI);
          }else{
            cal_zai = Number(Jdhdat.ret[0].CAL_ZAI);
          }
        }
        //E SK-0051-004
        setSelected05(kjn_zai);                              // 基準在庫
        setSelected06(lowhttsuu);                            // 最低発注数
        setSelected07(cal_zai);                              // 店舗在庫
        //更新に必要だから検索結果を一旦返却する
        props.handleValueChange04(Jdhdat.ret[0].HEN_JDHTTST);
        props.handleValueChange05(Jdhdat.ret[0].HEN_KJN_ZAI);
        props.handleValueChange06(Jdhdat.ret[0].HEN_LOWHTTSUU);
        props.handleValueChange07(Jdhdat.ret[0].HEN_CAL_ZAI);
      }
    }
    //setChkflg(0);
  }

  const onClick_Reg  = async() => {
    console.log('[シノプス]OKが押されました',selected01,'/',chkflg,'/',regflg)
    if (selected01 !== ''){
      // setRegflg(1); // regflgの判定必要無い★未使用★後で確認する
      // if (chkflg === 0 && regflg === 1){
      if (chkflg === 0){
        props.onReg(); 
        //returnFocus.current && returnFocus.current.focus();
        dateClear();
      }
      else{
        setRegflg(1);
      }
    }
  }

  const onClick_Reg2  = async() => {
    console.log('[シノプス]登録&発注が押されました',selected01,'/',chkflg,'/',regflg)
    if (selected01 !== ''){
      // setRegflg(1); // regflgの判定必要無い★未使用★後で確認する
      // if (chkflg === 0 && regflg === 1){
      if (chkflg === 0){
        props.onReg(2); 
        //returnFocus.current && returnFocus.current.focus();
        dateClear();
      }
      else{
        setRegflg(1);
      }
    }
  }

  const dateClear = () =>{
    setSelected01('');
    setSelected03('');
    setSelected04('');
    setSelected05('');
    setSelected06('');
    setSelected07('');
    setSelected08('');
    if (props.callergamen !== 'TCSH0100' && props.callergamen !== 'TCSH0130'){
      props.handleValueChange01('');
    }
    props.handleValueChange04('');
    props.handleValueChange05('');
    props.handleValueChange06('');
    props.handleValueChange07('');
    setChkflg(0);               // チェックフラグ
    setRegflg(0);               // チェックフラグ
  }
  return (
    <div className={`vba-message-box-0110-overlay ${props.isVisible ? 'show' : 'hide'}`}>
      <div className={`vba-message-box-0110 ${props.isVisible ? 'show' : 'hide'}`}>
        <div className='vba-message-box-0110-content'>
          <div className='vba-message-box-0110-text'>
            <p className='p-title-0110'>自動発注メンテナンス</p>
              {/* <input type='text' name='name' placeholder={props.message01} class='textbox'></input> */}
            {/* <div class='form__item'>
              <dt class='form__item-head'><label for='number'>スキャンコード</label></dt>
              <dd class='form__item-body'><input type='text' name='name' placeholder={props.message01} class='textbox'></input></dd>
            </div> */}
            <table class='design01-0110'>
              <tr>
                <th>スキャンコード</th>
                <td><input type='text'
                  // type='text' 
                  id={'JAN1'}
                  ref={inJAN1} 
                  pattern="^[+-]?([1-9][0-9]*|0)$"
                  maxlength="13"
                  // inputRef={inJAN1}                   //項目名定義？
                  value={selected01} 
                  onChange={(e)=>{
                    handleInputChange01(e);
                    onChangeJAN1(e);
                  }} 
                  onInput={validateNumberInput}
                  name='name01' 
                  class='textbox-0110'
                  autoComplete='off'
                  disabled={dissel01}                    //使用可／不可
                  onKeyDown={(e) => {
                    onKeyDown(e.key,inJAN1,inJAN1,inJotai);
                  }}
                ></input></td>
                </tr>
            </table>
            <p className='p-text-0110'></p>
            <table class='design02-0110'>
              {/* <tr>
                <th>JAN2</th>
                <td>{props.message02}</td>
              </tr> */}
              <tr>
                <th>商品名</th>
                {/* <td>{props.message03}</td> */}
                <td>{selected03}</td>
              </tr>
            </table>
            <p className='p-text-0110'></p>
            <table class='design01-0110'>
              <tr>
                <th>自動発注状態</th>
                {/* <td><input type='text' onChange={handleInputChange04} name='name04' placeholder={props.message04} class='textbox'></input></td> */}
                <td>
                <select 
                  ref={inJotai}
                  name='name04' 
                  id='select' 
                  onChange={handleChange} 
                  value={selected04} 
                  class='textbox-0110'
                  disabled={dissel04}                    //使用可／不可
                  onKeyDown={(e) => {
                    onKeyDown(e.key,inJotai,inJAN1,inChnry);
                  }}
                  >
                  <option value='01'>対象</option>
                  <option value='02'>対象外</option>
                  <option value='03'>店舗停止</option>
                  <option value='04'>本部停止</option>
                </select>            
                </td>
              </tr>
              <tr>
                <th>最低陳列量</th>
                <td><input 
                // type='text' 
                type='tel' 
                pattern="^[+-]?([1-9][0-9]*|0)$"
                maxlength='5'
                id='Chnry' 
                ref={inChnry}
                value={selected05} 
                onChange={handleInputChange05} 
                onInput={validateNumberInput}
                name='name05' 
                class='textbox-0110'
                autoComplete='off'
                disabled={dissel05}                    //使用可／不可
                onKeyDown={(e) => {
                  onKeyDown(e.key,inChnry,inJAN1,inHacsu);
                }}
                onFocus={(e) => e.target.select()}
                ></input></td>
              </tr>
              <tr>
                <th>最低発注数</th>
                <td><input 
                // type='text' 
                type='tel' 
                pattern="^[+-]?([1-9][0-9]*|0)$"
                maxlength='5'
                id='Hacsu' 
                ref={inHacsu}
                value={selected06} 
                onChange={handleInputChange06} 
                onInput={validateNumberInput}
                name='name06' 
                class='textbox-0110'
                autoComplete='off'
                disabled={dissel06}                    //使用可／不可
                onKeyDown={(e) => {
                  onKeyDown(e.key,inHacsu,inChnry,inTnpzik);
                }}
                onFocus={(e) => e.target.select()}
                ></input></td>
              </tr>
              <tr>
                <th>店舗在庫</th>
                <td><input 
                // type='text' 
                type='tel' 
                pattern="^[+-]?([1-9][0-9]*|0)$"
                maxlength='5'
                id='Tnpzik' 
                ref={inTnpzik}
                value={selected07} 
                onChange={handleInputChange07} 
                onInput={validateNumberInput}
                name='name07' 
                class='textbox-0110'
                autoComplete='off'
                disabled={dissel07}                    //使用可／不可
                onKeyDown={(e) => {
                  onKeyDown(e.key,inTnpzik,inChnry,okButtonRef);
                }}
                onFocus={(e) => e.target.select()}
                ></input></td>
              </tr>
            </table>
            <p className='p-text-0110'></p>
            <table class='design03-0110'>
              <tr>
                <th>ランク</th>
                {/* <td>{props.message08}</td> */}
                <td>{selected08}</td>
              </tr>
            </table>
          </div>
        </div>
        <div className='vba-message-box-0110-buttons' onKeyDown={handleKeyDown}>
          <button ref={backButtonRef} 
          onClick={() => { props.onClose(); returnFocus.current && returnFocus.current.focus(); }}>戻る
          </button>　
          <button ref={clearButtonRef} 
          //S SK-0014-002 2024/6/18           TCI)M.K【非活性ボタンの色変更】
          disabled={disselclearButton}                    //使用可／不可
          //E SK-0014-002
          onClick={(e) => onClick_FooterButton_Clear()}
          >再入力
          </button>　
          <button ref={okButtonRef} 
          /*onClick={() => { 
            props.onReg(); 
          returnFocus.current && returnFocus.current.focus(); }}>登録*/
          disabled={disselokButton1}                       //使用可／不可
          onClick={(e) => {onClick_Reg()}}>登録
          </button>
        </div>　
        <div className='vba-message-box-0110-buttons' onKeyDown={handleKeyDown}>
          <button ref={okButtonRef2} 
            disabled={disselokButton2}                    //使用可／不可
            style={{visibility:visselokButton2}}          //表示／非表示
            onClick={(e) => {onClick_Reg2()}}>登録&発注
          </button>
        </div>
      </div>
    </div>
  );
}

export default CustomFormTCSH0110;