import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, Button,Stack, Grid, Paper, Typography, InputLabel, MenuItem, FormControl, TextField, Select } from "@mui/material";
import { NumericFormat } from 'react-number-format';  //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
//S SK-0050-004 2024/7/23           TCI)M.K【要望：ログイン制御】
import { LOCAL_CONSTANT } from './constants.ts';
//E SK-0050-004

const commonStyles = {
  bgcolor: 'background.paper',
  m: 1,
  border: 0,
  width: '40px',
  height: '40px',
};

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSH0010 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [ksacde, setKsacde] = useState('');
  const [kmise, setKmise] = useState('');
  const [ksanm, setKsanm] = useState('');
  const [misenm, setMisenm] = useState('');
  const [alert, setAlert] = useState('店コードを設定して下さい。');//TCGM0002：
  const [K_KSACDE,setK_KSACD] = useState('');
  const [K_MISE,setK_MISE] = useState('');
  const [lK_FLG,setlK_FLG] = useState('');
  // const [keyDisabled,setKeyDisabled] = useState(false); // 多重防止

  const inKSACDE = useRef(null);
  const inMISE = useRef(null);

  const buttonRef = useRef(null);

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    // 多重防止
    // if (!keyDisabled) {
    //   setKeyDisabled(true);
    // } else {
    //   return;
    // }

    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      // if (state.MVEMGAMEN==='')
      // {
      //   navigate('/');
      // }else{
      //   navigate('/TCSA0003');
      // }      
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  const handleChange = (event, SelectChangeEvent) => {
    setKsacde(event.target.value);
    switch(event.target.value)
    {
      case '001':
        setKsanm('オークワ');
        break;
      case '003':
        setKsanm('オーク');
        break;
      case '005':
        setKsanm('パーティハウス');
        break;
      case '008':
        setKsanm('オー・エンターテイメント');
        break;
      default:
        break;
    };
    
    inMISE.current.focus();
    inMISE.current.select();
  };

  
  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    
    //テスト用最終納品日
    if (state.KSACDE == '005')
    {
      setKsacde('005');
      setKsanm('パーティハウス');  
    }else{
      setKsacde('001');
      setKsanm('オークワ');  
    }
    //setKmise('308');   // ログイン情報設定
    // setKeyDisabled(false);  // 多重防止
  },[]);

  useEffect(()=>{
    inMISE.current.focus();
  }, [ksacde]);

  useEffect(() => {
    // if (!document.fullscreenElement){
    //   document.documentElement.requestFullscreen();
    // }
    // const keydownHandler = (e) => {
    //   if (e.key === 'F1') {
    //     console.log('F1押下1');
    //     e.preventDefault();
    //     console.log('F1押下2');

    //   }

    //   // if (e.key === 'F5') {
    //   //   console.log('F5押下');
    //   //   e.preventDefault();
    //   //   navigate('/');
    //   // }
    // };

    // console.log('イベントリスナー追加');
    // document.addEventListener('keydown', keydownHandler, { passive: false });

    // console.log('イベントリスナー削除');
    // return () => {
    //   // コンポーネントがアンマウントされる時にイベントリスナーを削除する
    //   document.removeEventListener('keydown', keydownHandler);
    // };  
    // console.log('終わり');

    //イベントリスナーを登録
    // window.addEventListener('keydown', (e) => {
    //     if (e.key==='F1')
    //     {
    //       e.preventDefault();
    //       navigate('/TCSA0001');
    //     }
    //     if (e.key==='F5')
    //     {
    //       e.preventDefault();
    //       navigate('/');
    //     }
    //   }
    // );
  }, []);

  // 次へボタン押下時の処理
  const onClick_FooterButton_Next = async() => {
    let lmisenm = '';
    let errflg = 0;

    // if ( misenm === '')
    // {
      lmisenm = await lst_chk(inMISE);
      // if (lmisenm === 0) //間違い。修正する
      if (lmisenm === 1) 
      {
        return;
      }
    // }else{
      lmisenm  = misenm;
    // }
    // console.log('検索済'+errflg+'|'+lmisenm);
    if (errflg == 0 && lmisenm !== '')
    {
      // console.log('会社S:'+ksacde);
      // console.log('店S:'+kmise);
      // console.log('店名2:'+misenm);
      // console.log('lENT'+K_KSACDE+'|'+K_MISE);
      //let inMVEMGAMEN = '/TCSH0010';
      dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
        KSACDES : ksacde,MISES: kmise,K_KSACDES : K_KSACDE,K_MISES: K_MISE,
        MISEMEI: lmisenm,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
        SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: lK_FLG,SDAY: state.SDAY,BUTTON:state.BUTTON, 
        BMNCDE:state.BMNCDE,BRUMEI:state.BRUMEI,GRPCDE:state.GRPCDE,IRYOFLG:state.IRYOFLG
      });

      //S SK-0050-005 2024/7/24           TCI)M.K【要望：ログイン制御】
      console.log('定数読込み:%o',LOCAL_CONSTANT);
      //S SK-0058-002 2024/8/5           TCI)M.K【要望：ログイン制御 本社所属は対象外】
      // if (LOCAL_CONSTANT.EXCLUSION_CONTROL === 1){
      if (LOCAL_CONSTANT.EXCLUSION_CONTROL === 1 && state.MISE !== '200'){
      //E SK-0058-002
        // 排他制御ONの場合
        // (0)同時接続マスタチェック（同期処理）
        // (1)接続数チェック（同期処理）
        //  接続数がマスタ以上場合、エラー
        //  接続数がマスタより少ない場合、正常
        // (2)正常時、排他制御テーブルに情報を書き込む（レスポンスアップの為、非同期）
        let connection_cnt = LOCAL_CONSTANT.CONNECTION_CNT;
        const getHitMst = await fetch('/TCSH0000/get_HIT_MST',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            KSACDE:state.KSACDE, 
            MISE:inMISE.current.defaultValue, 
            BMNCDE:state.BMNCDE, 
          })});
        const HitMst = await getHitMst.json();
        console.log('HitMst:%o',HitMst);

        if(HitMst.ret.length !== 0){
          connection_cnt = Number(HitMst.ret[0].CON_CNT)
        }

        let HitDat = undefined;
        let HitDat2 = undefined;
        // FLG=1:ﾛｸﾞｲﾝ者以外でﾛｸﾞｲﾝしている数を検索
        const getHitDat = await fetch('/TCSH0000/get_HIT_DAT',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            KSACDE:state.KSACDE, 
            MISE:inMISE.current.defaultValue, 
            BMNCDE:state.BMNCDE, 
            JGNCDE:state.JGNCDE,
            FLG:1,
          })});
          HitDat = await getHitDat.json();
        console.log('HitDat:%o',HitDat);
        
        if(HitDat.ret.length !== 0) {
          if (connection_cnt <= HitDat.ret.length){
            setAlert(state.BMNCDE+'部門での同時ログインは'+connection_cnt+'名までです');
            // setAlert(HitDat.ret[0].JGNMEIKNJ+' さんが '+state.BMNCDE+' 部門でログイン中です');
            return;
          }
        }

        // FLG=2:ﾛｸﾞｲﾝ者が異なる部門でﾛｸﾞｲﾝしていないか検索
        const getHitDat2 = await fetch('/TCSH0000/get_HIT_DAT',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            KSACDE:state.KSACDE, 
            MISE:inMISE.current.defaultValue, 
            BMNCDE:state.BMNCDE, 
            JGNCDE:state.JGNCDE,
            FLG:2,
          })});
          HitDat2 = await getHitDat2.json();
        console.log('HitDat2:%o',HitDat2);

        if(HitDat2.ret.length !== 0) {
          setAlert(HitDat2.ret[0].JGNMEIKNJ+' さんが 別の端末でログイン中です ログイン部門：'+HitDat2.ret[0].BMNCDE);
          return;
        }

        const repHitDat = fetch('/TCSH0000/Replace_HIT_DAT',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            KSACDE:state.KSACDE, 
            MISE:inMISE.current.defaultValue, 
            BMNCDE:state.BMNCDE, 
            JGNCDE:state.JGNCDE, 
            JGNMEIKNJ:state.JGNMEIKNJ, 
            PRG_ID:'TCSH0010',
            DELFLG:'0',
          })});
        console.log('repHitDat:%o',repHitDat);
      }
      //E SK-0050-005
      
      if (state.IRYOFLG === '0'){
        navigate('/TCSH0020');
      }else{
        navigate('/TCSH0021');
      }
    }
  }

  // 戻るボタン押下時の処理
  const onClick_FooterButton_Back = async() => {
      navigate('/');
  }

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    //console.log(key);
    switch (key) {
      case "Enter":
        let errflg = 0;
        setAlert('　');
        if (inputRef.current.id == "mise")
        {
          console.log('mise:'+inputRef.current.defaultValue);
          //従業員マスタ店読み込み
          const responseC = await fetch('/TCSH0000/MR_WRKM_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({KSACDE: state.KSACDE,JGNCDE: state.JGNCDE,MISE:'',RCNT:0})});
          const dataC = await responseC.json();
          if (dataC.RCNT >= 1)
          {
            if ( state.MISE != '200' && state.MISE != '700')
            {
              var srcresult = dataC.res.find((v) => v.MISE == inputRef.current.defaultValue);
              if (typeof srcresult === "undefined")
              {
                setAlert('その店コードは処理できません。');//TCGE0005_x1：
                inKSACDE.current.focus();
                inputRef.current.focus();
                errflg = -1;
              }
            }
          }
          //店舗マスタ読み込み
          console.log('会社コード:'+ksacde);
          console.log('mise:'+inputRef.current.defaultValue);
          const responseF = await fetch('/TCSH0000/MR_STR_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({KSACDE: ksacde,MISE: inputRef.current.defaultValue})});
          const dataF = await responseF.json();
          console.log('店舗マスタ読み込み:%o',dataF);
          if (dataF.RCNT >= 1)
          {
            await setMisenm(dataF.MISEMEI);
          }
          else
          {
            setAlert('その店コードは処理できません。');//TCGE0005x_3：
            inKSACDE.current.focus();
            inputRef.current.focus();
            errflg = -1;
          }
          //確認してください！！追加
          if(errflg==0){            
            setAlert('確認してください！！');
            //onClick_FooterButton_Next()
          }
        }

        if (errflg == 0)
        {
          inputNxRef.current.focus();   //次の項目に遷移

        }
        break;
      case "Home":
        break;
      case "ArrowUp":
        break;
      case "ArrowDown":
        break;
      default:
        break;
    }
  };  

  const lst_chk = async (inputRef) => {
    setAlert('　');
    let errflg = 0;
    console.log('mise:'+inputRef.current.defaultValue);
    //従業員マスタ店読み込み
    const responseC = await fetch('/TCSH0000/MR_WRKM_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({KSACDE: state.KSACDE,JGNCDE: state.JGNCDE,MISE:'',RCNT:0})});
    const dataC = await responseC.json();
    if (dataC.RCNT >= 1)
    {
      if ( state.MISE != '200' && state.MISE != '700' )
      {
        var srcresult = dataC.res.find((v) => v.MISE == inputRef.current.defaultValue);
        if (typeof srcresult === "undefined")
        {
          setAlert('その店コードは処理できません。');//TCGE0005_01：
          inputRef.current.focus();
          //S SK-0058-007 2024/8/5           TCI)M.K【要望：ログイン制御 バグFIX】
          // // return 1;
          // errflg = -1;
          errflg = -1;
          return 1;
          //E SK-0058-007
        }
      }
    }

    //店舗マスタ読み込み
    console.log('会社コード:'+ksacde);
    console.log('mise:'+inputRef.current.defaultValue);
    const responseF = await fetch('/TCSH0000/MR_STR_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({KSACDE: ksacde,MISE: inputRef.current.defaultValue})});
    const dataF = await responseF.json();
    console.log('店舗マスタ読み込み:%o',dataF);
    if (dataF.RCNT >= 1)
    {
      await setMisenm(dataF.MISEMEI);
    }
    else
    {
      setAlert('その店コードは処理できません。');//TCGE0005_03：
      inputRef.current.focus();
      errflg = -1;
      return 1;
    }
    //確認してください！！追加
    if(errflg==0){            
      setAlert('確認してください！！');
    }
    return dataF.MISEMEI;
  };


  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  // 確認のWidthを設定する
  const cssInputOutlineRetSetwidth = (obj) => {
    let resObj = obj;
    resObj.width = '50px';
    return resObj;
  }

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
         fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
    cssInputOutlineJotKbn: {
      '> div': {
        minHeight: 'auto !important',
        paddingTop: '.4em',
        paddingBottom: '.3em',
      }
    },
    cssInputSelect: {
      ' label': {
        color: '#000000'
      },
      ' fieldset': {
        borderColor: '#000000'
      },
    },
  }

  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "470px",
          width: "280px",
          m: "20px auto"
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="center"
        >
          {/* 画像の角丸対応 */}
          <Box sx={{  ...commonStyles, borderRadius: '5px',overflow: 'hidden' }}><img src={`${process.env.PUBLIC_URL}/OKUWA_Logo.svg`} alt="Logo" width="40" height="40" /></Box>
          {/* <Avatar sx={{ bgcolor: teal[400] }}>
          </Avatar> */}
          <Typography variant={"h5"} sx={{ m: "30px" }}>
            店舗入力
          </Typography>
        </Grid>

        <br/>
        <Stack direction="row" spacing={2}>
          <FormControl size="small" sx={Object.assign({}, styles.cssInputSelect, { minWidth: 80 })}>
            <InputLabel id="select-label" color='success'>会社CD</InputLabel>
            <Select
                inputRef={inKSACDE}
                color='success'
                defaultValue={'001'}
                labelId="select-label"
                id="select-label"
                className="cssInputOutlineJotKbn" 
                sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 5), styles.cssInputOutlineJotKbn)} 
                value={ksacde}
                label="会社CD"
                onChange={handleChange}
                >
              <MenuItem value={'001'}>001</MenuItem>
              <MenuItem value={'003'}>003</MenuItem>
              <MenuItem value={'005'}>005</MenuItem>
              <MenuItem value={'008'}>008</MenuItem>
            </Select>
          </FormControl>
          <InputLabel variant={'outlined'} sx={{ml:'-5px',mt:'-15px'}}>{ksanm}</InputLabel>
        </Stack>
        <br/>
        <Stack direction="row" spacing={2}>
          <NumericFormat  //店CD
            id={'mise'}
            inputRef={inMISE}                   //項目名定義？
            color='success'
            autoFocus                           //初期カーソル位置設定
            thousandSeparator={false}           //1000単位の区切り           
            customInput={TextField}             //textfieldにreact-number-formatの機能を追加
            size='small'                        //textboxの全体サイズ
            label='店CD'                         //textboxの左上に表示するラベル
            variant='outlined'                  //枠の表示方法
            type='tel'
            inputMode='tel'
            fixedDecimalScale={false}            //小数点On/Off
            decimalScale={0}                    //少数部の桁
            allowNegative={false}               //マイナス入力off
            InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
            sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 3))} 
            //value={dbdata.ZEIRTU}               //初期値
            allowLeadingZeros                   //前ゼロ表示 on
            disabled={false}                    //使用可／不可
            inputProps={{ autoComplete: 'off',maxLength: 3,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
            onKeyDown={(e) => onKeyDown(e.key,inMISE,inMISE,buttonRef)}
            onChange={(event) => {
              setKmise(event.target.value);
              setMisenm('');
              inMISE.current.focus();
            }}
            onFocus={(e) => e.target.select()}
            // onBlur={() => onblur(inMISE)}
            />
          <InputLabel variant={'outlined'} sx={{ml:'-5px',mt:'-15px'}}>{misenm}</InputLabel>
        </Stack>
        <br /><br />
        <Box ml={2} flexDirection="row" justifyContent="flex-end" display="flex" marginTop={'5px'}>
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:50,mr:'150px'}}
              onClick={(e) => onClick_FooterButton_Back()}
              >
              {'戻る'}
            </Button>&nbsp;
          </Box >
          <Box >
            <Button
              ref={buttonRef}
              variant='contained'
              color='success'
              sx={{width:50,mr:'5px'}}
              onClick={(e) => onClick_FooterButton_Next()}
              >
              {'次へ'}
            </Button>
          </Box>
        </Box>
        {/* <p>{ state.usrnm }</p> */}
        <p><font color="red">{ alert }</font></p>
      </Paper>
    </Grid>
  );
};

export default TCSH0010;