import React, { useState,useContext,useEffect,useRef } from 'react'
import { Grid, Paper, Typography, TextField, Stack } from "@mui/material";
import { NumericFormat,PatternFormat } from 'react-number-format';  //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F5キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F9キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSA0004 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('　');
  const [nhnymd, setNhnymd] = useState(''); //実納品日用
  const [nhnymd01, setNhnymd01] = useState(''); //実納品日用１回目メモリ
  const [bin, setBin] = useState('0'); //便（23/09/10初期値0とする）
  const [bgcolor01,setBgcolor01] = useState('#FFFFFF'); //実納品日用 エラー：#f59d9d、ケアーエラー：ffffbe
  const [bgcolor02,setBgcolor02] = useState('#FFFFFF'); //便 エラー：#f59d9d、ケアーエラー：ffffbe
  const [dis,setDis] = useState(true);

  const inNHNYMD = useRef(null);
  const inBIN = useRef(null);
  const inRET = useRef(null);

  const commonStyles = {
    bgcolor: 'background.paper',
    m: 1,
    border: 0,
    width: '40px',
    height: '40px',
  };

  
  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      navigate('/TCSA0003');
    }
    if (event.keyCode === 120) { // F9キーが押された場合の処理
      Gmclr();
      // console.log('x1:%o',inNHNYMD)
      // if (inNHNYMD.current !==null && typeof inNHNYMD.current !== 'undefined')
      // {
        // inNHNYMD.current.focus();
      // }
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  useEffect (()=>{
    // document.documentElement.requestFullscreen();
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    
    console.log('物流フラグ:'+state.K_KSACDES)
    if (state.K_KSACDES != '')
    {
      console.log('物流フラグtrue:'+state.K_KSACDES)
      setDis(false);
    }
  },[]);

  useEffect(() => {
    // document.documentElement.requestFullscreen();
    // イベントリスナーを登録
    // window.addEventListener('keydown', (e) => {
    //   if (e.key==='F5')
    //   {
    //     e.preventDefault();
    //     navigate('/TCSA0003');
    //   }
    //   if (e.key==='F9')
    //   {
    //     e.preventDefault();
    //     Gmclr();
    //     console.log('x:%o',inNHNYMD)
    //     if (inNHNYMD.current !==null && typeof inNHNYMD.current !== 'undefined')
    //     {
    //       inNHNYMD.current.focus();
    //     }

    //     //console.log('遷移:%o',inNHNYMD);
    //   }
    // });
  }, []);

  let mvFlg = 0;  //項目遷移フラグ 

  // 日付存在チェック
  const ckDate = (strYYYYMMDD) =>{  // YYYY/MM/DD ⇒ 2023/09/07 等
    if(!strYYYYMMDD.match(/^\d{4}\/\d{2}\/\d{2}$/)){
        return false;
    }
    var y = strYYYYMMDD.split("/")[0];
    var m = strYYYYMMDD.split("/")[1] - 1;
    var d = strYYYYMMDD.split("/")[2];
    var date = new Date(y,m,d);
    if(date.getFullYear() != y || date.getMonth() != m || date.getDate() != d){
        return false;
    }
    return true;
}

  const Gmclr = () =>{
    setNhnymd('');
    setNhnymd01('');
    // setBin('');
    if (state.K_KSACDES != ''){setBin('1');}else{setBin('0');}
    setTimeout(() => {inNHNYMD.current.focus();},100);
    //エラー背景色クリア
    setBgcolor01('#FFFFFF');
    setBgcolor02('#FFFFFF');
    //エラーメッセージクリア
    setAlert('　');
  }

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    //console.log(key);
    mvFlg = 0;
    switch (key) {
      case "Enter":
        setAlert('　');
        if (inputRef.current.id == 'NHNYMD')  //実納品日チェック
        {
          var strDate = '20'+nhnymd;
          var dtdate = new Date(strDate);
          setBgcolor01('#FFFFFF');
          //日付チェック
          if (ckDate(strDate)===true){
            console.log('実納品日チェック1'+nhnymd01+'|'+strDate);
            if (nhnymd01 == '')   //初回処理
            {
              setNhnymd01(strDate);
              setNhnymd('');
              console.log('初回セット'+nhnymd+' | '+nhnymd01)
              mvFlg = 1;
            }else{
              console.log('日付範囲1');
              if(nhnymd01!=strDate)
              {
                console.log('実納品日チェック2'+nhnymd01+' != '+strDate);
                setNhnymd01(strDate); //1回目入れ替え
                setBgcolor01('#f59d9d');
                // setAlert('TCGE0016:日付入力エラー');//エラーが違う
                setAlert('正しい日付を入力してください。');//TCGE0003
                setNhnymd('');
                inRET.current.focus();
                inputRef.current.focus();
                mvFlg = 1;
              }else{
                console.log('日付範囲2');
                let dt = new Date(state.SDAY);
                let dts = new Date(dt.getFullYear()-1,dt.getMonth(),dt.getDate());
                let dte = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate());
                //dts.setFullYear(dts.getFullYear() - 1);
                console.log('日付'+dts+'<='+dtdate+'<'+dte);
                if (dts < dtdate && dtdate <= dte)
                {}else
                {
                  setBgcolor01('#f59d9d');
                  setAlert('日付入力エラー');//TCGE0016:
                  setNhnymd('');
                  inRET.current.focus();
                  inputRef.current.focus();
                  mvFlg = 1;
                }
              }
            }
          }else{
            setBgcolor01('#f59d9d');
            // setAlert('TCGE0016:日付入力エラー');//エラーが違う
            setAlert('正しい日付を入力してください。');//TCGE0003
            inRET.current.focus();
            inputRef.current.focus();
            mvFlg = 1;
          }
        }
        if (inputRef.current.id == 'bin')
        {
          setBgcolor02('#FFFFFF');
          if (!(bin == '1' || bin == '2'))
          {
            setAlert('便は「１」、「２」以外は入力することはできません。');//TCGE0004:
            setBgcolor02('#f59d9d');
            inRET.current.focus();
            inputRef.current.focus();
            mvFlg = 1;
          }
        }
        if (inputRef.current.id == 'ret')     //確認
        {
          console.log('x:%o',nhnymd)
          if (nhnymd === '')
          {
            inNHNYMD.current.focus();
            return;
          }

          console.log('次画面:TCSA0100');
          dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
          KSACDES : state.KSACDES,MISES: state.MISES,K_KSACDES : state.K_KSACDES,K_MISES: state.K_MISES,
          MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
          SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,NHNYMD: '20'+nhnymd,BIN: bin,K_FLG:state.K_FLG,ETC: state.ETC,SDAY: state.SDAY,BUTTON:state.BUTTON });
          navigate('/TCSA0100');
        }
        console.log('xxxx'+mvFlg);
        if (mvFlg == 0)
        {
          console.log('便項目情報:'+dis);
          if (dis == true)
          {
            inRET.current.focus();
          }else{
            inputNxRef.current.focus();   //次の項目に遷移
          }
        }
        break;
      // case "PageUp":
      //   if (dis == true)
      //   {
      //     inNHNYMD.current.focus();
      //   }else{
      //     inputNxRef.current.focus();   //次の項目に遷移
      //   }
      //   break;
      // case "PageDown":
      //   if (dis == true)
      //   {
      //     inRET.current.focus();
      //   }else{
      //     inputNxRef.current.focus();   //次の項目に遷移
      //   }
      //   break;
      case "ArrowUp":
        break;
      case "ArrowDown":
        break;
      default:
        break;
    }
  };  
  
  //lostfocus
  const onBlur = async(inRef) => {
    setAlert('　');
    if (inRef.current.id == 'NHNYMD')  //実納品日チェック
    {
      var strDate = '20'+nhnymd;
      var dtdate = new Date(strDate);
      setBgcolor01('#FFFFFF');
      //日付チェック
      if (ckDate(strDate)===true){
        console.log('実納品日チェック1'+nhnymd01+'|'+strDate);
        if (nhnymd01 == '')   //初回処理
        {
          setNhnymd01(strDate);
          setNhnymd('');
          console.log('初回セット'+nhnymd+' | '+nhnymd01)
          mvFlg = 1;
        }else{
          console.log('日付範囲1');
          if(nhnymd01!=strDate)
          {
            console.log('実納品日チェック2'+nhnymd01+' != '+strDate);
            setNhnymd01(strDate); //1回目入れ替え
            setBgcolor01('#f59d9d');
            // setAlert('TCGE0016:日付入力エラー');//エラーが違う
            setAlert('正しい日付を入力してください。');//TCGE0003
            setNhnymd('');
            inRET.current.focus();
            inRef.current.focus();
          }else{
            console.log('日付範囲2');
            let dt = new Date(state.SDAY);
            let dts = new Date(dt.getFullYear()-1,dt.getMonth(),dt.getDate());
            let dte = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate());
            //dts.setFullYear(dts.getFullYear() - 1);
            console.log('日付'+dts+'<='+dtdate+'<'+dte);
            //if (dts <= dtdate && dtdate <= dte)//条件が仕様書と異なる
            if (dts < dtdate && dtdate <= dte)
            {}else
            {
              setBgcolor01('#f59d9d');
              setAlert('日付入力エラー');//TCGE0016:
              setNhnymd('');
              inRET.current.focus();
              inRef.current.focus();
            }
          }
        }
      }else{
        setBgcolor01('#f59d9d');
        // setAlert('TCGE0016:日付入力エラー');//エラーが違う
        setAlert('正しい日付を入力してください。');//TCGE0003
        inRET.current.focus();
        inRef.current.focus();
      }
    }
    if (inRef.current.id == 'bin')
    {
      setBgcolor02('#FFFFFF');
      if (!(bin == '1' || bin == '2'))
      {
        setAlert('便は「１」、「２」以外は入力することはできません。');//TCGE0004:
        setBgcolor02('#f59d9d');
        inRET.current.focus();
        inRef.current.focus();
      }
    }
  }

  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  // 確認のWidthを設定する
  const cssInputOutlineRetSetwidth = (obj) => {
    let resObj = obj;
    resObj.width = '50px';
    return resObj;
  }

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
         fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
  }

  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "320px",
          width: "400px",
          m: "20px auto"
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="center"
        >
          <div style={{display:"flex"}}>
          <Typography variant={"h4"} sx={{ m: "10px" }}>
            実納品日入力
          </Typography>
          {/* <Typography variant={"h6"} sx={{ m: "15px" }}>
            {state.JGNMEIKNJ}
          </Typography> */}
          </div>
        </Grid>
        <br/>
        <br/><br/>
        <PatternFormat  //実納品日
          id={'NHNYMD'}
          inputRef={inNHNYMD}                   //項目名定義？
          autoFocus                           //初期カーソル位置設定
          format="##/##/##"
          mask={['Y','Y','M','M','D','D']}
          color='success'
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='実納品日'                        //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'
          inputMode='tel'
          // fixedDecimalScale={false}            //小数点On/Off
          // decimalScale={0}                    //少数部の桁
          // allowNegative={false}               //マイナス入力off
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          // onValueChange={(values, sourceInfo) => {
          //   console.log(values.value);
          //   console.log(values.formattedValue);
          //   console.log(values.floatValue);
          // }}              
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 9), {background:bgcolor01})}
          //value={dbdata.ZEIRTU}               //初期値
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={false}                    //使用可／不可
          value={nhnymd}
          placeholder="YY/MM/DD"
          inputProps={{ autoComplete: 'off',maxLength: 9,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
          onKeyDown={(e) => onKeyDown(e.key,inNHNYMD,inNHNYMD,inBIN)}
          onBlur={() => onBlur(inNHNYMD)}
          onFocus={(e) => setTimeout(() => {e.target.select()},100)}
          onChange={(e) => setNhnymd(e.target.value)}
          />
        　
        <NumericFormat  //便
            id={'bin'}
            inputRef={inBIN}                   //項目名定義？
            color='success'
            thousandSeparator={false}           //1000単位の区切り           
            customInput={TextField}             //textfieldにreact-number-formatの機能を追加
            size='small'                        //textboxの全体サイズ
            label='便'                         //textboxの左上に表示するラベル
            variant='outlined'                  //枠の表示方法
            type='tel'
            inputMode='tel'
            fixedDecimalScale={false}            //小数点On/Off
            decimalScale={0}                    //少数部の桁
            InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
            sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 4), {background:bgcolor02})}
            value={bin}               //初期値
            allowLeadingZeros                   //前ゼロ表示 on
            disabled={dis}                    //使用可／不可
            inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
            onKeyDown={(e) => onKeyDown(e.key,inBIN,inNHNYMD,inRET)}
            onBlur={() => onBlur(inBIN)}
            onChange={(e) => setBin(e.target.value)}
            onFocus={(e) => setTimeout(() => {e.target.select()},100)}
            />
          <br /><br /><br /><br />
          <font color="red">{ alert }</font>
          <Stack direction="row" spacing={2}>
            <font color="black">{ 'F9:中止 F5:終了　　　　　　　　　　' }</font>
              <NumericFormat  //確認
                id={'ret'}
                inputRef={inRET}                    //項目名定義？
                thousandSeparator={false}           //1000単位の区切り           
                color='success'
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='確認'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                fixedDecimalScale={false}            //小数点On/Off
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={Object.assign({},cssInputOutlineRetSetwidth(styles.cssInputOutline))}
                //value={dbdata.ZEIRTU}               //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={false}                    //使用可／不可
                inputProps={{ autoComplete: 'off',maxLength: 0,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
                onKeyDown={(e) => onKeyDown(e.key,inRET,inBIN,inRET)}
                />
          </Stack>
        {/* <p>{ state.usrnm }</p> */}
      </Paper>
    </Grid>
  );
};

export default TCSA0004;