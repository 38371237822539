import { useState } from 'react'

// 商談書No.取得
export const useSyodanAction = () => {
  const [syodanData, setSyodanData] = useState({});                   // 商談データ

  // 商談書No.存在チェック
  const getSyodan = async(KSACDE, MR_BND_DAT) => {
    const getSyodan = await fetch('/TCGT0100/MR_BND_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({KSACDE: KSACDE,MR_BND_DAT: MR_BND_DAT})});
    const syodan = await getSyodan.json();
    return syodan.ret;
  };

  // 取得した商談データを保存する。
  function setSyodan(syodan)
  {
    setSyodanData(syodan);
  };

  return {syodanData, getSyodan, setSyodan};
};

export default useSyodanAction;