import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, TextField, Grid, Paper, Typography, Stack, InputLabel } from "@mui/material";
import { NumericFormat } from 'react-number-format';    //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';

import CustomAlert from './CustomAlert';
import './common.css'

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F5キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F9キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F11") { // F11キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応


function TCSA0200 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('ファンクションキーを押してください。');
  const [dismeisai, setMeisai] = useState(false);    //disabled設定（明細）
  const [sdate, setsDate] = useState([]);
  const [inp_kbn, setkbn] = useState('0');      //区分分岐用
  const [inp_kkn, setkkn] = useState('');       //リスト出力と画面戻る指示分岐用
  const [upd_flg, setUpdflg] = useState('0');   //更新用フラグ

  const inKBN = useRef(null);
  const inRET = useRef(null);

  //項目のバックカラー制御用
  const [itKBNclr,setItKBNclr] = useState('#FFFFFF');

  const commonStyles = {
    bgcolor: 'background.paper',
    m: 1,
    border: 0,
    width: '40px',
    height: '40px',
  };

  // アラート↓↓↓
  const [isAlertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  // アラートを表示
  const showAlert = (message) => {
    window.glAlertDialogFlg = true;
    setAlertMessage(message);
    setAlertVisible(true);
  };
  // アラートを閉じる
  const closeAlert = () => {
    window.glAlertDialogFlg = false;
    setAlertMessage('');
    setAlertVisible(false);
  };
  // アラートここまで↑↑↑

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
      PRINTsyori();
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      if (state.GAMEN == 0)
      {
        navigate('/TCSA0003');
      }else{
        navigate('/TCSA0005');
      }
    }
    if (event.keyCode === 120) { // F9キーが押された場合の処理
    }
    if (event.keyCode === 122) { // F11キーが押された場合の処理
      if (inKBN.current !== null) {
        inKBN.current.focus();
        console.log('state.KSACDE:'+state.KSACDES);
        console.log('state.MISES:'+state.MISES);
      }
      setAlert('Ｆ５：終了'); 
}
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応
  
  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);
    if (state.KENGEN === '99')
    {
      setUpdflg('1');
    }

  },[]);

  const SetCheck = (CheckName,Position) =>{  // CheckName:チェック対象、Pstn：1（チェック対象項目）,2（確認）
    let ErrFlg;
    console.log('連携データ：'+CheckName+'-'+Position);
    setAlert('　'); 
    if (CheckName == 'KBN') {
      setItKBNclr('#FFFFFF');
      if (!(inp_kbn === '0' || inp_kbn === '4'|| inp_kbn === '5'|| inp_kbn === '6'))
      {
        setAlert('0,4～6のいずれかを入力してください！');//TCGE0078：
        setItKBNclr('#f59d9d');

        switch(Position) {
          case 1:
            ErrFlg = 1;
            break;
          case 2:
            ErrFlg = 2;
            break;
          default:
            break;
          }
          console.log('エラーあり：'+ErrFlg);
        }else{
        ErrFlg = 0;
        console.log('エラーなし：'+ErrFlg);
        setAlert('ファンクションキーを押してください。')
      }
    }

    console.log('戻り値：'+ErrFlg);
    return ErrFlg;
  };

  const PRINTsyori = async() =>{
    //出力処理

    //チェック処理追加（2023/09/29亀田）
    if (CHECKsyori()===false){
      return;
    }

    //S 184-004 2023/09/20 連続印刷指示抑制の為、メッセージボックスを表示する
    // window.alert('印刷要求を受け付けました。しばらくお待ちください');
    showAlert('印刷要求を受け付けました。しばらくお待ちください');
    //E 184-004 2023/09/20 連続印刷指示抑制の為、メッセージボックスを表示する
    setAlert('　'); 
    console.log('PRINTsyori:件数確認開始');

    let dt = new Date(state.SDAY);
    // 未処理対象期間 = 30日
    let dts = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate()-30); // 実行日から30日前の日付
    // let dts = new Date('2023/08/20'); // テスト用
    let NotYetYMD = dts.getFullYear() + ('0' + (dts.getMonth() + 1)).slice(-2) + ('0' + dts.getDate()).slice(-2);
    console.log('元情報：'+dts);
    console.log('未処理対象期間：'+NotYetYMD);
    switch (inp_kbn)
    {
      case '0':
        const responseA01 = await fetch('/TCSA0200/SL_HED_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,NotYetYMD: NotYetYMD,UPD_FLG:upd_flg})});
        const dataA01 = await responseA01.json();
        if (dataA01.RCNT >= 1) //一件以上
        {
          const responseB01 = await fetch('/TCSA0200/SAPRT0210',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,NotYetYMD: NotYetYMD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
          const dataB01 = await responseB01.json();
          const responseB02 = await fetch('/TCSA0200/SAPRT0220',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,NotYetYMD: NotYetYMD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
          const dataB02 = await responseB02.json();
          const responseB03 = await fetch('/TCSA0200/SAPRT0230',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,NotYetYMD: NotYetYMD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
          const dataB03 = await responseB03.json();

          setAlert('正常に処理が完了しました。');//TCGM0003：
        }else
        {
          setAlert('出力データはありませんでした。');//TCGE0076：
        }
      break;
      case '4':
        console.log('case 4');
        const responseA02 = await fetch('/TCSA0200/SL_HED_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,NotYetYMD: NotYetYMD,UPD_FLG:upd_flg})});
        const dataA02 = await responseA02.json();

        console.log('dataA02.RCNT:'+dataA02.RCNT);
        if (dataA02.RCNT >= 1) //一件以上
        {
          const responseB2 = await fetch('/TCSA0200/SAPRT0210',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,NotYetYMD: NotYetYMD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
          const dataB2 = await responseB2.json();

          setAlert('正常に処理が完了しました。');//TCGM0003：
        }else
        {
          setAlert('出力データはありませんでした。');//TCGE0076：
        }
      break;
      case '5':
        const responseA03 = await fetch('/TCSA0200/SL_HED_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,NotYetYMD: NotYetYMD,UPD_FLG:upd_flg})});
        const dataA03 = await responseA03.json();
        if (dataA03.RCNT >= 1) //一件以上
        {
          const responseB3 = await fetch('/TCSA0200/SAPRT0220',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,NotYetYMD: NotYetYMD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
          const dataB3 = await responseB3.json();

          setAlert('正常に処理が完了しました。');//TCGM0003：
        }else
        {
          setAlert('出力データはありませんでした。');//TCGE0076：
        }
      break;
      case '6':
        const responseA04 = await fetch('/TCSA0200/SL_HED_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,NotYetYMD: NotYetYMD,UPD_FLG:upd_flg})});
        const dataA04 = await responseA04.json();
        if (dataA04.RCNT >= 1) //一件以上
        {
          const responseB4 = await fetch('/TCSA0200/SAPRT0230',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,NotYetYMD: NotYetYMD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
          const dataB4 = await responseB4.json();
          
          setAlert('正常に処理が完了しました。');//TCGM0003：
        }else
        {
          setAlert('出力データはありませんでした。');//TCGE0076：
        }
      break;
      default:
        break;
     }
  };

  const CHECKsyori = () =>{
    //チェック処理
    console.log('チェック処理開始');
    console.log('inp_kbn'+inp_kbn);
    //初期化
    setAlert('ファンクションキーを押してください。')
    setItKBNclr('#FFFFFF');

    if (!(inp_kbn === '0' || inp_kbn === '4'|| inp_kbn === '5'|| inp_kbn === '6'))
    {
      setAlert('0,4～6のいずれかを入力してください！');//TCGE0078：
      setItKBNclr('#f59d9d');
      return false;
    }else{
      return true;
    }
  };

  useEffect(() => {
    // // イベントリスナーを登録
    // window.addEventListener('keydown', (e) => {
    //   switch(e.key)
    //   {
    //     case 'F5':
    //       e.preventDefault();
    //       navigate('/TCSA0003');
    //       break;

    //     case 'F11':
    //       e.preventDefault();
    //       if (inKBN.current !== null) {
    //         inKBN.current.focus();
    //         console.log('state.KSACDE:'+state.KSACDES);
    //         console.log('state.MISES:'+state.MISES);
    //       }
    //       setAlert('Ｆ５：終了'); 
    //       break;
    //     default:
    //       break;
    //   }

    // });
  }, []);

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef,inUpRef,inRgRef,inDnRef,inLfRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報、上キー、右キー、下キー、左キー
    //console.log(key);
    let mvFlg = 0;
    switch (key) {
      case "Enter":
        console.log(inp_kkn);
        console.log(state.KSACDES);
        console.log(state.MISES);
        if (inputRef.current.id==='KBN') 
          {
            // 区分チェック
            mvFlg = SetCheck('KBN',1);
            console.log('チェック結果(KBN)：'+ mvFlg);
          }

        if (inputRef.current.id==='ret')
        {
          if (inp_kkn === '1')
          {
            // 区分チェック
            mvFlg = SetCheck('KBN',2);
            console.log('チェック結果(ret)：'+ mvFlg);
            if (mvFlg == 0){
              // 帳票出力
              PRINTsyori();
            }

            }else if (inp_kkn === '5')
            {
              navigate('/TCSA0003');
          }
        }
        
        // switch (mvFlg) {
        //   case 0:
        //     inputNxRef.current.focus();   //次の項目に遷移
        //     break;
        //   case 1:
        //     inRET.current.focus();
        //     inputRef.current.focus();   //現在の項目に遷移
        //     break;
        //   case 2:
        //     inputRtRef.current.focus();   //前の項目に遷移
        //     break;
        //   case 9:
        //     // inDENNO.current.focus();   //伝票No.の項目に遷移
        //     break;
        //   default:
        //     break;
        //   }
        break;
      case "Home":
        break;
      case "PageUp":
        inputRtRef.current.focus();   //前の項目に遷移
        break;
      case "PageDown":
        inputNxRef.current.focus();   //次の項目に遷移
        break;
      default:
        break;
    }
  };  


  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
         fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
  }

  return (

    <Grid>
      <CustomAlert isVisible={isAlertVisible} message={alertMessage} onClose={closeAlert} />
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "730px",
          width: "900px",
          m: "10px auto",
          padding:"10px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="left"
        >
          <div style={{display:"flex"}}>
            <Typography variant={"h5"} sx={{ m: "10px" }}>
              未処理リスト出力処理
            </Typography>
            <Box width={'65%'} display={'flex'} justifyContent={'flex-end'}>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"17px",fontSize:15 }}>
                {state.MISEMEI}：{state.JGNMEIKNJ}
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"17px",fontSize:15 }}>
                { sdate }
              </Typography>　　　　　
            </Box>
          </div>
        </Grid>
        <br />
        <Stack direction="row">
          <InputLabel variant={'outlined'}>未処理リストを出力します。</InputLabel>   
        </Stack>
        <br />
        <br />        
        <Stack direction="row" >
          <Grid>
            <div>　　</div>
          </Grid>
          <NumericFormat  //区分
              id={'KBN'}
              inputRef={inKBN}                   //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り           
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='区分'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 1), {background:itKBNclr})}
              value={inp_kbn}                     //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={false}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
              onKeyDown={(e) => onKeyDown(e.key,inKBN,inKBN,inRET,'','','','')}
              onChange={(event) => setkbn(event.target.value)}
              // onFocus={(e) => e.target.select()}
              onFocus={(e) => setTimeout(() => {e.target.select();},100)}
              />
              <InputLabel variant={'outlined'}>（０：全件　４：手書Ｉ型　５：移動　６：売変）</InputLabel>   
        </Stack>
        <br />
        <br />
        {/* <Stack direction="row" >
          <Grid>
            <div>　　</div>
          </Grid>
          <NumericFormat  //確認
                id={'ret'}
                inputRef={inRET}                    //項目名定義？
                thousandSeparator={false}           //1000単位の区切り           
                color='success'
                autoFocus                           //初期カーソル位置設定
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='確認'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                type='tel'                          //[PDA、Tablet対応]入力モード：tel
                inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
                fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
                decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '5ch'}}                 //textboxの幅
                value={inp_kkn}                     //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={false}                    //使用可／不可
                inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
                onKeyDown={(e) => onKeyDown(e.key,inRET,inKBN,inRET,'','','','')}
                onChange={(event) => setkkn(event.target.value)}
                />              
                <InputLabel variant={'outlined'}>（１：リスト出力　５：前画面へ）</InputLabel>     
        </Stack> */}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <font color="red">{ alert }</font>
        <Stack direction="row" spacing={2}>
          <font color="black">{ 'Ｆ１：リスト出力　Ｆ５：終了　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　' }</font>          
        </Stack>
      </Paper>
    </Grid>
  );
};


export default TCSA0200;