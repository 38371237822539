import React, { useState,useContext,useEffect } from 'react'
import { Grid, Paper, Typography, Stack } from "@mui/material";

// 以下HandsonTable使用のためのインポート群
import 'handsontable/dist/handsontable.full.min.css';   // HandsonTableのCSSをインポート
import {
  registerCellType,
  CheckboxCellType,
} from 'handsontable/cellTypes';
import { HotTable } from '@handsontable/react';         // HotTableをインポートし使用できるようにする
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応


function TCSA0220 () {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const [alert, setAlert] = useState('権限に紐づくボタンの活性・非活性の設定を行います。修正内容はログイン時反映されます。');
  const {state, dispatch} = useContext(UserContext);
  const [sdate, setsDate] = useState([]);

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      navigate('/TCSA0003');
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  // セルタイプを登録
  registerCellType(CheckboxCellType);

  useEffect (()=>{
    // DBからデータ取得
    const data = getSL_AUT_MST();
    data.then(async result => {
      if (result != null) {
        console.log('画面表示権限マスタ取得');
        console.log(result);
        // rows作成
        let id = 0;
        let newRows = [];
        result.forEach(element => {
          newRows.push({ 
            id: id,
            REC_NO: element.REC_NO,
            GAMENNM: element.GAMENNM,
            AUT01: cnvStrToBool(element.AUT01),
            AUT02: cnvStrToBool(element.AUT02),
            AUT03: cnvStrToBool(element.AUT03),
            AUT04: cnvStrToBool(element.AUT04),
            AUT05: cnvStrToBool(element.AUT05),
            AUT06: cnvStrToBool(element.AUT06),
            AUT07: cnvStrToBool(element.AUT07),
            AUT08: cnvStrToBool(element.AUT08),
            AUT09: cnvStrToBool(element.AUT09),
            AUT99: cnvStrToBool(element.AUT99),
          });
          id++;
        });

        console.log('画面表示用データ作成');
        console.log(newRows)
        // 権限マスタリストを設定
        setRows(newRows);
      }
    });

    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);
  }, []);

  // 00フォーマット用
  const  zeroPadding = (val,length) =>  ( "00" + val).slice(-length); 

  // 数字からBoolan型に変換
  const cnvStrToBool = (value) => {
    const res = Number(value)
    // 変換後が数値かつ0or1か判定する
    if ((!isNaN(res)) && (0 == res || 1 == res)) {
      return Boolean(res);
    }
    else {
      // 失敗の場合はとりあえずfalse固定
      return false;
    }
  }

  const getSL_AUT_MST = async () => {
    // バックエンドから取得
    const response = await fetch('/TCSA0220/getSL_AUT_MST',{method: 'POST',headers: {'Content-Type': 'application/json'},
    });
    return response.json();
  };

  const updateSL_AUT_MST = async (valeu) => {
    // バックエンドを更新
    const response = await fetch('/TCSA0220/updateSL_AUT_MST',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(valeu)
    });
    return response.json();
  };
  
  // セル列情報設定
  const columns = [
    { 
      data: 'REC_NO',
      type: 'text',
      className:'htCenter',
      readOnly: true
    },
    { 
      data: 'GAMENNM',
      type: 'text',
      readOnly: true
    },
    { 
      data: 'AUT01',
      type: 'checkbox',
      className:'htCenter',
    },
    { 
      data: 'AUT02',
      type: 'checkbox',
      className:'htCenter'
    },
    { 
      data: 'AUT03',
      type: 'checkbox',
      className:'htCenter'
    },
    { 
      data: 'AUT04',
      type: 'checkbox',
      className:'htCenter'
    },
    { 
      data: 'AUT05',
      type: 'checkbox',
      className:'htCenter'
    },
    { 
      data: 'AUT06',
      type: 'checkbox',
      className:'htCenter'
    },
    { 
      data: 'AUT07',
      type: 'checkbox',
      className:'htCenter'
    },
    { 
      data: 'AUT08',
      type: 'checkbox',
      className:'htCenter'
    },
    { 
      data: 'AUT09',
      type: 'checkbox',
      className:'htCenter'
    },
    { 
      data: 'AUT99',
      type: 'checkbox',
      className:'htCenter'
    },
  ]

  // ヘッダ名称設定
  const Headers = [
    'No.',
    '画面名',
    '権限<br/>01',
    '権限<br/>02',
    '権限<br/>03',
    '権限<br/>04',
    '権限<br/>05',
    '権限<br/>06',
    '権限<br/>07',
    '権限<br/>08',
    '権限<br/>09',
    '権限<br/>99',
  ]

  return (
    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "730px",
          width: "1200px",
          m: "10px auto",
          padding:"10px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="left"
        >
          <div style={{display:"flex"}}>
            <Typography variant={"h5"} sx={{ m: "10px" }}>
            画面表示権限設定画面
            </Typography>
            <Typography variant={"h6"} sx={{ m: "15px",ml:"400px" }}>
            {state.MISEMEI}：{state.JGNMEIKNJ}
            </Typography>
            <Typography variant={"h6"} sx={{ m: "15px" }}>
            {sdate}
            </Typography>
          </div>
        </Grid>
        <br />

        {/* 権限設定リストを表示する */}
        <HotTable
          data = {rows}
          columns = {columns}
          colHeaders = {Headers}
          licenseKey = 'non-commercial-and-evaluation'
          colWidths = {[50, 300, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50]}
          disableVisualSelection = {true}
          afterChange={(changes, source) => {
            changes?.forEach(([row, prop, oldValue, newValue]) => {
              // Some logic...
              const parms = {
                rec_no: zeroPadding((row + 1), 2),
                value: Number(newValue),
                key: prop
              }
              updateSL_AUT_MST(parms)
              console.log(row);
              console.log(prop);
              console.log(oldValue);
              console.log(newValue);
            });
          }}
        />
        <br />
        <font color="red">{ alert }</font>
        <Stack direction="row" spacing={2}>
          <font color="black">{ 'F5:戻る　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　' }</font>
        </Stack>

      </Paper>
    </Grid>
  );

};

export default TCSA0220;