import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, Button, TextField, Grid, Paper, Typography, Stack, InputLabel } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  jaJP                              // 日本語用のファイルをインポート
} from '@mui/x-data-grid';
import { NumericFormat,PatternFormat } from 'react-number-format';    //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import { useDenKbnAction }from './hooks/useDenKbnAction'  // 伝票区分共通処理
import { useRiyuAction } from './hooks/useRiyuAction'     // 理由No共通処理
import { useSyodanAction } from './hooks/useSyodanAction' // 商談No共通処理
import { onMyFocus,onNextFocus } from './hooks/useOnMyFocus';     // select()処理

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);

      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCGT0100 () {
  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('');
  const [sdate, setsDate] = useState([]);     // 本日日付（YYYY/MM/DD)
  const [iryoZei, setIryoZei] = useState(0);  // 衣料タグ税剥がし税率固定

  // 画面が再読み込みされた際に伝票区分が初期値に戻らない為のフラグ
  const [relodeFlg, setRelodeFlg] = useState(0);

  const [riyuKyokaList, setRiyuKyokaList] = useState([]);       // 伝票区分情報(区分に対する使用可能理由No.）
  const [visibleSYODAN, setVisibleSYODAN] = useState(false);    // 商談No表示フラグ

  const {denKbnList, denKbnComment, denKbnName, changeDenKbn2} = useDenKbnAction(); // 伝票区分共通処理
  const {riyuList, riyuComment, riyuName, changeRiyuNo2} = useRiyuAction();  // 理由No共通処理
  const {syodanData, getSyodan, setSyodan} = useSyodanAction();

  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  //項目のバックカラー制御用
  const [inBMNCDEclr,setBMNCDEclr] = useState('#FFFFFF');
  const [inHZKclr,setHZKclr] = useState('#FFFFFF');
  const [inDENKBNclr,setDENKBNclr] = useState('#FFFFFF');
  const [inRYUNOclr,setRYUNOclr] = useState('#FFFFFF');
  const [inSYODANclr,setSYODANclr] = useState('#FFFFFF');
  const [inJAN1clr,setJAN1clr] = useState('#FFFFFF');
  const [inJAN2clr,setJAN2clr] = useState('#FFFFFF');
  const [inSUUclr,setSUUclr] = useState('#FFFFFF');
  const [inGNTclr,setGNTclr] = useState('#FFFFFF');
  const [inBTNclr,setBTNclr] = useState('#FFFFFF');
  const [inJYOTOYMDclr,setJYOTOYMDclr] = useState('#FFFFFF');

  //disabled制御用
  const [disRyuno, setDisRyuno] = useState(false);    // 理由No
  const [disHeder, setDHeder] = useState(false);    // ヘッダー用
  const [disJan1, setJan1] = useState(true);    // JAN1
  const [disJan2, setJan2] = useState(true);    // JAN2
  // 10/11
  const [disRow, setDisRow] = useState(true);    // 数量 原単価 売単価 譲渡日etc

  const [dismeisai, setMeisai] = useState(false);   //disabled設定（明細）

  const inBMNCDE = useRef(null);
  const inHZK = useRef(null);
  const inDENKBN = useRef(null);
  const inRYUNO = useRef(null);
  const inSYODAN = useRef(null);
  const inSIRCDE = useRef(null);
  const inZEIRTU = useRef(null);

  const inJAN1 = useRef(null);
  const inSUU = useRef(null);
  const inGNT = useRef(null);
  const inBTN = useRef(null);
  const inJYOTOYMD = useRef(null);
  const inJAN2 = useRef(null);
  const inSIRCDE2 = useRef(null);
  const inZEIRTU2 = useRef(null);

  const inSUUG = useRef(null);
  const inGNKFOT = useRef(null);
  const inBBAKFOT = useRef(null);

  const [pagenationModel, setPagenationModel] = useState({page: 0, pageSize: 5});

  // ロストフォーカス用
  const [ErrID,setErrID] = useState('');
  const [chkJAN1,setChkJAN1] = useState('0');
  const [chkJAN2,setChkJAN2] = useState('0');

  // ヘッダ1データ　◇画面上だけのデータ
  const headerData = {
    KSACDE:state.KSACDES,         // 会社コード
    MISE:state.MISES,             // 店コード
    BMNCDE:'',                    // 部門コード
    DENYMD:'',                    // 伝票日付
    DENNO:'',                     // 伝票番号
    DEN_EDABAN :'1',              // 伝票枝番
    SEQ:'001',                    // SEQ
    TENPONM:state.MISEMEI,        // 店舗名
    BMNMEI:'',                    // 部門名
    HZK:'',                       // 日付
    HZK2:'',                      // ◇日付2
    DENKBN:'',                    // 伝票区分
    DENKBNNM:'',                  // 伝票区分名
    RYUNO:'',                     // 理由№
    RYUMEI:'',                    // 理由名
    SYODAN:'',                    // 商談書№
    SIRCDE:'',                    // 仕入先(取引先)コード
    SIRCDE2:'',                   // 仕入先(取引先)コード2
    SIRMEIK:'',                   // 仕入先(取引先)名
    SIRMEIK2:'',                  // 仕入先(取引先)名2
    SRYFOT:0,                     // 数量合計
    GNKFOT:0,                     // 原価金額合計
    BAKFOT:0,                     // 売価金額合計
    JGNCDE:state.JGNCDE,          // 従業員コード
    JGNMEIKNJ:state.JGNMEIKNJ,    // 担当者氏名
    IN_DATE:'',                   // 入力日時
    ZEIRTU:'',                    // 税率
    ZEIRTU2:'',                   // 税率2
    JOTKBN:'1',                   // 状態区分
    KIJYKETKBN:'',                // 計上結果区分
    PRG_ID: 'TCGT0100',           // プログラムID
    DELFLG: '0',                  // 削除フラグ
    TRL_ID:state.MISES,           // 端末ＩＤ★一旦店コード入れる
    GNKKNRFLG: '',                // 原価管理フラグ
  }
  const [headerItem,setHeaderItem] = useState(headerData);
  // ヘッダ2データ
  const rowData = {
    id : 0,                       // ◇行№
    KSACDE :state.KSACDES,        // 会社コード
    MISE : state.MISES,           // 店コード
    BMNCDE : '',                  // 部門コード
    DENYMD : '',                  // 伝票日付
    DENNO : '',                   // 伝票番号
    DEN_EDABAN : '1',             // 伝票枝番
    SEQ : '',                     // ＳＥＱ
    //REC_NO : '',                // 行№
    JANCDE : '',                  // ＪＡＮコード
    SHNMEIKN : '',                // 商品名
    KIKAKUKN : '',                // 規格
    SUU : 0,                      // 数量(数字)
    SUU2 : '',                    // ◇数量2(文字列)
    GNT : 0,                      // 原単価(数字)
    GNT2 : '',                    // ◇原単価2(文字列)
    GNKKIN : 0,                   // 原価金額
    BTN : 0,                      // 売単価(数字)
    BTN2 : '',                    // ◇売単価2(文字列)
    BAKKIN : 0,                   // 売価金額
    JYOTOYMD : '',                // 譲渡日
    JYOTOYMD2 : '',               // ◇譲渡日2
    BEF_CST_AMT : '0.00',         // 訂正前原価
    AFT_CST_AMT : '0.00',         // 訂正後原価
    BEF_SEL_AMT : '0',            // 訂正前売価
    AFT_SEL_AMT : '0',            // 訂正後売価
    EOSCDE : '',                  // ＥＯＳコード
    JANCDE2 : '',                 // ＪＡＮコード２
    TAGASTCDE : '',               // タグＡＵ
    TAGLINCDE : '',               // タグライン
    TAGCLSCDE : '',               // タグクラス
    TAGSZN : '',                  // タグシーズン
    TAGITM : '',                  // タグアイテム
    TRIHBN : '',                  // 取引先品番
    SIZCDE : '',                  // サイズコード
    TAGSIZMEI : '',               // サイズ名カナ
    TAGCOLCDE : '',               // カラーコード
    TAGCOLMEI : '',               // カラー名カナ
    IRYO_FLG : '0',               // 衣料フラグ
    IN_CD_KBN : '',               // 入力コード区分
    UPD_JGNCDE: state.JGNCDE,     // 担当者氏名
    PRG_ID: 'TCGT0100',           // プログラムID
    DELFLG: '0',                  // 削除フラグ
    TRL_ID:state.MISES            // 端末ＩＤ★一旦店コード入れる
  }
  const [rowItem,setRowItem] = useState(rowData);

  const commonStyles = {
    bgcolor: 'background.paper',
    m: 1,
    border: 0,
    width: '40px',
    height: '40px',
  };

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  // 伝票区分入力時の処理
  const changeDenKbn = (value, header) => {     // 23/09/25
    //const header = JSON.parse(JSON.stringify(headerItem));
    let denKbnName = '';
    if (value !== '') {
      const inputDenKbn = denKbnList.filter(element => {
        return Number(element.cd) === Number(value);
      });
      if (inputDenKbn.length !== 0) {
        denKbnName = inputDenKbn[0].name;
      }
    }
    // header.DENKBN = value;
    // header.DENKBNNM = denKbnName;
    setHeaderItem(HeaderItem => ({...HeaderItem,DENKBN:value}));
    setHeaderItem(HeaderItem => ({...HeaderItem,DENKBNNM:denKbnName}));

    // 理由Noをリセットする。
    setDisRyuno(false);
    // header.RYUNO = '';
    // header.RYUMEI = '';
    setHeaderItem(HeaderItem => ({...HeaderItem,RYUNO:''}));
    setHeaderItem(HeaderItem => ({...HeaderItem,RYUMEI:''}));

    // 商談データをリセットする。
    setVisibleSYODAN(false);
    header.SYODAN='';
    setHeaderItem(HeaderItem => ({...HeaderItem,SYODAN:''}));

    // setHeaderItem(header);
  };

  // 伝票区分入力時の処理
  const retDenKbn = (value) => {     // 23/09/25
    if (value !== '') {
      const inputDenKbn = denKbnList.filter(element => {
        return Number(element.cd) === Number(value);
      });
      if (inputDenKbn.length !== 0) {
        return inputDenKbn[0].name;
      }
    }
  };

  // 理由No.入力時の処理
  const changeRiyuNo = (value,header) => {
    //const header = JSON.parse(JSON.stringify(headerItem));
    let riyuNoName = '';
    if (value !== '') {
      const inputRiyu = riyuList.filter(element => {
        return element.cd === value
      });

      if (inputRiyu.length !== 0) {
        riyuNoName = inputRiyu[0].name;
      }
    }

    // header.RYUNO = value;
    // header.RYUMEI = riyuNoName;
    setHeaderItem(HeaderItem => ({...HeaderItem,RYUNO:value}));
    setHeaderItem(HeaderItem => ({...HeaderItem,RYUMEI:riyuNoName}));

    // setHeaderItem(header);
  };

  /**
   * 数量設定 (numをrow.SUUに数字で、row.SUU2に文字列で設定)
   * @param row 設定行
   * @param num 設定値
   */
  const setRowSuu = async (row, num) => {
    let suu = Number(num);
    if (isNaN(suu)){
      suu = 0;
    }
    // 数量
    row.SUU = suu;
    // 数量2
    row.SUU2 = suu.toLocaleString(undefined,{minimumFractionDigits : 1});
  }

  /**
   * 原単価設定 (numをrow.GNTに数字で、row.GNT2に文字列で設定)
   * @param row 設定行
   * @param num 設定値
   */
  const setRowGnt = async (row, num) => {
    let gnt = Number(num);
    if (isNaN(gnt)){
      gnt = 0;
    }
    // 原単価
    row.GNT = gnt;
    // 原単価2
    row.GNT2 = gnt.toLocaleString(undefined,{minimumFractionDigits : 2});
  }

  /**
   * 売単価設定 (numをrow.BTNに数字で、row.BTN2に文字列で設定)
   * @param row 設定行
   * @param num 設定値
   */
  const setRowBtn = async (row, num) => {
    let btn = Number(num);
    if (isNaN(btn)){
      btn = 0;
    }
    // 売単価
    row.BTN = btn;
    // 売単価2
    row.BTN2 = btn.toLocaleString(undefined,{minimumFractionDigits : 0});
  }

  const ckDate = (strYYYYMMDD) =>{  // YYYY/MM/DD ⇒ 2023/09/07 等
    if(!strYYYYMMDD.match(/^\d{4}\/\d{2}\/\d{2}$/)){
        return false;
    }
    var y = strYYYYMMDD.split("/")[0];
    var m = strYYYYMMDD.split("/")[1] - 1;
    var d = strYYYYMMDD.split("/")[2];
    var date = new Date(y,m,d);
    if(date.getFullYear() != y || date.getMonth() != m || date.getDate() != d){
        return false;
    }
    return true;
  }

  // 初期処理
  useEffect (() => {
    if (state.KENGEN === '') {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    } else {
      console.log('メニュー:%o',state);
    }
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);

    if(relodeFlg === 0){
      setHeaderReset();
    }
    getIryo();
  }, []);

  // 行が追加されたら最後のページを表示する
  useEffect(() => {
    let last_page = Math.ceil(rows.length / pagenationModel.pageSize) - 1;
    if (last_page < 0) last_page = 0;
    setPagenationModel({page: last_page, pageSize: pagenationModel.pageSize});
  }, [rows]);

  // JAN1入力後、数量が全選択状態にならないため、
  // 数量の描画完了後にフォーカス移動させる必要がある
  const [nextActive, setNextActive] = useState(null);
  useEffect(() => {
    if (nextActive === 'SUU') {
      inSUU.current.focus();
      //setTimeout(() => {inSUU.current.select();},100);
      onNextFocus(inSUU);
      setNextActive('');
    }
  }, [rowItem.SUU]);

  useEffect(() => {
    if (nextActive === 'JAN1') {
      inJAN1.current.focus();
      setNextActive('');
    }
  }, [disJan1]);

  // JAN1入力後、数量が全選択状態にならないため、
  // 数量の描画完了後にフォーカス移動させる必要がある
  useEffect(() => {
    if (nextActive === 'JAN2') {
      inJAN2.current.focus();
      setNextActive('');
    }
  }, [disJan2]);

  useEffect (()=>{
    if(disRow === false){
      inSUU.current.focus();
    }
  }, [disRow]);

  // ヘッダの表示をリセットする。
  const setHeaderReset =  async () =>{
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = (d.getMonth() + 1).toString().padStart(2, '0');
    let day = d.getDate().toString().padStart(2, '0');

    const header = headerData;
    header.HZK = year + month + day;                // 日付
    header.HZK2 = year + '/' + month + '/' + day;   // 日付2
    header.DENKBN = '02'
    await setHeaderItem(header);
    if (denKbnList.length > 0){
      changeDenKbn('02', header);     // 23/09/25
    }
  }

  // 伝票区分の初期表示（伝票区分名表示のために伝票区分リスト読込後に処理）
  useEffect (() => {
    if (denKbnList.length > 0){
      if(relodeFlg === 0){
        const header = JSON.parse(JSON.stringify(headerItem));
        changeDenKbn('02', header);     // 23/09/25
        setRelodeFlg(1);
      }
    }
  }, [denKbnList]);


  // 衣料タグ税剥がし税率固定取得
  const getIryo = async () => {
    const getRiyuCd = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({CDE: '006',SCDE: '0000'})});
    const riyuCdList = await getRiyuCd.json();
    if (riyuCdList.DATE01 !== '') {
      setIryoZei(Number(riyuCdList.DATE01));
    }
  }

  //項目のバックグランドカラー初期化
  const gmbgclr = () => {
    setBMNCDEclr('#FFFFFF');
    setHZKclr('#FFFFFF');
    setDENKBNclr('#FFFFFF');
    setRYUNOclr('#FFFFFF');
    setSYODANclr('#FFFFFF');
    setSUUclr('#FFFFFF');
    setJAN1clr('#FFFFFF');
    setJAN2clr('#FFFFFF');
    setGNTclr('#FFFFFF');
    setBTNclr('#FFFFFF');
    setJYOTOYMDclr('#FFFFFF');
  }

  // #region　KeyDown
  const onKeyDown =  async (key,inputRef,inptRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    switch (key) {
      case 'Enter':
        gmbgclr();
        switch (inputRef.current.id) {
          case 'BMNCDE':  // 部門コード
            await onKeyDown_BMNCDE(inputRef, inputNxRef);
            break;
          case 'HZK':     // 日付チェック
            await onKeyDown_HZK(inputRef, inputNxRef);
            break;
          case 'DENKBN':  // 伝票区分チェック
            const header = JSON.parse(JSON.stringify(headerItem));
            await onKeyDown_DENKBN(inputRef, inputNxRef, header);
            break;
          case 'RYUNO':   // 理由No.チェック
            await onKeyDown_RYUNO(inputRef.current.value);
            break;
          case 'SYODAN':  // 商談書No.
            await onKeyDown_SYODAN(inputRef, inputNxRef);
            break;
          case 'JAN1':    // ＪＡＮ１
            await onKeyDown_JAN1(inputRef, inputNxRef);
            break;
          case 'JAN2':    // ＪＡＮ２（活性時）
            await onKeyDown_JAN2(inputRef, inputNxRef);
            break;
          case 'SUU':     // 数量
            await onKeyDown_SUU(inputRef, inputNxRef);
            break;
          case 'GNT':     // 原単価
            await onKeyDown_GNT(inputRef, inputNxRef);
            break;
          case 'BTN':     // 売単価
            await onKeyDown_BTN(inputRef, inputNxRef);
            break;
          case 'JYOTOYMD': // 譲渡日
            await onKeyDown_JYOTOYMD(inputRef, inputNxRef);
            break;

          default:
            console.log('default移動:'+inputNxRef.current.id);
            inputNxRef.current.focus(); //次の項目に遷移;
            break;
        }
        break;
      case 'Return':
        if (rows.length != 0)
        {
          if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))){  //TCGM0007：
            return;
          }
        }
        navigate('/TCSA0003');
        break;
      case 'Home':
        break;
      case 'ArrowUp':
        inptRtRef.current.focus();    //前の項目に遷移
        break;
      case 'ArrowDown':
        inputNxRef.current.focus();   //次の項目に遷移
        break;
      default:
        break;
    }
  };
  // #endregion

  const onblur = async (inputRef) => {
    gmbgclr();
    console.log('LostFocus:'+inputRef.current.id+' | '+chkJAN1+'|'+chkJAN2);
    if (inputRef.current.id === 'JAN1' && (chkJAN1 == '0'))
    {
      await onKeyDown_JAN1(inputRef, inSUU)
    }
    if (inputRef.current.id === 'JAN2' && (chkJAN2 == '0'))
    {
      await onKeyDown_JAN2(inputRef, inSUU)
    }
  }

  // ヘッダ部１入力　部門CD（初期カーソル）
  const onKeyDown_BMNCDE =  async (inputRef, inputNxRef) => {
    // 必須チェック
    if(inputRef.current.value === '') {
      setBMNCDEclr('#f59d9d');
      window.alert('部門コードを入力して下さい。');   //TCGE0019：
      // setTimeout(() => {inputRef.current.focus();},100)
      return 1;
    }
    // 入力値の前0詰め
    const padBmnCd = inputRef.current.value.padStart(4, '0');

    // 部門CDチェック
    const responseB1 = await fetch('/MR_SHP_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({KSACDE: state.KSACDES, MISE: state.MISES, BMNCDE: padBmnCd, RCNT:0})});
    const dataB1 = await responseB1.json();
    if (dataB1.RCNT === 0) {
      setBMNCDEclr('#f59d9d');
      window.alert('部門コードは存在しません。');     //TCGE0007：
      setErrID('BMNCDE');
      // setTimeout(() => {inputRef.current.focus();},100)
      return 1;
    }

    // 部門名取得
    const responseB2 = await fetch('/TCGT0100/getBrumei',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({KSACDE: state.KSACDES, BMNCDE: padBmnCd, RCNT:0})});
    const dataB2 = await responseB2.json();
    if (dataB2.RCNT === 0) {
      setBMNCDEclr('#f59d9d');
      window.alert('部門コードは存在しません。');   //TCGE0007：
      setErrID('BMNCDE');
      // inputRef.current.focus();
      return 1;
    }

    const header = JSON.parse(JSON.stringify(headerItem));
    // header.BMNCDE = padBmnCd;
    // header.BMNMEI = dataB2.BRUMEI;
    // header.GNKKNRFLG = dataB2.GNKKNRFLG;
    // setHeaderItem(header);
    console.log('部門名取得:'+dataB2.BRUMEI)
    setHeaderItem(HeaderItem => ({...HeaderItem,BMNCDE:padBmnCd}));
    setHeaderItem(HeaderItem => ({...HeaderItem,BMNMEI:dataB2.BRUMEI}));
    setHeaderItem(HeaderItem => ({...HeaderItem,GNKKNRFLG:dataB2.GNKKNRFLG}));
    inputNxRef.current.focus();
    return 0;
  };

  // ヘッダ部１入力　日付
  const onKeyDown_HZK =  async (inputRef, inputNxRef) => {
    // 必須チェック
    if(inputRef.current.value ==='') {
      setHZKclr('#f59d9d');
      window.alert('日付を入力して下さい。');     //TCGE0019：
      return 1;
    }

    // 日付チェック
    const strDate = inputRef.current.value;
    const indate = new Date(strDate);
    if(ckDate(strDate)===false){
      setHZKclr('#f59d9d');
      window.alert('日付入力エラー');       //TCGE0016：
      return 1;
    }

    // システム日－２か月≦入力日（西暦）＜システム日の日付範囲チェック
    let maxdate = new Date(sdate);
    maxdate.setDate(maxdate.getDate() + 14);    // 2023/11/22 2週間後まで入力可能に変更
    let mindate = new Date(sdate);
    mindate.setMonth(mindate.getMonth()-2);
    //console.log('日付範囲チェック'+maxdate+'>='+indate+'&&'+mindate+'<='+indate);
    if (mindate > indate) {
      setHZKclr('#f59d9d');
      window.alert('入力された日付は返品対象外期間です');   //TCGE0016：
      return 1;
    }

    if (maxdate < indate) {
      setHZKclr('#f59d9d');
      window.alert('入力された日付は返品対象外期間です'); //TCGE0016：
      return 1;
    }

    //次の項目に遷移
    inputNxRef.current.focus();
    return 0;
  };

  // ヘッダ部１入力　伝票区分
  const onKeyDown_DENKBN =  async (inputRef, inputNxRef,header) => {
    // 日付必須チェック
    if(inHZK.current.value ==='') {
      setHZKclr('#f59d9d');
      window.alert('日付を入力して下さい。'); //TCGE0019：
      inHZK.current.focus();
      return 1;
    }

    // 必須チェック
    if(inputRef.current.value ==='') {
      setDENKBNclr('#f59d9d');
      window.alert('伝票区分を入力して下さい。'); //TCGE0019：
      return 1;
    }

    const denKbn = inDENKBN.current.value.padStart(2, '0');// 伝票区分コード入力値の前0詰め
    header.DENKBN = denKbn;
    setHeaderItem(HeaderItem => ({...HeaderItem,DENKBN:denKbn}));

    // 設定可能区分チェック
    const inputDenKbn = denKbnList.filter(element => {
      return element.cd === denKbn;
    });
    if (inputDenKbn.length === 0) {
      setDENKBNclr('#f59d9d');
      window.alert('伝票区分入力エラー。'); //TCGE0013：
      return 1;
    }

    // 伝票区分情報.制限チェック(理由許可が１つの場合はセット)
    let kyokaList = [];
    let denkyoka = inputDenKbn[0].kyokaNo;
    if(denkyoka !== '') {
      kyokaList = denkyoka.split(',').map(x => x.trim());
    }
    setRiyuKyokaList(kyokaList)
    if(kyokaList.length === 1) {
      header.RYUNO = kyokaList[0];
      setHeaderItem(HeaderItem => ({...HeaderItem,RYUNO:kyokaList[0]}));

      setDisRyuno(true);
      onKeyDown_RYUNO(kyokaList[0]);
      changeRiyuNo(kyokaList[0],header);
      //setHeaderItem();
      return;
    }

    // setHeaderItem(header);

    //次の項目に遷移
    await setDisRyuno(false);
    inputNxRef.current.focus();
    return 0;
  };

  // ヘッダ部１入力　理由No.
  const onKeyDown_RYUNO =  async (ryuno_val) => {

    // 伝票区分必須チェック
    if(inDENKBN.current.value ==='') {
      setDENKBNclr('#f59d9d');
      window.alert('伝票区分を入力して下さい。'); //TCGE0019：
      inDENKBN.current.focus();
      return;
    }

    // 必須チェック
    if(ryuno_val === '') {
      setRYUNOclr('#f59d9d');
      window.alert('理由No.を入力して下さい。');  //TCGE0019：
      return;
    }

    // 設定可能区分チェック
    const inputRiyu = riyuList.filter(element => {
      return element.cd === ryuno_val;
    });
    if (inputRiyu.length === 0) {
      setRYUNOclr('#f59d9d');
      window.alert('理由No入力エラー。'); //TCGE0044：
      return;
    }

    //伝票区分情報.制限チェックで理由許可対象チェック
    if (riyuKyokaList.length > 0) {
      const inputRiyuCheck = riyuKyokaList.filter(element => {
        return element === ryuno_val;
      });
      if (inputRiyuCheck.length === 0)
      {
        setRYUNOclr('#f59d9d');
        window.alert('理由No入力エラー。'); //TCGE0044：
        return;
      }
    }

    const header = JSON.parse(JSON.stringify(headerItem));
    header.RYUNO = ryuno_val;
    setHeaderItem(HeaderItem => ({...HeaderItem,RYUNO:ryuno_val}));
    // setHeaderItem(header);

    // 理由No.に[3]が設定された場合 項目　商談書Noを表示し、カーソルを商談書Noに設定
    if (ryuno_val === '3'){
      await setVisibleSYODAN(true);
      inSYODAN.current.focus();   //商談Noに遷移
      return;
    } else {
      // ヘッダ１チェック
      let ret01 = await onKeyDown_BMNCDE(inBMNCDE, inHZK);
      if (ret01 === 1)
      {
        inBMNCDE.current.focus();
        return;
      }
      ret01 = await onKeyDown_HZK(inHZK, inDENKBN);
      if (ret01 === 1)
      {
        inHZK.current.focus();
        return;
      }
      ret01 = await onKeyDown_DENKBN(inDENKBN, inRYUNO,header);
      if (ret01 === 1)
      {
        inDENKBN.current.focus();
        return;
      }
      // await onKeyDown_SYODAN(inDENKBN, inJAN1);
      // current = document.activeElement;
      // console.log('現在のカーソル位置05:'+current.id);
      setVisibleSYODAN(false);
      // header.SIRCDE = '';
      // header.SIRMEIK = '';
      // header.ZEIRTU = '';
      setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE:''}));
      setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK:''}));
      setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU:''}));
      await getReturnDate('',header);

      await setNextActive(inJAN1.current.id);
      setJan1(false);
      setDHeder(true);
      await setDisRyuno(true);
      // setJan1(false);
      // inJAN1.current.focus(); //次の項目に遷移
    }
  };

  // ヘッダ部１入力　商談書No.
  const onKeyDown_SYODAN =  async (inputRef, inputNxRef) => {

    if (inputRef.current.value === '')
    {
      setSYODANclr('#f59d9d');
      window.alert('TCGE0019:商談書№を入力して下さい。');
      inSYODAN.current.focus();
      return;
    }
    const header = JSON.parse(JSON.stringify(headerItem));

    // ヘッダ１チェック
    console.log('onKeyDown_SYODAN_商談書:%o',inBMNCDE);
    let ret01 = await onKeyDown_BMNCDE(inBMNCDE, inHZK);
    if (ret01 === 1)
    {
      inBMNCDE.current.focus();
      return;
    }
    ret01 = await onKeyDown_HZK(inHZK, inDENKBN);
    if (ret01 === 1)
    {
      inHZK.current.focus();
      return;
    }
    ret01 = await onKeyDown_DENKBN(inDENKBN, inRYUNO,header);
    if (ret01 === 1)
    {
      inDENKBN.current.focus();
      return;
    }

    const syodanNo = inputRef.current.value.padStart(13, '0');// 商談NO入力値の前0詰め
    header.SYODAN =　syodanNo;
    setHeaderItem(HeaderItem => ({...HeaderItem,SYODAN:syodanNo}));
    // 商談書No.存在チェック
    const syodanList = await getSyodan(state.KSACDES, syodanNo);
    if(syodanList.length === 0) {
      setSYODANclr('#f59d9d');
      window.alert('TCGE0079:該当する商談書№が存在しません。');
      setHeaderItem(header);
      inSYODAN.current.focus();
      return;
    }

    // 部門コードチェック
    const syodanItem = syodanList.filter(element => {
      return element.BMNCDE === inBMNCDE.current.value;
    });
    if (syodanItem.length === 0) {
      setSyodan([]);
      setBMNCDEclr('#f59d9d');
      window.alert('入力した部門が商談書の部門と異なります。'); //TCGE0045：
      inBMNCDE.current.focus();
      return;
    }

    // 伝票区分チェック
    if (syodanItem[0].DENKBN !== inDENKBN.current.value) {
      setSyodan([]);
      setDENKBNclr('#f59d9d');
      window.alert('入力した伝票区分が商談書の伝票区分と異なります。'); //TCGE0046：
      inDENKBN.current.focus();
      return;
    }

    // 取引期間チェック
    // 画面の日付8桁の数字化
    const checkData = Number(headerItem.HZK);
    let mindate = Number(syodanItem[0].HPNSTTYMD);
    let maxdate = Number(syodanItem[0].HPNENDYMD);
    if (!(mindate <= checkData && checkData<= maxdate)) {
      setSyodan([]);
      setHZKclr('#f59d9d');
      window.alert('入力した日付が商談書の取引期間外です。'); //TCGE0047：
      inHZK.current.focus();
      return;
    }

    //仕入先情報取得
    await getSirmeik(syodanItem[0].SIRCDE,header,1);

    console.log('headerItem情報:%o',headerItem);

    setSyodan(syodanItem[0]);
    getReturnDate(inputRef.current.value,header);

    console.log('商談書'+syodanNo);
    //次の項目に遷移
    // inputNxRef.current.focus();
    setNextActive(inJAN1.current.id);
    setJan1(false);
    setDHeder(true);
    setDisRyuno(true);
  };

  // 明細データ抽出
  const getReturnDate =  async(SYODAN,header) => {
    // const header = JSON.parse(JSON.stringify(headerItem));
    header.SRYFOT = 0;   // 合計数量
    header.GNKFOT = 0;   // 原価金額合計
    header.BAKFOT = 0;   // 売価金額合計
    header.SYODAN = SYODAN;
    setHeaderItem(HeaderItem => ({...HeaderItem,SRYFOT:0}));
    setHeaderItem(HeaderItem => ({...HeaderItem,GNKFOT:0}));
    setHeaderItem(HeaderItem => ({...HeaderItem,BAKFOT:0}));
    setHeaderItem(HeaderItem => ({...HeaderItem,SYODAN:SYODAN}));
    // setHeaderItem(header);
    setRowItem(rowData);
    setRows([]);
  };

  // ヘッダ部２入力　ＪＡＮ１
  const onKeyDown_JAN1 = async(inputRef, inputNxRef) => {
    setChkJAN1('1');
    // 必須チェック
    // 伝票区分必須チェック
    if  (inputRef.current.value === '')
    {
      return 1;
    }

    if(inDENKBN.current.value ==='') {
      setDENKBNclr('#f59d9d');
      window.alert('伝票区分を入力して下さい。'); //TCGE0019：
      inDENKBN.current.focus();
      return 1;
    }

    // 伝票区分必須チェック
    if(inRYUNO.current.value === '') {
      setRYUNOclr('#f59d9d');
      window.alert('理由No.を入力して下さい。');  //TCGE0019：
      inRYUNO.current.focus();
      return 1;
    }

    const row = await JSON.parse(JSON.stringify(rowItem));
    const header = await JSON.parse(JSON.stringify(headerItem));
    console.log('headerItem01:%o',headerItem);
    console.log('header01:%o',header);
    let Jancde = row.JANCDE;

    // ＪＡＮ１入力値の前0詰め
    if(row.JANCDE.length === 7){
      row.JANCDE = row.JANCDE.padStart(8, '0');
      Jancde = row.JANCDE.padStart(8, '0');
    }
    if(9 <= row.JANCDE.length && row.JANCDE.length <= 12){
      row.JANCDE = row.JANCDE.padStart(13, '0');
      Jancde = row.JANCDE.padStart(13, '0');
    }

    // 2段判定
    const jan1cd = Jancde.substring(0, 2);

    if (Jancde.length == 13 && (
          (jan1cd === '22' || jan1cd === '23')
        ||((jan1cd === '21' || jan1cd === '22') && state.KSACDES ==='005')
    )){

      //TODO 本番コメント化解除
      // 譲渡日の取得
      // 2段JANの場合
      const EosCode = row.JANCDE.substring(2, 10);
      if (process.env.NODE_ENV !== 'development') {
        const responseX = await fetch('/trans_GetShnmstFinl',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({URL: `http://10.0.63.10/olive/oasis_plus/menu/denpyo/trans/trans_GetShnmstFinlnhnYmd.jsp?EOSCDE=${EosCode}&MISE=${row.MISE}`})});
        const dataX = await responseX.json();
        console.log('譲渡日02:%o',dataX);
        console.log('譲渡日02URL:'+`http://10.0.63.10/olive/oasis_plus/menu/denpyo/trans/trans_GetShnmstFinlnhnYmd.jsp?EOSCDE=${EosCode}&MISE=${row.MISE}`);

        row.JYOTOYMD = dataX.finlnhnymd;
        row.JYOTOYMD2 = dataX.finlnhnymd.substring(0, 4) + '/' + dataX.finlnhnymd.substring(4, 6) + '/' + dataX.finlnhnymd.substring(6,8);
      }else{
        //開発環境のみ
        row.JYOTOYMD = '20230921';
        row.JYOTOYMD2 = '2023/09/21';
        console.log('テスト譲渡日設定02:'+`http://10.0.63.10/olive/oasis_plus/menu/denpyo/trans/trans_GetShnmstFinlnhnYmd.jsp?EOSCDE=${EosCode}&MISE=${row.MISE}`);
        console.log('テスト譲渡日設定02:%o',row);
      }

      await setRow_Two_JAN1 (row,jan1cd,Jancde);

      //次の項目に遷移
      if (disJan2 === false) {
        // 活性時の場合はuseEffectは発生しない
        inJAN2.current.focus();
      } else {
        // 非活性時は、活性化の描画完了後（useEffect）にフォーカス移動させる必要がある
        setNextActive(inJAN2.current.id);
        setJan2(false);
      }
      return 0;
    }
    if(!(jan1cd === '21' || jan1cd === '22' || jan1cd === '23')){
      setJan2(true);
      row.JANCDE2 = '';
    }

    // 衣料品事業部配下分類かどうかを確認
    const getIryoFlg = await fetch('/TCGT0100/getIryoFlg',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ header:headerItem })});
    const iryo_flg = await getIryoFlg.json();

    // 衣料品フラグ = １ の場合
    if(iryo_flg.IRYOFLG === '1') {
      row.IRYO_FLG = '1';
    } else {
      row.IRYO_FLG = '0';
    }


    let shohinMaster = undefined;
    // 8桁の場合、商品マスタ（基本）にEOSとしてデータが存在するかチェック。
    if(Jancde.length === 8){
      const getShohinMaster = await fetch('/TCGT0100/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({row:row, EOSCDE:Jancde })});;
      shohinMaster = await getShohinMaster.json();

      if(shohinMaster.ret.length !== 0) {
        row.IN_CD_KBN = '2'
      }
    };

    // 8、12、13 桁の場合、商品マスタ（基本）にJANとしてデータが存在するかチェック。
    if(row.IN_CD_KBN !== '2' &&
    (Jancde.length === 8 || Jancde.length === 12 || Jancde.length === 13)) {
      const getShohinMaster = await fetch('/TCGT0100/getShohinMaster_JanCode',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({row:row, JANCDEX:Jancde })});
      shohinMaster = await getShohinMaster.json();

      if(shohinMaster.ret.length !== 0) {
        row.IN_CD_KBN = '1'
      }
    }

    if(!shohinMaster) {
      setJAN1clr('#f59d9d');
      window.alert('商品が存在しません。'); //TCGE0047：
      return 1;
    }

    // 仕入先名称取得
    if (shohinMaster.ret.length !== 0) {
      console.log('tori:'+header.SIRCDE2);
      let ret = '0';
      if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined){
        ret = await getSirmeik(shohinMaster.ret[0].SIRCDE,header,2);
        if (ret === '-1')
        {
          setJAN1clr('#f59d9d');
          window.alert('取引先コード入力エラー'); //TCGE0015：
          return 1;
        }
      }
      console.log('992:'+header.SIRCDE+'!=='+shohinMaster.ret[0].SIRCDE+'/'+ret);
      if(header.SIRCDE !== shohinMaster.ret[0].SIRCDE && ret === '0') {
        setJAN1clr('#f59d9d');
        setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
        setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
        setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
        console.log('取引先コード：'+header.SIRCDE2+'!=='+shohinMaster.ret[0].SIRCDE);
        window.alert('取引先コード入力エラー'); //TCGE0015：
        return 1;
      }

      // if(header.SIRCDE === '') {
      //   setJAN1clr('#f59d9d');
      //   window.alert('TCGE0015_02：取引先コード入力エラー');
      //   return 1;
      // }
    }

    // 譲渡日の取得
    let responseX_url = '';
    if(row.IN_CD_KBN === '1') {
      //JANの場合
      responseX_url = `http://10.0.63.10/olive/oasis_plus/menu/denpyo/trans/trans_GetShnmstFinlnhnYmd.jsp?JANCDE=${row.JANCDE}&MISE=${row.MISE}`

    } else {
      //EOSの場合
      responseX_url = `http://10.0.63.10/olive/oasis_plus/menu/denpyo/trans/trans_GetShnmstFinlnhnYmd.jsp?EOSCDE=${row.JANCDE}&MISE=${row.MISE}`
    }
    if (jan1cd === '23')
    {
      //EOSの場合
      const EosCode = row.JANCDE.substring(2, 10);
      responseX_url = `http://10.0.63.10/olive/oasis_plus/menu/denpyo/trans/trans_GetShnmstFinlnhnYmd.jsp?EOSCDE=${EosCode}&MISE=${row.MISE}`
    }

    //TODO 本番コメント化解除
    if (process.env.NODE_ENV !== 'development') {
      const responseX = await fetch('/trans_GetShnmstFinl',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({URL: responseX_url})});
      const dataX = await responseX.json();
      console.log('譲渡日設定01:%o',dataX);

      row.JYOTOYMD = dataX.finlnhnymd;
      row.JYOTOYMD2 = dataX.finlnhnymd.substring(0, 4) + '/' + dataX.finlnhnymd.substring(4, 6) + '/' + dataX.finlnhnymd.substring(6,8);
    }else{
      //開発環境のみ
      row.JYOTOYMD = '20230921';
      row.JYOTOYMD2 = '2023/09/21';
      console.log('テスト譲渡日設定01');
    }


    // ＪＡＮ１チェック
    // (1)
    if(shohinMaster.ret.length === 0) {
      setJAN1clr('#f59d9d');
      window.alert('商品が存在しません。'); //TCGE0047：
      return 1;
    }

    // (2)
    if(header.BMNCDE  !== shohinMaster.ret[0].BMNCDE) {
      console.log('header02:%o',header);
      setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE:''}));
      setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK:''}));
      setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU:''}));
      setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
      setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
      setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
      setJAN1clr('#f59d9d');
      window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
      return 1;
    }

    // (3)
    if(shohinMaster.ret[0].HPN_FKA_KBN === '1') {
      if(!(window.confirm('警告：この商品は返品不可扱いです。強制返品しますか？'))){  //TCGC0003：
        setJAN1clr('#f59d9d');
        return 1;
      }
    }

    // データセット（1段）
    await setRow_One(row,shohinMaster.ret[0],Jancde);
    // ＪＡＮ入力後チェック
    onKeyDown_JANCheck(row, inputNxRef);
  }

  // データセット（1段）
  function setRow_One(row,shohinMaster,jancde){
    row.SHNMEIKN = shohinMaster.SHNMEIKN;      // 商品名
    row.KIKAKUKN = shohinMaster.KIKAKUKN;      // 規格
    row.JANCDE = jancde;         // ＪＡＮコード
    row.TRIHBN = shohinMaster.TRIHBN;          // 取引先品番
    setRowSuu(row, '1');                       // 数量
    setRowGnt(row, shohinMaster.GNT);          // 原単価
    row.GNKKIN = 0;                            // 原価金額
    setRowBtn(row, shohinMaster.BTN);          // 売単価
    row.BAKKIN = 0;                            // 売価金額

    if( row.IN_CD_KBN === '2') {
      row.EOSCDE = jancde;       // ＥＯＳコード
    }

    const denKbn = headerItem.DENKBN.padStart(2, '0'); // 伝票区分コード入力値の前0詰め
    if(denKbn === '05' || denKbn === '06') {
      row.BEF_CST_AMT = shohinMaster.GNT;  // 訂正前原価
      row.BEF_SEL_AMT = shohinMaster.BTN;  // 訂正前売価
      row.AFT_CST_AMT = shohinMaster.GNT;  // 訂正後原価
      row.AFT_SEL_AMT = shohinMaster.BTN;  // 訂正後売価
    } else {
      row.BEF_CST_AMT = '0';  // 訂正前原価
      row.BEF_SEL_AMT = '0';  // 訂正前売価
      row.AFT_CST_AMT = '0';  // 訂正後原価
      row.AFT_SEL_AMT = '0';  // 訂正後売価
    }

    if(row.IRYO_FLG === '1'){
      row.TAGASTCDE = shohinMaster.ASTCDE;     // タグＡＵ
      row.TAGLINCDE = shohinMaster.LINCDE;     // タグライン
      row.TAGCLSCDE = shohinMaster.CLSCDE;     // タグクラス
      row.TAGSZN = shohinMaster.TAGSZN;        // タグシーズン
      row.TRIHBN = shohinMaster.TRIHBN;        // 取引先品番
      row.SIZCDE = shohinMaster.TAGSIZCDE;     // サイズコード
      row.TAGSIZMEI = shohinMaster.TAGSIZMEI;  // サイズ名カナ
      row.TAGCOLCDE = shohinMaster.TAGCOLCDE;  // カラーコード
      row.TAGCOLMEI = shohinMaster.TAGCOLMEI;  // カラー名カナ
    }
    setRowItem(row);
  }

  // データセット（2段 JAN1時）
  function setRow_Two_JAN1 (row, jan1cd,jancde) {
    row.IRYO_FLG = '1';                        // 衣料フラグ
    row.JANCDE = jancde;         // ＪＡＮコード
    //row.EOSCDE = jancde;         // ＥＯＳコード
    if(jan1cd === '22') {
      row.IN_CD_KBN = '3'                      // 入力コード区分
    } else {
      row.IN_CD_KBN = '4'                      // 入力コード区分
      row.EOSCDE = jancde;         // ＥＯＳコード
    }
    setRowItem(row);
  }

  // ヘッダ部２入力　ＪＡＮ２（活性時）
  const onKeyDown_JAN2 = async (inputRef, inputNxRef) => {
    setChkJAN2('1');
    let row = JSON.parse(JSON.stringify(rowItem));

    // ＪＡＮ2入力値の前0詰め
    let Jan2cde = inJAN2.current.value;
    if(Jan2cde.length === 7){
      Jan2cde = Jan2cde.padStart(8, '0');
    }
    if(9 <= Jan2cde.length && Jan2cde.length <= 12){
      Jan2cde =Jan2cde.padStart(13, '0');
    }
    row.JANCDE2 = Jan2cde;

    const jan2cdH = inJAN2.current.value.substring(0, 2);
    // state.KSACDES = '001'の場合は、前２桁が「28」、「29」以外はエラー
    if(state.KSACDES === '001' && !(jan2cdH === '28' ||jan2cdH === '29')){
      setJAN2clr('#f59d9d');
      window.alert('ＪＡＮ２入力エラー。'); //TCGE00XX：
      return 1;
    }

    // state.KSACDES = '005'の場合は、前２桁が「26」、「27」以外はエラー
    if(state.KSACDES === '005' && !(jan2cdH === '26' ||jan2cdH === '27')){
      setJAN2clr('#f59d9d');
      window.alert('ＪＡＮ２入力エラー。'); //TCGE00XX：
      return 1;
    }

    const header = await JSON.parse(JSON.stringify(headerItem));
    const jan1cd = inJAN1.current.value.substring(0, 2);
    let tagMaster = undefined;
    let shohinMaster = undefined;
    //１）ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005以外もしくは、「２３」の場合
    if((jan1cd ==='22' && state.KSACDES !== '005')|| jan1cd ==='23') {
      //(1)ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005以外の場合
      if(jan1cd ==='22' && state.KSACDES !== '005') {
        const ASTCDE = Jan2cde.substring(2, 5);                      // ＡＵ
        const LINCDE = inJAN1.current.value.substring(2, 3);         // ライン
        const CLSCDE = '000' + inJAN1.current.value.substring(3, 4); // クラス
        const TAGSZN = inJAN1.current.value.substring(4, 5);         // タグシーズン
        const TAGITM = inJAN1.current.value.substring(5, 8);         // タグアイテム
        const TAGSIZCDE = inJAN1.current.value.substring(8, 10);     // サイズコード
        const TAGCOLCDE = inJAN1.current.value.substring(10, 12);    // カラーコード

        //①タグマスタから情報取得
        const getTagMaster = await fetch('/TCGT0100/getTagMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,MISE:state.MISES,ASTCDE:ASTCDE,LINCDE:LINCDE,CLSCDE:CLSCDE,TAGSZN:TAGSZN,TAGITM:TAGITM
        })});
        tagMaster = await getTagMaster.json();

        if (tagMaster.ret.length === 0){
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          return 1;
        }

        // 仕入先名称取得
        if (tagMaster.ret.length !== 0) {
          let ret = '0';
          if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined){
            ret = await getSirmeik(tagMaster.ret[0].SIRCDE,header,2);
            if (ret === '-1')
            {
              setJAN1clr('#f59d9d');
              window.alert('取引先コード入力エラー'); //TCGE0015：
              return 1;
            }
          }
          console.log('1182:'+header.SIRCDE+'!=='+tagMaster.ret[0].SIRCDE);
          if(header.SIRCDE !== tagMaster.ret[0].SIRCDE && ret === '0') {
            setJAN1clr('#f59d9d');
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
            window.alert('取引先コード入力エラー'); //TCGE0015：
            return 1;
          }

          // 2023/10/06
          if(header.BMNCDE !== tagMaster.ret[0].BMNCDE) {
            console.log('header02:%o',header);
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
            setJAN1clr('#f59d9d');
            inJAN1.current.focus();
            window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
            return 1;
          }

          // if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined) {
          //   setJAN1clr('#f59d9d');
          //   window.alert('TCGE0015_04：取引先コード入力エラー');
          //   return 1;
          // }
        }

        //② 衣料サイズマスタから情報取得
        const getSizeMaster = await fetch('/TCGT0100/getSizeMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,BMNCDE:header.BMNCDE,ASTCDE:ASTCDE,LINCDE:LINCDE,TAGSIZCDE:TAGSIZCDE
        })});
        const sizeMaster = await getSizeMaster.json();
        if(sizeMaster.ret.length !== 0) {
          row.SIZCDE = sizeMaster.ret[0].TAGSIZCDE;     // サイズコード
          row.TAGSIZMEI = sizeMaster.ret[0].TAGSIZMEI;  // サイズ名カナ
        } else {
          row.SIZCDE = '';     // サイズコード
          row.TAGSIZMEI = '';  // サイズ名カナ
        }


        //③ 衣料カラーマスタから情報取得
        const getColorMaster = await fetch('/TCGT0100/getColorMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({KSACDE:state.KSACDES,TAGCOLCDE:TAGCOLCDE})});
        const colorMaster = await getColorMaster.json();
        if(colorMaster.ret.length !== 0) {
          row.TAGCOLCDE = colorMaster.ret[0].TAGCOLCDE; // カラーコード
          row.TAGCOLMEI = colorMaster.ret[0].TAGCOLMEI; // カラー名カナ
        } else {
          row.TAGCOLCDE = ''; // カラーコード
          row.TAGCOLMEI = ''; // カラー名カナ
        }

        setRow_Two(row, tagMaster.ret[0]);
      }

      //(2) ＪＡＮ１の前２桁が「２３」の場合
      if(jan1cd ==='23') {
        // 桁数チェック
        if(Jan2cde.length !== 13){
          setJAN2clr('#f59d9d');
          window.alert('ＪＡＮ２入力エラー。'); //TCGE0047：
          return;
        }

        //① 商品マスタ(基本)から情報取得
        const EosCode = inJAN1.current.value.substring(2, 10);
        const getShohinMaster = await fetch('/TCGT0100/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({row:rowItem, EOSCDE:EosCode})});
        shohinMaster = await getShohinMaster.json();

        if (shohinMaster.ret.length === 0){
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          return 1;
        }

        row.EOSCDE = EosCode;
        setRowGnt(row, shohinMaster.ret[0].GNT);          // 原単価
        setRowBtn(row, shohinMaster.ret[0].BTN);          // 売単価
        //② 返品不可判定
        if (shohinMaster.ret[0].HPN_FKA_KBN === '1') {
          if(!(window.confirm('警告：この商品は返品不可扱いです。強制返品しますか？'))){  //TCGC0003：
            setJAN1clr('#f59d9d');
            inJAN1.current.focus();
            setRowItem(row);
            return 1;
          }
        }

        // 仕入先名称取得
        if (shohinMaster.ret.length !== 0) {
          let ret = '0';
          if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined){
            ret = await getSirmeik(shohinMaster.ret[0].SIRCDE,header,2);
            if (ret === '-1')
            {
              setJAN1clr('#f59d9d');
              window.alert('取引先コード入力エラー'); //TCGE0015：
              return 1;
            }
          }
          console.log('1269:'+header.SIRCDE+'!=='+shohinMaster.ret[0].SIRCDE+'/'+ret);
          if(header.SIRCDE !== shohinMaster.ret[0].SIRCDE && ret === '0') {
            setJAN1clr('#f59d9d');
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
            window.alert('取引先コード入力エラー'); //TCGE0015：
            return 1;
          }

          // 2023/10/06
          if(header.BMNCDE !== shohinMaster.ret[0].BMNCDE) {
            console.log('header02:%o',header);
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
            setJAN1clr('#f59d9d');
            inJAN1.current.focus();
            window.alert('部門の異なる商品は入力できません。');   //TCGE0048：
            return 1;
          }

          // if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined) {
          //   setJAN1clr('#f59d9d');
          //   window.alert('TCGE0015_06：取引先コード入力エラー');
          //   return 1;
          // }
        }

        setRow_Two2(row, shohinMaster.ret[0]);
      }

      //(3) 計算
      const taxcde = Number(Jan2cde.substring(5, 6));
      const jankng = Number(Jan2cde.substring(6, 12));
      if(jankng !== 0){
        //①２段目バーコードの6桁目(税区分)＝0(総額)の場合
        if(taxcde === 0){
          let tax = (jankng * iryoZei) / (100 + iryoZei)
          setRowBtn(row, (jankng - tax));     // 売単価
        }

        //②２段目バーコードの6桁目(税区分)≠0(総額)の場合
        if(taxcde !== 0){
          setRowBtn(row, jankng);             // 売単価
        }
      }
      //③２段目バーコードの金額が０の場合。
      //設定済

    }

    //２）ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005もしくは、「２１」の場合
    if((jan1cd === '22' && state.KSACDES === '005') || jan1cd ==='21') {
      //(1)ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005の場合
      if(jan1cd === '22' && state.KSACDES === '005') {
        const ASTCDE = Jan2cde.substring(2, 5);                      // ＡＵ
        const LINCDE = inJAN1.current.value.substring(2, 3);         // ライン
        const CLSCDE = '000' + inJAN1.current.value.substring(3, 4); // クラス
        const TAGSZN = inJAN1.current.value.substring(4, 5);         // タグシーズン
        const TAGITM = inJAN1.current.value.substring(5, 8);         // タグアイテム
        const TAGSIZCDE = inJAN1.current.value.substring(8, 10);     // サイズコード
        const TAGCOLCDE = inJAN1.current.value.substring(10, 12);    // カラーコード

        //①タグマスタから情報取得
        const getTagMaster = await fetch('/TCGT0100/getTagMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,MISE:state.MISES,ASTCDE:ASTCDE,LINCDE:LINCDE,CLSCDE:CLSCDE,TAGSZN:TAGSZN,TAGITM:TAGITM
        })});
        tagMaster = await getTagMaster.json();

        if (tagMaster.ret.length === 0){
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          return 1;
        }

        // 仕入先名称取得
        if (tagMaster.ret.length !== 0) {
          let ret = '0';
          if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined){
            ret = await getSirmeik(tagMaster.ret[0].SIRCDE,header,2);
            if (ret === '-1')
            {
              setJAN1clr('#f59d9d');
              window.alert('取引先コード入力エラー'); //TCGE0015：
              return 1;
            }
          }
          console.log('1346:'+header.SIRCDE+'!=='+tagMaster.ret[0].SIRCDE);
          if(header.SIRCDE !== tagMaster.ret[0].SIRCDE && ret === '0') {
            setJAN1clr('#f59d9d');
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));

            window.alert('取引先コード入力エラー'); //TCGE0015：
            return 1;
          }

          // 2023/10/06
          if(header.BMNCDE !== tagMaster.ret[0].BMNCDE) {
            console.log('header02:%o',header);
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
            setJAN1clr('#f59d9d');
            inJAN1.current.focus();
            window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
            return 1;
          }

          // if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined) {
          //   setJAN1clr('#f59d9d');
          //   window.alert('TCGE0015_08：取引先コード入力エラー');
          //   return 1;
          // }
        }

        //② 衣料サイズマスタから情報取得
        const getSizeMaster = await fetch('/TCGT0100/getSizeMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,BMNCDE:header.BMNCDE,ASTCDE:ASTCDE,LINCDE:LINCDE,TAGSIZCDE:TAGSIZCDE
        })});
        const sizeMaster = await getSizeMaster.json();
        if(sizeMaster.ret.length !== 0) {
          row.SIZCDE = sizeMaster.ret[0].TAGSIZCDE;     // サイズコード
          row.TAGSIZMEI = sizeMaster.ret[0].TAGSIZMEI;  // サイズ名カナ
        } else {
          row.SIZCDE = '';     // サイズコード
          row.TAGSIZMEI = '';  // サイズ名カナ
        }

        //③ 衣料カラーマスタから情報取得
        const getColorMaster = await fetch('/TCGT0100/getColorMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({KSACDE:state.KSACDES,TAGCOLCDE:TAGCOLCDE})});
        const colorMaster = await getColorMaster.json();
        if(colorMaster.ret.length !== 0) {
          row.TAGCOLCDE = colorMaster.ret[0].TAGCOLCDE; // カラーコード
          row.TAGCOLMEI = colorMaster.ret[0].TAGCOLMEI; // カラー名カナ
        } else {
          row.TAGCOLCDE = ''; // カラーコード
          row.TAGCOLMEI = ''; // カラー名カナ
        }

        setRow_Two(row, tagMaster.ret[0]);
      }
      //(2) ＪＡＮ１の前２桁が「２１」の場合
      if (jan1cd === '21') {
        // 桁数チェック
        if(Jan2cde.length !== 13){
          setJAN2clr('#f59d9d');
          window.alert('ＪＡＮ２入力エラー。'); //TCGE0047：
          return;
        }

        //① 商品マスタ(基本)から情報取得
        const EosCode = inJAN1.current.value.substring(4, 12);
        const getShohinMaster = await fetch('/TCGT0100/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({row:rowItem, EOSCDE:EosCode})});
        shohinMaster = await getShohinMaster.json();

        if (shohinMaster.ret.length === 0) {
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          return 1;
        }

        row.EOSCDE = EosCode;
        setRowGnt(row, shohinMaster.ret[0].GNT);           // 原単価
        setRowBtn(row, shohinMaster.ret[0].BTN);           // 売単価

        //② 返品不可判定
        if (shohinMaster.ret[0].HPN_FKA_KBN === '1') {
          if(!(window.confirm('警告：この商品は返品不可扱いです。強制返品しますか？'))){  //TCGC0003：
            setJAN1clr('#f59d9d');
            inJAN1.current.focus();
            setRowItem(row);
            return 1;
          }
        }

        // 仕入先名称取得
        if (shohinMaster.ret.length !== 0) {
          let ret = '0';
          if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined){
            ret = await getSirmeik(shohinMaster.ret[0].SIRCDE,header,2);
            if (ret === '-1')
            {
              setJAN1clr('#f59d9d');
              window.alert('取引先コード入力エラー'); //TCGE0015：
              return 1;
            }

          }
          console.log('1435:'+header.SIRCDE+'!=='+shohinMaster.ret[0].SIRCDE);
          if(header.SIRCDE !== shohinMaster.ret[0].SIRCDE && ret === '0') {
            setJAN1clr('#f59d9d');
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
            window.alert('取引先コード入力エラー'); //TCGE0015：
            return 1;
          }

          // 2023/10/06
          if(header.BMNCDE !== shohinMaster.ret[0].BMNCDE) {
            console.log('header02:%o',header);
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
            setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
            setJAN1clr('#f59d9d');
            inJAN1.current.focus();
            window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
            return 1;
          }

          // if(header.SIRCDE2 === '' || header.SIRCDE2 === undefined) {
          //   setJAN1clr('#f59d9d');
          //   window.alert('TCGE0015_10：取引先コード入力エラー');
          //   return 1;
          // }
        }

        setRow_Two2(row, shohinMaster.ret[0]);
      }

      //(3) 計算
      const jankng = Number(Jan2cde.substring(6, 12));
      setRowBtn(row, jankng);           // 売単価
    }

    setRowItem(row);
    //ＪＡＮ入力後チェック
    onKeyDown_JANCheck(row, inputNxRef);
  }

  // データセット（2段）
  function setRow_Two(row, tagMaster) {
    row.SHNMEIKN = tagMaster.SHNMEIKN;  // 商品名
    row.KIKAKUKN = tagMaster.KIKAKUKN;  // 規格
    setRowSuu(row, '1');                // 数量
    setRowGnt(row, tagMaster.GNK);      // 原単価
    row.GNKKIN = 0;                     // 原価金額
    setRowBtn(row, tagMaster.BAK);      // 売単価
    row.BAKKIN = 0;                     // 売価金額
    //row.JANCDE2 = inJAN2.current.value; // ＪＡＮコード２

    const denKbn = headerItem.DENKBN.padStart(2, '0'); // 伝票区分コード入力値の前0詰め
    if(denKbn === '05' || denKbn === '06') {
      row.BEF_CST_AMT = tagMaster.GNK;  // 訂正前原価
      row.BEF_SEL_AMT = tagMaster.BAK;  // 訂正前売価
      row.AFT_CST_AMT = tagMaster.GNK;  // 訂正後原価
      row.AFT_SEL_AMT = tagMaster.BAK;  // 訂正後売価
    } else {
      row.BEF_CST_AMT = '0';  // 訂正前原価
      row.BEF_SEL_AMT = '0';  // 訂正前売価
      row.AFT_CST_AMT = '0';  // 訂正後原価
      row.AFT_SEL_AMT = '0';  // 訂正後売価
    }

    row.TAGASTCDE = tagMaster.ASTCDE;   // タグＡＵ
    row.TAGLINCDE = tagMaster.LINCDE;   // タグライン
    row.TAGCLSCDE = tagMaster.CLSCDE;   // タグクラス
    row.TAGSZN = tagMaster.TAGSZN;      // タグシーズン
    row.TAGITM = tagMaster.TAGITM;      // タグアイテム
    row.TRIHBN = tagMaster.TRIHBN;      // 取引先品番
  }

  // データセット（2段）2
  function setRow_Two2(row, shohinMaster) {
    row.SHNMEIKN = shohinMaster.SHNMEIKN;    // 商品名
    row.KIKAKUKN = shohinMaster.KIKAKUKN;    // 規格
    setRowSuu(row, '1');                     // 数量
    row.GNKKIN = 0;                          // 原価金額
    row.BAKKIN = 0;                          // 売価金額
    //row.JANCDE2 = inJAN2.current.value;      // ＪＡＮコード２

    const denKbn = headerItem.DENKBN.padStart(2, '0'); // 伝票区分コード入力値の前0詰め
    if(denKbn === '05' || denKbn === '06') {
      row.BEF_CST_AMT = shohinMaster.GNT;  // 訂正前原価
      row.BEF_SEL_AMT = shohinMaster.BTN;  // 訂正前売価
      row.AFT_CST_AMT = shohinMaster.GNT;  // 訂正後原価
      row.AFT_SEL_AMT = shohinMaster.BTN;  // 訂正後売価
    } else {
      row.BEF_CST_AMT = '0';  // 訂正前原価
      row.BEF_SEL_AMT = '0';  // 訂正前売価
      row.AFT_CST_AMT = '0';  // 訂正後原価
      row.AFT_SEL_AMT = '0';  // 訂正後売価
    }

    row.TAGASTCDE = shohinMaster.ASTCDE;     // タグＡＵ
    row.TAGLINCDE = shohinMaster.LINCDE;     // タグライン
    row.TAGCLSCDE = shohinMaster.CLSCDE;     // タグクラス
    row.TAGSZN = shohinMaster.TAGSZN;        // タグシーズン
    row.TRIHBN = shohinMaster.TRIHBN;        // 取引先品番
    row.SIZCDE = shohinMaster.TAGSIZCDE;     // サイズコード
    row.TAGSIZMEI = shohinMaster.TAGSIZMEI;  // サイズ名カナ
    row.TAGCOLCDE = shohinMaster.TAGCOLCDE;  // カラーコード
    row.TAGCOLMEI = shohinMaster.TAGCOLMEI;  // カラー名カナ
  }

  // ヘッダ部２入力　ＪＡＮ入力後チェック
  const onKeyDown_JANCheck = async (row, inputNxRef) => {
    setDisRow(false);
    // ＪＡＮ２処理　内　ＪＡＮ入力後チェック
    const denKbn = inDENKBN.current.value.padStart(2, '0'); // 伝票区分コード入力値の前0詰め

    // (1) 関係チェック１
    // 売単価 ＞ 原単価 × 10 本体と税込みがある
    if(row.BTN > row.GNT * 10) {
      if(window.confirm('売単価が原単価に比べて大きすぎます。よろしいですか？')){ //TCGC0005：
        inputNxRef.current.focus();
        return;
      }else{
        setGNTclr('#f59d9d');
        inGNT.current.focus();
        return;
      }
    }

    // (2) 関係チェック２
    // 売単価 ＜ 原単価 ÷ 10 本体と税込みがある
    if(row.BTN < row.GNT / 10) {
      if(window.confirm('売単価が原単価に比べて小さすぎます。よろしいですか？')){ //TCGC0006：
        inputNxRef.current.focus();
        return;
      }else{
        setGNTclr('#f59d9d');
        inGNT.current.focus();
        return;
      }
    }

    // (3)訂正後価格チェック
    console.log('訂正後価格チェック');
    // 旧売価(訂正前売価)
    if(row.JANCDE2 !== ''){
      row.BEF_SEL_AMT = Number(row.JANCDE2.substring(6, 12));
    }

    console.log('商談書：原価率:'+syodanData.GNKRTU);
    console.log('商談書：売価率:'+syodanData.BAKRTU);
    console.log('商談書：差引原価:'+syodanData.SPKGNK);
    console.log('商談書：差引売価:'+syodanData.SPKBAK);
    if(denKbn === '05'){

      // 金額、％指定 無し
      if(Number(syodanData.SPKBAK) === 0  // 差引売価
      && Number(syodanData.SPKGNK) === 0  // 差引原価
      && Number(syodanData.BAKRTU) === 0  // 売価率
      && Number(syodanData.GNKRTU) === 0  // 原価率
      ){
        row.GNT = 0;        // 原単価
        row.GNT2 = '';      // 原単価2
        setRowBtn(row, 0);  // 売単価
        row.AFT_CST_AMT = row.BEF_CST_AMT;    // 訂正後原価
        row.AFT_SEL_AMT = row.BEF_SEL_AMT;    // 訂正後売価

        console.log('旧原価:'+row.BEF_CST_AMT);
        console.log('旧売価:'+row.BEF_SEL_AMT);
        console.log('新原価:'+row.AFT_CST_AMT);
        console.log('新売価:'+row.AFT_SEL_AMT);
      }

      // 金額指定
      else if(Number(syodanData.SPKGNK) > 0  // 差引原価
      ){
        row.AFT_CST_AMT = Number(row.BEF_CST_AMT) - Number(syodanData.SPKGNK);           // 訂正後原価
        setRowGnt(row, syodanData.SPKGNK);           // 原単価
        setRowBtn(row, 0);  // 売単価
        row.AFT_SEL_AMT = row.BEF_SEL_AMT;   // 訂正後売価

        console.log('旧原価:'+row.BEF_CST_AMT);
        console.log('旧売価:'+row.BEF_SEL_AMT);
        console.log('新原価:'+row.AFT_CST_AMT);
        console.log('新売価:'+row.AFT_SEL_AMT);

        //  旧原価＜=(C) 差引原価 の場合
        if(Number(row.BEF_CST_AMT) <= Number(syodanData.SPKGNK)){
          window.alert('商談書データから算出した新原価が０以下になりました。'); //TCGE0051：
          //row.BTN = 0;     // 売単価
          row.GNT = 0;     // 原単価
          //row.BTN2 = '';   // 売単価2
          row.GNT2 = '';   // 原単価2
          setRowItem(row);
          inSUU.current.focus();
          //setTimeout(() => {inSUU.current.select();},100);
          onNextFocus(inSUU);
          return;
        }
      }

      // ％指定（掛率）
      else if(Number(syodanData.GNKRTU) > 0  // 原価率
      ){
        // 新原価
        let new_genka = Number(row.BEF_SEL_AMT) * Number(syodanData.GNKRTU) / 100;
        if(row.IRYO_FLG === '1'){
          new_genka = Math.floor(new_genka);
        } else {
          new_genka = Math.floor(new_genka * 100) / 100;
        }
        setRowGnt(row, (Number(row.BEF_CST_AMT) - new_genka));  // 原単価
        setRowBtn(row, 0);    // 売単価
        row.AFT_CST_AMT = new_genka;         // 訂正後原価
        row.AFT_SEL_AMT = row.BEF_SEL_AMT;   // 訂正後売価

        console.log('旧原価:'+row.BEF_CST_AMT);
        console.log('旧売価:'+row.BEF_SEL_AMT);
        console.log('新原価:'+row.AFT_CST_AMT);
        console.log('新売価:'+row.AFT_SEL_AMT);
        // エラーチェック
        if(Number(row.BEF_CST_AMT) <= new_genka){
          window.alert('商談書データから算出した原単価が０以下になりました。'); //TCGE0054：
          //row.BTN = 0;     // 売単価
          row.GNT = 0;     // 原単価
          //row.BTN2 = '';   // 売単価2
          row.GNT2 = '';   // 原単価2
          setRowItem(row);
          inSUU.current.focus();
          //setTimeout(() => {inSUU.current.select();},100);
          onNextFocus(inSUU);
          return;
        }
      }
      setRowItem(row);
    }

    if(denKbn === '06'){
      // 金額、％指定 無し
      if(Number(syodanData.SPKBAK) === 0  // 差引売価
      && Number(syodanData.SPKGNK) === 0  // 差引原価
      && Number(syodanData.BAKRTU) === 0  // 売価率
      && Number(syodanData.GNKRTU) === 0  // 原価率
      ){
        row.BTN = 0;     // 売単価
        row.GNT = 0;     // 原単価
        row.BTN2 = '';   // 売単価2
        row.GNT2 = '';   // 原単価2
        row.AFT_CST_AMT = row.BEF_CST_AMT;   // 訂正後原価
        row.AFT_SEL_AMT = row.BEF_SEL_AMT;   // 訂正後売価

        console.log('旧原価:'+row.BEF_CST_AMT);
        console.log('旧売価:'+row.BEF_SEL_AMT);
        console.log('新原価:'+row.AFT_CST_AMT);
        console.log('新売価:'+row.AFT_SEL_AMT);
      }

      // 金額指定
      else if(Number(syodanData.SPKBAK) > 0   // 差引売価
      && Number(syodanData.SPKGNK) > 0        // 差引原価
      ){

        row.AFT_CST_AMT = Number(row.BEF_CST_AMT) - Number(syodanData.SPKGNK);          // 訂正後原価
        row.AFT_SEL_AMT = Number(row.BEF_SEL_AMT) - Number(syodanData.SPKBAK);          // 訂正後売価

        setRowGnt(row, syodanData.SPKGNK);           // 原単価
        setRowBtn(row, syodanData.SPKBAK);           // 売単価

        console.log('旧原価:'+row.BEF_CST_AMT);
        console.log('旧売価:'+row.BEF_SEL_AMT);
        console.log('新原価:'+row.AFT_CST_AMT);
        console.log('新売価:'+row.AFT_SEL_AMT);

        // 旧原価＜=(C) 差引原価　かつ　旧売価＜=(C) 差引売価の場合
        if(Number(row.BEF_CST_AMT) <= Number(syodanData.SPKGNK)
          && Number(row.BEF_SEL_AMT) <= Number(syodanData.SPKBAK)){
          window.alert('商談書データから算出した新原価と新売価が０以下になりました。'); //TCGE0053：
          row.BTN = 0;     // 売単価
          row.GNT = 0;     // 原単価
          row.BTN2 = '';   // 売単価2
          row.GNT2 = '';   // 原単価2
          setRowItem(row);
          inSUU.current.focus();
          //setTimeout(() => {inSUU.current.select();},100);
          onNextFocus(inSUU);
          return;
        }

        // 旧原価＜=(C) 差引原価 の場合
        if(Number(row.BEF_CST_AMT) <= Number(syodanData.SPKGNK)){
          window.alert('商談書データから算出した新原価が０以下になりました。'); //TCGE0051：
          //row.BTN = 0;     // 売単価
          row.GNT = 0;     // 原単価
          //row.BTN2 = '';   // 売単価2
          row.GNT2 = '';   // 原単価2
          setRowItem(row);
          inSUU.current.focus();
          //setTimeout(() => {inSUU.current.select();},100);
          onNextFocus(inSUU);
          return;
        }

        // 旧売価＜=(C) 差引売価 の場合
        if(Number(row.BEF_SEL_AMT) <= Number(syodanData.SPKBAK)){
          window.alert('商談書データから算出した新売価が０以下になりました。'); //TCGE0052：
          row.BTN = 0;     // 売単価
          //row.GNT = 0;     // 原単価
          row.BTN2 = '';   // 売単価2
          //row.GNT2 = '';   // 原単価2
          setRowItem(row);
          inSUU.current.focus();
          //setTimeout(() => {inSUU.current.select();},100);
          onNextFocus(inSUU);
          return;
        }
      }

      // ％指定（掛率）
      else if(Number(syodanData.BAKRTU) > 0   // 売価率
      && Number(syodanData.GNKRTU) > 0        // 原価率
      ){
        // 新売価
        let new_baika = Number(row.BEF_SEL_AMT) * Number(syodanData.BAKRTU) / 100;

        // 新原価
        let new_genka = new_baika * Number(syodanData.GNKRTU) / 100;
        if(row.IRYO_FLG === '1'){
          new_genka = Math.floor(new_genka);
        } else {
          new_genka = Math.floor(new_genka * 100) / 100;
        }

        row.AFT_CST_AMT = new_genka;          // 訂正後原価
        row.AFT_SEL_AMT = new_baika;          // 訂正後売価
        setRowGnt(row, (Number(row.BEF_CST_AMT) - new_genka));           // 原単価
        setRowBtn(row, (Number(row.BEF_SEL_AMT) - new_baika));           // 売単価

        console.log('旧原価:'+row.BEF_CST_AMT);
        console.log('旧売価:'+row.BEF_SEL_AMT);
        console.log('新原価:'+row.AFT_CST_AMT);
        console.log('新売価:'+row.AFT_SEL_AMT);

        // エラーチェック
        if(Number(row.BEF_CST_AMT) <= new_genka){
          window.alert('商談書データから算出した原単価が０以下になりました。'); //TCGE0054：
          //row.BTN = 0;     // 売単価
          row.GNT = 0;     // 原単価
          //row.BTN2 = '';   // 売単価2
          row.GNT2 = '';   // 原単価2
          setRowItem(row);
          inSUU.current.focus();
          //setTimeout(() => {inSUU.current.select();},100);
          onNextFocus(inSUU);
          return;
        }
      }
      setRowItem(row);
    }

    //次の項目に遷移
    //inputNxRef.current.focus();
    if (inSUU.current.value === '1.0') {
      // 表示が変わらない場合はuseEffectは発生しない
      inSUU.current.focus();
    } else {
      // 表示が変わるときは、数量の描画完了後（useEffect）にフォーカス移動させる必要がある
      setNextActive(inSUU.current.id);
    }
  }

  // ヘッダ部２入力 数量
  const onKeyDown_SUU = async(inputRef, inputNxRef) => {
    if(rowItem.SUU === 0 || rowItem.SUU2 === '') {
      setSUUclr('#f59d9d');
      window.alert('数量に０は入力できません。'); //TCGE0063：
      return;
    }
    //次の項目に遷移
    inputNxRef.current.focus();
  }

  // ヘッダ部２入力　原単価
  const onKeyDown_GNT =  async (inputRef, inputNxRef) => {
    if(rowItem.SUU === 0 || rowItem.SUU2 === '') {
      setSUUclr('#f59d9d');
      window.alert('数量に０は入力できません。'); //TCGE0063：
      return;
    }
    // (1) 数値チェック
    if(rowItem.GNT === 0 || rowItem.GNT2 === '') {
      setGNTclr('#f59d9d');
      window.alert('原単価に０円は入力できません。'); //TCGE0049：
      return;
    }

    //  四捨五入
    let gnkkin = rowItem.SUU * rowItem.GNT;
    gnkkin = Math.round(gnkkin);

    // (2) 1千万越えチェック
    if(gnkkin > 10000000){
      setGNTclr('#f59d9d');
      setSUUclr('#f59d9d');
      inSUU.current.focus();
      window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
      return;
    }

    // (3) 100万越えチェック
    if(gnkkin > 1000000){
      if(!(window.confirm('１００万円を超える金額が入力されました。'))){  //TTCGC0004：
        setGNTclr('#f59d9d');
        return;
      }
    }

    // (4) 伝票区分='05' OR 伝票区分='06'
    const denKbn = inDENKBN.current.value.padStart(2, '0'); // 伝票区分コード入力値の前0詰め
    if(denKbn ==='05' || denKbn ==='06'){
      //旧原価＜=画面.原単価
      if(Number(rowItem.BEF_CST_AMT) <= rowItem.GNT){
        setGNTclr('#f59d9d');
        window.alert('新原価が０以下になる原単価は入力できません。（旧原価－原単価＜＝０）'); //TCGE0055：
        return;
      }
    }

    //次の項目に遷移
    inputNxRef.current.focus();
  }

  // ヘッダ部２入力　売単価
  const onKeyDown_BTN =  async (inputRef, inputNxRef) => {
    const denKbn = inDENKBN.current.value.padStart(2, '0'); // 伝票区分コード入力値の前0詰め

    const header = await JSON.parse(JSON.stringify(headerItem));

    // (4) 伝票区分='05'
    if(denKbn ==='05') {
      if(rowItem.BTN !== 0) {
        setBTNclr('#f59d9d');
        window.alert('売単価は０以外入力できません。'); //TCGE0056：
        return;
      } else {
        //次の項目に遷移
        inputNxRef.current.focus();
        return;
      }
    }

    // (1) 数値チェック
    if(header.GNKKNRFLG === '0' && (rowItem.BTN === 0 || rowItem.BTN2 === '')){
      if(!window.confirm('売単価に０円が入力されています。よろしいですか？')){  //TCGC0007：
        setBTNclr('#f59d9d');
        return;
      } else {
        inputNxRef.current.focus();
        return;
      }
    }

    // 追加(2) 売単価 ＞ 原単価×10　チェック
    if (rowItem.BTN > (rowItem.GNT * 10)) {
      if(!window.confirm('売単価が原単価に比べて大きすぎます。よろしいですか？')){  //TCGC0008：
        setBTNclr('#f59d9d');
        return;
      }
    };

    // 追加(3) 売単価 ＜ 原単価÷10 チェック
    if (rowItem.BTN < (rowItem.GNT / 10)) {
      if(!window.confirm('売単価が原単価に比べて小さすぎます。よろしいですか？')){  //TCGC0009：
        setBTNclr('#f59d9d');
        return;
      }
    };


    const bakkin = rowItem.SUU * rowItem.BTN;

    // (2) 1千万越えチェック
    if(bakkin > 10000000){
      setBTNclr('#f59d9d');
      setSUUclr('#f59d9d');
      inSUU.current.focus();
      window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
      return;
    }

    // (3) 100万越えチェック
    if(bakkin > 1000000){
      if(!(window.confirm('１００万円を超える金額が入力されました。'))){  //TTCGC0004：
        setBTNclr('#f59d9d');
        return;
      }
    }


    // (4) 伝票区分='06'
    if(denKbn ==='06' && Number(rowItem.BEF_SEL_AMT) <= rowItem.BTN){
      setBTNclr('#f59d9d');
      window.alert('新売価が０以下になる売単価は入力できません。（旧売価－売単価＜＝０）'); //TCGE0057：
      return;
    }

    //次の項目に遷移
    inputNxRef.current.focus();
  }

  // ヘッダ部２入力　譲渡日
  const onKeyDown_JYOTOYMD =  async () => {
    let dt1 = new Date(headerItem.HZK2);  // ヘッダ年月日
    let dt2 = new Date('2023/10/01');   // インボイス開始日（2023/10/01）
    if (dt1 >= dt2){
      // 必須チェック
      if(rowItem.JYOTOYMD2 ==='') {
        setJYOTOYMDclr('#f59d9d');
        window.alert('譲渡日を入力して下さい。');
        return;
      }
    }

    if(rowItem.JYOTOYMD2 !==''){
      // 日付チェック
      if(ckDate(rowItem.JYOTOYMD2)===false){
        setJYOTOYMDclr('#f59d9d');
        window.alert('TCGE0016:日付入力エラー');
        return;
      }

      // 2023/10/16 システム日付からヘッダ１の日付に変更
      // システム日－7年 ≦入力日（西暦）＜ヘッダ１.日付の日付範囲チェック
      let indate = new Date(rowItem.JYOTOYMD2);
      let maxdate = dt1;
      let mindate = new Date(sdate);
      mindate.setFullYear(mindate.getFullYear()-7);
      //console.log('日付範囲チェック'+maxdate+'>='+indate+'&&'+mindate+'<='+indate);
      if (indate > maxdate ||  mindate > indate) {
        setJYOTOYMDclr('#f59d9d');
        window.alert('TCGE0016:日付入力エラー');
        return;
      }
    }
    console.log('Header2_confirmed_start');
    Header2_confirmed();
  }

  // ヘッダ部２入力　ヘッダ部２確定時
  const Header2_confirmed = async () => {
    // rowItemをコピーして使用
    const row = JSON.parse(JSON.stringify(rowItem));
    const header = JSON.parse(JSON.stringify(headerItem));

    // 入力チェック 取引先コード
    if (header.SIRCDE !== header.SIRCDE2) {
      setJAN1clr('#f59d9d');
      window.alert('取引先コード入力エラー'); //TCGE0015：
      inJAN1.current.focus();
      return;
    }

    // ＪＡＮ１必須チェック
    if(row.JANCDE === '') {
      setJAN1clr('#f59d9d');
      window.alert('ＪＡＮ１を入力して下さい。');
      inJAN1.current.focus();
      return;
    }

    // 計算
    row.GNKKIN = Math.round(row.SUU * row.GNT); // 原価金額
    row.BAKKIN = row.SUU * row.BTN;             // 売価金額
    row.AFT_CST_AMT = row.BEF_CST_AMT - row.GNT;
    row.AFT_SEL_AMT = row.BEF_SEL_AMT - row.BTN;
    if (!(header.DENKBN==='05' || header.DENKBN==='06')) {
      row.AFT_CST_AMT = 0;
      row.BEF_CST_AMT = 0;
      row.AFT_SEL_AMT = 0;
      row.BEF_SEL_AMT = 0;
    }

    // 入力チェック 数量
    if(row.SUU === 0 || row.SUU2 === '') {
      setSUUclr('#f59d9d');
      window.alert('数量に０は入力できません。'); //TCGE0063：
      inSUU.current.focus();
      return;
    }

    // 入力チェック 原単価
    if(row.GNT === 0 || row.GNT2 === '') {
      setGNTclr('#f59d9d');
      window.alert('原単価に０円は入力できません。'); //TCGE0049：
      inGNT.current.focus();
      return;
    }
    // 1千万越えチェック
    if(row.GNKKIN > 10000000){
      setGNTclr('#f59d9d');
      setSUUclr('#f59d9d');
      inSUU.current.focus();
      window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
      return;
    }

    if(header.DENKBN==='05' || header.DENKBN==='06'){
      //旧原価＜=画面.原単価
      if(Number(row.BEF_CST_AMT) <= row.GNT){
        setGNTclr('#f59d9d');
        window.alert('新原価が０以下になる原単価は入力できません。（旧原価－原単価＜＝０）'); //TCGE0055：
        inGNT.current.focus();
        return;
      }
    }

    // 入力チェック 売単価
    if(header.DENKBN ==='05') {
      if(rowItem.BTN !== 0) {
        setBTNclr('#f59d9d');
        window.alert('売単価は０以外入力できません。'); //TCGE0056：
        inBTN.current.focus();
        return;
      }
    }else{
      if(!(header.GNKKNRFLG === '0' && (row.BTN === 0 || row.BTN2 === ''))){
        // 1千万越えチェック
        if(row.BAKKIN > 10000000){
          setBTNclr('#f59d9d');
          setSUUclr('#f59d9d');
          window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
          inBTN.current.focus();
          return;
        }
        if(header.DENKBN  ==='06' && Number(row.BEF_SEL_AMT) <= row.BTN){
          setBTNclr('#f59d9d');
          window.alert('新売価が０以下になる売単価は入力できません。（旧売価－売単価＜＝０）'); //TCGE0057：
          inBTN.current.focus();
          return;
        }
      }
    }

    const selected = rows.filter(x => selectionModel.indexOf(x.id) != -1);
    // 新規追加時の処理
    if(selected.length !== 1) {
      // 入力内容の重複確認
      let new_rows = JSON.parse(JSON.stringify(rows));
      const old_row = new_rows.filter(element => {
        return element.JANCDE === row.JANCDE
        && element.GNT === row.GNT
        && element.BTN === row.BTN
      });

      // 既に入力済の内容であれば合算値を確認する
      if (old_row.length !== 0){
        // 合計数量計算
        let totalSuu = row.SUU;
        for(const ckRow of old_row) {
          totalSuu = totalSuu + ckRow.SUU;
        }

        // 原価金額合計 金額オーバーチェック
        let ckGnkkin = Math.round(totalSuu * row.GNT);
        if(ckGnkkin > 10000000){
          setGNTclr('#f59d9d');
          window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
          inSUU.current.focus();
          return;
        }

        // 売価金額合計 金額オーバーチェック
        let ckBakkin = totalSuu * row.BTN;
        if(ckBakkin > 10000000){
          setBTNclr('#f59d9d');
          window.alert('合計で１千万円を超える金額は入力できません。'); //TCGE0060：
          inSUU.current.focus();
          return;
        }
      }

      // 編集中の明細にidを設定する。
      row.id = rows.length + 1;

      // 明細リストの合計を再計算する。
      header.SRYFOT = Number(header.SRYFOT) + row.SUU;
      header.GNKFOT = Number(header.GNKFOT) + Number(row.GNKKIN);
      header.BAKFOT = Number(header.BAKFOT) + Number(row.BAKKIN);
      header.SIRCDE2 = '';
      header.SIRMEIK2 = '';
      header.ZEIRTU2 = '';

      // 編集中データを反映する。
      setRowItem(rowData);
      setRows((rows) => ([...rows, row]));
      setHeaderItem(header);
      inJAN1.current.focus();
    }
    // 編集時の処理
    else {
      // 編集中の明細にidを設定する。
      row.id = selected[0].id;

      // 新しく保存する明細リスト
      let new_rows = JSON.parse(JSON.stringify(rows));
      new_rows = await new_rows.map((target) => (target.id === selected[0].id ? row : target));

      // 入力内容の重複確認
      const old_row = new_rows.filter(element => {
        return element.JANCDE === row.JANCDE
        && element.GNT === row.GNT
        && element.BTN === row.BTN
      });

      // 同じ値のデータ合算値を確認する
      if (old_row.length !== 0){
        // 合計数量計算
        let totalSuu = 0;
        for(const ckRow of old_row) {
          totalSuu = totalSuu + ckRow.SUU;
        }

        // 原価金額合計 金額オーバーチェック
        let ckGnkkin = Math.round(totalSuu * row.GNT);
        if(ckGnkkin > 10000000){
          setGNTclr('#f59d9d');
          window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
          inSUU.current.focus();
          return;
        }

        // 売価金額合計 金額オーバーチェック
        let ckBakkin = totalSuu * row.BTN;
        if(ckBakkin > 10000000){
          setBTNclr('#f59d9d');
          window.alert('合計で１千万円を超える金額は入力できません。'); //TCGE0060：
          inSUU.current.focus();
          return;
        }
      }

      // 明細リストの合計を再計算する。
      let totalSuryo = 0;   // 合計数量
      let totalGenka = 0;   // 原価金額合計
      let totalBaika = 0;   // 売価金額合計

      new_rows.forEach(element => {
        totalSuryo += element.SUU;
        totalGenka += Number(element.GNKKIN);
        totalBaika += Number(element.BAKKIN);
      });

      header.SRYFOT = totalSuryo;
      header.GNKFOT = totalGenka;
      header.BAKFOT = totalBaika;
      header.SIRCDE2 = '';
      header.SIRMEIK2 = '';
      header.ZEIRTU2 = '';

      // 編集中データを反映する。
      setSelectionModel([]);
      setRows(new_rows);
      setRowItem(rowData);
      setHeaderItem(header);
      inJAN1.current.focus();
    }
  }

  // フッタ部　取消
  const onClick_FooterButton_Reset = async () => {
    if(window.confirm('入力された内容を破棄します。よろしいですか？')){ //TCGM0007：
      const header = headerData;

      header.BMNCDE = headerItem.BMNCDE;
      header.BMNMEI = headerItem.BMNMEI;
      header.HZK = headerItem.HZK;
      header.HZK2 = headerItem.HZK2;
      let denkbn = headerItem.DENKBN;
      header.DENKBNNM = headerItem.DENKBNNM;
      setDHeder(false);
      setJan1(true);
      setJan2(true);
      setDisRow(true);
      setBMNCDEclr('#FFFFFF');
      setHZKclr('#FFFFFF');
      setDENKBNclr('#FFFFFF');
      setRYUNOclr('#FFFFFF');
      setSYODANclr('#FFFFFF');
      setSUUclr('#FFFFFF');
      setJAN1clr('#FFFFFF');
      setJAN2clr('#FFFFFF');
      setGNTclr('#FFFFFF');
      setBTNclr('#FFFFFF');
      setJYOTOYMDclr('#FFFFFF');
      setHeaderItem(header);
      changeDenKbn(denkbn, header);     // 23/09/25
      setRowItem(rowData);
      setRows([]);
      onKeyDown_DENKBN(inDENKBN,inRYUNO,header);
    } else {
      inJAN1.current.focus();
    }
  }

  // フッタ部　削除
  const onClick_FooterButton_Delete = async () => {
    let target_rows = JSON.parse(JSON.stringify(rows))
    const selected = target_rows.filter(x => selectionModel.indexOf(x.id) != -1);
    if(selected.length !== 1) {
      inJAN1.current.focus();
      return;
    }
    setSelectionModel([]);

    const targetRow = selected[0];

    // 除処理を行うか確認メッセージを表示する
    if (!(window.confirm(''+ targetRow.SHNMEIKN +'を削除します。よろしいですか？'))) {  //TCGM0008：
      return;
    }

    target_rows = target_rows.filter(x => x.id != targetRow.id);

    // 合計額再計算
    const header = JSON.parse(JSON.stringify(headerItem));
    let id = 1;
    let totalSuryo = 0;   // 合計数量
    let totalGenka = 0;   // 原価金額合計
    let totalBaika = 0;   // 売価金額合計
    target_rows.forEach(element => {
      element.id = id;
      totalSuryo += element.SUU;
      totalGenka += Number(element.GNKKIN);
      totalBaika += Number(element.BAKKIN);
      id += 1;
    });
    header.SRYFOT = totalSuryo;
    header.GNKFOT = totalGenka;
    header.BAKFOT = totalBaika;
    setRowItem(rowData);
    setRows(target_rows);
    setHeaderItem(header);
    inJAN1.current.focus();
  }

  // フッタ部　登録
  const onClick_FooterButton_Send = async() => {
    console.log('rows:%o',rows.length);

    if (rows.length === 0) {
      window.alert('登録するデータが存在しません。'); //TCGE0088：
      return;
    }

    if ((window.confirm('データの更新を行います。よろしいですか？'))) { //TCGM0009：
      // 重複行を合算する処理
      let combinedList = [];
      for(const add_row of rows) {
        // 入力内容の重複確認
        const matched_row = combinedList.filter(element => {
          return element.JANCDE === add_row.JANCDE
          && element.GNT === add_row.GNT
          && element.BTN === add_row.BTN
        });

        // 既に登録済の内容であれば数を合算する
        if (matched_row.length !== 0) {
          matched_row[0].SUU = matched_row[0].SUU + add_row.SUU;
          matched_row[0].GNKKIN = Math.round(matched_row[0].SUU * add_row.GNT); // 原価金額
          matched_row[0].BAKKIN = matched_row[0].SUU * add_row.BTN;             // 売価金額
          combinedList = combinedList.map((target) => (target === matched_row ? matched_row[0] : target));
        }else{
          // 登録済みで無ければ行追加
          combinedList.push(add_row);
        }
      }

      const header = JSON.parse(JSON.stringify(headerItem));

      let d = new Date(state.SDAY);
      let year = d.getFullYear().toString();
      let month = (d.getMonth() + 1).toString().padStart(2, '0');
      let day = d.getDate().toString().padStart(2, '0');
      let hours = d.getHours().toString().padStart(2, '0')
      let minutes = d.getMinutes().toString().padStart(2, '0')
      let seconds = d.getSeconds().toString().padStart(2, '0')
      header.DENYMD = year + month + day;
      header.IN_DATE = year + month + day + hours + minutes + seconds;

      // SEQの取得
      const getSeqNo = await fetch('/TCGT0100/getSeqNo',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({header:header})});
      const resseqno = await getSeqNo.json();

      // 登録用のボディデータ作成
      const updateHeder = [];
      const updateRows = [];
      let pushRowlist = [];
      let denno = await getNowDenno(header);
      let hederSEQ = Number(resseqno.SEQ) + 1;
      let checkGenka = 0;
      let checkBaika = 0;
      let count = 1;
      // 10/13 登録時のバグ修正
      //await Promise.all(rows.map(async(row) =>{
      for(const row of combinedList){
        console.log('row:%o',row);
        if(count > 6
            || checkGenka + Number(row.GNKKIN) > 10000000
            || checkBaika + Number(row.BAKKIN) > 10000000
        ) {
          // 6件、もしくは合計原価、合計売価が1千万を超える場合とSEQ番号を変更する。
          updateRows.push(pushRowlist);
          pushRowlist = [];
          count = 1;
          checkGenka = 0;
          checkBaika = 0;
          denno = await getNowDenno(header);
        }
        row.REC_NO = count;
        row.SEQ = hederSEQ;
        row.DENNO = denno;
        row.BMNCDE = header.BMNCDE;
        row.DENYMD = header.DENYMD;
        row.DEN_EDABAN = header.DEN_EDABAN;
        row.JGNCDE = header.JGNCDE;
        row.PRG_ID = header.PRG_ID;

        checkGenka += Number(row.GNKKIN);
        checkBaika += Number(row.BAKKIN);
        pushRowlist.push(row);
        count++;
      }
      //}));
      updateRows.push(pushRowlist);

      // 登録用のヘッダデータ作成
      updateRows.forEach(rowsList => {
        const pushHeder = JSON.parse(JSON.stringify(header));
        pushHeder.SEQ = rowsList[0].SEQ;
        pushHeder.DENNO = rowsList[0].DENNO;

        // 伝票区分名の再取得
        pushHeder.DENKBNNM = retDenKbn(pushHeder.DENKBN);

        let totalSuryo = 0;   // 合計数量
        let totalGenka = 0;   // 原価金額合計
        let totalBaika = 0;   // 売価金額合計
        rowsList.forEach(row => {
          totalSuryo += row.SUU;
          totalGenka += Number(row.GNKKIN);
          totalBaika += Number(row.BAKKIN);
        });
        pushHeder.SRYFOT = totalSuryo;
        pushHeder.GNKFOT = totalGenka;
        pushHeder.BAKFOT = totalBaika;
        updateHeder.push(pushHeder);
      })

      // データの更新
      const response = await fetch('/TCGT0100/send',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
        header:updateHeder,
        rows:updateRows,
      })});

      const data = await response.json();
      if (data.res === -1) {
        window.alert('伝票データ修正エラー'); //TCGE0024：
        return;
      }else{
        // 正常終了
        const header2 = headerData;
        header2.BMNCDE = header.BMNCDE;
        header2.BMNMEI = header.BMNMEI;
        header2.HZK = header.HZK;
        header2.HZK2 = header.HZK2;
        header2.DENKBN = header.DENKBN;
        header2.DENKBNNM = header.DENKBNNM;
        setHeaderItem(header2);
        setDHeder(false);
        setJan1(true);
        setJan2(true);
        setDisRow(true);
        setDisRyuno(false);
        changeDenKbn(header2.DENKBN, header2);     // 23/09/25
        setRowItem(rowData);
        setRows([]);
        onKeyDown_DENKBN(inDENKBN,inRYUNO,header2);
        return;
      }
    } else {
      inJAN1.current.focus();
    }
  }

  const getNowDenno = async(header) => {
    // 採番マスタ取得
    let denpyoNo = {
      KSACDE:header.KSACDE,     // 会社コード
      MISE:header.MISE,         // 店コード
      MR_KBN:'2',               // 移動返品区分
      MIN_VLU:'1',              // 最小値
      MAX_VLU:'999',            // 最大値
      JGNCDE:header.JGNCDE,     // ユーザーID
      PRG_ID:header.PRG_ID,     // プログラムID
      TRL_ID:header.TRL_ID,     // 端末ＩＤ
      DELFLG:'0'                // 削除フラグ
    };

    // 採番マスタ取得
    const getDenNo = await fetch('/TCGT0100/getDenNo',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({denpyoNo:denpyoNo})});
    const resdenno = await getDenNo.json();
    const NOW_DENNO = resdenno.denno.toString().padStart(3, '0');

    // 伝票番号の取得
    let denno_A = state.MISES + NOW_DENNO;
    let denno_B = Number(denno_A) / 7;
    let denno_C = Math.floor(denno_B) * 7;
    let checkDigit = Number(denno_A) - denno_C;
    let updateDenpyoNo = denno_A + checkDigit.toString();
    return updateDenpyoNo;
  }

  // 仕入先名称取得
  const getSirmeik =  async (sircode,header,lflg) => {
    //header.SIRCDE2 = sircode ?? '';
    const getSirmeik = await fetch('/TCGT0100/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:sircode})});
    const sirmeik = await getSirmeik.json();
    let ret_sircde = '0';
    if(sirmeik !== null) {
      if (lflg === 1)
      {
        // header.SIRCDE = sircode;
        // header.SIRMEIK = sirmeik.SIRMEIK;
        // header.ZEIRTU = sirmeik.ZEIRTU;
        setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE:sircode}));
        setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK:sirmeik.SIRMEIK}));
        setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU:sirmeik.ZEIRTU}));
      }else
      {
        // header.SIRCDE2 = sircode;
        // header.SIRMEIK2 = sirmeik.SIRMEIK;
        // header.ZEIRTU2 = sirmeik.ZEIRTU;
        // console.log('取引先取得2:'+sircode);
        setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:sircode}));
        setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:sirmeik.SIRMEIK}));
        setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:sirmeik.ZEIRTU}));
        if (header.SIRCDE === '' || header.SIRCDE === undefined){
          // header.SIRCDE = sircode;
          // header.SIRMEIK = sirmeik.SIRMEIK;
          // header.ZEIRTU = sirmeik.ZEIRTU;
          setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE:sircode}));
          setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK:sirmeik.SIRMEIK}));
          setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU:sirmeik.ZEIRTU}));
          ret_sircde = sircode;
          }
      }
      return ret_sircde;
    }else
    {
      return '-1';
    }
    // await setHeaderItem(header);
  }

  // 商品名Cell
  const SHNMEIKNCell = (params) => {
    if (params.row.IRYO_FLG !== '1')
    {
      // 衣料フラグ≠1なら表示
      return (
        <div>
          <Typography>{params.row.SHNMEIKN}</Typography>
          <Typography color='textSecondary'>{params.row.KIKAKUKN + ' ' + params.row.JANCDE}</Typography>
        </div>
      )
    } else {
      // 衣料フラグ=1なら表示
      return (
        <div>
          <Typography>{params.row.SHNMEIKN}</Typography>
          <Typography color='textSecondary'>
            {params.row.TRIHBN + ' ' + params.row.SIZCDE + ' ' + params.row.TAGSIZMEI
            + ' ' + params.row.TAGCOLCDE + ' ' + params.row.TAGCOLMEI}</Typography>
        </div>
      )
    }
  };

  // グリッド列情報(エラー出力されているが処理可能)
  const columns/*: GridColDef[]*/ = [
    // {
    //   field: 'detailAction',
    //   headerName: '　', // セルの右側に罫線を表示するために全角スペースを表示する
    //   align: 'left',
    //   width: 60,
    //   type: 'actions',
    //   getActions: getDetailAction,
    //   description: '詳細画面を表示',  // ツールチップで表示される説明
    //   hideable: false,              // 非表示にできないようにする
    //   sortable: false,              // ソートできないようにする
    //   hideSortIcons: false,         // ソートアイコンを表示しない
    //   resizable: false,             // リサイズできないようにする
    //   disableExport: false,         // エクスポートに含めないようにする
    //   disableReorder: false,        // 並べ替えできないようにする
    //   filterable: false,            // フィルタリングしないようにする
    //   disableColumnMenu: true       // カラムメニューを表示しない
    // } as GridColDef ,
    {
      field: 'id',
      headerName: 'No',
      headerAlign: 'center',
      align:'center',
      width: 80,
      sortable:false,
      disableColumnMenu:true
    }, //IDは必ず必要みたい
    {
      field: 'SHNMEIKN',
      headerName: '商品名',
      headerAlign: 'center',
      width: 350,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true,
      renderCell: (params) => SHNMEIKNCell(params)
    },
    {
      field: 'SUU2',
      headerName: '数量',
      type: 'number',
      headerAlign: 'center',
      width: 80,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true
    },
    {
      field: 'GNT2',
      headerName: '原単価',
      type: 'number',
      headerAlign: 'center',
      width: 120,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true
    },
    {
      field: 'GNKKIN',
      headerName: '原価金額',
      type: 'number',
      headerAlign: 'center',
      width: 120,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true
    },
    {
      field: 'BTN2',
      headerName: '売単価',
      type: 'number',
      headerAlign: 'center',
      width: 120,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true
    },
    {
      field: 'BAKKIN',
      headerName: '売価金額',
      type: 'number',
      headerAlign: 'center',
      width: 120,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true
    },
    {
      field: 'JYOTOYMD2',
      headerName: '譲渡日',
      headerAlign: 'center',
      align: 'center',
      width: 120,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true
    },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params: GridValueGetterParams) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  //罫線表示（Data Grid）
  const gridStyle = {
    transform: 'scale(0.9)',
    // 他のスタイルプロパティをここに追加することもできます
  };

  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  // 確認のWidthを設定する
  const cssInputOutlineRetSetwidth = (obj) => {
    let resObj = obj;
    resObj.width = '50px';
    return resObj;
  }

  //罫線表示（Data Grid）
  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
    cssInputOutlineJotKbn: {
      '> div': {
        minHeight: 'auto !important',
        paddingTop: '.4em',
        paddingBottom: '.3em',
      }
    },
    cssInputSelect: {
      ' label': {
        color: '#000000'
      },
      ' fieldset': {
        borderColor: '#000000'
      },
    },
  }

  const styles2 = {
    grid: {
      // x-data-gridの全体的な縮小
      '.MuiDataGrid-root': {
        transform: 'scale(0.5)',
      },
      '.MuiDataGrid-toolbarContainer': {
        borderBottom: 'solid 1px rgba(224, 224, 224, 1)'  // ついでにツールバーの下に罫線を引く
      },
      '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
        borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      // 2022/06/01 追記 列ヘッダの最終セルの右側に罫線が表示されなくなった・・・
      // 列ヘッダの最終列の右端に罫線を表示する
      '.MuiDataGrid-columnHeadersInner': {
        border: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      '.MuiDataGrid-columnSeparator--sideRight':{
        visibility: 'show',
      },
      '.MuiDataGrid-columnHeaders':{
        height:'40px',
      },
      // 削除済明細は背景色をグレーとする
      '.MuiDataGrid-row.row-deactive': {
        background: '#888888',
      },

    }
  }

  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: '750px',
          width: '1050px',
          m: '10px auto',
          padding:"10px",
        }}
      >
        <Grid
          container
          direction='column'
          justifyContent='flex-start' //多分、デフォルトflex-startなので省略できる。
          alignItems='left'
        >
          <div style={{display:'flex'}}>
            <Typography variant={'h5'} sx={{ m: '5px',height:'30px' }}>
            返品伝票作成
            </Typography>
            <Box width={'72%'} display={'flex'} justifyContent={'flex-end'}>
              <Typography variant={'h6'} sx={{ m: '13px' }}>
                {headerItem.TENPONM}：{headerItem.JGNMEIKNJ}
              </Typography>
              <Typography variant={'h6'} sx={{ m: '13px' }}>
                { sdate }
              </Typography>
            </Box>
          </div>
        </Grid>

        <Paper
            elevation={2}
            sx={{
              p: 2,
              height: '80px',
              width: '1012px',
            }}
          >
          <Stack direction='row' sx={{mb:'10px'}}>
            <NumericFormat  //部門CD
              id={'BMNCDE'}
              inputRef={inBMNCDE}                   //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='分類CD'                       //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              //sx={{width: '7ch',background:inBMNCDEclr,padding:'1px'}}                 //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 1), {background:inBMNCDEclr})}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.BMNCDE}
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disHeder}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onChange={(e)=>{
                const header = JSON.parse(JSON.stringify(headerItem));
                header.BMNCDE = e.target.value;
                setHeaderItem(header);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inBMNCDE,inBMNCDE,inHZK)}
              onFocus={(e) => onMyFocus(e)}
            />
            <InputLabel variant={'outlined'}sx={{mr:'30px'}}>{ String(headerItem.BMNMEI).trim() }</InputLabel>
            &nbsp;
            <PatternFormat  //日付
              id={'HZK'}
              inputRef={inHZK}                   //項目名定義？
              format='####/##/##'
              mask={['Y','Y','Y','Y','M','M','D','D']}
              color='success'
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='日付'                        //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              // fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              // decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              onValueChange={(values, sourceInfo) => {
                // console.log(values.value);
                // console.log(values.formattedValue);
                // console.log(values.floatValue);
                const header = JSON.parse(JSON.stringify(headerItem));
                header.HZK =  values.value;
                header.HZK2 = values.formattedValue;
                setHeaderItem(header);
              }}
              //sx={{width: '13ch',background:inHZKclr}}                 //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 8), {background:inHZKclr})}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.HZK2}
              // allowLeadingZeros                   //前ゼロ表示 on
              disabled={disHeder}                    //使用可／不可
              placeholder='YYYY/MM/DD'
              //inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onKeyDown={(e) => onKeyDown(e.key,inHZK,inBMNCDE,inDENKBN)}
              onFocus={(e) => onMyFocus(e)}
            />
            &nbsp;
            <NumericFormat  //伝票区分
              id={'DENKBN'}
              inputRef={inDENKBN}                 //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='伝票区分'                      //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              //sx={{width: '8ch',marginLeft:'20px',background:inDENKBNclr}}                 //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 2), {background:inDENKBNclr})}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.DENKBN}
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disHeder}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 2,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 2,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              // onChange={(e) => changeDenKbn(e.target.value)}
              onChange={(e) => {
                const header = JSON.parse(JSON.stringify(headerItem));
                changeDenKbn(e.target.value, header);     // 23/09/25
              }}
              onKeyDown={(e) => onKeyDown(e.key,inDENKBN,inHZK,inRYUNO)}
              onFocus={(e) => onMyFocus(e)}
            />
            <InputLabel variant={'outlined'}sx={{mr:'30px'}}>{headerItem.DENKBNNM}</InputLabel>
            &nbsp;
            <NumericFormat  //理由No.
              id={'RYUNO'}
              inputRef={inRYUNO}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='理由No.'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              //sx={{width: '8ch',background:inRYUNOclr}}                 //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 2), {background:inRYUNOclr},{width:'7ch'})}  //2023/11/07 入力項目の表示内容拡大
              //value={dbdata.ZEIRTU}               //初期値
              value = {headerItem.RYUNO}
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disRyuno}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onChange={(e) => {
                const header = JSON.parse(JSON.stringify(headerItem));
                changeRiyuNo(e.target.value,header);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inRYUNO,inDENKBN,inJAN1)}
              onFocus={(e) => onMyFocus(e)}
            />
            <InputLabel variant={'outlined'}sx={{mr:'10px'}}>{headerItem.RYUMEI}</InputLabel>
            &nbsp;
            <NumericFormat  //商談書No.
                id={'SYODAN'}
                inputRef={inSYODAN}                   //項目名定義？
                color='success'
                thousandSeparator={false}           //1000単位の区切り
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='商談書No.'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                type='tel'                          //[PDA、Tablet対応]入力モード：tel
                inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
                fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
                decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                //sx={{width: '15ch',marginLeft:'20px',visibility:visibleSYODAN ? 'visible' : 'hidden' ,background:inSYODANclr}}                 //textboxの幅
                sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 11), {background:inSYODANclr},{visibility:visibleSYODAN ? 'visible' : 'hidden'})}  //2023/11/07 入力項目の表示内容拡大
                value={headerItem.SYODAN}               //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={disHeder}                    //使用可／不可
                //inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center'}}}  //補完,最大文字数,中央寄せ
                inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
                onChange={(e)=>{
                  const header = JSON.parse(JSON.stringify(headerItem));
                  header.SYODAN = e.target.value;
                  setHeaderItem(header);
                }}
                onKeyDown={(e) => onKeyDown(e.key,inSYODAN,inRYUNO,inJAN1)}
                onFocus={(e) => onMyFocus(e)}
                />
          </Stack>
          <Stack direction='row' sx={{mb:'15px'}}>
            <NumericFormat  //取引先CD
              id={'SIRCDE'}
              inputRef={inSIRCDE}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='取引先'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              //sx={{width: '9ch'}}                 //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 6), {background:inRYUNOclr})}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.SIRCDE}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 0,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 0,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onFocus={(e) => onMyFocus(e)}
              />
            <InputLabel variant={'outlined'}sx={{mr:'10px'}}>{headerItem.SIRMEIK.trim()}</InputLabel>
            &nbsp;
            <NumericFormat  //税率
              id={'ZEIRTU'}
              inputRef={inZEIRTU}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='税率'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              //sx={{width: '9ch',marginLeft:'20px'}}                 //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 1),{width:'6ch'})}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.ZEIRTU}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 0,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 0,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onFocus={(e) => onMyFocus(e)}
              />
            <InputLabel variant={'outlined'}sx={{mr:'20px'}}>％</InputLabel>
            <Box >
              <InputLabel variant={'standard'} sx={{fontSize:12,mt:'0px'}}>{denKbnComment}</InputLabel>
              <InputLabel variant={'standard'} sx={{fontSize:12,mt:'0px'}}>{riyuComment}</InputLabel>
            </Box>
          </Stack>
        </Paper>
        <Paper
            elevation={2}
            sx={{
              p: 2,
              height: '80px',
              width: '1012px',
              mt: '3px'
            }}
          >
          <Stack direction='row' sx={{mb:'10px'}}>
            <NumericFormat  //JANCD1
              id={'JAN1'}
              inputRef={inJAN1}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='ＪＡＮ１'                      //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              //sx={{width: '15ch',background:inJAN1clr}}                 //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 11), {background:inJAN1clr})}  //2023/11/07 入力項目の表示内容拡大
              value={rowItem.JANCDE}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disJan1}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onChange={(e)=>{
                const row = JSON.parse(JSON.stringify(rowData));
                row.JANCDE = e.target.value;
                setRowItem(row);
                setChkJAN1('0');
                setDisRow(true);

                setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
                setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
                setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
              }}
              onKeyDown={(e) => onKeyDown(e.key,inJAN1,inRYUNO,inSUU)}
              onFocus={(e) => onMyFocus(e)}
              onBlur={() => onblur(inJAN1)}
              />
            &nbsp;
            <NumericFormat  //数量
              id={'SUU'}
              inputRef={inSUU}
              color='success'
              thousandSeparator={','}
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={true}            //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={1}                    //[PDA、Tablet対応]少数部の桁[0]
              customInput={TextField}
              size='small'
              label='数量'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              //sx={{width: '80px',background:inSUUclr}}
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 6), {background:inSUUclr})}  //2023/11/07 入力項目の表示内容拡大
              value={rowItem.SUU2}
              allowLeadingZeros
              disabled={disRow}                    //使用可／不可
              //inputProps={{autoComplete: 'off',maxLength: 9,style: {textAlign: 'right'} }}
              inputProps={{ autoComplete: 'off',maxLength: 9,style: {textAlign: 'right',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              isAllowed={(values) => {
                const numValue = Number(values.value);
                if (isNaN(numValue)) return true;
                return numValue >= 0 && numValue <= 99999.9;
              }}
              // onValueChange={(values, sourceInfo) => {
              //   const row = JSON.parse(JSON.stringify(rowItem));
              //   row.SUU = values.floatValue;
              //   row.SUU2 = values.formattedValue;
              //   setRowItem(row);
              // }}
              onChange={(e)=>{
                const row = JSON.parse(JSON.stringify(rowItem));
                // let suu = Number(e.target.value);
                let suu = Number(String(e.target.value||0).replaceAll(',',''));
                if (isNaN(suu) || e.target.value === ''){
                  row.SUU = 0;
                  row.SUU2 = '0.0';
                }
                else{
                  row.SUU = suu;
                  row.SUU2 = suu.toLocaleString(undefined,{minimumFractionDigits : 1});
                }
                setRowItem(row);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inSUU,inJAN1,inGNT)}
              onFocus={(e) => onMyFocus(e)}
            />
            &nbsp;
            <NumericFormat  //原単価
              id={'GNT'}
              inputRef={inGNT}
              color='success'
              thousandSeparator={','}
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={true}            //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={2}                    //[PDA、Tablet対応]少数部の桁[0]
              customInput={TextField}
              size='small'
              label='原単価'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              //sx={{width: '110px',background:inGNTclr}}
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 9), {background:inGNTclr})}  //2023/11/07 入力項目の表示内容拡大

              value={rowItem.GNT2}
              allowLeadingZeros
              disabled={disRow}                    //使用可／不可
              //inputProps={{autoComplete: 'off',maxLength: 13,style: {textAlign: 'right'} }}
              inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'right',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              isAllowed={(values) => {
                const numValue = Number(values.value);
                if (isNaN(numValue)) return true;
                return numValue >= -9999999.99 && numValue <= 9999999.99;
              }}
              // onValueChange={(values, sourceInfo) => {
              //   const row = JSON.parse(JSON.stringify(rowItem));
              //   row.GNT = values.floatValue;
              //   row.GNT2 = values.formattedValue;
              //   setRowItem(row);
              // }}
              onChange={(e)=>{
                const row = JSON.parse(JSON.stringify(rowItem));
                // let gnt = Number(e.target.value);
                let gnt = Number(String(e.target.value||0).replaceAll(',',''));
                if (isNaN(gnt) || e.target.value === ''){
                  row.GNT = 0;
                  row.GNT2 = '0.00';
                }
                else{
                  row.GNT = gnt;
                  row.GNT2 = gnt.toLocaleString(undefined,{minimumFractionDigits : 2});
                }
                setRowItem(row);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inGNT,inSUU,inBTN)}
              onFocus={(e) => onMyFocus(e)}
            />
            &nbsp;
            <NumericFormat  //売単価
              id={'BTN'}
              inputRef={inBTN}
              color='success'
              thousandSeparator={','}
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              customInput={TextField}
              size='small'
              label='売単価'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              //sx={{width: '110px',background:inBTNclr}}
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 7), {background:inBTNclr})}  //2023/11/07 入力項目の表示内容拡大
              value={rowItem.BTN2}
              allowLeadingZeros
              disabled={disRow}                    //使用可／不可
              //inputProps={{autoComplete: 'off',maxLength: 13,style: {textAlign: 'right'} }}
              inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'right',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              isAllowed={(values) => {
                const numValue = Number(values.value);
                if (isNaN(numValue)) return true;
                return numValue >= -9999999.99 && numValue <= 9999999.99;
              }}
              // onValueChange={(values, sourceInfo) => {
              //   const row = JSON.parse(JSON.stringify(rowItem));
              //   row.BTN = values.floatValue;
              //   row.BTN2 = values.formattedValue;
              //   setRowItem(row);
              // }}
              onChange={(e)=>{
                const row = JSON.parse(JSON.stringify(rowItem));
                // let btn = Number(e.target.value);
                let btn = Number(String(e.target.value||0).replaceAll(',',''));
                if (isNaN(btn) || e.target.value === ''){
                  row.BTN = 0;
                  row.BTN2 = '0';
                }
                else{
                  row.BTN = btn;
                  row.BTN2 = btn.toLocaleString(undefined,{minimumFractionDigits : 0});
                }
                setRowItem(row);
              }}
              onKeyDown={(e) => onKeyDown(e.key,inBTN,inGNT,inJYOTOYMD)}
              onFocus={(e) => onMyFocus(e)}
            />
            &nbsp;
            <PatternFormat  //譲渡日
              id={'JYOTOYMD'}
              inputRef={inJYOTOYMD}                   //項目名定義？
              format='####/##/##'
              mask={['Y','Y','Y','Y','M','M','D','D']}
              color='success'
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='譲渡日'                        //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              // fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              // decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              onValueChange={(values, sourceInfo) => {
                // console.log(values.value);
                // console.log(values.formattedValue);
                // console.log(values.floatValue);
                const row = JSON.parse(JSON.stringify(rowItem));
                row.JYOTOYMD = values.value;
                row.JYOTOYMD2 = values.formattedValue;
                setRowItem(row);
              }}
              //sx={{width: '13ch',background:inJYOTOYMDclr}}              //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 8), {background:inJYOTOYMDclr})}  //2023/11/07 入力項目の表示内容拡大
              value={rowItem.JYOTOYMD2}           //初期値
              // allowLeadingZeros                   //前ゼロ表示 on
              disabled={disRow}                    //使用可／不可
              placeholder='YYYY/MM/DD'
              //inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onKeyDown={(e) => onKeyDown(e.key,inJYOTOYMD,inBTN,inJYOTOYMD)}
              onFocus={(e) => onMyFocus(e)}
            />
            &nbsp;
          </Stack>
          <Stack direction='row' >
            <NumericFormat  //JANCD2
              id={'JAN2'}
              inputRef={inJAN2}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='ＪＡＮ２'                      //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              //sx={{width: '15ch',background:inJAN2clr}}                 //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 11), {background:inJAN2clr})}  //2023/11/07 入力項目の表示内容拡大
              value={rowItem.JANCDE2}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={disJan2}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onChange={(e)=>{
                const row = JSON.parse(JSON.stringify(rowData));
                row.JANCDE = rowItem.JANCDE;
                row.JYOTOYMD = rowItem.JYOTOYMD;
                row.JYOTOYMD2 = rowItem.JYOTOYMD2;
                row.IN_CD_KBN = rowItem.IN_CD_KBN;
                row.IRYO_FLG = rowItem.IRYO_FLG;  //10/25
                row.EOSCDE = rowItem.EOSCDE;      //10/25
                row.JANCDE2 = e.target.value;
                setChkJAN2('0');
                setRowItem(row);
                setDisRow(true);

                setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
                setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
                setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));
              }}
              onKeyDown={(e) => onKeyDown(e.key,inJAN2,inJAN1,inSUU)}
              onFocus={(e) => onMyFocus(e)}
              onBlur={() => onblur(inJAN2)}
            />
            &nbsp;
            <TextField //商品名
              id={'SIRMEIK'}
              size='small'                        //textboxの全体サイズ
              label='商品名'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '30ch'}}                 //textboxの幅
              value={rowItem.SHNMEIKN}               //初期値
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',style: {textAlign: 'left'} }}  //補完,最大文字数,左寄せ
            />

            <NumericFormat  //取引先CD
              id={'SIRCDE2'}
              inputRef={inSIRCDE2}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='取引先'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '9ch',marginLeft:'5px'}}                 //textboxの幅
              value={headerItem.SIRCDE2}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 0,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              onKeyDown={(e) => onKeyDown(e.key,inSIRCDE2,inBTN,inJYOTOYMD)}
              onFocus={(e) => onMyFocus(e)}
              />

            <InputLabel variant={'outlined'} >{headerItem.SIRMEIK2.trim()}</InputLabel>

            <NumericFormat  //税率
                id={'ZEIRTU2'}
                inputRef={inZEIRTU2}                   //項目名定義？
                color='success'
                thousandSeparator={false}           //1000単位の区切り
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='税率'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                type='tel'                          //[PDA、Tablet対応]入力モード：tel
                inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
                fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
                decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                //sx={{width: '9ch',marginLeft:'20px'}}                 //textboxの幅
                sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 1),{width:'6ch',ml:'15px'})}  //2023/11/07 入力項目の表示内容拡大
                value={headerItem.ZEIRTU2}               //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={true}                    //使用可／不可
                inputProps={{ autoComplete: 'off',maxLength: 0,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
                onFocus={(e) => onMyFocus(e)}
                />
            <InputLabel variant={'outlined'} >％</InputLabel>
          </Stack>
        </Paper>
        {/* テーブル表示処理 */}
        <Box style={gridStyle} sx={{ height: 371, width: '1162px',ml:'-60px',mt:'-15px' }}>
          <DataGrid
            sx={styles2.grid}     // --> 不具合対応のためcssで罫線を引く
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}  // メニュー日本語化
            showColumnRightBorder
            rowSelectionModel={selectionModel}
            getRowClassName={(params) => {
              if (params.row.DELFLG === '1') {
                // 削除済明細にクラスを設定する
                return 'row-deactive'
              }
              return '';
            }}
            onRowSelectionModelChange={(RowIds) => {
              // 今回チェックされたチェックボックスだけをチェック状態にする
              const diff = RowIds.filter(i => selectionModel.indexOf(i) == -1);
              const diffRows = rows.filter(x =>diff.indexOf(x.id) != -1);
              if(diffRows.length > 0){
                setRowItem(diffRows[0]);
              }
              const delRows = diffRows.filter(x => x.DELFLG == '1');
              if (delRows.length > 0) {
                // 削除済明細の場合はリックされた際に選択状態にしない
                return;
              }
              setSelectionModel(diff);
            }}
            paginationModel={pagenationModel}
            onPaginationModelChange={(model) => {
              setPagenationModel(model);
            }}
          />
        </Box>
        <Box my={2} flexDirection='row' justifyContent='flex-end' display='flex' marginBottom={'5px'}>
          <Box ml={2} sx={{mt:'-20px'}}>
            <NumericFormat  //合計数量
              id={'SUUG'}
              inputRef={inSUUG}
              color='success'
              thousandSeparator={','}
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={true}            //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={1}                    //[PDA、Tablet対応]少数部の桁[0]
              customInput={TextField}
              size='small'
              label='合計数量'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              //sx={{width: '110px'}}
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 6))}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.SRYFOT}
              allowLeadingZeros
              disabled={dismeisai}
              //inputProps={{autoComplete: 'off',maxLength: 0,style: {textAlign: 'right'} }}
              inputProps={{ autoComplete: 'off',maxLength: 0,style: {textAlign: 'right',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              //onChange={(event) => setGamen((prevState) => ({ ...prevState, jancde1:event.target.value}))}
            />
            &nbsp;
            <NumericFormat  //原価金額合計
              id={'GNKFOT'}
              inputRef={inGNKFOT}
              color='success'
              thousandSeparator={','}
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              customInput={TextField}
              size='small'
              label='原価金額合計'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              //sx={{width: '150px'}}
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 10))}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.GNKFOT}
              allowLeadingZeros
              disabled={dismeisai}
              //inputProps={{autoComplete: 'off',maxLength: 0,style: {textAlign: 'right'} }}
              inputProps={{ autoComplete: 'off',maxLength: 0,style: {textAlign: 'right',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応

            />
            &nbsp;
            <NumericFormat  //売価金額合計
              id={'BBAKFOT'}
              inputRef={inBBAKFOT}
              color='success'
              thousandSeparator={','}
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              customInput={TextField}
              size='small'
              label='売価金額合計'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              //sx={{width: '150px',mr:'5px'}}
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 10))}  //2023/11/07 入力項目の表示内容拡大
              value={headerItem.BAKFOT}
              allowLeadingZeros
              disabled={dismeisai}
              //inputProps={{autoComplete: 'off',maxLength: 0,style: {textAlign: 'right'} }}
              inputProps={{ autoComplete: 'off',maxLength: 0,style: {textAlign: 'right',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
            />
            &nbsp;
          </Box>
        </Box>
        <Box my={2} flexDirection='row' justifyContent='flex-end' display='flex' marginTop={'5px'}>
          <Box ml={2}>
            <Button
              variant='contained'
              color='success'
              sx={{width:100,mr:'580px'}}
              onClick={(e) => onKeyDown('Return','','') }
              >
              {'戻　る'}
            </Button>&nbsp;
            <Button
              variant='contained'
              color='success'
              sx={{width:100}}
              onClick={(e) => onClick_FooterButton_Reset()}
            >
              {'取　消'}
            </Button>&nbsp;
            <Button
              variant='contained'
              color='success'
              sx={{width:100}}
              onClick={(e) => onClick_FooterButton_Delete()}
            >
              {'削　除'}
            </Button>&nbsp;
            <Button
              variant='contained'
              color='success'
              sx={{width:100,mr:'10px'}}
              onClick={(e) => onClick_FooterButton_Send()}
              >
              {'登　録'}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default TCGT0100;