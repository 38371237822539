import { useState, useEffect } from 'react'

 // 状態区分情報取得
export const useJotKbnAction = () => {
  const [JotKbnList, setJotKbnList] = useState([]);                   // 状態区分リスト
  const [JotKbnName, setJotKbnName] = useState('');                   // 状態区分（表示名称）
  const [JotKbnComment, setJotKbnComment] = useState('※状態区分');    // 状態区分コメント
  const [JotKbnOptions, setJotKbnOptions] = useState([]);

  useEffect (() =>  {
    getJotKbn();
  }, []);
  const getJotKbn = async() => {
    // 状態区分情報（使用可能区分）取得
    const getJotKbnCd = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({CDE: '011',SCDE: '0000'})});
    const JotKbnCdList = await getJotKbnCd.json();

    // 状態区分情報（区分に対する名称）取得（
    const getJotKbnName = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({CDE: '011',SCDE: '0010'})});
    const JotKbnNameList = await getJotKbnName.json();

    // 状態区分リスト化
    setJotKbnList([]);
    setJotKbnList((JotKbnList) => [...JotKbnList ,{cd:JotKbnCdList.DATE01,name:JotKbnNameList.DATE01}]);
    setJotKbnList((JotKbnList) => [...JotKbnList ,{cd:JotKbnCdList.DATE02,name:JotKbnNameList.DATE02}]);
    setJotKbnList((JotKbnList) => [...JotKbnList ,{cd:JotKbnCdList.DATE03,name:JotKbnNameList.DATE03}]);
    setJotKbnList((JotKbnList) => [...JotKbnList ,{cd:JotKbnCdList.DATE04,name:JotKbnNameList.DATE04}]);
    setJotKbnList((JotKbnList) => [...JotKbnList ,{cd:JotKbnCdList.DATE05,name:JotKbnNameList.DATE05}]);
    setJotKbnList((JotKbnList) => [...JotKbnList ,{cd:JotKbnCdList.DATE06,name:JotKbnNameList.DATE06}]);
    setJotKbnList((JotKbnList) => [...JotKbnList ,{cd:JotKbnCdList.DATE07,name:JotKbnNameList.DATE07}]);
    setJotKbnList((JotKbnList) => [...JotKbnList ,{cd:JotKbnCdList.DATE08,name:JotKbnNameList.DATE08}]);
    setJotKbnList((JotKbnList) => [...JotKbnList ,{cd:JotKbnCdList.DATE09,name:JotKbnNameList.DATE09}]);
    setJotKbnList((JotKbnList) => [...JotKbnList ,{cd:JotKbnCdList.DATE10,name:JotKbnNameList.DATE10}]);
  };

  // 理由No.コメントの表示
  useEffect (() => {
    let comment = '※状態区分.';
    JotKbnList.forEach(element => {
      if(element.cd !== '') {
        comment = comment + ' ' + element.cd + ':' + element.name;
      }
    });
    setJotKbnComment(comment)

    const list = [];
    JotKbnList.forEach(element => {
      if(element.cd !== '') {
        list.push({value:element.cd, label:element.name});
      }
    });
    setJotKbnOptions(list);
  }, [JotKbnList]);

  const changeJotKbnNo = (JotKbnValue) => {
    let JotKbnNoName = '';
    if (JotKbnValue !== '')
    {
      const inputJotKbn = JotKbnList.filter(element => {
        return element.cd === JotKbnValue
      });

      if (inputJotKbn.length !== 0){
        JotKbnNoName = inputJotKbn[0].name;
      }
    }

    setJotKbnName(JotKbnNoName);
  };

  return {JotKbnList, JotKbnComment, JotKbnName, JotKbnOptions, changeJotKbnNo}
};

export default useJotKbnAction;
