import React, { useState,useContext,useEffect } from 'react'
import { Grid, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import TCPD0131 from './TCPD0131'; // ヘッダ1画面
import TCPD0132 from './TCPD0132'; // ヘッダ2画面

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);

      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCPD0130 () {
  const [screenPhase, setScreenPhase] = useState(1);        // 画面フェーズ

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('');
  const [sdate, setsDate] = useState([]);

  const [dressTaxrate, setDressTaxrate] = useState('');     //衣料タグ税剥がし税率
  const [regionList, setRegionList] = useState([]);         //産地区分情報
  const [prefectureList, setPrefectureList] = useState([]); //都道府県国
  const [prefectureList2, setPrefectureList2] = useState(0); //表示制御用
  const [homeList, setHomeList] = useState([]);             //原産地情報
  const [foodLabelList, setFoodLabelList] = useState([]);   //食品ラベル区分情報

  const [rows, setRows] = useState([]);

  // ヘッダデータ　◇画面上だけのデータ
  const headerData = {
    KBN: '1',                       // ◇区分
    KSACDE : state.KSACDES,         // 会社コード
    MISE : state.MISES,             // 店コード
    BMNCDE : '',                    // 部門コード
    ASTCDE : '000',                 // ＡＵ
    DENYMD : '',                    // 伝票日付
    DENNO : '',                     // 伝票番号
    DEN_EDABAN : '1',               // 伝票枝番
    SEQ : '001',                    // ＳＥＱ
    TENPONM : state.MISEMEI,        // 店舗名
    BMNMEI : '',                    // 部門名
    ASTMEI : '',                    // ＡＵ名
    HASDD : '',                     // 発送日
    HASDD2 : '',                    // 発送日2
    UIR_KSACDE : state.KSACDES,     // 受取会社コード
    UIR_MISE : '',                  // 受取店コード
    UIR_MISEMEI : '',               // 受取店名
    UIR_BMNCDE : '',                // 受取部門コード
    UIR_BMNMEI : '',                // 受取部門名
    UIR_ASTCDE : '000',             // 受取ＡＵ
    UIR_ASTMEI : '',                // 受取ＡＵ名
    KAKEZEIRTU : '',                // 掛率
    SRYFOT : '',                    // 数量合計
    GNKFOT : '',                    // 原価金額合計
    BAKFOT : '',                    // 売価金額合計
    // IN_JGNCDE : '',                 // 入力担当者コード
    // IN_JGNMEIKNJ : state.JGNMEIKNJ, // 入力担当者名
    // IN_DATE : '',                   // 入力日時
    // OUT_DATE : '',                  // 発行日時
    // SND_DATE : '',                  // 送信日時
    JOTKBN : '1',                   // 状態区分
    // KIJYKETKBN : '',                // 計上結果区分
    // KIJYMD : '',                    // 計上日
    DATE : '',                      // 日時
    JGNCDE : state.JGNCDE,          // ユーザーID
    JGNMEIKNJ : state.JGNMEIKNJ,    // ◇ユーザー名
    PRG_ID : 'TCPD0130',            // プログラムID
    TRL_ID:state.MISES,             // 端末ＩＤ★一旦店コード入れる
    DELFLG : '0',                   // 削除フラグ
  }
  const [headerItem,setHeaderItem] = useState(headerData);
  // ヘッダ2データ
  const rowData ={
    id : 1,                        // 行№
    KSACDE : state.KSACDES,        // 会社コード
    MISE : state.MISES,            // 店コード
    BMNCDE : '',                   // 部門コード
    ASTCDE : '000',                // ＡＵ
    DENYMD : '',                   // 伝票日付
    DENNO : '',                    // 伝票番号
    DEN_EDABAN : '1',              // 伝票枝番
    SEQ : '',                      // ＳＥＱ
    // GYONO : '',                    // 行№
    JANCDE : '',                   // ＪＡＮコード
    SHNMEIKN : '',                 // 商品名
    KIKAKUKN : '',                 // 規格
    SUU : 0,                       // 数量(数字)
    SUU2 : '',                     // ◇数量2(文字列)
    GNT : 0,                       // 原単価(数字)
    GNT2 : '',                     // ◇原単価2(文字列)
    GNKKIN : 0,                    // 原価金額
    BTN : 0,                       // 売単価(数字)
    BTN2 : '',                     // ◇売単価2(文字列)
    BAKKIN : 0,                    // 売価金額
    SANTI_KBN : '',                // 産地区分
    TODOFUKEN_KUNI : '',           // 都道府県国
    GENSANTI : '',                 // 原産地
    SKHLBLKBN : '',                // 食品区分
    SIRCDE : '',                   // 仕入先(取引先)コード
    SIRMEIK : '',                  // 仕入先(取引先)名
    EOSCDE : '',                   // ＥＯＳコード
    JANCDE2 : '',                  // ＪＡＮコード２
    TAGASTCDE : '',                // タグＡＵ
    TAGLINCDE : '',                // タグライン
    TAGCLSCDE : '',                // タグクラス
    TAGSZN : '',                   // タグシーズン
    TAGITM : '',                   // タグアイテム
    TRIHBN : '',                   // 取引先品番
    TAGSIZ : '',                   // サイズコード
    TAGSIZMEI : '',                // サイズ名カナ
    TAGCOLCDE : '',                // カラーコード
    TAGCOLMEI : '',                // カラー名カナ
    IRYO_FLG : '0',                 // 衣料フラグ
    IN_CD_KBN : '',                // 入力コード区分
    // DATE : '',                     // 作成日時
    JGNCDE : state.JGNCDE,         // ユーザーID
    PRG_ID : 'TCPD0130',           // プログラムID
    TRL_ID:state.MISES,             // 端末ＩＤ★一旦店コード入れる
    DELFLG : '0',                  // 削除フラグ
  }
  const [rowItem,setRowItem] = useState(rowData);

  const commonStyles = {
    bgcolor: 'background.paper',
    m: 1,
    border: 0,
    width: '40px',
    height: '40px',
  };

  // 衣料タグ税剥がし税率固定取得
  const getDressTaxrate = async() => {
    const get_DressTax = await fetch('/SL_SET_MST_sel',{method: 'POST', headers:{'Content-Type': 'application/json'},
    body: JSON.stringify({ CDE:'006', SCDE:'0000' })});
    const set_DressTax = await get_DressTax.json();
    if(set_DressTax.DATE01 != ''){
      setDressTaxrate(Number(set_DressTax.DATE01));
    }
  }

  // セレクトボックスリスト取得
  const getRegionList = async(code) => {
    const get_region = await fetch('/TCGT0110/SL_SET_MST_sel',{method: 'POST', headers:{'Content-Type': 'application/json'},
    body: JSON.stringify({ CDE:code })});
    const data_region = await get_region.json();
    const valueList = data_region.ret.filter(element => {
      return element.SCDE === '0000'
    });
    const labelList = data_region.ret.filter(element => {
      return element.SCDE === '0010'
    });

    let list = [
      {value:'　',label:'未選択'},
      {value:valueList[0].DATE01,label:labelList[0].DATE01},
      {value:valueList[0].DATE02,label:labelList[0].DATE02},
      {value:valueList[0].DATE03,label:labelList[0].DATE03},
      {value:valueList[0].DATE04,label:labelList[0].DATE04},
      {value:valueList[0].DATE05,label:labelList[0].DATE05},
      {value:valueList[0].DATE06,label:labelList[0].DATE06},
      {value:valueList[0].DATE07,label:labelList[0].DATE07},
      {value:valueList[0].DATE08,label:labelList[0].DATE08},
      {value:valueList[0].DATE09,label:labelList[0].DATE09},
      {value:valueList[0].DATE10,label:labelList[0].DATE10},
    ]

    return list.filter(element => {
      return element.value !== '' || element.label !== ''
    });
  }
  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  useEffect (()=>{
    if (state.KENGEN === '') {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    } else {
      console.log('メニュー:%o',state)
    }
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);

    getDressTaxrate();
    getSelectMenuList();
    setHeaderHASDD();
  }, []);

  // ヘッダの表示をリセットする。
  const setHeaderHASDD =  async () =>{     // 23/09/25
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = (d.getMonth() + 1).toString().padStart(2, '0');
    let day = d.getDate().toString().padStart(2, '0');

    const header = headerData;
    header.HASDD = year + month + day;                // 発送日
    header.HASDD2 = year + '/' + month + '/' + day;   // 発送日2
    await setHeaderItem(header);
  }

  const getSelectMenuList = async()=>{
    let a2 = await getRegionList('008');
    let a4 = await getRegionList('009');
    let a5 = await getRegionList('007');

    setRegionList(a2);
    setHomeList(a4);
    setFoodLabelList(a5);
  }

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        height: '780px',
        width: '400px',
        m: '20px auto'
      }}
    >
      <Grid
        container
        direction='column'
        justifyContent='flex-start' //多分、デフォルトflex-startなので省略できる。
        alignItems='left'
      >
        <div style={{display:'flex'}}>
          <Grid container>
            <Grid item xs={6}>
              {/* <Paper> */}
                <Typography variant={'h6'} sx={{ m: '5px' }}>
                  移動伝票作成
                </Typography>
              {/* </Paper> */}
            </Grid>
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={3}>
              {/* <Paper>  */}
                  <Typography variant={'h6'} sx={{ m: '5px' }}>
                    { sdate }
                  </Typography>
              {/* </Paper> */}
            </Grid>
            <Grid item xs={8}>
              {/* <Paper> */}
                <Typography variant={'h7'} sx={{ m: '5px' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
              {/* </Paper> */}
            </Grid>

            <Grid item xs={4}>
                <Typography variant={'h7'} sx={{ m: '10px' } } component='span'>
                  {state.JGNMEIKNJ}
                </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
      {(()=> {
        if(screenPhase === 1) {
          return (
            <Grid>
              <TCPD0131
                setScreenPhase = {setScreenPhase}
                rows = {rows}
                setRows = {setRows}
                rowData = {rowData}
                rowItem = {rowItem}
                setRowItem = {setRowItem}
                headerData = {headerData}
                headerItem = {headerItem}
                setHeaderItem = {setHeaderItem}
                setPrefectureList = {setPrefectureList}
                setPrefectureList2 = {setPrefectureList2}
                sdate = {sdate}
                setHeaderHASDD={setHeaderHASDD}     // 23/09/25
              />
            </Grid>
          )
        } else if (screenPhase === 2) {
          return (
            <Grid>
              <TCPD0132
                setScreenPhase = {setScreenPhase}
                rows = {rows}
                setRows = {setRows}
                rowData = {rowData}
                rowItem = {rowItem}
                setRowItem = {setRowItem}
                headerData = {headerData}
                headerItem = {headerItem}
                setHeaderItem = {setHeaderItem}
                regionList = {regionList}
                prefectureList = {prefectureList}
                prefectureList2 = {prefectureList2}
                homeList = {homeList}
                foodLabelList = {foodLabelList}
                dressTaxrate = {dressTaxrate}
                setHeaderHASDD={setHeaderHASDD}     // 23/09/25
              />
            </Grid>
          )
        }
      })()}
    </Paper>
  )
};

export default TCPD0130;