import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, Button,Stack, Grid, Paper, Typography, InputLabel, MenuItem, FormControl, TextField, Select } from "@mui/material";
import { NumericFormat } from 'react-number-format';  //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import {
  DataGrid,
  jaJP                              // 日本語用のファイルをインポート
} from '@mui/x-data-grid';

//モーダルウィンドウ関係
import CustomFormTCSH0110 from './CustomFormTCSH0110';

const commonStyles = {
  bgcolor: 'background.paper',
  m: 1,
  border: 0,
  width: '40px',
  height: '40px',
};

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSH0130 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [ksacde, setKsacde] = useState('');
  const [kmise, setKmise] = useState('');
  const [ksanm, setKsanm] = useState('');
  const [misenm, setMisenm] = useState('');
  const [alert, setAlert] = useState('');
  const [K_KSACDE,setK_KSACD] = useState('');
  const [K_MISE,setK_MISE] = useState('');
  //S SK-0037-004 2024/7/4           TCI)M.K【課題No.73：要チェックリストの遷移】
  // const [bmncde,setBmncde] = useState(state.BMNCDE);
  const [bmncde,setBmncde] = useState('');
  //E SK-0037-004
  const [jtkbn,setJtkbn] = useState('3');
  const [sdate, setsDate] = useState([]);     // 本日日付（YYYY/MM/DD)
  const [sdate2, setsDate2] = useState([]);     // 本日日付（YYYYMMDD)
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [pagenationModel, setPagenationModel] = useState({page: 0, pageSize: 10});
  const [jdhttst, setJdhttst] = useState('-');  // 自動発注状態
  const [jdhttstcd, setJdhttstcd] = useState('');  // 自動発注状態コード
  const [shnrank, setShnrank] = useState('-');  // 商品ランク
  const [kjn_zai, setKjn_zai] = useState('-');  // 基準在庫
  const [lowhttsuu, setLowhttsuu] = useState('-');  // 最低発注数
  const [cal_zai, setCal_zai] = useState('-');  // 計算在庫
  const [jancde, setJancde] = useState('-');    // ＪＡＮコード
  const [shnmeikn, setShnmeikn] = useState('-');  // 商品名
  const [irisuu, setIrisuu] = useState('');     // 入数 2024/06/06 追加
  const [movefocusflg,setMove] = useState();      // 次へボタン遷移制御用
  const [yobi, setYobi] = useState('');

  const inBtnSearch = useRef(null);
  const inBMNCDE = useRef(null);
  const inJTKBN = useRef(null);

  //項目のバックカラー制御用
  const [inBMNCDEclr,setBMNCDEclr] = useState('#FFFFFF');
  const [inJtkbnclr,setJtkbnclr] = useState('#FFFFFF');

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    // 多重防止
    // if (!keyDisabled) {
    //   setKeyDisabled(true);
    // } else {
    //   return;
    // }

    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      // if (state.MVEMGAMEN==='')
      // {
      //   navigate('/');
      // }else{
      //   navigate('/TCSA0003');
      // }      
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  const handleChange = (event, SelectChangeEvent) => {
    setJtkbn(event.target.value);
    console.log('選択状態区分:%o',event.target.value)
    switch(event.target.value)
    {
      case '1':
        break;
      case '2':
        break;
      case '3':
        break;
      default:
        break;
    };
  };

  // シノプス情報表示↓↓↓
  const rowData ={
    id : 0,                        // 行№
    KSACDE : state.KSACDES,        // 会社コード
    MISE : state.MISES,            // 店コード
    BMNCDE : bmncde,               // 部門コード
    SHNID : '',                    // 商品ID
  }
  const [rowItem,setRowItem] = useState(rowData);
  const [text, setText] = useState('');
  const [text2, setText2] = useState('');
  const [text3, setText3] = useState('');
  const [text4, setText4] = useState('');
  // ↑親コンポーネントで使う：textの初期値とtextを更新する関数を宣言
  // ↓子コンポーネントから受け取った値で親コンポーネントのtextを更新する関数A
  const handleValueChange04 = async(newValue) => {
    console.log(' 親コンポーネントで受け取った値：',newValue)
    setText(newValue);
  };
  const handleValueChange05 = (newValue) => {
    console.log(' 親コンポーネントで受け取った値Text2：',newValue)
    setText2(newValue);
  };
  const handleValueChange06 = (newValue) => {
    setText3(newValue);
  };
  const handleValueChange07 = (newValue) => {
    setText4(newValue);
  };
  const [isSinopsInfoVisible, setSinopsInfoVisible] = useState(false);
  // シノプス入力画面を表示
  const showSinopsInfo = () => {
    window.glAlertDialogFlg = true;
    setSinopsInfoVisible(true);
  };
  // シノプス入力画面を閉じる
  const closeSinopsInfo = () => {
    window.glAlertDialogFlg = false;
    setSinopsInfoVisible(false);
  };
  // シノプス情報を登録する
  const regSinopsInfo = async(gamen) => {
    // チェック
    // let W_cal_zai = ''
    // if (Number(text4) >= 0){
    //   W_cal_zai = '+' + ('0000' + text4).slice(-5);
    // }else{
    //   W_cal_zai = '-' + ('0000' + (Number(text4)*-1)).slice(-5);
    // }
    let W_jdhttst = ''
    let W_kjn_zai = ''
    let W_lowhttsuu = ''
    let W_cal_zai = ''

    if (text === ''){   // 変更無しの場合
      W_jdhttst = jdhttstcd;
    }else{
      W_jdhttst = text;
    }

    if (text2 === ''){   // 変更無しの場合
      // W_kjn_zai = ('0000' + kjn_zai).slice(-5);
      if(kjn_zai === '-'){
        W_kjn_zai = '';  
      }else{
        W_kjn_zai = kjn_zai;
      }
    }else{
      // W_kjn_zai = ('0000' + text2).slice(-5);
      if(text2 === '-'){
        W_kjn_zai = '';  
      }else{
        W_kjn_zai = text2;
      }
    }

    if (text3 === ''){   // 変更無しの場合
      // W_lowhttsuu = ('0000' + lowhttsuu).slice(-5);
      if(lowhttsuu === '-'){
        W_lowhttsuu = '';  
      }else{
        W_lowhttsuu = lowhttsuu;
      }
    }else{
      // W_lowhttsuu = ('0000' + text3).slice(-5);
      if(text3 === '-'){
        W_lowhttsuu = '';  
      }else{
        W_lowhttsuu = text3;
      }
    }

    // if (text4 === ''){   // 変更無しの場合
    //   if (Number(cal_zai) >= 0){
    //     W_cal_zai = '+' + ('0000' + cal_zai).slice(-5);
    //   }else{
    //     W_cal_zai = '-' + ('0000' + (Number(cal_zai)*-1)).slice(-5);
    //   }
    // }else{
    //   if (Number(text4) >= 0){
    //     W_cal_zai = '+' + ('0000' + text4).slice(-5);
    //   }else{
    //     W_cal_zai = '-' + ('0000' + (Number(text4)*-1)).slice(-5);
    //   }
    // }
    if (text4 === ''){   // 変更無しの場合
      if(cal_zai === '-'){
        W_cal_zai = '';  
      }else{
        W_cal_zai = cal_zai;
      }
    }else{
      if(text4 === '-'){
        W_cal_zai = '';  
      }else{
        W_cal_zai = text4;
      }
    }

    // データの更新
    // const response = await fetch('/TCSH0100/UpdJDHDat',{method: 'POST',headers: {'Content-Type': 'application/json'},
    //   body: JSON.stringify({
    //   header:httItem,
    //   jdhttst:text,
    //   kjn_zai:('0000' + text2).slice(-5),
    //   lowhttsuu:('0000' + text3).slice(-5),
    //   cal_zai:W_cal_zai,
    // })});
    let ssiflg = 0;       // 修正フラグ
    // if(gamen === 2 && yobi === '3'){    // 130からの修正かつ予備＝３の時
    if(yobi === '3'){    // 予備＝３の時
      ssiflg = 2;
    }

    const response = await fetch('/TCSH0100/UpdJDHDat',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
      header:rowItem,
      jdhttst:W_jdhttst,
      kjn_zai:W_kjn_zai,
      lowhttsuu:W_lowhttsuu,
      cal_zai:W_cal_zai,
      Jancde:jancde,
      //S SK-0038-004 2024/7/4           TCI)M.K【課題No.72：自動発注不正データ発生の為、SQL是正】
      add_prg_id:'TCSH0130',
      //E SK-0038-004
      JGNCDE:state.JGNCDE,
      DELFLG:ssiflg,
    })});
    
    if(response.res === '-1') {
      console.log('送信失敗');
    }else{
      if (gamen === 2){
        dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
        KSACDES : state.KSACDES,MISES: state.MISES,K_KSACDES : state.K_KSACDE,K_MISES: state.K_MISE,
        MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
        SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: state.K_FLG,SDAY: state.SDAY,BUTTON:state.BUTTON,
        BMNCDE:state.BMNCDE,BRUMEI:state.BRUMEI,GRPCDE:state.GRPCDE,IRYOFLG:state.IRYOFLG,HTTYMD:sdate2,HTTKBN:'1', MVEMGAMEN:'TCSH0130', JANCDE:jancde
        //S SK-0037-001 2024/7/4           TCI)M.K【課題No.73：要チェックリストの遷移】
        ,ETC:bmncde
        //E SK-0037-001
        });        
        if (state.GAMEN == 0)
          { 
            console.log('スキャン発注開く')
            if (state.IRYOFLG === '0'){
              navigate('/TCSH0100');
            }else if (state.IRYOFLG === '1'){
              navigate('/TCSH0101');
            }
          }else{
            if (state.IRYOFLG === '0'){
              navigate('/TCSH0200');
            }else if (state.IRYOFLG === '1'){
              navigate('/TCSH0201');
            }
        }
      }else{
        switch(text)
        {
          case '01':
            setJdhttst('対象');
            break;
          case '02':
            setJdhttst('対象外');
            break;
          case '03':
            setJdhttst('店舗停止');
            break;
          case '04':
            setJdhttst('本部停止');
            break;
          default:
            break;
        };
        // setKjn_zai(text2);
        // setLowhttsuu(text3);
        // setCal_zai(text4);
        setKjn_zai(Number(W_kjn_zai));
        setLowhttsuu(Number(W_lowhttsuu));
        setCal_zai(Number(W_cal_zai));

        //S SK-0033-018 2024/7/3           TCI)M.K【課題No.69：集計用部門コード認識誤り】
        // 登録したら再検索する
        onClick_FooterButton_Next1();
        //E SK-0033-018
      }
    }

    // 閉じる
    window.glAlertDialogFlg = false;
    setSinopsInfoVisible(false);
  };
  // シノプス情報表示ここまで↑↑↑

  const clearSelect = () => {
    if (movefocusflg != 1) {
      setJtkbn(inJTKBN.current.value);
    }
    setMove(0);
  }

  // 初期処理
  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);
    setsDate2(year.toString() + ('0'+month.toString()).slice(-2) + ('0'+day.toString()).slice(-2));

    //S SK-0037-002 2024/7/4           TCI)M.K【課題No.73：要チェックリストの遷移】
    console.log('部門コードstateチェック:%o',state.ETC);
    if (state.ETC === undefined){
      setBmncde(state.BMNCDE);
    }else{
      setBmncde(state.ETC);
    }
    // 全選択状態とする
    setTimeout(() => {
      inBMNCDE.current.select();
    }, 500);
    //E SK-0037-002
  },[]);

  useEffect(() => {
  }, []);

  //S SK-0037-003 2024/7/4           TCI)M.K【課題No.73：要チェックリストの遷移】
  useEffect(() => {
    if (bmncde.length === 4){
      console.log('部門コードの変更を検知:%o',bmncde);
      onClick_FooterButton_Next1();
    }
  }, [bmncde]);
  //E SK-0037-003

  // ボタン１押下時の処理
  const onClick_FooterButton_Next1 = async() => {
    console.log('検索ボタン押下されました:%o',bmncde)
    let errflg = 0;
    //S SK-0016-001 2024/6/19           TCI)M.K【課題No.50：要チェック一覧1289対応】
    let jgbcde = 0;
    //E SK-0016-001
    //S SK-0033-006 2024/7/3           TCI)M.K【課題No.69：集計用部門コード認識誤り】
    let grpcde = 0;
    //E SK-0033-006

    // 2024/06/06 1289部門・3599部門考慮漏れ対応(今までと逆対応版)
    let line = 0;
    //S SK-0033-016 2024/7/3           TCI)M.K【課題No.69：集計用部門コード認識誤り】
    // if(state.BMNCDE  === '3599'||state.BMNCDE  === '3699'){
    if(state.BMNCDE  === '3599'||state.BMNCDE  === '1289'){
    //E SK-0033-016
      //S SK-0016-002 2024/6/19           TCI)M.K【課題No.50：要チェック一覧1289対応】
      // // console.log('(1)3599or3699の為、グループコードチェック:%o',state.GRPCDE);
      // // 部門名取得
      // const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
      //   body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:bmncde, ASTCDE:'XXX' })});
      // const j_ItemName = await getItemName.json();
      // console.log('(1)3599or3699の為、グループコードチェック:%o',j_ItemName.GRPCDE);
      // if(state.GRPCDE !== j_ItemName.GRPCDE){
      //   line = -1
      // }else{
      //   line = 0
      // }
      if (bmncde === '3599'){
        jgbcde = 3;
      //S SK-0033-017 2024/7/3           TCI)M.K【課題No.69：集計用部門コード認識誤り】
      }else if (bmncde === '1289'){
        jgbcde = 1;
        grpcde = 12;
      //E SK-0033-017
      }else{
        const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:bmncde, ASTCDE:'XXX' })});
        const j_ItemName = await getItemName.json();
        console.log('(1)1289or3599の為、グループコードチェック:%o',j_ItemName.GRPCDE);
        if(state.GRPCDE !== j_ItemName.GRPCDE){
          line = -1
        }else{
          line = 0
        }
      }
      //E SK-0016-002
    //S SK-0033-007 2024/7/3           TCI)M.K【課題No.69：集計用部門コード認識誤り】
    // }else if(state.BMNCDE  === '1289'){   // 1999⇒1289に変更（2024/04/15）
    }else if(state.BMNCDE  === '1999'){
    //E SK-0033-007
      //S SK-0016-003 2024/6/19           TCI)M.K【課題No.50：要チェック一覧1289対応】
      // // console.log('(2)1289の為、事業部コード１桁チェック:%o',state.GRPCDE);
      // // 部門名取得
      // const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
      //   body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:bmncde, ASTCDE:'XXX' })});
      // const j_ItemName = await getItemName.json();
      // console.log('(2)1289の為、事業部コード１桁チェック:%o',j_ItemName.JGBCDE);
      // if(state.GRPCDE !== j_ItemName.JGBCDE){
      //   line = -1
      // }else{
      //   line = 0
      // }
      //S SK-0033-008 2024/7/3           TCI)M.K【課題No.69：集計用部門コード認識誤り】
      // if (bmncde === '1289'){
      //   jgbcde = 1;
      if (bmncde === '1999'){
        jgbcde = 1;
      //E SK-0033-008
      }else{
        const getItemName = await fetch('/TCSH0100/MR_PRD_CLS_MST_GROUP_sel',{method: 'POST', headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ KSACDE:state.KSACDE, BMNCDE:bmncde, ASTCDE:'XXX' })});
        const j_ItemName = await getItemName.json();
        console.log('(2)1999の為、事業部コード１桁チェック:%o',j_ItemName.JGBCDE);
        if(state.GRPCDE !== j_ItemName.JGBCDE){
          line = -1
        }else{
          line = 0
        }
      }
      //E SK-0016-003
    }else{
      console.log('(3)それ以外の為、商品マスタ.部門コード全桁チェック:%o',state.BMNCDE,bmncde);
      if(state.BMNCDE  !== bmncde) {
        line = -1
      }else{
        line = 0
      }
    }

    console.log('errflg%o',line);
    if (line < 0)
    {
      //S SK-0016-004 2024/6/19           TCI)M.K【課題No.50：要チェック一覧1289対応】
      // window.alert('入力されたＪＡＮ１は部門が異なります');
      window.alert('入力された部門が異なります');
      inBMNCDE.current.select();
      //E SK-0016-004
    return -1;
    }else{
    }      
  
    // マイナス在庫一覧データ抽出
    failedSearch();
    const responseB1 = await fetch('/TCSH0100/search_minus', {method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        KSACDE: state.KSACDES,            // 会社コード
        MISE: state.MISES,                // 店
        BMNCDE: bmncde,                   // 部門
        YOBI: jtkbn,                      // 状態区分
        //S SK-0016-005 2024/6/19           TCI)M.K【課題No.50：要チェック一覧1289対応】
        JGBCDE: jgbcde,                   // 事業部コード
        //E SK-0016-005
        //S SK-0033-009 2024/7/3           TCI)M.K【課題No.69：集計用部門コード認識誤り】
        GRPCDE: grpcde,                   // グループコード
        //E SK-0033-009
        RCNT:0})});
    const dataB1 = await responseB1.json();
    if (dataB1.CNT === 0) {
      // データが存在しない場合は、以下のメッセージを表示して、明細の0行表示を行う。
      window.alert('条件に一致するデータが存在しません。'); //TCGE0065：
      failedSearch();
      return -1;
    }
    if (dataB1.CNT > dataB1.MAXCNT) {
      // 抽出結果が９９９件を超えてしまった場合は、再抽出を促すメッセージを表示する。
      window.alert('条件に一致するデータが多すぎます。条件を見直してください。'); //TCGE0066：
      return -1;
    }
    if (errflg == 0 )
    {

      let id = 1;
      const newRows = [];
      dataB1.ret.forEach(element => {
        newRows.push({
          id: id,
          //S SK-0047-001 2024/7/10           TCI)M.K【課題No.78：要チェックリスト並び順変更】
          // //S SK-0016-008 2024/6/19           TCI)M.K【課題No.50：要チェック一覧1289対応】
          // // astlincls:element.ASTCDE + '-' + element.LINCDE + '-' + element.CLSCDE,       // AU+ﾗｲﾝ+ｸﾗｽ
          // astlincls:element.BMNCDE + '-' + element.ASTCDE + '-' + element.LINCDE + '-' + element.CLSCDE,       // 部門+AU+ﾗｲﾝ+ｸﾗｽ
          // //E SK-0016-008
          astlincls:element.JOI_BMNCDE + '-' + element.ASTCDE + '-' + element.LINCDE + '-' + element.CLSCDE,       // 上位部門+AU+ﾗｲﾝ+ｸﾗｽ
          //E SK-0047-001
          jancde: element.JANCDE,       // JANコード
          //S SK-0011-003 2024/6/17           TCI)M.K【課題No.48：商品名領域拡張】
          // shnmeikn: element.SHNMEIKN,   // 商品名カナ
          shnmeikn: element.SHNMEIKN+element.KIKAKUKN,   // 商品名カナ+規格
          //E SK-0011-003
          irisuu: element.IRISUU,       // 入数 2024/06/06 追加
          SHNID: element.SHNID,         // 商品ID
          HENYMD: element.HENYMD,       // 変更日
          JDHTTST: element.JDHTTST,     // 自動発注状態
          KJN_ZAI: element.KJN_ZAI,     // 基準在庫
          LOWHTTSUU: element.LOWHTTSUU, // 最低発注数
          // CAL_ZAI: Number(element.CAL_ZAI),     // 在庫
          // CAL_ZAI: element.CAL_ZAI,     // 在庫
          CAL_ZAI: {                    // 在庫
            CAL_ZAI: element.CAL_ZAI,
            HEN_CAL_ZAI: element.HEN_CAL_ZAI,
            //S SK-0051-003 2024/7/24           TCI)M.K【課題No.77：在庫以外の修正は在庫をリアルタイム更新】
            CAL_ZAI_ENTTIM: element.CAL_ZAI_ENTTIM, //計算在庫入力時刻
            //E SK-0051-003
          },
          HEN_JDHTTST: element.HEN_JDHTTST,     // 自動発注状態
          HEN_KJN_ZAI: element.HEN_KJN_ZAI,     // 基準在庫
          HEN_LOWHTTSUU: element.HEN_LOWHTTSUU, // 最低発注数
          HEN_CAL_ZAI: element.HEN_CAL_ZAI,     // 在庫
          shnrank: element.SHNRANK,     // 商品ランク
          YOBI: element.YOBI,           // 状態区分
          DELFLG:element.DELFLG,        // 削除フラグ（２：修正済の場合、グレーアウトする）
        }
        );
        id++;
      });
      console.log('newRows:%o',newRows)

      setRows(newRows);
      return 0;
    }
  }

  // ボタン２押下時の処理
  const onClick_FooterButton_Next2 = async() => {
    const selected = rows.filter(x => selectionModel.indexOf(x.id) != -1);
    if (selected.length == 0) {
      window.alert('伝票が選択されていません。再度選択してください。'); 
      return;
    }else{
      console.log('選択行情報:%o',selected);
      const target = selected[0];
      console.log('test:%o', target);
      let kjn_zai = '-';
      let lowhttsuu = '-';
      let cal_zai = '-';
      setJancde(target.jancde);                   // ＪＡＮコード
      setShnmeikn(target.shnmeikn);               // 商品名
      setIrisuu(target.irisuu);                   // 入数 2024/06/06 追加
      setShnrank(target.shnrank);                 // 商品ランク
      setYobi(target.YOBI);                       // 予備
      if(Number(target.HENYMD) === 0) {
        console.log('変更日=0:'+target.HENYMD);
        setJdhttstcd(target.JDHTTST);                // 自動発注状態
        switch(target.JDHTTST)     // 自動発注状態01:対象　02:対象外 03:店舗停止 04:本部停止 　
        {
          case '01':
            setJdhttst('対象');
            break; 
          case '02':
            setJdhttst('対象外');
            break;
          case '03':
            setJdhttst('店舗停止');
            break;
          case '04':
            setJdhttst('本部停止');
            break;
          default:
            break;
        };
        console.log('変更日=0:target.CAL_ZAI',target.CAL_ZAI);
        // setKjn_zai(Number(target.KJN_ZAI));          // 基準在庫
        // setLowhttsuu(Number(target.LOWHTTSUU));      // 最低発注数
        // setCal_zai(Number(target.CAL_ZAI));          // 店舗在庫
        if(target.KJN_ZAI !== ''){kjn_zai = Number(target.KJN_ZAI);}
        if(target.LOWHTTSUU !== ''){lowhttsuu = Number(target.LOWHTTSUU);}
        // if(target.CAL_ZAI !== ''){cal_zai = Number(target.CAL_ZAI);}
        if(target.CAL_ZAI.CAL_ZAI !== ''){cal_zai = Number(target.CAL_ZAI.CAL_ZAI);}
        setKjn_zai(kjn_zai);                              // 基準在庫
        setLowhttsuu(lowhttsuu);                          // 最低発注数
        setCal_zai(cal_zai);                              // 店舗在庫
      }else{
        console.log('変更日≠0:'+target.HENYMD);
        setJdhttstcd(target.HEN_JDHTTST);                // 自動発注状態
        switch(target.HEN_JDHTTST)     // 自動発注状態01:対象　02:対象外 03:店舗停止 04:本部停止 　
        {
          case '01':
            setJdhttst('対象');
            break; 
          case '02':
            setJdhttst('対象外');
            break;
          case '03':
            setJdhttst('店舗停止');
            break;
          case '04':
            setJdhttst('本部停止');
            break;
          default:
            break;
        };
        // setKjn_zai(Number(target.HEN_KJN_ZAI));          // 基準在庫
        // setLowhttsuu(Number(target.HEN_LOWHTTSUU));      // 最低発注数
        // setCal_zai(Number(target.HEN_CAL_ZAI));          // 店舗在庫
        if(target.HEN_KJN_ZAI !== ''){kjn_zai = Number(target.HEN_KJN_ZAI);}
        if(target.HEN_LOWHTTSUU !== ''){lowhttsuu = Number(target.HEN_LOWHTTSUU);}
        //S SK-0051-002 2024/7/24           TCI)M.K【課題No.77：在庫以外の修正は在庫をリアルタイム更新】
        // // if(target.HEN_CAL_ZAI !== ''){cal_zai = Number(target.HEN_CAL_ZAI);}
        // if(target.CAL_ZAI.HEN_CAL_ZAI !== ''){cal_zai = Number(target.CAL_ZAI.HEN_CAL_ZAI);}
        if(target.CAL_ZAI.HEN_CAL_ZAI !== ''){
          if(target.CAL_ZAI.CAL_ZAI_ENTTIM !== ''){
            cal_zai = Number(target.CAL_ZAI.HEN_CAL_ZAI);
          }else{
            cal_zai = Number(target.CAL_ZAI.CAL_ZAI);
          }
        }
        //E SK-0051-002
        setKjn_zai(kjn_zai);                              // 基準在庫
        setLowhttsuu(lowhttsuu);                          // 最低発注数
        setCal_zai(cal_zai);                              // 店舗在庫
      }
      showSinopsInfo();
    }
  }

  // 戻るボタン押下時の処理
  const onClick_FooterButton_Back = async() => {
    navigate('/TCSH0030');
  }

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    //console.log(key);
    switch (key) {
      case "Enter":
        let errflg = 0;
        setAlert('　');
        errflg = await onClick_FooterButton_Next1();
        console.log('エラーフラグ:%o',errflg);
        if (errflg == 0)
        {
          console.log('エラー無し');
          inputRef.current.blur();        //次の項目に遷移出来ないからフォーカス外す
        }
        break;
      case "Home":
        break;
      case "ArrowUp":
        break;
      case "ArrowDown":
        break;
      default:
        break;
    }
  };  

  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  // 確認のWidthを設定する
  const cssInputOutlineRetSetwidth = (obj) => {
    let resObj = obj;
    resObj.width = '50px';
    return resObj;
  }

  // 検索失敗時の明細0件表示
  const failedSearch = async () => {
    setPagenationModel({page: 0, pageSize: 10});      // 2024/05/29 Pageを初期化する
    await setRows([]);
  }

  // グリッド列情報
  const columns /*: GridColDef[]*/ = [
    // {
    //   field: 'detailAction',
    //   headerName: '　', // セルの右側に罫線を表示するために全角スペースを表示する
    //   align: 'left',
    //   width: 60,
    //   type: 'actions',
    //   // getActions: getDetailAction,
    //   description: '詳細画面を表示',  // ツールチップで表示される説明
    //   hideable: false,          // 非表示にできないようにする
    //   sortable: false,          // ソートできないようにする
    //   hideSortIcons: false,     // ソートアイコンを表示しない
    //   resizable: false,         // リサイズできないようにする
    //   disableExport: false,     // エクスポートに含めないようにする
    //   disableReorder: false,    // 並べ替えできないようにする
    //   filterable: false,        // フィルタリングしないようにする
    //   disableColumnMenu: true  // カラムメニューを表示しない
    // } ,

    //IDは必ず必要みたい
    // {
    //   field: 'id',
    //   headerName: 'No.',
    //   // align:'center',
    //   width: 10 ,
    //   editable: false ,         //編集可能:false
    //   sortable: false,          // ソートできないようにする
    //   disableColumnMenu: true,   // カラムメニューを表示しない
    // },
    //S SK-0071-001 2024/9/6           TCI)M.K【課題No.97：分類の項目を一番後ろにする】
    // {
    //   field: 'astlincls',
    //   //S SK-0016-009 2024/6/19           TCI)M.K【課題No.50：要チェック一覧1289対応】
    //   // headerName: 'AU-ﾗｲﾝ-ｸﾗｽ',
    //   headerName: '分類-AU-ﾗｲﾝ-ｸﾗｽ',
    //   //E SK-0016-009
    //   headerAlign: 'center',
    //   // align:'center',
    //   width: 130 ,
    //   editable: false ,         //編集可能:false
    //   sortable: false,          // ソートできないようにする
    //   disableColumnMenu: true,   // カラムメニューを表示しない
    //   renderCell: (params) => (
    //     <div>
    //       <Typography>{params.value}</Typography>
    //     </div>
    //   )
    // },
    //E SK-0071-001
    {
      field: 'jancde',
      headerName: 'ＪＡＮ',
      headerAlign: 'center',
      // align:'center',
      width: 130 ,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{params.value}</Typography>
        </div>
      )
    },
    {
      field: 'shnmeikn',
      headerName: '商品名',
      headerAlign: 'center',
      //S SK-0011-001 2024/6/17           TCI)M.K【課題No.48：商品名領域拡張】
      // width: 155,
      width: 420,
      //E SK-0011-001
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,  // カラムメニューを表示しない
      disableColumnSelector: true,
      renderCell: (params) => (
        <div>
          <Typography>{params.value}</Typography>
        </div>
      )
    },
    {
      field: 'CAL_ZAI',
      headerName: '在庫数',
      headerAlign: 'center',
      align:'right',
      width: 70,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => {
        // console.log('params:' + params.value.HEN_CAL_ZAI);
        if (params.value.HEN_CAL_ZAI === ''){
          return (
            <div>
              <Typography>{params.value.CAL_ZAI}</Typography>
            </div>
          )
        }else{
          return (
            <div>
              <Typography>{params.value.HEN_CAL_ZAI}</Typography>
            </div>
          )
        }
      }
    },
    //S SK-0071-002 2024/9/6           TCI)M.K【課題No.97：分類の項目を一番後ろにする】
    {
      field: 'astlincls',
      //S SK-0016-009 2024/6/19           TCI)M.K【課題No.50：要チェック一覧1289対応】
      // headerName: 'AU-ﾗｲﾝ-ｸﾗｽ',
      headerName: '分類-AU-ﾗｲﾝ-ｸﾗｽ',
      //E SK-0016-009
      headerAlign: 'center',
      // align:'center',
      width: 130 ,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{params.value}</Typography>
        </div>
      )
    },
    //E SK-0071-002
  ];

  const handleSelectionModelChange = (newSelectionModel) => {
    // 新しい選択があった場合、その最後の要素のみを保持して更新
    if (newSelectionModel.length > 1) {
      // 最後に選択された1つのみを保持
      const newSelectedId = newSelectionModel[newSelectionModel.length - 1];
      setSelectionModel([newSelectedId]);
    } else {
      setSelectionModel(newSelectionModel);
    }
  };

  //罫線表示（Data Grid）
  const gridStyle = {
    transform: 'scale(0.9)',
    grid: {
      // x-data-gridの全体的な縮小
      '& .MuiDataGrid-root': {
        transform: 'scale(0.9)',
      },
      '.MuiDataGrid-toolbarContainer': {
        borderBottom: 'solid 1px rgba(224, 224, 224, 1)'  // ついでにツールバーの下に罫線を引く
      },
      '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
        borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      // 2022/06/01 追記 列ヘッダの最終セルの右側に罫線が表示されなくなった・・・
      // 列ヘッダの最終列の右端に罫線を表示する
      '.MuiDataGrid-columnHeadersInner': {
        border: 'solid 1px rgba(224, 224, 224, 1) !important',
        transform: 'scale(0.9)',
      },
      '.MuiDataGrid-columnSeparator--sideRight':{
        visibility: 'show',
      },
      '.MuiDataGrid-columnHeaders':{
        height:'40px',
      },
      // 削除済明細は背景色をグレーとする
      '.MuiDataGrid-row.row-deactive': {
        background: '#888888',
      },
      // 選択状態を黄色にする 2023/12/04 add imai
      '.MuiDataGrid-row.Mui-selected':{
        background: '#ffff00',
        '&:hover': {
          background: '#ffff00',       // ホバー時のバックグランドカラー
        },
      },
    }
  };

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
         fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
    cssInputOutlineJotKbn: {
      '> div': {
        minHeight: 'auto !important',
        paddingTop: '.4em',
        paddingBottom: '.3em',
      }
    },
    cssInputSelect: {
      ' label': {
        color: '#000000'
      },
      ' fieldset': {
        borderColor: '#000000'
      },
    },
  }

  return (

    <Grid>
      <CustomFormTCSH0110 
        isVisible={isSinopsInfoVisible} 
        message01={jancde}                // スキャンコード
        // message02={trihbn}                // JAN2
        // message03={shnmeikn}              // 商品名
        message03={shnmeikn+ ' 入数' + irisuu}              // 商品名 + 入数 2024/06/06 追加
        message04={jdhttstcd}             // 自動発注状態
        message05={kjn_zai}               // 最低陳列量
        message06={lowhttsuu}             // 最低発注数
        message07={cal_zai}               // 店舗在庫
        message08={shnrank}               // ランク
        callergamen={'TCSH0130'}          // 呼び出し元画面
        onClose={closeSinopsInfo} 
        // onClear={clearSinopsInfo} 
        onReg={regSinopsInfo} 
        handleValueChange04={handleValueChange04}
        handleValueChange05={handleValueChange05}
        handleValueChange06={handleValueChange06}
        handleValueChange07={handleValueChange07}
        />
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "470px",
          width: "280px",
          m: "20px auto",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="center"
        >
          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
            <Typography variant={'h3'} sx={{ m: '5px',mt:'1px',fontSize:11,width:400,mr:'10px' }}>
            {state.MISES}:{state.BMNCDE} {state.MISEMEI}:{state.BRUMEI}<br/>{state.JGNMEIKNJ}
            </Typography>
            <Typography variant={'h3'} sx={{ m: '5px',mt:'10px',fontSize:11 ,width:110,mr:'1px'}}>
              { sdate }
            </Typography>
          </Box>
          {/* 画像の角丸対応 */}
          <Typography variant={"h6"} sx={{ m: "10px",mt:'-10px' }}>
          要チェック一覧
          </Typography>
        </Grid>
        <Stack direction="row" spacing={2}>
          <FormControl size="small" sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 8))}>
            <InputLabel id="select-label" color='success'>種類</InputLabel>
            <Select
                inputRef={inJTKBN}
                color='success'
                defaultValue={'3'}
                labelId="select-label"
                id="select-label"
                className="cssInputOutlineJotKbn" 
                sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 12), styles.cssInputOutlineJotKbn, {background:inJtkbnclr})} 
                value={jtkbn}
                label="種類"
                onChange={handleChange}
                onFocus={clearSelect}
                >
              <MenuItem value={'1'}>１：過剰発注</MenuItem>
              <MenuItem value={'2'}>２：欠品</MenuItem>
              <MenuItem value={'3'}>３：ﾏｲﾅｽ在庫</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <br />
        <Stack direction='row' sx={{mb:'10px'}}>
          <NumericFormat  //部門CD
              id={'BMNCDE'}
              inputRef={inBMNCDE}                   //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='分類CD'                       //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              //sx={{width: '7ch',background:inBMNCDEclr,padding:'1px'}}                 //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 1), {background:inBMNCDEclr})}  //2023/11/07 入力項目の表示内容拡大
              value={bmncde}
              allowLeadingZeros                   //前ゼロ表示 on
              // disabled={disHeder}                    //使用可／不可
              //inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              // onChange={e => setBmncde(e.target.value)}
              onChange={(e)=>{
                const row = JSON.parse(JSON.stringify(rowItem));
                row.BMNCDE = e.target.value;
                setRowItem(row);
                setBmncde(e.target.value);
              }}              
              onKeyDown={(e) => onKeyDown(e.key,inBMNCDE,inBMNCDE,inBtnSearch)}
              onFocus={(e) => e.target.select()}
              />　
          <Box >
            <Button
              inputRef={inBtnSearch}                   //項目名定義？
              type="submit" 
              color="success" 
              variant="contained" 
              fullWidth
              onClick={(e) => onClick_FooterButton_Next1()}
              >
              {'照会'}
            </Button>
          </Box>
         </Stack>&nbsp;
         <Box className="dataGridList" style={gridStyle} sx={{ display: 'flex', justifyContent: 'flex-start',height: 300, width: '370px',padding:'0px',ml:'-45px',mt:'-30px' }}>
            <DataGrid
              sx={gridStyle.grid}     // --> 不具合対応のためcssで罫線を引く
              rows={rows}
              columns={columns}
              density='compact'//compact（狭め）、standard（標準）、comfortable（広め）
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              checkboxSelection={true}
              disableRowSelectionOnClick
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}  // メニュー日本語化
              showColumnRightBorder                                             // 列ヘッダセルの右側に線を引く
              showCellRightBorder                                               // セルの右側に線を引く
              getRowClassName={(params) => {
                if (params.row.DELFLG === '2') {
                  // 削除済明細にクラスを設定する
                  return 'row-deactive'
                }
                return '';
              }}
              rowSelectionModel={selectionModel}
              onRowSelectionModelChange={(RowIds) => {
                // const diffRows = rows.filter(x =>RowIds.indexOf(x.id) != -1);
                // const delRows = diffRows.filter(x => x.DELFLG == '1');
                // if (delRows.length > 0) {
                //   // 削除済明細の場合はクリックされた際に選択状態にしない
                //   return;
                // }
                // setSelectionModel(RowIds);
                console.log('RowIds,selectionModel:',RowIds,selectionModel);
                if (RowIds.length > 1) 
                {
                  const newSelectedId = RowIds[RowIds.length - 1];
                  setSelectionModel([newSelectedId]);
                }else if (RowIds.length === 0){
                  // setSelectionModel(selectionModel);
                  setSelectionModel([]);
                } else {
                  setSelectionModel(RowIds);
                }
              }}
              paginationModel={pagenationModel}
              onPaginationModelChange={(model) => {
                console.log(model);
                setPagenationModel(model);
              }}
              disableMultipleRowSelection={true}//複数行選択不可
              />
          </Box>
        <Box ml={2} flexDirection="row" justifyContent="flex-end" display="flex" marginTop={'5px'}>
          <Box >
            <Button
              variant='contained'
              color='success'
              // sx={{width:50,mr:'150px'}}
              sx={{width:50,mr:'150px',height:38,padding:0,mt:'-15px'}}
              onClick={(e) => onClick_FooterButton_Back()}
              >
              {'戻る'}
            </Button>
          </Box >
          <Box >
            <Button
              type="submit" 
              color="success" 
              variant="contained" 
              // sx={{width:50,mr:'10px'}}
              sx={{width:50,mr:'10px',height:38,padding:0,mt:'-15px'}}
              onClick={(e) => onClick_FooterButton_Next2()}
              >
              {'選択'}
            </Button>
          </Box >
        </Box>
        {/* <p>{ state.usrnm }</p> */}
        <p><font color="red">{ alert }</font></p>
      </Paper>
    </Grid>
  );
};

export default TCSH0130;