import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, TextField, Grid, Paper, Typography, Stack, InputLabel } from "@mui/material";
import { NumericFormat } from 'react-number-format';    //数値入力
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';

import CustomAlert from './CustomAlert';
import './common.css'

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F5キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F9キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F11") { // F9キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSA0180 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('ファンクションキーを押してください。');
  const [dismeisai, setMeisai] = useState(false);    //disabled設定（明細）
  const [sdate, setsDate] = useState([]);
  const [inp_kbn, setkbn] = useState('0');       //検索入力用伝票番号
  const [inp_kkn, setkkn] = useState('');       //リスト出力と画面戻る指示分岐用
  const [inp_THOOUTYMD, setTHOOUTYMD] = useState('');       //検索入力用伝票番
  const [upd_flg, setUpdflg] = useState('0');   //更新用フラグ

  const paramsA = {                      // 渡したいパラメータをJSON形式で書く
    method: 'POST',                       //POST送信(GETは×)
    headers: {
      'Content-Type': 'application/json'  //JSON形式
    },
    body: JSON.stringify({
      ENT_KSACDE: '001',
      ENT_MISE: '311',
      KIJYYM: 'xxx'
    })
  };

  const inKBN = useRef(null);
  const inTHOOUTYMD = useRef(null);
  const inRET = useRef(null);

  //項目のバックカラー制御用
  const [itKBNclr,setItkbnclr] = useState('#FFFFFF');
  //const [itTHOOUTYMD,setItTHOOUTYMD] = useState('#FFFFFF');
  const [itTHOOUTYMDclr,setItTHOOUTYMDclr] = useState('#FFFFFF');

  const commonStyles = {
    bgcolor: 'background.paper',
    m: 1,
    border: 0,
    width: '40px',
    height: '40px',
  };

  // アラート↓↓↓
  const [isAlertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  // アラートを表示
  const showAlert = (message) => {
    window.glAlertDialogFlg = true;
    setAlertMessage(message);
    setAlertVisible(true);
  };
  // アラートを閉じる
  const closeAlert = () => {
    window.glAlertDialogFlg = false;
    setAlertMessage('');
    setAlertVisible(false);
  };
  // アラートここまで↑↑↑

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
      PRINTsyori();
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      if (state.GAMEN == 0)
      {
        navigate('/TCSA0003');
      }else{
        navigate('/TCSA0005');
      }
    }
    if (event.keyCode === 120) { // F9キーが押された場合の処理
    }
    if (event.keyCode === 122) { // F11キーが押された場合の処理
      if (inKBN.current !== null) {
        inKBN.current.focus();
        console.log('state.KSACDE:'+state.KSACDES);
        console.log('state.MISES:'+state.MISES);
      }
      setAlert('Ｆ５：終了'); 
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応
  

  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    let d = new Date(state.SDAY);
    d.setDate(d.getDate() - 1);// 2023/11/02 kameda デフォルト-1日とする
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);

    let YYMMDD = String(year).substring(2,4)+ '/' + ('0'+month).slice(-2) + '/' + ('0'+day).slice(-2);
    setTHOOUTYMD(YYMMDD);
    if (state.KENGEN === '99')
    {
      setUpdflg('1');
    }

  },[]);
  
  const PRINTsyori = async() =>{
    //出力処理

    //チェック処理追加（2023/09/29亀田）
    if (CHECKsyori()===false){
      return;
    }
    //S 184-002 2023/09/20 連続印刷指示抑制の為、メッセージボックスを表示する
    // window.alert('印刷要求を受け付けました。しばらくお待ちください');
    showAlert('印刷要求を受け付けました。しばらくお待ちください');
    //E 184-002 2023/09/20 連続印刷指示抑制の為、メッセージボックスを表示する
    let inpYYYYMMDD = '20'+inp_THOOUTYMD.substring(0,2)+inp_THOOUTYMD.substring(3,5)+inp_THOOUTYMD.substring(6,8);
    switch (inp_kbn)
    {
      case '0':
        const responseA01 = await fetch('/TCSA0180/SL_HED_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,THOOUTYMD:inpYYYYMMDD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
        const dataA01 = await responseA01.json();
        if (dataA01.RCNT >= 1) //一件以上
        {
            const responseB01 = await fetch('/TCSA0180/SAPRT0010',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,THOOUTYMD:inpYYYYMMDD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
            const dataB01 = await responseB01.json();
            const responseB02 = await fetch('/TCSA0180/SAPRT0040',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,THOOUTYMD:inpYYYYMMDD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
            const dataB02 = await responseB02.json();
            const responseB03 = await fetch('/TCSA0180/SAPRT0050',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,THOOUTYMD:inpYYYYMMDD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
            const dataB03 = await responseB03.json();
            const responseB04 = await fetch('/TCSA0180/SAPRT0060',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,THOOUTYMD:inpYYYYMMDD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
            const dataB04 = await responseB04.json();
            setAlert('正常に処理が完了しました。'); //TCGM0003：
        
        }else
        {
            setAlert('出力データはありませんでした。');//TCGE0076：
        }
      break;
      case '1':
        const responseA02 = await fetch('/TCSA0180/SL_HED_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,THOOUTYMD:inpYYYYMMDD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
        const dataA02 = await responseA02.json();
        if (dataA02.RCNT >= 1) //一件以上
        {
            const responseB01 = await fetch('/TCSA0180/SAPRT0010',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,THOOUTYMD:inpYYYYMMDD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
            const dataB01 = await responseB01.json();
            setAlert('正常に処理が完了しました。'); //TCGM0003：
        }else
        {
            setAlert('出力データはありませんでした。');//TCGE0076：
        }
      break;
      case '4':
        const responseA03 = await fetch('/TCSA0180/SL_HED_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,THOOUTYMD:inpYYYYMMDD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
        const dataA03 = await responseA03.json();
        if (dataA03.RCNT >= 1) //一件以上
        {
            const responseB2 = await fetch('/TCSA0180/SAPRT0040',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,THOOUTYMD:inpYYYYMMDD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
            const dataB2 = await responseB2.json();
            setAlert('正常に処理が完了しました。'); //TCGM0003：
        }else
        {
            setAlert('出力データはありませんでした。');//TCGE0076：
        }
      break;
      case '5':
        const responseA04 = await fetch('/TCSA0180/SL_HED_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,THOOUTYMD:inpYYYYMMDD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
        const dataA04 = await responseA04.json();
        if (dataA04.RCNT >= 1) //一件以上
        {
            const responseB3 = await fetch('/TCSA0180/SAPRT0050',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,THOOUTYMD:inpYYYYMMDD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
            const dataB3 = await responseB3.json();
            setAlert('正常に処理が完了しました。'); //TCGM0003：
        }else
        {
            setAlert('出力データはありませんでした。');//TCGE0076：
        }
      break;
      case '6':
        const responseA05 = await fetch('/TCSA0180/SL_HED_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,THOOUTYMD:inpYYYYMMDD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
        const dataA05 = await responseA05.json();
        if (dataA05.RCNT >= 1) //一件以上
        {
            const responseB4 = await fetch('/TCSA0180/SAPRT0060',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,DENSBT:inp_kbn,THOOUTYMD:inpYYYYMMDD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
            const dataB4 = await responseB4.json();
            setAlert('正常に処理が完了しました。'); //TCGM0003：
        }else
        {
            setAlert('出力データはありませんでした。');//TCGE0076：
        }
      break;
      default:
        break;
     }
  };

  const CHECKsyori = () =>{
    //チェック処理
    console.log('チェック処理開始');
    console.log('inp_kbn'+inp_kbn);
    //初期化
    setAlert('ファンクションキーを押してください。')
    setItTHOOUTYMDclr('#FFFFFF');
    setItkbnclr('#FFFFFF');

    // 出力日チェック
    var strDate = '20'+inp_THOOUTYMD;
    var dtdate = new Date(strDate);

    if (isNaN(dtdate.getDate())==false){
    console.log('出力日チェック1'+'|'+strDate);
    console.log('日付範囲');
    let dt = new Date(state.SDAY);
    let dts = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate()-90); // 実行日から90日前の日付
    let dte = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate());      // 実行日の日付

    console.log('日付'+dts+'<='+dtdate+'<='+dte);
    if (dts <= dtdate && dtdate <= dte) //実行日90日前より過去または、実行日より未来
    {
      setAlert('ファンクションキーを押してください。');  
    }else{                
      setItTHOOUTYMDclr('#f59d9d');
      setAlert('日付入力エラー');//TCGE0016：
      return false;
    }
    }
    
    // 区分チェック
    if (!(inp_kbn === '0' || inp_kbn === '1' || inp_kbn === '4'|| inp_kbn === '5'|| inp_kbn === '6'))
    {
      setAlert('0,1,4～6のいずれかを入力してください！');//TCGE0077：
      setItkbnclr('#f59d9d');
      return false;
    }

    //チェックＯＫ
    setAlert('ファンクションキーを押してください。');  
    return true;
  };

  useEffect(() => {
    // // イベントリスナーを登録
    // window.addEventListener('keydown', (e) => {
    //   //e.preventDefault();
    //   switch(e.key)
    //   {
    //     case 'F5':
    //       e.preventDefault();
    //       navigate('/TCSA0003');
    //       break;

    //     case 'F11':
    //       e.preventDefault();
    //       if (inKBN.current !== null) {
    //         inKBN.current.focus();
    //         console.log('state.KSACDE:'+state.KSACDES);
    //         console.log('state.MISES:'+state.MISES);
    //       }
    //       setAlert('Ｆ５：終了'); 
    //       break;
    //     default:
    //       break;

    //   }
    // });
  }, []);

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    console.log('onKeyDown:'+key);
    console.log(inputRef.current.id);
    console.log('区分:'+inp_kbn);
    console.log('確認:'+inp_kkn);
    let mvFlg = 0;
    switch (key) {
      case "Enter":
        if (inputRef.current.id==='KBN') 
        {
          setItkbnclr('#FFFFFF');
          if (!(inp_kbn === '0' || inp_kbn === '1' || inp_kbn === '4'|| inp_kbn === '5'|| inp_kbn === '6'))
          {
            setAlert('0,1,4～6のいずれかを入力してください！');//TCGE0077：
            setItkbnclr('#f59d9d');
            inKBN.current.focus();
            inputRef.current.focus();
            mvFlg = 1;
          }
          setAlert('ファンクションキーを押してください。');  
        }
        else
        if (inputRef.current.id==='ret')
        {
          if (inp_kkn === '1')
          {
             PRINTsyori();
            }else if (inp_kkn === '5')
            {
              navigate('/TCSA0003');
            }
        }

        if (inputRef.current.id==='THOYMD') 
        {
          setItTHOOUTYMDclr('#FFFFFF');
            // 出力日チェック
            var strDate = '20'+inp_THOOUTYMD;
            var dtdate = new Date(strDate);
            //setItTHOOUTYMD('#FFFFFF');
            if (isNaN(dtdate.getDate())==false){
              console.log('出力日チェック1'+'|'+strDate);
              console.log('日付範囲');
              let dt = new Date(state.SDAY);
              let dts = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate()-90); // 実行日から90日前の日付
              let dte = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate());      // 実行日の日付
              //dts.setFullYear(dts.getFullYear() - 1);
              console.log('日付'+dts+'<='+dtdate+'<='+dte);
              if (dts <= dtdate && dtdate <= dte) //実行日90日前より過去または、実行日より未来
              {
                setAlert('ファンクションキーを押してください。');  
              }else{                
                setItTHOOUTYMDclr('#f59d9d');
                setAlert('日付入力エラー');//TCGE0016：
                mvFlg = 1;
                inTHOOUTYMD.current.focus();
                inputRef.current.focus();
              }
            }else{
              setItTHOOUTYMDclr('#f59d9d');
              setAlert('日付入力エラー');//TCGE0016：
              mvFlg = 1;
              inTHOOUTYMD.current.focus();
              inputRef.current.focus();
            }
        }
        
        if (mvFlg === 0)
        {
          
          inputNxRef.current.focus();   //次の項目に遷移
        }      
        
        break;
      case "Home":
        break;
      case "PageUp":
        inputRtRef.current.focus();   //次の項目に遷移
        break;
      case "PageDown":
        inputNxRef.current.focus();   //次の項目に遷移
        break;
      default:
        break;
    }
  };  

  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
         fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
  }

  return (

    <Grid>
      <CustomAlert isVisible={isAlertVisible} message={alertMessage} onClose={closeAlert} />
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "730px",
          width: "900px",
          m: "10px auto",
          padding:"10px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="left"
        >
          <div style={{display:"flex"}}>
            <Typography variant={"h5"} sx={{ m: "10px" }}>
              計上リスト再出力処理
            </Typography>
            <Box width={'65%'} display={'flex'} justifyContent={'flex-end'}>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"17px",fontSize:15 }}>
              {state.MISEMEI}：{state.JGNMEIKNJ}
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"17px",fontSize:15 }}>
                { state.SDAY }
              </Typography>　　　　　
            </Box>
          </div>
        </Grid>
        <br />
        <Stack direction="row">
          <font color="black">{ '計上リストを再出力します。' }</font>
        </Stack>
        <br />
        <br />        
        <Stack direction="row">
          <Grid>
            <div>　　</div>
          </Grid>
          <PatternFormat  //帳票出力日
            id={'THOYMD'}
            inputRef={inTHOOUTYMD}                   //項目名定義？
            format="##/##/##"
            mask={['Y','Y','M','M','D','D']}
            autoFocus                           //初期カーソル位置設定
            color='success'
            customInput={TextField}             //textfieldにreact-number-formatの機能を追加
            size='small'                        //textboxの全体サイズ
            label='処理日'                        //textboxの左上に表示するラベル
            variant='outlined'                  //枠の表示方法
            type='tel'                          //[PDA、Tablet対応]入力モード：tel
            inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
            fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
            decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
            InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
            onValueChange={(values, sourceInfo) => {
              console.log(values.value);
              console.log(values.formattedValue);
              console.log(values.floatValue);
            }}              
            sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 7), {background:itTHOOUTYMDclr})}
            //value={dbdata.ZEIRTU}               //初期値
            value={inp_THOOUTYMD}               //初期値
            allowLeadingZeros                   //前ゼロ表示 on
            disabled={false}                    //使用可／不可
            placeholder="YY/MM/DD"
            inputProps={{ autoComplete: 'off',maxLength: 9,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
            onKeyDown={(e) => onKeyDown(e.key,inTHOOUTYMD,inTHOOUTYMD,inKBN)}
            //onFocus={(e) => e.target.select()}
            onFocus={(e) => setTimeout(() => {inTHOOUTYMD.current.select();},100)}
            onChange={(event) => setTHOOUTYMD(event.target.value)}
            />
        </Stack>
        <br />
        <br />
        <Stack direction="row" >
          <Grid>
            <div>　　</div>
          </Grid>
          <NumericFormat  //区分
              id={'KBN'}
              inputRef={inKBN}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り           
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='区分'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 1), {background:itKBNclr})}
              //value={dbdata.ZEIRTU}               //初期値
              value={inp_kbn}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={false}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
              onKeyDown={(e) => onKeyDown(e.key,inKBN,inTHOOUTYMD,inRET)}
              onChange={(event) => setkbn(event.target.value)}
              //onFocus={(e) => e.target.select()}
              onFocus={(e) => setTimeout(() => {inKBN.current.select();},100)}
              />
              <InputLabel variant={'outlined'}>（０：全件　１：ターン　４：手書Ｉ型　５：移動　６：売変）</InputLabel>     
        </Stack>
        <br />
        <br />
        {/* <Stack direction="row" >
          <Grid>
            <div>　　</div>
          </Grid>
          <NumericFormat  //確認
                id={'ret'}
                inputRef={inRET}                    //項目名定義？
                autoFocus                           //初期カーソル位置設定       
                thousandSeparator={false}           //1000単位の区切り    
                color='success'
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='確認'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                type='tel'                          //[PDA、Tablet対応]入力モード：tel
                inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
                fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
                decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '5ch'}}                 //textboxの幅
                value={inp_kkn}               //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={false}                    //使用可／不可
                inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
                onKeyDown={(e) => onKeyDown(e.key,inRET,inKBN,inRET)}
                onChange={(event) => setkkn(event.target.value)}
                />    
                <InputLabel variant={'outlined'}>（１：リスト出力　５：前画面へ）</InputLabel>          
        </Stack> */}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <font color="red">{ alert }</font>
        <Stack direction="row" spacing={2}>
          <font color="black">{ 'Ｆ１:リスト出力　Ｆ５:終了　Up:前項目　Down:次項目　　　　　　　　　　　　　　　　　　　　　　　' }</font>
        </Stack>
      </Paper>
    </Grid>
  );
};

export default TCSA0180;