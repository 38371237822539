import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, TextField, Grid, Paper, Typography, Stack, InputLabel } from "@mui/material";
import Table from '@mui/material/Table';              //テーブル用
import TableBody from '@mui/material/TableBody';      //テーブル用
import TableCell from '@mui/material/TableCell';      //テーブル用
import TableContainer from '@mui/material/TableContainer';  //テーブル用
import TableHead from '@mui/material/TableHead';      //テーブル用
import TableRow from '@mui/material/TableRow';        //テーブル用
import { NumericFormat } from 'react-number-format';    //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import { onNextFocus } from './hooks/useOnMyFocus'     // select()処理
import TCSA0150_parts from './TCSA0150_parts';

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      console.log('useEventListener:'+event.key);
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F5キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F9キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

// コンポーネント呼び出し
const {
  CKubun,CDenpyoNo,CKeijyoYM,CMiseCode,CBunruiCode,CDenpyoYMD,
  CShohinCode,CGennin,CSuuryo,COldBaika,CNewBaika,CSagaku,CBaihenGaku,CFugou,
  CSuuryoGoukei,COldBaikaGoukei,CNewBaikaGoukei,CBaihenGakuGoukei
} = TCSA0150_parts();

function TCSA0150 () {
  //#region 変数
  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('');
  const [dismeisai, setMeisai] = useState(false);    //disabled設定（明細）
  const [sdate, setsDate] = useState([]);
  const [itRcnt,setItrcnt] = useState(0);
  const keyDisabled = useRef(false);
  const [regionList, setRegionList] = useState([]);  //店情報[高速化用]
  const [regionList2, setRegionList2] = useState([]);  //部門情報[高速化用]

  // コンポーネント
  const kubunRef = useRef(null);              // 区分
  const denpyoNoRef = useRef(null);           // 伝票No.
  const keijyoYMRef = useRef(null);           // 計上年月
  const miseCodeRef = useRef(null);           // 店CD
  const bunruiCodeRef = useRef(null);         // 分類CD
  const denpyoYMDRef = useRef(null);          // 売変実施日
  const shohinCode1Ref = useRef(null);        // 商品コード1
  const shohinCode2Ref = useRef(null);        // 商品コード2
  const shohinCode3Ref = useRef(null);        // 商品コード3
  const shohinCode4Ref = useRef(null);        // 商品コード4
  const shohinCode5Ref = useRef(null);        // 商品コード5
  const shohinCode6Ref = useRef(null);        // 商品コード6
  const shohinCode7Ref = useRef(null);        // 商品コード7
  const shohinCode8Ref = useRef(null);        // 商品コード8
  const gennin1Ref = useRef(null);            // 原因1
  const gennin2Ref = useRef(null);            // 原因2
  const gennin3Ref = useRef(null);            // 原因3
  const gennin4Ref = useRef(null);            // 原因4
  const gennin5Ref = useRef(null);            // 原因5
  const gennin6Ref = useRef(null);            // 原因6
  const gennin7Ref = useRef(null);            // 原因7
  const gennin8Ref = useRef(null);            // 原因8
  const suuryo1Ref = useRef(null);            // 数量1
  const suuryo2Ref = useRef(null);            // 数量2
  const suuryo3Ref = useRef(null);            // 数量3
  const suuryo4Ref = useRef(null);            // 数量4
  const suuryo5Ref = useRef(null);            // 数量5
  const suuryo6Ref = useRef(null);            // 数量6
  const suuryo7Ref = useRef(null);            // 数量7
  const suuryo8Ref = useRef(null);            // 数量8
  const oldBaika1Ref = useRef(null);          // 旧売価1
  const oldBaika2Ref = useRef(null);          // 旧売価2
  const oldBaika3Ref = useRef(null);          // 旧売価3
  const oldBaika4Ref = useRef(null);          // 旧売価4
  const oldBaika5Ref = useRef(null);          // 旧売価5
  const oldBaika6Ref = useRef(null);          // 旧売価6
  const oldBaika7Ref = useRef(null);          // 旧売価7
  const oldBaika8Ref = useRef(null);          // 旧売価8
  const newBaika1Ref = useRef(null);          // 新売価1
  const newBaika2Ref = useRef(null);          // 新売価2
  const newBaika3Ref = useRef(null);          // 新売価3
  const newBaika4Ref = useRef(null);          // 新売価4
  const newBaika5Ref = useRef(null);          // 新売価5
  const newBaika6Ref = useRef(null);          // 新売価6
  const newBaika7Ref = useRef(null);          // 新売価7
  const newBaika8Ref = useRef(null);          // 新売価8
  const sagaku1Ref = useRef(null);            // 差　額1
  const sagaku2Ref = useRef(null);            // 差　額2
  const sagaku3Ref = useRef(null);            // 差　額3
  const sagaku4Ref = useRef(null);            // 差　額4
  const sagaku5Ref = useRef(null);            // 差　額5
  const sagaku6Ref = useRef(null);            // 差　額6
  const sagaku7Ref = useRef(null);            // 差　額7
  const sagaku8Ref = useRef(null);            // 差　額8
  const baihenGaku1Ref = useRef(null);        // 売変額1
  const baihenGaku2Ref = useRef(null);        // 売変額2
  const baihenGaku3Ref = useRef(null);        // 売変額3
  const baihenGaku4Ref = useRef(null);        // 売変額4
  const baihenGaku5Ref = useRef(null);        // 売変額5
  const baihenGaku6Ref = useRef(null);        // 売変額6
  const baihenGaku7Ref = useRef(null);        // 売変額7
  const baihenGaku8Ref = useRef(null);        // 売変額8
  const fugou1Ref = useRef(null);             // 符号1
  const fugou2Ref = useRef(null);             // 符号2
  const fugou3Ref = useRef(null);             // 符号3
  const fugou4Ref = useRef(null);             // 符号4
  const fugou5Ref = useRef(null);             // 符号5
  const fugou6Ref = useRef(null);             // 符号6
  const fugou7Ref = useRef(null);             // 符号7
  const fugou8Ref = useRef(null);             // 符号8
  const suuryoGoukeiRef = useRef(null);       // 数量合計
  const oldBaikaGoukeiRef = useRef(null);     // 旧売価合計
  const newBaikaGoukeiRef = useRef(null);     // 新売価合計
  const baihenGakuGoukeiRef = useRef(null);   // 売価金額合計



  // ヘッダ部
  const inKBN = useRef(null);
  const inDENNO = useRef(null);
  const inKIJYYM = useRef(null);
  const inMISE = useRef(null);
  const inBMNCDE = useRef(null);
  const inDENYMD = useRef(null);

  // 確認
  const inRET = useRef(null);

  // 明細部
  const inSHNCDE1 = useRef(null);
  const inDWNGIIN1 = useRef(null);
  const inSUU1 = useRef(null);
  const inOLDBAK1 = useRef(null);
  const inNEWBAK1 = useRef(null);
  const inSAGAKU1 = useRef(null);
  const inBIHNGK1 = useRef(null);
  const inFGO1 = useRef(null);
  const inSHNCDE2 = useRef(null);
  const inDWNGIIN2 = useRef(null);
  const inSUU2 = useRef(null);
  const inOLDBAK2 = useRef(null);
  const inNEWBAK2 = useRef(null);
  const inSAGAKU2 = useRef(null);
  const inBIHNGK2 = useRef(null);
  const inFGO2 = useRef(null);
  const inSHNCDE3 = useRef(null);
  const inDWNGIIN3 = useRef(null);
  const inSUU3 = useRef(null);
  const inOLDBAK3 = useRef(null);
  const inNEWBAK3 = useRef(null);
  const inSAGAKU3 = useRef(null);
  const inBIHNGK3 = useRef(null);
  const inFGO3 = useRef(null);
  const inSHNCDE4 = useRef(null);
  const inDWNGIIN4 = useRef(null);
  const inSUU4 = useRef(null);
  const inOLDBAK4 = useRef(null);
  const inNEWBAK4 = useRef(null);
  const inSAGAKU4 = useRef(null);
  const inBIHNGK4 = useRef(null);
  const inFGO4 = useRef(null);
  const inSHNCDE5 = useRef(null);
  const inDWNGIIN5 = useRef(null);
  const inSUU5 = useRef(null);
  const inOLDBAK5 = useRef(null);
  const inNEWBAK5 = useRef(null);
  const inSAGAKU5 = useRef(null);
  const inBIHNGK5 = useRef(null);
  const inFGO5 = useRef(null);
  const inSHNCDE6 = useRef(null);
  const inDWNGIIN6 = useRef(null);
  const inSUU6 = useRef(null);
  const inOLDBAK6 = useRef(null);
  const inNEWBAK6 = useRef(null);
  const inSAGAKU6 = useRef(null);
  const inBIHNGK6 = useRef(null);
  const inFGO6 = useRef(null);
  const inSHNCDE7 = useRef(null);
  const inDWNGIIN7 = useRef(null);
  const inSUU7 = useRef(null);
  const inOLDBAK7 = useRef(null);
  const inNEWBAK7 = useRef(null);
  const inSAGAKU7 = useRef(null);
  const inBIHNGK7 = useRef(null);
  const inFGO7 = useRef(null);
  const inSHNCDE8 = useRef(null);
  const inDWNGIIN8 = useRef(null);
  const inSUU8 = useRef(null);
  const inOLDBAK8 = useRef(null);
  const inNEWBAK8 = useRef(null);
  const inSAGAKU8 = useRef(null);
  const inBIHNGK8 = useRef(null);
  const inFGO8 = useRef(null);

  // フッタ部
  const inSUUG = useRef(null);
  const inOLDBAKG = useRef(null);
  const inNEWBAKG = useRef(null);
  const inBIHNGKG = useRef(null);

  // ヘッダ部
  const [itKBNNM,setItKBNNM] = useState('');                      // タイトル部表示用（区分）

  // ベリファイ（２回入力）用
  const [itDENNO01,setItDENNO01] = useState('');                  //伝票No.１回目メモリ
  const [itBMNCDE01,setItBMNCDE01] = useState('');                //分類CD用１回目メモリ

  // 計上年月表示用
  const [itKIJYYM2,setItkijyym2] = useState('');                  //YYMM形式　計上年月

  //メモリ保存用
  const [itESJ_KBN1,setItesj_kbn1] = useState('');    //伝票明細：ＥＯＳ／ＪＡＮ区分1
  const [itESJ_KBN2,setItesj_kbn2] = useState('');    //伝票明細：ＥＯＳ／ＪＡＮ区分2
  const [itESJ_KBN3,setItesj_kbn3] = useState('');    //伝票明細：ＥＯＳ／ＪＡＮ区分3
  const [itESJ_KBN4,setItesj_kbn4] = useState('');    //伝票明細：ＥＯＳ／ＪＡＮ区分4
  const [itESJ_KBN5,setItesj_kbn5] = useState('');    //伝票明細：ＥＯＳ／ＪＡＮ区分5
  const [itESJ_KBN6,setItesj_kbn6] = useState('');    //伝票明細：ＥＯＳ／ＪＡＮ区分6
  const [itESJ_KBN7,setItesj_kbn7] = useState('');    //伝票明細：ＥＯＳ／ＪＡＮ区分7
  const [itESJ_KBN8,setItesj_kbn8] = useState('');    //伝票明細：ＥＯＳ／ＪＡＮ区分8

  // エラーチェック用 0:エラーなし, 1:未チェック, 2:エラー, 3：ベリファイ1回目入力, 9:確認中
  const [chkflg,setChkflg] = useState(0);               // チェックフラグ
  const [chkflg2,setChkflg2] = useState(0);             // チェックフラグ2
  const [inputConfirmflg,setInputConfirmflg] = useState(0);   // チェック完了フラグ

  const [blurTableflg,setBlurTableflg] = useState(false);       // blurTableflg
  // const [blurkey,setBlurkey] = useState(false);                 // blurTableflg時にPageUp処理追加
  const [ttl ,setTtl] = useState('');

  let isInInputConfirm = false;
  //#endregion

  //#region 初期処理
  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      navigate('/TCSA0003');
    }
    if (event.keyCode === 120) { // F9キーが押された場合の処理
      setAlert(' ');
      if (inDENNO.current !== null) {
        Gmclr();              //画面クリア
      }
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);
    if (state.KSACDES ==='005')
    {
      setTtl('(PH)');
    }else
    {
      setTtl('');
    }
    getRegionList();  //2023/11/21 MAP化

  },[]);

  const getRegionList = async() => {

    //店情報
    const get_region = await fetch('/TCSA0140/MR_SHP_MSTM_sel',{method: 'POST', headers:{'Content-Type': 'application/json'},
    body: JSON.stringify({ })});
    const data_region = await get_region.json();
    setRegionList(data_region.ret);

    //部門CD
    const get_region2 = await fetch('/TCSA0140/MR_SHP_MSTM_sel2',{method: 'POST', headers:{'Content-Type': 'application/json'},
    body: JSON.stringify({ })});
    const data_region2 = await get_region2.json();
    setRegionList2(data_region2.ret);

  }



  //#endregion

  //#region 呼出処理
  /**
   * 日付存在チェック
   * @param strYYYYMMDD 年月日（YYYY/MM/DD ⇒ 2023/09/07 等）
   * @returns true:存在, false:エラー
   */
  const ckDate = (strYYYYMMDD) =>{
    if(!strYYYYMMDD.match(/^\d{4}\/\d{2}\/\d{2}$/)){
        return false;
    }
    var y = strYYYYMMDD.split("/")[0];
    var m = strYYYYMMDD.split("/")[1] - 1;
    var d = strYYYYMMDD.split("/")[2];
    var date = new Date(y,m,d);
    if(date.getFullYear() != y || date.getMonth() != m || date.getDate() != d){
        return false;
    }
    return true;
  }

  /**
   * 計上年月算出
   * @param p_YMD 年月日（YYYY/MM/DD ⇒ 2023/09/07 等）
   * @returns 計上年月
   */
  const KeijoYM = (p_YMD) =>{
    const c_YMD = String(p_YMD).replaceAll('/','');
    const n_now = new Date(state.SDAY);
    const n_day = String(n_now.getDate()).padStart(2,'0');  //システム日付の日を取得
    const n_dt = String(n_now.getFullYear())+String(n_now.getMonth()+1).padStart(2,'0')+'21';    //当月の20日以前チェック用
    const np_now = new Date(n_now.getFullYear(),n_now.getMonth(),'01'); //月＋１計算用（一旦日を01にしないとおかしいくなる）

    console.log('n_now:%o',n_now);
    console.log('n_day:%o',n_day);
    console.log('n_dt:%o',n_dt);
    console.log('np_now:%o',np_now);

    np_now.setMonth(np_now.getMonth()+1); //マイナス１月対策

    console.log('対策後n_now:%o',n_now);
    console.log('対策後np_now:%o',np_now);
    console.log('n_now.getMonth:%o',n_now.getMonth())
    console.log('np_now:%o',np_now.getMonth())

    if (n_day <= '20')
    {
      //1パターン
      console.log('パターン１');
      return String(n_now.getFullYear()).slice(2,4)+'年'+String(n_now.getMonth()+1).padStart(2,'0')+'月';
    }else if(n_day === '21' || n_day === '22' )
    {
      // 2023/11/21 upd 誤り（手書きに仕様を合わせる
      // const c_YM = c_YMD.slice(0, 6);
      // const n_YM = String(n_now.getFullYear())+String(n_now.getMonth()+1);
      // if (c_YM < n_YM) {
        if (c_YMD < n_dt){
        //2パターン
        console.log('パターン２'+c_YMD+'<'+n_dt);
        return String(n_now.getFullYear()).slice(2,4)+'年'+String(n_now.getMonth()+1).padStart(2,'0')+'月';
      }else{
        //5パターン
        let ret = String(np_now.getFullYear()).slice(2,4)+'年'+String(np_now.getMonth()+1).padStart(2,'0')+'月';
        console.log('パターン５'+ret);
        return ret;
      }
    }else{
      //3,6パターン
      let ret = String(np_now.getFullYear()).slice(2,4)+'年'+String(np_now.getMonth()+1).padStart(2,'0')+'月';
      console.log('パターン３，６'+ret);
      return ret;
    }
  }

  /**
   * 頭0埋め処理
   * @param SetDate 対象情報
   * @param SFLG 0:商品コード以外、1:商品コード
   * @param NumDig 桁数(頭0埋め含む)
   * @returns
   */
  const Fhwith = (SetDate,SFLG=1,NumDig = 0) =>{
    let Afcorr;
    console.log('SetDate:%o',typeof(SetDate)) ;
    if (SetDate !== '')
    {
      if (SFLG == 1){
        switch(SetDate.length){
          case 1:
          case 2:
            Afcorr = SetDate.padStart(3,'0');
            break;
          case 7:
            Afcorr = SetDate.padStart(8,'0');
            break;
          case 9:
          case 10:
          case 11:
          case 12:
            Afcorr = SetDate.padStart(13,'0');
            break;
          default:
            Afcorr = SetDate;
            break;
        }
      }else{
        Afcorr = SetDate.padStart(NumDig,'0');
      }
    }else{
      Afcorr = SetDate;
    }
    return Afcorr;
  }

  /**
   * 値呼び出し
   * @param rowNo 呼び出し行
   * @param checkName 呼び出し項目
   * @returns 取得値
   */
  function getItitemval(rowNo,checkName) {
    let value = '';
    switch (rowNo) {
      case 1:
        switch(checkName){
          case 'shncde':
            value = shohinCode1Ref.current?.getValue();         // 商品コード1
            break;
          case 'DWNGIIN':
            value = gennin1Ref.current?.getValue();             // 原因1
            break;
          case 'SUU':
            value = suuryo1Ref.current?.getValue();             // 数量1
            break;
          case 'OLDBAK':
            value = oldBaika1Ref.current?.getValue();           // 旧売価1
            break;
          case 'NEWBAK':
            value = newBaika1Ref.current?.getValue();           // 新売価1
            break;
          case 'SAGAKU':
            value = sagaku1Ref.current?.getValue();             // 差　額1
            break;
          case 'BIHNGK':
            value = baihenGaku1Ref.current?.getValue();         // 売変額1
            break;
          case 'FGO':
            value = fugou1Ref.current?.getValue();              // 符号1
            break;
        }
        break;
      case 2:
        switch(checkName){
          case 'shncde':
            value = shohinCode2Ref.current?.getValue();         // 商品コード2
            break;
          case 'DWNGIIN':
            value = gennin2Ref.current?.getValue();             // 原因2
            break;
          case 'SUU':
            value = suuryo2Ref.current?.getValue();             // 数量2
            break;
          case 'OLDBAK':
            value = oldBaika2Ref.current?.getValue();           // 旧売価2
            break;
          case 'NEWBAK':
            value = newBaika2Ref.current?.getValue();           // 新売価2
            break;
          case 'SAGAKU':
            value = sagaku2Ref.current?.getValue();             // 差　額2
            break;
          case 'BIHNGK':
            value = baihenGaku2Ref.current?.getValue();         // 売変額2
            break;
          case 'FGO':
            value = fugou2Ref.current?.getValue();              // 符号2
            break;
        }
        break;
      case 3:
        switch(checkName){
          case 'shncde':
            value = shohinCode3Ref.current?.getValue();         // 商品コード3
            break;
          case 'DWNGIIN':
            value = gennin3Ref.current?.getValue();             // 原因3
            break;
          case 'SUU':
            value = suuryo3Ref.current?.getValue();             // 数量3
            break;
          case 'OLDBAK':
            value = oldBaika3Ref.current?.getValue();           // 旧売価3
            break;
          case 'NEWBAK':
            value = newBaika3Ref.current?.getValue();           // 新売価3
            break;
          case 'SAGAKU':
            value = sagaku3Ref.current?.getValue();             // 差　額3
            break;
          case 'BIHNGK':
            value = baihenGaku3Ref.current?.getValue();         // 売変額3
            break;
          case 'FGO':
            value = fugou3Ref.current?.getValue();              // 符号3
            break;
        }
        break;
      case 4:
        switch(checkName){
          case 'shncde':
            value = shohinCode4Ref.current?.getValue();         // 商品コード4
            break;
          case 'DWNGIIN':
            value = gennin4Ref.current?.getValue();             // 原因4
            break;
          case 'SUU':
            value = suuryo4Ref.current?.getValue();             // 数量4
            break;
          case 'OLDBAK':
            value = oldBaika4Ref.current?.getValue();           // 旧売価4
            break;
          case 'NEWBAK':
            value = newBaika4Ref.current?.getValue();           // 新売価4
            break;
          case 'SAGAKU':
            value = sagaku4Ref.current?.getValue();             // 差　額4
            break;
          case 'BIHNGK':
            value = baihenGaku4Ref.current?.getValue();         // 売変額4
            break;
          case 'FGO':
            value = fugou4Ref.current?.getValue();              // 符号4
            break;
        }
        break;
      case 5:
        switch(checkName){
          case 'shncde':
            value = shohinCode5Ref.current?.getValue();         // 商品コード5
            break;
          case 'DWNGIIN':
            value = gennin5Ref.current?.getValue();             // 原因5
            break;
          case 'SUU':
            value = suuryo5Ref.current?.getValue();             // 数量5
            break;
          case 'OLDBAK':
            value = oldBaika5Ref.current?.getValue();           // 旧売価5
            break;
          case 'NEWBAK':
            value = newBaika5Ref.current?.getValue();           // 新売価5
            break;
          case 'SAGAKU':
            value = sagaku5Ref.current?.getValue();             // 差　額5
            break;
          case 'BIHNGK':
            value = baihenGaku5Ref.current?.getValue();         // 売変額5
            break;
          case 'FGO':
            value = fugou5Ref.current?.getValue();              // 符号5
            break;
        }
        break;
      case 6:
        switch(checkName){
          case 'shncde':
            value = shohinCode6Ref.current?.getValue();         // 商品コード6
            break;
          case 'DWNGIIN':
            value = gennin6Ref.current?.getValue();             // 原因6
            break;
          case 'SUU':
            value = suuryo6Ref.current?.getValue();             // 数量6
            break;
          case 'OLDBAK':
            value = oldBaika6Ref.current?.getValue();           // 旧売価6
            break;
          case 'NEWBAK':
            value = newBaika6Ref.current?.getValue();           // 新売価6
            break;
          case 'SAGAKU':
            value = sagaku6Ref.current?.getValue();             // 差　額6
            break;
          case 'BIHNGK':
            value = baihenGaku6Ref.current?.getValue();         // 売変額6
            break;
          case 'FGO':
            value = fugou6Ref.current?.getValue();              // 符号6
            break;
        }
        break;
      case 7:
        switch(checkName){
          case 'shncde':
            value = shohinCode7Ref.current?.getValue();         // 商品コード7
            break;
          case 'DWNGIIN':
            value = gennin7Ref.current?.getValue();             // 原因7
            break;
          case 'SUU':
            value = suuryo7Ref.current?.getValue();             // 数量7
            break;
          case 'OLDBAK':
            value = oldBaika7Ref.current?.getValue();           // 旧売価7
            break;
          case 'NEWBAK':
            value = newBaika7Ref.current?.getValue();           // 新売価7
            break;
          case 'SAGAKU':
            value = sagaku7Ref.current?.getValue();             // 差　額7
            break;
          case 'BIHNGK':
            value = baihenGaku7Ref.current?.getValue();         // 売変額7
            break;
          case 'FGO':
            value = fugou7Ref.current?.getValue();              // 符号7
            break;
        }
        break;
      case 8:
        switch(checkName){
          case 'shncde':
            value = shohinCode8Ref.current?.getValue();         // 商品コード8
            break;
          case 'DWNGIIN':
            value = gennin8Ref.current?.getValue();             // 原因8
            break;
          case 'SUU':
            value = suuryo8Ref.current?.getValue();             // 数量8
            break;
          case 'OLDBAK':
            value = oldBaika8Ref.current?.getValue();           // 旧売価8
            break;
          case 'NEWBAK':
            value = newBaika8Ref.current?.getValue();           // 新売価8
            break;
          case 'SAGAKU':
            value = sagaku8Ref.current?.getValue();             // 差　額8
            break;
          case 'BIHNGK':
            value = baihenGaku8Ref.current?.getValue();         // 売変額8
            break;
          case 'FGO':
            value = fugou8Ref.current?.getValue();              // 符号8
            break;
        }
        break;
    }
    return value;
  }

  /**
   * 値設定
   * @param value 設定値
   * @param rowNo 設定行
   * @param checkName 設定項目
   */
  function setItitemval(value,rowNo,checkName) {
    switch (rowNo) {
      case 1:
        switch(checkName){
          case 'shncde':
            shohinCode1Ref.current?.setValue(value);       // 商品コード1
            break;
          case 'DWNGIIN':
            gennin1Ref.current?.setValue(value);      // 原因1
            break;
          case 'SUU':
            suuryo1Ref.current?.setValue(value);          // 数量1
            break;
          case 'OLDBAK':
            oldBaika1Ref.current?.setValue(value);       // 旧売価1
            break;
          case 'NEWBAK':
            newBaika1Ref.current?.setValue(value);       // 新売価1
            break;
          case 'SAGAKU':
            sagaku1Ref.current?.setValue(value);       // 差　額1
            break;
          case 'BIHNGK':
            baihenGaku1Ref.current?.setValue(value);       // 売変額1
            break;
          case 'FGO':
            fugou1Ref.current?.setValue(value);          // 符号1
            break;
          case 'esj_kbn':
            setItesj_kbn1(value);      // ＥＯＳ／ＪＡＮ区分1
            break;
        }
        break;
      case 2:
        switch(checkName){
          case 'shncde':
            shohinCode2Ref.current?.setValue(value);       // 商品コード2
            break;
          case 'DWNGIIN':
            gennin2Ref.current?.setValue(value);      // 原因2
            break;
          case 'SUU':
            suuryo2Ref.current?.setValue(value);          // 数量2
            break;
          case 'OLDBAK':
            oldBaika2Ref.current?.setValue(value);       // 旧売価2
            break;
          case 'NEWBAK':
            newBaika2Ref.current?.setValue(value);       // 新売価2
            break;
          case 'SAGAKU':
            sagaku2Ref.current?.setValue(value);       // 差　額2
            break;
          case 'BIHNGK':
            baihenGaku2Ref.current?.setValue(value);       // 売変額2
            break;
          case 'FGO':
            fugou2Ref.current?.setValue(value);          // 符号2
            break;
          case 'esj_kbn':
            setItesj_kbn2(value);      // ＥＯＳ／ＪＡＮ区分2
            break;
        }
        break;
      case 3:
        switch(checkName){
          case 'shncde':
            shohinCode3Ref.current?.setValue(value);       // 商品コード3
            break;
          case 'DWNGIIN':
            gennin3Ref.current?.setValue(value);      // 原因3
            break;
          case 'SUU':
            suuryo3Ref.current?.setValue(value);          // 数量3
            break;
          case 'OLDBAK':
            oldBaika3Ref.current?.setValue(value);       // 旧売価3
            break;
          case 'NEWBAK':
            newBaika3Ref.current?.setValue(value);       // 新売価3
            break;
          case 'SAGAKU':
            sagaku3Ref.current?.setValue(value);       // 差　額3
            break;
          case 'BIHNGK':
            baihenGaku3Ref.current?.setValue(value);       // 売変額3
            break;
          case 'FGO':
            fugou3Ref.current?.setValue(value);          // 符号3
            break;
          case 'esj_kbn':
            setItesj_kbn3(value);      // ＥＯＳ／ＪＡＮ区分3
            break;
        }
        break;
      case 4:
        switch(checkName){
          case 'shncde':
            shohinCode4Ref.current?.setValue(value);       // 商品コード4
            break;
          case 'DWNGIIN':
            gennin4Ref.current?.setValue(value);      // 原因4
            break;
          case 'SUU':
            suuryo4Ref.current?.setValue(value);          // 数量4
            break;
          case 'OLDBAK':
            oldBaika4Ref.current?.setValue(value);       // 旧売価4
            break;
          case 'NEWBAK':
            newBaika4Ref.current?.setValue(value);       // 新売価4
            break;
          case 'SAGAKU':
            sagaku4Ref.current?.setValue(value);       // 差　額4
            break;
          case 'BIHNGK':
            baihenGaku4Ref.current?.setValue(value);       // 売変額4
            break;
          case 'FGO':
            fugou4Ref.current?.setValue(value);          // 符号4
            break;
          case 'esj_kbn':
            setItesj_kbn4(value);      // ＥＯＳ／ＪＡＮ区分4
            break;
        }
        break;
      case 5:
        switch(checkName){
          case 'shncde':
            shohinCode5Ref.current?.setValue(value);       // 商品コード5
            break;
          case 'DWNGIIN':
            gennin5Ref.current?.setValue(value);      // 原因5
            break;
          case 'SUU':
            suuryo5Ref.current?.setValue(value);          // 数量5
            break;
          case 'OLDBAK':
            oldBaika5Ref.current?.setValue(value);       // 旧売価5
            break;
          case 'NEWBAK':
            newBaika5Ref.current?.setValue(value);       // 新売価5
            break;
          case 'SAGAKU':
            sagaku5Ref.current?.setValue(value);       // 差　額5
            break;
          case 'BIHNGK':
            baihenGaku5Ref.current?.setValue(value);       // 売変額5
            break;
          case 'FGO':
            fugou5Ref.current?.setValue(value);          // 符号5
            break;
          case 'esj_kbn':
            setItesj_kbn5(value);      // ＥＯＳ／ＪＡＮ区分5
            break;
        }
        break;
      case 6:
        switch(checkName){
          case 'shncde':
            shohinCode6Ref.current?.setValue(value);       // 商品コード6
            break;
          case 'DWNGIIN':
            gennin6Ref.current?.setValue(value);      // 原因6
            break;
          case 'SUU':
            suuryo6Ref.current?.setValue(value);          // 数量6
            break;
          case 'OLDBAK':
            oldBaika6Ref.current?.setValue(value);       // 旧売価6
            break;
          case 'NEWBAK':
            newBaika6Ref.current?.setValue(value);       // 新売価6
            break;
          case 'SAGAKU':
            sagaku6Ref.current?.setValue(value);       // 差　額6
            break;
          case 'BIHNGK':
            baihenGaku6Ref.current?.setValue(value);       // 売変額6
            break;
          case 'FGO':
            fugou6Ref.current?.setValue(value);          // 符号6
            break;
          case 'esj_kbn':
            setItesj_kbn6(value);      // ＥＯＳ／ＪＡＮ区分6
            break;
        }
        break;
      case 7:
        switch(checkName){
          case 'shncde':
            shohinCode7Ref.current?.setValue(value);       // 商品コード7
            break;
          case 'DWNGIIN':
            gennin7Ref.current?.setValue(value);      // 原因7
            break;
          case 'SUU':
            suuryo7Ref.current?.setValue(value);          // 数量7
            break;
          case 'OLDBAK':
            oldBaika7Ref.current?.setValue(value);       // 旧売価7
            break;
          case 'NEWBAK':
            newBaika7Ref.current?.setValue(value);       // 新売価7
            break;
          case 'SAGAKU':
            sagaku7Ref.current?.setValue(value);       // 差　額7
            break;
          case 'BIHNGK':
            baihenGaku7Ref.current?.setValue(value);       // 売変額7
            break;
          case 'FGO':
            fugou7Ref.current?.setValue(value);          // 符号7
            break;
          case 'esj_kbn':
            setItesj_kbn7(value);      // ＥＯＳ／ＪＡＮ区分7
            break;
        }
        break;
      case 8:
        switch(checkName){
          case 'shncde':
            shohinCode8Ref.current?.setValue(value);       // 商品コード8
            break;
          case 'DWNGIIN':
            gennin8Ref.current?.setValue(value);      // 原因8
            break;
          case 'SUU':
            suuryo8Ref.current?.setValue(value);          // 数量8
            break;
          case 'OLDBAK':
            oldBaika8Ref.current?.setValue(value);       // 旧売価8
            break;
          case 'NEWBAK':
            newBaika8Ref.current?.setValue(value);       // 新売価8
            break;
          case 'SAGAKU':
            sagaku8Ref.current?.setValue(value);       // 差　額8
            break;
          case 'BIHNGK':
            baihenGaku8Ref.current?.setValue(value);       // 売変額8
            break;
          case 'FGO':
            fugou8Ref.current?.setValue(value);          // 符号8
            break;
          case 'esj_kbn':
            setItesj_kbn8(value);      // ＥＯＳ／ＪＡＮ区分8
            break;
        }
        break;
      default:
        break;
    }
  }

  /**
   * 色設定
   * @param color 設定色
   * @param rowNo 設定行
   * @param checkName 設定項目
   */
  function setItitemclr(color,rowNo,checkName) {
    switch (rowNo) {
      case 1:
        switch(checkName){
          case 'shncde':
            shohinCode1Ref.current?.setBGColor(color);
            break;
          case 'suu':
            suuryo1Ref.current?.setBGColor(color);
            break;
          case 'DWNGIIN':
            gennin1Ref.current?.setBGColor(color);
            break;
          case 'bak':
            oldBaika1Ref.current?.setBGColor(color);
            newBaika1Ref.current?.setBGColor(color);
            break;
          default:
            break;
        }
        break;
      case 2:
        switch(checkName){
          case 'shncde':
            shohinCode2Ref.current?.setBGColor(color);
            break;
          case 'suu':
            suuryo2Ref.current?.setBGColor(color);
            break;
          case 'DWNGIIN':
            gennin2Ref.current?.setBGColor(color);
            break;
          case 'bak':
            oldBaika2Ref.current?.setBGColor(color);
            newBaika2Ref.current?.setBGColor(color);
            break;
          default:
            break;
        }
        break;
      case 3:
        switch(checkName){
          case 'shncde':
            shohinCode3Ref.current?.setBGColor(color);
            break;
          case 'suu':
            suuryo3Ref.current?.setBGColor(color);
            break;
          case 'DWNGIIN':
            gennin3Ref.current?.setBGColor(color);
            break;
          case 'bak':
            oldBaika3Ref.current?.setBGColor(color);
            newBaika3Ref.current?.setBGColor(color);
            break;
          default:
            break;
        }
        break;
      case 4:
        switch(checkName){
          case 'shncde':
            shohinCode4Ref.current?.setBGColor(color);
            break;
          case 'suu':
            suuryo4Ref.current?.setBGColor(color);
            break;
          case 'DWNGIIN':
              gennin4Ref.current?.setBGColor(color);
              break;
          case 'bak':
            oldBaika4Ref.current?.setBGColor(color);
            newBaika4Ref.current?.setBGColor(color);
            break;
          default:
            break;
        }
        break;
      case 5:
        switch(checkName){
          case 'shncde':
            shohinCode5Ref.current?.setBGColor(color);
            break;
          case 'suu':
            suuryo5Ref.current?.setBGColor(color);
            break;
          case 'DWNGIIN':
              gennin5Ref.current?.setBGColor(color);
              break;
          case 'bak':
            oldBaika5Ref.current?.setBGColor(color);
            newBaika5Ref.current?.setBGColor(color);
            break;
          default:
            break;
        }
        break;
      case 6:
        switch(checkName){
          case 'shncde':
            shohinCode6Ref.current?.setBGColor(color);
            break;
          case 'suu':
            suuryo6Ref.current?.setBGColor(color);
            break;
          case 'DWNGIIN':
              gennin6Ref.current?.setBGColor(color);
              break;
          case 'bak':
            oldBaika6Ref.current?.setBGColor(color);
            newBaika6Ref.current?.setBGColor(color);
            break;
          default:
            break;
        }
        break;
      case 7:
        switch(checkName){
          case 'shncde':
            shohinCode7Ref.current?.setBGColor(color);
            break;
          case 'suu':
            suuryo7Ref.current?.setBGColor(color);
            break;
          case 'DWNGIIN':
              gennin7Ref.current?.setBGColor(color);
              break;
          case 'bak':
            oldBaika7Ref.current?.setBGColor(color);
            newBaika7Ref.current?.setBGColor(color);
            break;
          default:
            break;
        }
        break;
      case 8:
        switch(checkName){
          case 'shncde':
            shohinCode8Ref.current?.setBGColor(color);
            break;
          case 'suu':
            suuryo8Ref.current?.setBGColor(color);
            break;
          case 'DWNGIIN':
            gennin8Ref.current?.setBGColor(color);
            break;
          case 'bak':
            oldBaika8Ref.current?.setBGColor(color);
            newBaika8Ref.current?.setBGColor(color);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  /**
   * 商品コードチェックフラグ変更
   * @param rowNo 設定行
   * @param cde 0:エラーなし, 1:未チェック, 2:エラー, 3：ベリファイ1回目入力, 9:確認中
   */
  function setChkSHNCDE (rowNo,cde){
    switch (rowNo) {
      case 1:
        shohinCode1Ref.current?.setCheckflg(cde);
        break;
      case 2:
        shohinCode2Ref.current?.setCheckflg(cde);
        break;
      case 3:
        shohinCode3Ref.current?.setCheckflg(cde);
        break;
      case 4:
        shohinCode4Ref.current?.setCheckflg(cde);
        break;
      case 5:
        shohinCode5Ref.current?.setCheckflg(cde);
        break;
      case 6:
        shohinCode6Ref.current?.setCheckflg(cde);
        break;
      case 7:
        shohinCode7Ref.current?.setCheckflg(cde);
        break;
      case 8:
        shohinCode8Ref.current?.setCheckflg(cde);
        break;
      default:
        break;
    }
  }

  //#endregion

  //#region onKeyDown
  /**
   * ボタン押下時の処理
   * @param key 入力キー
   * @param inputRef 現在の項目情報
   * @param inputRtRef 前項目情報
   * @param inputNxRef 次項目情報
   */
  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {
    let mvFlg = 0;
    console.log('key1:'+key+'/'+window.getSelection().toString());
    // setBlurkey(key);
    switch (key) {
      case "Enter":
        switch (inputRef.current.id) {
          // ＊＊＊ヘッダ部チェック＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊
          case 'KBN':         // 区分設定
            mvFlg = onKeyDown_KBN();
            break;
          case 'DENNO':       // 伝票No.
            mvFlg = await onKeyDown_DENNO();
            break;
          case 'MISE':        // 店CD
            mvFlg = await onKeyDown_MISE();
            break;
          case 'BMNCDE':      // 分類CD
            mvFlg = await onKeyDown_BMNCDE();
            break;
          case 'DENYMD':      // 売変実施日
            mvFlg = onKeyDown_DENYMD();
            break;
          // ＊＊＊ヘッダ部チェック＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊

          // ＊＊＊フッタ部チェック＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊
          case 'SUUG':        // 数量合計
            mvFlg = await onKeyDown_SUUG();
            break;
          case 'OLDBAKG':        // 旧売価合計
            mvFlg = await onKeyDown_OLDBAKG();
            break;
          case 'NEWBAKG':        // 新売価合計
            mvFlg = await onKeyDown_NEWBAKG();
            break;
          case 'BIHNGKG':        // 売変額合計
            mvFlg = await onKeyDown_BIHNGKG();
            break;
          // ＊＊＊フッタ部チェック＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊
          // ＊＊＊確認ボタン処理＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊
          case 'ret':         // 登録処理
            setAlert('　');
            if (keyDisabled.current)
            {
              return;
            }
            keyDisabled.current = true;
            // setItretclr('#FFFFFF');
            // console.log('確認:',kubunRef.current?.getValue());
            const ret_kbn = onKeyDown_KBN(); // 入力エラーがなければ0が返る
            if (ret_kbn == 0) {
              isInInputConfirm = true;
              mvFlg = 2;
              await InputConfirmed();
              isInInputConfirm = false;
            } else {
              inKBN.current.focus();
              mvFlg = 2;
            }
            setTimeout(() => {
              // 処理中フラグを下げる
              keyDisabled.current = false;
            }, 1500);
            break;
        // ＊＊＊確認ボタン処理＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊
          default:
            break;
        }

        // ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊
        switch (mvFlg) {
          case 0:
            inputNxRef.current.focus();   //次の項目に遷移
            onNextFocus(inputNxRef);
            break;
          case 1:
            inputRef.current.focus();   //現在の項目に遷移
            onNextFocus(inputRef);
            break;
          case 2:
            break;
          case 9:
            break;
          default:
            break;
        }
        break;
      case "PageUp":
        // 確認で押下時の処理
        if (inputRef.current.id == 'ret'){
          if (kubunRef.current?.getValue() === '1'){
            inNEWBAKG.current.focus();
          }else if (kubunRef.current?.getValue() === '2'){
            inDENNO.current.focus();
          }else{
            inDENNO.current.focus();
          }
        }else{
          // 確認以外での遷移処理
          inputRtRef.current.focus();
        }
        break;
      case "PageDown":
          inputNxRef.current.focus();
        break;
      default:
        break;
    }
    console.log('key2:'+key+'/'+window.getSelection().toString());
  };

  /**
   * ボタン押下時の処理
   * @param key 入力キー
   * @param inputRef 現在の項目情報
   * @param inputNxRef 次項目情報
   * @param inUpRef 上キー
   * @param inRgRef 右キー
   * @param inDnRef 下キー
   * @param inLfRef 左キー
   */
  const onKeyDown_Meisai =  async (key,inputRef,inputNxRef,inUpRef,inRgRef,inDnRef,inLfRef) => {
    let mvFlg = 0;
    switch (key) {
      case "Enter":
        switch (inputRef.current.id) {
          // ＊＊＊明細部チェック＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊
          case 'SHNCDE1':     // 商品コード1
            mvFlg = 2;
            shohinCode1Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(inputNxRef,1) == 0){
              onKeyDown_SHNCDE(1);
            }
            break;
          case 'SHNCDE2':     // 商品コード2
            mvFlg = 2;
            shohinCode2Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(inputNxRef,2) == 0){
              onKeyDown_SHNCDE(2);
            }
            break;
          case 'SHNCDE3':     // 商品コード3
            mvFlg = 2;
            shohinCode3Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(inputNxRef,3) == 0){
              onKeyDown_SHNCDE(3);
            }
            break;
          case 'SHNCDE4':     // 商品コード4
            mvFlg = 2;
            shohinCode4Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(inputNxRef,4) == 0){
              onKeyDown_SHNCDE(4);
            }
            break;
          case 'SHNCDE5':     // 商品コード5
            mvFlg = 2;
            shohinCode5Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(inputNxRef,5) == 0){
              onKeyDown_SHNCDE(5);
            }
            break;
          case 'SHNCDE6':     // 商品コード6
              mvFlg = 2;
              shohinCode6Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(inputNxRef,6) == 0){
              onKeyDown_SHNCDE(6);
            }
            break;
          case 'SHNCDE7':     // 商品コード7
            mvFlg = 2;
            shohinCode7Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(inputNxRef,7) == 0){
              onKeyDown_SHNCDE(7);
            }
            break;
          case 'SHNCDE8':     // 商品コード8
            mvFlg = 2;
            shohinCode8Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(inputNxRef,8) == 0){
              onKeyDown_SHNCDE(8);
            }
            break;
          case 'DWNGIIN1':    // 原因
            onKeyDown_DWNGIIN(1);
            break;
          case 'DWNGIIN2':    // 原因
            onKeyDown_DWNGIIN(2);
            break;
          case 'DWNGIIN3':    // 原因
            onKeyDown_DWNGIIN(3);
            break;
          case 'DWNGIIN4':    // 原因
            onKeyDown_DWNGIIN(4);
            break;
          case 'DWNGIIN5':    // 原因
            onKeyDown_DWNGIIN(5);
            break;
          case 'DWNGIIN6':    // 原因
            onKeyDown_DWNGIIN(6);
            break;
          case 'DWNGIIN7':    // 原因
            onKeyDown_DWNGIIN(7);
            break;
          case 'DWNGIIN8':    // 原因
            onKeyDown_DWNGIIN(8);
            break;
          case 'SUU1':        // 数量
            onKeyDown_SUU(1);
            break;
          case 'SUU2':        // 数量
            onKeyDown_SUU(2);
            break;
          case 'SUU3':        // 数量
            onKeyDown_SUU(3);
            break;
          case 'SUU4':        // 数量
            onKeyDown_SUU(4);
            break;
          case 'SUU5':        // 数量
            onKeyDown_SUU(5);
            break;
          case 'SUU6':        // 数量
            onKeyDown_SUU(6);
            break;
          case 'SUU7':        // 数量
            onKeyDown_SUU(7);
            break;
          case 'SUU8':        // 数量
            onKeyDown_SUU(8);
            break;
          case 'OLDBAK1':     // 旧売価
            onKeyDown_OLDBAK(1);
            break;
          case 'OLDBAK2':     // 旧売価
            onKeyDown_OLDBAK(2);
            break;
          case 'OLDBAK3':     // 旧売価
            onKeyDown_OLDBAK(3);
            break;
          case 'OLDBAK4':     // 旧売価
            onKeyDown_OLDBAK(4);
            break;
          case 'OLDBAK5':     // 旧売価
            onKeyDown_OLDBAK(5);
            break;
          case 'OLDBAK6':     // 旧売価
            onKeyDown_OLDBAK(6);
            break;
          case 'OLDBAK7':     // 旧売価
            onKeyDown_OLDBAK(7);
            break;
          case 'OLDBAK8':     // 旧売価
            onKeyDown_OLDBAK(8);
            break;
          case 'NEWBAK1':     // 新売価
            onKeyDown_NEWBAK(1);
            break;
          case 'NEWBAK2':     // 新売価
            onKeyDown_NEWBAK(2);
            break;
          case 'NEWBAK3':     // 新売価
            onKeyDown_NEWBAK(3);
            break;
          case 'NEWBAK4':     // 新売価
            onKeyDown_NEWBAK(4);
            break;
          case 'NEWBAK5':     // 新売価
            onKeyDown_NEWBAK(5);
            break;
          case 'NEWBAK6':     // 新売価
            onKeyDown_NEWBAK(6);
            break;
          case 'NEWBAK7':     // 新売価
            onKeyDown_NEWBAK(7);
            break;
          case 'NEWBAK8':     // 新売価
            onKeyDown_NEWBAK(8);
            break;
          // ＊＊＊明細部チェック＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊
          default:
            break;
        }

        // ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊
        switch (mvFlg) {
          case 0:
            inputNxRef.current.focus();   //次の項目に遷移
            onNextFocus(inputNxRef);
            break;
          case 1:
            inputRef.current.focus();   //現在の項目に遷移
            onNextFocus(inputRef);
            break;
          case 2:
            break;
          case 9:
            break;
          default:
            break;
        }
        break;
      case "PageUp":
        inBMNCDE.current.focus();
        break;
      case "PageDown":
        inSUUG.current.focus();
        break;
      case "ArrowUp":
        inUpRef.current.focus();      //上の項目遷移（明細用）
        break;
      case "ArrowRight":
        inRgRef.current.focus();      //右の項目遷移（明細用）
        break;
      case "ArrowDown":
        inDnRef.current.focus();      //右の項目遷移（明細用）
        break;
      case "ArrowLeft":
        inLfRef.current.focus();      //左の項目遷移（明細用）
        break;
      default:
        break;
    }
  };

  /**
   * 確認項目にフォーカス時の処理
   */
  function onFocus_ret(){
    switch(kubunRef.current?.getValue()){
      case '1':
        setAlert('確認してください！！');//TCGM0005：
        break;
      case '2':
        setAlert('確認してください！！');//TCGM0005：
        break;
      case '3':
        setAlert('削除を行いますか？');//TCGM0006：
        break;
      default:
        setAlert('　');
        break;
    }
  }
  //#endregion

  const getInputType = (id) => {
    let ret = 0;
    switch (id) {
      case 'KBN':         // 区分設定
      case 'DENNO':       // 伝票No.
      case 'MISE':        // 店CD
      case 'BMNCDE':      // 分類CD
      case 'DENYMD':      // 売変実施日
        ret = 1;
        break;
      case 'SHNCDE1':     // 商品コード1
      case 'DWNGIIN1':    // 原因1
      case 'SUU1':        // 数量1
      case 'OLDBAK1':     // 旧売価1
      case 'NEWBAK1':     // 新売価1
      case 'SHNCDE2':     // 商品コード2
      case 'DWNGIIN2':    // 原因2
      case 'SUU2':        // 数量2
      case 'OLDBAK2':     // 旧売価2
      case 'NEWBAK2':     // 新売価2
      case 'SHNCDE3':     // 商品コード3
      case 'DWNGIIN3':    // 原因3
      case 'SUU3':        // 数量3
      case 'OLDBAK3':     // 旧売価3
      case 'NEWBAK3':     // 新売価3
      case 'SHNCDE4':     // 商品コード4
      case 'DWNGIIN4':    // 原因4
      case 'SUU4':        // 数量4
      case 'OLDBAK4':     // 旧売価4
      case 'NEWBAK4':     // 新売価4
      case 'SHNCDE5':     // 商品コード5
      case 'DWNGIIN5':    // 原因5
      case 'SUU5':        // 数量5
      case 'OLDBAK5':     // 旧売価5
      case 'NEWBAK5':     // 新売価5
      case 'SHNCDE6':     // 商品コード6
      case 'DWNGIIN6':    // 原因6
      case 'SUU6':        // 数量6
      case 'OLDBAK6':     // 旧売価6
      case 'NEWBAK6':     // 新売価6
      case 'SHNCDE7':     // 商品コード7
      case 'DWNGIIN7':    // 原因7
      case 'SUU7':        // 数量7
      case 'OLDBAK7':     // 旧売価7
      case 'NEWBAK7':     // 新売価7
      case 'SHNCDE8':     // 商品コード8
      case 'DWNGIIN8':    // 原因8
      case 'SUU8':        // 数量8
      case 'OLDBAK8':     // 旧売価8
      case 'NEWBAK8':     // 新売価8
        ret = 2;
        break;
      case 'SUUG':        // 数量合計
      case 'OLDBAKG':     // 旧売価合計
      case 'NEWBAKG':     // 新売価合計
      case 'BIHNGKG':     // 売変額合計
        ret = 3;
        break;
      case 'ret':         // 登録処理
        ret = 9;
        break;
      default:
        break;
    }
    return ret;
  }
  //#endregion

  //#region ヘッダ部入力
  // 区分設定
  function onKeyDown_KBN() {
    let mvFlg = 0;
    console.log('KBNの処理実行');
    kubunRef.current?.setBGColor('#FFFFFF');
    setAlert('　');

    switch(kubunRef.current?.getValue()){
      case '1':
        setItKBNNM('新規');
        break;
      case '2':
        setItKBNNM('修正');
        break;
      case '3':
        setItKBNNM('削除');
        break;
      default:
        console.log('1:新規,2:修正,3:削除以外は入力できません。');
        setAlert('区分に1:新規,2:修正,3:削除以外は入力できません。');//TCGE0008：
        kubunRef.current?.setBGColor('#f59d9d');
        mvFlg = 1;
        break;
    }
    return mvFlg;
  }

  // ヘッダ部入力 伝票No.
  const onKeyDown_DENNO = async()=>{
    let mvFlg = 0;
    let ldenno = '';

    // 入力値の前0詰め
    if (denpyoNoRef.current?.getValue() !== ''){
      ldenno = denpyoNoRef.current?.getValue().padStart(7,'0');
      denpyoNoRef.current?.setValue(ldenno);
    }

    // 区分「1」の場合ベリファイを実行
    if (kubunRef.current?.getValue() == '1'){
      /*** 入力1回目 ***/
      denpyoNoRef.current?.setBGColor('#FFFFFF');
      console.log('DENNOの処理実行');
      console.log('SL_HED_DAT_6_sel:'+ldenno+'|'+itDENNO01);
      if (itDENNO01 == '' && ldenno != ''){
        mvFlg = 1;
        setItDENNO01(ldenno);
        denpyoNoRef.current?.setValue('');
        denpyoNoRef.current?.setCheckflg(3);
        return mvFlg;
      }

      if(isInInputConfirm){
        // 空白時の処理
        if (ldenno == ''){
          setAlert('伝票№入力エラー');//TCGE0014：
          denpyoNoRef.current?.setCheckflg(2);
          mvFlg = 1;
        }
      }

      /*** 入力2回目 ***/
      // ベリファイエラー
      if (itDENNO01 != ldenno){
        setAlert('伝票№入力エラー');//TCGE0014：
        denpyoNoRef.current?.setBGColor('#f59d9d');
        setItDENNO01(ldenno);
        denpyoNoRef.current?.setValue('');
        denpyoNoRef.current?.setCheckflg(2);
        mvFlg = 1;
        return mvFlg;
      }

      // 空白時の処理
      if (ldenno == ''){
        setAlert('　');
        setItDENNO01(ldenno);
        if(isInInputConfirm){
          setAlert('伝票№入力エラー');//TCGE0014：
          denpyoNoRef.current?.setCheckflg(2);
          mvFlg = 1;
        }else{
          mvFlg = 0;
        }
        return mvFlg;
      }
      denpyoNoRef.current?.setBGColor('#FFFFFF');
      const responseA = await fetch('/TCSA0150/SL_HED_DAT_6_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ent_ksacde: state.KSACDES,ent_mise: state.MISES,denno:ldenno,RCNT:0})});
      const dataA = await responseA.json();
      console.log('伝票ヘッダ:%o',dataA);
      console.log('件数:%o',dataA.RCNT);
      //一件以上
      if(dataA.RCNT >= 1){
        setAlert('既に登録済みの伝票です。');//TCGE0032：
        denpyoNoRef.current?.setBGColor('#f59d9d');
        mvFlg = 1;
        denpyoNoRef.current?.setCheckflg(2);
        return mvFlg;
      }

      console.log('itDENNO01 == denpyoNoRef.current?.getValue() else');
      setAlert('　');
      //2023/09/07追加
      if (mvFlg === 0){
        miseCodeRef.current?.setValue(state.MISES);
        if(!isInInputConfirm) {
          inBMNCDE.current.focus();
          mvFlg = 2;
        }
      }
    // 区分「2」または「3」の場合ベリファイを実行しない
    }else{
      // 空白時の処理
      if (ldenno == ''){
        setAlert('　');
        setItDENNO01(ldenno);
        if(isInInputConfirm){
          setAlert('伝票№入力エラー');//TCGE0014：
          denpyoNoRef.current?.setCheckflg(2);
          mvFlg = 1;
        }else{
          mvFlg = 0;
        }
        return mvFlg;
      }
      denpyoNoRef.current?.setBGColor('#FFFFFF');
      // 伝票ヘッダデータ取得
      const responseA = await fetch('/TCSA0150/SL_HED_DAT_6_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ent_ksacde: state.KSACDES,ent_mise: state.MISES,denno:ldenno,RCNT:0,SND_KNR_KBN:''})});
      const dataA = await responseA.json();
      console.log('伝票ヘッダ:%o',dataA);
      console.log('件数:%o',dataA.RCNT);

      // 伝票ヘッダ0件エラー
      if (dataA.RCNT == 0){
        setAlert('伝票が登録されていません。');//TCGE0028：
        denpyoNoRef.current?.setBGColor('#f59d9d');
        mvFlg = 1;
        denpyoNoRef.current?.setCheckflg(2);
        return mvFlg;
      }

      // 伝票ヘッダ1件以上
      console.log('SND_KNR_KBN:',dataA.res[0].SND_KNR_KBN);
      // 伝票明細データ取得
      const responseB = await fetch('/TCSA0150/SL_DTL_DAT_6_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ent_ksacde: state.KSACDES,ent_mise: state.MISES,denno:ldenno,RCNT:0})});
      const dataB = await responseB.json();
      console.log('伝票明細:%o',dataB);
      console.log('件数:%o',dataB.RCNT);

      // 伝票明細データ0件エラー
      if (dataB.RCNT == 0){
        setAlert('伝票の明細が登録されていません。');//TCGE0033：
        denpyoNoRef.current?.setBGColor('#f59d9d');
        mvFlg = 1;
        denpyoNoRef.current?.setCheckflg(2);
        return mvFlg;
      }

      // 送信済伝票エラー
      if(dataA.res[0].SND_KNR_KBN != '0'){
        setAlert('送信処理済みの伝票です。（修正・削除不可）');//TCGE0029：
        denpyoNoRef.current?.setBGColor('#f59d9d');
        mvFlg = 1;
        denpyoNoRef.current?.setCheckflg(2);
        return mvFlg;
      }

      // 伝票明細1件以上
      console.log('dataB',dataB);

      setAlert('　');
      // 伝票ヘッダ
      keijyoYMRef.current?.setValue(String(dataA.res[0].KIJYYM).substring(2,4)+'年'+String(dataA.res[0].KIJYYM).slice(-2)+'月');   // 計上年月
      setItkijyym2(String(dataA.res[0].KIJYYM).substring(2,4)+String(dataA.res[0].KIJYYM).slice(-2));
      miseCodeRef.current?.setValue(dataA.res[0].ENT_MISE);                                                // 店CD
      bunruiCodeRef.current?.setValue(dataA.res[0].BMNCDE);                                                // 分類CD
      setItBMNCDE01(dataA.res[0].BMNCDE);                                              // 分類CD01
      denpyoYMDRef.current?.setValue(String(dataA.res[0].DENYMD).substring(2,4)+'/'+String(dataA.res[0].DENYMD).substring(4,6)+'/'+String(dataA.res[0].DENYMD).slice(-2)); //売変実施日

      // フッタ部
      suuryoGoukeiRef.current?.setValue(Number(dataA.res[0].SRYFOT).toLocaleString(undefined, {minimumFractionDigits: 1}));        // 数量合計
      oldBaikaGoukeiRef.current?.setValue(Number(dataA.res[0].OLDBAKFOT).toLocaleString());  // 旧売価合計
      newBaikaGoukeiRef.current?.setValue(Number(dataA.res[0].NEWBAKFOT).toLocaleString());  // 新売価合計
      baihenGakuGoukeiRef.current?.setValue(dataA.res[0].BIHNFOK);     // 売変額合計

      //伝票明細(苦し紛れ)
      for (let i = 0; i<dataB.RCNT; i++){
        console.log('SHNCDE1',dataB.res[i].SHNCDE);
        if (i == 0){
          shohinCode1Ref.current?.setValue(dataB.res[i].SHNCDE);                  // 商品コード1
          gennin1Ref.current?.setValue(dataB.res[i].DWNGIIN);                // 原因1
          suuryo1Ref.current?.setValue(Number(dataB.res[i].SUU).toLocaleString(undefined, {minimumFractionDigits: 1}));// 数量1
          oldBaika1Ref.current?.setValue(Number(dataB.res[i].OLDBAK).toLocaleString());                  // 旧売価1
          newBaika1Ref.current?.setValue(Number(dataB.res[i].NEWBAK).toLocaleString());                  // 新売価1
          sagaku1Ref.current?.setValue(dataB.res[i].SAGAKU);                  // 差　額1
          baihenGaku1Ref.current?.setValue(dataB.res[i].BIHNGK);                  // 売変額1
          fugou1Ref.current?.setValue(dataB.res[i].FGO);                        // 符号1
          setItesj_kbn1(dataB.res[i].ESJ_KBN);                 // ＥＯＳ／ＪＡＮ区分
        }else if (i == 1){
          shohinCode2Ref.current?.setValue(dataB.res[i].SHNCDE);                  // 商品コード2
          gennin2Ref.current?.setValue(dataB.res[i].DWNGIIN);                // 原因2
          suuryo2Ref.current?.setValue(Number(dataB.res[i].SUU).toLocaleString(undefined, {minimumFractionDigits: 1}));// 数量2
          oldBaika2Ref.current?.setValue(Number(dataB.res[i].OLDBAK).toLocaleString());                  // 旧売価2
          newBaika2Ref.current?.setValue(Number(dataB.res[i].NEWBAK).toLocaleString());                  // 新売価2
          sagaku2Ref.current?.setValue(dataB.res[i].SAGAKU);                  // 差　額2
          baihenGaku2Ref.current?.setValue(dataB.res[i].BIHNGK);                  // 売変額2
          fugou2Ref.current?.setValue(dataB.res[i].FGO);                        // 符号2
          setItesj_kbn2(dataB.res[i].ESJ_KBN);                 // ＥＯＳ／ＪＡＮ区分
        }else if (i == 2){
          shohinCode3Ref.current?.setValue(dataB.res[i].SHNCDE);                  // 商品コード3
          gennin3Ref.current?.setValue(dataB.res[i].DWNGIIN);                // 原因3
          suuryo3Ref.current?.setValue(Number(dataB.res[i].SUU).toLocaleString(undefined, {minimumFractionDigits: 1}));// 数量3
          oldBaika3Ref.current?.setValue(Number(dataB.res[i].OLDBAK).toLocaleString());                  // 旧売価3
          newBaika3Ref.current?.setValue(Number(dataB.res[i].NEWBAK).toLocaleString());                  // 新売価3
          sagaku3Ref.current?.setValue(dataB.res[i].SAGAKU);                  // 差　額3
          baihenGaku3Ref.current?.setValue(dataB.res[i].BIHNGK);                  // 売変額3
          fugou3Ref.current?.setValue(dataB.res[i].FGO);                        // 符号3
          setItesj_kbn3(dataB.res[i].ESJ_KBN);                 // ＥＯＳ／ＪＡＮ区分
        }else if (i == 3){
          shohinCode4Ref.current?.setValue(dataB.res[i].SHNCDE);                  // 商品コード4
          gennin4Ref.current?.setValue(dataB.res[i].DWNGIIN);                // 原因4
          suuryo4Ref.current?.setValue(Number(dataB.res[i].SUU).toLocaleString(undefined, {minimumFractionDigits: 1}));// 数量4
          oldBaika4Ref.current?.setValue(Number(dataB.res[i].OLDBAK).toLocaleString());                  // 旧売価4
          newBaika4Ref.current?.setValue(Number(dataB.res[i].NEWBAK).toLocaleString());                  // 新売価4
          sagaku4Ref.current?.setValue(dataB.res[i].SAGAKU);                  // 差　額4
          baihenGaku4Ref.current?.setValue(dataB.res[i].BIHNGK);                  // 売変額4
          fugou4Ref.current?.setValue(dataB.res[i].FGO);                        // 符号4
          setItesj_kbn4(dataB.res[i].ESJ_KBN);                 // ＥＯＳ／ＪＡＮ区分
        }else if (i == 4){
          shohinCode5Ref.current?.setValue(dataB.res[i].SHNCDE);                  // 商品コード5
          gennin5Ref.current?.setValue(dataB.res[i].DWNGIIN);                // 原因5
          suuryo5Ref.current?.setValue(Number(dataB.res[i].SUU).toLocaleString(undefined, {minimumFractionDigits: 1}));// 数量5
          oldBaika5Ref.current?.setValue(Number(dataB.res[i].OLDBAK).toLocaleString());                  // 旧売価5
          newBaika5Ref.current?.setValue(Number(dataB.res[i].NEWBAK).toLocaleString());                  // 新売価5
          sagaku5Ref.current?.setValue(dataB.res[i].SAGAKU);                  // 差　額5
          baihenGaku5Ref.current?.setValue(dataB.res[i].BIHNGK);                  // 売変額5
          fugou5Ref.current?.setValue(dataB.res[i].FGO);                        // 符号5
          setItesj_kbn5(dataB.res[i].ESJ_KBN);                 // ＥＯＳ／ＪＡＮ区分
        }else if (i == 5){
          shohinCode6Ref.current?.setValue(dataB.res[i].SHNCDE);                  // 商品コード6
          gennin6Ref.current?.setValue(dataB.res[i].DWNGIIN);                // 原因6
          suuryo6Ref.current?.setValue(Number(dataB.res[i].SUU).toLocaleString(undefined, {minimumFractionDigits: 1}));// 数量6
          oldBaika6Ref.current?.setValue(Number(dataB.res[i].OLDBAK).toLocaleString());                  // 旧売価6
          newBaika6Ref.current?.setValue(Number(dataB.res[i].NEWBAK).toLocaleString());                  // 新売価6
          sagaku6Ref.current?.setValue(dataB.res[i].SAGAKU);                  // 差　額6
          baihenGaku6Ref.current?.setValue(dataB.res[i].BIHNGK);                  // 売変額6
          fugou6Ref.current?.setValue(dataB.res[i].FGO);                        // 符号6
          setItesj_kbn6(dataB.res[i].ESJ_KBN);                 // ＥＯＳ／ＪＡＮ区分
        }else if (i == 6){
          shohinCode7Ref.current?.setValue(dataB.res[i].SHNCDE);                  // 商品コード7
          gennin7Ref.current?.setValue(dataB.res[i].DWNGIIN);                // 原因7
          suuryo7Ref.current?.setValue(Number(dataB.res[i].SUU).toLocaleString(undefined, {minimumFractionDigits: 1}));// 数量7
          oldBaika7Ref.current?.setValue(Number(dataB.res[i].OLDBAK).toLocaleString());                  // 旧売価7
          newBaika7Ref.current?.setValue(Number(dataB.res[i].NEWBAK).toLocaleString());                  // 新売価7
          sagaku7Ref.current?.setValue(dataB.res[i].SAGAKU);                  // 差　額7
          baihenGaku7Ref.current?.setValue(dataB.res[i].BIHNGK);                  // 売変額7
          fugou7Ref.current?.setValue(dataB.res[i].FGO);                        // 符号7
          setItesj_kbn7(dataB.res[i].ESJ_KBN);                 // ＥＯＳ／ＪＡＮ区分
        }else if (i == 7){
          shohinCode8Ref.current?.setValue(dataB.res[i].SHNCDE);                  // 商品コード8
          gennin8Ref.current?.setValue(dataB.res[i].DWNGIIN);                // 原因8
          suuryo8Ref.current?.setValue(Number(dataB.res[i].SUU).toLocaleString(undefined, {minimumFractionDigits: 1}));// 数量8
          oldBaika8Ref.current?.setValue(Number(dataB.res[i].OLDBAK).toLocaleString());                  // 旧売価8
          newBaika8Ref.current?.setValue(Number(dataB.res[i].NEWBAK).toLocaleString());                  // 新売価8
          sagaku8Ref.current?.setValue(dataB.res[i].SAGAKU);                  // 差　額8
          baihenGaku8Ref.current?.setValue(dataB.res[i].BIHNGK);                  // 売変額8
          fugou8Ref.current?.setValue(dataB.res[i].FGO);                        // 符号8
          setItesj_kbn8(dataB.res[i].ESJ_KBN);                 // ＥＯＳ／ＪＡＮ区分
        }
      };

      if(!isInInputConfirm) {
        //明細行に移動する為にmvFlg立てる
        mvFlg = 2;
        if (kubunRef.current?.getValue() == '2'){
          inSHNCDE1.current.focus();
        }else{
          inRET.current.focus();
        }
      }
    }

    denpyoNoRef.current?.setCheckflg(0);
    return mvFlg;
  }

  // ヘッダ部入力 店CD
  const onKeyDown_MISE = async()=>{
    let mvFlg = 0;
    miseCodeRef.current?.setBGColor('#FFFFFF');

    // 画面.発送店が3桁未満の場合、3桁[頭0埋め]に変換して画面.発送店に設定および後続の作業を行う
    var padMiseCd = inMISE.current.value.padStart(3, '0');
    miseCodeRef.current?.setValue(padMiseCd);

    if (padMiseCd != state.MISES){
      setAlert('店コード入力エラー');//TCGE0010：
      miseCodeRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
    }else{
      setAlert('　');
      mvFlg = 0;
    }
    return mvFlg;
  }

  // ヘッダ部入力 分類CD
  const onKeyDown_BMNCDE = async()=>{
    let mvFlg = 0;
    let lBMNCDE = Fhwith(bunruiCodeRef.current?.getValue(),0,4);
    bunruiCodeRef.current?.setValue(lBMNCDE);
    bunruiCodeRef.current?.setBGColor('#FFFFFF');
    console.log('BMNCDEの処理実行');
    console.log('MR_PRD_CLS_MST_BM_sel:'+lBMNCDE+'|'+itBMNCDE01);

    bunruiCodeRef.current?.setCheckflg(0);   // 変更フラグをリセット。

    /*** 入力1回目 ***/
    if (itBMNCDE01 == '' || itBMNCDE01 != lBMNCDE){
      // 分類CD[部門コード]チェック
      const responseA = await fetch('/TCSA0150/MR_PRD_CLS_MST_BM_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ksacde: state.KSACDES,bmncde:lBMNCDE,RCNT:0,GNKKNRFLG:''})});
      const dataA = await responseA.json();
      console.log('分類CD[部門コード]:%o',dataA);
      console.log('件数:%o',dataA.RCNT);

      // 分類CD登録なし
      if (dataA.RCNT == 0){
        console.log('該当データなし');
        setAlert('分類コード入力エラー');//TCGE0011：
        bunruiCodeRef.current?.setBGColor('#f59d9d');
        setItBMNCDE01('');
        mvFlg = 1;
        return mvFlg;
      }

      // 一件以上
      console.log('データ：',dataA.GNKKNRFLG)
      if (dataA.GNKKNRFLG == '1'){
        console.log('原価管理フラグが[1]エラー');
        setAlert('分類コード入力エラー');//TCGE0011：
        bunruiCodeRef.current?.setBGColor('#f59d9d');
        setItBMNCDE01('');
        mvFlg = 1;
      }else{
        setAlert('　');
        setItBMNCDE01(lBMNCDE);
        mvFlg = 0;
      }

      mvFlg = 1;
      bunruiCodeRef.current?.setValue('');
      return mvFlg;
    }

    if(isInInputConfirm){
      // 空白時の処理
      if (bunruiCodeRef.current?.getValue() == ''){
        mvFlg = 1;
        return mvFlg;
      }
    }

    /*** 入力2回目 ***/
    // ベリファイエラー
    // if (itBMNCDE01 != lBMNCDE){
    //   console.log('2回入力不一致');
    //   setAlert('分類コード入力エラー');//TCGE0011：
    //   bunruiCodeRef.current?.setBGColor('#f59d9d');
    //   setItBMNCDE01(bunruiCodeRef.current?.getValue());
    //   // bunruiCodeRef.current?.setValue('');//2回目不一致の場合、クリアする
    //   mvFlg = 1;
    //   return mvFlg;
    // }

    // if (bunruiCodeRef.current?.getValue() != ''){
    //   // 分類CD[部門コード]チェック
    //   const responseA = await fetch('/TCSA0150/MR_PRD_CLS_MST_BM_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    //   body: JSON.stringify({ksacde: state.KSACDES,bmncde:lBMNCDE,RCNT:0,GNKKNRFLG:''})});
    //   const dataA = await responseA.json();
    //   console.log('分類CD[部門コード]:%o',dataA);
    //   console.log('件数:%o',dataA.RCNT);

    //   // 分類CD登録なし
    //   if (dataA.RCNT == 0){
    //     console.log('該当データなし');
    //     setAlert('分類コード入力エラー');//TCGE0011：
    //     bunruiCodeRef.current?.setBGColor('#f59d9d');
    //     setItBMNCDE01(bunruiCodeRef.current?.getValue());
    //     mvFlg = 1;
    //     return mvFlg;
    //   }

    //   // 一件以上
    //   console.log('データ：',dataA.GNKKNRFLG)
    //   if (dataA.GNKKNRFLG == '1'){
    //     console.log('原価管理フラグが[1]エラー');
    //     setAlert('分類コード入力エラー');//TCGE0011：
    //     bunruiCodeRef.current?.setBGColor('#f59d9d');
    //     setItBMNCDE01(bunruiCodeRef.current?.getValue());
    //     mvFlg = 1;
    //   }else{
    //     setAlert('　');
    //     mvFlg = 0;
    //   }
    // }
    setAlert('　');
    return mvFlg;
  }

  // ヘッダ部入力 年月日
  function onKeyDown_DENYMD(){
    let mvFlg = 0;
    var strDate = '20'+denpyoYMDRef.current?.getValue();
    var dtdate = new Date(strDate);
    denpyoYMDRef.current?.setBGColor('#FFFFFF');
    //日付チェック
    if (ckDate(strDate)===true){
      console.log('発送日チェック1'+'|'+strDate);
      console.log('日付範囲');
      let dt = new Date(state.SDAY);
      let dts = new Date(dt.getFullYear()-1,dt.getMonth(),dt.getDate());
      let dte = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate());
      console.log('日付'+dts+'<='+dtdate+'<'+dte);
      if (dts <= dtdate && dtdate <= dte){
        setAlert('　');
        mvFlg = 0;
        //計上日算出
        // const kret = KeijoYM(strDate);
        const kret = KeijoYM('20'+denpyoYMDRef.current?.getValue());
        const kret2 = (String(kret).replace('年','')).replace('月','');
        keijyoYMRef.current?.setValue(kret);
        setItkijyym2(kret2);
      }else{
        denpyoYMDRef.current?.setBGColor('#f59d9d');
        setAlert('日付入力エラー');//TCGE0016:
        denpyoYMDRef.current?.setValue('');
        mvFlg = 1;
      }
    }else{
      denpyoYMDRef.current?.setBGColor('#f59d9d');
      setAlert('日付入力エラー');//TCGE0016:
      mvFlg = 1;
    }
    return mvFlg;
  }
  //#endregion

  //#region 明細部入力
  /**
   * 行クリア処理
   * @param rowNo 対象行
   */
  const clearRow = (rowNo) => {
    setItitemval('', rowNo, 'shncde');
    setItitemval('', rowNo, 'DWNGIIN');
    setItitemval('', rowNo, 'SUU');
    setItitemval('', rowNo, 'OLDBAK');
    setItitemval('', rowNo, 'NEWBAK');
    setItitemval('', rowNo, 'SAGAKU');
    setItitemval('', rowNo, 'BIHNGK');
    setItitemval('', rowNo, 'FGO');
    setItitemclr('#FFFFFF', rowNo, 'shncde');
    setItitemclr('#FFFFFF', rowNo, 'suu');
    setItitemclr('#FFFFFF', rowNo, 'DWNGIIN');
    setItitemclr('#FFFFFF', rowNo, 'bak');
    setChkSHNCDE(rowNo,0);
    setChkSHNCDE(rowNo,0);
  }

  /**
   * 空白行チェック
   * @param rowNo 対象行
   * @param shncdeflg true:商品コード
   * @returns
   */
  function confirmed_rows(rowNo,shncdeflg = false)
  {
    let tagrow = 0;
    let errorflg = 0;
    switch (rowNo) {
      case 1:   /** 1列目 **/
        if (shohinCode1Ref.current?.getValue() == '')  //一行目は必須
        {
          errorflg = 2;
          tagrow = rowNo;
          shohinCode1Ref.current?.setCheckflg(2);
          setAlert('商品コードを入力して下さい。');//TCGE0026：
          setItitemclr('#f59d9d',tagrow,'shncde');
          shohinCode1Ref.current?.setCheckflg(2);
          if(isInInputConfirm){
            inSHNCDE1.current.focus();
          }
          return;
        }else{
          // 1行目商品コード削除時、又は1行目全て空白時の処理
          if ((shncdeflg && shohinCode1Ref.current?.getValue() == '')
          || (shohinCode1Ref.current?.getValue() == '' && gennin1Ref.current?.getValue() == '' && suuryo1Ref.current?.getValue() == '' && oldBaika1Ref.current?.getValue() == '' && newBaika1Ref.current?.getValue() == ''))
          {
            // 2行目が入力済みだと1行目エラー
            if (shohinCode2Ref.current?.getValue() != '' || gennin2Ref.current?.getValue() != '' || suuryo2Ref.current?.getValue() != '' || oldBaika2Ref.current?.getValue() != '' || newBaika2Ref.current?.getValue() != ''){
              errorflg = 2;
              tagrow = rowNo;
              shohinCode1Ref.current?.setCheckflg(2);
              if(isInInputConfirm){
                inSHNCDE1.current.focus();
              }
            }
          }
        }
        break;
      case 2:   /** 2列目 **/
        // 2行目商品コード削除時、又は2行目全て空白時の処理
        if ((shncdeflg && shohinCode2Ref.current?.getValue() == '')
        || (shohinCode2Ref.current?.getValue() == '' && gennin2Ref.current?.getValue() == '' && suuryo2Ref.current?.getValue() == '' && oldBaika2Ref.current?.getValue() == '' && newBaika2Ref.current?.getValue() == ''))
        {
          // 3行目が入力済みだと2行目エラー
          if (shohinCode3Ref.current?.getValue() != '' || gennin3Ref.current?.getValue() != '' || suuryo3Ref.current?.getValue() != '' || oldBaika3Ref.current?.getValue() != '' || newBaika3Ref.current?.getValue() != ''){
            errorflg = 2;
            tagrow = rowNo;
            shohinCode2Ref.current?.setCheckflg(2);
            if(isInInputConfirm){
              inSHNCDE2.current.focus();
            }
          // 1行目が未入力だと、1行目のエラーをクリア
          } else if(shohinCode1Ref.current?.getValue() == '' && gennin1Ref.current?.getValue() == '' && suuryo1Ref.current?.getValue() == '' && oldBaika1Ref.current?.getValue() == '' && newBaika1Ref.current?.getValue() == ''){
            clearRow(1);
          }
        }
        // 2行目入力あり、1行目全て空白時エラー
        else if(shohinCode1Ref.current?.getValue() == '' && gennin1Ref.current?.getValue() == '' && suuryo1Ref.current?.getValue() == '' && oldBaika1Ref.current?.getValue() == '' && newBaika1Ref.current?.getValue() == ''){
          errorflg = 1;
          tagrow = rowNo - 1;
          shohinCode1Ref.current?.setCheckflg(2);
          if(isInInputConfirm){
            inSHNCDE1.current.focus();
          }
        }
        break;
      case 3:   /** 3列目 **/
        // 3行目商品コード削除時、又は3行目全て空白時の処理
        if ((shncdeflg && shohinCode3Ref.current?.getValue() == '')
                ||(shohinCode3Ref.current?.getValue() == '' && gennin3Ref.current?.getValue() == '' && suuryo3Ref.current?.getValue() == '' && oldBaika3Ref.current?.getValue() == '' && newBaika3Ref.current?.getValue() == ''))
        {
          // 4行目が入力済みだと3行目エラー
          if (shohinCode4Ref.current?.getValue() != '' || gennin4Ref.current?.getValue() != '' || suuryo4Ref.current?.getValue() != '' || oldBaika4Ref.current?.getValue() != '' || newBaika4Ref.current?.getValue() != ''){
            errorflg = 2;
            tagrow = rowNo;
            shohinCode3Ref.current?.setCheckflg(2);
            if(isInInputConfirm){
              inSHNCDE3.current.focus();
            }
          // 2行目が未入力だと、2行目のエラーをクリア
          } else if(shohinCode2Ref.current?.getValue() == '' && gennin2Ref.current?.getValue() == '' && suuryo2Ref.current?.getValue() == '' && oldBaika2Ref.current?.getValue() == '' && newBaika2Ref.current?.getValue() == ''){
            clearRow(2);
          }
        }
        // 3行目入力あり、2行目全て空白時エラー
        else if(shohinCode2Ref.current?.getValue() == '' && gennin2Ref.current?.getValue() == '' && suuryo2Ref.current?.getValue() == '' && oldBaika2Ref.current?.getValue() == '' && newBaika2Ref.current?.getValue() == ''){
          errorflg = 1;
          tagrow = rowNo - 1;
          shohinCode2Ref.current?.setCheckflg(2);
          if(isInInputConfirm){
            inSHNCDE2.current.focus();
          }
        }
        break;
      case 4:   /** 4列目 **/
        // 4行目商品コード削除時、又は4行目全て空白時の処理
        if ((shncdeflg && shohinCode4Ref.current?.getValue() == '')
                ||(shohinCode4Ref.current?.getValue() == '' && gennin4Ref.current?.getValue() == '' && suuryo4Ref.current?.getValue() == '' && oldBaika4Ref.current?.getValue() == '' && newBaika4Ref.current?.getValue() == ''))
        {
          // 5行目が入力済みだと4行目エラー
          if (shohinCode5Ref.current?.getValue() != '' || gennin5Ref.current?.getValue() != '' || suuryo5Ref.current?.getValue() != '' || oldBaika5Ref.current?.getValue() != '' || newBaika5Ref.current?.getValue() != ''){
            errorflg = 2;
            tagrow = rowNo;
            shohinCode4Ref.current?.setCheckflg(2);
            if(isInInputConfirm){
              inSHNCDE4.current.focus();
            }
          // 3行目が未入力だと、3行目のエラーをクリア
          } else if(shohinCode3Ref.current?.getValue() == '' && gennin3Ref.current?.getValue() == '' && suuryo3Ref.current?.getValue() == '' && oldBaika3Ref.current?.getValue() == '' && newBaika3Ref.current?.getValue() == ''){
            clearRow(3);
          }
        }
        // 4行目入力あり、3行目全て空白時エラー
        else if(shohinCode3Ref.current?.getValue() == '' && gennin3Ref.current?.getValue() == '' && suuryo3Ref.current?.getValue() == '' && oldBaika3Ref.current?.getValue() == '' && newBaika3Ref.current?.getValue() == ''){
          errorflg = 1;
          tagrow = rowNo - 1;
          shohinCode3Ref.current?.setCheckflg(2);
          if(isInInputConfirm){
            inSHNCDE3.current.focus();
          }
        }
        break;
      case 5:   /** 5列目 **/
        // 5行目商品コード削除時、又は5行目全て空白時の処理
        if ((shncdeflg && shohinCode5Ref.current?.getValue() == '')
                ||(shohinCode5Ref.current?.getValue() == '' && gennin5Ref.current?.getValue() == '' && suuryo5Ref.current?.getValue() == '' && oldBaika5Ref.current?.getValue() == '' && newBaika5Ref.current?.getValue() == ''))
        {
          // 6行目が入力済みだと5行目エラー
          if (shohinCode6Ref.current?.getValue() != '' || gennin6Ref.current?.getValue() != '' || suuryo6Ref.current?.getValue() != '' || oldBaika6Ref.current?.getValue() != '' || newBaika6Ref.current?.getValue() != ''){
            errorflg = 2;
            tagrow = rowNo;
            shohinCode5Ref.current?.setCheckflg(2);
            if(isInInputConfirm){
              inSHNCDE5.current.focus();
            }
          // 4行目が未入力だと、4行目のエラーをクリア
          } else if(shohinCode4Ref.current?.getValue() == '' && gennin4Ref.current?.getValue() == '' && suuryo4Ref.current?.getValue() == '' && oldBaika4Ref.current?.getValue() == '' && newBaika4Ref.current?.getValue() == ''){
            clearRow(4);
          }
        }
        // 5行目入力あり、4行目全て空白時エラー
        else if(shohinCode4Ref.current?.getValue() == '' && gennin4Ref.current?.getValue() == '' && suuryo4Ref.current?.getValue() == '' && oldBaika4Ref.current?.getValue() == '' && newBaika4Ref.current?.getValue() == ''){
          errorflg = 1;
          tagrow = rowNo - 1;
          shohinCode4Ref.current?.setCheckflg(2);
          if(isInInputConfirm){
            inSHNCDE4.current.focus();
          }
        }
        break;
      case 6:   /** 6列目 **/
        // 6行目商品コード削除時、又は6行目全て空白時の処理
        if ((shncdeflg && shohinCode6Ref.current?.getValue() == '')
                ||(shohinCode6Ref.current?.getValue() == '' && gennin6Ref.current?.getValue() == '' && suuryo6Ref.current?.getValue() == '' && oldBaika6Ref.current?.getValue() == '' && newBaika6Ref.current?.getValue() == ''))
        {
          // 7行目が入力済みだと6行目エラー
          if (shohinCode7Ref.current?.getValue() != '' || gennin7Ref.current?.getValue() != '' || suuryo7Ref.current?.getValue() != '' || oldBaika7Ref.current?.getValue() != '' || newBaika7Ref.current?.getValue() != ''){
            errorflg = 2;
            tagrow = rowNo;
            shohinCode6Ref.current?.setCheckflg(2);
            if(isInInputConfirm){
              inSHNCDE6.current.focus();
            }
          // 5行目が未入力だと、5行目のエラーをクリア
          } else if(shohinCode5Ref.current?.getValue() == '' && gennin5Ref.current?.getValue() == '' && suuryo5Ref.current?.getValue() == '' && oldBaika5Ref.current?.getValue() == '' && newBaika5Ref.current?.getValue() == ''){
            clearRow(5);
          }
        }
        // 6行目入力あり、5行目全て空白時エラー
        else if(shohinCode5Ref.current?.getValue() == '' && gennin5Ref.current?.getValue() == '' && suuryo5Ref.current?.getValue() == '' && oldBaika5Ref.current?.getValue() == '' && newBaika5Ref.current?.getValue() == ''){
          errorflg = 1;
          tagrow = rowNo - 1;
          shohinCode5Ref.current?.setCheckflg(2);
          if(isInInputConfirm){
            inSHNCDE5.current.focus();
          }
        }
        break;
      case 7:   /** 7列目 **/
        // 7行目商品コード削除時、又は7行目全て空白時の処理
        if ((shncdeflg && shohinCode7Ref.current?.getValue() == '')
                ||(shohinCode7Ref.current?.getValue() == '' && gennin7Ref.current?.getValue() == '' && suuryo7Ref.current?.getValue() == '' && oldBaika7Ref.current?.getValue() == '' && newBaika7Ref.current?.getValue() == ''))
        {
          // 8行目が入力済みだと7行目エラー
          if (shohinCode8Ref.current?.getValue() != '' || gennin8Ref.current?.getValue() != '' || suuryo8Ref.current?.getValue() != '' || oldBaika8Ref.current?.getValue() != '' || newBaika8Ref.current?.getValue() != ''){
            errorflg = 2;
            tagrow = rowNo;
            shohinCode7Ref.current?.setCheckflg(2);
            if(isInInputConfirm){
              inSHNCDE7.current.focus();
            }
          // 6行目が未入力だと、6行目のエラーをクリア
          } else if(shohinCode6Ref.current?.getValue() == '' && gennin6Ref.current?.getValue() == '' && suuryo6Ref.current?.getValue() == '' && oldBaika6Ref.current?.getValue() == '' && newBaika6Ref.current?.getValue() == ''){
            clearRow(6);
          }
        }
        // 7行目入力あり、6行目全て空白時エラー
        else if(shohinCode6Ref.current?.getValue() == '' && gennin6Ref.current?.getValue() == '' && suuryo6Ref.current?.getValue() == '' && oldBaika6Ref.current?.getValue() == '' && newBaika6Ref.current?.getValue() == ''){
          errorflg = 1;
          tagrow = rowNo - 1;
          shohinCode6Ref.current?.setCheckflg(2);
          if(isInInputConfirm){
            inSHNCDE6.current.focus();
          }
        }
        break;
      case 8:   /** 8列目 **/
        // 8行目商品コード削除時、又は8行目全て空白時の処理
        if ((shncdeflg && shohinCode8Ref.current?.getValue() == '')
          ||(shohinCode8Ref.current?.getValue() == '' && gennin8Ref.current?.getValue() == '' && suuryo8Ref.current?.getValue() == '' && oldBaika8Ref.current?.getValue() == '' && newBaika8Ref.current?.getValue() == ''))
        {
          // 7行目が未入力だと、7行目のエラーをクリア
          if(shohinCode7Ref.current?.getValue() == '' && gennin7Ref.current?.getValue() == '' && suuryo7Ref.current?.getValue() == '' && oldBaika7Ref.current?.getValue() == '' && newBaika7Ref.current?.getValue() == ''){
            clearRow(7);
          }
        }
        // 8行目入力あり、7行目全て空白時エラー
        else if(shohinCode7Ref.current?.getValue() == '' && gennin7Ref.current?.getValue() == '' && suuryo7Ref.current?.getValue() == '' && oldBaika7Ref.current?.getValue() == '' && newBaika7Ref.current?.getValue() == ''){
          errorflg = 1;
          tagrow = rowNo - 1;
          shohinCode7Ref.current?.setCheckflg(2);
          if(isInInputConfirm){
            inSHNCDE7.current.focus();
          }
        }
        break;
      default:
        break;
    }

    if (errorflg != 0){

      setAlert('明細行を詰めて入力してください。');
      setItitemclr('#f59d9d',tagrow,'shncde');
      setItitemclr('#f59d9d',tagrow,'suu');
      setItitemclr('#f59d9d',tagrow,'DWNGIIN');
      setItitemclr('#f59d9d',tagrow,'bak');
    }
    return errorflg;
  }

  // 明細部入力 商品コード 遷移の処理
  function inInputConfirm_SHNCDE(inputNxRef,rowNo,instart = 0){
    let l_SHNCDE = getItitemval(rowNo,'shncde');

    if (l_SHNCDE == '') {
      // 明細行で商品コードが空白で入力さエンター押下した場合、
      // 数量、原単価、原価金額、売単価、売価金額を空白にする（バックカラーもクリアにする）
      clearRow(rowNo);
    }

    // 空白行チェック
    let errflg = confirmed_rows(rowNo, true);

    if (l_SHNCDE == '') {
      if (errflg != 0) {return 1};

      if(instart === 0){
        inSUUG.current.focus();
        return 1;
      }
    }

    if(instart === 0){
      inputNxRef.current.focus();
    }
    return 0;
  }

  // 明細部入力 商品コード
  const onKeyDown_SHNCDE = async(rowNo)=>{
    let mvFlg = 0;

    let l_SHNCDE = getItitemval(rowNo,'shncde');

    let itSHNCDE = Fhwith(l_SHNCDE);
    setItitemval(itSHNCDE,rowNo,'shncde');
    setItitemclr('#FFFFFF',rowNo,'shncde');

    let itGNKKNRFLG = '0'; // ※不使用
    let itESJ_KBN = '';     // 2023/10/10

    // 事業部コードチェック
    let strDENYMD = '20'+denpyoYMDRef.current?.getValue().slice(0,2)+denpyoYMDRef.current?.getValue().slice(3,5)+denpyoYMDRef.current?.getValue().slice(6,8)
    const responseA = await fetch('/TCSA0150/MR_PRD_CLS_MST_JGB_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ksacde: state.KSACDE,bmncde:bunruiCodeRef.current?.getValue(),denymd:'20'+strDENYMD})});
    const dataA = await responseA.json();
    console.log('jgbcde:'+dataA.jgbcde);
    console.log('gnkknrflg:'+dataA.gnkknrflg);
    console.log('itSHNCDE:'+itSHNCDE);
    console.log('length:'+itSHNCDE.length);
    console.log('件数:'+dataA.RCNT);
    // 一件以上
    if (dataA.RCNT >= 1) {
      itGNKKNRFLG = dataA.gnkknrflg;

      // 商品コード 3桁の場合
      if (itSHNCDE.length == 3) {
        console.log('3桁です');
        console.log('商品コード1:%o',itSHNCDE)
        // 商品分類マスタ存在チェック
        const responseB = await fetch('/TCSA0150/MR_PRD_CLS_MST_3keta_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ksacde: state.KSACDE,bmncde:bunruiCodeRef.current?.getValue(),shncde:itSHNCDE})});
        const dataB = await responseB.json();
        console.log('dataB.RCNT:'+dataB.RCNT);
        // 一件以上
        if (dataB.RCNT >= 1) {
          console.log("-- 商品コード 3桁が商品分類マスタに有る --");
          itESJ_KBN = ' ';
          console.log('ＥＯＳ／ＪＡＮ区分:%o',itESJ_KBN);
        }else{
          console.log("-- 商品コード 3桁が商品分類マスタに無い --");
          setAlert('商品コード入力エラー');//TCGE0026：
          mvFlg = 1;
        }

      //商品コード 8桁の場合
      }else if (itSHNCDE.length == 8) {
        console.log('8桁です');
        // 商品マスタ（基本） 存在チェック EOSコード
        const responseB = await fetch('/TCSA0150/MR_PRD_RMST_EOS_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ksacde: state.KSACDE,bmncde:bunruiCodeRef.current?.getValue(),shncde:itSHNCDE})});
        const dataB = await responseB.json();
        console.log('dataB.RCNT:'+dataB.RCNT);
        if(dataB.record_exists == 1) {
          // データ存在時の処理
          console.log('商品コード[EOS] 8桁　存在チェックOK');
          itESJ_KBN = 'E';
          console.log('ＥＯＳ／ＪＡＮ区分:%o',itESJ_KBN);
        }else{
          // 商品マスタ（基本） 存在チェック JANコード
          const responseC = await fetch('/TCSA0150/MR_PRD_RMST_JAN_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ksacde: state.KSACDE,bmncde:bunruiCodeRef.current?.getValue(),shncde:itSHNCDE})});
          const dataC = await responseC.json();
          console.log('dataC.RCNT:'+dataC.RCNT);
          // データ存在時の処理
          if(dataC.record_exists == 1) {
            console.log('商品コード[JAN] 8桁　存在チェックOK');
            itESJ_KBN = 'J';
            console.log('ＥＯＳ／ＪＡＮ区分:%o',itESJ_KBN);
          }else{
            setAlert('商品コード入力エラー');//TCGE0026：
            mvFlg = 1;
          }
        }

      // 商品コード 13桁の場合（Fhwith処理で12桁も13桁に変更済）
      }else if (itSHNCDE.length == 13) {
        console.log('13桁です');
        if (itSHNCDE.substring(0, 2) == '02' && itSHNCDE.slice(-6)=='000000'){
          itESJ_KBN = 'J';
          itGNKKNRFLG = '1';
          console.log('ＥＯＳ／ＪＡＮ区分:%o',itESJ_KBN);
          console.log('原価管理フラグ:%o',itGNKKNRFLG);
        }else{
          // 商品マスタ（基本） 存在チェック JANコード
          const responseB = await fetch('/TCSA0150/MR_PRD_RMST_JAN_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ksacde: state.KSACDE,bmncde:bunruiCodeRef.current?.getValue(),shncde:itSHNCDE})});
          const dataB = await responseB.json();
          console.log('dataB.RCNT:'+dataB.RCNT);
          // データ存在時の処理
          if(dataB.record_exists == 1) {
            console.log('商品コード 13桁　存在チェックOK');
            itESJ_KBN = 'J';
            console.log('ＥＯＳ／ＪＡＮ区分:%o',itESJ_KBN);
          }else{
            console.log("-- 商品コード 13桁　存在チェックエラー --");
            setAlert('商品コード入力エラー');//TCGE0026：
            mvFlg = 1;
          }
        }
      }else{
        console.log("-- 商品コード 3, 8,12,13 桁以外はエラー --");
        setAlert('商品コード入力エラー');//TCGE0026：
        mvFlg = 1;
      }
    }

    // エラー時の処理
    if (mvFlg !== 0){
      mvFlg = 2;
      setAlert('商品コード入力エラー');//TCGE0026：
      setChkSHNCDE(rowNo,2);
      setChkflg(1);
      setItitemclr('#f59d9d',rowNo,'shncde');
    }

    // 正常時の処理
    if (mvFlg === 0){
      //mvFlg = 2;
      setChkSHNCDE(rowNo,0);
      setChkflg(1);
      setItitemval(itESJ_KBN,rowNo,'esj_kbn');
    }
    return mvFlg;
  }

  // 明細部入力 原因
  function onKeyDown_DWNGIIN(rowNo) {
    let l_SHNCDE = getItitemval(rowNo,'shncde');
    let itDWNGIIN = getItitemval(rowNo,'DWNGIIN');
    let mvFlg = 0;
    setItitemclr('#FFFFFF',rowNo,'DWNGIIN');

    // 空白行チェック
    confirmed_rows(rowNo, true);

    if (l_SHNCDE === '')
    {
      setAlert('商品コードを入力して下さい。');//TCGE0026：
      setItitemclr('#f59d9d',rowNo,'shncde');
      setChkSHNCDE(rowNo,2);
    }

    if (itDWNGIIN>=0 && itDWNGIIN<=9 && itDWNGIIN!==''){
      // if (state.MISES == '200'){
      //   setAlert('　');
      // }else{
        //本社入力チェック:店コードS≠２００の場合、原因区分入力値「５」、「９」はエラー
        if (itDWNGIIN==='5'||itDWNGIIN==='9')
        {
          console.log('本社判定値:'+state.MISE);
          setAlert('店舗で入力不可');//本社でしか入力できない原因区分になります。
          setItitemclr('#f59d9d',rowNo,'DWNGIIN');
          mvFlg = 1;
        }else{
          setAlert('　');
        }
      // }
    }else{
      setAlert('原因区分入力エラー（０～９）');//TCGE0040：
      mvFlg = 1;
    }

    if (mvFlg !== 0){
      setItitemclr('#f59d9d',rowNo,'DWNGIIN');
    }

    return mvFlg;
  }

  // 明細部入力 数量
  function onKeyDown_SUU(rowNo) {
    let l_SHNCDE = getItitemval(rowNo,'shncde');
    let l_SUU = getItitemval(rowNo,'SUU');
    let mvFlg = 0;
    setItitemclr('#FFFFFF',rowNo,'suu');

    // 空白行チェック
    confirmed_rows(rowNo, true);

    if (l_SHNCDE === '')
    {
      setAlert('商品コードを入力して下さい。');//TCGE0026：
      setItitemclr('#f59d9d',rowNo,'shncde');
      setChkSHNCDE(rowNo,2);
    }

    if (l_SUU==0){
      setAlert('数量を入力して下さい。');//TCGE0019：
      setItitemclr('#f59d9d',rowNo,'suu');

      mvFlg = 1;
    }else{
      setAlert('　');
      mvFlg = 0;
    }

    return mvFlg;
  }

  // 明細部入力 旧売価
  function onKeyDown_OLDBAK(rowNo) {
    let l_SHNCDE = getItitemval(rowNo,'shncde');
    let l_DWNGIIN = getItitemval(rowNo,'DWNGIIN');
    let l_OLDBAK = getItitemval(rowNo,'OLDBAK');
    // 商品コードと原因の値があるときは、旧売価が空白の時、画面に「０」と表示する
    if(l_SHNCDE != '' && l_DWNGIIN != ''  && l_OLDBAK === ''){
      setItitemval('0',rowNo,'OLDBAK');
    }
  }

  // 明細部入力 新売価
  function onKeyDown_NEWBAK(rowNo) {
    let mvFlg = 0;
    let l_SHNCDE = getItitemval(rowNo,'shncde');
    let l_SUU = getItitemval(rowNo,'SUU');
    let l_DWNGIIN = getItitemval(rowNo,'DWNGIIN');
    let l_OLDBAK = getItitemval(rowNo,'OLDBAK');
    let l_NEWBAK = getItitemval(rowNo,'NEWBAK');

    let itSHNCDE = l_SHNCDE.toString();
    let itDWNGIIN = l_DWNGIIN.toString();
    let itSUU = parseFloat(l_SUU.replace(/,/g, ''))||0;
    let itOLDBAK = parseFloat(l_OLDBAK.replace(/,/g, ''))||0;
    let itNEWBAK = parseFloat(l_NEWBAK.replace(/,/g, ''))||0;

    // 商品コードと原因が存在するときは、新売価が空白の時、画面に「０」と表示する
    if(l_SHNCDE != '' && l_DWNGIIN != ''  && l_NEWBAK === ''){
      setItitemval('0',rowNo,'NEWBAK');
    }

    // 空白行チェック
    confirmed_rows(rowNo, true);

    setItitemclr('#FFFFFF',rowNo,'bak');

    console.log('売価チェック入力値（原因）:'+itDWNGIIN);
    console.log('売価チェック入力値（数）　:'+itSUU);
    console.log('売価チェック入力値（旧）　:'+itOLDBAK);
    console.log('売価チェック入力値（新）　:'+itNEWBAK);
    setAlert('　');

    console.log('明細後処理 未入力チェック');             // added by reed

    let errmsg = '';
    let BAK_err = hasErr_at_BAK(itDWNGIIN,itOLDBAK,itNEWBAK);
    mvFlg = has_row_error(itSHNCDE,itDWNGIIN,itSUU,BAK_err,rowNo);
    const errmsg_plus = () =>{
      if(itSHNCDE == ''){
        errmsg += '商品コードを入力してください。\n';
        setChkSHNCDE(rowNo,2);
        //return;
      }
      if(itDWNGIIN == ''){
        errmsg += '原因を入力してください。\n';
        //return;
      }
      if(itSUU == ''){
        errmsg += '数量を入力してください。\n';
        //return;
      }
      if(BAK_err !== 0){
        errmsg += '売価入力エラー\n';
        //return;
      }
    }
    errmsg_plus();
    setAlert(errmsg);
    if (mvFlg === 0){
      //エラー無しの場合
      setItitemval((itNEWBAK-itOLDBAK),rowNo,'SAGAKU');
      setItitemval(((itNEWBAK-itOLDBAK)*itSUU),rowNo,'BIHNGK');
      if ((itNEWBAK-itOLDBAK)<0)
      {
        setItitemval('-',rowNo,'FGO');
      }else{
        setItitemval('+',rowNo,'FGO');
      };
      //E 183-001 2023/09/20 差額及び売変額はマイナス編集する
    }

    return mvFlg;
  }

  /**
   * 明細からフォーカスが外れた際に確認処理を呼び出す。
   */
  useEffect(() => {
    // 下記の処理でblurTableflgを変更した際に
    // 重複して処理が走らないようにする為にtrueチェック
    // if(blurTableflg){

    let type = getInputType(document.activeElement.id);
    // if(type != 2){
    //   pageup時はヘッダ情報に遷移し、チェックは行わない
    //   if (blurkey!='PageUp')
    //   {
    //     onBlurTable();
    //   }
    // }
    // ※押下ボタンの判定だと、クリア時にエラーとなる
    if(type != 1 && type != 2){
      onBlurTable();
    }
    // }
  }, [blurTableflg]);

  // 明細からフォーカスが外れた際の処理
  const onBlurTable = async()=>{
    console.log('明細項目チェック開始');

    let clear_row = 9;
    if(shohinCode8Ref.current?.getValue() == '' && gennin8Ref.current?.getValue() == '' && suuryo8Ref.current?.getValue() == '' && oldBaika8Ref.current?.getValue() == '' && newBaika8Ref.current?.getValue() == ''){
      clearRow(8);
      clear_row = 8;
    }
    if(shohinCode7Ref.current?.getValue() == '' && gennin7Ref.current?.getValue() == '' && suuryo7Ref.current?.getValue() == '' && oldBaika7Ref.current?.getValue() == '' && newBaika7Ref.current?.getValue() == '' && clear_row == 8){
      clearRow(7);
      clear_row = 7;
    }
    if(shohinCode6Ref.current?.getValue() == '' && gennin6Ref.current?.getValue() == '' && suuryo6Ref.current?.getValue() == '' && oldBaika6Ref.current?.getValue() == '' && newBaika6Ref.current?.getValue() == '' && clear_row == 7){
      clearRow(6);
      clear_row = 6;
    }
    if(shohinCode5Ref.current?.getValue() == '' && gennin5Ref.current?.getValue() == '' && suuryo5Ref.current?.getValue() == '' && oldBaika5Ref.current?.getValue() == '' && newBaika5Ref.current?.getValue() == '' && clear_row == 6){
      clearRow(5);
      clear_row = 5;
    }
    if(shohinCode4Ref.current?.getValue() == '' && gennin4Ref.current?.getValue() == '' && suuryo4Ref.current?.getValue() == '' && oldBaika4Ref.current?.getValue() == '' && newBaika4Ref.current?.getValue() == '' && clear_row == 5){
      clearRow(4);
      clear_row = 4;
    }
    if(shohinCode3Ref.current?.getValue() == '' && gennin3Ref.current?.getValue() == '' && suuryo3Ref.current?.getValue() == '' && oldBaika3Ref.current?.getValue() == '' && newBaika3Ref.current?.getValue() == '' && clear_row == 4){
      clearRow(3);
      clear_row = 3;
    }
    if(shohinCode2Ref.current?.getValue() == '' && gennin2Ref.current?.getValue() == '' && suuryo2Ref.current?.getValue() == '' && oldBaika2Ref.current?.getValue() == '' && newBaika2Ref.current?.getValue() == '' && clear_row == 3){
      clearRow(2);
      clear_row = 2;
    }
    if(shohinCode1Ref.current?.getValue() == '' && gennin1Ref.current?.getValue() == '' && suuryo1Ref.current?.getValue() == '' && oldBaika1Ref.current?.getValue() == '' && newBaika1Ref.current?.getValue() == '' && clear_row == 2){
      clearRow(1);
      clear_row = 1;
    }

    let ret = 0;

    if(clear_row > 1){
      if(shohinCode1Ref.current?.getValue() != '' || gennin1Ref.current?.getValue() != '' || suuryo1Ref.current?.getValue() != '' || oldBaika1Ref.current?.getValue() != '' || newBaika1Ref.current?.getValue() != ''){
        // 商品コード1
        if(shohinCode1Ref.current?.getCheckflg() === 2) {
          setAlert('商品コード入力エラー');//TCGE0026：
          inSHNCDE1.current.focus();
          return;
        }
        if(shohinCode1Ref.current?.getCheckflg() === 1 || shohinCode1Ref.current?.getCheckflg() === 9){
          if(inInputConfirm_SHNCDE(inSHNCDE1,1,1) == 0){
            ret = await onKeyDown_SHNCDE(1);
            if (ret != 0){
              inSHNCDE1.current.focus();
              return;
            }
          }else{
            inSHNCDE1.current.focus();
            return;
          }
        }

        // 原因1
        ret = onKeyDown_DWNGIIN(1);
        if (ret != 0){
          inDWNGIIN1.current.focus();
          return;
        }

        // 数量1
        ret = onKeyDown_SUU(1);
        if (ret != 0){
          inSUU1.current.focus();
          return;
        }

        // 旧売価1
        onKeyDown_OLDBAK(1);

        // 新売価1
        ret = onKeyDown_NEWBAK(1);
        if (ret != 0){
          inNEWBAK1.current.focus();
          return;
        }
      }
    }

    if(clear_row > 2){
      if(shohinCode2Ref.current?.getValue() != '' || gennin2Ref.current?.getValue() != '' || suuryo2Ref.current?.getValue() != '' || oldBaika2Ref.current?.getValue() != '' || newBaika2Ref.current?.getValue() != ''){
        // 商品コード2
        if(shohinCode2Ref.current?.getCheckflg() === 2) {
          setAlert('商品コード入力エラー');//TCGE0026：
          inSHNCDE2.current.focus();
          return;
        }
        if(shohinCode2Ref.current?.getCheckflg() === 1 || shohinCode2Ref.current?.getCheckflg() === 9){
          if(inInputConfirm_SHNCDE(inSHNCDE2,2,1) == 0){
            ret = await onKeyDown_SHNCDE(2);
            if (ret != 0){
              inSHNCDE2.current.focus();
              return;
            }
          }else{
            inSHNCDE2.current.focus();
            return;
          }
        }

        // 原因2
        ret = onKeyDown_DWNGIIN(2);
        if (ret != 0){
          inDWNGIIN2.current.focus();
          return;
        }

        // 数量2
        ret = onKeyDown_SUU(2);
        if (ret != 0){
          inSUU2.current.focus();
          return;
        }

        // 旧売価2
        onKeyDown_OLDBAK(2);

        // 新売価2
        ret = onKeyDown_NEWBAK(2);
        if (ret != 0){
          inNEWBAK2.current.focus();
          return;
        }
      }
    }

    if(clear_row > 3){
      if(shohinCode3Ref.current?.getValue() != '' || gennin3Ref.current?.getValue() != '' || suuryo3Ref.current?.getValue() != '' || oldBaika3Ref.current?.getValue() != '' || newBaika3Ref.current?.getValue() != ''){
        // 商品コード3
        if(shohinCode3Ref.current?.getCheckflg() === 2) {
          setAlert('商品コード入力エラー');//TCGE0026：
          inSHNCDE3.current.focus();
          return;
        }
        if(shohinCode3Ref.current?.getCheckflg() === 1 || shohinCode3Ref.current?.getCheckflg() === 9){
          if(inInputConfirm_SHNCDE(inSHNCDE3,3,1) == 0){
            ret = await onKeyDown_SHNCDE(3);
            if (ret != 0){
              inSHNCDE3.current.focus();
              return;
            }
          }else{
            inSHNCDE3.current.focus();
            return;
          }
        }

        // 原因3
        ret = onKeyDown_DWNGIIN(3);
        if (ret != 0){
          inDWNGIIN3.current.focus();
          return;
        }

        // 数量3
        ret = onKeyDown_SUU(3);
        if (ret != 0){
          inSUU3.current.focus();
          return;
        }

        // 旧売価3
        onKeyDown_OLDBAK(3);

        // 新売価3
        ret = onKeyDown_NEWBAK(3);
        if (ret != 0){
          inNEWBAK3.current.focus();
          return;
        }
      }
    }

    if(clear_row > 4){
      if(shohinCode4Ref.current?.getValue() != '' || gennin4Ref.current?.getValue() != '' || suuryo4Ref.current?.getValue() != '' || oldBaika4Ref.current?.getValue() != '' || newBaika4Ref.current?.getValue() != ''){
        // 商品コード4
        if(shohinCode4Ref.current?.getCheckflg() === 2) {
          setAlert('商品コード入力エラー');//TCGE0026：
          inSHNCDE4.current.focus();
          return;
        }
        if(shohinCode4Ref.current?.getCheckflg() === 1 || shohinCode4Ref.current?.getCheckflg() === 9){
          if(inInputConfirm_SHNCDE(inSHNCDE4,4,1) == 0){
            ret = await onKeyDown_SHNCDE(4);
            if (ret != 0){
              inSHNCDE4.current.focus();
              return;
            }
          }else{
            inSHNCDE4.current.focus();
            return;
          }
        }

        // 原因4
        ret = onKeyDown_DWNGIIN(4);
        if (ret != 0){
          inDWNGIIN4.current.focus();
          return;
        }

        // 数量4
        ret = onKeyDown_SUU(4);
        if (ret != 0){
          inSUU4.current.focus();
          return;
        }

        // 旧売価4
        onKeyDown_OLDBAK(4);

        // 新売価4
        ret = onKeyDown_NEWBAK(4);
        if (ret != 0){
          inNEWBAK4.current.focus();
          return;
        }
      }
    }

    if(clear_row > 5){
      if(shohinCode5Ref.current?.getValue() != '' || gennin5Ref.current?.getValue() != '' || suuryo5Ref.current?.getValue() != '' || oldBaika5Ref.current?.getValue() != '' || newBaika5Ref.current?.getValue() != ''){
        // 商品コード5
        if(shohinCode5Ref.current?.getCheckflg() === 2) {
          setAlert('商品コード入力エラー');//TCGE0026：
          inSHNCDE5.current.focus();
          return;
        }
        if(shohinCode5Ref.current?.getCheckflg() === 1 || shohinCode5Ref.current?.getCheckflg() === 9){
          if(inInputConfirm_SHNCDE(inSHNCDE5,5,1) == 0){
            ret = await onKeyDown_SHNCDE(5);
            if (ret != 0){
              inSHNCDE5.current.focus();
              return;
            }
          }else{
            inSHNCDE5.current.focus();
            return;
          }
        }

        // 原因5
        ret = onKeyDown_DWNGIIN(5);
        if (ret != 0){
          inDWNGIIN5.current.focus();
          return;
        }

        // 数量5
        ret = onKeyDown_SUU(5);
        if (ret != 0){
          inSUU5.current.focus();
          return;
        }

        // 旧売価5
        onKeyDown_OLDBAK(5);

        // 新売価5
        ret = onKeyDown_NEWBAK(5);
        if (ret != 0){
          inNEWBAK5.current.focus();
          return;
        }
      }
    }

    if(clear_row > 6){
      if(shohinCode6Ref.current?.getValue() != '' || gennin6Ref.current?.getValue() != '' || suuryo6Ref.current?.getValue() != '' || oldBaika6Ref.current?.getValue() != '' || newBaika6Ref.current?.getValue() != ''){
        // 商品コード6
        if(shohinCode6Ref.current?.getCheckflg() === 2) {
          setAlert('商品コード入力エラー');//TCGE0026：
          inSHNCDE6.current.focus();
          return;
        }
        if(shohinCode6Ref.current?.getCheckflg() === 1 || shohinCode6Ref.current?.getCheckflg() === 9){
          if(inInputConfirm_SHNCDE(inSHNCDE6,6,1) == 0){
            ret = await onKeyDown_SHNCDE(6);
            if (ret != 0){
              inSHNCDE6.current.focus();
              return;
            }
          }else{
            inSHNCDE6.current.focus();
            return;
          }
        }

        // 原因6
        ret = onKeyDown_DWNGIIN(6);
        if (ret != 0){
          inDWNGIIN6.current.focus();
          return;
        }

        // 数量6
        ret = onKeyDown_SUU(6);
        if (ret != 0){
          inSUU6.current.focus();
          return;
        }

        // 旧売価6
        onKeyDown_OLDBAK(6);

        // 新売価6
        ret = onKeyDown_NEWBAK(6);
        if (ret != 0){
          inNEWBAK6.current.focus();
          return;
        }
      }
    }

    if(clear_row > 7){
      if(shohinCode7Ref.current?.getValue() != '' || gennin7Ref.current?.getValue() != '' || suuryo7Ref.current?.getValue() != '' || oldBaika7Ref.current?.getValue() != '' || newBaika7Ref.current?.getValue() != ''){
        // 商品コード7
        if(shohinCode7Ref.current?.getCheckflg() === 2) {
          setAlert('商品コード入力エラー');//TCGE0026：
          inSHNCDE7.current.focus();
          return;
        }
        if(shohinCode7Ref.current?.getCheckflg() === 1 || shohinCode7Ref.current?.getCheckflg() === 9){
          if(inInputConfirm_SHNCDE(inSHNCDE7,7,1) == 0){
            ret = await onKeyDown_SHNCDE(7);
            if (ret != 0){
              inSHNCDE7.current.focus();
              return;
            }
          }else{
            inSHNCDE7.current.focus();
            return;
          }
        }

        // 原因7
        ret = onKeyDown_DWNGIIN(7);
        if (ret != 0){
          inDWNGIIN7.current.focus();
          return;
        }

        // 数量7
        ret = onKeyDown_SUU(7);
        if (ret != 0){
          inSUU7.current.focus();
          return;
        }

        // 旧売価7
        onKeyDown_OLDBAK(7);

        // 新売価7
        ret = onKeyDown_NEWBAK(7);
        if (ret != 0){
          inNEWBAK7.current.focus();
          return;
        }
      }
    }

    if(clear_row > 8){
      if(shohinCode8Ref.current?.getValue() != '' || gennin8Ref.current?.getValue() != '' || suuryo8Ref.current?.getValue() != '' || oldBaika8Ref.current?.getValue() != '' || newBaika8Ref.current?.getValue() != ''){
        // 商品コード8
        if(shohinCode8Ref.current?.getCheckflg() === 2) {
          setAlert('商品コード入力エラー');//TCGE0026：
          inSHNCDE8.current.focus();
          return;
        }
        if(shohinCode8Ref.current?.getCheckflg() === 1 || shohinCode8Ref.current?.getCheckflg() === 9){
          if(inInputConfirm_SHNCDE(inSHNCDE8,8,1) == 0){
            ret = await onKeyDown_SHNCDE(8);
            if (ret != 0){
              inSHNCDE8.current.focus();
              return;
            }
          }else{
            inSHNCDE8.current.focus();
            return;
          }
        }

        // 原因8
        ret = onKeyDown_DWNGIIN(8);
        if (ret != 0){
          inDWNGIIN8.current.focus();
          return;
        }

        // 数量8
        ret = onKeyDown_SUU(8);
        if (ret != 0){
          inSUU8.current.focus();
          return;
        }

        // 旧売価8
        onKeyDown_OLDBAK(8);

        // 新売価8
        ret = onKeyDown_NEWBAK(8);
        if (ret != 0){
          inNEWBAK8.current.focus();
          return;
        }
      }
    }
    setAlert('　');
  };
  //#endregion

  //#region フッタ部入力
  // フッタ部入力 数量合計
  const onKeyDown_SUUG = async()=>{
    let mvFlg = 0;
    suuryoGoukeiRef.current?.setBGColor('#FFFFFF');
    console.log("-- 数量合計チェック --");
    let l_suu1 = parseFloat(suuryo1Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu2 = parseFloat(suuryo2Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu3 = parseFloat(suuryo3Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu4 = parseFloat(suuryo4Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu5 = parseFloat(suuryo5Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu6 = parseFloat(suuryo6Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu7 = parseFloat(suuryo7Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu8 = parseFloat(suuryo8Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suug = parseFloat(suuryoGoukeiRef.current?.getValue().replace(/,/g, ''))||0;

    console.log('suuryoGoukeiRef.current?.getValue():'+l_suug);
    if (l_suu1+l_suu2+l_suu3+l_suu4+l_suu5+l_suu6+l_suu7+l_suu8 !== l_suug){
      setAlert('数量合計入力エラー');//TCGE0037：
      suuryoGoukeiRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
    }else{
      setAlert('　');
      mvFlg = 0;
    }
    return mvFlg;
  }

  // フッタ部入力 旧売価合計
  const onKeyDown_OLDBAKG = async()=>{
    let mvFlg = 0;
    oldBaikaGoukeiRef.current?.setBGColor('#FFFFFF');
    console.log("-- 旧売価合計チェック --");
    let l_OLDBAK1 = parseFloat(oldBaika1Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_OLDBAK2 = parseFloat(oldBaika2Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_OLDBAK3 = parseFloat(oldBaika3Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_OLDBAK4 = parseFloat(oldBaika4Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_OLDBAK5 = parseFloat(oldBaika5Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_OLDBAK6 = parseFloat(oldBaika6Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_OLDBAK7 = parseFloat(oldBaika7Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_OLDBAK8 = parseFloat(oldBaika8Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_OLDBAKG = parseFloat(oldBaikaGoukeiRef.current?.getValue().replace(/,/g, ''))||0;
    console.log('oldBaikaGoukeiRef.current?.getValue():'+l_OLDBAKG);
    if(l_OLDBAK1+l_OLDBAK2+l_OLDBAK3+l_OLDBAK4+l_OLDBAK5+l_OLDBAK6+l_OLDBAK7+l_OLDBAK8 !== l_OLDBAKG){
      setAlert('旧売価合計エラー');//TCGE0042：
      oldBaikaGoukeiRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
    }else{
      setAlert('　');
      mvFlg = 0;
    }
    return mvFlg;
  }

  // フッタ部入力 新売価合計
  const onKeyDown_NEWBAKG = async()=>{
    let mvFlg = 0;
    newBaikaGoukeiRef.current?.setBGColor('#FFFFFF');
    console.log("-- 新売価合計チェック --");
    let l_suu1 = parseFloat(suuryo1Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu2 = parseFloat(suuryo2Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu3 = parseFloat(suuryo3Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu4 = parseFloat(suuryo4Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu5 = parseFloat(suuryo5Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu6 = parseFloat(suuryo6Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu7 = parseFloat(suuryo7Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_suu8 = parseFloat(suuryo8Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_OLDBAK1 = parseFloat(oldBaika1Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_OLDBAK2 = parseFloat(oldBaika2Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_OLDBAK3 = parseFloat(oldBaika3Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_OLDBAK4 = parseFloat(oldBaika4Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_OLDBAK5 = parseFloat(oldBaika5Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_OLDBAK6 = parseFloat(oldBaika6Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_OLDBAK7 = parseFloat(oldBaika7Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_OLDBAK8 = parseFloat(oldBaika8Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_NEWBAK1 = parseFloat(newBaika1Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_NEWBAK2 = parseFloat(newBaika2Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_NEWBAK3 = parseFloat(newBaika3Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_NEWBAK4 = parseFloat(newBaika4Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_NEWBAK5 = parseFloat(newBaika5Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_NEWBAK6 = parseFloat(newBaika6Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_NEWBAK7 = parseFloat(newBaika7Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_NEWBAK8 = parseFloat(newBaika8Ref.current?.getValue().replace(/,/g, ''))||0;
    let l_NEWBAKG = parseFloat(newBaikaGoukeiRef.current?.getValue().replace(/,/g, ''))||0;
    console.log('l_NEWBAK1:'+l_NEWBAK1);
    console.log('newBaikaGoukeiRef.current?.getValue():'+l_NEWBAKG);
    if (l_NEWBAK1+l_NEWBAK2+l_NEWBAK3+l_NEWBAK4+l_NEWBAK5+l_NEWBAK6+l_NEWBAK7+l_NEWBAK8 !== l_NEWBAKG){
      setAlert('新売価合計エラー');//TCGE0043：
      newBaikaGoukeiRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
    }else{
      setAlert('　');
      mvFlg = 0;

      //ここから売価合計チェック
      let l_BIHNGKG = (l_NEWBAK1-l_OLDBAK1)*l_suu1
        + (l_NEWBAK2-l_OLDBAK2)*l_suu2
        + (l_NEWBAK3-l_OLDBAK3)*l_suu3
        + (l_NEWBAK4-l_OLDBAK4)*l_suu4
        + (l_NEWBAK5-l_OLDBAK5)*l_suu5
        + (l_NEWBAK6-l_OLDBAK6)*l_suu6
        + (l_NEWBAK7-l_OLDBAK7)*l_suu7
        + (l_NEWBAK8-l_OLDBAK8)*l_suu8;
      baihenGakuGoukeiRef.current?.setValue(l_BIHNGKG);
    }
    return mvFlg;
  }

  // フッタ部入力 売変額合計
  const onKeyDown_BIHNGKG = async()=>{
    let mvFlg = 0;
    baihenGakuGoukeiRef.current?.setBGColor('#FFFFFF');
    console.log("-- 売変額合計チェック --");
    let l_BIHNGK1 = parseFloat(String(baihenGaku1Ref.current?.getValue()).replace(/,/g, ''))||0;
    let l_BIHNGK2 = parseFloat(String(baihenGaku2Ref.current?.getValue()).replace(/,/g, ''))||0;
    let l_BIHNGK3 = parseFloat(String(baihenGaku3Ref.current?.getValue()).replace(/,/g, ''))||0;
    let l_BIHNGK4 = parseFloat(String(baihenGaku4Ref.current?.getValue()).replace(/,/g, ''))||0;
    let l_BIHNGK5 = parseFloat(String(baihenGaku5Ref.current?.getValue()).replace(/,/g, ''))||0;
    let l_BIHNGK6 = parseFloat(String(baihenGaku6Ref.current?.getValue()).replace(/,/g, ''))||0;
    let l_BIHNGK7 = parseFloat(String(baihenGaku7Ref.current?.getValue()).replace(/,/g, ''))||0;
    let l_BIHNGK8 = parseFloat(String(baihenGaku8Ref.current?.getValue()).replace(/,/g, ''))||0;
    let l_BIHNGKG = parseFloat(String(baihenGakuGoukeiRef.current?.getValue()).replace(/,/g, ''))||0;
    console.log('baihenGakuGoukeiRef.current?.getValue():'+l_BIHNGKG);
    if (l_BIHNGK1+l_BIHNGK2+l_BIHNGK3+l_BIHNGK4+l_BIHNGK5+l_BIHNGK6+l_BIHNGK7+l_BIHNGK8 !== l_BIHNGKG){
      setAlert('売変額合計エラー');//TCGE0044：
      baihenGakuGoukeiRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
    }else{
      setAlert('　');
      mvFlg = 0;
    }
    return mvFlg;
  }
  //#endregion

  //#region 登録処理
  // 商品コードの処理終了後に再処理
  useEffect (()=>{
    if(chkflg2 === 1){
      onKeyDown('Enter',inRET,'',inRET,'','','','');
    }
    setChkflg(0);
  },[chkflg]);

  // 最終チェック
  const InputConfirmed =  async()=>{
    let ret = 0;
    // 商品コードの処理が途中なら一旦処理を中段し、確認が終った後に再処理
    if(shohinCode1Ref.current?.getCheckflg() === 9 || shohinCode2Ref.current?.getCheckflg() === 9 || shohinCode3Ref.current?.getCheckflg() === 9 || shohinCode4Ref.current?.getCheckflg() === 9
      || shohinCode5Ref.current?.getCheckflg() === 9 || shohinCode6Ref.current?.getCheckflg() === 9 || shohinCode7Ref.current?.getCheckflg() === 9 || shohinCode8Ref.current?.getCheckflg() === 9
      || denpyoNoRef.current?.getCheckflg() === 9){
      setChkflg2(1);
      return;
    }
    setChkflg2(0);

    setAlert('　');
    console.log('ヘッダー項目チェック開始');

    // 伝票No
    if(denpyoNoRef.current?.getCheckflg() === 2 || denpyoNoRef.current?.getCheckflg() === '') {
      setAlert('伝票№入力エラー');//TCGE0014：
      denpyoNoRef.current?.setBGColor('#f59d9d');
      inDENNO.current.focus();
      return;
    }
    if(denpyoNoRef.current?.getCheckflg() === 1 || denpyoNoRef.current?.getCheckflg() === 3){
      ret = await onKeyDown_DENNO();
      if (ret === 1){
        if(alert == '　'){
          setAlert('伝票№入力エラー');//TCGE0014：
        }
        inDENNO.current.focus();
        return;
      }
    }

    // 店コード未入力時、伝票№から取得した店コードを使用する為、
    // 処理を中段し、最終チェック確認後処理を呼び出す。
    setInputConfirmflg(1);
  }

  // 最終チェック2
  const InputConfirmed2 =  async()=>{
    let ret = 0;
    // 店CD
    ret = await onKeyDown_MISE();
    if (ret === 1){
      inMISE.current.focus();
      return;
    }

    // 分類CD
    ret = await onKeyDown_BMNCDE();
    if (ret === 1){
      inBMNCDE.current.focus();
      return;
    }

    // 売変実施日
    ret = onKeyDown_DENYMD();
    if  (ret === 1){
      inDENYMD.current.focus();
      return;
    }

    console.log('明細項目チェック開始');
    {
      //商品コード1
      if(shohinCode1Ref.current?.getCheckflg() === 2 || shohinCode1Ref.current?.getValue() === '') {
        setAlert('商品コード入力エラー');//TCGE0026：
        setItitemclr('#f59d9d',1,'shncde');
        inSHNCDE1.current.focus();
        return;
      }
      if(shohinCode1Ref.current?.getCheckflg() === 1){
        if(inInputConfirm_SHNCDE(inSHNCDE1,1,1) == 0){
          ret = await onKeyDown_SHNCDE(1);

          if (ret === 1){
            inSHNCDE1.current.focus();
            return 1;
          }
        }else{
          inSHNCDE1.current.focus();
          return 1;
        }
      }

      // 原因1
      ret = onKeyDown_DWNGIIN(1);
      if (ret != 0){
        inDWNGIIN1.current.focus();
        return;
      }

      // 数量1
      ret = onKeyDown_SUU(1);
      if (ret != 0){
        inSUU1.current.focus();
        return;
      }

      // 旧売価1
      onKeyDown_OLDBAK(1);

      // 新売価1
      ret = onKeyDown_NEWBAK(1);
      if (ret != 0){
        inNEWBAK1.current.focus();
        return;
      }
    }

    if(shohinCode2Ref.current?.getValue() != '' || gennin2Ref.current?.getValue()!= '' || suuryo2Ref.current?.getValue()!= '' || newBaika2Ref.current?.getValue()!= '' ){
      //商品コード2
      if(shohinCode2Ref.current?.getCheckflg() === 2 || shohinCode2Ref.current?.getValue() === '') {
        setAlert('商品コード入力エラー');//TCGE0026：
        setItitemclr('#f59d9d',2,'shncde');
        inSHNCDE2.current.focus();
        return;
      }
      if(shohinCode2Ref.current?.getCheckflg() === 1){
        if(inInputConfirm_SHNCDE(inSHNCDE2,2,1) == 0){
          ret = await onKeyDown_SHNCDE(2);

          if (ret === 1){
            inSHNCDE2.current.focus();
            return 1;
          }
        }else{
          inSHNCDE2.current.focus();
          return 1;
        }
      }

      // 原因2
      ret = onKeyDown_DWNGIIN(2);
      if (ret != 0){
        inDWNGIIN2.current.focus();
        return;
      }

      // 数量2
      ret = onKeyDown_SUU(2);
      if (ret != 0){
        inSUU2.current.focus();
        return;
      }

      // 旧売価2
      onKeyDown_OLDBAK(2);

      // 新売価2
      ret = onKeyDown_NEWBAK(2);
      if (ret != 0){
        inNEWBAK2.current.focus();
        return;
      }
    }

    if(shohinCode3Ref.current?.getValue() != '' || gennin3Ref.current?.getValue()!= '' || suuryo3Ref.current?.getValue()!= '' || newBaika3Ref.current?.getValue()!= '' ){
      //商品コード3
      if(shohinCode3Ref.current?.getCheckflg() === 2 || shohinCode3Ref.current?.getValue() === '') {
        setAlert('商品コード入力エラー');//TCGE0026：
        setItitemclr('#f59d9d',3,'shncde');
        inSHNCDE3.current.focus();
        return;
      }
      if(shohinCode3Ref.current?.getCheckflg() === 1){
        if(inInputConfirm_SHNCDE(inSHNCDE3,3,1) == 0){
          ret = await onKeyDown_SHNCDE(3);

          if (ret === 1){
            inSHNCDE3.current.focus();
            return 1;
          }
        }else{
          inSHNCDE3.current.focus();
          return 1;
        }
      }

      // 原因3
      ret = onKeyDown_DWNGIIN(3);
      if (ret != 0){
        inDWNGIIN3.current.focus();
        return;
      }

      // 数量3
      ret = onKeyDown_SUU(3);
      if (ret != 0){
        inSUU3.current.focus();
        return;
      }

      // 旧売価3
      onKeyDown_OLDBAK(3);

      // 新売価3
      ret = onKeyDown_NEWBAK(3);
      if (ret != 0){
        inNEWBAK3.current.focus();
        return;
      }
    }

    if(shohinCode4Ref.current?.getValue() != '' || gennin4Ref.current?.getValue()!= '' || suuryo4Ref.current?.getValue()!= '' || newBaika4Ref.current?.getValue()!= '' ){
      //商品コード4
      if(shohinCode4Ref.current?.getCheckflg() === 2 || shohinCode4Ref.current?.getValue() === '') {
        setAlert('商品コード入力エラー');//TCGE0026：
        setItitemclr('#f59d9d',4,'shncde');
        inSHNCDE4.current.focus();
        return;
      }
      if(shohinCode4Ref.current?.getCheckflg() === 1){
        if(inInputConfirm_SHNCDE(inSHNCDE4,4,1) == 0){
          ret = await onKeyDown_SHNCDE(4);

          if (ret === 1){
            inSHNCDE4.current.focus();
            return 1;
          }
        }else{
          inSHNCDE4.current.focus();
          return 1;
        }
      }

      // 原因4
      ret = onKeyDown_DWNGIIN(4);
      if (ret != 0){
        inDWNGIIN4.current.focus();
        return;
      }

      // 数量4
      ret = onKeyDown_SUU(4);
      if (ret != 0){
        inSUU4.current.focus();
        return;
      }

      // 旧売価4
      onKeyDown_OLDBAK(4);

      // 新売価4
      ret = onKeyDown_NEWBAK(4);
      if (ret != 0){
        inNEWBAK4.current.focus();
        return;
      }
    }

    if(shohinCode5Ref.current?.getValue() != '' || gennin5Ref.current?.getValue()!= '' || suuryo5Ref.current?.getValue()!= '' || newBaika5Ref.current?.getValue()!= '' ){
      //商品コード5
      if(shohinCode5Ref.current?.getCheckflg() === 2 || shohinCode5Ref.current?.getValue() === '') {
        setAlert('商品コード入力エラー');//TCGE0026：
        setItitemclr('#f59d9d',5,'shncde');
        inSHNCDE5.current.focus();
        return;
      }
      if(shohinCode5Ref.current?.getCheckflg() === 1){
        if(inInputConfirm_SHNCDE(inSHNCDE5,5,1) == 0){
          ret = await onKeyDown_SHNCDE(5);

          if (ret === 1){
            inSHNCDE5.current.focus();
            return 1;
          }
        }else{
          inSHNCDE5.current.focus();
          return 1;
        }
      }

      // 原因5
      ret = onKeyDown_DWNGIIN(5);
      if (ret != 0){
        inDWNGIIN5.current.focus();
        return;
      }

      // 数量5
      ret = onKeyDown_SUU(5);
      if (ret != 0){
        inSUU5.current.focus();
        return;
      }

      // 旧売価5
      onKeyDown_OLDBAK(5);

      // 新売価5
      ret = onKeyDown_NEWBAK(5);
      if (ret != 0){
        inNEWBAK5.current.focus();
        return;
      }
    }

    if(shohinCode6Ref.current?.getValue() != '' || gennin6Ref.current?.getValue()!= '' || suuryo6Ref.current?.getValue()!= '' || newBaika6Ref.current?.getValue()!= '' ){
      //商品コード6
      if(shohinCode6Ref.current?.getCheckflg() === 2 || shohinCode6Ref.current?.getValue() === '') {
        setAlert('商品コード入力エラー');//TCGE0026：
        setItitemclr('#f59d9d',6,'shncde');
        inSHNCDE6.current.focus();
        return;
      }
      if(shohinCode6Ref.current?.getCheckflg() === 1){
        if(inInputConfirm_SHNCDE(inSHNCDE6,6,1) == 0){
          ret = await onKeyDown_SHNCDE(6);

          if (ret === 1){
            inSHNCDE6.current.focus();
            return 1;
          }
        }else{
          inSHNCDE6.current.focus();
          return 1;
        }
      }

      // 原因6
      ret = onKeyDown_DWNGIIN(6);
      if (ret != 0){
        inDWNGIIN6.current.focus();
        return;
      }

      // 数量6
      ret = onKeyDown_SUU(6);
      if (ret != 0){
        inSUU6.current.focus();
        return;
      }

      // 旧売価6
      onKeyDown_OLDBAK(6);

      // 新売価6
      ret = onKeyDown_NEWBAK(6);
      if (ret != 0){
        inNEWBAK6.current.focus();
        return;
      }
    }

    if(shohinCode7Ref.current?.getValue() != '' || gennin7Ref.current?.getValue()!= '' || suuryo7Ref.current?.getValue()!= '' || newBaika7Ref.current?.getValue()!= '' ){
      //商品コード7
      if(shohinCode7Ref.current?.getCheckflg() === 2 || shohinCode7Ref.current?.getValue() === '') {
        setAlert('商品コード入力エラー');//TCGE0026：
        setItitemclr('#f59d9d',7,'shncde');
        inSHNCDE7.current.focus();
        return;
      }
      if(shohinCode7Ref.current?.getCheckflg() === 1){
        if(inInputConfirm_SHNCDE(inSHNCDE7,7,1) == 0){
          ret = await onKeyDown_SHNCDE(7);

          if (ret === 1){
            inSHNCDE7.current.focus();
            return 1;
          }
        }else{
          inSHNCDE7.current.focus();
          return 1;
        }
      }

      // 原因7
      ret = onKeyDown_DWNGIIN(7);
      if (ret != 0){
        inDWNGIIN7.current.focus();
        return;
      }

      // 数量7
      ret = onKeyDown_SUU(7);
      if (ret != 0){
        inSUU7.current.focus();
        return;
      }

      // 旧売価7
      onKeyDown_OLDBAK(7);

      // 新売価7
      ret = onKeyDown_NEWBAK(7);
      if (ret != 0){
        inNEWBAK7.current.focus();
        return;
      }
    }

    if(shohinCode8Ref.current?.getValue() != '' || gennin8Ref.current?.getValue()!= '' || suuryo8Ref.current?.getValue()!= '' || newBaika8Ref.current?.getValue()!= '' ){
      //商品コード8
      if(shohinCode8Ref.current?.getCheckflg() === 2 || shohinCode8Ref.current?.getValue() === '') {
        setAlert('商品コード入力エラー');//TCGE0026：
        setItitemclr('#f59d9d',8,'shncde');
        inSHNCDE8.current.focus();
        return;
      }
      if(shohinCode8Ref.current?.getCheckflg() === 1){
        if(inInputConfirm_SHNCDE(inSHNCDE8,8,1) == 0){
          ret = await onKeyDown_SHNCDE(8);

          if (ret === 1){
            inSHNCDE8.current.focus();
            return 1;
          }
        }else{
          inSHNCDE8.current.focus();
          return 1;
        }
      }

      // 原因8
      ret = onKeyDown_DWNGIIN(8);
      if (ret != 0){
        inDWNGIIN8.current.focus();
        return;
      }

      // 数量8
      ret = onKeyDown_SUU(8);
      if (ret != 0){
        inSUU8.current.focus();
        return;
      }

      // 旧売価8
      onKeyDown_OLDBAK(8);

      // 新売価8
      ret = onKeyDown_NEWBAK(8);
      if (ret != 0){
        inNEWBAK8.current.focus();
        return;
      }
    }

    // 一度処理を終了しないと、明細の合計値が反映されない為、フッダ確認時にエラーとなる。
    // 処理を中段し、最終チェック確認後処理を呼び出す。
    setInputConfirmflg(2);
  }

  // 最終チェック3
  const InputConfirmed3 =  async()=>{
    let ret = 0;
    console.log('フッタ部項目チェック開始');
    // 数量合計
    ret = await onKeyDown_SUUG();
    if (ret === 1){ return; }

    // 旧売価合計
    ret = await onKeyDown_OLDBAKG();
    if (ret === 1){ return; }

    // 新売価合計
    ret = await onKeyDown_NEWBAKG();
    if (ret === 1){ return; }

    // 売変額合計
    ret = await onKeyDown_BIHNGKG();
    if (ret === 1){ return; }

    setInputConfirmflg(9);
    return;
  }

  // 最終チェック確認後処理
  useEffect (()=>{
    if(inputConfirmflg == 1){
      setInputConfirmflg(0);
      // 最終チェック1正常終了時、最終チェック2(InputConfirmed2)を呼び出す。
      InputConfirmed2();
    }else if(inputConfirmflg == 2){
      setInputConfirmflg(0);
      // 最終チェック2正常終了時、最終チェック3(InputConfirmed3)を呼び出す。
      InputConfirmed3();
    }else if(inputConfirmflg !== 0){
      setInputConfirmflg(0);
      // 最終チェック2正常終了時、DB更新処理を呼び出す。
      register();
    }
  },[inputConfirmflg]);

  // 確認後DB更新処理
  const register =  async()=>{
    let mvFlg = 0;
    if(kubunRef.current?.getValue() === '1') {
      mvFlg = await onKeyDown_Insert();   // 新規
    } else if(kubunRef.current?.getValue() === '2') {
      mvFlg = await onKeyDown_Update();   // 更新
    } else {
      mvFlg = await onKeyDown_Delete();   // 削除
    }

    switch (mvFlg) {
      case 0:
        inRET.current.focus();   //次の項目に遷移
        break;
      case 1:
        inRET.current.focus();   //現在の項目に遷移
        break;
      case 2:
        break;
      case 9:
        // inDENNO.current.focus();   //伝票No.の項目に遷移
        break;
      default:
        break;
    }
  }

  // 確認 登録処理
  const onKeyDown_Insert = async()=>{
    // isInInputConfirm = true;
    // if (await InputConfirmed() !== 0) {
    //   isInInputConfirm = false;
    //   return 2;
    // }
    // isInInputConfirm = false;

    // window.alert('チェックok onKeyDown_Insert');
    // return 0;

    let mvFlg = 0;
    // 伝票ヘッダ 新規登録
    console.log('伝票ヘッダ新規');
    const responseUA1 = await fetch('/TCSA0150/SL_HED_DAT_INS',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      ent_ksacde : state.KSACDES  //入力元会社コード
      ,ent_mise : state.MISES     //入力元店コード
      ,densbt : '06'              //伝票種別
      ,denno : denpyoNoRef.current?.getValue()            //伝票番号
      ,kijymd :'20'+itKIJYYM2+'20'//計上日
      ,kijyym :'20'+itKIJYYM2     //計上年月
      ,ksacde : state.KSACDES     //会社コード
      ,mise :  state.MISES        //店コード
      ,edaban : '01'              //枝番号
      ,bmncde : bunruiCodeRef.current?.getValue()          //部門CD
      ,denymd : '20'+denpyoYMDRef.current?.getValue().replace(/(\\|\/)/g,'')    //伝票日付
      ,sryfot : suuryoGoukeiRef.current?.getValue().replaceAll(/,/g, '')            //数量合計
      ,oldbakfot : oldBaikaGoukeiRef.current?.getValue().replaceAll(/,/g, '')      // 旧売価
      ,newbakfot : newBaikaGoukeiRef.current?.getValue().replaceAll(/,/g, '')      // 新売価
      ,bihnfok : Number(String(baihenGakuGoukeiRef.current?.getValue()).replaceAll(/,/g, '')) //売変額
      ,set_fix_kbn :'0'           //決算修正区分
      ,dat_mkbn :'1'              //データメンテ区分
      ,add_jgncde : state.JGNCDE  //ユーザーID
      ,add_prg_id : 'TCSA0150'    //プログラムID
      ,trl_id :state.MISES        //端末ＩＤ★一旦店コード入れる
      ,delflg :'0'                //削除フラグ
    })});
    const dataUA1 = await responseUA1.json();
    console.log('伝票ヘッダ:%o',dataUA1.res);

    // 伝票ヘッダ 登録エラー
    if (dataUA1.res == -1) {
      setAlert('伝票データ登録エラー');//TCGE0023：
      mvFlg = 1;
      return mvFlg;
    }

    // 伝票ボディ 新規登録
    const responseUA2 = await fetch('/TCSA0150/SL_DTL_DAT_INS',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      ent_ksacde : state.KSACDES  //入力元会社コード
      ,ent_mise : state.MISES     //入力元店コード
      ,densbt : '06'              //伝票種別
      ,denno : denpyoNoRef.current?.getValue()            //伝票番号
      ,ksacde : state.KSACDES     //会社コード
      ,mise :  state.MISES        //店コード
      ,edaban : '01'              //枝番号
      ,meisai:[
        {lineno:'1',shncde:shohinCode1Ref.current?.getValue(),suu:suuryo1Ref.current?.getValue().replaceAll(/,/g, ''),dwngiin:gennin1Ref.current?.getValue(),oldbak:oldBaika1Ref.current?.getValue().replaceAll(/,/g, ''),newbak:newBaika1Ref.current?.getValue().replaceAll(/,/g, ''),sagaku:Number(String(sagaku1Ref.current?.getValue()).replaceAll(/,/g, '')),bihngk:Number(String(baihenGaku1Ref.current?.getValue()).replaceAll(/,/g, '')),fgo:fugou1Ref.current?.getValue(),esj_kbn:itESJ_KBN1}
        ,{lineno:'2',shncde:shohinCode2Ref.current?.getValue(),suu:suuryo2Ref.current?.getValue().replaceAll(/,/g, ''),dwngiin:gennin2Ref.current?.getValue(),oldbak:oldBaika2Ref.current?.getValue().replaceAll(/,/g, ''),newbak:newBaika2Ref.current?.getValue().replaceAll(/,/g, ''),sagaku:Number(String(sagaku2Ref.current?.getValue()).replaceAll(/,/g, '')),bihngk:Number(String(baihenGaku2Ref.current?.getValue()).replaceAll(/,/g, '')),fgo:fugou2Ref.current?.getValue(),esj_kbn:itESJ_KBN2}
        ,{lineno:'3',shncde:shohinCode3Ref.current?.getValue(),suu:suuryo3Ref.current?.getValue().replaceAll(/,/g, ''),dwngiin:gennin3Ref.current?.getValue(),oldbak:oldBaika3Ref.current?.getValue().replaceAll(/,/g, ''),newbak:newBaika3Ref.current?.getValue().replaceAll(/,/g, ''),sagaku:Number(String(sagaku3Ref.current?.getValue()).replaceAll(/,/g, '')),bihngk:Number(String(baihenGaku3Ref.current?.getValue()).replaceAll(/,/g, '')),fgo:fugou3Ref.current?.getValue(),esj_kbn:itESJ_KBN3}
        ,{lineno:'4',shncde:shohinCode4Ref.current?.getValue(),suu:suuryo4Ref.current?.getValue().replaceAll(/,/g, ''),dwngiin:gennin4Ref.current?.getValue(),oldbak:oldBaika4Ref.current?.getValue().replaceAll(/,/g, ''),newbak:newBaika4Ref.current?.getValue().replaceAll(/,/g, ''),sagaku:Number(String(sagaku4Ref.current?.getValue()).replaceAll(/,/g, '')),bihngk:Number(String(baihenGaku4Ref.current?.getValue()).replaceAll(/,/g, '')),fgo:fugou4Ref.current?.getValue(),esj_kbn:itESJ_KBN4}
        ,{lineno:'5',shncde:shohinCode5Ref.current?.getValue(),suu:suuryo5Ref.current?.getValue().replaceAll(/,/g, ''),dwngiin:gennin5Ref.current?.getValue(),oldbak:oldBaika5Ref.current?.getValue().replaceAll(/,/g, ''),newbak:newBaika5Ref.current?.getValue().replaceAll(/,/g, ''),sagaku:Number(String(sagaku5Ref.current?.getValue()).replaceAll(/,/g, '')),bihngk:Number(String(baihenGaku5Ref.current?.getValue()).replaceAll(/,/g, '')),fgo:fugou5Ref.current?.getValue(),esj_kbn:itESJ_KBN5}
        ,{lineno:'6',shncde:shohinCode6Ref.current?.getValue(),suu:suuryo6Ref.current?.getValue().replaceAll(/,/g, ''),dwngiin:gennin6Ref.current?.getValue(),oldbak:oldBaika6Ref.current?.getValue().replaceAll(/,/g, ''),newbak:newBaika6Ref.current?.getValue().replaceAll(/,/g, ''),sagaku:Number(String(sagaku6Ref.current?.getValue()).replaceAll(/,/g, '')),bihngk:Number(String(baihenGaku6Ref.current?.getValue()).replaceAll(/,/g, '')),fgo:fugou6Ref.current?.getValue(),esj_kbn:itESJ_KBN6}
        ,{lineno:'7',shncde:shohinCode7Ref.current?.getValue(),suu:suuryo7Ref.current?.getValue().replaceAll(/,/g, ''),dwngiin:gennin7Ref.current?.getValue(),oldbak:oldBaika7Ref.current?.getValue().replaceAll(/,/g, ''),newbak:newBaika7Ref.current?.getValue().replaceAll(/,/g, ''),sagaku:Number(String(sagaku7Ref.current?.getValue()).replaceAll(/,/g, '')),bihngk:Number(String(baihenGaku7Ref.current?.getValue()).replaceAll(/,/g, '')),fgo:fugou7Ref.current?.getValue(),esj_kbn:itESJ_KBN7}
        ,{lineno:'8',shncde:shohinCode8Ref.current?.getValue(),suu:suuryo8Ref.current?.getValue().replaceAll(/,/g, ''),dwngiin:gennin8Ref.current?.getValue(),oldbak:oldBaika8Ref.current?.getValue().replaceAll(/,/g, ''),newbak:newBaika8Ref.current?.getValue().replaceAll(/,/g, ''),sagaku:Number(String(sagaku8Ref.current?.getValue()).replaceAll(/,/g, '')),bihngk:Number(String(baihenGaku8Ref.current?.getValue()).replaceAll(/,/g, '')),fgo:fugou8Ref.current?.getValue(),esj_kbn:itESJ_KBN8}
      ]
      // ,esj_kbn:itESJ_KBN
      ,add_jgncde : state.JGNCDE  //ユーザーID
      ,add_prg_id : 'TCSA0150'    //プログラムID
      ,trl_id :state.MISES        //端末ＩＤ★一旦店コード入れる
      ,delflg :'0'                //削除フラグ
    })});
    const dataUA2 = await responseUA2.json();
    console.log('伝票明細:%o',dataUA2.res);

    // 伝票ボディ 登録エラー
    if (dataUA2.res == -1) {
      setAlert('伝票データ登録エラー');//TCGE0023：
      mvFlg = 1;
      return mvFlg;
    }

    setItrcnt(itRcnt+1);
    Gmclr();

    //こちらでmv制御する為、mvFlg立てる
    mvFlg = 9;
    return mvFlg;
  }

  // 確認 更新処理
  const onKeyDown_Update = async()=>{
    // isInInputConfirm = true;
    // if (await InputConfirmed() !== 0) {
    //   isInInputConfirm = false;
    //   return 2;
    // }
    // isInInputConfirm = false;

    // window.alert('チェックok onKeyDown_Update');
    // return 0;

    let mvFlg = 0;
    // 伝票ヘッダ 更新処理
    console.log('伝票ヘッダ更新');
    const responseUA1 = await fetch('/TCSA0150/SL_HED_DAT_upd',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      kijymd:'20'+itKIJYYM2+'20'//計上日
      ,kijyym:'20'+itKIJYYM2         //計上年月
      ,mise:miseCodeRef.current?.getValue()              //店コード
      ,bmncde:bunruiCodeRef.current?.getValue()          //部門CD
      ,denymd: '20'+denpyoYMDRef.current?.getValue().replace(/(\\|\/)/g,'')    //伝票日付
      ,sryfot : suuryoGoukeiRef.current?.getValue().replaceAll(/,/g, '')            //数量合計
      ,oldbakfot : oldBaikaGoukeiRef.current?.getValue().replaceAll(/,/g, '')
      ,newbakfot : newBaikaGoukeiRef.current?.getValue().replaceAll(/,/g, '')
      ,bihnfok : Number(String(baihenGakuGoukeiRef.current?.getValue()).replaceAll(/,/g, ''))
      ,set_fix_kbn :'0'         //決算修正区分
      ,dat_mkbn :'1'            //データメンテ区分
      ,upd_jgncde: state.JGNCDE //ユーザーID
      ,upd_prg_id: 'TCSA0150'   //プログラムID
      ,trl_id :state.MISES        //端末ＩＤ★一旦店コード入れる
      ,ent_ksacde: state.KSACDES
      ,ent_mise: state.MISES
      ,densbt: '06'
      ,denno: denpyoNoRef.current?.getValue()
      // ,sircde: ''
      ,ksacde: state.KSACDES
      ,mise: state.MISES
      // ,httymd:''
    })});
    const dataUA1 = await responseUA1.json();
    console.log('伝票ヘッダ:%o',dataUA1);

    // 伝票ヘッダ　更新エラー
    if (dataUA1.res == -1) {
      setAlert('伝票データ修正エラー');//TCGE0024：
      mvFlg = 1;
      return mvFlg;
    }

    console.log('伝票明細更新');

    // 伝票ボディ 更新処理
    const responseUA2 = await fetch('/TCSA0150/SL_DTL_DAT_upd',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      ent_ksacde : state.KSACDES  //入力元会社コード
      ,ent_mise : state.MISES     //入力元店コード
      ,densbt : '06'              //伝票種別
      ,denno : denpyoNoRef.current?.getValue()            //伝票番号
      ,ksacde : state.KSACDES     //会社コード
      ,mise :  state.MISES        //店コード
      ,edaban : '01'              //枝番号
      ,meisai:[
        {lineno:'1',shncde:shohinCode1Ref.current?.getValue(),suu:suuryo1Ref.current?.getValue().replaceAll(/,/g, ''),dwngiin:gennin1Ref.current?.getValue(),oldbak:oldBaika1Ref.current?.getValue().replaceAll(/,/g, ''),newbak:newBaika1Ref.current?.getValue().replaceAll(/,/g, ''),sagaku:Number(String(sagaku1Ref.current?.getValue()).replaceAll(/,/g, '')),bihngk:Number(String(baihenGaku1Ref.current?.getValue()).replaceAll(/,/g, '')),fgo:fugou1Ref.current?.getValue(),esj_kbn:itESJ_KBN1}
        ,{lineno:'2',shncde:shohinCode2Ref.current?.getValue(),suu:suuryo2Ref.current?.getValue().replaceAll(/,/g, ''),dwngiin:gennin2Ref.current?.getValue(),oldbak:oldBaika2Ref.current?.getValue().replaceAll(/,/g, ''),newbak:newBaika2Ref.current?.getValue().replaceAll(/,/g, ''),sagaku:Number(String(sagaku2Ref.current?.getValue()).replaceAll(/,/g, '')),bihngk:Number(String(baihenGaku2Ref.current?.getValue()).replaceAll(/,/g, '')),fgo:fugou2Ref.current?.getValue(),esj_kbn:itESJ_KBN2}
        ,{lineno:'3',shncde:shohinCode3Ref.current?.getValue(),suu:suuryo3Ref.current?.getValue().replaceAll(/,/g, ''),dwngiin:gennin3Ref.current?.getValue(),oldbak:oldBaika3Ref.current?.getValue().replaceAll(/,/g, ''),newbak:newBaika3Ref.current?.getValue().replaceAll(/,/g, ''),sagaku:Number(String(sagaku3Ref.current?.getValue()).replaceAll(/,/g, '')),bihngk:Number(String(baihenGaku3Ref.current?.getValue()).replaceAll(/,/g, '')),fgo:fugou3Ref.current?.getValue(),esj_kbn:itESJ_KBN3}
        ,{lineno:'4',shncde:shohinCode4Ref.current?.getValue(),suu:suuryo4Ref.current?.getValue().replaceAll(/,/g, ''),dwngiin:gennin4Ref.current?.getValue(),oldbak:oldBaika4Ref.current?.getValue().replaceAll(/,/g, ''),newbak:newBaika4Ref.current?.getValue().replaceAll(/,/g, ''),sagaku:Number(String(sagaku4Ref.current?.getValue()).replaceAll(/,/g, '')),bihngk:Number(String(baihenGaku4Ref.current?.getValue()).replaceAll(/,/g, '')),fgo:fugou4Ref.current?.getValue(),esj_kbn:itESJ_KBN4}
        ,{lineno:'5',shncde:shohinCode5Ref.current?.getValue(),suu:suuryo5Ref.current?.getValue().replaceAll(/,/g, ''),dwngiin:gennin5Ref.current?.getValue(),oldbak:oldBaika5Ref.current?.getValue().replaceAll(/,/g, ''),newbak:newBaika5Ref.current?.getValue().replaceAll(/,/g, ''),sagaku:Number(String(sagaku5Ref.current?.getValue()).replaceAll(/,/g, '')),bihngk:Number(String(baihenGaku5Ref.current?.getValue()).replaceAll(/,/g, '')),fgo:fugou5Ref.current?.getValue(),esj_kbn:itESJ_KBN5}
        ,{lineno:'6',shncde:shohinCode6Ref.current?.getValue(),suu:suuryo6Ref.current?.getValue().replaceAll(/,/g, ''),dwngiin:gennin6Ref.current?.getValue(),oldbak:oldBaika6Ref.current?.getValue().replaceAll(/,/g, ''),newbak:newBaika6Ref.current?.getValue().replaceAll(/,/g, ''),sagaku:Number(String(sagaku6Ref.current?.getValue()).replaceAll(/,/g, '')),bihngk:Number(String(baihenGaku6Ref.current?.getValue()).replaceAll(/,/g, '')),fgo:fugou6Ref.current?.getValue(),esj_kbn:itESJ_KBN6}
        ,{lineno:'7',shncde:shohinCode7Ref.current?.getValue(),suu:suuryo7Ref.current?.getValue().replaceAll(/,/g, ''),dwngiin:gennin7Ref.current?.getValue(),oldbak:oldBaika7Ref.current?.getValue().replaceAll(/,/g, ''),newbak:newBaika7Ref.current?.getValue().replaceAll(/,/g, ''),sagaku:Number(String(sagaku7Ref.current?.getValue()).replaceAll(/,/g, '')),bihngk:Number(String(baihenGaku7Ref.current?.getValue()).replaceAll(/,/g, '')),fgo:fugou7Ref.current?.getValue(),esj_kbn:itESJ_KBN7}
        ,{lineno:'8',shncde:shohinCode8Ref.current?.getValue(),suu:suuryo8Ref.current?.getValue().replaceAll(/,/g, ''),dwngiin:gennin8Ref.current?.getValue(),oldbak:oldBaika8Ref.current?.getValue().replaceAll(/,/g, ''),newbak:newBaika8Ref.current?.getValue().replaceAll(/,/g, ''),sagaku:Number(String(sagaku8Ref.current?.getValue()).replaceAll(/,/g, '')),bihngk:Number(String(baihenGaku8Ref.current?.getValue()).replaceAll(/,/g, '')),fgo:fugou8Ref.current?.getValue(),esj_kbn:itESJ_KBN8}
      ]
      // ,esj_kbn:itESJ_KBN
      ,add_jgncde : state.JGNCDE  //ユーザーID
      ,add_prg_id : 'TCSA0150'    //プログラムID
      ,trl_id :state.MISES        //端末ＩＤ★一旦店コード入れる
      ,delflg :'0'                //削除フラグ
    })});

    console.log(responseUA2.body.meisai);
    const dataUA2 = await responseUA2.json();
    console.log('伝票明細:%o',dataUA2.res);

    // 伝票ヘッダ　更新エラー
    if (dataUA2.res == -1) {
      setAlert('伝票データ修正エラー');//TCGE0024：
      // setItretclr('#f59d9d');
      //inputRef.current.focus();
      mvFlg = 1;
      return mvFlg;
    }

    setItrcnt(itRcnt+1);
    Gmclr();
    // Gmclrでmv制御する為、mvFlg立てる
    mvFlg = 9;


    return mvFlg;
  }

  // 確認 削除処理
  const onKeyDown_Delete = async()=>{
    // isInInputConfirm = true;
    // if (await InputConfirmed() !== 0) {
    //   isInInputConfirm = false;
    //   return 2;
    // }
    // isInInputConfirm = false;

    // window.alert('チェックok onKeyDown_Delete');
    // return 0;

    let mvFlg = 0;
    // 伝票ヘッダ 削除処理
    console.log('伝票ヘッダ削除');
    const responseUA1 = await fetch('/TCSA0150/SL_HED_DAT_del',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      ent_ksacde: state.KSACDES
      ,ent_mise: state.MISES
      ,densbt: '06'
      ,denno: denpyoNoRef.current?.getValue()
      ,ksacde: state.KSACDES
      ,mise: state.MISES
    })});
    const dataUA1 = await responseUA1.json();
    console.log('伝票ヘッダ:%o',dataUA1);

    // 伝票ボディ 削除エラー
    if (dataUA1.res == -1) {
      setAlert('伝票データ削除エラー');//TCGE0025：
      mvFlg = 1;
      return mvFlg;
    }

    console.log('伝票明細削除');

    // 伝票ヘッダ 削除処理
    const responseUA2 = await fetch('/TCSA0150/SL_DTL_DAT_del',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      ent_ksacde : state.KSACDES  //入力元会社コード
      ,ent_mise : state.MISES     //入力元店コード
      ,densbt : '06'              //伝票種別
      ,denno : denpyoNoRef.current?.getValue()            //伝票番号
      ,ksacde : state.KSACDES     //会社コード
      ,mise :  state.MISES        //店コード
      ,edaban : '01'              //枝番号
      })});
    const dataUA2 = await responseUA2.json();
    console.log('伝票明細:%o',dataUA2.res);

    // 伝票ボディ 削除エラー
    if (dataUA2.res == -1){
      setAlert('伝票データ削除エラー');//TCGE0025：
      // setItretclr('#f59d9d');
      // inRET.current.focus();
      // inputRef.current.focus();
      mvFlg = 1;
      return mvFlg;
    }

    Gmclr();
    //こちらでmv制御する為、mvFlg立てる
    mvFlg = 9;

    return mvFlg;
  }
  //#endregion

  const hasErr_at_BAK=(DWNGIIN,OLDBAK,NEWBAK)=>{      // check the error about OLDBAK and NEWBAK
    console.log('旧売価/新売価比較開始');

    if (DWNGIIN >= 1 && DWNGIIN <= 6)
    {
      //旧売価≦新売価の場合エラー
      // if (Number(OLDBAK1) <= Number(NEWBAK1))
      if (OLDBAK <= NEWBAK)
      {
        console.log('OLDBAK<=NEWBAK');
        setAlert('売価入力エラー');//TCGE0041：
        return 2;
      }else{
        //差額、売変額、符号計算
        console.log('差額、売変額、符号計算1開始');
        return 0;
      }
    }
    //else if((DWNGIIN == 8) || (DWNGIIN == 9)||(DWNGIIN == 7 && state.MISE!=='200' && state.K_FLG!=='1'))
    else if(DWNGIIN == 8 || DWNGIIN == 9 || DWNGIIN == 7)
    {
      //旧売価≧新売価の場合エラー
      if (OLDBAK >= NEWBAK)
      {
        console.log('OLDBAK>=NEWBAK');
        setAlert('売価入力エラー');//TCGE0041：
        return 2;
      }else{
        //差額、売変額、符号計算
        console.log('差額、売変額、符号計算2開始');
        return 0;
      }
    }else{
      //今は何もしない。
      console.log('原因区分０が入力されました');
      return 0;
    }
  }

  // const Extr_Row = (str) => {           // Extract row number
  //   let rownum = str.substring(str.length-1,str.length);
  //   return rownum;
  // }

  const has_row_error = (SHNCDE,DWNGIIN,SUU,mvflag,rowNum) => {
    switch(rowNum){
      case 1:
        if(SHNCDE == ''){
          shohinCode1Ref.current?.setBGColor('#f59d9d');
          inSHNCDE1.current.focus();
          return 1;
        }
        if(DWNGIIN == ''){
          gennin1Ref.current?.setBGColor('#f59d9d');
          inDWNGIIN1.current.focus();
          return 1;
        }
        if(SUU == ''){
          suuryo1Ref.current?.setBGColor('#f59d9d');
          inSUU1.current.focus();
          return 1;
        }
        if(mvflag !== 0){
          oldBaika1Ref.current?.setBGColor('#f59d9d');
          newBaika1Ref.current?.setBGColor('#f59d9d');
          inOLDBAK1.current.focus();
          sagaku1Ref.current?.setValue('');
          baihenGaku1Ref.current?.setValue('');
          fugou1Ref.current?.setValue('');
          return 1;
        }
        return 0;
      break;
      case 2:
        if(SHNCDE == ''){
          shohinCode2Ref.current?.setBGColor('#f59d9d');
          inSHNCDE2.current.focus();
          return 1;
        }
        if(DWNGIIN == ''){
          gennin2Ref.current?.setBGColor('#f59d9d');
          inDWNGIIN2.current.focus();
          return 1;
        }
        if(SUU == ''){
          suuryo2Ref.current?.setBGColor('#f59d9d');
          inSUU2.current.focus();
          return 1;
        }
        if(mvflag !== 0){
          oldBaika2Ref.current?.setBGColor('#f59d9d');
          newBaika2Ref.current?.setBGColor('#f59d9d');
          inOLDBAK2.current.focus();
          sagaku2Ref.current?.setValue('');
          baihenGaku2Ref.current?.setValue('');
          fugou2Ref.current?.setValue('');
          return 1;
        }
        return 0;
      break;
      case 3:
        if(SHNCDE == ''){
          shohinCode3Ref.current?.setBGColor('#f59d9d');
          inSHNCDE3.current.focus();
          return 1;
        }
        if(DWNGIIN == ''){
          gennin3Ref.current?.setBGColor('#f59d9d');
          inDWNGIIN3.current.focus();
          return 1;
        }
        if(SUU == ''){
          suuryo3Ref.current?.setBGColor('#f59d9d');
          inSUU3.current.focus();
          return 1;
        }
        if(mvflag !== 0){
          oldBaika3Ref.current?.setBGColor('#f59d9d');
          newBaika3Ref.current?.setBGColor('#f59d9d');
          inOLDBAK3.current.focus();
          sagaku3Ref.current?.setValue('');
          baihenGaku3Ref.current?.setValue('');
          fugou3Ref.current?.setValue('');
          return 1;
        }
        return 0;
      break;
      case 4:
        if(SHNCDE == ''){
          shohinCode4Ref.current?.setBGColor('#f59d9d');
          inSHNCDE4.current.focus();
          return 1;
        }
        if(DWNGIIN == ''){
          gennin4Ref.current?.setBGColor('#f59d9d');
          inDWNGIIN4.current.focus();
          return 1;
        }
        if(SUU == ''){
          suuryo4Ref.current?.setBGColor('#f59d9d');
          inSUU4.current.focus();
          return 1;
        }
        if(mvflag !== 0){
          oldBaika4Ref.current?.setBGColor('#f59d9d');
          newBaika4Ref.current?.setBGColor('#f59d9d');
          inOLDBAK4.current.focus();
          sagaku4Ref.current?.setValue('');
          baihenGaku4Ref.current?.setValue('');
          fugou4Ref.current?.setValue('');
          return 1;
        }
        return 0;
      break;
      case 5:
        if(SHNCDE == ''){
          shohinCode5Ref.current?.setBGColor('#f59d9d');
          inSHNCDE5.current.focus();
          return 1;
        }
        if(DWNGIIN == ''){
          gennin5Ref.current?.setBGColor('#f59d9d');
          inDWNGIIN5.current.focus();
          return 1;
        }
        if(SUU == ''){
          suuryo5Ref.current?.setBGColor('#f59d9d');
          inSUU5.current.focus();
          return 1;
        }
        if(mvflag !== 0){
          oldBaika5Ref.current?.setBGColor('#f59d9d');
          newBaika5Ref.current?.setBGColor('#f59d9d');
          inOLDBAK5.current.focus();
          sagaku5Ref.current?.setValue('');
          baihenGaku5Ref.current?.setValue('');
          fugou5Ref.current?.setValue('');
          return 1;
        }
        return 0;
      break;
      case 6:
        if(SHNCDE == ''){
          shohinCode6Ref.current?.setBGColor('#f59d9d');
          inSHNCDE6.current.focus();
          return 1;
        }
        if(DWNGIIN == ''){
          gennin6Ref.current?.setBGColor('#f59d9d');
          inDWNGIIN6.current.focus();
          return 1;
        }
        if(SUU == ''){
          suuryo6Ref.current?.setBGColor('#f59d9d');
          inSUU6.current.focus();
          return 1;
        }
        if(mvflag !== 0){
          oldBaika6Ref.current?.setBGColor('#f59d9d');
          newBaika6Ref.current?.setBGColor('#f59d9d');
          inOLDBAK6.current.focus();
          sagaku6Ref.current?.setValue('');
          baihenGaku6Ref.current?.setValue('');
          fugou6Ref.current?.setValue('');
          return 1;
        }
        return 0;
      break;
      case 7:
        if(SHNCDE == ''){
          shohinCode7Ref.current?.setBGColor('#f59d9d');
          inSHNCDE7.current.focus();
          return 1;
        }
        if(DWNGIIN == ''){
          gennin7Ref.current?.setBGColor('#f59d9d');
          inDWNGIIN7.current.focus();
          return 1;
        }
        if(SUU == ''){
          suuryo7Ref.current?.setBGColor('#f59d9d');
          inSUU7.current.focus();
          return 1;
        }
        if(mvflag !== 0){
          oldBaika7Ref.current?.setBGColor('#f59d9d');
          newBaika7Ref.current?.setBGColor('#f59d9d');
          inOLDBAK7.current.focus();
          sagaku7Ref.current?.setValue('');
          baihenGaku7Ref.current?.setValue('');
          fugou7Ref.current?.setValue('');
          return 1;
        }
        return 0;
      break;
      case 8:
        if(SHNCDE == ''){
          shohinCode8Ref.current?.setBGColor('#f59d9d');
          inSHNCDE8.current.focus();
          return 1;
        }
        if(DWNGIIN == ''){
          gennin8Ref.current?.setBGColor('#f59d9d');
          inDWNGIIN8.current.focus();
          return 1;
        }
        if(SUU == ''){
          suuryo8Ref.current?.setBGColor('#f59d9d');
          inSUU8.current.focus();
          return 1;
        }
        if(mvflag !== 0){
          oldBaika8Ref.current?.setBGColor('#f59d9d');
          newBaika8Ref.current?.setBGColor('#f59d9d');
          inOLDBAK8.current.focus();
          sagaku8Ref.current?.setValue('');
          baihenGaku8Ref.current?.setValue('');
          fugou8Ref.current?.setValue('');
          return 1;
        }
        return 0;
      break;
      default:
        return 0;
      break;
    }
  }

  //画面クリア
  const Gmclr = () =>{
    // ヘッダ部
    // setItKBN('');                          // 入力値格納用（区分）
    // setItKBNNM('');                      // タイトル部表示用（区分）
    denpyoNoRef.current?.setValue('');                      // 入力値格納用（伝票No.）
    miseCodeRef.current?.setValue('');                        // 入力値格納用（店CD）
    bunruiCodeRef.current?.setValue('');                    // 入力値格納用（分類CD）
    denpyoYMDRef.current?.setValue('');                    // 入力値格納用（売変実施日）

    // 明細部
    shohinCode1Ref.current?.setValue('');                  // 入力値格納用（商品コード1）
    gennin1Ref.current?.setValue('');                // 入力値格納用（原因1）
    suuryo1Ref.current?.setValue('');                        // 入力値格納用（数量1）
    oldBaika1Ref.current?.setValue('');                  // 入力値格納用（旧売価1）
    newBaika1Ref.current?.setValue('');                  // 入力値格納用（新売価1）
    sagaku1Ref.current?.setValue('');                  // 入力値格納用（差　額1）
    baihenGaku1Ref.current?.setValue('');                  // 入力値格納用（売変額1）
    fugou1Ref.current?.setValue('');                        // 入力値格納用（符号1）
    shohinCode2Ref.current?.setValue('');                  // 入力値格納用（商品コード2）
    gennin2Ref.current?.setValue('');                // 入力値格納用（原因2）
    suuryo2Ref.current?.setValue('');                        // 入力値格納用（数量2）
    oldBaika2Ref.current?.setValue('');                  // 入力値格納用（旧売価2）
    newBaika2Ref.current?.setValue('');                  // 入力値格納用（新売価2）
    sagaku2Ref.current?.setValue('');                  // 入力値格納用（差　額2）
    baihenGaku2Ref.current?.setValue('');                  // 入力値格納用（売変額2）
    fugou2Ref.current?.setValue('');                        // 入力値格納用（符号2）
    shohinCode3Ref.current?.setValue('');                  // 入力値格納用（商品コード3）
    gennin3Ref.current?.setValue('');                // 入力値格納用（原因3）
    suuryo3Ref.current?.setValue('');                        // 入力値格納用（数量3）
    oldBaika3Ref.current?.setValue('');                  // 入力値格納用（旧売価3）
    newBaika3Ref.current?.setValue('');                  // 入力値格納用（新売価3）
    sagaku3Ref.current?.setValue('');                  // 入力値格納用（差　額3）
    baihenGaku3Ref.current?.setValue('');                  // 入力値格納用（売変額3）
    fugou3Ref.current?.setValue('');                        // 入力値格納用（符号3）
    shohinCode4Ref.current?.setValue('');                  // 入力値格納用（商品コード4）
    gennin4Ref.current?.setValue('');                // 入力値格納用（原因4）
    suuryo4Ref.current?.setValue('');                        // 入力値格納用（数量4）
    oldBaika4Ref.current?.setValue('');                  // 入力値格納用（旧売価4）
    newBaika4Ref.current?.setValue('');                  // 入力値格納用（新売価4）
    sagaku4Ref.current?.setValue('');                  // 入力値格納用（差　額4）
    baihenGaku4Ref.current?.setValue('');                  // 入力値格納用（売変額4）
    fugou4Ref.current?.setValue('');                        // 入力値格納用（符号4）
    shohinCode5Ref.current?.setValue('');                  // 入力値格納用（商品コード5）
    gennin5Ref.current?.setValue('');                // 入力値格納用（原因5）
    suuryo5Ref.current?.setValue('');                        // 入力値格納用（数量5）
    oldBaika5Ref.current?.setValue('');                  // 入力値格納用（旧売価5）
    newBaika5Ref.current?.setValue('');                  // 入力値格納用（新売価5）
    sagaku5Ref.current?.setValue('');                  // 入力値格納用（差　額5）
    baihenGaku5Ref.current?.setValue('');                  // 入力値格納用（売変額5）
    fugou5Ref.current?.setValue('');                        // 入力値格納用（符号5）
    shohinCode6Ref.current?.setValue('');                  // 入力値格納用（商品コード6）
    gennin6Ref.current?.setValue('');                // 入力値格納用（原因6）
    suuryo6Ref.current?.setValue('');                        // 入力値格納用（数量6）
    oldBaika6Ref.current?.setValue('');                  // 入力値格納用（旧売価6）
    newBaika6Ref.current?.setValue('');                  // 入力値格納用（新売価6）
    sagaku6Ref.current?.setValue('');                  // 入力値格納用（差　額6）
    baihenGaku6Ref.current?.setValue('');                  // 入力値格納用（売変額6）
    fugou6Ref.current?.setValue('');                        // 入力値格納用（符号6）
    shohinCode7Ref.current?.setValue('');                  // 入力値格納用（商品コード7）
    gennin7Ref.current?.setValue('');                // 入力値格納用（原因7）
    suuryo7Ref.current?.setValue('');                        // 入力値格納用（数量7）
    oldBaika7Ref.current?.setValue('');                  // 入力値格納用（旧売価7）
    newBaika7Ref.current?.setValue('');                  // 入力値格納用（新売価7）
    sagaku7Ref.current?.setValue('');                  // 入力値格納用（差　額7）
    baihenGaku7Ref.current?.setValue('');                  // 入力値格納用（売変額7）
    fugou7Ref.current?.setValue('');                        // 入力値格納用（符号7）
    shohinCode8Ref.current?.setValue('');                  // 入力値格納用（商品コード8）
    gennin8Ref.current?.setValue('');                // 入力値格納用（原因8）
    suuryo8Ref.current?.setValue('');                        // 入力値格納用（数量8）
    oldBaika8Ref.current?.setValue('');                  // 入力値格納用（旧売価8）
    newBaika8Ref.current?.setValue('');                  // 入力値格納用（新売価8）
    sagaku8Ref.current?.setValue('');                  // 入力値格納用（差　額8）
    baihenGaku8Ref.current?.setValue('');                  // 入力値格納用（売変額8）
    fugou8Ref.current?.setValue('');                        // 入力値格納用（符号8）

    // フッタ部
    suuryoGoukeiRef.current?.setValue('');                        // 入力値格納用（数量合計）
    oldBaikaGoukeiRef.current?.setValue('');                  // 入力値格納用（旧売価合計）
    newBaikaGoukeiRef.current?.setValue('');                  // 入力値格納用（新売価合計）
    baihenGakuGoukeiRef.current?.setValue('');                  // 入力値格納用（売変額合計）

    // ベリファイ（２回入力）用
    setItDENNO01('');                  //伝票No.１回目メモリ
    setItBMNCDE01('');                 //分類CD用１回目メモリ

    // 計上年月表示用
    keijyoYMRef.current?.setValue('');                    //YYMM形式　計上年月
    setItkijyym2('');                  //YYMM形式　計上年月

    //メモリ保存用
    setItesj_kbn1('');    //伝票明細：ＥＯＳ／ＪＡＮ区分1
    setItesj_kbn2('');    //伝票明細：ＥＯＳ／ＪＡＮ区分2
    setItesj_kbn3('');    //伝票明細：ＥＯＳ／ＪＡＮ区分3
    setItesj_kbn4('');    //伝票明細：ＥＯＳ／ＪＡＮ区分4
    setItesj_kbn5('');    //伝票明細：ＥＯＳ／ＪＡＮ区分5
    setItesj_kbn6('');    //伝票明細：ＥＯＳ／ＪＡＮ区分6
    setItesj_kbn7('');    //伝票明細：ＥＯＳ／ＪＡＮ区分7
    setItesj_kbn8('');    //伝票明細：ＥＯＳ／ＪＡＮ区分8

    setAlert('　')

    // ヘッダ部
    kubunRef.current?.setBGColor('#FFFFFF');             // 区分
    denpyoNoRef.current?.setBGColor('#FFFFFF');         // 伝票No.
    miseCodeRef.current?.setBGColor('#FFFFFF');           // 店CD
    bunruiCodeRef.current?.setBGColor('#FFFFFF');       // 分類CD
    denpyoYMDRef.current?.setBGColor('#FFFFFF');       // 売変実施日
    // 明細部
    shohinCode1Ref.current?.setBGColor('#FFFFFF');     // 商品コード1
    gennin1Ref.current?.setBGColor('#FFFFFF');   // 原因1
    suuryo1Ref.current?.setBGColor('#FFFFFF');           // 数量1
    oldBaika1Ref.current?.setBGColor('#FFFFFF');     // 旧売価1
    newBaika1Ref.current?.setBGColor('#FFFFFF');     // 新売価1
    sagaku1Ref.current?.setBGColor('#FFFFFF');     // 差　額1
    baihenGaku1Ref.current?.setBGColor('#FFFFFF');     // 売変額1
    fugou1Ref.current?.setBGColor('#FFFFFF');           // 符号1
    shohinCode2Ref.current?.setBGColor('#FFFFFF');     // 商品コード2
    gennin2Ref.current?.setBGColor('#FFFFFF');   // 原因2
    suuryo2Ref.current?.setBGColor('#FFFFFF');           // 数量2
    oldBaika2Ref.current?.setBGColor('#FFFFFF');     // 旧売価2
    newBaika2Ref.current?.setBGColor('#FFFFFF');     // 新売価2
    sagaku2Ref.current?.setBGColor('#FFFFFF');     // 差　額2
    baihenGaku2Ref.current?.setBGColor('#FFFFFF');     // 売変額2
    fugou2Ref.current?.setBGColor('#FFFFFF');           // 符号2
    shohinCode3Ref.current?.setBGColor('#FFFFFF');     // 商品コード3
    gennin3Ref.current?.setBGColor('#FFFFFF');   // 原因3
    suuryo3Ref.current?.setBGColor('#FFFFFF');           // 数量3
    oldBaika3Ref.current?.setBGColor('#FFFFFF');     // 旧売価3
    newBaika3Ref.current?.setBGColor('#FFFFFF');     // 新売価3
    sagaku3Ref.current?.setBGColor('#FFFFFF');     // 差　額3
    baihenGaku3Ref.current?.setBGColor('#FFFFFF');     // 売変額3
    fugou3Ref.current?.setBGColor('#FFFFFF');           // 符号3
    shohinCode4Ref.current?.setBGColor('#FFFFFF');     // 商品コード4
    gennin4Ref.current?.setBGColor('#FFFFFF');   // 原因4
    suuryo4Ref.current?.setBGColor('#FFFFFF');           // 数量4
    oldBaika4Ref.current?.setBGColor('#FFFFFF');     // 旧売価4
    newBaika4Ref.current?.setBGColor('#FFFFFF');     // 新売価4
    sagaku4Ref.current?.setBGColor('#FFFFFF');     // 差　額4
    baihenGaku4Ref.current?.setBGColor('#FFFFFF');     // 売変額4
    fugou4Ref.current?.setBGColor('#FFFFFF');           // 符号4
    shohinCode5Ref.current?.setBGColor('#FFFFFF');     // 商品コード5
    gennin5Ref.current?.setBGColor('#FFFFFF');   // 原因5
    suuryo5Ref.current?.setBGColor('#FFFFFF');           // 数量5
    oldBaika5Ref.current?.setBGColor('#FFFFFF');     // 旧売価5
    newBaika5Ref.current?.setBGColor('#FFFFFF');     // 新売価5
    sagaku5Ref.current?.setBGColor('#FFFFFF');     // 差　額5
    baihenGaku5Ref.current?.setBGColor('#FFFFFF');     // 売変額5
    fugou5Ref.current?.setBGColor('#FFFFFF');           // 符号5
    shohinCode6Ref.current?.setBGColor('#FFFFFF');     // 商品コード6
    gennin6Ref.current?.setBGColor('#FFFFFF');   // 原因6
    suuryo6Ref.current?.setBGColor('#FFFFFF');           // 数量6
    oldBaika6Ref.current?.setBGColor('#FFFFFF');     // 旧売価6
    newBaika6Ref.current?.setBGColor('#FFFFFF');     // 新売価6
    sagaku6Ref.current?.setBGColor('#FFFFFF');     // 差　額6
    baihenGaku6Ref.current?.setBGColor('#FFFFFF');     // 売変額6
    fugou6Ref.current?.setBGColor('#FFFFFF');           // 符号6
    shohinCode7Ref.current?.setBGColor('#FFFFFF');     // 商品コード7
    gennin7Ref.current?.setBGColor('#FFFFFF');   // 原因7
    suuryo7Ref.current?.setBGColor('#FFFFFF');           // 数量7
    oldBaika7Ref.current?.setBGColor('#FFFFFF');     // 旧売価7
    newBaika7Ref.current?.setBGColor('#FFFFFF');     // 新売価7
    sagaku7Ref.current?.setBGColor('#FFFFFF');     // 差　額7
    baihenGaku7Ref.current?.setBGColor('#FFFFFF');     // 売変額7
    fugou7Ref.current?.setBGColor('#FFFFFF');           // 符号7
    shohinCode8Ref.current?.setBGColor('#FFFFFF');     // 商品コード8
    gennin8Ref.current?.setBGColor('#FFFFFF');   // 原因8
    suuryo8Ref.current?.setBGColor('#FFFFFF');           // 数量8
    oldBaika8Ref.current?.setBGColor('#FFFFFF');     // 旧売価8
    newBaika8Ref.current?.setBGColor('#FFFFFF');     // 新売価8
    sagaku8Ref.current?.setBGColor('#FFFFFF');     // 差　額8
    baihenGaku8Ref.current?.setBGColor('#FFFFFF');     // 売変額8
    fugou8Ref.current?.setBGColor('#FFFFFF');           // 符号8
    // フッタ部
    suuryoGoukeiRef.current?.setBGColor('#FFFFFF');           // 数量合計
    oldBaikaGoukeiRef.current?.setBGColor('#FFFFFF');     // 旧売価合計
    newBaikaGoukeiRef.current?.setBGColor('#FFFFFF');     // 新売価合計
    baihenGakuGoukeiRef.current?.setBGColor('#FFFFFF');     // 売変額合計

    // 変更フラグ
    denpyoNoRef.current?.setCheckflg(0);         // 伝票No.
    shohinCode1Ref.current?.setCheckflg(0);       // 商品コード1
    shohinCode2Ref.current?.setCheckflg(0);       // 商品コード2
    shohinCode3Ref.current?.setCheckflg(0);       // 商品コード3
    shohinCode4Ref.current?.setCheckflg(0);       // 商品コード4
    shohinCode5Ref.current?.setCheckflg(0);       // 商品コード5
    shohinCode6Ref.current?.setCheckflg(0);       // 商品コード6
    shohinCode7Ref.current?.setCheckflg(0);       // 商品コード7
    shohinCode8Ref.current?.setCheckflg(0);       // 商品コード8

    inDENNO.current.focus();//クリアしたら伝票番号に戻す

  }

  //#region スタイル
  // 確認のWidthを設定する
  const cssInputOutlineRetSetwidth = (obj) => {
    let resObj = obj;
    resObj.width = '50px';
    return resObj;
  }

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
        fontSize: '1.6rem',             // フォントサイズ
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },
    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
  }
  //#endregion

  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "650px",
          width: "820px",
          m: "10px auto",
          padding:"10px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="left"
        >
          <div style={{display:"flex"}}>
            <Typography variant={"h6"} sx={{ m: "10px" }}>
              売変伝票{ttl}
            </Typography>
            <Box width={'60%'} display={'flex'} justifyContent={'flex-end'}>
              <Typography variant={"h5"} sx={{ m: "15px",mt:"20px",fontSize:15 }}>
              ［{itKBNNM}］
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px",fontSize:20 }}>
              ({itRcnt.toLocaleString()+'件'}){/* {state.JGNMEIKNJ} */}
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"20px",fontSize:15 }}>
                {state.MISEMEI}：{state.JGNMEIKNJ}
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"20px",fontSize:15 }}>
                { sdate }
              </Typography>
            </Box>
          </div>
        </Grid>

        <Stack direction="row" sx={{mt:'-15px'}}>
          {/* 区分 */}
          <CKubun
            ref = {kubunRef}
            onKeyDown = {onKeyDown}
            inputRef = {inKBN}
            inputRtRef = {inKBN}
            inputNxRef = {inDENNO}
          />
          {/* 伝票No. */}
          <CDenpyoNo
            ref = {denpyoNoRef}
            onKeyDown = {onKeyDown}
            inputRef = {inDENNO}
            inputRtRef = {inKBN}
            inputNxRef = {inBMNCDE}
          />

          <InputLabel variant={'outlined'} >　　　　　　　　　　　　　　　　　　　　　　　　　</InputLabel>
          {/* 計上年月. */}
          <CKeijyoYM
            ref = {keijyoYMRef}
            inputRef = {inKIJYYM}
          />
        </Stack>
        <br />
        <Stack direction="row" sx={{mt:'-15px'}}>
          {/* 店CD */}
          <CMiseCode
            ref = {miseCodeRef}
            onKeyDown = {onKeyDown}
            inputRef = {inMISE}
            inputRtRef = {inDENNO}
            inputNxRef = {inBMNCDE}
          />

          {/* 分類CD */}
          <CBunruiCode
            ref = {bunruiCodeRef}
            onKeyDown = {onKeyDown}
            inputRef = {inBMNCDE}
            inputRtRef = {inDENNO}
            inputNxRef = {inDENYMD}
          />
          &emsp;
          {/* 売変実施日 */}
          <CDenpyoYMD
            ref = {denpyoYMDRef}
            onKeyDown = {onKeyDown}
            inputRef = {inDENYMD}
            inputRtRef = {inBMNCDE}
            inputNxRef = {inSHNCDE1}
          />
        </Stack>
        <br />
        {/* テーブル表示処理 */}
        {/* <TableContainer component={Paper} sx={{maxWidth:800,mt:'-15px'}}> */}
        <TableContainer
          component={Paper}
          sx={{maxWidth:800,mt:'-15px'}}
        >
          <Table sx={{border:0}} aria-label="spanning table" size="small"
            onBlur={(e) => {
              // setBlurTableflg(false);
              // setTimeout(() => setBlurTableflg(true),100);
              if (e.relatedTarget?.id != null && e.relatedTarget?.id != undefined)  //2023/11/13　明細内のカーソル移動だけで再レンダリングしないよう対応
              {
                let type = getInputType(e.relatedTarget.id);
                if(type !== 2){
                  if (blurTableflg === true)
                  {
                    setBlurTableflg(false);
                  }else{
                    setBlurTableflg(true);
                  }
                }
              }
            }}
          >
            <TableHead> {/* テーブル表示処理ヘッダ部 */}
              <TableRow>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="150px" align="center" bgcolor="lightgray">商品コード</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="40px" align="center"  bgcolor="lightgray">原因</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="40px" align="center"  bgcolor="lightgray">数量</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="40px" align="center"  bgcolor="lightgray">旧売価</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="40px" align="center"  bgcolor="lightgray">新売価</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="50px" align="center"  bgcolor="lightgray">差　額</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="50px" align="center"  bgcolor="lightgray">売変額</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="50px" align="center"  bgcolor="lightgray">符号</TableCell>
              </TableRow>
            </TableHead>
          <TableBody>   {/* テーブル表示処理明細部 */}
            <TableRow>
              {/* 商品コード1 */}
              <CShohinCode
                ref = {shohinCode1Ref}
                id = {'SHNCDE1'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inSHNCDE1}
                inputNxRef = {inDWNGIIN1}
                inUpRef = {inSHNCDE1}
                inRgRef = {inDWNGIIN1}
                inDnRef = {inSHNCDE2}
                inLfRef = {inSHNCDE1}
                dismeisai = {dismeisai}
              />

              {/* 原因1 */}
              <CGennin
                ref = {gennin1Ref}
                id = {'DWNGIIN1'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inDWNGIIN1}
                inputNxRef = {inSUU1}
                inUpRef = {inDWNGIIN1}
                inRgRef = {inSUU1}
                inDnRef = {inDWNGIIN2}
                inLfRef = {inSHNCDE1}
                dismeisai = {dismeisai}
              />

              {/* 数量1 */}
              <CSuuryo
                ref = {suuryo1Ref}
                id = {'SUU1'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inSUU1}
                inputNxRef = {inOLDBAK1}
                inUpRef = {inSUU1}
                inRgRef = {inOLDBAK1}
                inDnRef = {inSUU2}
                inLfRef = {inDWNGIIN1}
                dismeisai = {dismeisai}
              />

              {/* 旧売価1 */}
              <COldBaika
                ref = {oldBaika1Ref}
                id = {'OLDBAK1'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inOLDBAK1}
                inputNxRef = {inNEWBAK1}
                inUpRef = {inOLDBAK1}
                inRgRef = {inNEWBAK1}
                inDnRef = {inOLDBAK2}
                inLfRef = {inSUU1}
                dismeisai = {dismeisai}
              />

              {/* 新売価1 */}
              <CNewBaika
                ref = {newBaika1Ref}
                id = {'NEWBAK1'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inNEWBAK1}
                inputNxRef = {inSHNCDE2}
                inUpRef = {inNEWBAK1}
                inRgRef = {inNEWBAK1}
                inDnRef = {inNEWBAK2}
                inLfRef = {inOLDBAK1}
                dismeisai = {dismeisai}
              />

              {/* 差　額1 */}
              <CSagaku
                ref = {sagaku1Ref}
                id = {'SAGAKU1'}
                inputRef = {inSAGAKU1}
              />

              {/* 売変額1 */}
              <CBaihenGaku
                ref = {baihenGaku1Ref}
                id = {'BIHNGK1'}
                inputRef = {inBIHNGK1}
              />

              {/* 符号1 */}
              <CFugou
                ref = {fugou1Ref}
                id = {'FGO1'}
                inputRef = {inFGO1}
              />

            </TableRow>
            <TableRow>
              {/* 商品コード2 */}
              <CShohinCode
                ref = {shohinCode2Ref}
                id = {'SHNCDE2'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inSHNCDE2}
                inputNxRef = {inDWNGIIN2}
                inUpRef = {inSHNCDE1}
                inRgRef = {inDWNGIIN2}
                inDnRef = {inSHNCDE3}
                inLfRef = {inSHNCDE2}
                dismeisai = {dismeisai}
              />

              {/* 原因2 */}
              <CGennin
                ref = {gennin2Ref}
                id = {'DWNGIIN2'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inDWNGIIN2}
                inputNxRef = {inSUU2}
                inUpRef = {inDWNGIIN1}
                inRgRef = {inSUU2}
                inDnRef = {inDWNGIIN3}
                inLfRef = {inSHNCDE2}
                dismeisai = {dismeisai}
              />

              {/* 数量2 */}
              <CSuuryo
                ref = {suuryo2Ref}
                id = {'SUU2'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inSUU2}
                inputNxRef = {inOLDBAK2}
                inUpRef = {inSUU1}
                inRgRef = {inOLDBAK2}
                inDnRef = {inSUU3}
                inLfRef = {inDWNGIIN2}
                dismeisai = {dismeisai}
              />

              {/* 旧売価2 */}
              <COldBaika
                ref = {oldBaika2Ref}
                id = {'OLDBAK2'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inOLDBAK2}
                inputNxRef = {inNEWBAK2}
                inUpRef = {inOLDBAK1}
                inRgRef = {inNEWBAK2}
                inDnRef = {inOLDBAK3}
                inLfRef = {inSUU2}
                dismeisai = {dismeisai}
              />

              {/* 新売価2 */}
              <CNewBaika
                ref = {newBaika2Ref}
                id = {'NEWBAK2'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inNEWBAK2}
                inputNxRef = {inSHNCDE3}
                inUpRef = {inNEWBAK1}
                inRgRef = {inNEWBAK2}
                inDnRef = {inNEWBAK3}
                inLfRef = {inOLDBAK2}
                dismeisai = {dismeisai}
              />

              {/* 差　額2 */}
              <CSagaku
                ref = {sagaku2Ref}
                id = {'SAGAKU2'}
                inputRef = {inSAGAKU2}
              />

              {/* 売変額2 */}
              <CBaihenGaku
                ref = {baihenGaku2Ref}
                id = {'BIHNGK2'}
                inputRef = {inBIHNGK2}
              />

              {/* 符号2 */}
              <CFugou
                ref = {fugou2Ref}
                id = {'FGO2'}
                inputRef = {inFGO2}
              />
            </TableRow>
            <TableRow>
              {/* 商品コード3 */}
              <CShohinCode
                ref = {shohinCode3Ref}
                id = {'SHNCDE3'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inSHNCDE3}
                inputNxRef = {inDWNGIIN3}
                inUpRef = {inSHNCDE2}
                inRgRef = {inDWNGIIN3}
                inDnRef = {inSHNCDE4}
                inLfRef = {inSHNCDE3}
                dismeisai = {dismeisai}
              />

              {/* 原因3 */}
              <CGennin
                ref = {gennin3Ref}
                id = {'DWNGIIN3'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inDWNGIIN3}
                inputNxRef = {inSUU3}
                inUpRef = {inDWNGIIN2}
                inRgRef = {inSUU3}
                inDnRef = {inDWNGIIN4}
                inLfRef = {inSHNCDE3}
                dismeisai = {dismeisai}
              />

              {/* 数量3 */}
              <CSuuryo
                ref = {suuryo3Ref}
                id = {'SUU3'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inSUU3}
                inputNxRef = {inOLDBAK3}
                inUpRef = {inSUU2}
                inRgRef = {inOLDBAK3}
                inDnRef = {inSUU4}
                inLfRef = {inDWNGIIN3}
                dismeisai = {dismeisai}
              />

              {/* 旧売価3 */}
              <COldBaika
                ref = {oldBaika3Ref}
                id = {'OLDBAK3'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inOLDBAK3}
                inputNxRef = {inNEWBAK3}
                inUpRef = {inOLDBAK2}
                inRgRef = {inNEWBAK3}
                inDnRef = {inOLDBAK4}
                inLfRef = {inSUU3}
                dismeisai = {dismeisai}
              />

              {/* 新売価3 */}
              <CNewBaika
                ref = {newBaika3Ref}
                id = {'NEWBAK3'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inNEWBAK3}
                inputNxRef = {inSHNCDE4}
                inUpRef = {inNEWBAK2}
                inRgRef = {inNEWBAK3}
                inDnRef = {inNEWBAK4}
                inLfRef = {inOLDBAK3}
                dismeisai = {dismeisai}
              />

              {/* 差　額3 */}
              <CSagaku
                ref = {sagaku3Ref}
                id = {'SAGAKU3'}
                inputRef = {inSAGAKU3}
              />

              {/* 売変額3 */}
              <CBaihenGaku
                ref = {baihenGaku3Ref}
                id = {'BIHNGK3'}
                inputRef = {inBIHNGK3}
              />

              {/* 符号3 */}
              <CFugou
                ref = {fugou3Ref}
                id = {'FGO3'}
                inputRef = {inFGO3}
              />
            </TableRow>
            <TableRow>
              {/* 商品コード4 */}
              <CShohinCode
                ref = {shohinCode4Ref}
                id = {'SHNCDE4'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inSHNCDE4}
                inputNxRef = {inDWNGIIN4}
                inUpRef = {inSHNCDE3}
                inRgRef = {inDWNGIIN4}
                inDnRef = {inSHNCDE5}
                inLfRef = {inSHNCDE4}
                dismeisai = {dismeisai}
              />

              {/* 原因4 */}
              <CGennin
                ref = {gennin4Ref}
                id = {'DWNGIIN4'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inDWNGIIN4}
                inputNxRef = {inSUU4}
                inUpRef = {inDWNGIIN3}
                inRgRef = {inSUU4}
                inDnRef = {inDWNGIIN5}
                inLfRef = {inSHNCDE4}
                dismeisai = {dismeisai}
              />

              {/* 数量4 */}
              <CSuuryo
                ref = {suuryo4Ref}
                id = {'SUU4'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inSUU4}
                inputNxRef = {inOLDBAK4}
                inUpRef = {inSUU3}
                inRgRef = {inOLDBAK4}
                inDnRef = {inSUU5}
                inLfRef = {inDWNGIIN4}
                dismeisai = {dismeisai}
              />

              {/* 旧売価4 */}
              <COldBaika
                ref = {oldBaika4Ref}
                id = {'OLDBAK4'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inOLDBAK4}
                inputNxRef = {inNEWBAK4}
                inUpRef = {inOLDBAK3}
                inRgRef = {inNEWBAK4}
                inDnRef = {inOLDBAK5}
                inLfRef = {inSUU4}
                dismeisai = {dismeisai}
              />

              {/* 新売価4 */}
              <CNewBaika
                ref = {newBaika4Ref}
                id = {'NEWBAK4'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inNEWBAK4}
                inputNxRef = {inSHNCDE5}
                inUpRef = {inNEWBAK3}
                inRgRef = {inNEWBAK4}
                inDnRef = {inNEWBAK5}
                inLfRef = {inOLDBAK4}
                dismeisai = {dismeisai}
              />

              {/* 差　額4 */}
              <CSagaku
                ref = {sagaku4Ref}
                id = {'SAGAKU4'}
                inputRef = {inSAGAKU4}
              />

              {/* 売変額4 */}
              <CBaihenGaku
                ref = {baihenGaku4Ref}
                id = {'BIHNGK4'}
                inputRef = {inBIHNGK4}
              />

              {/* 符号4 */}
              <CFugou
                ref = {fugou4Ref}
                id = {'FGO4'}
                inputRef = {inFGO4}
              />
            </TableRow>
            <TableRow>
              {/* 商品コード5 */}
              <CShohinCode
                ref = {shohinCode5Ref}
                id = {'SHNCDE5'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inSHNCDE5}
                inputNxRef = {inDWNGIIN5}
                inUpRef = {inSHNCDE4}
                inRgRef = {inDWNGIIN5}
                inDnRef = {inSHNCDE6}
                inLfRef = {inSHNCDE5}
                dismeisai = {dismeisai}
              />

              {/* 原因5 */}
              <CGennin
                ref = {gennin5Ref}
                id = {'DWNGIIN5'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inDWNGIIN5}
                inputNxRef = {inSUU5}
                inUpRef = {inDWNGIIN4}
                inRgRef = {inSUU5}
                inDnRef = {inDWNGIIN6}
                inLfRef = {inSHNCDE5}
                dismeisai = {dismeisai}
              />

              {/* 数量5 */}
              <CSuuryo
                ref = {suuryo5Ref}
                id = {'SUU5'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inSUU5}
                inputNxRef = {inOLDBAK5}
                inUpRef = {inSUU4}
                inRgRef = {inOLDBAK5}
                inDnRef = {inSUU6}
                inLfRef = {inDWNGIIN5}
                dismeisai = {dismeisai}
              />

              {/* 旧売価5 */}
              <COldBaika
                ref = {oldBaika5Ref}
                id = {'OLDBAK5'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inOLDBAK5}
                inputNxRef = {inNEWBAK5}
                inUpRef = {inOLDBAK4}
                inRgRef = {inNEWBAK5}
                inDnRef = {inOLDBAK6}
                inLfRef = {inSUU5}
                dismeisai = {dismeisai}
              />

              {/* 新売価5 */}
              <CNewBaika
                ref = {newBaika5Ref}
                id = {'NEWBAK5'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inNEWBAK5}
                inputNxRef = {inSHNCDE6}
                inUpRef = {inNEWBAK4}
                inRgRef = {inNEWBAK5}
                inDnRef = {inNEWBAK6}
                inLfRef = {inOLDBAK5}
                dismeisai = {dismeisai}
              />

              {/* 差　額5 */}
              <CSagaku
                ref = {sagaku5Ref}
                id = {'SAGAKU5'}
                inputRef = {inSAGAKU5}
              />

              {/* 売変額5 */}
              <CBaihenGaku
                ref = {baihenGaku5Ref}
                id = {'BIHNGK5'}
                inputRef = {inBIHNGK5}
              />

              {/* 符号5 */}
              <CFugou
                ref = {fugou5Ref}
                id = {'FGO5'}
                inputRef = {inFGO5}
              />
            </TableRow>
            <TableRow>
              {/* 商品コード6 */}
              <CShohinCode
                ref = {shohinCode6Ref}
                id = {'SHNCDE6'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inSHNCDE6}
                inputNxRef = {inDWNGIIN6}
                inUpRef = {inSHNCDE5}
                inRgRef = {inDWNGIIN6}
                inDnRef = {inSHNCDE7}
                inLfRef = {inSHNCDE6}
                dismeisai = {dismeisai}
              />

              {/* 原因6 */}
              <CGennin
                ref = {gennin6Ref}
                id = {'DWNGIIN6'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inDWNGIIN6}
                inputNxRef = {inSUU6}
                inUpRef = {inDWNGIIN5}
                inRgRef = {inSUU6}
                inDnRef = {inDWNGIIN7}
                inLfRef = {inSHNCDE6}
                dismeisai = {dismeisai}
              />

              {/* 数量6 */}
              <CSuuryo
                ref = {suuryo6Ref}
                id = {'SUU6'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inSUU6}
                inputNxRef = {inOLDBAK6}
                inUpRef = {inSUU5}
                inRgRef = {inOLDBAK6}
                inDnRef = {inSUU7}
                inLfRef = {inDWNGIIN6}
                dismeisai = {dismeisai}
              />

              {/* 旧売価6 */}
              <COldBaika
                ref = {oldBaika6Ref}
                id = {'OLDBAK6'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inOLDBAK6}
                inputNxRef = {inNEWBAK6}
                inUpRef = {inOLDBAK5}
                inRgRef = {inNEWBAK6}
                inDnRef = {inOLDBAK7}
                inLfRef = {inSUU6}
                dismeisai = {dismeisai}
              />

              {/* 新売価6 */}
              <CNewBaika
                ref = {newBaika6Ref}
                id = {'NEWBAK6'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inNEWBAK6}
                inputNxRef = {inSHNCDE7}
                inUpRef = {inNEWBAK5}
                inRgRef = {inNEWBAK6}
                inDnRef = {inNEWBAK7}
                inLfRef = {inOLDBAK6}
                dismeisai = {dismeisai}
              />

              {/* 差　額6 */}
              <CSagaku
                ref = {sagaku6Ref}
                id = {'SAGAKU6'}
                inputRef = {inSAGAKU6}
              />

              {/* 売変額6 */}
              <CBaihenGaku
                ref = {baihenGaku6Ref}
                id = {'BIHNGK6'}
                inputRef = {inBIHNGK6}
              />

              {/* 符号6 */}
              <CFugou
                ref = {fugou6Ref}
                id = {'FGO6'}
                inputRef = {inFGO6}
              />
            </TableRow>
            <TableRow>
              {/* 商品コード7 */}
              <CShohinCode
                ref = {shohinCode7Ref}
                id = {'SHNCDE7'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inSHNCDE7}
                inputNxRef = {inDWNGIIN7}
                inUpRef = {inSHNCDE6}
                inRgRef = {inDWNGIIN7}
                inDnRef = {inSHNCDE8}
                inLfRef = {inSHNCDE7}
                dismeisai = {dismeisai}
              />

              {/* 原因7 */}
              <CGennin
                ref = {gennin7Ref}
                id = {'DWNGIIN7'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inDWNGIIN7}
                inputNxRef = {inSUU7}
                inUpRef = {inDWNGIIN6}
                inRgRef = {inSUU7}
                inDnRef = {inDWNGIIN8}
                inLfRef = {inSHNCDE7}
                dismeisai = {dismeisai}
              />

              {/* 数量7 */}
              <CSuuryo
                ref = {suuryo7Ref}
                id = {'SUU7'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inSUU7}
                inputNxRef = {inOLDBAK7}
                inUpRef = {inSUU6}
                inRgRef = {inOLDBAK7}
                inDnRef = {inSUU8}
                inLfRef = {inDWNGIIN7}
                dismeisai = {dismeisai}
              />

              {/* 旧売価7 */}
              <COldBaika
                ref = {oldBaika7Ref}
                id = {'OLDBAK7'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inOLDBAK7}
                inputNxRef = {inNEWBAK7}
                inUpRef = {inOLDBAK6}
                inRgRef = {inNEWBAK7}
                inDnRef = {inOLDBAK8}
                inLfRef = {inSUU7}
                dismeisai = {dismeisai}
              />

              {/* 新売価7 */}
              <CNewBaika
                ref = {newBaika7Ref}
                id = {'NEWBAK7'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inNEWBAK7}
                inputNxRef = {inSHNCDE8}
                inUpRef = {inNEWBAK6}
                inRgRef = {inNEWBAK7}
                inDnRef = {inNEWBAK8}
                inLfRef = {inOLDBAK7}
                dismeisai = {dismeisai}
              />

              {/* 差　額7 */}
              <CSagaku
                ref = {sagaku7Ref}
                id = {'SAGAKU7'}
                inputRef = {inSAGAKU7}
              />

              {/* 売変額7 */}
              <CBaihenGaku
                ref = {baihenGaku7Ref}
                id = {'BIHNGK7'}
                inputRef = {inBIHNGK7}
              />

              {/* 符号7 */}
              <CFugou
                ref = {fugou7Ref}
                id = {'FGO7'}
                inputRef = {inFGO7}
              />
            </TableRow>
            <TableRow>
              {/* 商品コード8 */}
              <CShohinCode
                ref = {shohinCode8Ref}
                id = {'SHNCDE8'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inSHNCDE8}
                inputNxRef = {inDWNGIIN8}
                inUpRef = {inSHNCDE7}
                inRgRef = {inDWNGIIN8}
                inDnRef = {inSHNCDE8}
                inLfRef = {inSHNCDE8}
                dismeisai = {dismeisai}
              />

              {/* 原因8 */}
              <CGennin
                ref = {gennin8Ref}
                id = {'DWNGIIN8'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inDWNGIIN8}
                inputNxRef = {inSUU8}
                inUpRef = {inDWNGIIN7}
                inRgRef = {inSUU8}
                inDnRef = {inDWNGIIN8}
                inLfRef = {inSHNCDE8}
                dismeisai = {dismeisai}
              />

              {/* 数量8 */}
              <CSuuryo
                ref = {suuryo8Ref}
                id = {'SUU8'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inSUU8}
                inputNxRef = {inOLDBAK8}
                inUpRef = {inSUU7}
                inRgRef = {inOLDBAK8}
                inDnRef = {inSUU8}
                inLfRef = {inDWNGIIN8}
                dismeisai = {dismeisai}
              />

              {/* 旧売価8 */}
              <COldBaika
                ref = {oldBaika8Ref}
                id = {'OLDBAK8'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inOLDBAK8}
                inputNxRef = {inNEWBAK8}
                inUpRef = {inOLDBAK7}
                inRgRef = {inNEWBAK8}
                inDnRef = {inOLDBAK8}
                inLfRef = {inSUU8}
                dismeisai = {dismeisai}
              />

              {/* 新売価8 */}
              <CNewBaika
                ref = {newBaika8Ref}
                id = {'NEWBAK8'}
                onKeyDown = {onKeyDown_Meisai}
                inputRef = {inNEWBAK8}
                inputNxRef = {inSUUG}
                inUpRef = {inNEWBAK7}
                inRgRef = {inNEWBAK8}
                inDnRef = {inNEWBAK8}
                inLfRef = {inOLDBAK8}
                dismeisai = {dismeisai}
              />

              {/* 差　額8 */}
              <CSagaku
                ref = {sagaku8Ref}
                id = {'SAGAKU8'}
                inputRef = {inSAGAKU8}
              />

              {/* 売変額8 */}
              <CBaihenGaku
                ref = {baihenGaku8Ref}
                id = {'BIHNGK8'}
                inputRef = {inBIHNGK8}
              />

              {/* 符号8 */}
              <CFugou
                ref = {fugou8Ref}
                id = {'FGO8'}
                inputRef = {inFGO8}
              />
            </TableRow>
            </TableBody>
          </Table>
        </TableContainer><br />

          <Table sx={{border:0,maxWidth:800}} aria-label="spanning table" size="small">
            <TableBody>   {/* テーブル表示処理明細部 */}
            <TableRow>
              <TableCell align="right" sx={{border:0,padding:0,paddingRight:'0'}} width="200">　</TableCell>
              <TableCell align="center" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="46.82">合計</TableCell>
              {/* 数量合計 */}
              <CSuuryoGoukei
                ref = {suuryoGoukeiRef}
                onKeyDown = {onKeyDown}
                inputRef = {inSUUG}
                inputRtRef = {inSHNCDE1}
                inputNxRef = {inOLDBAKG}
                dismeisai = {dismeisai}
              />

              {/* 旧売価合計 */}
              <COldBaikaGoukei
                ref = {oldBaikaGoukeiRef}
                onKeyDown = {onKeyDown}
                inputRef = {inOLDBAKG}
                inputRtRef = {inSUUG}
                inputNxRef = {inNEWBAKG}
                dismeisai = {dismeisai}
              />

              {/* 新売価合計 */}
              <CNewBaikaGoukei
                ref = {newBaikaGoukeiRef}
                onKeyDown = {onKeyDown}
                inputRef = {inNEWBAKG}
                inputRtRef = {inOLDBAKG}
                inputNxRef = {inRET}
                dismeisai = {dismeisai}
              />

              <TableCell align="right" sx={{border:0,padding:0,paddingRight:'0'}} width="104.19">　</TableCell>

              {/* 売変額合計 */}
              <CBaihenGakuGoukei
                ref = {baihenGakuGoukeiRef}
                onKeyDown = {onKeyDown}
                inputRef = {inBIHNGKG}
                inputRtRef = {inNEWBAKG}
                inputNxRef = {inRET}
                dismeisai = {dismeisai}
              />
              <TableCell align="right" sx={{border:0,padding:0,paddingRight:'0'}} width="65">　</TableCell>
            </TableRow>
            </TableBody>
          </Table>

        <br />
        <font color="red">{ alert }</font>
        <Stack direction="row" spacing={2}>
          <font color="black">{ 'F9:中止　F5:終了　Up:前項目　Down:次項目　　　　　　　　　　　　　　　　　　　　　　　　　' }</font>
          <NumericFormat  //確認
            id={'ret'}
            inputRef={inRET}                    //項目名定義？
            thousandSeparator={false}           //1000単位の区切り
            color='success'
            customInput={TextField}             //textfieldにreact-number-formatの機能を追加
            size='small'                        //textboxの全体サイズ
            label='確認'                         //textboxの左上に表示するラベル
            variant='outlined'                  //枠の表示方法
            fixedDecimalScale={false}            //小数点On/Off
            InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
            sx={Object.assign({},cssInputOutlineRetSetwidth(styles.cssInputOutline))}
            //value={dbdata.ZEIRTU}               //初期値
            allowLeadingZeros                   //前ゼロ表示 on
            disabled={false}                    //使用可／不可
            inputProps={{ autoComplete: 'off',maxLength: 0,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
            onKeyDown={(e) => onKeyDown(e.key,inRET,inBIHNGKG,inRET)}
            onFocus={(e) => onFocus_ret()}
          />
        </Stack>
      </Paper>
    </Grid>
  );
};

export default TCSA0150;