import { useState, useEffect } from 'react'

// 理由No.情報取得
export const useDenKbnAction = () => {
  const [denKbnList, setDenKbnList] = useState([]);                 // 伝票区分リスト
  const [denKbnName, setDenKbnName] = useState('');                 // 伝票区分（表示名称）
  const [denKbnComment, setDenKbnComment] = useState('※伝票区分'); // 伝票区分コメント

  useEffect (() => {
    getDenKbn();
  }, []);

  // 伝票区分取得
  const getDenKbn = async() => {
    // 伝票区分情報(使用可能区分）取得
    const getDenKbnCd = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({CDE: '004',SCDE: '0000'})});
    const denKbnCdList = await getDenKbnCd.json();

    // 伝票区分情報(区分に対する名称）取得
    const getDenKbnName = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({CDE: '004',SCDE: '0010'})});
    const denKbnNameList = await getDenKbnName.json();

    // 伝票区分情報(区分に対する使用可能理由No.）取得
    const getDenKbnKyoka = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({CDE: '004',SCDE: '0020'})});
    const denKbnKyokaList = await getDenKbnKyoka.json();

    // 伝票区分リスト化
    setDenKbnList([]);
    setDenKbnList((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE01,name:denKbnNameList.DATE01,kyokaNo:denKbnKyokaList.DATE01}]);
    setDenKbnList((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE02,name:denKbnNameList.DATE02,kyokaNo:denKbnKyokaList.DATE02}]);
    setDenKbnList((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE03,name:denKbnNameList.DATE03,kyokaNo:denKbnKyokaList.DATE03}]);
    setDenKbnList((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE04,name:denKbnNameList.DATE04,kyokaNo:denKbnKyokaList.DATE04}]);
    setDenKbnList((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE05,name:denKbnNameList.DATE05,kyokaNo:denKbnKyokaList.DATE05}]);
    setDenKbnList((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE06,name:denKbnNameList.DATE06,kyokaNo:denKbnKyokaList.DATE06}]);
    setDenKbnList((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE07,name:denKbnNameList.DATE07,kyokaNo:denKbnKyokaList.DATE07}]);
    setDenKbnList((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE08,name:denKbnNameList.DATE08,kyokaNo:denKbnKyokaList.DATE08}]);
    setDenKbnList((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE09,name:denKbnNameList.DATE09,kyokaNo:denKbnKyokaList.DATE09}]);
    setDenKbnList((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE10,name:denKbnNameList.DATE10,kyokaNo:denKbnKyokaList.DATE10}]);
  };

  // 伝票区分コメントの表示
  useEffect (() => {
    let comment = '※伝票区分';
    denKbnList.forEach(element => {
      if(element.cd !== '') {
        comment = comment + ' ' + element.cd + ':' + element.name;
      }
    });
    setDenKbnComment(comment);
  }, [denKbnList]);

  // 伝票区分入力時の処理
  const changeDenKbn = (denKbnVal) => {
    let denKbnName = '';
    if (denKbnVal !== '')
    {
      const inputDenKbn = denKbnList.filter(element => {
        return Number(element.cd) === Number(denKbnVal);
      });

      if (inputDenKbn.length !== 0){
        denKbnName = inputDenKbn[0].name;
      }
    }

    setDenKbnName(denKbnName);
  };

  return {denKbnList, denKbnComment, denKbnName, changeDenKbn}
};


export default useDenKbnAction;