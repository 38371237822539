import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, TextField, Grid, Paper, Typography, Stack, InputLabel } from "@mui/material";
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';

import CustomAlert from './CustomAlert';
import CustomDialog from './CustomDialog';
import './common.css'

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F5キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F9キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F11") { // F11キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSA0190 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('ファンクションキーを押してください。');
  const [dismeisai, setMeisai] = useState(false);    //disabled設定（明細）
  const [sdate, setsDate] = useState([]);
  const [inp_OUTYMD, setOUTYMD] = useState('');       //検索入力用伝票番号
  const [inp_kkn, setkkn] = useState('');       //リスト出力と画面戻る指示分岐用
  const [upd_flg, setUpdflg] = useState('0');   //更新用フラグ

  const inOUTYMD = useRef(null);
  const inRET = useRef(null);

  //項目のバックカラー制御用
  const [itOUTYMDclr,setItOUTYMDclr] = useState('#FFFFFF');

  const commonStyles = {
    bgcolor: 'background.paper',
    m: 1,
    border: 0,
    width: '40px',
    height: '40px',
  };

  // アラート↓↓↓
  const [isAlertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  // アラートを表示
  const showAlert = (message) => {
    window.glAlertDialogFlg = true;
    setAlertMessage(message);
    setAlertVisible(true);
  };
  // アラートを閉じる
  const closeAlert = () => {
    window.glAlertDialogFlg = false;
    setAlertMessage('');
    setAlertVisible(false);
  };
  // アラートここまで↑↑↑

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
      PRINTsyori();
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      if (state.GAMEN == 0)
      {
        navigate('/TCSA0003');
      }else{
        navigate('/TCSA0005');
      }
    }
    if (event.keyCode === 120) { // F9キーが押された場合の処理
    }
    if (event.keyCode === 122) { // F11キーが押された場合の処理
      if (inOUTYMD.current !== null) {
        inOUTYMD.current.focus();
        console.log('state.KSACDE:'+state.KSACDES);
        console.log('state.MISES:'+state.MISES);
      }
      setAlert('Ｆ５：終了'); 
}
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応
  
  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    let d = new Date(state.SDAY);
    d.setDate(d.getDate() - 1);// 2023/11/02 kameda デフォルト-1日とする
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);

    let YYMMDD = String(year).substring(2,4)+ '/' + ('0'+month).slice(-2) + '/' + ('0'+day).slice(-2);
    setOUTYMD(YYMMDD);
    if (state.KENGEN === '99')
    {
      setUpdflg('1');
    }
  }, []);

  const PRINTsyori = async() =>{
    //出力処理

    //チェック処理追加（2023/09/29亀田）
    if (CHECKsyori()===false){
      return;
    }

    //S 184-003 2023/09/20 連続印刷指示抑制の為、メッセージボックスを表示する
    // window.alert('印刷要求を受け付けました。しばらくお待ちください');
    showAlert('印刷要求を受け付けました。しばらくお待ちください');
    //E 184-003 2023/09/20 連続印刷指示抑制の為、メッセージボックスを表示する
    setAlert('　'); 
    console.log('PRINTsyori:件数確認開始');
    let inpYYYYMMDD = '20'+inp_OUTYMD.substring(0,2)+inp_OUTYMD.substring(3,5)+inp_OUTYMD.substring(6,8);
    console.log('inpYYYYMMDD：'+inpYYYYMMDD);
    const responseA = await fetch('/TCSA0190/SL_ERR_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,THOOUTDD:inpYYYYMMDD,UPD_FLG:upd_flg})});
    const dataA = await responseA.json();
    console.log('PRINTsyori:件数確認完了');
    if (dataA.RCNT >= 1) //一件以上
    {
      console.log('PRINTsyori:データあり');
      // setAlert('Ｆキーを押してください。'); 
      const responseB = await fetch('/TCSA0190/SAPRT0070',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,THOOUTDD:inpYYYYMMDD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
      const dataB = await responseB.json();

      setAlert('正常に処理が完了しました。');//TCGM0003：

    }else
    {
      console.log('PRINTsyori:データなし');

      //不要ロジック削除（2023/09/30亀田）
      // // setAlert('Ｆキーを押してください。'); 
      const responseB = await fetch('/TCSA0190/SAPRT0070',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ENT_KSACDE: state.KSACDES,ENT_MISE: state.MISES,ENT_K_KSACDE: state.K_KSACDES,ENT_K_MISE: state.K_MISES,THOOUTDD:inpYYYYMMDD,JGNCDE:state.JGNCDE,UPD_FLG:upd_flg})});
      const dataB = await responseB.json();

      // setAlert('出力データはありませんでした。');//TCGE0076：
      setAlert('正常に処理が完了しました。');//TCGM0003：
    }
  };

  const CHECKsyori = () =>{
    //チェック処理
    console.log('チェック処理開始');
    //初期化
    setAlert('ファンクションキーを押してください。')
    setItOUTYMDclr('#FFFFFF');

    // 出力日チェック
    var strDate = '20'+inp_OUTYMD;
    var dtdate = new Date(strDate);
    if (isNaN(dtdate.getDate())==false){
      console.log('発送日チェック1'+'|'+strDate);
      console.log('日付範囲');
      let dt = new Date(state.SDAY);
      let dts = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate()-90); // 実行日から90日前の日付
      let dte = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate());      // 実行日の日付
      console.log('日付'+dts+'<='+dtdate+'<='+dte);
      if (dts <= dtdate && dtdate <= dte) //実行日90日前より過去または、実行日より未来
      {
      }else{                
        setItOUTYMDclr('#f59d9d');
        setAlert('日付入力エラー');//TCGE0016：
        return false;
      }
    }else{
      setItOUTYMDclr('#f59d9d');
      setAlert('日付入力エラー');//TCGE0016：
      return false;
    }
    
    //チェックＯＫ
    console.log('対象件数チェックおよびPDF出力処理開始');
    setAlert('ファンクションキーを押してください。');  
    return true;
  };

  useEffect(() => {
    // // イベントリスナーを登録
    // window.addEventListener('keydown', (e) => {
    //   // if (e.key==='F5')
    //   // {
    //   //   e.preventDefault();
    //   //   navigate('/TCSA0003');
    //   // }
    //   // console.log(e.key);
    //   switch(e.key)
    //   {
    //     case 'F5':
    //       e.preventDefault();
    //       navigate('/TCSA0003');
    //       break;

    //     case 'F11':
    //       e.preventDefault();
    //       if (inOUTYMD.current !== null) {
    //         inOUTYMD.current.focus();
    //         console.log('state.KSACDE:'+state.KSACDES);
    //         console.log('state.MISES:'+state.MISES);
    //       }
    //       setAlert('Ｆ５：終了'); 
    //       break;
    //     default:
    //       break;
    //   }

    // });
  }, []);

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef,inUpRef,inRgRef,inDnRef,inLfRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報、上キー、右キー、下キー、左キー
    console.log(key);
    // let mvFlg = 0;
    switch (key) {
      case "Enter":

      //間違い。修正する  
      // if (inputRef.current.id==='ret') 
      if (inputRef.current.id==='OUTYMD') 
        {
          // if (inp_kkn === '1')
          // {
            // 出力日チェック
            var strDate = '20'+inp_OUTYMD;
            var dtdate = new Date(strDate);
            setItOUTYMDclr('#FFFFFF');
            setAlert('ファンクションキーを押してください。');  
            if (isNaN(dtdate.getDate())==false){
              console.log('発送日チェック1'+'|'+strDate);
              console.log('日付範囲');
              let dt = new Date(state.SDAY);
              let dts = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate()-90); // 実行日から90日前の日付
              let dte = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate());      // 実行日の日付
              //dts.setFullYear(dts.getFullYear() - 1);
              console.log('日付'+dts+'<='+dtdate+'<='+dte);
              if (dts <= dtdate && dtdate <= dte) //実行日90日前より過去または、実行日より未来
              {
                console.log('対象件数チェックおよびPDF出力処理開始');
                // PRINTsyori();
              }else{                
                setItOUTYMDclr('#f59d9d');
                setAlert('日付入力エラー');//TCGE0016：
                // mvFlg = 2;
              }
            }else{
              setItOUTYMDclr('#f59d9d');
              setAlert('日付入力エラー');//TCGE0016：
              // mvFlg = 2;
            }

          // }else if (inp_kkn === '5')
          // {
          //   navigate('/TCSA0003');
          // }
        }

        // switch (mvFlg) {
        //   case 0:
        //     inputNxRef.current.focus();   //次の項目に遷移
        //     break;
        //   case 1:
        //     inRET.current.focus();
        //     inputRef.current.focus();   //現在の項目に遷移
        //     break;
        //   case 2:
        //     inputRtRef.current.focus();   //前の項目に遷移
        //     break;
        //   case 9:
        //     // inDENNO.current.focus();   //伝票No.の項目に遷移
        //     break;
        //   default:
        //     break;
        //   }
        break;
      case "Home":
        break;
      case "PageUp":
        inputRtRef.current.focus();   //前の項目に遷移
        break;
      case "PageDown":
        inputNxRef.current.focus();   //次の項目に遷移
        break;
      default:
        break;
    }
  };  

  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
          fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
  }

  return (

    <Grid>
      <CustomAlert isVisible={isAlertVisible} message={alertMessage} onClose={closeAlert} />
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "730px",
          width: "900px",
          m: "10px auto",
          padding:"10px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="left"
        >
          <div style={{display:"flex"}}>
            <Typography variant={"h5"} sx={{ m: "10px" }}>
            チェックリスト再出力処理
            </Typography>
            <Box width={'65%'} display={'flex'} justifyContent={'flex-end'}>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"17px",fontSize:15 }}>
                {state.MISEMEI}：{state.JGNMEIKNJ}
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"17px",fontSize:15 }}>
                { state.SDAY }
              </Typography>　　　　　
            </Box>
          </div>
        </Grid>
        <br />
        <Stack direction="row">
          <InputLabel variant={'outlined'}>チェックリストを再出力します。</InputLabel>    
        </Stack>
        <br />
        <br />        
        <Stack direction="row">
          <Grid>
            <div>　　</div>
          </Grid>
          <PatternFormat  //出力日
            id={'OUTYMD'}
            inputRef={inOUTYMD}                   //項目名定義？
            format="##/##/##"
            mask={['Y','Y','M','M','D','D']}
            autoFocus                           //初期カーソル位置設定
            color='success'
            customInput={TextField}             //textfieldにreact-number-formatの機能を追加
            size='small'                        //textboxの全体サイズ
            label='処理日'                        //textboxの左上に表示するラベル
            variant='outlined'                  //枠の表示方法
            type='tel'                          //[PDA、Tablet対応]入力モード：tel
            inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
            fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
            decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
            InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
            onValueChange={(values, sourceInfo) => {
              console.log(values.value);
              console.log(values.formattedValue);
              console.log(values.floatValue);
            }}              
            sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 7), {background:itOUTYMDclr})}
            value={inp_OUTYMD}                  //初期値
            allowLeadingZeros                   //前ゼロ表示 on
            disabled={false}                    //使用可／不可
            placeholder="YY/MM/DD"
            inputProps={{ autoComplete: 'off',maxLength: 9,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
            onKeyDown={(e) => onKeyDown(e.key,inOUTYMD,inOUTYMD,inRET,'','','','')}
            onChange={(event) => setOUTYMD(event.target.value)}
            //onFocus={(e) => e.target.select()}
            onFocus={(e) => setTimeout(() => {inOUTYMD.current.select();},100)}
            />
        </Stack>
        <br />
        <br />
        {/* <Stack direction="row" >
          <Grid>
            <div>　　</div>
          </Grid>
          <NumericFormat  //確認
                id={'ret'}
                inputRef={inRET}                    //項目名定義？
                thousandSeparator={false}           //1000単位の区切り           
                color='success'
                autoFocus
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='確認'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                type='tel'                          //[PDA、Tablet対応]入力モード：tel
                inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
                fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
                decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '5ch'}}                 //textboxの幅
                value={inp_kkn}                     //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={false}                    //使用可／不可
                inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
                onKeyDown={(e) => onKeyDown(e.key,inRET,inOUTYMD,inRET,'','','','')}
                onChange={(event) => setkkn(event.target.value)}
                />  
                <InputLabel variant={'outlined'}>（１：リスト出力　５：前画面へ）</InputLabel>               
        </Stack> */}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <font color="red">{ alert }</font>
        <Stack direction="row" spacing={2}>
          {/* <font color="black">{ 'F9:中止　F5:終了　Up:前項目　Down:次項目　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　' }</font> */}
          <font color="black">{ 'Ｆ１：リスト出力　Ｆ５：終了　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　' }</font>          
        </Stack>
      </Paper>
    </Grid>
  );
};

export default TCSA0190;