import React, { useState,useContext,useEffect } from 'react'
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import './common.css';

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSA0003 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [btn01, setBtn01] = useState(false); //ターンアラウンド入力
  const [btn02, setBtn02] = useState(false); //手書Ⅰ型入力
  const [btn03, setBtn03] = useState(false); //移動伝票入力
  const [btn04, setBtn04] = useState(false); //売変伝票
  const [btn05, setBtn05] = useState(false); //各店ターンアラウンド入力
  const [btn06, setBtn06] = useState(false); //各店手書Ⅰ型入力
  const [btn07, setBtn07] = useState(false); //各店移動伝票入力
  const [btn08, setBtn08] = useState(false); //確認リスト
  const [btn09, setBtn09] = useState(false); //入力データ送信
  const [btn10, setBtn10] = useState(false); //計上リスト出力
  const [btn11, setBtn11] = useState(false); //チェックリスト出力
  const [btn12, setBtn12] = useState(false); //（月末）未処理リスト出力
  const [btn13, setBtn13] = useState(false); //返品伝票作成
  const [btn14, setBtn14] = useState(false); //移動伝票作成
  const [btn15, setBtn15] = useState(false); //計上リスト出力（返品）
  const [btn16, setBtn16] = useState(false); //返品伝票確認・送信
  const [btn17, setBtn17] = useState(false); //移動伝票確認・送信
  const [btn18, setBtn18] = useState(false); //計上リスト出力（移動）
  const [btn19, setBtn19] = useState(true); //店舗切替
  const [btn20, setBtn20] = useState(false); //ユーザー管理
  const [ttl ,setTtl] = useState('');
  const [ttl2 ,setTtl2] = useState('　テスト　');
  const [ttl3 ,setTtl3] = useState('　本番　');
  // const [ttl2 ,setTtl2] = useState('');
  // const [ttl3 ,setTtl3] = useState('');
  const [sdate, setsDate] = useState([]);
  const [visibleBtn, setVisibleBtn] = useState(false);    // 商談No表示フラグ
  const [almsg1, setAlmsg01] = useState('問い合わせ先');
  const [almsg2, setAlmsg02] = useState('XXX-XXX-XXX');


  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
    }
    if (event.keyCode === 120) { // F9キーが押された場合の処理
    }
    console.log('key:'+event.keyCode);
    if (event.keyCode === 17)
    {
      if (visibleBtn === true)
      {
        setVisibleBtn(false);
      }else
      {
        setVisibleBtn(true);
      }

    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  const getHelp = async () => {
    const getMemo = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({CDE: '012',SCDE: '0000'})});
    const mem01 = await getMemo.json();
    setAlmsg01(mem01.MEMO);
    const getMemo2 = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({CDE: '012',SCDE: '0010'})});
    const mem02 = await getMemo2.json();
    setAlmsg02(mem02.MEMO);
  }

  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
      return;
    }else
    {
      console.log('メニュー:%o',state);
    }
    getHelp();
    switch(state.KENGEN)
    {
      case '01'://店舗後方部門担当者
        setBtn05(true);
        setBtn06(true);
        setBtn07(true);
        break;
      case '02'://店舗部門担当者
        setBtn01(true);
        setBtn02(true);
        setBtn03(true);
        setBtn04(true);
        setBtn05(true);
        setBtn06(true);
        setBtn07(true);
        setBtn08(true);
        setBtn09(true);
        setBtn10(true);
        setBtn11(true);
        setBtn12(true);
        setBtn15(true);
        //setBtn16(true);
        //setBtn17(true);
        setBtn18(true);
        // setBtn19(true);
        setBtn20(true);
        break;
      case '03'://物流・工場所属
        setBtn01(true);
        setBtn04(true);
        setBtn13(true);
        setBtn14(true);
        setBtn15(true);
        setBtn16(true);
        setBtn17(true);
        setBtn18(true);
        setBtn20(true);
        break;
      case '04'://本社（店舗サポート部、情報管理部除く）
        setBtn01(true);
        setBtn02(true);
        setBtn03(true);
        setBtn04(true);
        setBtn05(true);
        setBtn06(true);
        setBtn07(true);
        setBtn08(true);
        setBtn09(true);
        setBtn10(true);
        setBtn11(true);
        setBtn12(true);
        setBtn15(true);
        //setBtn16(true);
        //setBtn17(true);
        setBtn18(true);
        // setBtn19(true);
        setBtn20(true);
        break;
      case '05':
        break;
      case '06':
        break;
      case '07':
        break;
      case '08':
        break;
      case '09'://店舗サポート部
        setBtn05(true);
        setBtn06(true);
        setBtn07(true);
        setBtn12(true);
        break;
      case '99'://情報管理部
        if (state.K_FLG == 0)
        {
          setBtn05(true);
          setBtn06(true);
          setBtn07(true);
        }
        break;
      default:
        break;
    }
    if (state.MISE == '200' || state.K_KSACDES != '')
    {
      setBtn19(false);
    }else if(state.SMISEFLG === 1){
      setBtn19(false);
    }
    setTtl('＜'+state.MISES+'＞');
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);
    console.log('TCSA0003 モード:%o',document.fullscreenElement);
    if (!document.fullscreenElement){
      console.log('TCSA0003 モード:on');
      document.documentElement.webkitRequestFullScreen();
    }
  },[]);

  const onKeyDown =  async (key,inputRef) => {  //  入力キー、現在の項目情報、次項目情報
    //console.log('keydown:%o',inputRef);
    switch (key) {
      case "Enter":
        switch(inputRef)
        {
          // case "TCSA0100":  //仕入伝票入力（ターンアラウンド）
          //   navigate('/TCSA0100');
          //   break;
          case "TCSA0004":  //実納品日
            dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
            KSACDES : state.KSACDE,MISES: state.MISES,K_KSACDES : state.K_KSACDES,K_MISES: state.K_MISES,
            MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
            SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: state.K_FLG,ETC:''});
            navigate('/TCSA0004');
            break;
          case "TCSA0160":  //確認リスト出力
            navigate('/TCSA0160');
            break;
          case "TCSA0130":  //仕入伝票入力（手書Ⅰ型）
            dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
            KSACDES : state.KSACDE,MISES: state.MISES,K_KSACDES : state.K_KSACDES,K_MISES: state.K_MISES,
            MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
            SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: state.K_FLG,ETC:''});
            navigate('/TCSA0130');
            break;
          case "TCSA1110":  //入力データ送信
            navigate('/TCSA1110');
            break;
          case "TCSA0140":  //移動伝票入力
            dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
            KSACDES : state.KSACDE,MISES: state.MISES,K_KSACDES : state.K_KSACDES,K_MISES: state.K_MISES,
            MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
            SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: state.K_FLG,ETC:''});
            navigate('/TCSA0140');
            break;
          case "TCSA0180":  //計上リスト出力
            navigate('/TCSA0180');
            break;
          case "TCSA0150":  //売変伝票入力
            navigate('/TCSA0150');
            break;
          case "TCSA0190":  //チェックリスト出力
            navigate('/TCSA0190');
            break;
            case "TCSA0200":  //(月次)未処理リスト出力
            navigate('/TCSA0200');
            break;
          case "TCSA9999":  //(月次)未処理リスト出力
            navigate('/TCSA9999');
            break;
          case "TCGT0100":  //返品伝票作成
            navigate('/TCGT0100');
            break;
          case "TCHP0150":  //返品伝票確認・送信
            navigate('/TCHP0150');
            break;
          case "TCGT0110":  //移動伝票作成
            navigate('/TCGT0110');
            break;
          case "TCHP0160":  //移動伝票確認・送信
            navigate('/TCHP0160');
            break;
          case "TCGT0170":  //計上リスト出力（返品）
            navigate('/TCGT0170');
            break;
          case "TCGT0180":  //計上リスト出力（移動）
            navigate('/TCGT0180');
            break;
          case "TCSA0001":  //店舗入力 or 店舗選択
            dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
            KSACDES : state.KSACDE,MISES: state.MISES,K_KSACDES : state.K_KSACDES,K_MISES: state.K_MISES,
            MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
            SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,MVEMGAMEN:'TCSA0003',K_FLG: state.K_FLG,ETC:''});
            // if(state.SMISEFLG == 1)
            // {
            //   navigate('/TCSA0002');
            // }else{
            //   navigate('/TCSA0001');
            // }
            if (state.MISE == '200' || state.K_KSACDES != '')
            {
              navigate('/TCSA0001');
            }else if(state.SMISEFLG === 1){
              navigate('/TCSA0002');
            }
            break;
            case "TCSA0006":  //従業員マスタ
            navigate('/TCSA0006');
            break;
          case "TCSA0000":  //ログイン画面
            navigate('/');
            break;
          case "TCSA0007":  //ログイン画面
            navigate('/TCSA0007');
            break;
          case "TCSA0004K":  //実納品日
            dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
            KSACDES : state.KSACDE,MISES: state.MISES,K_KSACDES : state.K_KSACDES,K_MISES: state.K_MISES,
            MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
            SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: state.K_FLG,ETC:'K'});

            navigate('/TCSA0004');
            break;
          case "TCSA0130K":  //仕入伝票入力（手書Ⅰ型）
            dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
            KSACDES : state.KSACDE,MISES: state.MISES,K_KSACDES : state.K_KSACDES,K_MISES: state.K_MISES,
            MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
            SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: state.K_FLG,ETC:'K'});
            navigate('/TCSA0130');
            break;
          case "TCSA0140K":  //移動伝票入力
            dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
            KSACDES : state.KSACDE,MISES: state.MISES,K_KSACDES : state.K_KSACDES,K_MISES: state.K_MISES,
            MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
            SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: state.K_FLG,ETC:'K'});
            navigate('/TCSA0140');
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }; 
   

  return (
    <table align="center" border="0" width="1250" height="650">
      <tr>
        <td align="left"><font size='6'>業務メニュー</font></td>
        <td align="center"><font size='4'>{state.MISEMEI}：{state.JGNMEIKNJ}　{sdate}</font></td>
      </tr>
      <tr align="center">
        <td valign="top">
          <table border="0" width="620" height="500">
            <tr align="center" height="92">
              <td colspan="2" >伝票入力{ttl}<font color='#FF0000' size='6'><span style={{backgroundColor: '#FFFF00'}}><b>{ttl2}</b></span></font></td>
            </tr>
            <tr align="center">
              <td height="46">
                <button type="button" className="btnM btnGreen" style={{width:'90%'}} disabled={btn01} onClick={(e) => onKeyDown('Enter','TCSA0004') }>仕入伝票入力（ターンアラウンド）<span></span></button>
              </td>
              <td>
                <button type="button" className="btnM btnGreen" style={{width:'90%'}} disabled={btn08} onClick={(e) => onKeyDown('Enter','TCSA0160') }>確認リスト出力<span></span></button>
              </td>
            </tr>
            <tr align="center">
              <td height="46">
                <button type="button" className="btnM btnGreen" style={{width:'90%'}} disabled={btn02} onClick={(e) => onKeyDown('Enter','TCSA0130') }>仕入伝票入力（手書Ⅰ型）<span></span></button>
              </td>
              <td>
                <button type="button" className="btnM btnGreen" style={{width:'90%'}} disabled={btn09} onClick={(e) => onKeyDown('Enter','TCSA1110') }>入力データ送信<span></span></button>
              </td>
            </tr>
            <tr align="center">
              <td height="46">
                <button type="button" className="btnM btnGreen" style={{width:'90%'}} disabled={btn03} onClick={(e) => onKeyDown('Enter','TCSA0140') }>移動伝票入力<span></span></button>
              </td>
              <td>
                <button type="button" className="btnM btnGreen" style={{width:'90%'}} disabled={btn10} onClick={(e) => onKeyDown('Enter','TCSA0180') }>計上リスト再出力<span></span></button>
              </td>
            </tr>
            <tr align="center">
              <td height="46">
                <button type="button" className="btnM btnGreen" style={{width:'90%'}} disabled={btn04} onClick={(e) => onKeyDown('Enter','TCSA0150') }>売変伝票入力<span></span></button>
              </td>
              <td>
                <button type="button" className="btnM btnGreen" style={{width:'90%'}} disabled={btn11} onClick={(e) => onKeyDown('Enter','TCSA0190') }>チェックリスト再出力<span></span></button>
              </td>
            </tr>
            <tr align="center">
              <td valign="top">
                <table border="0" width="300" height="200">
                  <tr align="left">
                    <td height="45">　＜各店＞</td>
                  </tr>
                  <tr align="center">
                    <td height="45">
                      <button type="button" className="btnM btnGreen" style={{width:'93%'}} disabled={btn05} onClick={(e) => onKeyDown('Enter','TCSA0004K') }>仕入伝票入力（ターンアラウンド）<span></span></button>
                    </td>
                  </tr>
                  <tr align="center">
                    <td height="45">
                      <button type="button" className="btnM btnGreen" style={{width:'93%'}} disabled={btn06} onClick={(e) => onKeyDown('Enter','TCSA0130K') }>仕入伝票入力（手書Ⅰ型）<span></span></button>
                    </td>
                  </tr>
                  <tr align="center">
                    <td height="45">
                      <button type="button" className="btnM btnGreen" style={{width:'93%'}} disabled={btn07} onClick={(e) => onKeyDown('Enter','TCSA0140K') }>移動伝票入力<span></span></button>
                    </td>
                  </tr>
                </table>
              </td>
              <td valign="top">
              <table border="0" width="300" height="200">
                  <tr align="center">
                    <td height="45"></td>
                  </tr>
                  <tr align="center">
                    <td height="45">
                      <button type="button" className="btnM btnGreen" style={{width:'93%'}} disabled={btn12} onClick={(e) => onKeyDown('Enter','TCSA0200') }>（月末）未処理リスト出力<span></span></button>
                    </td>
                  </tr>
                  <tr align="center">
                    <td height="45"></td>
                  </tr>
                  <tr align="center">
                    <td height="45"></td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>

        </td>
        <td valign="top">
          <table border="0" width="620" height="250">
            <tr align="center" height="90">
              <td colspan="2">伝票作成 <font color='#FFFFFF'size='6'><span style={{backgroundColor: '#000000'}}><b>{ttl3}</b></span></font></td>
            </tr>
            <tr align="center">
              <td height="45">
                <button type="button" className="btnM btnOrange" style={{width:'93%'}} disabled={btn13} onClick={(e) => onKeyDown('Enter','TCGT0100') }>返品伝票作成<span></span></button>
              </td>
              <td>
                <button type="button" className="btnM btnViolet" style={{width:'93%'}} disabled={btn14} onClick={(e) => onKeyDown('Enter','TCGT0110') }>移動伝票作成<span></span></button>
              </td>
            </tr>
            <tr align="center">
              <td height="45">
                <button type="button" className="btnM btnOrange" style={{width:'93%'}} disabled={btn16} onClick={(e) => onKeyDown('Enter','TCHP0150') }>返品伝票確認・送信<span></span></button>
              </td>
              <td>
                <button type="button" className="btnM btnViolet" style={{width:'93%'}} disabled={btn17} onClick={(e) => onKeyDown('Enter','TCHP0160') }>移動伝票確認・送信<span></span></button>
              </td>
            </tr>
            <tr align="center">
              <td height="45">
                <button type="button" className="btnM btnOrange" style={{width:'93%'}} disabled={btn15} onClick={(e) => onKeyDown('Enter','TCGT0170') }>計上リスト再出力（返品）<span></span></button>
              </td>
              <td>
                <button type="button" className="btnM btnViolet" style={{width:'93%'}} disabled={btn18} onClick={(e) => onKeyDown('Enter','TCGT0180') }>計上リスト再出力（移動）<span></span></button>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr align="center">
        <td>
          <font color="navy">　{almsg1} {almsg2}</font>
        </td>
        <td>
          <button type="button" className="btnM btnGreen" style={{width:'30%'}} disabled={btn19} onClick={(e) => onKeyDown('Enter','TCSA0001') }>店舗切替<span></span></button>　
          <button type="button" className="btnM btnGreen" style={{width:'30%'}} disabled={btn20} onClick={(e) => onKeyDown('Enter','TCSA0006') }>ユーザー管理<span></span></button>　
          <button type="button" className="btnM btnGreen" style={{width:'30%'}} onClick={(e) => onKeyDown('Enter','TCSA0000') }>戻　　る<span></span></button>
        </td>
      </tr>
    </table>

    // <Grid>
    //   <Paper
    //     elevation={3}
    //     sx={{
    //       p: 1,
    //       height: "650px",
    //       width: "1250px",
    //       //flexDirection: { xs: "column", md: "row" }, // <-- 画面幅によって切り替える。
    //       //alignItems: { xs: 'center', md: 'flex-start' }, // <-- 画面幅によって切り替える。
    //       m: "1px auto"
    //     }}
    //   >
    //   <Grid
    //       container
    //       direction="column"
    //       justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
    //       alignItems="left"
    //     >
    //       <div style={{display:"flex"}}>
    //       <Typography variant={"h4"} sx={{ m: "10px" }}>
    //         業務メニュー
    //       </Typography>　　　
    //       <Typography variant={"h6"} sx={{ m: "15px",ml:"400px" }}>
    //       {state.MISEMEI}：{state.JGNMEIKNJ}
    //       </Typography>
    //       <Typography variant={"h6"} sx={{ m: "15px" }}>
    //       {sdate}
    //       </Typography>
    //       </div>
    //     </Grid>
    //     <Stack direction="row" >
    //       <Paper
    //         sx={{
    //           p: 1,
    //           height: '80%',
    //           width: '50%',
    //           m: 1
    //         }}
    //         // alignItems='center'
    //       >
    //         <Box sx={{ width: '100%' }}>
    //           <Stack spacing={2} alignItems={'center'}>
    //             <Typography variant={"h6"} sx={{ m: "10px" }}>
    //               伝票入力{ttl} <font color='#FF0000' size='6'><span style={{backgroundColor: '#FFFF00'}}><b>{ttl2}</b></span></font>

    //             </Typography>
    //           </Stack>
    //         </Box>
    //         <Stack direction="row" spacing={2}>
    //           <button type="button" className="btnM btnGreen" style={{width:'50%'}} disabled={btn01} onClick={(e) => onKeyDown('Enter','TCSA0004') }>仕入伝票入力（ターンアラウンド）<span></span></button>
    //           {/* <Button 
    //             variant="contained" 
    //             color='success' 
    //             sx={{width:'50%',ml:"-5px",mr:"15px"}}
    //             disabled={btn01}
    //             onClick={(e) => onKeyDown('Enter','TCSA0004') }
    //             >
    //             仕入伝票入力（ターンアラウンド）
    //           </Button> */}
    //           <button type="button" className="btnM btnGreen" style={{width:'50%'}} disabled={btn08} onClick={(e) => onKeyDown('Enter','TCSA0160') }>確認リスト出力<span></span></button>
    //           {/* <Button 
    //             variant="contained" 
    //             color='success' 
    //             sx={{width:'50%',ml:"-5px",mr:"15px"}}
    //             disabled={btn08}
    //             onClick={(e) => onKeyDown('Enter','TCSA0160') }
    //             >
    //             確認リスト出力
    //           </Button> */}
    //         </Stack>
    //         <br />
    //         <Stack direction="row" spacing={2}>
    //           <button type="button" className="btnM btnGreen" style={{width:'50%'}} disabled={btn02} onClick={(e) => onKeyDown('Enter','TCSA0130') }>仕入伝票入力（手書Ⅰ型）<span></span></button>
    //           {/* <Button 
    //             variant="contained" 
    //             color='success' 
    //             sx={{width:'50%',ml:"-5px",mr:"15px"}}
    //             disabled={btn02}
    //             onClick={(e) => onKeyDown('Enter','TCSA0130') }
    //             >
    //             仕入伝票入力（手書Ⅰ型）
    //           </Button> */}
    //           <button type="button" className="btnM btnGreen" style={{width:'50%'}} disabled={btn09} onClick={(e) => onKeyDown('Enter','TCSA1110') }>入力データ送信<span></span></button>
    //           {/* <Button 
    //             variant="contained" 
    //             color='success' 
    //             sx={{width:'50%',ml:"-5px",mr:"15px"}}
    //             disabled={btn09}
    //             onClick={(e) => onKeyDown('Enter','TCSA1110') }
    //             >
    //             入力データ送信
    //           </Button> */}
    //         </Stack>
    //         <br />
    //         <Stack direction="row" spacing={2}>
    //           <button type="button" className="btnM btnGreen" style={{width:'50%'}} disabled={btn03} onClick={(e) => onKeyDown('Enter','TCSA0140') }>移動伝票入力<span></span></button>
    //           {/* <Button 
    //             variant="contained" 
    //             color='success' 
    //             sx={{width:'50%',ml:"-5px",mr:"15px"}}
    //             disabled={btn03}
    //             onClick={(e) => onKeyDown('Enter','TCSA0140') }
    //             >
    //             移動伝票入力
    //           </Button> */}
    //           <button type="button" className="btnM btnGreen" style={{width:'50%'}} disabled={btn10} onClick={(e) => onKeyDown('Enter','TCSA0180') }>計上リスト再出力<span></span></button>
    //           {/* <Button 
    //             variant="contained" 
    //             color='success' 
    //             sx={{width:'50%',ml:"-5px",mr:"15px"}}
    //             disabled={btn10}
    //             onClick={(e) => onKeyDown('Enter','TCSA0180') }
    //             >
    //             計上リスト再出力
    //           </Button> */}
    //         </Stack>
    //         <br />
    //         <Stack direction="row" spacing={2}>
    //           <button type="button" className="btnM btnGreen" style={{width:'50%'}} disabled={btn04} onClick={(e) => onKeyDown('Enter','TCSA0150') }>売変伝票入力<span></span></button>
    //           {/* <Button 
    //             variant="contained" 
    //             color='success' 
    //             sx={{width:'50%',ml:"-5px",mr:"15px"}}
    //             disabled={btn04}
    //             onClick={(e) => onKeyDown('Enter','TCSA0150') }
    //             >
    //             売変伝票入力
    //           </Button> */}
    //           <button type="button" className="btnM btnGreen" style={{width:'50%'}} disabled={btn11} onClick={(e) => onKeyDown('Enter','TCSA0190') }>チェックリスト再出力<span></span></button>
    //           {/* <Button 
    //             variant="contained" 
    //             color='success' 
    //             sx={{width:'50%',ml:"-5px",mr:"15px"}}
    //             disabled={btn11}
    //             onClick={(e) => onKeyDown('Enter','TCSA0190') }
    //             >
    //             チェックリスト再出力
    //           </Button> */}
    //         </Stack>
    //         <Stack direction="row" spacing={0}>
    //           <Paper
    //             elevation={3}
    //             sx={{
    //               p: 1,
    //               height: '45%',
    //               width: '48%',
    //               m: 1,
    //               ml:'-5px'
    //             }}
    //           >
    //             ＜各店＞
    //             <br />
    //             <button type="button" className="btnM btnGreen" style={{width:'100%'}} disabled={btn05} onClick={(e) => onKeyDown('Enter','TCSA0004K') }>仕入伝票入力（ターンアラウンド）<span></span></button>
    //             {/* <Button 
    //               variant="contained" 
    //               color='success' 
    //               sx={{width:'100%'}}
    //               disabled={btn05}
    //               onClick={(e) => onKeyDown('Enter','TCSA0004K') }
    //               >
    //               仕入伝票入力（ターンアラウンド）
    //             </Button> */}
    //             <br /><br />
    //             <button type="button" className="btnM btnGreen" style={{width:'100%'}} disabled={btn06} onClick={(e) => onKeyDown('Enter','TCSA0130K') }>仕入伝票入力（手書Ⅰ型）<span></span></button>
    //             {/* <Button 
    //               variant="contained" 
    //               color='success' 
    //               sx={{width:'100%'}}
    //               disabled={btn06}
    //               onClick={(e) => onKeyDown('Enter','TCSA0130K') }
    //               >
    //               仕入伝票入力（手書Ⅰ型）
    //             </Button> */}
    //             <br /><br />
    //             <button type="button" className="btnM btnGreen" style={{width:'100%'}} disabled={btn07} onClick={(e) => onKeyDown('Enter','TCSA0140K') }>移動伝票入力<span></span></button>
    //             {/* <Button 
    //               variant="contained" 
    //               color='success' 
    //               sx={{width:'100%'}}
    //               disabled={btn07}
    //               onClick={(e) => onKeyDown('Enter','TCSA0140K') }
    //               >
    //               移動伝票入力
    //             </Button> */}
    //           </Paper>
    //           <Paper
    //             elevation={0}
    //             sx={{
    //               p: 2,
    //               height: "100px",
    //               width: '50%',
    //               m: "0px auto",
    //               ml:"-5px"
    //             }}
    //           >
    //             <br />
    //             <button type="button" className="btnM btnGreen" style={{width:'100%'}} disabled={btn12} onClick={(e) => onKeyDown('Enter','TCSA0200') }>（月末）未処理リスト出力<span></span></button>
    //             {/* <Button 
    //               variant="contained" 
    //               color='success' 
    //               sx={{width:'260px',height:36.5 ,ml:'5px',mr:'0px'}}
    //               disabled={btn12}
    //               onClick={(e) => onKeyDown('Enter','TCSA0200') }
    //               >
    //               （月末）未処理リスト出力
    //             </Button> */}
    //             <br /><br />
    //             {/* <Button 
    //               variant="contained" 
    //               color='success' 
    //               sx={{width:'100%',height:36.5}}
    //               disabled={btn12}
    //               onClick={(e) => onKeyDown('Enter','TCSA9999') }
    //               >
    //               帳票リスト出力
    //             </Button> */}
    //           </Paper>
    //         </Stack>

    //       </Paper>　
    //       <Paper
    //         sx={{
    //           p: 1,
    //           height: '80%',
    //           width: '50%',
    //           m: 1
    //         }}
    //       >
    //         <Box sx={{ width: '100%' }}>
    //           <Stack spacing={2} alignItems={'center'}>
    //             <Typography variant={"h6"} sx={{ m: "10px" }}>
    //               伝票作成 <font color='#FFFFFF'size='6'><span style={{backgroundColor: '#000000'}}><b>{ttl3}</b></span></font>
    //             </Typography>
    //           </Stack>
    //         </Box>
    //         <Stack direction="row" spacing={2}>
    //           <button type="button" className="btnM btnOrange" style={{width:'50%'}} disabled={btn13} onClick={(e) => onKeyDown('Enter','TCGT0100') }>返品伝票作成<span></span></button>
    //           {/* <Button 
    //             variant="contained" 
    //             color='warning' 
    //             sx={{width:'50%'}}
    //             disabled={btn13}
    //             onClick={(e) => onKeyDown('Enter','TCGT0100') }
    //             >
    //             返品伝票作成
    //           </Button> */}
    //           <button type="button" className="btnM btnViolet" style={{width:'50%'}} disabled={btn14} onClick={(e) => onKeyDown('Enter','TCGT0110') }>移動伝票作成<span></span></button>
    //           {/* <Button 
    //             variant="contained" 
    //             color='secondary' 
    //             sx={{width:'50%'}}
    //             disabled={btn14}
    //             onClick={(e) => onKeyDown('Enter','TCGT0110') }
    //             >
    //             移動伝票作成
    //           </Button> */}
    //         </Stack>
    //         <br />
    //         <Stack direction="row" spacing={2}>
    //           <button type="button" className="btnM btnOrange" style={{width:'50%'}} disabled={btn16} onClick={(e) => onKeyDown('Enter','TCHP0150') }>返品伝票確認・送信<span></span></button>
    //           {/* <Button 
    //             variant="contained" 
    //             color='warning' 
    //             sx={{width:'50%'}}
    //             disabled={btn16}
    //             onClick={(e) => onKeyDown('Enter','TCHP0150') }
    //             >
    //             返品伝票確認・送信
    //           </Button> */}
    //           <button type="button" className="btnM btnViolet" style={{width:'50%'}} disabled={btn17} onClick={(e) => onKeyDown('Enter','TCHP0160') }>移動伝票確認・送信<span></span></button>
    //           {/* <Button 
    //             variant="contained" 
    //             color='secondary' 
    //             sx={{width:'50%'}}
    //             disabled={btn17}
    //             onClick={(e) => onKeyDown('Enter','TCHP0160') }
    //             >
    //             移動伝票確認・送信
    //           </Button> */}
    //         </Stack>
    //         <br />
    //         <Stack direction="row" spacing={2}>
    //           <button type="button" className="btnM btnOrange" style={{width:'50%'}} disabled={btn15} onClick={(e) => onKeyDown('Enter','TCGT0170') }>計上リスト再出力（返品）<span></span></button>
    //           {/* <Button 
    //             variant="contained" 
    //             color='warning' 
    //             sx={{width:'50%'}}
    //             disabled={btn15}
    //             onClick={(e) => onKeyDown('Enter','TCGT0170') }
    //             >
    //             計上リスト再出力（返品）
    //           </Button> */}
    //           <button type="button" className="btnM btnViolet" style={{width:'50%'}} disabled={btn18} onClick={(e) => onKeyDown('Enter','TCGT0180') }>計上リスト再出力（移動）<span></span></button>
    //           {/* <Button 
    //             variant="contained" 
    //             color='secondary' 
    //             sx={{width:'50%'}}
    //             disabled={btn18}
    //             onClick={(e) => onKeyDown('Enter','TCGT0180') }
    //             >
    //             計上リスト再出力（移動）
    //           </Button> */}
    //         </Stack>
    //       </Paper>
    //     </Stack>
    //     <Stack direction="row" spacing={2} sx={{mt:'10px'}} >
    //       <font color="navy">　{almsg1} {almsg2}</font>
    //     </Stack>
    //     <Box display={'flex'} justifyContent={'flex-end'} sx={{mt:'-25px'}}>
    //       <Stack direction="row" spacing={2} >
    //         <Button 
    //           variant="contained" 
    //           color='success' 
    //           sx={{width:10,height:36.5,visibility:visibleBtn ? 'visible' : 'hidden'}}
    //           disabled={btn19}
    //           onClick={(e) => onKeyDown('Enter','TCSA0007') }
    //           >
              
    //         </Button>
    //         <button type="button" className="btnM btnGreen" style={{width:180}} disabled={btn19} onClick={(e) => onKeyDown('Enter','TCSA0001') }>店舗切替<span></span></button>
    //         {/* <Button 
    //           variant="contained" 
    //           color='success' 
    //           sx={{width:180,height:36.5}}
    //           disabled={btn19}
    //           onClick={(e) => onKeyDown('Enter','TCSA0001') }
    //           >
    //           店舗切替
    //         </Button> */}
    //         <button type="button" className="btnM btnGreen" style={{width:180}} disabled={btn20} onClick={(e) => onKeyDown('Enter','TCSA0006') }>ユーザー管理<span></span></button>
    //         {/* <Button 
    //           variant="contained" 
    //           color='success' 
    //           sx={{width:180,height:36.5}}
    //           disabled={btn20}
    //           onClick={(e) => onKeyDown('Enter','TCSA0006') }
    //           >
    //           ユーザー管理
    //         </Button> */}
    //         <button type="button" className="btnM btnGreen" style={{width:180}} onClick={(e) => onKeyDown('Enter','TCSA0000') }>戻　　る<span></span></button>
    //         {/* <Button 
    //           variant="contained" 
    //           color='success' 
    //           sx={{width:180,height:36.5}}
    //           onClick={(e) => onKeyDown('Enter','TCSA0000') }
    //           >
    //           戻　　る
    //         </Button> */}
    //       </Stack>
    //     </Box>
    //   </Paper>
    // </Grid>
  );
};

export default TCSA0003;