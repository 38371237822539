import React, { useState,useContext,useEffect,useRef } from 'react'
import { Button, TextField, Grid,Stack, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import { NumericFormat } from 'react-number-format';    //数値入力
import { UserContext } from './App';
import { onMyFocus,onNextFocus } from './hooks/useOnMyFocus';     // select()処理

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);

      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

const TCPD0132 = ({
  setScreenPhase,
  rows,
  setRows,
  rowData,
  rowItem,
  setRowItem,
  headerData,
  headerItem,
  setHeaderItem,
  regionList,
  prefectureList,
  prefectureList2,
  homeList,
  foodLabelList,
  dressTaxrate,
  setHeaderHASDD     // 23/09/25
}) => {
  const {state, dispatch} = useContext(UserContext)
  const [valueSHNMEI ,setValueSHNMEI] = useState('');   // 商品名
  //項目のバックカラー制御用
  const [inJAN1clr,setJAN1clr] = useState('#FFFFFF');
  const [inJAN2clr,setJAN2clr] = useState('#FFFFFF');
  const [inSUUclr,setSUUclr] = useState('#FFFFFF');
  const [inGNTclr,setGNTclr] = useState('#FFFFFF');
  const [inBTNclr,setBTNclr] = useState('#FFFFFF');

  //disabled制御用
  const [disJan1, setJan1] = useState(false);    // JAN1
  const [disJan2, setJan2] = useState(true);    // JAN2
  // 2023/10/06
  const [disRow, setDisRow] = useState(true);    // 数量 原単価 売単価etc

  const [chkJAN1,setChkJAN1] = useState('0');
  const [chkJAN2,setChkJAN2] = useState('0');

  // セレクトボックス制御用
  const [enableSearch, setEnableSearch] = useState(false);
  //const [enableId, setEnableId] = useState('0');// 23/09/25 idチェック追加（表示クリア時のチェンジイベント回避）
  let enableId = 0;

  const inJAN1 = useRef(null);
  const inJAN2 = useRef(null);
  const inSHNMEI = useRef(null);
  const inSUU = useRef(null);
  const inGNT = useRef(null);
  const inBTN = useRef(null);

  const inSANTIKBN = useRef(null);
  const inTODOFUKENKUNI = useRef(null);
  const inGENSANTI = useRef(null);
  const inSKHLBLKBN = useRef(null);

  useEffect (()=>{
    let  rowName = rowItem.SHNMEIKN + '\n';
    if (rowItem.IRYO_FLG !== '1')
    {
      rowName = rowName + rowItem.KIKAKUKN + '\n';
    }else{
      rowName = rowName + rowItem.TRIHBN + '\n' + rowItem.TAGSIZMEI + ' ' + rowItem.TAGCOLMEI;
    }
    setValueSHNMEI(rowName);

    if(rowItem.JANCDE2 !== '') {
      if (rowItem.id === 0)
      {
        setJan2(false);
      }
    }else if(rowItem.JANCDE === ''){
      setJan2(true);
    }
  }, [rowItem]);

  useEffect (()=>{
    if(disRow === false){
      inSUU.current.focus();
    }
  }, [disRow]);

  /**
   * 数量設定 (numをrow.SUUに数字で、row.SUU2に文字列で設定)
   * @param row 設定行
   * @param num 設定値
   */
  const setRowSuu = async (row, num) => {
    let suu = Number(num);
    if (isNaN(suu)){
      suu = 0;
    }
    // 数量
    row.SUU = suu;
    // 数量2
    row.SUU2 = suu.toLocaleString(undefined,{minimumFractionDigits : 1});
  }

  /**
   * 原単価設定 (numをrow.GNTに数字で、row.GNT2に文字列で設定)
   * @param row 設定行
   * @param num 設定値
   */
  const setRowGnt = async (row, num) => {
    let gnt = Number(num);
    if (isNaN(gnt)){
      gnt = 0;
    }
    // 原単価
    row.GNT = gnt;
    // 原単価2
    row.GNT2 = gnt.toLocaleString(undefined,{minimumFractionDigits : 2});
  }

  /**
   * 売単価設定 (numをrow.BTNに数字で、row.BTN2に文字列で設定)
   * @param row 設定行
   * @param num 設定値
   */
  const setRowBtn = async (row, num) => {
    let btn = Number(num);
    if (isNaN(btn)){
      btn = 0;
    }
    // 売単価
    row.BTN = btn;
    // 売単価2
    row.BTN2 = btn.toLocaleString(undefined,{minimumFractionDigits : 0});
  }

  const commonStyles = {
    bgcolor: 'background.paper',
    m: 1,
    border: 0,
    width: '40px',
    height: '40px',
  };

  const [nextActive, setNextActive] = useState(null);
  useEffect(() => {
    if (nextActive === 'SUU') {
      //setTimeout(() => {inSUU.current.select();},100);
      inSUU.current.focus();
      onNextFocus(inSUU);
      setNextActive('');
    }
  }, [rowItem.SUU]);

  const onKeyDown =  async (key,inputRef,inptRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    //console.log(key);
    switch (key) {
      case 'Enter':
        setJAN1clr('#FFFFFF');
        setJAN2clr('#FFFFFF');
        setSUUclr('#FFFFFF');
        setGNTclr('#FFFFFF');
        setBTNclr('#FFFFFF');
        switch(inputRef.current.id){
          case 'JAN1':
            await onKeyDown_JAN1(inputRef,inputNxRef);  // JANCD1
            break;
          case 'JAN2':
            await onKeyDown_JAN2(inputRef,inputNxRef);  // JANCD2
            break;
          case 'SUU':
            await onKeyDown_SUU(inputRef,inputNxRef);  // 数量
            break;
          case 'GNT':
            await onKeyDown_GNT(inputRef,inputNxRef);  // 原単価
            break;
          case 'BTN':
            await onKeyDown_BTN(inputRef,inputNxRef);  // 売単価
            break;
          default:
            await inputNxRef.current.focus();
            break;
        }
      case 'Home':
        break;
      case 'ArrowUp':
        inptRtRef.current.focus();    //前の項目に遷移
        break;
      case 'ArrowDown':
        inputNxRef.current.focus();   //次の項目に遷移
        break;
      default:
        break;
    }
  };

  const onblur = async (inputRef) => {
    setJAN1clr('#FFFFFF');
    setJAN2clr('#FFFFFF');
    setSUUclr('#FFFFFF');
    setGNTclr('#FFFFFF');
    setBTNclr('#FFFFFF');
    console.log('LostFocus:'+inputRef.current.id+' | '+chkJAN1+'|'+chkJAN2);
    if (inputRef.current.id === 'JAN1' && (chkJAN1 == '0'))
    {
      await onKeyDown_JAN1(inputRef, inSUU)
      return;
    }
    if (inputRef.current.id === 'JAN2' && (chkJAN2 == '0'))
    {
      await onKeyDown_JAN2(inputRef, inSUU)
    }
  }

  // ヘッダ部２入力　ＪＡＮ１
  const onKeyDown_JAN1 = async(inputRef, inputNxRef) => {
    setChkJAN1('1');
    const row = await JSON.parse(JSON.stringify(rowItem));
    const header = await JSON.parse(JSON.stringify(headerItem));
    let Jancde = '';

    // ＪＡＮ１入力値の前0詰め
    // if(row.JANCDE.length === 7){
    //   row.JANCDE = row.JANCDE.padStart(8, '0');
    // }
    // if(9 <= row.JANCDE.length && row.JANCDE.length <= 12){
    //   row.JANCDE = row.JANCDE.padStart(13, '0');
    // }
    // ＪＡＮ１入力値の前0詰め
    Jancde = row.JANCDE;
    if(row.JANCDE.length === 7){
      row.JANCDE = row.JANCDE.padStart(8, '0');
      Jancde = row.JANCDE.padStart(8, '0');
    }
    if(9 <= row.JANCDE.length && row.JANCDE.length <= 12){
      row.JANCDE = row.JANCDE.padStart(13, '0');
      Jancde = row.JANCDE.padStart(13, '0');
    }
    console.log('商品コード'+row.JANCDE);

    // 2段判定
    //const jan1cd = inputRef.current.value.substring(0, 2);
    const jan1cd = Jancde.substring(0, 2);

    //if (inputRef.current.value.length == 13 && (
    if (Jancde.length == 13 && (
          (jan1cd === '22' ||jan1cd === '23')
        ||((jan1cd === '21' ||jan1cd === '22') && state.KSACDES ==='005')
    )){
      await setRow_Two_JAN1 (row,jan1cd);
      await setJan2(false);
      inJAN2.current.focus();
      setTimeout(() => {inJAN2.current.focus();},100);
      return;
    }
    if(!(jan1cd === '21' || jan1cd === '22' || jan1cd === '23')){
      setJan2(true);
      row.JANCDE2 = '';
    }

    // 衣料品事業部配下分類かどうかを確認
    const getIryoFlg = await fetch('/TCGT0110/getIryoFlg',{method: 'POST', headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ header:headerItem })});
    const iryo_flg = await getIryoFlg.json();

    // 衣料品フラグ = １ の場合
    if(iryo_flg.IRYOFLG === '1') {
      row.IRYO_FLG = '1';
    } else {
      row.IRYO_FLG = '0';
    }

    let shohinMaster = undefined;
    // 8桁の場合、商品マスタ（基本）にEOSとしてデータが存在するかチェック。
    if(Jancde.length === 8){
      const getShohinMaster = await fetch('/TCGT0110/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({row:rowItem, EOSCDE:Jancde })});
      shohinMaster = await getShohinMaster.json();

      if(shohinMaster.ret.length !== 0) {
        row.IN_CD_KBN = '2'
      }
    };

    // 8、12、13 桁の場合、商品マスタ（基本）にJANとしてデータが存在するかチェック。
    if(row.IN_CD_KBN !== '2' &&
    (Jancde.length === 8 || Jancde.length === 12 || Jancde.length === 13)) {
      const getShohinMaster = await fetch('/TCGT0110/getShohinMaster_JanCode',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({row:rowItem, JANCDEX:Jancde })});
        shohinMaster = await getShohinMaster.json();

      if(shohinMaster.ret.length !== 0) {
        row.IN_CD_KBN = '1'
      }
    }

    if(!shohinMaster) {
      setJAN1clr('#f59d9d');
      window.alert('商品が存在しません。'); //TCGE0047：
      return;
    }

    // 仕入先名称取得
    if (shohinMaster.ret.length !== 0) {
      row.SIRCDE = shohinMaster.ret[0].SIRCDE;  // 仕入先(取引先)コード
      const getSirmeik = await fetch('/TCGT0110/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:row.SIRCDE})});
      const sirmeik = await getSirmeik.json();
      if(sirmeik !== null) {
        row.SIRMEIK = sirmeik.SIRMEIK;          // 仕入先(取引先)名
      }
    }

    // ＪＡＮ１チェック
    // (1)
    if(shohinMaster.ret.length === 0) {
      setJAN1clr('#f59d9d');
      window.alert('商品が存在しません。'); //TCGE0047：
      return;
    }

    // (2)
    if(header.BMNCDE  !== shohinMaster.ret[0].BMNCDE) {
      setJAN1clr('#f59d9d');
      window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
      return;
    }

    // (3)
    // if(shohinMaster.ret[0].HPN_FKA_KBN === '1') {
    //   if(!(window.confirm('TCGC0003：警告：この商品は返品不可扱いです。強制返品しますか？'))){
    //     setJAN1clr('#f59d9d');
    //     return;
    //   }
    // }

    // データセット（1段）
    // await setRow_One(row,shohinMaster.ret[0],Jancde);

    // //次の項目に遷移
    // inputNxRef.current.focus();
    await setRow_One(row,shohinMaster.ret[0],Jancde);
    //await setRowItem(row);

    // 2023/10/06
    setDisRow(false);
    //次の項目に遷移
    //inputNxRef.current.focus();
    if (inputNxRef.current.value === '1.0') {
      // 表示が変わらない場合はuseEffectは発生しない
      inputNxRef.current.focus();
    } else {
      if (inputNxRef.current.id === 'SUU') {
        // 表示が変わるときは、数量の描画完了後（useEffect）にフォーカス移動させる必要がある
        setNextActive(inputNxRef.current.id);
      } else {
        // 数量以外へのフォーカス移動は問題なし
        inputNxRef.current.focus();
      }
    }
  }

  // データセット（1段）
  function setRow_One(row, shohinMaster,jancde){
    row.SHNMEIKN = shohinMaster.SHNMEIKN;      // 商品名
    row.KIKAKUKN = shohinMaster.KIKAKUKN;      // 規格
    row.JANCDE = jancde;         // ＪＡＮコード
    row.TRIHBN = shohinMaster.TRIHBN;          // 取引先品番
    setRowSuu(row, '1');                       // 数量
    // setRowGnt(row, shohinMaster.GNT);          // 原単価
    row.GNKKIN = 0;                            // 原価金額
    setRowBtn(row, shohinMaster.BTN);          // 売単価
    row.BAKKIN = 0;                            // 売価金額
    // if(headerItem.KAKEZEIRTU !== ''){
    //   //原単価＝売価×掛け率
    //   let gentanka = Number(shohinMaster.BTN) * Number(headerItem.KAKEZEIRTU) / 100;
    //   setRowGnt(row, gentanka);          // 原単価
    // }else{
    //   setRowGnt(row, shohinMaster.GNT);          // 原単価
    // }
    if(headerItem.KAKEZEIRTU == '' || headerItem.KAKEZEIRTU == '0'){
      setRowGnt(row, shohinMaster.GNT);          // 原単価
    }else{
      //原単価＝売価×掛け率
      let gentanka = Number(shohinMaster.BTN) * Number(headerItem.KAKEZEIRTU) / 100;
      setRowGnt(row, gentanka);          // 原単価
    }


    if( row.IN_CD_KBN === '2') {
      row.EOSCDE = jancde;       // ＥＯＳコード
    }

    if(row.IRYO_FLG === '1') {
      row.TAGASTCDE = shohinMaster.ASTCDE;     // タグＡＵ
      row.TAGLINCDE = shohinMaster.LINCDE;     // タグライン
      row.TAGCLSCDE = shohinMaster.CLSCDE;     // タグクラス
      row.TAGSZN = shohinMaster.TAGSZN;        // タグシーズン
      row.TAGSIZ = shohinMaster.TAGSIZCDE;     // サイズコード
      row.TAGSIZMEI = shohinMaster.TAGSIZMEI;  // サイズ名カナ
      row.TAGCOLCDE = shohinMaster.TAGCOLCDE;  // カラーコード
      row.TAGCOLMEI = shohinMaster.TAGCOLMEI;  // カラー名カナ
    }

    setRowItem(row);
  }

  // データセット（2段 JAN1時）
  function setRow_Two_JAN1 (row, jan1cd) {
    row.IRYO_FLG = '1';                        // 衣料フラグ
    row.JANCDE = inJAN1.current.value;         // ＪＡＮコード
    //row.EOSCDE = inJAN1.current.value;         // ＥＯＳコード
    if(jan1cd === '22') {
      row.IN_CD_KBN = '3'                      // 入力コード区分
    } else {
      row.IN_CD_KBN = '4'                      // 入力コード区分
      row.EOSCDE = inJAN1.current.value;         // ＥＯＳコード
    }
    setRowItem(row);
  }

  // ヘッダ部２入力　ＪＡＮ２（活性時）
  const onKeyDown_JAN2 = async (inputRef, inputNxRef) => {
    setChkJAN2('1');
    let row = JSON.parse(JSON.stringify(rowItem));

    // ＪＡＮ2入力値の前0詰め
    let Jan2cde = inJAN2.current.value;
    if(Jan2cde.length === 7){
      Jan2cde = Jan2cde.padStart(8, '0');
    }
    if(9 <= Jan2cde.length && Jan2cde.length <= 12){
      Jan2cde =Jan2cde.padStart(13, '0');
    }
    row.JANCDE2 = Jan2cde;

    const jan2cdH = inJAN2.current.value.substring(0, 2);
    // state.KSACDES = '001'の場合は、前２桁が「28」、「29」以外はエラー
    if(state.KSACDES === '001' && !(jan2cdH === '28' ||jan2cdH === '29')){
      setJAN2clr('#f59d9d');
      window.alert('ＪＡＮ２入力エラー。'); //TCGE00XX：
      return 1;
    }

    // state.KSACDES = '005'の場合は、前２桁が「26」、「27」以外はエラー
    if(state.KSACDES === '005' && !(jan2cdH === '26' ||jan2cdH === '27')){
      setJAN2clr('#f59d9d');
      window.alert('ＪＡＮ２入力エラー。'); //TCGE00XX：
      return 1;
    }

    const header = await JSON.parse(JSON.stringify(headerItem));
    const jan1cd = inJAN1.current.value.substring(0, 2);
    let tagMaster = undefined;
    let shohinMaster = undefined;
    //１）ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005以外もしくは、「２３」の場合
    if((jan1cd ==='22' && state.KSACDES !== '005')|| jan1cd ==='23') {
      //(1)ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005以外の場合
      if(jan1cd ==='22' && state.KSACDES !== '005') {
        const ASTCDE = Jan2cde.substring(2, 5);                      // ＡＵ
        const LINCDE = inJAN1.current.value.substring(2, 3);         // ライン
        const CLSCDE = '000' + inJAN1.current.value.substring(3, 4); // クラス
        const TAGSZN = inJAN1.current.value.substring(4, 5);         // タグシーズン
        const TAGITM = inJAN1.current.value.substring(5, 8);         // タグアイテム
        const TAGSIZCDE = inJAN1.current.value.substring(8, 10);     // サイズコード
        const TAGCOLCDE = inJAN1.current.value.substring(10, 12);    // カラーコード

        //①タグマスタから情報取得
        const getTagMaster = await fetch('/TCGT0110/getTagMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,MISE:state.MISES,ASTCDE:ASTCDE,LINCDE:LINCDE,CLSCDE:CLSCDE,TAGSZN:TAGSZN,TAGITM:TAGITM
        })});
        tagMaster = await getTagMaster.json();

        if (tagMaster.ret.length === 0){
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          return;
        }

        // 仕入先名称取得
        if (tagMaster.ret.length !== 0) {
          row.SIRCDE = tagMaster.ret[0].SIRCDE;     // 仕入先(取引先)コード
          const getSirmeik = await fetch('/TCGT0110/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:row.SIRCDE})});
          const sirmeik = await getSirmeik.json();
          if(sirmeik !== null) {
            row.SIRMEIK = sirmeik.SIRMEIK;          // 仕入先(取引先)名
          }
        }

        //② 衣料サイズマスタから情報取得
        const getSizeMaster = await fetch('/TCGT0110/getSizeMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,BMNCDE:header.BMNCDE,ASTCDE:ASTCDE,LINCDE:LINCDE,TAGSIZCDE:TAGSIZCDE
        })});
        const sizeMaster = await getSizeMaster.json();
        if(sizeMaster.ret.length !== 0) {
          row.TAGSIZ = sizeMaster.ret[0].TAGSIZCDE;     // サイズコード
          row.TAGSIZMEI = sizeMaster.ret[0].TAGSIZMEI;  // サイズ名カナ
        } else {
          row.TAGSIZ = '';     // サイズコード
          row.TAGSIZMEI = '';  // サイズ名カナ
        }


        //③ 衣料カラーマスタから情報取得
        const getColorMaster = await fetch('/TCGT0110/getColorMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({KSACDE:state.KSACDES,TAGCOLCDE:TAGCOLCDE})});
        const colorMaster = await getColorMaster.json();
        if(colorMaster.ret.length !== 0) {
          row.TAGCOLCDE = colorMaster.ret[0].TAGCOLCDE; // カラーコード
          row.TAGCOLMEI = colorMaster.ret[0].TAGCOLMEI; // カラー名カナ
        } else {
          row.TAGCOLCDE = ''; // カラーコード
          row.TAGCOLMEI = ''; // カラー名カナ
        }

        // 2023/10/06
        if(header.BMNCDE !== tagMaster.ret[0].BMNCDE) {
          console.log('header02:%o',header);
          setJAN1clr('#f59d9d');
          inJAN1.current.focus();
          window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
          return;
        }

        setRow_Two(row, tagMaster.ret[0]);
      }

      //(2) ＪＡＮ１の前２桁が「２３」の場合
      if(jan1cd ==='23') {
        // 桁数チェック
        if(Jan2cde.length !== 13){
          setJAN2clr('#f59d9d');
          window.alert('ＪＡＮ２入力エラー。'); //TCGE0047：
          return;
        }

        //① 商品マスタ(基本)から情報取得
        const EosCode = inJAN1.current.value.substring(2, 10);
        const getShohinMaster = await fetch('/TCGT0110/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({row:rowItem, EOSCDE:EosCode})});
        shohinMaster = await getShohinMaster.json();

        if (shohinMaster.ret.length === 0){
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          return;
        }

        row.EOSCDE = EosCode;
        //setRowGnt(row, shohinMaster.ret[0].GNT);          // 原単価
        setRowBtn(row, shohinMaster.ret[0].BTN);          // 売単価
        if(headerItem.KAKEZEIRTU !== ''){
          //原単価＝売価×掛け率
          let gentanka = Number(shohinMaster.ret[0].BTN) * Number(headerItem.KAKEZEIRTU) / 100;
          setRowGnt(row, gentanka);          // 原単価
        }else{
          setRowGnt(row, shohinMaster.ret[0].GNT);          // 原単価
        }
        //② 返品不可判定
        if (shohinMaster.ret[0].HPN_FKA_KBN === '1') {
          if(!(window.confirm('警告：この商品は返品不可扱いです。強制返品しますか？'))){  //TCGC0003：
            setJAN1clr('#f59d9d');
            inJAN1.current.focus();
            setTimeout(() => {inJAN1.current.focus();},100);
            setRowItem(row);
            return;
          }
        }

        // 仕入先名称取得
        if (shohinMaster.ret.length !== 0) {
          row.SIRCDE = shohinMaster.ret[0].SIRCDE;  // 仕入先(取引先)コード
          const getSirmeik = await fetch('/TCGT0110/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:row.SIRCDE})});
          const sirmeik = await getSirmeik.json();
          if(sirmeik !== null) {
            row.SIRMEIK = sirmeik.SIRMEIK;          // 仕入先(取引先)名
          }
        }

        // 2023/10/06
        if(header.BMNCDE !== shohinMaster.ret[0].BMNCDE) {
          console.log('header02:%o',header);
          setJAN1clr('#f59d9d');
          inJAN1.current.focus();
          window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
          return 1;
        }

        setRow_Two2(row, shohinMaster.ret[0]);
      }

      //(3) 計算
      const taxcde = Number(Jan2cde.substring(5, 6));
      const jankng = Number(Jan2cde.substring(6, 12));
      //①２段目バーコードの6桁目(税区分)＝0(総額)の場合
      if(taxcde === 0){
        let tax = (jankng * dressTaxrate) / (100 + dressTaxrate)
        setRowBtn(row, jankng - tax);

        if(headerItem.KAKEZEIRTU !== ''){
          //原単価＝売価×掛け率
          let gentanka = (jankng - tax) * Number(headerItem.KAKEZEIRTU) / 100;
          setRowGnt(row, gentanka);          // 原単価
        }

      }

      //②２段目バーコードの6桁目(税区分)≠0(総額)の場合
      if(taxcde !== 0){
        setRowBtn(row, jankng);             // 売単価

        if(headerItem.KAKEZEIRTU !== ''){
          //原単価＝売価×掛け率
          let gentanka = jankng * Number(headerItem.KAKEZEIRTU) / 100;
          setRowGnt(row, gentanka);          // 原単価
        }
      }
      //③２段目バーコードの金額が０の場合。
      //設定済

    }

    //２）ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005もしくは、「２１」の場合
    if((jan1cd === '22' && state.KSACDES === '005') || jan1cd ==='21') {
      //(1)ＪＡＮ１の前２桁が「２２」かつ引数.会社コードS=005の場合
      if(jan1cd === '22' && state.KSACDES === '005') {
        const ASTCDE = Jan2cde.substring(2, 5);                      // ＡＵ
        const LINCDE = inJAN1.current.value.substring(2, 3);         // ライン
        const CLSCDE = '000' + inJAN1.current.value.substring(3, 4); // クラス
        const TAGSZN = inJAN1.current.value.substring(4, 5);         // タグシーズン
        const TAGITM = inJAN1.current.value.substring(5, 8);         // タグアイテム
        const TAGSIZCDE = inJAN1.current.value.substring(8, 10);     // サイズコード
        const TAGCOLCDE = inJAN1.current.value.substring(10, 12);    // カラーコード

        //①タグマスタから情報取得
        const getTagMaster = await fetch('/TCGT0110/getTagMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,MISE:state.MISES,ASTCDE:ASTCDE,LINCDE:LINCDE,CLSCDE:CLSCDE,TAGSZN:TAGSZN,TAGITM:TAGITM
        })});
        tagMaster = await getTagMaster.json();

        if (tagMaster.ret.length === 0){
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          return;
        }

        // 仕入先名称取得
        if (tagMaster.ret.length !== 0) {
          row.SIRCDE = tagMaster.ret[0].SIRCDE;     // 仕入先(取引先)コード
          const getSirmeik = await fetch('/TCGT0110/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:row.SIRCDE})});
          const sirmeik = await getSirmeik.json();
          if(sirmeik !== null) {
            row.SIRMEIK = sirmeik.SIRMEIK;          // 仕入先(取引先)名
          }
        }

        //② 衣料サイズマスタから情報取得
        const getSizeMaster = await fetch('/TCGT0110/getSizeMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE:state.KSACDES,BMNCDE:header.BMNCDE,ASTCDE:ASTCDE,LINCDE:LINCDE,TAGSIZCDE:TAGSIZCDE
        })});
        const sizeMaster = await getSizeMaster.json();
        if(sizeMaster.ret.length !== 0) {
          row.TAGSIZ = sizeMaster.ret[0].TAGSIZCDE;     // サイズコード
          row.TAGSIZMEI = sizeMaster.ret[0].TAGSIZMEI;  // サイズ名カナ
        } else {
          row.TAGSIZ = '';     // サイズコード
          row.TAGSIZMEI = '';  // サイズ名カナ
        }

        //③ 衣料カラーマスタから情報取得
        const getColorMaster = await fetch('/TCGT0110/getColorMaster',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({KSACDE:state.KSACDES,TAGCOLCDE:TAGCOLCDE})});
        const colorMaster = await getColorMaster.json();
        if(colorMaster.ret.length !== 0) {
          row.TAGCOLCDE = colorMaster.ret[0].TAGCOLCDE; // カラーコード
          row.TAGCOLMEI = colorMaster.ret[0].TAGCOLMEI; // カラー名カナ
        } else {
          row.TAGCOLCDE = ''; // カラーコード
          row.TAGCOLMEI = ''; // カラー名カナ
        }

        // 2023/10/06
        if(header.BMNCDE !== tagMaster.ret[0].BMNCDE) {
          console.log('header02:%o',header);
          setJAN1clr('#f59d9d');
          inJAN1.current.focus();
          window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
          return;
        }
        setRow_Two(row, tagMaster.ret[0]);
      }
      //(2) ＪＡＮ１の前２桁が「２１」の場合
      if (jan1cd === '21') {
        // 桁数チェック
        if(Jan2cde.length !== 13){
          setJAN2clr('#f59d9d');
          window.alert('ＪＡＮ２入力エラー。'); //TCGE0047：
          return;
        }

        //① 商品マスタ(基本)から情報取得
        const EosCode = inJAN1.current.value.substring(4, 12);
        const getShohinMaster = await fetch('/TCGT0110/getShohinMaster_Eoscode',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({row:rowItem, EOSCDE:EosCode})});
        shohinMaster = await getShohinMaster.json();

        if (shohinMaster.ret.length === 0){
          setJAN2clr('#f59d9d');
          window.alert('商品が存在しません。'); //TCGE0047：
          return;
        }

        row.EOSCDE = EosCode;
        //setRowGnt(row, shohinMaster.ret[0].GNT);           // 原単価
        setRowBtn(row, shohinMaster.ret[0].BTN);           // 売単価
        if(headerItem.KAKEZEIRTU !== ''){
          //原単価＝売価×掛け率
          let gentanka = Number(shohinMaster.ret[0].BTN) * Number(headerItem.KAKEZEIRTU) / 100;
          setRowGnt(row, gentanka);          // 原単価
        }else{
          setRowGnt(row, shohinMaster.ret[0].GNT);          // 原単価
        }

        //② 返品不可判定
        if (shohinMaster.ret[0].HPN_FKA_KBN === '1') {
          if(!(window.confirm('警告：この商品は返品不可扱いです。強制返品しますか？'))){  //TCGC0003：
            setJAN1clr('#f59d9d');
            inJAN1.current.focus();
            setTimeout(() => {inJAN1.current.focus();},100);
            setRowItem(row);
            return;
          }
        }

        // 仕入先名称取得
        if (shohinMaster.ret.length !== 0) {
          row.SIRCDE = shohinMaster.ret[0].SIRCDE;  // 仕入先(取引先)コード
          const getSirmeik = await fetch('/TCGT0110/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:row.SIRCDE})});
          const sirmeik = await getSirmeik.json();
          if(sirmeik !== null) {
            row.SIRMEIK = sirmeik.SIRMEIK;          // 仕入先(取引先)名
          }
        }

        // 2023/10/06
        if(header.BMNCDE !== shohinMaster.ret[0].BMNCDE) {
          console.log('header02:%o',header);
          setJAN1clr('#f59d9d');
          inJAN1.current.focus();
          window.alert('部門の異なる商品は入力できません。'); //TCGE0048：
          return 1;
        }

        setRow_Two2(row, shohinMaster.ret[0]);
      }

      //(3) 計算
      const jankng = Number(Jan2cde.substring(6, 12));
      setRowBtn(row, jankng);           // 売単価

      if(headerItem.KAKEZEIRTU !== ''){
        //原単価＝売価×掛け率
        let gentanka = jankng * Number(headerItem.KAKEZEIRTU) / 100;
        setRowGnt(row, gentanka);          // 原単価
      }
    }

    // 2023/10/06
    setDisRow(false);
    setRowItem(row);
    inputNxRef.current.focus();
  }

  // データセット（2段）
  function setRow_Two(row, tagMaster) {
    row.SHNMEIKN = tagMaster.SHNMEIKN;  // 商品名
    row.KIKAKUKN = tagMaster.KIKAKUKN;  // 規格
    setRowSuu(row, '1');                // 数量
    //setRowGnt(row, tagMaster.GNK);      // 原単価
    row.GNKKIN = 0;                     // 原価金額
    setRowBtn(row, tagMaster.BAK);      // 売単価
    row.BAKKIN = 0;                     // 売価金額

    if(headerItem.KAKEZEIRTU !== ''){
      //原単価＝売価×掛け率
      let gentanka = Number(tagMaster.BAK) * Number(headerItem.KAKEZEIRTU) / 100;
      setRowGnt(row, gentanka);          // 原単価
    }else{
      setRowGnt(row, tagMaster.GNK);      // 原単価
    }

    //row.JANCDE2 = inJAN2.current.value; // ＪＡＮコード２
    row.TAGASTCDE = tagMaster.ASTCDE;   // タグＡＵ
    row.TAGLINCDE = tagMaster.LINCDE;   // タグライン
    row.TAGCLSCDE = tagMaster.CLSCDE;   // タグクラス
    row.TAGSZN = tagMaster.TAGSZN;      // タグシーズン
    row.TAGITM = tagMaster.TAGITM;      // タグアイテム
    row.TRIHBN = tagMaster.TRIHBN;      // 取引先品番
  }

  // データセット（2段）2
  function setRow_Two2(row, shohinMaster) {
    row.SHNMEIKN = shohinMaster.SHNMEIKN;        // 商品名
    row.KIKAKUKN = shohinMaster.KIKAKUKN;        // 規格
    setRowSuu(row, '1');                         // 数量
    //setRowGnt(row, shohinMaster.GNT);            // 原単価
    row.GNKKIN = 0;                              // 原価金額
    setRowBtn(row, shohinMaster.BTN);            // 売単価
    row.BAKKIN = 0;                              // 売価金額

    if(headerItem.KAKEZEIRTU !== ''){
      //原単価＝売価×掛け率
      let gentanka = Number(shohinMaster.BTN) * Number(headerItem.KAKEZEIRTU) / 100;
      setRowGnt(row, gentanka);          // 原単価
    }else{
      setRowGnt(row, shohinMaster.GNT);      // 原単価
    }

    //row.JANCDE2 = inJAN2.current.value;          // ＪＡＮコード２
    row.TAGASTCDE = shohinMaster.ASTCDE;         // タグＡＵ
    row.TAGLINCDE = shohinMaster.LINCDE;         // タグライン
    row.TAGCLSCDE = shohinMaster.CLSCDE;         // タグクラス
    row.TAGSZN = shohinMaster.TAGSZN;            // タグシーズン
    row.TRIHBN = shohinMaster.TRIHBN;            // 取引先品番
    row.TAGSIZ = shohinMaster.TAGSIZCDE;         // サイズコード
    row.TAGSIZMEI = shohinMaster.TAGSIZMEI;      // サイズ名カナ
    row.TAGCOLCDE = shohinMaster.TAGCOLCDE;      // カラーコード
    row.TAGCOLMEI = shohinMaster.TAGCOLMEI;      // カラー名カナ
  }

  // ヘッダ部２入力 数量
  const onKeyDown_SUU = async(inputRef, inputNxRef) => {
    // 数量チェック
    if(rowItem.SUU === 0 || rowItem.SUU2 === '') {
      setSUUclr('#f59d9d');
      window.alert('数量に０は入力できません。'); //TCGE0063：
      return;
    }

    //次の項目に遷移
    inputNxRef.current.focus();
  }

  // ヘッダ部２入力　原単価
  const onKeyDown_GNT =  async (inputRef, inputNxRef) => {
    // 数量チェック
    if(rowItem.SUU === 0 || rowItem.SUU2 === '') {
      setSUUclr('#f59d9d');
      window.alert('数量に０は入力できません。'); //TCGE0063：
      return;
    }

    // (1) 数値チェック
    if(rowItem.GNT === 0 || rowItem.GNT2 === '') {
      setGNTclr('#f59d9d');
      window.alert('原単価に０円は入力できません。'); //TCGE0049：
      return;
    }

    //  四捨五入
    let gnkkin = rowItem.SUU * rowItem.GNT;
    gnkkin = Math.round(gnkkin);

    // (2) 1千万越えチェック
    if(gnkkin > 10000000){
      setGNTclr('#f59d9d');
      window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
      return;
    }

    // (3) 100万越えチェック
    if(gnkkin > 1000000){
      if(!(window.confirm('１００万円を超える金額が入力されました。'))){  //TTCGC0004：
        setGNTclr('#f59d9d');
        return;
      }
    }

    //次の項目に遷移
    inputNxRef.current.focus();
  }

  // ヘッダ部２入力　売単価
  const onKeyDown_BTN =  async (inputRef, inputNxRef) => {
    // 数量チェック
    if(rowItem.SUU === 0 || rowItem.SUU2 === '') {
      setSUUclr('#f59d9d');
      window.alert('数量に０は入力できません。'); //TCGE0063：
      return;
    }

    let row = JSON.parse(JSON.stringify(rowItem));
    if(headerItem.KAKEZEIRTU !== ''){
      //原単価＝売価×掛け率
      let gentanka = row.BTN * Number(headerItem.KAKEZEIRTU) / 100;
      setRowGnt(row, gentanka);          // 原単価
      setRowItem(row);
    }

    // (1) 数値チェック
    if (row.BTN === 0 || row.BTN2 === ''){
      if(!window.confirm('売単価に０円が入力されています。よろしいですか？')){  //TCGC0007：
        setBTNclr('#f59d9d');
        return;
      }
    }

    // (2) 売単価 ＞ 原単価×10　チェック
    if (row.BTN > (row.GNT * 10)) {
      if(!window.confirm('売単価が原単価に比べて大きすぎます。よろしいですか？')){  //TCGC0008：
        setBTNclr('#f59d9d');
        return;
      }
    };

    // (3) 売単価 ＜ 原単価÷10 チェック
    if ((row.BTN < (row.GNT / 10)) && row.BTN !== 0) {
      if(!window.confirm('売単価が原単価に比べて小さすぎます。よろしいですか？')){  //TCGC0009：
        setBTNclr('#f59d9d');
        return;
      }
    };

    const bakkin = row.SUU * row.BTN;

    // (4) 1千万越えチェック
    if(bakkin > 10000000){
      setBTNclr('#f59d9d');
      window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
      return;
    }

    // (5) 100万越えチェック
    if(bakkin > 1000000){
      if(!(window.confirm('１００万円を超える金額が入力されました。'))){  //TTCGC0004：
        setBTNclr('#f59d9d');
        return;
      }
    }

    if (prefectureList.length !==0) {
      //次の項目に遷移
      inSANTIKBN.current.focus();
    } else {
      Header2_confirmed(row);
    }
  }

  // ヘッダ部2入力　産地区分を選択の処理
  // コンボボックスでEnterキーのイベントを補足できない模様
  // 代わりに産地区分が変更されたら検索を行う
  useEffect(() => {
    console.log('更新掛かってる1？'+headerItem.BMNCDE+'/'+enableSearch);
    if (!enableSearch || enableId != rowItem.id) { // 23/09/25 idチェック追加（表示クリア時のチェンジイベント回避）
      return;
    }
    if(rowItem.SANTI_KBN === '　'){
      const row = JSON.parse(JSON.stringify(rowItem));
      Header2_confirmed(row);
      return;
    }
    inTODOFUKENKUNI.current.focus();
  }, [rowItem.SANTI_KBN]);

  // ヘッダ部2入力　都道府県/国名を選択の処理
  // コンボボックスでEnterキーのイベントを補足できない模様
  // 代わりに都道府県/国名が変更されたら検索を行う
  useEffect(()=>{
    console.log('更新掛かってる2？'+headerItem.BMNCDE+'/'+enableSearch);
    if (!enableSearch || enableId != rowItem.id) { // 23/09/25 idチェック追加（表示クリア時のチェンジイベント回避）
      return;
    }
    if(headerItem.BMNCDE === '1110' || headerItem.BMNCDE === '1120'){
      const row = JSON.parse(JSON.stringify(rowItem));
      Header2_confirmed(row);
      return;
    }
    inGENSANTI.current.focus();
  }, [rowItem.TODOFUKEN_KUNI]);

  // ヘッダ部2入力　原産地を選択の処理
  // コンボボックスでEnterキーのイベントを補足できない模様
  // 代わりに原産地が変更されたら検索を行う
  useEffect(()=>{
    if (!enableSearch || enableId != rowItem.id) { // 23/09/25 idチェック追加（表示クリア時のチェンジイベント回避）
      return;
    }
    inSKHLBLKBN.current.focus();
  }, [rowItem.GENSANTI]);

  // ヘッダ部2入力　食品区分を選択の処理
  // コンボボックスでEnterキーのイベントを補足できない模様
  // 代わりに食品区分が変更されたら検索を行う
  useEffect(()=>{
    console.log('更新掛かってる3？'+rowItem.SKHLBLKBN);
    if (!enableSearch || enableId != rowItem.id) { // 23/09/25 idチェック追加（表示クリア時のチェンジイベント回避）
      return;
    }
    const row = JSON.parse(JSON.stringify(rowItem));
    Header2_confirmed(row);
  }, [rowItem.SKHLBLKBN]);

  // ヘッダ部２入力　ヘッダ部２確定時
  const Header2_confirmed = async (row) => {
    console.log('Header2_confirmed_01:%o',row);
    const header = JSON.parse(JSON.stringify(headerItem));
    enableId = 0;// 23/11/22 idチェック追加（表示クリア時のチェンジイベント回避）

    // ＪＡＮ１必須チェック
    if(row.JANCDE === '') {
      setJAN1clr('#f59d9d');
      window.alert('ＪＡＮ１を入力して下さい。');
      inJAN1.current.focus();
      return;
    }

    // 計算
    row.GNKKIN = Math.round(row.SUU * row.GNT);  // 原価金額
    row.BAKKIN = row.SUU * row.BTN;              // 売価金額

    // 数量チェック
    if(rowItem.SUU === 0 || rowItem.SUU2 === '') {
      setSUUclr('#f59d9d');
      window.alert('数量に０は入力できません。'); //TCGE0063：
      inSUU.current.focus();
      return;
    }

    // 入力チェック 原単価
    if(row.GNT === 0 || row.GNT2 === '') {
      setGNTclr('#f59d9d');
      window.alert('原単価に０円は入力できません。'); //TCGE0049：
      inGNT.current.focus();
      return;
    }
    // 1千万越えチェック
    if(row.GNKKIN > 10000000){
      setGNTclr('#f59d9d');
      setSUUclr('#f59d9d');
      window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
      inSUU.current.focus();
      return;
    }

    // 入力チェック 売単価
    // 1千万越えチェック
    if(row.BAKKIN > 10000000){
      setBTNclr('#f59d9d');
      window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
      inSUU.current.focus();
      return;
    }

    // 新規追加時の処理
    if(row.id === rows.length){
      // 入力内容の重複確認
      let new_rows = JSON.parse(JSON.stringify(rows));
      const old_row = new_rows.filter(element => {
        return element.JANCDE === row.JANCDE
        && element.GNT === row.GNT
        && element.BTN === row.BTN
      });

      // 既に入力済の内容であれば合算値を確認する
      if (old_row.length !== 0) {
        // 合計数量計算
        let totalSuu = row.SUU;
        for(const ckRow of old_row) {
          totalSuu = totalSuu + ckRow.SUU;
        }

        // 原価金額合計 金額オーバーチェック
        let ckGnkkin = Math.round(totalSuu * row.GNT);
        if(ckGnkkin > 10000000){
          setGNTclr('#f59d9d');
          window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
          inSUU.current.focus();
          return;
        }

        // 売価金額合計 金額オーバーチェック
        let ckBakkin = totalSuu * row.BTN;
        if(ckBakkin > 10000000){
          setBTNclr('#f59d9d');
          window.alert('合計で１千万円を超える金額は入力できません。'); //TCGE0060：
          inSUU.current.focus();
          return;
        }
      }

      // 明細リストの合計を再計算する。
      header.SRYFOT = Number(header.SRYFOT) + row.SUU;
      header.GNKFOT = Number(header.GNKFOT) + Number(row.GNKKIN);
      header.BAKFOT = Number(header.BAKFOT) + Number(row.BAKKIN);

      // 新規ページを用意する
      let new_row = await JSON.parse(JSON.stringify(rowData));
      // 新規ページのid設定
      new_row.id = rows.length + 1;
      new_rows = await new_rows.map((target) => (target.id === row.id ? row :target));
      setRowItem(new_row);
      setRows(() => ([...new_rows, new_row]));
      setHeaderItem(header);
      inJAN1.current.focus();
    }
    // 編集時の処理
    else{
      // 新しく保存する明細リスト
      let new_rows = JSON.parse(JSON.stringify(rows));
      new_rows = await new_rows.map((target) => (target.id === row.id ? row : target));

      // 入力内容の重複確認
      const old_row = new_rows.filter(element => {
        return element.JANCDE === row.JANCDE
        && element.GNT === row.GNT
        && element.BTN === row.BTN
      });

      // 同じ値のデータ合算値を確認する
      if (old_row.length !== 0){
        // 合計数量計算
        let totalSuu = 0;
        for(const ckRow of old_row) {
          totalSuu = totalSuu + ckRow.SUU;
        }

        // 原価金額合計 金額オーバーチェック
        let ckGnkkin = Math.round(totalSuu * row.GNT);
        if(ckGnkkin > 10000000){
          setGNTclr('#f59d9d');
          window.alert('１千万円を超える金額は入力できません。'); //TCGE0050：
          inSUU.current.focus();
          return;
        }

        // 売価金額合計 金額オーバーチェック
        let ckBakkin = totalSuu * row.BTN;
        if(ckBakkin > 10000000){
          setBTNclr('#f59d9d');
          window.alert('合計で１千万円を超える金額は入力できません。'); //TCGE0060：
          inSUU.current.focus();
          return;
        }
      }

      // 明細リストの合計を再計算する。
      let totalSuryo = 0;   // 合計数量
      let totalGenka = 0;   // 原価金額合計
      let totalBaika = 0;   // 売価金額合計

      new_rows.forEach(element => {
        totalSuryo += element.SUU;
        totalGenka += Number(element.GNKKIN);
        totalBaika += Number(element.BAKKIN);
      });

      header.SRYFOT = totalSuryo;
      header.GNKFOT = totalGenka;
      header.BAKFOT = totalBaika;

      // 編集中データを反映する。
      await setRows(new_rows);
      setRowItem(row);
      setHeaderItem(header);
      inSUU.current.focus();
    }
  }

  // フッタ部　前商品
  const onClick_PreviousPage = async () => {
    // データが編集中か確認する。
    const now_rows = rows.filter(element => {
      return element.id === rowItem.id;
    });
    // if(now_rows[0] !== rowItem){
    //   if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))){  //TCGM0007：
    //     return;
    //   }
    // }
    let flg = 0;
    Object.keys(now_rows[0]).map((key)=>{
      if(now_rows[0][key] !== rowItem[key]){
        flg++;
      }
    });
    if(flg > 0){
      if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))){  //TCGM0007：
        return;
      }
    }

    // 移動先データを探す
    setJAN1clr('#FFFFFF');
    setJAN2clr('#FFFFFF');
    setSUUclr('#FFFFFF');
    setGNTclr('#FFFFFF');
    setBTNclr('#FFFFFF');
    const target_id = rowItem.id - 1;
    const target_rows = rows.filter(element => {
      return element.id === target_id;
    });
    if(target_rows.length > 0){
      // 対象があれば移動する
      setDisRow(false);
      setJan1(true);
      setJan2(true);
      await setRowItem(target_rows[0]);
    }
    inSUU.current.focus();
  }

  // フッタ部　次商品
  const onClick_NextPage = async () => {
    // データが編集中か確認する。
    const now_rows = rows.filter(element => {
      return element.id === rowItem.id;
    });
    // if(now_rows[0] !== rowItem){
    //   if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))){  //TCGM0007：
    //     return;
    //   }
    // }
    let flg = 0;
    Object.keys(now_rows[0]).map((key)=>{
      if(now_rows[0][key] !== rowItem[key]){
        flg++;
      }
    });
    if(flg > 0){
      if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))){  //TCGM0007：
        return;
      }
    }

    // 移動先データを探す
    setJAN1clr('#FFFFFF');
    setJAN2clr('#FFFFFF');
    setSUUclr('#FFFFFF');
    setGNTclr('#FFFFFF');
    setBTNclr('#FFFFFF');
    const target_id = rowItem.id + 1;
    const target_rows = rows.filter(element => {
      return element.id === target_id;
    });
    console.log('対象ありなし:%o',target_rows);
    if(target_rows.length > 0){
      // 対象があれば移動する
      await setRowItem(target_rows[0]);
      if (target_rows[0].JANCDE === '')
      {
        setDisRow(true);
        setJan1(false);
        setJan2(true);
        setTimeout(() => {inJAN1.current.focus();},100);
        //inJAN1.current.focus();
      }else{
        setDisRow(false);
        setJan1(true);
        setJan2(true);
        setTimeout(() => {inSUU.current.focus();},100);
      }
    }else{
      setDisRow(true);
      setJan1(false);
      setJan2(true);
      setTimeout(() => {inJAN1.current.focus();},100);
    }

  }

  // フッタ部　最後尾
  const onClick_LastPage = async () => {
    // データが編集中か確認する。
    const now_rows = rows.filter(element => {
      return element.id === rowItem.id;
    });
    // if(now_rows[0] !== rowItem){
    //   if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))){  //TCGM0007：
    //     return;
    //   }
    // }
    let flg = 0;
    Object.keys(now_rows[0]).map((key)=>{
      if(now_rows[0][key] !== rowItem[key]){
        flg++;
      }
    });
    if(flg > 0){
      if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))){  //TCGM0007：
        return;
      }
    }

    // 移動先データを探す
    setJAN1clr('#FFFFFF');
    setJAN2clr('#FFFFFF');
    setSUUclr('#FFFFFF');
    setGNTclr('#FFFFFF');
    setBTNclr('#FFFFFF');
    const target_id = rows.length;
    const target_rows = rows.filter(element => {
      return element.id === target_id;
    });
    if(target_rows.length > 0){
      // 対象があれば移動する
      setDisRow(true);
      setJan1(false);
      setJan2(true);
      await setRowItem(target_rows[0]);
    }
    inJAN1.current.focus();
  }

  // 23/09/25　onClick処理追加
  // フッタ部　戻る
  const onClick_BackPage = async () => {
    if(rows.length !== 1 || inJAN1.current.value !== '')
    {
      if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))) { //TCGM0007：
        return;
      }
    }
    setRowItem(rowData);
    setRows([]);
    setHeaderItem(headerData);
    setHeaderHASDD();
    setScreenPhase(1)
  }

  // フッタ部　再入力
  const onClick_ReEnterPage = async () => {
    if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))) { //TCGM0007：
      return;
    }

    const target_id = rowItem.id;

    // 最終ページに移動する。
    const target_row = rows.filter(element => {
      return element.id === target_id;
    });
    await setRowItem(target_row[0]);
    inJAN1.current.focus();
  }

  // フッタ部　削除
  const onClick_DeletePage = async () => {
    setJAN1clr('#FFFFFF');
    setJAN2clr('#FFFFFF');
    setSUUclr('#FFFFFF');
    setGNTclr('#FFFFFF');
    setBTNclr('#FFFFFF');
    const target_id = rowItem.id;

    if(rows.length === rowItem.id) {
      if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))) { //TCGM0007：
        return;
      }else{
        // 最終ページに移動する。
        const target_row = rows.filter(element => {
          return element.id === target_id;
        });
        await setRowItem(target_row[0]);
        inJAN1.current.focus();
        return;
      }
    } else{
      // 削除除処理を行うか確認メッセージを表示する
      const del_row = rows.filter(element => {
        return element.id !== target_id;
      });
      if (!(window.confirm(''+ del_row[0].SHNMEIKN +'を削除します。よろしいですか？'))) { //TCGM0008：
        return;
      }
    }

    // 指定行を取り除く
    const target_rows = rows.filter(element => {
      return element.id !== target_id;
    });

    // 合計額再計算
    const header = JSON.parse(JSON.stringify(headerItem));
    let totalSuryo = 0;   // 合計数量
    let totalGenka = 0;   // 原価金額合計
    let totalBaika = 0;   // 売価金額合計
    let id = 1;
    target_rows.forEach(element => {
      element.id = id;
      totalSuryo += Number(element.SUU);
      totalGenka += Number(element.GNKKIN);
      totalBaika += Number(element.BAKKIN);
      id += 1;
    });
    header.SRYFOT = totalSuryo;
    header.GNKFOT = totalGenka;
    header.BAKFOT = totalBaika;

    await setRows(target_rows);

    // 最終ページに移動する。
    const last_row = target_rows.filter(element => {
      return element.id === target_rows.length;
    });
    await setRowItem(last_row[0]);

    inJAN1.current.focus();
  }

  // フッタ部　登録
  const onClick_Send = async()=> {
    setJAN1clr('#FFFFFF');
    setJAN2clr('#FFFFFF');
    setSUUclr('#FFFFFF');
    setGNTclr('#FFFFFF');
    setBTNclr('#FFFFFF');

    if ((window.confirm('データの更新を行います。よろしいですか？'))) { //TCGM0009：
      const header = JSON.parse(JSON.stringify(headerItem));
      let target_rows = JSON.parse(JSON.stringify(rows));

      // 最終ページ（新規ページ）を取り除く
      target_rows = target_rows.filter(x => x.id !== rows.length);

      // 重複行を合算する処理
      let combinedList = [];
      for(const add_row of target_rows) {
        // 入力内容の重複確認
        const matched_row = combinedList.filter(element => {
          return element.JANCDE === add_row.JANCDE
          && element.GNT === add_row.GNT
          && element.BTN === add_row.BTN
        });

        // 既に登録済の内容であれば数を合算する
        if (matched_row.length !== 0) {
          matched_row[0].SUU = matched_row[0].SUU + add_row.SUU;
          matched_row[0].GNKKIN = Math.round(matched_row[0].SUU * add_row.GNT); // 原価金額
          matched_row[0].BAKKIN = matched_row[0].SUU * add_row.BTN;             // 売価金額
          combinedList = combinedList.map((target) => (target === matched_row ? matched_row[0] : target));
        }else{
          // 登録済みで無ければ行追加
          combinedList.push(add_row);
        }
      }

      let d = new Date(state.SDAY);
      let year = d.getFullYear().toString();
      let month = (d.getMonth() + 1).toString().padStart(2, '0');
      let day = d.getDate().toString().padStart(2, '0');
      header.DENYMD = year + month + day;

      // SEQの取得
      const getSeqNo = await fetch('/TCGT0110/getSeqNo',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({header:header})});
      const resseqno = await getSeqNo.json();

      // 登録用のボディデータ作成
      const updateHeder = [];
      const updateRows = [];
      let pushRowlist = [];
      let denno = await getNowDenno(header);
      let hederSEQ = Number(resseqno.SEQ) + 1;
      let checkGenka = 0;
      let checkBaika = 0;
      let count = 1;
      // 10/13 登録時のバグ修正
      // await Promise.all(target_rows.map(async(row) =>{
      for(const row of combinedList){
        console.log('row:%o',row);
        if(count > 20
            || checkGenka + Number(row.GNKKIN) > 10000000
            || checkBaika + Number(row.BAKKIN) > 10000000
        ) {
          // 20件、もしくは合計原価、合計売価が1千万を超える場合とSEQ番号を変更する。
          updateRows.push(pushRowlist);
          pushRowlist = [];
          count = 1;
          checkGenka = 0;
          checkBaika = 0;
          denno = await getNowDenno(header);
        }
        row.ASTCDE = header.ASTCDE;
        row.GYONO = count;
        row.SEQ = hederSEQ;
        row.DENNO = denno;
        row.BMNCDE = header.BMNCDE;
        row.DENYMD = header.DENYMD;
        row.DEN_EDABAN = header.DEN_EDABAN;
        row.JGNCDE = header.JGNCDE;
        row.PRG_ID = header.PRG_ID;

        checkGenka += Number(row.GNKKIN);
        checkBaika += Number(row.BAKKIN);
        pushRowlist.push(row);
        count++;
      };
      //}));
      updateRows.push(pushRowlist);

      // 登録用のヘッダデータ作成
      updateRows.forEach(rows => {
        const pushHeder = JSON.parse(JSON.stringify(header));
        pushHeder.SEQ = rows[0].SEQ;
        pushHeder.DENNO = rows[0].DENNO;
        let totalSuryo = 0;   // 合計数量
        let totalGenka = 0;   // 原価金額合計
        let totalBaika = 0;   // 売価金額合計
        rows.forEach(row => {
          totalSuryo += row.SUU;
          totalGenka += Number(row.GNKKIN);
          totalBaika += Number(row.BAKKIN);
        });
        pushHeder.SRYFOT = totalSuryo;
        pushHeder.GNKFOT = totalGenka;
        pushHeder.BAKFOT = totalBaika;
        updateHeder.push(pushHeder);
      })

      // データの更新
      const response = await fetch('/TCGT0110/send',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          header:updateHeder,
          rows:updateRows,
      })});

      const data = await response.json();
      if (data.res === -1) {
        window.alert('登録エラー');
        return;
      }else{
        const header2 = headerData;
        header2.KBN = header.KBN;
        header2.BMNCDE = header.BMNCDE;
        header2.BMNMEI = header.BMNMEI;
        header2.HASDD = header.HASDD;
        header2.HASDD2 = header.HASDD2;
        header2.UIR_MISE = header.UIR_MISE;
        header2.UIR_MISEMEI = header.UIR_MISEMEI;
        header2.UIR_BMNCDE = header.UIR_BMNCDE;
        header2.UIR_BMNMEI = header.UIR_BMNMEI;
        header2.KAKEZEIRTU = header.KAKEZEIRTU;
        await setHeaderItem(header2);
        const row2 = rowData;
        row2.id = 1;
        setRowItem(row2);
        setRows([row2]);

        inJAN1.current.focus();
        //setTimeout(() => {inJAN1.current.select();},100);
        onNextFocus(inJAN1);
        return;
      }
    }
  }

  const getNowDenno = async(header) => {
    // 採番マスタ取得
    let denpyoNo = {
      KSACDE:header.KSACDE,     // 会社コード
      MISE:header.MISE,         // 店コード
      MR_KBN:'1',               // 移動返品区分
      MIN_VLU:'1',              // 最小値
      MAX_VLU:'999',            // 最大値
      JGNCDE:header.JGNCDE,     // ユーザーID
      PRG_ID:header.PRG_ID,     // プログラムID
      TRL_ID:header.TRL_ID,     // 端末ＩＤ
      DELFLG:'0'                // 削除フラグ
    }

    // 採番マスタ取得
    const getDenNo = await fetch('/TCGT0110/getDenNo',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({denpyoNo:denpyoNo})});
    const resdenno = await getDenNo.json();
    const NOW_DENNO = resdenno.denno.toString().padStart(3, '0');

    // 伝票番号の取得
    return '0' + state.MISES + NOW_DENNO;
  }

  return (
    <Grid>
      <Stack direction='row' >
        <InputLabel variant={'outlined'} style={{ textAlign: 'right' }}>
          {headerItem.BMNCDE + '部門　→　' + headerItem.UIR_MISE + '店' + headerItem.UIR_BMNCDE + '部門'}
        </InputLabel>
      </Stack>
      <br />
      <Stack direction='row' >
        <NumericFormat  //JAN1
          id={'JAN1'}
          inputRef={inJAN1}                //項目名定義？
          color='success'
          autoFocus                           //初期カーソル位置設定
          thousandSeparator={false}           //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='ＪＡＮ'                      //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '17ch', background:inJAN1clr}}                  //textboxの幅
          value={rowItem.JANCDE}              //初期値
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={disJan1}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          onChange={(e)=>{
            const row = JSON.parse(JSON.stringify(rowData));
            row.id = rowItem.id;
            row.JANCDE = e.target.value;
            setRowItem(row);
            setChkJAN1('0');
            setDisRow(true);
          }}
          onKeyDown={(e) => onKeyDown(e.key,inJAN1,inJAN1,inSUU)}
          onFocus={(e) => {
            if (inJAN1.current !==null && typeof inJAN1.current !== 'undefined')
            {
              onMyFocus(e);
            }
          }}
          onBlur={() => onblur(inJAN1)}
        />
      </Stack>
      <Stack direction='row' >
        <NumericFormat  //JAN2
          id={'JAN2'}
          inputRef={inJAN2}                   //項目名定義？
          color='success'
          thousandSeparator={false}           //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label=''                            //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '17ch', background:inJAN2clr}}               //textboxの幅
          value={rowItem.JANCDE2}             //初期値
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={disJan2}                  //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          onChange={(e)=>{
            const row = JSON.parse(JSON.stringify(rowData));
            row.id = rowItem.id;
            row.JANCDE = rowItem.JANCDE;
            row.IN_CD_KBN = rowItem.IN_CD_KBN;
            row.IRYO_FLG = rowItem.IRYO_FLG;  //10/25
            row.EOSCDE = rowItem.EOSCDE;      //10/25
            row.JANCDE2 = e.target.value;
            setRowItem(row);
            setChkJAN2('0');
            setDisRow(true);
          }}
          onKeyDown={(e) => onKeyDown(e.key,inJAN2,inJAN1,inSUU)}
          onFocus={(e) => onMyFocus(e)}
          onBlur={() => onblur(inJAN2)}
        />
      </Stack>
      <br />
      <Stack direction='row'>
        <TextField  //商品名
          id={'SHNMEI'}
          inputRef={inSHNMEI}                   //項目名定義？
          color='success'
          label='商品名'                         //textboxの左上に表示するラベル
          multiline
          maxRows={3}
          variant='outlined'                  //枠の表示方法
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '50ch'}}                 //textboxの幅
          value={valueSHNMEI}               //初期値
          disabled={false}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 200,style: {textAlign: 'left'} }}  //補完,最大文字数,左寄せ
          onKeyDown={(e) => onKeyDown(e.key,inSHNMEI,inJAN1,inSUU)}
          onFocus={(e) => onMyFocus(e)}
        />
      </Stack>
      <br />
      <Stack direction='row'  spacing={3}>
        <NumericFormat  //数量
          id={'SUU'}
          inputRef={inSUU}
          color='success'
          thousandSeparator={','}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={true}            //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={1}                    //[PDA、Tablet対応]少数部の桁[0]
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='数量'                         //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '13ch',background:inSUUclr}}                //textboxの幅
          value={rowItem.SUU2}                  //初期値
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={disRow}                    //使用可／不可
          inputProps={{autoComplete: 'off',maxLength: 9,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
          isAllowed={(values) => {
            const numValue = Number(values.value);
            if (isNaN(numValue)) return true;
            return numValue >= 0 && numValue <= 99999.9;
          }}
          onValueChange={(values, sourceInfo) => {
            const row = JSON.parse(JSON.stringify(rowItem));
            row.SUU = values.floatValue||0;
            row.SUU2 = values.formattedValue||'0.0';
            setRowItem(row);
          }}
          // onChange={(e)=>{
          //   const row = JSON.parse(JSON.stringify(rowItem));
          //   // let suu = Number(e.target.value);
          //   let suu = Number(String(e.target.value||0).replaceAll(',',''));
          //   if (isNaN(suu) || e.target.value === ''){
          //     row.SUU = 0;
          //     row.SUU2 = '0.0';
          //   }
          //   else{
          //     row.SUU = suu;
          //     row.SUU2 = suu.toLocaleString(undefined,{minimumFractionDigits : 1});
          //   }
          //   setRowItem(row);
          // }}
          onKeyDown={(e) => onKeyDown(e.key,inSUU,inJAN1,inGNT)}
          onFocus={(e) => onMyFocus(e)}
        />
        <FormControl
          sx={{
            minWidth: 135,
            maxWidth: 135,
            mr:'10px',
            visibility: prefectureList.length !== 0 ? 'visible' : 'hidden'
          }}
          size='small'
        >
          <InputLabel id='select-label' color='success'>産地区分</InputLabel>
          <Select
            inputRef={inSANTIKBN}
            color='success'
            defaultValue={''}
            labelId='select-label'
            id='select-label'
            value={rowItem.SANTI_KBN}
            label='産地区分'
            onFocus={(e) => {
              setEnableSearch(true);
              enableId = rowItem.id;
              //setEnableId(rowItem.id);// 23/09/25 idチェック追加（表示クリア時のチェンジイベント回避）
            }}
            onBlur={(e) => setEnableSearch(false)}
            disabled={disRow}                    //使用可／不可
            onChange={(e)=>{
              const row = JSON.parse(JSON.stringify(rowItem));
              row.SANTI_KBN = e.target.value;
              setRowItem(row);
            }}
          >
            {
              regionList.map((region) => {
                return <MenuItem value={region.value} key={region.value}>{region.label}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </Stack>
      <br />
      <Stack direction='row' spacing={3}>
        <NumericFormat  //原単価
          id={'GNT'}
          inputRef={inGNT}
          color='success'
          thousandSeparator={','}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={true}            //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={2}                    //[PDA、Tablet対応]少数部の桁[0]
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='原単価'                         //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '13ch', background:inGNTclr}}                //textboxの幅
          value={rowItem.GNT2}                  //初期値
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={disRow}                    //使用可／不可
          inputProps={{autoComplete: 'off',maxLength: 11,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
          isAllowed={(values) => {
            const numValue = Number(values.value);
            if (isNaN(numValue)) return true;
            return numValue >= -9999999.99 && numValue <= 9999999.99;
          }}
          onValueChange={(values, sourceInfo) => {
            const row = JSON.parse(JSON.stringify(rowItem));
            row.GNT = values.floatValue||0;
            row.GNT2 = values.formattedValue||'0.00';
            setRowItem(row);
          }}
          // onChange={(e)=>{
          //   const row = JSON.parse(JSON.stringify(rowItem));
          //   // let gnt = Number(e.target.value);
          //   let gnt = Number(String(e.target.value||0).replaceAll(',',''));
          //   if (isNaN(gnt) || e.target.value === ''){
          //     row.GNT = 0;
          //     row.GNT2 = '0.00';
          //   }
          //   else{
          //     row.GNT = gnt;
          //     row.GNT2 = gnt.toLocaleString(undefined,{minimumFractionDigits : 2});
          //   }
          //   setRowItem(row);
          // }}
          onKeyDown={(e) => onKeyDown(e.key,inGNT,inSUU,inBTN)}
          onFocus={(e) => onMyFocus(e)}
        />
        <FormControl
          sx={{
            minWidth: 150,
            maxWidth: 150,
            mr:'10px',
            visibility: prefectureList.length !==0 ? 'visible' : 'hidden'
          }}
          size='small'
        >
          <InputLabel id='select-label' color='success'>都道府県/国名</InputLabel>
          <Select
            inputRef={inTODOFUKENKUNI}
            color='success'
            defaultValue={''}
            labelId='select-label'
            id='select-label'
            value={rowItem.TODOFUKEN_KUNI}
            label='都道府県/国名'
            MenuProps={{style:{maxHeight:'620px'}}}
            onFocus={(e) => {
              setEnableSearch(true);
              enableId = rowItem.id;
              //setEnableId(rowItem.id);// 23/09/25 idチェック追加（表示クリア時のチェンジイベント回避）
            }}
            onBlur={(e) => setEnableSearch(false)}
            disabled={disRow}                    //使用可／不可
            onChange={(e)=>{
              const row = JSON.parse(JSON.stringify(rowItem));
              row.TODOFUKEN_KUNI = e.target.value;
              setRowItem(row);
            }}
          >
            {
              prefectureList.map((prefecture) => {
                return <MenuItem value={prefecture.value} key={prefecture.value}>{prefecture.label}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </Stack>
      <br />
      <Stack direction='row' spacing={3}>
        <NumericFormat  //売単価
          id={'BTN'}
          inputRef={inBTN}                   //項目名定義？
          thousandSeparator={','}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          color='success'
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='売単価'                         //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '13ch',background:inBTNclr}}                //textboxの幅
          value={rowItem.BTN2}                  //初期値
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={disRow}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
          isAllowed={(values) => {
            const numValue = Number(values.value);
            if (isNaN(numValue)) return true;
            return numValue >= -9999999.99 && numValue <= 9999999.99;
          }}
          onValueChange={(values, sourceInfo) => {
            const row = JSON.parse(JSON.stringify(rowItem));
            row.BTN = values.floatValue||0;
            row.BTN2 = values.formattedValue||'0';
            setRowItem(row);
          }}
          // onChange={(e)=>{
          //   const row = JSON.parse(JSON.stringify(rowItem));
          //   // let btn = Number(e.target.value);
          //   let btn = Number(String(e.target.value||0).replaceAll(',',''));
          //   if (isNaN(btn) || e.target.value === ''){
          //     row.BTN = 0;
          //     row.BTN2 = '0';
          //   }
          //   else{
          //     row.BTN = btn;
          //     row.BTN2 = btn.toLocaleString(undefined,{minimumFractionDigits : 0});
          //   }
          //   setRowItem(row);
          // }}
          onKeyDown={(e) => onKeyDown(e.key,inBTN,inGNT,inBTN)}
          onFocus={(e) => onMyFocus(e)}
        />
        <FormControl
          sx={{
            minWidth: 135,
            maxWidth: 135,
            mr:'10px',
            visibility: prefectureList2 !==0 ? 'visible' : 'hidden'
          }}
          size='small'
        >
          <InputLabel id='select-label' color='success'>原産地ほか</InputLabel>
          <Select
            inputRef={inGENSANTI}
            color='success'
            defaultValue={''}
            labelId='select-label'
            id='select-label'
            value={rowItem.GENSANTI}
            label='原産地ほか'
            onFocus={(e) => {
              setEnableSearch(true);
              enableId = rowItem.id;
              //setEnableId(rowItem.id);// 23/09/25 idチェック追加（表示クリア時のチェンジイベント回避）
            }}
            onBlur={(e) => setEnableSearch(false)}
            disabled={disRow}                    //使用可／不可
            onChange={(e)=>{
              const row = JSON.parse(JSON.stringify(rowItem));
              row.GENSANTI = e.target.value;
              setRowItem(row);
            }}
          >
            {
              homeList.map((home) => {
                return <MenuItem value={home.value} key={home.value}>{home.label}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </Stack>
      <br />
      <Stack direction='row'>&nbsp;
        <FormControl
          sx={{
            minWidth: 135,
            maxWidth: 135,
            mr:'10px',
            visibility: prefectureList2 !==0 ? 'visible' : 'hidden'
          }}
          size='small'
        >
          <InputLabel id='select-label' color='success'>食品区分</InputLabel>
          <Select
            inputRef={inSKHLBLKBN}
            color='success'
            defaultValue={''}
            labelId='select-label'
            id='select-label'
            value={rowItem.SKHLBLKBN}
            label='食品区分'
            onFocus={(e) => {
              setEnableSearch(true);
              enableId = rowItem.id;
              //setEnableId(rowItem.id);// 23/09/25 idチェック追加（表示クリア時のチェンジイベント回避）
            }}
            onBlur={(e) => setEnableSearch(false)}
            disabled={disRow}                    //使用可／不可
            onChange={(e)=>{
              const row = JSON.parse(JSON.stringify(rowItem));
              row.SKHLBLKBN = e.target.value;
              setRowItem(row);
            }}
          >
            {
              foodLabelList.map((foodLabel) => {
                return <MenuItem value={foodLabel.value} key={foodLabel.value}>{foodLabel.label}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </Stack>
      <br /><br /><br /><br /><br /><br />
      <Stack direction='row' spacing={0} mx={{mb:'5px'}}>
        <Button
          variant='contained'
          color='primary'
          sx={{
            width:80,
          }}
          disabled={rowItem.id === 1 ? true : false }                  //使用可／不可
          onClick={(e) => onClick_PreviousPage()}
        >
          {'前商品'}
        </Button>&nbsp;
        <Button
          variant='contained'
          color='primary'
          sx={{width:80}}
          disabled={rowItem.id === rows.length ? true : false }                  //使用可／不可
          onClick={(e) => onClick_NextPage()}
        >
          {'後商品'}
        </Button>&nbsp;
        <InputLabel variant={'outlined'}>{ rowItem.id + '/' + rows.length}</InputLabel>
      </Stack>
      <Stack direction='row' spacing={0}>
        <Button
          variant='contained'
          color='primary'
          sx={{width:80}}
          // onClick={(e) => setScreenPhase(1)}
          onClick={(e) => onClick_BackPage()} // 23/09/25　onClick処理追加
        >
          {'戻る'}
        </Button>&nbsp;
        {(()=> {
          if(rowItem.id === rows.length) {
            return (
              <Button
                variant='contained'
                color='primary'
                sx={{width:80}}
                onClick={(e) => onClick_ReEnterPage()}
              >
                {'再入力'}
              </Button>
            )
          } else {
            return (
              <Button
                variant='contained'
                color='primary'
                sx={{width:80}}
                onClick={(e) => onClick_LastPage()}
              >
                {'最後尾'}
              </Button>
            )
          }
        })()}
        &nbsp;
        <Button
          variant='contained'
          color='primary'
          sx={{width:80}}
          onClick={(e) => onClick_DeletePage()}
        >
          {'削除'}
        </Button>&nbsp;
        <Button
          variant='contained'
          color='primary'
          sx={{width:80}}
          onClick={(e) => onClick_Send()}
          style={{
            display: (rows.length === 1 ? "none" : "inline-flex"),
          }}
        >
          {'登録'}
        </Button>&nbsp;
      </Stack>
    </Grid>
  );
};

export default TCPD0132;