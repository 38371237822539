import React, { useState,useContext,useEffect,useRef } from 'react'
import { Button, TextField, Stack, InputLabel } from "@mui/material";
import { UserContext } from './App';
import { NumericFormat } from 'react-number-format';    //数値入力
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { onMyFocus } from './hooks/useOnMyFocus';     // select()処理

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);

      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCPD0141 ({
  sdate,
  ckDate,
  setRows,
  rowData,
  setRowItem,
  rowItem,
  headerData,
  headerItem,
  setHeaderItem,
  getSyodan,
  setSyodan,
  denKbnList,
  denKbnComment,
  changeDenKbn,
  retDenKbn,
  visibleSYODAN,
  setVisibleSYODAN,
  riyuList,
  riyuComment,
  disRyuno,
  setDisRyuno,
  setScreenPhase,
  setHeaderReset2     // 23/09/25
}) {

  const [] = useState(false);    // 商談No表示フラグ


  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('');

  const [riyuKyokaList, setRiyuKyokaList] = useState([]);       // 伝票区分情報(区分に対する使用可能理由No.）


  //項目のバックカラー制御用
  const [inBMNCDEclr,setBMNCDEclr] = useState('#FFFFFF');
  const [inHZKclr,setHZKclr] = useState('#FFFFFF');
  const [inDENKBNclr,setDENKBNclr] = useState('#FFFFFF');
  const [inRYUNOclr,setRYUNOclr] = useState('#FFFFFF');
  const [inSYODANclr,setSYODANclr] = useState('#FFFFFF');

  const inBMNCDE = useRef(null);
  const inHZK = useRef(null);
  const inDENKBN = useRef(null);
  const inRYUNO = useRef(null);
  const inSYODAN = useRef(null);

  const commonStyles = {
    bgcolor: 'background.paper',
    m: 1,
    border: 0,
    width: '40px',
    height: '40px',
  };


  // 理由No.入力時の処理
  const changeRiyuNo = (value, header) => {
    let riyuNoName = '';
    if (value !== '') {
      const inputRiyu = riyuList.filter(element => {
        return element.cd === value
      });

      if (inputRiyu.length !== 0) {
        riyuNoName = inputRiyu[0].name;
      }
    }

    header.RYUNO = value;
    header.RYUMEI = riyuNoName;
    //setHeaderItem(HeaderItem => ({...HeaderItem,RYUNO:value}));
    //setHeaderItem(HeaderItem => ({...HeaderItem,RYUMEI:riyuNoName}));
    setHeaderItem(header);
  };

  const onKeyDown =  async (key,inputRef,inptRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    switch (key) {
      case 'Enter':
        setBMNCDEclr('#FFFFFF');
        setHZKclr('#FFFFFF');
        setDENKBNclr('#FFFFFF');
        setRYUNOclr('#FFFFFF');
        setSYODANclr('#FFFFFF');
        const header = JSON.parse(JSON.stringify(headerItem));
        switch (inputRef.current.id) {
          case 'BMNCDE':  // 部門コード
            await onKeyDown_BMNCDE(inputRef, inputNxRef, header);
            break;
          case 'HZK':     // 日付チェック
            await onKeyDown_HZK(inputRef, inputNxRef);
            break;
          case 'DENKBN':  // 伝票区分チェック
            await onKeyDown_DENKBN(inputRef, inputNxRef, header);
            break;
          case 'RYUNO':   // 理由No.チェック
            await onKeyDown_RYUNO(inputRef.current.value, header);
            break;
          case 'SYODAN':  // 商談書No.
            await onKeyDown_SYODAN(inputRef, header);
            break;
          default:
            inputNxRef.current.focus(); //次の項目に遷移;
            break;
        }
        break;
      case 'TCSA0005':
        navigate('/TCSA0005');
        break;
      case 'Home':
        break;
      case 'ArrowUp':
        inptRtRef.current.focus();    //前の項目に遷移
        break;
      case 'ArrowDown':
        inputNxRef.current.focus();   //次の項目に遷移
        break;
      default:
        break;
    }
  };

  // ヘッダ部１入力　部門CD（初期カーソル）
  const onKeyDown_BMNCDE =  async (inputRef, inputNxRef, header) => {
    // 必須チェック
    if(inputRef.current.value === '') {
      setBMNCDEclr('#f59d9d');
      window.alert('部門コードを入力して下さい。'); //TCGE0019：
      return 1;
    }

    // 入力値の前0詰め
    const padBmnCd = inputRef.current.value.padStart(4, '0');

    // 部門CDチェック
    const responseB1 = await fetch('/MR_SHP_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({KSACDE: state.KSACDES, MISE: state.MISES, BMNCDE: padBmnCd, RCNT:0})});
    const dataB1 = await responseB1.json();
    if (dataB1.RCNT === 0) {
      setBMNCDEclr('#f59d9d');
      window.alert('部門コードは存在しません。'); //TCGE0007：
      return 1;
    }

    // 部門名取得
    const responseB2 = await fetch('/TCGT0100/getBrumei',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({KSACDE: state.KSACDES, BMNCDE: padBmnCd, RCNT:0})});
    const dataB2 = await responseB2.json();
    if (dataB2.RCNT === 0) {
      setBMNCDEclr('#f59d9d');
      window.alert('部門コードは存在しません。'); //TCGE0007：
      return 1;
    }

    //const header = JSON.parse(JSON.stringify(headerItem));
    header.BMNCDE = padBmnCd;
    //setHeaderItem(HeaderItem => ({...HeaderItem,BMNCDE:padBmnCd}));

    header.BMNMEI = dataB2.BRUMEI;
    header.GNKKNRFLG = dataB2.GNKKNRFLG;
    // setHeaderItem(HeaderItem => ({...HeaderItem,BMNMEI:dataB2.BRUMEI}));
    // setHeaderItem(HeaderItem => ({...HeaderItem,GNKKNRFLG:dataB2.GNKKNRFLG}));
    setHeaderItem(header);

    //次の項目に遷移;
    inputNxRef.current.focus();
    return 0;
  };

  // ヘッダ部１入力　部門CD（初期カーソル）
  const onKeyDown_BMNCDE2 =  async (inputRef, inputNxRef, header) => {
    // 必須チェック
    if(inputRef.current.value === '') {
      setBMNCDEclr('#f59d9d');
      window.alert('部門コードを入力して下さい。'); //TCGE0019：
      inBMNCDE.current.focus();
      return 1;
    }

    // 入力値の前0詰め
    const padBmnCd = inputRef.current.value.padStart(4, '0');

    // 部門CDチェック
    const responseB1 = await fetch('/MR_SHP_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({KSACDE: state.KSACDES, MISE: state.MISES, BMNCDE: padBmnCd, RCNT:0})});
    const dataB1 = await responseB1.json();
    if (dataB1.RCNT === 0) {
      setBMNCDEclr('#f59d9d');
      window.alert('部門コードは存在しません。'); //TCGE0007：
      inBMNCDE.current.focus();
      return 1;
    }

    // 部門名取得
    const responseB2 = await fetch('/TCGT0100/getBrumei',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({KSACDE: state.KSACDES, BMNCDE: padBmnCd, RCNT:0})});
    const dataB2 = await responseB2.json();
    if (dataB2.RCNT === 0) {
      setBMNCDEclr('#f59d9d');
      window.alert('部門コードは存在しません。'); //TCGE0007：
      inBMNCDE.current.focus();
      return 1;
    }

    header.BMNCDE = padBmnCd;
    header.BMNMEI = dataB2.BRUMEI;
    header.GNKKNRFLG = dataB2.GNKKNRFLG;
    setHeaderItem(header);
    return 0;
  };

  // ヘッダ部１入力　日付
  const onKeyDown_HZK =  async (inputRef, inputNxRef) => {
    // 必須チェック
    if(inputRef.current.value ==='') {
      setHZKclr('#f59d9d');
      window.alert('日付を入力して下さい。'); //TCGE0019：
      return 1;
    }

    // 日付チェック
    const strDate = inputRef.current.value;
    const indate = new Date(strDate);
    if(ckDate(strDate)===false){
      setHZKclr('#f59d9d');
      window.alert('日付入力エラー'); //TCGE0016：
      return 1;
    }

    // システム日－２か月≦入力日（西暦）＜システム日の日付範囲チェック
    let maxdate = new Date(sdate);
    maxdate.setDate(maxdate.getDate() + 14);    // 2023/11/22 2週間後まで入力可能に変更
    let mindate = new Date(sdate);
    mindate.setMonth(mindate.getMonth()-2);
    //console.log('日付範囲チェック'+maxdate+'>='+indate+'&&'+mindate+'<='+indate);
    if (mindate > indate) {
      setHZKclr('#f59d9d');
      window.alert('入力された日付は返品対象外期間です'); //TCGE0016：
      return 1;
    }

    if (maxdate < indate) {
      setHZKclr('#f59d9d');
      window.alert('入力された日付は返品対象外期間です'); //TCGE0016：
      return 1;
    }

    //次の項目に遷移
    inputNxRef.current.focus();
    return 0;
  };

  const onKeyDown_HZK2 =  async (inputRef, inputNxRef) => {
    // 必須チェック
    if(inputRef.current.value ==='') {
      setHZKclr('#f59d9d');
      window.alert('日付を入力して下さい。'); //TCGE0019：
      inHZK.current.focus();
      return 1;
    }

    // 日付チェック
    const strDate = inputRef.current.value;
    const indate = new Date(strDate);
    if(ckDate(strDate)===false){
      setHZKclr('#f59d9d');
      window.alert('日付入力エラー'); //TCGE0016：
      inHZK.current.focus();
      return 1;
    }

    // システム日－２か月≦入力日（西暦）＜システム日の日付範囲チェック
    let maxdate = new Date(sdate);
    maxdate.setDate(maxdate.getDate() + 14);    // 2023/11/22 2週間後まで入力可能に変更
    let mindate = new Date(sdate);
    mindate.setMonth(mindate.getMonth()-2);
    //console.log('日付範囲チェック'+maxdate+'>='+indate+'&&'+mindate+'<='+indate);
    if (mindate > indate) {
      setHZKclr('#f59d9d');
      window.alert('入力された日付は返品対象外期間です'); //TCGE0016：
      inHZK.current.focus();
      return 1;
    }

    if (maxdate < indate) {
      setHZKclr('#f59d9d');
      window.alert('入力された日付は返品対象外期間です'); //TCGE0016：
      inHZK.current.focus();
      return 1;
    }

    //次の項目に遷移
    return 0;
  };

  // ヘッダ部１入力　伝票区分
  const onKeyDown_DENKBN =  async (inputRef, inputNxRef, header) => {

    // 日付必須チェック
    if(inHZK.current.value ==='') {
      setHZKclr('#f59d9d');
      window.alert('日付を入力して下さい。'); //TCGE0019：
      inHZK.current.focus();
      return 1;
    }

    // 必須チェック
    if(inputRef.current.value ==='') {
      setDENKBNclr('#f59d9d');
      window.alert('伝票区分を入力して下さい。'); //TCGE0019：
      return 1;
    }

    //const header = JSON.parse(JSON.stringify(headerItem));
    const denKbn = inDENKBN.current.value.padStart(2, '0');// 伝票区分コード入力値の前0詰め
    header.DENKBN = denKbn;
    //setHeaderItem(HeaderItem => ({...HeaderItem,DENKBN:denKbn}));

    // 設定可能区分チェック
    const inputDenKbn = denKbnList.filter(element => {
      return element.cd === denKbn;
    });
    if (inputDenKbn.length === 0) {
      setDENKBNclr('#f59d9d');
      window.alert('伝票区分入力エラー。'); //TCGE0013：
      setHeaderItem(header);
      return 1;
    }

    // 伝票区分情報.制限チェック(理由許可が１つの場合はセット)
    let kyokaList = [];
    let denkyoka = inputDenKbn[0].kyokaNo;
    if(denkyoka !== '') {
      kyokaList = denkyoka.split(',').map(x => x.trim());
    }
    setRiyuKyokaList(kyokaList)
    if(kyokaList.length === 1) {
      header.RYUNO = kyokaList[0];
      //setHeaderItem(HeaderItem => ({...HeaderItem,RYUNO:kyokaList[0]}));
      setDisRyuno(true);
      onKeyDown_RYUNO(kyokaList[0], header);
      changeRiyuNo(kyokaList[0], header);
      setHeaderItem(header);
      return;
    }

    setHeaderItem(header);

    //次の項目に遷移
    await setDisRyuno(false);
    inputNxRef.current.focus();
    return 0;
  };

  const onKeyDown_DENKBN2 =  async (inputRef, inputNxRef, header) => {

    // 必須チェック
    if(inputRef.current.value ==='') {
      setDENKBNclr('#f59d9d');
      window.alert('伝票区分を入力して下さい。'); //TCGE0019：
      return 1;
    }

    //const header = JSON.parse(JSON.stringify(headerItem));
    const denKbn = inDENKBN.current.value.padStart(2, '0');// 伝票区分コード入力値の前0詰め
    header.DENKBN = denKbn;
    //setHeaderItem(HeaderItem => ({...HeaderItem,DENKBN:denKbn}));

    // 設定可能区分チェック
    const inputDenKbn = denKbnList.filter(element => {
      return element.cd === denKbn;
    });
    if (inputDenKbn.length === 0) {
      setDENKBNclr('#f59d9d');
      window.alert('伝票区分入力エラー。'); //TCGE0013：
      setHeaderItem(header);
      return 1;
    }

    // 伝票区分情報.制限チェック(理由許可が１つの場合はセット)
    let kyokaList = [];
    let denkyoka = inputDenKbn[0].kyokaNo;
    if(denkyoka !== '') {
      kyokaList = denkyoka.split(',').map(x => x.trim());
    }
    setRiyuKyokaList(kyokaList)
    if(kyokaList.length === 1) {
      header.RYUNO = kyokaList[0];
      //setHeaderItem(HeaderItem => ({...HeaderItem,RYUNO:kyokaList[0]}));
      setDisRyuno(true);
      onKeyDown_RYUNO(kyokaList[0], header);
      changeRiyuNo(kyokaList[0], header);
      setHeaderItem(header);
      return 0;
    }

    setHeaderItem(header);

    //次の項目に遷移
    await setDisRyuno(false);
    return 0;
  };

  // 商談書入力時チェック 伝票区分
  const onKeyDown_DENKBN3 =  async (inputRef, header) => {

    // 必須チェック
    if(inputRef.current.value ==='') {
      setDENKBNclr('#f59d9d');
      window.alert('伝票区分を入力して下さい。'); //TCGE0019：
      return 1;
    }

    const denKbn = inDENKBN.current.value.padStart(2, '0');// 伝票区分コード入力値の前0詰め
    header.DENKBN = denKbn;
    // 設定可能区分チェック
    const inputDenKbn = denKbnList.filter(element => {
      return element.cd === denKbn;
    });
    if (inputDenKbn.length === 0) {
      setDENKBNclr('#f59d9d');
      window.alert('伝票区分入力エラー。'); //TCGE0013：
      setHeaderItem(header);
      return 1;
    }

    // 伝票区分情報.制限チェック(理由許可が１つの場合はセット)
    let kyokaList = [];
    let denkyoka = inputDenKbn[0].kyokaNo;
    if(denkyoka !== '') {
      kyokaList = denkyoka.split(',').map(x => x.trim());
    }
    setRiyuKyokaList(kyokaList)
    if(kyokaList.length === 1) {
      changeRiyuNo(kyokaList[0], header);
    }
    return 0;
  };

  // ヘッダ部１入力　理由No.
  const onKeyDown_RYUNO = async (ryuno_val, header) => {

    // 伝票区分必須チェック
    if(inDENKBN.current.value ==='') {
      setDENKBNclr('#f59d9d');
      window.alert('伝票区分を入力して下さい。'); //TCGE0019：
      inDENKBN.current.focus();
      return 1;
    }

    // 必須チェック
    if(ryuno_val === '') {
      setRYUNOclr('#f59d9d');
      window.alert('理由No.を入力して下さい。');  //TCGE00191：
      return 1;
    }

    // 設定可能区分チェック
    const inputRiyu = riyuList.filter(element => {
      return element.cd === ryuno_val;
    });
    if (inputRiyu.length === 0) {
      setRYUNOclr('#f59d9d');
      window.alert('理由No入力エラー。'); //TCGE0044：
      return 1;
    }

    //伝票区分情報.制限チェックで理由許可対象チェック
    if (riyuKyokaList.length > 0) {
      const inputRiyuCheck = riyuKyokaList.filter(element => {
        return element === ryuno_val;
      });
      if (inputRiyuCheck.length === 0)
      {
        setRYUNOclr('#f59d9d');
        window.alert('理由No入力エラー。'); //TCGE0044：
        return 1;
      }
    }

    //const header = JSON.parse(JSON.stringify(headerItem));
    header.RYUNO = ryuno_val;
    header.SYODAN = '';
    // setHeaderItem(HeaderItem => ({...HeaderItem,RYUNO:ryuno_val}));
    // setHeaderItem(HeaderItem => ({...HeaderItem,SYODAN:''}));
    setHeaderItem(header);

    // 理由No.に[3]が設定された場合 項目　商談書Noを表示し、カーソルを商談書Noに設定
    if (ryuno_val === '3'){
      setVisibleSYODAN(true);
      //inSYODAN.current.focus();       //商談Noに遷移
      setTimeout(() => {
        if (inSYODAN.current !==null && typeof inSYODAN.current !== 'undefined')
        {
          inSYODAN.current.focus();
        }
      },100)

      return 1;
    } else {
      // ヘッダ１チェック
      console.log('ヘッダチェック:'+inBMNCDE.current.value);
      let ret01 = await onKeyDown_BMNCDE2(inBMNCDE, inHZK, header);
      if (ret01 === 1)
      {
        // inBMNCDE.current.focus();
        return 1;
      }
      ret01 = await onKeyDown_HZK2(inHZK, inDENKBN);
      if (ret01 === 1)
      {
        // inHZK.current.focus();
        return 1;
      }
      ret01 = await onKeyDown_DENKBN2(inDENKBN, inRYUNO, header);
      if (ret01 === 1)
      {
        // inDENKBN.current.focus();
        return 1;
      }
      setVisibleSYODAN(false);
      // 2023/10/06　header2の使用を廃止
      // const header2 = JSON.parse(JSON.stringify(headerItem));
      // console.log('次の画面_header:%o',header2);
      await getReturnDate(header);
    }
  };

  const onKeyDown_RYUNO2 = async (ryuno_val, header) => {

    // 必須チェック
    if(ryuno_val === '') {
      setRYUNOclr('#f59d9d');
      window.alert('理由No.を入力して下さい。');  //TCGE00192：
      return 1;
    }

    // 設定可能区分チェック
    const inputRiyu = riyuList.filter(element => {
      return element.cd === ryuno_val;
    });
    if (inputRiyu.length === 0) {
      setRYUNOclr('#f59d9d');
      window.alert('理由No入力エラー。'); //TCGE0044：
      return 1;
    }

    //伝票区分情報.制限チェックで理由許可対象チェック
    if (riyuKyokaList.length > 0) {
      const inputRiyuCheck = riyuKyokaList.filter(element => {
        return element === ryuno_val;
      });
      if (inputRiyuCheck.length === 0)
      {
        setRYUNOclr('#f59d9d');
        window.alert('理由No入力エラー。'); //TCGE0044：
        return 1;
      }
    }

    //const header = JSON.parse(JSON.stringify(headerItem));
    header.RYUNO = ryuno_val;
    header.SYODAN = '';
    // setHeaderItem(HeaderItem => ({...HeaderItem,RYUNO:ryuno_val}));
    // setHeaderItem(HeaderItem => ({...HeaderItem,SYODAN:''}));
    setHeaderItem(header);

    // 理由No.に[3]が設定された場合 項目　商談書Noを表示し、カーソルを商談書Noに設定
    if (ryuno_val === '3'){
      setVisibleSYODAN(true);
      //inSYODAN.current.focus();       //商談Noに遷移
      setTimeout(() => {
        if (inSYODAN.current !==null && typeof inSYODAN.current !== 'undefined')
        {
          inSYODAN.current.focus();
        }
      },100)
      console.log('理由:3');
      return 0;
    } else {
      setVisibleSYODAN(false);
      // const header2 = JSON.parse(JSON.stringify(headerItem));
      // console.log('次の画面_header:%o',header2);
      getReturnDate(header);
    }
    return 0;
  };

  // 仕入先名称取得
  const getSirmeik =  async (sircode,header,lflg) => {
    //header.SIRCDE2 = sircode ?? '';
    const getSirmeik = await fetch('/TCGT0100/getSirmeik',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({KSACDE:state.KSACDES,SIRCDE:sircode})});
    const sirmeik = await getSirmeik.json();
    if(sirmeik !== null) {
      if (lflg === 1)
      {
        header.SIRCDE = sircode;
        header.SIRMEIK = sirmeik.SIRMEIK;
        header.ZEIRTU = sirmeik.ZEIRTU;
      }else
      {
        header.SIRCDE2 = sircode;
        header.SIRMEIK2 = sirmeik.SIRMEIK;
        header.ZEIRTU2 = sirmeik.ZEIRTU;
        if (header.SIRCDE === '' || header.SIRCDE === undefined){
          header.SIRCDE = sircode;
          header.SIRMEIK = sirmeik.SIRMEIK;
          header.ZEIRTU = sirmeik.ZEIRTU;
        }
      }
    }
    await setHeaderItem(header);
  }

  // ヘッダ部１入力　商談書No.
  const onKeyDown_SYODAN =  async (inputRef, header) => {
    if (inputRef.current.value === '')
    {
      setSYODANclr('#f59d9d');
      window.alert('TCGE0019:商談書№を入力して下さい。');
      inSYODAN.current.focus();
      return 1;
    }

    const syodanNo = inputRef.current.value.padStart(13, '0');// 商談NO入力値の前0詰め
    //const header = JSON.parse(JSON.stringify(headerItem));
    // 2023/10/06 商談書入力時に他項目も確認する
    header.SYODAN =syodanNo;
    let ret01 = await onKeyDown_BMNCDE2(inBMNCDE, inHZK, header);
    if (ret01 === 1) {
      return;
    }
    ret01 = await onKeyDown_HZK2(inHZK, inDENKBN);
    if (ret01 === 1) {
      return;
    }
    ret01 = await onKeyDown_DENKBN3(inDENKBN, header);
    if (ret01 === 1) {
      return;
    }
    if (header.RYUNO !== '3') {
      inRYUNO.current.focus();
      setRYUNOclr('#f59d9d');
      return;
    }

    // setHeaderItem(HeaderItem => ({...HeaderItem,SYODAN:syodanNo}));
    // 商談書No.存在チェック
    const syodanList = await getSyodan(state.KSACDES, syodanNo);
    if(syodanList.length === 0) {
      setSYODANclr('#f59d9d');
      window.alert('TCGE0079:該当する商談書№が存在しません。');
      setHeaderItem(header);
      inSYODAN.current.focus();
      return 1;
    }

    // 部門コードチェック
    const syodanItem = syodanList.filter(element => {
      return element.BMNCDE === header.BMNCDE;
    });
    if (syodanItem.length === 0) {
      setSyodan([]);
      setBMNCDEclr('#f59d9d');
      window.alert('入力した部門が商談書の部門と異なります。'); //TCGE0045：
      inBMNCDE.current.focus();
      return 1;
    }

    // 伝票区分チェック
    if (syodanItem[0].DENKBN !== headerItem.DENKBN) {
      setSyodan([]);
      setDENKBNclr('#f59d9d');
      window.alert('入力した伝票区分が商談書の伝票区分と異なります。'); //TCGE0046：
      inDENKBN.current.focus();
      return 1;
    }

    // 取引期間チェック
    // 画面の日付8桁の数字化
    const checkData = Number(headerItem.HZK);
    let mindate = Number(syodanItem[0].HPNSTTYMD);
    let maxdate = Number(syodanItem[0].HPNENDYMD);
    if (!(mindate <= checkData && checkData<= maxdate)) {
      setSyodan([]);
      setHZKclr('#f59d9d');
      window.alert('入力した日付が商談書の取引期間外です。'); //TCGE0047：
      inHZK.current.focus();
      return 1;
    }

    //仕入先情報取得
    await getSirmeik(syodanItem[0].SIRCDE,header,1);
    console.log('headerItem情報:%o',headerItem);

    setSyodan(syodanItem[0]);
    getReturnDate(header);
    return 0;
  };

  // ヘッダ部１入力　商談書No.（2）
  const onKeyDown_SYODAN2 =  async (inputRef, header) => {
    console.log('商談書'+inputRef.current.value);
    if (inputRef.current.value === '')
    {
      setSYODANclr('#f59d9d');
      window.alert('TCGE0019:商談書№を入力して下さい。');
      return 1;
    }

    const syodanNo = inputRef.current.value.padStart(13, '0');// 商談NO入力値の前0詰め
    const BmnCde =headerItem.BMNCDE.padStart(4, '0');// 部門CD入力値の前0詰め
    //const header = JSON.parse(JSON.stringify(headerItem));
    header.SYODAN =syodanNo;
    //setHeaderItem(HeaderItem => ({...HeaderItem,SYODAN:syodanNo}));
    setHeaderItem(header);
    // 商談書No.存在チェック
    const syodanList = await getSyodan(state.KSACDES, syodanNo);
    if(syodanList.length === 0) {
      setSYODANclr('#f59d9d');
      window.alert('TCGE0079:該当する商談書№が存在しません。');
      return 1;
    }

    // 部門コードチェック
    const syodanItem = syodanList.filter(element => {
      return element.BMNCDE === BmnCde;
    });
    if (syodanItem.length === 0) {
      setSyodan([]);
      setBMNCDEclr('#f59d9d');
      window.alert('入力した部門が商談書の部門と異なります。'); //TCGE0045：
      return 1;
    }

    // 伝票区分チェック
    if (syodanItem[0].DENKBN !== header.DENKBN) {
      setSyodan([]);
      setDENKBNclr('#f59d9d');
      window.alert('入力した伝票区分が商談書の伝票区分と異なります。'); //TCGE0046：
      return 1;
    }

    // 取引期間チェック
    // 画面の日付8桁の数字化
    const checkData = Number(header.HZK);
    let mindate = Number(syodanItem[0].HPNSTTYMD);
    let maxdate = Number(syodanItem[0].HPNENDYMD);
    if (!(mindate <= checkData && checkData<= maxdate)) {
      setSyodan([]);
      setHZKclr('#f59d9d');
      window.alert('入力した日付が商談書の取引期間外です。'); //TCGE0047：
      return 1;
    }

    //仕入先情報取得
    await getSirmeik(syodanItem[0].SIRCDE,header,1);
    console.log('headerItem情報:%o',headerItem);

    setSyodan(syodanItem[0]);
    getReturnDate(header);
    return 0;
  };

  // 明細データ抽出
  const
  getReturnDate =  async(header) => {
    header.SRYFOT = 0;   // 合計数量
    header.GNKFOT = 0;   // 原価金額合計
    header.BAKFOT = 0;   // 売価金額合計
    // header.SIRCDE = '';   // 仕入先(取引先)コード
    // header.SIRMEIK = '';  // 仕入先(取引先)名
    // header.ZEIRTU = '';   // 税率
    header.SIRCDE2 = '';   // 仕入先(取引先)コード
    header.SIRMEIK2 = '';  // 仕入先(取引先)名
    header.ZEIRTU2 = '';   // 税率
    // setHeaderItem(HeaderItem => ({...HeaderItem,SRYFOT:0}));
    // setHeaderItem(HeaderItem => ({...HeaderItem,GNKFOT:0}));
    // setHeaderItem(HeaderItem => ({...HeaderItem,BAKFOT:0}));
    // setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE:''}));
    // setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK:''}));
    // setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU:''}));
    // setHeaderItem(HeaderItem => ({...HeaderItem,SIRCDE2:''}));
    // setHeaderItem(HeaderItem => ({...HeaderItem,SIRMEIK2:''}));
    // setHeaderItem(HeaderItem => ({...HeaderItem,ZEIRTU2:''}));

    setRowItem(rowData);
    setRows([rowData]);
    setHeaderItem(header);
    setScreenPhase(2)
    console.log('明細データ抽出_header:%o',header);
  };

  // フッタ部　取消
  const onClick_Reset = async () => {
    if(!(window.confirm('入力された内容を破棄します。よろしいですか？'))) { //TCGM0007：
      return;
    }

    setRowItem(rowData);
    setRows([]);
    //setHeaderItem(headerData);
    setHeaderReset2();     // 23/09/25
    if (inRYUNO.current.value === '3'){
      await setVisibleSYODAN(true);
      //inSYODAN.current.focus();       //商談Noに遷移
      setTimeout(() => {
        if (inSYODAN.current !==null && typeof inSYODAN.current !== 'undefined')
        {
          inSYODAN.current.focus();
        }
      },100)

      setDisRyuno(true);
    }
    console.log('伝票区分:');
    inDENKBN.current.focus();
  }

  // フッタ部　次へ
  const onClick_Next = async () => {
    // 10/05 headerで最後上書きするので、それを受け渡して値を変更する方法に統一
    //        関数内でheaderItemを変更しても変更した値を取得出来ない
    //        渡した値は関数内で変更すれば変更される
    const header = JSON.parse(JSON.stringify(headerItem));
    //console.log('次画面:%o',header)
    let ret01 = await onKeyDown_BMNCDE2(inBMNCDE, inHZK, header);
    if (ret01 === 1)
    {
      // inBMNCDE.current.focus();
      return;
    }

    ret01 = await onKeyDown_HZK2(inHZK, inDENKBN);
    if (ret01 === 1)
    {
      // inHZK.current.focus();
      return;
    }
    ret01 = await onKeyDown_DENKBN2(inDENKBN, inRYUNO, header);
    if (ret01 === 1)
    {
      // inDENKBN.current.focus();
      return;
    }else{
      inRYUNO.current.focus();
    }
    //console.log('伝票区分02:'+inRYUNO.current.value);

    // const header2 = JSON.parse(JSON.stringify(headerItem));
    // console.log('RYUNO02:%o',header2);
    ret01 = await onKeyDown_RYUNO2(header.RYUNO, header);
    if (ret01 === 1)
    {
        //inDENKBN.current.focus();
      return;
    }
    console.log('理由No02:'+inRYUNO.current.value);
    //if (inRYUNO.current.value === "3")
    if (header.RYUNO === "3")
    {
      ret01 = await onKeyDown_SYODAN2(inSYODAN, header);
      if (ret01 === 1)
      {
        // inSYODAN.current.focus();
        return;
      }
    }

    getReturnDate(header);
  }

  return (
    <>
      <Stack direction="row" >
        <NumericFormat  //部門CD
          id={'BMNCDE'}
          inputRef={inBMNCDE}                   //項目名定義？
          color='success'
          autoFocus                           //初期カーソル位置設定
          thousandSeparator={false}           //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='部門'                         //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '8ch', background:inBMNCDEclr }}                 //textboxの幅
          value={headerItem.BMNCDE}           //初期値
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={false}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          onChange={(e) => setHeaderItem(HeaderItem => ({...HeaderItem,BMNCDE:e.target.value}))}
          onKeyDown={(e) => onKeyDown(e.key,inBMNCDE,inBMNCDE,inHZK)}
          onFocus={(e) => {
            if (inBMNCDE.current !==null && typeof inBMNCDE.current !== 'undefined')
            {
              onMyFocus(e);
            }
          }}
        />
        <InputLabel >{headerItem.BMNMEI}</InputLabel>
      </Stack>
      <br />
      <Stack direction="row" spacing={3}>
        <PatternFormat  //日付
          id={'HZK'}
          inputRef={inHZK}                   //項目名定義？
          format='####/##/##'
          mask={['Y','Y','Y','Y','M','M','D','D']}
          color='success'
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='日付'                        //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          onValueChange={(values, sourceInfo) => {
            const header = JSON.parse(JSON.stringify(headerItem));
            header.HZK = values.value;
            header.HZK2 = values.formattedValue;
            setHeaderItem(header);
          }}
          sx={{width: '13ch', background:inHZKclr}}                 //textboxの幅
          value={headerItem.HZK2}
          disabled={false}                    //使用可／不可
          placeholder='YYYY/MM/DD'
          inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          onKeyDown={(e) => onKeyDown(e.key,inHZK,inBMNCDE,inDENKBN)}
          onFocus={(e) => onMyFocus(e)}
        />
      </Stack>
      <br />
      <Stack direction="row">
        <NumericFormat  //伝票区分
          id={'DENKBN'}
          inputRef={inDENKBN}                 //項目名定義？
          color='success'
          thousandSeparator={false}           //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='伝票区分'                      //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '8ch', background:inDENKBNclr}}                 //textboxの幅
          value={headerItem.DENKBN}
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={false}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 2,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          // onChange={(e) => changeDenKbn(e.target.value)}
          onChange={(e) => {
            const header = JSON.parse(JSON.stringify(headerItem));
            changeDenKbn(e.target.value, header);     // 23/09/25
          }}
          onKeyDown={(e) => onKeyDown(e.key,inDENKBN,inHZK,inRYUNO)}
          onFocus={(e) => setTimeout(() => {
            if (inDENKBN.current !==null && typeof inDENKBN.current !== 'undefined')
            {
              onMyFocus(e);
            }
          },100)}
        />
        <InputLabel >{headerItem.DENKBNNM}</InputLabel>
      </Stack>
      <Stack>
        <InputLabel sx={{fontSize:13,mt:'0px'}}>{denKbnComment}</InputLabel>
      </Stack>
      <br />
      <Stack direction="row">
        <NumericFormat  //理由No.
          id={'RYUNO'}
          inputRef={inRYUNO}                   //項目名定義？
          color='success'
          thousandSeparator={false}           //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='理由No.'                         //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '8ch',background:inRYUNOclr}}                 //textboxの幅
          value = {headerItem.RYUNO}
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={disRyuno}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
          onChange={(e) => {
            const header = JSON.parse(JSON.stringify(headerItem));
            changeRiyuNo(e.target.value,header);
          }}
          onKeyDown={(e) => onKeyDown(e.key,inRYUNO,inDENKBN,inRYUNO)}
          onFocus={(e) => onMyFocus(e)}
        />
        <InputLabel >{headerItem.RYUMEI}</InputLabel>
      </Stack>
      <Stack>
        <InputLabel >{riyuComment}</InputLabel>
      </Stack>
      <br />
      <Stack direction="row">
        <NumericFormat  //商談書No.
          id={'SYODAN'}
          inputRef={inSYODAN}                   //項目名定義？
          color='success'
          thousandSeparator={false}           //1000単位の区切り
          customInput={TextField}             //textfieldにreact-number-formatの機能を追加
          size='small'                        //textboxの全体サイズ
          label='商談書No.'                         //textboxの左上に表示するラベル
          variant='outlined'                  //枠の表示方法
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
          sx={{width: '16ch',visibility:visibleSYODAN ? 'visible' : 'hidden' ,background:inSYODANclr}}                 //textboxの幅
          value={headerItem.SYODAN}               //初期値
          allowLeadingZeros                   //前ゼロ表示 on
          disabled={false}                    //使用可／不可
          inputProps={{ autoComplete: 'off',maxLength: 13,style: {textAlign: 'center'}}}  //補完,最大文字数,中央寄せ
          onChange={(e)=>{
            const header = JSON.parse(JSON.stringify(headerItem));
            header.SYODAN = e.target.value;
            setHeaderItem(header);
          }}
          onKeyDown={(e) => onKeyDown(e.key,inSYODAN,inRYUNO,inSYODAN)}
          onFocus={(e) => {
            if (inSYODAN.current !==null && typeof inSYODAN.current !== 'undefined')
            {
              onMyFocus(e);
            }
          }}
        />
      </Stack>
      <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      <Stack direction="row" spacing={0}>
      <Button
          variant='contained'
          color='primary'
          sx={{width:80}}
          onClick={(e) => navigate('/TCSA0005') }
          >
          {'戻る'}
        </Button>&nbsp;
        <Button
          variant='contained'
          color='primary'
          sx={{width:80}}
          onClick={(e) => onClick_Reset()}
        >
          {'再入力'}
        </Button>&nbsp;
        <Button
          variant='contained'
          color='primary'
          sx={{width:80}}
          onClick={(e) => onClick_Next()}
        >
          {'次へ'}
        </Button>
      </Stack>
    </>
  );
};

export default TCPD0141;