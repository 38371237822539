import React, { useState,useContext,useEffect } from 'react'
import { Box, Grid, Paper, Typography, Stack } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F5キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F9キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSA9999 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState(' ');
  const [dismeisai, setMeisai] = useState(false);    //disabled設定（明細）
  const [disdeng, setDENG] = useState(false);    //disabled設定（明細）
  const [sdate, setsDate] = useState([]);

  let Err_flg = 0;                                 //カーソル移動時チェックon/off制御：0=on,1=off

  const [rows, setRows] = useState([]);

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
      getFileList();
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      navigate('/TCSA0003');
    }
    if (event.keyCode === 120) { // F9キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  const getFileList = async() =>{
    // if (itJGYUCDE=='')
    // {
      console.log('チェック:'+state.MISES);
      const responseA = await fetch('/TCSA9999/SL_PRT_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({MISE: state.MISES})});
      const dataA = await responseA.json();
      console.log('帳票管理:%o',dataA);
      if (dataA.res.length != 0)
      {
        setRows(dataA.res);
      }
    // }
  }

  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    getFileList();
  },[]);


  //罫線表示（Data Grid）
  const styles = {
    grid: {
      '.MuiDataGrid-toolbarContainer': {
        borderBottom: 'solid 1px rgba(224, 224, 224, 1)'  // ついでにツールバーの下に罫線を引く
      },
      '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
        borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      // 2022/06/01 追記 列ヘッダの最終セルの右側に罫線が表示されなくなった・・・
      // 列ヘッダの最終列の右端に罫線を表示する
      '.MuiDataGrid-columnHeadersInner': {
        border: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      '.MuiDataGrid-columnSeparator--sideRight':{
        visibility: 'show',
      },
      '.MuiDataGrid-columnHeaders':{
        height:'40px',
      },
      // 削除済明細は背景色をグレーとする
      '.MuiDataGrid-row.row-deactive': {
        background: '#888888',
      }
    }
  }

  const handleDownload = (value,value2) => {
    // ファイルのURL
    const fileUrl = value;
    console.log('DL:'+value);
    // リンクをクリックしてダウンロード
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = value2;
    const data = {
      "files":[
        {
          "status":'9',
          "mise":state.MISES,
          "sectime":'',
          "filename":'',
          "filepath": value
        }
      ]
    };
    const response2 = fetch('/TCSAPRINU/SL_PRT_MST_upd',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data)});
    // const data2 = response2.json();
    // console.log('伝票ヘッダ:%o',data2);

    link.click();
    getFileList();
    
  };

  // グリッド列情報(エラー出力されているが処理可能)
  const columns: GridColDef[] = [
    // {
    //   field: 'detailAction',
    //   headerName: '　', // セルの右側に罫線を表示するために全角スペースを表示する
    //   align: 'left',
    //   width: 60,
    //   type: 'actions',
    //   getActions: getDetailAction,
    //   description: '詳細画面を表示',  // ツールチップで表示される説明
    //   hideable: false,              // 非表示にできないようにする
    //   sortable: false,              // ソートできないようにする
    //   hideSortIcons: false,         // ソートアイコンを表示しない
    //   resizable: false,             // リサイズできないようにする
    //   disableExport: false,         // エクスポートに含めないようにする
    //   disableReorder: false,        // 並べ替えできないようにする
    //   filterable: false,            // フィルタリングしないようにする
    //   disableColumnMenu: true       // カラムメニューを表示しない
    // } as GridColDef ,
    {
      field: 'id',
      headerName: 'No',
      headerAlign: 'center',
      align:'center',
      width: 50,
      sortable:false,
      disableColumnMenu:true
    }, //IDは必ず必要みたい
    {
      field: 'status',
      headerName: 'ステータス',
      headerAlign: 'center',
      align:'center',
      width: 100,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true,
    },
    {
      field: 'filename',
      headerName: '帳票名',
      headerAlign: 'center',
      width: 440,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true
    },
    {
      field: 'filepath',
      headerName: '',
      headerAlign: 'center',
      width: 405,
      editable: false ,   //編集可能:False
      sortable:false,
      disableColumnMenu:true,
      renderCell: (params: GridRenderCellParams<row>) => (
        <>
        {console.log('詳細:%o',params)}
          {/* <Link to={`${params.value}`}>
          {params.value}
          </Link> */}
          <button onClick={() => handleDownload(params.value,params.row.bfilename)}>
            Download
          </button>          
      </>      ),
    },
  ];

  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "750px",
          // height: "85vh",
          width: "1250",
          m: "20px auto"
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="left"
        >
          <div style={{display:"flex"}}>
            <Typography variant={"h5"} sx={{ m: "10px" }}>
              仕入伝票（手書Ⅰ型）入力
            </Typography>
            <Box width={'60%'} display={'flex'} justifyContent={'flex-end'}>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"17px",fontSize:15 }}>
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"17px",fontSize:15 }}>
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"17px",fontSize:15 }}>
                {state.MISEMEI}：{state.JGNMEIKNJ}
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"17px",fontSize:15 }}>
                { sdate }
              </Typography>
            </Box>
          </div>
        </Grid>

        {/* テーブル表示処理 */}
        <Box sx={{ height: 631, width: '1250', mt:'10px' }}>
          <DataGrid
            sx={styles.grid}     // --> 不具合対応のためcssで罫線を引く
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            disableRowSelectionOnClick
            showColumnRightBorder
            getRowClassName={(params) => {
              if (params.row.DELFLG === '1') {
                // 削除済明細にクラスを設定する
                return 'row-deactive'
              }
              return '';
            }}
          />
        </Box>
        <br />
        <font color="red">{ alert }</font>
        <Stack direction="row" spacing={2}>
          <font color="black">{ 'F1:再検索　F5:終了' }</font>
          
        </Stack>
      </Paper>
    </Grid>
  );
};

export default TCSA9999;