import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, TextField, Grid, Paper, Typography, Stack, InputLabel } from "@mui/material";
import Table from '@mui/material/Table';              //テーブル用
import TableBody from '@mui/material/TableBody';      //テーブル用
import TableCell from '@mui/material/TableCell';      //テーブル用
import TableContainer from '@mui/material/TableContainer';  //テーブル用
import TableHead from '@mui/material/TableHead';      //テーブル用
import TableRow from '@mui/material/TableRow';        //テーブル用
import { NumericFormat } from 'react-number-format';    //数値入力
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F5キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F9キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F11") { // F11キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSA0210 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('　');
  const [dismeisai, setMeisai] = useState(true);    //disabled設定（明細）
  const [sdate, setsDate] = useState([]);

  const [transitionSource, setTransitionSource] = useState({});  // 遷移元
  const [tgtDate, setTgtDate] = useState('');  // 対象日付
  const [inpbin, setbin] = useState('1');        // 2023/11/02 kameda 便追加
  const [rows, setRows] = useState([]);
  const [rowsh, setRowsH] = useState([]);       //従業員番号表示用
  const [underTotal, setUnderTotal] = useState([]);
  const [grandTotal, setGrandTotal] = useState('');

  const [inTgtDateclr,setTgtDateclr] = useState('#FFFFFF');
  const [itBinclr,setItBinclr] = useState('#FFFFFF');   // 2023/11/02 kameda 便追加

  const inKIJYYM = useRef(null);
  const inBIN = useRef(null);       // 2023/11/02 kameda 便追加
  const inDENYMD = useRef(null);

  const inRET = useRef(null);

  const inDWNGIIN1 = useRef(null);  
 
  const inSUUG = useRef(null);
  const inOLDBAKG = useRef(null);  
  const inNEWBAKG = useRef(null);  
  const inBIHNGKG = useRef(null);  

// No.
  const inNo01 = useRef(null);  
  const inNo02 = useRef(null);  
  const inNo03 = useRef(null);  
  const inNo04 = useRef(null);  
  const inNo05 = useRef(null);  
  const inNo06 = useRef(null);  
  const inNo07 = useRef(null);  
  const inNo08 = useRef(null);  
  const inNo09 = useRef(null);  
  const inNo10 = useRef(null);  
  const inNo11 = useRef(null);  
  const inNo12 = useRef(null);  
  const inNo13 = useRef(null);  
  const inNo14 = useRef(null);  
  const inNo15 = useRef(null);  

//分類CD
const inBMNCDE01 = useRef(null);  
const inBMNCDE02 = useRef(null);  
const inBMNCDE03 = useRef(null);  
const inBMNCDE04 = useRef(null);  
const inBMNCDE05 = useRef(null);  
const inBMNCDE06 = useRef(null);  
const inBMNCDE07 = useRef(null);  
const inBMNCDE08 = useRef(null);  
const inBMNCDE09 = useRef(null);  
const inBMNCDE10 = useRef(null);  
const inBMNCDE11 = useRef(null);  
const inBMNCDE12 = useRef(null);  
const inBMNCDE13 = useRef(null);  
const inBMNCDE14 = useRef(null);  
const inBMNCDE15 = useRef(null);  

//部門名
const inBMNMEI01 = useRef(null);  
const inBMNMEI02 = useRef(null);  
const inBMNMEI03 = useRef(null);  
const inBMNMEI04 = useRef(null);  
const inBMNMEI05 = useRef(null);  
const inBMNMEI06 = useRef(null);  
const inBMNMEI07 = useRef(null);  
const inBMNMEI08 = useRef(null);  
const inBMNMEI09 = useRef(null);  
const inBMNMEI10 = useRef(null);  
const inBMNMEI11 = useRef(null);  
const inBMNMEI12 = useRef(null);  
const inBMNMEI13 = useRef(null);  
const inBMNMEI14 = useRef(null);  
const inBMNMEI15 = useRef(null);  

//担当者1
const inJGN101 = useRef(null);  
const inJGN102 = useRef(null);  
const inJGN103 = useRef(null);  
const inJGN104 = useRef(null);  
const inJGN105 = useRef(null);  
const inJGN106 = useRef(null);  
const inJGN107 = useRef(null);  
const inJGN108 = useRef(null);  
const inJGN109 = useRef(null);  
const inJGN110 = useRef(null);  
const inJGN111 = useRef(null);  
const inJGN112 = useRef(null);  
const inJGN113 = useRef(null);  
const inJGN114 = useRef(null);  
const inJGN115 = useRef(null);  

//担当者2
const inJGN201 = useRef(null);  
const inJGN202 = useRef(null);  
const inJGN203 = useRef(null);  
const inJGN204 = useRef(null);  
const inJGN205 = useRef(null);  
const inJGN206 = useRef(null);  
const inJGN207 = useRef(null);  
const inJGN208 = useRef(null);  
const inJGN209 = useRef(null);  
const inJGN210 = useRef(null);  
const inJGN211 = useRef(null);  
const inJGN212 = useRef(null);  
const inJGN213 = useRef(null);  
const inJGN214 = useRef(null);  
const inJGN215 = useRef(null);  

//担当者3
const inJGN301 = useRef(null);  
const inJGN302 = useRef(null);  
const inJGN303 = useRef(null);  
const inJGN304 = useRef(null);  
const inJGN305 = useRef(null);  
const inJGN306 = useRef(null);  
const inJGN307 = useRef(null);  
const inJGN308 = useRef(null);  
const inJGN309 = useRef(null);  
const inJGN310 = useRef(null);  
const inJGN311 = useRef(null);  
const inJGN312 = useRef(null);  
const inJGN313 = useRef(null);  
const inJGN314 = useRef(null);  
const inJGN315 = useRef(null);  

//担当者4
const inJGN401 = useRef(null);  
const inJGN402 = useRef(null);  
const inJGN403 = useRef(null);  
const inJGN404 = useRef(null);  
const inJGN405 = useRef(null);  
const inJGN406 = useRef(null);  
const inJGN407 = useRef(null);  
const inJGN408 = useRef(null);  
const inJGN409 = useRef(null);  
const inJGN410 = useRef(null);  
const inJGN411 = useRef(null);  
const inJGN412 = useRef(null);  
const inJGN413 = useRef(null);  
const inJGN414 = useRef(null);  
const inJGN415 = useRef(null);  

//担当者5
const inJGN501 = useRef(null);  
const inJGN502 = useRef(null);  
const inJGN503 = useRef(null);  
const inJGN504 = useRef(null);  
const inJGN505 = useRef(null);  
const inJGN506 = useRef(null);  
const inJGN507 = useRef(null);  
const inJGN508 = useRef(null);  
const inJGN509 = useRef(null);  
const inJGN510 = useRef(null);  
const inJGN511 = useRef(null);  
const inJGN512 = useRef(null);  
const inJGN513 = useRef(null);  
const inJGN514 = useRef(null);  
const inJGN515 = useRef(null);  

//担当者6
const inJGN601 = useRef(null);  
const inJGN602 = useRef(null);  
const inJGN603 = useRef(null);  
const inJGN604 = useRef(null);  
const inJGN605 = useRef(null);  
const inJGN606 = useRef(null);  
const inJGN607 = useRef(null);  
const inJGN608 = useRef(null);  
const inJGN609 = useRef(null);  
const inJGN610 = useRef(null);  
const inJGN611 = useRef(null);  
const inJGN612 = useRef(null);  
const inJGN613 = useRef(null);  
const inJGN614 = useRef(null);  
const inJGN615 = useRef(null);  

//担当者7
const inJGN701 = useRef(null);  
const inJGN702 = useRef(null);  
const inJGN703 = useRef(null);  
const inJGN704 = useRef(null);  
const inJGN705 = useRef(null);  
const inJGN706 = useRef(null);  
const inJGN707 = useRef(null);  
const inJGN708 = useRef(null);  
const inJGN709 = useRef(null);  
const inJGN710 = useRef(null);  
const inJGN711 = useRef(null);  
const inJGN712 = useRef(null);  
const inJGN713 = useRef(null);  
const inJGN714 = useRef(null);  
const inJGN715 = useRef(null);  

//行合計
const inSUUG01 = useRef(null);
const inSUUG02 = useRef(null);
const inSUUG03 = useRef(null);
const inSUUG04 = useRef(null);
const inSUUG05 = useRef(null);
const inSUUG06 = useRef(null);
const inSUUG07 = useRef(null);
const inSUUG08 = useRef(null);
const inSUUG09 = useRef(null);
const inSUUG10 = useRef(null);
const inSUUG11 = useRef(null);
const inSUUG12 = useRef(null);
const inSUUG13 = useRef(null);
const inSUUG14 = useRef(null);
const inSUUG15 = useRef(null);



//フッタ部
//数量合計
const inJGNSUUG1 = useRef(null);  
const inJGNSUUG2 = useRef(null);  
const inJGNSUUG3 = useRef(null);  
const inJGNSUUG4 = useRef(null);  
const inJGNSUUG5 = useRef(null);  
const inJGNSUUG6 = useRef(null);  
const inJGNSUUG7 = useRef(null);  


  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      // navigate('/TCSA0003');
      switch(state.MVEMGAMEN){
        case "TCSA0100":
          // navigate('/TCSA0004');//前の画面に戻る場合はTCSA0004経由する
          navigate('/TCSA0100');
          break;
        case "TCSA0130":
          navigate('/TCSA0130');
          break;
        default:
          break;
      }
   }
    if (event.keyCode === 120) { // F9キーが押された場合の処理
    }
    if (event.keyCode === 122) { // F11キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  
  useEffect (()=>{
    // いったんコメントアウト
    // if (state.KENGEN === '')
    // {
    //   console.log('直リン不可'+state.KENGEN);
    //   navigate('/');
    // }else
    // {
    //   console.log('メニュー:%o',state)
    // }
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    const makeDate = year + '/' + month + '/' + day;
    setsDate(makeDate);

    // 日付オブジェクトを作成
    let originalDate = new Date(makeDate);
    // 年、月、日を取得
    year = originalDate.getFullYear().toString();
    month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // 月は0から始まるため+1して、2桁にパディング
    day = originalDate.getDate().toString().padStart(2, '0'); // 日も2桁にパディング
    setTgtDate(year.substr(-2) + '/' + month + '/' + day);

    // 遷移元でテキスト切り替え
    // if (window.location.pathname == "/TCSA0100") {
    //   setTransitionSource({page: "TCSA0100", text: "ターンアラウンド"});
    // } else if (window.location.pathname == "/TCSA0130"){
    //   setTransitionSource({page: "TCSA0130", text: "手書Ⅰ型"});
    // } else {
    //   console.log('他の遷移元からは不可');
    //   // navigate('/');
    // }
    // // 仮
    // setTransitionSource({page: "TCSA0130", text: "手書Ⅰ型"});
    if (state.MVEMGAMEN==='TCSA0100')
    {
      setTransitionSource({page: "TCSA0100", text: "ターンアラウンド"});
    }else if(state.MVEMGAMEN==='TCSA0130') {
      setTransitionSource({page: "TCSA0130", text: "手書Ⅰ型"});
    }else{
      console.log('他の遷移元からは不可');
    }
    setTimeout(() => {totalling();},1000);
    // totalling();
  },[]);
 
  //集計関数
  const totalling =  async () => {
            //初期化
            setRows([]);
            setRowsH([]);
            let objJgnData = {
              num1: 0,
              num2: 0,
              num3: 0,
              num4: 0,
              num5: 0,
              num6: 0,
              num7: 0,
            };
            setUnderTotal(objJgnData);
            setGrandTotal('');

            const errCheckFlg = await onKeyDown_KIJYYM();

            if (errCheckFlg === false) {
              // 検索条件となる、会社コード、店コードをセンター管理マスタより取得
              const responseA = await fetch('/TCSA0210/getSL_CTR_MST',
                {method: 'POST',
                  headers: {'Content-Type': 'application/json'},
                  body: JSON.stringify({
                    ent_ksacde: state.KSACDES,
                    ent_mise: state.MISES
                  })
                });
              const dataA = await responseA.json();

              if (dataA === null) {
                setAlert("物流センター管理マスタに登録されていません。");
                return;
              }

              let apiPath = "";
              // 呼出元画面がTCSA0100の場合
              if (state.MVEMGAMEN === "TCSA0100") {
                apiPath = "/TCSA0210/getTCSA0100";
              }
              // 呼出元画面がTCSA0130の場合
              else if(state.MVEMGAMEN === "TCSA0130") {
                apiPath = "/TCSA0210/getTCSA0130";
              }else{
                console.log("呼出元画面不明");
                return;
              }

              // 2023/11/01 kameda 物流対応（各店の場合は各店のみ集計する）
              let ksacdex = "";
              let misex = "";
              if (state.ETC === 'K')
              {
                ksacdex = state.K_KSACDES;
                misex = state.K_MISES;
              }else{
                ksacdex = state.KSACDES;
                misex = state.MISES;
              }
                            
              const sendDate = await convertDate(inKIJYYM.current.value);
              const responseB = await fetch(apiPath,
                {method: 'POST',
                  headers: {'Content-Type': 'application/json'},
                  // 2023/11/01 kameda 物流対応（各店の場合は各店のみ集計する）
                  // body: JSON.stringify({data: dataA, tgtDate: sendDate})
                  // 2023/11/02 kameda 便対応
                  // body: JSON.stringify({ent_ksacde: ksacdex,ent_mise: misex, tgtDate: sendDate})
                  body: JSON.stringify({ent_ksacde: ksacdex,ent_mise: misex, tgtDate: sendDate,bin:inpbin})
                });
              const dataB = await responseB.json();
              console.log(dataB);

              if (dataB === null) {
                setAlert("データが在りませんでした。");
                return;
              }

              // 商品分類名（漢字）取得
              const responseC = await fetch('/TCSA0210/getMR_PRD_CLS_MST',
                {method: 'POST',
                  headers: {'Content-Type': 'application/json'},
                  body: JSON.stringify({
                    ent_ksacde: state.KSACDES,
                    ent_mise: state.MISES,
                    data: dataB,
                  })
                });
              const dataC = await responseC.json();
              console.log(dataC);

              // if (dataC === null) {
              //   setAlert("データが在りませんでした。");
              //   break;
              // }

              // rows作成
              let no = 1;
              let newRows = [];
              let tmpData = dataB[0];
              let jgn_cnt = 1;
              let objMakeData = {
                no: no,
                bmn_cde: "",
                brumei: "",
                jgnData: JSON.parse(JSON.stringify(objJgnData)),
                total: 0,
              };
              let tmpJgnData = JSON.parse(JSON.stringify(objJgnData));
              let tmpMakeData = JSON.parse(JSON.stringify(objMakeData));
              let tmpUnderTotal = JSON.parse(JSON.stringify(objJgnData));
              let tmpGrandTotal = 0;

              // 2023/10/12（従業員番号表示用）
              let newRowsH = [];
              // 2023/10/12（従業員番号とjgn_cntを紐付け用WORK）
              let objJGNCDE = {
                num1: '',
                num2: '',
                num3: '',
                num4: '',
                num5: '',
                num6: '',
                num7: '',
              };

              dataB.forEach((element, index, array) => {

                // 2023/10/12（従業員番号とjgn_cntを紐付け）
                // let wkJGN = 1;
                for (let i = 1; i <= 7; i++){
                  if (objJGNCDE["num" + i] == ''){
                    jgn_cnt = i;
                    break;
                  }else if(objJGNCDE["num" + i] == element.UPD_JGNCDE){
                    jgn_cnt = i;
                    break;
                  }
                }
                if(jgn_cnt!==0){
                  objJGNCDE["num" + jgn_cnt]=element.UPD_JGNCDE;
                }

                if(element.BMNCDE != tmpData.BMNCDE){
                  tmpMakeData.jgnData = tmpJgnData;
                  newRows.push(tmpMakeData);
                  no++;
                      // 初期化
                  // jgn_cnt = 1;// 2023/10/12 ↑でカウントするからコメント化
                  tmpJgnData = JSON.parse(JSON.stringify(objJgnData));
                  tmpMakeData = JSON.parse(JSON.stringify(objMakeData));
                  tmpMakeData.no = no;
                }
                // 2023/10/12 ↑でカウントするからコメント化
                // if(tmpJgnData["num" + jgn_cnt] != 0){
                //   jgn_cnt++;
                // }
                tmpJgnData["num" + jgn_cnt] = element.UPD_JGNCDE_COUNT;
                // 下の合計加算
                tmpMakeData.total += element.UPD_JGNCDE_COUNT;
                // 右の合計加算
                tmpUnderTotal["num" + jgn_cnt] += element.UPD_JGNCDE_COUNT;
                // 総合計加算
                tmpGrandTotal += element.UPD_JGNCDE_COUNT;
                
                // 部類名取得
                const find = dataC.find((obj) => {
                  return obj.BMNCDE == element.BMNCDE;
                });
                if(find){
                  tmpMakeData.bmn_cde = find.BMNCDE;
                  tmpMakeData.brumei = find.BRUMEI;
                }

                tmpData = element;

                //（暫定処置）forEachの最後のみプッシュする
                if (index === array.length - 1){ 
                  tmpMakeData.jgnData = tmpJgnData;
                  newRows.push(tmpMakeData);
                }
              });
              console.log(newRows);
              setRows(newRows);
              setUnderTotal(tmpUnderTotal);
              setGrandTotal(tmpGrandTotal);

              //従業員番号をRowsに格納する
              newRowsH.push(objJGNCDE);
              // console.log(newRowsH);
              setRowsH(newRowsH);
            }
            return;
  }

  const onKeyDown =  async (key,inputRef,inputNxRef) => {  //  入力キー、現在の項目情報、次項目情報
    //console.log(key);
    switch (key) {
      case "Enter":
        setAlert('　');
        setTgtDateclr('#FFFFFF');
        inputNxRef.current.focus();   //次の項目に遷移
        
        switch(inputRef.current.id) {
          case 'YMD':
            // //初期化
            // setRows([]);
            // setRowsH([]);
            // let objJgnData = {
            //   num1: 0,
            //   num2: 0,
            //   num3: 0,
            //   num4: 0,
            //   num5: 0,
            //   num6: 0,
            //   num7: 0,
            // };
            // setUnderTotal(objJgnData);
            // setGrandTotal('');

            // const errCheckFlg = await onKeyDown_KIJYYM();

            // if (errCheckFlg === false) {
            //   // 仮
            //   // const dummy_ent_ksacde = "001";
            //   // const dummy_ent_mise = "350";
            //   // 検索条件となる、会社コード、店コードをセンター管理マスタより取得
            //   const responseA = await fetch('/TCSA0210/getSL_CTR_MST',
            //     {method: 'POST',
            //       headers: {'Content-Type': 'application/json'},
            //       body: JSON.stringify({
            //         ent_ksacde: state.KSACDES,
            //         ent_mise: state.MISES
            //         // ent_ksacde: dummy_ent_ksacde,
            //         // ent_mise: dummy_ent_mise
            //       })
            //     });
            //   const dataA = await responseA.json();

            //   if (dataA === null) {
            //     setAlert("物流センター管理マスタに登録されていません。");
            //     break;
            //   }

            //   // console.log('transitionSource.page:',transitionSource.page);
            //   let apiPath = "";
            //   // 呼出元画面がTCSA0100の場合
            //   if (transitionSource.page === "TCSA0100") {
            //     apiPath = "/TCSA0210/getTCSA0100";
            //   }
            //   // 呼出元画面がTCSA0130の場合
            //   else if(transitionSource.page === "TCSA0130") {
            //     apiPath = "/TCSA0210/getTCSA0130";
            //   }

            //   const sendDate = convertDate(tgtDate);
            //   const responseB = await fetch(apiPath,
            //     {method: 'POST',
            //       headers: {'Content-Type': 'application/json'},
            //       body: JSON.stringify({data: dataA, tgtDate: sendDate})
            //     });
            //   const dataB = await responseB.json();
            //   console.log(dataB);

            //   if (dataB === null) {
            //     setAlert("データが在りませんでした。");
            //     break;
            //   }

            //   // 商品分類名（漢字）取得
            //   const responseC = await fetch('/TCSA0210/getMR_PRD_CLS_MST',
            //     {method: 'POST',
            //       headers: {'Content-Type': 'application/json'},
            //       body: JSON.stringify({
            //         ent_ksacde: state.KSACDES,
            //         ent_mise: state.MISES,
            //         // ent_ksacde: dummy_ent_ksacde,
            //         // ent_mise: dummy_ent_mise,
            //         data: dataB,
            //       })
            //     });
            //   const dataC = await responseC.json();
            //   console.log(dataC);

            //   // if (dataC === null) {
            //   //   setAlert("データが在りませんでした。");
            //   //   break;
            //   // }

            //   // rows作成
            //   let no = 1;
            //   let newRows = [];
            //   let tmpData = dataB[0];
            //   let jgn_cnt = 1;
            //   let objMakeData = {
            //     no: no,
            //     bmn_cde: "",
            //     brumei: "",
            //     jgnData: JSON.parse(JSON.stringify(objJgnData)),
            //     total: 0,
            //   };
            //   let tmpJgnData = JSON.parse(JSON.stringify(objJgnData));
            //   let tmpMakeData = JSON.parse(JSON.stringify(objMakeData));
            //   let tmpUnderTotal = JSON.parse(JSON.stringify(objJgnData));
            //   let tmpGrandTotal = 0;

            //   // 2023/10/12（従業員番号表示用）
            //   let newRowsH = [];
            //   // 2023/10/12（従業員番号とjgn_cntを紐付け用WORK）
            //   let objJGNCDE = {
            //     num1: '',
            //     num2: '',
            //     num3: '',
            //     num4: '',
            //     num5: '',
            //     num6: '',
            //     num7: '',
            //   };

            //   dataB.forEach((element, index, array) => {
            //     // if(element.BMNCDE != tmpData.BMNCDE){
            //     //   tmpMakeData.jgnData = tmpJgnData;
            //     //   newRows.push(tmpMakeData);
            //     //   no++;
            //     //       // 初期化
            //     //   jgn_cnt = 1;
            //     //   tmpJgnData = JSON.parse(JSON.stringify(objJgnData));
            //     //   tmpMakeData = JSON.parse(JSON.stringify(objMakeData));
            //     //   tmpMakeData.no = no;
            //     // }

            //     // 2023/10/12（従業員番号とjgn_cntを紐付け）
            //     // let wkJGN = 1;
            //     for (let i = 1; i <= 7; i++){
            //       if (objJGNCDE["num" + i] == ''){
            //         jgn_cnt = i;
            //         break;
            //       }else if(objJGNCDE["num" + i] == element.UPD_JGNCDE){
            //         jgn_cnt = i;
            //         break;
            //       }
            //     }
            //     if(jgn_cnt!==0){
            //       objJGNCDE["num" + jgn_cnt]=element.UPD_JGNCDE;
            //     }

            //     if(element.BMNCDE != tmpData.BMNCDE){
            //       tmpMakeData.jgnData = tmpJgnData;
            //       newRows.push(tmpMakeData);
            //       no++;
            //           // 初期化
            //       // jgn_cnt = 1;// 2023/10/12 ↑でカウントするからコメント化
            //       tmpJgnData = JSON.parse(JSON.stringify(objJgnData));
            //       tmpMakeData = JSON.parse(JSON.stringify(objMakeData));
            //       tmpMakeData.no = no;
            //     }
            //     // 2023/10/12 ↑でカウントするからコメント化
            //     // if(tmpJgnData["num" + jgn_cnt] != 0){
            //     //   jgn_cnt++;
            //     // }
            //     tmpJgnData["num" + jgn_cnt] = element.UPD_JGNCDE_COUNT;
            //     // 下の合計加算
            //     tmpMakeData.total += element.UPD_JGNCDE_COUNT;
            //     // 右の合計加算
            //     tmpUnderTotal["num" + jgn_cnt] += element.UPD_JGNCDE_COUNT;
            //     // 総合計加算
            //     tmpGrandTotal += element.UPD_JGNCDE_COUNT;
                
            //     // 部類名取得
            //     const find = dataC.find((obj) => {
            //       return obj.BMNCDE == element.BMNCDE;
            //     });
            //     if(find){
            //       tmpMakeData.bmn_cde = find.BMNCDE;
            //       tmpMakeData.brumei = find.BRUMEI;
            //     }

            //     tmpData = element;

            //     //（暫定処置）forEachの最後のみプッシュする
            //     if (index === array.length - 1){ 
            //       tmpMakeData.jgnData = tmpJgnData;
            //       newRows.push(tmpMakeData);
            //     }
            //   });
            //   console.log(newRows);
            //   setRows(newRows);
            //   setUnderTotal(tmpUnderTotal);
            //   setGrandTotal(tmpGrandTotal);

            //   //従業員番号をRowsに格納する
            //   newRowsH.push(objJGNCDE);
            //   // console.log(newRowsH);
            //   setRowsH(newRowsH);
            // }
            // break;
            totalling();
            break;
          case "BIN":
            totalling();
            break;
          default:
            break;
        }
        break;
      case "Home":
        break;
      case "ArrowUp":
        inputRef.current.focus();   //次の項目に遷移
        break;
      case "ArrowDown":
        inputNxRef.current.focus();   //次の項目に遷移
        break;
      default:
        break;
    }
  };

  // ヘッダ部１入力　　対象日付
  const onKeyDown_KIJYYM = async () => {
    // 日付チェック
    let strDate = inKIJYYM.current.value;
    let indate = new Date('20'+strDate);
    if (isNaN(indate.getDate())) {
      setTgtDateclr('#f59d9d');
      setAlert('日付入力エラー');//TCGE0016：
      return true;
    }

    //便チェック
    setItBinclr('#ffffff');
    if (!(inpbin == '1' || inpbin == '2'))
    {
      setAlert('便は「１」、「２」以外は入力することはできません。');//TCGE0004:
      setItBinclr('#f59d9d');
      inKIJYYM.current.focus();
      inBIN.current.focus();
      return true;
    }

    return false;
  }

  // 日付コンバート
  const convertDate =  async (dateString) => {
    var dateParts = dateString.split("/");
  
    if (dateParts[0].length === 2) {
      dateParts[0] = "20" + dateParts[0];
    }
  
    var newDate = dateParts.join("/");
    return newDate;
  }

  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
         fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    //明細用
    cssInputOutline2: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#FFFFFF',               // 入力文字の色
      },
      '& label': {
        color: '#FFFFFF',               // 通常時のラベル色
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#FFFFFF',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#FFFFFF',   // ホバー時のボーダー色
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#FFFFFF',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):after': {
        borderBottomColor: '#FFFFFF',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#FFFFFF',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#FFFFFF',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#FFFFFF',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#FFFFFF',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
          fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
  }

  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "730px",
          width: "1200px",
          m: "10px auto",
          padding:"10px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="left"
        >
          <div style={{display:"flex"}}>
            <Typography variant={"h5"} sx={{ m: "10px" }}>
            分類別入力枚数確認
            </Typography>
            <Box width={'65%'} display={'flex'} justifyContent={'flex-end'}>
              <Typography variant={"h5"} sx={{ m: "10px",mt:"17px",fontSize:15 }}>
                {transitionSource.text}
              </Typography>
              　　　　　
              <Typography variant={"h6"} sx={{ m: "13px",mt:"17px",fontSize:15 }}>
                {state.MISEMEI}：{state.JGNMEIKNJ}
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"17px",fontSize:15 }}>
                { sdate }
              </Typography>　　　　　
            </Box>
          </div>
        </Grid>

        <Stack direction="row" >
          <PatternFormat  //対象日付
              id={'YMD'}
              inputRef={inKIJYYM}                   //項目名定義？
              format="##/##/##"
              mask={['Y','Y','M','M','D','D']}
              color='success'
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り           
              size='small'                        //textboxの全体サイズ
              label='対象日付'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              // sx={{width: '10ch',marginLeft:'20px', background:inTgtDateclr}}                 //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 9), {background:inTgtDateclr})}
              value={tgtDate}                     //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={false}                    //使用可／不可
              placeholder="YY/MM/DD"
              inputProps={{ autoComplete: 'off',maxLength: 9,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
              onKeyDown={(e) => onKeyDown(e.key,inKIJYYM,inBIN)}
              onChange={e => setTgtDate(e.target.value)}
              />　
          <NumericFormat  //便
              id={'BIN'}
              inputRef={inBIN}                   //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り           
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='便'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 1), {background:itBinclr},{visibility:state.MVEMGAMEN === "TCSA0100" ? 'visible' : 'hidden'})}
              value={inpbin}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={false}                    //使用可／不可
              placeholder=""
              inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
              onKeyDown={(e) => onKeyDown(e.key,inBIN,inKIJYYM)}
              onChange={(event) => setbin(event.target.value)}
              //onFocus={(e) => e.target.select()}
              onFocus={(e) => setTimeout(() => {inBIN.current.select();},100)}
              />
        </Stack>
        <InputLabel variant={'outlined'} >入力枚数確認明細</InputLabel>
        <Stack direction="row">
        </Stack>
        <br />
        {/* テーブル表示処理 */}
        <TableContainer component={Paper} sx={{maxWidth:900}}>
          <Table sx={{border:0}} aria-label="spanning table" size="small">
            <TableHead> {/* テーブル表示処理ヘッダ部 */}
            {rowsh.map((rowsh) => (
              <TableRow>
                <TableCell sx={{border:1,padding:0}} width="20px" align="center" bgcolor="lightgray">No.</TableCell>
                <TableCell sx={{border:1,padding:0}} width="40px" align="center" bgcolor="lightgray">分類CD</TableCell>
                <TableCell sx={{border:1,padding:0}} width="160px" align="center"  bgcolor="lightgray">分類名</TableCell>
                {/* <TableCell sx={{border:1,padding:0}} width="40px" align="center"  bgcolor="lightgray">担当者1</TableCell> */}
                {/* <TableCell sx={{border:1,padding:0}} width="50px" align="center"  bgcolor="lightgray">担当者2</TableCell> */}
                {/* <TableCell sx={{border:1,padding:0}} width="50px" align="center"  bgcolor="lightgray">担当者3</TableCell> */}
                {/* <TableCell sx={{border:1,padding:0}} width="50px" align="center"  bgcolor="lightgray">担当者4</TableCell> */}
                {/* <TableCell sx={{border:1,padding:0}} width="50px" align="center"  bgcolor="lightgray">担当者5</TableCell> */}
                {/* <TableCell sx={{border:1,padding:0}} width="50px" align="center"  bgcolor="lightgray">担当者6</TableCell> */}
                {/* <TableCell sx={{border:1,padding:0}} width="50px" align="center"  bgcolor="lightgray">担当者7</TableCell> */}
                <TableCell sx={{border:1,padding:0}} width="40px" align="center"  bgcolor="lightgray">{rowsh.num1}</TableCell>
                <TableCell sx={{border:1,padding:0}} width="50px" align="center"  bgcolor="lightgray">{rowsh.num2}</TableCell>
                <TableCell sx={{border:1,padding:0}} width="50px" align="center"  bgcolor="lightgray">{rowsh.num3}</TableCell>
                <TableCell sx={{border:1,padding:0}} width="50px" align="center"  bgcolor="lightgray">{rowsh.num4}</TableCell>
                <TableCell sx={{border:1,padding:0}} width="50px" align="center"  bgcolor="lightgray">{rowsh.num5}</TableCell>
                <TableCell sx={{border:1,padding:0}} width="50px" align="center"  bgcolor="lightgray">{rowsh.num6}</TableCell>
                <TableCell sx={{border:1,padding:0}} width="50px" align="center"  bgcolor="lightgray">{rowsh.num7}</TableCell>
                <TableCell sx={{border:1,padding:0}} width="50px" align="center"  bgcolor="lightgray">合計</TableCell>
            </TableRow>
            ))}
          </TableHead>
          <TableBody>   {/* テーブル表示処理明細部 */}
          {rows.map((rows) => (
            <TableRow>
              <TableCell align="center" sx={{border:1,padding:0}}>
                <NumericFormat  //No.1
                  id={'NO01'}
                  inputRef={inNo01}
                  color='success'
                  thousandSeparator={false}           
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '30px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'30px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={rows.no}
                  inputProps={{ autoComplete: 'off',maxLength: 12,style: {textAlign: 'center'} }} //最大文字数,右寄せ
                  disabled={dismeisai}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, suu1:event.target.value}))}
                  onKeyDown={(e) => onKeyDown(e.key,inDENYMD,inDWNGIIN1)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
              <TableCell align="center" sx={{border:1,padding:0}}>
                <NumericFormat  //分類CD1
                  id={'BMNCDE01'}
                  inputRef={inBMNCDE01}
                  color='success'
                  thousandSeparator={false}           
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '50px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'50px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={rows.bmn_cde}
                  inputProps={{ autoComplete: 'off',maxLength: 12,style: {textAlign: 'right'} }} //最大文字数,右寄せ
                  disabled={dismeisai}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, suu1:event.target.value}))}
                  onKeyDown={(e) => onKeyDown(e.key,inDENYMD,inDWNGIIN1)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
              {/* <TableCell align="center" sx={{border:1,padding:0}}>
                <NumericFormat  //分類名01
                  id={'BMNMEI01'}
                  inputRef={inBMNMEI01}
                  color='success'
                  thousandSeparator={false}           
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  sx={{width: '220px'}}
                  value={rows.brumei}
                  inputProps={{ autoComplete: 'off',maxLength: 12,style: {textAlign: 'center'} }} //最大文字数,右寄せ
                  disabled={dismeisai}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, suu1:event.target.value}))}
                  onKeyDown={(e) => onKeyDown(e.key,inDENYMD,inDWNGIIN1)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell> */}
              <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>{rows.brumei}</TableCell>
              <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
                <NumericFormat  //担当者101
                  id={'JGN101'}
                  inputRef={inJGN101}
                  color='success'
                  thousandSeparator={true}           
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '40px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'40px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={rows.jgnData.num1}
                  inputProps={{ autoComplete: 'off',maxLength: 12,style: {textAlign: 'right'} }} //最大文字数,右寄せ
                  disabled={dismeisai}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, suu1:event.target.value}))}
                  onKeyDown={(e) => onKeyDown(e.key,inDENYMD,inDWNGIIN1)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
              <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
                <NumericFormat  //担当者201
                  id={'JGN201'}
                  inputRef={inJGN201}
                  color='success'
                  thousandSeparator={true}           
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '40px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'40px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={rows.jgnData.num2}
                  inputProps={{ autoComplete: 'off',maxLength: 12,style: {textAlign: 'right'} }} //最大文字数,右寄せ
                  disabled={dismeisai}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, suu1:event.target.value}))}
                  onKeyDown={(e) => onKeyDown(e.key,inDENYMD,inDWNGIIN1)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
              <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
                <NumericFormat  //担当者301
                  id={'JGN301'}
                  inputRef={inJGN301}
                  color='success'
                  thousandSeparator={true}           
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '40px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'40px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={rows.jgnData.num3}
                  inputProps={{ autoComplete: 'off',maxLength: 12,style: {textAlign: 'right'} }} //最大文字数,右寄せ
                  disabled={dismeisai}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, suu1:event.target.value}))}
                  onKeyDown={(e) => onKeyDown(e.key,inDENYMD,inDWNGIIN1)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
              <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
                <NumericFormat  //担当者401
                  id={'JGN401'}
                  inputRef={inJGN401}
                  color='success'
                  thousandSeparator={true}           
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '40px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'40px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={rows.jgnData.num4}
                  inputProps={{ autoComplete: 'off',maxLength: 12,style: {textAlign: 'right'} }} //最大文字数,右寄せ
                  disabled={dismeisai}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, suu1:event.target.value}))}
                  onKeyDown={(e) => onKeyDown(e.key,inDENYMD,inDWNGIIN1)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
              <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
                <NumericFormat  //担当者501
                  id={'JGN501'}
                  inputRef={inJGN501}
                  color='success'
                  thousandSeparator={true}           
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '40px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'40px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={rows.jgnData.num5}
                  inputProps={{ autoComplete: 'off',maxLength: 12,style: {textAlign: 'right'} }} //最大文字数,右寄せ
                  disabled={dismeisai}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, suu1:event.target.value}))}
                  onKeyDown={(e) => onKeyDown(e.key,inDENYMD,inDWNGIIN1)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
              <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
                <NumericFormat  //担当者601
                  id={'JGN601'}
                  inputRef={inJGN601}
                  color='success'
                  thousandSeparator={true}           
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '40px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'40px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={rows.jgnData.num6}
                  inputProps={{ autoComplete: 'off',maxLength: 12,style: {textAlign: 'right'} }} //最大文字数,右寄せ
                  disabled={dismeisai}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, suu1:event.target.value}))}
                  onKeyDown={(e) => onKeyDown(e.key,inDENYMD,inDWNGIIN1)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
              <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
                <NumericFormat  //担当者701
                  id={'JGN701'}
                  inputRef={inJGN701}
                  color='success'
                  thousandSeparator={true}           
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '40px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'40px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={rows.jgnData.num7}
                  inputProps={{ autoComplete: 'off',maxLength: 12,style: {textAlign: 'right'} }} //最大文字数,右寄せ
                  disabled={dismeisai}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, suu1:event.target.value}))}
                  onKeyDown={(e) => onKeyDown(e.key,inDENYMD,inDWNGIIN1)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
              <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
                <NumericFormat  //行合計01
                  id={'SUUG01'}
                  inputRef={inSUUG01}
                  color='success'
                  thousandSeparator={true}           
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '40px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'40px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={rows.total}
                  inputProps={{ autoComplete: 'off',maxLength: 12,style: {textAlign: 'right'} }} //最大文字数,右寄せ
                  disabled={dismeisai}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, suu1:event.target.value}))}
                  onKeyDown={(e) => onKeyDown(e.key,inDENYMD,inDWNGIIN1)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
            </TableRow>
          ))}





         




            <TableRow>
              <TableCell align="right" sx={{border:0,padding:0,paddingRight:'10px'}}>　</TableCell>
              <TableCell align="right" sx={{border:0,padding:0,paddingRight:'10px'}}>　</TableCell>
              <TableCell align="center" sx={{border:1,padding:0}}>合計</TableCell>
              <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
                <NumericFormat  //担当者１数量合計
                  id={'JGNSUUG1'}
                  inputRef={inJGNSUUG1}
                  color='success'
                  thousandSeparator={','}
                  decimalScale={0}
                  fixedDecimalScale 
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '50px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'50px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={underTotal.num1}
                  allowLeadingZeros
                  disabled={dismeisai}
                  inputProps={{autoComplete: 'off',maxLength: 10,style: {textAlign: 'right'} }}
                  isAllowed={(values) => {
                    const numValue = Number(values.value);
                    if (isNaN(numValue)) return true;
                    return numValue >= -999999.9 && numValue <= 999999.9;
                  }}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, jancde1:event.target.value}))}
                  // onKeyDown={(e) => onKeyDown(e.key,inBAKKIN6,inOLDBAKG)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
              <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
                <NumericFormat  //担当者２数量合計
                  id={'JGNSUUG2'}
                  inputRef={inJGNSUUG2}
                  color='success'
                  thousandSeparator={','}
                  decimalScale={0}
                  fixedDecimalScale 
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '50px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'50px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={underTotal.num2}
                  allowLeadingZeros
                  disabled={dismeisai}
                  inputProps={{autoComplete: 'off',maxLength: 9,style: {textAlign: 'right'} }}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, jancde1:event.target.value}))}
                  // onKeyDown={(e) => onKeyDown(e.key,inSUUG,inNEWBAKG)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
              <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
                <NumericFormat  //担当者3数量合計
                  id={'JGNSUUG3'}
                  inputRef={inJGNSUUG3}
                  color='success'
                  thousandSeparator={','}
                  decimalScale={0}
                  fixedDecimalScale 
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '50px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'50px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={underTotal.num3}
                  allowLeadingZeros
                  disabled={dismeisai}
                  inputProps={{autoComplete: 'off',maxLength: 9,style: {textAlign: 'right'} }}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, jancde1:event.target.value}))}
                  onKeyDown={(e) => onKeyDown(e.key,inOLDBAKG,inBIHNGKG)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
              <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
                <NumericFormat  //担当者4数量合計
                  id={'JGNSUUG4'}
                  inputRef={inJGNSUUG4}
                  color='success'
                  thousandSeparator={','}
                  decimalScale={0}
                  fixedDecimalScale 
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '50px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'50px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={underTotal.num4}
                  allowLeadingZeros
                  disabled={dismeisai}
                  inputProps={{autoComplete: 'off',maxLength: 10,style: {textAlign: 'right'} }}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, jancde1:event.target.value}))}
                  onKeyDown={(e) => onKeyDown(e.key,inNEWBAKG,inRET)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
              <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
                <NumericFormat  //担当者5数量合計
                  id={'JGNSUUG5'}
                  inputRef={inJGNSUUG5}
                  color='success'
                  thousandSeparator={','}
                  decimalScale={0}
                  fixedDecimalScale 
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '50px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'50px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={underTotal.num5}
                  allowLeadingZeros
                  disabled={dismeisai}
                  inputProps={{autoComplete: 'off',maxLength: 10,style: {textAlign: 'right'} }}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, jancde1:event.target.value}))}
                  onKeyDown={(e) => onKeyDown(e.key,inNEWBAKG,inRET)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
              <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
                <NumericFormat  //担当者6数量合計
                  id={'JGNSUUG6'}
                  inputRef={inJGNSUUG6}
                  color='success'
                  thousandSeparator={','}
                  decimalScale={0}
                  fixedDecimalScale 
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '50px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'50px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={underTotal.num6}
                  allowLeadingZeros
                  disabled={dismeisai}
                  inputProps={{autoComplete: 'off',maxLength: 10,style: {textAlign: 'right'} }}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, jancde1:event.target.value}))}
                  onKeyDown={(e) => onKeyDown(e.key,inNEWBAKG,inRET)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
              <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
                <NumericFormat  //担当者7数量合計
                  id={'JGNSUUG7'}
                  inputRef={inJGNSUUG7}
                  color='success'
                  thousandSeparator={','}
                  decimalScale={0}
                  fixedDecimalScale 
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '50px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'50px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={underTotal.num7}
                  allowLeadingZeros
                  disabled={dismeisai}
                  inputProps={{autoComplete: 'off',maxLength: 10,style: {textAlign: 'right'} }}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, jancde1:event.target.value}))}
                  onKeyDown={(e) => onKeyDown(e.key,inNEWBAKG,inRET)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
              <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
                <NumericFormat  //数量総合計
                  id={'SUUG'}
                  inputRef={inSUUG}
                  color='success'
                  thousandSeparator={','}
                  decimalScale={0}
                  fixedDecimalScale 
                  customInput={TextField}
                  size='small'
                  label=''
                  variant='standard'
                  InputLabelProps={{ shrink: true }}
                  // sx={{width: '50px'}}
                  sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width:'50px',background:'#FFFFFF',input: { color: '#000000' }})}
                  value={grandTotal}
                  allowLeadingZeros
                  disabled={dismeisai}
                  inputProps={{autoComplete: 'off',maxLength: 10,style: {textAlign: 'right'} }}
                  //onChange={(event) => setGamen((prevState) => ({ ...prevState, jancde1:event.target.value}))}
                  onKeyDown={(e) => onKeyDown(e.key,inNEWBAKG,inRET)}
                  onFocus={(e) => e.target.select()}
                /> 
              </TableCell>
            </TableRow>
          </TableBody>
          </Table>
        </TableContainer><br/>
        <br />
        <font color="red">{ alert }</font>
        <Stack direction="row" spacing={2}>
          <font color="black">{ 'F5:戻る　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　' }</font>
        </Stack>
      </Paper>
    </Grid>
  );
};

export default TCSA0210;