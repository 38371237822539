import React, { useState, forwardRef, useImperativeHandle} from 'react'
import { TextField } from "@mui/material";
import TableCell from '@mui/material/TableCell';      //テーブル用
import { NumericFormat } from 'react-number-format';    //数値入力
import { PatternFormat } from 'react-number-format';
import { onMyFocus } from './hooks/useOnMyFocus';     // select()処理

//#region スタイル
// スタイルオブジェクトのWidthを自動設定する
const cssInputOutlineSetwidth = (obj, len) => {
  let resObj = obj;
  let calcLen = len;
  const minLen = 4;   // 最小桁数
  const oneLen = 20;  // 1桁につき20pxとして定義
  // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
  if (len < minLen) {
    calcLen = minLen;
  }
  resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
  return resObj;
}

const styles = {
  // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
  cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合
  cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合
  cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合
  cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合

  // OutLineのスタイル指定
  // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
  cssInputOutline: {
    // 活性時デザイン
    '& .MuiInputBase-input': {
      color: '#000000',               // 入力文字の色
      fontSize: '1.6rem',             // フォントサイズ
    },
    '& label': {
      color: '#000000',               // 通常時のラベル色
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#000000',   // 通常時のボーダー色
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#000000',   // ホバー時のボーダー色
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
      },
      '&:hover fieldset': {
        borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
      },
    },
    // 非活性時デザイン
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000000", // 非活性時の文字色
    },
    '& label.Mui-disabled': {
      color: '#000000',               // 非活性時のラベル色
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      '& fieldset': {
        borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
      },
      '&:hover.Mui-disabled fieldset': {
        borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
      },
    },
    width: '0px',         // 設定変更するので0pxで固定定義する
  },
  //明細用
  cssInputOutline2: {
    // 活性時デザイン
    '& .MuiInputBase-input': {
      color: '#FFFFFF',               // 入力文字の色
      fontSize: '1.6rem',             // フォントサイズ
    },
    '& label': {
      color: '#FFFFFF',               // 通常時のラベル色
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#FFFFFF',   // 通常時のボーダー色
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#FFFFFF',   // ホバー時のボーダー色
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFFFFF',   // 通常時のボーダー色
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):after': {
      borderBottomColor: '#FFFFFF',   // ホバー時のボーダー色
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#FFFFFF',       // 通常時のボーダー色(アウトライン)
      },
      '&:hover fieldset': {
        borderColor: '#FFFFFF',       // ホバー時のボーダー色(アウトライン)
      },
    },
    // 非活性時デザイン
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000000", // 非活性時の文字色
    },
    '& label.Mui-disabled': {
      color: '#000000',               // 非活性時のラベル色
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      '& fieldset': {
        borderColor: '#FFFFFF',       // 非活性時のボーダー色(アウトライン)
      },
      '&:hover.Mui-disabled fieldset': {
        borderColor: '#FFFFFF',       // ホバー時のボーダー色(アウトライン)
      },
    },
    width: '0px',         // 設定変更するので0pxで固定定義する
  },

  cssRecordFontSize: {
    // フォントサイズ
    '& .MuiInputBase-input': {
      fontSize: '1.2rem'
    },
    fontSize: '1.2rem',
  },

  cssRecordBorder: {
    border:1,
    padding:0,
  },

  cssRecordBorderAddPaddingRight: {
    border:1,
    padding:0,
    paddingRight:'10px',
  },
}
//#endregion

export const TCSA0150_parts = () => {
  //#region ヘッダ部
  // 区分
  const CKubun = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <NumericFormat  //区分
        id={'KBN'}
        inputRef={inputRef}                   //項目名定義？
        color='success'
        autoFocus                           //初期カーソル位置設定
        thousandSeparator={false}           //1000単位の区切り
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label='区分'                         //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
        decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 1), {background:itBGColor})}
        value={itValue}                     //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={false}                    //使用可／不可
        inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
        onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
        onFocus={(e) => onMyFocus(e)}
        onChange={(e) => setItValue(e.target.value)}
      />
    )
  });

  // 伝票No.
  const CDenpyoNo = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');
    const [checkflg,setCheckflg] = useState(0);
    const [focusflg,setFocusflg] = useState(false);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
        getCheckflg() {
          return checkflg;
        },
        setCheckflg(value) {
          setCheckflg(value);
        },
        getFocusflg() {
          return focusflg;
        },
        setFocusflg(value) {
          setFocusflg(value);
        },
      }
    });

    return (
      <NumericFormat  //伝票No.
        id={'DENNO'}
        inputRef={inputRef}                   //項目名定義？
        color='success'
        thousandSeparator={false}           //1000単位の区切り
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label='伝票No.'                         //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
        decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 7), {marginLeft:'20px',background:itBGColor})}
        value={itValue}               //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={false}                    //使用可／不可
        inputProps={{ autoComplete: 'off',maxLength: 7,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
        onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
        onBlur={(e) => setFocusflg(true)}
        onFocus={(e) => onMyFocus(e)}
        onChange={(e) => {
          setCheckflg(1);
          setItValue(e.target.value);
        }}
      />
    )
  });

  // 計上年月
  const CKeijyoYM = forwardRef(({
    inputRef,
  }, ref) => {
    const [itValue,setItValue] = useState('');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
      }
    });

    return (
      <TextField  //計上年月
        id={'KIJYYM'}
        inputRef={inputRef}                   //項目名定義？
        color='success'
        size='small'                        //textboxの全体サイズ
        label='計上年月'                         //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 6), {marginLeft:'20px', padding: styles.cssIputOutlinPadding})}
        value={itValue}               //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={true}                    //使用可／不可
        inputProps={{ autoComplete: 'off',maxLength: 6,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
      />
    )
  });

  // 店CD
  const CMiseCode = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });
    return (
      <NumericFormat  //店CD
        id={'MISE'}
        inputRef={inputRef}                   //項目名定義？
        color='success'
        thousandSeparator={false}           //1000単位の区切り
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label='店CD'                         //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
        decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 3), {background:itBGColor})}
        value={itValue}               //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={true}                    //使用可／不可
        inputProps={{ autoComplete: 'off',maxLength: 3,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
        onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
        onFocus={(e) => onMyFocus(e)}
        onChange={(e) => setItValue(e.target.value)}
      />
    )
  });

  // 分類CD
  const CBunruiCode = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');
    const [checkflg,setCheckflg] = useState(0);
    const [focusflg,setFocusflg] = useState(false);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
        getCheckflg() {
          return checkflg;
        },
        setCheckflg(value) {
          setCheckflg(value);
        },
        getFocusflg() {
          return focusflg;
        },
        setFocusflg(value) {
          setFocusflg(value);
        },
      }
    });
    return (
      <NumericFormat  //分類CD
        id={'BMNCDE'}
        inputRef={inputRef}                   //項目名定義？
        color='success'
        thousandSeparator={false}           //1000単位の区切り
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label='分類CD'                       //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
        decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 4), {marginLeft:'20px',background:itBGColor})}
        value={itValue}               //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={false}                    //使用可／不可
        inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
        onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
        onBlur={(e) => setFocusflg(true)}
        onFocus={(e) => onMyFocus(e, 10)}
        onChange={(e) => {
          setCheckflg(1);
          setItValue(e.target.value);
        }
        }
      />
    )
  });


  // 売変実施日
  const CDenpyoYMD = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });
    return (
      <PatternFormat  //売変実施日
        id={'DENYMD'}
        inputRef={inputRef}                   //項目名定義？
        format="##/##/##"
        mask={['Y','Y','M','M','D','D']}
        color='success'
        customInput={TextField}             //textfieldにreact-number-formatの機能を追加
        size='small'                        //textboxの全体サイズ
        label='売変実施日'                        //textboxの左上に表示するラベル
        variant='outlined'                  //枠の表示方法
        type='tel'                          //[PDA、Tablet対応]入力モード：tel
        inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
        fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
        decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
        InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
        sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 9), {background:itBGColor})}
        value={itValue}               //初期値
        allowLeadingZeros                   //前ゼロ表示 on
        disabled={false}                    //使用可／不可
        placeholder="YY/MM/DD"
        inputProps={{ autoComplete: 'off',maxLength: 9,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
        onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
        onFocus={(e) => onMyFocus(e)}
        onChange={(e) => setItValue(e.target.value)}
      />
    )
  });
  //#endregion

  //#region 明細部
  // 商品コード
  const CShohinCode = forwardRef(({
    id,
    onKeyDown,
    inputRef,
    inputNxRef,
    inUpRef,
    inRgRef,
    inDnRef,
    inLfRef,
    dismeisai,
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');
    const [checkflg,setCheckflg] = useState(0);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
        getCheckflg() {
          return checkflg;
        },
        setCheckflg(value) {
          setCheckflg(value);
        },
      }
    });

    return (
      <TableCell align="center" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}>
        <NumericFormat  //商品コード
          id={id}
          inputRef={inputRef}
          color='success'
          thousandSeparator={false}
          customInput={TextField}
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width: '150px',background:itBGColor})}
          value={itValue}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          inputProps={{autoComplete: 'off',maxLength: 13,style: {textAlign: 'center', padding: styles.cssRecordFontSizePadding} }} //最大文字数,右寄せ
          disabled={dismeisai}
          onKeyDown={(e) => onKeyDown(e.key,inputRef,inputNxRef,inUpRef,inRgRef,inDnRef,inLfRef)}
          onFocus={(e) => onMyFocus(e)}
          allowLeadingZeros
          onChange={(e) => {
            setCheckflg(1);
            setItValue(e.target.value);
          }}
        />
      </TableCell>
    )
  });

  // 原因
  const CGennin = forwardRef(({
    id,
    onKeyDown,
    inputRef,
    inputNxRef,
    inUpRef,
    inRgRef,
    inDnRef,
    inLfRef,
    dismeisai,
  }, ref) => {

    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <TableCell align="center" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}>
        <NumericFormat  //原因
          id={id}
          inputRef={inputRef}
          color='success'
          thousandSeparator={true}
          customInput={TextField}
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width: '20px',background:itBGColor})}
          value={itValue}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center', padding: styles.cssRecordFontSizePadding} }} //最大文字数,右寄せ
          disabled={dismeisai}
          onKeyDown={(e) => onKeyDown(e.key,inputRef,inputNxRef,inUpRef,inRgRef,inDnRef,inLfRef)}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => setItValue(e.target.value)}
        />
      </TableCell>
    )
  });

  // 数量
  const CSuuryo = forwardRef(({
    id,
    onKeyDown,
    inputRef,
    inputNxRef,
    inUpRef,
    inRgRef,
    inDnRef,
    inLfRef,
    dismeisai,
  }, ref) => {

    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
        <NumericFormat  //数量
          id={id}
          inputRef={inputRef}
          color='success'
          thousandSeparator={','}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={true}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={1}                    //[PDA、Tablet対応]少数部の桁[0]
          customInput={TextField}
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width: '80px',background:itBGColor})}
          value={itValue}
          allowLeadingZeros
          disabled={dismeisai}
          inputProps={{autoComplete: 'off',maxLength: 9,style: {textAlign: 'right', padding: styles.cssRecordFontSizePaddingRightModle} }}
          isAllowed={(values) => {
            const numValue = Number(values.value);
            if (isNaN(numValue)) return true;
            return numValue >= -99999.9 && numValue <= 99999.9;
          }}
          onKeyDown={(e) => onKeyDown(e.key,inputRef,inputNxRef,inUpRef,inRgRef,inDnRef,inLfRef)}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => setItValue(e.target.value)}
        />
      </TableCell>
    )
  });

  // 旧売価
  const COldBaika = forwardRef(({
    id,
    onKeyDown,
    inputRef,
    inputNxRef,
    inUpRef,
    inRgRef,
    inDnRef,
    inLfRef,
    dismeisai,
  }, ref) => {

    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
        <NumericFormat  //旧売価
          id={id}
          inputRef={inputRef}
          color='success'
          thousandSeparator={','}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          customInput={TextField}
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width: '80px',background:itBGColor})}
          value={itValue}
          allowLeadingZeros
          disabled={dismeisai}
          inputProps={{autoComplete: 'off',maxLength: 7,style: {textAlign: 'right', padding: styles.cssRecordFontSizePaddingRightModle} }}
          onKeyDown={(e) => onKeyDown(e.key,inputRef,inputNxRef,inUpRef,inRgRef,inDnRef,inLfRef)}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => setItValue(e.target.value)}
        />
      </TableCell>
    )
  });

  // 新売価
  const CNewBaika = forwardRef(({
    id,
    onKeyDown,
    inputRef,
    inputNxRef,
    inUpRef,
    inRgRef,
    inDnRef,
    inLfRef,
    dismeisai,
  }, ref) => {

    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
        <NumericFormat  //新売価
          id={id}
          inputRef={inputRef}
          color='success'
          thousandSeparator={','}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          customInput={TextField}
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width: '80px',background:itBGColor})}
          value={itValue}
          allowLeadingZeros
          disabled={dismeisai}
          inputProps={{autoComplete: 'off',maxLength: 7,style: {textAlign: 'right', padding: styles.cssRecordFontSizePaddingRightModle} }}
          onKeyDown={(e) => onKeyDown(e.key,inputRef,inputNxRef,inUpRef,inRgRef,inDnRef,inLfRef)}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => setItValue(e.target.value)}
        />
      </TableCell>
    )
  });

  // 差　額
  const CSagaku = forwardRef(({
    id,
    inputRef,
  }, ref) => {

    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
        <NumericFormat  //差　額
          id={id}
          inputRef={inputRef}
          color='success'
          thousandSeparator={','}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          customInput={TextField}
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width: '80px',background:itBGColor})}
          value={itValue}
          allowLeadingZeros
          disabled={true}
          inputProps={{autoComplete: 'off',maxLength: 7,style: {textAlign: 'right', padding: styles.cssRecordFontSizePaddingRightModle} }}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => setItValue(e.target.value)}
        />
      </TableCell>
    )
  });

  // 売変額
  const CBaihenGaku = forwardRef(({
    id,
    inputRef,
  }, ref) => {

    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <TableCell align="right" sx={{border:1,padding:0,paddingRight:'10px'}}>
        <NumericFormat  //売変額
          id={id}
          inputRef={inputRef}
          color='success'
          thousandSeparator={','}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          customInput={TextField}
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width: '80px',background:itBGColor})}
          value={itValue}
          allowLeadingZeros
          disabled={true}
          inputProps={{autoComplete: 'off',maxLength: 8,style: {textAlign: 'right', padding: styles.cssRecordFontSizePaddingRightModle} }}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => setItValue(e.target.value)}
        />
      </TableCell>
    )
  });

  // 符号
  const CFugou = forwardRef(({
    id,
    inputRef,
  }, ref) => {

    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <TableCell align="center" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}>
        <TextField  //符号
          id={id}
          inputRef={inputRef}
          color='success'
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width: '20px',background:itBGColor})}
          value={itValue}
          inputProps={{ autoComplete: 'off',maxLength: 1,style: {textAlign: 'center', padding: styles.cssRecordFontSizePadding} }} //最大文字数,右寄せ
          disabled={true}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => setItValue(e.target.value)}
        />
      </TableCell>
    )
  });
  //#endregion

  //#region フッダ部
  // 数量合計
  const CSuuryoGoukei = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
    dismeisai
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <TableCell align="right" sx={{border:1,padding:0,paddingRight:'0'}} width="104.19">
        <NumericFormat  //数量合計
          id={'SUUG'}
          inputRef={inputRef}
          color='success'
          thousandSeparator={','}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={true}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={1}                    //[PDA、Tablet対応]少数部の桁[0]
          customInput={TextField}
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width: '95px',background:itBGColor})}
          value={itValue}
          allowLeadingZeros
          disabled={dismeisai}
          inputProps={{autoComplete: 'off',maxLength: 10,style: {textAlign: 'right', padding: styles.cssRecordFontSizePaddingRightModle} }}
          isAllowed={(values) => {
            const numValue = Number(values.value);
            if (isNaN(numValue)) return true;
            return numValue >= -999999.9 && numValue <= 999999.9;
          }}
          onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => setItValue(e.target.value)}
        />
      </TableCell>
    )
  });

  // 旧売価合計
  const COldBaikaGoukei = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
    dismeisai
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <TableCell align="right" sx={{border:1,padding:0,paddingRight:'0'}} width="104.19">
        <NumericFormat  //旧売価合計
          id={'OLDBAKG'}
          inputRef={inputRef}
          color='success'
          thousandSeparator={','}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          customInput={TextField}
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width: '110px',background:itBGColor})}
          value={itValue}
          allowLeadingZeros
          disabled={dismeisai}
          inputProps={{autoComplete: 'off',maxLength: 9,style: {textAlign: 'right', padding: styles.cssRecordFontSizePaddingRightModle} }}
          onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => setItValue(e.target.value)}
        />
      </TableCell>
    )
  });

  // 新売価合計
  const CNewBaikaGoukei = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
    dismeisai
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <TableCell align="right" sx={{border:1,padding:0,paddingRight:'0'}} width="104.19">
        <NumericFormat  //新売価合計
          id={'NEWBAKG'}
          inputRef={inputRef}
          color='success'
          thousandSeparator={','}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          customInput={TextField}
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width: '110px',background:itBGColor})}
          value={itValue}
          allowLeadingZeros
          disabled={dismeisai}
          inputProps={{autoComplete: 'off',maxLength: 9,style: {textAlign: 'right', padding: styles.cssRecordFontSizePaddingRightModle} }}
          onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => setItValue(e.target.value)}
        />
      </TableCell>
    )
  });

  // 売変額合計
  const CBaihenGakuGoukei = forwardRef(({
    onKeyDown,
    inputRef,
    inputRtRef,
    inputNxRef,
    dismeisai
  }, ref) => {
    const [itValue,setItValue] = useState('');
    const [itBGColor,setItBGColor] = useState('#FFFFFF');

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return itValue;
        },
        setValue(value) {
          setItValue(value);
        },
        getBGColor() {
          return itBGColor;
        },
        setBGColor(value){
          setItBGColor(value);
        },
      }
    });

    return (
      <TableCell align="right" sx={{border:1,padding:0,paddingRight:'0'}} width="104.19">
        <NumericFormat  //売変額合計
          id={'BIHNGKG'}
          inputRef={inputRef}
          color='success'
          thousandSeparator={','}
          type='tel'                          //[PDA、Tablet対応]入力モード：tel
          inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
          fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
          decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
          customInput={TextField}
          size='small'
          label=''
          variant='standard'
          InputLabelProps={{ shrink: true }}
          sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline2, 0),styles.cssRecordFontSize, {width: '110px',background:itBGColor})}
          value={itValue}
          allowLeadingZeros
          disabled={true}
          inputProps={{autoComplete: 'off',maxLength: 10,style: {textAlign: 'right', padding: styles.cssRecordFontSizePaddingRightModle} }}
          onKeyDown={(e) => onKeyDown(e.key,inputRef,inputRtRef,inputNxRef)}
          onFocus={(e) => onMyFocus(e)}
          onChange={(e) => setItValue(e.target.value)}
        />
      </TableCell>
    )
  });
  //#endregion


  return {CKubun,CDenpyoNo,CKeijyoYM,CMiseCode,CBunruiCode,CDenpyoYMD,
    CShohinCode,CGennin,CSuuryo,COldBaika,CNewBaika,CSagaku,CBaihenGaku,CFugou,
    CSuuryoGoukei,COldBaikaGoukei,CNewBaikaGoukei,CBaihenGakuGoukei
  }

};
export default TCSA0150_parts;