import React, { useState,useContext,useEffect } from 'react'
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import './common.css';

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F5キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F9キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSA0005 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [age, setAge] = useState('');
  const [alert, setAlert] = useState('');
  const [btn13, setBtn13] = useState(true); //返品伝票作成
  const [btn14, setBtn14] = useState(true); //移動伝票作成
  const [btn19, setBtn19] = useState(true); //店舗切替

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応
  
  useEffect (()=>{
    //document.documentElement.requestFullscreen();
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    setAge('001');
    console.log('TCSA0005 初期処理:%o',state)
    // stateからデータ取得
    if (state.BUTTON !==undefined){
      let l_button = state.BUTTON.split(',');
      for (var i = 0; i < l_button.length; i = i + 2) {
        if (l_button[i] ==='13' && l_button[i+1] ==='1'){setBtn13(false);}   //返品伝票作成
        if (l_button[i] ==='14' && l_button[i+1] ==='1'){setBtn14(false);}   //移動伝票作成
      }
    }
    
    if (state.MISE == '200' || state.K_KSACDES != '')
    {
      setBtn19(false);
    }else if(state.SMISEFLG === 1){
      setBtn19(false);
    }    
  },[]);

  const onKeyDown =  async (key,inputRef,inputNxRef) => {  //  入力キー、現在の項目情報、次項目情報
    //console.log(key);
    switch (key) {
      case "Enter":
        inputNxRef.current.focus();   //次の項目に遷移
        break;
      case "TCPD0140":  //店舗入力 or 店舗選択
        navigate('/TCPD0140');
        break;
      case "TCPD0130":  //店舗入力 or 店舗選択
        navigate('/TCPD0130');
        break;
      case "TCSA0001":  //店舗入力 or 店舗選択
        dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
        KSACDES : state.KSACDE,MISES: state.MISES,K_KSACDES : state.K_KSACDES,K_MISES: state.K_MISES,
        MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
        SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,MVEMGAMEN:'TCSA0005',K_FLG: state.K_FLG,ETC:'',SDAY: state.SDAY,BUTTON:state.BUTTON });
        if (state.SMISEFLG == 1)
        {
          navigate('/TCSA0002');
        }else{
          navigate('/TCSA0001');
        }
        break;
      case "TCSA0000":  //ログイン画面
        navigate('/');
        break;
      case "Home":
        break;
      case "ArrowUp":
        break;
      case "ArrowDown":
        break;
      default:
        break;
    }
  };  

  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "55vh",
          width: "460px",
          m: "20px auto"
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="center"
        >
          <div style={{display:"flex"}}>
          <Typography variant={"h4"} sx={{ m: "10px" }}>
            業務メニュー(PDA)
          </Typography>
          {/* <Typography variant={"h6"} sx={{ m: "15px" }}>
            {state.JGNMEIKNJ}
          </Typography> */}
          </div>
        </Grid>
        <br/>
        <Box display={'flex'} justifyContent={'center'}>
          <button type="button" className="btnM btnOrange" style={{width:285}} disabled={btn13} onClick={(e) => onKeyDown('TCPD0140','','') }>返品伝票作成<span></span></button>
          {/* <Button variant="contained" color="warning" 
            sx={{width:285}}
            onClick={(e) => onKeyDown('TCPD0140','','') }
            >
            返品伝票作成
          </Button> */}
        </Box>
        <br/><br/>
        <Box display={'flex'} justifyContent={'center'}>
          <button type="button" className="btnM btnOrange" style={{width:285}} disabled={btn14} onClick={(e) => onKeyDown('TCPD0130','','') }>移動伝票作成<span></span></button>
          {/* <Button variant="contained" color="warning" sx={{width:285}}
            onClick={(e) => onKeyDown('TCPD0130','','') }
            >
            移動伝票作成
          </Button> */}
        </Box>
        <br/><br/>
        <Box display={'flex'} justifyContent={'center'}>
          <button type="button" className="btnM btnGreen" style={{width:285}} disabled={btn19}  onClick={(e) => onKeyDown('TCSA0001','','') }>店舗切替<span></span></button>
          {/* <Button variant="contained" color="success" sx={{width:285}}
            disabled={btn19}
            onClick={(e) => onKeyDown('TCSA0001','','') }
            >
            店舗切替
          </Button> */}
        </Box>
        <br/><br/>
        <Box display={'flex'} justifyContent={'center'}>
          <button type="button" className="btnM btnGreen" style={{width:285}} onClick={(e) => onKeyDown('TCSA0000','','') }>戻　　る<span></span></button>
          {/* <Button variant="contained" color="success" sx={{width:285}}
            onClick={(e) => onKeyDown('TCSA0000','','') }
            >
            戻　　る
          </Button> */}
        </Box>
        <p><font color="red">{ alert }</font></p>
      </Paper>
    </Grid>
  );
};

export default TCSA0005;