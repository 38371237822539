import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, TextField, Grid, Paper, Typography, Stack } from "@mui/material";
import Table from '@mui/material/Table';              //テーブル用
import TableBody from '@mui/material/TableBody';      //テーブル用
import TableCell from '@mui/material/TableCell';      //テーブル用
import TableContainer from '@mui/material/TableContainer';  //テーブル用
import TableHead from '@mui/material/TableHead';      //テーブル用
import TableRow from '@mui/material/TableRow';        //テーブル用
import { NumericFormat } from 'react-number-format';    //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import { onNextFocus } from './hooks/useOnMyFocus';     // select()処理
import { Decimal } from 'decimal.js';
import TCSA0130_parts from './TCSA0130_parts';

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);

      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F5キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F9キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

// コンポーネント呼び出し
const {
  CKubun,CZeiritu,CZeiKubun,CSyaMise,CBunruiCode,CDenpyoKubun,CDenpyoNo,CSireCode,CDenpyoYMD,CKeijyoYM,
  CShohinCode,CShohinSuu,CShohinGentanka,CShohinGenkakingaku,CShohinBaitanka,CShohinBaikakingaku,
  CDenpyoFH,CDenpyoIJK,CDenpyoG,CGoukei,CBin
} = TCSA0130_parts();

function TCSA0130 () {
  // #region 変数宣言
  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('');
  const [dismeisai, setMeisai] = useState(false);    //disabled設定（明細）
  const [dismeisais, setMeisaiS] = useState(false);  //disabled設定（明細）商品コード（PH用）
  const [disdeng, setDENG] = useState(false);        //disabled設定（明細）
  const [dissmise, setSMISE] = useState(true);       //disabled設定（社店CD）
  const [sdate, setsDate] = useState([]);
  const keyDisabled = useRef(false);
  const [regionList, setRegionList] = useState([]);  //店情報[高速化用]
  const [regionList2, setRegionList2] = useState([]);  //部門情報[高速化用]
  const [regionList3, setRegionList3] = useState([]);  //税情報[高速化用]
  const [regionList4, setRegionList4] = useState([]);  //取引先[仕入先]情報[高速化用]
  const [regionList5, setRegionList5] = useState([]);  //税区分＆税率情報[高速化用]

  let Err_flg = 0;                                 //カーソル移動時チェックon/off制御：0=on,1=off

  const [itRcnt,setItrcnt] = useState(0);
  const [itJGYUCDE,setItjgyucde] = useState('');      //事業部コード
  const [itGNKKNRFLG,setItgnkknrflg] = useState('');  //売原管理フラグ/原価管理フラグ
  const [itEOSJANKBN1,setIteosjankbn1] = useState('');  //ＥＯＳ／ＪＡＮ区分1
  const [itEOSJANKBN2,setIteosjankbn2] = useState('');  //ＥＯＳ／ＪＡＮ区分2
  const [itEOSJANKBN3,setIteosjankbn3] = useState('');  //ＥＯＳ／ＪＡＮ区分3
  const [itEOSJANKBN4,setIteosjankbn4] = useState('');  //ＥＯＳ／ＪＡＮ区分4
  const [itEOSJANKBN5,setIteosjankbn5] = useState('');  //ＥＯＳ／ＪＡＮ区分5
  const [itEOSJANKBN6,setIteosjankbn6] = useState('');  //ＥＯＳ／ＪＡＮ区分6

  const [cent_ksacde,setCent_ksacde] = useState('');  //各店対応用
  const [cent_mise,setCent_mise] = useState('');      //各店対応用

  const [selectdata,setSelectData] = useState('');

  const [itKBNNM,setItkbnnm] = useState('');
  const [itDENNO01,setItdenno01] = useState('');   //伝票No.用1回目メモリ
  const [itSIRNM,setItsirnm] = useState('');
  const [itSMISE01,setItsmise01] = useState('');    //社店CD用1回目メモリ
  const [itSMISENM,setItsmisenm] = useState('');

  const [itBMNCDE01,setItbmncde01] = useState('');  //分類CD用１回目メモリ

  // コンポーネント
  const kubunRef = useRef(null);
  const zeirituRef = useRef(null);
  const zeiKubunRef = useRef(null);
  const syaMiseRef = useRef(null);
  const bunruiCodeRef = useRef(null);
  const denpyoKubunRef = useRef(null);
  const denpyoNoRef = useRef(null);
  const sireCodeRef = useRef(null);
  const denpyoYMDRef = useRef(null);
  const keijyoYMRef = useRef(null);
  const binRef = useRef(null);                  // 便
  const shohinCode1Ref = useRef(null);
  const shohinCode2Ref = useRef(null);
  const shohinCode3Ref = useRef(null);
  const shohinCode4Ref = useRef(null);
  const shohinCode5Ref = useRef(null);
  const shohinCode6Ref = useRef(null);
  const shohinSuu1Ref = useRef(null);
  const shohinSuu2Ref = useRef(null);
  const shohinSuu3Ref = useRef(null);
  const shohinSuu4Ref = useRef(null);
  const shohinSuu5Ref = useRef(null);
  const shohinSuu6Ref = useRef(null);
  const shohinGnt1Ref = useRef(null);
  const shohinGnt2Ref = useRef(null);
  const shohinGnt3Ref = useRef(null);
  const shohinGnt4Ref = useRef(null);
  const shohinGnt5Ref = useRef(null);
  const shohinGnt6Ref = useRef(null);
  const shohinGnkkin1Ref = useRef(null);
  const shohinGnkkin2Ref = useRef(null);
  const shohinGnkkin3Ref = useRef(null);
  const shohinGnkkin4Ref = useRef(null);
  const shohinGnkkin5Ref = useRef(null);
  const shohinGnkkin6Ref = useRef(null);
  const shohinBtn1Ref = useRef(null);
  const shohinBtn2Ref = useRef(null);
  const shohinBtn3Ref = useRef(null);
  const shohinBtn4Ref = useRef(null);
  const shohinBtn5Ref = useRef(null);
  const shohinBtn6Ref = useRef(null);
  const shohinBakkin1Ref = useRef(null);
  const shohinBakkin2Ref = useRef(null);
  const shohinBakkin3Ref = useRef(null);
  const shohinBakkin4Ref = useRef(null);
  const shohinBakkin5Ref = useRef(null);
  const shohinBakkin6Ref = useRef(null);
  const denFRef = useRef(null);
  const denGRef = useRef(null);
  const denHRef = useRef(null);
  const denIRef = useRef(null);
  const denJRef = useRef(null);
  const denKRef = useRef(null);
  const TSEGNKFOTRef = useRef(null);
  const GNKFOTRef = useRef(null);
  const TSEBAKFOTRef = useRef(null);
  const BAKFOTRef = useRef(null);

  const inKBN = useRef(null);
  const inDENNO = useRef(null);
  const inZEIRTU = useRef(null);
  const inSIRCDE = useRef(null);
  const inKIJYYM = useRef(null);
  const inZEIKBN = useRef(null);
  const inSMISE = useRef(null);
  const inBMNCDE = useRef(null);
  const inDENKBN = useRef(null);
  const inDENYMD = useRef(null);
  const inBIN = useRef(null);
  const inRET = useRef(null);
  const inDENF = useRef(null);
  const inDENH = useRef(null);
  const inDENG = useRef(null);
  const inDENI = useRef(null);
  const inDENJ = useRef(null);
  const inDENK = useRef(null);
  const inTSEGNKFOT = useRef(null);
  const inGNKFOT = useRef(null);
  const inTSEBAKFOT = useRef(null);
  const inBAKFOT = useRef(null);
  const inSHNCDE1 = useRef(null);
  const inSHNCDE2 = useRef(null);
  const inSHNCDE3 = useRef(null);
  const inSHNCDE4 = useRef(null);
  const inSHNCDE5 = useRef(null);
  const inSHNCDE6 = useRef(null);
  const inSUU1 = useRef(null);
  const inSUU2 = useRef(null);
  const inSUU3 = useRef(null);
  const inSUU4 = useRef(null);
  const inSUU5 = useRef(null);
  const inSUU6 = useRef(null);
  const inGNT1 = useRef(null);
  const inGNT2 = useRef(null);
  const inGNT3 = useRef(null);
  const inGNT4 = useRef(null);
  const inGNT5 = useRef(null);
  const inGNT6 = useRef(null);
  const inGNKKIN1 = useRef(null);
  const inGNKKIN2 = useRef(null);
  const inGNKKIN3 = useRef(null);
  const inGNKKIN4 = useRef(null);
  const inGNKKIN5 = useRef(null);
  const inGNKKIN6 = useRef(null);
  const inBTN1 = useRef(null);
  const inBTN2 = useRef(null);
  const inBTN3 = useRef(null);
  const inBTN4 = useRef(null);
  const inBTN5 = useRef(null);
  const inBTN6 = useRef(null);
  const inBAKKIN1 = useRef(null);
  const inBAKKIN2 = useRef(null);
  const inBAKKIN3 = useRef(null);
  const inBAKKIN4 = useRef(null);
  const inBAKKIN5 = useRef(null);
  const inBAKKIN6 = useRef(null);

  // エラーチェック用 0:エラーなし, 1:未チェック, 2:エラー, 9:確認中
  const [chkflg,setChkflg] = useState(0);               // チェックフラグ
  const [chkflg2,setChkflg2] = useState(0);             // チェックフラグ2

  const [inputConfirmflg,setInputConfirmflg] = useState(0);   // チェック完了フラグ

  // 空白行確認用フラグ
  const [blurTableflg,setBlurTableflg] = useState(false);       // blurTableflg

  // マイナス制御
  const [allow,setallow] = useState(false);

  // 伝票No.移動フラグ
  const [ttl ,setTtl] = useState('');

  let isInInputConfirm = false;

  // 2023/09/29 確認フォーカス処理の重複を制御
  // 確認制御用
  const [enable, setEnable] = useState(false);
  // #endregion 変数

  //#region 初期処理
  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 113) { // F2キーが押された場合の処理
      dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
      KSACDES : state.KSACDES,MISES: state.MISES,K_KSACDES : state.K_KSACDES,K_MISES: state.K_MISES,
      MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
      SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,MVEMGAMEN:'TCSA0130',K_FLG: state.K_FLG,ETC:state.ETC,SDAY:state.SDAY,BUTTON:state.BUTTON });
      navigate('/TCSA0210');
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      navigate('/TCSA0003');
    }
    if (event.keyCode === 120) { // F9キーが押された場合の処理
      Err_flg = 1;
      Gmclr();
      setfclr('') // 伝票区分が削除されるのでG欄を非活性に設定
      if(kubunRef.current?.getValue()=='1')
      {
        if (inZEIRTU.current !==null && typeof inZEIRTU.current !== 'undefined')
        {
          inZEIRTU.current.focus();
        }
      }else if(kubunRef.current?.getValue()=='2' || kubunRef.current?.getValue()=='3')
      {
        if (inDENNO.current !==null && typeof inDENNO.current !== 'undefined')
        {
          inDENNO.current.focus();
        }

      }else{
        if (inKBN.current !==null && typeof inKBN.current !== 'undefined')
        {
          inKBN.current.focus();
        }
      }
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  useEffect (()=>{
    if (state.KENGEN === '')
    {
      // console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー0130:%o',state)
    }
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);
    zeiKubunRef.current?.setValue('0');
    if (state.ETC == 'K')
    {
      setSMISE(false); // 各店時のみ社店CDを活性にする
      setCent_ksacde(state.K_KSACDES);
      setCent_mise(state.K_MISES);
      if (state.K_FLG !== 1 ){
        syaMiseRef.current?.setValue(state.K_MISES);
      }
      if (state.KSACDES ==='005')
      {
        setTtl('(PH 各店)');
        setMeisaiS(true);
      }else
      {
        setTtl('(各店)');
        setMeisaiS(false);
        binRef.current?.setValue('1');
      }
    }else{
      setCent_ksacde(state.KSACDES);
      setCent_mise(state.MISES);
      if (state.K_FLG !== 1 ){
        syaMiseRef.current?.setValue(state.MISES);

        setItsmise01(state.MISES);
      }
      if (state.KSACDES ==='005')
      {
        setTtl('(PH)');
        setMeisaiS(true);
      }else
      {
        setTtl('');
        setMeisaiS(false);
      }
      setItsmisenm(state.MISEMEI);
    }
    setTimeout(() => {document.documentElement.webkitRequestFullScreen();},1)
    getRegionList();  //2023/11/21 MAP化
    setfclr('') // 初回遷移時は伝票区分が設定されていないのでG欄を非活性とする
  },[]);

  const getRegionList = async() => {

    //店情報
    const get_region = await fetch('/TCSA0130/MR_SHP_MSTM_sel3',{method: 'POST', headers:{'Content-Type': 'application/json'},
    body: JSON.stringify({ })});
    const data_region = await get_region.json();
    setRegionList(data_region.ret);
    // const mapResult = data_region.ret.filter(item=>{return item.id === '001570';});
    // console.log('結果:%o',mapResult);

    //部門CD
    const get_region2 = await fetch('/TCSA0130/MR_SHP_MSTM_sel4',{method: 'POST', headers:{'Content-Type': 'application/json'},
    body: JSON.stringify({ })});
    const data_region2 = await get_region2.json();
    setRegionList2(data_region2.ret);

    //税区分
    const get_region3 = await fetch('/TCSA0130/SL_SET_MST4_sel',{method: 'POST', headers:{'Content-Type': 'application/json'},
    body: JSON.stringify({ })});
    const data_region3 = await get_region3.json();
    setRegionList3(data_region3.ret);

    //取引先[仕入先]情報
    const get_region4 = await fetch('/TCSA0130/MR_SUP_MST_sel2',{method: 'POST', headers:{'Content-Type': 'application/json'},
    body: JSON.stringify({ })});
    const data_region4 = await get_region4.json();
    setRegionList4(data_region4.ret);

    //取引先[仕入先]情報
    const get_region5 = await fetch('/TCSA0130/SL_SET_MST5_sel',{method: 'POST', headers:{'Content-Type': 'application/json'},
    body: JSON.stringify({ })});
    const data_region5 = await get_region5.json();
    setRegionList5(data_region5.ret);

  }


  //#endregion

  //#region 呼出処理
  /**
   * 伝票No.チェックデジット
   * @param p_DENNO 伝票No.
   * @returns 0:正常, -1:異常
   */
  const checkDJDENNO = (p_DENNO) =>{
    const chk_denno = ('000000'+p_DENNO).slice(-7);
    let w1 = chk_denno.slice(0,6);
    let wD = chk_denno.charAt(6,1);
    let wGk = Number(w1)%7;
    // console.log('計算:'+chk_denno+'|'+w1+'|'+wD+'|'+wGk);
    let iRet = 0;
    if (wGk != wD)
    {
      iRet = -1;
    }
    return iRet;
  }

  /**
   * 仕入先コードチェックデジット
   * @param p_SIRCDE 仕入先コード
   * @returns  0:正常, -1:異常
   */
  const checkDJSIRCD = (p_SIRCDE) =>{
    const chk_sircde = p_SIRCDE;
    let w1 = chk_sircde.charAt(0) * 8;
    let w2 = chk_sircde.charAt(1) * 7;
    let w3 = chk_sircde.charAt(2) * 4;
    let w4 = chk_sircde.charAt(3) * 3;
    let w5 = chk_sircde.charAt(4) * 2;
    let wD = chk_sircde.charAt(5).toString();
    let wGk = (w1+w2+w3+w4+w5)%10;
    let iRet = 0;
    wGk = 10-wGk;
    let wAmri = wGk.toString().slice(-1);
    // console.log('取引先DJ:'+wAmri+'|'+wD);
    if (wAmri != wD)
    {
      iRet = -1;
    }
    return iRet;
  }

  /**
   * 計上年月算出
   * @param p_YMD 年月日（YYYY/MM/DD ⇒ 2023/09/07 等）
   * @returns 計上年月
   */
  const KeijoYM = (p_YMD) =>{
    const c_YMD = String(p_YMD).replaceAll('/','');
    const n_now = new Date(state.SDAY);
    const n_day = String(n_now.getDate()).padStart(2,'0');  //システム日付の日を取得
    const n_dt = String(n_now.getFullYear())+String(n_now.getMonth()+1).padStart(2,'0')+'21';    //当月の20日以前チェック用
    const np_now = new Date(n_now.getFullYear(),n_now.getMonth(),'01'); //月＋１計算用（一旦日を01にしないとおかしいくなる）

    // console.log('n_now:%o',n_now);
    // console.log('n_day:%o',n_day);
    // console.log('n_dt:%o',n_dt);
    // console.log('np_now:%o',np_now);

    np_now.setMonth(np_now.getMonth()+1); //マイナス１月対策

    // console.log('対策後n_now:%o',n_now);
    // console.log('対策後np_now:%o',np_now);
    // console.log('n_now.getMonth:%o',n_now.getMonth())
    // console.log('np_now:%o',np_now.getMonth())

    if (n_day <= '20')
    {
      //1パターン
      // console.log('パターン１');
      return String(n_now.getFullYear()).slice(2,4)+'年'+String(n_now.getMonth()+1).padStart(2,'0')+'月';
    }else if(n_day === '21' || n_day === '22' )
    {
      if (c_YMD < n_dt){
        //2パターン
        // console.log('パターン２'+c_YMD+'<'+n_dt);
        return String(n_now.getFullYear()).slice(2,4)+'年'+String(n_now.getMonth()+1).padStart(2,'0')+'月';
      }else{
        //5パターン
        let ret = String(np_now.getFullYear()).slice(2,4)+'年'+String(np_now.getMonth()+1).padStart(2,'0')+'月';
        // console.log('パターン５'+ret);
        return ret;
      }
    }else{
      //3,6パターン
      let ret = String(np_now.getFullYear()).slice(2,4)+'年'+String(np_now.getMonth()+1).padStart(2,'0')+'月';
      // console.log('パターン３，６'+ret);
      return ret;
    }
  }

  /**
   * 日付存在チェック
   * @param strYYYYMMDD 年月日（YYYY/MM/DD ⇒ 2023/09/07 等）
   * @returns true:存在, false:エラー
   */
  const ckDate = (strYYYYMMDD) =>{
    if(!strYYYYMMDD.match(/^\d{4}\/\d{2}\/\d{2}$/)){
        return false;
    }
    var y = strYYYYMMDD.split("/")[0];
    var m = strYYYYMMDD.split("/")[1] - 1;
    var d = strYYYYMMDD.split("/")[2];
    var date = new Date(y,m,d);
    if(date.getFullYear() != y || date.getMonth() != m || date.getDate() != d){
        return false;
    }
    return true;
  }

  /**
   * 商品分類マスタ(事業部コード取得)
   * @param p_BMNCDE 部門（分類）コード
   * @param p_denymd 年月日
   * @returns 商品分類マスタ.事業部コード
   */
  const getJIGYOBUCDE = async(p_BMNCDE,p_denymd) =>{
    // if (itJGYUCDE=='')
    // {
      const responseF = await fetch('/TCSA0130/MR_PRD_CLS_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({KSACDE: cent_ksacde,BMNCDE:p_BMNCDE,AUCDE:'XXX',YMD:'20'+p_denymd.replaceAll('/','')})});
      const dataF = await responseF.json();
      // console.log('事業CDE00:%o',dataF);
      if (dataF.RCNT != 0)
      {
        // console.log('事業CDE01:'+dataF.res[0].JGBCDE);
        setItjgyucde(dataF.res[0].JGBCDE);
        setItgnkknrflg(dataF.res[0].GNKKNRFLG);
        return dataF.res[0].JGBCDE;
      }
    // }
  }

  /**
   * 商品分類マスタ（データ存在チェック）
   * @param p_BMNCDE 部門（分類）コード
   * @param p_AUCDE AUコード（商品コード）
   * @param p_denymd 年月日
   * @returns 0：存在なし, 1：存在あり
   */
  const chkPRDCLS = async(p_BMNCDE,p_AUCDE,p_denymd) =>{
    const responseG = await fetch('/TCSA0130/MR_PRD_CLS_MST_AU_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({KSACDE: cent_ksacde,BMNCDE:p_BMNCDE,ASTCDE:p_AUCDE,YMD:'20'+p_denymd.replaceAll('/','')})});
    const dataG = await responseG.json();
    // console.log('分類:%o',dataG);
    // console.log('件数:'+dataG.RCNT);
    if (dataG.RCNT != 0)
    {
      return 1;
    }else
    {
      return 0;
    }
  }

  /**
   * 商品マスタ（基本）
   * @param p_BMNCDE 部門（分類）コード
   * @param p_shncde 商品コード
   * @param p_EJKBN 0：[EOS], 1：[JAN]
   * @returns 0：存在なし, 1：存在あり
   */
  const chkSHNCDEMST_K = async(p_BMNCDE,p_shncde,p_EJKBN) =>{
    
    const responseH = await fetch('/TCSA0130/MR_PRD_RMST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({KSACDE: cent_ksacde,BMNCDE:p_BMNCDE,EJCDE:p_shncde,EJKBN:p_EJKBN})});
    const dataH = await responseH.json();
    // console.log('dataH:%o',dataH)
    //if (dataH.RCNT != 0)record_exists // 2023/09/28　if条件修正
    if (dataH.res[0].record_exists != 0)
    {
      // console.log('商品マスタ基本正常')
      return 1;
    }else
    {
      return 0;
    }
  }

  /**
   * 値呼び出し
   * @param rowNo 呼び出し行
   * @param checkName 呼び出し項目
   * @returns 取得値
   */
  function getItitemval(rowNo,checkName) {
    let value = '';
    switch (rowNo) {
      case 1:
        switch(checkName){
          case 'shncde':
            value = shohinCode1Ref.current?.getValue();
            break;
          case 'suu':
            value = shohinSuu1Ref.current?.getValue();
            break;
          case 'gnt':
            value = shohinGnt1Ref.current?.getValue();
            break;
          case 'btn':
            value = shohinBtn1Ref.current?.getValue();
            break;
          case 'gnkkin':
            value = shohinGnkkin1Ref.current?.getValue();
            break;
          case 'bakkin':
            value = shohinBakkin1Ref.current?.getValue();
            break;
          default:
            break;
        }
        break;
      case 2:
        switch(checkName){
          case 'shncde':
            value = shohinCode2Ref.current?.getValue();
            break;
          case 'suu':
            value = shohinSuu2Ref.current?.getValue();
            break;
          case 'gnt':
            value = shohinGnt2Ref.current?.getValue();
            break;
          case 'btn':
            value = shohinBtn2Ref.current?.getValue();
            break;
          case 'gnkkin':
            value = shohinGnkkin2Ref.current?.getValue();
            break;
          case 'bakkin':
            value = shohinBakkin2Ref.current?.getValue();
            break;
          default:
            break;
        }
        break;
      case 3:
        switch(checkName){
          case 'shncde':
            value = shohinCode3Ref.current?.getValue();
            break;
          case 'suu':
            value = shohinSuu3Ref.current?.getValue();
            break;
          case 'gnt':
            value = shohinGnt3Ref.current?.getValue();
            break;
          case 'btn':
            value = shohinBtn3Ref.current?.getValue();
            break;
          case 'gnkkin':
            value = shohinGnkkin3Ref.current?.getValue();
            break;
          case 'bakkin':
            value = shohinBakkin3Ref.current?.getValue();
            break;
          default:
            break;
        }
        break;
      case 4:
        switch(checkName){
          case 'shncde':
            value = shohinCode4Ref.current?.getValue();
            break;
          case 'suu':
            value = shohinSuu4Ref.current?.getValue();
            break;
          case 'gnt':
            value = shohinGnt4Ref.current?.getValue();
            break;
          case 'btn':
            value = shohinBtn4Ref.current?.getValue();
            break;
          case 'gnkkin':
            value = shohinGnkkin4Ref.current?.getValue();
            break;
          case 'bakkin':
            value = shohinBakkin4Ref.current?.getValue();
            break;
          default:
            break;
        }
        break;
      case 5:
        switch(checkName){
          case 'shncde':
            value = shohinCode5Ref.current?.getValue();
            break;
          case 'suu':
            value = shohinSuu5Ref.current?.getValue();
            break;
          case 'gnt':
            value = shohinGnt5Ref.current?.getValue();
            break;
          case 'btn':
            value = shohinBtn5Ref.current?.getValue();
            break;
          case 'gnkkin':
            value = shohinGnkkin5Ref.current?.getValue();
            break;
          case 'bakkin':
            value = shohinBakkin5Ref.current?.getValue();
            break;
          default:
            break;
        }
        break;
      case 6:
        switch(checkName){
          case 'shncde':
            value = shohinCode6Ref.current?.getValue();
            break;
          case 'suu':
            value = shohinSuu6Ref.current?.getValue();
            break;
          case 'gnt':
            value = shohinGnt6Ref.current?.getValue();
            break;
          case 'btn':
            value = shohinBtn6Ref.current?.getValue();
            break;
          case 'gnkkin':
            value = shohinGnkkin6Ref.current?.getValue();
            break;
          case 'bakkin':
            value = shohinBakkin6Ref.current?.getValue();
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    return value;
  }

  /**
   * 値設定
   * @param value 設定値
   * @param rowNo 設定行
   * @param checkName 設定項目
   */
  function setItitemval(value,rowNo,checkName) {
    switch (rowNo) {
      case 1:
        switch(checkName){
          case 'shncde':
            shohinCode1Ref.current?.setValue(value)
            break;
          case 'suu':
            shohinSuu1Ref.current?.setValue(value);
            break;
          case 'gnt':
            shohinGnt1Ref.current?.setValue(value);
            break;
          case 'btn':
            shohinBtn1Ref.current?.setValue(value);
            break;
          case 'gnkkin':
            shohinGnkkin1Ref.current?.setValue(value);
            break;
          case 'bakkin':
            shohinBakkin1Ref.current?.setValue(value);
            break;
          case 'eosjankbn':
            setIteosjankbn1(value);
            break;
          default:
            break;
        }
        break;
      case 2:
        switch(checkName){
          case 'shncde':
            shohinCode2Ref.current?.setValue(value)
            break;
          case 'suu':
            shohinSuu2Ref.current?.setValue(value);
            break;
          case 'gnt':
            shohinGnt2Ref.current?.setValue(value);
            break;
          case 'btn':
            shohinBtn2Ref.current?.setValue(value);
            break;
          case 'gnkkin':
            shohinGnkkin2Ref.current?.setValue(value);
            break;
          case 'bakkin':
            shohinBakkin2Ref.current?.setValue(value);
            break;
          case 'eosjankbn':
            setIteosjankbn2(value);
            break;
          default:
            break;
        }
        break;
      case 3:
        switch(checkName){
          case 'shncde':
            shohinCode3Ref.current?.setValue(value)
            break;
          case 'suu':
            shohinSuu3Ref.current?.setValue(value);
            break;
          case 'gnt':
            shohinGnt3Ref.current?.setValue(value);
            break;
          case 'btn':
            shohinBtn3Ref.current?.setValue(value);
            break;
          case 'gnkkin':
            shohinGnkkin3Ref.current?.setValue(value);
            break;
          case 'bakkin':
            shohinBakkin3Ref.current?.setValue(value);
            break;
          case 'eosjankbn':
            setIteosjankbn3(value);
            break;
          default:
            break;
        }
        break;
      case 4:
        switch(checkName){
          case 'shncde':
            shohinCode4Ref.current?.setValue(value)
            break;
          case 'suu':
            shohinSuu4Ref.current?.setValue(value);
            break;
          case 'gnt':
            shohinGnt4Ref.current?.setValue(value);
            break;
          case 'btn':
            shohinBtn4Ref.current?.setValue(value);
            break;
          case 'gnkkin':
            shohinGnkkin4Ref.current?.setValue(value);
            break;
          case 'bakkin':
            shohinBakkin4Ref.current?.setValue(value);
            break;
          case 'eosjankbn':
            setIteosjankbn4(value);
            break;
          default:
            break;
        }
        break;
      case 5:
        switch(checkName){
          case 'shncde':
            shohinCode5Ref.current?.setValue(value)
            break;
          case 'suu':
            shohinSuu5Ref.current?.setValue(value);
            break;
          case 'gnt':
            shohinGnt5Ref.current?.setValue(value);
            break;
          case 'btn':
            shohinBtn5Ref.current?.setValue(value);
            break;
          case 'gnkkin':
            shohinGnkkin5Ref.current?.setValue(value);
            break;
          case 'bakkin':
            shohinBakkin5Ref.current?.setValue(value);
            break;
          case 'eosjankbn':
            setIteosjankbn5(value);
            break;
          default:
            break;
        }
        break;
      case 6:
        switch(checkName){
          case 'shncde':
            shohinCode6Ref.current?.setValue(value)
            break;
          case 'suu':
            shohinSuu6Ref.current?.setValue(value);
            break;
          case 'gnt':
            shohinGnt6Ref.current?.setValue(value);
            break;
          case 'btn':
            shohinBtn6Ref.current?.setValue(value);
            break;
          case 'gnkkin':
            shohinGnkkin6Ref.current?.setValue(value);
            break;
          case 'bakkin':
            shohinBakkin6Ref.current?.setValue(value);
            break;
          case 'eosjankbn':
            setIteosjankbn6(value);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  /**
   * 色設定
   * @param color 設定色
   * @param rowNo 設定行
   * @param checkName 設定項目
   */
  function setItitemclr(color,rowNo,checkName) {
    switch (rowNo) {
      case 1:
        switch(checkName){
          case 'shncde':
            shohinCode1Ref.current?.setBGColor(color);
            break;
          case 'suu':
            shohinSuu1Ref.current?.setBGColor(color);
            break;
          case 'gnt':
            shohinGnt1Ref.current?.setBGColor(color);
            break;
          case 'btn':
            shohinBtn1Ref.current?.setBGColor(color);
              break;
          case 'suufclr':
            shohinSuu1Ref.current?.setColor(color);
            break;
          case 'gnkkinfclr':
            shohinGnkkin1Ref.current?.setColor(color);
            break;
          case 'bakkinfclr':
            shohinBakkin1Ref.current?.setColor(color);
            break;
          default:
            break;
        }
        break;
      case 2:
        switch(checkName){
          case 'shncde':
            shohinCode2Ref.current?.setBGColor(color);
            break;
          case 'suu':
            shohinSuu2Ref.current?.setBGColor(color);
            break;
          case 'gnt':
            shohinGnt2Ref.current?.setBGColor(color);
            break;
          case 'btn':
            shohinBtn2Ref.current?.setBGColor(color);
              break;
          case 'suufclr':
            shohinSuu2Ref.current?.setColor(color);
            break;
          case 'gnkkinfclr':
            shohinGnkkin2Ref.current?.setColor(color);
            break;
          case 'bakkinfclr':
            shohinBakkin2Ref.current?.setColor(color);
            break;
          default:
            break;
        }
        break;
      case 3:
        switch(checkName){
          case 'shncde':
            shohinCode3Ref.current?.setBGColor(color);
            break;
          case 'suu':
            shohinSuu3Ref.current?.setBGColor(color);
            break;
          case 'gnt':
            shohinGnt3Ref.current?.setBGColor(color);
            break;
          case 'btn':
            shohinBtn3Ref.current?.setBGColor(color);
              break;
          case 'suufclr':
            shohinSuu3Ref.current?.setColor(color);
            break;
          case 'gnkkinfclr':
            shohinGnkkin3Ref.current?.setColor(color);
            break;
          case 'bakkinfclr':
            shohinBakkin3Ref.current?.setColor(color);
            break;
          default:
            break;
        }
        break;
      case 4:
        switch(checkName){
          case 'shncde':
            shohinCode4Ref.current?.setBGColor(color);
            break;
          case 'suu':
            shohinSuu4Ref.current?.setBGColor(color);
            break;
          case 'gnt':
            shohinGnt4Ref.current?.setBGColor(color);
            break;
          case 'btn':
            shohinBtn4Ref.current?.setBGColor(color);
              break;
          case 'suufclr':
            shohinSuu4Ref.current?.setColor(color);
            break;
          case 'gnkkinfclr':
            shohinGnkkin4Ref.current?.setColor(color);
            break;
          case 'bakkinfclr':
            shohinBakkin4Ref.current?.setColor(color);
            break;
          default:
            break;
        }
        break;
      case 5:
        switch(checkName){
          case 'shncde':
            shohinCode5Ref.current?.setBGColor(color);
            break;
          case 'suu':
            shohinSuu5Ref.current?.setBGColor(color);
            break;
          case 'gnt':
            shohinGnt5Ref.current?.setBGColor(color);
            break;
          case 'btn':
            shohinBtn5Ref.current?.setBGColor(color);
              break;
          case 'suufclr':
            shohinSuu5Ref.current?.setColor(color);
            break;
          case 'gnkkinfclr':
            shohinGnkkin5Ref.current?.setColor(color);
            break;
          case 'bakkinfclr':
            shohinBakkin5Ref.current?.setColor(color);
            break;
          default:
            break;
        }
        break;
      case 6:
        switch(checkName){
          case 'shncde':
            shohinCode6Ref.current?.setBGColor(color);
            break;
          case 'suu':
            shohinSuu6Ref.current?.setBGColor(color);
            break;
          case 'gnt':
            shohinGnt6Ref.current?.setBGColor(color);
            break;
          case 'btn':
            shohinBtn6Ref.current?.setBGColor(color);
              break;
          case 'suufclr':
            shohinSuu6Ref.current?.setColor(color);
            break;
          case 'gnkkinfclr':
            shohinGnkkin6Ref.current?.setColor(color);
            break;
          case 'bakkinfclr':
            shohinBakkin6Ref.current?.setColor(color);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  /**
   * 商品コードチェックフラグ変更
   * @param rowNo 設定行
   * @param cde 0:エラーなし, 1:未チェック, 2:エラー, 9:確認中
   */
  function setChkSHNCDE (rowNo,cde){
    switch (rowNo) {
      case 1:
        shohinCode1Ref.current?.setCheckflg(cde);
        setChkflg(1);
        break;
      case 2:
        shohinCode2Ref.current?.setCheckflg(cde);
        setChkflg(1);
        break;
      case 3:
        shohinCode3Ref.current?.setCheckflg(cde);
        setChkflg(1);
        break;
      case 4:
        shohinCode4Ref.current?.setCheckflg(cde);
        setChkflg(1);
        break;
      case 5:
        shohinCode5Ref.current?.setCheckflg(cde);
        setChkflg(1);
        break;
      case 6:
        shohinCode6Ref.current?.setCheckflg(cde);
        setChkflg(1);
        break;
      default:
        break;
    }
  }

  /**
   * 画面クリア
   */
  const Gmclr = () =>{
    setItjgyucde('');      //事業部コード
    setItgnkknrflg('');  //売原管理フラグ/原価管理フラグ
    setIteosjankbn1('');  //ＥＯＳ／ＪＡＮ区分1
    setIteosjankbn2('');  //ＥＯＳ／ＪＡＮ区分2
    setIteosjankbn3('');  //ＥＯＳ／ＪＡＮ区分3
    setIteosjankbn4('');  //ＥＯＳ／ＪＡＮ区分4
    setIteosjankbn5('');  //ＥＯＳ／ＪＡＮ区分5
    setIteosjankbn6('');  //ＥＯＳ／ＪＡＮ区分6
    zeirituRef.current?.setValue('');
    
    if (kubunRef.current?.getValue()=='1' || kubunRef.current?.getValue()=='')
    {
      zeiKubunRef.current?.setValue('0');
    }else{
      zeiKubunRef.current?.setValue('');
    }

    denpyoNoRef.current?.setValue('');
    setItdenno01('');   //伝票No.用1回目メモリ
    sireCodeRef.current?.setValue('');
    setItsirnm('');
    keijyoYMRef.current?.setValue('');
    syaMiseRef.current?.setValue('');
    setItsmise01('');   //社店CD用1回目メモリ
    if (state.K_FLG !== 1 ){
      syaMiseRef.current?.setValue(state.MISES);
      setItsmise01(state.MISES);
    }

    if (state.ETC == 'K'){
      setItsmisenm('');
    }else{
      setItsmisenm(state.MISEMEI);
    }
    bunruiCodeRef.current?.setValue('');
    setItbmncde01('');  //分類CD用１回目メモリ
    denpyoKubunRef.current?.setValue('');
    denpyoYMDRef.current?.setValue('');
    denGRef.current?.setValue('');
    TSEGNKFOTRef.current?.setValue('');
    GNKFOTRef.current?.setValue('');
    TSEBAKFOTRef.current?.setValue('');
    BAKFOTRef.current?.setValue('');
    shohinCode1Ref.current?.setValue('');
    shohinCode2Ref.current?.setValue('');
    shohinCode3Ref.current?.setValue('');
    shohinCode4Ref.current?.setValue('');
    shohinCode5Ref.current?.setValue('');
    shohinCode6Ref.current?.setValue('');
    shohinSuu1Ref.current?.setValue('');
    shohinSuu2Ref.current?.setValue('');
    shohinSuu3Ref.current?.setValue('');
    shohinSuu4Ref.current?.setValue('');
    shohinSuu5Ref.current?.setValue('');
    shohinSuu6Ref.current?.setValue('');
    shohinGnt1Ref.current?.setValue('');
    shohinGnt2Ref.current?.setValue('');
    shohinGnt3Ref.current?.setValue('');
    shohinGnt4Ref.current?.setValue('');
    shohinGnt5Ref.current?.setValue('');
    shohinGnt6Ref.current?.setValue('');
    shohinGnkkin1Ref.current?.setValue('');
    shohinGnkkin2Ref.current?.setValue('');
    shohinGnkkin3Ref.current?.setValue('');
    shohinGnkkin4Ref.current?.setValue('');
    shohinGnkkin5Ref.current?.setValue('');
    shohinGnkkin6Ref.current?.setValue('');
    shohinBtn1Ref.current?.setValue('');
    shohinBtn2Ref.current?.setValue('');
    shohinBtn3Ref.current?.setValue('');
    shohinBtn4Ref.current?.setValue('');
    shohinBtn5Ref.current?.setValue('');
    shohinBtn6Ref.current?.setValue('');
    shohinBakkin1Ref.current?.setValue('');
    shohinBakkin2Ref.current?.setValue('');
    shohinBakkin3Ref.current?.setValue('');
    shohinBakkin4Ref.current?.setValue('');
    shohinBakkin5Ref.current?.setValue('');
    shohinBakkin6Ref.current?.setValue('');
    kubunRef.current?.setBGColor('#FFFFFF');
    zeirituRef.current?.setBGColor('#FFFFFF');
    zeiKubunRef.current?.setBGColor('#FFFFFF');
    denpyoNoRef.current?.setBGColor('#FFFFFF');
    sireCodeRef.current?.setBGColor('#FFFFFF');
    keijyoYMRef.current?.setBGColor('#FFFFFF');
    syaMiseRef.current?.setBGColor('#FFFFFF');
    bunruiCodeRef.current?.setBGColor('#FFFFFF');
    denpyoKubunRef.current?.setBGColor('#FFFFFF');
    denpyoYMDRef.current?.setBGColor('#FFFFFF');
    binRef.current?.setBGColor('#FFFFFF');
    denFRef.current?.setBGColor('#FFFFFF');
    denHRef.current?.setBGColor('#FFFFFF');
    denGRef.current?.setBGColor('#FFFFFF');
    denIRef.current?.setBGColor('#FFFFFF');
    denJRef.current?.setBGColor('#FFFFFF');
    denKRef.current?.setBGColor('#FFFFFF');
    TSEGNKFOTRef.current?.setBGColor('#FFFFFF');
    GNKFOTRef.current?.setBGColor('#FFFFFF');
    TSEBAKFOTRef.current?.setBGColor('#FFFFFF');
    BAKFOTRef.current?.setBGColor('#FFFFFF');
    shohinCode1Ref.current?.setBGColor('#FFFFFF');
    shohinCode2Ref.current?.setBGColor('#FFFFFF');
    shohinCode3Ref.current?.setBGColor('#FFFFFF');
    shohinCode4Ref.current?.setBGColor('#FFFFFF');
    shohinCode5Ref.current?.setBGColor('#FFFFFF');
    shohinCode6Ref.current?.setBGColor('#FFFFFF');
    shohinSuu1Ref.current?.setBGColor('#FFFFFF');
    shohinSuu2Ref.current?.setBGColor('#FFFFFF');
    shohinSuu3Ref.current?.setBGColor('#FFFFFF');
    shohinSuu4Ref.current?.setBGColor('#FFFFFF');
    shohinSuu5Ref.current?.setBGColor('#FFFFFF');
    shohinSuu6Ref.current?.setBGColor('#FFFFFF');
    shohinGnt1Ref.current?.setBGColor('#FFFFFF');
    shohinGnt2Ref.current?.setBGColor('#FFFFFF');
    shohinGnt3Ref.current?.setBGColor('#FFFFFF');
    shohinGnt4Ref.current?.setBGColor('#FFFFFF');
    shohinGnt5Ref.current?.setBGColor('#FFFFFF');
    shohinGnt6Ref.current?.setBGColor('#FFFFFF');
    shohinGnkkin1Ref.current?.setBGColor('#FFFFFF');
    shohinGnkkin2Ref.current?.setBGColor('#FFFFFF');
    shohinGnkkin3Ref.current?.setBGColor('#FFFFFF');
    shohinGnkkin4Ref.current?.setBGColor('#FFFFFF');
    shohinGnkkin5Ref.current?.setBGColor('#FFFFFF');
    shohinGnkkin6Ref.current?.setBGColor('#FFFFFF');
    shohinBtn1Ref.current?.setBGColor('#FFFFFF');
    shohinBtn2Ref.current?.setBGColor('#FFFFFF');
    shohinBtn3Ref.current?.setBGColor('#FFFFFF');
    shohinBtn4Ref.current?.setBGColor('#FFFFFF');
    shohinBtn5Ref.current?.setBGColor('#FFFFFF');
    shohinBtn6Ref.current?.setBGColor('#FFFFFF');
    shohinBakkin1Ref.current?.setBGColor('#FFFFFF');
    shohinBakkin2Ref.current?.setBGColor('#FFFFFF');
    shohinBakkin3Ref.current?.setBGColor('#FFFFFF');
    shohinBakkin4Ref.current?.setBGColor('#FFFFFF');
    shohinBakkin5Ref.current?.setBGColor('#FFFFFF');
    shohinBakkin6Ref.current?.setBGColor('#FFFFFF');

    shohinCode1Ref.current?.setColor('#000000');
    shohinCode2Ref.current?.setColor('#000000');
    shohinCode3Ref.current?.setColor('#000000');
    shohinCode4Ref.current?.setColor('#000000');
    shohinCode5Ref.current?.setColor('#000000');
    shohinCode6Ref.current?.setColor('#000000');
    shohinSuu1Ref.current?.setColor('#000000');
    shohinSuu2Ref.current?.setColor('#000000');
    shohinSuu3Ref.current?.setColor('#000000');
    shohinSuu4Ref.current?.setColor('#000000');
    shohinSuu5Ref.current?.setColor('#000000');
    shohinSuu6Ref.current?.setColor('#000000');
    shohinGnt1Ref.current?.setColor('#000000');
    shohinGnt2Ref.current?.setColor('#000000');
    shohinGnt3Ref.current?.setColor('#000000');
    shohinGnt4Ref.current?.setColor('#000000');
    shohinGnt5Ref.current?.setColor('#000000');
    shohinGnt6Ref.current?.setColor('#000000');
    shohinGnkkin1Ref.current?.setColor('#000000');
    shohinGnkkin2Ref.current?.setColor('#000000');
    shohinGnkkin3Ref.current?.setColor('#000000');
    shohinGnkkin4Ref.current?.setColor('#000000');
    shohinGnkkin5Ref.current?.setColor('#000000');
    shohinGnkkin6Ref.current?.setColor('#000000');
    shohinBtn1Ref.current?.setColor('#000000');
    shohinBtn2Ref.current?.setColor('#000000');
    shohinBtn3Ref.current?.setColor('#000000');
    shohinBtn4Ref.current?.setColor('#000000');
    shohinBtn5Ref.current?.setColor('#000000');
    shohinBtn6Ref.current?.setColor('#000000');
    shohinBakkin1Ref.current?.setColor('#000000');
    shohinBakkin2Ref.current?.setColor('#000000');
    shohinBakkin3Ref.current?.setColor('#000000');
    shohinBakkin4Ref.current?.setColor('#000000');
    shohinBakkin5Ref.current?.setColor('#000000');
    shohinBakkin6Ref.current?.setColor('#000000');
    TSEGNKFOTRef.current?.setColor('#000000');
    GNKFOTRef.current?.setColor('#000000');
    BAKFOTRef.current?.setColor('#000000');
    TSEBAKFOTRef.current?.setColor('#000000');

    sireCodeRef.current?.setMeisaiFlg(0);
    kubunRef.current?.setCheckflg(0);           // 区分
    syaMiseRef.current?.setCheckflg(0);         // 社店CD
    sireCodeRef.current?.setCheckflg(0);        // 取引先CD
    zeirituRef.current?.setCheckflg(0);         // 税率
    zeiKubunRef.current?.setCheckflg(0);        // 税区分
    bunruiCodeRef.current?.setCheckflg(0);      // 分類CD
    denpyoKubunRef.current?.setCheckflg(0);     // 伝票区分
    
    binRef.current?.setCheckflg(0);             // 便
    shohinCode1Ref.current?.setCheckflg(0);     // 商品コード1
    shohinCode2Ref.current?.setCheckflg(0);     // 商品コード2
    shohinCode3Ref.current?.setCheckflg(0);     // 商品コード3
    shohinCode4Ref.current?.setCheckflg(0);     // 商品コード4
    shohinCode5Ref.current?.setCheckflg(0);     // 商品コード5
    shohinCode6Ref.current?.setCheckflg(0);     // 商品コード6

    setAlert('　');         // アラートメッセージの初期化
  }

  /**
   * 頭0埋め処理
   * @param SetDate 対象情報
   * @param SFLG 0:商品コード以外、1:商品コード
   * @param NumDig 桁数(頭0埋め含む)
   * @returns　頭0埋めされた値
   */
  const Fhwith = (SetDate,SFLG=1,NumDig = 0) =>{
    let Afcorr;
    // console.log('SetDate:%o',typeof(SetDate)) ;
    if (SetDate !== '')
    {
      if (SFLG == 1){
        switch(SetDate.length){
          case 1:
          case 2:
            Afcorr = SetDate.padStart(3,'0');
            break;
          case 7:
            Afcorr = SetDate.padStart(8,'0');
            break;
          case 9:
          case 10:
          case 11:
          case 12:
            Afcorr = SetDate.padStart(13,'0');
            break;
          default:
            Afcorr = SetDate;
            break;
        }
      }else{
        Afcorr = SetDate.padStart(NumDig,'0');
      }
    }else{
      Afcorr = SetDate;
    }
    return Afcorr;
  }

  /**
   * 明細欄　色変更処理
   * @param SetDENKBN 伝票区分
   */
  const setfclr = (SetDENKBN) =>{
    let Setcolor;
    let SetFlg = true;
    switch (SetDENKBN){
      case '02':
      case '03':
      case '09':
      case '22':
        // 赤字
        Setcolor = '#FF0000';
        // 伝票区分 02:返品 03:値引き 09:返品 22:物流経由返品の場合、G欄活性化
        SetFlg = false;
        setallow(true);
        break;
      default:
        // 黒字
        Setcolor = '#000000';
        // G欄非活性化
        SetFlg = true;
        setDENG(true);
        denGRef.current?.setValue('');  // 伝票区分変更時はG欄をクリア 2023/10/30
        setallow(false);
        break;
    }

    setDENG(SetFlg);

    // 色変更
    shohinCode1Ref.current?.setColor(Setcolor);
    shohinCode2Ref.current?.setColor(Setcolor);
    shohinCode3Ref.current?.setColor(Setcolor);
    shohinCode4Ref.current?.setColor(Setcolor);
    shohinCode5Ref.current?.setColor(Setcolor);
    shohinCode6Ref.current?.setColor(Setcolor);
    shohinSuu1Ref.current?.setColor(Setcolor);
    shohinSuu2Ref.current?.setColor(Setcolor);
    shohinSuu3Ref.current?.setColor(Setcolor);
    shohinSuu4Ref.current?.setColor(Setcolor);
    shohinSuu5Ref.current?.setColor(Setcolor);
    shohinSuu6Ref.current?.setColor(Setcolor);
    shohinGnt1Ref.current?.setColor(Setcolor);
    shohinGnt2Ref.current?.setColor(Setcolor);
    shohinGnt3Ref.current?.setColor(Setcolor);
    shohinGnt4Ref.current?.setColor(Setcolor);
    shohinGnt5Ref.current?.setColor(Setcolor);
    shohinGnt6Ref.current?.setColor(Setcolor);
    shohinGnkkin1Ref.current?.setColor(Setcolor);
    shohinGnkkin2Ref.current?.setColor(Setcolor);
    shohinGnkkin3Ref.current?.setColor(Setcolor);
    shohinGnkkin4Ref.current?.setColor(Setcolor);
    shohinGnkkin5Ref.current?.setColor(Setcolor);
    shohinGnkkin6Ref.current?.setColor(Setcolor);
    shohinBtn1Ref.current?.setColor(Setcolor);
    shohinBtn2Ref.current?.setColor(Setcolor);
    shohinBtn3Ref.current?.setColor(Setcolor);
    shohinBtn4Ref.current?.setColor(Setcolor);
    shohinBtn5Ref.current?.setColor(Setcolor);
    shohinBtn6Ref.current?.setColor(Setcolor);
    shohinBakkin1Ref.current?.setColor(Setcolor);
    shohinBakkin2Ref.current?.setColor(Setcolor);
    shohinBakkin3Ref.current?.setColor(Setcolor);
    shohinBakkin4Ref.current?.setColor(Setcolor);
    shohinBakkin5Ref.current?.setColor(Setcolor);
    shohinBakkin6Ref.current?.setColor(Setcolor);
    TSEGNKFOTRef.current?.setColor(Setcolor);
    GNKFOTRef.current?.setColor(Setcolor);
    BAKFOTRef.current?.setColor(Setcolor);
    TSEBAKFOTRef.current?.setColor(Setcolor);

  }

  /**
   * 要素所属タイプ
   * @param id 項目id
   * @returns 1:ヘッダ要素,2:明細要素,3:フッタ要素,9:確認
   */
  const getInputType = (id) => {
    let ret = 0;
    switch (id) {
      case 'KBN':          // 区分
      case 'ZEIRTU':       // 税率
      case 'ZEIKBN':       // 税区分
      case 'SMISE':        // 社店CD
      case 'BMNCDE':       // 分類CD
      case 'DENKBN':       // 伝票区分
      case 'DENNO':        // 伝票No.
      case 'SIRCDE':       // 取引先CD
      case 'DENYMD':       // 年月日(伝票日付)
      case 'KIJYYM':       // 計上年月
        ret = 1;
        break;
      case 'SHNCDE1':      // 商品コード1
      case 'SHNCDE2':      // 商品コード2
      case 'SHNCDE3':      // 商品コード3
      case 'SHNCDE4':      // 商品コード4
      case 'SHNCDE5':      // 商品コード5
      case 'SHNCDE6':      // 商品コード6
      case 'SUU1':         // 数量1
      case 'SUU2':         // 数量2
      case 'SUU3':         // 数量3
      case 'SUU4':         // 数量4
      case 'SUU5':         // 数量5
      case 'SUU6':         // 数量6
      case 'GNT1':         // 原価単価1
      case 'GNT2':         // 原価単価2
      case 'GNT3':         // 原価単価3
      case 'GNT4':         // 原価単価4
      case 'GNT5':         // 原価単価5
      case 'GNT6':         // 原価単価6
      case 'BTN1':         // 売単価1
      case 'BTN2':         // 売単価2
      case 'BTN3':         // 売単価3
      case 'BTN4':         // 売単価4
      case 'BTN5':         // 売単価5
      case 'BTN6':         // 売単価6
        ret = 2;
        break;
      case 'DENG':         // 伝票G欄
      case 'GNKFOT':       // 原価金額合計
      case 'BAKFOT':       // 売価金額合計
      case 'TSEGNKFOT':    // 訂正後原価金額合計
      case 'TSEBAKFOT':    // 訂正後売価金額合計
        ret = 3;
        break;
      case 'ret':          // 登録処理
        ret = 9;
        break;
      default:
        break;
    }
    return ret;
  }
  //#endregion

  //#region onKeyDown
  /**
   * ボタン押下時の処理
   * @param key 入力キー
   * @param inputRef 現在の項目情報
   * @param inputRtRef 前項目情報
   * @param inputNxRef 次項目情報
   */
  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {
    let mvFlg = 0;
    switch (key) {
      case "Enter":
        //setAlert('　');
        switch (inputRef.current.id) {
          case 'KBN':         // 区分設定
            mvFlg = onKeyDown_KBN();
            break;
          case 'ZEIRTU':      // 税率
            await onKeyDown_ZEIRTU();
            break;
          case 'ZEIKBN':      // 税区分
            mvFlg = await onKeyDown_ZEIKBN();
            break;
          case 'SMISE':       // 社店CD
            mvFlg = await onKeyDown_SMISE();
            break;
          case 'BMNCDE':      // 分類CD
            mvFlg = await onKeyDown_BMNCDE();
            break;
          case 'DENKBN':      // 伝票区分
            mvFlg = await onKeyDown_DENKBN();
            break;
          case 'DENNO':       // 伝票No.
            mvFlg = onKeyDown_DENNO();
            break;
          case 'SIRCDE':      // 取引先CD
            mvFlg = await onKeyDown_SIRCDE();
            break;
          case 'DENYMD':      // 年月日
            mvFlg = onKeyDown_DENYMD();
            break;
          case 'BIN':         // 便
            mvFlg = onKeyDown_BIN();
            break;
          case 'GNKFOT':      // 原価金額合計
            mvFlg = await onKeyDown_GNKFOT();
            break;
          case 'BAKFOT':      // 売価金額合計
            mvFlg = await onKeyDown_BAKFOT();
            break;
          case 'TSEGNKFOT':   // 訂正後原価金額合計
            mvFlg = await onKeyDown_TSEGNKFOT();
            break;
          case 'TSEBAKFOT':   // 訂正後売価金額合計
            mvFlg = await onKeyDown_TSEBAKFOT();
            break;
          case 'DENG':        // 伝票G欄チェック
            mvFlg = await onKeyDown_DENG();
            break;
          case 'ret':         // 登録処理
            if (keyDisabled.current) 
            {
              return;
            }
            keyDisabled.current = true;

            const ret_kbn = onKeyDown_KBN(1); // 入力エラーがなければ0が返る
            if (ret_kbn == 0) {
              isInInputConfirm = true;
              mvFlg = 2;
              await InputConfirmed();
              isInInputConfirm = false;
            }
            setTimeout(() => {
              // 処理中フラグを下げる
              keyDisabled.current = false;
            }, 1500);

            break;
          default:
            break;
        }
        switch (mvFlg) {
          case 0:
            inputNxRef.current.focus();   //次の項目に遷移
            onNextFocus(inputNxRef);
            break;
          case 1:
            inputRef.current.focus();     //現在の項目に遷移
            onNextFocus(inputRef);
            break;
          case 2:
            break;
          case 9:
            // inDENNO.current.focus();   //伝票No.の項目に遷移
            break;
          default:
            break;
        }
        break;
      case "PageUp":
        // 確認で押下時の処理
        if (inputRef.current.id == 'ret') {
          // 伝票G欄有効化確認
          if (disdeng === true){
            if (kubunRef.current?.getValue() === '1')
            {
              inBAKFOT.current.focus();
            }else if (kubunRef.current?.getValue() === '2'){
              inTSEBAKFOT.current.focus();
            }else{
              inZEIRTU.current.focus();
            }
          }else{
            // 伝票G欄が有効ならそのまま遷移
            inputRtRef.current.focus();
          }
        }else{
          // 確認と明細以外での遷移処理
          // inputRtRef.current.focus();
          const inputType = getInputType(inputRef.current.id);
          // 明細
          if (inputType == 2) {
            inDENYMD.current.focus();
          }else{
            // 確認と明細以外での遷移処理
            inputRtRef.current.focus();
          }
        }
        break;
      case "PageDown":
        // 伝票G欄有効化確認
        if (disdeng === true){
          switch (inputRef.current.id){
            case 'BAKFOT':
            case 'TSEBAKFOT':
              inRET.current.focus();
              break;
            default:
              inputNxRef.current.focus();
              break;
          }
        }else{
          inputNxRef.current.focus();
        }
        break;
      default:
        break;
    }
  };

  /**
   * ボタン押下時の処理(明細部)
   * @param key 入力キー
   * @param inputRef 現在の項目情報
   * @param inputRtRef 前項目情報
   * @param inputNxRef 次項目情報
   * @param inUpRef 上キー
   * @param inRgRef 右キー
   * @param inDnRef 下キー
   * @param inLfRef 左キー
   */
  const onKeyDown_Meisai =  async (key,inputRef,inputRtRef,inputNxRef,inUpRef,inRgRef,inDnRef,inLfRef) => {
    let mvFlg = 0;
    switch (key) {
      case "Enter":
        switch (inputRef.current.id) {
          case 'SHNCDE1':     // 商品コード1
            mvFlg = 2;
            shohinCode1Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(1) == 0){
              onKeyDown_SHNCDE(1);
            }
            break;
          case 'SHNCDE2':     // 商品コード2
            mvFlg = 2;
            shohinCode2Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(2) == 0){
              onKeyDown_SHNCDE(2);
            }
            break;
          case 'SHNCDE3':     // 商品コード3
            mvFlg = 2;
            shohinCode3Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(3) == 0){
              onKeyDown_SHNCDE(3);
            }
            break;
          case 'SHNCDE4':     // 商品コード4
            mvFlg = 2;
            shohinCode4Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(4) == 0){
              onKeyDown_SHNCDE(4);
            }
            break;
          case 'SHNCDE5':     // 商品コード5
            mvFlg = 2;
            shohinCode5Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(5) == 0){
              onKeyDown_SHNCDE(5);
            }
            break;
          case 'SHNCDE6':     // 商品コード6
            mvFlg = 2;
            shohinCode6Ref.current?.setCheckflg(9);
            if(inInputConfirm_SHNCDE(6) == 0){
              onKeyDown_SHNCDE(6);
            }
            break;
          case 'SUU1':        // 数量1
            mvFlg = onKeyDown_SUU(1);
            if(mvFlg == 1){mvFlg = 0};
            break;
          case 'GNT1':        // 原単価1
            onKeyDown_GNT(1);
            break;
          case 'BTN1':        // 売単価1
            onKeyDown_BTN(1);
            break;
          case 'SUU2':        // 数量2
            mvFlg = onKeyDown_SUU(2);
            if(mvFlg == 1){mvFlg = 0};
            break;
          case 'GNT2':        // 原単価2
            onKeyDown_GNT(2);
            break;
          case 'BTN2':        // 売単価2
            onKeyDown_BTN(2);
            break;
          case 'SUU3':        // 数量3
            mvFlg = onKeyDown_SUU(3);
            if(mvFlg == 1){mvFlg = 0};
            break;
          case 'GNT3':        // 原単価3
            onKeyDown_GNT(3);
            break;
          case 'BTN3':        // 売単価3
            onKeyDown_BTN(3);
            break;
          case 'SUU4':        // 数量4
            mvFlg = onKeyDown_SUU(4);
            if(mvFlg == 1){mvFlg = 0};
            break;
          case 'GNT4':        // 原単価4
            onKeyDown_GNT(4);
            break;
          case 'BTN4':        // 売単価4
            onKeyDown_BTN(4);
            break;
          case 'SUU5':        // 数量5
            mvFlg = onKeyDown_SUU(5);
            if(mvFlg == 1){mvFlg = 0};
            break;
          case 'GNT5':        // 原単価5
            onKeyDown_GNT(5);
            break;
          case 'BTN5':        // 売単価5
            onKeyDown_BTN(5);
            break;
          case 'SUU6':        // 数量6
            mvFlg = onKeyDown_SUU(6);
            if(mvFlg == 1){mvFlg = 0};
            break;
          case 'GNT6':        // 原単価6
            onKeyDown_GNT(6);
            break;
          case 'BTN6':        // 売単価6
            onKeyDown_BTN(6);
            break;

        }
        switch (mvFlg) {
          case 0:
            inputNxRef.current.focus();   //次の項目に遷移
            onNextFocus(inputNxRef);
            break;
          case 1:
            inputRef.current.focus();   //現在の項目に遷移
            onNextFocus(inputRef);
            break;
          case 2:
            break;
          case 9:
            // inDENNO.current.focus();   //伝票No.の項目に遷移
            break;
          default:
            break;
        }
        break;
      case "PageUp":
        inDENYMD.current.focus();
        break;
      case "PageDown":
        // 明細でPageDown
        if (kubunRef.current?.getValue() == '1') {
          // 区分：１	原価金額合計
          inGNKFOT.current.focus();
        } else if (kubunRef.current?.getValue() == '2') {
          // 区分：２	訂正後原価合計金額
          inTSEGNKFOT.current.focus();
        } else if (kubunRef.current?.getValue() == '3') {
          // 区分：３	確認
          inRET.current.focus();
        }
        break;
      case "ArrowUp":
        inUpRef.current.focus();      //上の項目遷移（明細用）
        break;
      case "ArrowRight":
        inRgRef.current.focus();      //右の項目遷移（明細用）
        break;
      case "ArrowDown":
        inDnRef.current.focus();      //右の項目遷移（明細用）
        break;
      case "ArrowLeft":
        inLfRef.current.focus();      //左の項目遷移（明細用）

        break;
    }
  };

  /**
     * 確認項目にフォーカス時の処理
     */
  function onFocus_ret(){
    let itKBN = kubunRef.current?.getValue();
    switch(itKBN){
      case '1':
        setAlert('確認してください！！');//TCGM0005：
        break;
      case '2':
        setAlert('確認してください！！');//TCGM0005：
        break;
      case '3':
        setAlert('削除を行いますか？');//TCGM0006：
        break;
      default:
        setAlert('　');
        break;
    }
  }
  //#endregion

  //#region ヘッダ部入力
  // 区分設定
  function onKeyDown_KBN(instart = 0){
    let mvFlg = 0;
    kubunRef.current?.setBGColor('#FFFFFF');
    //setAlert('　');
    if (!(kubunRef.current?.getValue() == '1' || kubunRef.current?.getValue() == '2' || kubunRef.current?.getValue() == '3')){
      setAlert('区分に1:新規,2:修正,3:削除以外は入力できません。');//TCGE0008:
      kubunRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
      return mvFlg;
    }
    kubunRef.current?.setCheckflg(0); //2023/11/09 add
    switch(kubunRef.current?.getValue()){
      case '1':
        setItkbnnm('新規');
        break;
      case '2':
        setItkbnnm('修正');
        if(instart === 0){
          inDENNO.current.focus();
          mvFlg = 2;  // 2023/09/28
        }
        break;
      case '3':
        setItkbnnm('削除');
        if(instart === 0){
          inDENNO.current.focus();
          mvFlg = 2;  // 2023/09/28
        }
        break;
      default:
        break;
    }
    setAlert('　');
    return mvFlg;
  }

  // ヘッダ部入力 税率
  const onKeyDown_ZEIRTU = async()=>{
    let mvFlg = 0;
    zeirituRef.current?.setBGColor('#FFFFFF');
    sireCodeRef.current?.setBGColor('#FFFFFF');
    if (zeirituRef.current?.getValue() === ''){
      setAlert('税率入力エラー');//TCGE0009：
      zeirituRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
      return mvFlg;
    }

    if (zeiKubunRef.current?.getValue() === '2'){
      if (zeirituRef.current?.getValue() !== '0.0'){
        setAlert('税率と税区分の組み合わせエラー');
        zeirituRef.current?.setBGColor('#f59d9d');
        zeiKubunRef.current?.setBGColor('#f59d9d');
        mvFlg = 1;
        return mvFlg;
      }else{
        setAlert(' ');
        zeirituRef.current?.setBGColor('#FFFFFF');
        zeiKubunRef.current?.setBGColor('#FFFFFF');
      }
    }


    //設定管理マスタ 存在チェック
    // 2023/11/21 高速化
    const mapResult = regionList5.filter(item=>{return item.id+item.value === '0020000'+zeirituRef.current?.getValue();});
    // const response1 = await fetch('/TCSA0130/SL_SET_MST2_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},  //2023/11/09 upd
    // body: JSON.stringify({CDE: '002',SCDE: '0000',item:zeirituRef.current?.getValue()})});
    // const data1 = await response1.json();
    // if (data1.recnt == 0){
    if (mapResult.length == 0){
      setAlert('税率入力エラー');//TCGE0009：
      zeirituRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
      return mvFlg;
    }

    if (sireCodeRef.current?.getValue() !== ''){
      // 取引先CD 存在チェック
      let lsircde = sireCodeRef.current?.getValue().padStart(6,'0');
      sireCodeRef.current?.setValue(lsircde);

      // 2023/11/21 高速化対応
      const mapResult = regionList4.filter(item=>{return item.id === cent_ksacde+lsircde;});
      // const responseC = await fetch('/TCSA0130/MR_SUP_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      // body: JSON.stringify({KSACDE:cent_ksacde,SIRCDE: lsircde})}); // 2023/09/28 state.KSACDEをcent_ksacdeに変更
      // const dataC = await responseC.json();
      // if (dataC.RCNT != 0){
      if (mapResult.length != 0){
        // setItsirnm(dataC.res[0].SIRMEIK);
        setItsirnm(mapResult[0].SIRMEIK);   //2023/11/21 高速化対応
        // console.log('取引先税率:'+dataC.res[0].ZEIRTU+' | '+zeirituRef.current?.getValue());
        if (zeiKubunRef.current?.getValue() == '0' || zeiKubunRef.current?.getValue() == '1' || zeiKubunRef.current?.getValue().trim() == ''){
          // if (dataC.res[0].ZEIRTU != zeirituRef.current?.getValue())   //2023/11/21 高速化対応
          if (mapResult[0].ZEIRTU != zeirituRef.current?.getValue())
          {
            setAlert('税率と税区分と取引先コードの組み合わせエラー');//TCGE0017：
            zeirituRef.current?.setBGColor('#f59d9d');
            sireCodeRef.current?.setBGColor('#f59d9d');
            mvFlg = 2;
            return mvFlg;
          }
        }
      }else{
        setAlert('取引先コード入力エラー');//TCGE0015：
        sireCodeRef.current?.setBGColor('#f59d9d');
        mvFlg = 2;
        return mvFlg;
      }

      // 伝票ヘッダ抽出
      // console.log('伝票ヘッダ抽出'+itlKBN);
      const responseD = await fetch('/TCSA0130/SL_HED_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ent_ksacde: cent_ksacde,ent_mise:cent_mise,sircde: lsircde,denno:denpyoNoRef.current?.getValue()})});
      const dataD = await responseD.json();
      // console.log('dataD:%o',dataD);
      if (dataD.RCNT==0){
        if (kubunRef.current?.getValue()=='2' || kubunRef.current?.getValue()=='3'){
          setAlert('伝票が登録されていません。');//TCGE0028：
          sireCodeRef.current?.setBGColor('#f59d9d');
          //inRET.current.focus();
          inSIRCDE.current.focus();
          mvFlg = 2;
          return mvFlg;
        }
      }else{
        // 2023/09/28 チェックの順番変更
        if (kubunRef.current?.getValue()=='1'){
          setAlert('既に登録済みの伝票です。');//TCGE0032：
          sireCodeRef.current?.setBGColor('#f59d9d');
          mvFlg = 1;
          return mvFlg;
        }else{
          if (dataD.res[0].SND_KNR_KBN != 0){
            setAlert('送信処理済みの伝票です。（修正・削除不可）');//TCGE0029：
            sireCodeRef.current?.setBGColor('#f59d9d');
            mvFlg = 1;
            return mvFlg;
          }
        }
      }

      // 伝票明細抽出
      if (kubunRef.current?.getValue()=='2' || kubunRef.current?.getValue()=='3'){
        // console.log('伝票明細抽出'+itlKBN);
        const responseE = await fetch('/TCSA0130/SL_DTL_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ent_ksacde: dataD.res[0].ENT_KSACDE,ent_mise:dataD.res[0].ENT_MISE,densbt: dataD.res[0].DENSBT,
                              denno:dataD.res[0].DENNO,sircde:dataD.res[0].SIRCDE,ksacde:dataD.res[0].KSACDE,mise:dataD.res[0].MISE,httymd:''})});
        const dataE = await responseE.json();
        // console.log('伝票明細抽出結果：%o',dataE);
        // 2023/09/28 伝票の明細　未登録エラーの追加
        if (dataE.RCNT==0){
          setAlert('伝票の明細が登録されていません。');//TCGE0033：
          sireCodeRef.current?.setBGColor('#f59d9d');
          mvFlg = 2;
          return mvFlg;
        }

        if((!isInInputConfirm) || (sireCodeRef.current?.getMeisaiFlg() == 1))
        {
          zeirituRef.current?.setValue(dataD.res[0].ZEIRTU);
          zeiKubunRef.current?.setValue(dataD.res[0].ZEIKBN);
          syaMiseRef.current?.setValue(dataD.res[0].MISE);
          bunruiCodeRef.current?.setValue(dataD.res[0].BMNCDE);
          denpyoKubunRef.current?.setValue(dataD.res[0].DENKBN);
          denpyoYMDRef.current?.setValue(dataD.res[0].DENYMD.substr(2,2)+'/'+dataD.res[0].DENYMD.substr(4,2)+'/'+dataD.res[0].DENYMD.slice(-2));
          keijyoYMRef.current?.setValue(dataD.res[0].KIJYYM.substr(2,2)+'年'+dataD.res[0].KIJYYM.slice(-2)+'月');
          setfclr(dataD.res[0].DENKBN);
          if (kubunRef.current?.getValue() == '2'){
            GNKFOTRef.current?.setValue(Number(dataD.res[0].GNKFOT).toLocaleString());
            BAKFOTRef.current?.setValue(Number(dataD.res[0].BAKFOT).toLocaleString());
            TSEGNKFOTRef.current?.setValue(Number(dataD.res[0].TSEGNKFOT).toLocaleString());
            TSEBAKFOTRef.current?.setValue(Number(dataD.res[0].TSEBAKFOT).toLocaleString());
            if (dataD.res[0].DEN_G != '' && dataD.res[0].DEN_G != '0'){
              denGRef.current?.setValue(dataD.res[0].DEN_G.substr(2,2)+'/'+dataD.res[0].DEN_G.substr(4,2)+'/'+dataD.res[0].DEN_G.slice(-2));
            }
          }
          for(const key in dataE.res){
            switch(dataE.res[key].LINENO){
              case '1':
                shohinCode1Ref.current?.setValue(dataE.res[key].SHNCDE);
                shohinSuu1Ref.current?.setValue(Number(dataE.res[key].SUU).toFixed(1).toLocaleString());
                shohinGnt1Ref.current?.setValue(Number(dataE.res[key].GNT).toFixed(2).toLocaleString());
                shohinGnkkin1Ref.current?.setValue(Number(dataE.res[key].GNKKIN).toLocaleString());
                // console.log('数値チェック:'+dataE.res[key].BTN+'|'+dataE.res[key].BAKKIN);
                if (dataE.res[key].BTN==0 && dataE.res[key].BAKKIN==0){
                  shohinBtn1Ref.current?.setValue('0');
                  shohinBakkin1Ref.current?.setValue('0');
                }else{
                  shohinBtn1Ref.current?.setValue(Number(dataE.res[key].BTN).toLocaleString());
                  shohinBakkin1Ref.current?.setValue(Number(dataE.res[key].BAKKIN).toLocaleString());
                }
                break;
              case '2':
                shohinCode2Ref.current?.setValue(dataE.res[key].SHNCDE);
                shohinSuu2Ref.current?.setValue(Number(dataE.res[key].SUU).toFixed(1).toLocaleString());
                shohinGnt2Ref.current?.setValue(Number(dataE.res[key].GNT).toFixed(2).toLocaleString());
                shohinGnkkin2Ref.current?.setValue(Number(dataE.res[key].GNKKIN).toLocaleString());
                if (dataE.res[key].BTN==0 && dataE.res[key].BAKKIN==0){
                  shohinBtn2Ref.current?.setValue('0');
                  shohinBakkin2Ref.current?.setValue('0');
                }else{
                  shohinBtn2Ref.current?.setValue(Number(dataE.res[key].BTN).toLocaleString());
                  shohinBakkin2Ref.current?.setValue(Number(dataE.res[key].BAKKIN).toLocaleString());
                }
                break;
              case '3':
                shohinCode3Ref.current?.setValue(dataE.res[key].SHNCDE);
                shohinSuu3Ref.current?.setValue(Number(dataE.res[key].SUU).toFixed(1).toLocaleString());
                shohinGnt3Ref.current?.setValue(Number(dataE.res[key].GNT).toFixed(2).toLocaleString());
                shohinGnkkin3Ref.current?.setValue(Number(dataE.res[key].GNKKIN).toLocaleString());
                if (dataE.res[key].BTN==0 && dataE.res[key].BAKKIN==0){
                  shohinBtn3Ref.current?.setValue('0');
                  shohinBakkin3Ref.current?.setValue('0');
                }else{
                  shohinBtn3Ref.current?.setValue(Number(dataE.res[key].BTN).toLocaleString());
                  shohinBakkin3Ref.current?.setValue(Number(dataE.res[key].BAKKIN).toLocaleString());
                }
                break;
              case '4':
                shohinCode4Ref.current?.setValue(dataE.res[key].SHNCDE);
                shohinSuu4Ref.current?.setValue(Number(dataE.res[key].SUU).toFixed(1).toLocaleString());
                shohinGnt4Ref.current?.setValue(Number(dataE.res[key].GNT).toFixed(2).toLocaleString());
                shohinGnkkin4Ref.current?.setValue(Number(dataE.res[key].GNKKIN).toLocaleString());
                if (dataE.res[key].BTN==0 && dataE.res[key].BAKKIN==0){
                  shohinBtn4Ref.current?.setValue('0');
                  shohinBakkin4Ref.current?.setValue('0');
                }else{
                  shohinBtn4Ref.current?.setValue(Number(dataE.res[key].BTN).toLocaleString());
                  shohinBakkin4Ref.current?.setValue(Number(dataE.res[key].BAKKIN).toLocaleString());
                }
                break;
              case '5':
                shohinCode5Ref.current?.setValue(dataE.res[key].SHNCDE);
                shohinSuu5Ref.current?.setValue(Number(dataE.res[key].SUU).toFixed(1).toLocaleString());
                shohinGnt5Ref.current?.setValue(Number(dataE.res[key].GNT).toFixed(2).toLocaleString());
                shohinGnkkin5Ref.current?.setValue(Number(dataE.res[key].GNKKIN).toLocaleString());
                if (dataE.res[key].BTN==0 && dataE.res[key].BAKKIN==0){
                  shohinBtn5Ref.current?.setValue('0');
                  shohinBakkin5Ref.current?.setValue('0');
                }else{
                  shohinBtn5Ref.current?.setValue(Number(dataE.res[key].BTN).toLocaleString());
                  shohinBakkin5Ref.current?.setValue(Number(dataE.res[key].BAKKIN).toLocaleString());
                }
                break;
              case '6':
                shohinCode6Ref.current?.setValue(dataE.res[key].SHNCDE);
                shohinSuu6Ref.current?.setValue(Number(dataE.res[key].SUU).toFixed(1).toLocaleString());
                shohinGnt6Ref.current?.setValue(Number(dataE.res[key].GNT).toFixed(2).toLocaleString());
                shohinGnkkin6Ref.current?.setValue(Number(dataE.res[key].GNKKIN).toLocaleString());
                if (dataE.res[key].BTN==0 && dataE.res[key].BAKKIN==0){
                  shohinBtn6Ref.current?.setValue('0');
                  shohinBakkin6Ref.current?.setValue('0');
                }else{
                  shohinBtn6Ref.current?.setValue(Number(dataE.res[key].BTN).toLocaleString());
                  shohinBakkin6Ref.current?.setValue(Number(dataE.res[key].BAKKIN).toLocaleString());
                }
                break;
              default:
                break;
            }
          }
        }
        sireCodeRef.current?.setMeisaiFlg(0);
        // zeirituRef.current?.setMeisaiFlg(0);
        // meisaiflg = 0;
        if (!isInInputConfirm) {
          if (kubunRef.current?.getValue() == '2'){
            inTSEGNKFOT.current.focus();
            mvFlg = 2;
          }else{
            inRET.current.focus();
            mvFlg = 2;
          }
        }
      }
    }

    //chkZEIRTU = 0;  //2023/11/09 add
    zeirituRef.current?.setCheckflg(0);
    setAlert('　');
    return mvFlg;
  }

  // ヘッダ部入力 税区分
  const onKeyDown_ZEIKBN = async(instart = 0)=>{
    let mvFlg = 0;
    let lZEIKBN = Fhwith(zeiKubunRef.current?.getValue(),0,1);
    zeiKubunRef.current?.setValue(lZEIKBN);
    zeiKubunRef.current?.setBGColor('#FFFFFF');
    sireCodeRef.current?.setBGColor('#FFFFFF');
    zeirituRef.current?.setBGColor('#FFFFFF');
    //setAlert('　');

    // 必須確認
    if (lZEIKBN.trim() === ''){
      setAlert('税区分入力エラー');//TCGE0010：
      zeiKubunRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
      return mvFlg;
    }

    // 存在チェック
    // 2023/11/21 高速化
    const mapResult = regionList5.filter(item=>{return item.id+item.value === '0030000'+lZEIKBN;});
    // const response1 = await fetch('/TCSA0130/SL_SET_MST2_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    // body: JSON.stringify({CDE: '003',SCDE: '0000',item:lZEIKBN})});
    // const data1 = await response1.json();
    // // console.log('検索結果：%o',data1.recnt);
    // if (data1.recnt == 0){
    if (mapResult.length == 0){
      setAlert('税区分入力エラー');//TCGE0010：
      zeiKubunRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
      return mvFlg;
    }

    if (lZEIKBN === '2'){
      if (zeirituRef.current?.getValue() !== '0.0'){
        setAlert('税率と税区分の組み合わせエラー');
        zeirituRef.current?.setBGColor('#f59d9d');
        zeiKubunRef.current?.setBGColor('#f59d9d');
        mvFlg = 1;
        return mvFlg;
      }
    }

    // <各店>時の遷移制御
    if(instart === 0){
      if (state.ETC != 'K'){
        syaMiseRef.current?.setValue(state.MISES);
        setItsmise01(state.MISES);
        inBMNCDE.current.focus();
        mvFlg = 2;
      }
    }
    zeiKubunRef.current?.setCheckflg(0);
    setAlert('　');
    return mvFlg;
  }

  // ヘッダ部入力 社店CD
  const onKeyDown_SMISE = async()=>{
    let mvFlg = 0;
    let lMISE = Fhwith(syaMiseRef.current?.getValue(),0,3);
    let lMISE01 = itSMISE01;

    if (syaMiseRef.current?.getValue() == '')
    {
      setAlert('店コード入力エラー');
      syaMiseRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
      return mvFlg;
    }

    // 一度フォーカス移動しているか確認。　2023/10/26
    if(syaMiseRef.current?.getFocusflg()){
      syaMiseRef.current?.setFocusflg(false);    // フォーカス移動のフラグをＯＦＦにする。
      // 値が変更されているか確認
      if(syaMiseRef.current?.getCheckflg() == 1){
        lMISE01 = '';            // 変更されていたらベリファイをリセット。
      }
    }

    if (state.ETC ==='K') {
      // ＜各店＞時の処理 ベリファイ処理
      /*** 入力1回目 ***/
      if (lMISE01 == '' ){
        if(lMISE!=''){
          if (isInInputConfirm) {
            setAlert('店コード入力エラー');
            syaMiseRef.current?.setBGColor('#f59d9d');
            syaMiseRef.current?.setValue('');
            mvFlg = 1;
            return mvFlg;
          } else {
            syaMiseRef.current?.setBGColor('#FFFFFF');
            setAlert('　');
            syaMiseRef.current?.setValue('');
          }
          //店管理&店舗マスタ読込(高速化)
          const mapResult = regionList.filter(item=>{return item.id === cent_ksacde+lMISE;});
          // const response0 = await fetch('/TCSA0130/MR_SHP_MSTM_sel2',{method: 'POST',headers: {'Content-Type': 'application/json'},
          // body: JSON.stringify({KSACDE: cent_ksacde,MISE: lMISE})});
          // const data0 = await response0.json();
          // if (data0.RCNT == 0){
          //   setAlert('店コード入力エラー');//TCGE0010：
          //   syaMiseRef.current?.setBGColor('#f59d9d');
          //   // setItsmise01('');
          //   mvFlg = 1;
          //   return mvFlg;
          // }
          if (mapResult.length == 0){
            setAlert('店コード入力エラー');//TCGE0010：
            syaMiseRef.current?.setBGColor('#f59d9d');
            // setItsmise01('');
            mvFlg = 1;
            return mvFlg;
          }
          syaMiseRef.current?.setBGColor('#FFFFFF');
          setItsmise01(lMISE);
          // setItsmisenm(data0.res[0].MISEMEI);
          setItsmisenm(mapResult[0].MISEMEI.trim());
          syaMiseRef.current?.setValue('');
          mvFlg = 1;
          return mvFlg;
        }else{
          setAlert('店コード入力エラー');//TCGE0010：
          syaMiseRef.current?.setBGColor('#f59d9d');
          mvFlg = 1;
          return mvFlg;
        }
      }

      /*** 入力2回目 ***/
      if (lMISE!=lMISE01){
        setAlert('店コード入力エラー');//TCGE0010：
        syaMiseRef.current?.setBGColor('#f59d9d');
        setItsmise01(lMISE);
        syaMiseRef.current?.setValue('');
        //店管理&店舗マスタ読込
        const mapResult = regionList.filter(item=>{return item.id === cent_ksacde+lMISE;});
        // const response0 = await fetch('/TCSA0130/MR_SHP_MSTM_sel2',{method: 'POST',headers: {'Content-Type': 'application/json'},
        // body: JSON.stringify({KSACDE: cent_ksacde,MISE: lMISE})});
        // const data0 = await response0.json();
        // if (data0.RCNT != 0){
          if (mapResult.length == 0){
          // setItsmisenm(data0.res[0].MISEMEI);
          setItsmisenm(mapResult[0].MISEMEI.trim());
        }else{
          setItsmise01('');
        }
        mvFlg = 1;
        return mvFlg;
      }

      syaMiseRef.current?.setCheckflg(0);   // 変更フラグをリセット。
    }
    setAlert('　');
    return mvFlg;
  }

  // ヘッダ部入力 分類CD
  const onKeyDown_BMNCDE = async()=>{
    let mvFlg = 0;
    setSelectData(bunruiCodeRef.current?.getValue());
    let lBMNCDE = Fhwith(bunruiCodeRef.current?.getValue(),0,4);
    bunruiCodeRef.current?.setValue(lBMNCDE);
    bunruiCodeRef.current?.setBGColor('#FFFFFF');

    /*** 入力1回目 ***/
    if (itBMNCDE01 == '' || itBMNCDE01 != lBMNCDE){
      const mapResult = regionList2.filter(item=>{return item.id === cent_ksacde+syaMiseRef.current?.getValue()+lBMNCDE;});
      // const responseA = await fetch('/TCSA0130/MR_SHP_MSTM_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},  //2023/11/09 upd
      // body: JSON.stringify({ksacde: cent_ksacde,mise: syaMiseRef.current?.getValue(),bmncde:lBMNCDE})});
      // const dataA = await responseA.json();
      // if (dataA.RCNT == 0){
      if (mapResult.length == 0){
        setAlert('分類コード入力エラー');//TCGE0011：
        bunruiCodeRef.current?.setBGColor('#f59d9d');
        setItbmncde01('');
        inBMNCDE.current.focus();
        setTimeout(() => inBMNCDE.current.select(),100)
        mvFlg = 1;
      }else{
        if (itBMNCDE01 != '') {
          setAlert('分類コード入力エラー');
          bunruiCodeRef.current?.setBGColor('#f59d9d');
          inBMNCDE.current.focus();
          setTimeout(() => inBMNCDE.current.select(),100)
        }else{
          setAlert('　');
        }
        setItbmncde01(lBMNCDE);
        bunruiCodeRef.current?.setValue('');
        inBMNCDE.current.focus();
        mvFlg = 2;
      }
      return mvFlg;
    }else{
      /*** 入力2回目 ***/
      // if (itBMNCDE01 != lBMNCDE){
      //   setAlert('分類コード入力エラー');
      //   bunruiCodeRef.current?.setBGColor('#f59d9d');
      //   setItbmncde01('');
      //   bunruiCodeRef.current?.setValue('');
      //   mvFlg = 1;
      // }else
      // {
        //分類コードの入力可能税区分チェック
        // console.log('zeiKubunRef.current?.getValue()'+zeiKubunRef.current?.getValue());
        const mapResult = regionList3.filter(item=>{return item.id === '001'+lBMNCDE;});
        const mapResult2 = regionList3.filter(item=>{return item.id === '0010000';});
        let okflg = 0;
        // const responseA = await fetch('/TCSA0130/SL_SET_MST3_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},  //2023/11/09 upd
        // body: JSON.stringify({CDE:'001',SCDE:lBMNCDE,item:zeiKubunRef.current?.getValue()})});
        // const dataA = await responseA.json();
        // if (!(dataA.resDt[0].DATE01==zeiKubunRef.current?.getValue() || dataA.resDt[0].DATE02==zeiKubunRef.current?.getValue() ||
        //     dataA.resDt[0].DATE03==zeiKubunRef.current?.getValue() || dataA.resDt[0].DATE04==zeiKubunRef.current?.getValue() ||
        //     dataA.resDt[0].DATE05==zeiKubunRef.current?.getValue() || dataA.resDt[0].DATE06==zeiKubunRef.current?.getValue() ||
        //     dataA.resDt[0].DATE07==zeiKubunRef.current?.getValue() || dataA.resDt[0].DATE08==zeiKubunRef.current?.getValue() ||
        //     dataA.resDt[0].DATE09==zeiKubunRef.current?.getValue() || dataA.resDt[0].DATE10==zeiKubunRef.current?.getValue()))
        if (mapResult.length != 0){
          const mapResult3 = regionList3.filter(item=>{return item.id+item.value === '001'+lBMNCDE+zeiKubunRef.current?.getValue();});
          if (mapResult3!=0)
          {
            okflg = 1;
          }
        }else{
          if (mapResult2.length != 0){
            const mapResult4 = regionList3.filter(item=>{return item.id+item.value === '0010000'+zeiKubunRef.current?.getValue();});
            if (mapResult4.length != 0)
            {
              okflg = 1;
            }
          }
        }
        if (okflg == 0)
        {
          setAlert('税区分と分類コードの組み合わせエラー');//TCGE0012：
          bunruiCodeRef.current?.setBGColor('#f59d9d');
          //S 215 2023/09/19 税区分と分類コードの組み合わせエラーは税区分に移動する
          zeiKubunRef.current?.setBGColor('#f59d9d');
          inZEIKBN.current.focus();
          mvFlg = 2;
          return mvFlg;
          //E 215 2023/09/19 税区分と分類コードの組み合わせエラーは税区分に移動する
        }else{
          bunruiCodeRef.current?.setBGColor('#ffffff');
          zeiKubunRef.current?.setBGColor('#ffffff');
          setAlert('　');
        }
      // }
    }
    //chkBMNCDE = 0;  //2023/11/09 upd
    bunruiCodeRef.current?.setCheckflg(0);
    return mvFlg;
  }

  // ヘッダ部入力 伝票区分
  const onKeyDown_DENKBN = async()=>{
    let mvFlg = 0;
    denpyoKubunRef.current?.setBGColor('#ffffff');
    //denGRef.current?.setValue('');  // 伝票区分変更時はG欄をクリア
    let ldenkbn;
    if (denpyoKubunRef.current?.getValue()!==''){
      ldenkbn = denpyoKubunRef.current?.getValue().padStart(2,'0');
      denpyoKubunRef.current?.setValue(ldenkbn);
      setfclr(ldenkbn);
    }
    // console.log('data1:'+state.ETC+'|'+state.K_MISES);
    //基本
    if (ldenkbn === '01' || ldenkbn === '02' || ldenkbn === '03' ||
        // ldenkbn === '05' || ldenkbn === '06' || ldenkbn === '09' ||
        ldenkbn === '09' ||
        ldenkbn === '12' || ldenkbn === '22')
    {
      //物流各店
      if (state.ETC ==='K'){
        if (!(ldenkbn === '09' || ldenkbn === '12' || ldenkbn === '22')){
          setAlert('伝票区分入力エラー');
          denpyoKubunRef.current?.setBGColor('#f59d9d');
          mvFlg = 1;
          return mvFlg;
        }else{
          // setfclr(ldenkbn);
        }
      }else{
        if (state.K_MISES===''){
          //通常店舗
          if (!(ldenkbn === '01' || ldenkbn === '02' || ldenkbn === '03' ||
              ldenkbn === '05' || ldenkbn === '06'))
          {
            setAlert('伝票区分入力エラー');
            denpyoKubunRef.current?.setBGColor('#f59d9d');
            mvFlg = 1;
            return mvFlg;
          }else{
            // setfclr(ldenkbn);
          }
        }else{
          //物流センター
          if (!(ldenkbn === '01' || ldenkbn === '02' || ldenkbn === '03' ||
                ldenkbn === '12' || ldenkbn === '22'))
          {
            if ((state.MISES === '930' || state.MISES === '940') && ldenkbn === '09')
            {
              // setfclr(ldenkbn);
            }else{
              setAlert('伝票区分入力エラー');
              denpyoKubunRef.current?.setBGColor('#f59d9d');
              mvFlg = 1;
              return mvFlg;
            }
          }else{
            // console.log('data2:'+state.MISES+'|'+ldenkbn);
            if (((state.MISES === '930' || state.MISES === '940') &&
                (ldenkbn === '05'||ldenkbn === '06')))
            {
              setAlert('伝票区分入力エラー');
              denpyoKubunRef.current?.setBGColor('#f59d9d');
              mvFlg = 1;
              return mvFlg;
            }else{
              // setfclr(ldenkbn);
            }
          }
        }
      }
    }else{
      setAlert('伝票区分入力エラー');
      denpyoKubunRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
      return mvFlg;
    }
    //chkDENKBN = 0;  //2023/11/09 upd
    denpyoKubunRef.current?.setCheckflg(0);
    setAlert('　');
    return mvFlg;
  }

  // ヘッダ部入力 伝票No.
  function onKeyDown_DENNO(){
    let mvFlg = 0;
    let lDENNO = Fhwith(denpyoNoRef.current?.getValue(),0,7);
    let lDENNO01 = itDENNO01;
    denpyoNoRef.current?.setValue(lDENNO);

    // 区分「2」または「3」の場合ベリファイを実行しない
    if (kubunRef.current?.getValue() == '2' || kubunRef.current?.getValue() == '3'){
      return mvFlg;
    }

    // 一度フォーカス移動しているか確認。　2023/10/26
    if(denpyoNoRef.current?.getFocusflg()){
      denpyoNoRef.current?.setFocusflg(false);    // フォーカス移動のフラグをＯＦＦにする。

      // 値が変更されているか確認
      if(denpyoNoRef.current?.getCheckflg() == 1){
        lDENNO01 = '';            // 変更されていたらベリファイをリセット。
      }
    }
    denpyoNoRef.current?.setCheckflg(0);   // 変更フラグをリセット。


    /*** 入力1回目 ***/
    if (lDENNO01 === '') {
      let iRet = checkDJDENNO(lDENNO);
      // console.log('チェックデジット'+iRet);
      if (iRet === -1){
        setAlert('警告：伝票No.のチェックデジットの計算が合いません');//TCGC0001：
        denpyoNoRef.current?.setBGColor('#ffff00');
        setItdenno01(lDENNO);
        denpyoNoRef.current?.setValue('');
        inDENNO.current.focus();
        setTimeout(() => inDENNO.current.select(),100)
        mvFlg = 2;
        return mvFlg;
      }
      // console.log('初回チェック:'+itDENNO01);
      if (isInInputConfirm) {
        setAlert('伝票№入力エラー');
        denpyoNoRef.current?.setBGColor('#f59d9d');
      } else {
        denpyoNoRef.current?.setBGColor('#FFFFFF');
      }
      setItdenno01(lDENNO);
      denpyoNoRef.current?.setValue('');
      mvFlg = 2;
      inDENNO.current.focus();
      setTimeout(() => inDENNO.current.select(),100)
      return mvFlg;
    }
    /*** 入力2回目 ***/
    else if (itDENNO01 != lDENNO){
      setAlert('伝票№入力エラー');
      denpyoNoRef.current?.setBGColor('#f59d9d');
      setItdenno01(lDENNO);
      denpyoNoRef.current?.setValue('');
      mvFlg = 2;
      inDENNO.current.focus();
      setTimeout(() => inDENNO.current.select(),100)
      return mvFlg;
    }

    // console.log('カラーチェック'+itDENNOclr);
    if (denpyoNoRef.current?.getBGColor() != '#ffff00'){
      // console.log('カラーチェックOUT');
      denpyoNoRef.current?.setBGColor('#FFFFFF');
    }
    mvFlg = 0;
    setAlert('　');
    return mvFlg;
  }

  // ヘッダ部入力 取引先CD
  const onKeyDown_SIRCDE = async()=>{
    let mvFlg = 0;
    let lSIRCDE = Fhwith(sireCodeRef.current?.getValue(),0,6);
    sireCodeRef.current?.setValue(lSIRCDE);
    sireCodeRef.current?.setBGColor('#FFFFFF');  // 取引先CD背景色を初期化
    zeirituRef.current?.setBGColor('#FFFFFF');  // 税率背景色を初期化
    //setAlert('　');

    if(state.KSACDES !== '005'){
      // 仕入先コードチェックデジット
      let iRet = checkDJSIRCD(lSIRCDE);
      if (iRet == -1){
        setAlert('取引先コード入力エラー');
        sireCodeRef.current?.setBGColor('#f59d9d');
        mvFlg = 1;
        return mvFlg;
      }
    }

    // 取引先コード存在チェック
    const mapResult = regionList4.filter(item=>{return item.id === cent_ksacde+lSIRCDE;});
    // 2023/11/21 高速化対応
    // const responseC = await fetch('/TCSA0130/MR_SUP_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    // body: JSON.stringify({KSACDE:cent_ksacde,SIRCDE: lSIRCDE})}); // 2023/09/28 state.KSACDEをcent_ksacdeに変更
    // const dataC = await responseC.json();
    // if (dataC.RCNT == 0){
    if (mapResult.length == 0){
      setAlert('取引先コード入力エラー');//TCGE0015：
      sireCodeRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
      return mvFlg;
    }

    // setItsirnm(dataC.res[0].SIRMEIK);  2023/11/21 高速化対応
    setItsirnm(mapResult[0].SIRMEIK);
    // console.log('取引先税率:'+dataC.res[0].ZEIRTU+' | '+zeirituRef.current?.getValue());
    // 2023/10/02 区分2、3の場合は税率に取得した値をセットする
    if(kubunRef.current?.getValue() == '1'){
      //S 018 2023/09/19 税区分［3］、［4］の場合も追加する
      if (zeiKubunRef.current?.getValue() == '0' || zeiKubunRef.current?.getValue() == '1' || zeiKubunRef.current?.getValue() == '3' || zeiKubunRef.current?.getValue() == '4' || zeiKubunRef.current?.getValue().trim() == ''){
      //E 018 2023/09/19 税区分［3］、［4］の場合も追加する
        // if (dataC.res[0].ZEIRTU != zeirituRef.current?.getValue()){  2023/11/21 高速化対応
        if (mapResult[0].ZEIRTU != zeirituRef.current?.getValue()){
          setAlert('税率と税区分と取引先コードの組み合わせエラー');
          sireCodeRef.current?.setBGColor('#f59d9d');
          //S 216 2023/09/19 税率と税区分と取引先コードの組み合わせエラーは税率に移動する
          inZEIRTU.current.focus();
          setTimeout(() => inZEIRTU.current.select(),100)
          mvFlg = 2;
          zeirituRef.current?.setBGColor('#f59d9d');
          //E 216 2023/09/19 税率と税区分と取引先コードの組み合わせエラーは税率に移動する
          return mvFlg;
        }
      }
    }else if (kubunRef.current?.getValue() == '2' || kubunRef.current?.getValue() == '3'){
      // zeirituRef.current?.setValue(dataC.res[0].ZEIRTU);  2023/11/21 高速化対応
      zeirituRef.current?.setValue(mapResult[0].ZEIRTU);
    }

    let f_sel = '';
    if (kubunRef.current?.getValue()=='1'){
      f_sel = '/TCSA0130/SL_HED_DAT_sel2';
    }else{
      f_sel = '/TCSA0130/SL_HED_DAT_sel';
    }

    // 伝票ヘッダ抽出
    // console.log('伝票ヘッダ抽出'+itlKBN);
    const responseD = await fetch(f_sel,{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ent_ksacde: cent_ksacde,ent_mise:cent_mise,sircde: lSIRCDE,denno:denpyoNoRef.current?.getValue()})});
    const dataD = await responseD.json();
    // console.log('dataD:%o',dataD);
    if (dataD.RCNT==0){
      if (kubunRef.current?.getValue()=='2' || kubunRef.current?.getValue()=='3'){
        setAlert('伝票が登録されていません。');//TCGE0028：
        sireCodeRef.current?.setBGColor('#f59d9d');
        mvFlg = 1;
        return mvFlg;
      }
    }else{
      if (kubunRef.current?.getValue()=='1'){
        setAlert('既に登録済みの伝票です。');//TCGE0032：
        sireCodeRef.current?.setBGColor('#f59d9d');
        mvFlg = 1;
        return mvFlg;
      }else{
        if (dataD.res[0].SND_KNR_KBN != 0){
          setAlert('送信処理済みの伝票です。（修正・削除不可）');//TCGE0029：
          sireCodeRef.current?.setBGColor('#f59d9d');
          mvFlg = 1;
          return mvFlg;
        }
      }
    }

    // 伝票明細抽出
    if (kubunRef.current?.getValue()=='2' || kubunRef.current?.getValue()=='3'){
      // console.log('伝票明細抽出'+itlKBN);
      const responseE = await fetch('/TCSA0130/SL_DTL_DAT_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ent_ksacde: dataD.res[0].ENT_KSACDE,ent_mise:dataD.res[0].ENT_MISE,densbt: dataD.res[0].DENSBT,
                            denno:dataD.res[0].DENNO,sircde:dataD.res[0].SIRCDE,ksacde:dataD.res[0].KSACDE,mise:dataD.res[0].MISE,httymd:''})});
      const dataE = await responseE.json();
      // console.log('伝票明細抽出結果：%o',dataE);
      // 2023/09/28 伝票の明細　未登録エラーの追加
      if (dataE.RCNT==0){
        setAlert('伝票の明細が登録されていません。');//TCGE0033：
        sireCodeRef.current?.setBGColor('#f59d9d');
        mvFlg = 2;
        return mvFlg;
      }

      if((!isInInputConfirm) || (sireCodeRef.current?.getMeisaiFlg() == 1))
      {
        zeirituRef.current?.setValue(dataD.res[0].ZEIRTU);
        zeiKubunRef.current?.setValue(dataD.res[0].ZEIKBN);
        syaMiseRef.current?.setValue(dataD.res[0].MISE);
        setItsmise01(dataD.res[0].MISE);
        bunruiCodeRef.current?.setValue(dataD.res[0].BMNCDE);
        denpyoKubunRef.current?.setValue(dataD.res[0].DENKBN);
        denpyoYMDRef.current?.setValue(dataD.res[0].DENYMD.substr(2,2)+'/'+dataD.res[0].DENYMD.substr(4,2)+'/'+dataD.res[0].DENYMD.slice(-2));
        keijyoYMRef.current?.setValue(dataD.res[0].KIJYYM.substr(2,2)+'年'+dataD.res[0].KIJYYM.slice(-2)+'月');
        binRef.current?.setValue(dataD.res[0].BIN);
        setfclr(dataD.res[0].DENKBN);

        setItbmncde01(dataD.res[0].BMNCDE);            // ベリファイ用

        GNKFOTRef.current?.setValue(Number(dataD.res[0].GNKFOT).toLocaleString());
        BAKFOTRef.current?.setValue(Number(dataD.res[0].BAKFOT).toLocaleString());
        TSEGNKFOTRef.current?.setValue(Number(dataD.res[0].TSEGNKFOT).toLocaleString());
        TSEBAKFOTRef.current?.setValue(Number(dataD.res[0].TSEBAKFOT).toLocaleString());
        if (dataD.res[0].DEN_G != '' && dataD.res[0].DEN_G != '0'){
          denGRef.current?.setValue(dataD.res[0].DEN_G.substr(2,2)+'/'+dataD.res[0].DEN_G.substr(4,2)+'/'+dataD.res[0].DEN_G.slice(-2));
        }

        for(const key in dataE.res){
          switch(dataE.res[key].LINENO){
            case '1':
              shohinCode1Ref.current?.setValue(dataE.res[key].SHNCDE);
              shohinSuu1Ref.current?.setValue(Number(dataE.res[key].SUU).toFixed(1).toLocaleString());
              shohinGnt1Ref.current?.setValue(Number(dataE.res[key].GNT).toFixed(2).toLocaleString());
              shohinGnkkin1Ref.current?.setValue(Number(dataE.res[key].GNKKIN).toLocaleString());
              setIteosjankbn1(dataE.res[key].ESJ_KBN);
              // console.log('数値チェック:'+dataE.res[key].BTN+'|'+dataE.res[key].BAKKIN);
              if (dataE.res[key].BTN==0 && dataE.res[key].BAKKIN==0){
                shohinBtn1Ref.current?.setValue('0');
                shohinBakkin1Ref.current?.setValue('0');
              }else{
                shohinBtn1Ref.current?.setValue(Number(dataE.res[key].BTN).toLocaleString());
                shohinBakkin1Ref.current?.setValue(Number(dataE.res[key].BAKKIN).toLocaleString());
              }
              break;
            case '2':
              shohinCode2Ref.current?.setValue(dataE.res[key].SHNCDE);
              shohinSuu2Ref.current?.setValue(Number(dataE.res[key].SUU).toFixed(1).toLocaleString());
              shohinGnt2Ref.current?.setValue(Number(dataE.res[key].GNT).toFixed(2).toLocaleString());
              shohinGnkkin2Ref.current?.setValue(Number(dataE.res[key].GNKKIN).toLocaleString());
              setIteosjankbn2(dataE.res[key].ESJ_KBN);
              if (dataE.res[key].BTN==0 && dataE.res[key].BAKKIN==0){
                shohinBtn2Ref.current?.setValue('0');
                shohinBakkin2Ref.current?.setValue('0');
              }else{
                shohinBtn2Ref.current?.setValue(Number(dataE.res[key].BTN).toLocaleString());
                shohinBakkin2Ref.current?.setValue(Number(dataE.res[key].BAKKIN).toLocaleString());
              }
              break;
            case '3':
              shohinCode3Ref.current?.setValue(dataE.res[key].SHNCDE);
              shohinSuu3Ref.current?.setValue(Number(dataE.res[key].SUU).toFixed(1).toLocaleString());
              shohinGnt3Ref.current?.setValue(Number(dataE.res[key].GNT).toFixed(2).toLocaleString());
              shohinGnkkin3Ref.current?.setValue(Number(dataE.res[key].GNKKIN).toLocaleString());
              setIteosjankbn3(dataE.res[key].ESJ_KBN);
              if (dataE.res[key].BTN==0 && dataE.res[key].BAKKIN==0){
                shohinBtn3Ref.current?.setValue('0');
                shohinBakkin3Ref.current?.setValue('0');
              }else{
                shohinBtn3Ref.current?.setValue(Number(dataE.res[key].BTN).toLocaleString());
                shohinBakkin3Ref.current?.setValue(Number(dataE.res[key].BAKKIN).toLocaleString());
              }
              break;
            case '4':
              shohinCode4Ref.current?.setValue(dataE.res[key].SHNCDE);
              shohinSuu4Ref.current?.setValue(Number(dataE.res[key].SUU).toFixed(1).toLocaleString());
              shohinGnt4Ref.current?.setValue(Number(dataE.res[key].GNT).toFixed(2).toLocaleString());
              shohinGnkkin4Ref.current?.setValue(Number(dataE.res[key].GNKKIN).toLocaleString());
              setIteosjankbn4(dataE.res[key].ESJ_KBN);
              if (dataE.res[key].BTN==0 && dataE.res[key].BAKKIN==0){
                shohinBtn4Ref.current?.setValue('0');
                shohinBakkin4Ref.current?.setValue('0');
              }else{
                shohinBtn4Ref.current?.setValue(Number(dataE.res[key].BTN).toLocaleString());
                shohinBakkin4Ref.current?.setValue(Number(dataE.res[key].BAKKIN).toLocaleString());
              }
              break;
            case '5':
              shohinCode5Ref.current?.setValue(dataE.res[key].SHNCDE);
              shohinSuu5Ref.current?.setValue(Number(dataE.res[key].SUU).toFixed(1).toLocaleString());
              shohinGnt5Ref.current?.setValue(Number(dataE.res[key].GNT).toFixed(2).toLocaleString());
              shohinGnkkin5Ref.current?.setValue(Number(dataE.res[key].GNKKIN).toLocaleString());
              setIteosjankbn5(dataE.res[key].ESJ_KBN);
              if (dataE.res[key].BTN==0 && dataE.res[key].BAKKIN==0){
                shohinBtn5Ref.current?.setValue('0');
                shohinBakkin5Ref.current?.setValue('0');
              }else{
                shohinBtn5Ref.current?.setValue(Number(dataE.res[key].BTN).toLocaleString());
                shohinBakkin5Ref.current?.setValue(Number(dataE.res[key].BAKKIN).toLocaleString());
              }
              break;
            case '6':
              shohinCode6Ref.current?.setValue(dataE.res[key].SHNCDE);
              shohinSuu6Ref.current?.setValue(Number(dataE.res[key].SUU).toFixed(1).toLocaleString());
              shohinGnt6Ref.current?.setValue(Number(dataE.res[key].GNT).toFixed(2).toLocaleString());
              shohinGnkkin6Ref.current?.setValue(Number(dataE.res[key].GNKKIN).toLocaleString());
              setIteosjankbn6(dataE.res[key].ESJ_KBN);
              if (dataE.res[key].BTN==0 && dataE.res[key].BAKKIN==0){
                shohinBtn6Ref.current?.setValue('0');
                shohinBakkin6Ref.current?.setValue('0');
              }else{
                shohinBtn6Ref.current?.setValue(Number(dataE.res[key].BTN).toLocaleString());
                shohinBakkin6Ref.current?.setValue(Number(dataE.res[key].BAKKIN).toLocaleString());
              }
              break;
            default:
              break;
          }
        }
      }
      sireCodeRef.current?.setMeisaiFlg(0);
      // zeirituRef.current?.setMeisaiFlg(0);
      // meisaiflg = 0;
      if (!isInInputConfirm) {
        // 遷移処理
        if(kubunRef.current?.getValue() == 2){
          inTSEGNKFOT.current.focus();
          mvFlg = 2;
        }else{
          inRET.current.focus();
          mvFlg = 2;
        }
      }
    }
    //chkSIRCDE = 0;  //2023/11/09 add
    sireCodeRef.current?.setCheckflg(0);      // 取引先CD
    setAlert('　');
    return mvFlg;
  }

  // ヘッダ部入力 年月日
  function onKeyDown_DENYMD(){
    let mvFlg = 0;
    if (denpyoYMDRef.current?.getValue() !=''){
      var strDate = '20'+denpyoYMDRef.current?.getValue();
      var dtdate = new Date(strDate);
      denpyoYMDRef.current?.setBGColor('#FFFFFF');
      //日付チェック
      if (ckDate(strDate)===true){
        // console.log('日付範囲2');
        let dt = new Date(state.SDAY);
        let dts = new Date(dt.getFullYear()-1,dt.getMonth(),dt.getDate());
        let dte = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate());
        // console.log('日付'+dts+'<'+dtdate+'<='+dte);
        if (dts < dtdate && dtdate <= dte){
          let ke = KeijoYM('20'+denpyoYMDRef.current?.getValue());
          // console.log('結果:'+ke);
          keijyoYMRef.current?.setValue(ke);
        }else{
          denpyoYMDRef.current?.setBGColor('#f59d9d');
          setAlert('日付入力エラー');//TCGE0016:
          mvFlg = 1;
          return mvFlg;
        }
      }else{
        denpyoYMDRef.current?.setBGColor('#f59d9d');
        setAlert('日付入力エラー');//TCGE0016:
        mvFlg = 1;
        return mvFlg;
      }
    }else{
      denpyoYMDRef.current?.setBGColor('#f59d9d');
      setAlert('日付入力エラー');//TCGE0016:
      mvFlg = 1;
      return mvFlg;
    }
    //chkDENYMD = 0;  //2023/11/09 add
    denpyoYMDRef.current?.setCheckflg(0);
    setAlert('　');
    return mvFlg;
  }
  
  // ヘッダ部入力 便
  function onKeyDown_BIN(){
    let mvFlg = 0;
    switch (binRef.current?.getValue())
    {
      case '':
        binRef.current?.setBGColor('#FFFFFF');
        if (state.ETC == 'K')
        {
          binRef.current?.setBGColor('#f59d9d');
          setAlert('便は「１」、「２」以外は入力することはできません。');//TCGE0004:
          mvFlg = 1;
          return mvFlg;
        }
        break;
      case '1':
      case '2':
        binRef.current?.setBGColor('#FFFFFF');
        break;
      default:
        binRef.current?.setBGColor('#f59d9d');
        setAlert('便は「１」、「２」以外は入力することはできません。');//TCGE0004:
        mvFlg = 1;
        return mvFlg;
        break;
    }
    binRef.current?.setCheckflg(0);
    return mvFlg;
  }
  //#endregion

  //#region 明細部入力
  /**
   * 明細部入力 商品コード 遷移の処理
   * @param rowNo 行No
   * @param instart Default = 0; 0:遷移あり、1:遷移なし
   * @returns 処理継続判定（0:後続処理続行, 1:後続処理中断）
   */
  function inInputConfirm_SHNCDE(rowNo,instart = 0){
    if (rowNo == 1 && shohinCode1Ref.current?.getValue() == ''){
      shohinCode1Ref.current?.setCheckflg(2);
      setAlert('商品コードを入力して下さい。');//TCGE0026：
      setItitemclr('#f59d9d',1,'shncde');
      if(instart === 0){
        inSUU1.current.focus();
      }
      return 1;
    }

    let itSHNCDE = getItitemval(rowNo,'shncde');
    if (itSHNCDE == '') {
      // 明細行で商品コードが空白で入力さエンター押下した場合、
      // 数量、原単価、原価金額、売単価、売価金額を空白にする（バックカラーもクリアにする）
      clearRow(rowNo);
    }

    // 空白行チェック
    let errflg = confirmed_rows(rowNo, true);

    if (itSHNCDE == '') {
      if (errflg != 0) {return 1};

      if(instart === 0){
        // 区分によって遷移先を変更
        if (kubunRef.current?.getValue() == '1'){
          inGNKFOT.current.focus();
        }else{
          inTSEGNKFOT.current.focus();
        }
        return 1;
      }
    }

    if(instart === 0){
      let inputNxRef = ['',inSUU1,inSUU2,inSUU3,inSUU4,inSUU5,inSUU6];
      inputNxRef[rowNo].current.focus();
    }
    return 0;
  }

  /**
   * 明細部入力 商品コード
   * @param rowNo 明細行
   * @returns 0:正常, 1:エラー
   */
  const onKeyDown_SHNCDE = async(rowNo)=>{

    let itSHNCDE = getItitemval(rowNo,'shncde');
    setItitemclr('#FFFFFF',rowNo,'shncde');
    let litJGYUCDE = await getJIGYOBUCDE(bunruiCodeRef.current?.getValue(),denpyoYMDRef.current?.getValue());
    let litSHNCDE = Fhwith(itSHNCDE);

    // 商品コードの前２桁が「０２」の場合、下６桁を「００００００」に変換 22023/10/25
    if(litSHNCDE.slice(0,2) == '02' && litSHNCDE.length == 13){
      litSHNCDE = litSHNCDE.slice(0,7) + '000000';
    };

    setItitemval(litSHNCDE,rowNo,'shncde');

    // console.log('事業CD:'+litJGYUCDE);
    if (!(litSHNCDE.length == 3 || litSHNCDE.length == 8 || litSHNCDE.length == 12 || litSHNCDE.length == 13 )){
      setAlert('商品コード入力エラー');//TCGE0026：
      setItitemclr('#f59d9d',rowNo,'shncde');
      setChkSHNCDE(rowNo,2);
      //setFocusSHNCDE(rowNo);
      return 1;
    }

    if(litSHNCDE.length == '3'){
      const ret = await chkPRDCLS(bunruiCodeRef.current?.getValue(),litSHNCDE,denpyoYMDRef.current?.getValue());
      // console.log('結果:'+ret);
      if (ret == 1){
        setItitemval(' ',rowNo,'eosjankbn');
      }else{
        setAlert('商品コード入力エラー');//TCGE0026：
        setItitemclr('#f59d9d',rowNo,'shncde');
        setChkSHNCDE(rowNo,2);
        return 1;
      }
    }

    if(litSHNCDE.length == '8'){
      //EOSCDで再チェック
      const ret = await chkSHNCDEMST_K(bunruiCodeRef.current?.getValue(),litSHNCDE,0);
      if (ret == 1){
        setItitemval('E',rowNo,'eosjankbn');
      }else{
        //JANCDで再チェック
        const ret = await chkSHNCDEMST_K(bunruiCodeRef.current?.getValue(),litSHNCDE,1);
        if (ret == 1){
          setItitemval('J',rowNo,'eosjankbn');

        }else{
          setAlert('商品コード入力エラー');//TCGE0026：
          setItitemclr('#f59d9d',rowNo,'shncde');
          setChkSHNCDE(rowNo,2);
          return 1;
        }
      }
    }

    if(litSHNCDE.length == '12' || litSHNCDE.length == '13'){
      // console.log('02&00000:'+litSHNCDE.slice(0,2)+'|'+litSHNCDE.slice(-6));
      if (litSHNCDE.length == '13' && litSHNCDE.slice(0,2)=='02' && litSHNCDE.slice(-6)=='000000'){
        setItitemval('J',rowNo,'eosjankbn');
        setItgnkknrflg('1');
      }else{
        const ret = await chkSHNCDEMST_K(bunruiCodeRef.current?.getValue(),litSHNCDE,1);
        if (ret == 1){
          setItitemval('J',rowNo,'eosjankbn');
        }else{
          setAlert('商品コード入力エラー');//TCGE0026：
          setItitemclr('#f59d9d',rowNo,'shncde');
          setChkSHNCDE(rowNo,2);
          return 1;
        }
      }
    }

    setChkSHNCDE(rowNo,0);

    // if(instart === 0){
    //   inputNxRef.current.focus();
    // }
    setAlert('　');
    return 0;
  }

  /**
   * 明細部入力 数量
   * @param rowNo 明細行
   * @param instart Default = 0; 0:遷移あり、1:遷移なし
   * @returns 0:正常, 1:エラー, 2:パーティーハウス時の遷移
   */
  function onKeyDown_SUU(rowNo, instart = 0){

    let itSHNCDE = getItitemval(rowNo,'shncde');
    let itSUU = getItitemval(rowNo,'suu');
    let itGNT = getItitemval(rowNo,'gnt');
    let itBTN = getItitemval(rowNo,'btn');
    let mvFlg = 0;
    let l_suu = 0;

    // 空白行チェック 2023/10/25
    confirmed_rows(rowNo);

    if(state.KSACDES !== '005'){
      // 商品コード入力確認
      if (itSHNCDE === ''){
        setAlert('商品コードを入力して下さい。');//TCGE0026：
        setItitemclr('#f59d9d',rowNo,'shncde');
        setChkSHNCDE(rowNo,2);
      }
    }else{
      if (itSUU == '') {
        if(rowNo != 1) {
          clearRow(rowNo);
          if(instart === 0){
            if (kubunRef.current?.getValue() == '1') {
              inGNKFOT.current.focus();
            }else if (kubunRef.current?.getValue() == '2'){
              inTSEGNKFOT.current.focus();
            }else{
              inRET.current.focus();
            }
          }
          return 2;
        }else{
          setAlert('数量を入力して下さい');
          setItitemclr('#f59d9d',rowNo,'suu');
          mvFlg = 1;
        }
      }
    }

    //マイナス編集
    let m_flg = 0;  //マイナス判定用（マイナスだとroundでは四捨五入されない）
    if (denpyoKubunRef.current?.getValue() == '02' || denpyoKubunRef.current?.getValue() == '03' || denpyoKubunRef.current?.getValue() == '05' || denpyoKubunRef.current?.getValue() == '06' || denpyoKubunRef.current?.getValue() == '09' ||denpyoKubunRef.current?.getValue() == '22'){
      l_suu = parseFloat(String(itSUU).replaceAll(/,/g, ''));
      m_flg = 1;
      setItitemclr('#FF0000',rowNo,'suufclr');
      if (l_suu > 0){
        l_suu = new Decimal(l_suu * -1);
        setItitemval(l_suu.toNumber(),rowNo,'suu')
      }else{
        l_suu = new Decimal(l_suu);
      }
    }else{
      setItitemclr('#000000',rowNo,'suufclr');
    }

    // 2023/09/28 原価金額及び売価金額を再計算する
    if(l_suu == 0){
      l_suu = new Decimal(parseFloat(String(itSUU||0).replaceAll(/,/g, '')));
    }
    let cgnt = new Decimal('0');
    if(itGNT!=''){
      cgnt = new Decimal(parseFloat(String(itGNT||0).replaceAll(/,/g, '')));
    }
    let cbtm = new Decimal('0');;
    if(itBTN!=''){
      cbtm = new Decimal(parseFloat(String(itBTN||0).replaceAll(/,/g, '')));
    }

    let gnk='';
    let bak='';
    if(itGNT!=''){
      if (m_flg ==0){
        // gnk = String(Math.round(l_suu*cgnt));
        // gnk = String(Math.round(((l_suu*100)*(cgnt*100))/10000));
        gnk = Math.round(l_suu.mul(cgnt).toNumber());
      }else{
        // gnk = String(Math.round(l_suu*cgnt*-1));
        // gnk = String(Math.round((((l_suu*100)*(cgnt*100))/10000)*-1));;
        gnk = Math.round(l_suu.mul(cgnt).toNumber()*-1);
        gnk = gnk * -1;
      }
      if (l_suu < 0){
        setItitemclr('#FF0000',rowNo,'gnkkinfclr');
      }
    }
    if (itBTN!==''){
      if (m_flg ==0){
        // bak = String(Math.round(l_suu*cbtm));
        // bak = String(Math.round(((l_suu*100)*(cbtm*100))/10000));
        bak = Math.round(l_suu.mul(cbtm).toNumber());
      }else{
        // bak = String(Math.round(l_suu*cbtm*-1));
        // bak = String(Math.round((((l_suu*100)*(cbtm*100))/10000)*-1));;
        bak = Math.round(l_suu.mul(cbtm).toNumber()*-1);
        if (bak == 0)
        {}else{
          bak = bak * -1;
        }
      }
      if (l_suu < 0){
        setItitemclr('#FF0000',rowNo,'bakkinfclr');
      }
    }
    if (gnk != '')
    {
      setItitemval(gnk.toLocaleString(),rowNo,'gnkkin');
    }
    if (bak != '')
    {
      setItitemval(bak.toLocaleString(),rowNo,'bakkin');
    }

    return mvFlg;
  }

  /**
   * 明細部入力 原単価
   * @param rowNo 明細行
   * @returns 0:正常, 1:エラー
   */
  function onKeyDown_GNT(rowNo){
    let itSHNCDE = getItitemval(rowNo,'shncde');
    let itSUU = getItitemval(rowNo,'suu');
    let itGNT = getItitemval(rowNo,'gnt');
    let itBTN = getItitemval(rowNo,'btn');
    let mvFlg = 0;

    //setAlert('　');
    setItitemclr('#FFFFFF',rowNo,'suu');
    setItitemclr('#FFFFFF',rowNo,'gnt');

    // 空白行チェック 2023/10/25
    confirmed_rows(rowNo);

    // 数量及び原単価0エラー
    if ((itSUU||0)==0 && (itGNT||0)==0){
      setItitemval(itSUU||0,rowNo,'suu');
      setAlert('数量もしくは原単価を入力して下さい');//TCGE0019：
      setItitemclr('#f59d9d',rowNo,'suu');
      setItitemclr('#f59d9d',rowNo,'gnt');
      mvFlg = 1;
    }else{
      if (itSUU!='' || itGNT!=''){
        let csuu = new Decimal(String(itSUU||0).replaceAll(/,/g, ''));
        let cgnt = new Decimal(String(itGNT||0).replaceAll(/,/g, ''));
        let cbtm = new Decimal(String(itBTN||0).replaceAll(/,/g, ''));
        // console.log('数量:'+csuu+'/'+csuu.length);
        let m_flg = 0;  //マイナス判定用（マイナスだとroundでは四捨五入されない）
        if (denpyoKubunRef.current?.getValue() == '02' || denpyoKubunRef.current?.getValue() == '03' || denpyoKubunRef.current?.getValue() == '05' || denpyoKubunRef.current?.getValue() == '06' || denpyoKubunRef.current?.getValue() == '09' ||denpyoKubunRef.current?.getValue() == '22'){
          m_flg = 1
        } 
        let gnk;
        let bak;
        if (m_flg == 0)  
        {
          // gnk = Math.round(parseFloat(csuu)*parseFloat(cgnt));
          // bak = Math.round(parseFloat(csuu)*parseFloat(cbtm));
          // gnk = Math.round(((parseFloat(csuu)*100)*(parseFloat(cgnt)*100))/10000);
          // bak = Math.round(((parseFloat(csuu)*100)*(parseFloat(cbtm)*100))/10000);
          gnk = Math.round(csuu.mul(cgnt).toNumber());
          bak = Math.round(csuu.mul(cbtm).toNumber());
        }else{
          // gnk = Math.round(parseFloat(csuu)*parseFloat(cgnt)*-1);
          // bak = Math.round(parseFloat(csuu)*parseFloat(cbtm)*-1);
          // gnk = Math.round((((parseFloat(csuu)*100)*(parseFloat(cgnt)*100))/10000)*-1);
          // bak = Math.round((((parseFloat(csuu)*100)*(parseFloat(cbtm)*100))/10000)*-1);
          gnk = Math.round((csuu.mul(cgnt).toNumber())*-1);
          bak = Math.round((csuu.mul(cbtm).toNumber())*-1);
          gnk = gnk * -1;
          bak = bak * -1;
        }
        setItitemval(String(itGNT||0),rowNo,'gnt');
        //マイナス処理
        if (csuu < 0){
          setItitemclr('#FF0000',rowNo,'gnkkinfclr');
          setItitemclr('#FF0000',rowNo,'bakkinfclr');
        }
        setItitemval(gnk.toLocaleString(),rowNo,'gnkkin');
        if (itBTN!=''){
          setItitemval(bak.toLocaleString(),rowNo,'bakkin');
        }
      }
    }

    if(state.KSACDES !== '005'){
      // 商品コードが未入力ならエラー
      if (itGNT != '' && itSHNCDE === ''){
          setAlert('商品コードを入力して下さい。');//TCGE0026：
          setItitemclr('#f59d9d',rowNo,'shncde');
          setChkSHNCDE(rowNo,2);
      }
    }
    return mvFlg;
  }

  /**
   * 明細部入力 売単価
   * @param rowNo 明細行
   * @returns 0
   */
  function onKeyDown_BTN(rowNo){
    let itSHNCDE = getItitemval(rowNo,'shncde');
    let itSUU = getItitemval(rowNo,'suu');
    let itGNT = getItitemval(rowNo,'gnt');
    let itBTN = getItitemval(rowNo,'btn');
    let mvFlg = 0;

    // 空白行チェック 2023/10/25
    confirmed_rows(rowNo);

    //setAlert('　');
    setItitemclr('#FFFFFF',rowNo,'btn');
    if (itSUU!='' && itBTN!=''){
      const csuu = new Decimal(String(itSUU).replaceAll(/,/g, ''));
      const cbtm = new Decimal(String(itBTN).replaceAll(/,/g, ''));
      // console.log('数量:'+csuu+'/'+csuu.length);
      let m_flg = 0;  //マイナス判定用（マイナスだとroundでは四捨五入されない）
      if (denpyoKubunRef.current?.getValue() == '02' || denpyoKubunRef.current?.getValue() == '03' || denpyoKubunRef.current?.getValue() == '05' || denpyoKubunRef.current?.getValue() == '06' || denpyoKubunRef.current?.getValue() == '09' ||denpyoKubunRef.current?.getValue() == '22'){
        m_flg = 1
      } 
      let bak;    
      if (m_flg == 0)
      {
        // bak = Math.round(parseFloat(csuu)*parseFloat(cbtm));
        // bak = Math.round(((parseFloat(csuu)*100)*(parseFloat(cbtm)*100))/10000);
        bak = Math.round(csuu.mul(cbtm).toNumber());
      }else{
        // bak = Math.round(parseFloat(csuu)*parseFloat(cbtm)*-1);
        // bak = Math.round((((parseFloat(csuu)*100)*(parseFloat(cbtm)*100))/10000)*-1);
        bak = Math.round(csuu.mul(cbtm).toNumber()*-1);
        bak = bak * -1;
      }
      if (bak == -0)
      {
        bak = 0;
      }
      //マイナス処理
      if (csuu < 0){
        setItitemclr('#FF0000',rowNo,'bakkinfclr');
      }
      setItitemval(bak.toLocaleString(),rowNo,'bakkin');
    }
    if (itSUU!='' && itBTN==''){
      setItitemval('0',rowNo,'btn');
      setItitemval('0',rowNo,'bakkin');
    }
    if (itGNKKNRFLG==0){
      const cbtm = new Decimal(Number(String(itBTN).replaceAll(/,/g, '')));
      const cgnt = new Decimal(Number(String(itGNT).replaceAll(/,/g, '')));
      const cbtn = new Decimal(cbtm.div(cgnt).toNumber()) // cbtm/cgnt;
      if (cbtm.toNumber()==0 || cbtn.toNumber()<0.1){
        // 2023/10/16 エラーメッセージ変更
        setAlert('売価単価入力確認');//TCGC0002：
        setItitemclr('#ffff00',rowNo,'btn');
        mvFlg = 0;
      }else if (cbtn.toNumber()>10){
        // 2023/10/16 エラーメッセージ変更
        setAlert('売価単価入力確認');//TCGC0002：
        setItitemclr('#ffff00',rowNo,'btn');
        mvFlg = 0;
      }
    }

    if (denpyoKubunRef.current?.getValue() == '03' && (itBTN!='' && itBTN!='0'))
    {
      setAlert('売価単価入力エラー');//TCGC0002：
      setItitemclr('#f59d9d',rowNo,'btn');
      mvFlg = 1;
    }else{
      setAlert('');//TCGC0002：
    }

    if(state.KSACDES !== '005'){
      if (itBTN != '' && itSHNCDE === ''){
        setAlert('商品コードを入力して下さい。');//TCGE0026：
        setItitemclr('#f59d9d',rowNo,'shncde');
        setChkSHNCDE(rowNo,2);
      }
    }
    return mvFlg;
  }
  //#endregion

  //#region 明細部関係の処理
  /**
   * 空白行チェック
   * @param rowNo 対象行
   * @param shncdeflg true:商品コード
   * @returns
   */
  function confirmed_rows(rowNo,shncdeflg = false)
  {
    let tagrow = 0;
    let errorflg = 0;
    switch (rowNo) {
      case 1:   /** 1列目 **/
        // 1行目商品コード削除時、又は1行目全て空白時の処理
        if ((shncdeflg && shohinCode1Ref.current?.getValue() == '')
        || (shohinCode1Ref.current?.getValue() == '' && shohinSuu1Ref.current?.getValue() == '' && shohinGnt1Ref.current?.getValue() == '' && shohinBtn1Ref.current?.getValue() == ''))
        {
          // 2行目が入力済みだと1行目エラー
          if (shohinCode2Ref.current?.getValue() != '' || shohinSuu2Ref.current?.getValue() != '' || shohinGnt2Ref.current?.getValue() != '' || shohinBtn2Ref.current?.getValue() != ''){
            errorflg = 2;
            tagrow = rowNo;
            if(state.KSACDES !== '005'){
              shohinCode1Ref.current?.setCheckflg(2);
              if(isInInputConfirm){
                inSHNCDE1.current.focus();
              }
            }
          }
        }
        break;
      case 2:   /** 2列目 **/
        // 2行目商品コード削除時、又は2行目全て空白時の処理
        if ((shncdeflg && shohinCode2Ref.current?.getValue() == '')
         || (shohinCode2Ref.current?.getValue() == '' && shohinSuu2Ref.current?.getValue() == '' && shohinGnt2Ref.current?.getValue() == '' && shohinBtn2Ref.current?.getValue() == ''))
        {
          // 3行目が入力済みだと2行目エラー
          if (shohinCode3Ref.current?.getValue() != '' || shohinSuu3Ref.current?.getValue() != '' || shohinGnt3Ref.current?.getValue() != '' || shohinBtn3Ref.current?.getValue() != ''){
            errorflg = 2;
            tagrow = rowNo;
            if(state.KSACDES !== '005'){
              shohinCode2Ref.current?.setCheckflg(2);
              if(isInInputConfirm){
                inSHNCDE2.current.focus();
              }
            }else{
              if(isInInputConfirm){
                inSUU2.current.focus();
              }
            }
          // 1行目が未入力だと、1行目のエラーをクリア
          } else if(shohinCode1Ref.current?.getValue() == '' && shohinSuu1Ref.current?.getValue() == '' && shohinGnt1Ref.current?.getValue() == '' && shohinBtn1Ref.current?.getValue() == ''){
            clearRow(1);
          }
        }
        // 2行目入力あり、1行目全て空白時エラー
        else if(shohinCode1Ref.current?.getValue() == '' && shohinSuu1Ref.current?.getValue() == '' && shohinGnt1Ref.current?.getValue() == '' && shohinBtn1Ref.current?.getValue() == ''){
          errorflg = 1;
          tagrow = rowNo - 1;
          if(state.KSACDES !== '005'){
            shohinCode1Ref.current?.setCheckflg(2);
            if(isInInputConfirm){
              inSHNCDE1.current.focus();
            }
          }else{
            if(isInInputConfirm){
              inSUU1.current.focus();
            }
          }
        }
        break;
      case 3:   /** 3列目 **/
        // 3行目商品コード削除時、又は3行目全て空白時の処理
        if ((shncdeflg && shohinCode3Ref.current?.getValue() == '')
                 ||(shohinCode3Ref.current?.getValue() == '' && shohinSuu3Ref.current?.getValue() == '' && shohinGnt3Ref.current?.getValue() == '' && shohinBtn3Ref.current?.getValue() == ''))
        {
          // 4行目が入力済みだと3行目エラー
          if (shohinCode4Ref.current?.getValue() != '' || shohinSuu4Ref.current?.getValue() != '' || shohinGnt4Ref.current?.getValue() != '' || shohinBtn4Ref.current?.getValue() != ''){
            errorflg = 2;
            tagrow = rowNo;
            if(state.KSACDES !== '005'){
              shohinCode3Ref.current?.setCheckflg(2);
              if(isInInputConfirm){
                inSHNCDE3.current.focus();
              }
            }else{
              if(isInInputConfirm){
                inSUU3.current.focus();
              }
            }
          // 2行目が未入力だと、2行目のエラーをクリア
          } else if(shohinCode2Ref.current?.getValue() == '' && shohinSuu2Ref.current?.getValue() == '' && shohinGnt2Ref.current?.getValue() == '' && shohinBtn2Ref.current?.getValue() == ''){
            clearRow(2);
          }
        }
        // 3行目入力あり、2行目全て空白時エラー
        else if(shohinCode2Ref.current?.getValue() == '' && shohinSuu2Ref.current?.getValue() == '' && shohinGnt2Ref.current?.getValue() == '' && shohinBtn2Ref.current?.getValue() == ''){
          errorflg = 1;
          tagrow = rowNo - 1;
          if(state.KSACDES !== '005'){
            shohinCode2Ref.current?.setCheckflg(2);
            if(isInInputConfirm){
              inSHNCDE2.current.focus();
            }
          }else{
            if(isInInputConfirm){
              inSUU2.current.focus();
            }
          }
        }
        break;
      case 4:   /** 4列目 **/
        // 4行目商品コード削除時、又は4行目全て空白時の処理
        if ((shncdeflg && shohinCode4Ref.current?.getValue() == '')
                 ||(shohinCode4Ref.current?.getValue() == '' && shohinSuu4Ref.current?.getValue() == '' && shohinGnt4Ref.current?.getValue() == '' && shohinBtn4Ref.current?.getValue() == ''))
        {
          // 5行目が入力済みだと4行目エラー
          if (shohinCode5Ref.current?.getValue() != '' || shohinSuu5Ref.current?.getValue() != '' || shohinGnt5Ref.current?.getValue() != '' || shohinBtn5Ref.current?.getValue() != ''){
            errorflg = 2;
            tagrow = rowNo;
            if(state.KSACDES !== '005'){
              shohinCode4Ref.current?.setCheckflg(2);
              if(isInInputConfirm){
                inSHNCDE4.current.focus();
              }
            }else{
              if(isInInputConfirm){
                inSUU4.current.focus();
              }
            }
          // 3行目が未入力だと、3行目のエラーをクリア
          } else if(shohinCode3Ref.current?.getValue() == '' && shohinSuu3Ref.current?.getValue() == '' && shohinGnt3Ref.current?.getValue() == '' && shohinBtn3Ref.current?.getValue() == ''){
            clearRow(3);
          }
        }
        // 4行目入力あり、3行目全て空白時エラー
        else if(shohinCode3Ref.current?.getValue() == '' && shohinSuu3Ref.current?.getValue() == '' && shohinGnt3Ref.current?.getValue() == '' && shohinBtn3Ref.current?.getValue() == ''){
          errorflg = 1;
          tagrow = rowNo - 1;
          if(state.KSACDES !== '005'){
            shohinCode3Ref.current?.setCheckflg(2);
            if(isInInputConfirm){
              inSHNCDE3.current.focus();
            }
          }else{
            if(isInInputConfirm){
              inSUU3.current.focus();
            }
          }
        }
        break;
      case 5:   /** 5列目 **/
        // 5行目商品コード削除時、又は5行目全て空白時の処理
        if ((shncdeflg && shohinCode5Ref.current?.getValue() == '')
                 ||(shohinCode5Ref.current?.getValue() == '' && shohinSuu5Ref.current?.getValue() == '' && shohinGnt5Ref.current?.getValue() == '' && shohinBtn5Ref.current?.getValue() == ''))
        {
          // 6行目が入力済みだと5行目エラー
          if (shohinCode6Ref.current?.getValue() != '' || shohinSuu6Ref.current?.getValue() != '' || shohinGnt6Ref.current?.getValue() != '' || shohinBtn6Ref.current?.getValue() != ''){
            errorflg = 2;
            tagrow = rowNo;
            if(state.KSACDES !== '005'){
              shohinCode5Ref.current?.setCheckflg(2);
              if(isInInputConfirm){
                inSHNCDE5.current.focus();
              }
            }else{
              if(isInInputConfirm){
                inSUU5.current.focus();
              }
            }
          // 4行目が未入力だと、4行目のエラーをクリア
          } else if(shohinCode4Ref.current?.getValue() == '' && shohinSuu4Ref.current?.getValue() == '' && shohinGnt4Ref.current?.getValue() == '' && shohinBtn4Ref.current?.getValue() == ''){
            clearRow(4);
          }
        }
        // 5行目入力あり、4行目全て空白時エラー
        else if(shohinCode4Ref.current?.getValue() == '' && shohinSuu4Ref.current?.getValue() == '' && shohinGnt4Ref.current?.getValue() == '' && shohinBtn4Ref.current?.getValue() == ''){
          errorflg = 1;
          tagrow = rowNo - 1;
          if(state.KSACDES !== '005'){
            shohinCode4Ref.current?.setCheckflg(2);
            if(isInInputConfirm){
              inSHNCDE4.current.focus();
            }
          }else{
            if(isInInputConfirm){
              inSUU4.current.focus();
            }
          }
        }
        break;
      case 6:   /** 6列目 **/
        // 6行目商品コード削除時、又は6行目全て空白時の処理
        if ((shncdeflg && shohinCode6Ref.current?.getValue() == '')
          ||(shohinCode6Ref.current?.getValue() == '' && shohinSuu6Ref.current?.getValue() == '' && shohinGnt6Ref.current?.getValue() == '' && shohinBtn6Ref.current?.getValue() == ''))
        {
          // 5行目が未入力だと、5行目のエラーをクリア
          if(shohinCode5Ref.current?.getValue() == '' && shohinSuu5Ref.current?.getValue() == '' && shohinGnt5Ref.current?.getValue() == '' && shohinBtn5Ref.current?.getValue() == ''){
            clearRow(5);
          }
        }
        // 6行目入力あり、5行目全て空白時エラー
        else if(shohinCode5Ref.current?.getValue() == '' && shohinSuu5Ref.current?.getValue() == '' && shohinGnt5Ref.current?.getValue() == '' && shohinBtn5Ref.current?.getValue() == ''){
          errorflg = 1;
          tagrow = rowNo - 1;
          if(state.KSACDES !== '005'){
            shohinCode5Ref.current?.setCheckflg(2);
            if(isInInputConfirm){
              inSHNCDE5.current.focus();
            }
          }else{
            if(isInInputConfirm){
              inSUU5.current.focus();
            }
          }
        }
        break;
      default:
        break;
    }

    if (errorflg != 0){

      setAlert('明細行を詰めて入力してください。');
      if(state.KSACDES !== '005'){
        setItitemclr('#f59d9d',tagrow,'shncde');
      }
      setItitemclr('#f59d9d',tagrow,'suu');
      setItitemclr('#f59d9d',tagrow,'gnt');
      setItitemclr('#f59d9d',tagrow,'btn');
    }
    return errorflg;
  }

  /**
   * 行クリア処理
   * @param rowNo 対象行
   */
  const clearRow = (rowNo) => {
    setItitemval('', rowNo, 'shncde');
    setItitemval('', rowNo, 'suu');
    setItitemval('', rowNo, 'gnt');
    setItitemval('', rowNo, 'btn');
    setItitemval('', rowNo, 'gnkkin');
    setItitemval('', rowNo, 'bakkin');
    setItitemclr('#FFFFFF', rowNo, 'shncde');
    setItitemclr('#FFFFFF', rowNo, 'suu');
    setItitemclr('#FFFFFF', rowNo, 'gnt');
    setItitemclr('#FFFFFF', rowNo, 'btn');
    setItitemclr('#FFFFFF', rowNo, 'gnkkin');
    setItitemclr('#FFFFFF', rowNo, 'bakkin');
    setChkSHNCDE(rowNo,0);
  }

  useEffect(() => {
    // 下記の処理でblurTableflgを変更した際に
    // 重複して処理が走らないようにする為にtrueチェック
    // if(blurTableflg){

      let type = getInputType(document.activeElement.id);
      // if(type != 2){
      //   pageup時はヘッダ情報に遷移し、チェックは行わない
      //   if (blurkey!='PageUp')
      //   {
      //     onBlurTable();
      //   }
      // }
      // ※押下ボタンの判定だと、クリア時にエラーとなる
      if(type != 1 && type != 2){
        onBlurTable();
      }
    // }
  }, [blurTableflg]);

  /**
   * 明細からフォーカスが外れた際の処理
   * @returns
   */
  const onBlurTable = async()=>{
    let chkSHNCDE = [
      0,
      shohinCode1Ref.current?.getCheckflg(),
      shohinCode2Ref.current?.getCheckflg(),
      shohinCode3Ref.current?.getCheckflg(),
      shohinCode4Ref.current?.getCheckflg(),
      shohinCode5Ref.current?.getCheckflg(),
      shohinCode6Ref.current?.getCheckflg(),
    ];
    let inSHNCDE = [0,inSHNCDE1,inSHNCDE2,inSHNCDE3,inSHNCDE4,inSHNCDE5,inSHNCDE6];
    let inSUU = [0,inSUU1,inSUU2,inSUU3,inSUU4,inSUU5,inSUU6];
    let inBTN = [0,inBTN1,inBTN2,inBTN3,inBTN4,inBTN5,inBTN6];
    let clear_row = 7;

    // パーティーハウスの場合
    if(state.KSACDES === '005'){
      // 1行目の数量は必須
      if (shohinSuu1Ref.current?.getValue() == '' || shohinSuu1Ref.current?.getValue() == 0){
        setAlert('数量を入力して下さい');
        setItitemclr('#f59d9d', 1, 'suu');
        inSUU1.current.focus();
        return;
      }

      // 数量が空白ならクリア
      for(let i = 6; i > 0; i--){
        // console.log('i:%o',i);
        let itSUU = getItitemval(i,'suu');
        if(itSUU == ''){
          clearRow(i);
          clear_row = i;
        }else{
          break;
        }
      }
    }

    // パーティーハウス以外の場合
    else{
      // 1行目の商品コードは必須
      if (shohinCode1Ref.current?.getValue() == ''){
        shohinCode1Ref.current?.setCheckflg(2);
        setAlert('商品コードを入力して下さい。');//TCGE0026：
        setItitemclr('#f59d9d', 1, 'shncde');
        inSHNCDE1.current.focus();
        return;
      }

      // 商品コードが空白ならクリア
      for(let i = 6; i > 0; i--){
        //console.log('i:%o',i);
        let itSHNCDE = getItitemval(i,'shncde');
        if(itSHNCDE == ''){
          clearRow(i);
          clear_row = i;
        }else{
          break;
        }
      }
    }

    let ret = 0;

    // クリアされていなければ処理
    // （廃止）※本来は1行目がクリアされていればエラーだが、明細から抜ける際はエラーにしない
    for(let i = 1; i <= 6; i++){
      if(clear_row > i){
        // 商品コード
        if(chkSHNCDE[i] === 2) {
          inSHNCDE[i].current.focus();
          setAlert('商品コード入力エラー');//TCGE0026：
          return;
        }
        let itSHNCDE = getItitemval(i,'shncde');
        if(state.KSACDES !== '005' && itSHNCDE === ''){
          setChkSHNCDE(i,2);
          setAlert('商品コードを入力して下さい。');//TCGE0026：
          setItitemclr('#f59d9d', i, 'shncde');
          inSHNCDE[i].current.focus();
          return;
        }

        // 数量
        ret = onKeyDown_SUU(i, 1);
        if (ret == 1){
          inSUU[i].current.focus();
          return;
        }else if(ret == 2){
          setAlert('数量を入力して下さい');
          setItitemclr('#f59d9d', i, 'suu');
          inSUU[i].current.focus();
          return;
        }

        // 原単価
        ret = onKeyDown_GNT(i);
        if (ret == 1){
          inSUU[i].current.focus();
          return;
        }

        // 売単価
        ret = onKeyDown_BTN(i);
        if (ret == 1){
          inBTN[i].current.focus();
          return;
        }
      }
    }

    setAlert('　');
  };
  //#endregion

  //#region フッタ部入力
  // フッタ部入力 原価金額合計
  const onKeyDown_GNKFOT = async()=>{
    let mvFlg = 0;
    //setAlert('　');
    GNKFOTRef.current?.setBGColor('#FFFFFF');
    let k_king = Number(String(shohinGnkkin1Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinGnkkin2Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinGnkkin3Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinGnkkin4Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinGnkkin5Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinGnkkin6Ref.current?.getValue()||0).replaceAll(',',''));
    // console.log('計算:'+Number(String(GNKFOTRef.current?.getValue()||0).replaceAll(',',''))+'|'+k_king);
    if (Number(String(GNKFOTRef.current?.getValue()||0).replaceAll(',','')) != k_king){
      setAlert('原価金額合計入力エラー');//TCGE0021：
      GNKFOTRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
    }else{
      setAlert('　');
    }
    return mvFlg;
  }

  // フッタ部入力 売価金額合計
  const onKeyDown_BAKFOT = async()=>{
    let mvFlg = 0;
    //setAlert('　');
    BAKFOTRef.current?.setBGColor('#FFFFFF');
    let k_king = Number(String(shohinBakkin1Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinBakkin2Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinBakkin3Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinBakkin4Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinBakkin5Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinBakkin6Ref.current?.getValue()||0).replaceAll(',',''));
    // console.log('計算:'+Number(String(GNKFOTRef.current?.getValue()||0).replaceAll(',',''))+'|'+k_king);
    if (Number(String(BAKFOTRef.current?.getValue()||0).replaceAll(',','')) != k_king){
      setAlert('売価金額合計入力エラー');//TCGE0022：
      BAKFOTRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
    }else{
      setAlert('　');
      if (Number(String(BAKFOTRef.current?.getValue()||0).replaceAll(',','')) ===0){
        BAKFOTRef.current?.setValue(0);
      }

      if (disdeng === true){
        if (!isInInputConfirm) {
          inRET.current.focus();
          mvFlg = 2;
        }
      }
    }
    return mvFlg;
  }

  // フッタ部入力 訂正後原価金額合計
  const onKeyDown_TSEGNKFOT = async()=>{
    let mvFlg = 0;
    //setAlert('　');
    TSEGNKFOTRef.current?.setBGColor('#FFFFFF');
    let k_king = Number(String(shohinGnkkin1Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinGnkkin2Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinGnkkin3Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinGnkkin4Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinGnkkin5Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinGnkkin6Ref.current?.getValue()||0).replaceAll(',',''));
    if (Number(String(TSEGNKFOTRef.current?.getValue()||0).replaceAll(',','')) != k_king){
      setAlert('原価金額合計入力エラー');//TCGE0021：
      TSEGNKFOTRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
    }else{
      setAlert('　');
    }
    return mvFlg;
  }

  // フッタ部入力 訂正後売価金額合計
  const onKeyDown_TSEBAKFOT = async()=>{
    let mvFlg = 0;
    //setAlert('　');
    TSEBAKFOTRef.current?.setBGColor('#FFFFFF');
    let k_king = Number(String(shohinBakkin1Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinBakkin2Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinBakkin3Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinBakkin4Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinBakkin5Ref.current?.getValue()||0).replaceAll(',',''))+Number(String(shohinBakkin6Ref.current?.getValue()||0).replaceAll(',',''));
    // console.log('計算:'+Number(String(GNKFOTRef.current?.getValue()||0).replaceAll(',',''))+'|'+k_king);
    if (Number(String(TSEBAKFOTRef.current?.getValue()||0).replaceAll(',','')) != k_king){
      setAlert('売価金額合計入力エラー');//TCGE0022：
      TSEBAKFOTRef.current?.setBGColor('#f59d9d');
      mvFlg = 1;
    }else{
      setAlert('　');
      if (Number(String(TSEBAKFOTRef.current?.getValue()||0).replaceAll(',','')) ===0){
        TSEBAKFOTRef.current?.setValue(0);
      }

      if (disdeng === true){
        inRET.current.focus();
        mvFlg = 2;
      }
    }
    return mvFlg;
  }

  // フッタ部入力 伝票G欄チェック
  const onKeyDown_DENG = async()=>{
    let mvFlg = 0;
    denGRef.current?.setBGColor('#ffffff');
    //setAlert('　');
    let dt1 = new Date('20'+denpyoYMDRef.current?.getValue());  // ヘッダ年月日
    let dt2 = new Date('2023/10/01');   // インボイス開始日（2023/10/01）
    if (dt1 >= dt2){
      if (denGRef.current?.getValue()==''){
        denGRef.current?.setBGColor('#f59d9d');
        setAlert('日付入力エラー');//TCGE0016:
        mvFlg = 1;
        return  mvFlg;
      }
    }
    if (denGRef.current?.getValue()!=''){
      var strDate = '20'+denGRef.current?.getValue();
      var dtdate = new Date(strDate);
      if (ckDate(strDate)===true){
        // 2023/10/16 返品時の日付チェック追加
        if(denpyoKubunRef.current?.getValue() === '02' || denpyoKubunRef.current?.getValue() === '03' || denpyoKubunRef.current?.getValue() === '22'){
          if (dtdate > dt1){
            denGRef.current?.setBGColor('#f59d9d');
            setAlert('日付入力エラー');//TCGE0016:
            mvFlg = 1;
            return mvFlg;
          }
        }else{
          let dt = new Date(state.SDAY);
          let dte = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate());
          if (dtdate > dte){
            denGRef.current?.setBGColor('#f59d9d');
            setAlert('日付入力エラー');//TCGE0016:
            mvFlg = 1;
            return mvFlg;
          }
        }
      }else{
        denGRef.current?.setBGColor('#f59d9d');
        setAlert('日付入力エラー');//TCGE0016:
        mvFlg = 1;
        return mvFlg;
      }
    }
    setAlert('　');
    return mvFlg;
  }
  //#endregion

  //#region 登録処理
  // 商品コードの処理終了後に再処理
  useEffect (()=>{
    if(chkflg2 === 1){
      onKeyDown('Enter',inRET,'',inRET);
    }
    setChkflg(0);
  },[chkflg]);

  // 最終チェック1
  const InputConfirmed = async()=>{
    let ret = 0;
    // 商品コードの処理が途中なら一旦処理を中段し、確認が終った後に再処理
    if(shohinCode1Ref.current?.getCheckflg() === 9 || shohinCode2Ref.current?.getCheckflg() === 9 || shohinCode3Ref.current?.getCheckflg() === 9
      || shohinCode4Ref.current?.getCheckflg() === 9 || shohinCode5Ref.current?.getCheckflg() === 9 || shohinCode6Ref.current?.getCheckflg() === 9){
      setChkflg2(1);
      return 1;
    }
    setChkflg2(0);

    setAlert('　');
    // console.log('ヘッダー項目チェック開始');
    // 必ず入力する項目
    // 区分設定
    if (kubunRef.current?.getCheckflg() !== 0)   //2023/11/09 チェック済みはスルー
    {
      ret = onKeyDown_KBN(1);
      if (ret === 1){
        inKBN.current.focus();
        return 1;
      }
    }

    // 伝票No.
    if (denpyoNoRef.current?.getCheckflg() !== 0)  //2023/11/09 チェック済みはスルー
    {
      ret = onKeyDown_DENNO();
      if (ret === 1 || ret === 2){
        inDENNO.current.focus();
        return 1;
      }
    }

    // 区分1の入力項目
    if(kubunRef.current?.getValue() === '1' || kubunRef.current?.getValue() === '2' || kubunRef.current?.getValue() === '3'){
      // 取引先CD
      if (sireCodeRef.current?.getCheckflg() !== 0)  //2023/11/09 チェック済みはスルー
      {
        ret = await onKeyDown_SIRCDE();
        if (ret === 1 || ret === 2){
          inSIRCDE.current.focus();
          return 1;
        }
      }

      // 税率
      if (zeirituRef.current?.getCheckflg() !== 0)  //2023/11/09 チェック済みはスルー
      {
        ret = await onKeyDown_ZEIRTU();
        if (ret === 1 || ret === 2){
          inZEIRTU.current.focus();
          return 1;
        }
      }

      // 税区分
      if (zeiKubunRef.current?.getCheckflg() !== 0)  //2023/11/09 チェック済みはスルー
      {
        ret = await onKeyDown_ZEIKBN(1);
        if (ret === 1){
          inZEIKBN.current.focus();
          return 1;
        }
      }

      // 社店CD
      if (syaMiseRef.current?.getCheckflg() !== 0)  //2023/11/09 チェック済みはスルー
      {
        ret = await onKeyDown_SMISE();
        if (ret === 1){
          inSMISE.current.focus();
          return 1;
        }
      }

      // 分類CD
      if (bunruiCodeRef.current?.getCheckflg() !== 0)  //2023/11/09 チェック済みはスルー
      {
        ret = await onKeyDown_BMNCDE();
        if (ret === 1){
          inBMNCDE.current.focus();
          return 1;
        } else if (ret === 2) {
          return 1;
        }
      }

      // 伝票区分
      if (denpyoKubunRef.current?.getCheckflg() !== 0)  //2023/11/09 チェック済みはスルー
      {
        ret = await onKeyDown_DENKBN();
        if  (ret === 1|| ret === 2){
          inDENKBN.current.focus();
          return 1;
        }
      }

      // 年月日
      if (denpyoYMDRef.current?.getCheckflg() !== 0)    //2023/11/09 チェック済みはスルー
      {
        ret = onKeyDown_DENYMD();
        if  (ret === 1){
          inDENYMD.current.focus();
          return 1;
        }
      }

      // 年月日
      if (binRef.current?.getCheckflg() !== 0)    //2023/11/09 チェック済みはスルー
      {
        ret = onKeyDown_BIN();
        if  (ret === 1){
          inBIN.current.focus();
          return 1;
        }
      }

      // console.log('明細項目チェック開始');
      {
        // 商品コード1
        if(state.KSACDES !== '005'){
          if(shohinCode1Ref.current?.getCheckflg() === 2 || shohinCode1Ref.current?.getValue() === '') {
            setAlert('商品コード入力エラー');//TCGE0026：
            setItitemclr('#f59d9d',1,'shncde');
            inSHNCDE1.current.focus();
            return 1;
          }
          if(shohinCode1Ref.current?.getCheckflg() === 1){
            if(inInputConfirm_SHNCDE(1,1) == 0){
              ret = await onKeyDown_SHNCDE(1);
            }
            if (ret === 1){
              inSHNCDE1.current.focus();
              return 1;
            }
          }
        }

        // 数量1
        ret = onKeyDown_SUU(1, 1);
        if (ret === 1){
          inSUU1.current.focus();
           return 1;
        }

        // 原単価1
        ret = onKeyDown_GNT(1);
        if (ret === 1){
          inGNT1.current.focus();
          return 1;
        }

        // 売単価1
        ret = onKeyDown_BTN(1);
        if (ret === 1){
          inBTN1.current.focus();
          return 1;
        }
      }

      if(shohinCode2Ref.current?.getValue() != '' || shohinSuu2Ref.current?.getValue()!= '' || shohinGnt2Ref.current?.getValue()!= '' || shohinBtn2Ref.current?.getValue()!= '' ){
        // 商品コード2
        if(state.KSACDES !== '005'){
          if(shohinCode2Ref.current?.getCheckflg() === 2 ||  shohinCode2Ref.current?.getValue() === '') {
            setAlert('商品コード入力エラー');//TCGE0026：
            setItitemclr('#f59d9d',2,'shncde');
            inSHNCDE2.current.focus();
            return 1;
          }
          if(shohinCode2Ref.current?.getCheckflg() === 1){
            if(inInputConfirm_SHNCDE(2,1) == 0){
              ret = await onKeyDown_SHNCDE(2);
            }
            if (ret === 1){
              inSHNCDE2.current.focus();
              return 1;
            }
          }
        }

        // 数量2
        ret = onKeyDown_SUU(2, 1);
        if (ret === 1){
          inSUU2.current.focus();
          return 1;
        }

        // 原単価2
        ret = onKeyDown_GNT(2);
        if (ret === 1){
          inGNT2.current.focus();
          return 1;
        }

        // 売単価2
        ret = onKeyDown_BTN(2);
        if (ret === 1){
          inBTN2.current.focus();
          return 1;
        }
      }

      if(shohinCode3Ref.current?.getValue() != '' || shohinSuu3Ref.current?.getValue()!= '' || shohinGnt3Ref.current?.getValue()!= '' || shohinBtn3Ref.current?.getValue()!= '' ){
        // 商品コード3
        if(state.KSACDES !== '005'){
          if(shohinCode3Ref.current?.getCheckflg() === 2 ||  shohinCode3Ref.current?.getValue() === '') {
            setAlert('商品コード入力エラー');//TCGE0026：
            setItitemclr('#f59d9d',3,'shncde');
            inSHNCDE3.current.focus();
            return 1;
          }
          if(shohinCode3Ref.current?.getCheckflg() === 1){
            if(inInputConfirm_SHNCDE(3,1) == 0){
              ret = await onKeyDown_SHNCDE(3);
            }
            if (ret === 1){
              inSHNCDE3.current.focus();
              return 1;
            }
          }
        }

        // 数量3
        ret = onKeyDown_SUU(3, 1);
        if (ret === 1){
          inSUU3.current.focus();
          return 1;
        }

        // 原単価3
        ret = onKeyDown_GNT(3);
        if (ret === 1){
          inGNT3.current.focus();
          return 1;
        }

        // 売単価3
        ret = onKeyDown_BTN(3);
        if (ret === 1){
          inBTN3.current.focus();
          return 1;
        }
      }

      if(shohinCode4Ref.current?.getValue() != '' || shohinSuu4Ref.current?.getValue()!= '' || shohinGnt4Ref.current?.getValue()!= '' || shohinBtn4Ref.current?.getValue()!= '' ){
        // 商品コード4
        if(state.KSACDES !== '005'){
          if(shohinCode4Ref.current?.getCheckflg() === 2 ||  shohinCode4Ref.current?.getValue() === '') {
            setAlert('商品コード入力エラー');//TCGE0026：
            setItitemclr('#f59d9d',4,'shncde');
            inSHNCDE4.current.focus();
            return 1;
          }
          if(shohinCode4Ref.current?.getCheckflg() === 1){
            if(inInputConfirm_SHNCDE(4,1) == 0){
              ret = await onKeyDown_SHNCDE(4);
            }
            if (ret === 1){
              inSHNCDE4.current.focus();
              return 1;
            }
          }
        }

        // 数量4
        ret = onKeyDown_SUU(4, 1);
        if (ret === 1){
          inSUU4.current.focus();
          return 1;
        }

        // 原単価4
        ret = onKeyDown_GNT(4);
        if (ret === 1){
          inGNT4.current.focus();
          return 1;
        }

        // 売単価4
        ret = onKeyDown_BTN(4);
        if (ret === 1){
          inBTN4.current.focus();
          return 1;
        }
      }

      if(shohinCode5Ref.current?.getValue() != '' || shohinSuu5Ref.current?.getValue()!= '' || shohinGnt5Ref.current?.getValue()!= '' || shohinBtn5Ref.current?.getValue()!= '' ){
        // 商品コード5
        if(state.KSACDES !== '005'){
          if(shohinCode5Ref.current?.getCheckflg() === 2 ||  shohinCode5Ref.current?.getValue() === '') {
            setAlert('商品コード入力エラー');//TCGE0026：
            setItitemclr('#f59d9d',5,'shncde');
            inSHNCDE5.current.focus();
            return 1;
          }
          if(shohinCode5Ref.current?.getCheckflg() === 1){
            if(inInputConfirm_SHNCDE(5,1) == 0){
              ret = await onKeyDown_SHNCDE(5);
            }
            if (ret === 1){
              inSHNCDE5.current.focus();
              return 1;
            }
          }
        }

        // 数量5
        ret = onKeyDown_SUU(5, 1);
        if (ret === 1){
          inSUU5.current.focus();
          return 1;
        }

        // 原単価5
        ret = onKeyDown_GNT(5);
        if (ret === 1){
          inGNT5.current.focus();
          return 1;
        }

        // 売単価5
        ret = onKeyDown_BTN(5);
        if (ret === 1){
          inBTN5.current.focus();
          return 1;
        }
      }

      if(shohinCode6Ref.current?.getValue() != '' || shohinSuu6Ref.current?.getValue()!= '' || shohinGnt6Ref.current?.getValue()!= '' || shohinBtn6Ref.current?.getValue()!= '' ){
        // 商品コード6
        if(state.KSACDES !== '005'){
          if(shohinCode6Ref.current?.getCheckflg() === 2 ||  shohinCode6Ref.current?.getValue() === '') {
            setAlert('商品コード入力エラー');//TCGE0026：
            setItitemclr('#f59d9d',6,'shncde');
            inSHNCDE6.current.focus();
            return 1;
          }
          if(shohinCode6Ref.current?.getCheckflg() === 1){
            if(inInputConfirm_SHNCDE(6,1) == 0){
              ret = await onKeyDown_SHNCDE(6);
            }

            if (ret === 1){
              inSHNCDE6.current.focus();
              return 1;
            }
          }
        }

        // 数量6
        ret = onKeyDown_SUU(6, 1);
        if (ret === 1){
          inSUU6.current.focus();
          return 1;
        }

        // 原単価6
        ret = onKeyDown_GNT(6);
        if (ret === 1){
          inGNT6.current.focus();
          return 1;
        }

        // 売単価6
        ret = onKeyDown_BTN(6);
        if (ret === 1){
          inBTN6.current.focus();
          return 1;
        }
      }
    }

    // 一度処理を終了しないと、明細の合計値が反映されない為、フッダ確認時にエラーとなる。
    // 処理を中段し、最終チェック確認後処理を呼び出す。
    setInputConfirmflg(1);
  }

  // 最終チェック2
  const InputConfirmed2 =  async()=>{
    let ret = 0;

    // console.log('フッダ項目チェック開始');
    // 区分1の入力項目
    if(kubunRef.current?.getValue() === '1'){
      // 原価金額合計
      ret = await onKeyDown_GNKFOT();
      if (ret === 1){
        inGNKFOT.current.focus();
        return 1;
       }

      // 売価金額合計
      ret = await onKeyDown_BAKFOT();
      if (ret === 1){
        inBAKFOT.current.focus();
        return 1;
      }
    }

    // 区分2の入力項目
    if(kubunRef.current?.getValue() === '2'){
      // 訂正後原価金額合計
      ret = await onKeyDown_TSEGNKFOT();
      if (ret === 1){
        inTSEGNKFOT.current.focus();
        return 1;
      }

      // 訂正後売価金額合計
      ret = await onKeyDown_TSEBAKFOT();
      if (ret === 1){
        inTSEBAKFOT.current.focus();
        return 1;
      }
    }

    // 区分1 or 2の入力項目
    if(kubunRef.current?.getValue() === '1' || kubunRef.current?.getValue() === '2'){
      if (!disdeng) {
        // 伝票G欄チェック
        ret = await onKeyDown_DENG();
        if (ret === 1){
          inDENG.current.focus();
          return 1;
        }
      }
    }
    setInputConfirmflg(9);
    return 0;
  }

  // 最終チェック確認後処理
  useEffect (()=>{
    if(inputConfirmflg == 1){
      setInputConfirmflg(0);
      // 最終チェック1正常終了時、最終チェック2(InputConfirmed2)を呼び出す。
      InputConfirmed2();
    }else if(inputConfirmflg !== 0){
      // 最終チェック2正常終了時、DB更新処理を呼び出す。
      register();
      setInputConfirmflg(0);
    }
  },[inputConfirmflg]);

  // 確認後DB更新処理
  const register =  async()=>{
    let mvFlg = 0;
    if(kubunRef.current?.getValue() === '1') {
      mvFlg = await onKeyDown_Insert();   // 新規
    } else if(kubunRef.current?.getValue() === '2') {
      mvFlg = await onKeyDown_Update();   // 更新
    } else {
      mvFlg = await onKeyDown_Delete();   // 削除
    }

    switch (mvFlg) {
      case 0:
      case 1:
        inRET.current.focus();   //現在の項目に遷移
        break;
      case 2:
      case 9:
      default:
        break;
    }
  }

  // 登録処理 登録処理
  const onKeyDown_Insert = async()=>{
    setAlert('　');

    let mvFlg = 0;
    // console.log('JSON:%o',state.JGNCDE);
    const csuu = shohinSuu1Ref.current?.getValue()||0+shohinSuu2Ref.current?.getValue()||0+shohinSuu3Ref.current?.getValue()||0+shohinSuu4Ref.current?.getValue()||0+shohinSuu5Ref.current?.getValue()||0+shohinSuu6Ref.current?.getValue()||0
    let Tgitem = '';
    if (denGRef.current?.getValue() !== ''){Tgitem = '20'+denGRef.current?.getValue().replaceAll('/','')}
    const responseUA1 = await fetch('/TCSA0130/SL_HED_DAT_ins',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ENT_KSACDE: cent_ksacde,ENT_MISE:cent_mise,DENNO: denpyoNoRef.current?.getValue(),
      SIRCDE: sireCodeRef.current?.getValue(),SIRMEIK:itSIRNM,KIJYMD:'20'+(keijyoYMRef.current?.getValue().replaceAll('年','')).replaceAll('月','')+'20',KSACDE: cent_ksacde,MISE: syaMiseRef.current?.getValue(),MISEMEI: itSMISENM,
      BMNCDE: bunruiCodeRef.current?.getValue(),DENKBN: denpyoKubunRef.current?.getValue(),DENYMD: '20'+denpyoYMDRef.current?.getValue().replaceAll('/',''),ZEIKBN: zeiKubunRef.current?.getValue(),MISE: syaMiseRef.current?.getValue(),SRYFOT: csuu,
      GNKFOT: String(GNKFOTRef.current?.getValue()||0).replaceAll(',',''),BAKFOT: String(BAKFOTRef.current?.getValue()||0).replaceAll(',',''),ZEIRTU: zeirituRef.current?.getValue(),DEN_G:Tgitem,KIJYYM:'20'+(keijyoYMRef.current?.getValue().replaceAll('年','')).replaceAll('月',''),
      BIN:binRef.current?.getValue(),upd_jgncde: state.JGNCDE,upd_prg_id: 'TCSA0130',trl_id: state.MISE})});
    const dataUA1 = await responseUA1.json();
    // console.log('伝票ヘッダ:%o',dataUA1);
    // 2023/09/29 エラー表示の追加
    if (dataUA1.res === '-1') {
      setAlert('伝票データ登録エラー');//TCGE0023：
      return mvFlg;;
    }

    const responseUA2 = await fetch('/TCSA0130/SL_DTL_DAT_ins',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ENT_KSACDE: cent_ksacde,ENT_MISE: cent_mise,DENNO: denpyoNoRef.current?.getValue(),
      SIRCDE: sireCodeRef.current?.getValue(),KSACDE: cent_ksacde,MISE: syaMiseRef.current?.getValue(),
      meisai:[
      {SHNCD: shohinCode1Ref.current?.getValue(),LINENO: '1',SUU: String(shohinSuu1Ref.current?.getValue()||0).replaceAll(',',''),GNT: String(shohinGnt1Ref.current?.getValue()||0).replaceAll(',',''),GNKKIN: String(shohinGnkkin1Ref.current?.getValue()||0).replaceAll(',',''),BTN: String(shohinBtn1Ref.current?.getValue()||0).replaceAll(',',''),BAKKIN: String(shohinBakkin1Ref.current?.getValue()||0).replaceAll(',',''),ESJ_KBNNT: itEOSJANKBN1},
      {SHNCD: shohinCode2Ref.current?.getValue(),LINENO: '2',SUU: String(shohinSuu2Ref.current?.getValue()||0).replaceAll(',',''),GNT: String(shohinGnt2Ref.current?.getValue()||0).replaceAll(',',''),GNKKIN: String(shohinGnkkin2Ref.current?.getValue()||0).replaceAll(',',''),BTN: String(shohinBtn2Ref.current?.getValue()||0).replaceAll(',',''),BAKKIN: String(shohinBakkin2Ref.current?.getValue()||0).replaceAll(',',''),ESJ_KBNNT: itEOSJANKBN2},
      {SHNCD: shohinCode3Ref.current?.getValue(),LINENO: '3',SUU: String(shohinSuu3Ref.current?.getValue()||0).replaceAll(',',''),GNT: String(shohinGnt3Ref.current?.getValue()||0).replaceAll(',',''),GNKKIN: String(shohinGnkkin3Ref.current?.getValue()||0).replaceAll(',',''),BTN: String(shohinBtn3Ref.current?.getValue()||0).replaceAll(',',''),BAKKIN: String(shohinBakkin3Ref.current?.getValue()||0).replaceAll(',',''),ESJ_KBNNT: itEOSJANKBN3},
      {SHNCD: shohinCode4Ref.current?.getValue(),LINENO: '4',SUU: String(shohinSuu4Ref.current?.getValue()||0).replaceAll(',',''),GNT: String(shohinGnt4Ref.current?.getValue()||0).replaceAll(',',''),GNKKIN: String(shohinGnkkin4Ref.current?.getValue()||0).replaceAll(',',''),BTN: String(shohinBtn4Ref.current?.getValue()||0).replaceAll(',',''),BAKKIN: String(shohinBakkin4Ref.current?.getValue()||0).replaceAll(',',''),ESJ_KBNNT: itEOSJANKBN4},
      {SHNCD: shohinCode5Ref.current?.getValue(),LINENO: '5',SUU: String(shohinSuu5Ref.current?.getValue()||0).replaceAll(',',''),GNT: String(shohinGnt5Ref.current?.getValue()||0).replaceAll(',',''),GNKKIN: String(shohinGnkkin5Ref.current?.getValue()||0).replaceAll(',',''),BTN: String(shohinBtn5Ref.current?.getValue()||0).replaceAll(',',''),BAKKIN: String(shohinBakkin5Ref.current?.getValue()||0).replaceAll(',',''),ESJ_KBNNT: itEOSJANKBN5},
      {SHNCD: shohinCode6Ref.current?.getValue(),LINENO: '6',SUU: String(shohinSuu6Ref.current?.getValue()||0).replaceAll(',',''),GNT: String(shohinGnt6Ref.current?.getValue()||0).replaceAll(',',''),GNKKIN: String(shohinGnkkin6Ref.current?.getValue()||0).replaceAll(',',''),BTN: String(shohinBtn6Ref.current?.getValue()||0).replaceAll(',',''),BAKKIN: String(shohinBakkin6Ref.current?.getValue()||0).replaceAll(',',''),ESJ_KBNNT: itEOSJANKBN6}
      ],
      upd_jgncde: state.JGNCDE,upd_prg_id: 'TCSA0130',trl_id: state.MISE})});
    const dataUA2 = await responseUA2.json();
    // console.log('伝票明細:%o',dataUA2);
    // 2023/09/29 エラー表示の追加
    if (dataUA2.res === '-1') {
      setAlert('伝票データ登録エラー');//TCGE0023：
      return mvFlg;;
    }

    setItrcnt(itRcnt+1);
    Err_flg = 1;
    Gmclr();
    // console.log('カーソル移動します。');
    inZEIRTU.current.focus();
    mvFlg = 2;
    return mvFlg;
  }

  // 登録処理 更新処理
  const onKeyDown_Update = async()=>{
    // isInInputConfirm = true;
    // if (await InputConfirmed() !== 0) {
    //   isInInputConfirm = false;
    //   return 2;
    // }
    // isInInputConfirm = false;

    setAlert('　');

    // window.alert('チェックok onKeyDown_Update');
    // return 0;

    let mvFlg = 0;
    // console.log('JSON:%o',state.JGNCDE);
    const csuu = shohinSuu1Ref.current?.getValue()||0+shohinSuu2Ref.current?.getValue()||0+shohinSuu3Ref.current?.getValue()||0+shohinSuu4Ref.current?.getValue()||0+shohinSuu5Ref.current?.getValue()||0+shohinSuu6Ref.current?.getValue()||0
    let Tgitem = '';
    if (denGRef.current?.getValue() !== ''){Tgitem = '20'+denGRef.current?.getValue().replaceAll('/','')}
    const responseUA1 = await fetch('/TCSA0130/SL_HED_DAT_upd',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ENT_KSACDE: cent_ksacde,ENT_MISE: cent_mise,DENNO: denpyoNoRef.current?.getValue(),SIRCDE: sireCodeRef.current?.getValue(),KSACDE: cent_ksacde,MISE: syaMiseRef.current?.getValue(),
      KIJYMD:'20'+(keijyoYMRef.current?.getValue().replaceAll('年','')).replaceAll('月','')+'20',BMNCDE: bunruiCodeRef.current?.getValue(),DENKBN: denpyoKubunRef.current?.getValue(),DENYMD: '20'+denpyoYMDRef.current?.getValue().replaceAll('/',''),ZEIKBN: zeiKubunRef.current?.getValue(),
      ZEIRTU: zeirituRef.current?.getValue(),DEN_G:Tgitem,KIJYYM:'20'+(keijyoYMRef.current?.getValue().replaceAll('年','')).replaceAll('月',''),
      TSESRYFOT: String(csuu).replaceAll(/,/g,''),TSEGNKFOT: String(TSEGNKFOTRef.current?.getValue()).replaceAll(/,/g,''),TSEBAKFOT: String(TSEBAKFOTRef.current?.getValue()).replaceAll(/,/g,''),DEN_G:Tgitem,
      BIN:binRef.current?.getValue(),upd_jgncde: state.JGNCDE,upd_prg_id: 'TCSA0130',trl_id: state.MISE})});
    const dataUA1 = await responseUA1.json();
    // console.log('伝票ヘッダ:%o',dataUA1);
    // 2023/09/29 エラー表示の追加
    if (dataUA1.res === '-1') {
      setAlert('伝票データ修正エラー');//TCGE0024：
      return mvFlg;;
    }

    // console.log('伝票明細更新1'+shohinSuu1Ref.current?.getValue()+'|'+shohinGnt1Ref.current?.getValue()+'|'+shohinBakkin1Ref.current?.getValue()+'|'+shohinBakkin2Ref.current?.getValue()+'|'+shohinBakkin3Ref.current?.getValue()+'|'+shohinBakkin4Ref.current?.getValue()+'|'+shohinBakkin5Ref.current?.getValue()+'|'+shohinBakkin6Ref.current?.getValue());
    const responseUA2 = await fetch('/TCSA0130/SL_DTL_DAT_upd',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ENT_KSACDE: cent_ksacde,ENT_MISE: cent_mise,DENNO: denpyoNoRef.current?.getValue(),SIRCDE: sireCodeRef.current?.getValue(),KSACDE: cent_ksacde,MISE: syaMiseRef.current?.getValue(),
        meisai:[
      {SHNCD: shohinCode1Ref.current?.getValue(),LINENO: '1',SUU: String(shohinSuu1Ref.current?.getValue()||0).replaceAll(',',''),GNT: String(shohinGnt1Ref.current?.getValue()||0).replaceAll(',',''),GNKKIN: String(shohinGnkkin1Ref.current?.getValue()||0).replaceAll(',',''),BTN: String(shohinBtn1Ref.current?.getValue()||0).replaceAll(',',''),BAKKIN: String(shohinBakkin1Ref.current?.getValue()||0).replaceAll(',',''),ESJ_KBNNT: itEOSJANKBN1},
      {SHNCD: shohinCode2Ref.current?.getValue(),LINENO: '2',SUU: String(shohinSuu2Ref.current?.getValue()||0).replaceAll(',',''),GNT: String(shohinGnt2Ref.current?.getValue()||0).replaceAll(',',''),GNKKIN: String(shohinGnkkin2Ref.current?.getValue()||0).replaceAll(',',''),BTN: String(shohinBtn2Ref.current?.getValue()||0).replaceAll(',',''),BAKKIN: String(shohinBakkin2Ref.current?.getValue()||0).replaceAll(',',''),ESJ_KBNNT: itEOSJANKBN2},
      {SHNCD: shohinCode3Ref.current?.getValue(),LINENO: '3',SUU: String(shohinSuu3Ref.current?.getValue()||0).replaceAll(',',''),GNT: String(shohinGnt3Ref.current?.getValue()||0).replaceAll(',',''),GNKKIN: String(shohinGnkkin3Ref.current?.getValue()||0).replaceAll(',',''),BTN: String(shohinBtn3Ref.current?.getValue()||0).replaceAll(',',''),BAKKIN: String(shohinBakkin3Ref.current?.getValue()||0).replaceAll(',',''),ESJ_KBNNT: itEOSJANKBN3},
      {SHNCD: shohinCode4Ref.current?.getValue(),LINENO: '4',SUU: String(shohinSuu4Ref.current?.getValue()||0).replaceAll(',',''),GNT: String(shohinGnt4Ref.current?.getValue()||0).replaceAll(',',''),GNKKIN: String(shohinGnkkin4Ref.current?.getValue()||0).replaceAll(',',''),BTN: String(shohinBtn4Ref.current?.getValue()||0).replaceAll(',',''),BAKKIN: String(shohinBakkin4Ref.current?.getValue()||0).replaceAll(',',''),ESJ_KBNNT: itEOSJANKBN4},
      {SHNCD: shohinCode5Ref.current?.getValue(),LINENO: '5',SUU: String(shohinSuu5Ref.current?.getValue()||0).replaceAll(',',''),GNT: String(shohinGnt5Ref.current?.getValue()||0).replaceAll(',',''),GNKKIN: String(shohinGnkkin5Ref.current?.getValue()||0).replaceAll(',',''),BTN: String(shohinBtn5Ref.current?.getValue()||0).replaceAll(',',''),BAKKIN: String(shohinBakkin5Ref.current?.getValue()||0).replaceAll(',',''),ESJ_KBNNT: itEOSJANKBN5},
      {SHNCD: shohinCode6Ref.current?.getValue(),LINENO: '6',SUU: String(shohinSuu6Ref.current?.getValue()||0).replaceAll(',',''),GNT: String(shohinGnt6Ref.current?.getValue()||0).replaceAll(',',''),GNKKIN: String(shohinGnkkin6Ref.current?.getValue()||0).replaceAll(',',''),BTN: String(shohinBtn6Ref.current?.getValue()||0).replaceAll(',',''),BAKKIN: String(shohinBakkin6Ref.current?.getValue()||0).replaceAll(',',''),ESJ_KBNNT: itEOSJANKBN6}
      ],
      upd_jgncde: state.JGNCDE,upd_prg_id: 'TCSA0130',trl_id: state.MISE})});
    const dataUA2 = await responseUA2.json();
    // console.log('伝票明細:%o',dataUA2);
      // 2023/09/29 エラー表示の追加
      if (dataUA2.res === '-1') {
      setAlert('伝票データ修正エラー');//TCGE0024：
      return mvFlg;;
    }
    setItrcnt(itRcnt+1);
    Err_flg = 1;
    Gmclr();
    inDENNO.current.focus();
    mvFlg = 2;
    return mvFlg;
  }

  // 登録処理 削除処理
  const onKeyDown_Delete = async()=>{
    // isInInputConfirm = true;
    // if (await InputConfirmed() !== 0) {
    //   isInInputConfirm = false;
    //   return 2;
    // }
    // isInInputConfirm = false;

    setAlert('　');

    // window.alert('チェックok onKeyDown_Delete');
    // return 0;

    let mvFlg = 0;
    const responseUB1 = await fetch('/TCSA0130/SL_HED_DAT_del',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ENT_KSACDE: cent_ksacde,ENT_MISE: cent_mise,DENNO: denpyoNoRef.current?.getValue(),SIRCDE: sireCodeRef.current?.getValue(),KSACDE: cent_ksacde,MISE: syaMiseRef.current?.getValue()})});
    const dataUB1 = await responseUB1.json();
    // console.log('伝票ヘッダ:%o',dataUB1);
    // 2023/09/29 エラー表示の追加
    if (dataUB1.res === '-1') {
      setAlert('伝票データ削除エラー');//TCGE0025：
      return mvFlg;;
    }

    // console.log('伝票明細削除');
    const responseUB2 = await fetch('/TCSA0130/SL_DTL_DAT_del',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ENT_KSACDE: cent_ksacde,ENT_MISE: cent_mise,DENNO: denpyoNoRef.current?.getValue(),SIRCDE: sireCodeRef.current?.getValue(),KSACDE: cent_ksacde,MISE: syaMiseRef.current?.getValue(),
    meisai:[
      {SHNCD: shohinCode1Ref.current?.getValue(),LINENO: '1',},{SHNCD: shohinCode2Ref.current?.getValue(),LINENO: '2',},{SHNCD: shohinCode3Ref.current?.getValue(),LINENO: '3',},
      {SHNCD: shohinCode4Ref.current?.getValue(),LINENO: '4',},{SHNCD: shohinCode5Ref.current?.getValue(),LINENO: '5',},{SHNCD: shohinCode6Ref.current?.getValue(),LINENO: '6',}]
    })});
    const dataUB2 = await responseUB2.json();
    // console.log('伝票明細:%o',dataUB2);
    // 2023/09/29 エラー表示の追加
    if (dataUB2.res === '-1') {
      setAlert('伝票データ削除エラー');//TCGE0025：
      return mvFlg;;
    }
    Gmclr();
    inDENNO.current.focus();
    setItrcnt(itRcnt+1);
    mvFlg = 2;
    return mvFlg;
  }
  //#endregion

  //#region スタイル
  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  // 確認のWidthを設定する
  const cssInputOutlineRetSetwidth = (obj) => {
    let resObj = obj;
    resObj.width = '50px';
    return resObj;
  }

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
          fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },
    //明細用
    cssInputOutline2: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#FFFFFF',               // 入力文字の色
      },
      '& label': {
        color: '#FFFFFF',               // 通常時のラベル色
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#FFFFFF',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#FFFFFF',   // ホバー時のボーダー色
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#FFFFFF',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):after': {
        borderBottomColor: '#FFFFFF',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#FFFFFF',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#FFFFFF',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#FFFFFF',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#FFFFFF',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
          fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },

  }
  //#endregion

  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "720px",
          // height: "85vh",
          width: "900px",
          m: "10px auto",
          padding:"10px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="left"
        >
          <div style={{display:"flex"}}>
            <Typography variant={"h6"} sx={{ m: "10px" }}>
              仕入(手書){ttl}
            </Typography>
            <Box width={'60%'} display={'flex'} justifyContent={'flex-end'}>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"20px",fontSize:15 }}>
              ［{itKBNNM}］
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px",fontSize:20 }}>
                ({itRcnt}件){/* {state.JGNMEIKNJ} */}
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"20px",fontSize:15 }}>
                {state.MISEMEI}：{state.JGNMEIKNJ}
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"20px",fontSize:15 }}>
                { sdate }
              </Typography>
            </Box>
          </div>
        </Grid>

        <Stack direction="row" sx={{mt:'-30px'}}>
          {/* 区分 */}
          <CKubun
            ref = {kubunRef}
            onKeyDown = {onKeyDown}
            inputRef = {inKBN}
            inputRtRef = {inKBN}
            inputNxRef = {inZEIRTU}
          />

          {/* 税率 */}
          <CZeiritu
            ref = {zeirituRef}
            onKeyDown = {onKeyDown}
            inputRef = {inZEIRTU}
            inputRtRef = {inKBN}
            inputNxRef = {inZEIKBN}
            setMeisaiflg = {sireCodeRef.current?.setMeisaiFlg}
          />
        </Stack>
        <br />
        <Stack direction="row">
          {/* 税区分 */}
          <CZeiKubun
            ref = {zeiKubunRef}
            onKeyDown = {onKeyDown}
            inputRef = {inZEIKBN}
            inputRtRef = {inZEIRTU}
            inputNxRef = {(dissmise ? inBMNCDE : inSMISE)}
          />
          {/* 社店CD */}
          <CSyaMise
            ref = {syaMiseRef}
            onKeyDown = {onKeyDown}
            inputRef = {inSMISE}
            inputRtRef = {inZEIKBN}
            inputNxRef = {inBMNCDE}
            dissmise = {dissmise}
          />
          {/* 分類CD */}
          <CBunruiCode
            ref = {bunruiCodeRef}
            onKeyDown = {onKeyDown}
            inputRef = {inBMNCDE}
            inputRtRef = {(dissmise ? inZEIKBN : inSMISE)}
            inputNxRef = {inDENKBN}
          />
          {/* 伝票区分 */}
          <CDenpyoKubun
            ref = {denpyoKubunRef}
            onKeyDown = {onKeyDown}
            inputRef = {inDENKBN}
            inputRtRef = {inBMNCDE}
            inputNxRef = {inDENNO}
          />
        </Stack>
        <br />
        <Stack direction="row" spacing={3}>
          {/* 伝票No. */}
          <CDenpyoNo
            ref = {denpyoNoRef}
            onKeyDown = {onKeyDown}
            inputRef = {inDENNO}
            inputRtRef = {inDENKBN}
            inputNxRef = {inSIRCDE}
          />
          {/* 取引先CD */}
          <CSireCode
            ref = {sireCodeRef}
            onKeyDown = {onKeyDown}
            inputRef = {inSIRCDE}
            inputRtRef = {inDENNO}
            inputNxRef = {inDENYMD}
          />
          {/* 年月日(伝票日付) */}
          <CDenpyoYMD
            ref = {denpyoYMDRef}
            onKeyDown = {onKeyDown}
            inputRef = {inDENYMD}
            inputRtRef = {inSIRCDE}
            inputNxRef = {(state.ETC == 'K')?inBIN:(state.KSACDES !== '005')?inSHNCDE1:inSUU1}
          />
           {/* 計上年月 */}
           <CKeijyoYM
            ref = {keijyoYMRef}
            onKeyDown = {onKeyDown}
            inputRef = {inKIJYYM}
            inputRtRef = {inDENYMD}
            inputNxRef = {(state.KSACDES !== '005')?inSHNCDE1:inSUU1 }
          />
          {/* 便 */}
          <CBin
            ref = {binRef}
            onKeyDown = {onKeyDown}
            inputRef = {inBIN}
            inputRtRef = {inDENYMD}
            inputNxRef = {(state.KSACDES !== '005')?inSHNCDE1:inSUU1}
            stateR = {state}
          />
        </Stack>
        <br />
        {/* テーブル表示処理 */}
        <TableContainer
          component={Paper}
          sx={{maxWidth:900}}
          onBlur={(e) => {
            // setBlurTableflg(false);
            // setTimeout(() => setBlurTableflg(true),100);
            if (e.relatedTarget?.id != null && e.relatedTarget?.id != undefined)  //2023/11/13　明細内のカーソル移動だけで再レンダリングしないよう対応
            {
              let type = getInputType(e.relatedTarget.id);
              if(type !== 2){
                if (blurTableflg === true)
                {
                  setBlurTableflg(false);
                }else{
                  setBlurTableflg(true);
                }
              }
            }

          }}
        >
          <Table sx={{border:1}} aria-label="spanning table" size="small">
            <TableHead> {/* テーブル表示処理ヘッダ部 */}
              <TableRow>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="170px" align="center" bgcolor="lightgray">商品コード</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="130px" align="center"  bgcolor="lightgray">数量</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="150px" align="center"  bgcolor="lightgray">原単価</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="150px" align="center"  bgcolor="lightgray">原価金額</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="150px" align="center"  bgcolor="lightgray">売単価</TableCell>
                <TableCell sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="150px" align="center"  bgcolor="lightgray">売価金額</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>   {/* テーブル表示処理明細部 */}
              <TableRow>
                {/* 商品コード1 */}
                <CShohinCode
                  ref = {shohinCode1Ref}
                  id = {'SHNCDE1'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSHNCDE1}
                  inputRtRef = {inDENYMD}
                  inputNxRef = {inSUU1}
                  inUpRef = {inSHNCDE1}
                  inRgRef = {inSUU1}
                  inDnRef = {inSHNCDE2}
                  inLfRef = {inSHNCDE1}
                  dismeisais = {dismeisais}
                />

                {/* 数量1 */}
                <CShohinSuu
                  ref = {shohinSuu1Ref}
                  id = {'SUU1'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSUU1}
                  inputRtRef = {(state.KSACDES !== '005')?inSHNCDE1:inDENYMD}
                  inputNxRef = {inGNT1}
                  inUpRef = {inSUU1}
                  inRgRef = {inGNT1}
                  inDnRef = {inSUU2}
                  inLfRef = {(state.KSACDES !== '005')?inSHNCDE1:inSUU1}
                  dismeisai = {dismeisai}
                  allow = {allow}
                />

                {/* 原価単価1 */}
                <CShohinGentanka
                  ref = {shohinGnt1Ref}
                  id={'GNT1'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNT1}
                  inputRtRef = {inSUU1}
                  inputNxRef = {inBTN1}
                  inUpRef = {inGNT1}
                  inRgRef = {inBTN1}
                  inDnRef = {inGNT2}
                  inLfRef = {inSUU1}
                  dismeisai = {dismeisai}
                />

                {/* 原価金額1 */}
                <CShohinGenkakingaku
                  ref = {shohinGnkkin1Ref}
                  id={'GNKKIN1'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNKKIN1}
                  inputRtRef = {inGNT1}
                  inputNxRef = {inBTN1}
                  inUpRef = {inGNKKIN1}
                  inRgRef = {inBTN1}
                  inDnRef = {inGNKKIN2}
                  inLfRef = {inGNT1}
                  dismeisai={dismeisai}
                  allow ={allow}
                />

                {/* 売単価1 */}
                <CShohinBaitanka
                  ref = {shohinBtn1Ref}
                  id={'BTN1'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBTN1}
                  inputRtRef = {inGNKKIN1}
                  inputNxRef = {(state.KSACDES !== '005')?inSHNCDE2:inSUU2}
                  inUpRef = {inBTN1}
                  inRgRef = {inBTN1}
                  inDnRef = {inBTN2}
                  inLfRef = {inGNT1}
                />

                {/* 売価金額1 */}
                <CShohinBaikakingaku
                  ref = {shohinBakkin1Ref}
                  id={'GNKKIN1'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBAKKIN1}
                  inputRtRef = {inBTN1}
                  inputNxRef = {(state.KSACDES !== '005')?inSHNCDE2:inSUU2}
                  inUpRef = {inBAKKIN1}
                  inRgRef = {inBAKKIN1}
                  inDnRef = {inBAKKIN2}
                  inLfRef = {inBTN1}
                  dismeisai = {dismeisai}
                  allow ={allow}
                />
              </TableRow>
              <TableRow>
                {/* 商品コード2 */}
                <CShohinCode
                  ref = {shohinCode2Ref}
                  id = {'SHNCDE2'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSHNCDE2}
                  inputRtRef = {inBAKKIN1}
                  inputNxRef = {inSUU2}
                  inUpRef = {inSHNCDE1}
                  inRgRef = {inSUU2}
                  inDnRef = {inSHNCDE3}
                  inLfRef = {inSHNCDE2}
                  dismeisais = {dismeisais}
                />

                {/* 数量2 */}
                <CShohinSuu
                  ref = {shohinSuu2Ref}
                  id = {'SUU2'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSUU2}
                  inputRtRef = {(state.KSACDES !== '005')?inSHNCDE2:inBAKKIN1}
                  inputNxRef = {inGNT2}
                  inUpRef = {inSUU1}
                  inRgRef = {inGNT2}
                  inDnRef = {inSUU3}
                  inLfRef = {(state.KSACDES !== '005')?inSHNCDE2:inSUU2}
                  dismeisai = {dismeisai}
                  allow = {allow}
                />

                {/* 原価単価2 */}
                <CShohinGentanka
                  ref = {shohinGnt2Ref}
                  id={'GNT2'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNT2}
                  inputRtRef = {inSUU2}
                  inputNxRef = {inBTN2}
                  inUpRef = {inGNT1}
                  inRgRef = {inBTN2}
                  inDnRef = {inGNT3}
                  inLfRef = {inSUU2}
                  dismeisai = {dismeisai}
                />

                {/* 原価金額2 */}
                <CShohinGenkakingaku
                  ref = {shohinGnkkin2Ref}
                  id={'GNKKIN2'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNKKIN2}
                  inputRtRef = {inGNT2}
                  inputNxRef = {inBTN2}
                  inUpRef = {inGNKKIN1}
                  inRgRef = {inBTN2}
                  inDnRef = {inGNKKIN3}
                  inLfRef = {inGNT2}
                  dismeisai={dismeisai}
                  allow ={allow}
                />

                {/* 売単価2 */}
                <CShohinBaitanka
                  ref = {shohinBtn2Ref}
                  id={'BTN2'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBTN2}
                  inputRtRef = {inGNKKIN2}
                  inputNxRef = {(state.KSACDES !== '005')?inSHNCDE3:inSUU3}
                  inUpRef = {inBTN1}
                  inRgRef = {inBTN2}
                  inDnRef = {inBTN3}
                  inLfRef = {inGNT2}
                  dismeisai = {dismeisai}
                />

                {/* 売価金額2 */}
                <CShohinBaikakingaku
                  ref = {shohinBakkin2Ref}
                  id={'GNKKIN2'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBAKKIN2}
                  inputRtRef = {inBTN2}
                  inputNxRef = {(state.KSACDES !== '005')?inSHNCDE3:inSUU3}
                  inUpRef = {inBAKKIN1}
                  inRgRef = {inBAKKIN2}
                  inDnRef = {inBAKKIN3}
                  inLfRef = {inBTN2}
                  dismeisai = {dismeisai}
                  allow ={allow}
                />
              </TableRow>
              <TableRow>
                {/* 商品コード3 */}
                <CShohinCode
                  ref = {shohinCode3Ref}
                  id = {'SHNCDE3'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSHNCDE3}
                  inputRtRef = {inBAKKIN2}
                  inputNxRef = {inSUU3}
                  inUpRef = {inSHNCDE2}
                  inRgRef = {inSUU3}
                  inDnRef = {inSHNCDE4}
                  inLfRef = {inSHNCDE3}
                  dismeisais = {dismeisais}
                />

                {/* 数量3 */}
                <CShohinSuu
                  ref = {shohinSuu3Ref}
                  id = {'SUU3'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSUU3}
                  inputRtRef = {(state.KSACDES !== '005')?inSHNCDE3:inBAKKIN2}
                  inputNxRef = {inGNT3}
                  inUpRef = {inSUU2}
                  inRgRef = {inGNT3}
                  inDnRef = {inSUU4}
                  inLfRef = {(state.KSACDES !== '005')?inSHNCDE3:inSUU3}
                  dismeisai = {dismeisai}
                  allow = {allow}
                />

                {/* 原価単価3 */}
                <CShohinGentanka
                  ref = {shohinGnt3Ref}
                  id={'GNT3'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNT3}
                  inputRtRef = {inSUU3}
                  inputNxRef = {inBTN3}
                  inUpRef = {inGNT2}
                  inRgRef = {inBTN3}
                  inDnRef = {inGNT4}
                  inLfRef = {inSUU3}
                  dismeisai = {dismeisai}
                />

                {/* 原価金額3 */}
                <CShohinGenkakingaku
                  ref = {shohinGnkkin3Ref}
                  id={'GNKKIN3'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNKKIN3}
                  inputRtRef = {inGNT3}
                  inputNxRef = {inBTN3}
                  inUpRef = {inGNKKIN2}
                  inRgRef = {inBTN3}
                  inDnRef = {inGNKKIN4}
                  inLfRef = {inGNT3}
                  dismeisai={dismeisai}
                  allow ={allow}
                />

                {/* 売単価3 */}
                <CShohinBaitanka
                  ref = {shohinBtn3Ref}
                  id={'BTN3'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBTN3}
                  inputRtRef = {inGNKKIN3}
                  inputNxRef = {(state.KSACDES !== '005')?inSHNCDE4:inSUU4}
                  inUpRef = {inBTN2}
                  inRgRef = {inBTN3}
                  inDnRef = {inBTN4}
                  inLfRef = {inGNT3}
                  dismeisai = {dismeisai}
                />

                {/* 売価金額3 */}
                <CShohinBaikakingaku
                  ref = {shohinBakkin3Ref}
                  id={'GNKKIN3'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBAKKIN3}
                  inputRtRef = {inBTN3}
                  inputNxRef = {(state.KSACDES !== '005')?inSHNCDE4:inSUU4}
                  inUpRef = {inBAKKIN2}
                  inRgRef = {inBAKKIN3}
                  inDnRef = {inBAKKIN4}
                  inLfRef = {inBTN3}
                  dismeisai = {dismeisai}
                  allow ={allow}
                />
              </TableRow>
              <TableRow>
                {/* 商品コード4 */}
                <CShohinCode
                  ref = {shohinCode4Ref}
                  id = {'SHNCDE4'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSHNCDE4}
                  inputRtRef = {inBAKKIN3}
                  inputNxRef = {inSUU4}
                  inUpRef = {inSHNCDE3}
                  inRgRef = {inSUU4}
                  inDnRef = {inSHNCDE5}
                  inLfRef = {inSHNCDE4}
                  dismeisais = {dismeisais}
                />

                {/* 数量4 */}
                <CShohinSuu
                  ref = {shohinSuu4Ref}
                  id = {'SUU4'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSUU4}
                  inputRtRef = {(state.KSACDES !== '005')?inSHNCDE4:inBAKKIN3}
                  inputNxRef = {inGNT4}
                  inUpRef = {inSUU3}
                  inRgRef = {inGNT4}
                  inDnRef = {inSUU5}
                  inLfRef = {(state.KSACDES !== '005')?inSHNCDE4:inSUU4}
                  dismeisai = {dismeisai}
                  allow = {allow}
                />

                {/* 原価単価4 */}
                <CShohinGentanka
                  ref = {shohinGnt4Ref}
                  id={'GNT4'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNT4}
                  inputRtRef = {inSUU4}
                  inputNxRef = {inBTN4}
                  inUpRef = {inGNT3}
                  inRgRef = {inBTN4}
                  inDnRef = {inGNT5}
                  inLfRef = {inSUU4}
                  dismeisai = {dismeisai}
                />

                {/* 原価金額4 */}
                <CShohinGenkakingaku
                  ref = {shohinGnkkin4Ref}
                  id={'GNKKIN4'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNKKIN4}
                  inputRtRef = {inGNT4}
                  inputNxRef = {inBTN4}
                  inUpRef = {inGNKKIN3}
                  inRgRef = {inBTN4}
                  inDnRef = {inGNKKIN5}
                  inLfRef = {inGNT4}
                  dismeisai={dismeisai}
                  allow ={allow}
                />

                {/* 売単価4 */}
                <CShohinBaitanka
                  ref = {shohinBtn4Ref}
                  id={'BTN4'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBTN4}
                  inputRtRef = {inGNKKIN4}
                  inputNxRef = {(state.KSACDES !== '005')?inSHNCDE5:inSUU5}
                  inUpRef = {inBTN3}
                  inRgRef = {inBTN4}
                  inDnRef = {inBTN5}
                  inLfRef = {inGNT4}
                  dismeisai = {dismeisai}
                />

                {/* 売価金額4 */}
                <CShohinBaikakingaku
                  ref = {shohinBakkin4Ref}
                  id={'GNKKIN4'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBAKKIN4}
                  inputRtRef = {inBTN4}
                  inputNxRef = {(state.KSACDES !== '005')?inSHNCDE5:inSUU5}
                  inUpRef = {inBAKKIN3}
                  inRgRef = {inBAKKIN4}
                  inDnRef = {inBAKKIN5}
                  inLfRef = {inBTN4}
                  dismeisai = {dismeisai}
                  allow ={allow}
                />
              </TableRow>
              <TableRow>
                {/* 商品コード5 */}
                <CShohinCode
                  ref = {shohinCode5Ref}
                  id = {'SHNCDE5'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSHNCDE5}
                  inputRtRef = {inBAKKIN4}
                  inputNxRef = {inSUU5}
                  inUpRef = {inSHNCDE4}
                  inRgRef = {inSUU5}
                  inDnRef = {inSHNCDE6}
                  inLfRef = {inSHNCDE5}
                  dismeisais = {dismeisais}
                />

                {/* 数量5 */}
                <CShohinSuu
                  ref = {shohinSuu5Ref}
                  id = {'SUU5'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSUU5}
                  inputRtRef = {(state.KSACDES !== '005')?inSHNCDE5:inBAKKIN4}
                  inputNxRef = {inGNT5}
                  inUpRef = {inSUU4}
                  inRgRef = {inGNT5}
                  inDnRef = {inSUU6}
                  inLfRef = {inSHNCDE5}
                  dismeisai = {dismeisai}
                  allow = {allow}
                />

                {/* 原価単価5 */}
                <CShohinGentanka
                  ref = {shohinGnt5Ref}
                  id={'GNT5'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNT5}
                  inputRtRef = {inSUU5}
                  inputNxRef = {inBTN5}
                  inUpRef = {inGNT4}
                  inRgRef = {inBTN5}
                  inDnRef = {inGNT6}
                  inLfRef = {inSUU5}
                  dismeisai = {dismeisai}
                />

                {/* 原価金額5 */}
                <CShohinGenkakingaku
                  ref = {shohinGnkkin5Ref}
                  id={'GNKKIN5'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNKKIN5}
                  inputRtRef = {inGNT5}
                  inputNxRef = {inBTN5}
                  inUpRef = {inGNKKIN4}
                  inRgRef = {inBTN5}
                  inDnRef = {inGNKKIN6}
                  inLfRef = {inGNT5}
                  dismeisai={dismeisai}
                  allow ={allow}
                />

                {/* 売単価5 */}
                <CShohinBaitanka
                  ref = {shohinBtn5Ref}
                  id={'BTN5'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBTN5}
                  inputRtRef = {inGNKKIN5}
                  inputNxRef = {(state.KSACDES !== '005')?inSHNCDE6:inSUU6}
                  inUpRef = {inBTN4}
                  inRgRef = {inBTN5}
                  inDnRef = {inBTN6}
                  inLfRef = {inGNT5}
                  dismeisai = {dismeisai}
                />

                {/* 売価金額5 */}
                <CShohinBaikakingaku
                  ref = {shohinBakkin5Ref}
                  id={'GNKKIN5'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBAKKIN5}
                  inputRtRef = {inBTN5}
                  inputNxRef = {(state.KSACDES !== '005')?inSHNCDE6:inSUU6}
                  inUpRef = {inBAKKIN4}
                  inRgRef = {inBAKKIN5}
                  inDnRef = {inBAKKIN6}
                  inLfRef = {inBTN5}
                  dismeisai = {dismeisai}
                  allow ={allow}
                />
              </TableRow>
              <TableRow>
                {/* 商品コード6 */}
                <CShohinCode
                  ref = {shohinCode6Ref}
                  id = {'SHNCDE6'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSHNCDE6}
                  inputRtRef = {inBAKKIN5}
                  inputNxRef = {inSUU6}
                  inUpRef = {inSHNCDE5}
                  inRgRef = {inSUU6}
                  inDnRef = {inSHNCDE6}
                  inLfRef = {inSHNCDE6}
                  dismeisais = {dismeisais}
                />

                {/* 数量6 */}
                <CShohinSuu
                  ref = {shohinSuu6Ref}
                  id = {'SUU6'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inSUU6}
                  inputRtRef = {(state.KSACDES !== '005')?inSHNCDE6:inBAKKIN5}
                  inputNxRef = {inGNT6}
                  inUpRef = {inSUU5}
                  inRgRef = {inGNT6}
                  inDnRef = {inSUU6}
                  inLfRef = {(state.KSACDES !== '005')?inSHNCDE6:inSUU6}
                  dismeisai = {dismeisai}
                  allow = {allow}
                />

                {/* 原価単価6 */}
                <CShohinGentanka
                  ref = {shohinGnt6Ref}
                  id={'GNT6'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNT6}
                  inputRtRef = {inSUU6}
                  inputNxRef = {inBTN6}
                  inUpRef = {inGNT5}
                  inRgRef = {inBTN6}
                  inDnRef = {inGNT6}
                  inLfRef = {inSUU6}
                  dismeisai = {dismeisai}
                />

                {/* 原価金額6 */}
                <CShohinGenkakingaku
                  ref = {shohinGnkkin6Ref}
                  id={'GNKKIN6'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inGNKKIN6}
                  inputRtRef = {inGNT6}
                  inputNxRef = {inBTN6}
                  inUpRef = {inGNKKIN5}
                  inRgRef = {inBTN6}
                  inDnRef = {inGNKKIN6}
                  inLfRef = {inGNT6}
                  dismeisai={dismeisai}
                  allow ={allow}
                />

                {/* 売単価6 */}
                <CShohinBaitanka
                  ref = {shohinBtn6Ref}
                  id={'BTN6'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBTN6}
                  inputRtRef = {inGNKKIN6}
                  inputNxRef = {inGNKFOT}
                  inUpRef = {inBTN5}
                  inRgRef = {inBTN6}
                  inDnRef = {inBTN6}
                  inLfRef = {inGNT6}
                  dismeisai = {dismeisai}
                />

                {/* 売価金額6 */}
                <CShohinBaikakingaku
                  ref = {shohinBakkin6Ref}
                  id={'GNKKIN6'}
                  onKeyDown = {onKeyDown_Meisai}
                  inputRef = {inBAKKIN6}
                  inputRtRef = {inBTN6}
                  inputNxRef = {inGNKFOT}
                  inUpRef = {inBAKKIN5}
                  inRgRef = {inBAKKIN6}
                  inDnRef = {inBAKKIN6}
                  inLfRef = {inBTN6}
                  dismeisai = {dismeisai}
                  allow ={allow}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer><br/>
        <Box display={'flex'} justifyContent={'flex-start'}>
          <Stack direction="row" spacing={2}>
            {/* 伝票F欄 */}
            <CDenpyoFH
              ref = {denFRef}
              id = {'DENF'}
              label = {'F'}
              inputRef={inDENF}
            />
            {/* 伝票H欄 */}
            <CDenpyoFH
              ref = {denHRef}
              id = {'DENH'}
              label = {'H'}
              inputRef={inDENH}
            />
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
            {/* 原価金額合計 */}
            <CGoukei
              ref = {GNKFOTRef}
              id = {'GNKFOT'}
              label = {'原価金額合計'}
              onKeyDown = {onKeyDown}
              inputRef = {inGNKFOT}
              inputRtRef = {(state.KSACDES !== '005')?inSHNCDE1:inSUU1}
              inputNxRef = {inBAKFOT}
            />
            &emsp;&emsp;
            {/* 売価金額合計 */}
            <CGoukei
              ref = {BAKFOTRef}
              id = {'BAKFOT'}
              label = {'売価金額合計'}
              onKeyDown = {onKeyDown}
              inputRef = {inBAKFOT}
              inputRtRef = {inGNKFOT}
              inputNxRef = {inDENG}
            />
          </Stack>
        </Box>
        <br />
        <Box display={'flex'} justifyContent={'flex-start'}>
          <Stack direction="row" spacing={2}>
            {/* 伝票G欄 */}
            <CDenpyoG
              ref = {denGRef}
              onKeyDown ={onKeyDown}
              inputRef = {inDENG}
              inputRtRef = {inBAKFOT}
              inputNxRef = {inRET}
              disdeng = {disdeng}
            />
            {/* 伝票I欄 */}
            <CDenpyoIJK
              ref = {denIRef}
              id = {'DENI'}
              label = {'I'}
              inputRef={inDENI}
            />
            {/* 伝票J欄 */}
            <CDenpyoIJK
              ref = {denJRef}
              id = {'DENJ'}
              label = {'J'}
              inputRef={inDENJ}
            />
            {/* 伝票K欄 */}
            <CDenpyoIJK
              ref = {denKRef}
              id = {'DENK'}
              label = {'K'}
              inputRef={inDENK}
            />
            &emsp;&emsp;&emsp;&emsp;
            {/* 訂正後原価金額合計 */}
            <CGoukei
              ref = {TSEGNKFOTRef}
              id = {'TSEGNKFOT'}
              label = {'訂正後原価金額合計'}
              onKeyDown = {onKeyDown}
              inputRef = {inTSEGNKFOT}
              inputRtRef = {(state.KSACDES !== '005')?inSHNCDE1:inSUU1}
              inputNxRef = {inTSEBAKFOT}
            />
            &emsp;&emsp;
            {/* 訂正後売価金額合計 */}
            <CGoukei
              ref = {TSEBAKFOTRef}
              id = {'TSEBAKFOT'}
              label = {'訂正後売価金額合計'}
              onKeyDown = {onKeyDown}
              inputRef = {inTSEBAKFOT}
              inputRtRef = {inTSEGNKFOT}
              inputNxRef = {inDENG}
            />
          </Stack>
        </Box>
        <br />
        <Box display={'flex'} justifyContent={'flex-end'}>
          <NumericFormat  //確認
            id={'ret'}
            inputRef={inRET}                    //項目名定義？
            thousandSeparator={false}           //1000単位の区切り
            color='success'
            customInput={TextField}             //textfieldにreact-number-formatの機能を追加
            size='small'                        //textboxの全体サイズ
            label='確認'                         //textboxの左上に表示するラベル
            variant='outlined'                  //枠の表示方法
            fixedDecimalScale={false}            //小数点On/Off
            InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
            sx={Object.assign({},cssInputOutlineRetSetwidth(styles.cssInputOutline))}
            allowLeadingZeros                   //前ゼロ表示 on
            disabled={false}                    //使用可／不可
            inputProps={{ autoComplete: 'off',maxLength: 0,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
            onKeyDown={(e) => onKeyDown(e.key,inRET,inDENG,inRET)}
            // 2023/09/29 処理の重複を制御
            onFocus={(e) =>{
              if(!enable){
                setEnable(true);
                onFocus_ret();
              }
            }}
            onBlur={(e) => setEnable(false)}
          />
        </Box>
        {/* <font color="red">{ alert+' | '+inputSpeed }</font> */}
        <font color="red">{ alert }</font>
        <Stack direction="row" spacing={2}>
          <font color="black">{ 'F9:中止　F5:終了　Up:前項目　Down:次項目　　　　　　　　　　　　　　　　　　　　　　　　' }</font>
        </Stack>
      </Paper>
    </Grid>
  );
};

export default TCSA0130;