import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, Button,Stack, Grid, Paper, Typography, InputLabel, MenuItem, FormControl, TextField } from "@mui/material";
import { NumericFormat } from 'react-number-format';    //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';

const commonStyles = {
  bgcolor: 'background.paper',
  m: 1,
  border: 0,
  width: '40px',
  height: '40px',
};

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSH0170 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('ただいま発注データ送信中です。');
  const [sdate, setsDate] = useState([]);     // 本日日付（YYYY/MM/DD)
  const [sendok, setSendOK] = useState(0);
  const [sendng, setSendNG] = useState(0);

  const inSendOK = useRef(null);
  const inSendNG = useRef(null);

  //項目のバックカラー制御用
  const [inSendOKclr,setSendOKclr] = useState('#FFFFFF');
  const [inSendNGclr,setSendNGclr] = useState('#FFFFFF');

  //disabled制御用
  const [disHttkbn, setDisHttkbn] = useState(false);    // 発注区分

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    // 多重防止
    // if (!keyDisabled) {
    //   setKeyDisabled(true);
    // } else {
    //   return;
    // }

    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  const handleChange = (event, SelectChangeEvent) => {
    console.log('選択発注区分:%o',event.target.value)
    switch(event.target.value)
    {
      case '1':
        break;
      case '2':
        break;
      default:
        break;
    };
  };
  
  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);

    // 送信処理
    // 送信処理はボタン押した回数だけ処理すべきだから、TCSH0100に実装する

    // 送信結果確認処理
    Htt_Sel();
    // let okcnt = Number(Htt_Sel());
    // setSendOK(okcnt);
  },[]);

  useEffect(() => {
  }, []);

  // // 送信処理
  // const Htt_Send = async() => {
  //   console.log('送信処理開始 :')
  //   let errflg = 0;

  //   const responseSD1 = await fetch('/TCSH0170/SH_HTT_DAT_upd',{method: 'POST',headers: {'Content-Type': 'application/json'},
  //   body: JSON.stringify({
  //     ksacde : state.KSACDE       // 会社コード
  //     ,mise :  state.MISE         // 店コード
  //     ,httymd : state.HTTYMD      // 発注日
  //     ,jgncde : state.JGNCDE      // 従業員コード
  //   })});
  //   const dataSD1 = await responseSD1.json();
  //   console.log('送信結果:%o',dataSD1.res);

  //   // 送信処理 登録エラー
  //   if (dataSD1.res === '-1') {
  //     setAlert('送信処理でエラーが発生しました');
  //     return -1;
  //   }

  //   // 正常時
  //   return 0;
  // }

  // 送信結果検索
  const Htt_Sel = async() => {
    console.log('検索処理開始 :')
    let errflg = 0;

    const responseSl1 = await fetch('/TCSH0170/SH_HTT_DAT_Sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      ksacde : state.KSACDE       // 会社コード
      ,mise :  state.MISES         // 店コード
      ,httymd : state.HTTYMD      // 発注日
      ,jgncde : state.JGNCDE      // 従業員コード
    })});
    const dataSl1 = await responseSl1.json();
    console.log('検索結果:%o',dataSl1.RCNT);
    if (dataSl1.RCNT === 0){
      // 異常時
      setSendOK(0);  
      setSendNG(0);  
    }else{
      for(const key in dataSl1.ret){
        if (dataSl1.ret[key].SND_KNR_KBN === '1'){
          setSendOK(Number(dataSl1.ret[key].CNT));
          setAlert('発注データを受け付けました。')
        }else if (dataSl1.ret[key].SND_KNR_KBN === '9'){
          setSendNG(Number(dataSl1.ret[key].CNT));
          setAlert(dataSl1.ret[key].CNT+'件の発注データが間に合いませんでした。')
        }
      }
    }
  }

  // 発注明細ボタン押下時の処理
  const onClick_FooterButton_Next = async() => {
    let errflg = 0;
    console.log('発注明細ボタン:%o',state)
    console.log('エラーフラグ:%o',errflg)
    if (errflg == 0 )
    {
      dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
        KSACDES : state.KSACDES,MISES: state.MISES,K_KSACDES : state.K_KSACDE,K_MISES: state.K_MISE,
        MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
        SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: state.K_FLG,SDAY: state.SDAY,BUTTON:state.BUTTON, 
        BMNCDE:state.BMNCDE,BRUMEI:state.BRUMEI,GRPCDE:state.GRPCDE,IRYOFLG:state.IRYOFLG,HTTYMD:state.HTTYMD,HTTKBN:state.HTTKBN,MVEMGAMEN:state.MVEMGAMEN,JANCDE:state.JANCDE
        //S SK-0037-007 2024/7/4           TCI)M.K【課題No.73：要チェックリストの遷移】
        ,ETC:state.ETC
        //E SK-0037-007
      });
      if (state.GAMEN === 0)
      { 
        console.log('発注明細画面開く')
        navigate('/TCSH0180');
      }else{
        navigate('/TCSH0280');
      }
    }
  }

  // 戻るボタン押下時の処理
  const onClick_FooterButton_Back = async() => {
    dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
    KSACDES : state.KSACDES,MISES: state.MISES,K_KSACDES : state.K_KSACDE,K_MISES: state.K_MISE,
    MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
    SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: state.K_FLG,SDAY: state.SDAY,BUTTON:state.BUTTON, 
    BMNCDE:state.BMNCDE,BRUMEI:state.BRUMEI,GRPCDE:state.GRPCDE,IRYOFLG:state.IRYOFLG,HTTYMD:state.HTTYMD,HTTKBN:state.HTTKBN,MVEMGAMEN:state.MVEMGAMEN,JANCDE:''
    //S SK-0037-008 2024/7/4           TCI)M.K【課題No.73：要チェックリストの遷移】
    ,ETC:state.ETC
    //E SK-0037-008
    });
    if (state.GAMEN == 0)
    { 
      // console.log('スキャン発注開く')
      if (state.IRYOFLG === '0'){
        navigate('/TCSH0100');
      }else if (state.IRYOFLG === '1'){
        navigate('/TCSH0101');
      }
    }else{
      if (state.IRYOFLG === '0'){
        navigate('/TCSH0200');
      }else if (state.IRYOFLG === '1'){
        navigate('/TCSH0201');
      }
    }
  }

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    //console.log(key);
    switch (key) {
      case "Enter":
        let errflg = 0;
        setAlert('　');
        if (inputRef.current.id == "httymd")
        {
          // console.log('Enter_httymd1:'+inputRef.current.defaultValue)
          // setHttymd(inputRef.current.defaultValue);
          // console.log('Enter_httymd2:'+httymd)
        }

        if (errflg == 0)
        {
          inputNxRef.current.focus();   //次の項目に遷移
        }
        break;
      case "Home":
        break;
      case "ArrowUp":
        break;
      case "ArrowDown":
        break;
      default:
        break;
    }
  };  

  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
         fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
    cssInputOutlineJotKbn: {
      '> div': {
        minHeight: 'auto !important',
        paddingTop: '.4em',
        paddingBottom: '.3em',
      }
    },
    cssInputSelect: {
      ' label': {
        color: '#000000'
      },
      ' fieldset': {
        borderColor: '#000000'
      },
    },
  }

  return (
    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "470px",
          width: "280px",
          m: "20px auto"
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="center"
        >
          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
            <Typography variant={'h3'} sx={{ m: '5px',mt:'1px',fontSize:11,width:400,mr:'10px' }}>
              {state.MISES}:{state.BMNCDE} {state.MISEMEI}:{state.BRUMEI}<br/>{state.JGNMEIKNJ}
            </Typography>
            <Typography variant={'h3'} sx={{ m: '5px',mt:'17px',fontSize:11 ,width:110,mr:'1px'}}>
              { sdate }
            </Typography>
          </Box>
          {/* 画像の角丸対応 */}
          <Box sx={{  ...commonStyles, borderRadius: '5px',overflow: 'hidden' }}><img src={`${process.env.PUBLIC_URL}/OKUWA_Logo.svg`} alt="Logo" width="40" height="40" /></Box>
          {/* <Avatar sx={{ bgcolor: teal[400] }}>
          </Avatar> */}
          <Typography variant={"h6"} sx={{ m: "30px" }}>
          送信結果
          </Typography>
        </Grid>
        <p><font color="red">{ alert }</font></p>
        <br/>
        <Stack direction="row" spacing={2}>
          <Box>
            <NumericFormat  //SendOK
              id={'SENDOK'}
              inputRef={inSendOK}                 //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='正常'                        //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 4), {background:inSendOKclr})}  //2023/11/07 入力項目の表示内容拡大
              value={sendok}                      //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                     //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onChange={(e)=>{
                // const row = JSON.parse(JSON.stringify(rowData));
                // row.JANCDE = e.target.value;
                // setRowItem(row);
                // setChkJAN1('0');
                // // setDisRow(true);
              }}
              // onKeyDown={(e) => onKeyDown(e.key,inJAN1,inJAN1,inBIN)}
              // onFocus={(e) => onMyFocus(e)}
              // onBlur={() => onblur(inJAN1)}
              />
          </Box>
          <Box>
            <NumericFormat  //SendNG
              id={'SENDNG'}
              inputRef={inSendNG}                 //項目名定義？
              color='success'
              thousandSeparator={false}           //1000単位の区切り
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='異常'                        //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 4), {background:inSendNGclr})}  //2023/11/07 入力項目の表示内容拡大
              value={sendng}                      //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                     //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onChange={(e)=>{
                // const row = JSON.parse(JSON.stringify(rowData));
                // row.JANCDE = e.target.value;
                // setRowItem(row);
                // setChkJAN1('0');
                // // setDisRow(true);
              }}
              // onKeyDown={(e) => onKeyDown(e.key,inJAN1,inJAN1,inBIN)}
              // onFocus={(e) => onMyFocus(e)}
              // onBlur={() => onblur(inJAN1)}
              />
          </Box>
        </Stack>
        <br/>
        <br/>
        <br/>
        <Box ml={2} flexDirection="row" justifyContent="flex-end" display="flex" marginTop={'5px'}>
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:50,mr:'120px'}}
              onClick={(e) => onClick_FooterButton_Back()}
              >
              {'戻る'}
            </Button>&nbsp;
          </Box >
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:100,mr:'5px'}}
              onClick={(e) => onClick_FooterButton_Next()}
              >
              {'発注明細'}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default TCSH0170;
