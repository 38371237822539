import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, Button, TextField, Grid, Paper, Typography, Stack } from "@mui/material";
import { NumericFormat } from 'react-number-format';    //数値入力
import { useNavigate, useLocation } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import { 
  DataGrid,
  jaJP                              // 日本語用のファイルをインポート
} from '@mui/x-data-grid';
// import { useOriginKbnAction } from './hooks/useOriginKbnAction'; // 原産地区分共通処理

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCHP0161 () {

  // const {OriginKbnList, OriginKbnComment, OriginKbnName, OriginKbnOptions, changeOriginKbnNo} = useOriginKbnAction();  // 原産地区分共通処理
  const navigate = useNavigate();
  const location = useLocation();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('');
  const [dismeisai, setMeisai] = useState(false);    //disabled設定（明細）
  const [sdate, setsDate] = useState([]);

  const [HASDD, setHASDD] = useState(''); // 発送日
  const [BMNCDE, setBMNCDE] = useState(''); // 部門コード
  const [BMNMEI, setBMNMEI] = useState(''); // 部門名
  const [UIR_MISE, setUIR_MISE] = useState(''); // 受取店コード
  const [UIR_MISEMEI, setUIR_MISEMEI] = useState(''); // 受取店名
  const [UIR_BMNCDE, setUIR_BMNCDE] = useState(''); // 受取部門コード
  const [UIR_BMNMEI, setUIR_BMNMEI] = useState(''); // 受取部門名
  const [ASTCDE, setASTCDE] = useState(''); //AUコード
  const [ASTMEI, setASTMEI] = useState(''); //AU店名
  const [UIR_ASTCDE, setUIR_ASTCDE] = useState(''); //受取AUコード
  const [UIR_ASTMEI, setUIR_ASTMEI] = useState(''); //受取AU店名
  const [MISE, setMISE] = useState(''); //店コード
  const [TENPONM, setTENPONM] = useState(''); //店舗名
  const [DENNO, setDENNO] = useState(''); // 伝票番号
  const [KENLIST, setKENLIST] = useState([]); // 都道府県区分
  const [KBNLIST, setKBNLIST] = useState([]); // 原産地区分
  const [SYOKUKBNLIST, setSYOKUKBNLIST] = useState([]);                 // 伝票区分リスト
  const [rows, setRows] = useState([]);

  const [totalSuryo, setTotalSuryo] = useState(0);     //数量合計
  const [totalGenka, setTotalGenka] = useState(0);     //原価金額合計
  const [totalBaika, setTotalBaika] = useState(0);     //売価金額合計

  const inHASDD = useRef(null);
  const inUIR_AST = useRef(null);
  const inMISE = useRef(null);
  const inBMNCDE = useRef(null);
  const inAST = useRef(null);
  const inDENNO = useRef(null);
  const inGNKFOT = useRef(null);
  const inBAKFOT = useRef(null);
  const inSRYFOT = useRef(null);

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応


  useEffect (()=>{
      if (state.KENGEN === '')
      {
        console.log('直リン不可'+state.KENGEN);
        navigate('/');
      }else
      {
        console.log('メニュー:%o',state)
      }
      let d = new Date(state.SDAY);
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      let day = d.getDate();
      setsDate(year + '/' + month + '/' + day);
  
      if (location.state != null) {
        const locationState = location.state;
        // rowsからselectionModelで一致した情報を取得
        const filteredRows = locationState.grid.rows.filter((item) => {
          return locationState.grid.selectionModel.includes(item.id);
        });
        console.log('引継ぎパラメータ:%o', filteredRows);
        // 引継ぎデータでパラメータを設定する
        setUIR_ASTCDE(filteredRows[0].UIR_ASTCDE); // 受取AU
        setUIR_ASTMEI(filteredRows[0].UIR_ASTMEI); // 受取AU名
        setASTCDE(filteredRows[0].ASTCDE); // AU
        setASTMEI(filteredRows[0].ASTMEI); // AU名
        setMISE(filteredRows[0].KEY.MISE); // 店コード
        setTENPONM(filteredRows[0].TENPONM); // 店舗名

        // DBからデータ取得
        const data = getIdouMeisaiShokai(filteredRows);
        data.then(async result => {
          if (result != null) {
            console.log(result);
            // ヘッダ作成
            // 日付をYY/MM/DD形式に変換
            if (result[0].HASDD) {
              // 念のためnull,空文字チェックして変換
              // ステータスに格納
              setHASDD(convertStringDate(result[0].HASDD));
            }
            else {
              setHASDD(result[0].HASDD);
            }
  
            setBMNCDE(result[0].BMNCDE); // 部門コード
            setBMNMEI(result[0].BMNMEI); // 部門名
            setUIR_MISE(result[0].UIR_MISE); // 受取店コード
            setUIR_MISEMEI(result[0].UIR_MISEMEI); // 受取店名
            setUIR_BMNCDE(result[0].UIR_BMNCDE); // 受取部門コード
            setUIR_BMNMEI(result[0].UIR_BMNMEI); // 受取部門名
            setDENNO(result[0].DENNO); // 伝票番号
   
            // rows作成
            let id = 1;
            let newRows = [];
            result.forEach(element => {
              newRows.push({ 
                id: id,
                no: element.GYONO,
                SHNMEIKN: {
                  SHNMEIKN: element.SHNMEIKN,
                  KIKAKUKN: element.KIKAKUKN,
                  JANCDE:   element.JANCDE,
                  TRIHBN:   element.TRIHBN,
                  SIZCDE:   element.TAGSIZ,
                  TAGSIZMEI:element.TAGSIZMEI,
                  TAGCOLCDE:element.TAGCOLCDE,
                  TAGCOLMEI:element.TAGCOLMEI
                },
                SUU: element.SUU,
                GNT: element.GNT,
                GNKKIN: element.GNKKIN,
                TODOFUKEN: element.TODOFUKEN_KUNI,
                BTN: element.BTN,
                BAKKIN: element.BAKKIN,
                SANTIKBN: element.SANTI_KBN,
                GENSANTI: element.GENSANTI,
                SKHLBLKBN: element.SKHLBLKBN,
                IRYO_FLG: element.IRYO_FLG,
              });
              id++;
            });
            setRows(newRows);
            
            console.log('都道府県マスタの取得');
            const data = getOriginKbnList(filteredRows);
            data.then(async result => {
              if (result != null) {
                console.log(result);

                // rows作成
                let id = 1;
                let newRows = [];
                result.forEach(element => {
                  newRows.push({
                    id: id,
                    SANTIKBN: element.SANTIKBN,
                    SANTINAM: element.SANTINAM,
                    BMNCDE: element.BMNCDE,
                  });
      
                  id++;
                });
                setKENLIST(newRows);
              }
            });

            console.log('食品区分リストの取得')
            await getSyokuKbn();

            console.log('原産地区分リストの取得')
            await getDenKbnList();

            // 合計数量
            const totalSuryo = newRows.reduce((sum, element) => sum + parseFloat(element.SUU), 0);
            setTotalSuryo(totalSuryo);
            // 原価金額合計
            const totalGenka = newRows.reduce((sum, element) => sum + parseInt(element.GNKKIN), 0);
            setTotalGenka(totalGenka);
            // 売価金額合計
            const totalBaika = newRows.reduce((sum, element) => sum + parseInt(element.BAKKIN), 0);
            setTotalBaika(totalBaika);
          }
        });
      }  
  }, []);

  const getIdouMeisaiShokai = async (filteredRows) => {
    // バックエンドから取得
    const response = await fetch('/TCID0161/getIdouMeisaiShokai',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(filteredRows)
    });
    return response.json();
  };

  const getOriginKbnList = async(filteredRows) => {
    // 原産地区分情報（使用可能区分）取得
    const response = await fetch('/TCID0161/getMrPrdReg',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(filteredRows)
    });
    return response.json();
  };

    // 食品区分取得
    const getSyokuKbn = async() => {
      // 食品区分情報(使用可能区分）取得
      const getSyokuKbnCd = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({CDE: '007',SCDE: '0000'})});
      const syokuKbnCdList = await getSyokuKbnCd.json();
  
      // 食品区分情報(区分に対する名称）取得
      const getSyokuKbnName = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({CDE: '007',SCDE: '0010'})});
      const syokuKbnNameList = await getSyokuKbnName.json();
  
      // 伝票区分リスト化
      setSYOKUKBNLIST([]);
      setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE01,name:syokuKbnNameList.DATE01}]);
      setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE02,name:syokuKbnNameList.DATE02}]);
      setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE03,name:syokuKbnNameList.DATE03}]);
      setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE04,name:syokuKbnNameList.DATE04}]);
      setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE05,name:syokuKbnNameList.DATE05}]);
      setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE06,name:syokuKbnNameList.DATE06}]);
      setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE07,name:syokuKbnNameList.DATE07}]);
      setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE08,name:syokuKbnNameList.DATE08}]);
      setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE09,name:syokuKbnNameList.DATE09}]);
      setSYOKUKBNLIST((syokuKbnList) => [...syokuKbnList ,{cd:syokuKbnCdList.DATE10,name:syokuKbnNameList.DATE10}]);
    };

    // 伝票区分取得
    const getDenKbnList = async() => {
      // 伝票区分情報(使用可能区分）取得
      // const getDenKbnCd = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      // body: JSON.stringify({CDE: '004',SCDE: '0000'})});
      const getDenKbnCd = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({CDE: '009',SCDE: '0000'})});
      const denKbnCdList = await getDenKbnCd.json();

      // 伝票区分情報(区分に対する名称）取得
      // const getDenKbnName = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      // body: JSON.stringify({CDE: '004',SCDE: '0010'})});
      const getDenKbnName = await fetch('/SL_SET_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({CDE: '009',SCDE: '0010'})});
      const denKbnNameList = await getDenKbnName.json();

      // 伝票区分リスト化
      setKBNLIST([]);
      setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE01,name:denKbnNameList.DATE01}]);
      setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE02,name:denKbnNameList.DATE02}]);
      setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE03,name:denKbnNameList.DATE03}]);
      setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE04,name:denKbnNameList.DATE04}]);
      setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE05,name:denKbnNameList.DATE05}]);
      setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE06,name:denKbnNameList.DATE06}]);
      setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE07,name:denKbnNameList.DATE07}]);
      setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE08,name:denKbnNameList.DATE08}]);
      setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE09,name:denKbnNameList.DATE09}]);
      setKBNLIST((denKbnList) => [...denKbnList ,{cd:denKbnCdList.DATE10,name:denKbnNameList.DATE10}]);
    };  
  

  // YYorMMorDD変換用
  const  zeroPadding = (val,length) =>  ( "00" + val).slice(-length); 
  // YY/MM/DD形式に変換
  const convertStringDate = (val) => {
    let year = zeroPadding(parseInt(val.substring(2, 4)), 2);
    let month = zeroPadding(parseInt(val.substring(4, 6)), 2);
    let day = zeroPadding(parseInt(val.substring(6, 8)), 2);

    return year + '/' + month + '/' + day;
  };

  // 金額表示フォーマット
  const formatCurrency = (amount, decimalPoint = 0) => {
    if(amount === null){ return amount; }

    return parseFloat(amount).toLocaleString('ja-JP', {
      style: 'currency',
      currency: 'JPY',
      minimumFractionDigits: decimalPoint,
    });
  }

  // 数量フォーマット
  const formatSUU = (amount) => {
    if(amount === null){ return amount; }

    // 数値が整数かどうかを判定
    if (Number.isInteger(amount)) {
      return amount.toLocaleString('ja-JP', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
      });
    } else {
      return amount.toLocaleString();
    }
  }

  //TODO 受け取ったデータ(location.stateのdetailKeyとsearchCondition)をもとにデータを検索。
  //検索データを元に設計書の指示通りのデータを絞り込み、各項目にデータをセットする。

  const onKeyDown =  async (key,inputRef,inputNxRef) => {  //  入力キー、現在の項目情報、次項目情報
    //console.log(key);
    switch (key) {
      case "Enter":
        inputNxRef.current.focus();   //次の項目に遷移
        
        break;
      case "TCHP0160":
        navigate('/TCHP0160', { state: location.state });
        break;
      case "Home":
        break;
      case "ArrowUp":
        inputRef.current.focus();   //次の項目に遷移
        break;
      case "ArrowDown":
        inputNxRef.current.focus();   //次の項目に遷移
        break;
      default:
        break;
    }
  };  

  //罫線表示（Data Grid）
  const gridStyle = {
    transform: 'scale(0.9)',
    // 他のスタイルプロパティをここに追加することもできます
  };

  // グリッド列情報
  const columns/*: GridColDef[]*/ = [
    // {
    //   field: 'detailAction',
    //   headerName: '　', // セルの右側に罫線を表示するために全角スペースを表示する
    //   align: 'left',
    //   width: 60,
    //   type: 'actions',
    //   // getActions: getDetailAction,
    //   description: '詳細画面を表示',  // ツールチップで表示される説明
    //   hideable: false,          // 非表示にできないようにする
    //   sortable: false,          // ソートできないようにする
    //   hideSortIcons: false,     // ソートアイコンを表示しない
    //   resizable: false,         // リサイズできないようにする
    //   disableExport: false,     // エクスポートに含めないようにする
    //   disableReorder: false,    // 並べ替えできないようにする
    //   filterable: false,        // フィルタリングしないようにする
    //   disableColumnMenu: true  // カラムメニューを表示しない
    // } ,

    //IDは必ず必要みたい
    { 
      field: 'id', 
      headerName: 'No.', 
      align:'center',
      width: 50 ,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true   // カラムメニューを表示しない
    }, 
    // { field: 'no', 
    //   headerName: 'No.', 
    //   headerAlign: 'center',
    //   width: 50 ,
    //   sortable: false,          // ソートできないようにする
    // },
    {
      field: 'SHNMEIKN',
      headerName: '商品名',
      headerAlign: 'center',
      width: 300,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // 衣料フラグによってレンダリング内容を切り替え
      renderCell: params => {
        console.log(params.id + ':IRYO_FLG=' + params.row.IRYO_FLG + ' result=' + params.row.IRYO_FLG == '1');
        // 衣料フラグが'1'の場合、商品名欄下段を取引先品番+サイズコード+サイズ名+カラーコード+カラー名で表示する
        if(params.row.IRYO_FLG == '1') {
          return (
            <div>
              <Typography>{params.value.SHNMEIKN}</Typography>
              <Typography color="textSecondary">{
                params.value.TRIHBN + ' ' +
                params.value.SIZCDE +
                params.value.TAGSIZMEI.substr(0, 8) + ' ' +
                params.value.TAGCOLCDE +
                params.value.TAGCOLMEI.substr(0, 8)
              }</Typography>
            </div>
          )
        }
        // 衣料フラグが'1'以外の場合、商品名欄下段を規格内容+JANコードで表示する
        else {
          return (
            <div>
              <Typography>{params.value.SHNMEIKN}</Typography>
              <Typography color="textSecondary">{
                params.value.KIKAKUKN + ' ' +
                params.value.JANCDE
              }</Typography>
            </div>
          )
        }
      }        
      
    },
    {
      field: 'SUU',
      headerName: '数量',
      type: 'number',
      headerAlign: 'center',
      width: 80,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (formatSUU(params.value))
    },
    {
      field: 'GNT',
      headerName: '原単価',
      type: 'number',
      headerAlign: 'center',
      width: 100,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{formatCurrency(params.value, 2)}</Typography>
        </div>
      )
    },
    {
      field: 'GNKKIN',
      headerName: '原価金額',
      type: 'number',
      headerAlign: 'center',
      width: 110,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{formatCurrency(params.value)}</Typography>
        </div>
      )
    },
    {
      field: 'BTN',
      headerName: '売単価',
      type: 'number',
      headerAlign: 'center',
      width: 110,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{formatCurrency(params.value)}</Typography>
        </div>
      )
    },
    {
      field: 'BAKKIN',
      headerName: '売価金額',
      type: 'number',
      headerAlign: 'center',
      width: 110,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{formatCurrency(params.value)}</Typography>
        </div>
      )
    },
    {
      field: 'GENSANTI',
      headerName: '原産地',
      headerAlign: 'center',
      align:'left',
      width: 258,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: params => {
        // 産地区分マスタと紐づけ
        let kbnMei = '';
        let gensantiMei = '';
        let syokuhinMei = '';
        // 都道府県国で都道府県マスタの一致する区分を探す
        KENLIST.forEach(element => {
          if (element.SANTIKBN == params.row.TODOFUKEN) {
            kbnMei = element.SANTINAM;
          }
        }); 
        // 食品区分で食品マスタの一致する区分を探す
        SYOKUKBNLIST.forEach(element => {
          if (element.cd == params.row.SKHLBLKBN) {
            syokuhinMei = element.name;
          }
        }); 

        // 産地区分が1もしくは2の場合
        if ((params.row.SANTIKBN === '1') || (params.row.SANTIKBN === '2')) {
          // 「産」を付与して設定
          gensantiMei = kbnMei + '産';
        // 産地区分が3の場合
        } else if ((params.row.SANTIKBN === '3')) {
          // 原産地に値が存在するか？
          if(params.row.GENSANTI) {
            // 原産地で産地区分マスタの一致する区分を探す
            // console.log('GENSANTI:'+params.row.GENSANTI);
            KBNLIST.forEach(element => {
              // console.log('element.cd:'+element.cd);
              if (element.cd == params.row.GENSANTI) {
                gensantiMei = '原料原産地　' + kbnMei + '（' + element.name + '）' +'　など';
              }
            }); 
          // それ以外
          } else {
            gensantiMei = '原料原産地　' + kbnMei + '　など';
          }
        }
        return (
          <div>
            {/* <Typography>{gensantiMei}</Typography> */}
            {/* <Typography>{syokuhinMei}</Typography> */}
            <Typography sx={{fontSize:14}}>{gensantiMei}</Typography>
            <Typography sx={{fontSize:14}}>{syokuhinMei}</Typography>
          </div>
        )
      }
    },
  ];

  //罫線表示（Data Grid）
  const styles = {
    grid: {
      '.MuiDataGrid-toolbarContainer': {
        borderBottom: 'solid 1px rgba(224, 224, 224, 1)'  // ついでにツールバーの下に罫線を引く
      },
      '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
        borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      // 2022/06/01 追記 列ヘッダの最終セルの右側に罫線が表示されなくなった・・・
      // 列ヘッダの最終列の右端に罫線を表示する
      '.MuiDataGrid-columnHeadersInner': {
        border: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      '.MuiDataGrid-columnSeparator--sideRight':{
        visibility: 'show',
      },
      '.MuiDataGrid-columnHeaders':{
        height:'40px',
      }
    }
  }
  return (
    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "750px",
          width: "1002px",
          m: "10px auto",
          padding:"10px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="left"
        >
          <div style={{display:"flex"}}>
            <Typography variant={"h5"} sx={{ m: '5px',height:'30px' }}>
            移動明細照会
            </Typography>
            <Box width={'65%'} display={'flex'} justifyContent={'flex-end'}>
              <Typography variant={"h6"} sx={{ m: "13px" }}>
                {state.MISEMEI}：{state.JGNMEIKNJ}
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px" }}>
                { sdate }
              </Typography>
            </Box>
          </div>
        </Grid>

        <Stack direction="row" sx={{mt:'-20px',mb:'-10px'}}>
        <TextField  //受取部門
              id={'BMNCDE'}
              inputRef={inBMNCDE}                   //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り           
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='受取部門'                       //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}           //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '18ch',marginLeft:'20px'}}                 //textboxの幅
              value={UIR_BMNCDE + ' ' + UIR_BMNMEI}                         //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              onFocus={(e) => e.target.select()}
              />
        <TextField  //受取AU
              id={'UIR_AST'}
              inputRef={inUIR_AST}                   //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り           
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='受取AU'                       //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}           //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '18ch',marginLeft:'20px'}}                 //textboxの幅
              value={UIR_ASTCDE + ' ' + UIR_ASTMEI}                         //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              onFocus={(e) => e.target.select()}
              />
          <TextField  //発送日
              id={'HASDD'}
              inputRef={inHASDD}                   //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り           
              size='small'                        //textboxの全体サイズ
              label='発送日'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}            //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '10ch',marginLeft:'20px'}}                 //textboxの幅
              value={HASDD}                  //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 6,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              />
          <NumericFormat  //伝票No.
              id={'DENNO'}
              inputRef={inDENNO}                   //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り           
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='伝票No.'                         //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}            //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '12ch',marginLeft:'20px'}}                 //textboxの幅
              value={DENNO}               //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 7,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              onFocus={(e) => e.target.select()}
              />
        </Stack>
        <br />
        <Stack direction="row">
        <TextField  //発送店
              id={'MISE'}
              inputRef={inMISE}                   //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り           
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='発送店'                       //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}           //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '18ch',marginLeft:'20px'}}                 //textboxの幅
              value={MISE + ' ' + TENPONM}                         //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              // onKeyDown={(e) => onKeyDown(e.key,inMISE,inDENKBN)}
              onFocus={(e) => e.target.select()}
              />
          <TextField  //発送部門
              id={'BMNCDE'}
              inputRef={inBMNCDE}                   //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り           
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='発送部門'                       //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}           //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '18ch',marginLeft:'20px'}}                 //textboxの幅
              value={BMNCDE + ' ' + BMNMEI}                         //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              onFocus={(e) => e.target.select()}
              />
          <TextField  //発送AU
              id={'AST'}
              inputRef={inAST}                   //項目名定義？
              color='success'
              autoFocus                           //初期カーソル位置設定
              thousandSeparator={false}           //1000単位の区切り           
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='発送AU'                       //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              fixedDecimalScale={false}           //小数点On/Off
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              sx={{width: '18ch',marginLeft:'20px'}}                 //textboxの幅
              value={ASTCDE + ' ' + ASTMEI}                         //初期値
              allowLeadingZeros                   //前ゼロ表示 on
              disabled={true}                    //使用可／不可
              inputProps={{ autoComplete: 'off',maxLength: 4,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              onFocus={(e) => e.target.select()}
              />
        </Stack>
        <br />

          <Box style={gridStyle} sx={{ height: 423, width: '1120px' ,ml:'-60px',mt:'-25px' }}>
            <DataGrid
              sx={styles.grid}     // --> 不具合対応のためcssで罫線を引く
              rows={rows}
              columns={columns}
              density='standard'//compact（狭め）、standard（標準）、comfortable（広め）
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              disableRowSelectionOnClick
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}  // メニュー日本語化
              showColumnRightBorder                                             // 列ヘッダセルの右側に線を引く
              showCellRightBorder                                               // セルの右側に線を引く
              />
          </Box>
          <Box my={2} flexDirection="row" justifyContent="flex-end" display="flex">
            <Box ml={2} sx={{mt:'-20px',mr:'5px'}}>
              <NumericFormat  //合計数量
                id={'SRYFOT'}
                inputRef={inSRYFOT}                   //項目名定義？
                thousandSeparator={','}
                decimalScale={1}
                fixedDecimalScale={true}            //小数点On/Off
                color='success'
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='合計数量'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '17ch'}}                //textboxの幅
                value={totalSuryo}                  //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={true}                    //使用可／不可
                inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
                // onKeyDown={(e) => onKeyDown(e.key,inDENH,inTSEBAKFOT)}
                onFocus={(e) => e.target.select()}
                />　
              <NumericFormat  //原価金額合計
                id={'GNKFOT'}
                inputRef={inGNKFOT}                   //項目名定義？
                thousandSeparator={','}
                decimalScale={0}
                fixedDecimalScale 
                color='success'
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='総原価金額合計'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '17ch'}}                 //textboxの幅
                value={totalGenka}               //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={true}                    //使用可／不可
                inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
                // onKeyDown={(e) => onKeyDown(e.key,inDENH,inTSEBAKFOT)}
                onFocus={(e) => e.target.select()}
                />　
              <NumericFormat  //売価金額合計
                id={'BAKFOT'}
                inputRef={inBAKFOT}                   //項目名定義？
                thousandSeparator={','}
                decimalScale={0}
                fixedDecimalScale 
                color='success'
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='総売価金額合計'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '17ch'}}                 //textboxの幅
                value={totalBaika}               //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={true}                    //使用可／不可
                inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
                // onKeyDown={(e) => onKeyDown(e.key,inTSEGNKFOT,inRET)}
                onFocus={(e) => e.target.select()}
                />
          </Box>
        </Box>
        <Box my={2} flexDirection="row" justifyContent="flex-end" display="flex">
          <Box ml={2}>
            <Button 
              variant="contained" 
              color='success' 
              sx={{width:100}}
              onClick={(e) => onKeyDown('TCHP0160','','') }
              >
              戻　る
            </Button>　
          </Box>
        </Box>
        </Paper>
    </Grid>
  );

};

export default TCHP0161;