import React, { useState,useContext,useEffect } from 'react'
import { Box, TextField, Grid, Paper, Typography, Stack, InputLabel } from "@mui/material";
import { NumericFormat } from 'react-number-format';    //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';

import CustomAlert from './CustomAlert';
import './common.css'

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F5キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F9キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSA1110 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [alert, setAlert] = useState('ファンクションキーを押してください。');
  const [sdate, setsDate] = useState([]);
  const [mmsg, setMmsg] = useState('未処理件数:問合せ中');
  const [fload, setfload] = useState(true);

// アラート↓↓↓
const [isAlertVisible, setAlertVisible] = useState(false);
const [alertMessage, setAlertMessage] = useState('');
// アラートを表示
const showAlert = (message) => {
  window.glAlertDialogFlg = true;
  setAlertMessage(message);
  setAlertVisible(true);
};
// アラートを閉じる
const closeAlert = () => {
  window.glAlertDialogFlg = false;
  setAlertMessage('');
  setAlertVisible(false);
};
// アラートここまで↑↑↑

//ファンクションキー対応
  const handleKeyDown = (event) => {
    if (event.keyCode === 112) { // F1キーが押された場合の処理
      dbsyori();
      setAlert('正常に処理が完了しました。');
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      navigate('/TCSA0003');
    }
    if (event.keyCode === 120) { // F9キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応
  
  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);
    msyoricnt();

  },[fload]);

  const dbsyori = async() =>{
    //送信更新処理
    console.log('MISECD:'+state.MISES);
    const hostName = window.location.hostname;
    const response2 = await fetch('/TCSA1110/SL_HED_DAT_upd',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ent_ksacde1: state.KSACDES,ent_mise1: state.MISES,
      ent_ksacde2: state.K_KSACDES,ent_mise2: state.K_MISES,
      upd_jgncde:state.JGNCDE,trl_id:hostName})});
    const data2 = await response2.json();
    console.log('伝票ヘッダ:%o',data2);
    
    //エラー処理追加
    if (data2.res == -1) {
      // console.log('伝票ヘッダ:%o',data2);
      setAlert('送信データがありませんでした。');
      // window.alert('送信データがありませんでした。');
      showAlert('送信データがありませんでした。');
      return;
    }
    msyoricnt();
  }

  const msyoricnt = async() =>{
    //送信更新処理
    console.log('MISECD:'+state.MISES);
    const hostName = window.location.hostname;
    const responseM = await fetch('/TCSA1110/SL_HED_DATM_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({ent_ksacde1: state.KSACDES,ent_mise1: state.MISES,
      ent_ksacde2: state.K_KSACDES,ent_mise2: state.K_MISES,
      upd_jgncde:state.JGNCDE,trl_id:hostName})});
    const dataM = await responseM.json();
    console.log('伝票ヘッダ:%o',dataM);
    
    //エラー処理追加
    if (dataM.res[0].MCNT != 0) {
      setMmsg('未送信件数:　'+dataM.res[0].MCNT+'件');
    }else{
      setMmsg('未送信件数:　0件');
    }
    return;
  }

  // const dbsyori = async() =>{
  //   //送信更新処理
  //   console.log('処理開始');
  //   //const hostName = window.location.hostname;
  //   const data = {
  //     "files":[
  //       {
  //         "status":'9',
  //         "mise":'101',
  //         "sectime":'20230902133402',
  //         "filename":'確認リスト（明細）（仕入ターン）',
  //         "filepath":'http://slip-entry-m.ap-northeast-1.elasticbeanstalk.com/data/101/SAPRT0080_20230902133402.pdf'
  //       },
  //       {
  //         "status":'9',
  //         "mise":'101',
  //         "sectime":'20230902134240',
  //         "filename":'確認リスト（明細）（仕入ターン）',
  //         "filepath":'http://slip-entry-m.ap-northeast-1.elasticbeanstalk.com/data/101/SAPRT0110_20230902134240.pdf'
  //       }
  //     ]
  //   };
  //   const response2 = await fetch('/TCSAPRINU/SL_PRT_MST_upd',{method: 'POST',headers: {'Content-Type': 'application/json'},
  //   body: JSON.stringify(data)});
  //   const data2 = await response2.json();
  //   console.log('伝票ヘッダ:%o',data2);
  // }

  useEffect(() => {
    // // イベントリスナーを登録
    // window.addEventListener('keydown', (e) => {
    //   if (e.key=='F1')
    //   {
    //     e.preventDefault();
    //     dbsyori();
    //     setAlert('TCGM0003：正常に処理が完了しました。');
    //   }
    //   if (e.key=='F5')
    //   {
    //     e.preventDefault();
    //     navigate('/TCSA0003');
    //   }
    // });
  }, []);

  // 確認のWidthを設定する
  const cssInputOutlineRetSetwidth = (obj) => {
    let resObj = obj;
    resObj.width = '50px';
    return resObj;
  }

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
          fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
  }

  return (
    <Grid>
      <CustomAlert isVisible={isAlertVisible} message={alertMessage} onClose={closeAlert} />
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "500px",
          width: "900px",
          m: "10px auto",
          padding:"10px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="left"
        >
          <div style={{display:"flex"}}>
            <Typography variant={"h5"} sx={{ m: "10px" }}>
              データ送信確定処理
            </Typography>
            <Box width={'65%'} display={'flex'} justifyContent={'flex-end'}>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"17px",fontSize:15 }}>
                {state.MISEMEI}：{state.JGNMEIKNJ}
              </Typography>
              <Typography variant={"h6"} sx={{ m: "13px",mt:"17px",fontSize:15 }}>
                { sdate }
              </Typography>　　　　　
            </Box>
          </div>
        </Grid>
        <br />
        <Stack direction="row">
          <InputLabel variant={'outlined'}>データ送信処理をを行いますか？</InputLabel>    
        </Stack>
        <br/><br/>
        <Stack direction="row">
          <InputLabel variant={'outlined'}>　　Ｆ１ を押すと処理が実行されます。</InputLabel>    
        </Stack>
        <br/>
        <Stack direction="row">
          <InputLabel variant={'outlined'}>　　Ｆ５ を押すと元の画面に戻ります。</InputLabel>    
        </Stack>
        <br />        
        <Stack direction="row">
          <InputLabel variant={'outlined'}>　　<font size="5"><b>{mmsg}</b></font></InputLabel>
        </Stack>
        <br />
        <Stack direction="row" >
          <Grid>
            <div>　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　</div>
          </Grid>                   
          <NumericFormat  //確認
                id={'ret'}
                thousandSeparator={false}           //1000単位の区切り           
                color='success'
                autoFocus                           //初期カーソル位置設定
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='確認'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                type='tel'                          //[PDA、Tablet対応]入力モード：tel
                inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
                fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
                decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={Object.assign({},cssInputOutlineRetSetwidth(styles.cssInputOutline))}
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={false}                    //使用可／不可
                inputProps={{ autoComplete: 'off',maxLength: 0,style: {textAlign: 'center', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ
                />  
        </Stack>
        <br />
        <br />
        <font color="red">{ alert }</font>
        <Stack direction="row" spacing={2}>
          <font color="black">{ 'Ｆ１：処理実行　Ｆ５：終了　　　　　　　　　　　　　　　　　　' }</font>
        </Stack>
      </Paper>
    </Grid>
  );
};

export default TCSA1110;