import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, Button,Stack, Grid, Paper, Typography, InputLabel, MenuItem, FormControl, TextField, Select } from "@mui/material";
import Table from '@mui/material/Table';              //テーブル用
import TableBody from '@mui/material/TableBody';      //テーブル用
import TableCell from '@mui/material/TableCell';      //テーブル用
import TableRow from '@mui/material/TableRow';        //テーブル用
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import {
  DataGrid,
  jaJP                              // 日本語用のファイルをインポート
} from '@mui/x-data-grid';
import FullPageSpinner from './FullPageSpinner.js'; // スピナーコンポーネントをインポート

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSH0151 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [ksacde, setKsacde] = useState('');
  const [shnmeikn, setShnmeikn] = useState('');
  const [ksanm, setKsanm] = useState('');
  const [misenm, setMisenm] = useState('');
  const [alert, setAlert] = useState('');
  const [K_KSACDE,setK_KSACD] = useState('');
  const [K_MISE,setK_MISE] = useState('');
  const [bmncde,setBmncde] = useState(state.BMNCDE);
  const [sdate, setsDate] = useState([]);     // 本日日付（YYYY/MM/DD)
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [pagenationModel, setPagenationModel] = useState({page: 0, pageSize: 10});

  const inKSACDE = useRef(null);
  const inMISE = useRef(null);
  const inBMNCDE = useRef(null);

  //項目のバックカラー制御用
  const [inBMNCDEclr,setBMNCDEclr] = useState('#FFFFFF');

  //スピナー表示
  const [loading, setLoading] = useState(false);

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    // 多重防止
    // if (!keyDisabled) {
    //   setKeyDisabled(true);
    // } else {
    //   return;
    // }

    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      // if (state.MVEMGAMEN==='')
      // {
      //   navigate('/');
      // }else{
      //   navigate('/TCSA0003');
      // }      
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  const handleChange = (event, SelectChangeEvent) => {
    setKsacde(event.target.value);
    switch(event.target.value)
    {
      case '001':
        setKsanm('オークワ');
        break;
      case '003':
        setKsanm('オーク');
        break;
      case '005':
        setKsanm('パーティハウス');
        break;
      case '008':
        setKsanm('オー・エンターテイメント');
        break;
      default:
        break;
    };
    
    inMISE.current.focus();
    inMISE.current.select();
  };

  // 初期処理
  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);

    onClick_FooterButton_Next1();
    
  },[]);

  useEffect(() => {
  }, []);

  const n_tim = async() => {

    let now = new Date();

    let hours = now.getHours();
    let minutes = now.getMinutes();
    let seconds = now.getSeconds();
    let timer = hours + ':'+minutes+':'+seconds;
    return timer
  }

  // ボタン１押下時の処理（自動検索）
  const onClick_FooterButton_Next1 = async() => {
    // console.log('検索ボタン押下されました:%o',state.TRIHBN)
    console.log('onClick_FooterButton_Next1処理:'+await n_tim());
    let errflg = 0;

    // 明細データ抽出
    failedSearch();

    // テスト用★後で見直す
    try {
      const responseB1 = await fetch('/TCSH0101/getSKU', {method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          KSACDE: state.KSACDES,    // 会社コード
          MISE: state.MISES,        // 店コード
          TRIHBN: state.TRIHBN,     // 取引先品番
          RCNT:0})});
      const dataB1 = await responseB1.json();
      setTimeout(() => {setLoading(false)},100);
      console.log('/TCSH0101/getSKU処理結果:'+await n_tim());
      console.log('TimeOver:%o',dataB1);
      if (dataB1.CNT === 0) {
        // データが存在しない場合は、以下のメッセージを表示して、明細の0行表示を行う。
        window.alert('条件に一致するデータが存在しません。'); 
        failedSearch();
        return;
      }
      if (dataB1.CNT > dataB1.MAXCNT) {
        // 抽出結果が９９９件を超えてしまった場合は、再抽出を促すメッセージを表示する。
        window.alert('条件に一致するデータが多すぎます。条件を見直してください。'); 
        return;
      }
      if (errflg === 0 )
      {

        let id = 1;
        const newRows = [];
        dataB1.ret.forEach(element => {
          if(id === 1){
            setShnmeikn(element.SHNMEIKN);  // 商品名表示用
          }

          newRows.push({
            id: id,
            shnid: element.SHNID,           // 商品ID
            jancde1: element.JANCDE1,       // JANコード
            eoscde: element.EOSCDE,         // EOSコード
            tagcolmei: element.TAGCOLMEI,   // カラー名
            tagsizmei: element.TAGSIZMEI    // サイズ名カナ
            }
          );
          // console.log('チェックボックスON:'+await n_tim());
          console.log('element.JANCDE1.:'+element.JANCDE1);
          console.log('element.EOSCDE:'+element.EOSCDE);
          // チェックボックスON
          // if (element.JANCDE1.trim() !== ''){
          if (state.IN_CD_KBN === '1' ){
            if (state.JANCDE === element.JANCDE1)
              {
                // const  newSelectedId = GridRowId;
                setSelectionModel([id]);
              }
          }else{
            if (state.EOSCDE === element.EOSCDE)
              {
                // const newSelectedId = id;
                setSelectionModel([id]);
              }
          }
          id++;
        });
        console.log('newRows:%o',newRows)
        setRows(newRows);
      }      
    }catch (error) {
      console.log('error_time:'+await n_tim());
      console.log('error_msg:%o',error);
      setTimeout(() => {setLoading(false)},100);
    }
  }

  // ボタン２押下時の処理
  const onClick_FooterButton_Next2 = async() => {
    const selected = rows.filter(x => selectionModel.indexOf(x.id) != -1);
    if (selected.length === 0) {
      window.alert('商品が選択されていません。再度選択してください。'); 
      return;
    }else if (selected.length !== 1) {
      window.alert('商品が複数選択されています。'); 
      return;
    }

    console.log('選択行情報:%o',selected);
    switch(state.MVEMGAMEN)
    {
      case 'TCSH0101':
        dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
        KSACDES : state.KSACDES,MISES: state.MISES,K_KSACDES : state.K_KSACDE,K_MISES: state.K_MISE,
        MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
        SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: state.K_FLG,SDAY: state.SDAY,BUTTON:state.BUTTON, MVEMGAMEN:'TCSH0151', 
        BMNCDE:state.BMNCDE,BRUMEI:state.BRUMEI,GRPCDE:state.GRPCDE,IRYOFLG:state.IRYOFLG,HTTYMD:state.HTTYMD,HTTKBN:state.HTTKBN,
        IN_CD_KBN:state.IN_CD_KBN,JANCDE:selected[0].jancde1,JANCDE2:'',EOSCDE:selected[0].eoscde
        });
        navigate('/TCSH0101');
        break;
      case 'TCSH0201':
        dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
        KSACDES : state.KSACDES,MISES: state.MISES,K_KSACDES : state.K_KSACDE,K_MISES: state.K_MISE,
        MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
        SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: state.K_FLG,SDAY: state.SDAY,BUTTON:state.BUTTON, MVEMGAMEN:'TCSH0151', 
        BMNCDE:state.BMNCDE,BRUMEI:state.BRUMEI,GRPCDE:state.GRPCDE,IRYOFLG:state.IRYOFLG,HTTYMD:state.HTTYMD,HTTKBN:state.HTTKBN,
        IN_CD_KBN:state.IN_CD_KBN,JANCDE:selected[0].jancde1,JANCDE2:'',EOSCDE:selected[0].eoscde
        });
        navigate('/TCSH0201');
        break;
      default:
        break;
    }
  }

  // 戻るボタン押下時の処理
  const onClick_FooterButton_Back = async() => {
    switch(state.MVEMGAMEN)
    {
      case 'TCSH0101':
        dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
        KSACDES : state.KSACDES,MISES: state.MISES,K_KSACDES : state.K_KSACDE,K_MISES: state.K_MISE,
        MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
        SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: state.K_FLG,SDAY: state.SDAY,BUTTON:state.BUTTON, MVEMGAMEN:'TCSH0151', 
        BMNCDE:state.BMNCDE,BRUMEI:state.BRUMEI,GRPCDE:state.GRPCDE,IRYOFLG:state.IRYOFLG,HTTYMD:state.HTTYMD,HTTKBN:state.HTTKBN,
        IN_CD_KBN:state.IN_CD_KBN,JANCDE:state.JANCDE,JANCDE2:state.JANCDE2,EOSCDE:state.EOSCDE
        });
        navigate('/TCSH0101');
        break;
      case 'TCSH0201':
        dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
        KSACDES : state.KSACDES,MISES: state.MISES,K_KSACDES : state.K_KSACDE,K_MISES: state.K_MISE,
        MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
        SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: state.K_FLG,SDAY: state.SDAY,BUTTON:state.BUTTON, MVEMGAMEN:'TCSH0151', 
        BMNCDE:state.BMNCDE,BRUMEI:state.BRUMEI,GRPCDE:state.GRPCDE,IRYOFLG:state.IRYOFLG,HTTYMD:state.HTTYMD,HTTKBN:state.HTTKBN,
        IN_CD_KBN:state.IN_CD_KBN,JANCDE:state.JANCDE,JANCDE2:state.JANCDE2,EOSCDE:state.EOSCDE
        });
        navigate('/TCSH0201');
        break;
      default:
        break;
    }
  }

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    //console.log(key);
    switch (key) {
      case "Enter":
        break;
      case "Home":
        break;
      case "ArrowUp":
        break;
      case "ArrowDown":
        break;
      default:
        break;
    }
  };  

  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  // 確認のWidthを設定する
  const cssInputOutlineRetSetwidth = (obj) => {
    let resObj = obj;
    resObj.width = '50px';
    return resObj;
  }

  // 検索失敗時の明細0件表示
  const failedSearch = async () => {
    await setRows([]);
  }

  // グリッド列情報
  const columns /*: GridColDef[]*/ = [
    // {
    //   field: 'detailAction',
    //   headerName: '　', // セルの右側に罫線を表示するために全角スペースを表示する
    //   align: 'left',
    //   width: 60,
    //   type: 'actions',
    //   // getActions: getDetailAction,
    //   description: '詳細画面を表示',  // ツールチップで表示される説明
    //   hideable: false,          // 非表示にできないようにする
    //   sortable: false,          // ソートできないようにする
    //   hideSortIcons: false,     // ソートアイコンを表示しない
    //   resizable: false,         // リサイズできないようにする
    //   disableExport: false,     // エクスポートに含めないようにする
    //   disableReorder: false,    // 並べ替えできないようにする
    //   filterable: false,        // フィルタリングしないようにする
    //   disableColumnMenu: true  // カラムメニューを表示しない
    // } ,

    //IDは必ず必要みたい
    {
      field: 'id',
      headerName: 'No.',
      // align:'center',
      width: 10 ,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
    },
    {
      // TAGCOLMEI
      field: 'tagcolmei',
      headerName: 'カラー名',
      headerAlign: 'center',
      // align:'center',
      width: 125 ,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{params.value}</Typography>
        </div>
      )
    },
    {
      // TAGSIZMEI
      field: 'tagsizmei',
      headerName: 'サイズ名',
      headerAlign: 'center',
      width: 145,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,  // カラムメニューを表示しない
      disableColumnSelector: true,
      renderCell: (params) => (
        <div>
          <Typography>{params.value}</Typography>
        </div>
      )
    },
  ];

  const handleSelectionModelChange = (newSelectionModel) => {
    // 新しい選択があった場合、その最後の要素のみを保持して更新
    if (newSelectionModel.length > 1) {
      // 最後に選択された1つのみを保持
      const newSelectedId = newSelectionModel[newSelectionModel.length - 1];
      setSelectionModel([newSelectedId]);
    } else {
      setSelectionModel(newSelectionModel);
    }
  };

  //罫線表示（Data Grid）
  const gridStyle = {
    transform: 'scale(0.9)',
    grid: {
      // x-data-gridの全体的な縮小
      '& .MuiDataGrid-root': {
        transform: 'scale(0.9)',
      },
      '.MuiDataGrid-toolbarContainer': {
        borderBottom: 'solid 1px rgba(224, 224, 224, 1)'  // ついでにツールバーの下に罫線を引く
      },
      '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
        borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      // 2022/06/01 追記 列ヘッダの最終セルの右側に罫線が表示されなくなった・・・
      // 列ヘッダの最終列の右端に罫線を表示する
      '.MuiDataGrid-columnHeadersInner': {
        border: 'solid 1px rgba(224, 224, 224, 1) !important',
        transform: 'scale(0.9)',
      },
      '.MuiDataGrid-columnSeparator--sideRight':{
        visibility: 'show',
      },
      '.MuiDataGrid-columnHeaders':{
        height:'40px',
      },
      // 削除済明細は背景色をグレーとする
      '.MuiDataGrid-row.row-deactive': {
        background: '#888888',
      },
      // 選択状態を黄色にする 2023/12/04 add imai
      '.MuiDataGrid-row.Mui-selected':{
        background: '#ffff00',
        '&:hover': {
          background: '#ffff00',       // ホバー時のバックグランドカラー
        },
      },
    }
  };

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
         fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '0.8rem'
      },
      fontSize: '0.8rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
    cssInputOutlineJotKbn: {
      '> div': {
        minHeight: 'auto !important',
        paddingTop: '.4em',
        paddingBottom: '.3em',
      }
    },
    cssInputSelect: {
      ' label': {
        color: '#000000'
      },
      ' fieldset': {
        borderColor: '#000000'
      },
    },
  }

  if (loading) {
    return <FullPageSpinner />;
  }

  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "470px",
          width: "280px",
          m: "20px auto"
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="center"
        >
          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
            <Typography variant={'h3'} sx={{ m: '5px',mt:'1px',fontSize:11,width:400,mr:'10px' }}>
            {state.MISES}:{state.BMNCDE} {state.MISEMEI}:{state.BRUMEI}<br/>{state.JGNMEIKNJ}
            </Typography>
            <Typography variant={'h3'} sx={{ m: '5px',mt:'17px',fontSize:11 ,width:110,mr:'1px'}}>
              { sdate }
            </Typography>
          </Box>
          {/* 画像の角丸対応 */}
          <Typography variant={"h6"} sx={{ m: "10px" }}>
          ＳＫＵ画面
          </Typography>
        </Grid>

        <Table sx={{border:0,maxWidth:300}} aria-label="spanning table" size="small">
            <TableBody>
              <TableRow>
                <TableCell align="left" bgcolor="lightgray" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="30px" >取引先品番</TableCell>
                <TableCell align="left" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="70px" >
                  {state.TRIHBN}
                </TableCell>
              </TableRow>
            </TableBody>
        </Table>
        <Table sx={{border:0,maxWidth:300}} aria-label="spanning table" size="small">
            <TableBody>
              <TableRow>
                <TableCell align="left" bgcolor="lightgray" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="30px" >商品名</TableCell>
                <TableCell align="left" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="70px" >
                  {shnmeikn}
                </TableCell>
              </TableRow>
            </TableBody>
        </Table>
        &nbsp;
         <Box className="dataGridList" style={gridStyle} sx={{ display: 'flex', justifyContent: 'flex-start',height: 300, width: '370px',padding:'0px',ml:'-45px',mt:'-30px' }}>
            <DataGrid
              sx={gridStyle.grid}     // --> 不具合対応のためcssで罫線を引く
              rows={rows}
              columns={columns}
              density='compact'//compact（狭め）、standard（標準）、comfortable（広め）
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              checkboxSelection
              disableRowSelectionOnClick
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}  // メニュー日本語化
              showColumnRightBorder                                             // 列ヘッダセルの右側に線を引く
              showCellRightBorder                                               // セルの右側に線を引く
              getRowClassName={(params) => {
                // if (params.row.DELFLG === '1') {
                //   // 削除済明細にクラスを設定する
                //   return 'row-deactive'
                // }
                // return '';
              }}
              rowSelectionModel={selectionModel}
              onRowSelectionModelChange={(RowIds) => {
                // const diffRows = rows.filter(x =>RowIds.indexOf(x.id) != -1);
                // const delRows = diffRows.filter(x => x.DELFLG == '1');
                if (RowIds.length > 1) 
                {
                  const newSelectedId = RowIds[RowIds.length - 1];
                  setSelectionModel([newSelectedId]);
                }else if (RowIds.length === 0){
                  setSelectionModel(selectionModel);
                } else {
                  setSelectionModel(RowIds);
                }

                // // console.log('選択行情報:%o',diffRows);
                // if (delRows.length > 0) {
                //   // 削除済明細の場合はクリックされた際に選択状態にしない
                //   return;
                // }
                // setSelectionModel(RowIds);
              }}
              paginationModel={pagenationModel}
              onPaginationModelChange={(model) => {
                console.log(model);
                setPagenationModel(model);
              }}
              disableMultipleRowSelection={true}//複数行選択不可
              />
          </Box>
        <Box ml={2} flexDirection="row" justifyContent="flex-end" display="flex" marginTop={'5px'}>
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:50,mr:'150px'}}
              onClick={(e) => onClick_FooterButton_Back()}
              >
              {'戻る'}
            </Button>&nbsp;
          </Box >
          <Box >
            <Button
              type="submit" 
              color="success" 
              variant="contained" 
              sx={{width:50,mr:'10px'}}
              onClick={(e) => onClick_FooterButton_Next2()}
              >
              {'選択'}
            </Button>&nbsp;
          </Box >
        </Box>
        {/* <p>{ state.usrnm }</p> */}
        <p><font color="red">{ alert }</font></p>
      </Paper>
    </Grid>
  );
};

export default TCSH0151;